

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import SearchView from '../search/SearchView';
import { Accordion, AccordionTab } from 'primereact/accordion';
import DynamicDataTableView from '../data-table/DynamicDataTableView';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';
import { Validators } from '../../utils';

import { orderAutoComplete, orderSearch } from '../../service/order.service'
import { autoCompleteWastetran, autoCompleteProduct } from '../../service/autocomplete.service'
import { PrintDialogView } from '../dialog/PrintDialogView';

import { dateFormat } from '../../utils/utilFunctions'

class ExampleWasteproReportBuyingCtrl extends Component {

    constructor(props) {
        console.log("ExampleWasteproReportBuyingCtrl :constructor")
        super(props);
        this.state = {
            showDialog: false,
            classNameRequired: {},
            //SearchView
            order_id: null,
            orderIdSuggestions: null,
            order_name: null,
            orderNameSuggestions: null,
            orderNameList: null,
            statusValue: 1000,
            //statusValue: { name: "เลือกสถานะ", value: 0 },
            pickupDate: null,
            pickupDateTo: null,
            appointmentDate: null,
            appointmentDateTo: null,
            collector_name: null,
            collectionNameSuggestions: null,
            //DynamicDataTable
            model: [
                { field: 'col1', header: 'พิมพ์ใบสรุป', body: this.actionTemplate },
                { field: 'col2', header: 'เลขที่', sortable: true },
                { field: 'col3', header: 'รายการ', sortable: true },
                { field: 'col4', header: 'วันที่ส่ง', sortable: true },
                { field: 'col5', header: 'ปริมาณส่ง (Kg)', sortable: true , className: 'p-text-right'},
                { field: 'col6', header: 'ราคา/หน่วย (บาท)', sortable: true , className: 'p-text-right'},
                { field: 'col7', header: 'ราคาขาย (บาท)', sortable: true , className: 'p-text-right'},
                { field: 'col8', header: 'ผู้ส่ง', sortable: true },
                { field: 'col9', header: 'สถานะ', sortable: true },
            ],
            pageNumber: 0,
        }

        //> Event : original input text
        this.onChangeInputText = this.onChangeInputText.bind(this);
        //> Event : search view
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onChangePickupdate = this.onChangePickupdate.bind(this);
        this.onChangePickupdateTo = this.onChangePickupdateTo.bind(this);
        this.onChangeAppointmentDate = this.onChangeAppointmentDate.bind(this);
        this.onChangeAppointmentDateTo = this.onChangeAppointmentDateTo.bind(this);
        this.onClickSearch = this.onClickSearch.bind(this);
        this.onSuggestorderIds = this.onSuggestorderIds.bind(this);
        this.onChangeAutocomplete = this.onChangeAutocomplete.bind(this);
        this.onSuggestorderNames = this.onSuggestorderNames.bind(this);
        this.onSuggestCollectionNames = this.onSuggestCollectionNames.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);

    }

    componentDidMount() {
        console.log("ExampleWasteproReportBuyingCtrl :componentDidMount")
        let criteria = {order_status: this.state.statusValue};

        this.props.dispatch(orderSearch("wastepro", criteria));

    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("ExampleWasteproReportBuyingCtrl :componentWillReceiveProps")
        if (nextProps.autoCompleteProduct && !nextProps.autoCompleteProduct.isLoading && !nextProps.autoCompleteProduct.isFailed) {
            if (nextProps.autoCompleteProduct.data) {
                this.setState({ orderNameList: nextProps.autoCompleteProduct.data })
            }
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("ExampleWasteproReportBuyingCtrl :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("ExampleWasteproReportBuyingCtrl :componentWillUpdate")
        if (nextState.order_id != this.state.order_id) {
            nextProps.dispatch(orderAutoComplete(nextState.order_id));
        }
        if (nextState.order_name != this.state.order_name) {
            nextProps.dispatch(autoCompleteProduct(nextState.order_name));
        }
        if (nextState.collector_name != this.state.collector_name) {
            nextProps.dispatch(autoCompleteWastetran(nextState.collector_name));
        }

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("ExampleWasteproReportBuyingCtrl :componentDidUpdate")
    }

    // Initial function
    initial = (object) => {
        if (object) {
            for (let key in object) {
                let val = object[key]
                this.setState({ [key]: val })
            }
        }
    }
    //> Submit
    onSubmit = () => {
        this.showSuccess();
    }

    //> Event
    onChangeInputText = (e) => {
        const { name, value, required } = e.target
        this.setState({ [name]: value })
        if (required) {
            let classNamed = [name] + "_required"
            if (Validators.isEmpty(value)) {
                this._classNameRequiredIsError(classNamed, true);
            } else {
                this._classNameRequiredIsError(classNamed, false);
            }
        }
    }
    onChangeStatus = (e) => {
        this.setState({ statusValue: e.value })
    }
    onChangePickupdate = (e) => {
        const { value } = e
        this.setState({ pickupDate: value })
        this._classNameRequiredIsError('pickup_date_required', false)
        if (!this.state.pickupDateTo) {
            this._classNameRequiredIsError('pickup_date_to_required', true)
        } else {
            this._classNameRequiredIsError('pickup_date_to_required', false)
        }
    }
    onChangePickupdateTo = (e) => {
        const { value } = e
        this.setState({ pickupDateTo: value })
        this._classNameRequiredIsError('pickup_date_to_required', false)
        if (!this.state.pickupDate) {
            this._classNameRequiredIsError('pickup_date_required', true)
        } else {
            this._classNameRequiredIsError('pickup_date_required', false)
        }
    }
    onChangeAppointmentDate = (e) => {
        const { value } = e
        this.setState({ appointmentDate: value })
        this._classNameRequiredIsError('appointment_date_required', false)
        if (!this.state.appointmentDateTo) {
            this._classNameRequiredIsError('appointment_date_to_required', true)
        } else {
            this._classNameRequiredIsError('appointment_date_to_required', false)
        }
    }
    onChangeAppointmentDateTo = (e) => {
        const { value } = e
        this.setState({ appointmentDateTo: value })
        this._classNameRequiredIsError('appointment_date_to_required', false)
        if (!this.state.appointmentDate) {
            this._classNameRequiredIsError('appointment_date_required', true)
        } else {
            this._classNameRequiredIsError('appointment_date_required', false)
        }
    }
    onClickSearch = (e) => {
        let search = true;
        let criteria = {};
        if (this.state.order_id) {
            criteria.order_code = this.state.order_id
        }
        if (this.state.order_name) {

            if (this.state.orderNameList instanceof Array) {
                var tmpCode = this.state.orderNameList.filter((rs) => {
                    return rs.product_name.indexOf(this.state.order_name) != -1
                })
                if (tmpCode[0]) {
                    criteria.product_code = tmpCode[0].product_code
                }
            }
        }
        if (this.state.statusValue || this.state.statusValue == 0 ) {
            criteria.order_status = this.state.statusValue
        }
        if (this.state.pickupDate) {
            var date = new Date(this.state.pickupDate.getTime() + 7 * 60 * 60000);
            criteria.pickup_date_start = date.toISOString();
            if (!this.state.pickupDateTo) {
                search = false;
            } else {
                search = true;
            }
        }
        if (this.state.pickupDateTo) {
            var date = new Date(this.state.pickupDateTo.getTime() + 7 * 60 * 60000);
            criteria.pickup_date_end = date.toISOString();
            if (!this.state.pickupDate) {
                search = false;
            } else {
                search = true;
            }
        }
        if (this.state.collector_name) {
            var tmpCode = this.props.autoCompleteWastetran.data.filter((rs) => {
                return rs.search_name.indexOf(this.state.collector_name) != -1
            })
            if (tmpCode[0]) {
                criteria.user2_search_name = tmpCode[0].uid
            }
        }
        console.log(criteria)
        if (search) {
            this.props.dispatch(orderSearch("wastepro", criteria));
            this.setState({ pageNumber: 0 });
        } else {
            this.showWarn("ไม่สามารถค้นหา กรุณาตรวจสอบ");
        }
    }
    onClickCancel = (e) => {
        this.setState({ order_id: null });
        this.setState({ order_name: null });
        this.setState({ statusValue: 1000 });
        this.setState({ pickupDate: null });
        this.setState({ pickupDateTo: null });
        this.setState({ appointmentDate: null });
        this.setState({ appointmentDateTo: null });
        this.setState({ collector_name: null });

        let criteria = {order_status: 1000};
        this.props.dispatch(orderSearch("wastepro", criteria));
        this.setState({ pageNumber: 0 });
    }
    onSuggestorderIds = (e) => {
        if (this.props.orderAutoComplete
            && this.props.orderAutoComplete.data
            && !this.props.orderAutoComplete.isLoading
        ) {
            // let results = this.props.orderAutoComplete.data.filter((orderId) => {
            //     return orderId.toLowerCase().startsWith(e.query.toLowerCase());
            // });

            this.setState({ orderIdSuggestions: this.props.orderAutoComplete.data });
        }
    }

    onSuggestorderNames = (e) => {

        if (this.props.autoCompleteProduct
            && this.props.autoCompleteProduct.data
            && !this.props.autoCompleteProduct.isLoading
        ) {
            // this.setState({ orderNameSuggestions: this.props.autoCompleteProduct.data });

            this.setState({ orderNameSuggestions: this.props.autoCompleteProduct.data.suggest });
        }

    }
    onSuggestCollectionNames = (e) => {

        if (this.props.autoCompleteWastetran
            && this.props.autoCompleteWastetran.data
            && !this.props.autoCompleteWastetran.isLoading
        ) {

            this.setState({ collectionNameSuggestions: this.props.autoCompleteWastetran.data.suggest });
        }
    }
    onChangeAutocomplete = (e) => {
        const { value, name } = e.target
        this.setState({ [name]: value })
    }
    //> Action print
    actionTemplate = (rowData, column) => {
        return <div>
            <Button
                type="button"
                icon="fa fa-print"
                className="p-button-success"
                onClick={(e) =>  window.open('/#/report-u3?order_code='+rowData.col2, "_blank") }
                style={{ marginRight: '.5em' }}></Button>
        </div>;
    }

    // ======= Validate ======
    validateAllRequired = (nextState) => {

    }
    _validateLogic = (name, value) => {
        if (Validators.isEmpty(value)) {
            this._classNameRequiredIsError(name, true);
        }
    }
    _classNameRequiredIsError = (classNamed, status) => {
        if (!classNamed) {
            if (status) {
                this.showWarn();
            }
        } else {
            if (status) {
                this.setState(pv => ({
                    classNameRequired: {
                        ...pv.classNameRequired, [classNamed]: "p-error "
                    }
                }))
            } else {
                this.setState(pv => ({
                    classNameRequired: {
                        ...pv.classNameRequired, [classNamed]: ""
                    }
                }))
            }
        }
    }
    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    render() {
        console.log("ExampleWasteproReportBuyingCtrl :render")
        let dynamicColumns = this.state.model.map((col, i) => {
            if (col.body) {
                return (<Column
                    header={col.header}
                    body={col.body}
                    style={{ textAlign: 'center', width: '8em' }}
                />)
                    ;
            } else if (col.field && col.header) {
                return <Column key={col.field}
                    field={col.field}
                    header={col.header}
                    sortable={col.sortable} />;
            } else {
                return <div></div>
            }
        });


        let data = []
        if (this.props.orderSearch && !this.props.orderSearch.isLoading) {
            if (!this.props.orderSearch.isFailed) {
                for (let key in this.props.orderSearch.data) {
                    let entity = this.props.orderSearch.data[key];
                    for (let keyDetail in entity.orderDetail) {
                        let entityDetail = entity.orderDetail[keyDetail]
                        if (entityDetail) {
                            let tmpData = {
                                "col1": null,
                                "col2": entity.order_code,
                                "col3": entityDetail.product_name,
                                "col4": dateFormat(entity.delivery_date),
                                "col5": entityDetail.delivery_quantity_kk,
                                "col6": entityDetail.price_per_unit,
                                "col7": entityDetail.delivery_quantity_kk * entityDetail.price_per_unit,
                                "col8": entity.u2_fullname,
                                "col9": entity.order_status_name
                            }
                            data.push(tmpData);
                        }
                    }

                }
            }
        }

        return (
            <div>
                <Growl ref={(el) => this.growl = el} />
                <h1>รายงานการรับซื้อ</h1>
                <div className="p-grid">
                    {/* DETAIL */}
                    <Accordion activeIndex={0}>
                        <AccordionTab header="เงื่อนไขของรายงาน">
                            <SearchView
                                mode="wastepro"
                                classNameRequired={this.state.classNameRequired}
                                order_id={this.state.order_id}
                                orderIdSuggestions={this.state.orderIdSuggestions}
                                order_name={this.state.order_name}
                                orderNameSuggestions={this.state.orderNameSuggestions}
                                statusValue={this.state.statusValue}
                                pickupDate={this.state.pickupDate}
                                pickupDateTo={this.state.pickupDateTo}
                                collector_name={this.state.collector_name}
                                collectionNameSuggestions={this.state.collectionNameSuggestions}
                                onChangeInputText={this.onChangeInputText}
                                onChangeStatus={this.onChangeStatus}
                                onChangePickupdate={this.onChangePickupdate}
                                onChangePickupdateTo={this.onChangePickupdateTo}
                                onChangeAppointmentDate={this.onChangeAppointmentDate}
                                onChangeAppointmentDateTo={this.onChangeAppointmentDateTo}
                                onClickSearch={this.onClickSearch}
                                onClickCancel={this.onClickCancel}
                                onSuggestorderIds={this.onSuggestorderIds}
                                onChangeAutocomplete={this.onChangeAutocomplete}
                                onSuggestCollectionNames={this.onSuggestCollectionNames}
                                onSuggestorderNames={this.onSuggestorderNames}
                            />
                        </AccordionTab>
                    </Accordion>
                </div>

                <div className="p-grid" style={{ marginTop: 20 }}>
                    <Panel header="รายงานการรับซื้อ">
                        <DynamicDataTableView
                            mode="wastepro"
                            model={this.state.model}
                            data={data}
                            exportExcel={true}
                            exportExcelFileName={'รายงานการรับซื้อ (ศูนย์จัดการฯซีซาเล้ง)'}
                            actionTemplate={this.actionTemplate}
                            loading={this.props.orderSearch.isLoading}
                        />
                    </Panel>
                </div>

                <PrintDialogView
                    onHide={(e) => this.setState({ showDialog: false })}
                    showDialog={this.state.showDialog}
                />
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    orderAutoComplete: state.orderAutoComplete,
    orderSearch: state.orderSearch,
    autoCompleteProduct: state.autoCompleteProduct,
    autoCompleteWastetran: state.autoCompleteWastetran
})

export default connect(mapStateToProps)(ExampleWasteproReportBuyingCtrl)
