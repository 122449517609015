
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';

export const DatatableOutBalanceView = ({listOutBalance}) => {

    return (
        <Panel header={(<div><labe><i class="fa fa-money"></i> ยอดเงินค้างโอน/ค้างชำระ</labe></div>)}>
            <DataTable value={listOutBalance} responsive={true} style={{ textAlign: 'center' }}>
                <Column field="col1" header="ลำดับ" style={{width:'70px'}} />
                <Column field="col2" header="กลุ่มผู้ใช้" />
                <Column className="p-text-right"field="col3" header="ขอเบิกแล้ว/แจ้งหนี้แล้ว ค้างโอน" />
                <Column className="p-text-right" field="col4" header="ยังไม่ได้เบิก/ไม่ได้แจ้งหนี้" />
            </DataTable>
        </Panel>
    )
}