import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Panel } from "primereact/components/panel/Panel";
import { Checkbox } from "primereact/components/checkbox/Checkbox";
import { Button } from "primereact/components/button/Button";

import { InputText } from "primereact/components/inputtext/InputText";
import { InputTextarea } from "primereact/components/inputtextarea/InputTextarea";
import { DataTable } from "primereact/components/datatable/DataTable";
import { Column } from "primereact/components/column/Column";
import { FullCalendar } from "primereact/fullcalendar";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Growl } from "primereact/growl";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { MapWithASearchBox } from "../../features/map/MapWithASearchBox";
import { MapMarkers } from "../../features/map/MapMarkers";
import { Calendar } from "primereact/calendar";
import { AutoComplete } from "primereact/autocomplete";
import { Avatar } from "../../features/widget/Avatar";
import { API_ROOT } from "../../constants/api";
import { ProgressSpinner } from "primereact/progressspinner";
import * as ServerAPI from "./service";
import * as LocationService from "../../service/LocationService";
import * as UserService from "../../service/user.service";
import * as MasterService from "../../service/MasterService";
import * as FileService from "../../service/file.service";
import * as DashBoardService from "./service";
import { isEmpty } from "../../utils/validators";
import {
  WASTE_TYPE,
  WASTE_TYPE_NUM_TO_NAME,
} from "../../constants/mappingValue";
import { CEInputText, CECalendar, CEDropdown } from "../../features/input";
import { CEFileUpload } from "../../features/widget";

class CreateOrderContainer extends Component {
  static propTypes = {};

  static defaultProps = {};

  constructor(props) {
    super(props);
    this.state = {
      mapGenZoom: 11,
      mapGenMarker: {},
      mapGenCenter: { lat: 13.7734283, lng: 100.57383099999993 },
      mapMarker: { lat: 13.7734283, lng: 100.57383099999993 },
      mapTranMarker: {},
      mapTranMarkers: [],
      mapTranZoom: 11,
      mapTranCenter: { lat: 13.7734283, lng: 100.57383099999993 },
      mapProMarkers: [],
      mapProZoom: 11,
      mapProCenter: { lat: 13.7734283, lng: 100.57383099999993 },
      wasteTypeItem: [
        { name: "ขวดแก้ว", code: 1 },
        { name: "กระดาษ", code: 2 },
        { name: "พลาสติก", code: 3 },
        { name: "โลหะ/อโลหะ", code: 4 },
        { name: "อิเล็กทรอนิกส์/เครื่องใช้ไฟฟ้า", code: 5 },
        { name: "อื่นๆ", code: 6 },
      ],
      productItem: [
        { name: "สินค้า 1", code: 1 },
        { name: "สินค้า 2", code: 2 },
        { name: "สินค้า 3", code: 3 },
        { name: "สินค้า 4", code: 4 },
        { name: "สินค้า 5", code: 5 },
        { name: "สินค้า 6", code: 6 },
      ],
      acceptFileExt: ".jpg,.jpeg,.png,.pdf,.JPG,.JPEG,.PNG,.PDF",
      OrderList: [
        // wasteType: '',
        // amount: '',
        // appraisedPrice: '',
        // salePrice: '',
      ],
      brandSuggestions: null,
      searchWastetran: [1, 2, 3, 4, 5],
      searchWastepro: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15],
      U1name: "นาย ผู้ขาย 1",
      U2name: "นาย JJ Mall",
      U3name: "ร้านมารวย",
      provinceTest: "",
      phone: "0812345678",
      addressDetail: "",
      province: 0,
      district: 0,
      subDistrict: 0,
      provinceCode: 0,
      districtList: [],
      districtCode: 0,
      subDistrictCode: 0,
      subDistrictList: [],
      lineId: "",
      email: "",
      genPhone: "",
      pickDate: null,
      pickDateStart: null,
      pickDateEnd: null,
      deliveryDate: null,
      deliveryCost: "",
      ModelInputLocation: {
        latitude: "13.7734283",
        longitude: "100.57383099999993",
        distance_km: 10,
      },
      wastetranDataList: [],
      wasteproDataList: [],
      provinceSelected: "",
      wasteTypeSelected: "",
      productSelected: "",
      amountSelected: "",
      appraisedPriceSelected: "",
      salePriceSelected: "",
      dataTableSelection2: "",
      dataTableSelection3: "",
      genImg: null,
      tranImg: null,
      proImg: null,
      personPrefixCode: "",
      personPrefix: "",
      showProgress: false,
      isProfileCompleted: false,
      isNotValid: false,
    };

    this.onUpload = this.onUpload.bind(this);
    this.onWasteTypeChange = this.onWasteTypeChange.bind(this);
    this.onproductsChange = this.onproductsChange.bind(this);
    this.onUsernameChanged = this.onUsernameChanged.bind(this);
    this.onSelectAutoCompleteSearchName = this.onSelectAutoCompleteSearchName.bind(
      this
    );
    this.onFileWasteChanged = this.onFileWasteChanged.bind(this);
    this.onFileDelete = this.onFileDelete.bind(this);
    this.onTableTranSelected = this.onTableTranSelected.bind(this);
    this.onTableProSelected = this.onTableProSelected.bind(this);
    this.getProfile = this.getProfile.bind(this);
    this.onProvinceChanged = this.onProvinceChanged.bind(this);
    this.onDistrictChanged = this.onDistrictChanged.bind(this);
    this.onSubDistrictChanged = this.onSubDistrictChanged.bind(this);
    this.mapChanged = this.mapChanged.bind(this);
    this.onMarkerMoved = this.onMarkerMoved.bind(this);
    this.onMarkerClicked = this.onMarkerClicked.bind(this);
    this.onMarkerProClicked = this.onMarkerProClicked.bind(this);
    this.addDataToTable = this.addDataToTable.bind(this);
    this.onClickSaveOrder = this.onClickSaveOrder.bind(this);
    this.onPickDateStartChange = this.onPickDateStartChange.bind(this);
    this.onPickDateEndChange = this.onPickDateEndChange.bind(this);
    // this.onPickupDateChange = this.onPickupDateChange.bind(this);
    this.onDeliveryDateChange = this.onDeliveryDateChange.bind(this);
    this.clearAllDataInput = this.clearAllDataInput.bind(this);
    this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
    this.requestProLocation = this.requestProLocation.bind(this);
    this.selectedTranByLatLng = this.selectedTranByLatLng.bind(this);
    this.selectedProByLatLng = this.selectedProByLatLng.bind(this);
    this.distanceTemplate = this.distanceTemplate.bind(this);
    this.onValidation = this.onValidation.bind(this);
    this.handleCreateResult = this.handleCreateResult.bind(this);
  }

  componentDidMount() {
    const {
      dashBoardServiceProps,
      locationServiceProps,
      masterServiceProps,
    } = this.props;
    dashBoardServiceProps.getDashboardUsers();
    locationServiceProps.getProvice();
    masterServiceProps.getProductType();
  }
  onValidation(valid) {
    console.log(valid);
    if (!valid) this.setState({ isNotValid: true });
    else this.setState({ isNotValid: false });
  }
  onProvinceChanged(e) {
    console.log(e);
    this.setState({
      provinceSelected: e.value,
      genAddressProvince: e.value.province_name,
    });
    this.setState({ provinceCode: e.value.provinceCode });
    this.setState({ districtSelected: null });
    this.setState({ subDistrictSelected: null });
    ServerAPI.getDistrict(e.value.provinceCode).then((res) => {
      this.setState({ districtList: res });
    });
  }
  onDistrictChanged(e) {
    this.setState({
      districtSelected: e.value,
      genAddressDistrict: e.value.districtName,
    });
    this.setState({ districtCode: e.value.districtCode });
    this.setState({ subDistrictSelected: null });
    ServerAPI.getSubDistrict(e.value.districtCode).then((res) => {
      this.setState({ subDistrictList: res });
    });
  }
  onSubDistrictChanged(e) {
    this.setState({ subDistrictSelected: e.value });
    this.setState({ subDistrictCode: e.value.subDistrictCode });
  }

  onPickDateStartChange(e) {
    //console.log(e.value.toISOString())
    if (this.state.deliveryDate != null || this.state.pickDateEnd != null) {
      if (
        e.value < this.state.deliveryDate ||
        e.value < this.state.pickDateEnd
      ) {
        this.setState({ pickDateStart: e.value });
      }
    } else this.setState({ pickDateStart: e.value });
  }
  onPickDateEndChange(e) {
    console.log(e);
    if (this.state.pickDateStart != null) {
      if (e.value > this.state.pickDateStart) {
        this.setState({ pickDateEnd: e.value });
      }
    } else this.setState({ pickDateEnd: e.value });
  }

  onDeliveryDateChange(e) {
    //if (this.state.pickDateStart < e.value) {
    this.setState({ deliveryDate: e.value });
    //}
  }

  // onPickupDateChange(e){
  //     this.setState({ pickDate: e.value })
  //     this.setState({ pickDateStart: e.value[0], pickDateEnd: e.value[1]})
  // }

  addDataToTable() {
    var isNotinput =
      this.state.wasteTypeSelected == "" ||
      this.state.productSelected == "" ||
      this.state.amountSelected == "" ||
      this.state.appraisedPriceSelected == "" ||
      this.state.salePriceSelected == "";

    if (isNotinput) {
      this.showWarn();
    } else {
      var OrderDetailList = {
        product_type_code: this.state.wasteTypeSelected.product_type_code,
        product_code: this.state.productSelected.product_code,
        product_name: this.state.productSelected.product_name,
        // wasteType: this.state.wasteTypeSelected.product_type_name,
        // amount: parseFloat(this.state.amountSelected).toFixed(2),
        // appraisedPrice: parseFloat(this.state.appraisedPriceSelected).toFixed(2),
        // salePrice: parseFloat(this.state.salePriceSelected).toFixed(2),

        quantity_kk: parseFloat(this.state.amountSelected).toFixed(2),
        estimate_price_per_unit: parseFloat(
          this.state.appraisedPriceSelected
        ).toFixed(2),
        price_per_unit: parseFloat(this.state.salePriceSelected).toFixed(2),
      };
      this.state.OrderList.push(OrderDetailList);

      this.setState({ wasteTypeSelected: "" });
      this.setState({ productSelected: "" });
      this.setState({ amountSelected: "" });
      this.setState({ appraisedPriceSelected: "" });
      this.setState({ salePriceSelected: "" });
      this.showSuccess();
    }
    console.log(this.state.OrderList);
  }

  onWasteTypeChange(e) {
    this.setState({ wasteTypeSelected: e.value });
    this.setState({ productTypeCode: e.value.product_type_code });

    const { masterServiceProps, product } = this.props;
    masterServiceProps.getProduct(e.value.product_type_code);

    this.setState({ productSelected: "" });
  }
  onproductsChange(e) {
    this.setState({ productSelected: e.value });
  }

  onUpload(event) {
    this.growl.show({
      severity: "info",
      summary: "Success",
      detail: "File Uploaded",
    });
  }
  onFileDelete(index) {
    var array = this.state.fileImgWaste;
    array.splice(index, 1);
    this.setState({ fileImgWaste: array });
  }
  onFileWasteChanged(e) {
    console.log(e.files);
    //this.setState({ fileImgIden: e.target.files[0] });
    var list = [];
    for (let index = 0; index < e.files.length; index++) {
      const file = e.files[index];
      console.log(file);
      list.push(file);
    }
    if (this.state.fileImgWaste) {
      var arr = this.state.fileImgWaste.concat(list);
      this.setState({ fileImgWaste: arr });
    } else {
      this.setState({ fileImgWaste: list });
    }
  }

  //auto complate handle
  suggestBrands(event) {
    console.log(event);
    /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

    //this.setState({ brandSuggestions: event });
  }

  onCenterChanged = (coor) => {
    console.log("onCenterChanged");
    console.log(this.state.mapGenMarker);
    if (coor) {
      this.setState({ latitude: coor.lat });
      this.setState({ longitude: coor.lng });
      this.setState({ mapGenMarker: { lat: coor.lat, lng: coor.lng } });
      let searchProModel = {
        latitude: coor.lat,
        longitude: coor.lng,
        distance_km: 10,
      };
      this.requestProLocation(searchProModel);
    }
  };
  onMarkerMoved(e) {
    var coor = e.latLng.toJSON();
    this.setState({ mapGenMarker: coor });
    //this.setState({ longitude: coor.lng })
    //this.setState({ marker: { lat: coor.lat, lng: coor.lng } })
    this.setState({ mapCenter: { lat: coor.lat, lng: coor.lng } });
    let searchProModel = {
      latitude: coor.lat,
      longitude: coor.lng,
      distance_km: 10,
    };
    this.requestProLocation(searchProModel);
  }

  selectedProByLatLng(coor) {
    this.setState({ dataTableSelection2: "" });
    this.setState({ u2_uid: "" });
    this.state.wasteproDataList.forEach((objPro) => {
      console.log("objPro.lat:" + objPro.lat);
      console.log("coor.lat   :" + coor.lat.toFixed(12));
      console.log("objPro.lng:" + objPro.lng);
      console.log("coor.lng   :" + coor.lng.toFixed(12));
      if (
        objPro.lat.toFixed(12) == coor.lat.toFixed(12) &&
        objPro.lng.toFixed(12) == coor.lng.toFixed(12)
      ) {
        console.log("ตรง");
        this.setState({ dataTableSelection3: objPro });
        this.setState({ proName: objPro.pro_name });
        this.setState({ proAddress: objPro.address_detail });
        this.setState({ proPhone: objPro.telephone_no1 });
        this.setState({ u3_uid: objPro.uid });

        var marder = { lat: objPro.lat, lng: objPro.lng };
        this.setState({ mapTranMarker: marder });

        let searchTranModel = {
          latitude: objPro.lat,
          longitude: objPro.lng,
          distance_km: 10,
          u3_uid: objPro.uid
        };
        ServerAPI.getTranLocation(searchTranModel)
          .then((res) => {
            console.log(res);
            this.setState({ wastetranDataList: res }, this.selectedTran);
            const markers = [];
            res.forEach((loc) => {
              markers.push({ lat: loc.lat, lng: loc.lng });
            });
            this.setState({ mapTranMarkers: markers });
          })
          .catch((error) => {});
      }
    });
  }

  selectedTranByLatLng(coor) {
    this.state.wastetranDataList.forEach((objTran) => {
      if (
        objTran.lat.toFixed(12) == coor.lat.toFixed(12) &&
        objTran.lng.toFixed(12) == coor.lng.toFixed(12)
      ) {
        this.setState({ dataTableSelection2: objTran });
        this.setState({ tranImg: objTran.img_person_name });
        this.setState({ tranName: objTran.tran_name });
        this.setState({ tranAddress: objTran.address_detail });
        this.setState({ tranPhone: objTran.telephone_no1 });
        this.setState({ u2_uid: objTran.uid });
      }
    });
  }

  getProfile(uid) {
    ServerAPI.getProfile(uid)
      .then((obj) => {
        console.log(obj);

        const currentUserInfo = obj.user_wasteTypes.find(
          (item) => item.wasteType === WASTE_TYPE["wastegen"]
        );
        console.log(currentUserInfo);
        if (currentUserInfo.profileStatusCode != 99) {
          this.showWarn("ข้อมูลโปรไฟล์ไม่สมบูรณ์");
          this.setState({ showProgress: false });
          this.setState({ isProfileCompleted: false });
        } else {
          this.setState({ isProfileCompleted: true });

          this.setState({ addressDetail: obj.addr_current_detail });
          this.setState({ provinceCode: obj.addr_current_province_code });
          this.setState({ districtCode: obj.addr_current_district_code });
          this.setState({ subDistrictCode: obj.addr_current_subdistrict_code });
          this.setState({ lineId: obj.line_id });
          this.setState({ email: obj.email });
          this.setState({ genImg: obj.img_person_name });
          this.setState({ genPhone: obj.telephone_no1 });
          this.setState({ genFname: obj.person_first_name });
          this.setState({ genLname: obj.person_last_name });
          this.setState({ personType: obj.person_type });
          this.setState({ personPrefixCode: obj.person_prefix_code });
          this.setState({ personIdcard: obj.person_idcard });
          this.setState({ latitude: obj.latitude });
          this.setState({ longitude: obj.longitude });

          if (this.state.personPrefixCode == 1) {
            this.setState({ personPrefix: "นาย" });
          }
          if (this.state.personPrefixCode == 2) {
            this.setState({ personPrefix: "นาง" });
          }
          if (this.state.personPrefixCode == 3) {
            this.setState({ personPrefix: "นางสาว" });
          }
          this.setState({ orgTypeCode: obj.org_type });
          if (this.state.orgTypeCode == 1) {
            this.setState({ orgType: "บริษัทจำกัด" });
          }
          if (this.state.orgTypeCode == 2) {
            this.setState({ orgType: "ห้างหุ้นส่วน" });
          }
          if (this.state.orgTypeCode == 3) {
            this.setState({ orgType: "บริษัทมหาชน" });
          }
          if (this.state.orgTypeCode == 4) {
            this.setState({ orgType: "ห้างร้าน" });
          }
          this.setState({ orgName: obj.org_name });

          var genMarder = { lat: obj.latitude, lng: obj.longitude };
          this.setState({ mapGenCenter: genMarder });
          this.setState({ mapGenMarker: genMarder });
          if (this.state.mapGenZoom == 12) {
            this.setState({ mapGenZoom: 12 });
          } else {
            this.setState({ mapGenZoom: 13 });
          }

          var marder = { lat: obj.latitude, lng: obj.longitude };
          this.setState({ mapProMarker: marder });

          ServerAPI.getDistrict(obj.addr_current_province_code).then((res) => {
            this.setState({ districtList: res });
            ServerAPI.getSubDistrict(obj.addr_current_district_code).then(
              (res) => {
                this.setState({ subDistrictList: res });
                this.bindingProfile();
              }
            );
          });

          let searchProModel = {
            latitude: obj.latitude,
            longitude: obj.longitude,
            distance_km: 10,
          };
          this.requestProLocation(searchProModel);
        }
      })
      .catch((error) => {});
  }

  requestProLocation(searchProModel) {
    ServerAPI.getProLocation(searchProModel)
      .then((res) => {
        console.log(res);
        this.setState({ wasteproDataList: res });
        const markers = [];
        res.forEach((loc) => {
          markers.push({ lat: loc.lat, lng: loc.lng });
        });
        this.setState({ mapProMarkers: markers });
        this.setState({
          mapProCenter: {
            lat: searchProModel.latitude,
            lng: searchProModel.longitude,
          },
        });
        this.setState({ showProgress: false });
        if (this.state.mapProZoom == 12) {
          this.setState({ mapProZoom: 13 });
        } else {
          this.setState({ mapProZoom: 12 });
        }
      })
      .catch((error) => {});

    // ServerAPI.getTranLocation(searchTranModel).then((res) => {
    //     console.log(res);
    //     this.setState({ wastetranDataList: res });
    //     const markers = [];
    //     res.forEach(loc => {
    //         markers.push({ lat: loc.lat, lng: loc.lng});
    //     });
    //     this.setState({ mapTranMarkers: markers });
    //     this.setState({ mapTranCenter: { lat: searchTranModel.latitude, lng: searchTranModel.longitude } });
    //     this.setState({ showProgress: false })
    // }).catch((error) => {
    // })
  }
  bindingProfile() {
    const {
      locationMaster,
      provinces,
      districts,
      subDistricts,
      localtionServiceProps,
    } = this.props;

    if (this.state.provinceCode && !locationMaster.isFetching) {
      provinces.forEach((objProvince) => {
        // console.log(JSON.stringify(objProvince));

        if (objProvince.provinceCode == this.state.provinceCode) {
          this.setState({ provinceSelected: objProvince });
          this.setState({ genAddressProvince: objProvince.province_name });
        }
      });
    }

    if (this.state.districtCode) {
      this.state.districtList.forEach((objDistrict) => {
        if (objDistrict.districtCode == this.state.districtCode) {
          this.setState({ districtSelected: objDistrict });
          this.setState({ genAddressDistrict: objDistrict.districtName });
        }
      });
    }

    if (this.state.subDistrictCode) {
      this.state.subDistrictList.forEach((objSubDistrict) => {
        if (objSubDistrict.subDistrictCode == this.state.subDistrictCode) {
          this.setState({ subDistrictSelected: objSubDistrict });
        }
      });
    }
  }

  onTableProSelected(e) {
    this.setState({ dataTableSelection2: "" });
    this.setState({ u2_uid: "" });
    console.log(e.value);
    this.setState({ dataTableSelection3: e.value });
    this.setState({ proImg: e.value.img_person_name });
    this.setState({ proName: e.value.pro_name });
    this.setState({ proAddress: e.value.address_detail });
    this.setState({ proPhone: e.value.telephone_no1 });
    this.setState({ u3_uid: e.value.uid });

    this.setState({ mapProCenter: { lat: e.value.lat, lng: e.value.lng } });
    if (this.state.mapProZoom == 12) {
      this.setState({ mapProZoom: 13 });
    } else {
      this.setState({ mapProZoom: 12 });
    }

    var marder = { lat: e.value.lat, lng: e.value.lng };
    this.setState({ mapTranMarker: marder });

    let searchTranModel = {
      latitude: e.value.lat,
      longitude: e.value.lng,
      distance_km: 10,
      u3_uid: e.value.uid
    };
    ServerAPI.getTranLocation(searchTranModel)
      .then((res) => {
        console.log(res);
        this.setState({ wastetranDataList: res });
        const markers = [];
        res.forEach((loc) => {
          markers.push({ lat: loc.lat, lng: loc.lng });
        });
        this.setState({ mapTranMarkers: markers });
        this.setState({
          mapTranCenter: { lat: e.value.lat, lng: e.value.lng },
        });
        if (this.state.mapTranZoom == 12) {
          this.setState({ mapTranZoom: 13 });
        } else {
          this.setState({ mapTranZoom: 12 });
        }
      })
      .catch((error) => {});
  }

  onTableTranSelected(e) {
    console.log(e.value);
    this.setState({ dataTableSelection2: e.value });
    this.setState({ tranImg: e.value.img_person_name });
    this.setState({ tranName: e.value.tran_name });
    this.setState({ tranAddress: e.value.address_detail });
    this.setState({ tranPhone: e.value.telephone_no1 });
    this.setState({ u2_uid: e.value.uid });

    this.setState({ mapTranCenter: { lat: e.value.lat, lng: e.value.lng } });
    if (this.state.mapTranZoom == 12) {
      this.setState({ mapTranZoom: 13 });
    } else {
      this.setState({ mapTranZoom: 12 });
    }
  }

  onUsernameChanged(search_name) {
    if (typeof search_name != "object")
      if (search_name.trim() != "") {
        this.setState({ brand: search_name });
        if (search_name.length >= 1) {
          ServerAPI.searchMemberName(search_name)
            .then((res) => {
              console.log(res);
              this.setState({ brandSuggestions: res });
            })
            .catch((error) => {});
        }
      } else this.setState({ brand: search_name });
  }
  itemTemplate(item) {
    return <div className="p-clearfix">{item.search_name}</div>;
  }
  onSelectAutoCompleteSearchName(item) {
    this.clearAllDataInput();
    this.setState({ brand: item.value.search_name });
    //console.log(item);
    //this.setState({ModelInputLocation: item.value.uid});
    this.setState({ showBodyDetail: true });
    this.setState({ showProgress: true });
    this.setState({ u1_uid: item.value.uid });
    this.getProfile(item.value.uid);
  }
  mapChanged(e) {
    console.log(e);
  }
  onMarkerClicked(e) {
    console.log("onMarkerClicked");
    var coor = e.latLng.toJSON();
    console.log(coor);
    this.selectedTranByLatLng(coor);
  }
  onMarkerProClicked(e) {
    console.log("onMarkerClicked");
    var coor = e.latLng.toJSON();
    console.log(coor);
    this.selectedProByLatLng(coor);
  }

  showSuccess = (message) => {
    this.growl.show({
      severity: "success",
      summary: "สถานะ",
      detail: message ? message : "ทำรายการสำเร็จ",
    });
  };
  showWarn = (message) => {
    this.growl.show({
      severity: "warn",
      summary: "สถานะ",
      detail: message ? message : "มีบางอย่างผิดพลาด กรุณาตรวจสอบ",
    });
  };
  clearAllDataInput() {
    this.setState({ showBodyDetail: false });
    this.setState({ brand: "" });
    this.setState({ addressDetail: "" });
    this.setState({ provinceSelected: "" });
    this.setState({ districtSelected: "" });
    this.setState({ subDistrictSelected: "" });
    this.setState({ lineId: "" });
    this.setState({ email: "" });
    this.setState({ genPhone: "" });
    this.setState({ pickDate: null });
    this.setState({ pickDateStart: null });
    this.setState({ pickDateEnd: null });
    this.setState({ deliveryDate: null });
    this.setState({ deliveryCost: "" });
    this.setState({ wasteTypeSelected: "" });
    this.setState({ productSelected: "" });
    this.setState({ amountSelected: "" });
    this.setState({ appraisedPriceSelected: "" });
    this.setState({ salePriceSelected: "" });
    var OrderDetailList = {};
    this.state.OrderList.push(OrderDetailList);
    this.setState({ OrderList: [] });
    this.setState({ wastetranDataList: "" });
    this.setState({ wasteproDataList: "" });
    this.setState({ personPrefix: "" });
    this.setState({ orgType: "" });
    this.setState({ orgName: "" });
    this.setState({ genFname: "" });
    this.setState({ genLname: "" });
    this.setState({ genAddressProvince: "" });
    this.setState({ genAddressDistrict: "" });
    this.setState({ genImg: "" });
    this.setState({ tranName: "" });
    this.setState({ tranImg: "" });
    this.setState({ tranAddress: "" });
    this.setState({ tranPhone: "" });
    this.setState({ proName: "" });
    this.setState({ proImg: "" });
    this.setState({ proAddress: "" });
    this.setState({ proPhone: "" });
    this.setState({ mapGenMarker: {} });
    this.setState({ mapTranMarker: {} });
    this.setState({ mapProMarker: {} });
    this.setState({ mapTranMarkers: [] });
    this.setState({ mapProMarkers: [] });
    this.setState({
      mapGenCenter: { lat: 13.7734283, lng: 100.57383099999993 },
    });
    this.setState({
      mapTranCenter: { lat: 13.7734283, lng: 100.57383099999993 },
    });
    this.setState({
      mapProCenter: { lat: 13.7734283, lng: 100.57383099999993 },
    });
    this.setState({ mapGenZoom: 11 });
    this.setState({ mapTranZoom: 11 });
    this.setState({ mapProZoom: 11 });
    this.setState({ dataTableSelection2: "" });
    this.setState({ dataTableSelection3: "" });

    this.setState({ fileImgWaste: null });

    console.log(this.state.fileImgWaste);
  }
  //validation
  onClickSaveOrder() {
    console.log(this.state.isNotValid);
    var inputAll = !(
      this.state.addressDetail == "" ||
      this.state.provinceSelected == "" ||
      this.state.districtSelected == "" ||
      this.state.subDistrictSelected == "" ||
      this.state.provinceSelected == null ||
      this.state.districtSelected == null ||
      this.state.subDistrictSelected == null ||
      this.state.genPhone == "" ||
      this.state.pickDateStart == null ||
      this.state.pickDateEnd == null ||
      this.state.deliveryDate == null ||
      this.state.pickDateStart == "" ||
      this.state.pickDateEnd == "" ||
      this.state.deliveryDate == "" ||
      this.state.OrderList == "" ||
      this.state.isNotValid
    );
    // this.state.wastetranDataList == '' || this.state.wasteproDataList == '' ||
    // this.state.dataTableSelection2 == '' || this.state.dataTableSelection3 == ''

    if (!inputAll) {
      this.showWarn();
      console.log(this.state.pickDateStart);
      console.log(this.state.deliveryDate);
    } else {
      this.createNewOrder();
    }
  }

  showWastegen() {
    const genPersonName = (
      <div className="p-col-12">
        <span style={{ color: "blue" }}>
          {this.state.personPrefix} {this.state.genFname} {this.state.genLname}{" "}
        </span>
        <span>
          {this.state.genAddressProvince} {this.state.genAddressDistrict}, Mob :{" "}
          {this.state.genPhone}
        </span>
      </div>
    );
    const genOrgName = (
      <div className="p-col-12">
        <span style={{ color: "blue" }}>
          {this.state.orgType} {this.state.orgName}{" "}
        </span>
        <span>
          {this.state.genAddressProvince} {this.state.genAddressDistrict}, Mob :{" "}
          {this.state.genPhone}
        </span>
      </div>
    );

    if (this.state.personType == 1) {
      return genPersonName;
    }
    if (this.state.personType == 2) {
      return genOrgName;
    } else return ", Mob :";
  }
  showWastetran() {
    const tranPersonName = (
      <div className="p-col-12">
        <span style={{ color: "red" }}>{this.state.tranName} </span>
        <span>
          {this.state.tranAddress} , Mob : {this.state.tranPhone}
        </span>
      </div>
    );
    // const tranOrgName = (
    //     <div className="p-col-12">
    //         <span style={{ color: 'blue' }}>{this.state.orgType} {this.state.orgName} </span>
    //         <span>{this.state.genAddress} , Mob : {this.state.genPhone}</span>
    //     </div>
    // );

    // if(this.state.personType == 1){
    //     return tranPersonName;
    // }if(this.state.personType == 2){
    //     return tranOrgName;
    // }
    return tranPersonName;
  }
  showWastepro() {
    const proPersonName = (
      <div className="p-col-12">
        <span style={{ color: "green" }}>{this.state.proName} </span>
        <span>
          {this.state.proAddress} , Mob : {this.state.proPhone}
        </span>
      </div>
    );
    // const proOrgName = (
    //     <div className="p-col-12">
    //         <span style={{ color: 'blue' }}>{this.state.orgType} {this.state.orgName} </span>
    //         <span>{this.state.genAddress} , Mob : {this.state.genPhone}</span>
    //     </div>
    // );

    // if(this.state.personType == 1){
    //     return proPersonName;
    // }if(this.state.personType == 2){
    //     return proOrgName;
    // }
    return proPersonName;
  }

  handleCreateResult(isSuccess, message) {
    if (isSuccess) {
        this.showSuccess();
        this.clearAllDataInput();
      } else {
        this.showWarn(message);
      }
  }

  createNewOrder() {
    console.log(this.state);

    const formData = new FormData();
    // formData.set('ProductImages',this.state.googleId);
    // formData.set('ProductImageNames',this.state.googleId);

    if (this.state.fileImgWaste != null)
      for (let index = 0; index < this.state.fileImgWaste.length; index++) {
        const file = this.state.fileImgWaste[index];
        formData.append("ProductImages", file);
      }

    formData.set(
      "OrderDetailListString",
      JSON.stringify(this.state.OrderList) || null
    );
    //formData.set('OrderDetail', this.state.prefixCode || 0);
    //formData.set('order_code', this.state.firstName);
    formData.set("u1_uid", this.state.u1_uid || "");
    formData.set("u2_uid", this.state.u2_uid || "");
    formData.set("u3_uid", this.state.u3_uid || "");
    formData.set("person_type", this.state.personType || 0);
    formData.set("person_prefix_code", this.state.personPrefixCode || 0);
    formData.set("person_first_name", this.state.genFname || "");
    formData.set("person_last_name", this.state.genLname || "");
    formData.set("person_idcard", this.state.personIdcard || "");
    formData.set("org_type", this.state.orgTypeCode || 0);
    formData.set("org_name", this.state.orgName || "");
    formData.set("telephone_no1", this.state.genPhone || "");
    formData.set("telephone_no2", this.state.genPhone2 || "");
    formData.set("line_id", this.state.lineId || "");
    formData.set("email", this.state.email || "");
    formData.set("addr_current_province_code", this.state.provinceCode || 0);
    formData.set("addr_current_district_code", this.state.districtCode || 0);
    formData.set(
      "addr_current_subdistrict_code",
      this.state.subDistrictCode || 0
    );
    formData.set("addr_current_detail", this.state.addressDetail || "");
    formData.set("latitude", this.state.latitude || 0);
    formData.set("longitude", this.state.longitude || 0);
    formData.set(
      "pickup_start_date",
      this.state.pickDateStart.toISOString() || ""
    );
    formData.set("pickup_end_date", this.state.pickDateEnd.toISOString() || "");
    formData.set("delivery_date", this.state.deliveryDate.toISOString() || "");
    formData.set("delivery_cost", this.state.deliveryCost || 0);
    // formData.set('created_date',this.state.googleId);
    // formData.set('update_date',this.state.googleId);

    ServerAPI.CreateOrder(formData, this.handleCreateResult)
  }

  actionTemplate(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          onClick={() => this.onClickDeleteItem(column.rowIndex)}
          icon="fa fa-trash"
          className="p-button-warning"
        />
      </div>
    );
  }
  distanceTemplate(rowData, column) {
    let distance = rowData.distance_km.toFixed(3);
    return (
      <div>
        <span> {distance}</span>
      </div>
    );
  }

  onClickDeleteItem(index) {
    if (index !== -1) {
      var array = this.state.OrderList;
      array.splice(index, 1);
      this.setState({ OrderList: array });
    }
  }
  showSearchPanel() {
    return (
      <div className="p-col-12">
        <div className="p-col-12">
          <h1>สร้าง Order ใหม่</h1>
        </div>
        <div className="card card-w-title">
          <label className="p-col-12 ">ค้นหาสมาชิก ผู้รักษ์โลก:</label>
          <div className="p-col-12 ">
            <AutoComplete
              inputClassName="input-100"
              value={this.state.brand}
              suggestions={this.state.brandSuggestions}
              onSelect={this.onSelectAutoCompleteSearchName}
              completeMethod={this.suggestBrands.bind(this)}
              onChange={(e) => this.onUsernameChanged(e.target.value)}
              itemTemplate={this.itemTemplate.bind(this)}
              placeholder="ระบุเบอร์โทรศัพท์/ชื่อสมาชิก"
            />
          </div>
        </div>
      </div>
    );
  }
  matchingResult() {
    const wastegenDetail = this.showWastegen();
    const wastetranDetail = this.showWastetran();
    const wasteproDetail = this.showWastepro();
    console.log(this.state.dataTableSelection2);
    console.log(this.state.dataTableSelection3);
    if (
      this.state.dataTableSelection3 == "" ||
      this.state.dataTableSelection2 == ""
    ) {
      return <div />;
    } else
      return (
        <Accordion multiple={true} activeIndex={[0]}>
          <AccordionTab header="ผลการ Matching">
            <div className="p-grid">
              <div className="p-col-12">
                <label>ผู้รักษ์โลก</label>
              </div>
              <div className="p-col-12 pg-md-12 p-lg-3" />
              <div className="p-col-12 pg-md-12 p-lg-9">
                <div className="p-col-12">
                  <Avatar
                    src={`${API_ROOT}/wastegen/getfile/${this.state.genImg}`}
                  />
                </div>
                {wastegenDetail}
              </div>

              <div className="p-col-12">
                <label>ทีมขนส่งซีซาเล้ง</label>
              </div>
              <div className="p-col-12 pg-md-12 p-lg-3" />
              <div className="p-col-12 pg-md-12 p-lg-9">
                <div className="p-col-12">
                  <Avatar
                    src={`${API_ROOT}/wastetran/getfile/${this.state.tranImg}`}
                  />
                </div>
                {wastetranDetail}
              </div>

              <div className="p-col-12">
                <label>ศูนย์จัดการฯซีซาเล้ง</label>
              </div>
              <div className="p-col-12 pg-md-12 p-lg-3" />
              <div className="p-col-12 pg-md-12 p-lg-9">
                <div className="p-col-12">
                  <Avatar
                    src={`${API_ROOT}/wastetran/getfile/${this.state.proImg}`}
                  />
                </div>
                {wasteproDetail}
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      );
  }
  showCreateDetail() {
    const {
      provinces,
      districts,
      subDistricts,
      productType,
      product,
    } = this.props;
    const minDate = new Date();
    const matchingTab = this.matchingResult();

    var tranHeader = (
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }} />
        <InputText
          type="search"
          onInput={(e) => this.setState({ globalFilterTran: e.target.value })}
          placeholder="กรอกชื่อ-สกุล หรือที่อยู่"
          size="50"
        />
      </div>
    );
    var proHeader = (
      <div style={{ textAlign: "left" }}>
        <i className="pi pi-search" style={{ margin: "4px 4px 0 0" }} />
        <InputText
          type="search"
          onInput={(e) => this.setState({ globalFilterPro: e.target.value })}
          placeholder="กรอกชื่อ-สกุล หรือที่อยู่"
          size="50"
        />
      </div>
    );
    if (
      !this.state.showBodyDetail ||
      this.state.showProgress ||
      !this.state.isProfileCompleted
    ) {
      return <div />;
    } else
      return (
        <div className="p-grid">
          <div className="p-col-12 pg-md-12 p-lg-6">
            <div className="card card-w-title">
              <Accordion multiple={true} activeIndex={[0]}>
                <AccordionTab header="รายละเอียดที่อยู่">
                  <div className="p-grid">
                    <label className="p-col-12 required">
                      เลขที่บ้าน/ที่อยู่
                    </label>
                    <div className="p-col-12 ">
                      <CEInputText
                        id="addressDetail"
                        type="text"
                        className="input-100"
                        value={this.state.addressDetail}
                        name="addressDetail"
                        placeholder="เลขที่บ้าน/ที่อยู่"
                        tooltip="กรอก เป็นตัวเลขหรือตัวอักษร"
                        maxlength={200}
                        required={true}
                        onChange={(e) =>
                          this.setState({ addressDetail: e.target.value })
                        }
                        isvalid={this.onValidation}
                      />
                    </div>
                    <label className="p-col-12 required">จังหวัด</label>
                    <div className="p-col-12 ">
                      <CEDropdown
                        required={true}
                        style={{ width: "100%" }}
                        value={this.state.provinceSelected}
                        options={provinces}
                        onChange={this.onProvinceChanged}
                        optionLabel="province_name"
                        placeholder="เลือกจังหวัด"
                        isvalid={(valid) => console.log(valid)}
                      />
                    </div>
                    <label className="p-col-12 required">อำเภอ/เขต</label>
                    <div className="p-col-12 ">
                      <CEDropdown
                        required={true}
                        style={{ width: "100%" }}
                        value={this.state.districtSelected}
                        options={this.state.districtList}
                        onChange={this.onDistrictChanged}
                        optionLabel="districtName"
                        placeholder="อำเภอ/เขต"
                        isvalid={(valid) => console.log(valid)}
                      />
                    </div>
                    <label className="p-col-12 required">ตำบล/แขวง</label>
                    <div className="p-col-12 ">
                      <CEDropdown
                        required={true}
                        style={{ width: "100%" }}
                        value={this.state.subDistrictSelected}
                        options={this.state.subDistrictList}
                        onChange={this.onSubDistrictChanged}
                        optionLabel="subDistrictName"
                        placeholder="ตำบล/แขวง"
                        isvalid={(valid) => console.log(valid)}
                      />
                    </div>

                    <label
                      className="p-col-12 p-md-6"
                      style={{ textAlign: "left", alignSelf: "center" }}
                    >
                      Line ID
                    </label>
                    <div
                      className="p-col-12 p-md-6"
                      style={{ textAlign: "left", alignSelf: "center" }}
                    >
                      <InputText
                        id="lineId"
                        type="text"
                        className="input-100"
                        value={this.state.lineId}
                        placeholder="Line ID"
                        tooltip="กรอก ID line"
                        maxlength={45}
                        onChange={(e) =>
                          this.setState({ lineId: e.target.value })
                        }
                      />
                    </div>
                    <label
                      className="p-col-12 p-md-6"
                      style={{ textAlign: "left", alignSelf: "center" }}
                    >
                      Email
                    </label>
                    <div
                      className="p-col-12 p-md-6"
                      style={{ textAlign: "left", alignSelf: "center" }}
                    >
                      <CEInputText
                        id="email"
                        type="email"
                        className="input-100"
                        value={this.state.email}
                        name="email"
                        placeholder="email"
                        tooltip="กรอก email"
                        maxlength={45}
                        required={false}
                        onChange={(e) =>
                          this.setState({ email: e.target.value })
                        }
                      />
                    </div>
                    <label
                      className="p-col-12 p-md-6 required"
                      style={{ textAlign: "left", alignSelf: "center" }}
                    >
                      Mobile
                    </label>
                    <div
                      className="p-col-12 p-md-6"
                      style={{ textAlign: "left", alignSelf: "center" }}
                    >
                      <CEInputText
                        id="genPhone"
                        type="telephone"
                        className="input-100"
                        value={this.state.genPhone}
                        name="genPhone"
                        placeholder="Mobile Number"
                        tooltip="กรอก หมายเลข 10 หลัก"
                        mask="9999999999"
                        required={true}
                        onChange={(e) =>
                          this.setState({ genPhone: e.target.value })
                        }
                        isvalid={this.onValidation}
                      />
                    </div>
                    <div className="p-col-12">
                      <label>
                        พิกัดสถานที่รับขยะฯ (latitude:{" "}
                        {this.state.mapGenMarker.lat} longitude:
                        {this.state.mapGenMarker.lng})
                      </label>
                    </div>
                    <div className="p-col-12">
                      <MapWithASearchBox
                        zoom={this.state.mapGenZoom}
                        marker={this.state.mapGenMarker}
                        onMarkerMoveEnd={this.onMarkerMoved}
                        onCenterChanged={this.onCenterChanged}
                        center={this.state.mapGenCenter}
                      />
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>

            <div className="card card-w-title">
              <Accordion multiple={true} activeIndex={[0, 1]}>
                <AccordionTab header="รายละเอียด Orders">
                  <div className="p-grid">
                    <div
                      className="p-col-12 p-md-4 required"
                      style={{ textAlign: "left", alignSelf: "center" }}
                    >
                      <label>ช่วงวันที่นัดรับสินค้า :</label>
                    </div>
                    <div
                      className="p-col-12 p-md-4 "
                      style={{ textAlign: "left", alignSelf: "center" }}
                    >
                      <CECalendar
                        dateFormat="dd/mm/yy"
                        hourFormat="24"
                        showIcon={true}
                        showTime={true}
                        value={this.state.pickDateStart}
                        onChange={this.onPickDateStartChange}
                        required={true}
                        isvalid={(valid) => console.log("calendar:" + valid)}
                        minDate={minDate}
                      />
                    </div>
                    <div
                      className="p-col-12 p-md-4 "
                      style={{ textAlign: "left", alignSelf: "center" }}
                    >
                      <CECalendar
                        dateFormat="dd/mm/yy"
                        hourFormat="24"
                        showIcon={true}
                        showTime={true}
                        value={this.state.pickDateEnd}
                        onChange={this.onPickDateEndChange}
                        required={true}
                        isvalid={(valid) => console.log("calendar:" + valid)}
                        minDate={minDate}
                      />
                    </div>
                    {/* <div className="p-col-12 p-md-8 " style={{ textAlign: 'left', alignSelf: 'center' }}>
                                        <CECalendar 
                                            dateFormat="dd/mm/yy" hourFormat="24" showIcon={true} showTime={false}
                                            value={this.state.pickDate} 
                                            onChange={this.onPickupDateChange} required={true} 
                                            isvalid={(valid) => console.log("calendar:"+ valid)}
                                            minDate={minDate} selectionMode="range" placeholder='เช่น 01/01/2020 - 02/01/2020'
                                            />
                                    </div> */}
                    <div
                      className="p-col-12 p-md-4 required"
                      style={{ textAlign: "left", alignSelf: "center" }}
                    >
                      <label>วันที่นัดส่งสินค้า :</label>
                    </div>
                    <div
                      className="p-col-12 p-md-8 "
                      style={{ textAlign: "left", alignSelf: "center" }}
                    >
                      <CECalendar
                        dateFormat="dd/mm/yy"
                        hourFormat="24"
                        showIcon={true}
                        showTime={false}
                        value={this.state.deliveryDate}
                        onChange={this.onDeliveryDateChange}
                        required={true}
                        isvalid={(valid) => console.log("calendar:" + valid)}
                        minDate={minDate}
                        placeholder="วันที่ส่งสินค้า"
                      />
                    </div>
                    <label className="p-col-12 ">ค่าขนส่ง (บาท)</label>
                    <div className="p-col-12 ">
                      <CEInputText
                        id="deliveryCost"
                        type="money"
                        className="input-100"
                        value={this.state.deliveryCost}
                        name="deliveryCost"
                        placeholder="00.00"
                        tooltip="กรอกค่าขนส่ง"
                        required={false}
                        onChange={(e) =>
                          this.setState({ deliveryCost: e.target.value })
                        }
                      />
                    </div>
                    <label className="p-col-12 ">รูปถ่ายขยะรีไซเคิล</label>
                    <div className="p-col-12">
                      <CEFileUpload
                        name="wastes[]"
                        value={this.state.fileImgWaste}
                        onSelect={(e) => this.onFileWasteChanged(e)}
                        onDelete={(index) => this.onFileDelete(index)}
                        mode="basic"
                        chooseLabel="แนบไฟล์"
                        cancelLabel="ยกเลิก"
                        multiple={true}
                        accept="image/*"
                        maxFileSize={10000000}
                      />
                    </div>
                  </div>
                </AccordionTab>

                <AccordionTab header="รายละเอียดสินค้า">
                  <div className="p-grid">
                    <label className="p-col-12 ">ประเภทขยะรีไซเคิล :</label>
                    <div className="p-col-12">
                      <Dropdown
                        style={{ width: "100%" }}
                        value={this.state.wasteTypeSelected}
                        options={productType}
                        onChange={this.onWasteTypeChange}
                        placeholder="เลือกประเภทขยะรีไซเคิล"
                        optionLabel="product_type_name"
                        dataKey="product_type_code"
                      />
                    </div>

                    <div className="p-col-12">
                      <label className="p-col-12 ">สินค้า :</label>
                      <Dropdown
                        style={{ width: "100%" }}
                        value={this.state.productSelected}
                        options={product}
                        onChange={this.onproductsChange}
                        placeholder="เลือกสินค้า"
                        optionLabel="product_name"
                        dataKey="product_code"
                      />
                    </div>

                    <label className="p-col-12 p-md-6 p-lg-6">
                      จำนวน (กิโลกรัม)
                    </label>
                    <div className="p-col-12 p-md-6 p-lg-6">
                      <InputText
                        value={this.state.amountSelected}
                        keyfilter="money"
                        placeholder="จำนวน"
                        type="text"
                        className="input-100"
                        onChange={(e) =>
                          this.setState({ amountSelected: e.target.value })
                        }
                      />
                    </div>
                    <label className="p-col-12 p-md-6 p-lg-6">
                      ราคาประเมิน/หน่วย
                    </label>
                    <div className="p-col-12 p-md-6 p-lg-6">
                      <InputText
                        value={this.state.appraisedPriceSelected}
                        keyfilter="money"
                        placeholder="00.00"
                        type="text"
                        className="input-100"
                        onChange={(e) =>
                          this.setState({
                            appraisedPriceSelected: e.target.value,
                          })
                        }
                      />
                    </div>
                    <label className="p-col-12 p-md-6 p-lg-6">
                      ราคาขาย/หน่วย
                    </label>
                    <div className="p-col-12 p-md-6 p-lg-6">
                      <InputText
                        value={this.state.salePriceSelected}
                        keyfilter="money"
                        placeholder="00.00"
                        type="text"
                        className="input-100"
                        onChange={(e) =>
                          this.setState({ salePriceSelected: e.target.value })
                        }
                      />
                    </div>

                    <div className="p-col-12 ">
                      <Button
                        style={{ float: "left" }}
                        label="เพิ่มรายการ"
                        icon="fa fa-plus"
                        iconPos="right"
                        onClick={this.addDataToTable}
                      />
                    </div>

                    <div className="p-col-12">
                      <Panel
                        style={{ textAlign: "center" }}
                        header="รายการสินค้า"
                      >
                        <DataTable
                          value={this.state.OrderList}
                          paginatorPosition="bottom"
                          paginator={true}
                          rows={5}
                          rowsPerPageOptions={[5, 10, 15]}
                          responsive={true}
                          emptyMessage={
                            <div style={{ textAlign: "center", color: "red" }}>
                              <label>
                                *** กรุณาเพิ่มสินค้าอย่างน้อย 1 รายการ ***
                              </label>
                            </div>
                          }
                        >
                          <Column
                            field="product_name"
                            header="รายการ"
                            sortable={true}
                          />
                          <Column
                            field="quantity_kk"
                            header="จำนวน (Kg)"
                            sortable={true}
                          />
                          <Column
                            field="estimate_price_per_unit"
                            header="ราคาประเมิน/หน่วย	"
                            sortable={true}
                          />
                          <Column
                            field="price_per_unit"
                            header="ราคาขาย/หน่วย"
                            sortable={true}
                          />

                          <Column
                            field="product_code"
                            body={this.actionTemplate.bind(this)}
                            header="ลบ"
                            style={{ width: "10%" }}
                          />
                        </DataTable>
                      </Panel>
                    </div>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          </div>

          <div className="p-col-12 pg-md-12 p-lg-6">
            <div className="card card-w-title">
              <Accordion multiple={true} activeIndex={[0, 1]}>
                <AccordionTab header="ค้นหา ศูนย์จัดการฯซีซาเล้ง">
                  <div className="p-grid">
                    <DataTable
                      value={this.state.wasteproDataList}
                      header={proHeader}
                      globalFilter={this.state.globalFilterPro}
                      //paginatorPosition="bottom" paginator={true} rows={5} rowsPerPageOptions={[5, 10, 15]}
                      scrollable={true}
                      scrollHeight="150px"
                      selectionMode="single"
                      responsive={true}
                      selection={this.state.dataTableSelection3}
                      onSelectionChange={(e) => this.onTableProSelected(e)}
                    >
                      <Column field="pro_name" header="ชื่อ-สกุล" />
                      <Column field="address_detail" header="ที่อยู่" />
                      <Column
                        body={this.distanceTemplate}
                        header="ระยะทาง (กม.)"
                        style={{ width: "8em" }}
                      />
                    </DataTable>
                    <div className="p-col-12">
                      <MapMarkers
                        marker={this.state.mapGenMarker}
                        onMarkerClicked={this.onMarkerProClicked}
                        onBoundsChanged={this.mapChanged}
                        markers={this.state.mapProMarkers}
                        center={this.state.mapProCenter}
                        zoom={this.state.mapProZoom}
                      />
                    </div>
                  </div>
                </AccordionTab>

                <AccordionTab header="ค้นหา ทีมขนส่งซีซาเล้ง">
                  <div className="p-grid">
                    <div className="p-col-12">
                      <DataTable
                        value={this.state.wastetranDataList}
                        header={tranHeader}
                        globalFilter={this.state.globalFilterTran}
                        scrollable={true}
                        scrollHeight="150px"
                        responsive={true}
                        selectionMode="single"
                        selection={this.state.dataTableSelection2}
                        onSelectionChange={(e) => this.onTableTranSelected(e)}
                      >
                        <Column field="tran_name" header="ชื่อ-สกุล" />
                        <Column field="address_detail" header="ที่อยู่" />
                        <Column
                          body={this.distanceTemplate}
                          header="ระยะทาง (กม.)"
                          style={{ width: "8em" }}
                        />
                      </DataTable>
                    </div>
                    {/* <div className="p-col-12">
                                    <Button
                                        style={{ float: "left" }}
                                        label="ค้นหาทีมขนส่งซีซาเล้ง"
                                        icon="fa fa-search"
                                        iconPos="left"/>
                                </div> */}
                  </div>
                  <div className="p-col-12">
                    <MapMarkers
                      marker={this.state.mapTranMarker}
                      onMarkerClicked={this.onMarkerClicked}
                      onBoundsChanged={this.mapChanged}
                      markers={this.state.mapTranMarkers}
                      center={this.state.mapTranCenter}
                      zoom={this.state.mapTranZoom}
                    />
                  </div>
                </AccordionTab>
              </Accordion>

              {matchingTab}
            </div>
          </div>

          <div className="p-col-12">
            <Button
              style={{ marginRight: "20px" }}
              type="submit"
              label="บันทึกรายการ Order"
              icon="fa fa-save"
              iconPos="left"
              onClick={this.onClickSaveOrder}
            />
            <Button
              style={{ backgroundColor: "#f0ad4e" }}
              type="submit"
              label="ยกเลิก"
              onClick={this.clearAllDataInput}
            />
          </div>
        </div>
      );
  }

  showProgressSpinner() {
    const Progress = (
      <div className="p-col-12 p-md-8 p-lg-9">
        <ProgressSpinner
          style={{ width: "80px", height: "80px" }}
          strokeWidth="8"
          fill="#EEEEEE"
          animationDuration="0.5s"
        />
      </div>
    );

    if (this.state.showProgress) {
      return Progress;
    } else return <div />;
  }

  render() {
    const searchPanel = this.showSearchPanel();
    const createDetail = this.showCreateDetail();
    const showProgress = this.showProgressSpinner();
    console.log(this.state.pickDateStart);
    console.log(this.state.pickDateEnd);

    return (
      <div className="p-grid ">
        <Growl ref={(el) => (this.growl = el)} />
        {searchPanel}
        {showProgress}
        {createDetail}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dhbUsers: state.dhbAdmin.dhbUsers,
  locationMaster: state.location,
  provinces: state.location.province,
  districts: state.location.district,
  subDistricts: state.location.subDistrict,
  cardProvince: state.location.cardProvince,
  cardDistrict: state.location.cardDistrict,
  cardSubDistrict: state.location.cardSubDistrict,
  authentication: state.authentication,
  userInfo: state.userInfo.data,
  userInfoMaster: state.userInfo,
  updateWasteproProfile: state.updateWasteproProfile,
  banks: state.master.banks,
  productType: state.master.productType,
  product: state.master.product,
  getFileWastepro: state.getFileWastepro,
});

const mapDispatchToProps = (dispatch) => ({
  dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
  locationServiceProps: bindActionCreators(LocationService, dispatch),
  userServiceProps: bindActionCreators(UserService, dispatch),
  masterServiceProps: bindActionCreators(MasterService, dispatch),
  fileServiceProps: bindActionCreators(FileService, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(CreateOrderContainer);
