import React, { Component } from 'react';
import Avatar from 'react-avatar-edit'



export default class ExampleAvatar extends Component {
    constructor(props) {
        super(props)
        const src = 'assets/avatar/user.png'
        this.state = {
          preview: null,
          src
        }
        this.onCropAvatar = this.onCropAvatar.bind(this)
        this.onCropAvatar = this.onCropAvatar.bind(this)
      }
      
      onCloseAvartar = () => {
        this.setState({preview: null})
      }
      
      onCropAvatar = (preview) => {
        this.setState({preview})
      }
      
      render () {
          console.log(this.state.preview)

        const renderAvatar = (
            <img className="img-cover" src={this.state.preview}    />
        )
        
        return (
          <div>
            <Avatar 
              width={390}
              height={295}
              onCrop={this.onCropAvatar}
              onClose={this.onCloseAvartar}
              src={this.state.src}
            />
            {renderAvatar}
          </div>
        )
      }
}
