import {
    WASTEPRO_ORDER_SEARCH,
    WASTEPRO_DASH_PRODUCT,
    WASTEPRO_DASH_PAYMENT
    , WASTEPRO_DASH_SUMMARY_OVERDUE
    , WASTEPRO_DASH_SUMMARY_PAID
    , WASTEPRO_DASH_SUMMARY_PRODUCTS,
    WASTEPRO_DASH_ORDER_STAT
} from '../service/actionType'

const initialState = {
    data: null,
    isFailed: false,
    isLoading: false
}

export function wasteproOrderSearch(state = initialState, action) {
    switch (action.type) {
        case WASTEPRO_ORDER_SEARCH.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTEPRO_ORDER_SEARCH.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTEPRO_ORDER_SEARCH.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function getWasteproDashProduct(state = initialState, action) {
    switch (action.type) {
        case WASTEPRO_DASH_PRODUCT.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTEPRO_DASH_PRODUCT.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTEPRO_DASH_PRODUCT.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}
export function getWasteproDashPayment(state = initialState, action) {
    switch (action.type) {
        case WASTEPRO_DASH_PAYMENT.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTEPRO_DASH_PAYMENT.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTEPRO_DASH_PAYMENT.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}


const initialStateSummary = {
    dataOverdue: null,
    dataPaid: null,
    dataProducts: null,
    isFailed: false,
    isLoading: false
}
export function getWasteproDashSummary(state = initialStateSummary, action) {
    switch (action.type) {
        case WASTEPRO_DASH_SUMMARY_OVERDUE.REQUEST:
            return { ...state, isFailed: false, dataOverdue: null, isLoading: true };
        case WASTEPRO_DASH_SUMMARY_OVERDUE.SUCCESS:
            return { ...state, isFailed: false, dataOverdue: action.payload, isLoading: false };
        case WASTEPRO_DASH_SUMMARY_OVERDUE.FAILURE:
            return { ...state, isFailed: true, dataOverdue: action.payload, isLoading: false };

        case WASTEPRO_DASH_SUMMARY_PAID.REQUEST:
            return { ...state, isFailed: false, dataPaid: null, isLoading: true };
        case WASTEPRO_DASH_SUMMARY_PAID.SUCCESS:
            return { ...state, isFailed: false, dataPaid: action.payload, isLoading: false };
        case WASTEPRO_DASH_SUMMARY_PAID.FAILURE:
            return { ...state, isFailed: true, dataPaid: action.payload, isLoading: false };

        case WASTEPRO_DASH_SUMMARY_PRODUCTS.REQUEST:
            return { ...state, isFailed: false, dataProducts: null, isLoading: true };
        case WASTEPRO_DASH_SUMMARY_PRODUCTS.SUCCESS:
            return { ...state, isFailed: false, dataProducts: action.payload, isLoading: false };
        case WASTEPRO_DASH_SUMMARY_PRODUCTS.FAILURE:
            return { ...state, isFailed: true, dataProducts: action.payload, isLoading: false };
        default:
            return state
    }
}

export function getWasteproDashOrderStat(state = initialState, action) {
    switch (action.type) {
        case WASTEPRO_DASH_ORDER_STAT.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTEPRO_DASH_ORDER_STAT.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTEPRO_DASH_ORDER_STAT.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}
