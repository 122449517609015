

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';

import { getProvice } from '../../service/LocationService';
import ChangePassword from '../change-password/ChangePassword';

import * as Validators from '../../utils/validators'
import * as SecureUtils from '../../utils/secureUtils';

class ExampleChangePasswordCtrl extends Component {

    constructor(props) {
        console.log("ExampleChangePasswordCtrl :constructor")
        super(props);
        this.state = {
            //VALIDATE
            isValidateError: false,
            classNameRequired: 'p-col-12',
            //PASSWORD
            isShowChangePassword: false,
            isChangePassword: false,
            checkedPasswordVisible: false,
            typePasswordInput: 'password',
            errorMessageOldPassword: null,
            errorMessagePassword: null,
            errorMessageConfirmPassword: null,
            oldPassword: null,
            password: null,
            confirmPassword: null,
            disableNewPass:true
        }
    }

    componentDidMount() {
        console.log("ExampleChangePasswordCtrl :componentDidMount")
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("ExampleChangePasswordCtrl :componentWillReceiveProps")

    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("ExampleChangePasswordCtrl :componentWillReceiveProps")

    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("ExampleChangePasswordCtrl :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("ExampleChangePasswordCtrl :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("ExampleChangePasswordCtrl :componentDidUpdate")

    }

    //> Submit
    onSubmit = () => {
        this.showSuccess();
    }

    //> Validate
    validateRequire = () => {
        console.log("validateRequire ", this.state)
        this._classNameRequired(false)
        
        this._validateLogic(this.state.telephone_no1)
        if (this.state.person_type == '1') {
            this._validateLogic(this.state.person_prefix_code)
            this._validateLogic(this.state.person_first_name)
            this._validateLogic(this.state.person_last_name)
        } else {
            this._validateLogic(this.state.org_type)
            this._validateLogic(this.state.org_name)
        }

        //Password
        if (this.state.isChangePassword == true) {
            this._validateLogic(this.state.oldPassword);
            this._validateLogic(this.state.password);
            this._validateLogic(this.state.confirmPassword);
        }
    }
    _validateLogic = (value) => {
        if (Validators.isEmpty(value)) {
            this._classNameRequired(true)
        }
    }
    _classNameRequired = (status) => {
        const classNameRequired = status == true ? "p-error p-col-12" : "p-col-12";
        this.setState({ classNameRequired: classNameRequired })
        this.setState({ isValidateError: status })
    }

    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }

    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'สถานะ', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }


    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    //> Event
    onChangeOldPassword = (e) => {
        let password = e.target.value;
        this.setState({ errorMessageOldPassword: null });
        if (Validators.isNotEmpty(password)) {
                this.setState({ errorMessageOldPassword: "" });
                this.setState({ errorMessagePassword: "กรุณาระบุรหัสผ่าน" });
                this.setState({ errorMessageConfirmPassword: "กรุณาระบุรหัสผ่าน" });
                this.setState({ isChangePassword: true })
                this.setState({ oldPassword: password })
                this.setState({disableNewPass:false});
        } else {
            this.setState({ isChangePassword: false })
            this._classNameRequired(false);
            this.setState({disableNewPass:true});
            
        }
    }

    onChangeNewPassword = (e) => {
        let password = e.target.value;
        this.setState({ errorMessagePassword: null });

        if (password.length >= 6) {
            const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
            const isOk = re.test(password);
            if (!isOk) {
                this.setState({ errorMessagePassword: "ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่" });
                this._classNameRequired(true)
            } else {
                this.setState({ password: password });
                this.setState({ errorMessagePassword: "" });
                if (password == this.state.confirmPassword) {
                    this.setState({ errorMessageConfirmPassword: "" });
                    this._classNameRequired(false)
                } else {
                    this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
                    this._classNameRequired(true)
                }
            }
        } else {
            this.setState({ errorMessagePassword: "กรุณากรอกอย่างน้อย 6 ตัวอักษร (ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่)" });
            this._classNameRequired(true)
        }
    }

    onChangeConfirmPassword = (e) => {
        let cfpassword = e.target.value;
        this.setState({ errorMessageConfirmPassword: "" });
        console.log("isChangePassword ", this.state.isChangePassword, "pass ", this.state.password, " cfpass ", cfpassword)
        if (this.state.password == cfpassword) {
            this.setState({ confirmPassword: cfpassword });
            this.setState({ errorMessageConfirmPassword: "" });
            this.setState({ isChangePassword: false })
            this._classNameRequired(false)
        } else {
            this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
            this.setState({ confirmPassword: cfpassword });

            this._classNameRequired(true)
        }
    }

    onChangePasswordVisible = (e) => {
        this.setState({ checkedPasswordVisible: e.value })
        if (e.value) {
            this.setState({ typePasswordInput: 'text' });
        } else {
            this.setState({ typePasswordInput: 'password' });
        }
    }


    render() {
        console.log("ExampleChangePasswordCtrl :render")


        return (
            <div>
                <Growl ref={(el) => this.growl = el} />
                <h1>Profile</h1>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-6">

                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>Change password</h1>
                                {

                                    <ChangePassword
                                        {...this.state}
                                        onChangeOldPassword={this.onChangeOldPassword}
                                        onChangeNewPassword={this.onChangeNewPassword}
                                        onChangeConfirmPassword={this.onChangeConfirmPassword}
                                        onChangePasswordVisible={this.onChangePasswordVisible}
                                    />

                                }
                            </div>
                        </div>

                    </div>

                    <div className="p-col-12">
                        <Button
                            className="p-col-12"
                            label="บันทึก"
                            icon="pi pi-check"
                            iconPos="left"
                            onClick={this.onSubmit} />
                    </div>

                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    userInfo: state.userInfo
})

export default connect(mapStateToProps)(ExampleChangePasswordCtrl)
