import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Checkbox } from 'primereact/checkbox';
import { Growl } from 'primereact/growl';
import { Chips } from 'primereact/chips';
import { ToggleButton } from 'primereact/togglebutton';
import { Panel } from 'primereact/panel';
import { FileView } from '../../features/widget/FileView';
import { API_ROOT } from '../../constants/api'

import * as Validators from '../../utils/validators'
import { InputSwitch } from 'primereact/inputswitch';
import { SecureUtils } from '../../utils';



export class WastetranProfile extends Component {

    constructor() {
        super();
        this.state = {
            isRendered: true,
            isImgIdCard: true,
            isImgRegis: true,
            isImgPerson: true,
            isImgVehicle: true,
            isLoadChip: true,
            isLoading: true,
            isUpdate: false,
            isLoadDist: false,
            isLoadSubDist: false,
            checkedSameAddress: false,
            selectedProv: null,
            companyTypes: [
                { name: 'บริษัทจำกัด', code: 1 },
                { name: 'ห้างหุ้นส่วน', code: 2 },
                { name: 'บริษัทมหาชน', code: 3 },
                { name: 'ห้างร้าน', code: 4 },
            ],
            selectedCompanyType: null,
            locationRecycle: [
                { inputId: 1, value: "บ้านในโครงการจัดสรร" },
                { inputId: 2, value: "คอนโดมีเนียม / อพาร์ทเม้นท์" },
                { inputId: 3, value: "ร้านค้า / ร้านอาหาร" },
                { inputId: 4, value: "อื่นๆ" },
                { inputId: 5, value: "บ้านพักอาศัยทั่วไป" },
                { inputId: 6, value: "อาคารสำนักงาน / โรงงาน" },
                { inputId: 7, value: "สถานศึกษา / สถานที่ราชการ" }
            ],
            dummyDropdownBank: [
                { name: 'กสิกรไทย', code: '1' },
                { name: 'นครหลวงไทย', code: '2' },
                { name: 'กรุงเทพ', code: '3' },
                { name: 'ไทยพาณิทย์', code: '4' },
            ],
            selectedBank: null,
            personPrefixs: [
                { name: 'นาย', code: 1 },
                { name: 'นาง', code: 2 },
                { name: 'นางสาว', code: 3 },
            ],
            selectedPersonPrefix: null,
            dummyTypeCars: [
                { inputId: 1, value: "มอเตอร์ไซค์" },
                { inputId: 2, value: "ซาเล้ง" },
                { inputId: 3, value: "รถกระบะและบรรทุก 4 ล้อ" },
                { inputId: 4, value: "รถบรรทุก 6 ล้อ" },
                { inputId: 5, value: "รถบรรทุก 10 ล้อ" },
                { inputId: 6, value: "รถพ่วง" },
            ],
            selectedTypeCar: null,

            isUseCardAdress: null,
            useCardAddress: false,

            provinceChipSelected: null, chipDistrict: [],

            selectedCurrentProv: null, selectedCurrentDistrict: null, selectedCurrentSubDistrict: null,

            selectedCardProv: null, selectedCardDistrict: null, selectedCardSubDistrict: null,

            acceptFileExt: '.jpg,.jpeg,.png,.pdf,.JPG,.JPEG,.PNG,.PDF',
            acceptPic: '.jpg,.jpeg,.png,.JPG,.JPEG,.PNG',

            imgIdCard: null,
            imgRegis: null,
            imgPerson: null,
            imgVehicle: null,
            //PASSWORD
            isShowChangePassword: false,
            isChangePassword: false,
            checkedPasswordVisible: false,
            typePasswordInput: 'password',
            errorMessageOldPassword: null,
            errorMessagePassword: null,
            errorMessageConfirmPassword: null,
            oldPassword: null,
            password: null,
            confirmPassword: null,

        }
        //fileImgIden: null, fileImgRegis: null, fileImgPerson: null,fileImgVehicle:null
        this.onFileVehicleChanged = this.onFileVehicleChanged.bind(this);
        this.onFileIDCardChanged = this.onFileIDCardChanged.bind(this);
        this.onFileRegisChanged = this.onFileRegisChanged.bind(this);
        this.onFilePersonChanged = this.onFilePersonChanged.bind(this);
    }

    componentDidMount() {
        const { userServiceProps } = this.props
        userServiceProps.getUserInfo();
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("WastetranProfile :componentWillReceiveProps")
        if (
            this.state.isLoading
            && !nextProps.userInfoMaster.isLoading
            && !nextProps.updateWastetranProfile.isLoading
            && !nextProps.getFileWastetran.isLoading
        ) {
            if (nextProps.userInfo) {
                if (this.state.isUpdate) {
                    if (nextProps.updateWastetranProfile.data) {
                        this.initial(nextProps.updateWastetranProfile.data)
                        this.setState({ isUpdate: false })
                        if (nextProps.updateWastetranProfile.isFailed) {
                            this.showError("บันทึกข้อมูลผิดพลาด");
                            this.setState({ isLoading: false })
                        } else {
                            this.prepareWasteTypes(nextProps.updateWastetranProfile.data);
                            this.setUid(nextProps.updateWastetranProfile.data);
                            this.setAddrCardUseCurrent(nextProps.updateWastetranProfile.data);
                            this.showSuccess("บันทึกข้อมูลสำเร็จ");
                            this.requrestImage(nextProps)
                        }
                    }
                } else {
                    this.initial(nextProps.userInfo)
                    this.prepareWasteTypes(nextProps.userInfo);
                    this.setUid(nextProps.userInfo);
                    this.setAddrCardUseCurrent(nextProps.userInfo);
                    this.requrestImage(nextProps)
                }
            }
        }
        this.validateRequire();

    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("WastetranProfile :shouldComponentUpdate")
        return true;
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("WastetranProfile :componentWillUpdate")
        this.renderImage(nextProps);
    }

    componentDidUpdate(prevProps, prevState) {
        console.log("WastetranProfile :componentDidUpdate")

        this.initialSelected();
    }

    // === Custom === 
    customChip = (item) => {
        //console.log("item chip ", item)
        return (
            <div>
                <span>อำเภอ/เขต{item.districtName} จังหวัด{item.provinceName} </span>
                <i className="pi pi-user-plus" style={{ fontSize: '14px' }}></i>
            </div>
        );
    }

    customChipRemove = (e) => {
        const { chipDistrict } = this.state

        chipDistrict.map((valueChip, index) => {
            if (valueChip == e.value[0]) {
                chipDistrict.splice(index, 1);
            }
        })
    }

    // ========= PREPARE =========
    initial = (object) => {
        if (object) {
            for (let key in object) {
                let val = object[key]
                this.setState({ [key]: val })
            }
            this.setState({ isLoading: false })
        }
    }

    initialSelected = () => {
        const {
            //> Service Location
            locationServiceProps,

            //> Current Location
            province,
            district,
            subDistrict,

            //> Card Location
            cardProvince,
            cardDistrict,
            cardSubDistrict,

            locationMaster,
            //> Chip
            chipProvince,
            chipDistrict,

            banks,

            getFileWastetran,
            fileServiceProps

        } = this.props

        //person_prefix_code default :: check dropdown
        if (!this.state.selectedPersonPrefix && this.state.person_prefix_code) {
            for (let key in this.state.personPrefixs) {
                let val = this.state.personPrefixs[key]
                if (this.state.person_prefix_code == val.code) {
                    this.setState({ selectedPersonPrefix: val })
                }
            }
        }
        //person_prefix_code default :: check dropdown
        if (!this.state.selectedCompanyType && this.state.org_type) {
            for (let key in this.state.companyTypes) {
                let val = this.state.companyTypes[key]
                if (this.state.org_type == val.code) {
                    this.setState({ selectedCompanyType: val })
                }
            }
        }
        //bank_code default :: check dropdown
        if (!this.state.selectedBank && this.state.bank_code) {
            for (let key in banks) {
                let val = banks[key]
                if (this.state.bank_code == val.bank_code) {
                    this.setState({ selectedBank: val })

                }
            }
        }
        //Current province default :: check dropdown
        if (!this.state.selectedCurrentProv && this.state.addr_current_province_code) {
            for (let key in province) {
                let val = province[key]
                if (this.state.addr_current_province_code == val.provinceCode) {
                    this.setState({ selectedCurrentProv: val })
                    locationServiceProps.getDistrict(val.provinceCode)
                }
            }
        }
        //Current District default :: check dropdown
        if (!this.state.selectedCurrentDistrict && this.state.addr_current_district_code) {
            for (let key in district) {
                let val = district[key]
                if (this.state.addr_current_district_code == val.districtCode) {
                    this.setState({ selectedCurrentDistrict: val })
                    locationServiceProps.getSubDistrict(val.districtCode)
                }
            }
        }
        //Current Sub District default :: check dropdown
        if (!this.state.selectedCurrentSubDistrict && this.state.addr_current_subdistrict_code) {
            for (let key in subDistrict) {
                let val = subDistrict[key]
                if (this.state.addr_current_subdistrict_code == val.subDistrictCode) {
                    this.setState({ selectedCurrentSubDistrict: val })
                }
            }
        }

        //province default :: check dropdown
        if (!this.state.selectedCardProv && this.state.addr_card_province_code) {
            for (let key in cardProvince) {
                let val = cardProvince[key]
                if (this.state.addr_card_province_code == val.provinceCode) {
                    this.setState({ selectedCardProv: val })
                    locationServiceProps.getCardDistrict(val.provinceCode)
                }
            }
        }
        //District default :: check dropdown
        if (!this.state.selectedCardDistrict && this.state.addr_card_district_code) {
            for (let key in cardDistrict) {
                let val = cardDistrict[key]
                if (this.state.addr_card_district_code == val.districtCode) {
                    this.setState({ selectedCardDistrict: val })
                    locationServiceProps.getCardSubDistrict(val.districtCode)
                }
            }
        }
        //District default :: check dropdown
        if (!this.state.selectedCardSubDistrict && this.state.addr_card_subdistrict_code) {
            for (let key in cardSubDistrict) {
                let val = cardSubDistrict[key]
                //console.log("this.state.addr_card_subdistrict_code ", this.state.addr_card_subdistrict_code, " ", val)
                if (this.state.addr_card_subdistrict_code == val.subDistrictCode) {
                    this.setState({ selectedCardSubDistrict: val })
                }
            }
        }

        //#chip  default :: check dropdown
        if (!this.state.provinceChipSelected
            && Validators.isNotEmpty(this.state.areaList)
        ) {

            if (this.state.areaList.length > 0) {
                let chkPov = false;
                for (let key in this.state.areaList) {
                    const valArea = this.state.areaList[key]
                    //>province
                    for (let keyProv in province) {
                        let provVal = province[keyProv]
                        if (valArea.provinceCode == provVal.provinceCode) {
                            if (!chkPov) {
                                chkPov = true;
                                locationServiceProps.getChipDistrict(valArea.provinceCode)
                                this.setState({ provinceChipSelected: provVal })
                            }
                            break;
                        }
                    }
                }

            }
        }

        //#chip District default :: check dropdown
        if (this.state.chipDistrict.length == 0 && this.state.isLoadChip) {

            if (!locationMaster.isFetching) {
                if (this.state.areaList) {
                    if (this.state.areaList.length > 0) {
                        for (let key in this.state.areaList) {
                            const valArea = this.state.areaList[key]
                            for (let keyDist in chipDistrict) {
                                let valDist = chipDistrict[keyDist]
                                if (valArea.districtCode == valDist.districtCode) {
                                    this.state.chipDistrict.push(valDist)
                                    this.setState({ isLoadChip: false })
                                    break;
                                }
                            }
                        }
                    }
                    if (this.state.areaList.length == 0) {
                        this.setState({ isLoadChip: false })
                    }
                }

            }
        }

        //>duplicate Address duplicate current check
        if (!this.state.isUseCardAdress && this.state.addr_card_use_current) {
            // useCardAddress
            if (this.state.addr_card_use_current == 1) {
                this.state.useCardAddress = true;
                this.state.isUseCardAdress = true;
            }
        }


    }

    requrestImage = (nextProps) => {
        //> Init img
        // imgIdCard:{},
        if (this.state.isImgIdCard && this.state.img_iden_name) {
            if (!nextProps.getFileWastetran.isLoading) {
                nextProps.fileServiceProps.getFileWastetranIden(this.state.img_iden_name)
            }
        }
        // imgRegis:{},
        if (this.state.isImgRegis && this.state.img_regis_org_name) {
            if (!nextProps.getFileWastetran.isLoading) {
                nextProps.fileServiceProps.getFileWastetranRegis(this.state.img_regis_org_name)
            }
        }
        // imgPerson:{},
        if (this.state.isImgPerson && this.state.img_person_name) {
            if (!nextProps.getFileWastetran.isLoading) {
                nextProps.fileServiceProps.getFileWastetranPerson(this.state.img_person_name)
            }
        }
        // imgVehicle:{}
        if (this.state.isImgVehicle && this.state.vehicle_img_name) {
            if (!nextProps.getFileWastetran.isLoading) {
                nextProps.fileServiceProps.getFileWastetranVehicle(this.state.vehicle_img_name)
            }
        }
    }

    renderImage = (nextProps) => {

        //> Init img
        // imgIdCard:{},
        if (this.state.isImgIdCard && this.state.img_iden_name) {
            if (!nextProps.getFileWastetran.isLoading) {
                if (!nextProps.getFileWastetran.isLoading && this.state.isImgIdCard) {

                    this.setState({ fileImgIden: nextProps.getFileWastetran.fileImgIden })
                    this.setState({ isImgIdCard: false })
                }
            }
        }
        // imgRegis:{},
        if (this.state.isImgRegis && this.state.img_regis_org_name) {
            if (!nextProps.getFileWastetran.isLoading) {
                if (!nextProps.getFileWastetran.isLoading && this.state.isImgRegis) {

                    this.setState({ imgRegis: nextProps.getFileWastetran.fileImgRegis })
                    this.setState({ isImgRegis: false })
                }

            }
        }
        // imgPerson:{},
        if (this.state.isImgPerson && this.state.img_person_name) {
            if (!nextProps.getFileWastetran.isLoading) {
                if (!nextProps.getFileWastetran.isLoading && this.state.isImgPerson) {

                    this.setState({ imgPerson: nextProps.getFileWastetran.fileImgPerson })
                    this.setState({ isImgPerson: false })
                }
            }
        }
        // imgVehicle:{}
        if (this.state.isImgVehicle && this.state.vehicle_img_name) {
            if (!nextProps.getFileWastetran.isLoading) {
                if (!nextProps.getFileWastetran.isLoading && this.state.isImgVehicle) {

                    this.setState({ imgVehicle: nextProps.getFileWastetran.fileImgVehicle })
                    this.setState({ isImgVehicle: false })
                }
            }
        }
    }

    prepareWasteTypes(userInfo) {

        if (userInfo && userInfo.wasteTypeList) {
            let showPassCheck = [...this.state.wasteTypesSelected];

            for (let key in userInfo.wasteTypeList) {
                let v = userInfo.wasteTypeList[key] - 1;
                let cc = this.state.wasteTypes;
                showPassCheck.push(this.state.wasteTypes[v]);

            }

            this.setState({ wasteTypesSelected: showPassCheck });

            var intChecked = [];
            for (let index = 0; index < showPassCheck.length; index++) {
                intChecked.push(showPassCheck[index].value);

            }

            this.setState({ wasteTypeListString: JSON.stringify(intChecked) });
            //console.log(JSON.stringify(intChecked));
        }
    }

    setAddrCardUseCurrent(userInfo) {
        if (userInfo && userInfo.addr_card_use_current && userInfo.addr_card_use_current === 1) {
            this.setState({ useCurrentAddress: true })
        }
    }

    setUid(userInfo) {
        if (userInfo && userInfo.user_wasteTypes) {
            for (let key in userInfo.user_wasteTypes) {
                this.setState({ uid: userInfo.user_wasteTypes[key].uid })
            }
        }
    }

    // ========= Event Chip=========
    onProvinceChipChanged = (e) => {
        const { locationServiceProps } = this.props

        this.setState({ provinceChipSelected: e.value })

        locationServiceProps.getChipDistrict(e.value.provinceCode)

    }

    onDistrictChipChanged = (e) => {
        this.setState({ districtSelected: e.value });
        let chk = false;
        this.state.chipDistrict.map((value) => {
            if (value.districtName == e.value.districtName) {
                chk = true
            }
        })
        if (chk == true) {
            this.showError("มีอำเภอซ้ำ กรุณาเลือกใหม่อีกครั้ง");
        } else {
            this.state.chipDistrict.push(e.value);
        }
    }

    // ========= Event =========
    onChangeInputText = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })

        this.validateRequire();
    }

    onChangeProv = (e) => {
        const { locationServiceProps } = this.props
        const { value } = e.target
        this.setState({ selectedProv: value })
        this.setState({ addr_current_province_code: value.provinceCode });
        this.setState({ selectedDistrict: null })
        this.setState({ selectedSubDistrict: null })

        locationServiceProps.getDistrict(value.provinceCode);
    }

    onChangeDistrict = (e) => {

        const { locationServiceProps } = this.props
        const { value } = e.target
        this.setState({ selectedDistrict: value })
        this.setState({ addr_current_province_code: value.provinceCode });
        this.setState({ selectedSubDistrict: null })

        locationServiceProps.getSubDistrict(value.districtCode);
    }

    onChangeSubDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedSubDistrict: value })
        this.setState({ addr_current_province_code: value.provinceCode });
        this.setState({ addr_current_district_code: value.districtCode });
        this.setState({ addr_current_subdistrict_code: value.subDistrictCode });
    }

    onChangePersonPrefix = (e) => {
        const { name, value } = e.target
        this.setState({ selectedPersonPrefix: value })
        this.setState({ [name]: value.code })
    }

    onChangeCompanyType = (e) => {
        const { name, value } = e.target
        this.setState({ selectedCompanyType: value })
        this.setState({ [name]: value.code })
    }

    onChanageBank = (e) => {
        const { name, value } = e.target
        this.setState({ selectedBank: value })
        this.setState({ [name]: value.bank_code })
    }

    onCheckTypeCar = (e) => {
        const { name, value } = e.target
        this.setState({ selectedTypeCar: value })
        this.setState({ [name]: value.code })
    }

    onDuplicate = (e) => {
        const { checked } = e
        this.setState({ useCardAddress: checked })
    }

    //> ========= #Dropdown Manage Location =========
    onChangeCurrentProv = (e) => {
        const { locationServiceProps, locationMaster } = this.props
        const { value } = e.target
        this.setState({ selectedCurrentProv: value })
        this.setState({ addr_current_province_code: value.provinceCode });

        if (!locationMaster.isFetching) {
            locationServiceProps.getDistrict(value.provinceCode);
        }

    }

    onChangeCurrentDistrict = (e) => {

        const { locationServiceProps, locationMaster } = this.props
        const { value } = e.target
        this.setState({ selectedCurrentDistrict: value })
        this.setState({ addr_current_district_code: value.districtCode });

        locationServiceProps.getSubDistrict(value.districtCode);

    }

    onChangeCurrentSubDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedCurrentSubDistrict: value })
        //> Set all
        this.setState({ addr_current_province_code: value.provinceCode });
        this.setState({ addr_current_district_code: value.districtCode });
        this.setState({ addr_current_subdistrict_code: value.subDistrictCode });

    }

    onChangeCardProv = (e) => {
        const { locationServiceProps, locationMaster } = this.props
        const { value } = e.target
        this.setState({ selectedCardProv: value })
        this.setState({ addr_card_province_code: value.provinceCode });

        if (!locationMaster.isFetching) {
            locationServiceProps.getCardDistrict(value.provinceCode);
        }

    }

    onChangeCardDistrict = (e) => {

        const { locationServiceProps, locationMaster } = this.props
        const { value } = e.target
        this.setState({ selectedCardDistrict: value })
        this.setState({ addr_card_district_code: value.districtCode });

        if (!locationMaster.isFetching) {
            locationServiceProps.getCardSubDistrict(value.districtCode);
        }

    }

    onChangeCardSubDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedCardSubDistrict: value })
        //> Set all
        this.setState({ addr_card_province_code: value.provinceCode });
        this.setState({ addr_card_district_code: value.districtCode });
        this.setState({ addr_card_subdistrict_code: value.subDistrictCode });


    }

    // ======== File Event ========
    onFileIDCardChanged = (e) => {
        this.setState({ fileImgIden: e.files[0] });

        // var file = e.target.files[0];
        // var reader = new FileReader();
        // reader.readAsDataURL(file);

        //  reader.onloadend = function (e) {
        //     this.setState({
        //         imgIdCard: [reader.result]
        //     })
        //   }.bind(this);

    }
    onFileRegisChanged = (e) => {
        this.setState({ fileImgRegis: e.files[0] });

        // var file = e.target.files[0];
        // var reader = new FileReader();
        // reader.readAsDataURL(file);

        //  reader.onloadend = function (e) {
        //     this.setState({
        //         imgRegis: [reader.result]
        //     })
        //   }.bind(this);
    }
    onFilePersonChanged = (e) => {
        this.setState({ fileImgPerson: e.files[0] });

        // var file = e.target.files[0];
        // var reader = new FileReader();
        // reader.readAsDataURL(file);

        //  reader.onloadend = function (e) {
        //     this.setState({
        //         imgPerson: [reader.result]
        //     })
        //   }.bind(this);
    }
    onFileVehicleChanged = (e) => {
        this.setState({ fileImgVehicle: e.files[0] });

        // var file = e.target.files[0];
        // var reader = new FileReader();
        // reader.readAsDataURL(file);

        //  reader.onloadend = function (e) {
        //     this.setState({
        //         imgVehicle: [reader.result]
        //     })
        //   }.bind(this);
    }

    // ======== Validate data =========
    validateRequire = () => {
        this.setState({ isValidateError: false })
        this._validateLogic(this.state.telephone_no1)
        //this._validateLogic(this.state.person_idcard)
        if (this.state.person_type == '1') {
            this._validateLogic(this.state.person_prefix_code)
            this._validateLogic(this.state.person_first_name)
            this._validateLogic(this.state.person_last_name)
        } else {
            this._validateLogic(this.state.org_type)
            this._validateLogic(this.state.org_name)
        }
        console.log(this.state.isValidateError)
        //Password
        if (this.state.isChangePassword) {
            console.log("old ", this.state.oldPassword, " pass ", this.state.password, " confirm ", this.state.confirmPassword)
            this._validateLogic(this.state.oldPassword);
            this._validateLogic(this.state.password);
            this._validateLogic(this.state.confirmPassword);
        }

        //Area
        if (this.state.chipDistrict.length <= 0) {
            this.setState({ isValidateError: true })
        }

        //Password
        if (this.state.isChangePassword) {
            console.log("old ", this.state.oldPassword, " pass ", this.state.password, " confirm ", this.state.confirmPassword)
            this._validateLogic(this.state.oldPassword);
            this._validateLogic(this.state.password);
            this._validateLogic(this.state.confirmPassword);
        }

    }

    _validateLogic = (value) => {
        console.log(value)
        if (Validators.isEmpty(value)) {
            this.setState({ isValidateError: true })
        }
    }

    // ======== SAVE =========
    onSubmit = (e) => {

        const { userServiceProps } = this.props

        //> validate before submit

        //> store data
        const formData = new FormData();
        const { userInfo } = this.props
        //
        for (let key in userInfo) {
            let valState = this.state[key]
            if (typeof valState == 'object') {
                if (Validators.isNotEmpty(valState)) {
                    formData.append([key], JSON.stringify(valState))
                }
            } else {
                if (Validators.isNotEmpty(valState)) {
                    formData.set([key], valState || '')
                }
            }
        }

        //Custom field other overide that
        let areListString = this.state.chipDistrict
        formData.set('areaListString', JSON.stringify(areListString))
        formData.set("addr_card_use_current", this.state.useCardAddress ? 1 : 0)

        //>custom File
        if (this.state.fileImgIden) {
            formData.append('FileImgIden', this.state.fileImgIden);
        }
        if (this.state.fileImgRegis) {
            formData.append('FileImgRegis', this.state.fileImgRegis);
        }
        if (this.state.fileImgPerson) {
            formData.append('FileImgPerson', this.state.fileImgPerson);
        }
        if (this.state.fileImgVehicle) {
            formData.append('FileImgVehicle', this.state.fileImgVehicle);
        }
        this.validateRequire();

        console.log("print state save ", this.state)
        if (this.state.isValidateError) {
            this.showWarn("กรุณากรอกข้อมูลที่เครื่องหมาย *");
        } else {

            if (this.state.isChangePassword) {
                var jsPass = {
                    username: SecureUtils.getItem('user').username,
                    oldPassword: this.state.oldPassword,
                    newPassword: this.state.password,
                    confirmPassword: this.state.confirmPassword,
                    statusMessage: "string",
                }
                userServiceProps.changePassword(JSON.parse(JSON.stringify(jsPass)))
            }

            //console.log(this.state)
            userServiceProps.updateWastetranProfile(formData)

            this.setState({ isUpdate: true })
            this.setState({ isLoading: true })
            this.setState({ isImgRegis: true, })
            this.setState({ isImgIdCard: true, })
            this.setState({ isImgPerson: true, })
            this.setState({ isImgVehicle: true, })
            this.setState({ isLoadChip: true, })
            this.setState({ isLoading: true, })
            this.setState({ isShowChangePassword: false })
        }

    }
    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }

    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'สถานะ', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }


    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    // === PASSWORD ===
    onOldPasswordChanged = (password) => {
        console.log(password)
        this.setState({ errorMessageOldPassword: null });

        if (Validators.isNotEmpty(password)) {
            if (password == SecureUtils.getItem('user').password) {
                this.setState({ errorMessageOldPassword: "" });
                this.setState({ errorMessagePassword: "กรุณาระบุรหัสผ่าน" });
                this.setState({ errorMessageConfirmPassword: "กรุณาระบุรหัสผ่าน" });
                this.setState({ isChangePassword: true })
                this.setState({ oldPassword: password })
            } else {
                this.setState({ errorMessageOldPassword: "รหัสผ่านไม่ตรงกัน" });
                this.setState({ isChangePassword: false })
                this.setState({ isValidateError: true })
            }
        } else {
            this.setState({ isChangePassword: false })
        }
    }

    onPasswordChanged = (password) => {
        this.setState({ errorMessagePassword: null });

        if (password.length >= 6) {
            const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
            const isOk = re.test(password);
            if (!isOk) {
                this.setState({ errorMessagePassword: "ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่" });
                this.setState({ isValidateError: true })
            } else {
                this.setState({ password: password });
                this.setState({ errorMessagePassword: "" });

                if (password == this.state.confirmPassword) {
                    this.setState({ errorMessageConfirmPassword: "" });
                } else {
                    this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
                    this.setState({ isValidateError: true })
                }
            }
        } else {
            this.setState({ errorMessagePassword: "กรุณากรอกอย่างน้อย 6 ตัวอักษร (ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่)" });
            this.setState({ isValidateError: true })
        }
    }

    onConfirmPasswordChanged = (cfpassword) => {
        this.setState({ errorMessageConfirmPassword: "" });
        console.log("isChangePassword ", this.state.isChangePassword, "pass ", this.state.password, " cfpass ", cfpassword)
        if (this.state.password == cfpassword) {
            this.setState({ confirmPassword: cfpassword });
            this.setState({ errorMessageConfirmPassword: "" });
        } else {
            this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
            this.setState({ confirmPassword: cfpassword });
            this.setState({ isValidateError: true })
        }
    }

    setPasswordVisible = (e) => {

        this.setState({ checkedPasswordVisible: e.value })
        if (e.value) {
            this.setState({ typePasswordInput: 'text' });
        } else {
            this.setState({ typePasswordInput: 'password' });
        }
    }

    render() {
        console.log("render")
        const {
            locationMaster,
            userInfoMaster,
            userInfo,
            province,
            district,
            subDistrict,
            updateWastetranProfile,
            locationServiceProps,
            userServiceProps,

            cardProvince,
            cardDistrict,
            cardSubDistrict,

            chipProvince,
            chipDistrict,

            banks,

            getFileWastetran
        } = this.props

        const { isValidateError } = this.state

        //Validate class error require
        const classNameRequired = isValidateError == true ? "p-error" : "";


        let contentUser = (
            <div>Loading...</div>
        )
        let contentPicture = (
            <div>Loading...</div>
        )
        let contentContact = (
            <div>Loading...</div>
        )
        let contentLocation = (
            <div>Loading...</div>
        )
        let contentAddress = (
            <div>Loading...</div>
        )
        let contentBank = (
            <div>Loading...</div>
        )
        let contentDriver = (
            <div>Loading...</div>
        )
        let contentLogin = (
            <div>Loading...</div>
        )
        //console.log(this.state)
        //> Transform props to state



        if (!userInfoMaster.isLoading
            && !updateWastetranProfile.isLoading
            && !getFileWastetran.isLoading
            && !locationMaster.isFetching) {

            //> Transform Dropdown to Object selected
            // this.renderImage();

            const personType = (
                <div className="p-grid">
                    <div className="p-col-12" style={{ textAlign: 'left' }}>
                        <label class="required">คำนำหน้าชื่อ</label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown
                            className={classNameRequired}
                            name="person_prefix_code"
                            value={this.state.selectedPersonPrefix}
                            options={this.state.personPrefixs}
                            onChange={this.onChangePersonPrefix}
                            placeholder="เลือกคำนำหน้า"
                            optionLabel="name"
                        />
                    </div>

                    <div style={{ marginTop: '.5em' }}></div>

                    <div className="p-col-12">
                        <label className="required">ชื่อ</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            type="text"
                            className={"input-100 " + classNameRequired}
                            value={this.state.person_first_name}
                            name="person_first_name"
                            placeholder="First Name"
                            onChange={this.onChangeInputText} />
                    </div>

                    <div className="p-col-12">
                        <label className="required">นามสกุล </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            type="text"
                            className={"input-100 " + classNameRequired}
                            name="person_last_name"
                            placeholder="Last Name"
                            value={this.state.person_last_name}
                            onChange={this.onChangeInputText} />
                    </div>

                    <div className="p-col-12">
                        <label>เลขบัตรประชาชน</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Thai ID"
                            name="person_idcard"
                            value={this.state.person_idcard}
                            onChange={this.onChangeInputText}
                        ></InputText>
                    </div>
                </div>
            );

            const legalType = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label className="required">ประเภทนิติบุคคล</label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown
                            className={classNameRequired}
                            name="org_type"
                            value={this.state.selectedCompanyType}
                            options={this.state.companyTypes}
                            onChange={this.onChangeCompanyType}
                            placeholder="เลือกประเภทนิติบุคคล"
                            optionLabel="name" />
                        <div style={{ marginTop: '.5em' }}></div>
                    </div>

                    <div className="p-col-12">
                        <label className="required">ชื่อนิติบุคคล</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            type="text"
                            className={"input-100 " + classNameRequired}
                            placeholder="Company Name"
                            value={this.state.org_name}
                            name="org_name"
                            onChange={this.onChangeInputText} />
                    </div>
                    <div className="p-col-12">
                        <label className="required">เลขนิติบุคคล</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className={"input-100 " + classNameRequired}
                            placeholder="Company Id"
                            name="org_code"
                            value={this.state.org_code}
                            onChange={this.onChangeInputText}
                        ></InputText>
                    </div>

                </div>

            );

            //> ข้อมูลสมาชิก
            contentUser = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label>ประเภทสมาชิก</label>
                    </div>
                    <div className="p-col-6">
                        <RadioButton inputId="rb1"
                            name="person_type" value="1"
                            onChange={this.onChangeInputText}
                            checked={this.state.person_type == '1'} />
                        <label htmlFor="rb1" className="p-radiobutton-label">บุคคลธรรมดา</label>

                    </div>
                    <div className="p-col-6">
                        <RadioButton inputId="rb2"
                            name="person_type" value="2"
                            onChange={this.onChangeInputText}
                            checked={this.state.person_type == '2'} />
                        <label htmlFor="rb2" className="p-radiobutton-label">นิติบุคคล</label>
                    </div>

                    <div className="p-col-12">
                        {this.state.person_type == '1' && personType}
                        {this.state.person_type == '2' && legalType}
                    </div>

                    <div className="p-col-12">
                        <label>หมายเลขโทรศัพท์มือถือ</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Mobile Number 1"
                            name="telephone_no1"
                            value={this.state.telephone_no1}
                            onChange={this.onChangeInputText}></InputText>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Mobile Number 2"
                            name="telephone_no2"
                            value={this.state.telephone_no2}
                            onChange={this.onChangeInputText}></InputText>
                    </div>

                    <div className="p-col-12">
                        <label>Line Id</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Line ID"
                            name="line_id"
                            value={this.state.line_id}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>
                    <div className="p-col-12">
                        <label>Email</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Email"
                            name="email"
                            value={this.state.email}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                </div>
            );

            //> รูปถ่าย
            contentPicture = (
                <div className="p-grid">

                    <div className="p-col-12">
                        <Panel header="รูปถ่ายบัตรประชาชน / หนังสือรับรอง">
                            <FileView src={`${API_ROOT}/wastetran/getfile/${this.state.img_iden_name}`} />
                            <FileUpload name="fileIDCard[]"
                                onSelect={(e) => this.onFileIDCardChanged(e)}
                                mode="basic"
                                chooseLabel="แนบไฟล์"
                                cancelLabel="ยกเลิก"
                                multiple={false}
                                accept="image/*,application/pdf"
                                maxFileSize={10000000} />
                        </Panel>
                    </div>

                    <div className="p-col-12">
                        <Panel header="กรณีเป็นนิติบุคคลรูปถ่าย ภพ20">
                            <FileView src={`${API_ROOT}/wastetran/getfile/${this.state.img_regis_org_name}`} />
                            <FileUpload name="fileRegis[]"
                                onSelect={(e) => this.onFileRegisChanged(e)}
                                mode="basic"
                                chooseLabel="แนบไฟล์"
                                cancelLabel="ยกเลิก"
                                multiple={false}
                                accept="image/*,application/pdf"
                                maxFileSize={10000000} />
                        </Panel>
                    </div>

                    <div className="p-col-12">
                        <Panel header="รูปถ่ายสมาชิก">
                            <FileView src={`${API_ROOT}/wastetran/getfile/${this.state.img_person_name}`} />
                            <FileUpload name="filePerson[]"
                                onSelect={(e) => this.onFilePersonChanged(e)}
                                mode="basic"
                                chooseLabel="แนบไฟล์"
                                cancelLabel="ยกเลิก"
                                multiple={false}
                                accept="image/*,application/pdf"
                                maxFileSize={10000000} />
                        </Panel>
                    </div>
                </div>
            );

            //> ที่อยู่ตามบัตรประชาชน/ ที่อยู่บริษัทกรณีเป็นนิติบุคคล
            contentContact = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label >
                            จังหวัด
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="province_name"
                            value={this.state.selectedCardProv}
                            options={cardProvince}
                            onChange={this.onChangeCardProv}
                            placeholder="-- selected --" />

                    </div>

                    <div className="p-col-12">
                        <label >
                            อำเภอ/เขต
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="districtName"
                            value={this.state.selectedCardDistrict}
                            options={cardDistrict}
                            onChange={this.onChangeCardDistrict}
                            placeholder="-- selected --" />

                    </div>

                    <div className="p-col-12">
                        <label >
                            ตำบล/แขวง
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="subDistrictName"
                            value={this.state.selectedCardSubDistrict}
                            options={cardSubDistrict}
                            onChange={this.onChangeCardSubDistrict}
                            placeholder="-- selected --" />

                    </div>

                    <div className="p-col-12">
                        <label >
                            เลขที่บ้าน/ที่อยู่
                    </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Address "
                            name="addr_card_detail"
                            value={this.state.addr_card_detail}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                </div>
            );

            //> ต้องการเก็บขยะรีไซเคิลในพื้นที่ใด
            contentLocation = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label className="required">
                            จังหวัด
                </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown
                            className={classNameRequired}
                            style={{ width: '100%' }}
                            value={this.state.provinceChipSelected}
                            options={chipProvince}
                            onChange={this.onProvinceChipChanged}
                            optionLabel="province_name"
                            placeholder="เลือกจังหวัด" />

                    </div>


                    <div className="p-col-12">
                        <label className="required">
                            อำเภอ/เขต
                         </label>
                    </div>

                    <div className="p-col-12">
                        <Dropdown
                            className={classNameRequired}
                            style={{ width: '100%' }}
                            value={this.state.districtSelected}
                            options={chipDistrict}
                            onChange={this.onDistrictChipChanged}
                            optionLabel="districtName"
                            placeholder="อำเภอ/เขต" />

                    </div>

                    <div className="p-col-12 p-lg-12" >
                        <Chips
                            style={{ margin: '7px' }}
                            value={this.state.chipDistrict}
                            itemTemplate={this.customChip}
                            onRemove={this.customChipRemove}
                        >
                        </Chips>
                    </div>

                </div>
            );

            //> ที่อยู่ปัจจุบัน
            contentAddress = (
                <div className="p-grid">
                    <div className="p-col-12 " style={{ textAlign: 'left' }}>
                        <Checkbox
                            checked={this.state.useCardAddress}
                            onChange={this.onDuplicate} />
                        <label htmlFor="pro_cb" className="p-checkbox-label">เหมือนที่อยู่ตามบัตรประชาชน/ ที่อยู่บริษัทกรณีเป็นนิติบุคคล</label>
                    </div>
                    <div className="p-col-12">
                        <label >
                            จังหวัด
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="province_name"
                            value={(this.state.useCardAddress) ? this.state.selectedCardProv : this.state.selectedCurrentProv}
                            options={province}
                            onChange={(this.state.useCardAddress) ? this.onChangeCardProv : this.onChangeCurrentProv}
                            placeholder="-- selected --"
                            disabled={this.state.useCardAddress} />

                    </div>

                    <div className="p-col-12">
                        <label >
                            อำเภอ/เขต
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="districtName"
                            value={(this.state.useCardAddress) ? this.state.selectedCardDistrict : this.state.selectedCurrentDistrict}
                            options={(this.state.useCardAddress) ? cardDistrict : district}
                            onChange={(this.state.useCardAddress) ? this.onChangeCardDistrict : this.onChangeCurrentDistrict}
                            placeholder="-- selected --"
                            disabled={this.state.useCardAddress} />

                    </div>

                    <div className="p-col-12">
                        <label >
                            ตำบล/แขวง
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="subDistrictName"
                            value={(this.state.useCardAddress) ? this.state.selectedCardSubDistrict : this.state.selectedCurrentSubDistrict}
                            options={(this.state.useCardAddress) ? cardSubDistrict : subDistrict}
                            onChange={(this.state.useCardAddress) ? this.onChangeCardSubDistrict : this.onChangeCurrentSubDistrict}
                            placeholder="-- selected --"
                            disabled={this.state.useCardAddress} />

                    </div>

                    <div className="p-col-12">
                        <label >
                            เลขที่บ้าน/ที่อยู่
                    </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Address"
                            name={(this.state.useCardAddress) ? "addr_card_detail" : "addr_current_detail"}
                            value={(this.state.useCardAddress) ? this.state.addr_card_detail : this.state.addr_current_detail}
                            disabled={this.state.useCardAddress}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>
                </div>
            );

            //> รายละเอียดบัญชีธนาคาร
            contentBank = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label >ชื่อบัญชี</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Account Name"
                            name="bank_account_name"
                            value={this.state.bank_account_name}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                    <div className="p-col-12">
                        <label >เลขที่บัญชี</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Account Number"
                            name="bank_account_no"
                            value={this.state.bank_account_no}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                    <div className="p-col-12">
                        <label >ธนาคาร</label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="bank_name"
                            name="bank_code"
                            value={this.state.selectedBank}
                            options={banks}
                            onChange={this.onChanageBank}
                            placeholder="เลือกธนาคาร"
                        />
                    </div>

                    <div className="p-col-12">
                        <label >Prompt-pay</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Prompt-pay"
                            name="bank_promptpay"
                            value={this.state.bank_promptpay}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                    <div className="p-col-12">
                        <label >รหัสเลขใช้สำหรับแจ้งถอนเงิน</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Pin Number"
                            name="bank_withdraw_code"
                            value={this.state.bank_withdraw_code}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                </div>
            );

            //> ข้อมูลยานพาหนะ
            contentDriver = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label>
                            ทะเบียนรถ
                    </label>
                    </div>

                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="ทะเบียนรถ"
                            name="vehicle_no"
                            value={this.state.vehicle_no}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                    <div className="p-col-12">
                        <label>
                            ประเภทรถ
                </label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <RadioButton
                            inputId="cb1"
                            value="1"
                            name="vehicle_type"
                            onChange={this.onChangeInputText}
                            checked={this.state.vehicle_type == '1'} />
                        <label htmlFor="cb1" className="p-checkbox-label">มอเตอร์ไซค์</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <RadioButton
                            name="vehicle_type"
                            inputId="cb2"
                            value="2"
                            onChange={this.onChangeInputText}
                            checked={this.state.vehicle_type == '2'}></RadioButton>
                        <label htmlFor="cb2" className="p-checkbox-label">ซาเล้ง</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <RadioButton
                            name="vehicle_type"
                            inputId="cb3"
                            value="3"
                            onChange={this.onChangeInputText}
                            checked={this.state.vehicle_type == '3'}></RadioButton>
                        <label htmlFor="cb3" className="p-checkbox-label">รถกระบะและบรรทุก 4 ล้อ</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <RadioButton
                            name="vehicle_type"
                            inputId="cb4"
                            value="4"
                            onChange={this.onChangeInputText}
                            checked={this.state.vehicle_type == '4'}></RadioButton>
                        <label htmlFor="cb4" className="p-checkbox-label">รถบรรทุก 6 ล้อ</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <RadioButton
                            name="vehicle_type"
                            inputId="cb5"
                            value="5"
                            onChange={this.onChangeInputText}
                            checked={this.state.vehicle_type == '5'}></RadioButton>
                        <label htmlFor="cb5" className="p-checkbox-label">รถบรรทุก 10 ล้อ</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <RadioButton
                            name="vehicle_type"
                            inputId="cb6"
                            value="6"
                            onChange={this.onChangeInputText}
                            checked={this.state.vehicle_type == '6'}></RadioButton>
                        <label htmlFor="cb6" className="p-checkbox-label">รถพ่วง</label>
                    </div>


                    {this.state.imgVehicle &&
                        <div className="p-col-12">
                            <img alt="imgVehicle" src={this.state.imgVehicle} className="p-col-12" />
                        </div>
                    }

                    <div className="p-col-12">
                        <Panel header="รูปถ่ายรถปัจจุบัน">
                            <FileView src={`${API_ROOT}/wastetran/getfile/${this.state.vehicle_img_name}`} />
                            <FileUpload name="fileVehicle[]"
                                onSelect={(e) => this.onFileVehicleChanged(e)}
                                mode="basic"
                                chooseLabel="แนบไฟล์"
                                cancelLabel="ยกเลิก"
                                multiple={false}
                                accept="image/*"
                                maxFileSize={10000000} />
                        </Panel>
                    </div>



                </div>
            );

            //> ข้อมูล Login
            contentLogin = (
                <div className="p-grid">
                    <Growl ref={(el) => this.growl = el} />
                    <div className="p-col-12">
                        <label className="required">
                            รหัสผ่านเดิม
                        </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            name="oldPassword"
                            type={this.state.typePasswordInput}
                            className={"input-100 " + classNameRequired}
                            placeholder="Old Password" autoComplete="new-password"
                            onChange={(e) => this.onOldPasswordChanged(e.target.value)}
                        >
                        </InputText>
                        <span style={{ color: 'red' }}>{this.state.errorMessageOldPassword} </span>
                    </div>
                    <div className="p-col-12">
                        <label className="required">
                            รหัสผ่านใหม่
                    </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            name="password"
                            className={"input-100 " + classNameRequired}
                            placeholder="New Password"
                            type={this.state.typePasswordInput}
                            onChange={(e) => this.onPasswordChanged(e.target.value)}>
                        </InputText>
                        {this.state.isChangePassword &&
                            <span style={{ color: 'red' }}>{this.state.errorMessagePassword} </span>
                        }
                    </div>

                    <div className="p-col-12">
                        <label className="required">
                            ยืนยันรหัสผ่าน
                    </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            name="confirmPassword"
                            className={"input-100 " + classNameRequired}
                            placeholder="Confirm Password"
                            type={this.state.typePasswordInput}
                            onChange={(e) => this.onConfirmPasswordChanged(e.target.value)}
                        >
                        </InputText>

                        {this.state.isChangePassword &&
                            <span style={{ color: 'red' }}>{this.state.errorMessageConfirmPassword} </span>
                        }
                    </div>

                    <div className="p-col-12 p-md-3 p-lg-3" >

                        <ToggleButton style={{
                            width: '150px'
                            , marginTop: '5px'
                            , backgroundColor: '#f0ad4e'
                            , color: '#000000'
                            , borderColor: '#eea236'
                        }}
                            className="p-button-secondary"
                            onLabel="ซ่อนรหัสผ่าน"
                            offLabel="แสดงรหัสผ่าน"
                            onIcon="fa fa-eye-slash"
                            offIcon="fa fa-eye"
                            checked={this.state.checkedPasswordVisible}
                            onChange={(e) => this.setPasswordVisible(e)}
                        />


                    </div>

                </div>
            );
        }


        return (
            <div>
                <Growl ref={(el) => this.growl = el} />
                <h1>Profile</h1>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-6">

                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>ข้อมูลสมาชิก</h1>
                                {contentUser}
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>ที่อยู่ตามบัตรประชาชน/ ที่อยู่บริษัทกรณีเป็นนิติบุคคล</h1>
                                {contentContact}
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>ที่อยู่ปัจจุบัน</h1>
                                {contentAddress}
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>ข้อมูลยานพาหนะ</h1>
                                {contentDriver}
                            </div>
                        </div>

                        {<div className="p-lg-12  p-md-12  p-sm-12">
                            <div className="card card-w-title">
                                <h1>เปลี่ยนรหัสผ่าน <Checkbox
                                    checked={this.state.isShowChangePassword}
                                    onChange={(e) => this.setState({ isShowChangePassword: e.checked })}
                                /></h1>
                                {this.state.isShowChangePassword && contentLogin}
                            </div>
                        </div>}

                    </div>
                    <div className="p-col-12 p-lg-6">

                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>รูปถ่าย</h1>
                                {contentPicture}
                            </div>
                        </div>

                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>ต้องการเก็บขยะรีไซเคิลในพื้นที่ใด</h1>
                                {contentLocation}
                            </div>
                        </div>


                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>รายละเอียดบัญชีธนาคาร</h1>
                                {contentBank}
                            </div>
                        </div>

                    </div>



                    <div className="p-col-12">
                        <Button
                            className="p-col-12"
                            label="บันทึก"
                            icon="pi pi-check"
                            iconPos="left"
                            onClick={this.onSubmit} />
                    </div>

                </div>
            </div>
        )
    }
}