import React from 'react'
import { Validators } from '../../utils';
import { Message } from 'primereact/message';
import { InputMask } from 'primereact/inputmask';

export const ValidatePhoneNumberRequiredView = ({ 
    value, 
    onChange, 
    mask,
    name,
    placeholder,
    errorMessage,
    required,
    isErrorPhone
}) => {

    mask = mask || "****************************************";
    errorMessage = errorMessage||`กรุณาระบุ ${placeholder}`
    var tmpRequired = required || true

    return (
        <div >
                <InputMask
                    required={tmpRequired}
                    className={Validators.isInValidPhoneNumberRequired(value, mask.length)||isErrorPhone ? 'p-error' : ''}
                    name={name}
                    mask={mask}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            {
                (Validators.isInValidPhoneNumberRequired(value, mask.length)||isErrorPhone) &&
                <Message severity="error" text={errorMessage} />
            }
        </div>
    )
}