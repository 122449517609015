import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Panel } from 'primereact/components/panel/Panel';
import { Button } from 'primereact/components/button/Button';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import * as ServerAPI from './service';
import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'
import * as DashBoardService from './service'
import { CEInputText} from '../../features/input';
import {Dialog} from 'primereact/dialog';

class ManageProductPrice extends Component {
    static propTypes = {

    }
    constructor() {
        super();
        this.state = {
            addressDetail: '',
            priceList: [],
            memberStore: 0,
            brandSuggestions: null,
            wasteTypeSelected: '',
            searchProductType: '',
            searchProduct: '',
            productSelected: '',
            priceSelected1: '',
            reportSearchOrders: [],
            showProgress: false,

        };
        this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
        this.onMemberNameChanged = this.onMemberNameChanged.bind(this);
        this.onMemberName1Changed = this.onMemberName1Changed.bind(this);
        this.onSelectMemberName = this.onSelectMemberName.bind(this);
        this.onSelectMemberName1 = this.onSelectMemberName1.bind(this);
        this.onproductsChange = this.onproductsChange.bind(this);
        this.onsearchProductChange = this.onsearchProductChange.bind(this);
        this.onWasteTypeChange = this.onWasteTypeChange.bind(this);
        this.onsearchProductTypeChange = this.onsearchProductTypeChange.bind(this);
        this.addDataToTable = this.addDataToTable.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.onValidation = this.onValidation.bind(this);
        this.getReward = this.getReward.bind(this);
        this.searchReward = this.searchReward.bind(this);
        this.getsalepricebyu3ForValid = this.getsalepricebyu3ForValid.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.onWeightUpdate = this.onWeightUpdate.bind(this);
        this.onproductTypeUpdate = this.onproductTypeUpdate.bind(this);

    }
    componentDidMount() {
        this.getReward();
        this.getsalepricebyu3ForValid();
        const { dashBoardServiceProps, locationServiceProps, masterServiceProps } = this.props
        dashBoardServiceProps.getDashboardUsers();
        locationServiceProps.getProvice();
        masterServiceProps.getProductType();
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div></div>);
    }
    searchReward(){
        this.setState({ showProgress: true })
        var search = {
            product_code: this.state.searchProductCode || '',
            product_type_code: this.state.searchProductTypeCode || '',
        }

        ServerAPI.searchReward(search).then((res) => {
            if (res) {
                var index = res.toString().indexOf("Error:");
                if (index == -1) {
                    this.setState({priceList: res })
                    // this.getprocessfee();
                    // this.clearAllDataInput();
                    this.setState({ showProgress: false })
                    this.showSuccess();
                    
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }

    getReward() {
        this.setState({ showProgress: true })
        ServerAPI.getReward().then((res) => {
            if (res) {
                var index = res.toString().indexOf("Error:");
                if (index == -1) {
                    this.setState({ priceList: res })
                    this.setState({ productPriceList: res})
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }
    getsalepricebyu3ForValid() {
        ServerAPI.getReward().then((res) => {
            if (res) {
                var index = res.toString().indexOf("Error:");
                if (index == -1) {
                    this.setState({ productPriceList: res})
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }


    itemTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }

    itemTemplate1(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }

    onSelectMemberName(item) {
        this.setState({ memberName: item.value.search_name });
        this.setState({ memberUid: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    onSelectMemberName1(item) {
        this.setState({ memberName1: item.value.search_name });
        this.setState({ memberUid1: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    suggestMemberName(event) {
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }
    suggestMemberName1(event) {
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }

    onMemberNameChanged(search_name) {
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwastepro(search_name).then((res) => {
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName: search_name });
                this.setState({ memberUid: null });
            }
    }
    onMemberName1Changed(search_name) {
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName1: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwastepro(search_name).then((res) => {
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName1: search_name });
                this.setState({ memberUid1: null });
            }
    }

    onproductTypeUpdate(e){
        this.setState({productTypeObjUpdate: e.value});
    }
    onWeightUpdate(e){
        this.setState({productObjUpdate: e.value});
 
    }

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" onClick={() => this.onproductPriceUpdate(column.rowData)} icon="pi pi-pencil" className="p-button" ></Button>
            <Button type="button" onClick={() => this.onproductPriceDelete(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button>
        </div>;
    }
    onClickDeleteItem(rowData) {
        if (rowData !== '') {
            var id = rowData.gid
            ServerAPI.deleteReward(id).then((res) => {
                if (res) {
                    var index = res.toString().indexOf("Error:");
                    if (index == -1) {
                        this.searchReward();
                        this.getsalepricebyu3ForValid()
                        this.setState({ showProgress: false })
                        //this.showSuccess();
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            }).catch((error) => {
            })
        }else {
            this.showWarn();
            this.setState({ showProgress: false })
        }
    }

    update() {
        this.setState({ showProgress: true })
        if(this.state.productPrice){
            var isNotinput = (
                this.state.productPrice.reward_per_kk == '' ||
                this.state.isNotValid == true
            );
            if (isNotinput) {
                this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
                this.setState({ showProgress: false })
            } else {
                var data = {
                    gid: this.state.productPrice.gid,
                    reward_per_kk: parseFloat(this.state.productPrice.reward_per_kk),
                }

                ServerAPI.editReward(data).then((res) => {
                    if (res) {
                        var index = res.toString().indexOf("Error:");
                        if (index == -1) {
                            this.getReward();
                            this.setState({ displayDialog:false});
                            this.clearAllDataInput();
                            this.showSuccess();
                        } else {
                            this.showWarn();
                            this.setState({ showProgress: false })
                        }
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                }).catch((error) => {
                    this.setState({ showProgress: false })
                })
                    
            }
        }
    }

    delete() {
        this.setState({ showProgress: true })
        var data = this.state.productPrice;
        this.onClickDeleteItem(data);
        // let index = this.findSelectedWeightIndex();
        // this.setState({
        //     cars: this.state.cars.filter((val,i) => i !== index),
        //     selectedCar: null,
        //     car: null,
        this.setState({deleteDialog: false});
    }

    updateProperty(property, value) {
        let productPrice = this.state.productPrice;
        productPrice[property] = value;
        this.setState({productPrice: productPrice});
    }

    onproductPriceUpdate(e){
        var productTypeDropdown = {
            product_type_code: e.product_type_code,
            product_type_name: e.product_type_name,
            less_than_kk: e.less_than_kk,
            more_than_kk: e.more_than_kk, 
        }
        var productDropdown = {
            product_code: e.product_code,
            product_name: e.product_name,
            less_than_kk: e.less_than_kk,
            more_than_kk: e.more_than_kk,
        }
        this.setState({
            displayDialog:true,
            productPrice: Object.assign({}, e),
            productTypeObjUpdate:  productTypeDropdown,
            productObjUpdate:  productDropdown,
        });
    }

    onproductPriceDelete(e){
        this.setState({
            deleteDialog:true,
            productPrice: Object.assign({}, e),
        });
    }

    onValidation(valid) {
        if (!valid) this.setState({ isNotValid: true })
        else this.setState({ isNotValid: false })
    }

    onWasteTypeChange(e) {
        this.setState({ wasteTypeSelected: e.value });
        this.setState({ productTypeCode: e.value.product_type_code });
        const { masterServiceProps, product } = this.props
        masterServiceProps.getProduct(e.value.product_type_code);
    }
    onsearchProductTypeChange(e) {
        this.setState({ searchProductType: e.value });
        this.setState({ searchProductTypeCode: e.value.product_type_code });
        const { masterServiceProps, product } = this.props
        masterServiceProps.getProduct(e.value.product_type_code);
    }

    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }

    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    addDataToTable() {
        this.setState({ showProgress: true })
        var isNotinput = (
            this.state.wasteTypeSelected == '' ||
            this.state.productSelected == '' ||
            // this.state.priceSelected1 == '' ||
            // this.state.memberUid == '' ||
            // this.state.memberName == '' ||
            this.state.isNotValid == true
        );

        if (isNotinput) {
            this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
            this.setState({ showProgress: false })
        } else {
            var notValid = false;
            this.getsalepricebyu3ForValid();
            if(this.state.productPriceList)
            this.state.productPriceList.forEach(objProductPrice => {
                if (objProductPrice.u3_uid == this.state.memberUid
                    && objProductPrice.product_type_code == this.state.productTypeCode
                    && objProductPrice.product_code == this.state.productSelected.product_code) {
                    notValid = true;
                }
            });

            if (notValid) {
                this.showWarn("ข้อมูลซ้ำ! กรุณาเลือกใหม่อีกครั้ง");
                this.setState({ showProgress: false })
            } else {
                var priceList = {
                    gid: this.state.gid,
                    u3_uid: this.state.memberUid,
                    u3_name: this.state.memberName,
                    product_type_code: this.state.wasteTypeSelected.product_type_code,
                    product_type_name: this.state.wasteTypeSelected.product_type_name,
                    product_code: this.state.productSelected.product_code,
                    product_name: this.state.productSelected.product_name,
                    reward_per_kk: parseFloat(this.state.priceSelected1),

                }

                ServerAPI.AddReward(priceList).then((res) => {
                    if (res) {
                        var index = res.toString().indexOf("Error:");
                        if (index == -1) {
                            this.searchReward();
                            this.clearAllDataInput();
                            //this.showSuccess();

                        } else {
                            this.showWarn();
                            this.setState({ showProgress: false })
                        }
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                }).catch((error) => {
                    this.setState({ showProgress: false })
                })
            }
        }
    }

    onproductsChange(e) {
        this.setState({ productSelected: e.value });
    }

    onsearchProductChange(e) {
        this.setState({ searchProduct: e.value });
        this.setState({ searchProductCode: e.value.product_code });
        this.setState({ searchProductName: e.value.product_name });
    }

    clearAllDataInput() {
        this.setState({ wasteTypeSelected: '' })
        this.setState({ productSelected: '' })
        this.setState({ priceSelected1: '' })
        this.setState({ memberName: '' })
        this.setState({ memberUid: '' })
        this.setState({ productPrice: null })
        this.setState({ productTypeObjUpdate: null })
        this.setState({ productObjUpdate: null })
    }

    clearSearchInput() {
        this.setState({ memberName1: null }); //order code from select table
        this.setState({ memberUid1: null }); 
        this.setState({ searchProductType: null }); //order code from auto complete
        this.setState({ searchProduct: null });
        this.setState({ searchProductTypeCode: null });
        this.setState({ searchProductCode: null });

        this.clearAllDataInput();
    }

    showSearchPanel() {
        const {
            productType1,
            product1,
        } = this.props;
        return (

            <Accordion multiple={true} activeIndex={[0, 1]}>
                <AccordionTab header="เงื่อนไขค้นหาเพื่อจัดการค่าความดี">
                    <div className="p-grid p-justify-start" >
                        {/* <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>ชื่อศูนย์จัดการฯ :</label>
                        </div>
                        <div className="p-col-12 p-md-10 p-lg-11" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <AutoComplete
                                style={{ width: '20%' }}
                                value={this.state.memberName1}
                                suggestions={this.state.memberSuggestions}
                                onSelect={this.onSelectMemberName1}
                                completeMethod={this.suggestMemberName1.bind(this)}
                                onChange={(e) => this.onMemberName1Changed(e.target.value)}
                                itemTemplate={this.itemTemplate.bind(this)}
                                placeholder="ระบุรหัสสมาชิก/ชื่อสมาชิก" />
                        </div> */}
                        <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>ประเภทสินค้า :</label>
                        </div>
                        <div className="p-col-12 p-md-10 p-lg-11" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <Dropdown
                                style={{ width: '20%' }}
                                value={this.state.searchProductType}
                                options={productType1}
                                onChange={this.onsearchProductTypeChange}
                                placeholder="เลือกประเภทขยะรีไซเคิล"
                                optionLabel="product_type_name"
                                //dataKey="product_type_code"
                            />

                        </div>
                        <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>สินค้า :</label>
                        </div>
                        <div className="p-col-12 p-md-10 p-lg-11" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <Dropdown
                                style={{ width: '20%' }}
                                value={this.state.searchProduct}
                                options={product1}
                                onChange={this.onsearchProductChange}
                                placeholder="เลือกสินค้า"
                                optionLabel="product_name"
                                //dataKey="product_code"
                            />
                        </div>

                        <div className="p-col-12">
                            <br/>
                            <Button
                                style={{marginRight:"20px"}}
                                label="ค้นหา"
                                icon="fa fa-search"
                                iconPos="left"
                                onClick={this.searchReward} />
                       
                            <Button
                                style={{ backgroundColor: '#f0ad4e' }}
                                type="submit"
                                label="ยกเลิก"
                                onClick={this.clearSearchInput}
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
        )
    }


    addNewProductTab() {
        const {
            productType,
            product,
        } = this.props;

        return (
            <Accordion multiple={true} >
                <AccordionTab header="เพิ่มการจัดการค่าความดี">
                    <div className="p-grid">
                        {/* <label className="p-col-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>ชื่อศูนย์จัดการฯ :</label>
                        <div className="p-col-10 ">
                            <AutoComplete
                                style={{ width: '20%' }}
                                value={this.state.memberName}
                                suggestions={this.state.memberSuggestions}
                                onSelect={this.onSelectMemberName}
                                completeMethod={this.suggestMemberName.bind(this)}
                                onChange={(e) => this.onMemberNameChanged(e.target.value)}
                                itemTemplate={this.itemTemplate.bind(this)}
                                placeholder="ระบุรหัสสมาชิก/ชื่อสมาชิก" />
                        </div> */}

                        <label className="p-col-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>ประเภทขยะรีไซเคิล :</label>
                        <div className="p-col-10">
                            <Dropdown
                                style={{ width: '20%' }}
                                value={this.state.wasteTypeSelected}
                                options={productType}
                                onChange={this.onWasteTypeChange}
                                placeholder="เลือกประเภทขยะรีไซเคิล"
                                optionLabel="product_type_name"
                                //dataKey="product_type_code"
                            />
                        </div>

                        <label className="p-col-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>สินค้า :</label>

                        <div className="p-col-10">
                            <Dropdown
                                style={{ width: '20%' }}
                                value={this.state.productSelected}
                                options={product}
                                onChange={this.onproductsChange}
                                placeholder="เลือกสินค้า"
                                optionLabel="product_name"
                                //dataKey="product_code"
                            />
                        </div>
                        <label className="p-col-2" style={{ textAlign: 'left', alignSelf: 'center' }}>ค่าความดีต่อกิโลกรัม :</label>
                        <div className="p-col-12 p-md-10 p-lg-10">
                            <CEInputText
                            style={{ width: '20%' }}
                                id="priceSelected1"
                                type="money"
                                value={this.state.priceSelected1}
                                name="priceSelected1"
                                placeholder="00.00"
                                tooltip="กรอกค่าขนส่ง (ทศนิยม 2 ตำแหน่ง)"
                                required={true}
                                isvalid={this.onValidation}
                                onChange={(e) => this.setState({ priceSelected1: e.target.value })}
                            />
                        </div>
                        
                        <div className="p-col-12 ">
                            <Button
                                style={{ float: "left" }}
                                label="เพิ่มรายการ"
                                icon="fa fa-plus"
                                iconPos="left"
                                onClick={this.addDataToTable}

                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>

        )
    }

    showWeightTable() {
        let newList = this.state.priceList
        newList.forEach((item,index) => {
            if(item.product_code == null || item.product_type_code == null ){
                newList.splice(index,1);
            }
        });
        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="บันทึก" icon="pi pi-check" onClick={this.update}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displayDialog: false})} className="p-button-warning"/>
            </div>;
        let deleteDialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ใช่" icon="pi pi-check" onClick={this.delete}/>
            <Button label="ไม่ใช่" icon="pi pi-times" onClick={() => this.setState({deleteDialog: false})} className="p-button-warning"/>
            </div>;
        const weightTable = (
            <div className="p-col-12">
                <Panel header="รายการค่าความดี" >
                    <DataTable value={newList} paginatorPosition="bottom"
                        selectionMode="single" paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                        selection={this.state.selectedCar1} onSelectionChange={e => this.setState({ selectedCar1: e.value })}
                        responsive={true} emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)} >
                        {/* <Column field="u3_name" header="รายชื่อศูนย์จัดการฯ" sortable={true} /> */}
                        <Column field="product_type_name" header="ประเภทสินค้า" sortable={true} filter={true} className="p-text-center"/>
                        <Column field="product_name" header="สินค้า" sortable={true} filter={true} className="p-text-center"/>
                        <Column field="reward_per_kk" header="ค่าความดีต่อกิโลกรัม" sortable={true} filter={true} className="p-text-center"/>
                        <Column field="gid" body={this.actionTemplate.bind(this)} header="จัดการ" className="p-text-center" style={{ width: '10%' }} />
                    </DataTable>
                </Panel>
                <Dialog visible={this.state.deleteDialog} width="300px" header="ยืนยันการลบข้อมูล" modal={true} footer={deleteDialogFooter} onHide={() => this.setState({deleteDialog: false})}>
                        {
                            this.state.productPrice &&
                            
                            <div className="p-grid p-fluid" >
                                <div className="p-col-12"><span>คุณต้องการจะลบข้อมูลต่อไปนี้</span></div>
                                {/* <div className="p-col-4" ><label htmlFor="u3_name">ชื่อศูนย์จัดการฯ :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productPrice.u3_name}</strong>
                                </div> */}

                                <div className="p-col-4" ><label htmlFor="product_type_name">ประเภทขยะรีไซเคิล :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productPrice.product_type_name}</strong>
                                </div>

                                <div className="p-col-4" ><label htmlFor="product_name">สินค้า :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productPrice.product_name}</strong>
                                </div>
                                <div className="p-col-4" ><label htmlFor="reward_per_kk">ค่าความดีต่อกิโลกรัม :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productPrice.reward_per_kk}</strong>
                                </div>
                            </div>
                        }
                    </Dialog>
                    <Dialog visible={this.state.displayDialog} style={{width: '40vw'}} header="แก้ไขข้อมูล" modal={true} footer={dialogFooter} onHide={() => this.setState({displayDialog: false})}>
                        {
                            this.state.productPrice &&
                            
                            <div className="p-grid p-fluid" >
                                
                                {/* <div className="p-col-12"><span>คุณต้องการจะลบข้อมูลต่อไปนี้</span></div> */}
                                {/* <div className="p-col-4" ><label htmlFor="u3_name">ชื่อศูนย์จัดการฯ :</label></div>
                                <div className="p-col-8" >
                                    {this.state.productPrice.u3_name}
                                </div> */}

                                <div className="p-col-4" ><label htmlFor="product_type_name">ประเภทขยะรีไซเคิล :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productPrice.product_type_name}</strong>
                                </div>

                                <div className="p-col-4" ><label htmlFor="product_name">สินค้า :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productPrice.product_name}</strong>
                                </div>

                                <div className="p-col-4" style={{ alignSelf: 'center' }}><label htmlFor="fee_baht">ค่าความดีต่อกิโลกรัม :</label></div>
                                <div className="p-col-8" style={{ padding: '0em' }}>
                                    <CEInputText   
                                        id="reward_per_kk"                        
                                        type="money"
                                        value={this.state.productPrice.reward_per_kk}
                                        name="reward_per_kk"
                                        placeholder="00.00"
                                        tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                        required={true}
                                        isvalid={this.onValidation}
                                        onChange={(e) => {this.updateProperty('reward_per_kk', e.target.value)}}
                                        />
                                    
                                </div>
                            </div>
                        }
                    </Dialog>
            </div>
        );
        if(!this.state.showProgress)
        return weightTable;
    }

    onSubmit() {

    }

    render() {
        const searchPanel = this.showSearchPanel();
        const addnew = this.addNewProductTab();
        const weightTablePanel = this.showWeightTable();
        const showProgress = this.showProgressSpinner();

        return <div className="p-grid ">
            <Growl ref={(el) => this.growl = el} />
            {searchPanel}
            {addnew}
            {showProgress}
            {weightTablePanel}
        </div>
    }
}

const mapStateToProps = (state) => ({
    dhbUsers: state.dhbAdmin.dhbUsers,
    locationMaster: state.location,
    provinces: state.location.province,
    districts: state.location.district,
    subDistricts: state.location.subDistrict,
    cardProvince: state.location.cardProvince,
    cardDistrict: state.location.cardDistrict,
    cardSubDistrict: state.location.cardSubDistrict,
    authentication: state.authentication,
    userInfo: state.userInfo.data,
    userInfoMaster: state.userInfo,
    updateWasteproProfile: state.updateWasteproProfile,
    banks: state.master.banks,
    productType: state.master.productType,
    product: state.master.product,
    productType1: state.master.productType,
    product1: state.master.product,
    getFileWastepro: state.getFileWastepro,
})

const mapDispatchToProps = dispatch => ({
    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    fileServiceProps: bindActionCreators(FileService, dispatch),

})

export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ManageProductPrice)