import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'moment-timezone'
import { Button } from 'primereact/button'
import { Growl } from 'primereact/growl'
import { API_ROOT, WEB_ROOT } from '../../constants/api'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Panel } from 'primereact/components/panel/Panel'
import { DataTable } from 'primereact/components/datatable/DataTable'
import { Column } from 'primereact/components/column/Column'
import { FileUpload } from 'primereact/fileupload'
import { ProgressSpinner } from 'primereact/progressspinner'
import {
    getCertAll,
    updateCertImage,
    getCertFile
} from '../../service/user.service'
import { orderBy } from 'lodash'
import { OverlayPanel } from 'primereact/overlaypanel';
import moment from 'moment'

export default function WasteGenCertificate() {

    const { userCert } = useSelector(state => state)
    const dispatch = useDispatch()

    const [certificationList, setCertificationList] = useState([])
    const [showProgress, setShowProgress] = useState(false)
    const [gid, setGid] = useState(0)
    const [isEdit, setIsEdit] = useState(false)
    const [selectedCert, setSelectedCert] = useState(null)
    const growl = useRef(null)
    const op = useRef(null)

    useEffect(() => {
        dispatch(getCertAll())
    }, [])

    useEffect(() => {
        if (userCert.data &&
            !userCert.isLoading &&
            !userCert.isFailed) {
            setCertificationList(orderBy(userCert.data, 'carbon_credit', 'desc'))
        }
    }, [userCert])

    const showProgressSpinner = () => {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner
                    style={{ width: '80px', height: '80px' }}
                    strokeWidth="8" fill="#EEEEEE"
                    animationDuration="0.5s"
                />
            </div>
        );

        if (showProgress) {
            return (Progress)
        } else
            return (<div></div>)
    }

    const showSuccess = (message) => {
        growl.current.show({
            severity: 'success',
            summary: 'สถานะ',
            detail: message ? message : 'ทำรายการสำเร็จ'
        })
    }
    const showWarn = (message) => {
        growl.current.show({
            severity: 'warn',
            summary: 'สถานะ',
            detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ'
        })
    }

    const updateCert = (e) => {
        if (e) {
            const formData = new FormData()
            formData.append('holderImages', e.files[0])
            formData.set('gid', gid)
            dispatch(updateCertImage(formData)).then((res) => {
                if (res.status == 200) {
                    setSelectedCert(res.data)
                    showSuccess()
                    dispatch(getCertAll())
                    setShowProgress(false)
                } else {
                    showWarn()
                    setShowProgress(false)
                }
            })
        }

    }

    // Shared to facebook
    function fbs_click() {
        if(selectedCert.cert_image_name == null) {
            showWarn('ยังไม่มีรูปประจำตัว')
        } else {
            let u = `${API_ROOT}/certification/getshare/${selectedCert.cert_image_name}`
            let t = selectedCert.cert_name;
            window.open('http://www.facebook.com/sharer.php?u=' + encodeURIComponent(u) + '&t=' + encodeURIComponent(t), 'sharer', 'toolbar=0,status=0,width=626,height=436'); return false;
        }
    }

    // Shared to Line
    function line_click() {
        if(selectedCert.cert_image_name == null) {
            showWarn('ยังไม่มีรูปประจำตัว')
        } else {
            let u = `${API_ROOT}/certification/getshare/${selectedCert.cert_image_name}`
            window.open('https://social-plugins.line.me/lineit/share?url=' + encodeURIComponent(u), 'sharer', 'toolbar=0,status=0,width=626,height=436'); return false;
        }
    }

    // Shared to Twitter
    // function twitter_click() {
    //     if(selectedCert.cert_image_name == null) {
    //         showWarn('ยังไม่มีรูปประจำตัว')
    //     } else {
    //         let u = `${API_ROOT}/certification/getshare/${selectedCert.cert_image_name}`
    //         window.open('https://twitter.com/intent/tweet?&url=' + encodeURIComponent(u), 'sharer', 'toolbar=0,status=0,width=626,height=436'); return false;
    //     }
    // }

    

    const socialShare = () => {
        return <div>
            <Button
                type="button"
                label="แชร์"
                icon="pi pi-share-alt"
                iconPos="left"
                onClick={(e) => op.current.toggle(e)}
                style={{ position: 'absolute', marginLeft: '725px', marginTop: '18px' }} />
            <OverlayPanel ref={op}>
                <img
                    width='68px'
                    height='68px'
                    src={"https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Facebook_icon_2013.svg/1200px-Facebook_icon_2013.svg.png"}
                    style={{
                        borderRadius: '20%',
                        marginLeft: '6px',
                        marginBottom: '6px'
                    }}
                    onClick={() => fbs_click()}
                />
                <img
                    width='80px'
                    height='80px'
                    src={"https://i.pinimg.com/originals/2b/30/40/2b3040fd70ad74f09806b105c1cfd94b.png"}
                    style={{
                        // position: 'absolute',
                        // marginLeft: '670px',
                        // marginTop: '10px'
                    }}
                    onClick={() => line_click()}
                />
                {/* <img
                    width='68px'
                    height='68px'
                    src={"https://www.iconfinder.com/data/icons/logotypes/32/square-twitter-512.png"}
                    style={{
                        marginTop: '6px',
                        marginBottom: '6px'
                    }}
                    onClick={() => twitter_click()}
                /> */}
                
            </OverlayPanel>
        </div>
    }

    const showEditCert = () => {
        if (selectedCert) {
            const editCert = (
                <div className="p-col-12"  >
                    <Accordion multiple={isEdit} activeIndex={[0]} onTabChange={() => {
                        setIsEdit(!isEdit)
                    }}>
                        <AccordionTab header="ภาพใบรับรอง">
                            <div className="p-grid">
                                <div className="p-col-12">
                                    <FileUpload
                                        name="fileCert[]"
                                        onSelect={updateCert}
                                        chooseLabel="เปลี่ยนรูปประจำตัว"
                                        accept="image/*"
                                        mode="basic"
                                        auto={true}
                                    />
                                    {socialShare()}
                                </div>
                                {selectedCert.cert_image_name == null ? <div className="p-col-12 p-md-12 p-lg-8" id="viewCert" >
                                    <img
                                        width={selectedCert.cert_image_width}
                                        height={selectedCert.cert_image_height}
                                        src='assets/avatar/avatarCert.png'
                                        style={{position: 'absolute', marginLeft: `${selectedCert.cert_image_axis_x}px`, marginTop: `${selectedCert.cert_image_axis_y}px` }}
                                    //onClick={() => onClickImgHandle()}
                                    />
                                    <span style={{
                                        position: 'absolute',
                                        marginLeft: selectedCert.holder_name_axis_x,
                                        marginTop: selectedCert.holder_name_axis_y,
                                        fontSize: selectedCert.holder_name_font_size
                                    }}>
                                        {selectedCert.cert_holder_name}
                                    </span>
                                    <span style={{
                                        position: 'absolute',
                                        marginLeft: selectedCert.cert_date_axis_x,
                                        marginTop: selectedCert.cert_date_axis_y,
                                        fontSize: selectedCert.cert_date_font_size
                                    }}>
                                        {moment(selectedCert.cert_date).format('DD MMM YYYY')}
                                    </span>
                                    <img
                                        width="800px"
                                        height="600px"
                                        src={`${API_ROOT}/certification/getfile/${selectedCert.cert_template_image}`}
                                    />
                                </div> :
                                    <div className="p-col-12 p-md-12 p-lg-8" id="viewCert" >
                                        <img
                                            width="800px"
                                            height="600px"
                                            src={`${API_ROOT}/certification/getfile/${selectedCert.cert_image_name}`}
                                        />
                                    </div>
                                }
                                <div className="p-col-12 p-md-12 p-lg-2"></div>
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
            );
            return editCert
        } else {
            return null
        }
    }

    const showCertTable = () => {
        const certTable = (
            <div className="p-col-12">
                <Panel header="รายการเหรียญตราที่ได้รับ" >
                    <DataTable
                        value={certificationList}
                        selection={selectedCert}
                        onSelectionChange={(e) => {
                            setSelectedCert(e.value)
                            setIsEdit(true)
                            setGid(e.value.gid)
                        }}
                        selectionMode="single"
                        emptyMessage={(<div className="p-text-center" style={{ color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)}>
                        <Column field="cert_template_name" header="ชื่อเหรียญ" sortable={true} />
                        <Column field="carbon_credit" header="เงื่อนไขที่ใช้ (คาร์บอนเครดิต)" sortable={true} className="p-text-center" />
                        <Column field="cert_date" header="วันที่ได้รับ" sortable={true} className="p-text-center" />
                    </DataTable>
                </Panel>
            </div>
        )
        if (!showProgress)
            return certTable;
    }

    return (
        <div className="p-grid ">
            <Growl ref={growl} />
            <div className="p-col-12">
                <h1>ใบรับรอง/เหรียญ</h1>
            </div>
            {showProgressSpinner()}
            {showCertTable()}
            {showEditCert()}
        </div>
    )
}
