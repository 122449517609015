import {
    WASTETRAN_FILE_DELETE,
    WASTEPRO_FILE_DELETE
} from '../service/actionType'

const initialState = {
    data: null,
    isFailed: false,
    isLoading: false
}

export function deleteWasteTranFile(state = initialState, action) {
    switch (action.type) {
        case WASTETRAN_FILE_DELETE.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTETRAN_FILE_DELETE.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTETRAN_FILE_DELETE.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}
export function deleteWasteproFile(state = initialState, action) {
    switch (action.type) {
        case WASTEPRO_FILE_DELETE.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTEPRO_FILE_DELETE.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTEPRO_FILE_DELETE.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}