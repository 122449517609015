import axios from 'axios'
import { API_ROOT } from '../../constants/api'
import {

} from '../../service/actionType'

export const getBadgeAdmin = ()  => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetUnread/admin`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
  }
  })
    .then(res => {
      return res.data;
    })
    .catch(error => {})
}

export const getBadgePickup = room_id  => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetUnreadByRoomId/${room_id}`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(res => {
      return res.data;
    })
    .catch(error => {
    })
}

export const getBadgeDelivery = room_id  => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetUnreadByRoomId/${room_id}`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(res => {
      return res.data;
    })
    .catch(error => {
    })
}

export const wastegenRegisWastetran = ()  => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastegen/register/wastetran`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  })
    .then((res) => {
      return res
    }).catch((error) => {
      return error
    })
}


  
