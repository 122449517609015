import React, { Component } from 'react';
import { connect } from 'react-redux';

import { WasteproOverviews } from '../wastepro-dashboard/WasteproOverviews';
import { DatatableWasteproDashView } from '../data-table/DatatableWasteproDashView';
import { DatatableDashBoardView } from '../data-table/DatatableDashBoardView';

import { userAnnounce } from '../../service/user.service';
import {  addCommaAndDotFromNumberOrStr } from '../../utils/utilFunctions';
import {
    getWasteproDashPayment,
    getWasteproDashProduct,
    getWasteproDashSummary,
    getWasteproDashOrderStat
} from '../../service/wastepro.service';
import { WasteProNewView } from '../waste-news/WasteProNewView';
import { getStatus } from '../../constants/order-status';
import { StatisticView } from '../dashboard/StatisticView';
import * as ServerAPI from '../../components/administrator/service';


class WasteproDashboard extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dummyData: [
                { order: 'ขวดแก้ว(สีชา,สีเขียว,สีขาว)', amount: 0, unit: '0 kg', price: 0 },
                { order: 'ขวดพลาสติกใส', amount: 0, unit: '0 kg', price: 0 },
                { order: 'อลูมิเนียม', amount: 0, unit: '0 kg', price: 0 },
                { order: 'กระดาษ', amount: 0, unit: '0 kg', price: 0 },
                { order: 'กล่องนม', amount: 0, unit: '0 kg', price: 0 },
            ]
        }
        this.getNewsList = this.getNewsList.bind(this);
    }

    componentDidMount() {
        this.getNewsList();
        console.log("WasteproDashboard :componentDidMount")
        this.props.dispatch(userAnnounce())
        this.props.dispatch(getWasteproDashPayment());

        // this.props.dispatch(getWasteproDashProduct());
        this.props.dispatch(getWasteproDashOrderStat());

        this.props.dispatch(getWasteproDashSummary(2));
        this.props.dispatch(getWasteproDashSummary(88));
        this.props.dispatch(getWasteproDashSummary(0));

    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("WasteproDashboard :componentWillReceiveProps")

    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("WasteproDashboard :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("WasteproDashboard :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("WasteproDashboard :componentDidUpdate")

    }

    getNewsList(){
        this.setState({ showProgress: true })
        ServerAPI.getAllNews().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    var list = [];
                    
                    res.forEach((obj,index) => {
                        let userType = []
                        let wastegen = '';
                        let wastetran = '';
                        let wastepro = '';
                        let userTypeLabel = '';
                        let newFileObj = []
                        if(obj.isWasteGen){
                            let type = {name: 'ผู้รักษ์โลก', code: '1'}
                            userType.push(type)
                            wastegen = 'ผู้รักษ์โลก'
                        }
                        if(obj.isWasteTran){
                            let type = {name: 'ทีมขนส่งซีซาเล้ง', code: '2'}
                            userType.push(type)
                            wastetran = 'ทีมขนส่งซีซาเล้ง'
                        }
                        if(obj.isWastePro){
                            let type = {name: 'ศูนย์จัดการฯซีซาเล้ง', code: '3'}
                            userType.push(type)
                            wastepro = 'ศูนย์จัดการฯซีซาเล้ง'
                        }

                        if(wastegen && wastetran && wastepro){
                            userTypeLabel = 'ทุกกลุ่ม'
                        }else{
                            userTypeLabel = wastegen+"\n"+wastetran+"\n"+wastepro
                        }

                        obj.newsImageDetails.forEach(item => {
                            let newValiable = {
                                defaultImage: item.defaultImage,
                                gid: item.gid,
                                name: item.imageName,
                                imageName: item.imageName,
                                isDeleted: item.isDeleted,
                            }
                            newFileObj.push(newValiable)
                        });

                        let arr = {
                            gid: obj.gid,
                            newsCode: obj.newsCode,
                            newsTitle: obj.newsTitle,
                            updateDate: obj.updateDate,
                            createdDate: obj.createdDate,
                            newsDetail: obj.newsDetail,

                            defaultImageIndex: obj.defaultImageIndex,
                            defaultImageName: obj.defaultImageName,
                            newsImageDetails: newFileObj,

                            userType: userType,
                            userTypeLabel: userTypeLabel,
                            isWasteGen: obj.isWasteGen,
                            isWasteTran: obj.isWasteTran,
                            isWastePro: obj.isWastePro,
                        }
                        list.push(arr)

                    });
                    console.log(list)
                    this.setState({ newsDataList: list })
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false})
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false})
            }
        }).catch((error) => {
            this.setState({ showProgress: false})
        })
    }

    render() {
        const {
            userAnnounce,
            getWasteproDashPayment,
            getWasteproDashProduct,
            getWasteproDashSummary,
            getWasteproDashOrderStat
        } = this.props

        //>ข่าวสาร
        const contentNews = (
            <WasteProNewView newsDataList = {this.state.newsDataList} />
        );

        //>Overview
        let outstanding_balance_thb = 0.00;
        let payment_amount_thb = 0.00;
        let total_payment_amount_thb = 0.00;
        if (!getWasteproDashSummary.isLoading && !getWasteproDashSummary.isFailed) {
            if (getWasteproDashPayment.data) {
                outstanding_balance_thb = getWasteproDashPayment.data.outstanding_balance_thb
                payment_amount_thb = getWasteproDashPayment.data.payment_amount_thb
                total_payment_amount_thb = getWasteproDashPayment.data.total_payment_amount_thb
            }
        }
        const contentOverview = (
            <WasteproOverviews
                outstanding_balance_thb={addCommaAndDotFromNumberOrStr(outstanding_balance_thb)}
                payment_amount_thb={addCommaAndDotFromNumberOrStr(payment_amount_thb)}
                total_payment_amount_thb={addCommaAndDotFromNumberOrStr(total_payment_amount_thb)}
            />
        );

        //>Overdue
        let dataListOverdue = []
        if (!getWasteproDashSummary.isLoading && !getWasteproDashSummary.isFailed) {
            if (getWasteproDashSummary.dataOverdue && getWasteproDashSummary.dataOverdue.length > 0) {
                for (let key in getWasteproDashSummary.dataOverdue) {
                    let entity = getWasteproDashSummary.dataOverdue[key]
                    let prepareData = {
                        order: entity.product_name,
                        amount: addCommaAndDotFromNumberOrStr(entity.price_cost),
                        unit: entity.delivery_quantity_kk ,
                        price: addCommaAndDotFromNumberOrStr(entity.price_per_unit_avg)
                    }
                    dataListOverdue.push(prepareData);
                }
            }
        }
        const contentOverdue = (
            <DatatableWasteproDashView data={dataListOverdue} header="รวมยอดสินค้าที่ค้างชำระ" />
        )

        //>Paid
        let dataListPaid = []
        if (!getWasteproDashSummary.isLoading && !getWasteproDashSummary.isFailed) {
            if (getWasteproDashSummary.dataPaid && getWasteproDashSummary.dataPaid.length > 0) {
                for (let key in getWasteproDashSummary.dataPaid) {
                    let entity = getWasteproDashSummary.dataPaid[key]
                    let prepareData = {
                        order: entity.product_name,
                        amount: addCommaAndDotFromNumberOrStr(entity.price_cost),
                        unit: entity.delivery_quantity_kk ,
                        price: addCommaAndDotFromNumberOrStr(entity.price_per_unit_avg)
                    }
                    dataListPaid.push(prepareData);
                }
            }
        }
        const contentPaid = (
            <DatatableWasteproDashView data={dataListPaid} header="รวมยอดสินค้าที่ชำระแล้ว" />
        )

        //>Products
        let dataListProducts = []
        if (!getWasteproDashSummary.isLoading && !getWasteproDashSummary.isFailed) {
            if (getWasteproDashSummary.dataProducts && getWasteproDashSummary.dataProducts.length > 0) {
                for (let key in getWasteproDashSummary.dataProducts) {
                    let entity = getWasteproDashSummary.dataProducts[key]
                    let prepareData = {
                        order: entity.product_name,
                        amount: addCommaAndDotFromNumberOrStr(entity.price_cost),
                        unit: entity.delivery_quantity_kk,
                        price: addCommaAndDotFromNumberOrStr(entity.price_per_unit_avg)
                    }
                    dataListProducts.push(prepareData);
                }
            }
        }
        const contentProducts = (
            <DatatableWasteproDashView data={dataListProducts} header="รวมยอดสินค้าทั้งหมด" />
        )

        //>statistic order
        let dataList = []
        if (!getWasteproDashProduct.isLoading && !getWasteproDashProduct.isFailed) {
            if (getWasteproDashProduct.data && getWasteproDashProduct.data.length > 0) {
                for (let key in getWasteproDashProduct.data) {
                    let entity = getWasteproDashProduct.data[key]
                    let prepareData = {
                        order: entity.product_type_name,
                        quantity: entity.quantity_kk 
                    };
                    dataList.push(prepareData);
                }
            }
        }
        console.log(dataList)
        let textHeader = (
            <span class="ui-panel-title" >
                <i class="fa fa-line-chart"></i>
                <span>สถิติการรับซื้อ</span>
            </span>
        )
        const contentStatisticOrders = (
            <DatatableDashBoardView
                data={dataList}
                header={textHeader}
            />
        )
         //> สถิติการรับงาน
         let waitDate = null;
         let waitOrder = null;
         let waitSendDate = null;
         let waitSendOrder = null;
         let closeDate = null;
         let closeOrder = null;
         let cancelDate = null;
         let cancelOrder = null;
         if (!getWasteproDashOrderStat.isLoading && !getWasteproDashOrderStat.isFailed) {
             if (getWasteproDashOrderStat.data && getWasteproDashOrderStat.data.length > 0) {
                 getWasteproDashOrderStat.data.map((value) => {
                     switch (getStatus(value.order_status).value) {
                         case 2:
                             waitDate=value.last_update_date
                             waitOrder=value.stat_count
                             break;
                         case 3:
                             waitSendDate=value.last_update_date
                             waitSendOrder=value.stat_count
                             break;
                         case 88:
                             closeDate=value.last_update_date
                             closeOrder=value.stat_count
                             break;
                         case 99:
                             cancelDate=value.last_update_date
                             cancelOrder=value.stat_count
                             break;
                     }
                 })
 
             }
         }
         const contentStatisticOrdersMain = (
             <StatisticView
                header="สถิติรับซื้อ"
                 waitDate={waitDate}
                 waitOrder={waitOrder}
                 waitSendDate={waitSendDate}
                 waitSendOrder={waitSendOrder}
                 closeDate={closeDate}
                 closeOrder={closeOrder}
                 cancelDate={cancelDate}
                 cancelOrder={cancelOrder}
             />
         );



        return (
            <div>
                
                {contentNews}
                <h1>Dashboard</h1>
                <div style={{ marginTop: '1em' }} />
                <div className="p-grid dashboard">
                    <div className="p-col-12 p-lg-8">
                        {contentOverview}
                        <div style={{ marginTop: '1em' }} />
                        {contentOverdue}
                        <div style={{ marginTop: '1em' }} />
                        {contentPaid}
                        <div style={{ marginTop: '1em' }} />
                        {contentProducts}
                    </div>
                    <div className="p-col-12 p-lg-4">
                        {/* {contentStatisticOrders} */}
                        {contentStatisticOrdersMain}
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userAnnounce: state.userAnnounce,
    getWasteproDashPayment: state.getWasteproDashPayment,
    getWasteproDashProduct: state.getWasteproDashProduct,
    getWasteproDashSummary: state.getWasteproDashSummary,
    getWasteproDashOrderStat:state.getWasteproDashOrderStat
})

export default connect(mapStateToProps)(WasteproDashboard)