
import { API_ROOT } from '../constants/api'
import axios from './axios'
import { SecureUtils } from '../utils';

import {
  WASTEGEN_ACCOUNT_BALANCE,
  WASTEGEN_ACCOUNT_WITHDRAW,
  WASTEGEN_ACCOUNT_WITHDRAW_HISTORY,
  WASTEGEN_DASH_LAST_ORDER,
  WASTEGEN_DASH_PRODUCT,
  WASTEGEN_CHANGE_PIN,
  WASTEGEN_DONATE,
  WASTEGEN_TRANSFER_DONATE,
  WASTEGEN_DONATE_HISTORY,
  WASTETGEN_REGIS_WASTETRAN
} from './actionType'

export const getBalance = () => (dispatch) => {
  dispatch({ type: WASTEGEN_ACCOUNT_BALANCE.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastegen/account/balance`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  })
    .then((res) => {
      dispatch({ type: WASTEGEN_ACCOUNT_BALANCE.SUCCESS, payload: res.data })
    }).catch((error) => {
      dispatch({ type: WASTEGEN_ACCOUNT_BALANCE.FAILURE, error })
    })
}

export const saveWithdrawal = (withdrawal_thb, bank_withdraw_code) => (dispatch) => {
  let requestParam = {
    "waste_uid": SecureUtils.getItem('uid'),
    "withdrawal_thb": withdrawal_thb,
    "bank_withdraw_code": bank_withdraw_code,
    "wasteType": 1
  };

  dispatch({ type: WASTEGEN_ACCOUNT_WITHDRAW.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastegen/account/withdraw`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    data: requestParam
  })
    .then((res) => {
      dispatch({ type: WASTEGEN_ACCOUNT_WITHDRAW.SUCCESS, payload: res.data })
    }).catch((error) => {
      if (error.response && error.response.data && error.response.data.length<50) {
        dispatch({ type: WASTEGEN_ACCOUNT_WITHDRAW.FAILURE, payload: error.response.data })
      }else{
        dispatch({ type: WASTEGEN_ACCOUNT_WITHDRAW.FAILURE, payload: 'รหัสถอนเงินไม่ถูกต้อง' })
      }
    })
}

export const getWithdrawHistory = () => (dispatch) => {
  dispatch({ type: WASTEGEN_ACCOUNT_WITHDRAW_HISTORY.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastegen/account/withdraw/history`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  })
    .then((res) => {
      dispatch({ type: WASTEGEN_ACCOUNT_WITHDRAW_HISTORY.SUCCESS, payload: res.data })
    }).catch((error) => {
      dispatch({ type: WASTEGEN_ACCOUNT_WITHDRAW_HISTORY.FAILURE, error })
    })
}

export const getWastegenDashLastOrder = () => (dispatch) => {
  dispatch({ type: WASTEGEN_DASH_LAST_ORDER.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastegen/dashboard/lastorder`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  }).then(res => {
    if (res.status == 200) {
      return res
    }
    throw Error(res.status)
  }).then((res) => {
    dispatch({ type: WASTEGEN_DASH_LAST_ORDER.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTEGEN_DASH_LAST_ORDER.FAILURE, error })
  })
}

export const getWastegenDashProduct = () => (dispatch) => {
  dispatch({ type: WASTEGEN_DASH_PRODUCT.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastegen/dashboard/product`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  }).then(res => {
    if (res.status == 200) {
      return res
    }
    throw Error(res.status)
  }).then((res) => {
    dispatch({ type: WASTEGEN_DASH_PRODUCT.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTEGEN_DASH_PRODUCT.FAILURE, error })
  })
}

export const wastegenChangePin = (
  data
) => (dispatch) => {
  dispatch({ type: WASTEGEN_CHANGE_PIN.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastegen/ChangePin`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    data: JSON.stringify(data)
  })
    .then(res => {
      if (res.status == 200) {
        return res
      }
      throw Error(res.status)
    })
    .then((res) => {
      dispatch({ type: WASTEGEN_CHANGE_PIN.SUCCESS, payload: res.data })
    }).catch((error) => {
      dispatch({ type: WASTEGEN_CHANGE_PIN.FAILURE, payload: error.response.data })
    })
}

//> OTP
export const otpResetPinSend = (mobile_no) =>{
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/otp-reset-pin/send/${mobile_no}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const otpResetPinResend = (mobile_no) =>{
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/otp-reset-pin/resend/${mobile_no}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const otpVerifyPin = (mobile_no,otp_code,otp_ref) =>{
  let data = {
      mobile_no: mobile_no,
      otp_code: otp_code,
      otp_ref: otp_ref
  }
  //console.log(data)
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/otp/verify-changepin`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') },
    data: data
  }).then((res) => {
    return res.data
  }).catch((error) => {
    return error;
  })
}

export const getWastegenDonate = () => (dispatch) => {
  dispatch({ type: WASTEGEN_DONATE.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/donate/organization/getall`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  }).then(res => {
    if (res.status == 200) {
      return res
    }
    throw Error(res.status)
  }).then((res) => {
    dispatch({ type: WASTEGEN_DONATE.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTEGEN_DONATE.FAILURE, error })
  })
}

export const wastegenTransferDonate = (
  data
) => (dispatch) => {
  dispatch({ type: WASTEGEN_TRANSFER_DONATE.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/donate/transfer`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    data: JSON.stringify(data)
  })
    .then(res => {
      if (res.status == 200) {
        return res
      }
      throw Error(res.status)
    })
    .then((res) => {
      dispatch({ type: WASTEGEN_TRANSFER_DONATE.SUCCESS, payload: res.data })
    }).catch((error) => {
      dispatch({ type: WASTEGEN_TRANSFER_DONATE.FAILURE, payload: error.response.data })
    })
}

export const getWastegenDonateHistory = () => (dispatch) => {
  dispatch({ type: WASTEGEN_DONATE_HISTORY.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastegen/account/donate/history`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  }).then(res => {
    if (res.status == 200) {
      return res
    }
    throw Error(res.status)
  }).then((res) => {
    dispatch({ type: WASTEGEN_DONATE_HISTORY.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTEGEN_DONATE_HISTORY.FAILURE, error })
  })
}

export const wastegenRegisWastetran = () => (dispatch) => {
  dispatch({ type: WASTETGEN_REGIS_WASTETRAN.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastegen/register/wastetran`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  })
    .then((res) => {
      dispatch({ type: WASTETGEN_REGIS_WASTETRAN.SUCCESS, payload: res.data })
    }).catch((error) => {
      dispatch({ type: WASTETGEN_REGIS_WASTETRAN.FAILURE, payload: error.response.data })
    })
}