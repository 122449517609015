import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import classNames from 'classnames';
import { AppTopbar } from './../AppTopbar';
import { AppInlineProfile } from './../AppInlineProfile';
import { AppFooter } from './../AppFooter';
import { AppMenu } from './../AppMenu';
import { Route } from 'react-router-dom';
import { Dashboard } from '../demo/Dashboard';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import 'primereact/resources/primereact.min.css';
// import 'fullcalendar/dist/fullcalendar.css';
import 'font-awesome/css/font-awesome.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import style from './style.css';
import { connect } from 'react-redux'

import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'
import * as ChatService from '../../service/ChatService'
import { addNewChatMessage } from '../../service/ChatService'
import { get } from 'https';
import { WasteproCreateOrder } from './WasteproCreateOrder';
import  WasteproManageOrder  from './WasteproManageOrder';
import { WasteproManageInvoice } from './WasteproManageInvoice';
import { WasteproManageTransfer } from './WasteproManageTransfer';
import { WasteproReportValidateOrder } from './WasteproReportValidateOrder';
import { WasteproReportTransfer } from './WasteproReportTransfer';
import  WasteproReportInvoice  from './WasteproReportInvoice';
import { WasteproReportBuying } from './WasteproReportBuying';
import { WasteproProfile } from './WasteproProfile';
import { WasteproInlineProfile } from './WasteproInlineProfile';
import PropsRoute from '../../PropsRoute';
import ExampleWasteproProfileCtrl from './ExampleWasteproProfileCtrl';
import ExampleWasteproChangePassword from './ExampleWasteproChangePassword';
import ExampleWasteproReportBuyingCtrl from './ExampleWasteproReportBuyingCtrl';
import ExampleWasteproNotiRecycleCtrl from './ExampleWasteproNotiRecycleCtrl';
import WasteproDashboard from './WasteproDashboard';
import WasteproManageProductPrice from './WasteproManageProductPrice';
import WasteproChatMassage from './WasteproChatMassage';
import WasteproManageAccept from './WasteproManageAccept';
import WasteproContact from './WasteproContact';
import WasteproChatAdmin from './WasteproChatAdmin';
import WasteproChatView from './WasteproChatView';
import WasteproChatWithU2 from './WasteproChatWithU2';
import WasteproNewsDetail from './WasteproNewsDetail';
import WasteproReportMaxPurchase from './WasteproReportMaxPurchase';
import WasteproReportPurchaseAmount from './WasteproReportPurchaseAmount';
import {
    connectWebsocket,
    implementWebsocket,
    closeWebsocket,
    navigateToAdminChatScene,
    navigateToUserChatScene,
    sendMessage
  } from '../../utils/chat'
import { WASTE_TYPE, WASTE_TYPE_NUM_TO_NAME } from '../../constants/mappingValue'
import { getBadgeAdmin } from './service'
import {Dialog} from 'primereact/dialog';
import { Button } from 'primereact/components/button/Button';
class WasteproContainer extends Component {
    static propTypes = {

    }

    static defaultProps = {
    }

    componentDidMount() {
        const { locationServiceProps, userServiceProps, masterServiceProps, ChatServiceProps } = this.props
        locationServiceProps.getProvice();
        userServiceProps.getUserInfo();
        locationServiceProps.getCardProvice();
        masterServiceProps.getBanks();
        ChatServiceProps.getBadgeAdmin();
    }

    componentWillReceiveProps(nextProps) {
        console.log("WasteproContainer :componentWillReceiveProps")
        if (
            nextProps.userInfo
            && !nextProps.locationMaster.isFetching
            && this.tickerLoad
        ) {
            if (nextProps.userInfo.addr_current_province_code) {
                nextProps.locationServiceProps.getDistrict(nextProps.userInfo.addr_current_province_code);
            }
            if (nextProps.userInfo.addr_current_district_code) {
                nextProps.locationServiceProps.getSubDistrict(nextProps.userInfo.addr_current_district_code);
            }
            if (nextProps.userInfo.addr_card_province_code) {
                nextProps.locationServiceProps.getCardDistrict(nextProps.userInfo.addr_card_province_code);
            }
            if (nextProps.userInfo.addr_card_district_code) {
                nextProps.locationServiceProps.getCardSubDistrict(nextProps.userInfo.addr_card_district_code);
            }
            if(nextProps.userInfo.profileStatusCode != 99){
                this.setState({userNotCompleteDialog: true})
            }
            this.tickerLoad = false;

            if (nextProps.userInfo){
                this.manageChatWebsocket(nextProps.userInfo,'wastepro')
            }else {
                closeWebsocket()
            }
        }

        if(!nextProps.chatSingleBadge.isLoading){
            getBadgeAdmin().then((res)=>{
                    this.createMenu(nextProps.userInfo,res);
                    this.setState({badge: res})
                })
            
        }
    }
    constructor() {
        super();
        this.tickerLoad = true;
        this.state = {
            layoutMode: 'static',
            profileMode: 'inline',
            layoutCompact: true,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            rotateMenuButton: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkMenu: false,
            menuActive: false,
            theme: 'green',
            layout: 'green',
            version: 'v3',
            userNotCompleteDialog: false,
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.createMenu();
    }
    manageChatWebsocket = (userInfo, userWasteType) => {
        const currentUserInfo = userInfo.user_wasteTypes.find(
          item => item.wasteType === WASTE_TYPE[userWasteType],
        )
        const senderId = currentUserInfo.uid
        const senderType = currentUserInfo.wasteType
        const onMessage = (message, currentRouteName) => {
            addNewChatMessage(message)
            getBadgeAdmin().then((res)=>{
                this.createMenu(userInfo,res);
                this.setState({badge: res})
            })
        //   if (currentRouteName === 'ChatScene') {
        //     addNewChatMessage(message)
        //   } else {
        //       console.log('fadeInMessageBanner(message)')
        //       //fadeInMessageBanner(message)
        //   }
        }
        connectWebsocket(senderId, senderType)
        implementWebsocket(onMessage)
    }

    onMenuClick(event) {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            rotateMenuButton: !this.state.rotateMenuButton,
            topbarMenuActive: false
        }));

        if (this.state.layoutMode === 'overlay') {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        }
        else {
            if (this.isDesktop())
                this.setState({ staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive });
            else
                this.setState({ staticMenuMobileActive: !this.state.staticMenuMobileActive });
        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({ activeTopbarItem: null });
        else
            this.setState({ activeTopbarItem: event.item });

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
        if (!event.item.items && this.isHorizontal()) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    onDocumentClick(event) {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

        if (!this.rightPanelClick) {
            this.setState({
                rightPanelActive: false
            })
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            rotateMenuButton: false,
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    changeTheme(theme) {
        this.setState({ theme: theme });
        if (this.state.version === 'v3') {
            this.changeStyleSheetUrl('theme-css', theme, 'theme');
        } else {
            this.changeStyleSheetUrl('theme-css', theme + '-v4', 'theme');
        }
    }

    changeLayout(layout, special) {
        this.setState({ layout: layout });
        if (this.state.version === 'v3') {
            this.changeStyleSheetUrl('layout-css', layout, 'layout');
        } else {
            this.changeStyleSheetUrl('layout-css', layout + '-v4', 'layout');
        }

        if (special) {
            this.setState({
                darkMenu: true
            })
        }
    }

    changeVersion(version) {
        this.setState({ version: version });
        if (version === 'v3') {
            this.changeStyleSheetUrl('layout-css', this.state.layout, 'layout');
            this.changeStyleSheetUrl('theme-css', this.state.theme, 'theme');
        } else {
            this.changeStyleSheetUrl('layout-css', this.state.layout + '-v4', 'layout');
            this.changeStyleSheetUrl('theme-css', this.state.theme + '-v4', 'theme');
        }
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');
        element.setAttribute('href', newURL);
    }

    createMenu(userInfo,badges) {
        if (userInfo) {
        this.menu = [
            { label: 'Dashboard', icon: 'fa fa-fw fa-home', to: '/wastepro' },
            
            { label: 'แชทกับแอดมิน', icon: 'fa fa-comments', to: '/wastepro/admin-chat', badge: badges || null  },
            //{ label: 'Chat with U2', icon: 'fa fa-comments', to: '/wastepro/chat' },
            { label: 'บันทึกรับขยะรีไซเคิล', icon: 'fa fa-fw fa-circle', to: '/wastepro/manage-orders' },
            { label: 'รายงานการรับซื้อ', icon: 'fa fa-fw fa-credit-card', to: '/wastepro/report-buying' },
            { label: 'รายงานการแจ้งหนี้', icon: 'fa fa-fw fa-credit-card', to: '/wastepro/report-invoice' },
            // { label: 'รายงานสรุปยอดการรับซื้อ', icon: 'fa fa-fw fa-credit-card', to: '/wastepro/report-purchase-amount' },
            // { label: 'รายงานการรับซื้อยอดนิยม', icon: 'fa fa-fw fa-credit-card', to: '/wastepro/report-max-purchase' },
            { label: 'จัดการราคารับซื้อ', icon: 'fa fa-fw fa-gg', to: '/wastepro/manage-product-price' },
            { label: 'ตั้งค่าการรับงาน', icon: 'fa fa-cog', to: '/wastepro/manage-accept' },
            //{ label: 'ข้อมูลการติดต่อ C3Leng', icon: 'fa fa-address-card-o', to: '/wastepro/wastepro-contact' },
            /*
             {label: 'สร้าง Order', icon: 'fa fa-fw fa-gg', to:'/wastepro/create-order'},
             {label: 'จัดการ Order', icon: 'fa fa-fw fa-gg', to:'/wastepro/manage-order'},
             {label: 'จัดการ Invoice', icon: 'fa fa-fw fa-gg', to:'/wastepro/manage-invoice'},
             {label: 'จัดการรายการโอนเงิน', icon: 'fa fa-fw fa-gg', to:'/wastepro/manage-transfer'},
             */
            /*{
               label: 'รายงาน', icon: 'fa fa-fw fa-credit-card',
               items: [
                   {label: 'รายงานการขาย', icon: 'fa fa-fw fa-bars',to: '/wastepro/report-buying'},
                  
                   {label: 'รายงานตรวจสอบ Orders', icon: 'fa fa-fw fa-bars', to: '/wastepro/report-validate-order'},
                   {label: 'สรุปเพื่อใช้เป็นข้อมูลในการโอนเงิน', icon: 'fa fa-fw fa-bars', to: '/wastepro/report-transfer'},
                   {label: 'สรุปเพื่อใช้เป็นข้อมูลแจ้งหนี้', icon: 'fa fa-fw fa-bars',to: '/wastepro/report-invoice'},
                   
                   
                   
               ]
           },*/
            // {
            //     label: 'Settings', icon: 'fa fa-fw fa-bars',
            //     items: [
            //         { label: 'Static Menu', icon: 'fa fa-fw fa-bars', command: () => this.setState({ layoutMode: 'static' }) },
            //         { label: 'Inline Profile', icon: 'fa fa-sun-o fa-fw', command: () => this.setState({ profileMode: 'inline' }) },
            //         { label: 'Top Profile', icon: 'fa fa-moon-o fa-fw', command: () => this.setState({ profileMode: 'top' }) },
            //         { label: 'Light Menu', icon: 'fa fa-sun-o fa-fw', command: () => this.setState({ darkMenu: false }) },
            //         { label: 'Dark Menu', icon: 'fa fa-moon-o fa-fw', command: () => this.setState({ darkMenu: true }) }
            //     ]
            // }
        ];
    }
    }

    redirectProfile = (event) => {
        this.setState({staticMenuMobileActive:false})
        window.location = '#/wastepro/profile';
        event.preventDefault();
        this.setState({userNotCompleteDialog: false})
    }
    changePasswordU3 = (event) => {
        this.setState({staticMenuMobileActive:false})
        window.location = '#/wastepro/change-password';
        event.preventDefault();
    }
   

    render() {
        const {
            banks,
            province,
            cardProvince,
            locationServiceProps,
            userServiceProps,
            userInfo
        } = this.props

        let layoutClassName = classNames('layout-wrapper', {
            'menu-layout-static': this.state.layoutMode !== 'overlay',
            'menu-layout-overlay': this.state.layoutMode === 'overlay',
            'layout-menu-overlay-active': this.state.overlayMenuActive,
            'menu-layout-slim': this.state.layoutMode === 'slim',
            'menu-layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-menu-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-menu-static-active': this.state.staticMenuMobileActive
        });
        let menuClassName = classNames('layout-menu-container', { 'layout-menu-dark': this.state.darkMenu });
        let userNotCompleteFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ตกลง" icon="pi pi-check" onClick={this.redirectProfile}/>
            <Button label="ปิด" icon="pi pi-times" onClick={() => this.setState({userNotCompleteDialog: false})} className="p-button-warning"/>
        </div>;

        return (
            <div className={layoutClassName} onClick={this.onDocumentClick}>
                <div>
                    <AppTopbar profileMode={this.state.profileMode} horizontal={this.isHorizontal()}
                        topbarMenuActive={this.state.topbarMenuActive} activeTopbarItem={this.state.activeTopbarItem}
                        onMenuButtonClick={this.onMenuButtonClick} onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                        onTopbarItemClick={this.onTopbarItemClick} />

                    <div className={menuClassName} onClick={this.onMenuClick}>
                        <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{ height: '100%' }}>
                            <div className="menu-scroll-content">
                                {(this.state.profileMode === 'inline' && this.state.layoutMode !== 'horizontal') && <WasteproInlineProfile redirectProfile={this.redirectProfile}
                                changePasswordU3={this.changePasswordU3}  changePinU3={this.changePinU3} {...this.props} />}
                                <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} onRootMenuItemClick={this.onRootMenuItemClick}
                                    layoutMode={this.state.layoutMode} active={this.state.menuActive} />
                            </div>
                        </ScrollPanel>
                    </div>

                    <div className="layout-main">

                        <PropsRoute path="/wastepro" exact component={WasteproDashboard} />
                        <PropsRoute path="/wastepro/admin-chat" exact component={WasteproChatAdmin} {...this.props} />
                        {/* <PropsRoute path="/wastepro/profilexx" component={WasteproProfile} {...this.props}/>
                    <PropsRoute path="/wastepro/create-order"  component={WasteproCreateOrder} />
                    
                    <PropsRoute path="/wastepro/manage-invoice"  component={WasteproManageInvoice} />
                    <PropsRoute path="/wastepro/manage-transfer"  component={WasteproManageTransfer} />
                    <PropsRoute path="/wastepro/report-validate-order"  component={WasteproReportValidateOrder} />
                    <PropsRoute path="/wastepro/report-transfer"  component={WasteproReportTransfer} />
                    <PropsRoute path="/wastepro/report-buying"  component={WasteproReportBuying} /> */}
                    <PropsRoute path="/wastepro/manage-orders"  component={WasteproManageOrder} />
                    <PropsRoute path="/wastepro/report-invoice"  component={WasteproReportInvoice} />
                    <PropsRoute path="/wastepro/report-purchase-amount"  component={WasteproReportPurchaseAmount} />
                    <PropsRoute path="/wastepro/report-max-purchase"  component={WasteproReportMaxPurchase} />
                    <PropsRoute path="/wastepro/manage-product-price"  component={WasteproManageProductPrice} />
                    <PropsRoute path="/wastepro/manage-accept"  component={WasteproManageAccept} />
                    <PropsRoute path="/wastepro/wastepro-contact"  component={WasteproContact} />
                    <PropsRoute path="/wastepro/chat"  component={WasteproChatView} />
                    <PropsRoute path="/wastepro/news-detail"  component={WasteproNewsDetail} />
                    
                        <PropsRoute path="/wastepro/profile"
                            component={ExampleWasteproProfileCtrl}
                            userInfo={this.props.userInfo}
                            province={this.props.province}
                            district={this.props.district}
                            subDistrict={this.props.subDistrict}
                            cardProvince={this.props.cardProvince}
                            cardDistrict={this.props.cardDistrict}
                            cardSubDistrict={this.props.cardSubDistrict}
                            banks={this.props.banks}
                        />

                        <PropsRoute
                            path="/wastepro/change-password"
                            component={ExampleWasteproChangePassword}
                            userInfo={this.props.userInfo}
                            province={this.props.province}
                            district={this.props.district}
                            subDistrict={this.props.subDistrict}
                            banks={this.props.banks}
                        />
                       

                        <PropsRoute
                            path="/wastepro/report-buying"
                            component={ExampleWasteproReportBuyingCtrl} />

                        <PropsRoute
                            path="/wastepro/noti-recycle"
                            component={ExampleWasteproNotiRecycleCtrl}
                            userInfo={this.props.userInfo}
                        />

                    </div>

                </div>

                <Dialog 
                    visible={this.state.userNotCompleteDialog} 
                    style={{width: '40vw'}} 
                    header="แจ้งเตือน" 
                    modal={true} 
                    footer={userNotCompleteFooter} 
                    onHide={() => this.setState({userNotCompleteDialog: false})}>
                        ข้อมูลโปรไฟล์ไม่ครบถ้วน แก้ไขข้อมูลเพื่อรับซื้อสินค้า
                </Dialog>
            </div>
        );
    }
}



const mapStateToProps = (state) => ({
    locationMaster: state.location,
    province: state.location.province,
    district: state.location.district,
    subDistrict: state.location.subDistrict,
    authentication: state.authentication,
    userInfo: state.userInfo.data,
    userInfoMaster: state.userInfo,
    updateWasteproProfile: state.updateWasteproProfile,

    cardProvince: state.location.cardProvince,
    cardDistrict: state.location.cardDistrict,
    cardSubDistrict: state.location.cardSubDistrict,

    banks: state.master.banks,

    changePassword: state.changePassword,

    getFileWastepro: state.getFileWastepro,
    chatSingleBadge: state.chatSingleBadge,
})

const mapDispatchToProps = dispatch => ({
    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    fileServiceProps: bindActionCreators(FileService, dispatch),
    ChatServiceProps: bindActionCreators(ChatService, dispatch),
})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(WasteproContainer)

