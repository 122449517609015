

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';

class ExampleCtrl extends Component {

    constructor(props) {
        console.log("ExampleCtrl :constructor")
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        console.log("ExampleCtrl :componentDidMount")
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("ExampleCtrl :componentWillReceiveProps")

    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("ExampleCtrl :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("ExampleCtrl :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("ExampleCtrl :componentDidUpdate")

    }

    // Initial function
    initial = (object) => {
        if (object) {
            for (let key in object) {
                let val = object[key]
                this.setState({ [key]: val })
            }
        }
    }

    //> Submit
    onSubmit = () => {
        this.showSuccess();
    }

    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    render() {
        console.log("ExampleCtrl :render")

        return (
            <div>
                <Growl ref={(el) => this.growl = el} />
                <h1>Profile</h1>
                <div className="p-grid">
                    {/* DETAIL */}
                    
                    <div className="p-col-12">
                        <Button
                            className="p-col-12"
                            label="บันทึก"
                            icon="pi pi-check"
                            iconPos="left"
                            onClick={this.onSubmit} />
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(ExampleCtrl)
