import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Moment from 'react-moment';
import 'moment-timezone';
import { Chips } from 'primereact/chips';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import { Growl } from 'primereact/growl';
import { Button } from 'primereact/components/button/Button';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { AutoComplete } from 'primereact/autocomplete';
import { ProgressSpinner } from 'primereact/progressspinner';
import * as LocationService from '../../service/LocationService'
import * as ReportService from './service'
import * as ServerAPI from './service';
import * as UserService from '../../service/user.service';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import {Dialog} from 'primereact/dialog';
import {MultiSelect} from 'primereact/multiselect';
import { InputText } from 'primereact/inputtext';
import { WASTE_TYPE, WASTE_TYPE_NUM_TO_NAME } from '../../constants/mappingValue'
import { Calendar } from 'primereact/calendar';

class ManageMemberContainer extends Component {
	static propTypes = {

    }
  
    static defaultProps = {
    }

    constructor() {
        super();
        this.tickerLoad = true;
        this.state = {
            dataTableValue:[],
            memberType: 0,
            memberTypeItem: [
                { label: 'เลือกทั้งหมด', value: 0 },
                { label: 'ผู้รักษ์โลก', value: 1 },
                { label: 'ทีมขนส่งซีซาเล้ง', value: 2 },
                { label: 'ศูนย์จัดการฯซีซาเล้ง', value: 3 },
                { label: 'ผู้ดูแลระบบ', value: 4 },
            ],
            selectedMemberType: null,
            uid: '',
            showProgress: true,
            showReportTable: true,
            showReportPanel: true,
            mapGenZoom: 11,
            mapGenMarker: {},
            mapGenCenter: { lat: 13.7734283, lng: 100.57383099999993 },
            mapMarker: { lat: 13.7734283, lng: 100.57383099999993 },
            locationRecycle: [
                { inputId: 1, value: "บ้านในโครงการจัดสรร" },
                { inputId: 2, value: "คอนโดมีเนียม / อพาร์ทเม้นท์" },
                { inputId: 3, value: "ร้านค้า / ร้านอาหาร" },
                { inputId: 4, value: "อื่นๆ" },
                { inputId: 5, value: "บ้านพักอาศัยทั่วไป" },
                { inputId: 6, value: "อาคารสำนักงาน / โรงงาน" },
                { inputId: 7, value: "สถานศึกษา / สถานที่ราชการ" }
            ],
            provinceChipSelected: {},
            districtChipSelected: null,
            districtsChipSelected: [],
            sendPasswordItemSelected: [],
            sendPasswordItem: [
                { label: 'เบอร์โทร', value: 1 },
                { label: 'อีเมล', value: 2 },
            ],
            isVerified: false,
            disableEmail: false,
            memberUid:null,
            fullname:null,
            remarkDetail:'',
		};
        this.toFormatDateTime = this.toFormatDateTime.bind(this);
        this.onOrderSelected = this.onMemberSelected.bind(this);
        this.showReportResult = this.showReportResult.bind(this);
        this.getMemberDetail = this.getMemberDetail.bind(this);
        this.onSelectMemberName = this.onSelectMemberName.bind(this);
        this.customChipRemove = this.customChipRemove.bind(this);
        this.onChipProvinceChanged = this.onChipProvinceChanged.bind(this);
        this.onChipDistrictChanged = this.onChipDistrictChanged.bind(this);
        this.onCheckChange = this.onCheckChange.bind(this);
        this.onMemberType = this.onMemberType.bind(this);

        this.updateSystemAllowed = this.updateSystemAllowed.bind(this);
        this.resetPassWord = this.resetPassWord.bind(this);
        this.getRemark = this.getRemark.bind(this);
        
        // this.searchUser = this.searchUser().bind(this);
    }

    componentDidMount() {
        this.searchUser();
        const { locationServiceProps ,userServiceProps} = this.props
        
        locationServiceProps.getProvice()
        userServiceProps.getUserInfo();
		
    }
    componentWillReceiveProps(nextProps) {
        if(nextProps.userInfo.data && !nextProps.userInfo.isFetching && this.tickerLoad){
            
            const currentUserInfo = nextProps.userInfo.data.user_wasteTypes.find(
                item => item.wasteType === WASTE_TYPE['administrator'])

                if(currentUserInfo.wasteType == 4){
                    this.setState({adminpermission: nextProps.userInfo.data.permission})
                }
            this.tickerLoad = false;
        }
    }

    //district dropdown
    onChipDistrictChanged(e) {
        this.setState({ districtChipSelected: e.value });
        
        var districtChipSelected = e.value;
        var isNotvalid = false;
        console.log(districtChipSelected.districtCode)
        console.log(this.state.districtsChipSelected)
        if(this.state.districtsChipSelected.length != 0){
            this.state.districtsChipSelected.forEach(districts => {
                console.log(districts.districtCode)
                console.log(districtChipSelected.districtCode)
                if(districts.districtCode == districtChipSelected.districtCode){
                    isNotvalid = true;
                }
            });
            if(isNotvalid){
                this.showWarn("ในรายการมีพื่นที่นี้อยู่แล้ว");
            }else{
                this.state.districtsChipSelected.push(districtChipSelected);
            }
        }else{
            this.state.districtsChipSelected.push(districtChipSelected);
        }

        //this.state.districtsChipSelected.push(e.value);
        this.setState({ districtCode: e.value.districtCode });
    }

    onChipProvinceChanged(e) {
        console.log(e);
        const { locationServiceProps } = this.props
        this.setState({ provinceChipSelected: e.value });
        locationServiceProps.getDistrict(e.value.provinceCode);
        this.setState({ provinceCode: e.value.provinceCode });
    }

    customChipRemove(e) {
        console.log(e.value);

        var array = this.state.districtsChipSelected; // make a separate copy of the array
        var index = array.indexOf(e.value[0])
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ districtsChipSelected: array });
        }
        console.log(array);
    }
    customChip(item) {
        return (
            <div>
                <span>{item.districtName} {item.provinceName} </span>
                <i className="pi pi-user-plus" style={{ fontSize: '14px' }}></i>
            </div>
        );
    }

	toFormatDateTime(dateTime){
        let _dateTime = new Date(dateTime);
        let year = _dateTime.getYear();

        if(year==-1899)
            return <div><span></span></div>;
        else 
        return <div><Moment format="DD/MM/YYYY">
                {dateTime}
                    </Moment><br/><Moment format="HH:mm">
                        {dateTime}
                    </Moment></div>; 
    }
	to_created_date(rowData, column){
        return this.toFormatDateTime(rowData.created_date);
    }
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12" style={{position:'absolute' , top:'50%' , left:'50%'}}>
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div className="p-col-12 p-md-8 p-lg-9"></div>);
    }
    
    //auto complate handle (ค้นหาสมาชิก)
    suggestMemberName(event) {
        console.log(event);
        
        //  if(this.state.brandSuggestions){
        //      let results = this.state.brandSuggestions.filter((brand) => {
        //          return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
        //      });
        //  }

        // this.setState({ brandSuggestions: event });
    }

    onMemberNameChanged(search_name) {
        console.log(search_name)
        console.log(typeof (search_name))
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwasteAll(search_name).then((res) => {
                        console.log(res);
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName: search_name });
                this.setState({ memberUid: null });
            }
    }
    onSelectMemberName(item) {
        console.log("onSelect")
        console.log(item)
        let searchName = item.value.search_name
        var searchNameArr = searchName.split(" ");
        var fullname = "";
        if(searchNameArr.length > 2){
            fullname = searchNameArr[2]
        }
        if(searchNameArr.length > 3){
            fullname += " "+searchNameArr[3]
        }
        // searchName.split

        this.setState({ memberName: item.value.search_name });
        this.setState({ memberUid: item.value.uid });
        this.setState({ fullname: fullname });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    itemTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }

    onMemberSelected(e) {
        this.setState({ showReportTable: false })
        this.setState({ showProgress: true })
        this.setState({ showReportPanel: false })
        console.log(e.value);
        this.setState({ memberSelection: e.value })
        this.setState({ uid: e.value.uid })
        
        this.getMemberDetail(e.value.uid)
    }

    showReportResult() {
        console.log(this.state.showReportTable)
        this.setState({ showReportTable: !this.state.showReportTable })
    }

    getMemberDetail(uid){
        ServerAPI.getProfile(uid).then((obj) => {
            console.log(obj);

            this.setState({ addressDetail: obj.addr_current_detail })
            this.setState({ provinceCode: obj.addr_current_province_code })
            this.setState({ districtCode: obj.addr_current_district_code })
            this.setState({ subDistrictCode: obj.addr_current_subdistrict_code })
            this.setState({ lineId: obj.line_id })
            this.setState({ email: obj.email })
            this.setState({ genImg: obj.img_person_name })
            this.setState({ genPhone: obj.telephone_no1 })
            this.setState({ genFname: obj.person_first_name })
            this.setState({ genLname: obj.person_last_name })
            this.setState({ personType: obj.person_type })
            this.setState({ isVerified: obj.is_verified })
            this.setState({ personPrefixCode: obj.person_prefix_code })
            this.setState({ personIdcard: obj.person_idcard })
            this.setState({ latitude: obj.latitude })
            this.setState({ longitude: obj.longitude })
            this.setState({ location_type_code: obj.location_type_code})

            if (this.state.personPrefixCode == 1) {
                this.setState({ personPrefix: 'นาย' })
            } if (this.state.personPrefixCode == 2) {
                this.setState({ personPrefix: 'นาง' })
            } if (this.state.personPrefixCode == 3) {
                this.setState({ personPrefix: 'นางสาว' })
            }
            this.setState({ orgTypeCode: obj.org_type })
            if (this.state.orgTypeCode == 1) {
                this.setState({ orgType: 'บริษัทจำกัด' })
            } if (this.state.orgTypeCode == 2) {
                this.setState({ orgType: 'ห้างหุ้นส่วน' })
            } if (this.state.orgTypeCode == 3) {
                this.setState({ orgType: 'บริษัทมหาชน' })
            } if (this.state.orgTypeCode === 4) {
                this.setState({ orgType: 'ห้างร้าน' })
            }
            this.setState({ orgName: obj.org_name })

            var genMarder = { lat: obj.latitude, lng: obj.longitude };
            this.setState({ mapGenCenter: genMarder })
            this.setState({ mapGenMarker: genMarder })
            if (this.state.mapGenZoom === 12) {
                this.setState({ mapGenZoom: 12 });
            } else {
                this.setState({ mapGenZoom: 13 });
            }

            ServerAPI.getDistrict(obj.addr_current_province_code).then((res) => {
                this.setState({ districtList: res });
                ServerAPI.getSubDistrict(obj.addr_current_district_code).then((res) => {
                    this.setState({ subDistrictList: res });
                    this.bindingProfile();
                });
            });

            this.setState({ showProgress: false });
            this.setState({ showReportPanel: true })
        }).catch((error) => {
        })
    }

    bindingProfile() {
        const {
            locationMaster,
            provinces,
        } = this.props
        if (this.state.provinceCode && !locationMaster.isFetching) {
            provinces.forEach(objProvince => {
                // console.log(JSON.stringify(objProvince));
                if (objProvince.provinceCode == this.state.provinceCode) {
                    this.setState({ provinceSelected: objProvince });
                    this.setState({ genAddressProvince: objProvince.province_name })
                }
            });
        }
        if (this.state.districtCode) {
            this.state.districtList.forEach(objDistrict => {
                if (objDistrict.districtCode == this.state.districtCode) {
                    this.setState({ districtSelected: objDistrict });
                    this.setState({ genAddressDistrict: objDistrict.districtName })
                }
            });
        }
        if (this.state.subDistrictCode) {
            this.state.subDistrictList.forEach(objSubDistrict => {
                if (objSubDistrict.subDistrictCode == this.state.subDistrictCode) {
                    this.setState({ subDistrictSelected: objSubDistrict });
                }
            });
        }
    }

    onChangeInputText = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    onCheckChange(e) {
        console.log(e);

        let showPassCheck = this.state.sendPasswordItemSelected;

        if (e.checked) {
            //his.state.sendPasswordItemSelected.checked=true;
            showPassCheck.push(e.value);
        }
        else {

            var index = this.state.sendPasswordItem.indexOf(e.value);
            //this.state.wasteTypes.check
            showPassCheck.splice(showPassCheck.indexOf(e.value), 1);
        }
        this.setState({ sendPasswordItemSelected: showPassCheck },this.isNotvalidStep2);

        var intChecked = [];
        for (let index = 0; index < showPassCheck.length; index++) {
            intChecked.push(showPassCheck[index].value);

        }

        this.setState({ sendPasswordListString: JSON.stringify(intChecked) });
        console.log(JSON.stringify(intChecked));
    }
    actionTemplate(rowData, column) {
        const {
            userInfo
        } = this.props

        return <div>
            {(this.state.adminpermission ? this.state.adminpermission.view_member:null)&&
                <Button type="button" onClick={() => this.onViewScreenClick(column.rowData)} icon="pi pi-user" label="มุมมองสมาชิก" className="p-button" style={{marginBottom: '0px'}}></Button>
            }
            {(this.state.adminpermission ? this.state.adminpermission.manage_password:null)&&
                <Button type="button" onClick={() => this.onResetPassword(column.rowData)} icon="pi pi-key" label="รีเซ็ตรหัสผ่าน" className="p-button-warning" style={{marginBottom: '0px'}}></Button>
            }
            
        </div>;
    }
    getRemark(uid){
        ServerAPI.getProfileState(uid).then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({remarkShowDetail: res[0].remark })
                    // this.clearAllDataInput();
                    this.setState({ showProgress: false })
                    // this.showSuccess();
                    
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }

    systemAllowedTemplate(rowData, column) {
        const {
            userInfo
        } = this.props
        var dissable = false;
        var label = '';
        var classname = '';
        var icons = '';
        if(rowData.is_verified == 0){
            label = 'ไม่ใช้งาน';
            classname = 'p-button-warning';
            icons = 'pi pi-times';
        }
        if(rowData.is_verified == 1){
            label = 'ใช้งาน';
            classname = 'p-button';
            icons = 'pi pi-check';
       }
       if(rowData.is_verified == 2){
            label = 'ระงับ';
            classname = 'p-button-warning';
            icons = 'pi pi-times';
            }
        if(rowData.is_verified == 3){
            label = 'บล็อก';
            classname = 'p-button-danger';
            icons = 'pi pi-times';
            }
        if(this.state.adminpermission)
        if(!this.state.adminpermission.manage_aproval){
            dissable = true
        }
        return <div>
            {/* {userInfo.data.permission.manage_aproval ? ( rowData.is_verified ?  
            <Button type="button" onClick={() => this.onSystemAllowedClick(column.rowData)} icon="pi pi-check" label="ใช้งาน" className="p-button" style={{width: '7em',marginBottom: '0px'}}></Button>
            : <Button type="button" onClick={() => this.onSystemAllowedClick(column.rowData)} icon="pi pi-times" label="ไม่ใช้งาน" className="p-button-warning" style={{width: '7em',marginBottom: '0px'}}></Button>
             ) : ( rowData.is_verified ?  
                <Button type="button" disabled onClick={() => this.onSystemAllowedClick(column.rowData)} icon="pi pi-check" label="ใช้งาน" className="p-button" style={{width: '7em',marginBottom: '0px'}}></Button>
                : <Button type="button" disabled onClick={() => this.onSystemAllowedClick(column.rowData)} icon="pi pi-times" label="ไม่ใช้งาน" className="p-button-warning" style={{width: '7em',marginBottom: '0px'}}></Button>
                 )
             }  */}
             <Button 
                type="button" 
                onClick={() => this.onSystemAllowedClick(column.rowData)} 
                icon={icons}
                label={label}
                className={classname}
                disabled={dissable}
                style={{width: '7em',marginBottom: '0px'}}>
             </Button>

        </div>;
    }

    statusMemberTypeTemplate(rowData, column) {

        let memberType = this.state.memberTypeItem
        let value;
        for (let index = 0 ; index < memberType.length;index++) {
            if(memberType[index].value === rowData.user_waste_type_id){
                value = memberType[index].label
                break;
            }
        }

        return <div>
            {value}
        </div>;
    }


    onSystemAllowedClick(e){
        console.log(e);
        this.getRemark(e.uid);
        this.setState({
            remarkDetail:null,
            displaySystemAllowedDialog: true,
            isVerified:e.is_verified,
            systemAllowedData: Object.assign({}, e),
        });
    }
    onViewScreenClick(e){
        console.log(e)
        this.setState({
            displayUserScreenDialog: true,
            userScreenData: Object.assign({}, e),
        });
    }

    onResetPassword(e){
        console.log(e)
        this.setState({
            displayResetPassDialog: true,
            resetPassData: Object.assign({}, e),
            sendPasswordItemSelected: [],
            sendPasswordListString: '',
        });

        if(e.email){
            this.setState({ disableEmail: false})
        }else{
            this.setState({ disableEmail: true})
        }
    }
    
    showSearchPanel() {
        const searchResult = this.showSearchResult();

        return (
            <div className="p-col-12">
                <Accordion multiple={true} activeIndex={[0, 1]}>
                    <AccordionTab header="เงื่อนไขค้นหาเพื่อจัดการข้อมูลสมาชิก">
                        <div className="p-grid p-justify-start" >
                            <div className="p-col-12 p-md-6 p-lg-4" style={{ alignSelf: 'center' }}>
                                <label style={{marginRight: '5px'}}>สมาชิก : </label>
                            
                                <AutoComplete
                                    value={this.state.memberName}
                                    suggestions={this.state.memberSuggestions}
                                    onSelect={this.onSelectMemberName}
                                    completeMethod={this.suggestMemberName.bind(this)}
                                    onChange={(e) => this.onMemberNameChanged(e.target.value)}
                                    itemTemplate={this.itemTemplate.bind(this)}
                                    placeholder="ระบุรหัสสมาชิก/ชื่อสมาชิก" />
                            </div>
                            <div className="p-col-12 p-md-6 p-lg-4" style={{ alignSelf: 'center' }}>
                                <label style={{marginRight: '5px'}}>ประเภทสมาชิก :</label>
                            
                                <Dropdown
                                    value={this.state.memberType}
                                    options={this.state.memberTypeItem}
                                    onChange={(e) => { this.setState({ memberType: e.value }) }}
                                >
                                </Dropdown>
                            </div>
                            <div className="p-col-12 p-lg-4"></div>

                            <div className="p-col-12 p-md-6 p-lg-4" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label style={{marginRight: '5px'}}>ช่วงวันที่ลงทะเบียน :</label>
                                <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.createStartDate} onChange={(e)=>this.setState({createStartDate: e.value})} ></Calendar>
                            </div>
                            <div className="p-col-12 p-md-6 p-lg-4" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label style={{marginRight: '5px'}}>ถึงวันที่ :</label>
                                <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.createEndDate} onChange={(e)=>this.setState({createEndDate: e.value})} ></Calendar>
                            </div> 

                            <div className="p-col-12">
                                <br/>
                                <Button
                                    style={{marginRight:"20px"}}
                                    label="ค้นหา"
                                    icon="fa fa-search"
                                    iconPos="left"
                                    onClick={(e) => this.searchUser()} />
                                <Button
                                    style={{ backgroundColor: '#f0ad4e' }}
                                    type="submit"
                                    label="ยกเลิก"
                                    onClick={(e) => this.clearSearchInput()}
                                />
                            </div>
                        </div>
                        {searchResult}
                    </AccordionTab>
                </Accordion></div>
        )
    }

    searchUser(){

        this.setState({ showProgress: true })

        console.log("Do searching user.")
        const {serverAPIProps} = this.props

        let body = {
            "user_waste_type_id": this.state.memberType,
            "user_id": this.state.memberUid,
            "name": (this.state.fullname)?this.state.fullname:this.state.memberName,
        }
        if (this.state.createStartDate) {
            //Bankok Time zone (+7hrs)
            var createStartDate = new Date(this.state.createStartDate.getTime() + 7 * 60 * 60000);
            body.created_date_start = createStartDate.toISOString();
        }
        if (this.state.createEndDate) {
            var createEndDate = new Date(this.state.createEndDate.getTime() + 7 * 60 * 60000);
            body.created_date_end = createEndDate.toISOString();
        }

        serverAPIProps.getUsersAllForManagement(body).then((res) => {
            this.setState({ showProgress: false })
        });
        
    }

    updateSystemAllowed(){

        this.setState({ showProgress: true })
        var data = {
            waste_uid : this.state.systemAllowedData.uid,
            is_verified : this.state.isVerified,
            remark : (this.state.isVerified == 1 || this.state.isVerified == 0 )? null:this.state.remarkShowDetail,
        }
        if(data.remark !== null || data.is_verified == 0 || data.is_verified == 1 ){
             ServerAPI.setProfileStateChange(data).then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                if (index === -1) {
                    this.searchUser();
                    this.setState({ displaySystemAllowedDialog:false});
                    this.showSuccess();
                    // this.setState({ showProgress: false })
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
        }else{
            this.showWarn('กรุณากรอกหมายเหตุ');
        }
       
        
    }

    resetPassWord(){

        this.setState({ showProgress: true })

        let resetType;
        if(this.state.sendPasswordItemSelected.indexOf(this.state.sendPasswordItem[0]) !== -1
            && this.state.sendPasswordItemSelected.indexOf(this.state.sendPasswordItem[1]) !== -1
        ){
            resetType = 3;
        }else if(this.state.sendPasswordItemSelected.indexOf(this.state.sendPasswordItem[0]) !== -1){
            resetType = 1;
        }else if(this.state.sendPasswordItemSelected.indexOf(this.state.sendPasswordItem[1]) !== -1){
            resetType = 2;
        }

        ServerAPI.resetpassword(this.state.resetPassData.username,resetType).then((res) => {
            if (res) {
                var index = res.toString().indexOf("Error:");
                if (index === -1) {
                    this.searchUser();
                    this.setState({ displayResetPassDialog:false});
                    this.showSuccess();
                    // this.setState({ showProgress: false })
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
        
    }

    clearSearchInput(){
        this.setState({ memberType: 0})
        this.setState({ memberUid: null})
        this.setState({ memberName: null})
        this.setState({ fullname: null})
        this.setState({ createStartDate: null})
        this.setState({ createEndDate: null})
    }
    
    onMemberType(event) {
        this.dt.filter(event.value, 'user_waste_type_id', 'in');
        this.setState({selectedMemberType: event.value});
    }

    showSearchResult(){
        const { allUserLists } = this.props
        const dialogSystemAllowedPanel = this.dialogSystemAllowedPanel();
        const dialogResetPassPanel = this.dialogResetPassPanel();
        const dialogUserScreenPanel = this.dialogUserScreenPanel();

        const memberTypeFilter = <MultiSelect style={{width:'100%'}} placeholder="ประเภทสมาชิก" 
            value={this.state.selectedMemberType} 
            options={this.state.memberTypeItem} 
            onChange={this.onMemberType} />;

        
        return (<div><div className="p-col-12" >

        <Accordion multiple={this.state.showReportTable} activeIndex={[0]} onTabChange={this.showReportResult}>
            <AccordionTab header="รายงานเรียกดูข้อมูลสมาชิก">
                <DataTable value={allUserLists} paginatorPosition="bottom" 
                    // selectionMode="single" 
                    paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                    responsive={true} selection={this.state.memberSelection} 
                    // onSelectionChange={e => this.onMemberSelected(e)}
                    emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)}
                    >
                    <Column field="username" header="ชื่อผู้ใช้" sortable={true} filter={true}
                        // filter filterPlaceholder="ชื่อผู้ใช้"
                        />
                    <Column field="telephone_no1" header="หมายเลขโทรศัพท์" sortable={true} filter={true}
                        // filter filterPlaceholder="หมายเลขโทรศัพท์"
                        />
                    <Column field="applicant_name" header="ชื่อสมาชิก" sortable={true} filter={true}
                        // filter filterPlaceholder="ชื่อสมาชิก"
                        />
                    <Column field="user_waste_type_id" body={this.statusMemberTypeTemplate.bind(this)} header="ประเภทสมาชิก" sortable={true} filter={true}
                        // filter={true} filterElement={memberTypeFilter} 
                        />
                    
                    {/* <Column field="province_name" header="จังหวัด" sortable={true} />
                    <Column field="district_name" header="อำเภอ" sortable={true} />
                    <Column field="subdistrict_name" header="ตำบล" sortable={true}/> */}
                    {/* <Column field="email" header="อีเมล​" sortable={true} /> */}
                    <Column field="is_verified" body={this.systemAllowedTemplate.bind(this)} header="จัดการผู้ใช้" className="p-text-center" style={{width: '10em' }}
                        sortable={true}/>
                    <Column field="uid" body={this.actionTemplate.bind(this)} header="จัดการ" className="p-text-center" style={{width: '20em' }} />
                </DataTable>
            </AccordionTab>
        </Accordion>
        {dialogSystemAllowedPanel}
        {dialogResetPassPanel}
        {dialogUserScreenPanel}
    </div></div>)
    }

    dialogSystemAllowedPanel(){
        let dialogApproveFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="บันทึก" icon="pi pi-check" onClick={this.updateSystemAllowed}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displaySystemAllowedDialog: false})} className="p-button-warning"/>
            </div>;
        return(
        <Dialog dismissableMask={true} visible={this.state.displaySystemAllowedDialog} 
                header="อนุญาติให้ใช้งานระบบ" modal={true} footer={dialogApproveFooter} 
                onHide={() => this.setState({displaySystemAllowedDialog: false})}
                style={{width:'30vw'}}
                >
                
                { this.state.systemAllowedData &&
                <div className="p-grid" >
                    {/* <div className="p-col-4" style={{ alignSelf: 'center' }}></div> */}
                    <div className="p-col-12" style={{ padding: '1em' }}>
                    <label style={{ marginRight: '5px' }}>อนุญาติให้ใช้งานระบบ : </label>
                    <br/>
                    <br/>
                    <div className="p-radiobutton-label">
                        <RadioButton inputId="rb2" name="isVerified" value={1} style={{marginBottom:'10px'}}
                            onChange={(e) => this.setState({ isVerified: e.value })}
                            checked={this.state.isVerified === 1} />
                        <label htmlFor="rb2" className="p-radiobutton-label">ใช้งาน</label>
                    </div>
                    <div className="p-radiobutton-label">
                        <RadioButton inputId="rb1" name="isVerified" value={0} style={{marginBottom:'10px'}}
                            onChange={(e) => this.setState({ isVerified: e.value })}
                            checked={this.state.isVerified === 0} />
                        <label htmlFor="rb1" className="p-radiobutton-label">ไม่ใช้งาน</label>
                    </div>
                    <div className="p-radiobutton-label">
                        <RadioButton inputId="rb3" name="isVerified" value={2} style={{marginBottom:'10px'}}
                            onChange={(e) => this.setState({ isVerified: e.value })}
                            checked={this.state.isVerified === 2} />
                        <label htmlFor="rb3" className="p-radiobutton-label">ระงับใช้งาน</label>
                        <br/>
                        {this.state.isVerified == 2 &&
                            <InputText className="p-inputtext-sm p-d-block p-mb-2" 
                                style={{margin:'10px' , marginLeft:'20px'}}
                                placeholder="กรอกหมายเหตุ"
                                value={this.state.remarkShowDetail} onChange={(e) => this.setState({remarkShowDetail: e.target.value})} 
                            />
                        }
                        
                    </div>
                    <div className="p-radiobutton-label">
                        <RadioButton inputId="rb4" name="isVerified" value={3} style={{marginBottom:'10px'}}
                            onChange={(e) => this.setState({ isVerified: e.value ,remarkDetail:""})}
                            checked={this.state.isVerified === 3} />
                        <label htmlFor="rb4" className="p-radiobutton-label">บล็อกผู้ใช้งาน</label>
                        <br/>
                        {this.state.isVerified == 3 &&
                        <InputText className="p-inputtext-sm p-d-block p-mb-2" 
                            style={{margin:'10px' , marginLeft:'20px'}}
                            placeholder="กรอกหมายเหตุ"
                            value={this.state.remarkShowDetail} onChange={(e) => this.setState({remarkShowDetail: e.target.value})} 
                        />
                        }
                    </div>    
                    </div>
                </div>
                }
        </Dialog>
        )
    }

    dialogUserScreenPanel(){
        let dialogUserScreenFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ยืนยัน" icon="pi pi-check" onClick={() => this.getToken()}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displayUserScreenDialog: false})} className="p-button-warning"/>
            </div>;
        return(
        <Dialog dismissableMask={true} 
            visible={this.state.displayUserScreenDialog} 
            width="300px" 
            header="มุมมองสมาชิก" 
            modal={true} 
            footer={dialogUserScreenFooter} 
            onHide={() => this.setState({displayUserScreenDialog: false})}>
                
                { this.state.userScreenData &&
                <div className="p-grid" >
                    {/* <div className="p-col-4" style={{ alignSelf: 'center' }}></div> */}
                    <div className="p-col-12" style={{ padding: '1em 5em 1em 5em' }}>
                        ต้องการไปที่หน้าจอสมาชิก ?
                    </div>
                    {this.state.getTokenfail && <div className="p-col-12" style={{ padding: '1em 5em 1em 5em' }}>
                        เกิดข้อผิดพลาด
                    </div>}
                </div>
                }
        </Dialog>
        )
    }

    dialogResetPassPanel(){
        let dialogResetPassFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="บันทึก" icon="pi pi-check" onClick={this.resetPassWord}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displayResetPassDialog: false})} className="p-button-warning"/>
            </div>;
        return(
        <Dialog dismissableMask={true} visible={this.state.displayResetPassDialog} width="300px" header="รีเซ็ตรหัสผ่าน" modal={true} footer={dialogResetPassFooter} onHide={() => this.setState({displayResetPassDialog: false})}>
                
                { this.state.resetPassData &&
                <div className="p-grid p-fluid" >

                    <div className="p-col-12">
                        เลือกช่องทางในการส่งรหัสผ่านใหม่
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6">
                        <Checkbox
                            inputId="cb1"
                            value={this.state.sendPasswordItem[0]}
                            onChange={(e) => { this.onCheckChange(e) }}
                            checked={this.state.sendPasswordItemSelected.indexOf(this.state.sendPasswordItem[0]) !== -1}/>
                        <label htmlFor="cb1" className="p-checkbox-label">เบอร์โทรศัพท์มือถือ</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{display: this.state.disableEmail?'none':'block'}}>
                        <Checkbox
                            inputId="cb2"
                             value={this.state.sendPasswordItem[1]}
                            onChange={(e) => { this.onCheckChange(e) }}
                            checked={this.state.sendPasswordItemSelected.indexOf(this.state.sendPasswordItem[1]) !== -1}
                            // disabled={this.state.disableEmail}
                            />
                        <label htmlFor="cb2" className="p-checkbox-label">อีเมล</label>
                    </div>
                </div>
                }
        </Dialog>
        )
    }
    setPasswordVisible(e) {

        this.setState({ checkedPasswordVisible: e.value })
        if (e.value) {
            this.setState({ typePasswordInput: 'text' });
        } else {
            this.setState({ typePasswordInput: 'password' });
        }
    }

    getToken(e){
        // const { adminServiceProps } = this.props
        if(this.state.userScreenData){
            ServerAPI.getToken(this.state.userScreenData.username).then((res) => {
                if(res){

                    UserService.getUserTypeFunction().then((re)=>{
                        if(re){
                            const userType = localStorage.getItem('user-type');
                            console.log(userType);
                            window.location = '#/'+userType;

                            // this.props.history.push({
                            //     pathname: '/'+userType,
                            // })
                        }
                        this.setState({getTokenfail: true })
                    });

                    
                }else{
                    this.setState({getTokenfail: true })
                }
            }).catch((error) => {
                console.log(error)
            this.setState({getTokenfail: true })
            })
        }
        
    }


    render() {
        const searchPanel = this.showSearchPanel();
        const showProgress = this.showProgressSpinner();
        
        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} />
                {searchPanel}
                {showProgress}
            </div>
        )        
    }
}



const mapStateToProps = (state) => ({
    provinces: state.location.province,
    districts: state.location.district,
    subDistricts: state.location.subDistrict,
    allUserLists: state.getUsersAllForManagement.data,
    locationMaster: state.location,
    userInfo: state.userInfo
})

const mapDispatchToProps = dispatch => ({
	locationServiceProps: bindActionCreators(LocationService, dispatch),
    reportServiceProps: bindActionCreators(ReportService, dispatch),
    serverAPIProps: bindActionCreators(ServerAPI, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch)

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ManageMemberContainer)