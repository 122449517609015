import React, { Component } from 'react';
import { Calendar } from 'primereact/calendar';
import classNames from 'classnames';
import { max } from 'moment';

class CECalendar extends Component {
    static defaultProps = {

        required:false,
        value: null,
         id:'',  
         className: null, 
         style: null,
         showIcon: true,
         showTime:true,
         hourFormat:24,
        onChange: () => { },
        isvalid: false,
        minDate: null,
        selectionMode: 'single',
        placeholder: ''
    }

    constructor(props) {
        super(props)
        this.state = { isvalid: false,value: ''}
        this.validation = this.validation.bind(this);
        this.changed = this.changed.bind(this);
        //this.showCtrl = this.showCtrl.bind(this);
        //regexp = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
        
    }
    componentDidMount() {
        this.setState({value: this.props.value})
        const _isValid = this.validation(this.props.value);
        this.props.isvalid(_isValid);
    }
   
    validation(value){
        console.log(value)
        const {required,selectionMode} = this.props;
        var _valid = true;
        let isArray = Array.isArray(value)
        if(isArray){
            if( required && selectionMode == 'range' && (value[0]==null || value[1]==null)) _valid = false;
        }else{
            if( required && (!value || value==null || value=='')) _valid = false;
        }
        return _valid;
    } 
    changed = (e) =>{
        this.setState({value:e.value})
        const _isValid = this.validation(e.value);
        this.props.isvalid(_isValid);
        this.props.onChange(e);
        
    }
    
    showCtrl(){
        
      const {required,value, id,  className, style,showIcon,onChange,showTime,hourFormat,minDate,selectionMode,placeholder} = this.props;
      const _isValid = this.validation(this.state.value);
      
      const _className = classNames(className, {
        'p-error': !_isValid
        });

     
      

      return <Calendar readOnlyInput={true} className={_className} placeholder={placeholder} dateFormat="dd/mm/yy" hourFormat={hourFormat} showIcon={showIcon} value={value} onChange={this.changed} showTime={showTime} minDate={minDate} selectionMode={selectionMode}></Calendar>
       

    }
    render(){
        const bodyContent = this.showCtrl();
      
        return (
            bodyContent
        )
    }
}

export default CECalendar