import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { closeWebsocket } from '../../utils/chat'
import classNames from 'classnames';
import * as UserService from '../../service/user.service'
import { authentication } from '../../reducer/authen.reducer';

export class AdministratorInlineProfile extends Component {

    constructor() {
        super();
        this.state = {
            expanded: true
        };

        this.onClick = this.onClick.bind(this);
        
    }

    onClick(event) {
        this.setState({ expanded: !this.state.expanded });
        event.preventDefault();
    }

   

    logout = () => {
        const { userServiceProps } = this.props
        userServiceProps.logout();
        closeWebsocket();
    }

   

    render() {
        const { userInfo } = this.props
        
        let fullName = "Administrator";
        if(userInfo){
            fullName = userInfo.person_first_name+" "+userInfo.person_last_name
        }

        if (!localStorage.getItem('user-type')) {
            window.location = "#/"
        }

        return (<div>
            <div className={classNames('profile', { 'profile-expanded': this.state.expanded })}>
                <button className="p-link" onClick={this.onClick}>
                    <img alt="Profile" className="profile-image" src="assets/layout/images/avatar.png" />
                    <span className="profile-name">{fullName}</span>
                    <i className="fa fa-fw fa-caret-down"></i>
                    <span className="profile-role">เกี่ยวกับผู้ใช้งานระบบ</span>
                </button>
            </div>

            <ul className="layout-menu profile-menu">

              
                <li role="menuitem">
                    <button
                        className="p-link"
                        tabIndex={this.state.expanded ? '3' : '-1'}
                        onClick={(this.logout.bind(true))}>
                        <i className="fa fa-fw fa-sign-out"></i>
                        <span>Logout</span>
                    </button>
                    <div className="layout-menu-tooltip">
                        <div className="layout-menu-tooltip-arrow"></div>
                        <div className="layout-menu-tooltip-text">Logout</div>
                    </div>
                </li>
            </ul>
        </div>)
    }
}


export default AdministratorInlineProfile
