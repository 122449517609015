import React, { Component } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';


export const DatatableGoodnessView = ({ data ,header}) => {
    
    header = header||"ค่าความดีของคุณ"
    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="content-section implementation">
                    <DataTable
                        value={data}
                        selectionMode="single"
                        header={header}
                        emptyMessage={
                            (<div style={{ textAlign: 'center', color: 'red' }}>
                                <label>ไม่พบข้อมูล</label>
                            </div>)
                        }>
                        <Column field="order" header="รายการ" sortable={true} />
                        <Column className="p-text-center" field="quantity" header="ปริมาณรวม (Kg)" sortable={true} />
                        <Column className="p-text-center" field="" header="carbon credit" sortable={true} />
                    </DataTable>
                </div>
            </div>
        </div>
    )
}
