import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { RadioButton } from 'primereact/radiobutton';
import { Dropdown } from 'primereact/dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Growl } from 'primereact/growl';
import { Checkbox } from 'primereact/checkbox';
import * as Validators from '../../utils/validators'
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { ToggleButton } from 'primereact/togglebutton';
import { Panel } from 'primereact/panel';
import { changePassword } from '../../service/user.service'
import { FileView } from '../../features/widget/FileView';
import { API_ROOT } from '../../constants/api'
import { SecureUtils } from '../../utils';

export class WasteproProfile extends Component {

    constructor() {
        super();
        this.state = {
            isValidateError: false,
            isLoading: true,
            isUpdate: false,
            isLoadDist: false,
            isLoadSubDist: false,
            selectedProv: null,
            selectedDistrict: null,
            selectedSubDistrict: null,
            companyTypes: [
                { name: 'บริษัทจำกัด', code: 1 },
                { name: 'ห้างหุ้นส่วน', code: 2 },
                { name: 'บริษัทมหาชน', code: 3 },
                { name: 'ห้างร้าน', code: 4 },
            ],

            personPrefixs: [
                { name: 'นาย', code: 1 },
                { name: 'นาง', code: 2 },
                { name: 'นางสาว', code: 3 },
            ],
            wasteTypesSelected: [],
            wasteTypes: [
                { label: 'ขวดแก้ว', value: 1 },
                { label: 'กระดาษ', value: 2 },
                { label: 'พลาสติก', value: 3 },
                { label: 'โลหะ/อโลหะ', value: 4 },
                { label: 'อิเล็กทรอนิกส์/เครื่องใช้ไฟฟ้า', value: 5 }
            ],
            selectedPersonPrefix: null,
            selectedBank: null,
            useCurrentAddress: false,
            marker: {},
            mapZoom: 11,
            mapCenter: { lat: 13.7734283, lng: 100.57383099999993 },
            //PASSWORD
            isShowChangePassword: false,
            isChangePassword: false,
            checkedPasswordVisible: false,
            typePasswordInput: 'password',
            errorMessageOldPassword: null,
            errorMessagePassword: null,
            errorMessageConfirmPassword: null,
            oldPassword: null,
            password: null,
            confirmPassword: null,
        }

        this.onChangeProv = this.onChangeProv.bind(this);
        this.prepareForm = this.prepareForm.bind(this);
        this.onFileIDCardChanged = this.onFileIDCardChanged.bind(this);
        this.onFileRegisChanged = this.onFileRegisChanged.bind(this);
        this.onFilePersonChanged = this.onFilePersonChanged.bind(this);
        this.onFileShopChanged = this.onFileShopChanged.bind(this);
        this.prepareWasteTypes = this.prepareWasteTypes.bind(this);
        this.setUid = this.setUid.bind(this);
        this.setAddrCardUseCurrent = this.setAddrCardUseCurrent.bind(this);
        this.onMarkerMoved = this.onMarkerMoved.bind(this);
    }

    componentDidMount() {
        const { userServiceProps } = this.props
        userServiceProps.getUserInfo();

        this.setState({ isLoading: true })
    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("WastegenProfile :componentWillReceiveProps")
    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("WastegenProfile :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("WastegenProfile :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("WastegenProfile :componentDidUpdate")

    }

    // ========= PREPARE =========
    prepareForm(object) {
        if (object) {
            for (let key in object) {
                let val = object[key]
                this.setState({ [key]: val })
            }

            this.setState({ isLoading: false })
            this.setState({ marker: { lat: object.latitude, lng: object.longitude } })
        }
    }

    setAddrCardUseCurrent(userInfo) {
        if (userInfo && userInfo.addr_card_use_current && userInfo.addr_card_use_current === 1) {
            this.setState({ useCurrentAddress: true })
        }
    }

    setUid(userInfo) {
        if (userInfo && userInfo.user_wasteTypes) {
            for (let key in userInfo.user_wasteTypes) {
                this.setState({ uid: userInfo.user_wasteTypes[key].uid })
            }
        }
    }

    // ========= validate =========
    transformDropdrown = () => {

        const { banks, province, district, subDistrict,
            cardProvince, cardDistrict, cardSubDistrict,
            locationServiceProps, locationMaster } = this.props

        //person_prefix_code default :: check dropdown
        if (!this.state.selectedPersonPrefix && this.state.person_prefix_code) {
            for (let key in this.state.personPrefixs) {
                let val = this.state.personPrefixs[key]
                if (this.state.person_prefix_code == val.code) {
                    this.setState({ selectedPersonPrefix: val })
                }
            }
        }

        //person_prefix_code default :: check dropdown
        if (this.state.org_type) {
            for (let key in this.state.companyTypes) {
                let val = this.state.companyTypes[key]
                if (this.state.org_type == val.code) {
                    this.setState({ org_type: val })
                }
            }
        }

        //bank_code default :: check dropdown
        if (!this.state.selectedBank && this.state.bank_code) {
            for (let key in banks) {
                let val = banks[key]
                if (this.state.bank_code == val.bank_code) {
                    this.setState({ selectedBank: val })

                }
            }
        }

        //province default :: check dropdown
        if (!this.state.selectedProv && this.state.addr_card_province_code && !locationMaster.isFetching) {
            for (let key in cardProvince) {
                let val = cardProvince[key]
                if (this.state.addr_card_province_code == val.provinceCode) {
                    //console.log("province ")
                    this.setState({ selectedProv: val })
                    locationServiceProps.getCardDistrict(val.provinceCode)
                }
            }
        }
        //District default :: check dropdown
        if (!this.state.selectedDistrict && this.state.addr_card_district_code && !locationMaster.isFetching) {
            for (let key in cardDistrict) {
                let val = cardDistrict[key]
                if (this.state.addr_card_district_code == val.districtCode) {
                    //console.log("district ",val)
                    this.setState({ selectedDistrict: val })
                    locationServiceProps.getCardSubDistrict(val.districtCode)
                }
            }
        }
        //District default :: check dropdown
        if (!this.state.selectedSubDistrict && this.state.addr_card_subdistrict_code && !locationMaster.isFetching) {
            cardSubDistrict.map((val) => {
                if (this.state.addr_card_subdistrict_code == val.subDistrictCode) {
                    //console.log("subDistrict ",val)
                    this.setState({ selectedSubDistrict: val })
                }
            })
            for (let key in cardSubDistrict) {
                const val = cardSubDistrict[key]

            }
        }

        //province default :: check dropdown
        if (!this.state.selectedCurrentProv && this.state.addr_current_province_code && !locationMaster.isFetching) {
            for (let key in province) {
                let val = province[key]
                if (this.state.addr_current_province_code == val.provinceCode) {
                    //console.log("province ")
                    this.setState({ selectedCurrentProv: val })
                    locationServiceProps.getDistrict(val.provinceCode)
                }
            }
        }
        //District default :: check dropdown
        if (!this.state.selectedCurrentDistrict && this.state.addr_current_district_code && !locationMaster.isFetching) {
            for (let key in district) {
                let val = district[key]
                if (this.state.addr_current_district_code == val.districtCode) {
                    //console.log("district ",val)
                    this.setState({ selectedCurrentDistrict: val })
                    locationServiceProps.getSubDistrict(val.districtCode)
                }
            }
        }
        //District default :: check dropdown
        if (!this.state.selectedCurrentSubDistrict && this.state.addr_current_subdistrict_code && !locationMaster.isFetching) {
            subDistrict.map((val) => {
                if (this.state.addr_current_subdistrict_code == val.subDistrictCode) {
                    //console.log("subDistrict ",val)
                    this.setState({ selectedCurrentSubDistrict: val })
                }
            })
            for (let key in subDistrict) {
                const val = subDistrict[key]

            }
        }

    }

    // ========= Event =========

    onChanageBank = (e) => {
        const { name, value } = e.target
        this.setState({ selectedBank: value })
        this.setState({ [name]: value.bank_code })
    }

    onChangePersonPrefix = (e) => {
        const { name, value } = e.target
        this.setState({ selectedPersonPrefix: value })
        this.setState({ [name]: value.code })
    }

    onFileIDCardChanged(e) {
        this.setState({ fileImgIden: e.files[0] });

    }
    onFileRegisChanged(e) {
        this.setState({ fileImgRegis: e.files[0] });
    }
    onFilePersonChanged(e) {
        this.setState({ fileImgPerson: e.files[0] });
    }
    onFileShopChanged(e) {
        this.setState({ fileImgShop: e.files[0] });
    }

    onChangeInputText = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }

    onChangeProv = (e) => {
        const { locationServiceProps } = this.props
        const { value } = e.target
        this.setState({ selectedProv: value })
        this.setState({ addr_card_province_code: value.provinceCode });

        locationServiceProps.getCardDistrict(value.provinceCode);
        e.preventDefault();
    }

    onChangeDistrict = (e) => {

        const { locationServiceProps } = this.props
        const { value } = e.target
        this.setState({ selectedDistrict: value })
        this.setState({ addr_card_province_code: value.provinceCode });

        locationServiceProps.getCardSubDistrict(value.districtCode);
        e.preventDefault();
    }


    onChangeSubDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedSubDistrict: value })
        this.setState({ addr_card_province_code: value.provinceCode });
        this.setState({ addr_card_district_code: value.districtCode });
        this.setState({ addr_card_subdistrict_code: value.subDistrictCode });

        e.preventDefault();
    }

    onChangeCurrentProv = (e) => {
        const { locationServiceProps } = this.props
        const { value } = e.target
        this.setState({ selectedCurrentProv: value })
        this.setState({ addr_current_province_code: value.provinceCode });

        locationServiceProps.getDistrict(value.provinceCode);
        e.preventDefault();
    }

    onChangeCurrentDistrict = (e) => {

        const { locationServiceProps } = this.props
        const { value } = e.target
        this.setState({ selectedCurrentDistrict: value })
        this.setState({ addr_current_province_code: value.provinceCode });

        locationServiceProps.getSubDistrict(value.districtCode);
        e.preventDefault();
    }


    onChangeCurrentSubDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedCurrentSubDistrict: value })
        this.setState({ addr_current_province_code: value.provinceCode });
        this.setState({ addr_current_district_code: value.districtCode });
        this.setState({ addr_current_subdistrict_code: value.subDistrictCode });

        e.preventDefault();
    }

    prepareWasteTypes(userInfo) {

        if (userInfo && userInfo.wasteTypeList) {
            let showPassCheck = [...this.state.wasteTypesSelected];

            for (let key in userInfo.wasteTypeList) {
                let v = userInfo.wasteTypeList[key] - 1;
                let cc = this.state.wasteTypes;
                showPassCheck.push(this.state.wasteTypes[v]);

            }

            this.setState({ wasteTypesSelected: showPassCheck });

            var intChecked = [];
            for (let index = 0; index < showPassCheck.length; index++) {
                intChecked.push(showPassCheck[index].value);

            }

            this.setState({ wasteTypeListString: JSON.stringify(intChecked) });
            console.log(JSON.stringify(intChecked));
        }
    }

    onCheckChange(e) {

        let showPassCheck = [...this.state.wasteTypesSelected];

        if (e.checked) {
            //his.state.wasteTypesSelected.checked=true;
            showPassCheck.push(e.value);
        }
        else {

            var index = this.state.wasteTypes.indexOf(e.value);
            // console.log( "index: "+index);
            //this.state.wasteTypes.check
            showPassCheck.splice(showPassCheck.indexOf(e.value), 1);
        }
        this.setState({ wasteTypesSelected: showPassCheck });

        var intChecked = [];
        for (let index = 0; index < showPassCheck.length; index++) {
            intChecked.push(showPassCheck[index].value);

        }

        this.setState({ wasteTypeListString: JSON.stringify(intChecked) });
        console.log(JSON.stringify(intChecked));
    }

    onCenterChanged = (coor) => {
        if(coor){
            this.setState({ latitude: coor.lat })
            this.setState({ longitude: coor.lng })
            this.setState({ marker: { lat: coor.lat, lng: coor.lng } })
           
        }
       
    }
    onMarkerMoved(e) {
        console.log(e);
        var coor = e.latLng.toJSON();
        console.log(coor);
        this.setState({ latitude: coor.lat });
        this.setState({ longitude: coor.lng });
    }

    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }

    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'สถานะ', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }


    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }
    // ======== Validate data =========
    validateRequire = () => {
        console.log("validateRequire ", this.state)
        this.setState({ isValidateError: false })
        this._validateLogic(this.state.telephone_no1)
        if (this.state.person_type == '1') {
            this._validateLogic(this.state.person_prefix_code)
            this._validateLogic(this.state.person_first_name)
            this._validateLogic(this.state.person_last_name)
        } else {
            this._validateLogic(this.state.org_type)
            this._validateLogic(this.state.org_name)
        }

        if (this.state.person_type == '1') {
            if (this.state.person_prefix_code < 1 || Validators.isEmpty(this.state.person_first_name) ||
                Validators.isEmpty(this.state.person_last_name)) {
                this.setState({ isValidateError: true })
            }

        } else if (this.state.person_type == '2') {
            if (this.state.org_type < 1 || Validators.isEmpty(this.state.org_name) || Validators.isEmpty(this.state.org_code)) {
                this.setState({ isValidateError: true })
            }

        }

        // if (this.state.wasteTypesSelected.length < 1) {
        //     this.setState({ isValidateError: true })
        // }

        //Password
        if (this.state.isChangePassword) {
            console.log("old ", this.state.oldPassword, " pass ", this.state.password, " confirm ", this.state.confirmPassword)
            this._validateLogic(this.state.oldPassword);
            this._validateLogic(this.state.password);
            this._validateLogic(this.state.confirmPassword);
        }
    }

    _validateLogic = (value) => {
        if (Validators.isEmpty(value)) {
            this.setState({ isValidateError: true })
        }
    }
    // ======== SAVE =========
    onSubmit = (e) => {
        const { userServiceProps } = this.props
        const formData = new FormData();

        console.log("this.state.person_type : " + this.state.person_type);
        let validate = true;
        if (this.state.person_type == '1') {
            if (this.state.person_prefix_code < 1 || Validators.isEmpty(this.state.person_first_name) || Validators.isEmpty(this.state.person_last_name)) {
                this.showWarn()
                validate = false
            }

        } else if (this.state.person_type == '2') {
            if (this.state.org_type < 1 || Validators.isEmpty(this.state.org_name) || Validators.isEmpty(this.state.org_code)) {
                this.showWarn()
                validate = false
            }

        }

        // if (this.state.wasteTypesSelected.length < 1) {
        //     this.showWarn();
        //     validate = false
        // }


        if (validate ) {
            if (this.state.isChangePassword) {
                var jsPass = {
                    username: SecureUtils.getItem('user').username,
                    oldPassword: this.state.oldPassword,
                    newPassword: this.state.password,
                    confirmPassword: this.state.confirmPassword,
                    statusMessage: "string",
                }
                userServiceProps.changePassword(JSON.parse(JSON.stringify(jsPass)))
            }

            formData.append('FileImgIden', this.state.fileImgIden);
            formData.append('FileImgRegis', this.state.fileImgRegis);
            formData.append('FileImgPerson', this.state.fileImgPerson);
            formData.append('FileImgShop', this.state.fileImgShop);
            formData.append('area_rai', this.state.area_rai || 0);
            formData.append('area_ngan', this.state.area_ngan || 0);
            formData.append('area_square_wah', this.state.area_square_wah || 0);
            formData.append('vehicle_type', this.state.vehicle_type || 0);
            formData.append('vehicle_no', this.state.vehicle_no);
            formData.append('vehicle_img_name', this.state.vehicle_img_name);
            formData.append('bank_account_name', this.state.bank_account_name);
            formData.append('bank_account_no', this.state.bank_account_no);
            formData.append('bank_code', this.state.bank_code);
            formData.append('bank_promptpay', this.state.bank_promptpay);
            formData.append('bank_withdraw_code', this.state.bank_withdraw_code);
            // formData.append('wasteTypeList', this.state.wasteTypesSelected);
            formData.append('wasteTypeListString', this.state.wasteTypeListString);
            formData.append('uid', this.state.uid);
            formData.append('person_type', this.state.person_type || 0);
            formData.append('person_prefix_code', this.state.person_prefix_code || 0);
            formData.append('person_first_name', this.state.person_first_name);
            formData.append('person_last_name', this.state.person_last_name);
            formData.append('person_idcard', this.state.person_idcard);
            formData.append('org_type', this.state.org_type || 0);
            formData.append('org_name', this.state.org_name);
            formData.append('org_code', this.state.org_code || 0);
            formData.append('telephone_no1', this.state.telephone_no1);
            formData.append('telephone_no2', this.state.telephone_no2 || '');
            formData.append('line_id', this.state.line_id || '');
            formData.append('email', this.state.email);
            formData.append('addr_card_province_code', (this.state.useCurrentAddress) ? this.state.addr_current_province_code : this.state.addr_card_province_code || 0);
            formData.append('addr_card_district_code', (this.state.useCurrentAddress) ? this.state.addr_current_district_code : this.state.addr_card_district_code || 0);
            formData.append('addr_card_subdistrict_code', (this.state.useCurrentAddress) ? this.state.addr_current_subdistrict_code : this.state.addr_card_subdistrict_code || 0);
            formData.append('addr_card_detail', (this.state.useCurrentAddress) ? this.state.addr_current_detail : this.state.addr_card_detail);
            formData.append('addr_current_province_code', this.state.addr_current_province_code || 0);
            formData.append('addr_current_district_code', this.state.addr_current_district_code || 0);
            formData.append('addr_current_subdistrict_code', this.state.addr_current_subdistrict_code || 0);
            formData.append('addr_current_detail', this.state.addr_current_detail);
            formData.append('latitude', this.state.latitude || 0);
            formData.append('longitude', this.state.longitude || 0);
            formData.append('img_iden_name', this.state.img_iden_name);
            formData.append('img_regis_org_name', this.state.img_regis_org_name);
            formData.append('img_person_name', this.state.img_person_name);
            formData.append('update_date', this.state.update_date);
            formData.append('update_by_uid', this.state.update_by_uid);
            formData.append('addr_card_use_current', (this.state.useCurrentAddress) ? 1 : 0);
            // userServiceProps.updateWasteproProfile(this.state)
            
            for (var pair of formData.entries()) {
                console.log(pair[0]+ ', ' + pair[1]); 
            }
            userServiceProps.updateWasteproProfile(formData)

            this.setState({ isUpdate: true })
            this.setState({ isLoading: true })
            this.setState({ isShowChangePassword: false })
        } else {
            this.showWarn();
        }

    }

    // === PASSWORD ===
    onOldPasswordChanged = (password) => {
        console.log(password)
        this.setState({ errorMessageOldPassword: null });

        if (Validators.isNotEmpty(password)) {
            if (password == SecureUtils.getItem('user').password) {
                this.setState({ errorMessageOldPassword: "" });
                this.setState({ errorMessagePassword: "กรุณาระบุรหัสผ่าน" });
                this.setState({ errorMessageConfirmPassword: "กรุณาระบุรหัสผ่าน" });
                this.setState({ isChangePassword: true })
                this.setState({ oldPassword: password })
            } else {
                this.setState({ errorMessageOldPassword: "รหัสผ่านไม่ตรงกัน" });
                this.setState({ isChangePassword: false })
                this.setState({ isValidateError: true })
            }
        } else {
            this.setState({ isChangePassword: false })
        }
    }

    onPasswordChanged = (password) => {
        this.setState({ errorMessagePassword: null });

        if (password.length >= 6) {
            const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
            const isOk = re.test(password);
            if (!isOk) {
                this.setState({ errorMessagePassword: "ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่" });
                this.setState({ isValidateError: true })
            } else {
                this.setState({ password: password });
                this.setState({ errorMessagePassword: "" });

                if (password == this.state.confirmPassword) {
                    this.setState({ errorMessageConfirmPassword: "" });
                } else {
                    this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
                    this.setState({ isValidateError: true })
                }
            }
        } else {
            this.setState({ errorMessagePassword: "กรุณากรอกอย่างน้อย 6 ตัวอักษร (ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่)" });
            this.setState({ isValidateError: true })
        }
    }

    onConfirmPasswordChanged = (cfpassword) => {
        this.setState({ errorMessageConfirmPassword: "" });
        console.log("isChangePassword ", this.state.isChangePassword, "pass ", this.state.password, " cfpass ", cfpassword)
        if (this.state.password == cfpassword) {
            this.setState({ confirmPassword: cfpassword });
            this.setState({ errorMessageConfirmPassword: "" });
        } else {
            this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
            this.setState({ confirmPassword: cfpassword });
            this.setState({ isValidateError: true })
        }
    }

    setPasswordVisible = (e) => {

        this.setState({ checkedPasswordVisible: e.value })
        if (e.value) {
            this.setState({ typePasswordInput: 'text' });
        } else {
            this.setState({ typePasswordInput: 'password' });
        }
    }

    // === PASSWORD ===
    onOldPasswordChanged = (password) => {
        console.log(password)
        this.setState({ errorMessageOldPassword: null });

        if (Validators.isNotEmpty(password)) {
            if (password == localStorage.getItem('oldpass')) {
                this.setState({ errorMessageOldPassword: "" });
                this.setState({ errorMessagePassword: "กรุณาระบุรหัสผ่าน" });
                this.setState({ errorMessageConfirmPassword: "กรุณาระบุรหัสผ่าน" });
                this.setState({ isChangePassword: true })
                this.setState({ oldPassword: password })
            } else {
                this.setState({ errorMessageOldPassword: "รหัสผ่านไม่ตรงกัน" });
                this.setState({ isChangePassword: false })
                this.setState({ isValidateError: true })
            }
        } else {
            this.setState({ isChangePassword: false })
        }
    }

    onPasswordChanged = (password) => {
        this.setState({ errorMessagePassword: null });

        if (password.length >= 6) {
            const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
            const isOk = re.test(password);
            if (!isOk) {
                this.setState({ errorMessagePassword: "ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่" });
                this.setState({ isValidateError: true })
            } else {
                this.setState({ password: password });
                this.setState({ errorMessagePassword: "" });

                if (password == this.state.confirmPassword) {
                    this.setState({ errorMessageConfirmPassword: "" });
                } else {
                    this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
                    this.setState({ isValidateError: true })
                }
            }
        } else {
            this.setState({ errorMessagePassword: "กรุณากรอกอย่างน้อย 6 ตัวอักษร (ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่)" });
            this.setState({ isValidateError: true })
        }
    }

    onConfirmPasswordChanged = (cfpassword) => {
        this.setState({ errorMessageConfirmPassword: "" });
        console.log("isChangePassword ", this.state.isChangePassword, "pass ", this.state.password, " cfpass ", cfpassword)
        if (this.state.password == cfpassword) {
            this.setState({ confirmPassword: cfpassword });
            this.setState({ errorMessageConfirmPassword: "" });
        } else {
            this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
            this.setState({ confirmPassword: cfpassword });
            this.setState({ isValidateError: true })
        }
    }

    setPasswordVisible = (e) => {

        this.setState({ checkedPasswordVisible: e.value })
        if (e.value) {
            this.setState({ typePasswordInput: 'text' });
        } else {
            this.setState({ typePasswordInput: 'password' });
        }
    }

    // ========= RENDER =========
    render() {
        const { locationMaster, userInfoMaster, userInfo, province, district, subDistrict, updateWasteproProfile, locationServiceProps,
            cardProvince, cardDistrict, cardSubDistrict, banks } = this.props

        const { isValidateError } = this.state
        //Validate class error require
        const classNameRequired = isValidateError == true ? "p-error" : "";

        if (this.state.isLoading && !userInfoMaster.isLoading && !updateWasteproProfile.isLoading) {
            if (this.state.isUpdate) {
                this.prepareForm(updateWasteproProfile.data)
                this.prepareWasteTypes(updateWasteproProfile.data);
                this.setUid(updateWasteproProfile.data);
                this.setAddrCardUseCurrent(updateWasteproProfile.data);
                this.setState({ isUpdate: false })
                this.showSuccess();
            } else {
                this.prepareForm(userInfo)
                this.prepareWasteTypes(userInfo);
                this.setUid(userInfo);
                this.setAddrCardUseCurrent(userInfo);
            }

        }

        //> loading all page
        let formInfomation = (
            <div>Loading...</div>
        )
        let contentBank = (
            <div>Loading...</div>
        )
        let contentContact = (
            <div>Loading...</div>
        )
        let contentLocation = (
            <div>Loading...</div>
        )
        let fileImg = (
            <div>Loading...</div>
        )
        let profileAddress = (
            <div>Loading...</div>
        )

        let contentLogin = (
            <div>Loading...</div>
        )

        if (!userInfoMaster.isLoading && !updateWasteproProfile.isLoading) {

            //> Transform Dropdown to Object selected
            this.transformDropdrown();
            // console.log(this.state)

            if (this.state.isLoadSubDist && subDistrict.length == 0) {
                let distCode = this.state.addr_current_district_code;
                //console.log("dist code ,",distCode)
                this.setState({ isLoadSubDist: false })
            }

            const personType = (
                <div className="p-grid">
                    <div className="p-col-12" style={{ textAlign: 'left' }}>
                        <label className="required">คำนำหน้าชื่อ</label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown
                            className={classNameRequired}
                            name="person_prefix_code"
                            value={this.state.selectedPersonPrefix}
                            options={this.state.personPrefixs}
                            onChange={this.onChangePersonPrefix}
                            placeholder="เลือกคำนำหน้า"
                            optionLabel="name"
                        />
                    </div>

                    <div style={{ marginTop: '.5em' }}></div>

                    <div className="p-col-12">
                        <label className="required">ชื่อ</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className={classNameRequired}
                            type="text"
                            className="input-100"
                            value={this.state.person_first_name}
                            name="person_first_name"
                            placeholder="First Name"
                            onChange={this.onChangeInputText} />
                    </div>

                    <div className="p-col-12">
                        <label className="required">นามสกุล </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className={classNameRequired}
                            type="text"
                            className="input-100"
                            name="person_last_name"
                            placeholder="Last Name"
                            value={this.state.person_last_name}
                            onChange={this.onChangeInputText} />
                    </div>

                    <div className="p-col-12">
                        <label>เลขบัตรประชาชน</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            keyfilter="int"
                            className="input-100"
                            placeholder="Thai ID"
                            name="person_idcard"
                            value={this.state.person_idcard}
                            onChange={this.onChangeInputText}
                            required="true"
                        ></InputText>
                    </div>
                </div>
            );

            const legalType = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label className="required">ประเภทนิติบุคคล</label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown
                            className={classNameRequired}
                            name="org_type"
                            value={this.state.org_type}
                            options={this.state.companyTypes}
                            onChange={this.onChangeInputText}
                            placeholder="เลือกประเภทนิติบุคคล"
                            optionLabel="name" />
                        <div style={{ marginTop: '.5em' }}></div>
                    </div>

                    <div className="p-col-12">
                        <label className="required">ชื่อนิติบุคคล</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className={classNameRequired + " p-col-12"}
                            type="text"
                            placeholder="ชื่อนิติบุคคล"
                            value={this.state.org_name}
                            name="org_name"
                            onChange={this.onChangeInputText} />
                    </div>
                    <div className="p-col-12">
                        <label className="required">เลขนิติบุคคล</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className={classNameRequired + " p-col-12"}
                            placeholder="Company Id"
                            name="org_code"
                            maxlength="13" keyfilter="pint"
                            value={this.state.org_code}
                            onChange={this.onChangeInputText}
                        ></InputText>
                    </div>

                </div>

            );

            //> ข้อมูลสมาชิก
            formInfomation = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label>ประเภทสมาชิก</label>
                    </div>
                    <div className="p-col-6">
                        <RadioButton inputId="rb1"
                            name="person_type" value="1"
                            onChange={this.onChangeInputText}
                            checked={this.state.person_type == '1'} />
                        <label htmlFor="rb1" className="p-radiobutton-label">บุคคลธรรมดา</label>

                    </div>
                    <div className="p-col-6">
                        <RadioButton inputId="rb2"
                            name="person_type" value="2"
                            onChange={this.onChangeInputText}
                            checked={this.state.person_type == '2'} />
                        <label htmlFor="rb2" className="p-radiobutton-label">นิติบุคคล</label>
                    </div>

                    <div className="p-col-12">
                        {this.state.person_type == '1' && personType}
                        {this.state.person_type == '2' && legalType}
                    </div>


                    <div className="p-col-12">
                        <label>หมายเลขโทรศัพท์</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            keyfilter="int"
                            className="input-100"
                            placeholder="Phone"
                            name="telephone_no1"
                            value={this.state.telephone_no1}
                            onChange={this.onChangeInputText}></InputText>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Phone"
                            name="telephone_no2"
                            value={this.state.telephone_no2}
                            onChange={this.onChangeInputText}></InputText>
                    </div>
                    <div className="p-col-12">
                        <label>Line ID</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Line Id"
                            name="line_id"
                            value={this.state.line_id}
                            onChange={this.onChangeInputText}></InputText>
                    </div>
                    <div className="p-col-12">
                        <label>Email</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Email"
                            name="email"
                            value={this.state.email}
                            onChange={this.onChangeInputText}></InputText>
                    </div>
                    {/* <div className="p-col-12">
                        <label>ประเภทสินค้าที่รับซื้อ <span style={{ color: 'red' }}>*</span></label>
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox
                            inputId="cb1"
                            value={this.state.wasteTypes[0]}
                            onChange={(e) => { this.onCheckChange(e) }}
                            checked={this.state.wasteTypesSelected.indexOf(this.state.wasteTypes[0]) !== -1}>
                        </Checkbox>
                        <label htmlFor="cb1" className="p-checkbox-label">ขวดแก้ว</label>
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox
                            inputId="cb2"
                            value={this.state.wasteTypes[1]}
                            onChange={(e) => { this.onCheckChange(e) }}
                            checked={this.state.wasteTypesSelected.indexOf(this.state.wasteTypes[1]) !== -1}>
                        </Checkbox>
                        <label htmlFor="cb2" className="p-checkbox-label">กระดาษ</label>
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox
                            inputId="cb3"
                            value={this.state.wasteTypes[2]}
                            onChange={(e) => { this.onCheckChange(e) }}
                            checked={this.state.wasteTypesSelected.indexOf(this.state.wasteTypes[2]) !== -1}>
                        </Checkbox>
                        <label htmlFor="cb3" className="p-checkbox-label">พลาสติก</label>
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox
                            inputId="cb4"
                            value={this.state.wasteTypes[3]}
                            onChange={(e) => { this.onCheckChange(e) }}
                            checked={this.state.wasteTypesSelected.indexOf(this.state.wasteTypes[3]) !== -1}>
                        </Checkbox>
                        <label htmlFor="cb4" className="p-checkbox-label">โลหะ/อโลหะ</label>
                    </div>
                    <div className="p-col-12 p-md-12 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox
                            inputId="cb5"
                            value={this.state.wasteTypes[4]}
                            onChange={(e) => { this.onCheckChange(e) }}
                            checked={this.state.wasteTypesSelected.indexOf(this.state.wasteTypes[4]) !== -1}>
                        </Checkbox>
                        <label htmlFor="cb5" className="p-checkbox-label">อิเล็กทรอนิกส์/เครื่องใช้ไฟฟ้า</label>
                    </div> */}
                </div>
            );

            //> รายละเอียดบัญชีธนาคาร
            contentBank = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label >ชื่อบัญชี</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Account Name"
                            name="bank_account_name"
                            value={this.state.bank_account_name}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                    <div className="p-col-12">
                        <label >เลขที่บัญชี</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            keyfilter="int"
                            className="input-100"
                            placeholder="Account Number"
                            name="bank_account_no"
                            maxlength="13" keyfilter="pint"
                            value={this.state.bank_account_no}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                    <div className="p-col-12">
                        <label >ธนาคาร</label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="bank_name"
                            name="bank_code"
                            value={this.state.selectedBank}
                            onChange={this.onChanageBank}
                            options={banks}
                            placeholder="-- selected --" />
                    </div>

                    <div className="p-col-12">
                        <label >Prompt-pay</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            keyfilter="int"
                            className="input-100"
                            placeholder="Prompt-pay"
                            name="bank_promptpay"
                            maxlength="13" keyfilter="pint"
                            value={this.state.bank_promptpay}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                    <div className="p-col-12">
                        <label >รหัสเลขใช้สำหรับแจ้งถอนเงิน</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Pin Number"
                            name="bank_withdraw_code"
                            keyfilter="pint"
                            value={this.state.bank_withdraw_code}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>


                </div>
            );

            //> รายละเอียดที่อยู่ติดต่อ
            contentContact = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label >
                            จังหวัด
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="province_name"
                            value={this.state.selectedCurrentProv}
                            options={province}
                            onChange={this.onChangeCurrentProv}
                            placeholder="-- selected --" />

                    </div>


                    <div className="p-col-12">
                        <label >
                            อำเภอ/เขต
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="districtName"
                            value={this.state.selectedCurrentDistrict}
                            options={district}
                            onChange={this.onChangeCurrentDistrict}
                            placeholder="-- selected --"
                            disabled={!district} />

                    </div>

                    <div className="p-col-12">
                        <label >
                            ตำบล/แขวง
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="subDistrictName"
                            value={this.state.selectedCurrentSubDistrict}
                            options={subDistrict}
                            onChange={this.onChangeCurrentSubDistrict}
                            placeholder="-- selected --" />

                    </div>

                    <div className="p-col-12">
                        <label >
                            เลขที่บ้าน/ที่อยู่
                    </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Address ID"
                            name="addr_current_detail"
                            value={this.state.addr_current_detail}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                    <div className="p-col-12">
                        <label >
                            ขนาดพื้นที่
                    </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            keyfilter="int"
                            className="input-100"
                            placeholder="ไร่"
                            name="area_rai"
                            value={this.state.area_rai}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            keyfilter="int"
                            className="input-100"
                            placeholder="งาน"
                            name="area_ngan"
                            value={this.state.area_ngan}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            keyfilter="num"
                            className="input-100"
                            placeholder="ตารางวา"
                            name="area_square_wah"
                            value={this.state.area_square_wah}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>
                </div>
            );

            //> ที่อยู่ตามบัตรประชาชน/ ที่อยู่นิติบุคคล
            profileAddress = (
                <div className="p-grid">
                    <div className="p-col-12 " style={{ textAlign: 'left' }}>
                        <Checkbox checked={this.state.useCurrentAddress} onChange={e => this.setState({ useCurrentAddress: e.checked })} />
                        <label htmlFor="pro_cb" className="p-checkbox-label">เหมือนที่อยู่ที่ตั้งร้าน/ที่ตั้งบริษัท</label>
                    </div>
                    <div className="p-col-12">
                        <label >
                            จังหวัด
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="province_name"
                            value={(this.state.useCurrentAddress) ? this.state.selectedCurrentProv : this.state.selectedProv}
                            options={cardProvince}
                            onChange={this.onChangeProv}
                            placeholder="-- selected --"
                            disabled={this.state.useCurrentAddress} />

                    </div>

                    <div className="p-col-12">
                        <label >
                            อำเภอ/เขต
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="districtName"
                            value={(this.state.useCurrentAddress) ? this.state.selectedCurrentDistrict : this.state.selectedDistrict}
                            options={(this.state.useCurrentAddress) ? district : cardDistrict}
                            onChange={this.onChangeDistrict}
                            placeholder="-- selected --"
                            disabled={!cardDistrict || this.state.useCurrentAddress} />

                    </div>

                    <div className="p-col-12">
                        <label >
                            ตำบล/แขวง
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="subDistrictName"
                            value={(this.state.useCurrentAddress) ? this.state.selectedCurrentSubDistrict : this.state.selectedSubDistrict}
                            options={(this.state.useCurrentAddress) ? subDistrict : cardSubDistrict}
                            onChange={this.onChangeSubDistrict}
                            placeholder="-- selected --"
                            disabled={this.state.useCurrentAddress} />

                    </div>

                    <div className="p-col-12">
                        <label >
                            เลขที่บ้าน/ที่อยู่
                    </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="input-100"
                            placeholder="Address ID"
                            name="addr_card_detail"
                            value={(this.state.useCurrentAddress) ? this.state.addr_current_detail : this.state.addr_card_detail}
                            disabled={this.state.useCurrentAddress}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                </div>
            );


            fileImg = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <Panel header="รูปถ่ายบัตรประชาชน / หนังสือรับรอง">

                            <FileView src={`${API_ROOT}/wastepro/getfile/${this.state.img_iden_name}`} />
                            <FileUpload name="fileIDCard[]"
                                onSelect={(e) => this.onFileIDCardChanged(e)}
                                mode="basic"
                                chooseLabel="แนบไฟล์"
                                cancelLabel="ยกเลิก"
                                multiple={false}
                                accept="image/*,application/pdf"
                                maxFileSize={10000000} />
                        </Panel>


                    </div>
                    <div className="p-col-12">
                        <Panel header="กรณีเป็นนิติบุคคลรูปถ่าย ภพ20">
                            <FileView src={`${API_ROOT}/wastepro/getfile/${this.state.img_regis_org_name}`} />
                            <FileUpload name="fileRegis[]"
                                onSelect={(e) => this.onFileRegisChanged(e)}
                                mode="basic"
                                chooseLabel="แนบไฟล์"
                                cancelLabel="ยกเลิก"
                                multiple={false}
                                accept="image/*,application/pdf"
                                maxFileSize={10000000} />
                        </Panel>
                    </div>
                    <div className="p-col-12">
                        <Panel header="รูปถ่ายสมาชิก">
                            <FileView src={`${API_ROOT}/wastepro/getfile/${this.state.img_person_name}`} />
                            <FileUpload name="filePerson[]"
                                onSelect={(e) => this.onFilePersonChanged(e)}
                                mode="basic"
                                chooseLabel="แนบไฟล์"
                                cancelLabel="ยกเลิก"
                                multiple={false}
                                accept="image/*,application/pdf"
                                maxFileSize={10000000} />
                        </Panel>
                    </div>
                    <div className="p-col-12">
                        <Panel header="รูปถ่ายหน้าร้าน">
                            <FileView src={`${API_ROOT}/wastepro/getfile/${this.state.vehicle_img_name}`} />
                            <FileUpload name="fileShop[]"
                                onSelect={(e) => this.onFileShopChanged(e)}
                                mode="basic"
                                chooseLabel="แนบไฟล์"
                                cancelLabel="ยกเลิก"
                                multiple={false}
                                accept="image/*"
                                maxFileSize={10000000} />
                        </Panel>
                    </div>

                </div>
            );

            //> Location google address
            contentLocation = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label >
                       
                            Address location (latitude: {this.state.latitude} longitude:{this.state.longitude})
                        </label>
                    </div>
                    <div className="p-col-12">
                        <MapWithASearchBox
                            zoom={this.state.mapZoom}
                            center={this.state.marker}
                            marker={this.state.marker}
                            onCenterChanged={this.onCenterChanged}
                            onMarkerMoveEnd={this.onMarkerMoved} />
                    </div>
                </div>
            );
            //> ข้อมูล Login
            contentLogin = (
                <div className="p-grid">
                    <Growl ref={(el) => this.growl = el} />
                    <div className="p-col-12">
                        <label className="required">
                            รหัสผ่านเดิม
                    </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            name="oldPassword"
                            type={this.state.typePasswordInput}
                            className={"input-100 " + classNameRequired}
                            placeholder="Old Password" autoComplete="new-password"
                            onChange={(e) => this.onOldPasswordChanged(e.target.value)}
                        >
                        </InputText>
                        <span style={{ color: 'red' }}>{this.state.errorMessageOldPassword} </span>
                    </div>
                    <div className="p-col-12">
                        <label className="required">
                            รหัสผ่านใหม่
                </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            name="password"
                            className={"input-100 " + classNameRequired}
                            placeholder="New Password"
                            type={this.state.typePasswordInput}
                            onChange={(e) => this.onPasswordChanged(e.target.value)}>
                        </InputText>
                        {this.state.isChangePassword &&
                            <span style={{ color: 'red' }}>{this.state.errorMessagePassword} </span>
                        }
                    </div>

                    <div className="p-col-12">
                        <label className="required">
                            ยืนยันรหัสผ่าน
                </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            name="confirmPassword"
                            className={"input-100 " + classNameRequired}
                            placeholder="Confirm Password"
                            type={this.state.typePasswordInput}
                            onChange={(e) => this.onConfirmPasswordChanged(e.target.value)}
                        >
                        </InputText>

                        {this.state.isChangePassword &&
                            <span style={{ color: 'red' }}>{this.state.errorMessageConfirmPassword} </span>
                        }
                    </div>

                    <div className="p-col-12 p-md-3 p-lg-3" >

                        <ToggleButton style={{
                            width: '150px'
                            , marginTop: '5px'
                            , backgroundColor: '#f0ad4e'
                            , color: '#000000'
                            , borderColor: '#eea236'
                        }}
                            className="p-button-secondary"
                            onLabel="ซ่อนรหัสผ่าน"
                            offLabel="แสดงรหัสผ่าน"
                            onIcon="fa fa-eye-slash"
                            offIcon="fa fa-eye"
                            checked={this.state.checkedPasswordVisible}
                            onChange={(e) => this.setPasswordVisible(e)}
                        />


                    </div>

                </div>
            );
        }//End Tax check User Info

        return (
            <div>
                <h1>Profile</h1>
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">

                    <div className="p-col-12 p-lg-6">

                    </div>
                    <div className="p-col-12 p-lg-6">

                    </div>

                    <div className="p-col-12 p-lg-6">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>ข้อมูลสมาชิก</h1>
                                {formInfomation}
                            </div>
                        </div>


                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>ที่ตั้งร้าน/ที่ตั้งบริษัท</h1>
                                {contentContact}
                            </div>
                        </div>
                        <div className="p-col-12">

                            <div className="card card-w-title">
                                <h1>ที่อยู่ตามบัตรประชาชน/ ที่อยู่นิติบุคคล</h1>
                                {profileAddress}
                            </div>
                        </div>

                        {<div className="p-lg-12  p-md-12  p-sm-12">
                            <div className="card card-w-title">
                                <h1>เปลี่ยนรหัสผ่าน <Checkbox
                                    checked={this.state.isShowChangePassword}
                                    onChange={(e) => this.setState({ isShowChangePassword: e.checked })}
                                /></h1>
                                {this.state.isShowChangePassword && contentLogin}
                            </div>
                        </div>}
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>แนบเอกสารประกอบ</h1>
                                {fileImg}
                            </div>
                        </div>

                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>Location google address</h1>
                                {contentLocation}
                            </div>
                        </div>

                        <div className="p-col-12">

                            <div className="card card-w-title">
                                <h1>รายละเอียดบัญชีธนาคาร</h1>
                                {contentBank}
                            </div>
                        </div>
                    </div>


                </div>
                <div className="p-col-12">
                    <Button
                        className="p-col-12"
                        label="Save"
                        onClick={this.onSubmit}>

                    </Button>
                </div>


            </div>

        )
    }
}


