

import React, { useState } from 'react';

import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Chips } from 'primereact/chips';


export const ChatWastegenView = ({ valueDd, listDd, onChangeDd, valueMessage, onChangeMessage, onSubmit, classNameRequired,
    valueMemberName, onChangeMemberName, onAddMember, customChipRemove, valueChips, valueProvince, provinceList, onChangedProvinceDb,
    valueDistrict, districtList, onChangedDistrictDb }) => {
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            return onsubmit
        }
    }
    const showChatMassage = (
       
            <div className="p-panal p-component no-pad">
                <div className="p-toggleble-content"
                    aria-hidden="false"
                    role="region"
                    id="pr_id_6_content"
                    aria-labelledby="pr_id_6_header">
                    {/* <div class=" p-panel-content" style={{ maxHeight: '450px' }}></div> */}
                        <ul>
                            <li class="clearfix message-from">
                                <img alt="Contact 2" src="assets/layout/images/avatar.png"></img>
                                <span>"ยินดีให้บริการครับ"</span>
                            </li>
                            <li class="clearfix message-own">
                                <img alt="User" src="assets/layout/images/avatar3.png"></img>
                                <span>"ค่ะ ขอบคุณมากๆค่ะ"</span>
                            </li>
                            <li class="clearfix message-from">
                                <img alt="Contact 2" src="assets/layout/images/avatar.png"></img>
                                <span>"login เข้าสู่ระบบ ไปที่หน้า profile เลื่อนลงมาข้างล่างสุด จะเห็นข้อความเปลี่ยนรหัสผ่าน ให้เช็คถูก แล้วทำตามขั้นตอนได้เลยครับ"</span>
                            </li>
                            <li class="clearfix message-own">
                                <img alt="User" src="assets/layout/images/avatar3.png"></img>
                                <span>"จะเปลี่ยนรหัสผ่านได้ยังไงคะ"</span>
                            </li>
                            <li class="clearfix message-from">
                                <img alt="Contact 2" src="assets/layout/images/avatar.png"></img>
                                <span>"สวัสดีครับ จะสอบถามเรื่องอะไรครับ"</span>
                            </li>
                            <li class="clearfix message-own">
                                <img alt="User" src="assets/layout/images/avatar3.png"></img>
                                <span>"สวัสดีค่ะ มีเรื่องจะสอบถามหน่อยค่ะ"</span>
                            </li>
                            
                        </ul>
                        {/* <div class="new-message" >
                            <div class="message-attachment">
                                <i class="fa fa-paperclip"></i>
                            </div>
                            <div class="message-input">
                                <input type="text" placeholder="Write a message" class="p-inputtext"></input>
                            </div>
                        </div> */}
                    
                </div>
            </div>
        
    )
    const header = (
        <div>แชทติดต่อสอบถามผู้ดูแลระบบ</div>
    )

    return (
        <Panel header={header}>
            <div className="p-grid p-fluid" >
                {/* ROW2 */}
                <div className="p-col-12 chat">
                    {showChatMassage}
                </div>
                <div className="p-col-12">
                    <div className="p-inputgroup">
                        <Button icon="fa fa-paperclip"
                                className="p-button-defual" />
                       
                            
                        <InputText placeholder="ระบุข้อความ" />
                        <Button icon="fa fa-paper-plane"
                                className="p-button-defual" />
                            
                    </div>
                </div>

            </div>
        </Panel>
    )
}


