import axios from 'axios'
import { API_ROOT } from '../../constants/api'
import {

} from '../../service/actionType'

export const autoComplateOrderID = (code) =>{
  
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastetran/order/autocomplete/${code}`,
    headers: {'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}   

export const searchOrder = (formData) =>{
  return axios({
        method: 'post',
        url: `${API_ROOT}/wastetran/order/search`,
        data: formData,
        headers: {'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
      })
      .then(function (res) {
        return res.data;
      })
      .catch(function (error) {
        return error;
      });
}

export const getOrderDetail = (code) =>{
  
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastetran/order/getbycode/${code}`,
    headers: { 
      'Content-Type': 'application/json' ,
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}  

export const updateOrder = (formData) =>{
  return axios({
        method: 'post',
        url: `${API_ROOT}/wastetran/order/update`,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data',
                  Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
      })
      .then(function (res) {
        return res.data;
      })
      .catch(function (error) {
        return error;
      });
}

export const updateWorkingStatus = (formData) =>{
  return axios({
        method: 'post',
        url: `${API_ROOT}/wastetran/profile/updateworkingstatus`,
        data: formData,
        headers: {'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
      })
      .then(function (res) {
        return res.data;
      })
      .catch(function (error) {
        return error;
      });
}

export const getBadgeAdmin = ()  => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetUnread/admin`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
  }
  })
    .then(res => {
      return res.data;
    })
    .catch(error => {})
}

export const getBadgePickup = room_id  => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetUnreadByRoomId/${room_id}`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(res => {
      return res.data;
    })
    .catch(error => {
    })
}

export const getBadgeDelivery = room_id  => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetUnreadByRoomId/${room_id}`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(res => {
      return res.data;
    })
    .catch(error => {
    })
}

export const wastetranRegisWastegen = ()  => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastetran/register/wastegen`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  })
    .then((res) => {
      return res
    }).catch((error) => {
      return error
    })
}

  
