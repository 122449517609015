import React, { Component } from 'react';
import classNames from 'classnames';
import AuthenService from '../../service/authenService';
import { API_ROOT } from '../../constants/api';
import { getFile } from '../../utils/utilFunctions'
import { closeWebsocket } from '../../utils/chat'

export class WasteproInlineProfile extends Component {

    constructor() {
        super();
        this.state = {
            isLoadAvatar: false,
            isLoading: true,
            expanded: true,
            fileImgRegis: null
        };
        this.onClick = this.onClick.bind(this);
        this.redirectProfile = this.redirectProfile.bind(this);
    }

    // ========= Initial : props to state =========
    initial = (object) => {
        if (object) {
            for (let key in object) {
                let val = object[key]
                this.setState({ [key]: val })
            }
            this.setState({ isLoading: false })
        }
    }

    onClick(event) {
        this.setState({ expanded: !this.state.expanded });
        event.preventDefault();
    }

    redirectProfile = (event) => {
        this.props.redirectProfile(event)
    }
    changePasswordU3 = (event) => {
        this.props.changePasswordU3(event)
    }

    logout = () => {
        const { userServiceProps } = this.props
        userServiceProps.logout();
        closeWebsocket();
    }

    swapToAdmin = () => {

        var token=localStorage.getItem('second-token');

        localStorage.removeItem('second-token')

        localStorage.setItem('access-token', token);
        localStorage.setItem('user-type', 'administrator');

        if(this.state.fromChat){
            window.location = '#/administrator/chat';
        }else{
            window.location = '#/administrator/manage-members';
        }
    }

    render() {
        const {
            userInfo,
            fileServiceProps,
            getFileWastepro
        } = this.props

        let fullName = "Loading...";
        let imageProfile = (
            <img alt="Profile" className="profile-image" src="assets/avatar/user.png" />
        );

        if (this.state.isLoading) {
            if (userInfo) {
                this.initial(userInfo);
                this.setState({ isLoadAvatar: true })
            }
            if(this.props.location.search == '?from=chat'){
                this.setState({ fromChat: true})
            }
        }

        if(userInfo){
            if(userInfo.person_type==1){
                if (userInfo.person_first_name && userInfo.person_last_name) {
                    fullName = userInfo.person_first_name + " " + userInfo.person_last_name
                }
            }else{
                fullName = userInfo.org_name
            }
        }

        if (this.props.userInfo) {
            if (!this.props.userInfo.img_person_name) {
                imageProfile = (
                    <img alt="Profile" className="profile-image" src="assets/avatar/user.png" />
                );
            } else {
                imageProfile = (
                    <img alt="Profile" className="profile-image image-cropper" src={`${API_ROOT}/wastepro/getfile/${this.props.userInfo.img_person_name}`} />

                );
            }
        }

        const contentProfile = (
            <div className={classNames('profile', { 'profile-expanded': this.state.expanded })}>
                <button className="p-link" onClick={this.onClick}>
                    {imageProfile}
                    <span className="profile-name">{fullName}</span>
                    <i className="fa fa-fw fa-caret-down"></i>
                    <span className="profile-role">ศูนย์จัดการฯซีซาเล้ง</span>
                </button>
            </div>
        );

        if (!localStorage.getItem('user-type')) {
            window.location = "#/"
        }

        let secondToken = localStorage.getItem('second-token');

        return <div>
            {contentProfile}

            <ul className="layout-menu profile-menu">

                {(secondToken)?
                    <li role="menuitem">
                        <button
                            className="p-link"
                            tabIndex={this.state.expanded ? '2' : '-1'}
                            onClick={(this.swapToAdmin.bind(true))}>
                            <i className="fa fa-fw fa-reply-all"></i>
                            <span>กลับหน้า Admin</span>
                        </button>
                        <div className="layout-menu-tooltip">
                            <div className="layout-menu-tooltip-arrow"></div>
                            <div className="layout-menu-tooltip-text">กลับหน้า Admin</div>
                        </div>
                    </li>
                    :''
                } 

                {(secondToken)?'':
                    <li role="menuitem">
                        <button className="p-link"
                            tabIndex={this.state.expanded ? '2' : '-1'}
                            onClick={(this.logout.bind(true))}
                        >
                            <i className="fa fa-fw fa-sign-out"></i>
                            <span>Logout</span>
                        </button>
                        <div className="layout-menu-tooltip">
                            <div className="layout-menu-tooltip-arrow"></div>
                            <div className="layout-menu-tooltip-text">Logout</div>
                        </div>
                    </li>
                }
                <li role="menuitem">
                    <button className="p-link" tabIndex={this.state.expanded ? '3' : '-1'}
                        onClick={this.redirectProfile}>
                        <i className="fa fa-fw fa-user"></i>
                        <span>Profile</span>
                    </button>

                    <div className="layout-menu-tooltip">
                        <div className="layout-menu-tooltip-arrow"></div>
                        <div className="layout-menu-tooltip-text">Profile</div>
                    </div>
                </li>

                {(secondToken)?'':
                    <li role="menuitem">
                        <button className="p-link" tabIndex={this.state.expanded ? '4' : '-1'}
                            onClick={this.changePasswordU3}>
                            <i className="pi pi-key"></i>
                            <span>เปลี่ยนรหัสผ่าน</span>
                        </button>

                        <div className="layout-menu-tooltip">
                            <div className="layout-menu-tooltip-arrow"></div>
                            <div className="layout-menu-tooltip-text">เปลี่ยนรหัสผ่าน</div>
                        </div>
                    </li>
                }
            </ul>
        </div>
    }
}