import axios from 'axios'
import { API_ROOT } from '../../constants/api'
import {

} from '../../service/actionType'
import { map, without, orderBy} from 'lodash'

export const getChatMessageByRoom = (roomId, pageSize, timeStamp) =>{
  
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetByRoomId/${roomId}/${pageSize}/${timeStamp}`,
    headers: { 
      'Content-Type': 'application/json' ,
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}  

export const loadMoreChatMessage = (roomId, pageSize, timeStamp) =>{
  
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetByRoomId/${roomId}/${pageSize}/${timeStamp}`,
    headers: { 
      'Content-Type': 'application/json' ,
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
} 

export const sendChatImage = (image, roomId, handleResult) =>{
  console.log(image)
  const formData = new FormData()
  const file = image;
  formData.append('file', file)
  formData.append('room_id', roomId)
  return axios({
        method: 'post',
        url: `${API_ROOT}/chatmessage/PostImage`,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data',
                  Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
      })
      .then(function (res) {
        return handleResult(true, res.data)
      })
      .catch(function (error) {
        return handleResult(false);
      });
}



export const addNewChatMessage = message => {
  return message
}

export const getImageChatMessage = (roomId, message) =>{
  
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetImage/${roomId}/${message}`,
    headers: { 
      'Content-Type': 'application/json' ,
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    console.log(res)
    return res.data;
  }).catch((error) => {
    return error;
  })
} 

export const updateReadAll = roomId => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/ReadAll/${roomId}`,
    headers: { 
      'Content-Type': 'application/json' ,
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then(res => {
    return res.data;
  })
  .catch(error => {
    return error
  })
}

export const getBadgeAdmin = () => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetUnread/admin`,
    headers: { 
      'Content-Type': 'application/json' ,
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  })
    .then(res => {
      return res
    })
    .catch(error => {
      return error
    })
}

export const updateUsersAll = (chatList, roomId, type, ws) => dispatch => {
  if(chatList) {
    var chatListOfId = map(chatList, function(o) {
      if (o.last_chat.room_id == roomId) return o;
    })
    var chatListById = without(chatListOfId, undefined)[0]
    var chatListNew = without(chatList, chatListById)
    if(chatList.length > 0) {
      if(type == 0) {
        chatListById.last_chat.count_unread = 0
      }
      if(type == 1) {
        chatListById.last_chat.count_unread += 1
        chatListById.last_chat.message_time  = ws.send_datetime
      }
    }
    chatListNew.unshift(chatListById)
    return orderBy(chatListNew, 'last_chat.message_time', 'desc')
  }
}

export const getToken = (username) => {
  console.log("USERNAME : " + username)
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/gettoken/${username}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }).then((res) => {
    var token = localStorage.getItem('access-token');

    localStorage.setItem('second-token', token);
    localStorage.setItem('access-token', res.data);

    return true;
  }).catch((error) => {
    console.log(error)
    return false;
  })
}