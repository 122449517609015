import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';

import { InputText } from 'primereact/components/inputtext/InputText';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { MapMarkers } from '../../features/map/MapMarkers'
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { Avatar } from '../../features/widget/Avatar';
import { API_ROOT } from '../../constants/api'
import { ProgressSpinner } from 'primereact/progressspinner';
import * as ServerAPI from './service';
import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'
import * as DashBoardService from './service'
import { isEmpty } from '../../utils/validators';
import { CEInputText, CECalendar, CEDropdown } from '../../features/input';
import { CEFileUpload } from '../../features/widget';
import { Dialog } from 'primereact/dialog';

class ManageAdminMember extends Component {
    static propTypes = {

    }
    constructor() {
        super();
        this.state = {
            // value1: 'ผู้ดูแลระบบประจำลำดับที่ 1',
            // value2: 'ผู้ดูแลระบบประจำลำดับที่ 2',
            // value3: 'ผู้ดูแลระบบประจำลำดับที่ 3',
            addressDetail: '',
            priceList: [],
            manageList: [],

            memberStore: 0,
            brandSuggestions: null,

            levelAdministrator: 0,
            levelAdmin: [
                { label: 'เลือกระดับผู้ดูแล', value: 0 },
                { label: 'ระดับที่ 1', value: 1 },
                { label: 'ระดับที่ 2', value: 2 },
                { label: 'ระดับที่ 3', value: 3 },
            ],

            reportSearchOrders: [],
            showProgress: false,

        };
        this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
        this.onMemberNameChanged = this.onMemberNameChanged.bind(this);
        this.onMemberName1Changed = this.onMemberName1Changed.bind(this);
        this.onSelectMemberName = this.onSelectMemberName.bind(this);
        this.onSelectMemberName1 = this.onSelectMemberName1.bind(this);
        this.addDataToTable = this.addDataToTable.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.onValidation = this.onValidation.bind(this);
        this.searchProductList = this.searchProductList.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.onWeightUpdate = this.onWeightUpdate.bind(this);
        this.onproductTypeUpdate = this.onproductTypeUpdate.bind(this);
        this.getAdminPermission = this.getAdminPermission.bind(this);
        

    }
    componentDidMount() {
        this.getAdminPermission();
        const { dashBoardServiceProps, locationServiceProps, masterServiceProps } = this.props
        dashBoardServiceProps.getDashboardUsers();
        locationServiceProps.getProvice();
        masterServiceProps.getProductType();
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div></div>);
    }


    searchProductList() {
        this.setState({ showProgress: true })
        var search = {
            u3_uid: this.state.memberUid1 || '',
            product_code: this.state.searchProductCode || '',
            product_type_code: this.state.searchProductTypeCode || '',
        }

        ServerAPI.searchSalePriceByU3(search).then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({ priceList: res })
                    // this.getprocessfee();
                    // this.clearAllDataInput();
                    this.setState({ showProgress: false })
                    this.showSuccess();

                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }


    getAdminPermission() {
        this.setState({ showProgress: true })
        ServerAPI.getAdminPermission().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({
                        value1_gid : res.admin_lvl_1.gid,
                        value1_role_name : res.admin_lvl_1.role_name,
                        value1_role_key : res.admin_lvl_1.role_key,
                        value1_manage_administrator : res.admin_lvl_1.manage_administrator,
                        value1_manage_aproval : res.admin_lvl_1.manage_aproval,
                        value1_manage_delivery_fee : res.admin_lvl_1.manage_delivery_fee,
                        value1_manage_invoice : res.admin_lvl_1.manage_invoice,
                        value1_manage_order : res.admin_lvl_1.manage_order,
                        value1_manage_password : res.admin_lvl_1.manage_password,
                        value1_manage_process_fee : res.admin_lvl_1.manage_process_fee,
                        value1_manage_sale_price : res.admin_lvl_1.manage_sale_price,
                        value1_manage_transfer : res.admin_lvl_1.manage_transfer,
                        value1_view_member : res.admin_lvl_1.view_member,
                        value1_view_report : res.admin_lvl_1.view_report,
                        //new update value1
                        value1_chat : res.admin_lvl_1.chat,
                        value1_create_order : res.admin_lvl_1.create_order,
                        value1_manage_certificate : res.admin_lvl_1.manage_certificate,
                        value1_manage_env_account : res.admin_lvl_1.manage_env_account,
                        value1_manage_faq : res.admin_lvl_1.manage_faq,
                        value1_manage_master : res.admin_lvl_1.manage_master,
                        value1_manage_member : res.admin_lvl_1.manage_member,
                        value1_manage_news : res.admin_lvl_1.manage_news,
                        value1_manage_reward : res.admin_lvl_1.manage_reward,
                        value1_view_donate : res.admin_lvl_1.view_donate,
                        value1_view_user_list : res.admin_lvl_1.view_user_list,

                        value2_gid : res.admin_lvl_2.gid,
                        value2_role_name : res.admin_lvl_2.role_name,
                        value2_role_key : res.admin_lvl_2.role_key,
                        value2_manage_administrator : res.admin_lvl_2.manage_administrator,
                        value2_manage_aproval : res.admin_lvl_2.manage_aproval,
                        value2_manage_delivery_fee : res.admin_lvl_2.manage_delivery_fee,
                        value2_manage_invoice : res.admin_lvl_2.manage_invoice,
                        value2_manage_order : res.admin_lvl_2.manage_order,
                        value2_manage_password : res.admin_lvl_2.manage_password,
                        value2_manage_process_fee : res.admin_lvl_2.manage_process_fee,
                        value2_manage_sale_price : res.admin_lvl_2.manage_sale_price,
                        value2_manage_transfer : res.admin_lvl_2.manage_transfer,
                        value2_view_member : res.admin_lvl_2.view_member,
                        value2_view_report : res.admin_lvl_2.view_report,
                        //new update value2
                        value2_chat : res.admin_lvl_2.chat,
                        value2_create_order : res.admin_lvl_2.create_order,
                        value2_manage_certificate : res.admin_lvl_2.manage_certificate,
                        value2_manage_env_account : res.admin_lvl_2.manage_env_account,
                        value2_manage_faq : res.admin_lvl_2.manage_faq,
                        value2_manage_master : res.admin_lvl_2.manage_master,
                        value2_manage_member : res.admin_lvl_2.manage_member,
                        value2_manage_news : res.admin_lvl_2.manage_news,
                        value2_manage_reward : res.admin_lvl_2.manage_reward,
                        value2_view_donate : res.admin_lvl_2.view_donate,
                        value2_view_user_list : res.admin_lvl_2.view_user_list,

                        value3_gid : res.admin_lvl_3.gid,
                        value3_role_name : res.admin_lvl_3.role_name,
                        value3_role_key : res.admin_lvl_3.role_key,
                        value3_manage_administrator : res.admin_lvl_3.manage_administrator,
                        value3_manage_aproval : res.admin_lvl_3.manage_aproval,
                        value3_manage_delivery_fee : res.admin_lvl_3.manage_delivery_fee,
                        value3_manage_invoice : res.admin_lvl_3.manage_invoice,
                        value3_manage_order : res.admin_lvl_3.manage_order,
                        value3_manage_password : res.admin_lvl_3.manage_password,
                        value3_manage_process_fee : res.admin_lvl_3.manage_process_fee,
                        value3_manage_sale_price : res.admin_lvl_3.manage_sale_price,
                        value3_manage_transfer : res.admin_lvl_3.manage_transfer,
                        value3_view_member : res.admin_lvl_3.view_member,
                        value3_view_report : res.admin_lvl_3.view_report,
                        //new update value3
                        value3_chat : res.admin_lvl_3.chat,
                        value3_create_order : res.admin_lvl_3.create_order,
                        value3_manage_certificate : res.admin_lvl_3.manage_certificate,
                        value3_manage_env_account : res.admin_lvl_3.manage_env_account,
                        value3_manage_faq : res.admin_lvl_3.manage_faq,
                        value3_manage_master : res.admin_lvl_3.manage_master,
                        value3_manage_member : res.admin_lvl_3.manage_member,
                        value3_manage_news : res.admin_lvl_3.manage_news,
                        value3_manage_reward : res.admin_lvl_3.manage_reward,
                        value3_view_donate : res.admin_lvl_3.view_donate,
                        value3_view_user_list : res.admin_lvl_3.view_user_list,


                    })
                    this.setState({ manageList: res })
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }

    itemTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }

    itemTemplate1(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }

    onSelectMemberName(item) {
        this.setState({ memberName: item.value.search_name });
        this.setState({ memberUid: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    suggestMemberName(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }
    suggestMemberName1(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }

    onSelectMemberName1(item) {
        console.log(item)
        this.setState({ memberName1: item.value.search_name });
        this.setState({ memberUid1: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
        console.log(item);
    }

    onMemberNameChanged(search_name) {
        console.log(search_name)
        console.log(typeof (search_name))
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwastepro(search_name).then((res) => {
                        console.log(res);
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName: search_name });
                this.setState({ memberUid: null });
            }
    }
    onMemberName1Changed(search_name) {
        console.log(search_name)
        console.log(typeof (search_name))
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName1: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwasteAll(search_name).then((res) => {
                        console.log(res);
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName1: search_name });
                this.setState({ memberUid1: null });
            }
    }

    onproductTypeUpdate(e) {
        console.log(e)
        this.setState({ productTypeObjUpdate: e.value });
    }
    onWeightUpdate(e) {
        console.log(e)
        this.setState({ productObjUpdate: e.value });

    }

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" onClick={() => this.onproductPriceUpdate(column.rowData)} icon="pi pi-pencil" className="p-button" ></Button>
            <Button type="button" onClick={() => this.onproductPriceDelete(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button>
        </div>;
    }

    
    onClickDeleteItem(rowData) {
        console.log(rowData)
        if (rowData !== '') {
            var id = rowData.gid
            ServerAPI.removesalepricebyu3(id).then((res) => {
                console.log(res);
                if (res) {
                    var index = res.toString().indexOf("Error:");
                    console.log("find error:" + (index));
                    if (index == -1) {
                        this.searchProductList();
                        this.getsalepricebyu3ForValid()
                        this.setState({ showProgress: false })
                        //this.showSuccess();
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            }).catch((error) => {
            })
        } else {
            this.showWarn();
            this.setState({ showProgress: false })
        }

        // if (index !== -1) {
        //     var array = this.state.priceList;
        //     array.splice(index, 1);
        //     this.setState({ priceList: array });
        // }
    }

    update() {
        this.setState({ showProgress: true })
        console.log(this.state.manageList)
        if (this.state.manageList) {
            var isNotinput = (
                this.state.value1_role_name == '' ||
                this.state.value2_role_name == '' ||
                this.state.value3_role_name == '' ||
                this.state.isNotValid == true
            );
            if (isNotinput) {
                this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
                this.setState({ showProgress: false })
            } else {
                console.log(this.state.value1_role_name);
                console.log(this.state.value2_role_name);
                console.log(this.state.value3_role_name);
                
                var data = {
                    admin_lvl_1: {
                        gid: this.state.value1_gid,
                        role_key: this.state.value1_role_key,
                        role_name: this.state.value1_role_name,
                        manage_order: this.state.value1_manage_order,
                        manage_invoice: this.state.value1_manage_invoice,
                        manage_sale_price: this.state.value1_manage_sale_price,
                        manage_delivery_fee: this.state.value1_manage_delivery_fee,
                        manage_transfer: this.state.value1_manage_transfer,
                        manage_process_fee: this.state.value1_manage_process_fee,
                        manage_administrator:this.state.value1_manage_administrator,
                        manage_password: this.state.value1_manage_password,
                        manage_aproval: this.state.value1_manage_aproval,
                        view_report: this.state.value1_view_report,
                        view_member: this.state.value1_view_member,
                        //new
                        chat : this.state.value1_chat,
                        create_order : this.state.value1_create_order ,
                        manage_certificate : this.state.value1_manage_certificate ,
                        manage_env_account : this.state.value1_manage_env_account ,
                        manage_faq : this.state.value1_manage_faq ,
                        manage_master : this.state.value1_manage_master ,
                        manage_member : this.state.value1_manage_member ,
                        manage_news : this.state.value1_manage_news ,
                        manage_reward : this.state.value1_manage_reward ,
                        view_donate : this.state.value1_view_donate ,
                        view_user_list : this.state.value1_view_user_list ,
                      
                      },
                      admin_lvl_2: {
                        gid: this.state.value2_gid,
                        role_key: this.state.value2_role_key,
                        role_name: this.state.value2_role_name,
                        manage_order: this.state.value2_manage_order,
                        manage_invoice: this.state.value2_manage_invoice,
                        manage_sale_price: this.state.value2_manage_sale_price,
                        manage_delivery_fee: this.state.value2_manage_delivery_fee,
                        manage_transfer: this.state.value2_manage_transfer,
                        manage_process_fee: this.state.value2_manage_process_fee,
                        manage_administrator:this.state.value2_manage_administrator,
                        manage_password: this.state.value2_manage_password,
                        manage_aproval: this.state.value2_manage_aproval,
                        view_report: this.state.value2_view_report,
                        view_member: this.state.value2_view_member,
                        //new
                        chat : this.state.value2_chat,
                        create_order : this.state.value2_create_order ,
                        manage_certificate : this.state.value2_manage_certificate ,
                        manage_env_account : this.state.value2_manage_env_account ,
                        manage_faq : this.state.value2_manage_faq ,
                        manage_master : this.state.value2_manage_master ,
                        manage_member : this.state.value2_manage_member ,
                        manage_news : this.state.value2_manage_news ,
                        manage_reward : this.state.value2_manage_reward ,
                        view_donate : this.state.value2_view_donate ,
                        view_user_list : this.state.value2_view_user_list ,
                     
                      },
                      admin_lvl_3: {
                        gid: this.state.value3_gid,
                        role_key: this.state.value3_role_key,
                        role_name: this.state.value3_role_name,
                        manage_order: this.state.value3_manage_order,
                        manage_invoice: this.state.value3_manage_invoice,
                        manage_sale_price: this.state.value3_manage_sale_price,
                        manage_delivery_fee: this.state.value3_manage_delivery_fee,
                        manage_transfer: this.state.value3_manage_transfer,
                        manage_process_fee: this.state.value3_manage_process_fee,
                        manage_administrator:this.state.value3_manage_administrator,
                        manage_password: this.state.value3_manage_password,
                        manage_aproval: this.state.value3_manage_aproval,
                        view_report: this.state.value3_view_report,
                        view_member: this.state.value3_view_member,
                        //new
                        chat : this.state.value3_chat,
                        create_order : this.state.value3_create_order ,
                        manage_certificate : this.state.value3_manage_certificate ,
                        manage_env_account : this.state.value3_manage_env_account ,
                        manage_faq : this.state.value3_manage_faq ,
                        manage_master : this.state.value3_manage_master ,
                        manage_member : this.state.value3_manage_member ,
                        manage_news : this.state.value3_manage_news ,
                        manage_reward : this.state.value3_manage_reward ,
                        view_donate : this.state.value3_view_donate ,
                        view_user_list : this.state.value3_view_user_list ,
                       
                    },
                    
                }
                this.setState({ showProgress: false })
                

                ServerAPI.updateAdminPermission(data).then((res) => {
                    console.log(res);
                    if (res) {
                        var index = res.toString().indexOf("Error:");
                        console.log("find error:" + (index));
                        if (index == -1) {
                            //this.getAdminPermission();
                            //this.setState({ displayDialog: false });
                            //this.clearAllDataInput();
                            this.showSuccess();
                        } else {
                            this.showWarn();
                            this.setState({ showProgress: false })
                        }
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                }).catch((error) => {
                    this.setState({ showProgress: false })
                })

            }
        }
        console.log(JSON.stringify(data))
    }
    
    delete() {
        this.setState({ showProgress: true })
        var data = this.state.manageList;
        this.onClickDeleteItem(data);
        // let index = this.findSelectedWeightIndex();
        // this.setState({
        //     cars: this.state.cars.filter((val,i) => i !== index),
        //     selectedCar: null,
        //     car: null,
        this.setState({ deleteDialog: false });
    }
    updateProperty(property, value) {
        let manageList = this.state.manageList;
        manageList[property] = value;
        this.setState({ manageList: manageList });
    }

    onproductPriceUpdate(e) {
        console.log(e)
        var productTypeDropdown = {
            product_type_code: e.product_type_code,
            product_type_name: e.product_type_name,
            less_than_kk: e.less_than_kk,
            more_than_kk: e.more_than_kk,
        }
        var productDropdown = {
            product_code: e.product_code,
            product_name: e.product_name,
            less_than_kk: e.less_than_kk,
            more_than_kk: e.more_than_kk,
        }
        this.setState({
            displayDialog: true,
            manageList: Object.assign({}, e),
            productTypeObjUpdate: productTypeDropdown,
            productObjUpdate: productDropdown,
        });
    }
    onproductPriceDelete(e) {
        console.log(e)
        this.setState({
            deleteDialog: true,
            manageList: Object.assign({}, e),
        });
    }

    onValidation(valid) {
        console.log(valid)
        if (!valid) this.setState({ isNotValid: true })
        else this.setState({ isNotValid: false })
    }

    


    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    addDataToTable() {
        this.setState({ showProgress: true })
        var isNotinput = (
            this.state.wasteTypeSelected == '' ||
            this.state.productSelected == '' ||
            this.state.priceSelected1 == '' ||
            this.state.memberUid == '' ||
            this.state.memberName == '' ||
            this.state.isNotValid == true
        );

        if (isNotinput) {
            this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
            this.setState({ showProgress: false })
        } else {
            var notValid = false;
            this.getsalepricebyu3ForValid();
            console.log(this.state.productPriceList)
            if (this.state.productPriceList)
                this.state.productPriceList.forEach(objProductPrice => {
                    console.log(objProductPrice.u3_uid);
                    console.log(this.state.memberUid);
                    console.log(objProductPrice.product_type_code);
                    console.log(this.state.productTypeCode);
                    console.log(objProductPrice.product_code);
                    console.log(this.state.productSelected.product_code);
                    if (objProductPrice.u3_uid == this.state.memberUid
                        && objProductPrice.product_type_code == this.state.productTypeCode
                        && objProductPrice.product_code == this.state.productSelected.product_code) {
                        notValid = true;
                    }
                });

            if (notValid) {
                this.showWarn("ข้อมูลซ้ำ! กรุณาเลือกใหม่อีกครั้ง");
                this.setState({ showProgress: false })
            } else {
                var priceList = {
                    gid: this.state.gid,
                    u3_uid: this.state.memberUid,
                    u3_name: this.state.memberName,
                    product_type_code: this.state.wasteTypeSelected.product_type_code,
                    product_type_name: this.state.wasteTypeSelected.product_type_name,
                    product_code: this.state.productSelected.product_code,
                    product_name: this.state.productSelected.product_name,
                    fee_per_kk: parseFloat(this.state.priceSelected1),

                }

                ServerAPI.Addsalepricebyu3(priceList).then((res) => {
                    console.log(res);
                    if (res) {
                        var index = res.toString().indexOf("Error:");
                        console.log("find error:" + (index));
                        if (index == -1) {
                            this.searchProductList();
                            this.clearAllDataInput();
                            //this.showSuccess();

                        } else {
                            this.showWarn();
                            this.setState({ showProgress: false })
                        }
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                }).catch((error) => {
                    this.setState({ showProgress: false })
                })
            }
        }
        console.log(this.state.priceList)
    }

    


    clearAllDataInput() {

        this.setState({ wasteTypeSelected: '' })
        this.setState({ productSelected: '' })
        this.setState({ priceSelected1: '' })
        this.setState({ memberName: '' })
        this.setState({ memberUid: '' })
        this.setState({ manageList: null })
        this.setState({ productTypeObjUpdate: null })
        this.setState({ productObjUpdate: null })

    }

    clearSearchInput() {
        this.setState({ memberName1: null }); //order code from select table
        this.setState({ memberUid1: null });
        this.setState({ searchProductType: null }); //order code from auto complete
        this.setState({ searchProduct: null });
        this.setState({ searchProductTypeCode: null });
        this.setState({ searchProductCode: null });

        console.log(this.state.searchProductType)
        console.log(this.state.searchProduct)
        console.log(this.state.searchProductTypeCode)
        console.log(this.state.searchProductCode)

        this.clearAllDataInput();
    }

    addNewAdministratorTab1() {
        const {
            productType,
            product,
        } = this.props;
        return (
            <Accordion multiple={true} activeIndex={[0, 1]}>
                <AccordionTab header=" ผู้ดูแลระบบระดับที่ 1">
                    <div className="p-grid">
                        <label className="p-col-12 p-md-2 p-lg-2  " style={{ textAlign: 'left', alignSelf: 'center' }}>ชื่อระดับผู้ดูแลระบบ :</label>
                        <div className="p-col-12 p-md-10 ">
                        
                        <InputText value={this.state.value1_role_name} onChange={(e) => this.setState({value1_role_name: e.target.value})}/>
                        </div>

                        {/* <label className="p-col-12 p-md-2 p-lg-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>ระดับผู้ดูแลระบบ :</label>
                        <div className="p-col-12 p-md-10">
                                ระดับที่ 1
                        </div> */}


                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value1_manage_order: e.checked})} checked={this.state.value1_manage_order}></Checkbox>
                        <label htmlFor="a2" className="p-checkbox-label">จัดการ Order</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value1_manage_invoice: e.checked})} checked={this.state.value1_manage_invoice}></Checkbox>
                        <label htmlFor="a3" className="p-checkbox-label">จัดการ Invoice</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value1_manage_transfer: e.checked})} checked={this.state.value1_manage_transfer}></Checkbox>
                        <label htmlFor="a4" className="p-checkbox-label">จัดการรายการโอนเงิน</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value1_manage_sale_price: e.checked})} checked={this.state.value1_manage_sale_price}></Checkbox>
                        <label htmlFor="a5" className="p-checkbox-label">จัดการราคารับซื้อ</label>
                    </div>
                    
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value1_manage_process_fee: e.checked})} checked={this.state.value1_manage_process_fee}></Checkbox>
                        <label htmlFor="a6" className="p-checkbox-label">จัดการค่าบริการ</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value1_manage_delivery_fee: e.checked})} checked={this.state.value1_manage_delivery_fee}></Checkbox>
                        <label htmlFor="a7" className="p-checkbox-label">จัดการค่าขนส่ง</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value1_manage_administrator: e.checked})} checked={this.state.value1_manage_administrator}></Checkbox>
                        <label htmlFor="a8" className="p-checkbox-label">จัดการผู้ดูแลระบบ</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value1_view_report: e.checked})} checked={this.state.value1_view_report}></Checkbox>
                        <label htmlFor="a9" className="p-checkbox-label">เรียกดูรายงาน</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value1_manage_password: e.checked})} checked={this.state.value1_manage_password}></Checkbox>
                        <label htmlFor="a10" className="p-checkbox-label">รีเซ็ตรหัสผ่าน</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value1_view_member: e.checked})} checked={this.state.value1_view_member}></Checkbox>
                        <label htmlFor="a11" className="p-checkbox-label">ดูหน้าจอสมาชิก</label>
                    </div>
                    
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value1_manage_aproval: e.checked})} checked={this.state.value1_manage_aproval}></Checkbox>
                        <label htmlFor="a12" className="p-checkbox-label">การอนุมัติสมาชิก</label>
                    </div>

                    {/* new update */}
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value1_chat: e.checked})} checked={this.state.value1_chat}></Checkbox>
                        <label htmlFor="a13" className="p-checkbox-label">แชทกับสมาชิก</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value1_create_order : e.checked})} checked={this.state.value1_create_order }></Checkbox>
                        <label htmlFor="a14" className="p-checkbox-label">สร้าง order</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value1_manage_certificate : e.checked})} checked={this.state.value1_manage_certificate }></Checkbox>
                        <label htmlFor="a15" className="p-checkbox-label">จัดการใบรับรอง/เหรียญ</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value1_manage_env_account : e.checked})} checked={this.state.value1_manage_env_account }></Checkbox>
                        <label htmlFor="a16" className="p-checkbox-label">จัดการบัญชีองค์กรสิ่งแวดล้อม</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value1_manage_faq : e.checked})} checked={this.state.value1_manage_faq }></Checkbox>
                        <label htmlFor="a17" className="p-checkbox-label">จัดการคำถามที่พบบ่อย(FAQ)</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value1_manage_master : e.checked})} checked={this.state.value1_manage_master }></Checkbox>
                        <label htmlFor="a18" className="p-checkbox-label">จัดการข้อมูลหลัก</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value1_manage_member : e.checked})} checked={this.state.value1_manage_member }></Checkbox>
                        <label htmlFor="a19" className="p-checkbox-label">จัดการข้อมูลสมาชิก</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value1_manage_news : e.checked})} checked={this.state.value1_manage_news }></Checkbox>
                        <label htmlFor="a20" className="p-checkbox-label">จัดการข่าว</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value1_manage_reward : e.checked})} checked={this.state.value1_manage_reward }></Checkbox>
                        <label htmlFor="a21" className="p-checkbox-label">จัดการค่าความดี</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value1_view_donate : e.checked})} checked={this.state.value1_view_donate }></Checkbox>
                        <label htmlFor="a22" className="p-checkbox-label">ดูรายการบริจาค</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value1_view_user_list : e.checked})} checked={this.state.value1_view_user_list }></Checkbox>
                        <label htmlFor="a23" className="p-checkbox-label">ดูรายชื่อผู้สมัครใช้งาน</label>
                    </div>

                    </div>
                </AccordionTab>
            </Accordion>

        )
    }

    addNewAdministratorTab2() {
        const {
            productType,
            product,
        } = this.props;
        return (
            <Accordion multiple={true} activeIndex={[0, 1]}>
                <AccordionTab header=" ผู้ดูแลระบบระดับที่ 2">
                    <div className="p-grid">
                        <label className="p-col-12 p-md-2 p-lg-2  " style={{ textAlign: 'left', alignSelf: 'center' }}>ชื่อระดับผู้ดูแลระบบ :</label>
                        <div className="p-col-12 p-md-10 ">
                        <InputText value={this.state.value2_role_name} onChange={(e) => this.setState({value2_role_name: e.target.value})}/>
                        </div>

                        {/* <label className="p-col-12 p-md-2 p-lg-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>ระดับผู้ดูแลระบบ :</label>
                        <div className="p-col-12 p-md-10">
                                ระดับที่ 2
                        </div> */}

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value2_manage_order: e.checked})} checked={this.state.value2_manage_order}></Checkbox>
                        <label htmlFor="a2" className="p-checkbox-label">จัดการ Order</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value2_manage_invoice: e.checked})} checked={this.state.value2_manage_invoice}></Checkbox>
                        <label htmlFor="a3" className="p-checkbox-label">จัดการ Invoice</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value2_manage_transfer: e.checked})} checked={this.state.value2_manage_transfer}></Checkbox>
                        <label htmlFor="a4" className="p-checkbox-label">จัดการรายการโอนเงิน</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value2_manage_sale_price: e.checked})} checked={this.state.value2_manage_sale_price}></Checkbox>
                        <label htmlFor="a5" className="p-checkbox-label">จัดการราคารับซื้อ</label>
                    </div>
                    
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value2_manage_process_fee: e.checked})} checked={this.state.value2_manage_process_fee}></Checkbox>
                        <label htmlFor="a6" className="p-checkbox-label">จัดการค่าบริการ</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value2_manage_delivery_fee: e.checked})} checked={this.state.value2_manage_delivery_fee}></Checkbox>
                        <label htmlFor="a7" className="p-checkbox-label">จัดการค่าขนส่ง</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value2_manage_administrator: e.checked})} checked={this.state.value2_manage_administrator}></Checkbox>
                        <label htmlFor="a8" className="p-checkbox-label">จัดการผู้ดูแลระบบ</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value2_view_report: e.checked})} checked={this.state.value2_view_report}></Checkbox>
                        <label htmlFor="a9" className="p-checkbox-label">เรียกดูรายงาน</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value2_manage_password: e.checked})} checked={this.state.value2_manage_password}></Checkbox>
                        <label htmlFor="a10" className="p-checkbox-label">รีเซ็ตรหัสผ่าน</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value2_view_member: e.checked})} checked={this.state.value2_view_member}></Checkbox>
                        <label htmlFor="a11" className="p-checkbox-label">ดูหน้าจอสมาชิก</label>
                    </div>
                    
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value2_manage_aproval: e.checked})} checked={this.state.value2_manage_aproval}></Checkbox>
                        <label htmlFor="a12" className="p-checkbox-label">การอนุมัติสมาชิก</label>
                    </div>

                    {/* new update */}
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value2_chat: e.checked})} checked={this.state.value2_chat}></Checkbox>
                        <label htmlFor="a13" className="p-checkbox-label">แชทกับสมาชิก</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value2_create_order : e.checked})} checked={this.state.value2_create_order }></Checkbox>
                        <label htmlFor="a14" className="p-checkbox-label">สร้าง order</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value2_manage_certificate : e.checked})} checked={this.state.value2_manage_certificate }></Checkbox>
                        <label htmlFor="a15" className="p-checkbox-label">จัดการใบรับรอง/เหรียญ</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value2_manage_env_account : e.checked})} checked={this.state.value2_manage_env_account }></Checkbox>
                        <label htmlFor="a16" className="p-checkbox-label">จัดการบัญชีองค์กรสิ่งแวดล้อม</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value2_manage_faq : e.checked})} checked={this.state.value2_manage_faq }></Checkbox>
                        <label htmlFor="a17" className="p-checkbox-label">จัดการคำถามที่พบบ่อย(FAQ)</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value2_manage_master : e.checked})} checked={this.state.value2_manage_master }></Checkbox>
                        <label htmlFor="a18" className="p-checkbox-label">จัดการข้อมูลหลัก</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value2_manage_member : e.checked})} checked={this.state.value2_manage_member }></Checkbox>
                        <label htmlFor="a19" className="p-checkbox-label">จัดการข้อมูลสมาชิก</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value2_manage_news : e.checked})} checked={this.state.value2_manage_news }></Checkbox>
                        <label htmlFor="a20" className="p-checkbox-label">จัดการข่าว</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value2_manage_reward : e.checked})} checked={this.state.value2_manage_reward }></Checkbox>
                        <label htmlFor="a21" className="p-checkbox-label">จัดการค่าความดี</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value2_view_donate : e.checked})} checked={this.state.value2_view_donate }></Checkbox>
                        <label htmlFor="a22" className="p-checkbox-label">ดูรายการบริจาค</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value2_view_user_list : e.checked})} checked={this.state.value2_view_user_list }></Checkbox>
                        <label htmlFor="a23" className="p-checkbox-label">ดูรายชื่อผู้สมัครใช้งาน</label>
                    </div>


                    </div>
                </AccordionTab>
            </Accordion>

        )
    }

    addNewAdministratorTab3() {
        const {
            productType,
            product,
        } = this.props;
        return (
            <Accordion multiple={true} activeIndex={[0, 1]}>
                <AccordionTab header=" ผู้ดูแลระบบระดับที่ 3">
                    <div className="p-grid">
                        <label className="p-col-12 p-md-2 p-lg-2  " style={{ textAlign: 'left', alignSelf: 'center' }}>ชื่อระดับผู้ดูแลระบบ :</label>
                        <div className="p-col-12 p-md-10 ">
                        <InputText value={this.state.value3_role_name} onChange={(e) => this.setState({value3_role_name: e.target.value})}/>
                        </div>

                        {/* <label className="p-col-12 p-md-2 p-lg-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>ระดับผู้ดูแลระบบ :</label>
                        <div className="p-col-12 p-md-10">
                                ระดับที่ 3
                        </div> */}

                    
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value3_manage_order: e.checked})} checked={this.state.value3_manage_order}></Checkbox>
                        <label htmlFor="a2" className="p-checkbox-label">จัดการ Order</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value3_manage_invoice: e.checked})} checked={this.state.value3_manage_invoice}></Checkbox>
                        <label htmlFor="a3" className="p-checkbox-label">จัดการ Invoice</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value3_manage_transfer: e.checked})} checked={this.state.value3_manage_transfer}></Checkbox>
                        <label htmlFor="a4" className="p-checkbox-label">จัดการรายการโอนเงิน</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value3_manage_sale_price: e.checked})} checked={this.state.value3_manage_sale_price}></Checkbox>
                        <label htmlFor="a5" className="p-checkbox-label">จัดการราคารับซื้อ</label>
                    </div>
                    
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value3_manage_process_fee: e.checked})} checked={this.state.value3_manage_process_fee}></Checkbox>
                        <label htmlFor="a6" className="p-checkbox-label">จัดการค่าบริการ</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value3_manage_delivery_fee: e.checked})} checked={this.state.value3_manage_delivery_fee}></Checkbox>
                        <label htmlFor="a7" className="p-checkbox-label">จัดการค่าขนส่ง</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value3_manage_administrator: e.checked})} checked={this.state.value3_manage_administrator}></Checkbox>
                        <label htmlFor="a8" className="p-checkbox-label">จัดการผู้ดูแลระบบ</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value3_view_report: e.checked})} checked={this.state.value3_view_report}></Checkbox>
                        <label htmlFor="a9" className="p-checkbox-label">เรียกดูรายงาน</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value3_manage_password: e.checked})} checked={this.state.value3_manage_password}></Checkbox>
                        <label htmlFor="a10" className="p-checkbox-label">รีเซ็ตรหัสผ่าน</label>
                    </div>

                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <Checkbox onChange={e => this.setState({value3_view_member: e.checked})} checked={this.state.value3_view_member}></Checkbox>
                        <label htmlFor="a11" className="p-checkbox-label">ดูหน้าจอสมาชิก</label>
                    </div>
                    
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value3_manage_aproval: e.checked})} checked={this.state.value3_manage_aproval}></Checkbox>
                        <label htmlFor="a12" className="p-checkbox-label">การอนุมัติสมาชิก</label>
                    </div>

                    {/* new update */}
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value3_chat: e.checked})} checked={this.state.value3_chat}></Checkbox>
                        <label htmlFor="a13" className="p-checkbox-label">แชทกับสมาชิก</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value3_create_order : e.checked})} checked={this.state.value3_create_order }></Checkbox>
                        <label htmlFor="a14" className="p-checkbox-label">สร้าง order</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value3_manage_certificate : e.checked})} checked={this.state.value3_manage_certificate }></Checkbox>
                        <label htmlFor="a15" className="p-checkbox-label">จัดการใบรับรอง/เหรียญ</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value3_manage_env_account : e.checked})} checked={this.state.value3_manage_env_account }></Checkbox>
                        <label htmlFor="a16" className="p-checkbox-label">จัดการบัญชีองค์กรสิ่งแวดล้อม</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value3_manage_faq : e.checked})} checked={this.state.value3_manage_faq }></Checkbox>
                        <label htmlFor="a17" className="p-checkbox-label">จัดการคำถามที่พบบ่อย(FAQ)</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value3_manage_master : e.checked})} checked={this.state.value3_manage_master }></Checkbox>
                        <label htmlFor="a18" className="p-checkbox-label">จัดการข้อมูลหลัก</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value3_manage_member : e.checked})} checked={this.state.value3_manage_member }></Checkbox>
                        <label htmlFor="a19" className="p-checkbox-label">จัดการข้อมูลสมาชิก</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value3_manage_news : e.checked})} checked={this.state.value3_manage_news }></Checkbox>
                        <label htmlFor="a20" className="p-checkbox-label">จัดการข่าว</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value3_manage_reward : e.checked})} checked={this.state.value3_manage_reward }></Checkbox>
                        <label htmlFor="a21" className="p-checkbox-label">จัดการค่าความดี</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value3_view_donate : e.checked})} checked={this.state.value3_view_donate }></Checkbox>
                        <label htmlFor="a22" className="p-checkbox-label">ดูรายการบริจาค</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <Checkbox onChange={e => this.setState({value3_view_user_list : e.checked})} checked={this.state.value3_view_user_list }></Checkbox>
                        <label htmlFor="a23" className="p-checkbox-label">ดูรายชื่อผู้สมัครใช้งาน</label>
                    </div>

                    </div>
                </AccordionTab>
            </Accordion>

        )
    }

    onSubmit() {

    }

    render() {
        const showProgress = this.showProgressSpinner();
        const addnew1 = this.addNewAdministratorTab1();
        const addnew2 = this.addNewAdministratorTab2();
        const addnew3 = this.addNewAdministratorTab3();


        return <div className="p-grid ">
            <Growl ref={(el) => this.growl = el} />
            <div className="p-col-12">
                <h1>ตั้งค่าความสามารถ</h1>
            </div>
            {addnew1}
            <br/>
            {addnew2}
            <br/>
            {addnew3}
            <br/>
            {showProgress}
            <br/>
            <div className="p-col-12 ">
                <Button
                    style={{ float: "left" ,width:"100%" }}
                    label="บันทึก"
                    icon="fa fa-floppy-o"
                    iconPos="left"
                    onClick={this.update}

                />
            </div>
            {/* {showLevelAdminTable1} */}
        </div>
    }
}



const mapStateToProps = (state) => ({
    dhbUsers: state.dhbAdmin.dhbUsers,
    locationMaster: state.location,
    provinces: state.location.province,
    districts: state.location.district,
    subDistricts: state.location.subDistrict,
    cardProvince: state.location.cardProvince,
    cardDistrict: state.location.cardDistrict,
    cardSubDistrict: state.location.cardSubDistrict,
    authentication: state.authentication,
    userInfo: state.userInfo.data,
    userInfoMaster: state.userInfo,
    updateWasteproProfile: state.updateWasteproProfile,
    banks: state.master.banks,
    productType: state.master.productType,
    product: state.master.product,
    productType1: state.master.productType,
    product1: state.master.product,
    getFileWastepro: state.getFileWastepro,
})

const mapDispatchToProps = dispatch => ({

    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    fileServiceProps: bindActionCreators(FileService, dispatch),

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ManageAdminMember)