export const WASTE_TYPE = {
  wastegen: 1,
  wastetran: 2,
  wastepro: 3,
  administrator: 4,
}

export const WASTE_TYPE_NUM_TO_NAME = {
  1: 'wastegen',
  2: 'wastetran',
  3: 'wastepro',
  4: 'administrator',
}
