import React, { Component } from 'react';

export class WasteproManageInvoice extends Component {
    render(){
        return (
            <div>
                <h1>จัดการ Invoice</h1>
                <div className="p-grid">
                    <div className="p-lg-12  p-md-12  p-sm-12">
                        <div className="card card-w-title">
                            <span >จะใช้งานได้ภายในเดือน มกราคม 2563</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}