import axios from 'axios'
import { API_ROOT } from '../../constants/api'
import {

} from '../../service/actionType'

import {
  RPT_APLC_WG,RPT_APLC_WP,RPT_APLC_WT,DHB_USER
} from '../../service/actionType'

export const autoComplateOrderID = (code) =>{
  
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastepro/order/autocomplete/${code}`,
    headers: {'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}   

export const searchOrder = (formData) =>{
  return axios({
        method: 'post',
        url: `${API_ROOT}/wastepro/order/search`,
        data: formData,
        headers: {'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
      })
      .then(function (res) {
        return res.data;
      })
      .catch(function (error) {
        return error;
      });
}

export const getOrderDetail = (code) =>{
  
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastepro/order/getbycode/${code}`,
    headers: { 
      'Content-Type': 'application/json' ,
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}  

export const updateOrder = (formData) =>{
  return axios({
        method: 'post',
        url: `${API_ROOT}/wastepro/order/update`,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data',
                  Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
      })
      .then(function (res) {
        return res.data;
      })
      .catch(function (error) {
        return error;
      });
}

export const searchInvoice = (formData) =>{
  return axios({
        method: 'post',
        url: `${API_ROOT}/wastepro/invoice/search`,
        data: formData,
        headers: {'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
      })
      .then(function (res) {
        return res.data;
      })
      .catch(function (error) {
        return error;
      });
}



//service copy form admin

export const Addsalepricebyu3 = (formData) =>{
  return axios({
        method: 'post',
        url: `${API_ROOT}/wastepro/salepricebyu3/add`,
        data: formData,
        headers: {'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
      })
      .then(function (res) {
        return res.data = 'success';
      })
      .catch(function (error) {
        return error;
      });
}

export const editSalepricebyu3 = (formData) =>{
  return axios({
        method: 'post',
        url: `${API_ROOT}/wastepro/salepricebyu3/edit`,
        data: formData,
        headers: {'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
      })
      .then(function (res) {
        return res.data = 'success';
      })
      .catch(function (error) {
        return error;
      });
}

export const getsalepricebyu3 = () =>{
  
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastepro/salepricebyu3/getall`,
    headers: { 
      'Content-Type': 'application/json' ,
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
} 

export const removesalepricebyu3 = (id) =>{
  
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastepro/salepricebyu3/delete/${id}`,
    headers: { 
      'Content-Type': 'application/json' ,
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    return error;
  })
} 

export const searchSalePriceByU3 = (formData) =>{
  return axios({
        method: 'post',
        url: `${API_ROOT}/wastepro/salepricebyu3/search`,
        data: formData,
        headers: {'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
      })
      .then(function (res) {
        return res.data;
      })
      .catch(function (error) {
        return error;
      });
}
export const searchwastepro = (search_name) =>{
  
  return axios({
    method: 'get',
    url: `${API_ROOT}/autocomplete/wastepro/${search_name}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}   

export const getDashboardUsers = () => (dispatch) => {
  dispatch({ type: DHB_USER.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/dashboard/users`,
  }).then((res) => {
    dispatch({ type: DHB_USER.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: DHB_USER.FAILURE, error })
  })
}

export const updateWorkingStatus = (formData) =>{
  return axios({
        method: 'post',
        url: `${API_ROOT}/wastepro/profile/updateworkingstatus`,
        data: formData,
        headers: {'Content-Type': 'application/json',
                  Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
      })
      .then(function (res) {
        return res.data;
      })
      .catch(function (error) {
        return error;
      });
}

export const getBadgeAdmin = ()  => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetUnread/admin`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
  }
  })
    .then(res => {
      return res.data;
    })
    .catch(error => {})
}

export const getBadgePickup = room_id  => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetUnreadByRoomId/${room_id}`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(res => {
      return res.data;
    })
    .catch(error => {
    })
}

export const getBadgeDelivery = room_id  => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetUnreadByRoomId/${room_id}`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(res => {
      return res.data;
    })
    .catch(error => {
    })
}


export const wasteproGetNewOrderByCode = (code) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastepro/order/getneworderbycode/${code}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
  }
  })
  .then(res => {
    return res.data;
  })
  .catch(error => {
    return error
  })
}

export const wasteproAcceptOrRejectOrder = (
  data,
  isAccepted,
) => {
  const newData = {
    ...data,
    isAccepted,
  }
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastepro/order/acceptorrejectorder`,
    data: newData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
  }
  })
  .then(res => {
    return res.data;
  })
  .catch(error => {
    return error
  })
}


  
