

import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Dropdown} from 'primereact/dropdown'
import {Chips} from 'primereact/chips'
import { Validators } from '../../utils';
import { Message } from 'primereact/message';

class LocationRecycleView extends Component {

    state = {

    }

    //>Initial 
    componentDidMount() {
        console.log("LocationRecycleView :componentDidMount")
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("LocationRecycleView :componentWillReceiveProps")
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log("LocationRecycleView :shouldComponentUpdate")
        return true
    }

    componentWillUpdate(nextProps, nextState) {
        console.log("LocationRecycleView :componentWillUpdate")

    }

    componentDidUpdate(prevProps, prevState) {
        console.log("LocationRecycleView :componentDidUpdate")

    }

    //> Event
    onChangeProvinceChip = (e) => {
        this.props.onChangeProvinceChip(e);
    }
    onChangeDistrictChip = (e) =>{
        this.props.onChangeDistrictChip(e);
    }
    itemTemplate = (item)=>{
        return this.props.itemTemplate(item);
    }
    onRemove = (e) =>{
        this.props.onRemove(e);
    }

    //> Mode render
    renderWastegen = () => {
        return (
            <div></div>
        )
    }
    renderWastetran = () => {
        return (
            <div></div>
        )
    }
    renderWastepro = () => {
        return (
            <div></div>
        )
    }

    render() {
        console.log("LocationRecycleView :render")
        const {
            mode
        } = this.props

        let renderMode;

        switch (mode) {
            case 'wastegen':
                renderMode = this.renderWastegen()
                break;
            case 'wastetran':
                renderMode = this.renderWastetran()
                break;
            case 'wastepro':
                renderMode = this.renderWastepro()
                break;

            default:
                renderMode = (<div></div>)
                break;
        }

        return (
            <div className="p-grid">

                <div className="p-col-12">
                    <label >
                        จังหวัด
                    </label>
                </div>
                <div className="p-col-12">
                    <Dropdown
                        style={{ width: '100%' }}
                        value={this.props.provinceChipSelected}
                        options={this.props.chipProvince}
                        onChange={this.onChangeProvinceChip}
                        optionLabel="province_name"
                        placeholder="เลือกจังหวัด" />

                </div>


                <div className="p-col-12">
                    <label 
                        >
                        อำเภอ/เขต
                 </label>
                </div>
                <div className="p-col-12">
                    <Dropdown
                        style={{ width: '100%' }}
                        value={this.props.districtChipSelected}
                        options={this.props.chipDistrict}
                        onChange={this.onChangeDistrictChip}
                        optionLabel="districtName"
                        placeholder="อำเภอ/เขต" />
                </div>

                <div className="p-col-12 p-lg-12" >
                    <Chips
                        className={Validators.isEmpty(this.props.selectedChipDistrict)?'p-error':''}
                        style={{ margin: '7px' }}
                        value={this.props.selectedChipDistrict}
                        itemTemplate={this.itemTemplate}
                        onRemove={this.onRemove}
                    >
                    </Chips>
                    {Validators.isEmpty(this.props.selectedChipDistrict)&&
                        <Message severity="error" text="กรุณาเลือกพื้นที่เก็บขยะรีไซเคิล"/>
                    }
                </div>
                {renderMode}

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(LocationRecycleView)
