import React from 'react'
import PropType from 'prop-types'
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea'

export const ExampleStateLessTestView = ({val,onChangeText}) =>{

    return (
        <div>
            <InputTextarea 
                name="val"
                value={val}
                onChange={onChangeText}
            />
        </div>
    )
}