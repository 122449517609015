

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';
import { AutoComplete } from 'primereact/autocomplete';

import {status} from '../../constants/order-status'
import { filter } from 'lodash'


class SearchView extends Component {

    static defaultProps = {
        classNameRequired:{},
        order_id: null,
        orderIdSuggestions: null,
        order_name: null,
        orderNameSuggestions: null,
        shop_name: null,
        shopNameSuggestions: null,
        statusValue: { name: "เลือกสถานะ", value: 0 },
        statusList: status,
        pickupDate: null,
        pickupDateTo: null,
        appointmentDate: null,
        appointmentDateTo: null,
        collector_name: null,
        collectionNameSuggestions: null,
        bank: null,
        banks: [],

        onChangeInputText: null,
        onChangeStatus: null,
        onChangePickupdate: null,
        onChangePickupdateTo: null,
        onChangeAppointmentDate: null,
        onChangeAppointmentDateTo: null,
        onClickSearch: null,
        onClickCancel:null,
        onSuggestorderIds: null,
        onSuggestorderNames: null,
        onSuggestCollectionNames: null,
        onChangeAutocomplete: null,
        onChangeBank: null,
        onSuggestShopNames: null,
        
    }

    static propTypes = {
        classNameRequired:PropTypes.object,
        order_id: PropTypes.string,
        orderIdSuggestions: PropTypes.array,
        order_name: PropTypes.string,
        orderNameSuggestions: PropTypes.array,
        shop_name: PropTypes.string,
        shopNameSuggestions: PropTypes.array,
        statusValue: PropTypes.object,
        statusList: PropTypes.array,
        pickupDate: PropTypes.instanceOf(Date),
        pickupDateTo: PropTypes.instanceOf(Date),
        collector_name: PropTypes.string,
        collectionNameSuggestions: PropTypes.array,
        bank: PropTypes.object,
        banks: PropTypes.array,

        onChangeInputText: PropTypes.func,
        onChangeStatus: PropTypes.func,
        onChangePickupdate: PropTypes.func,
        onChangePickupdateTo: PropTypes.func,
        onChangeAppointmentDate: PropTypes.func,
        onChangeAppointmentDateTo: PropTypes.func,
        onClickSearch: PropTypes.func,
        onClickCancel:PropTypes.func,
        onSuggestorderIds: PropTypes.func,
        onSuggestorderNames: PropTypes.func,
        onSuggestCollectionNames: PropTypes.func,
        onSuggestShopNames: PropTypes.func,
        onChangeAutocomplete: PropTypes.func,
        onChangeBank: PropTypes.func
    }

    //>Initial 
    componentDidMount() {
        console.log("SearchView :componentDidMount")
    }
    //>Life Cycle Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("SearchView :componentWillReceiveProps")
    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("SearchView :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("SearchView :componentWillUpdate")
    }
    componentDidUpdate(prevProps, prevState) {
        console.log("SearchView :componentDidUpdate")
    }

    //> Event
    onChangeInputText = (e) => {
        this.props.onChangeInputText(e);
    }
    onChangeStatus = (e) => {
        this.props.onChangeStatus(e);
    }
    onChangePickupdate = (e) => {
        this.props.onChangePickupdate(e);
    }
    onChangePickupdateTo = (e) => {
        this.props.onChangePickupdateTo(e);
    }
    onChangeAppointmentDate = (e) => {
        this.props.onChangeAppointmentDate(e);
    }
    onChangeAppointmentDateTo = (e) => {
        this.props.onChangeAppointmentDateTo(e);
    }
    onClickSearch = (e) => {
        this.props.onClickSearch(e)
    }
    onClickCancel=(e)=>this.props.onClickCancel(e);
    onSuggestorderIds = (e) => {
        this.props.onSuggestorderIds(e);
    }
    onSuggestorderNames = (e) => this.props.onSuggestorderNames(e);

    onSuggestCollectionNames = (e) => this.props.onSuggestCollectionNames(e);
    onSuggestShopNames = (e) => this.props.onSuggestShopNames(e);
    onChangeAutocomplete = (e) => {
        this.props.onChangeAutocomplete(e);
    }
    onChangeBank = (e) => this.props.onChangeBank(e);

    //> Mode render
    renderWastegen = () => {
        return (
            <div className="p-grid">
                {/* ROW1 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="order_id" className="p-label-right">Order id </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <AutoComplete
                        placeholder="Search Order Id"
                        name="order_id"
                        placeholder="ระบุ Order Id"
                        value={this.props.order_id}
                        onChange={this.onChangeAutocomplete}
                        suggestions={this.props.orderIdSuggestions}
                        completeMethod={this.onSuggestorderIds} />
                </div>
                <div className="p-col-12 p-lg-7" />

                {/* ROW2 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="order_name_id" className="p-label-right">รายการ </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <AutoComplete
                        id="order_name_id"
                        name="order_name"
                        placeholder="ระบุชื่อรายการ"
                        value={this.props.order_name}
                        onChange={this.onChangeAutocomplete}
                        suggestions={this.props.orderNameSuggestions}
                        completeMethod={this.onSuggestorderNames} />
                </div>
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="r_id" className="p-label-right">สถานะ </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Dropdown style={{ width: '100%' }}
                        id="status_id"
                        value={this.props.statusValue}
                        options={this.props.statusList}
                        onChange={this.onChangeStatus}
                        optionLabel="name"
                        placeholder="ระบุสถานะ"
                    />
                </div>
                <div className="p-col-12 p-lg-2" />

                {/* ROW3 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="pickup_date_id" className="p-label-right">วันนัดรับ </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Calendar showIcon={true}
                        dateFormat="dd/mm/yy"
                        id="pickup_date_id"
                        name="pickup_date"
                        className={this.props.classNameRequired.pickup_date_required}
                        required={true}
                        value={this.props.pickupDate}
                        maxDate={this.props.pickupDateTo}
                        onChange={this.onChangePickupdate}
                        placeholder="ระบุวันที่"
                    />
                </div>
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="pickup_date_to_id" className="p-label-right">ถึง </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Calendar showIcon={true} dateFormat="dd/mm/yy"
                        id="pickup_date_to_id"
                        name="pickup_date_to"
                        className={this.props.classNameRequired.pickup_date_to_required}
                        required={true}
                        value={this.props.pickupDateTo}
                        minDate={this.props.pickupDate}
                        onChange={this.onChangePickupdateTo}
                        placeholder="ระบุวันที่"
                    />
                </div>
                <div className="p-col-12 p-lg-2" />

                {/* ROW4 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="appointment_date_id" className="p-label-right">วันนัดส่ง </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Calendar showIcon={true} dateFormat="dd/mm/yy"
                        id="appointment_date_id"
                        name="appointment_date"
                        className={this.props.classNameRequired.appointment_date_required}
                        required={true}
                        value={this.props.appointmentDate}
                        maxDate={this.props.appointmentDateTo}
                        onChange={this.onChangeAppointmentDate}
                        placeholder="ระบุวันที่"
                    />
                </div>
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="appointment_date_to_id" className="p-label-right">ถึง </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Calendar showIcon={true} dateFormat="dd/mm/yy"
                        id="appointment_date_to_id"
                        name="appointment_date_to"
                        className={this.props.classNameRequired.appointment_date_to_required}
                        required={true}
                        value={this.props.appointmentDateTo}
                        minDate={this.props.appointmentDate}
                        onChange={this.onChangeAppointmentDateTo}
                        placeholder="ระบุวันที่"
                    />
                </div>
                <div className="p-col-12 p-lg-2" />

                {/* ROW5 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="collector_id" className="p-label-right">ทีมขนส่ง </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <AutoComplete
                        id="collector_id"
                        name="collector_name"
                        placeholder="ระบุชื่อทีมขนส่งซีซาเล้ง"
                        value={this.props.collector_name}
                        onChange={this.onChangeAutocomplete}
                        suggestions={this.props.collectionNameSuggestions}
                        completeMethod={this.onSuggestCollectionNames} />
                </div>
                <div className="p-col-12 p-lg-7" />
            </div>
        )
    }
    renderWastetran = () => {
        return (
            <div className="p-grid">
                {/* ROW1 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="order_id" className="p-label-right">Order id </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <AutoComplete
                        name="order_id"
                        placeholder="ระบุ Order Id"
                        value={this.props.order_id}
                        onChange={this.onChangeAutocomplete}
                        suggestions={this.props.orderIdSuggestions}
                        completeMethod={this.onSuggestorderIds} />
                </div>
                <div className="p-col-12 p-lg-7" />

                {/* ROW2 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="order_name_id" className="p-label-right">รายการ </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <AutoComplete
                        id="order_name_id"
                        name="order_name"
                        placeholder="ระบุชื่อรายการ"
                        value={this.props.order_name}
                        onChange={this.onChangeAutocomplete}
                        suggestions={this.props.orderNameSuggestions}
                        completeMethod={this.onSuggestorderNames} />
                </div>
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="r_id" className="p-label-right">สถานะ </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Dropdown style={{ width: '100%' }}
                        id="status_id"
                        value={this.props.statusValue}
                        options={this.props.statusList}
                        onChange={this.onChangeStatus}
                        optionLabel="name"
                        placeholder="ระบุสถานะ"
                    />
                </div>
                <div className="p-col-12 p-lg-2" />

               {/* ROW3 */}
               <div className="p-col-12 p-lg-1">
                    <label htmlFor="pickup_date_id" className="p-label-right">วันนัดรับ </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Calendar showIcon={true}
                        dateFormat="dd/mm/yy"
                        id="pickup_date_id"
                        name="pickup_date"
                        className={this.props.classNameRequired.pickup_date_required}
                        required={true}
                        value={this.props.pickupDate}
                        maxDate={this.props.pickupDateTo}
                        onChange={this.onChangePickupdate}
                        placeholder="ระบุวันที่"
                    />
                </div>
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="pickup_date_to_id" className="p-label-right">ถึง </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Calendar showIcon={true} dateFormat="dd/mm/yy"
                        id="pickup_date_to_id"
                        name="pickup_date_to"
                        className={this.props.classNameRequired.pickup_date_to_required}
                        required={true}
                        value={this.props.pickupDateTo}
                        minDate={this.props.pickupDate}
                        onChange={this.onChangePickupdateTo}
                        placeholder="ระบุวันที่"
                    />
                </div>
                <div className="p-col-12 p-lg-2" />

                {/* ROW4 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="appointment_date_id" className="p-label-right">วันนัดส่ง </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Calendar showIcon={true} dateFormat="dd/mm/yy"
                        id="appointment_date_id"
                        name="appointment_date"
                        className={this.props.classNameRequired.appointment_date_required}
                        required={true}
                        value={this.props.appointmentDate}
                        maxDate={this.props.appointmentDateTo}
                        onChange={this.onChangeAppointmentDate}
                        placeholder="ระบุวันที่"
                    />
                </div>
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="appointment_date_to_id" className="p-label-right">ถึง </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Calendar showIcon={true} dateFormat="dd/mm/yy"
                        id="appointment_date_to_id"
                        name="appointment_date_to"
                        className={this.props.classNameRequired.appointment_date_to_required}
                        required={true}
                        value={this.props.appointmentDateTo}
                        minDate={this.props.appointmentDate}
                        onChange={this.onChangeAppointmentDateTo}
                        placeholder="ระบุวันที่"
                    />
                </div>
                <div className="p-col-12 p-lg-2" />

                {/* ROW5 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="collector_id" className="p-label-right">ชื่อผู้ขาย </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <AutoComplete
                        id="collector_id"
                        name="collector_name"
                        placeholder="ระบุชื่อผู้ขาย"
                        value={this.props.collector_name}
                        onChange={this.onChangeAutocomplete}
                        suggestions={this.props.collectionNameSuggestions}
                        completeMethod={this.onSuggestCollectionNames} />
                </div>

                <div className="p-col-12 p-lg-1">
                    <label htmlFor="shop_name_id" className="p-label-right">ชื่อศูนย์จัดการฯ </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <AutoComplete
                        id="shop_name_id"
                        name="shop_name"
                        placeholder="ชื่อศูนย์จัดการฯ"
                        value={this.props.shop_name}
                        onChange={this.onChangeAutocomplete}
                        suggestions={this.props.shopNameSuggestions}
                        completeMethod={this.onSuggestShopNames} />
                </div>
                <div className="p-col-12 p-lg-2" />

            </div>
        )
    }
    renderWastepro = () => {
        return (
            <div className="p-grid">
                {/* ROW1 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="order_id" className="p-label-right">Order id </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <AutoComplete
                        placeholder="Search Order Id"
                        name="order_id"
                        placeholder="ระบุ Order Id"
                        value={this.props.order_id}
                        onChange={this.onChangeAutocomplete}
                        suggestions={this.props.orderIdSuggestions}
                        completeMethod={this.onSuggestorderIds} />
                </div>
                <div className="p-col-12 p-lg-7" />

                {/* ROW2 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="order_name_id" className="p-label-right">รายการ </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <AutoComplete
                        id="order_name_id"
                        name="order_name"
                        placeholder="ระบุชื่อรายการ"
                        value={this.props.order_name}
                        onChange={this.onChangeAutocomplete}
                        suggestions={this.props.orderNameSuggestions}
                        completeMethod={this.onSuggestorderNames} />
                </div>
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="r_id" className="p-label-right">สถานะ </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Dropdown style={{ width: '100%' }}
                        id="status_id"
                        value={this.props.statusValue}
                        options={filter(this.props.statusList, n => n.value != 0)}
                        onChange={this.onChangeStatus}
                        optionLabel="name"
                        placeholder="ระบุสถานะ"
                    />
                </div>
                <div className="p-col-12 p-lg-2" />

                {/* ROW3 */}
               <div className="p-col-12 p-lg-1">
                    <label htmlFor="pickup_date_id" className="p-label-right">วันที่ส่ง </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Calendar showIcon={true}
                        dateFormat="dd/mm/yy"
                        id="pickup_date_id"
                        name="pickup_date"
                        className={this.props.classNameRequired.pickup_date_required}
                        required={true}
                        value={this.props.pickupDate}
                        maxDate={this.props.pickupDateTo}
                        onChange={this.onChangePickupdate}
                        placeholder="ระบุวันที่"
                    />
                </div>
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="pickup_date_to_id" className="p-label-right">ถึง </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Calendar showIcon={true} dateFormat="dd/mm/yy"
                        id="pickup_date_to_id"
                        name="pickup_date_to"
                        className={this.props.classNameRequired.pickup_date_to_required}
                        required={true}
                        value={this.props.pickupDateTo}
                        minDate={this.props.pickupDate}
                        onChange={this.onChangePickupdateTo}
                        placeholder="ระบุวันที่"
                    />
                </div>
                <div className="p-col-12 p-lg-2" />

                {/* ROW5 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="collector_id" className="p-label-right">ทีมขนส่ง </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <AutoComplete
                        id="collector_id"
                        name="collector_name"
                        placeholder="ระบุชื่อทีมขนส่งซีซาเล้ง"
                        value={this.props.collector_name}
                        onChange={this.onChangeAutocomplete}
                        suggestions={this.props.collectionNameSuggestions}
                        completeMethod={this.onSuggestCollectionNames} />
                </div>
                <div className="p-col-12 p-lg-7" />
            </div>
        )
    }

    renderAdminWithdraw = () => {
        return (
            <div className="p-grid">

                {/* ROW2 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="order_id" className="p-label-right">ข้อมูลสมชิก </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <AutoComplete
                        id="order_id"
                        placeholder="ระบุข้อมูลสมชิก"
                        name="order_id"
                        value={this.props.order_id}
                        onChange={this.onChangeAutocomplete}
                        suggestions={this.props.orderIdSuggestions}
                        completeMethod={this.onSuggestorderIds} />
                </div>
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="r_id" className="p-label-right">สถานะ </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Dropdown style={{ width: '100%' }}
                        id="status_id"
                        value={this.props.statusValue}
                        options={this.props.statusList}
                        onChange={this.onChangeStatus}
                        optionLabel="name"
                        placeholder="ระบุสถานะ"
                    />
                </div>
                <div className="p-col-12 p-lg-2" />

                {/* ROW3 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="pickup_date_id" className="p-label-right">ช่วงวันที่แจ้ง </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Calendar showIcon={true}
                        dateFormat="dd/mm/yy"
                        id="pickup_date_id"
                        name="pickup_date"
                        className={this.props.classNameRequired.pickup_date_required}
                        required={true}
                        value={this.props.pickupDate}
                        maxDate={this.props.pickupDateTo}
                        onChange={this.onChangePickupdate}
                        placeholder="ระบุวันที่"
                    />
                </div>
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="pickup_date_to_id" className="p-label-right">ถึง </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Calendar showIcon={true} dateFormat="dd/mm/yy"
                        id="pickup_date_to_id"
                        name="pickup_date_to"
                        className={this.props.classNameRequired.pickup_date_to_required}
                        required={true}
                        value={this.props.pickupDateTo}
                        minDate={this.props.pickupDate}
                        onChange={this.onChangePickupdateTo}
                        placeholder="ระบุวันที่"
                    />
                </div>
                <div className="p-col-12 p-lg-2" />

                {/* ROW4 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="appointment_date_id" className="p-label-right">ช่วงวันที่โอน </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Calendar showIcon={true} dateFormat="dd/mm/yy"
                        id="appointment_date_id"
                        name="appointment_date"
                        className={this.props.classNameRequired.appointment_date_required}
                        required={true}
                        value={this.props.appointmentDate}
                        maxDate={this.props.appointmentDateTo}
                        onChange={this.onChangeAppointmentDate}
                        placeholder="ระบุวันที่"
                    />
                </div>
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="appointment_date_to_id" className="p-label-right">ถึง </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Calendar showIcon={true} dateFormat="dd/mm/yy"
                        id="appointment_date_to_id"
                        name="appointment_date_to"
                        className={this.props.classNameRequired.appointment_date_to_required}
                        required={true}
                        value={this.props.appointmentDateTo}
                        minDate={this.props.appointmentDate}
                        onChange={this.onChangeAppointmentDateTo}
                        placeholder="ระบุวันที่"
                    />
                </div>
                <div className="p-col-12 p-lg-2" />

                {/* ROW5 */}
                <div className="p-col-12 p-lg-1">
                    <label htmlFor="collector_id" className="p-label-right">ธนาคาร </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Dropdown style={{ width: '100%' }}
                        id="bank"
                        value={this.props.bank}
                        options={this.props.banks}
                        onChange={this.onChangeBank}
                        optionLabel="bank_name"
                        placeholder="ระบุสถานะ"
                    />
                </div>

                <div className="p-col-12 p-lg-1">
                    <label htmlFor="collector_id" className="p-label-right">ผู้ดูแล </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    
                    <AutoComplete
                        id="collector_id"
                        name="collector_name"
                        placeholder="ระบุชื่อทีมขนส่งซีซาเล้ง"
                        value={this.props.collector_name}
                        onChange={this.onChangeAutocomplete}
                        suggestions={this.props.collectionNameSuggestions}
                        completeMethod={this.onSuggestCollectionNames} />
                </div>
                <div className="p-col-12 p-lg-2" />
            </div>
        )
    }

    render() {
        console.log("SearchView :render")
        const {
            mode
        } = this.props
        console.log(`mode ::${mode}`)
        let renderMode;

        switch (mode) {
            case 'wastegen':
                renderMode = this.renderWastegen()
                break;
            case 'wastetran':
                renderMode = this.renderWastetran()
                break;
            case 'wastepro':
                renderMode = this.renderWastepro()
                break;
            case 'admin-withdraw':
                renderMode = this.renderAdminWithdraw()
                break;
            default:
                renderMode = this.renderWastegen()
                break;
        }

        return (
            <div className="p-grid">
                {renderMode}
                <div >
                    {!this.props.orderSearch.isLoading &&
                   
                        <div className="p-col-12"> 
                            <br/>
                            <Button
                                style={{marginRight:"20px"}}
                                label="ค้นหา"
                                icon="fa fa-search"
                                onClick={this.onClickSearch}
                            />
                            <Button
                                
                                label="ยกเลิก"
                                className="p-button-warning"
                                onClick={this.onClickCancel}
                            />
                        </div>
                    }
                </div>
                <div className="p-col-12 p-lg-4" />
                <div className="p-col-12 p-lg-4" />

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    orderSearch: state.orderSearch
})


export default connect(mapStateToProps)(SearchView)
