import {
    WASTEGEN_ACCOUNT_BALANCE,
    WASTEGEN_ACCOUNT_WITHDRAW,
    WASTEGEN_ACCOUNT_WITHDRAW_HISTORY,
    WASTEGEN_DASH_LAST_ORDER,
    WASTEGEN_DASH_PRODUCT,
    WASTEGEN_CHANGE_PIN,
    WASTEGEN_DONATE,
    WASTEGEN_TRANSFER_DONATE,
    WASTEGEN_DONATE_HISTORY,
    WASTETGEN_REGIS_WASTETRAN
} from '../service/actionType'

const initialState = {
    data: null,
    isFailed: false,
    isLoading: false
}

export function wastegenBalance(state = initialState, action) {
    switch (action.type) {
        case WASTEGEN_ACCOUNT_BALANCE.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTEGEN_ACCOUNT_BALANCE.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTEGEN_ACCOUNT_BALANCE.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastegenWithdraw(state = initialState, action) {
    switch (action.type) {
        case WASTEGEN_ACCOUNT_WITHDRAW.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTEGEN_ACCOUNT_WITHDRAW.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTEGEN_ACCOUNT_WITHDRAW.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastegenWithdrawHistory(state = initialState, action) {
    switch (action.type) {
        case WASTEGEN_ACCOUNT_WITHDRAW_HISTORY.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTEGEN_ACCOUNT_WITHDRAW_HISTORY.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTEGEN_ACCOUNT_WITHDRAW_HISTORY.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function getWastegenDashLastOrder(state = initialState, action) {
    switch (action.type) {
        case WASTEGEN_DASH_LAST_ORDER.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTEGEN_DASH_LAST_ORDER.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTEGEN_DASH_LAST_ORDER.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}
export function getWastegenDashProduct(state = initialState, action) {
    switch (action.type) {
        case WASTEGEN_DASH_PRODUCT.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTEGEN_DASH_PRODUCT.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTEGEN_DASH_PRODUCT.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastegenChangePin(state = initialState, action) {
    switch (action.type) {
        case WASTEGEN_CHANGE_PIN.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTEGEN_CHANGE_PIN.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTEGEN_CHANGE_PIN.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastegenDonate(state = initialState, action) {
    switch (action.type) {
        case WASTEGEN_DONATE.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTEGEN_DONATE.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTEGEN_DONATE.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastegenTransfer(state = initialState, action) {
    switch (action.type) {
        case WASTEGEN_TRANSFER_DONATE.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTEGEN_TRANSFER_DONATE.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTEGEN_TRANSFER_DONATE.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastegenDonateHistory(state = initialState, action) {
    switch (action.type) {
        case WASTEGEN_DONATE_HISTORY.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTEGEN_DONATE_HISTORY.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTEGEN_DONATE_HISTORY.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastegenRegisWastetran(state = initialState, action) {
    switch (action.type) {
        case WASTETGEN_REGIS_WASTETRAN.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTETGEN_REGIS_WASTETRAN.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTETGEN_REGIS_WASTETRAN.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}