

import { API_ROOT } from '../constants/api'
import axios from './axios'
import {
  LOGIN,
  USER_TYPE,
  LOGOUT,
  USER_INFO,
  UPDATE_WASTEGEN_PROFILE,
  UPDATE_WASTEPRO_PROFILE,
  UPDATE_WASTETRAN_PROFILE,
  CHANGE_PASSWORD,
  USER_ANNOUNCE,
  USER_CERT_LIST
} from './actionType'
import { SecureUtils } from '../utils';


export const userService = {
  login,
  logout,
};

export const login = (username, password) => (dispatch) => {
  dispatch({ type: LOGIN.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/login`,
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify({ username, password })
  })
    .then((res) => {
      if (res.status == 200) {
        return res
      }
      throw Error(res.status)
    })
    .then((res) => {
      //> store token  in local storage to keep user logged in between page refreshes
      localStorage.setItem('access-token', res.data);

      let user = {
        username: username,
        password: password
      }

      SecureUtils.setItem('user', user)

      dispatch({ type: LOGIN.SUCCESS, payload: res.data })
    }).catch((error) => {
      dispatch({ type: LOGIN.FAILURE, payload: error.message })
    })
}

export const loginExternal = (model) => (dispatch) => {
  dispatch({ type: LOGIN.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/login/external`,
    headers: { 'Content-Type': 'application/json' },
    data: JSON.stringify(model)
  }).then((res) => {
    //> store token  in local storage to keep user logged in between page refreshes
    localStorage.setItem('access-token', res.data);
    dispatch({ type: LOGIN.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: LOGIN.FAILURE, payload: error.message })
  })
}

export const logout = () => (dispatch) => {
  //> remove user from local storage to log user out
  // localStorage.removeItem('access-token');
  // localStorage.removeItem('user-type');
  // localStorage.removeItem('auth-facebook');
  // localStorage.removeItem('auth-google');
  SecureUtils.clear();
  dispatch({ type: LOGOUT.SUCCESS, accessToken: null, userType: null })
}


export const getUserType = () => (dispatch) => {
  dispatch({ type: USER_TYPE.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/Profile`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  })
    .then((res) => {
      if (res.status == 200) {
        return res
      }
      throw Error(res.status)
    })
    .then((res) => {

      let wasteTypeId = 0;
      let userType = res.data.user_wasteTypes;
      if (userType) {
        userType.map(rs => {
          if (rs.wasteType > wasteTypeId) {
            wasteTypeId = rs.wasteType
          }
        })
      }

      let wasteTypeName = '';
      switch (wasteTypeId) {
        case 1:
          wasteTypeName = 'wastegen';
          localStorage.setItem('user-type', wasteTypeName);
          break;
        case 2:
          wasteTypeName = 'wastetran';
          localStorage.setItem('user-type', wasteTypeName);
          break;
        case 3:
          wasteTypeName = 'wastepro';
          localStorage.setItem('user-type', wasteTypeName);
          break;
        case 4:
          wasteTypeName = 'administrator';
          localStorage.setItem('user-type', wasteTypeName);
          break;
        default:
          localStorage.removeItem('user-type')
          break;
      }

      dispatch({ type: USER_TYPE.SUCCESS, payload: wasteTypeName })
    }).catch((error) => {
      dispatch({ type: USER_TYPE.FAILURE, payload: error.message })
      return error;
    })
}

export const getUserTypeFunction = () => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/Profile`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  })
    .then((res) => {

      let wasteTypeId = 0;
      let userType = res.data.user_wasteTypes;
      if (userType) {
        userType.map(rs => {
          if (rs.wasteType > wasteTypeId) {
            wasteTypeId = rs.wasteType
          }
        })
      }

      let wasteTypeName = '';
      switch (wasteTypeId) {
        case 1:
          wasteTypeName = 'wastegen';
          localStorage.setItem('user-type', wasteTypeName);
          break;
        case 2:
          wasteTypeName = 'wastetran';
          localStorage.setItem('user-type', wasteTypeName);
          break;
        case 3:
          wasteTypeName = 'wastepro';
          localStorage.setItem('user-type', wasteTypeName);
          break;
        case 4:
          wasteTypeName = 'administrator';
          localStorage.setItem('user-type', wasteTypeName);
          break;
        default:
          localStorage.removeItem('user-type')
          break;
      }
      return true;
    }).catch((error) => {
      return false;
    })
}


export const getUserInfo = () => (dispatch) => {
  dispatch({ type: USER_INFO.REQUEST, isLoading: true })
  const role = localStorage.getItem('user-type')
  console.log('current role', role)
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/Profile/byrole/${role}`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  })
    .then((res) => {
      let userInfo = res.data;
      if (userInfo && userInfo.user_wasteTypes) {
        for (let key in userInfo.user_wasteTypes) {
          SecureUtils.setItem("uid", userInfo.user_wasteTypes[key].uid)
          //SecureUtils.setItem("wasteType", userInfo.user_wasteTypes[key].wasteType)
        }
      }
      SecureUtils.setItem("bank_code", userInfo.bank_code);
      dispatch({ type: USER_INFO.SUCCESS, payload: res.data })
    }).catch((error) => {
      dispatch({ type: USER_INFO.FAILURE, error })
    })
}

export const updateWastegenProfile = (data) => (dispatch) => {
  dispatch({ type: UPDATE_WASTEGEN_PROFILE.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastegen/profile/update`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    data: data
  }).then((res) => {
    dispatch({ type: UPDATE_WASTEGEN_PROFILE.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: UPDATE_WASTEGEN_PROFILE.FAILURE, payload: error.message })
  })
}

export const updateWasteproProfile = (data) => (dispatch) => {
  dispatch({ type: UPDATE_WASTEPRO_PROFILE.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastepro/profile/update`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    data: data
  }).then((res) => {
    dispatch({ type: UPDATE_WASTEPRO_PROFILE.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: UPDATE_WASTEPRO_PROFILE.FAILURE, payload: error.message })
  })
}


export const updateWastetranProfile = (data) => (dispatch) => {
  dispatch({ type: UPDATE_WASTETRAN_PROFILE.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastetran/profile/update`,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    data: data
  }).then((res) => {
    dispatch({ type: UPDATE_WASTETRAN_PROFILE.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: UPDATE_WASTETRAN_PROFILE.FAILURE, payload: error.message })
  })
}

export const changePassword = (data) => (dispatch) => {
  dispatch({ type: CHANGE_PASSWORD.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/changepassword`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    data: data
  })
    .then(res => {
      if (res.status == 200) {
        return res
      }
      throw Error(res.status)
    })
    .then((res) => {
      dispatch({ type: CHANGE_PASSWORD.SUCCESS, payload: res.data })
    }).catch((error) => {
      dispatch({ type: CHANGE_PASSWORD.FAILURE, payload: error.response.data })
    })
}

export const userAnnounce = () => (dispatch) => {
  dispatch({ type: USER_ANNOUNCE.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/Announcements`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  }).then(res => {
    if (res.status == 200) {
      return res
    }
    throw Error(res.status)
  }).then((res) => {
    dispatch({ type: USER_ANNOUNCE.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: USER_ANNOUNCE.FAILURE, error })
  })
}

export const updateCertImage = (data) => (dispatch) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/certification/updateimage`,
    data: data,
    headers: { 'Content-Type': 'multipart/form-data' ,
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }).then((res) => {
    return res
  }).catch((error) => {
    console.log(error)
    return error
  })
} 

export const getCertAll = () => (dispatch) => {
  dispatch({ type: USER_CERT_LIST.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/certification/GetByHolderUID`,
    headers: { 'Content-Type': 'application/json' ,
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }).then((res) => {
    dispatch({ type: USER_CERT_LIST.SUCCESS, payload: res.data })
  }).catch((error) => {
    console.log(error)
    dispatch({ type: USER_CERT_LIST.FAILURE, error })
  })
}

export const getCertFile = (filename) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/certification/getfile/${filename}`,
    responseType: 'arraybuffer'
  }).then((res) => {
    const buffer = Buffer.from(res.data, 'base64');
    return buffer
  }).catch((error) => {
    console.log(error)
    return error
  })
} 

export const otpResetPasswordSend = (mobile_no) =>{
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/otp-reset-password/send/${mobile_no}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}
export const otpResetPasswordResend = (mobile_no) =>{
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/otp-reset-password/resend/${mobile_no}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}
export const otpVerifyChangePassword = (data) =>{
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/otp/verify-changepassword`,
    data: data
  }).then((res) => {
    return res;
  }).catch((error) => {
    return error;
  })
}

export const checkExitPhoneAndName = (model) =>{
  
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/checkexit`,
    data: model
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const checkExit = (username) =>{
  
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/checkexit/${username}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}