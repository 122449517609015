import { generateActionType } from "../utils/utilFunctions"
export const BANK = generateActionType('BANK')
export const PROVINCE = generateActionType('PROVINCE')
export const DISTRICT = generateActionType('DISTRICT')
export const SUB_DISTRICT = generateActionType('SUB_DISTRICT')
export const CARD_PROVINCE = generateActionType('CARD_PROVINCE')
export const CARD_DISTRICT = generateActionType('CARD_DISTRICT')
export const CARD_SUB_DISTRICT = generateActionType('CARD_SUB_DISTRICT')
export const RPT_APLC_WG = generateActionType('RPT_APLC_WG')
export const RPT_APLC_WT = generateActionType('RPT_APLC_WT')
export const RPT_APLC_WP = generateActionType('RPT_APLC_WP')
export const DHB_USER = generateActionType('DHB_USER')

export const LOGIN = generateActionType('LOGIN')
export const LOGOUT = generateActionType('LOGOUT')
export const USER_TYPE = generateActionType('USER_TYPE')
export const USER_INFO = generateActionType('USER_INFO')
export const CHANGE_PASSWORD = generateActionType('CHANGE_PASSWORD')

export const CHIP_PROVINCE = generateActionType('CHIP_PROVINCE')
export const CHIP_DISTRICT = generateActionType('CHIP_DISTRICT')
export const ARR_CHIP_DISTRICT = generateActionType('ARR_CHIP_DISTRICT')

//> Wastegen
export const UPDATE_WASTEGEN_PROFILE = generateActionType('UPDATE_WASTEGEN_PROFILE')

export const WASTEGEN_ACCOUNT_BALANCE = generateActionType('WASTEGEN_ACCOUNT_BALANCE')
export const WASTEGEN_ACCOUNT_WITHDRAW = generateActionType('WASTEGEN_ACCOUNT_WITHDRAW')
export const WASTEGEN_ACCOUNT_WITHDRAW_HISTORY = generateActionType('WASTEGEN_ACCOUNT_WITHDRAW_HISTORY')

export const WASTEGEN_DASH_LAST_ORDER = generateActionType('WASTEGEN_DASH_LAST_ORDER')
export const WASTEGEN_DASH_PRODUCT = generateActionType('WASTEGEN_DASH_PRODUCT')

export const WASTEGEN_CHANGE_PIN = generateActionType('WASTEGEN_CHANGE_PIN')

export const WASTEGEN_DONATE = generateActionType('WASTEGEN_DONATE')
export const WASTEGEN_TRANSFER_DONATE = generateActionType('WASTEGEN_TRANSFER_DONATE')
export const   WASTEGEN_DONATE_HISTORY = generateActionType('WASTEGEN_DONATE_HISTORY')
export const WASTETGEN_REGIS_WASTETRAN = generateActionType('WASTETGEN_REGIS_WASTETRAN')

//> Wastetran
export const UPDATE_WASTETRAN_PROFILE = generateActionType('UPDATE_WASTETRAN_PROFILE')

export const WASTETRAN_ORDER_SEARCH_NOTI = generateActionType('WASTETRAN_ORDER_SEARCH_NOTI')

export const WASTETRAN_ACCOUNT_BALANCE = generateActionType('WASTETRAN_ACCOUNT_BALANCE')
export const WASTETRAN_ACCOUNT_WITHDRAW = generateActionType('WASTETRAN_ACCOUNT_WITHDRAW')
export const WASTETRAN_ACCOUNT_WITHDRAW_HISTORY = generateActionType('WASTETRAN_ACCOUNT_WITHDRAW_HISTORY')

export const WASTETRAN_AUTO_ORDER = generateActionType('WASTETRAN_AUTO_ORDER')

export const WASTETRAN_DASH_PRODUCT = generateActionType('WASTETRAN_DASH_PRODUCT')

export const WASTETRAN_DASH_ORDER_STAT = generateActionType('WASTETRAN_DASH_ORDER_STAT')

export const WASTETRAN_FILE_DELETE= generateActionType('WASTETRAN_FILE_DELETE')

export const WASTETRAN_CHANGE_PIN= generateActionType('WASTETRAN_CHANGE_PIN')

export const WASTETRAN_DONATE = generateActionType('WASTETRAN_DONATE')
export const WASTETRAN_TRANSFER_DONATE = generateActionType('WASTETRAN_TRANSFER_DONATE')
export const WASTETRAN_DONATE_HISTORY = generateActionType('WASTETRAN_DONATE_HISTORY')
export const WASTETRAN_REGIS_WASTEGEN = generateActionType('WASTETRAN_REGIS_WASTEGEN')

//> Wastepro
export const UPDATE_WASTEPRO_PROFILE = generateActionType('UPDATE_WASTEPRO_PROFILE')
export const PRDT = generateActionType('GET_FILE_PRODUCT_TYPE')
export const PRD = generateActionType('GET_FILE_PRODUCT')
export const WASTEPRO_ORDER_SEARCH = generateActionType('WASTEPRO_ORDER_SEARCH')

export const WASTEPRO_DASH_PAYMENT = generateActionType('WASTEPRO_DASH_PAYMENT')
export const WASTEPRO_DASH_PRODUCT = generateActionType('WASTEPRO_DASH_PRODUCT')
export const WASTEPRO_DASH_SUMMARY_OVERDUE = generateActionType('WASTEPRO_DASH_SUMMARY_OVERDUE')
export const WASTEPRO_DASH_SUMMARY_PAID = generateActionType('WASTEPRO_DASH_SUMMARY_PAID')
export const WASTEPRO_DASH_SUMMARY_PRODUCTS = generateActionType('WASTEPRO_DASH_SUMMARY_PRODUCTS')

export const WASTEPRO_DASH_ORDER_STAT= generateActionType('WASTEPRO_DASH_ORDER_STAT')

export const WASTEPRO_FILE_DELETE= generateActionType('WASTEPRO_FILE_DELETE')
//> ORDER
export const ORDER_SEARCH = generateActionType('ORDER_SEARCH')
export const ORDER_AUTO_COMP = generateActionType('ORDER_AUTO_COMP')

//>autocomplete
export const AUTO_ADMINALL = generateActionType('AUTO_ADMINALL')
export const AUTO_PROD = generateActionType('AUTO_PROD')
export const AUTO_WASTEALL = generateActionType('AUTO_WASTEALL')
export const AUTO_WASTEGEN = generateActionType('AUTO_WASTEGEN')
export const AUTO_WASTETRAN = generateActionType('AUTO_WASTETRAN')
export const AUTO_WASTEPRO = generateActionType('AUTO_WASTEPRO')

//>ADMIN
export const ADMIN_ANNOUNCE_ADD = generateActionType('ADMIN_ANNOUNCE_ADD')
export const ADMIN_DASH_SUMMARY_AREA = generateActionType('ADMIN_DASH_SUMMARY_AREA')
export const ADMIN_DASH_SUMMARY_AREA_SEARCH = generateActionType('ADMIN_DASH_SUMMARY_AREA_SEARCH')
export const ADMIN_DASH_SUMMARY_WITHDRAW = generateActionType('ADMIN_DASH_SUMMARY_WITHDRAW')
export const ADMIN_USER_LIST = generateActionType('ADMIN_USER_LIST')
export const ADMIN_USER_LIST_CHAT = generateActionType('ADMIN_USER_LIST_CHAT')
export const LOAD_MORE_ADMIN_USER_LIST = generateActionType('LOAD_MORE_ADMIN_USER_LIST')
export const ADD_NEW_ADMIN_USER_LIST = generateActionType('ADD_NEW_ADMIN_USER_LIST')

//>USER
export const USER_ANNOUNCE = generateActionType('USER_ANNOUNCE')

//>CERTIFICATE
export const USER_CERT_LIST = generateActionType('USER_CERT_LIST')
export const ADMIN_CERT_TEMPLATE_LIST = generateActionType('ADMIN_CERT_TEMPLATE_LIST')
export const ADMIN_CERT_RESULT_LIST = generateActionType('ADMIN_CERT_RESULT_LIST')

//ChatMessage
export const GET_CHAT_MESSAGE_BY_ROOM = generateActionType('GET_CHAT_MESSAGE_BY_ROOM')
export const LOAD_MORE_CHAT_MESSAGE = generateActionType('LOAD_MORE_CHAT_MESSAGE')
export const ADD_NEW_CHAT_MESSAGE = generateActionType('ADD_NEW_CHAT_MESSAGE')
export const SEND_CHAT_IMAGE = generateActionType('SEND_CHAT_IMAGE')
export const GET_CHAT_LIST = generateActionType('GET_CHAT_LIST')
export const GET_CHAT_PICKUP_BADGE = generateActionType('GET_CHAT_PICKUP_BADGE')
export const GET_CHAT_DELIVERY_BADGE = generateActionType('GET_CHAT_DELIVERY_BADGE')
export const GET_CHAT_ADMIN_BADGE = generateActionType('GET_CHAT_ADMIN_BADGE')

//>MASTER DATA
export const MASTER_SETTING_PARAMETER = generateActionType('ADMIN_SETTING_PARAMETER')