import {
  RPT_APLC_WG,RPT_APLC_WP,RPT_APLC_WT
} from '../service/actionType'

export const initialState = {
  wgApplicants: [],
  wtApplicants: [],
  wpApplicants: [],
  
}

export default (state = initialState, action) => {
  switch (action.type) {
    case RPT_APLC_WG.REQUEST:
    case RPT_APLC_WT.REQUEST:
    case RPT_APLC_WP.REQUEST:
    {
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    }
    case RPT_APLC_WG.FAILURE:
    case RPT_APLC_WT.FAILURE:
    case RPT_APLC_WT.FAILURE:
   {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    }

    case RPT_APLC_WG.SUCCESS: 
    {
      return {
        ...state,
        isFetching: false,
        error: null,
        wgApplicants: action.payload
      }
    }
    
    case RPT_APLC_WT.SUCCESS:
    {
          return {
            ...state,
            isFetching: false,
            error: null,
            wtApplicants: action.payload
          }
     }
        
    case RPT_APLC_WP.SUCCESS:
    {
      return {
        ...state,
        isFetching: false,
        error: null,
        wpApplicants: action.payload
      }
    }
    
    
    default:
      return state
  }
}
