import {
  BANK, 
  PRDT, 
  PRD,
  MASTER_SETTING_PARAMETER
} from '../service/actionType'

const initialState = {
  banks: [],
  productType: [],
  product: [],
}

const initialStateMaster = {
  data: null,
  isFailed: false,
  isLoading: false
}

export function master(state = initialState, action) {
  switch (action.type) {
    case BANK.REQUEST:
    case PRDT.REQUEST:
    case PRD.REQUEST:
      {
        return {
          ...state,
          isFetching: true,
          error: null,
        }
      }
    case BANK.FAILURE:
    case PRDT.FAILURE:
    case PRD.FAILURE:
      {
        return {
          ...state,
          isFetching: false,
          error: action.error,
        }
      }

    case BANK.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        banks: action.payload
      }
    }
    case PRDT.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        productType: action.payload
      }
    }
    case PRD.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        product: action.payload
      }
    }


    default:
      return state
  }
}

export function settingParameter(state = initialStateMaster, action) {
  switch (action.type) {
      case MASTER_SETTING_PARAMETER.REQUEST:
          return { ...state, isFailed: false, isLoading: true };
      case MASTER_SETTING_PARAMETER.SUCCESS:
          return { ...state, isFailed: false, data: action.payload, isLoading: false };
      case MASTER_SETTING_PARAMETER.FAILURE:
          return { ...state, isFailed: true, data: action.payload, isLoading: false };
      default:
          return state
  }
}