
import { API_ROOT } from '../constants/api'
import axios from './axios'

import {
  WASTETRAN_ORDER_SEARCH_NOTI,
  WASTETRAN_ACCOUNT_BALANCE,
  WASTETRAN_ACCOUNT_WITHDRAW,
  WASTETRAN_ACCOUNT_WITHDRAW_HISTORY,
  WASTETRAN_AUTO_ORDER,
  WASTETRAN_DASH_PRODUCT,
  WASTETRAN_DASH_ORDER_STAT,
  WASTETRAN_CHANGE_PIN,
  WASTETRAN_DONATE,
  WASTETRAN_TRANSFER_DONATE,
  WASTETRAN_DONATE_HISTORY,
  WASTETRAN_REGIS_WASTEGEN
} from './actionType'
import { SecureUtils } from '../utils'


export const wastetranOrderSearchNoti = (criteria) => (dispatch) => {
  dispatch({ type: WASTETRAN_ORDER_SEARCH_NOTI.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastetran/order/salereport/search`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    data: JSON.stringify(criteria)
  }).then((res) => {
    dispatch({ type: WASTETRAN_ORDER_SEARCH_NOTI.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTETRAN_ORDER_SEARCH_NOTI.FAILURE, payload: error.message })
  })
}


export const getBalance = () => (dispatch) => {
  dispatch({ type: WASTETRAN_ACCOUNT_BALANCE.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastetran/account/balance`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  })
    .then((res) => {
      dispatch({ type: WASTETRAN_ACCOUNT_BALANCE.SUCCESS, payload: res.data })
    }).catch((error) => {
      dispatch({ type: WASTETRAN_ACCOUNT_BALANCE.FAILURE, error })
    })
}

export const saveWithdrawal = (withdrawal_thb, bank_withdraw_code) => (dispatch) => {
  let requestParam = {
    "waste_uid": SecureUtils.getItem('uid'),
    "withdrawal_thb": withdrawal_thb,
    "bank_withdraw_code": bank_withdraw_code,
    "wasteType": 2
  };

  dispatch({ type: WASTETRAN_ACCOUNT_WITHDRAW.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastetran/account/withdraw`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    data: requestParam
  })
    .then((res) => {
      dispatch({ type: WASTETRAN_ACCOUNT_WITHDRAW.SUCCESS, payload: res.data })
    }).catch((error) => {
      if (error.response && error.response.data&&error.response.data.length<50) {
        dispatch({ type: WASTETRAN_ACCOUNT_WITHDRAW.FAILURE, payload: error.response.data })
      } else {
        dispatch({ type: WASTETRAN_ACCOUNT_WITHDRAW.FAILURE, payload: 'รหัสถอนเงินไม่ถูกต้อง' })
      }
    })
}

export const getWithdrawHistory = () => (dispatch) => {
  dispatch({ type: WASTETRAN_ACCOUNT_WITHDRAW_HISTORY.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastetran/account/withdraw/history`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  })
    .then((res) => {
      dispatch({ type: WASTETRAN_ACCOUNT_WITHDRAW_HISTORY.SUCCESS, payload: res.data })
    }).catch((error) => {
      dispatch({ type: WASTETRAN_ACCOUNT_WITHDRAW_HISTORY.FAILURE, error })
    })
}

export const wastetranAutoOrder = (search_name) => (dispatch) => {
  console.log(search_name)
  dispatch({ type: WASTETRAN_AUTO_ORDER.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastetran/order/autocomplete/${search_name}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }).then((res) => {
    dispatch({ type: WASTETRAN_AUTO_ORDER.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTETRAN_AUTO_ORDER.FAILURE, payload: error.message })
  })
}

export const getWastetranDashProduct = () => (dispatch) => {
  dispatch({ type: WASTETRAN_DASH_PRODUCT.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastetran/dashboard/product`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  }).then(res => {
    if (res.status == 200) {
      return res
    }
    throw Error(res.status)
  }).then((res) => {
    dispatch({ type: WASTETRAN_DASH_PRODUCT.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTETRAN_DASH_PRODUCT.FAILURE, error })
  })
}

export const getWastetranDashOrderStat = () => (dispatch) => {
  dispatch({ type: WASTETRAN_DASH_ORDER_STAT.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastetran/dashboard/orderstat`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  }).then(res => {
    if (res.status == 200) {
      return res
    }
    throw Error(res.status)
  }).then((res) => {
    dispatch({ type: WASTETRAN_DASH_ORDER_STAT.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTETRAN_DASH_ORDER_STAT.FAILURE, error })
  })
}

export const wastetranChangePin = (
  data
) => (dispatch) => {
  dispatch({ type: WASTETRAN_CHANGE_PIN.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastetran/ChangePin`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    data: JSON.stringify(data)
  })
    .then(res => {
      if (res.status == 200) {
        return res
      }
      throw Error(res.status)
    })
    .then((res) => {
      dispatch({ type: WASTETRAN_CHANGE_PIN.SUCCESS, payload: res.data })
    }).catch((error) => {
      dispatch({ type: WASTETRAN_CHANGE_PIN.FAILURE, payload: error.response.data })
    })
}


//> OTP
export const otpResetPinSend = (mobile_no) =>{
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/otp-reset-pin/send/${mobile_no}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const otpResetPinResend = (mobile_no) =>{
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/otp-reset-pin/resend/${mobile_no}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const otpVerifyPin = (mobile_no,otp_code,otp_ref) =>{
  let data = {
      mobile_no: mobile_no,
      otp_code: otp_code,
      otp_ref: otp_ref
  }
  //console.log(data)
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/otp/verify-changepin`,
    data: data
  }).then((res) => {
    return res.data
  }).catch((error) => {
    return error;
  })
}

export const otpVerifyChangePin = (mobile_no,otp_code,otp_ref) =>{
  let data = {
      mobile_no: mobile_no,
      otp_code: otp_code,
      otp_ref: otp_ref
  }
  //console.log(data)
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/otp/verify-changepin`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') },
    data: data
  }).then((res) => {
    return res.data
  }).catch((error) => {
    return error;
  })
}

export const getWastetranDonate = () => (dispatch) => {
  dispatch({ type: WASTETRAN_DONATE.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/donate/organization/getall`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  }).then(res => {
    if (res.status == 200) {
      return res
    }
    throw Error(res.status)
  }).then((res) => {
    dispatch({ type: WASTETRAN_DONATE.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTETRAN_DONATE.FAILURE, error })
  })
}

export const wastetranTransferDonate = (
  data
) => (dispatch) => {
  dispatch({ type: WASTETRAN_TRANSFER_DONATE.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/donate/transfer`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    data: JSON.stringify(data)
  })
    .then(res => {
      if (res.status == 200) {
        return res
      }
      throw Error(res.status)
    })
    .then((res) => {
      dispatch({ type: WASTETRAN_TRANSFER_DONATE.SUCCESS, payload: res.data })
    }).catch((error) => {
      dispatch({ type: WASTETRAN_TRANSFER_DONATE.FAILURE, payload: error.response.data })
    })
}

export const getWasteTranDonateHistory = () => (dispatch) => {
  dispatch({ type: WASTETRAN_DONATE_HISTORY.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastetran/account/donate/history`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  }).then(res => {
    if (res.status == 200) {
      return res
    }
    throw Error(res.status)
  }).then((res) => {
    dispatch({ type: WASTETRAN_DONATE_HISTORY.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTETRAN_DONATE_HISTORY.FAILURE, error })
  })
}

export const wastetranRegisWastegen = () => (dispatch) => {
  dispatch({ type: WASTETRAN_REGIS_WASTEGEN.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastetran/register/wastegen`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  })
    .then((res) => {
      dispatch({ type: WASTETRAN_REGIS_WASTEGEN.SUCCESS, payload: res.data })
    }).catch((error) => {
      dispatch({ type: WASTETRAN_REGIS_WASTEGEN.FAILURE, payload: error.response.data })
    })
}