import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom';
import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';

import { InputText } from 'primereact/components/inputtext/InputText';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import * as qs from 'query-string';
import * as OrderService from '../../service/order.service'
import Moment from 'react-moment';
import moment from 'moment';
import { PDFViewer , PDFDownloadLink} from '@react-pdf/renderer';
import { Document, Page, Text, View, StyleSheet, Font, } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import { MARKER_WITH_LABEL } from 'react-google-maps/lib/constants';


class WasteproInvoiceBuyingPrint extends Component {

    constructor() {
        super();
        console.log("constructor()");
        this.state = {
            order_code: null,
            delivery_date: null,
            orderDetail: [],
            total: {quantity_kk:0,price_per_unit:0,grand_total:0},
        }

        let source = "assets/layout/fonts/Kanit/Kanit-Regular.ttf"
        let sourceBold = "assets/layout/fonts/Kanit/Kanit-Bold.ttf"
        let sourceItalic = "assets/layout/fonts/Kanit/Kanit-Italic.ttf"
        Font.register({ family: 'Kanit-regular', src: source, weight: '400,500', });
        Font.register({ family: 'Kanit-bold', src: sourceBold, weight: 'bold', });
        Font.register({ family: 'Kanit-italic', src: sourceItalic, weight: '400,500', });

        this.RenderViewer = this.RenderViewer.bind(this);

     
        
    }
    componentDidMount(){
        
      
        console.log("1 componentDidMount()");
        if(this.props.location){
            if(this.props.location.search){
                let _order = qs.parse(this.props.location.search);
                if(_order)if(_order.order_code)
                    
                    OrderService.getOrderDetail(_order.order_code).then((obj) => {
                        console.log("1 componentDidMount()--> call service");
                        console.log(obj.orderDetail);
                        this.setState({order_code: obj.order_code});
                        var _date = moment(obj.delivery_date).format("DD/MM/YYYY");
                        this.setState({delivery_date: _date});                       
                        this.setState({orderDetail: obj.orderDetail});
                        var _total = {quantity_kk:0,price_per_unit:0,grand_total:0};
                       
                        var _item = [];
                        for(var index=0;index < obj.orderDetail.length;index++){
                            var el = Object.assign({},obj.orderDetail[index]);
                            _total.quantity_kk+=el.delivery_quantity_kk;
                            _total.price_per_unit+= (el.price_per_unit);
                            _total.grand_total+= ( el.delivery_quantity_kk*el.price_per_unit );
                            var no = index+1;
                            _item.push( {no: no.toString(), 
                                         product_name: el.product_name, 
                                         delivery_quantity_kk: el.delivery_quantity_kk.toString(),
                                         price_per_unit:  el.price_per_unit.toString()
                                         });
                        };
                        
                        _item.push( {no: "", 
                            product_name: "", 
                            delivery_quantity_kk: "  รวม",
                            price_per_unit:  _total.grand_total.toString()
                            });
                            
                        this.setState({item:_item});
                        this.setState({total: _total});
                        //const view = this.RenderViewer(obj.orderDetail);
                        //this.setState({pdfView:view});
                        //window.print();  
                    }).catch((error) => {
                    })
                
            }
        }
       
    }

    RenderViewer() {
        if(this.state.order_code==null || this.state.item==[]) return <div></div>;
        
        const item = this.state.item;
        const styles ={
            container: {
             marginTop: '150px',
             marginLeft: '80px',
             marginRight: '80px',
             width: '800px',
             height: '100vh',
             fontFamily:'Kanit-regular'
            },
            title: {
              fontSize: 26,
              fontWeight: 'bold',
            },
            subTitle: {
                fontSize: 19,
                marginRight: '60px',
                textAlign: 'right',
               
                
              },
            body:{
                fontSize: 19,
                
            },
            table: {
                marginTop: '20px',
                border: '1px solid black',
                width: '97%',
               
            },
            th: {
                height: '50px',
                
            },
            tdNo:{
                textAlign: 'center',
            },
            tdCost:{
                textAlign: 'right',
                padding: '15px',
            },
            tdFoot:{
                textAlign: 'right',
                fontWeight: 'bold',
            },
            tdFootCost:{
                textAlign: 'right',
                fontWeight: 'bold',
                padding: '15px',
            }
          };
          
        const pdf = 
          (<PDFViewer width="100%" height="100%">
          <Document >
          <Page size="A4" style={{fontFamily:'Kanit-regular'}} wrap={false}>
              <View style={{marginTop: '50px',marginLeft: '75px',marginRight: '75px'}}>
                  <View  style={styles.title}>
                      <Text>ใบแจ้งหนี้</Text>
                  </View> 
                  <View >
                    <Text>เลขที่ {this.state.order_code} วันที่รับซื้อ {this.state.delivery_date}</Text>         
                  </View>
                  <View style={{ marginTop: '20px' }}>
                        <Table
                            data={item}
                            styles={{width:'90%'}}
                        >

                            <TableHeader textAlign={"center"}>
                                <TableCell weighting={0.1}  >ลำดับที่ </TableCell>       
                                <TableCell styles={{paddingLeft:'10'}} weighting={0.4}  >รายการ </TableCell>  
                                <TableCell styles={{paddingRight:'10'}} weighting={0.2}  >ปริมาณซื้อ </TableCell>   
                                <TableCell styles={{paddingRight:'10'}}  weighting={0.3}  >ราคาซื้อ </TableCell>                        
                            </TableHeader>
                            <TableBody>
                                <DataTableCell styles={{textAlign:'center'}} weighting={0.1} getContent={(r) => r.no}/>
                                <DataTableCell styles={{paddingLeft:'10'}} weighting={0.4} getContent={(r) => r.product_name}/>
                                <DataTableCell styles={{paddingRight:'10',textAlign:'right'}} weighting={0.2} getContent={(r) => r.delivery_quantity_kk}/>
                                <DataTableCell styles={{paddingRight:'10',textAlign:'right'}} weighting={0.3} getContent={(r) => r.price_per_unit}/>
                                
                                
                            </TableBody>

                        </Table>
                    </View>
                    
              </View> 
          </Page> 
          </Document>
          </PDFViewer>);

          return pdf;
        
    }

    render() {
        
           
        
        //const pdfView = this.state.pdfView;
        return (
            <div style={{ width: '100%', height: '100vh' }}>
                {this.RenderViewer()}
            
            </div>


            
        );
        
    }
}
export default withRouter(WasteproInvoiceBuyingPrint);

ReactDOM.render(
    <WasteproInvoiceBuyingPrint />,
      document.getElementById('root')
  );
