import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Moment from 'react-moment';
import 'moment-timezone';
import { Button } from 'primereact/components/button/Button';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { Growl } from 'primereact/growl';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ScrollPanel } from 'primereact/scrollpanel';
import * as ServerAPI from './service';
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'
import * as DashBoardService from './service'
import { ExportExcel } from '../ExportExcel/ExportExcel';
import moment from 'moment'
import { Panel } from 'primereact/components/panel/Panel';


class ReportRating extends Component {
    static propTypes = {
    }

    static defaultProps = {
    }

    constructor() {
        super();
        this.state = {

            adminName: '',
            memberType: 1,
            date1: null,
            date2: null,
            status: 1,
            statusSearchItem: [
                { label: 'ผู้ขาย', value: 1 },
                { label: 'ผู้ขนส่ง', value: 2 },
                { label: 'ร้านรับซื้อ', value: 3 },
            ],
            brandSuggestions: null,
            amountSelected: '',
            appraisedPriceSelected: '',
            salePriceSelected: '',
            orderID: null,
            orderDetail: [],
            gid: 0,
            showProgress: false,
            reportSearchOrders: [],
            orderCode: null,
            memberUid: null,
            showReportTable: true,
            excelDisable: true
        };
        this.onMemberNameChanged = this.onMemberNameChanged.bind(this);
        this.onOrderIDChanged = this.onOrderIDChanged.bind(this);
        this.onSelectMemberName = this.onSelectMemberName.bind(this);
        this.onSelectOrderID = this.onSelectOrderID.bind(this);
        this.onDate1Change = this.onDate1Change.bind(this);
        this.onDate2Change = this.onDate2Change.bind(this);
        this.searchRatings = this.searchRatings.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.showProgressSpinner = this.showProgressSpinner.bind(this);
        this.distanceTemplate = this.distanceTemplate.bind(this);
        this.toFormatDateTime = this.toFormatDateTime.bind(this);
        this.to_created_date = this.to_created_date.bind(this);
        this.reConstruct = this.reConstruct.bind(this);
    }


    componentDidMount() {
        const { dashBoardServiceProps, locationServiceProps, masterServiceProps } = this.props
        dashBoardServiceProps.getDashboardUsers();
        masterServiceProps.getProductType();
    }



    onDate1Change(e) { //date in order edit
        console.log("e --> ", e.value.toISOString())
        if (this.state.date2 != null) {
            if (e.value <= this.state.date2) {
                this.setState({ date1: e.value })
            }
        } else
            this.setState({ date1: e.value })
    }
    onDate2Change(e) { //date in order edit 
        if (this.state.date1 <= e.value) {
            this.setState({ date2: e.value })
        }
    }

    itemTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }

    //auto complate handle (ค้นหาสมาชิก)
    suggestMemberName(event) {
        console.log(event);
    }
    onMemberNameChanged(search_name) {
        console.log(search_name)
        console.log(typeof (search_name))
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwasteAll(search_name).then((res) => {
                        console.log(res);
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName: search_name });
                this.setState({ memberUid: null });
            }
    }
    onSelectMemberName(item) {
        this.setState({ memberName: item.value.search_name });
        this.setState({ memberUid: item.value.uid });
    }

    //auto complate handle (ค้นหา Order ID)
    suggestOrderID(event) {
        //console.log(event);
    }

    onOrderIDChanged(code) {
        //console.log(code)
        if (code.trim() != "") {
            this.setState({ orderID: code });
            if (code.length > 1) {
                ServerAPI.autoComplateOrderID(code).then((res) => {
                    console.log(res);
                    this.setState({ orderIDSuggestions: res });
                }).catch((error) => {
                })
            }
        } else this.setState({ orderID: code });


    }
    onSelectOrderID(item) {
        this.setState({ orderID: item.value });
    }
    itemOrderIDTemplate(item) {
        return (
            <div className="p-clearfix">
                {item}
            </div>
        );
    }

    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    clearSearchInput() {
        this.setState({ orderCode: null }); //order code from select table
        this.setState({ orderID: null }); //order code from auto complete
        this.setState({ memberUid: null });
        this.setState({ memberName: '' });
        this.setState({ adminUid: null });
        this.setState({ adminName: '' });
        this.setState({ memberType: 0 });
        this.setState({ date1: null });
        this.setState({ date2: null });
        this.setState({ status: 1000 });
        var reportSearchList = {}
        this.state.reportSearchOrders.push(reportSearchList)
        this.setState({ reportSearchOrders: [] });
        this.setState({ excelDisable: true });

    }

    searchRatings() {
        this.setState({ showReportTable: true })
        this.setState({ showProgress: true })
        var reportSearchList = []
        this.state.reportSearchOrders.push(reportSearchList)
        this.setState({ reportSearchOrders: [] });
        const formData = new FormData();

        let localDate1 = "";
        let localDate2 = "";
        if (this.state.date1 != null) {
            var date = new Date(this.state.date1.getTime() + 7 * 60 * 60000);
            localDate1 = moment(date).format("YYYY-MM-DD");
        }
        if (this.state.date2 != null) {
            var date = new Date(this.state.date2.getTime() + 7 * 60 * 60000);
            localDate2 = moment(date).format("YYYY-MM-DD");
        }

        formData.set('order_code', this.state.orderID || "");
        formData.set('user_uid', this.state.memberUid || "");
        formData.set('user_waste_type', this.state.memberType || "");
        formData.set('order_date_start', localDate1 || "");
        formData.set('order_date_end', localDate2 || "");

        ServerAPI.searchReportRating(formData).then((res) => {
            console.log("response", res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    console.log(res);
                    if (res.length > 0) {
                        let data = []
                        res.forEach(element => {
                            var obj = {
                                "order_code": element['order_code'],
                                "order_date": element['order_date'] ? `${moment(element['order_date']).format("DD-MM-YYYY \n HH:mm:ss")}` : "",
                                "u1_fullname": element['u1_fullname'],
                                "u1_rating_from_u2": element['u1_rating_from_u2'],
                                "u1_rating_from_u3": element['u1_rating_from_u3'],
                                "u2_fullname": element['u2_fullname'],
                                "u2_rating_from_u1": element['u2_rating_from_u1'],
                                "u2_rating_from_u3": element['u2_rating_from_u3'],
                                "u3_fullname": element['u3_fullname'],
                                "u3_rating_from_u1": element['u3_rating_from_u1'],
                                "u3_rating_from_u2": element['u3_rating_from_u2'],
                            }
                            data.push(obj)
                        });

                        this.setState({ reportSearchOrders: data.reverse() });
                        this.reConstruct(res)
                        this.setState({ showProgress: false })
                    } else {
                        this.showWarn("ไม่พบข้อมูล");
                        this.setState({ showProgress: false })
                    }
                } else {
                    this.showWarn();
                }

            } else {
                this.showWarn();
            }
        }).catch((error) => {
        })
    }

    actionTemplate(rowData, column) {
        //console.log(rowData);
        let price = (rowData.quantity_kk * rowData.estimate_price_per_unit).toFixed(2);
        return <div>
            <span> {price}</span>
        </div>;
    }
    actionDeliveryPriceTemplate(rowData, column) {
        //console.log(rowData);
        let price = (rowData.delivery_quantity_kk * rowData.price_per_unit).toFixed(2);
        return <div>
            <span> {price}</span>
        </div>;
    }
    distanceTemplate(rowData, column) {
        let distance = rowData.distance_km.toFixed(3);
        return <div>
            <span> {distance}</span>
        </div>;
    }

    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div className="p-col-12 p-md-8 p-lg-9"></div>);
    }

    actionTemplate(rowData, column) {
        //console.log(rowData);
        let price = (rowData.quantity_kk * rowData.estimate_price_per_unit).toFixed(2);
        return <div>
            <span> {price}</span>
        </div>;
    }

    toFormatDateTime(dateTime) {
        let _dateTime = new Date(dateTime);
        let year = _dateTime.getYear();

        if (year == -1899)
            return <div><span></span></div>;
        else
            return <div><Moment format="DD/MM/YYYY">
                {dateTime}
            </Moment><br /><Moment format="HH:mm">
                    {dateTime}
                </Moment></div>;
    }
    to_created_date(rowData, column) {
        return this.toFormatDateTime(rowData.order_date);
    }

    reConstruct(listOfOrder) {
        console.log('Do')
        var tmp = []
        const Objmaster = listOfOrder || [];
        if (Objmaster.length > 0)
            for (let i = 0; i < Objmaster.length; i++) {
                const obj = {
                    "ORDER ID": Objmaster[i].order_code,
                    "วันที่เปิด Orders": (new Date(Objmaster[i].order_date)).toLocaleDateString(),
                    "ชื่อผู้ขาย": Objmaster[i].u1_fullname,
                    "ดาวจาก U2": Objmaster[i].u1_rating_from_u2,
                    "ดาวจาก U3": Objmaster[i].u1_rating_from_u3,
                    "ชื่อผู้ขนส่ง": Objmaster[i].u2_fullname,
                    "ดาวจาก U1": Objmaster[i].u2_rating_from_u1,
                    "ดาวจาก U3": Objmaster[i].u2_rating_from_u3,
                    "ชื่อร้านรับซื้อของเก่า": Objmaster[i].u3_fullname,
                    "ดาวจาก U1": Objmaster[i].u3_rating_from_u1,
                    "ดาวจาก U2": Objmaster[i].u3_rating_from_u2,
                }
                tmp.push(obj);
            }
        this.setState({ excelData: tmp })
        this.setState({ excelDisable: false })
    }
    showSearchResult() {
        if (this.state.showProgress) return <div></div>;
        var exportExcel =
            <div style={{ textAlign: 'left' }}>
                <ExportExcel data={this.state.excelData} isDisable={this.state.excelDisable} fileName="รายงานการให้ดาว" />
            </div>;
        return (<div>
            <div className="p-col-12">
                <Panel header="รายงานการให้ดาว">
                    {exportExcel}
                    <DataTable value={this.state.reportSearchOrders} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                        responsive={true}
                        scrollable={true}
                    >
                        <Column field="order_code" header="ORDER ID" sortable={true} filter={true} style={{ width: '150px' }} className="p-text-center" />
                        <Column field="order_date" header="วันที่เปิด Orders" sortable={true} filter={true} style={{ width: '120px' }} className="p-text-left" />
                        <Column field="u1_fullname" header="ชื่อผู้ขาย" sortable={true} filter={true} style={{ width: '120px' }} className="p-text-left" />
                        <Column field="u1_rating_from_u2" header="ดาวจาก U2" sortable={true} filter={true} style={{ width: '100px' }} className="p-text-center" />
                        <Column field="u1_rating_from_u3" header="ดาวจาก U3" sortable={true} filter={true} style={{ width: '100px' }} className="p-text-center" />
                        <Column field="u2_fullname" header="ชื่อผู้ขนส่ง" sortable={true} filter={true} className="p-text-center" style={{ width: '120px' }} className="p-text-left" />
                        <Column field="u2_rating_from_u1" header="ดาวจาก U1" sortable={true} filter={true} style={{ width: '100px' }} className="p-text-center" />
                        <Column field="u2_rating_from_u3" header="ดาวจาก U3" sortable={true} filter={true} style={{ width: '100px' }} className="p-text-center" />
                        <Column field="u3_fullname" header="ชื่อร้านรับซื้อของเก่า" sortable={true} filter={true} className="p-text-center" style={{ width: '120px' }} className="p-text-left" />
                        <Column field="u3_rating_from_u1" header="ดาวจาก U1" sortable={true} filter={true} style={{ width: '100px' }} className="p-text-center" />
                        <Column field="u3_rating_from_u2" header="ดาวจาก U2" sortable={true} filter={true} style={{ width: '100px' }} className="p-text-center" />
                    </DataTable>
                </Panel>
            </div>
        </div>);
    }


    showSearchPanel() {
        return (
            <div>
                <Accordion multiple={true} activeIndex={[0, 1]}>
                    <AccordionTab header="เงื่อนไขค้นหา รายงานการให้ดาว">
                        <div className="p-grid p-justify-start" >
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>Order ID :</label>
                            </div>
                            <div className="p-col-12 p-md-10 p-lg-11" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.orderID}
                                    suggestions={this.state.orderIDSuggestions}
                                    onSelect={this.onSelectOrderID}
                                    completeMethod={this.suggestOrderID.bind(this)}
                                    onChange={(e) => this.onOrderIDChanged(e.value)}
                                    onKeyPress={this.onOrderKeyPress}
                                    itemTemplate={this.itemOrderIDTemplate.bind(this)}
                                    placeholder="Order ID " />
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>สมาชิก : </label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.memberName}
                                    suggestions={this.state.memberSuggestions}
                                    onSelect={this.onSelectMemberName}
                                    completeMethod={this.suggestMemberName.bind(this)}
                                    onChange={(e) => this.onMemberNameChanged(e.target.value)}
                                    itemTemplate={this.itemTemplate.bind(this)}
                                    placeholder="ระบุรหัสสมาชิก/ชื่อสมาชิก" />
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label>ประเภทสมาชิก :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-7" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Dropdown
                                    value={this.state.memberType}
                                    options={this.state.statusSearchItem}
                                    onChange={(e) => { this.setState({ memberType: e.value }) }}
                                >
                                </Dropdown>
                            </div>


                            <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label>วันที่เปิด order :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.date1} onChange={this.onDate1Change} ></Calendar>
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label>ถึงวันที่ :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-7" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.date2} onChange={this.onDate2Change} ></Calendar>
                            </div>

                            <div className="p-col-12">
                                <Button
                                    style={{ marginRight: "20px" }}
                                    label="ค้นหา"
                                    icon="fa fa-search"
                                    iconPos="left"
                                    onClick={this.searchRatings} />
                                <Button
                                    style={{ backgroundColor: '#f0ad4e' }}
                                    type="submit"
                                    label="ยกเลิก"
                                    onClick={this.clearSearchInput}
                                />
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        )
    }

    render() {
        const searchPanel = this.showSearchPanel();
        const showProgress = this.showProgressSpinner();
        const searchResult = this.showSearchResult();
        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} />
                {searchPanel}
                {searchResult}
                {showProgress}
            </div>
        );
    }
}



const mapStateToProps = (state) => ({
    dhbUsers: state.dhbAdmin.dhbUsers,
    locationMaster: state.location,
})

const mapDispatchToProps = dispatch => ({
    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    fileServiceProps: bindActionCreators(FileService, dispatch),
})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ReportRating)