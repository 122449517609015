import React from 'react'
import { Dialog } from 'primereact/dialog';
import { Button } from 'primereact/button';

export const PrintDialogView = ({showDialog,onHide})=>{

    const footerDialog = (
        <div>
            <Button label="ปิด" icon="pi pi-times" onClick={onHide} />
        </div>
    );

    return (
        <Dialog
        header="พิมพ์ใบสรุป"
        style={{width:'50vw'}}
        visible={showDialog}
        modal={true}
        onHide={onHide}
        footer={footerDialog}
        >
        อยู่ในช่วงพัฒนา
    </Dialog>  
    );
}