import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';

import { InputText } from 'primereact/components/inputtext/InputText';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';




export class WasteproReportBuying extends Component {

    constructor() {
        super();
        this.state = {



            status: '0',
            status2: '0',
            statusItem: [
                { label: 'เลือกทั้งหมด', value: '0' },
                { label: 'รอนัดหมาย', value: '1' },
                { label: 'รอทีมขนส่ง', value: '2' },
                { label: 'รอส่งสินค้า', value: '3' },
                { label: 'ปิด Order', value: '4' },
                { label: 'ยกเลิก', value: '5' },
                { label: 'ผิดนัด', value: '6' },
            ],

            manageTable: [
                1, 2, 3, 4, 5, 6, 7
            ],
            orderDetail: [
                1, 2, 3, 4, 5
            ],
            acceptFileExt: '.jpg,.jpeg,.png,.pdf,.JPG,.JPEG,.PNG,.PDF',
        };

    }

    ExportButton(rowData, column) {
        return <div style={{ textAlign: 'center' }}>
            <Button type="button" icon="fa fa-print" className="p-button-success" ></Button>
        </div>;
    }

    render() {
        var header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            <Button label="Export" icon="fa fa-file-excel" style={{ 'float': 'center' }} /></div>;


        let footerGroup = <ColumnGroup>
            <Row>
                <Column style={{textAlign:'right'}} footer="รวม:" colSpan={3} />
                <Column footer="26" />
                <Column footer="110" />
                <Column footer="" colSpan={2} />
                
            </Row>
        </ColumnGroup>;

        return (



            // <div>
            //     <h1>แจ้งถอนเงิน</h1>
            //     <div className="p-grid">
            //         <div className="p-lg-12  p-md-12  p-sm-12">
            //             <div className="card card-w-title">
            //                 <span >จะใช้งานได้ภายในเดือน มกราคม 2563</span>
            //             </div>
            //         </div>
            //     </div>
            // </div>

            <div className="p-grid">
                <div className="p-col-12">
                    <div className="content-section implementation">

                        <Accordion multiple={true} activeIndex={[0, 1]}>
                            <AccordionTab header="เงื่อนไขของรายงาน">
                                <div className="p-grid " >
                                    <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                        <label>Order ID :</label>
                                    </div>
                                    <div className="p-col-12 p-md-10 p-lg-11" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                        <InputText placeholder="Order ID"></InputText>
                                    </div>

                                    <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                        <label>รายการ : </label>
                                    </div>
                                    <div className="p-col-12 p-md-2 p-lg-2" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                        <InputText placeholder="ระบุรายการ"></InputText>
                                    </div>
                                    <div className="p-col-12 p-md-2 p-lg-1"></div>
                                    <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                        <label>สถานะ : </label>
                                    </div>
                                    <div className="p-col-12 p-md-2 p-lg-2" style={{ alignSelf: 'center' }}>
                                        <Dropdown
                                            value={this.state.status2}
                                            options={this.state.statusItem}
                                            onChange={(e) => { this.setState({ status2: e.value }) }}
                                        />
                                    </div>
                                    <div className="p-col-12 p-md-2 p-lg-3"></div>
                                    <div className="p-col-12 p-md-0 p-lg-2"></div>


                                    <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                        <label>วันที่รับซื้อ :</label>
                                    </div>
                                    <div className="p-col-12 p-md-2 p-lg-2" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                        <Calendar value={this.state.date1} placeholder="เลือกวันที่" onChange={(e) => this.setState({ date1: e.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2 p-lg-1"></div>
                                    <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                        <label>ถึง</label>
                                    </div>
                                    <div className="p-col-12 p-md-2 p-lg-2" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                        <Calendar value={this.state.date2} placeholder="เลือกวันที่" onChange={(e) => this.setState({ date2: e.value })} />
                                    </div>
                                    <div className="p-col-12 p-md-2 p-lg-3"></div>
                                    <div className="p-col-12 p-md-0 p-lg-2"></div>

                                    <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                        <label>ทีมขนส่ง :</label>
                                    </div>
                                    <div className="p-col-12 p-md-11 p-lg-11" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                        <InputText placeholder="ระบุชื่อทีมขนส่งซีซาเล้ง"></InputText>
                                    </div>


                                    <div className="p-col-12">
                                        <Button label="ค้นหา" icon="fa fa-search" iconPos="left" ></Button>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                        </div>
                    </div>

                        <div className="p-col-12">
                            <Panel header="รายงานการขาย" >
                                <DataTable header={header} value={this.state.manageTable} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={5}
                                    responsive={true} selection={this.state.dataTableSelection} onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                    scrollable={true} rowsPerPageOptions={[5, 10, 20]} footerColumnGroup={footerGroup}
                                >
                                    <Column field="1" body={this.ExportButton} header="พิมพ์ใบสรุป" sortable={true} />
                                    <Column field="2" header="เลขที่" sortable={true} />
                                    <Column field="3" header="รายการ" sortable={true} />
                                    <Column field="4" header="วันที่ส่ง" sortable={true} />
                                    <Column field="5" header="ปริมาส่ง" sortable={true} />
                                    <Column field="6" header="ราคา/หน่วย" sortable={true} />
                                    <Column field="7" header="ราคาขาย" sortable={true} />
                                    <Column field="8" header="ทีมขนส่ง" sortable={true} />
                                    <Column field="9" header="สถานะ" sortable={true} />
                                  
                                </DataTable>
                            </Panel>
                        
                </div>
            </div>
        )
    }
}