import React, { Component } from 'react';
import classNames from 'classnames';
import { max } from 'moment';
import {InputText} from 'primereact/inputtext';

class CEPassword extends Component {
    static defaultProps = {

        required:false,
        value: '',
        id:null,
        placeholder: '',
        tooltip:'',
        type: 'text',
        className: '',
        style: '',
        regexp: "^(?=.*[a-z])(?=.*[A-Z]).{6,}$",
        minlength: '',
        maxlength: '',
        minvalue: '',
        maxvalue: '',
        onChange: () => { },
        onKeyPress: () => { },
        isvalid: () => {  },
        disabled:false
        
    }

    constructor(props) {
        super(props)
        this.state = { isvalid: false,value: ''}
        this.validation = this.validation.bind(this);
        this.changed = this.changed.bind(this);
        //this.showCtrl = this.showCtrl.bind(this);
        //regexp = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
        
    }
    componentDidMount() {
        this.setState({value: this.props.value})
        const _isValid = this.validation(this.props.value);
        this.props.isvalid(_isValid);
    }
   
    validation(value){
        var _valid = true;
        const {required, regexp,id, placeholder, type,  className, style,minlength,maxlength,onChange,minvalue,maxvalue} = this.props;     
        if(required && (value==''|| value==null))_valid =  false;
        if(type=='number') {
            var checkNumber = new RegExp("^[0-9]*$");
            const isNumber = checkNumber.test(value);
            if(!isNumber) _valid = false;
        }
        if(type=='money'){
            var checkNumber = new RegExp("^[0-9]+(\.[0-9]{1,2})?$");
            const isNumber = checkNumber.test(value);
            if(!isNumber) {
               if(value) _valid = false;
            }
        }
        if(type=='password'){
            var checkNumber = new RegExp(regexp);
            const isNumber = checkNumber.test(value);
            if(!isNumber) {
               if(value) _valid = false;
            }
        }
        if(type=='telephone') {
            var checkNumber = new RegExp("^[0]{1}[0-9]*$");
            const isNumber = checkNumber.test(value);
            if(!isNumber) _valid = false;
        }
        if(type=='email'){
            var checkEmail = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
            const isEmail = checkEmail.test(value);
            if(!isEmail && value!='') _valid = false;
        }
        if(minlength!=0 && value.length < Number(minlength)) _valid =  false;
        if(maxlength!=0 && value.length > Number(maxlength)) _valid = false;
        if(minvalue!='' && type=='number'){
            if( Number(value) < Number(minvalue) ) _valid = false;
        }
        if(maxvalue!='' && type=='number'){
            if( Number(value) > Number(maxvalue) ) _valid = false;
        }
        
        return _valid;
    } 
    changed = (e) =>{
        this.setState({value:e.target.value})
        const _isValid = this.validation(e.target.value);
        this.props.isvalid(_isValid);
        this.props.onChange(e);
        
    }
    
    showCtrl(){
        
      const {required,regexp,tooltip,value, id, placeholder, type,  className, style,minlength,maxlength,onChange,onKeyPress,disabled} = this.props;
      const _isValid = this.validation(this.state.value);
      
      const _className = classNames('p-inputtext p-component', className, {
        'p-error': !_isValid
        });

     

      let _txtTooltip = '';
      if(tooltip=='' || tooltip==null) _txtTooltip = "กรุณากรอก" + placeholder ; else _txtTooltip = tooltip;
      
      
        /* tooltip={_txtTooltip} 
                        tooltipOptions={{event: 'focus',position: 'bottom'}}*/
      return <InputText 
                        id={id} 
                        value={value}                     
                        placeholder={placeholder} 
                        type={type}
                        className={_className} 
                        onChange={this.changed}
                        onKeyPress={onKeyPress}
                        disabled={disabled}
                        maxLength={maxlength}
                        minLength={minlength}
                       
                        />
       

    }
    render(){
        const bodyContent = this.showCtrl();
      
        return (
            bodyContent
        )
    }
}

export default CEPassword