import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';

import { InputText } from 'primereact/components/inputtext/InputText';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { MapMarkers } from '../../features/map/MapMarkers'
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { Avatar } from '../../features/widget/Avatar';
import { API_ROOT } from '../../constants/api'
import { ProgressSpinner } from 'primereact/progressspinner';
import {Dialog} from 'primereact/dialog';
import * as ServerAPI from './service';
import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'
import * as DashBoardService from './service'
import { isEmpty } from '../../utils/validators';
import {Validators} from '../../utils'
import {CEInputText,CECalendar,CEDropdown} from '../../features/input';
import {CEFileUpload} from '../../features/widget';

class ManageDeliveryWeight extends Component {
    static propTypes = {

    }
    static defaultProps = {

    }

    constructor() {
        super();
        this.state = {
            weightList: [],
            vehicleType: '',
            weightLabel: '',
            minWeight: '',
            maxWeight: '',
            isNotValid: false,
            showProgress: false,
        };
        this.onClickAddWeight = this.onClickAddWeight.bind(this);
        this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
        this.onValidation = this.onValidation.bind(this);
        this.getTranspotWeightType = this.getTranspotWeightType.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.onWeightSelect = this.onWeightSelect.bind(this);
        // this.onRowSelected = this.onRowSelected.bind(this);
    }
    componentDidMount() {
        this.getTranspotWeightType();
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div></div>);
    }
    getTranspotWeightType(){
        this.setState({ showProgress: true })
        ServerAPI.getTranspotWeightType().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({weightList: res })
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }
    actionTemplate(rowData, column) {
        return <div>
            {/* <Button type="button" onClick={() => this.onClickDeleteItem(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button> */}
            <Button type="button" onClick={() => this.onWeightSelect(column.rowData)} icon="pi pi-pencil" className="p-button" ></Button>
            <Button type="button" onClick={() => this.onWeightDelete(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button>
        </div>;
    }
    onClickDeleteItem(rowData) {
        console.log(rowData)
        if (rowData !== '') {
            var id = rowData.gid
            ServerAPI.removeTranspotWeightType(id).then((res) => {
                console.log(res);
                if (res) {
                    var index = res.toString().indexOf("Error:");
                    console.log("find error:" + (index));
                    if (index == -1) {
                        this.showSuccess();
                        this.getTranspotWeightType();
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            }).catch((error) => {
                this.setState({ showProgress: false })
            })
        }else {
            this.showWarn();
            this.setState({ showProgress: false })
        }
    }
    update() {
        this.setState({ showProgress: true })
        console.log(this.state.weight)
        if(this.state.weight){
            var isNotinput = (
                this.state.weight.weight_name == '' ||
                this.state.weight.more_than_kk == '' ||
                this.state.weight.less_than_kk == '' ||
                this.state.isNotValid == true
            );
    
            if (isNotinput) {
                this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
                this.setState({ showProgress: false })
            } else {
                    var min = parseFloat(this.state.weight.more_than_kk)
                    var max = parseFloat(this.state.weight.less_than_kk)
    
                if(min >= max){
                    console.log(min)
                    console.log(max)
                    this.showWarn("น้ำหนักต่ำสุด ต้องน้อยกว่าน้ำหนักสูงสุด");
                    this.setState({ showProgress: false })
                }else{
                    var notValid = false;
                    // this.state.weightList.indexOf("")
                    // this.state.weightList.forEach(objWeightList => {
                    //     var objMin = parseFloat(objWeightList.more_than_kk)
                    //     var objMax = parseFloat(objWeightList.less_than_kk)
                    //     console.log(min+" > "+objMin);
                    //     console.log(max+" > "+objMin);
                    //     console.log(min+" < "+objMax);
                    //     console.log(max+" < "+objMax);
                        
                    //     if(objWeightList.gid != this.state.weight.gid)
                    //     if((min < objMin && max < objMin) || (min > objMax && max > objMax)){
                    //         console.log("pass!");
                    //     }else if(objMin <= min || objMax >= max){
                    //         console.log("not pass!")
                    //         notValid = true;
                    //         }else if(objMin >= min || objMax <= max){
                    //         console.log("not pass2!")
                    //         notValid = true;
                    //         }else console.log("pass2!");
                    // }); 
    
                    if(notValid){
                        this.showWarn("มีช่วงน้ำหนักที่ซ้ำซ้อนกัน");
                        this.setState({ showProgress: false })
                    }else{

                        var data = {
                            gid: this.state.weight.gid,
                            weight_name: this.state.weight.weight_name,
                            more_than_kk: parseFloat(this.state.weight.more_than_kk),
                            less_than_kk: parseFloat(this.state.weight.less_than_kk),
                        }

                        ServerAPI.editTransportWeightType(data).then((res) => {
                            console.log(res);
                            if (res) {
                                var index = res.toString().indexOf("Error:");
                                console.log("find error:" + (index));
                                if (index == -1) {
                                    this.getTranspotWeightType();
                                    this.setState({ displayDialog:false});
                                    this.showSuccess();
                                } else {
                                    this.showWarn();
                                    this.setState({ showProgress: false })
                                }
                            } else {
                                this.showWarn();
                                this.setState({ showProgress: false })
                            }
                        }).catch((error) => {
                            this.setState({ showProgress: false })
                        })
                    }
                }
            }
        }
    }

    delete() {
        this.setState({ showProgress: true })
        var data = this.state.weight;
        this.onClickDeleteItem(data);
        // let index = this.findSelectedWeightIndex();
        // this.setState({
        //     cars: this.state.cars.filter((val,i) => i !== index),
        //     selectedCar: null,
        //     car: null,
        this.setState({deleteDialog: false});
    }

    findSelectedWeightIndex() {
        return this.state.cars.indexOf(this.state.selectedCar);
    }

    updateProperty(property, value) {
        let weight = this.state.weight;
        weight[property] = value;
        this.setState({weight: weight});
    }

    onWeightSelect(e){
        console.log(e)
        this.setState({
            displayDialog:true,
            weight: Object.assign({}, e)
        });
    }
    onWeightDelete(e){
        console.log(e)
        this.setState({
            deleteDialog:true,
            weight: Object.assign({}, e)
        });
    }
    // onRowSelected(e){
    //     console.log(e)
    //     // this.newCar = false;
    //     // this.setState({
    //     //     // deleteDialog:true,
    //     //     weight: Object.assign({}, e.data)
    //     // });
    // }
    
    
    onValidation(valid){
        console.log(valid)
        if(!valid) this.setState({isNotValid: true })
        else this.setState({isNotValid: false })
    }
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    onClickAddWeight(){
        this.setState({ showProgress: true })
        var isNotinput = (
            this.state.weightLabel == '' ||
            this.state.minWeight == '' ||
            this.state.maxWeight == '' ||
            this.state.isNotValid == true
        );

        if (isNotinput) {
            this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
            this.setState({ showProgress: false })
        } else {
                var min = parseFloat(this.state.minWeight)
                var max = parseFloat(this.state.maxWeight)

            if(min >= max){
                console.log(min)
                console.log(max)
                this.showWarn("น้ำหนักต่ำสุด ต้องน้อยกว่าน้ำหนักสูงสุด");
                this.setState({ showProgress: false })
            }else{
                var notValid = false;
                // this.state.weightList.forEach(objWeightList => {
                //     var objMin = parseFloat(objWeightList.more_than_kk)
                //     var objMax = parseFloat(objWeightList.less_than_kk)
                //     console.log(min+" > "+objMin);
                //     console.log(max+" > "+objMin);
                //     console.log(min+" < "+objMax);
                //     console.log(max+" < "+objMax);
                //     if((min < objMin && max < objMin) || (min > objMax && max > objMax)){
                //         console.log("pass!");
                //     }else if(objMin <= min || objMax >= max){
                //             console.log("not pass!")
                //             notValid = true;
                //           }else if(objMin >= min || objMax <= max){
                //             console.log("not pass2!")
                //             notValid = true;
                //           }else console.log("pass2!");
                // }); 

                if(notValid){
                    this.showWarn("มีช่วงน้ำหนักที่ซ้ำซ้อนกัน");
                    this.setState({ showProgress: false })
                }else{
                    var weightList = {
                        weight_name: this.state.weightLabel,
                        more_than_kk: parseFloat(this.state.minWeight),
                        less_than_kk: parseFloat(this.state.maxWeight),
                    }
                    //this.state.weightList.push(weightList)

                    ServerAPI.AddTranspotWeightType(weightList).then((res) => {
                        console.log(res);
                        if (res) {
                            var index = res.toString().indexOf("Error:");
                            console.log("find error:" + (index));
                            if (index == -1) {
                                this.showSuccess();
                                this.getTranspotWeightType();
                                this.setState({ vehicleType: '' })
                                this.setState({ weightLabel: '' })
                                this.setState({ minWeight: '' })
                                this.setState({ maxWeight: '' })
                            } else {
                                this.showWarn();
                                this.setState({ showProgress: false })
                            }
                        } else {
                            this.showWarn();
                            this.setState({ showProgress: false })
                        }
                    }).catch((error) => {
                        this.setState({ showProgress: false })
                    })

                    
                    //this.showSuccess();
                }
            console.log(this.state.weightList)
            }
        }
    }
    
    showAddWeight(){
        const newWeight = (
            <div className="p-col-12" >
                    <Accordion multiple={true} >
                        <AccordionTab header="เพิ่มน้ำหนักใหม่">
                            <div className="p-grid">
                            <div className="p-col-12 p-md-2 p-lg-2" style={{ alignSelf: 'center' }}>
                                <label>ช่วงน้ำหนักที่บรรจุได้ :</label>
                            </div>
                            <div className="p-col-12 p-md-10 p-lg-10">
                            <CEInputText                         
                                type="text"
                                value={this.state.weightLabel}
                                placeholder="กำหนดช่วงน้ำหนัก"
                                maxlength={25}
                                required={true}
                                onChange={(e)=> this.setState({weightLabel: e.target.value})}
                                />
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-2" style={{ alignSelf: 'center' }}>
                                <label>น้ำหนักต่ำสุด :</label>
                            </div>
                            <div className="p-col-12 p-md-10 p-lg-10" style={{ alignSelf: 'center' }}>
                                <CEInputText   
                                    id="minWeight"                        
                                    type="money"
                                    value={this.state.minWeight}
                                    name="minWeight"
                                    placeholder="ต่ำสุด"
                                    tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                    required={true}
                                    isvalid={this.onValidation}
                                    onChange={(e)=> this.setState({minWeight: e.target.value})}
                                    />
                                <label style={{marginLeft: '10px'}}>กิโลกรัม</label>
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-2" style={{ alignSelf: 'center' }}>
                                <label>น้ำหนักสูงสุด :</label>
                            </div>
                            <div className="p-col-12 p-md-10 p-lg-10">
                            <CEInputText   
                                    id="maxWeight"                        
                                    type="money"
                                    value={this.state.maxWeight}
                                    name="maxWeight"
                                    placeholder="สูงสุด"
                                    tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                    required={true}
                                    isvalid={this.onValidation}
                                    onChange={(e)=> this.setState({maxWeight: e.target.value})}
                                    />
                                <label style={{marginLeft: '10px'}}>กิโลกรัม</label>
                            </div>
                            <div className="p-col-12">
                                <Button
                                    type="submit"
                                    label="เพิ่มระยะทาง"
                                    icon="pi pi-plus"
                                    iconPos="left"
                                    onClick={this.onClickAddWeight}
                                />
                            </div>
                            
                            </div>
                        </AccordionTab>
                        
                    </Accordion>
            </div>
        );

        return newWeight;
    }

    showWeightTable(){

        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="บันทึก" icon="pi pi-check" onClick={this.update}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displayDialog: false})} className="p-button-warning"/>
            </div>;
        let deleteDialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ใช่" icon="pi pi-check" onClick={this.delete}/>
            <Button label="ไม่ใช่" icon="pi pi-times" onClick={() => this.setState({deleteDialog: false})} className="p-button-warning"/>
            </div>;

        const weightTable = (
            <div className="p-col-12">
                    <Accordion multiple={true} activeIndex={[0, 1]}>
                        <AccordionTab header="รายการระยะทาง">
                        <DataTable value={this.state.weightList} paginatorPosition="bottom" paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                            responsive={true} 
                            // selectionMode="single" selection={this.state.selectedWeight} 
                            // onSelectionChange={e => this.setState({selectedWeight: e.value})} 
                            // // onRowSelect={this.onRowSelected}
                            emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                            </div>)}>
                            <Column field="weight_name" header="ช่วงน้ำหนักที่บรรจุได้" sortable={true} filter={true} />
                            <Column field="more_than_kk" header="น้ำหนักต่ำสุด (กก.)" sortable={true} filter={true} className="p-text-center"/>
                            <Column field="less_than_kk" header="น้ำหนักสูงสุด (กก.)" sortable={true} filter={true} className="p-text-center"/>
                            <Column field="gid" body={this.actionTemplate.bind(this)} header="จัดการ" className="p-text-center" style={{ width: '8em' }} />
                        </DataTable>
                    </AccordionTab>
                        
                    </Accordion>
                    <Dialog visible={this.state.deleteDialog} style={{width: '40vw'}} header="ยืนยันการลบข้อมูล" modal={true} footer={deleteDialogFooter} onHide={() => this.setState({deleteDialog: false})}>
                        {
                            this.state.weight &&
                            
                            <div className="p-grid p-fluid" >
                                <div className="p-col-12"><span>คุณต้องการจะลบข้อมูลต่อไปนี้</span></div>
                                <div className="p-col-4" ><label htmlFor="weight_name">ช่วงน้ำหนักที่บรรจุได้ :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.weight.weight_name}</strong>
                                </div>
                                <div className="p-col-4" ><label htmlFor="more_than_kk">น้ำหนักต่ำสุด (กก.) :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.weight.more_than_kk}</strong>
                                </div>
                                <div className="p-col-4" ><label htmlFor="less_than_kk">น้ำหนักสูงสุด (กก.) :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.weight.less_than_kk}</strong>
                                </div>
                            </div>
                        }
                    </Dialog>
                    <Dialog visible={this.state.displayDialog} style={{width: '40vw'}} header="แก้ไขข้อมูล" modal={true} footer={dialogFooter} onHide={() => this.setState({displayDialog: false})}>
                        {
                            this.state.weight &&

                            <div className="p-grid p-fluid" >
                                
                                <div className="p-col-4" style={{ alignSelf: 'center' }}><label htmlFor="weight_name">ช่วงน้ำหนักที่บรรจุได้ :</label></div>
                                <div className="p-col-8" >
                                    <CEInputText   
                                        id="weight_name"                      
                                        type="text"
                                        value={this.state.weight.weight_name}
                                        placeholder="กำหนดช่วงน้ำหนัก"
                                        maxlength={25}
                                        required={true}
                                        onChange={(e) => {this.updateProperty('weight_name', e.target.value)}}
                                        />
                                </div>

                                <div className="p-col-4" style={{ alignSelf: 'center' }}><label htmlFor="more_than_kk">น้ำหนักต่ำสุด (กก.) :</label></div>
                                <div className="p-col-8" >
                                    <CEInputText   
                                        id="more_than_kk"                        
                                        type="money"
                                        value={this.state.weight.more_than_kk}
                                        name="more_than_kk"
                                        placeholder="ต่ำสุด"
                                        tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                        required={true}
                                        isvalid={this.onValidation}
                                        onChange={(e) => {this.updateProperty('more_than_kk', e.target.value)}}
                                        />
                                        
                                </div>

                                <div className="p-col-4" style={{ alignSelf: 'center' }}><label htmlFor="less_than_kk">น้ำหนักสูงสุด (กก.) :</label></div>
                                <div className="p-col-8" >
                                    <CEInputText   
                                        id="less_than_kk"                        
                                        type="money"
                                        value={this.state.weight.less_than_kk}
                                        name="less_than_kk"
                                        placeholder="สูงสุด"
                                        tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                        required={true}
                                        isvalid={this.onValidation}
                                        onChange={(e) => {this.updateProperty('less_than_kk', e.target.value)}}
                                        />
                                    
                                </div>
                            </div>
                        }
                    </Dialog>
            </div>
        );
        if(!this.state.showProgress)
        return weightTable;
    }
    

    render() {
        const addWeightPanel = this.showAddWeight();
        const weightTablePanel = this.showWeightTable();
        const showProgress = this.showProgressSpinner();
        return <div className="p-grid ">
            <Growl ref={(el) => this.growl = el} />
                <div className="p-col-12">
                        <h1>ตั้งค่าน้ำหนักขนส่ง</h1>
                    </div>

                    {addWeightPanel}
                    {showProgress}
                    {weightTablePanel}


        </div>
    }
}



const mapStateToProps = (state) => ({
    dhbUsers: state.dhbAdmin.dhbUsers,
    locationMaster: state.location,
    provinces: state.location.province,
    districts: state.location.district,
    subDistricts: state.location.subDistrict,
    cardProvince: state.location.cardProvince,
    cardDistrict: state.location.cardDistrict,
    cardSubDistrict: state.location.cardSubDistrict,
    authentication: state.authentication,
    userInfo: state.userInfo.data,
    userInfoMaster: state.userInfo,
    updateWasteproProfile: state.updateWasteproProfile,
    banks: state.master.banks,
    productType: state.master.productType,
    product: state.master.product,
    getFileWastepro: state.getFileWastepro,
})

const mapDispatchToProps = dispatch => ({

    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    fileServiceProps: bindActionCreators(FileService, dispatch),

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ManageDeliveryWeight)