

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import WithDrawHeaderView from '../withdraw/WithDrawHeaderView';
import { Panel } from 'primereact/panel';
import DynamicDataTableView from '../data-table/DynamicDataTableView';
import { Validators } from '../../utils';

import { getBalance ,getWithdrawHistory, saveWithdrawal } from '../../service/wastetran.service'

import {dateFormat} from '../../utils/utilFunctions'

class ExampleWastetranNotiWithdrawCtrl extends Component {

    constructor(props) {
        console.log("ExampleWastetranNotiWithdrawCtrl :constructor")
        super(props);
        this.state = {
            //save
            isSave:false,
            //Validate require
            isValidateError: false,
            classNameRequired: {},

            //WithDrawHeaderView
            withdrawAmount: 0,
            unApproveAmount:0,
            input_amount: null,
            input_amount_required: {
                valid: true,
                className: '',
                message: ''
            },
            bank_withdraw_code: null,
            bank_withdraw_code_required: {
                valid: true,
                className: '',
                message: ''
            },
            //DynamicDataTableView
            model: [
                { field: 'col1', header: 'วันที่แจ้ง', sortable: true },
                { field: 'col2', header: 'วันที่โอน', sortable: true },
                { field: 'col3', header: 'จำนวนเงิน (บาท)', sortable: true , className: 'p-text-right'},
                { field: 'col4', header: 'ชื่อบัญชี', sortable: true },
                { field: 'col5', header: 'เลขที่บัญชี', sortable: true },
                { field: 'col6', header: 'ธนาคาร', sortable: true },
                { field: 'col7', header: 'สถานะ', sortable: true },
            ],
            data: [

            ]
        }

        this.validateRequired = {};

        //Event : original input text
        this.onChangeInputText = this.onChangeInputText.bind(this);
        //Event : withdraw
        this.onClickNotiMoney = this.onClickNotiMoney.bind(this);
    }

    componentDidMount() {
        console.log("ExampleWastetranNotiWithdrawCtrl :componentDidMount")
        this.props.dispatch(getBalance());
        this.props.dispatch(getWithdrawHistory());
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("ExampleWastetranNotiWithdrawCtrl :componentWillReceiveProps")
        if (this.state.isSave) {
            if (!nextProps.wastetranWithdraw.isLoading) {
                if (!nextProps.wastetranWithdraw.isFailed) {
                    this.props.dispatch(getBalance());
                    this.props.dispatch(getWithdrawHistory());
                    this.setState({ isSave: false })
                    this.showSuccess();
                    this._validateRequired('bank_withdraw_code_required', true)
                    this.setState({ input_amount: '' })
                    this.setState({ bank_withdraw_code: '' })
                }
                if (nextProps.wastetranWithdraw.isFailed) {
                    this.showError(nextProps.wastetranWithdraw.data);
                    this._validateRequired('bank_withdraw_code_required', false, nextProps.wastetranWithdraw.data)
                    this.setState({ isSave: false })
                }
            }
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("ExampleWastetranNotiWithdrawCtrl :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("ExampleWastetranNotiWithdrawCtrl :componentWillUpdate")
        
       

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("ExampleWastetranNotiWithdrawCtrl :componentDidUpdate", this.state, " ,", prevState)


        if (prevProps.wastetranBalance.data != this.props.wastetranBalance.data) {
            if(this.props.wastetranBalance.data && this.props.wastetranBalance.data.outstanding_balance_thb){
                this.setState({ withdrawAmount: (this.props.wastetranBalance.data.outstanding_balance_thb).toFixed(2) })
                this.setState({ unApproveAmount: (this.props.wastetranBalance.data.withdraw_balance_thb).toFixed(2) })
            }
            console.log(`print state ${this.state}`)
        }
    }
    // Initial function
    initial = (object) => {
        if (object) {
            for (let key in object) {
                let val = object[key]
                this.setState({ [key]: val })
            }
        }
    }
    //> Submit
    onSubmit = () => {
        this.showSuccess();
    }
    //> Event
    onChangeInputText = (e) => {
        const { name, value, required } = e.target
        this.setState({ [name]: value })
        if (required) {
            let classNamed = [name] + "_required"
            if ([name] == "input_amount") {
                if (Number(value) > Number(this.state.withdrawAmount)) {
                    this._validateRequired(classNamed, false, "ยอดทั้งหมดที่ถอนได้ไม่พอสำหรับแจ้งถอน")
                } else {
                    this._validateRequired(classNamed, true)
                }
                if (Validators.isEmpty(this.state.bank_withdraw_code)) {
                    this._validateRequired('bank_withdraw_code_required', false, "กรุณาระบุรหัสสำหรับแจ้งถอนเงิน")
                } else {
                    this._validateRequired('bank_withdraw_code_required', true)
                }
            }
            if ([name] == "bank_withdraw_code") {
                if (Validators.isEmpty(value)) {
                    this._validateRequired(classNamed, false, "กรุณาระบุรหัสสำหรับแจ้งถอนเงิน")
                } else {
                    this._validateRequired(classNamed, true)
                }
            }
        }
    }
    onClickNotiMoney = (e) => {
        const { input_amount, withdrawAmount ,bank_withdraw_code } = this.state;
        console.log(input_amount, ",", withdrawAmount)
        if (parseFloat(input_amount) && parseFloat(input_amount) > 0 && Validators.isNotEmpty(this.state.bank_withdraw_code)) {
            if (parseFloat(input_amount) > parseFloat(withdrawAmount)) {
                this.showWarn("ยอดทั้งหมดที่ถอนได้ ไม่เพียงพอ")
                this._classNameRequiredIsError("input_amount_required", true)
            } else {
                this.props.dispatch(saveWithdrawal(input_amount,bank_withdraw_code))
                this.setState({isSave:true})
                // this.setState(pv => ({
                //     withdrawAmount: (pv.withdrawAmount - input_amount).toFixed(2)
                // }))
            }
        } else {
            this.showWarn("ไม่สามารถทำรายการได้ กรุณาตรวจสอบ")
            if(Validators.isEmpty(this.state.input_amount)){
                this._validateRequired('input_amount_required',false,'กรุณาระบุยอดเงินที่ต้องการถอน')
            }
            if(Validators.isEmpty(this.state.bank_withdraw_code)){
                this._validateRequired('bank_withdraw_code_required',false,'กรุณาระบุรหัสสำหรับแจ้งถอนเงิน')
            }
        }
    }

    // ======= Validate ======
    validateAllRequired = (nextState) => {
        if (nextState.input_amount) {
            this._validateLogic("input_amount_required", nextState.input_amount);
        }
    }
    _validateLogic = (name, value) => {
        if (Validators.isEmpty(value)) {
            this._classNameRequiredIsError(name, true);
        }
    }
    _classNameRequiredIsError = (classNamed, status) => {
        if (!classNamed) {
            if (status) {
                this.showWarn();
            }
        } else {
            if (status) {
                this.setState(pv => ({
                    classNameRequired: {
                        ...pv.classNameRequired, [classNamed]: "p-error p-col-12"
                    }
                }))
            } else {
                this.setState(pv => ({
                    classNameRequired: {
                        ...pv.classNameRequired, [classNamed]: "p-col-12"
                    }
                }))
            }
        }
    }
    _validateRequired = (className, valid, message) => {
        message = message || ''
        this.setState({
            [className]: {
                valid: valid,
                className: valid ? '' : 'p-error',
                message: message
            }
        })
    }
    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    render() {
        console.log("ExampleWastetranNotiWithdrawCtrl :render", this.state)

        let dataHistory = [];
        if (this.props.wastetranWithdrawHistory && !this.props.wastetranWithdrawHistory.isLoading) {
            if (!this.props.wastetranWithdrawHistory.isFailed) {
                for(let key in this.props.wastetranWithdrawHistory.data){
                    let entity = this.props.wastetranWithdrawHistory.data[key];
                    console.log(`entity ${entity}`)
                    let tmpData = {
                        "col1": dateFormat(entity.requested_date),
                        "col2": dateFormat(entity.approver_date),
                        "col3": entity.withdrawal_thb,
                        "col4": entity.bank_account_name,
                        "col5": entity.bank_account_no,
                        "col6": entity.bank_name,
                        "col7": entity.approver_uid === entity.waste_uid ? 'บริจาคแล้ว' : entity.approver_uid?'โอนแล้ว':'ต้องการถอน'
                    }
                    dataHistory.push(tmpData);
                }
            }
        }

        return (
            <div>
                <Growl ref={(el) => this.growl = el} />
                <h1>แจ้งถอนเงิน</h1>
                <div className="p-grid">
                    {/* DETAIL */}
                    <div className="p-col-12">
                        <div className="card card-w-title">
                            <WithDrawHeaderView
                                input_amount={this.state.input_amount}
                                input_amount_required={this.state.input_amount_required}
                                bank_withdraw_code={this.state.bank_withdraw_code}
                                bank_withdraw_code_required={this.state.bank_withdraw_code_required}
                                classNameRequired={this.state.classNameRequired}
                                withdrawAmount={this.state.withdrawAmount}
                                unApproveAmount={this.state.unApproveAmount}
                                onChangeInputText={this.onChangeInputText}
                                onClickNotiMoney={this.onClickNotiMoney}
                            />
                        </div>
                    </div>

                    <div className="p-col-12">
                        <Panel header="ประวัติการเบิกถอน">
                            <DynamicDataTableView
                                model={this.state.model}
                                data={dataHistory}
                            />
                        </Panel>
                    </div>


                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    wastetranBalance: state.wastetranBalance,
    wastetranWithdraw:state.wastetranWithdraw,
    wastetranWithdrawHistory:state.wastetranWithdrawHistory
})

export default connect(mapStateToProps)(ExampleWastetranNotiWithdrawCtrl)
