

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropsType from 'prop-types'

import { Dropdown } from 'primereact/dropdown'
import { InputText } from 'primereact/inputtext'
import { Panel } from 'primereact/panel';
import { Message } from 'primereact/message';
import { Validators, UtilFunctions } from '../../utils';


class AddressSwapInfoView extends Component {
    static defaultProps = {
        required: false
    }

    static propTypes = {
        required: PropsType.bool
    }
    state = {

    }

    //>Initial 
    componentDidMount() {
        //console.log("AddressSwapInfoView :componentDidMount")
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        //console.log("AddressSwapInfoView :componentWillReceiveProps")
    }

    shouldComponentUpdate(nextProps, nextState) {
        //console.log("AddressSwapInfoView :shouldComponentUpdate")
        return true
    }

    componentWillUpdate(nextProps, nextState) {
        //console.log("AddressSwapInfoView :componentWillUpdate")

    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("AddressSwapInfoView :componentDidUpdate")

    }

    //> Event
    handlePositiveNumber = (e) => {
        const characterCode = e.key
        if (characterCode === 'Backspace') return

        const characterNumber = Number(characterCode)
        if (characterNumber >= 0 && characterNumber <= 9) {
            if (e.currentTarget.value && e.currentTarget.value.length) {
                return
            } else if (characterNumber === 0) {
                // e.preventDefault()
            }
        } else {
            e.preventDefault()
        }
    }
    onChangeInputText = (e) => {
        const {
            value,
            max,
            min
        } = e.target
        if (Validators.isNotEmpty(max)) {
            if (Number(value) > Number(max)) {
                return;
            }
        }
        if (Validators.isNotEmpty(min)) {
            if (Number(value) < Number(min)) {
                return;
            }
        }
        this.props.onChangeInputText(e);
    }
    onChangeAdrCurrProv = (e) => {
        this.props.onChangeAdrCurrProv(e);
    }
    onChangeAdrCurrentDistrict = (e) => {
        this.props.onChangeAdrCurrentDistrict(e);
    }
    onChangeAdrCurrentSubDistrict = (e) => {
        this.props.onChangeAdrCurrentSubDistrict(e);
    }
    onChangeAdrCardProv = (e) => {
        this.props.onChangeAdrCardProv(e);
    }
    onChangeAdrCardDistrict = (e) => {
        this.props.onChangeAdrCardDistrict(e);
    }
    onChangeAdrCardSubDistrict = (e) => {
        this.props.onChangeAdrCardSubDistrict(e);
    }

    //> Mode render
    renderWastegen = () => {
        return (
            <div></div>
        )
    }
    renderWastetran = () => {
        return (
            <div></div>
        )
    }
    renderWastepro = () => {
        return (
            <div className="p-col-12">
                <Panel header={<label >ขนาดพื้นที่</label>}>
                    <div className="p-float-label" style={{ marginTop: "1.2em" }}>
                        <InputText
                            maxLength={11}
                            onKeyPress={Validators.handleNumber}
                            type="number"
                            min={0}
                            id="area_rai_id"
                            name="area_rai"
                            value={this.props.area_rai || ''}
                            onChange={this.onChangeInputText}>
                        </InputText>
                        <label htmlFor="area_rai_id">ไร่</label>
                    </div>

                    <div className="p-float-label" style={{ marginTop: "1.2em" }}>
                        <InputText
                            name="area_ngan"
                            type="number"
                            min={0}
                            max={3}
                            onKeyPress={this.handlePositiveNumber}
                            value={this.props.area_ngan || ''}
                            onChange={this.onChangeInputText}>
                        </InputText>
                        <label htmlFor="area_ngan_id">งาน</label>
                    </div>
                    <div className="p-float-label" style={{ marginTop: "1.2em" }}>
                        <InputText
                            id="area_square_wah_id"
                            type="number"
                            min={0}
                            max={99}
                            onKeyPress={this.handlePositiveNumber}
                            name="area_square_wah"
                            value={this.props.area_square_wah || ''}
                            onChange={this.onChangeInputText}>
                        </InputText>
                        <label htmlFor="area_square_wah_id">ตารางวา </label>
                    </div>
                </Panel>
            </div >

        )
    }

    validateShowMessage = (name) => {
        const {
            required,
            useCardAddress,
            selectedAdrCardProv,
            selectedAdrCurrentProv,
            selectedAdrCardDistrict,
            selectedAdrCurrentDistrict,
            selectedAdrCardSubDistrict,
            selectedAdrCurrentSubDistrict,
            addr_card_detail,
            addr_current_detail
        } = this.props

        switch (name) {
            case 'province_name':
                if (required) {
                    if (useCardAddress) {
                        return Validators.isEmpty(selectedAdrCardProv)
                    } else {
                        return Validators.isEmpty(selectedAdrCurrentProv)
                    }
                }
                break;
            case 'districtName':
                if (required) {
                    if (useCardAddress) {
                        return Validators.isEmpty(selectedAdrCardDistrict)
                    } else {
                        return Validators.isEmpty(selectedAdrCurrentDistrict)
                    }
                }
                break;
            case 'subDistrictName':
                if (required) {
                    if (useCardAddress) {
                        return Validators.isEmpty(selectedAdrCardSubDistrict)
                    } else {
                        return Validators.isEmpty(selectedAdrCurrentSubDistrict)
                    }
                }
                break;
            case 'address':

                if (required) {
                    if (useCardAddress) {
                        return Validators.isEmpty(addr_card_detail)
                    } else {
                        return Validators.isEmpty(addr_current_detail)
                    }
                }
                break;
        }
        return false
    }

    render() {
        console.log("AddressSwapInfoView :render", this.props)
        const {
            mode
        } = this.props

        let renderMode;

        switch (mode) {
            case 'wastegen':
                renderMode = this.renderWastegen()
                break;
            case 'wastetran':
                renderMode = this.renderWastetran()
                break;
            case 'wastepro':
                renderMode = this.renderWastepro()
                break;

            default:
                renderMode = (<div></div>)
                break;
        }

        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12  p-group">
                    <label className={this.props.required ? 'required' : ''}>
                        จังหวัด
                    </label>
                    <Dropdown style={{ width: '100%' }}
                        optionLabel="province_name"
                        required={this.props.required}
                        className={this.validateShowMessage("province_name")?'p-error':''}
                        value={(this.props.useCardAddress == true) ? this.props.selectedAdrCardProv : this.props.selectedAdrCurrentProv}
                        options={(this.props.useCardAddress == true) ? this.props.cardProvince : this.props.province}
                        onChange={(this.props.useCardAddress == true) ? this.onChangeAdrCardProv : this.onChangeAdrCurrProv}
                        placeholder="เลือก จังหวัด"
                        disabled={this.props.disabled} />
                    {this.validateShowMessage("province_name") &&
                        <Message severity="error" text="กรุณาระบุจังหวัด" />
                    }

                </div>

                <div className="p-col-12  p-group">
                    <label className={this.props.required ? 'required' : ''}>
                        อำเภอ/เขต
                    </label>
                    <Dropdown style={{ width: '100%' }}
                        optionLabel="districtName"
                        value={(this.props.useCardAddress == true) ? this.props.selectedAdrCardDistrict : this.props.selectedAdrCurrentDistrict}
                        options={(this.props.useCardAddress == true) ? this.props.cardDistrict : this.props.currentDistrict}
                        onChange={(this.props.useCardAddress == true) ? this.onChangeAdrCardDistrict : this.onChangeAdrCurrentDistrict}
                        placeholder="เลือก อำเภอ/เขต"
                        disabled={this.props.disabled} 
                        required={this.props.required}
                        className={this.validateShowMessage("districtName")?'p-error':''}
                        />
                    {this.validateShowMessage("districtName") &&
                        <Message severity="error" text="กรุณาระบุอำเภอ/เขต" />
                    }
                </div>

                <div className="p-col-12  p-group">
                    <label className={this.props.required ? 'required' : ''}>
                        ตำบล/แขวง
                    </label>
                    <Dropdown style={{ width: '100%' }}
                        optionLabel="subDistrictName"
                        value={(this.props.useCardAddress == true) ? this.props.selectedAdrCardSubDistrict : this.props.selectedAdrCurrentSubDistrict}
                        options={(this.props.useCardAddress == true) ? this.props.cardSubDistrict : this.props.currentSubDistrict}
                        onChange={(this.props.useCardAddress == true) ? this.onChangeAdrCardSubDistrict : this.onChangeAdrCurrentSubDistrict}
                        placeholder="เลือก ตำบล/แขวง"
                        disabled={this.props.disabled} 
                        required={this.props.required}
                        className={this.validateShowMessage("subDistrictName")?'p-error':''}
                        />
                    {this.validateShowMessage("subDistrictName") &&
                        <Message severity="error" text="กรุณาระบุตำบล/แขวง" />
                    }
                </div>

                <div className="p-col-12 p-group">
                    <label className={this.props.required ? 'required' : ''}>
                        เลขที่บ้าน/ที่อยู่
                    </label>
                    <InputText
                        maxLength={200}
                        placeholder="Address"
                        name={(this.props.useCardAddress == true) ? "addr_card_detail" : "addr_current_detail"}
                        value={(this.props.useCardAddress == true) ? this.props.addr_card_detail || '' : this.props.addr_current_detail || ''}
                        disabled={this.props.disabled}
                        onChange={this.onChangeInputText}
                        required={this.props.required}
                        className={this.validateShowMessage("address")?'p-error':''}
                        >
                    </InputText>
                    {this.validateShowMessage("address") &&
                        <Message severity="error" text="กรุณาระบุเลขที่บ้าน/ที่อยู่" />
                    }
                </div>
                {renderMode}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(AddressSwapInfoView)
