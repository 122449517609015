import React, { Component } from 'react';


export const WastetranOverviews = ({ summaryBalance, successBalance, noSuccessBalance, donateSummary }) => {

    return (
        <div className="p-grid ">
            <div className="p-col-12 p-md-6 p-lg-4">
                <div className="p-grid overview-box overview-box-1">
                    <div className="overview-box-title">
                        <i className="fa fa-inbox"></i>
                        <span>ยอดเงินสะสม</span>
                    </div>
                    <div className="overview-box-count">{summaryBalance}</div>
                    <div className="overview-box-stats">บาท</div>
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-4">
                <div className="p-grid overview-box overview-box-2">
                    <div className="overview-box-title">
                        <i className="fa fa-inbox"></i>
                        <span>ยอดเงินเบิกแล้ว</span>
                    </div>
                    <div className="overview-box-count">{successBalance}</div>
                    <div className="overview-box-stats">{`บาท (บริจาค ${donateSummary} บาท)`}</div>
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-4">
                <div className="p-grid overview-box overview-box-3">
                    <div className="overview-box-title">
                        <i className="fa fa-folder"></i>
                        <span>ยอดเงินค้างเบิก</span>
                    </div>
                    <div className="overview-box-count">{noSuccessBalance}</div>
                    <div className="overview-box-stats">บาท</div>
                </div>
            </div>
        </div>
    )

}