

import React, { Component } from 'react';
import propstype from 'prop-types'
import { connect } from 'react-redux';
import { Panel } from 'primereact/panel';
import { FileView } from '../../features/widget/FileView';
import { FileUpload } from 'primereact/fileupload';
import { getFile } from '../../utils/utilFunctions';
import { Validators } from '../../utils';
import { Button } from 'primereact/button';


class ImageInfoView extends Component {
    static defaultProps = {
        onSelect: null,
        onRefresh: null,
        onDelete: null
    }

    static propTypes = {
        onSelect: propstype.func,
        onRefresh: propstype.func,
        onDelete: propstype.func
    }

    state = {

    }

    //>Initial 
    componentDidMount() {
        //console.log
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        //console.log
    }

    shouldComponentUpdate(nextProps, nextState) {
        //console.log
        return true
    }

    componentWillUpdate(nextProps, nextState) {
        //console.log

    }

    componentDidUpdate(prevProps, prevState) {
        //console.log

    }

    //> Event
    onSelect = (e) => {
        this.props.onSelect(e);
    }
    onRefresh = (e) => this.props.onRefresh(e);
    onDelete = (e) => this.props.onDelete(e);

    //> Mode render
    renderWastegen = () => {
        return (
            <div></div>
        )
    }
    renderWastetran = () => {
        return (
            <div></div>
        )
    }
    renderWastepro = () => {
        return (
            <div></div>
        )
    }

    render() {
        //console.log
        const {
            mode,
            blob
        } = this.props

        let renderMode;

        switch (mode) {
            case 'wastegen':
                renderMode = this.renderWastegen()
                break;
            case 'wastetran':
                renderMode = this.renderWastetran()
                break;
            case 'wastepro':
                renderMode = this.renderWastepro()
                break;

            default:
                renderMode = (<div></div>)
                break;
        }

        let urlImage = (
            <div style={{ textAlign: 'center' }}>
                {!this.props.fileName &&
                    <div className="p-col-12" >
                        <Button
                            style={{ float: 'right' }}
                            icon="pi pi-refresh"
                            className="p-button-raised p-button-rounded p-button-secondary"
                            label="Refresh"
                            onClick={this.onRefresh}
                        />
                        <img width="95%" src="assets/layout/images/exception/image-not-found.png" />
                    </div>
                }
                {this.props.fileName &&
                    <div className="p-col-12">
                        <Button
                            style={{ float: 'right' }}
                            icon="pi pi-times"
                            className="p-button-raised p-button-rounded p-button-secondary"
                            label="Delete"
                            onClick={this.onDelete}
                        />
                        <FileView src={getFile(this.props.src)} />
                        {/* <label style={{ paddingLeft: '0px' }}>ชื่อไฟล์ : {this.props.fileName}</label> */}

                    </div>
                }
            </div>
        );
        if (Validators.isNotEmpty(blob)) {
            console.log(blob)
            if (blob.objectURL) {
                urlImage = (
                    <div className="p-col-12">
                        <Button
                            style={{ float: 'right' }}
                            icon="pi pi-times"
                            className="p-button-raised p-button-rounded p-button-secondary"
                            label="Delete"
                            onClick={this.onDelete}
                        />
                        <img width="95%" src={blob.objectURL} />

                        {/* <div className="p-col-12">
                            <label style={{ paddingLeft: '0px' }}>ชื่อไฟล์ : {blob.name}</label>
                        </div> */}
                    </div>
                );
            }
        }

        return (
            <div className="p-col-12">
                <Panel header={this.props.header} toggleable={true}>
                    <div className="p-col-12" style={{ textAlign: "center" }}>
                        {urlImage}
                    </div>
                    {(localStorage.getItem('second-token'))?'':
                        <FileUpload
                            name={this.props.name}
                            onSelect={this.onSelect}
                            chooseLabel="แนบไฟล์"
                            withCredentials={true}
                            cancelLabel="ยกเลิก"
                            multiple={false}
                            accept={this.props.accept}
                            maxFileSize={10000000}
                            auto={true}
                        />
                    }
                </Panel>
                {renderMode}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(ImageInfoView)
