import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import 'moment-timezone';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import * as DashBoardService from './service'
import { Growl } from 'primereact/growl';
import { DatatableRankView } from '../data-table/DatatableRankView';
import { DatatableOutBalanceView } from '../data-table/DatatableOutBalanceView';
import { Validators, SecureUtils } from '../../utils';
import { ChatAdminView } from '../message/ChatAdminView';
import { ChatRoom } from '../message/ChatRoom';
import { Card } from 'primereact/card';
import { Panel } from 'primereact/panel';
import {ListBox} from 'primereact/listbox';
import {OrderList} from 'primereact/orderlist';
import {InputText} from 'primereact/inputtext';
import { Button } from 'primereact/button';
import * as AdminService from '../../service/admin.service'
import { dateFormat } from '../../utils/utilFunctions';
import * as ServerAPI from './service';
import moment from 'moment'
import { API_ROOT } from '../../constants/api';
import { WASTE_TYPE_NUM_TO_NAME } from '../../constants/mappingValue'
import { DataScroller } from 'primereact/datascroller';
import { minBy, get } from 'lodash'
class ChatAdministrator extends Component {
    static propTypes = {

    }

    static defaultProps = {
    }

    constructor(props) {
        super(props);
        this.state = {
            userList: '',
        };

    }

    componentDidMount() {
        //console.log("ChatAdministrator :componentDidMount")
        this.getuserList()

        const { dashBoardServiceProps, adminServiceProps } = this.props
        dashBoardServiceProps.getDashboardUsers();
        adminServiceProps.getAdminDashSummaryWithdraw();
        // console.log(userList)
        // if(userList.length == 0){
        //     console.log('userList == 0 Load new Data')
        // const body = {
        //     per_page: 20,
        //     time_stamp: moment()
        //       .add(1, 'days')
        //       .format('YYYY-MM-DDTHH:mm:ss'),
        //   }
        //adminServiceProps.getUsersAllForManagement();
        // }
        // const listBoxElement = document.getElementsByClassName("p-listbox-list-wrapper")
        // listBoxElement[0].addEventListener('scroll', (e)=>this.handleScroll(e));

    }
    shouldComponentUpdate(nextProps, nextState) {
        //console.log("ChatAdministrator :shouldComponentUpdate")
        return true
    }
    componentWillReceiveProps(nextProps) {
        //console.log(nextProps)
    }
    componentWillUpdate(nextProps, nextState) {
        //console.log("ChatAdministrator :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        //console.log("ChatAdministrator :componentDidUpdate")
    }

    // handleScroll(e){
    //     const { userList,adminServiceProps } = this.props
    //     if(userList.length > 0){
    //         let scrollHeight = parseInt(e.srcElement.scrollHeight)
    //         let scrollTop = parseInt(e.srcElement.scrollTop)
    //         let clientHeight = parseInt(e.srcElement.clientHeight)
    //         // console.log(scrollHeight)
    //         // console.log(scrollTop)
    //         // console.log(clientHeight)
    //         if(scrollHeight-scrollTop == clientHeight || scrollHeight-scrollTop == clientHeight+1 || scrollHeight-scrollTop == clientHeight-1){
    //             console.log('load more old userList')
    //             //this.getMoreChatMessage(this.props.roomId, this.pageSize)
    //             const body = {
    //                 per_page: 20,
    //                 time_stamp: get(minBy(userList, 'last_chat.message_time'), 'last_chat.message_time', moment().format('YYYY-MM-DDTHH:mm:ss'))
    //             }
    //             // console.log(userList)
    //             // console.log(userList[19].last_chat.message_time)
    //             // console.log(body.time_stamp)
    //             adminServiceProps.getUsersAllForManagement(body);
    //         }
    //     }
    // }

    // ======= SHOW MESSAGE BOX =======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div></div>);
    }

    getuserList() {
        this.setState({ showProgress: true })
        const body = {
            per_page: 20,
            time_stamp: moment()
              .add(1, 'days')
              .format('YYYY-MM-DDTHH:mm:ss'),
          }
        ServerAPI.searchChatWasteAll(body).then((res) => {
            if (res) {
                var index = res.toString().indexOf("Error:");
                //console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({ userList: res })
                    this.setState({ showProgress: false })
                } else {
                    this.setState({ showProgress: false })
                }
            } else {
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }

    nameChatTemplate(option) {
        let avatar;
        const src = `${API_ROOT}/user/avatar/getfile/resize/100/${option.last_chat.receiver_img}`;  
        const ext = new RegExp(/\.(JPG|JPEG|PNG|jpg|jpeg|gif|png|tiff|svg)$/);
        const isCorrect = ext.test(src);
       
        if(isCorrect){
            avatar = src
        }else{
            avatar = "assets/avatar/user.png"
        }
        return (
            <div className="p-clearfix" style={{padding: '5px'}} key={option.uid}>
            <img 
                alt="Profile" className="profile-image image-cropper"
                src={avatar} 
                onError={(e) => e.target.src='assets/avatar/user.png'}
                style={{float:'left',display:'inline-block',marginTop:'2px',width:'50px',height:'50px',marginRight: '5px'}} />
                
            <span style={{fontSize:'1em',float:'left'}}>
                {option.applicant_name}
            </span>
            <br/>
            <span style={{fontSize:'1em',float:'left'}}>
                {option.username}
            </span>
            
            {option.last_chat.count_unread != 0 && 
            <div style={{
                height: '25px',
                width: '25px',
                backgroundColor: '#d73838',
                borderRadius: '50%',
                display: 'inline-block',
                float:'right'
                }}>
                <span 
                style={{margin: '9px',color:'#ffffff'}}
                >
                    {option.last_chat.count_unread}</span>
            </div>}
            <br/>
            <span style={{marginRight: '5px',color:'#cec5c7'}}>{moment(option.last_chat.message_time).format('DD/MM/YYYY HH:mm:ss')}</span>
            
        </div>
        );
    }
    emptyChatList(){
        const header = (
            <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
                <span>{'แชท'}</span>
                <Button onClick="" icon="pi pi-user" label="หน้ามุมมองสมาชิก" style={{ 'float': 'right' ,margin: '0'}} disabled/>
            </div>
        )
        const firstMessage = [{name:'เลือกคู่สนทนาจากรายชื่อ เพื่อเริ่มการสนทนา'}]
        const emptyChat = (
            <ListBox 
                options={firstMessage} 
                optionLabel="name"
                itemTemplate={this.firstMessageTemplate} 
                style={{width: '100%'}} 
                listStyle={{height: '400px'}}
                />
        );
        const inputMessage = (
            <div className="p-inputgroup">
            <Button 
                icon="pi pi-plus"
                className="p-button-defual" 
                disabled={true}
                iconPos='left'
                label=' '
                style={{}}/>
            <InputText 
                placeholder="ระบุข้อความ" 
                disabled={true}/>
            <Button 
                icon="fa fa-paper-plane"
                className="p-button-defual" 
                disabled={true}/>
        </div>
        );

        return (<Panel header={header}>
            <div className="p-grid p-fluid" >
                <div className="p-col-12 chat">
                    {emptyChat}
                </div>
                <div className="p-col-12">
                    {inputMessage}
                </div>

            </div>
        </Panel>)
    }
    firstMessageTemplate(firstMessage){
        return <div style={{textAlign: 'center',color: 'gray'}}>
                <h1>{firstMessage.name}</h1>
            </div>
    }
    chatList(){
        const { userList } = this.props
        const item = this.state.userSelected
        let otherWasteType = ''
        if(item){
            switch (item.user_waste_type_id) {
                case 1:
                  otherWasteType = 'wastegen'
                  break;
                case 2:
                  otherWasteType = 'wastetran'
                  break;
                case 3:
                  otherWasteType = 'wastepro'
                  break;
                default:
                  otherWasteType = 'administrator'
                  break;
              }
            return (
                <ChatRoom
                    orderCode={item.uid}
                    roomId={item.last_chat.room_id}
                    receiverId={item.uid}
                    receiverImage={item.last_chat.receiver_img}
                    receiverWasteType={otherWasteType}
                    isAdminChat={true}
                    isAmAdmin={true}
                    header={'แชทกับ '+item.applicant_name}
                    userList={userList}
                    receiverUserName={item.username}
                    onUpdateUserList={(e)=>this.updateRead(e)}
                />
                )   
        }else {
            return this.emptyChatList()
        }
        
    }
    onSelectListBox(e){
        if(e.value && e.value != null){
            this.setState({userSelected: e.target.value})
        }
        
        
    }

    updateRead(e){
        console.log(e)
        if(e.type == 0){
            this.props.adminServiceProps.updateUsersAll(e.userList, e.roomId, e.type)
        }
        

        if(e.type == 1){
            this.props.adminServiceProps.updateUsersAll(e.userList, e.roomId, e.type, e.message)
        }
    }

    render() {
        const { userList } = this.props
        console.log(this.props)
        const _userList = this.state.userList
        const _chatList = this.chatList()
        
        return (
            <div>
                <h1>Chat massage</h1>
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid dashboard">
                    <div className="p-col-12 p-md-3 p-lg-4">
                        
                            <ListBox
                                key={this.state.userList}
                                listStyle={{height:'480px'}}
                                value={this.state.userSelected} 
                                filter={true}
                                filterBy={'applicant_name'} 
                                filterPlaceholder="ค้นหาชื่อสมาชิก" 
                                options={userList} 
                                onChange={(e) => this.onSelectListBox(e)} 
                                itemTemplate={this.nameChatTemplate}
                                style={{width: '100%'}} 
                            />
                            {/* <DataScroller 
                                value={_userList} 
                                itemTemplate={this.nameChatTemplate} 
                                rows={7} 
                                inline 
                                scrollHeight="480px" 
                                header="Scroll Down to Load More" /> */}
                    </div>
                    <div className="p-col-12 p-md-9 p-lg-8" >
                        {_chatList}
                    </div>
                </div>
            </div>
        )
    }
}



const mapStateToProps = (state) => ({

    dhbUsers: state.dhbAdmin.dhbUsers,
    adminAnnounceAdd: state.adminAnnounceAdd,
    getAdminDashSummaryAreaSearch: state.getAdminDashSummaryAreaSearch,
    getAdminDashSummaryWithdraw: state.getAdminDashSummaryWithdraw,
    userList: state.getUsersAll.data,
})

const mapDispatchToProps = dispatch => ({

    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
    adminServiceProps: bindActionCreators(AdminService, dispatch)

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ChatAdministrator)