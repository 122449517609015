import React, { Component } from 'react';
import { Galleria } from 'primereact/galleria';
import { connect } from 'react-redux';
import { API_ROOT } from '../../constants/api';
import * as ServerAPI from '../../components/administrator/service';
import * as qs from 'query-string'
import { Button } from 'primereact/button';
import { ProgressSpinner } from 'primereact/progressspinner';
import Moment from 'react-moment';
import 'moment-timezone';


export class WasteproNewsDetail extends Component {

    constructor() {
        super();

        this.state = {
            images1: null,
            activeIndex: 2,
            list_news: null,
            showProgress: false,
            images: [
                { url: 'https://scontent.fbkk6-2.fna.fbcdn.net/v/t1.0-9/117237663_2757130567905276_6786226976383872435_o.jpg?_nc_cat=101&_nc_sid=8024bb&_nc_eui2=AeFfimm6COF5Rb8llRypOyR9iBDSpNwljcOIENKk3CWNw_BOwHqriQWmgTFpE2leoTUcwpiiGLijEHBVu2t-5BGD&_nc_ohc=k__BYJZ_suoAX-qafdn&_nc_ht=scontent.fbkk6-2.fna&oh=3d35dfdf12cb31c71db066f06bf6ee40&oe=5F510145' },
                { url: 'https://scontent.fbkk6-2.fna.fbcdn.net/v/t1.0-9/117378877_2757130544571945_5786670707519545710_n.jpg?_nc_cat=104&_nc_sid=8024bb&_nc_eui2=AeF_HEMDuBT_D5ExLWHZMj37R_4XwAx08x1H_hfADHTzHSIbPF1kkKHVii43sAUhUFVa89Mw7bjSei5lePa_6EEj&_nc_ohc=-l3e9zdn0cMAX9wLtE-&_nc_ht=scontent.fbkk6-2.fna&oh=e5281c53ebf72010422af826308450c7&oe=5F509DA3' },
                { url: 'https://i1.wp.com/marketeeronline.co/wp-content/uploads/2020/03/1..jpg?resize=1024%2C683&ssl=1' },
                { url: 'https://scontent.fbkk6-2.fna.fbcdn.net/v/t1.0-9/117237663_2757130567905276_6786226976383872435_o.jpg?_nc_cat=101&_nc_sid=8024bb&_nc_eui2=AeFfimm6COF5Rb8llRypOyR9iBDSpNwljcOIENKk3CWNw_BOwHqriQWmgTFpE2leoTUcwpiiGLijEHBVu2t-5BGD&_nc_ohc=k__BYJZ_suoAX-qafdn&_nc_ht=scontent.fbkk6-2.fna&oh=3d35dfdf12cb31c71db066f06bf6ee40&oe=5F510145' },
                { url: 'https://scontent.fbkk6-2.fna.fbcdn.net/v/t1.0-9/117378877_2757130544571945_5786670707519545710_n.jpg?_nc_cat=104&_nc_sid=8024bb&_nc_eui2=AeF_HEMDuBT_D5ExLWHZMj37R_4XwAx08x1H_hfADHTzHSIbPF1kkKHVii43sAUhUFVa89Mw7bjSei5lePa_6EEj&_nc_ohc=-l3e9zdn0cMAX9wLtE-&_nc_ht=scontent.fbkk6-2.fna&oh=e5281c53ebf72010422af826308450c7&oe=5F509DA3' },
                { url: 'https://i1.wp.com/marketeeronline.co/wp-content/uploads/2020/03/1..jpg?resize=1024%2C683&ssl=1' },
                { url: 'https://scontent.fbkk6-2.fna.fbcdn.net/v/t1.0-9/117237663_2757130567905276_6786226976383872435_o.jpg?_nc_cat=101&_nc_sid=8024bb&_nc_eui2=AeFfimm6COF5Rb8llRypOyR9iBDSpNwljcOIENKk3CWNw_BOwHqriQWmgTFpE2leoTUcwpiiGLijEHBVu2t-5BGD&_nc_ohc=k__BYJZ_suoAX-qafdn&_nc_ht=scontent.fbkk6-2.fna&oh=3d35dfdf12cb31c71db066f06bf6ee40&oe=5F510145' },
                { url: 'https://scontent.fbkk6-2.fna.fbcdn.net/v/t1.0-9/117378877_2757130544571945_5786670707519545710_n.jpg?_nc_cat=104&_nc_sid=8024bb&_nc_eui2=AeF_HEMDuBT_D5ExLWHZMj37R_4XwAx08x1H_hfADHTzHSIbPF1kkKHVii43sAUhUFVa89Mw7bjSei5lePa_6EEj&_nc_ohc=-l3e9zdn0cMAX9wLtE-&_nc_ht=scontent.fbkk6-2.fna&oh=e5281c53ebf72010422af826308450c7&oe=5F509DA3' },
                { url: 'https://i1.wp.com/marketeeronline.co/wp-content/uploads/2020/03/1..jpg?resize=1024%2C683&ssl=1' },
            ]
        };

        this.itemTemplate = this.itemTemplate.bind(this);
        this.previewTemplate = this.previewTemplate.bind(this);
        this.onItemChange = this.onItemChange.bind(this)


        this.responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 5
            },
            {
                breakpoint: '768px',
                numVisible: 3
            },
            {
                breakpoint: '560px',
                numVisible: 1
            }
        ];
    }

    componentDidMount() {
        console.log(this.props);
        this.setState({ showProgress: true })

        if (this.props.location) {
            if (this.props.location.search) {
                let newsCode = qs.parse(this.props.location.search);
                this.setState({ newsgid: newsCode.newsCode });
                console.log(newsCode.newsCode);
                ServerAPI.getNewsByCode(newsCode.newsCode).then((res) => {
                    console.log(res);
                    if (res) {
                        var index = res.toString().indexOf("Error:");
                        console.log("find error:" + (index));
                        if (index == -1) {
                            console.log('sucsress');
                            this.setState(
                                {
                                    list_news: res[0],
                                    activeIndex: res[0].defaultImageIndex,
                                    showProgress: false
                                }
                            )
                        } else {
                            this.setState({ showProgress: false })
                        }
                    } else {
                        this.setState({ showProgress: false })
                    }
                }).catch((error) => {
                    this.setState({ showProgress: false })
                })
            } else {
                this.setState({ showProgress: false })
            }
        } else {
            this.setState({ showProgress: false })
        }
    }


    onOpenFiles(e) {
        console.log(e)

        var imgList = [];
        e.newsImageDetails.forEach((item, index) => {
            let array = {
                url: `${API_ROOT}/user/news/getfile/${item.imageName}`,
                name: item.name,
                gid: item.gid,
                index: index,
            }
            imgList.push(array)
            console.log(imgList)
            this.setState({ fileImgList: imgList });
        })
    }

    onItemChange(event) {
        this.setState({ activeIndex: event.index });
    }

    itemTemplate(item) {
        return (
            <div className="p-grid p-nogutter p-justify-center">
                <img src={`${API_ROOT}/user/news/getfile/${item.imageName}`} alt={item.alt} style={{ width: '80%' ,margin:'20px'}} />
            </div>
        );

    }

    previewTemplate(item) {
        console.log(item);
        return <img src={`${API_ROOT}/user/news/getfile/${item.imageName}`} alt={item.alt} style={{ width: '80%' }} />
    }

    toFormatDateTime(dateTime){
        return <div>
            <Moment format="วันที่ DD/MM/YYYY">
                {dateTime}
            </Moment>
            
            <Moment format=" เวลา HH:mm น.">
                {dateTime}
            </Moment></div>; 
    }
    to_create_date(rowData, column){
        return this.toFormatDateTime(rowData.createdDate);
    }


    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div></div>);
    }


    render() {

        console.log(this.state.list_news);
        if (this.state.list_news == null) {
            console.log(this.state.list_news);
            if (this.state.showProgress == true) {
                return <div>
                    {this.showProgressSpinner()}
                </div>
            } else {
                return <div>
                    เกิดข้อผิดพลาด
                    <a href="#/wastepro">
                        <div style={{ color: 'green500' }}><u>ย้อนกลับ</u></div>
                    </a>
                </div>
            }

        }
        return (
            <div>
                {/* <h1>ข่าวสาร</h1> */}
                <div className="p-grid">
                    <div className="card card-w-title p-lg-12  p-md-12  p-sm-12">
                        <div className="p-col-6 p-offset-1" style={{ width: '1000px' }} >
                            <br />
                            <span className="text" style={{ marginTop: '10px', fontSize: '40px' }}>
                                {this.state.list_news.newsTitle}
                            </span>

                            <br /><br />
                            <div style={{ borderStyle: 'solid', color: 'green' }}></div>
                            <br />
                            <span className="text" style={{ marginTop: '10px', fontSize: '20px', color: 'green' }}>
                                C3leng News
                            </span>
                            <br />
                            
                            <span className="text" style={{ marginTop: '10px', fontSize: '18px', color: '#acacac' }}>
                            {this.toFormatDateTime(this.state.list_news.updateDate)}
                            </span>

                            <br /><br />
                            <img src={`${API_ROOT}/user/news/getfile/${this.state.list_news.defaultImageName}`} style={{ width: '100%' }} />
                            <br /><br />
                            <div style={{ borderStyle: 'solid', color: 'green' }}></div>
                            <br /><br />
                            <span className="text" style={{ marginTop: '10px', fontSize: '18px' , letterSpacing:'1px',lineHeight:'2'}}>
                                &emsp;&emsp;&emsp;{this.state.list_news.newsDetail}
                            </span>
                            <br /><br />
                            <div style={{borderWidth:'1px', borderTop: 'solid', color: '#acacac' }}></div>
                            <br /><br />
                            <Galleria value={this.state.list_news.newsImageDetails} activeIndex={this.state.activeIndex} onItemChange={this.onItemChange}
                                responsiveOptions={this.responsiveOptions} numVisible={5} previewItemTemplate={this.previewTemplate}
                                thumbnailItemTemplate={this.itemTemplate} style={{ maxWidth: '1500px' }} thumbnailsPosition="top" />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    wastegenBalance: state.wastegenBalance,
    userAnnounce: state.userAnnounce,
    getWastegenDashLastOrder: state.getWastegenDashLastOrder,
    getWastegenDashProduct: state.getWastegenDashProduct
})

export default connect(mapStateToProps)(WasteproNewsDetail)