import React, { useState, useEffect, useRef } from "react";

export default function Landing() {

  const home = useRef()
  const about = useRef()
  const privacyPolicy = useRef()
  const contractUs = useRef()

  const [isPrivacy, setIsPrivacy] = useState(false)

  const scrollTo = (ref) => {
    if (ref && ref.current /* + other conditions */) {
      ref.current.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <>
      {/******Main Wrapper******/}
      <div ref={home} className="main-wrapper">
        <header className="main-header">
          {/*NAVBAR WITH RESPONSIVE TOGGLE*/}
          <div className="top_menu">
            <nav className="navbar navbar-expand-lg navbar-light bg-transparent">
              <div className="container">
                <a className="navbar-brand">
                  <img src="assets/images/logo_c3leng.png" alt="logo" style={{ width: '80%', marginTop: -5 }} />
                </a>
                <button
                  className="navbar-toggler"
                  data-toggle="collapse"
                  data-target="#navbarNav"
                >
                  {" "}
                  <span className="navbar-toggler-icon" />
                </button>
                <div className="collapse navbar-collapse" id="navbarNav" style={{ marginTop: -15 }}>
                  <ul className="navbar-nav ml-auto" id="nav">
                    <li className="nav-item">
                      <a className="nav-link js-scroll-trigger" onClick={() => scrollTo(home)}>
                        หน้าหลัก
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link js-scroll-trigger" onClick={() => scrollTo(about)}>
                        บริการของเรา
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link js-scroll-trigger"
                        onClick={() => scrollTo(privacyPolicy)}
                      >
                        นโยบายความเป็นส่วนตัว
                      </a>
                    </li>
                    <li className="nav-item">
                      <a className="nav-link js-scroll-trigger" onClick={() => scrollTo(contractUs)}>
                        ติดต่อเรา
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </header>

        {/******Main Cntainer******/}
        <section className="main_banner_area text-white">
          <div id="particles-js" />
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-5 col-md-6 mr-md-auto">
                <div className="info">
                  <h1 className="display-3 mb-3 bold">ซีซาเล้ง</h1>
                  <p className="mb-5" style={{ fontSize: "1.4em" }}>
                    จัดการขยะรีไซเคิลเข้าสู่ระบบฯ ครบ จบ ในแอปฯเดียว
                    โหลดได้เลยที่นี่
                  </p>
                  {/*<button i className="fab fa-google-play"  className="btn"><span>available on</span> Play Store</button>*/}

                  <div className="app-download-area">
                    <div className="app-download-btn">
                      {/*Google Play Btn*/}
                      <a href="https://play.google.com/store/apps/details?id=com.GrungThaiRecycle.c3leng">
                        <i className="fab fa-google-play" />
                        <p className="mb-0">
                          <span>available on</span> Play Store
                        </p>
                      </a>
                    </div>
                    <div className="app-download-btn">
                      {/*Apple Store Btn*/}
                      <a href="https://apps.apple.com/us/app/c3leng/id1538005429">
                        <i className="fab fa-apple" />
                        <p className="mb-0">
                          <span>available on</span> Apple Store
                        </p>
                      </a>
                    </div>
                  </div>
                  <h4 className="display- mb-3 bold">
                    เตรียมพบกับ ซีซาเล้ง รูปโฉมใหม่เร็วๆนี้
                  </h4>
                </div>
              </div>

              <div className="col-12 col-lg-5 col-md-6">
                <div className="app-img">
                  <img src="assets/images/bg-img/main-bg-img.png" alt="image" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/****** special about our app Section ******/}
        <section ref={about}>
          <div className="container">
            <div className="row">
              <div className="col-12">
                {/*Section Heading Area*/}
                <div className="section-heading text-center">
                  <h2>ทำความรู้จัก ซีซาเล้ง </h2>
                  <div className="line-title-center" />
                  <p>"C3Leng" แอปฯ ซาเล้งรับซื้อขยะรีไซเคิล</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-lg-6 col-sm-12">
                <div className="feature-right">
                  <div className="feature-single">
                    <h4>
                      <i className="icon-screen-smartphone" /> C3Leng Friend
                      (ผู้ทิ้งหรือผู้ขายขยะรีไซเคิล)
                    </h4>
                    <p style={{ textIndent: "45px" }}>
                      บุคคลทั่วไป / ร้านค้าฯ / องค์กรฯ / ชุมชนฯ
                      ที่ต้องการนำขยะรีไซเคิลเข้าสู่กระบวนการจัดการที่ดีและถูกต้อง
                      เพื่อลดขยะตกค้าง
                      นำมาซึ่งสิ่งแวดล้อมที่ดีและโลกน่าอยู่มากขึ้น
                    </p>
                  </div>
                  <div className="feature-single">
                    <h4>
                      <i className="icon-equalizer icons" /> C3Leng Move
                      (ผู้ขนส่งขยะรีไซเคิล)
                    </h4>
                    <p style={{ textIndent: "45px" }}>
                      เป็นผู้ขนส่ง เพื่อนำขยะรีไซเคิลจาก
                      “ผู้ทิ้งหรือผู้ขายขยะรีไซเคิล” เพื่อส่งมอบไปยัง
                      “ร้านรับซื้อขยะรีไซเคิล”
                      เข้าสู่กระบวนการจัดการค้าวัสดุรีไซเคิล
                      เพื่อคัดแยกประเภทต่อไป
                    </p>
                  </div>
                  <div className="feature-single">
                    <h4>
                      <i className="icon-support icons" /> Collecting Center
                      (ร้านรับซื้อขยะรีไซเคิล)
                    </h4>
                    <p style={{ textIndent: "45px" }}>
                      เป็นผู้รวบรวม, คัดแยกขยะรีไซเคิล
                      เพื่อเข้าสู่กระบวนการแปรรูปเป็นวัตถุดิบทดแทน,
                      ลดการใช้ทรัพยากรธรรมชาติ
                      เพื่อส่งต่อสิ่งแวดล้อมที่ดีและยั่งยืนให้คนรุ่นต่อไป
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-lg-6 col-sm-12">
                <div className="feature-img text-center">
                  <img src="assets/images/bg-img/lt_mob.png" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <h4> </h4>
                <div className="feature-img">
                  <img
                    src="assets/images/scr-img/backdrop.jpg"
                    style={{ width: "100%" }}
                    alt="C-3leng Feature"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/****** special policy our app Section ******/}
        <section ref={privacyPolicy}>
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                {/*Heading Text */}
                <div className="section-heading text-center">
                  <h2>นโยบายคุ้มครองสิทธิความเป็นส่วนตัวของ ซีซาเล้ง</h2>
                  <div className="line-title-center" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div id="accordion" role="tablist">
                  <div className="card">
                    <div className="card-header heading" role="tab" style={{ background: 'none', border: 'none'}}>
                      <h6 className="mb-0">
                        <a
                          href="#collapse1"
                          data-parent="#accordion"
                          data-toggle="collapse"
                          aria-expanded="false"
                          className="collapsed"
                          onClick={() => setIsPrivacy(!isPrivacy)}
                        >
                          เปิดอ่านนโยบายคุ้มครองสิทธิความเป็นส่วนตัวของ ซีซาเล้ง
                          {!isPrivacy ? <i className="icon-arrow-down float-right" /> : <i className="icon-arrow-up float-right" />}
                        </a>
                      </h6>
                    </div>

                    <div id="collapse1" className="collapse">
                      <div className="card-body">
                        <p style={{ textIndent: "45px" }}>
                          บริษัท แก้วกรุงไทย จำกัด (“บริษัทฯ”)
                          ซึ่งประกอบกิจการและกำกับดูแลตลาดออนไลน์ภายใต้ชื่อ
                          “C3Leng” ในประเทศไทยแต่เพียงผู้เดียว
                          คำนึงถึงมาตราการปกป้องข้อมูลทุกรูปแบบที่บริษัทฯ
                          มีในครอบครองทั้งหมดตามกฎหมายต่างๆ ที่เกี่ยวข้อง บริษัท
                          แก้วกรุงไทย จำกัด ในฐานะผู้ควบคุมข้อมูลตามกฎหมาย
                          มีสำนักงานตั้งอยู่เลขที่ 589 อาคารวิชชุวรรณ
                          ชั้นที่ 2 ถนนรัชดาภิเษก แขวงดินแดง เขตดินแดง
                          กรุงเทพมหานคร 10400  นโยบายความเป็นส่วนตัว (“นโยบาย”)
                          นี้ ชี้แจงรายละเอียดเกี่ยวกับการได้มา การใช้/ประมวลผล
                          และการเก็บรักษา
                          ข้อมูลของผู้ใช้บริการ C3Leng ของบริษัทฯ เท่านั้น
                          <br />
                        </p>
                        <p style={{ textIndent: "45px" }}>
                          บริษัทฯ
                          ให้ความสำคัญเกี่ยวกับความโปร่งใสในการดำเนินธุรกิจและการให้บริการต่อสมาชิกหรือผู้ใช้บริการ C3Leng ในฐานะเจ้าของข้อมูล
                          (“คุณ”)
                          นโยบายความเป็นส่วนตัวนี้ใช้บังคับเกี่ยวกับข้อมูลที่บริษัทฯ
                          เก็บรวบรวมและรักษาไว้ โดยอธิบายครอบคลุมถึงประเภท
                          วัตถุประสงค์
                          และวิธีปฏิบัติเกี่ยวกับการใช้ประมวลผลและการเก็บรักษาข้อมูลต่างๆ
                          อันได้แก่ ไอพีแอดเดรส (IP Address) คุกกี้ (Cookies)
                          และ/หรือข้อมูลส่วนบุคคลที่คุณมอบให้ผ่านเว็บไซต์และแอพพลิเคชั่นของบริษัทฯ
                          ขณะที่ลงทะเบียนและใช้บริการ C3Leng ซึ่งรวมถึง
                          ข้อมูล Facebook และ/หรือ Google Account ของคุณ
                          คุณมีหน้าที่ต้องอ่านและรับทราบนโยบายนี้ทั้งหมดก่อนที่จะให้ข้อมูลใดๆ
                          กับบริษัทฯ 
                          การใช้เว็บไซต์หรือแอพพลิเคชั่น C3Leng หรือลงทะเบียนการขอใช้บริการและกิจกรรมอื่นๆ
                          ของเรา
                          โดยคุณจะต้องให้ความยินยอมผ่านการแสดงเจตนาโดยชัดแจ้งหรือโดยนัยในการอนุญาตให้บริษัทฯ
                          รวบรวม
                          ใช้และเก็บรักษาข้อมูลของคุณตามที่กำหนดไว้ตามนโยบายนี้
                          หากคุณมีข้อสงสัยเกี่ยวกับแนวทางปฏิบัติตามนโยบายความเป็นส่วนตัวนี้
                          รวมถึงข้อกังวลหรือข้อร้องเรียนเกี่ยวกับข้อมูลของคุณประการใด
                          โปรดติดต่อโดยส่งเป็นจดหมายหรือคำร้องผ่านทางอีเมลมาที่
                          adminc3leng@c3leng.com ข้อมูลที่บริษัทฯรวบรวม
                        </p>
                        <h3 style={{ fontSize: "1rem" }}>แหล่งข้อมูล</h3>
                        <p style={{ textIndent: "45px" }}>
                          บริษัทฯรวบรวมข้อมูลเกี่ยวกับคุณหรือข้อมูลเกี่ยวกับอุปกรณ์การใช้งานของคุณจากคุณโดยตรงเท่านั้น
                          ตราบเท่าที่คุณอนุญาตโดยชัดแจ้งหรือโดยนัยให้เราใช้ประมวลผลและเก็บรักษาข้อมูลดังกล่าวผ่านทาง
                        </p>
                        <p>
                          &ensp;• เว็บไซต์ C3Leng
                          <br />
                          &ensp;• แอปพลิเคชั่น C3Leng
                          <br />
                          &ensp;• Facebook (ข้อมูลบัญชีสมาชิกที่เป็นสาธารณะ)
                          <br />
                          &ensp;• Google Account
                          (ข้อมูลบัญชีสมาชิกที่เป็นสาธารณะ)
                          <br />
                          &ensp;• อุปกรณ์ของผู้ใช้ (คอมพิวเตอร์ และ/หรือ
                          โทรศัพท์มือถือ)
                          <br />
                        </p>
                        <h3 style={{ fontSize: "1rem" }}>ประเภทของข้อมูล</h3>
                        <p>
                          จากแหล่งข้อมูลข้างต้น
                          ข้อมูลที่บริษัทฯจะเก็บรวบรวมมีดังนี้
                          <br />
                        </p>
                        <p />
                        &ensp;• ชื่อ
                        <br />
                        &ensp;• นามสกุล
                        <br />
                        &ensp;• รูปภาพโปรไฟล์ / ภาพถ่าย
                        <br />
                        &ensp;• ที่อยู่อีเมล์
                        <br />
                        &ensp;• ชื่อบัญชี C3Len Application
                        <br />
                        &ensp;• ชื่อและรหัสผ่านสำหรับบัญชีสมาชิก C3Leng
                        <br />
                        &ensp;• ที่อยู่
                        <br />
                        &ensp;• หมายเลขโทรศัพท์
                        <br />
                        &ensp;• Facebook (member_id, public_profile)
                        <br />
                        &ensp;• Google Account (member_id, public_profile)
                        <br />
                        &ensp;•
                        เนื้อหาหรือข้อมูลเกี่ยวกับการโพสต์สินค้าบนแพลตฟอร์มของ C3Leng
                        <br />
                        &ensp;• คุกกี้
                        <br />
                        &ensp;• ไอพีแอดเดรส (IP Address)
                        <br />
                        &ensp;• ตำแหน่งทางภูมิศาสตร์
                        <br />
                        &ensp;•
                        เนื้อหาหรือข้อมูลในเครื่องมือแชทที่มีอยู่บน C3Leng
                        <br />
                        <h3 style={{ fontSize: "1rem" }}>
                          วิธีการเก็บรวบรวมข้อมูล
                        </h3>
                        <p>เราเก็บรวบรวมข้อมูลเกี่ยวกับคุณ ดังต่อไปนี้</p>
                        <br />
                        <p>
                          &ensp;•
                          ในขณะที่คุณกรอกข้อมูลเพื่อการลงทะเบียนเปิดบัญชีสมาชิก C3Leng หรือดูแลปรับปรุงข้อมูลบัญชีสมาชิกของคุณ
                          หรือขอรับบริการต่าง ๆ บนแพลตฟอร์ม C3Leng
                          <br />
                          &ensp;•
                          การลงทะเบียนบัญชีสมาชิก C3Leng บริษัทฯจะเก็บรวบรวมข้อมูลเฉพาะที่คุณได้ให้ในแบบฟอร์มที่แสดงอยู่บนแพลตฟอร์มเท่านั้น
                          <br />
                          &ensp;•
                          การเลือกลงทะเบียนบัญชีสมาชิก C3Leng โดยให้เชื่อมต่อกับบัญชีสมาชิก Facebook หรือ
                          Google ของคุณ
                          บริษัทฯจะเก็บรวบรวมข้อมูลโปรไฟล์จากบัญชีสมาชิก Facebook หรือ
                          Google เฉพาะส่วนที่คุณเปิดเผยแก่สาธารณะ
                          โปรดทราบว่าบริษัทฯไม่จัดเก็บหรือรับรู้รหัสผ่านเข้าบัญชีสมาชิก Facebook หรือ
                          Google ของคุณในระบบข้อมูลของบริษัทฯแต่อย่างใด
                          <br />
                          &ensp;• ข้อมูลเกี่ยวกับสินค้า คำบรรยายสินค้า
                          รูปภาพ ที่ผู้ใช้งานได้ให้หรือปรากฏบนแพลตฟอร์มของ C3Leng
                          <br />
                          &ensp;•
                          ข้อมูลการสนทนาหรือข้อความระหว่างผู้ใช้งานที่ปรากฏในระบบสนทนา บนแพลตฟอร์ม C3Leng
                          <br />
                        </p>
                        <p>
                          บริษัทฯรวบรวมข้อมูลเกี่ยวกับอุปกรณ์ของคุณ ดังต่อไปนี้
                        </p>
                        <p>
                          เมื่อคุณใช้เว็บไซต์หรือแอพพลิเคชั่น C3Leng บริษัทฯจะเก็บรวบรวมและวิเคราะห์ข้อมูลบางอย่างโดยอัตโนมัติซึ่งรวมถึงเบราว์เซอร์ที่ชี้เฉพาะเจาะจง
                          ที่อยู่ IP และข้อมูลเกี่ยวกับระบบปฏิบัติการ
                          อุปกรณ์ที่ชี้เฉพาะเจาะจง (เช่น Apple IDFA หรือ Android
                          Advertising
                          ID) ตำแหน่งทางภูมิศาสตร์และข้อมูลที่ชี้เฉพาะถึงอุปกรณ์อื่น
                          ๆ ข้อมูลเกี่ยวกับอินเทอร์เน็ต
                          รวมถึงรายละเอียดเกี่ยวกับธุรกรรมของคุณที่เกิดขึ้นบนเว็บไซต์และแอพ C3Leng
                        </p>
                        <br />
                        <h3 style={{ fontSize: "1rem" }}>
                          การใช้คุกกี้และเทคโนโลยีอื่น ๆ
                        </h3>
                        <p>
                          &emsp;บริษัทฯ
                          และบุคคลที่สามใช้เทคโนโลยีเพื่อเก็บรวบรวมข้อมูลเกี่ยวกับการใช้งานของคุณที่เกิดขึ้นบนเว็บไซต์และแอพ C3Leng
                        </p>
                        <p>
                          &ensp;• คุกกี้และเทคโนโลยีอื่น ๆ
                          เพื่อการเก็บข้อมูล คุกกี้และเทคโนโลยีอื่น ๆ
                          เพื่อการจัดเก็บ
                          หมายความถึงไฟล์ที่มีข้อมูลเฉพาะของอุปกรณ์ที่ทางบริษัท
                          ฯ
                          หรือบุคคลที่สามได้ส่งถึงหรืออ่านได้จากอุปกรณ์ของคุณซึ่งจะบันทึกจดจำอุปกรณ์ดังกล่าวเพื่อวัตถุประสงค์ในการให้บริการ
                          การบันทึก การวิเคราะห์พฤติกรรมและทางการตลาด  บริษัท ฯ
                          และบุคคลที่สามจะใช้วิธีการอัตโนมัติในการอ่านหรือบันทึกข้อมูลอุปกรณ์ของคุณ
                          ซึ่งรวมถึงคุกกี้ประเภทต่าง ๆ และเบราว์เซอร์ทั้งหลาย
                          หรือปลั๊กอินอื่น ๆ
                          (เช่นที่เก็บ HTML5 หรือฐานข้อมูลแบบ Flash)
                          โดยคุณอาจเลือกที่จะตั้งค่าเว็บเบราว์เซอร์ของคุณให้ปฏิเสธการให้จัดเก็บคุกกี้บางประเภทหรือจัดให้มีการเตือนในกรณีที่มีการส่งคุกกี้บางประเภทได้
                          ซึ่งเบราว์เซอร์บางชนิดอาจสามารถถูกตั้งค่าที่คล้ายกันในแบบ HTML5 และ Flash ได้ อย่างไรก็ตามหากคุณป้องกันหรือปฏิเสธการให้จัดเก็บคุกกี้หรือที่เก็บข้อมูลในพื้นที่เว็บไซต์ของบริษัทฯ
                          อาจส่งผลต่อการทำงานของเว็บไซต์ C3Leng ได้
                          <br />
                          &ensp;• เทคโนโลยีอื่น ๆ ในการเก็บข้อมูล 
                          บริษัทฯใช้เทคโนโลยีอัตโนมัติมาตรฐานต่าง ๆ
                          ในการเก็บข้อมูลเช่นเว็บบีคอน (หรือเรียกว่า clear
                          GIFs หรือพิกเซลแท็ก)
                          หรือเทคโนโลยีที่คล้ายคลึงกันเพื่อการติดต่อหรือสื่อสารผ่านทางคุกกี้ต่าง
                          ๆ
                          และเพื่อติดตามผลการใช้งานของคุณบนเว็บไซต์และ/หรือแอพของบริษัทฯ
                          นอกจากนี้บริษัทฯยังใช้เว็บบีคอนกับข้อความในอีเมล์เพื่อติดตามผลว่าคุณเปิดอ่านข้อความ
                          รวมถึงการใช้งานอื่น ๆ ดังต่อไปนี้ <br />
                        </p>
                        <p>
                          &emsp;ในบางกรณีบริษัทฯอาจใช้ข้อมูลดังกล่าวเพื่อการโฆษณาที่ดำเนินการโดยบุคคลที่สาม
                          ซึ่งจะใช้วิธีการเก็บรวบรวมข้อมูลโดยอัตโนมัติ
                          เช่นคุกกี้
                          ที่จะติดตามการใช้งานบนเว็บไซต์ของคุณเป็นช่วงๆไป
                          โดยข้อมูลดังกล่าว
                          รวมถึงข้อมูลเกี่ยวกับการเข้าชมเว็บไซต์หรือแอพพลิเคชั่นต่างๆ
                          และข้อมูลอื่นๆ ที่บริษัทฯได้รับ
                          อาจถูกใช้เพื่อปรับการเสนอโฆษณาที่เหมาะสมตรงกับความสนใจหรือคุณลักษณะเฉพาะตัว
                          <br />
                        </p>
                        <p>
                          &emsp;อย่างไรก็ดี
                          คุณสามารถควบคุมจัดการที่เกี่ยวกับโฆษณาและการวิเคราะห์ที่นำเสนอโดย Google ได้ที่ https://adssettings.google.com/ และ https://tools.google.com/dlpage/gaoptout ซึ่งการจัดการ
                          เปลี่ยนแปลงหรืออัปเกรดเบราว์เซอร์ของคุณหรือการลบคุกกี้ออกจากระบบดังกล่าว
                          อาจต้องทำผ่านวิธีการเลือกไม่รับ (opt-out) อีกขั้นตอนหนึ่ง
                          ซึ่งการต่อสัญญาณที่ไม่ได้ติดตามบนเบราว์เซอร์ดังกล่าวอยู่นอกเหนือความรับผิดชอบของบริษัทฯ
                          <br />
                        </p>
                        <p>
                          &emsp;สำหรับการควบคุมจัดการความเป็นส่วนตัวเกี่ยวกับอุปกรณ์สื่อสารไร้สายของคุณ
                          โปรดตรวจสอบรายละเอียดคำแนะนำเกี่ยวกับวิธีใช้การควบคุมความเป็นส่วนตัวเพิ่มเติมใดๆ
                          ที่ระบบปฏิบัติการมือถือของคุณนำเสนอเช่นการตั้งค่าความเป็นส่วนตัวสำหรับตัวระบุอุปกรณ์และตำแหน่งทางภูมิศาสตร์ได้ด้วยตนเองที่เว็บไซต์ของผู้ผลิตอุปกรณ์อุปกรณ์สื่อสารไร้สายของคุณ 
                          <br />
                        </p>
                        <h3 style={{ fontSize: "1rem" }}>
                          ลิงค์ของบุคคลที่สาม
                        </h3>
                        <p>
                          •
                          เว็บไซต์และแอปพลิเคชันของ C3Leng อาจมีลิงก์ของเว็บไซต์อื่นๆ
                          (“บุคคลที่สาม”) ซึ่งไม่อยู่ภายใต้การดูแลของบริษัทฯ
                          คุณควรตรวจสอบนโยบายความเป็นส่วนตัวของเว็บไซต์ของบุคคลที่สามอย่างละเอียดก่อน
                          การนำเสนอลิงก์ของบุคคลที่สามนั้น
                          มีไว้เพื่อความสะดวกที่ผู้ใช้บริการของบริษัทฯสามารถค้นหาข้อมูลเพิ่มเติมเกี่ยวกับหัวข้อบางอย่าง
                          โดยบุคคลที่สามนั้นไม่ได้ให้หรือแชร์ข้อมูลใดๆ
                          ของคุณกับบริษัทฯแต่อย่างใด
                          ดังนั้นบริษัทฯไม่มีความรับผิดชอบต่อการปกป้องความเป็นส่วนตัวหรือเนื้อหาของเว็บไซต์ของบุคคลที่สาม
                          ไม่ว่าบางส่วนหรือทั้งหมด
                        </p>
                        <h3 style={{ fontSize: "1rem" }}>
                          บริษัทฯใช้ข้อมูลที่รวบรวมอย่างไร
                        </h3>
                        <p>
                          &emsp;บริษัทฯใช้หรือเปิดเผยข้อมูลของคุณเฉพาะด้วยความยินยอมโดยนัยหรือโดยชัดแจ้งที่ระบุไว้ในระเบียบข้อบังคับของกฎหมายที่บังคับทั้งหมดในเขตอำนาจศาลที่เกี่ยวข้องเท่านั้น
                          ตามวัตถุประสงค์ดังต่อไปนี้
                          <br />
                        </p>
                        <p>
                          &ensp;• เพื่อวัตถุประสงค์ทางธุรกิจของบริษัทฯ
                          บริษัทฯจะใช้และเก็บข้อมูลเพื่อประมวลผล
                          วิเคราะห์และปรับปรุงธุรกิจของบริษัทฯ
                          ซึ่งรวมถึงจัดสรรบริการบนแพลตฟอร์ม C3Leng
                          <br />
                          &ensp;• เพื่อการรักษาความปลอดภัยทางไซเบอร์
                          บริษัทฯใช้หรือวิเคราะห์ข้อมูลในการตรวจสอบพฤติกรรมหรือกิจกรรมที่อาจผิดกฎหมายเพื่อการป้องกันการฉ้อโกงหรืออาชญากรรมต่างๆ 
                          <br />
                          &ensp;•
                          เพื่อส่งข้อมูลข่าวสารเกี่ยวกับธุรกิจบางอย่างของบริษัทฯให้แก่คุณ
                          รวมถึงการประชาสัมพันธ์ การส่งเสริมการขาย
                          การเปลี่ยนแปลงนโยบายต่างๆ
                          ของแพลตฟอร์มและการสื่อสารทางการตลาด
                          <br />
                          &ensp;•
                          เพื่อการปรับแต่งเนื้อหาและการโฆษณาที่คุณพบเห็นบนเว็บไซต์และแอพของบริษัทฯผ่านทางอินเทอร์เน็ตและที่อื่นๆ
                          <br />
                        </p>
                        <h3 style={{ fontSize: "1rem" }}>
                          การเปิดเผยข้อมูลที่บริษัทฯรวบรวม
                        </h3>
                        <p>
                          &ensp;•
                          บริษัทฯอาจแบ่งปันข้อมูลตามกฎหมายหรือคำขออย่างเป็นทางการของหน่วยงานของรัฐ
                          <br />
                          &ensp;•
                          บริษัทฯอาจแบ่งปันข้อมูลที่ไม่อาจระบุตัวตนของคุณกับบริษัทในเครือของบริษัทฯ ซึ่งถือเป็นส่วนหนึ่งในการดำเนินธุรกิจตามปกติของบริษัทฯ
                        </p>
                        <h3 style={{ fontSize: "1rem" }}>
                          ระยะเวลาการเก็บข้อมูล
                        </h3>
                        <p>
                          &emsp;บริษัทฯจะเก็บรักษาข้อมูลต่างๆ
                          ตราบเท่าที่จำเป็นในการดำเนินธุรกิจของบริษัทฯ
                          หรือตามที่กำหนดไว้ตามกฎหมายของประเทศไทย
                          ซึ่งโดยปกติเป็นระยะเวลา 2
                          ปีหลังจากนับตั้งแต่เมื่อมีการปิดหรือลบบัญชีสมาชิกหรือตามระยะเวลาที่กำหนดโดยกฎหมายที่เกี่ยวข้อง
                          เมื่อครบกำหนดเวลาดังกล่าว
                          บริษัทฯจะลบข้อมูลนั้นออกจากฐานข้อมูลของบริษัทฯ
                          <br />
                        </p>
                        <p>
                          &emsp;อย่างไรก็ดี
                          คุณอาจใช้สิทธิเพื่อปกป้องความเป็นส่วนตัวของคุณที่จะขอลบหรือทำลายข้อมูลของคุณจากฐานข้อมูลของบริษัทฯได้
                          โดยทำคำขอเป็นลายลักษณ์อักษรส่งถึงบริษัทฯที่อีเมล์ adminc3leng@c3leng.com
                        </p>
                        <h3 style={{ fontSize: "1rem" }}>
                          การปรับปรุงและแก้ไขนโยบายนี้
                        </h3>
                        <p>
                          &emsp;บริษัท แก้วกรุงไทย
                          จำกัด ขอสงวนสิทธิ์ตามดุลยพินิจแต่เพียงผู้เดียวในการเปลี่ยนแปลง แก้ไขหรือลบบางส่วนของนโยบายนี้ได้ตลอดเวลา
                          โปรดติดตามตรวจสอบการเปลี่ยนแปลงหรือแก้ไขเพิ่มเติมนโยบายความเป็นส่วนตัวนี้บนหน้าเว็บไซต์และแพลตฟอร์ม C3Leng 
                          <br />
                        </p>
                        <p>
                          &emsp;การที่คุณใช้งานหรือบริการบนเว็บไซต์ต่อไปหลังจากที่มีการเปลี่ยนแปลงหรือแก้ไขเพิ่มเติมนโยบายนี้
                          ถือว่าคุณได้แสดงเจตนาตกลงยอมรับเงื่อนไขไม่เฉพาะแต่เพียงบางส่วน
                          แต่ทั้งหมด
                        </p>
                        <br />
                        <p>
                          นโยบายนี้ปรับปรุงล่าสุดและมีผลใช้บังคับตั้งแต่วันที่ 9 เมษายน 2563
                        </p>
                        <br />
                        <p />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 ml-auto">
                <div className="faq-img">
                  <img src="assets/images/bg-img/app2.png" alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>

        {/****** Video Area Start ***** 
		<section className="video-section">
			<div className="container">
				<div className="video-area" style="background-image: url(images/bg-img/vdo-screenshot.jpeg);">
					<div className="video-play-btn">
						<a className="video_btn" href="https://www.youtube.com/watch?v=kJQ9AT7jE3s"><i className="icon-control-play" aria-hidden="true"></i></a>
					</div>
				</div>
			</div>
		</section>
		 ***** Video Area End ******/}

        {/****** footer-Area-start ******/}
        <section ref={contractUs} style={{ paddingBottom: 0 }}>
          <footer className="text-center">
            <ul className="social-icons list-inline">
              <li className="list-inline-item">
                <a href="https://www.facebook.com/C3LengRecycle">
                  <i className="fab fa-facebook-f" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://www.youtube.com/channel/UCaDjjRguHRqvm2-eMWQDvaQ">
                  <i className="fab fa-youtube" />
                </a>
              </li>
              <li className="list-inline-item">
                <a href="https://line.me/ti/p/@C3Leng">
                  <i className="fab fa-line" />
                </a>
              </li>
            </ul>
            <p className="copy-right">
              All Rights Reserved C3Leng <a href="#">by GGT</a>
            </p>
          </footer>
        </section>
        {/****** footer-Area-End ******/}
      </div>
    </>
  );
}
