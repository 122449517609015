import {
    AUTO_ADMINALL,
    AUTO_PROD,
    AUTO_WASTEALL,
    AUTO_WASTEGEN,
    AUTO_WASTETRAN,
    AUTO_WASTEPRO
} from '../service/actionType'

const initialState = {
    data: null,
    isFailed: false,
    isLoading: false
}

export function autoCompleteAdminAll(state = initialState, action) {
    switch (action.type) {
        case AUTO_ADMINALL.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case AUTO_ADMINALL.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case AUTO_ADMINALL.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}
export function autoCompleteProduct(state = initialState, action) {
    switch (action.type) {
        case AUTO_PROD.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case AUTO_PROD.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case AUTO_PROD.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}
export function autoCompleteWasteall(state = initialState, action) {
    switch (action.type) {
        case AUTO_WASTEALL.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case AUTO_WASTEALL.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case AUTO_WASTEALL.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}
export function autoCompleteWastegen(state = initialState, action) {
    switch (action.type) {
        case AUTO_WASTEGEN.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case AUTO_WASTEGEN.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case AUTO_WASTEGEN.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}
export function autoCompleteWastetran(state = initialState, action) {
    switch (action.type) {
        case AUTO_WASTETRAN.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case AUTO_WASTETRAN.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case AUTO_WASTETRAN.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}
export function autoCompleteWastepro(state = initialState, action) {
    switch (action.type) {
        case AUTO_WASTEPRO.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case AUTO_WASTEPRO.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case AUTO_WASTEPRO.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}