import React from 'react';
import { Document, Page, Text, View, StyleSheet, Font, } from '@react-pdf/renderer';
import { Table, TableHeader, TableCell, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'

export const PdfTableExample = ({ title, model, data, orderCode, date, summary }) => {
    // Register font
    let source = "assets/layout/fonts/Kanit/Kanit-Regular.ttf"
    let sourceBold = "assets/layout/fonts/Kanit/Kanit-Bold.ttf"
    let sourceItalic = "assets/layout/fonts/Kanit/Kanit-Italic.ttf"
    Font.register({ family: 'font-regular', src: source, weight: '400,500', });
    Font.register({ family: 'font-bold', src: sourceBold, weight: 'bold', });
    Font.register({ family: 'font-italic', src: sourceItalic, weight: '400,500', });
    // Create styles
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            width: '100vh',
            height: '100vh',
            fontFamily: 'font-regular'

        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        },
        header: {
            fontFamily: 'font-bold',
            height: '30px'
        },
        cellHeader: {
            paddingLeft: 5,
            backgroundColor: 'gray',
            textAlign: 'center',
            height: '30px'
        },
        cellDetail: {
            paddingLeft: 5,
            textAlign: 'left'
        },
        cellDetailRight: {
            paddingRight: 5,
            textAlign: 'right'
        },
        cellDetailCenter: {
            paddingLeft: 5,
            textAlign: 'center'
        },
        summary: {
            fontFamily: 'font-bold',
            fontSize: '16px'
        },
        box: {
            width: '300px',
            marginTop: '20px',
            padding: '10px',
            alignSelf: 'flex-end',
        },
        headerContainer: {
            paddingLeft: '50%',
            flexDirection: 'row',
            alignItems: 'stretch',
        },
        summaryContainer: {
            flexDirection: 'row',
            borderBottomWidth: 2,
            borderBottomColor: '#112131',
            borderBottomStyle: 'solid',
            alignItems: 'stretch',
        },
        leftColumn: {
            flexDirection: 'column',
            flexGrow: 9,
        },
        rightColumn: {
            flexDirection: 'column',
            flexGrow: 2,
            alignSelf: 'flex-end',
            justifySelf: 'flex-end',
        }
    });
    
    return (
        <Document >
            <Page size="A4" style={styles.page}>
                <View style={styles.section}>
                    <View>
                        <Text>{title}</Text>
                    </View>
                    <View style={styles.headerContainer}>
                        <View style={styles.leftColumn}>
                            <Text>เลขที่</Text>
                        </View>
                        <View style={styles.rightColumn}>
                            <Text>{orderCode}</Text>
                        </View>
                    </View>

                    <View style={styles.headerContainer}>
                        <View style={styles.leftColumn}>
                            <Text>วันที่</Text>
                        </View>
                        <View style={styles.rightColumn}>
                            <Text>{date}</Text>
                        </View>
                    </View>

                    <View style={{ marginTop: '20px' }}>
                        <Table
                            data={data}
                        >

                            <TableHeader styles={styles.header}>
                                {
                                    model.map((value) => {
                                        return (
                                            <TableCell styles={styles.cellHeader}>
                                                {value.label}
                                            </TableCell>
                                        )
                                    })
                                }
                            </TableHeader>
                            <TableBody>
                                {
                                   model.map((value)=>{
                                    let style = styles.cellDetail;
                                    if (value.alignRight) {
                                        style = styles.cellDetailRight
                                    }
                                    if (value.alignCenter) {
                                        style = styles.cellDetailCenter
                                    }
                                    return <DataTableCell styles={style} getContent={(r) => r[value.columnName]} />
                                   
                                   })
                                }
                            </TableBody>

                        </Table>
                    </View>


                    <View style={{ width: '100%' }}>
                        <View style={styles.box}>
                            {
                                summary.map((value) => {
                                    return (
                                        <View style={styles.summaryContainer}>
                                            <View style={styles.leftColumn}>
                                                <Text style={styles.name}>{value.header}</Text>
                                            </View>
                                            <View style={styles.rightColumn}>
                                                <Text style={styles.link}>{value.value}</Text>
                                            </View>
                                        </View>
                                    )
                                })
                            }

                        </View>
                    </View>

                </View>
            </Page>
        </Document>
    )
}
