export const monthWord1 = 'มกราคม'
export const monthWord2 = 'กุมภาพันธ์'
export const monthWord3 = 'มีนาคม'
export const monthWord4 = 'เมษายน'
export const monthWord5 = 'พฤษภาคม'
export const monthWord6 = 'มิถุนายน'
export const monthWord7 = 'กรกฎาคม'
export const monthWord8 = 'สิงหาคม'
export const monthWord9 = 'กันยายน'
export const monthWord10 = 'ตุลาคม'
export const monthWord11 = 'พฤศจิกายน'
export const monthWord12 = 'ธันวาคม'

export const HAY_TYPE = {
  AGRICULTURE: 'agriculture',
  ACTIVITY: 'activity',
  PRODUCT: 'product',
  PRODUCER_GROUP: 'producer_group',
  PRODUCER_OTHER: 'producer_other',
}

export const APP_SETTING = {
  APP_NAME: 'C2Leng'
}

export const PAGE = {
  PRODUCER: 'PRODUCER'
}
