import React from 'react'
import { Validators } from '../../utils';
import { Message } from 'primereact/message';
import { Dropdown } from 'primereact/dropdown';

export const ValidateDropdownView = ({ 
    value, 
    onChange, 
    name,
    placeholder,
    errorMessage,
    required,
    options,
    optionLabel
}) => {

    errorMessage = errorMessage||`กรุณาระบุ ${placeholder}`
    required = required || true

    return (
        <div>
                <Dropdown
                        required={required}
                        className={Validators.isInValid(value) ? 'p-error' : ''}
                        name={name}
                        value={value}
                        options={options}
                        onChange={onChange}
                        placeholder={placeholder}
                        optionLabel={optionLabel} 
                        />
            {
                Validators.isInValid(value) &&
                <Message severity="error" text={errorMessage} />
            }
        </div>
    )
}