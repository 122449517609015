import axios from '../../service/axios'
import { API_ROOT } from '../../constants/api'
import {
  RPT_APLC_WG,
  RPT_APLC_WP,
  RPT_APLC_WT,
  DHB_USER,
  ADMIN_USER_LIST,
  ADMIN_CERT_TEMPLATE_LIST,
  ADMIN_CERT_RESULT_LIST
} from '../../service/actionType'


export const getReportApplicantWastegen = () => (dispatch) => {
  dispatch({ type: RPT_APLC_WG.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/report/applicant/wastegen`,
  }).then((res) => {
    dispatch({ type: RPT_APLC_WG.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: RPT_APLC_WG.FAILURE, error })
  })
}

export const getReportApplicantWastepro = () => (dispatch) => {
  dispatch({ type: RPT_APLC_WP.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/report/applicant/wastepro`,
  }).then((res) => {
    dispatch({ type: RPT_APLC_WP.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: RPT_APLC_WP.FAILURE, error })
  })
}


export const getReportApplicantWastetran = () => (dispatch) => {
  dispatch({ type: RPT_APLC_WT.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/report/applicant/wastetran`,
  }).then((res) => {
    dispatch({ type: RPT_APLC_WT.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: RPT_APLC_WT.FAILURE, error })
  })
}


export const getDashboardUsers = () => (dispatch) => {
  dispatch({ type: DHB_USER.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/dashboard/users`,
  }).then((res) => {
    dispatch({ type: DHB_USER.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: DHB_USER.FAILURE, error })
  })
}




export const searchMemberName = (search_name) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/autocomplete/wastegen/${search_name}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const getTranLocation = (model) => {

  return axios({
    method: 'post',
    url: `${API_ROOT}/wastetran/getbylocation`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    data: model
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const getProfile = (uid) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/user/profile/${uid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}


export const getDistrict = (provinceCode) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/master/district/${provinceCode}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}


export const getSubDistrict = (districtCode) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/master/subdistrict/${districtCode}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const getProLocation = (model) => {

  return axios({
    method: 'post',
    url: `${API_ROOT}/wastepro/getbylocation`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    data: model
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const CreateOrder = (formData, handleCreateResult) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/order/create`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      handleCreateResult(true, res.data.order_code)
    })
    .catch(function (error) {
      handleCreateResult(false, error.response.data)
    });
}

export const updateOrder = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/order/update`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const autoComplateOrderID = (code) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/order/autocomplete/${code}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const searchOrder = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/order/search`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const searchReportOrder = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/order/report/search`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const searchReportOrderDownload = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/order/report/search`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    responseType: 'blob'
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const searchReportRating = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/order/rating/report`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const searchReportWasteByLocation = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/report/wastebylocation`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const getOrderDetail = (code) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/order/getbycode/${code}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const searchwasteAll = (search_name) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/autocomplete/wasteall/${search_name}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const searchInvoice = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/invoice/search`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const updateInvoice = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/invoice/update`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const searchwastepro = (search_name) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/autocomplete/wastepro/${search_name}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const searchProduct = (search_name) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/autocomplete/product/${search_name}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const searchOrgAll = (search_name) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/autocomplete/orgall/${search_name}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const searchOrgAccountName = (search_name) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/autocomplete/orgAccountName/${search_name}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const searchadminAll = (search_name) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/autocomplete/adminall/${search_name}`,

  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}



export const searchWithDrawRequest = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/withdraw/history`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}


export const AddTransfer = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/transfer/add`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const AddTranspotDistanceType = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/TransportDistanceType/add`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const getTranspotDistanceType = () => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/account/TransportDistanceType/getall`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const removeTranspotDistanceType = (id) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/account/TransportDistanceType/remove/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    return error;
  })
}

export const AddTranspotWeightType = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/TransportWeightType/add`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const getTranspotWeightType = () => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/account/TransportWeightType/getall`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const removeTranspotWeightType = (id) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/account/TransportWeightType/remove/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    return error;
  })
}


export const AddFeeWeightType = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/FeeWeightType/add`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const getFeeWeightType = () => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/account/FeeWeightType/getall`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const removeFeeWeightType = (id) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/account/FeeWeightType/remove/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    return error;
  })
}

export const Addprocessfee = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/processfee/add`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}


export const AddTransportFee = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/TransportFee/add`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const getprocessfee = () => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/account/processfee/getall`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const getTransportFee = () => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/account/TransportFee/getall`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const removeprocessfee = (id) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/account/processfee/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    return error;
  })
}


export const Addsalepricebyu3 = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/salepricebyu3/add`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const getsalepricebyu3 = () => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/account/salepricebyu3/getall`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const removesalepricebyu3 = (id) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/account/salepricebyu3/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    return error;
  })
}


// service for manage product-type
export const getAllProductType = () => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/admin/producttype/getall`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const addProductType = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/master/admin/producttype/create`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const updateProductType = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/master/admin/producttype/update`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const removeProductType = (id) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/admin/producttype/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    return error;
  })
}


//service setting-reward
export const getReward = () => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/account/reward/getall`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    console.log("get success : ", res.data);
    return res.data;
  }).catch((error) => {
    console.log("error reward : ", error);
    return error;
  })
}

export const AddReward = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/reward/add`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
      console.log("data : ", res.data);
    })
    .catch(function (error) {
      return error;
    });
}

export const searchReward = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/reward/search`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const editReward = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/reward/edit`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const deleteReward = (gid) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/account/reward/delete/${gid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}


export const removeTransportFee = (id) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/account/TransportFee/delete/${id}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    return error;
  })
}

export const searchProuctFee = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/processfee/search`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const searchSalePriceByU3 = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/salepricebyu3/search`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const searchDonateAccount = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/donate/organization/search`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const editTransportWeightType = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/TransportWeightType/edit`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}


export const editTransportDistanceType = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/TransportDistanceType/edit`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const editTransportFee = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/TransportFee/edit`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const editSalepricebyu3 = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/salepricebyu3/edit`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const editProcessfee = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/processfee/edit`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}
export const editFeeWeightType = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/FeeWeightType/edit`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const createNews = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/news/create`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const editNews = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/news/edit`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const getAllNews = () => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/account/news/getall`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const getNewsByCode = (code) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/account/news/getbycode/${code}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const getFileImgNews = (filename) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/news/getfile/${filename}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}


export const getAllfaq = () => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/account/faq/getall`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const addFAQ = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/faq/add`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const editFAQ = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/faq/edit`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const removeFAQ = (id) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/account/faq/delete/${id} `,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    return error;
  })
}

export const deleteNews = (gid) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/account/news/delete/${gid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const getAdminRoles = () => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/account/GetAdminRoles`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}



export const CreateAdministrator = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/CreateAdministrator`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const UpdateAdministratorProfile = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/UpdateAdministratorProfile`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const getAdminPermission = () => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/account/getAdminPermission`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const updateAdminPermission = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/SettingAdminPermission`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}

export const getToken = (username) => {
  console.log("USERNAME : " + username)
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/gettoken/${username}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }).then((res) => {
    var token = localStorage.getItem('access-token');

    localStorage.setItem('second-token', token);
    localStorage.setItem('access-token', res.data);

    return true;
  }).catch((error) => {
    console.log(error)
    return false;
  })
}

export const getUsersAllForManagement = (body) => (dispatch) => {
  console.log(body)
  dispatch({ type: ADMIN_USER_LIST.REQUEST, isFetching: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/GetUsersAllForManagement`,
    data: body,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }).then((res) => {
    dispatch({ type: ADMIN_USER_LIST.SUCCESS, payload: res.data })
  }).catch((error) => {
    console.log(error)
    dispatch({ type: ADMIN_USER_LIST.FAILURE, error })
  })
}

//> Certification Action Service
export const createCertTemplate = (data) => (dispatch) => {
  console.log(data)
  return axios({
    method: 'post',
    url: `${API_ROOT}/certificationtemplate/create`,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    console.log(error)
    return error
  })
}

export const updateCertTemplate = (data) => (dispatch) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/certificationtemplate/update`,
    data: data,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }).then((res) => {
    return res.data = 'success'
  }).catch((error) => {
    console.log(error)
    return error
  })
}

export const getCertTemplateAll = () => (dispatch) => {
  dispatch({ type: ADMIN_CERT_TEMPLATE_LIST.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/certificationtemplate/GetAll`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }).then((res) => {
    dispatch({ type: ADMIN_CERT_TEMPLATE_LIST.SUCCESS, payload: res.data })
  }).catch((error) => {
    console.log(error)
    dispatch({ type: ADMIN_CERT_TEMPLATE_LIST.FAILURE, error })
  })
}

export const getCertTemplateById = (gid) => (dispatch) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/certificationtemplate/getbyid/${gid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }).then((res) => {
    return res.data
  }).catch((error) => {
    return error
  })
}

export const deleteCertTemplate = (gid) => (dispatch) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/certificationtemplate/Delete/${gid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }).then((res) => {
    return res.data = "success"
  }).catch((error) => {
    return error
  })
}

export const getCertResult = () => (dispatch) => {
  dispatch({ type: ADMIN_CERT_RESULT_LIST.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/certification/GetResult`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }).then((res) => {
    dispatch({ type: ADMIN_CERT_RESULT_LIST.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: ADMIN_CERT_RESULT_LIST.FAILURE, error })
  })
}

export const searchWaste = (wasteCode, provinceCode, districtCode, searchName) => {
  let wasteType = 'wasteall'
  switch (wasteCode) {
    case 0:
      wasteType = 'wasteall'
      break;
    case 1:
      wasteType = 'wastegen'
      break;
    case 2:
      if (provinceCode == 0 || districtCode == 0) {
        wasteType = 'wastetran'
      } else {
        wasteType = `wastetran/${provinceCode}/${districtCode}`
      }
      break;
    case 3:
      wasteType = 'wastepro'
      break;
    default:
      wasteType = 'wasteall'
      break;
  }

  return axios({
    method: 'get',
    url: `${API_ROOT}/autocomplete/${wasteType}/${searchName}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const searchChatWasteAll = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/GetUsersAllForManagement`,

    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') },
    data: formData,
    // headers: {
    //   'Content-Type': 'application/json',
    //   Authorization: 'Bearer ' + localStorage.getItem('access-token')
    // }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const CreateOrganizationDonateAccount = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/donate/organization/create`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}


export const getOrganizationAccount = () => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/donate/organization/getall`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}


export const updateDonateOrganizationAccount = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/donate/organization/update`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}


export const removeDonateOrganizationAccount = (gid) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/donate/organization/delete/${gid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    return error;
  })
}

export const setProfileVerification = (uid,isVerified) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/account/profile-verification/${uid}/${isVerified}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    return error;
  })
}


export const resetpassword = (username,resettype) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/account/resetpassword/${username}/${resettype}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    return error;
  })
}


export const getBadgeAdmin = ()  => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetUnread/admin`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(res => {
      return res.data;
    })
    .catch(error => {
      return error
    })
}

export const checkExitPhoneAndName = (model) =>{
  
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/checkexit`,
    data: model
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}


export const getproductAll = () => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/admin/product/getall`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}


export const removeProduct = (gid) => {

  return axios({
    method: 'get',
    url: `${API_ROOT}/master/admin/product/delete/${gid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    return error;
  })
}

export const AddProduct = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/master/admin/product/create`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}



export const editProduct = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/master/admin/product/update`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data = 'success';
    })
    .catch(function (error) {
      return error;
    });
}


export const searchDonateRequest = (formData) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/donate/history`,
    data: formData,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}


export const searchProductList = (product_type_code,search_name) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/admin/product/search/${product_type_code}/${search_name}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

export const searchProductTypeList = (search_name) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/admin/producttype/search/${search_name}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}


export const setProfileStateChange = (data) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/account/profile-state-change`,
    data: data,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  }).then((res) => {
    return res.data = 'success';
  }).catch((error) => {
    return error;
  })
}

export const getProfileState = (waste_uid) => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/account/profile-state-log/${waste_uid}`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(function (res) {
      return res.data;
    })
    .catch(function (error) {
      return error;
    });
}

