import { createStore, applyMiddleware, compose } from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducer'

const configureStore = (initialState) => {
  const middlewares = [
    thunk
  ]

  const enhancer = compose(applyMiddleware(...middlewares))
  const store = createStore(reducer, initialState, enhancer)

  return {
    store,
  }
}

export const { store } = configureStore()
