import React from 'react'
import { Panel } from 'primereact/panel'
import { Message } from 'primereact/message'
import { ScrollPanel } from 'primereact/scrollpanel';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { Galleria } from 'primereact/galleria';
import { Document, Page, Text, View, StyleSheet, } from '@react-pdf/renderer';
import { API_ROOT } from '../../constants/api';
import { Route, Link } from 'react-router-dom'
import {WastetranNewsDetail} from '../wastetran/WastetranNewsDetail'

import { dateFormat, plusDate, convertDateMonthNumberToWord } from '../../utils/utilFunctions'

export const WasteTranNewView = ({ newsDataList }) => {
    const data = newsDataList || []
    let wastetrannews = [];
    data.forEach(element => {
        if(element.isWasteTran){
            wastetrannews.push(element);
        }
        
    });
    //console.log(data);
    // const images = [
    //     { url: 'https://scontent.fbkk6-2.fna.fbcdn.net/v/t1.0-9/117237663_2757130567905276_6786226976383872435_o.jpg?_nc_cat=101&_nc_sid=8024bb&_nc_eui2=AeFfimm6COF5Rb8llRypOyR9iBDSpNwljcOIENKk3CWNw_BOwHqriQWmgTFpE2leoTUcwpiiGLijEHBVu2t-5BGD&_nc_ohc=k__BYJZ_suoAX-qafdn&_nc_ht=scontent.fbkk6-2.fna&oh=3d35dfdf12cb31c71db066f06bf6ee40&oe=5F510145', headernews: 'หัว 1' },
    //     { url: 'https://scontent.fbkk6-2.fna.fbcdn.net/v/t1.0-9/117378877_2757130544571945_5786670707519545710_n.jpg?_nc_cat=104&_nc_sid=8024bb&_nc_eui2=AeF_HEMDuBT_D5ExLWHZMj37R_4XwAx08x1H_hfADHTzHSIbPF1kkKHVii43sAUhUFVa89Mw7bjSei5lePa_6EEj&_nc_ohc=-l3e9zdn0cMAX9wLtE-&_nc_ht=scontent.fbkk6-2.fna&oh=e5281c53ebf72010422af826308450c7&oe=5F509DA3', headernews: 'หัว 2' },
    //     { url: 'https://i1.wp.com/marketeeronline.co/wp-content/uploads/2020/03/1..jpg?resize=1024%2C683&ssl=1', headernews: 'หัว 3' },
    //     { url: 'https://scontent.fbkk6-2.fna.fbcdn.net/v/t1.0-9/117237663_2757130567905276_6786226976383872435_o.jpg?_nc_cat=101&_nc_sid=8024bb&_nc_eui2=AeFfimm6COF5Rb8llRypOyR9iBDSpNwljcOIENKk3CWNw_BOwHqriQWmgTFpE2leoTUcwpiiGLijEHBVu2t-5BGD&_nc_ohc=k__BYJZ_suoAX-qafdn&_nc_ht=scontent.fbkk6-2.fna&oh=3d35dfdf12cb31c71db066f06bf6ee40&oe=5F510145', headernews: 'หัว 4' },
    //     { url: 'https://scontent.fbkk6-2.fna.fbcdn.net/v/t1.0-9/117378877_2757130544571945_5786670707519545710_n.jpg?_nc_cat=104&_nc_sid=8024bb&_nc_eui2=AeF_HEMDuBT_D5ExLWHZMj37R_4XwAx08x1H_hfADHTzHSIbPF1kkKHVii43sAUhUFVa89Mw7bjSei5lePa_6EEj&_nc_ohc=-l3e9zdn0cMAX9wLtE-&_nc_ht=scontent.fbkk6-2.fna&oh=e5281c53ebf72010422af826308450c7&oe=5F509DA3', headernews: 'หัว 5' },
    //     { url: 'https://i1.wp.com/marketeeronline.co/wp-content/uploads/2020/03/1..jpg?resize=1024%2C683&ssl=1', headernews: 'หัว 6' },
    //     { url: 'https://scontent.fbkk6-2.fna.fbcdn.net/v/t1.0-9/117237663_2757130567905276_6786226976383872435_o.jpg?_nc_cat=101&_nc_sid=8024bb&_nc_eui2=AeFfimm6COF5Rb8llRypOyR9iBDSpNwljcOIENKk3CWNw_BOwHqriQWmgTFpE2leoTUcwpiiGLijEHBVu2t-5BGD&_nc_ohc=k__BYJZ_suoAX-qafdn&_nc_ht=scontent.fbkk6-2.fna&oh=3d35dfdf12cb31c71db066f06bf6ee40&oe=5F510145', headernews: 'หัว 7' },
    //     { url: 'https://scontent.fbkk6-2.fna.fbcdn.net/v/t1.0-9/117378877_2757130544571945_5786670707519545710_n.jpg?_nc_cat=104&_nc_sid=8024bb&_nc_eui2=AeF_HEMDuBT_D5ExLWHZMj37R_4XwAx08x1H_hfADHTzHSIbPF1kkKHVii43sAUhUFVa89Mw7bjSei5lePa_6EEj&_nc_ohc=-l3e9zdn0cMAX9wLtE-&_nc_ht=scontent.fbkk6-2.fna&oh=e5281c53ebf72010422af826308450c7&oe=5F509DA3', headernews: 'หัว 8' },
    //     { url: 'https://i1.wp.com/marketeeronline.co/wp-content/uploads/2020/03/1..jpg?resize=1024%2C683&ssl=1', headernews: 'หัว 9' },
    // ]


    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 5
        },
        {
            breakpoint: '768px',
            numVisible: 3
        },
        {
            breakpoint: '560px',
            numVisible: 1
        }
    ];

    const itemTemplate = (item) => {
        return (
            <div className="p-grid p-nogutter p-justify-center">
                <img src={`${item.url}`} alt={item.alt} style={{ display: 'block' }} />
            </div>
        );
    }

    const onClickToNews = () => {
        // this.props.history.push("#/wastegen/news-detail");

    }


    

    const previewTemplate = (item) => {
        if (item.isWasteTran)
        return (<div>
            <img src={`${API_ROOT}/user/news/getfile/${item.defaultImageName}`} alt={item.alt} style={{  display: 'block' }} width="550" height="350"/>
            <span className="text" style={styles.textheader}>
                {item.newsTitle}
            </span>
            <span className="text" style={styles.textdetail}>
                {item.newsDetail}
            </span>
            <div className="text" style={styles.text2}>
                {/* <a href={`#/wastegen/news-detail/${item.gid}`} onClick={onClickToNews}> */}
                    <Link to={`/wastetran/news-detail/?newsCode=${item.newsCode}`} style={{color: '#43a047'}}>อ่านต่อ</Link>
                    <Route exact path = "/wastetran/news-detail/" component={WastetranNewsDetail}></Route>
                    {/* <div style={{ color: 'white' }}><u>อ่านต่อ</u></div> */}
                {/* </a> */}
            </div>
        </div>
        )
    }

    const styles = StyleSheet.create({

        container: {
            position: 'relative',
            //width: '50%'
        },
        image: {
            display: 'block',
            width: '100%',
            height: 'auto',
            
        },
        overlay: {
            position: 'absolute',
            bottom: '0',
            left: '0',
            right: '0',
            backgroundColor: '#0000007e',
            //opacity:'70%',
            overflow: 'hidden',
            width: '100%',
            height: '0',
            transition: '.5s ease',
            height: '20%'
        },

        textheader: {
            color: '#a1db89',
            fontSize: '20px',
            position: 'absolute',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            zIndex: '1',
            top: '85%',
            left: '44%',
            width: '80%',
            height: 'auto',
            transform: 'translate(-50%, -50%)',
            mstransform: 'translate(-50%, -50%)',
        },
        textdetail: {
            color: 'white',
            fontSize: '15px',
            position: 'absolute',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            zIndex: '1',
            top: '94%',
            left: '44%',
            width: '80%',
            height: 'auto',
            transform: 'translate(-50%, -50%)',
            mstransform: 'translate(-50%, -50%)',
        },
        text2: {
            color: '#a1db89',
            fontSize: '15px',
            position: 'absolute',
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            zIndex: '1',
            top: '94%',
            left: '140%',
            width: '100%',
            height: 'auto',
            transform: 'translate(-50%, -50%)',
            mstransform: 'translate(-50%, -50%)',
            textDecoration: 'underline'
        },
    });



    return (
        <div className="p-grid p-dir-rev">
            <div className="p-col-12">
                <Panel>
                    {/* {data.length == 0 && */}
                    <div style={{ paddingLeft: 20 }}>
                        {/* <span>  เริ่มให้บริการ 1 มีนาคม 2563 เป็นต้นไป</span> */}

                        <div className="p-col-12">

                            <div className="p-grid">
                                {/* <div className="p-col-6 p-offset-3">
                                <Galleria value={images} responsiveOptions={responsiveOptions} numVisible={5} 
                                        autoPlay={true} circular={true}
                                        previewItemTemplate={previewTemplate} thumbnailItemTemplate={itemTemplate} showThumbnails={false}
                                        showIndicators={true} changePreviewOnIndicatorHover={false} 
                                        style={{maxWidth: '500px', marginTop: '2em'}} showIndicatorsOnPreview={true} indicatorsPosition="top"/>
                                </div> */}
                                <div className="p-col-2" style={{marginLeft:'80px'}}></div>
                                
                                <div className="container" style={styles.container}>
                                    <Galleria value={wastetrannews} responsiveOptions={responsiveOptions} numVisible={5}
                                        autoPlay={true} circular={true} className="custom-galleria-indicators"
                                        previewItemTemplate={previewTemplate} thumbnailItemTemplate={itemTemplate} showThumbnails={false}
                                        showIndicators={true} changePreviewOnIndicatorHover={false}
                                        style={{  marginTop: '2em' }} showIndicatorsOnPreview={true} indicatorsPosition="top" />

                                    <div className="overlay" style={styles.overlay}>
                                        {/* <ScrollPanel style={{width: '100%', height: '200px'}}> */}
                                        {/* <span className="text" style={styles.text}>
                                           หัวข่าว
                                        </span> */}
                                        {/* <div className="text" style={styles.text2}>
                                        <a href="#/wastegen/news-detail">
                                            <div style={{color: 'white'}}><u>อ่านต่อ</u></div>
                                        </a>
                                        </div> */}
                                        {/* </ScrollPanel> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    {/* } */}
                    {/* {data.length > 0 &&
                        <li style={{ paddingLeft: 20 }}>
                            <span>{data[data.length-1].messages}</span>
                        </li>
                    } */}
                </Panel>
            </div>
        </div>
    )
}

