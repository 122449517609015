import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";

import { ProgressSpinner } from 'primereact/progressspinner';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import { ToggleButton } from 'primereact/togglebutton';
import * as ServerAPI from './service';
import Dialog from 'primereact/dialog'
import {Panel} from 'primereact/panel';
import { API_ROOT ,WEB_ROOT} from '../../constants/api'
import * as qs from 'query-string';
class AppleLandingContainer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			visibleErrorDialogy: false,
            errorMessenger:"",
            isLoading:true,
        };
        
        
       
	}
    componentDidMount() {
       
        
    }


	
	
	
	onClick(){
        window.open(WEB_ROOT,"_self");
        //window.location = '#/';
    }
	render() {


		const { username, password } = this.state

		const { authentication, loginProps } = this.props
		if (authentication && authentication.accessToken) {
			if (!authentication.userType) {
				if (!authentication.isLoading) {
					loginProps.getUserType();
				}
			}
		}

		if (authentication.logedIn) {
			window.location = '#/' + localStorage.getItem('user-type')
        }
        
        

		return (
            <div className="login-body" >
				<div className="p-grid p-fluid"  style={{textAlign:'center'}}>
                    <div className="p-col-12 p-sm-12 p-md-1 p-lg-3" >
                    </div>
					<div className="p-col-12 p-sm-12 p-md-6 p-lg-6" >
                                    <br/>
									<div className="p-col-12">
                                        {!this.state.isLoading && 
                                        <Panel header="ข้อความจากระบบ">
                                           {this.state.errorMessenger}
                                           <br />
                                           <br />
                                           <Button onClick={this.onClick} label="กลับหน้าหลัก" className="p-button-warning" style={{width:'10em', marginRight:'.25em'}} />
                                        </Panel>
                                        }
										{this.state.isLoading && <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>}

									</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	authentication: state.authentication

})

const mapDispatchToProps = dispatch => ({

	//loginProps: bindActionCreators(UserService, dispatch)

})


export default
	connect(
		mapStateToProps,
		mapDispatchToProps, null, { forwardRef: true })(AppleLandingContainer)