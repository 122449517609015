import axios from 'axios'
import { API_ROOT } from '../constants/api'
import {
  BANK,
  PRDT,
  PRD,
  MASTER_SETTING_PARAMETER
} from './actionType'

// Location
export const getBanks = () => (dispatch) => {
  dispatch({ type: BANK.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/bank/all`,
  }).then((res) => {
    dispatch({ type: BANK.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: BANK.FAILURE, error })
  })
}

export const getProductType = () => (dispatch) => {
  dispatch({ type: PRDT.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/producttype/all`,
  }).then((res) => {
    dispatch({ type: PRDT.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: PRDT.FAILURE, error })
  })
}

export const getProduct = (product_type_code) => (dispatch) => {
  dispatch({ type: PRD.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/product/${product_type_code}`,
  }).then((res) => {
    dispatch({ type: PRD.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: PRD.FAILURE, error })
  })
}


export const getSettingParameterAll = () => (dispatch) => {
  dispatch({ type: MASTER_SETTING_PARAMETER.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/admin/parameter/getall`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }).then((res) => {
    dispatch({ type: MASTER_SETTING_PARAMETER.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: MASTER_SETTING_PARAMETER.FAILURE, error })
  })
}

export const updateSettingParameter = (data) => (dispatch) => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/master/admin/parameter/update`,
    data: data,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  }).then((res) => {
    return res.data
  }).catch((error) => {
    return error
  })
}