import {
  PROVINCE, DISTRICT, SUB_DISTRICT ,CARD_PROVINCE,CARD_DISTRICT, CARD_SUB_DISTRICT,
  CHIP_PROVINCE,
  CHIP_DISTRICT,
  ARR_CHIP_DISTRICT
} from '../service/actionType'

 const initialState = {
  province: [],
  district: [],
  subDistrict: [],
  cardProvince: [],
  cardDistrict: [],
  cardSubDistrict: [],
  chipProvince:[],
  chipDistrict:[]
  
}
export function location(state = initialState, action) {
  switch (action.type) {
    case PROVINCE.REQUEST:
    case DISTRICT.REQUEST:
    case SUB_DISTRICT.REQUEST:
    case CHIP_PROVINCE.REQUEST:
      return {  
        ...state,
        isFetching: true,
        error: null
      };
    case CARD_PROVINCE.REQUEST:
    case CARD_DISTRICT.REQUEST:
    case CARD_SUB_DISTRICT.REQUEST:
    {
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    }
    case PROVINCE.FAILURE:
    case DISTRICT.FAILURE:
    case SUB_DISTRICT.FAILURE:
    case CHIP_PROVINCE.FAILURE:
    case CHIP_DISTRICT.FAILURE:
    case CARD_PROVINCE.FAILURE:
    case CARD_PROVINCE.FAILURE:
    case CARD_DISTRICT.FAILURE:
    case CARD_SUB_DISTRICT.FAILURE:
   {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    }

    case PROVINCE.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        province: action.payload
      }
    }
    case DISTRICT.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        district: action.payload
      }
    }
    case SUB_DISTRICT.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        subDistrict: action.payload
      }
    }
    case CARD_PROVINCE.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        cardProvince: action.payload
      }
    }
    case CARD_DISTRICT.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        cardDistrict: action.payload
      }
    }
    case CARD_SUB_DISTRICT.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        cardSubDistrict: action.payload
      }
    }
    case CHIP_PROVINCE.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        chipProvince: action.payload
      }
    }
    case CHIP_DISTRICT.SUCCESS: {
      return {
        ...state,
        isFetching: false,
        error: null,
        chipDistrict: action.payload
      }
    }
    default:
      return state
  }
}


const initialStateArrChips = {
  data: null,
  isFailed: false,
  isLoading: false
}
export function getArrChipDistrict(state = initialStateArrChips, action) {
  switch (action.type) {
      case ARR_CHIP_DISTRICT.REQUEST:
          return { ...state, isFailed: false, data: null, isLoading: true };
      case ARR_CHIP_DISTRICT.SUCCESS:
          return { ...state, isFailed: false, data: action.payload, isLoading: false };
      case ARR_CHIP_DISTRICT.FAILURE:
          return { ...state, isFailed: true, data: action.payload, isLoading: false };
      default:
          return state
  }
}