
import { API_ROOT } from '../constants/api'
import axios from './axios'
import {
    ORDER_SEARCH,
    ORDER_AUTO_COMP
} from './actionType'

export const orderSearch = (wastetype,criteria) => (dispatch) => {
    dispatch({ type: ORDER_SEARCH.REQUEST, isLoading: true })
    return axios({
        method: 'post',
        url: `${API_ROOT}/${wastetype}/order/salereport/search`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        },
        data: JSON.stringify(criteria)
    }).then((res) => {
        dispatch({ type: ORDER_SEARCH.SUCCESS, payload: res.data })
    }).catch((error) => {
        dispatch({ type: ORDER_SEARCH.FAILURE, payload: error.message })
    })
}

export const orderAutoComplete = (code) => (dispatch) => {
    dispatch({ type: ORDER_AUTO_COMP.REQUEST, isLoading: true })
    return axios({
        method: 'get',
        url: `${API_ROOT}/order/autocomplete/${code}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
    }).then((res) => {
        dispatch({ type: ORDER_AUTO_COMP.SUCCESS, payload: res.data })
    }).catch((error) => {
        dispatch({ type: ORDER_AUTO_COMP.FAILURE, payload: error.message })
    })
}

export const getOrderDetail = (code) =>{
  
    return axios({
      method: 'get',
      url: `${API_ROOT}/order/getbycode/${code}`,
      headers: { 
        'Content-Type': 'application/json' ,
        Authorization: 'Bearer ' + localStorage.getItem('access-token')
      },
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      return error;
    })
  }   