

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { ExampleStateLessTestView } from './ExampleStateLessTestView';

class TestStateLessCtrl extends Component {

    constructor(props) {
        console.log("TestStateLessCtrl :constructor")
        super(props);
        this.state = {
            val:null
        }

        this.onChangeText = this.onChangeText.bind(this);
    }

    componentDidMount() {
        console.log("TestStateLessCtrl :componentDidMount")
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("TestStateLessCtrl :componentWillReceiveProps")

    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("TestStateLessCtrl :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("TestStateLessCtrl :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("TestStateLessCtrl :componentDidUpdate")

    }

    // Initial function
    initial = (object) => {
        if (object) {
            for (let key in object) {
                let val = object[key]
                this.setState({ [key]: val })
            }
        }
    }

    //>Event 
    onChangeText = (e)=>{
        const {
            name,value
        }=e.target
        this.setState({[name]:value})
    }

    //> Submit
    onSubmit = () => {
        this.setState({val:""})

        this.showSuccess();
    }

    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    render() {
        console.log("TestStateLessCtrl :render")

        return (
            <div>
                <Growl ref={(el) => this.growl = el} />
                <h1>Profile</h1>
                <div className="p-grid">
                    {/* DETAIL */}
                    <ExampleStateLessTestView 
                        val={this.state.val}
                        onChangeText={this.onChangeText}
                    />

                    <div className="p-col-12">
                        <Button
                            className="p-col-12"
                            label="บันทึก"
                            icon="pi pi-check"
                            iconPos="left"
                            onClick={this.onSubmit} />
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(TestStateLessCtrl)
