import React from 'react';
const _ = require("lodash");
const { compose, withProps, lifecycle } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} = require("react-google-maps");
const { SearchBox } = require("react-google-maps/lib/components/places/SearchBox");

export const MapWithASearchBoxAndMarkers = compose(
  withProps({
    googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyA4jjZnsw8iYo3eDeZROTP-S63t1wfPYyo&v=3&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `400px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    
  }),
  lifecycle({
    componentWillMount() {
      const refs = {}
      
      this.setState({
        bounds: null,       
        center: { lat: 13.7734283, lng: 100.57383099999993 },
       
        onBoundsChanged: _.debounce(
          () => {
           
         
            let { onBoundsChange ,center} = this.props
            this.setState({
              bounds: refs.map.getBounds(),
              center: center
            })
            console.log("props"+ JSON.stringify(center));
            if (onBoundsChange) {
              onBoundsChange(refs.map)
            }
          },
          100,
          { maxWait: 500 }
        ),
        onMapMounted: ref => {
          refs.map = ref;
          console.log(ref);
        },
        /*onBoundsChanged: () => {
          this.setState({
            bounds: refs.map.getBounds(),
            center: refs.map.getCenter(),
          })
        },*/
        onSearchBoxMounted: ref => {
          refs.searchBox = ref;
        },

       
        onPlacesChanged: () => {
          const places = refs.searchBox.getPlaces();
          const bounds = new window.google.maps.LatLngBounds();
         
          places.forEach(place => {
            if (place.geometry.viewport) {
              bounds.union(place.geometry.viewport)
            } else {
              bounds.extend(place.geometry.location)
            }
            
          });
         
          
          const nextMarkers = places.map(place => ({
            position: place.geometry.location,
          }));
          const nextCenter = _.get(nextMarkers, '0.position', this.state.center);
          console.log(nextCenter.toJSON());
          this.setState({
            center: nextCenter,
           
            marker: nextCenter.toJSON()
          });
          //this.setState({marker: nextCenter[0]});
          // refs.map.fitBounds(bounds);
          
        },
      })
    },
  }),
  withScriptjs,
  withGoogleMap,
)(props =>
 
  <GoogleMap
    resetBoundsOnResize={true}
    ref={props.onMapMounted}
    defaultZoom={15}
    zoom={props.zoom}
    defaultCenter={{ lat: 13.7734283, lng: 100.57383099999993 }}
    center={props.center}
    onBoundsChanged={props.onBoundsChanged}
    
  >
  
    <SearchBox
      ref={props.onSearchBoxMounted}
      bounds={props.bounds}
      controlPosition={window.google.maps.ControlPosition.TOP_LEFT}
      onPlacesChanged={props.onPlacesChanged}
    >
      

      <input
        className="input-100"
        type="text"
        placeholder="ค้นหาที่อยู่"
        style={{
          fontFamily: "Kanit",
          boxSizing: `border-box`,
          border: `1px solid transparent`,
          width: `240px`,
          height: `32px`,
          marginTop: `57px`,
          marginLeft: `-180px`,
          borderRadius: `3px`,
          boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
          fontSize: `14px`,
          outline: `none`,
          textOverflow: `ellipses`,
        }} 
      />
    </SearchBox>

    <Marker  position={props.marker} icon="assets/map/c3leng-marker.png"/>    
    {props.markers.map((marker, index) =>
      <Marker key={index} position={marker} />      
    )}
    

  </GoogleMap>
);

