

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { DataTable } from 'primereact/datatable'
import { Column } from 'primereact/column'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { Button } from 'primereact/button';
import { ExportExcel } from '../ExportExcel/ExportExcel';


class TableProductGoodnessView extends Component {

    static defaultProps = {
        model: [{ field: null, header: null }],
        data: [],
        rows: 10,
        exportExcel: false,
        loading: false,
        selection: [],
        saveTransfer: false,

        actionTemplate: null,
        onPage: null,
        onSelectionChange: null,
        onSaveTransfer: null
    }

    static propTypes = {
        model: PropTypes.array,
        data: PropTypes.array,
        rows: PropTypes.number,
        exportExcel: PropTypes.bool,
        loading: PropTypes.bool,
        selection: PropTypes.array,
        saveTransfer: PropTypes.bool,

        actionTemplate: PropTypes.func,
        onPage: PropTypes.func,
        onSelectionChange: PropTypes.func,
        onSaveTransfer: PropTypes.func
    }

    state = {
        selectedCar1:null,
        rows: 5,
        pageNumber: 0,
        summary: []
    }

    //>Initial 
    componentDidMount() {
        console.log("TableProductGoodnessView :componentDidMount")
    }
    componentDidUpdate(preProp, preState) {
        if (preProp.data.length != this.props.data.length) {
            this.setState({ pageNumber: 0 })
        }
    }

    //> Event
    actionTemplate = (rowData, column) => {
        return this.props.actionTemplate(rowData, column);
    }
    onPage = (e) => {
        this.setState({
            loading: true
        });
        console.log(`firest: ${e.first}`)
        //imitate delay of a backend call
        setTimeout(() => {
            const startIndex = e.first;
            // const endIndex = event.first + this.state.rows;
            this.setState({ rows: e.rows })
            this.setState({
                pageNumber: startIndex,
                loading: false
            });
        }, 250);
    }

    export = () => this.dt.exportCSV();
    onSaveTransfer = () => this.props.onSaveTransfer();

    //> Mode render
    renderWastegen = () => {
        var sumCol6 = 0;
        var sumCol7 = 0;
        var sumCol8 = 0;
        var sumCol9 = 0;

        for (let key in this.props.data) {
            let entity = this.props.data[key]
            sumCol6 += parseFloat(entity['col6'])
            sumCol7 += parseFloat(entity['col7'])
            sumCol8 += parseFloat(entity['col8'])
            sumCol9 += parseFloat(entity['col9'])
        }

        let colSpan = 5;
        if(this.props.model.length!=11){
            colSpan=4;
        }

        return (
            <div></div>
            // <ColumnGroup>
            //     <Row>
            //         <Column style={{ textAlign: 'right' }} footer="Totals" colSpan={colSpan} />
            //         <Column style={{ textAlign: 'right' }} footer={`${sumCol6} Kg.`} />
            //         <Column style={{ textAlign: 'right' }} footer={`${sumCol7} บาท`} />
            //         <Column style={{ textAlign: 'right' }} footer={`${sumCol8} Kg.`} />
            //         <Column style={{ textAlign: 'right' }} footer={`${sumCol9} บาท`} />
            //     </Row>
            // </ColumnGroup>
        )
    }
    
    
    render() {
        console.log("TableProductGoodnessView :render")
        const {
            mode
        } = this.props

        let renderMode;

        switch (mode) {
            case 'wastegen':
                renderMode = this.renderWastegen()
                break;
            case 'wastetran':
                renderMode = this.renderWastetran()
                break;
            case 'wastepro':
                renderMode = this.renderWastepro()
                break;

            default:
                renderMode = (<div></div>)
                break;
        }

        let dynamicColumns = this.props.model.map((col, i) => {
            if (col.selectedMode) {
                return (
                    <Column
                        selectionMode="multiple"
                        style={{ width: '3em' }}
                    />
                )
            } else if (col.body) {
                return (
                    <Column
                        style={col.style}
                        header={col.header}
                        body={col.body}
                        style={{ textAlign: 'center', width: '10em' }}
                    />
                );
            } else if (col.field && col.header) {
                console.log("col ,",col)
                return (
                    <Column key={col.field}
                        style={col.style}
                        field={col.field}
                        header={col.header}
                        sortable={col.sortable} />
                );
            } else {
                return <div></div>
            }
        });

        const data = [];
        for (let keyData in this.props.data) {
            let entityData = this.props.data[keyData]

            let tmpObj = {};
            for (let keyModel in this.props.model) {
                let entityModel = this.props.model[keyModel]
                let field = entityModel.field
                let val = entityData[field]
                if (entityModel.selectedMode || entityModel.body) {
                    continue;
                }
                tmpObj[entityModel.header] = val

            }
            data.push(tmpObj);
        }
        console.log(`data is ${data}`)
        var exportExcel =
            <div style={{ textAlign: 'center' }}>
                {/* <Button
                    type="button"
                    icon="fa fa-fw fa-file-excel-o"
                    iconPos="left"
                    label="Export"
                    onClick={this.export}>
                </Button> */}
                ประเภทสินค้า
                {/* <ExportExcel data={data} fileName="export" /> */}
            </div>;
        var saveTransfer = (
            <div style={{ textAlign: 'right' }}>
                <Button
                    type="button"
                    icon="fa fa-fw fa-save"
                    iconPos="left"
                    label="โอนเงินให้แล้ว"
                    onClick={this.onSaveTransfer}>
                </Button>
            </div>
        )

        return (
            <div >
                <div className="content-section implementation">
                    <DataTable
                        selectionMode="single"
                        selection={this.state.selectedCar1} 
                        onSelectionChange={e => this.setState({selectedCar1: e.value})}
                        value={this.props.data}
                        responsive={true}
                        paginator={true}
                        rows={this.state.rows}
                        header={this.props.exportExcel ? exportExcel : (this.props.saveTransfer ? saveTransfer : null)}
                        emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)}
                        sortMode="multiple"
                        rowsPerPageOptions={[5, 10, 20]}
                        footerColumnGroup={renderMode}
                        first={this.state.pageNumber}
                        onPage={this.onPage}
                        loading={this.props.loading}
                    >
                        {dynamicColumns}
                    </DataTable>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(TableProductGoodnessView)
