import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import Moment from 'react-moment';
import 'moment-timezone';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import {Avatar} from '../../features/widget/Avatar';
import { API_ROOT } from '../../constants/api';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Panel } from 'primereact/components/panel/Panel';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { MapWithASearchBoxAndMarkers } from '../../features/map/MapWithASearchBoxAndMarkers'
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import * as ServerAPI from './service';
import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as FileService from '../../service/file.service'
import * as DashBoardService from './service'
import * as MasterService from '../../service/MasterService'
import { isEmpty } from '../../utils/validators';
import {Validators} from '../../utils'
import {CEInputText,CECalendar,CEDropdown} from '../../features/input';
import {CEFileUpload} from '../../features/widget';
import {Dialog} from 'primereact/dialog';
import {InputSwitch} from 'primereact/inputswitch';
import {RadioButton} from 'primereact/radiobutton';
import {Card} from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { UserAPI } from '../../service/user.service'
 class WasteproManageAccept extends Component {
    static propTypes = {

    }
    static defaultProps = {

    }

    constructor() {
        super();
        this.state = {
            acceptTimeList: [],
            distanceLabel: '',
            timeList: [
                {id: 0, disable: true,startTime: new Date(2020,1,1,8,0,0,0), endTime: new Date(2020,1,1,17,0,0,0)},
                {id: 1, disable: true,startTime: new Date(2020,1,1,8,0,0,0), endTime: new Date(2020,1,1,17,0,0,0)},
                {id: 2, disable: true,startTime: new Date(2020,1,1,8,0,0,0), endTime: new Date(2020,1,1,17,0,0,0)},
                {id: 3, disable: true,startTime: new Date(2020,1,1,8,0,0,0), endTime: new Date(2020,1,1,17,0,0,0)},
                {id: 4, disable: true,startTime: new Date(2020,1,1,8,0,0,0), endTime: new Date(2020,1,1,17,0,0,0)},
                {id: 5, disable: true,startTime: new Date(2020,1,1,8,0,0,0), endTime: new Date(2020,1,1,17,0,0,0)},
                {id: 6, disable: true,startTime: new Date(2020,1,1,8,0,0,0), endTime: new Date(2020,1,1,17,0,0,0)},
            ],
            isNotValid: false,
            isNotValidUpdate: false,
            showProgress: false,
            acceptJob: true,
            acceptCode: 0,
            daySelected: [],
            week: [
                { id: 'cb1', label: 'อาทิตย์', value: 1 },
                { id: 'cb2', label: 'จันทร์', value: 2 },
                { id: 'cb3', label: 'อังคาร', value: 3 },
                { id: 'cb4', label: 'พุธ', value: 4 },
                { id: 'cb5', label: 'พฤหัสบดี', value: 5 },
                { id: 'cb6', label: 'ศุกร์', value: 6 },
                { id: 'cb7', label: 'เสาร์', value: 7 }
            ],
            blankWeek: [
                { id: 'cb1', label: 'อาทิตย์', value: 1 },
                { id: 'cb2', label: 'จันทร์', value: 2 },
                { id: 'cb3', label: 'อังคาร', value: 3 },
                { id: 'cb4', label: 'พุธ', value: 4 },
                { id: 'cb5', label: 'พฤหัสบดี', value: 5 },
                { id: 'cb6', label: 'ศุกร์', value: 6 },
                { id: 'cb7', label: 'เสาร์', value: 7 }
            ],
            showSettingPanel: false,
            isInitial: true,
            wasteWorkStatus: [],
            startTimeList: [],
            endTimeList: [],
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
        this.onValidation = this.onValidation.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.onUpdateValidation = this.onUpdateValidation.bind(this);
        this.toFormatDateTime = this.toFormatDateTime.bind(this);
        this.startTimeInputPanel = this.startTimeInputPanel.bind(this);
        this.endTimeInputPanel = this.endTimeInputPanel.bind(this);
        this.onStartTimeChange = this.onStartTimeChange.bind(this);
        this.onEndTimeChange = this.onEndTimeChange.bind(this);
    }
    componentDidMount() {
        const { userServiceProps} = this.props
        userServiceProps.getUserInfo()
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.userInfo != this.props.userInfo) {
            if (nextProps.userInfo && this.state.isInitial){
            this.initial(nextProps.userInfo)
            // this.initialSelected(this.state)
        }
        }
        
    }
    initial = (nextProps) => {

        console.log(nextProps)
        const res = nextProps.wasteWorkStatus

        if(res.is_active){
            this.setState({ acceptCode: 1})
        }else{
            this.setState({ acceptCode: 2})
        }

        var dayChecked = []
        var dayCheckedString = []
        if(res.is_sunday){
            dayChecked.push(this.state.week[0])
            dayCheckedString.push(1)
        }
        if(res.is_monday){
            dayChecked.push(this.state.week[1])
            dayCheckedString.push(2)
        }
        if(res.is_tuesday){
            dayChecked.push(this.state.week[2])
            dayCheckedString.push(3)
        }
        if(res.is_wednesday){
            dayChecked.push(this.state.week[3])
            dayCheckedString.push(4)
        }
        if(res.is_thursday){
            dayChecked.push(this.state.week[4])
            dayCheckedString.push(5)
        }
        if(res.is_friday){
            dayChecked.push(this.state.week[5])
            dayCheckedString.push(6)
        }
        if(res.is_saturday){
            dayChecked.push(this.state.week[6])
            dayCheckedString.push(7)
        }

        var startTimeList = []
        startTimeList.push(res.sunday_start_time)
        startTimeList.push(res.monday_start_time)
        startTimeList.push(res.tuesday_start_time)
        startTimeList.push(res.wednesday_start_time)
        startTimeList.push(res.thursday_start_time)
        startTimeList.push(res.friday_start_time)
        startTimeList.push(res.saturday_start_time)

        var endTimeList = []
        endTimeList.push(res.sunday_end_time)
        endTimeList.push(res.monday_end_time)
        endTimeList.push(res.tuesday_end_time)
        endTimeList.push(res.wednesday_end_time)
        endTimeList.push(res.thursday_end_time)
        endTimeList.push(res.friday_end_time)
        endTimeList.push(res.saturday_end_time)

        this.setState({ 
            wasteWorkStatus: res,
            gid: res.gid,
            uid: res.user_uid,
            startTimeList: startTimeList,
            endTimeList: endTimeList,
            daySelected: dayChecked,
            daySelectedString: JSON.stringify(dayCheckedString),
        })
        // this.setState({ isInitial: false})
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div></div>);
    }
    actionTemplate(rowData, column) {
        return <div>
            {/* <Button type="button" onClick={() => this.onClickDeleteItem(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button> */}
            <Button type="button" onClick={() => this.onDistanceUpdate(column.rowData)} icon="pi pi-pencil" className="p-button" ></Button>
            <Button type="button" onClick={() => this.onDistanceDelete(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button>
        </div>;
    }
    onClickDeleteItem(rowData) {
        if (rowData !== '') {
            console.log(rowData)
            var id = rowData.gid;
            // ServerAPI.removeTranspotDistanceType(id).then((res) => {
            //     console.log(res);
            //     if (res) {
            //         var index = res.toString().indexOf("Error:");
            //         console.log("find error:" + (index));
            //         if (index == -1) {
            //             this.showSuccess();
            //             this.getTranspotDistanceType();
            //         } else {
            //             this.showWarn();
            //             this.setState({ showProgress: false})
            //         }
            //     } else {
            //         this.showWarn();
            //         this.setState({ showProgress: false})
            //     }
            // }).catch((error) => {
            //     this.setState({ showProgress: false})
            // })
        }else{
            this.showWarn();
            this.setState({ showProgress: false})
        }
        // if (index !== -1) {
        //     var array = this.state.distanceList;
        //     array.splice(index, 1);
        //     this.setState({ distanceList: array });
        // }
    }
    update() {
        this.setState({ showProgress: true })
        console.log(this.state.distance)
        console.log(this.state.isNotValidUpdate)
        if(this.state.distance){
            var isNotinput = (
                this.state.distance.distance_name == '' ||
                this.state.distance.more_than_km == '' ||
                this.state.distance.less_than_km == '' ||
                this.state.isNotValidUpdate == true
            );
    
            if (isNotinput) {
                this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
                this.setState({ showProgress: false })
            } else {
                    var min = parseFloat(this.state.distance.more_than_km)
                    var max = parseFloat(this.state.distance.less_than_km)
    
                if(min >= max){
                    console.log(min)
                    console.log(max)
                    this.showWarn("น้ำหนักต่ำสุด ต้องน้อยกว่าน้ำหนักสูงสุด");
                    this.setState({ showProgress: false })
                }else{
                    var notValid = false;
                    this.state.acceptTimeList.indexOf("")
                    this.state.acceptTimeList.forEach(objAcceptTimeList => {
                        var objMin = parseFloat(objAcceptTimeList.more_than_km)
                        var objMax = parseFloat(objAcceptTimeList.less_than_km)
                        console.log(min+" > "+objMin);
                        console.log(max+" > "+objMin);
                        console.log(min+" < "+objMax);
                        console.log(max+" < "+objMax);
                        
                        if(objAcceptTimeList.gid != this.state.distance.gid)
                        if((min < objMin && max < objMin) || (min > objMax && max > objMax)){
                            console.log("pass!");
                        }else if(objMin <= min || objMax >= max){
                            console.log("not pass!")
                            notValid = true;
                            }else if(objMin >= min || objMax <= max){
                            console.log("not pass2!")
                            notValid = true;
                            }else console.log("pass2!");
                    }); 
    
                    if(notValid){
                        this.showWarn("มีช่วงระยะทางที่ซ้ำซ้อนกัน");
                        this.setState({ showProgress: false })
                    }else{
                        var data = {
                            gid: this.state.distance.gid,
                            distance_name: this.state.distance.distance_name,
                            more_than_km: parseFloat(this.state.distance.more_than_km),
                            less_than_km: parseFloat(this.state.distance.less_than_km),
                        }

                        // ServerAPI.editTransportDistanceType(data).then((res) => {
                        //     console.log(res);
                        //     if (res) {
                        //         var index = res.toString().indexOf("Error:");
                        //         console.log("find error:" + (index));
                        //         if (index == -1) {
                        //             this.getTranspotDistanceType();
                        //             this.setState({ displayDialog:false});
                        //             this.showSuccess();
                        //         } else {
                        //             this.showWarn();
                        //             this.setState({ showProgress: false })
                        //         }
                        //     } else {
                        //         this.showWarn();
                        //         this.setState({ showProgress: false })
                        //     }
                        // }).catch((error) => {
                        //     this.setState({ showProgress: false })
                        // })
                    }
                }
            }
        }
    }

    delete() {
        this.setState({ showProgress: true })
        var data = this.state.distance;
        this.onClickDeleteItem(data);
        // let index = this.findSelectedWeightIndex();
        // this.setState({
        //     cars: this.state.cars.filter((val,i) => i !== index),
        //     selectedCar: null,
        //     car: null,
        this.setState({deleteDialog: false});
    }
    updateProperty(property, value) {
        let distance = this.state.distance;
        distance[property] = value;
        this.setState({distance: distance});
    }

    onDistanceUpdate(e){
        console.log(e)
        this.setState({
            displayDialog:true,
            distance: Object.assign({}, e)
        });
    }
    onDistanceDelete(e){
        console.log(e)
        this.setState({
            deleteDialog:true,
            distance: Object.assign({}, e)
        });
    }
    onValidation(valid){
        console.log(valid)
        if(!valid) this.setState({isNotValid: true })
        else this.setState({isNotValid: false })
    }
    onUpdateValidation(valid){
        console.log(valid)
        if(!valid) this.setState({isNotValidUpdate: true })
        else this.setState({isNotValidUpdate: false })
    }
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    onCheckChange(e) {
        console.log(e);

        let showPassCheck = this.state.daySelected;
        if (e.checked) {
            //his.state.wasteTypesSelected.checked=true;
            showPassCheck.push(e.value);
            console.log(e)
        }
        else {

            var index = this.state.week.indexOf(e.value);
            //this.state.wasteTypes.check
            showPassCheck.splice(showPassCheck.indexOf(e.value), 1);
        }
        this.setState({ daySelected: showPassCheck });

        var intChecked = [];
        for (let index = 0; index < showPassCheck.length; index++) {
            intChecked.push(showPassCheck[index].value);

        }

        this.setState({ daySelectedString: JSON.stringify(intChecked) });
        console.log(JSON.stringify(intChecked));
    }

    toFormatDateTime(dateTime){
        return <div><Moment format="HH:mm">
                {dateTime}
            </Moment></div>; 
    }

    onSubmit(){
        console.log(this.state.startTimeList)
        console.log(this.state.endTimeList)
        console.log(this.state.acceptCode)
        console.log(this.state.daySelected)
        this.setState({ showProgress: true })
        var isNotinput = (
            this.state.startTimeList == null ||
            this.state.endTimeList == null ||
            this.state.acceptCode == 0
            // || this.state.isNotValid == true
        );

        if (isNotinput) {
            this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
            this.setState({ showProgress: false })
        } else {

                if(this.state.acceptCode == 1 && this.state.daySelected.length == 0){
                    this.showWarn("กรณีเปิดรับงาน กรุณาเลือกวันที่ต้องการรับงาน");
                    this.setState({ showProgress: false })
                }else{
                    var notValid = false;
                    if(notValid){
                        this.showWarn("มีช่วงระยะทางที่ซ้ำซ้อนกัน");
                        this.setState({ showProgress: false })
                    }else{
                        const dayChecked = this.state.daySelectedString
                        console.log(JSON.stringify(dayChecked))
                        const startTime = this.state.startTimeList
                        const endTime = this.state.endTimeList
                        var acceptTimeList = {
                            gid: this.state.gid,
                            user_uid: this.state.uid,
                            is_active: (this.state.acceptCode == 1 ? true : false),
                            is_sunday: (dayChecked.toString().indexOf('1') != -1 ? true : false),
                            is_monday: (dayChecked.toString().indexOf('2') != -1 ? true : false),
                            is_tuesday: (dayChecked.toString().indexOf('3') != -1 ? true : false),
                            is_wednesday: (dayChecked.toString().indexOf('4') != -1 ? true : false),
                            is_thursday: (dayChecked.toString().indexOf('5') != -1 ? true : false),
                            is_friday: (dayChecked.toString().indexOf('6') != -1 ? true : false),
                            is_saturday: (dayChecked.toString().indexOf('7') != -1 ? true : false),
                            sunday_start_time: startTime[0],
                            sunday_end_time: endTime[0],
                            monday_start_time: startTime[1],
                            monday_end_time: endTime[1],
                            tuesday_start_time: startTime[2],
                            tuesday_end_time: endTime[2],
                            wednesday_start_time: startTime[3],
                            wednesday_end_time: endTime[3],
                            thursday_start_time: startTime[4],
                            thursday_end_time: endTime[4],
                            friday_start_time: startTime[5],
                            friday_end_time: endTime[5],
                            saturday_start_time: startTime[6],
                            saturday_end_time: endTime[6],
                            
                        }
                        //this.state.acceptTimeList.push(acceptTimeList)
                        console.log(acceptTimeList)
                        // this.showSuccess();
                        // this.setState({ showProgress: false})
                        ServerAPI.updateWorkingStatus(acceptTimeList).then((res) => {
                            console.log(res);
                            if (res) {
                                var index = res.toString().indexOf("Error:");
                                console.log("find error:" + (index));
                                if (index == -1) {
                                    this.showSuccess();
                                    this.setState({ showProgress: false})
                                } else {
                                    this.showWarn();
                                    this.setState({ showProgress: false})
                                }
                            } else {
                                this.showWarn();
                                this.setState({ showProgress: false})
                            }
                        }).catch((error) => {
                            this.setState({ showProgress: false})
                        })

                    }
                    console.log(this.state.acceptTimeList)
                }
        }
    }
    startTimeInputPanel = (index,disable) =>{
        let startTime = this.state.timeList[index].startTime
        if(this.state.startTimeList.length != 0){
            let hours = this.state.startTimeList[index].substring(0,2)
            let minutes = this.state.startTimeList[index].substring(3,5)
            let seconds = this.state.startTimeList[index].substring(6,8)
            startTime.setHours(hours)
            startTime.setMinutes(minutes)
            startTime.setSeconds(seconds)
        }
        
        return (
            <div className="p-col-fixed" style={{ textAlign: 'left' }}>
                <label style={{marginRight: '5px'}}>ช่วงเวลา :</label>
                <Calendar 
                    inputStyle={{width: '100%'}}
                    value={startTime} 
                    //onChange={(e) => this.setState({ startTime: e.value })} 
                    onChange={(e) => this.onStartTimeChange(e,index) } 
                    timeOnly={true} 
                    hourFormat="24" 
                    placeholder="เลือกเวลา"
                    readOnlyInput={true}
                    showIcon={true}
                    icon="fa fa-clock-o"
                    disabled={disable ? true : !(this.state.daySelected.indexOf(this.state.week[index]) !== -1)}
                    />
            </div>
        );
    }
    endTimeInputPanel = (index,disable) =>{
        let endTime = this.state.timeList[index].endTime
        if(this.state.endTimeList.length != 0){
            let hours = this.state.endTimeList[index].substring(0,2)
            let minutes = this.state.endTimeList[index].substring(3,5)
            endTime.setHours(hours)
            endTime.setMinutes(minutes)
        }

        return (
            <div className="p-col-fixed" style={{ textAlign: 'left' }}>
                <label style={{marginRight: '20px',marginLeft: '30px'}}>ถึงเวลา</label>
                <Calendar 
                    inputStyle={{width: '100%'}}
                    value={endTime} 
                    // onChange={(e) => this.setState({ endTime: e.value })} 
                    onChange={(e) => this.onEndTimeChange(e,index) } 
                    timeOnly={true} 
                    hourFormat="24"
                    placeholder="เลือกเวลา"
                    readOnlyInput={true}
                    showIcon={true}
                    icon="fa fa-clock-o"
                    disabled={disable ? true : !(this.state.daySelected.indexOf(this.state.week[index]) !== -1)}
                    />
            </div>
        );
    }
    onStartTimeChange(e,index){
        const time = e.value;
        let timelist = this.state.timeList
        timelist[index].startTime = time

        this.setState({timeList: timelist})

        var setTime = e.value;
        let startTime = this.state.startTimeList
        setTime.toISOString()
        console.log(setTime)
        let start = setTime.toString().indexOf(":")-2
        let end = setTime.toString().lastIndexOf(":")+3

        setTime = setTime.toString().substring(start,end)

        console.log(setTime)
        startTime[index] = setTime

        this.setState({startTimeList: startTime})
    }
    onEndTimeChange(e,index){
        const time = e.value;
        let timelist = this.state.timeList
        timelist[index].endTime = time

        this.setState({timeList: timelist})

        var setTime = e.value;
        let endTime = this.state.endTimeList
        setTime.toISOString()
        console.log(setTime)
        let start = setTime.toString().indexOf(":")-2
        let end = setTime.toString().lastIndexOf(":")+3

        setTime = setTime.toString().substring(start,end)

        console.log(setTime)
        endTime[index] = setTime

        this.setState({endTimeList: endTime})
    }

    showAddDistance(){
        const timeInputPanel = this.state.week.map((item,index)=>
            <div className="p-grid">
                <div className="p-col-fixed" style={{width: '100px', textAlign: 'left', alignSelf: 'center' }}>
                    <Checkbox
                        inputId={item.id}
                        value={this.state.week[index]}
                        onChange={(e) => { this.onCheckChange(e) }}
                        checked={this.state.daySelected.indexOf(this.state.week[index]) !== -1}>
                    </Checkbox>
                    <label htmlFor={item.id} className="p-checkbox-label">{item.label}</label>
                </div>
                {this.startTimeInputPanel(index)}
                {this.endTimeInputPanel(index)}
            </div>
        );

        const disableTimeInputPanel = this.state.blankWeek.map((item,index)=>
            <div className="p-grid">
                <div className="p-col-fixed" style={{width: '100px', textAlign: 'left', alignSelf: 'center' }}>
                    <Checkbox
                        inputId={item.id}
                        value={this.state.week[index]}
                        onChange={(e) => { this.onCheckChange(e) }}
                        checked={this.state.daySelected.indexOf(this.state.week[index]) !== -1}
                        disabled={true}>
                    </Checkbox>
                    <label htmlFor={item.id} className="p-checkbox-label">{item.label}</label>
                </div>
                {this.startTimeInputPanel(index,true)}
                {this.endTimeInputPanel(index,true)}
            </div>
        );

        const newDistance = (
            <div className="p-col-12" >
                    {/* <Accordion multiple={true} activeIndex={[0]}>
                        <AccordionTab header="วันเวลารับงาน" >
                            <div>
                            {this.state.acceptCode == 1 && timeInputPanel}
                            {this.state.acceptCode == 2 && disableTimeInputPanel}

                            
                            
                            </div>
                        </AccordionTab>
                        
                    </Accordion> */}
                    <br/>
                    {(localStorage.getItem('second-token'))?'':
                        <div className="p-col-12">
                            <Button
                                type="submit"
                                label="บันทึก"
                                icon="fa fa-save"
                                iconPos="left"
                                onClick={this.onSubmit}
                            />
                        </div>
                    }
                    
            </div>
        );
        return newDistance;
    }

    showDistanceTable(){
        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="บันทึก" icon="pi pi-check" onClick={this.update}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displayDialog: false})} className="p-button-warning"/>
            </div>;
        let deleteDialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ใช่" icon="pi pi-check" onClick={this.delete}/>
            <Button label="ไม่ใช่" icon="pi pi-times" onClick={() => this.setState({deleteDialog: false})} className="p-button-warning"/>
            </div>;
        const distanceTable = (
            <div className="p-col-12">
                    <Panel header="รายการช่วงเวลาที่รับงาน" >
                        <DataTable value={this.state.acceptTimeList} paginatorPosition="bottom" paginator={true} rows={5} rowsPerPageOptions={[5, 10, 15]}
                            responsive={true}
                            emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                            </div>)}>
                            <Column field="timeLabel" header="ช่วงเวลา" sortable={true} />
                            <Column field="sun" header="อาทิตย์" sortable={true} className="p-text-center"/>
                            <Column field="mon" header="จันทร์" sortable={true} className="p-text-center"/>
                            <Column field="tue" header="อังคาร" sortable={true} className="p-text-center"/>
                            <Column field="wes" header="พุธ" sortable={true} className="p-text-center"/>
                            <Column field="thu" header="พฤหัสบดี" sortable={true} className="p-text-center"/>
                            <Column field="fri" header="ศุกร์" sortable={true} className="p-text-center"/>
                            <Column field="sat" header="เสาร์" sortable={true} className="p-text-center"/>
                            <Column field="gid" body={this.actionTemplate.bind(this)} header="ลบ" className="p-text-center" style={{ width: '8em' }} />
                        </DataTable>
                    </Panel>
                    <Dialog visible={this.state.deleteDialog} width="300px" header="ยืนยันการลบข้อมูล" modal={true} footer={deleteDialogFooter} onHide={() => this.setState({deleteDialog: false})}>
                        {
                            this.state.distance &&
                            
                            <div className="p-grid p-fluid" >
                                <div className="p-col-12"><span>คุณต้องการจะลบข้อมูลต่อไปนี้</span></div>
                                <div className="p-col-4" ><label htmlFor="distance_name">ระยะทาง :</label></div>
                                <div className="p-col-8" >
                                    {this.state.distance.distance_name}
                                </div>

                                <div className="p-col-4" ><label htmlFor="more_than_km">ค่าเริ่มต้น (กม.) :</label></div>
                                <div className="p-col-8" >
                                    {this.state.distance.more_than_km}
                                </div>

                                <div className="p-col-4" ><label htmlFor="less_than_km">ค่าสิ้นสุด (กม.) :</label></div>
                                <div className="p-col-8" >
                                    {this.state.distance.less_than_km}
                                </div>
                            </div>
                        }
                    </Dialog>
                    <Dialog visible={this.state.displayDialog} width="300px" header="แก้ไขข้อมูล" modal={true} footer={dialogFooter} onHide={() => this.setState({displayDialog: false})}>
                        {
                            this.state.distance &&

                            <div className="p-grid p-fluid" >
                                
                                <div className="p-col-4" style={{ alignSelf: 'center' }}><label htmlFor="distance_name">ระยะทาง :</label></div>
                                <div className="p-col-8" >
                                    <CEInputText   
                                        id="distance_name"                      
                                        type="text"
                                        value={this.state.distance.distance_name}
                                        placeholder="กำหนดช่วงน้ำหนัก"
                                        maxlength={25}
                                        required={true}
                                        onChange={(e) => {this.updateProperty('distance_name', e.target.value)}}
                                        />
                                </div>

                                <div className="p-col-4" style={{ alignSelf: 'center' }}><label htmlFor="more_than_km">ค่าเริ่มต้น (กม.) :</label></div>
                                <div className="p-col-8" >
                                    <CEInputText   
                                        id="more_than_km"                        
                                        type="money"
                                        value={this.state.distance.more_than_km}
                                        name="more_than_km"
                                        placeholder="ต่ำสุด"
                                        tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                        required={true}
                                        isvalid={this.onUpdateValidation}
                                        onChange={(e) => {this.updateProperty('more_than_km', e.target.value)}}
                                        />
                                        
                                </div>

                                <div className="p-col-4" style={{ alignSelf: 'center' }}><label htmlFor="less_than_km">ค่าสิ้นสุด (กม.) :</label></div>
                                <div className="p-col-8" >
                                    <CEInputText   
                                        id="less_than_km"                        
                                        type="money"
                                        value={this.state.distance.less_than_km}
                                        name="less_than_km"
                                        placeholder="สูงสุด"
                                        tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                        required={true}
                                        isvalid={this.onUpdateValidation}
                                        onChange={(e) => {this.updateProperty('less_than_km', e.target.value)}}
                                        />
                                    
                                </div>
                            </div>
                        }
                    </Dialog>
            </div>
        );
        if(!this.state.showProgress)
        return distanceTable;
    }

    acceptJobStastus(){
        return (
        <div className="p-col-12">
        <Panel header="สถานะรับงาน" >
            <div className="p-grid">
                <div className="p-col-12 p-md-4 p-lg-4">
                    <RadioButton inputId="rb1" name="acceptCode" value={1}
                        onChange={(e) => this.setState({ acceptCode: e.value })}
                        checked={this.state.acceptCode === 1} />
                    <label htmlFor="rb1" className="p-radiobutton-label" style={{marginRight: '20px'}}>รับงาน</label>
                
                    <RadioButton inputId="rb2" name="acceptCode" value={2}
                        onChange={(e) => this.setState({ acceptCode: e.value })}
                        checked={this.state.acceptCode === 2} />
                    <label htmlFor="rb2" className="p-radiobutton-label">ไม่รับงาน</label>
                </div>
                <div className="p-col-12 p-md-8 p-lg-8"></div>
            </div>   
        </Panel>
        </div>
        );
    }
    

    render() {
        const acceptJobStastusPanel = this.acceptJobStastus();
        const addDistancePanel = this.showAddDistance();
        const distanceTablePanel = this.showDistanceTable();
        const showProgress = this.showProgressSpinner();
        return <div className="p-grid ">
            <Growl ref={(el) => this.growl = el} />
                <div className="p-col-12">
                        <h1>ตั้งค่าการรับงาน</h1>
                    </div>
                    {acceptJobStastusPanel}
                    {addDistancePanel}
                    {showProgress}
                    {/* {distanceTablePanel} */}

        </div>
    }
    
}


const mapStateToProps = (state) => ({
    
    productType: state.master.productType,
    product: state.master.product,
    userInfo: state.userInfo.data,

})

const mapDispatchToProps = dispatch => ({
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    userServiceProps: bindActionCreators(UserService,dispatch),
})

export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(WasteproManageAccept)

