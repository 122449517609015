import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'moment-timezone'
import { Button } from 'primereact/button'
import { Growl } from 'primereact/growl'
import { Panel } from 'primereact/components/panel/Panel'
import { DataTable } from 'primereact/components/datatable/DataTable'
import { Column } from 'primereact/components/column/Column'
import { ProgressSpinner } from 'primereact/progressspinner'
import {
    getSettingParameterAll,
    updateSettingParameter
} from '../../service/MasterService'
import { CEInputText } from '../../features/input'
import { Dialog } from 'primereact/dialog'
export default function ManageSettingParameter() {
    const { settingParameter } = useSelector(state => state)
    const [showProgress, setShowProgress] = useState(false)
    const [selectItem, setSelectItem] = useState(null)
    const [isEdit, setIsEdit] = useState(false)
    const [isValid, setIsValid] = useState(true)
    const [selectRow, setSelectRow] = useState(null)
    const growl = useRef(null)
    const dispatch = useDispatch()

    useEffect(() => {
        setShowProgress(true)
        dispatch(getSettingParameterAll()).then(res => {
            setShowProgress(false)
        })
    }, [])

    const onSelectItem = (data) => {
        setSelectItem(data)
        setIsEdit(true)
    }

    const onChangeValue = (value) => {
        setSelectItem({ ...selectItem, param_value: value })
    }

    const showProgressSpinner = () => {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner
                    style={{ width: '80px', height: '80px' }}
                    strokeWidth="8" fill="#EEEEEE"
                    animationDuration="0.5s"
                />
            </div>
        );

        if (showProgress) {
            return (Progress)
        } else
            return (<div></div>)
    }

    const actionTemplate = (rowData, column) => {
        return <div>
            <Button
                type="button"
                onClick={() => onSelectItem(column.rowData)}
                icon="pi pi-pencil"
                className="p-button"
            ></Button>
        </div>;
    }

    const onUpdate = () => {
        if (!isValid) {
            showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
            setShowProgress(false)
        } else {
            dispatch(updateSettingParameter(selectItem)).then(res => {
                dispatch(getSettingParameterAll())
                setIsEdit(false)
                showSuccess()
            })
        }
    }

    const showSuccess = (message) => {
        growl.current.show({
            severity: 'success',
            summary: 'สถานะ',
            detail: message ? message : 'ทำรายการสำเร็จ'
        })
    }
    const showWarn = (message) => {
        growl.current.show({
            severity: 'warn',
            summary: 'สถานะ',
            detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ'
        })
    }

    const showSettingTable = () => {
        let editDialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="แก้ไข" icon="pi pi-check" disabled={!isValid} onClick={onUpdate} />
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => setIsEdit(false)} className="p-button-warning" />
        </div>;
        const settingTable = (
            <div className="p-col-12">
                <Panel header="รายการตั้งค่า" >
                    <DataTable
                        value={settingParameter.data}
                        paginatorPosition="bottom"
                        paginator={true} rows={15}
                        rowsPerPageOptions={[5, 10, 15]}
                        responsive={true}
                        selectionMode="single"
                        selection={selectRow}
                        onSelectionChange={e => setSelectRow(e.value)}
                        emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)}>
                        <Column field="param_label" header="ชื่อพารามิเตอร์" sortable={true} filter={true} className="p-text-center"/>
                        <Column field="param_value" header="ค่าพารามิเตอร์" sortable={true} filter={true} className="p-text-center" />
                        <Column field="param_uom" header="หน่วย" sortable={true} filter={true} className="p-text-center" />
                        <Column field="gid" body={actionTemplate} header="แก้ไข" className="p-text-center" style={{ width: '8em' }} />
                    </DataTable>
                </Panel>

                <Dialog visible={isEdit} width="200px" header="แก้ไขค่าพารามิเตอร์"
                    modal={true} footer={editDialogFooter} onHide={() => setIsEdit(false)}>
                    {
                        selectItem &&
                        <div className="p-grid p-fluid" >
                            <div className="p-col-6" style={{ alignSelf: 'center', textAlign: 'right' }}>
                                <label htmlFor="cert_name">{selectItem.param_label} :</label>
                            </div>
                            <div className="p-col-3" >
                                <CEInputText
                                    className="input-50"
                                    id="paramValue"
                                    type="money"
                                    value={selectItem.param_value}
                                    name="paramValue"
                                    placeholder="กำหนดค่า"
                                    tooltip="(ทศนิยม 2 ตำแหน่ง)"
                                    required={true}
                                    isvalid={(e) => setIsValid(e)}
                                    onChange={(e) => onChangeValue(e.target.value)}
                                />
                            </div>
                            <div className="p-col-3" style={{ alignSelf: 'center' }}>
                                {selectItem.param_uom}
                            </div>
                        </div>
                    }
                </Dialog>
            </div>
        )
        if (!showProgress)
            return settingTable;
    }

    return (
        <div className="p-grid ">
            <Growl ref={growl} />
            <div className="p-col-12">
                <h1>การตั้งค่าพารามิเตอร์</h1>
            </div>
            {showProgressSpinner()}
            {showSettingTable()}
        </div>
    )
}
