import React from 'react'
import { Validators } from '../../utils';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';
import { InputMask } from 'primereact/inputmask';

export const ValidateInputNumberLengthView = ({ 
    value, 
    onChange, 
    mask,
    name,
    placeholder,
    errorMessage,
    required
}) => {

    mask = mask || "****************************************";
    errorMessage = errorMessage||`กรุณาระบุ ${placeholder}`
    required = required || true

    return (
        <div>
                <InputMask
                    required={required}
                    className={Validators.isInValid(value, mask.length) ? 'p-error' : ''}
                    name={name}
                    mask={mask}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            {
                Validators.isInValid(value, mask.length) &&
                <Message severity="error" text={errorMessage} />
            }
        </div>
    )
}