import { API_ROOT } from '../constants/api'
import axios from './axios'
import {
  GET_CHAT_MESSAGE_BY_ROOM,
  LOAD_MORE_CHAT_MESSAGE,
  ADD_NEW_CHAT_MESSAGE,
  SEND_CHAT_IMAGE,
  GET_CHAT_LIST,
  GET_CHAT_PICKUP_BADGE,
  GET_CHAT_DELIVERY_BADGE,
  GET_CHAT_ADMIN_BADGE,
} from './actionType'
import { map, without, orderBy, includes } from 'lodash'

export const getChatMessageByRoom = (roomId, pageSize, timeStamp) => dispatch => {
  dispatch({ type: GET_CHAT_MESSAGE_BY_ROOM.REQUEST })
  console.log('getChatMessageByRoom roomId', roomId)
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetByRoomId/${roomId}/${pageSize}/${timeStamp}`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(res => {
      // console.log('getChatMessageByRoom data', res.data)
      dispatch({ type: GET_CHAT_MESSAGE_BY_ROOM.SUCCESS, payload: res.data })
    })
    .catch(error => {
      dispatch({ type: GET_CHAT_MESSAGE_BY_ROOM.FAILURE, error })
    })
}

export const loadMoreChatMessage = (roomId, pageSize, timeStamp) => dispatch => {
  dispatch({ type: LOAD_MORE_CHAT_MESSAGE.REQUEST })
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetByRoomId/${roomId}/${pageSize}/${timeStamp}`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(res => {
      dispatch({ type: LOAD_MORE_CHAT_MESSAGE.SUCCESS, payload: res.data })
    })
    .catch(error => {
      dispatch({ type: LOAD_MORE_CHAT_MESSAGE.FAILURE, error })
    })
}

export const addNewChatMessage = message => dispatch => {
  dispatch({ type: ADD_NEW_CHAT_MESSAGE, message })
}

export const sendChatImage = (image, roomId, handleResult) => dispatch => {
  dispatch({
    type: SEND_CHAT_IMAGE.REQUEST,
    isLoading: true,
  })

  const formData = new FormData()
  const fileData = image.uri.split('/')
  const fileName = fileData[fileData.length - 1]
  formData.append('file', { uri: image.uri, type: image.type, name: fileName })
  formData.append('room_id', roomId)

  // return
  return axios({
    method: 'post',
    url: `${API_ROOT}/chatmessage/PostImage`,
    data: formData,
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(res => {
      dispatch({
        type: SEND_CHAT_IMAGE.SUCCESS,
        payload: res.data,
      })
      console.log('res.data', res.data)
      handleResult(true, res.data)
    })
    .catch(error => {
      dispatch({
        type: SEND_CHAT_IMAGE.FAILURE,
        payload: error.message,
      })
      console.log(error.response)
      handleResult(false)
    })
}

export const getChatList = () => dispatch => {
  dispatch({ type: GET_CHAT_LIST.REQUEST })

  dispatch({ type: GET_CHAT_LIST.SUCCESS, payload: _mockChatList })
  // return axiosTokenJson({
  //   method: 'get',
  //   url: `${API_ROOT}/chatmessage/GetByRoomId/${roomId}`,
  // })
  //   .then(res => {
  //     dispatch({ type: GET_CHAT_MESSAGE_BY_ROOM.SUCCESS, payload: res.data })
  //   })
  //   .catch(error => {
  //     dispatch({ type: GET_CHAT_MESSAGE_BY_ROOM.FAILURE, error })
  //   })
}

export const getAdminUid = () => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetAdminUid`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(res => {
      return res.data
    })
    .catch(error => {
      return error
    })
}

export const getBadgeAdmin = () => dispatch => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetUnread/admin`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
  }
  })
    .then(res => {
      dispatch({
        type: GET_CHAT_ADMIN_BADGE,
        payload: res.data,
      })
    })
    .catch(error => {})
}

export const getBadgePickup = room_id => dispatch => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetUnreadByRoomId/${room_id}`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(res => {
      dispatch({
        type: GET_CHAT_PICKUP_BADGE,
        payload: res.data,
      })
    })
    .catch(error => {
    })
}

export const getBadgeDelivery = room_id => dispatch => {
  return axios({
    method: 'get',
    url: `${API_ROOT}/chatmessage/GetUnreadByRoomId/${room_id}`,
    headers: {
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('access-token')
    }
  })
    .then(res => {
      dispatch({
        type: GET_CHAT_DELIVERY_BADGE,
        payload: res.data,
      })
    })
    .catch(error => {
    })
}

export const updateReadAll = (roomId,type,badges) => dispatch => {
  let isPickup = includes(roomId, 'pickup')
  let isDelivery = includes(roomId, 'delivery')
  let isAdminChat = includes(roomId, 'admin')
  // return axios({
  //   method: 'get',
  //   url: `${API_ROOT}/chatmessage/ReadAll/${roomId}`,
  //   headers: {
  //       'Content-Type': 'application/json',
  //       Authorization: 'Bearer ' + localStorage.getItem('access-token')
  //   }
  // })
  //   .then(res => {
      if(isPickup) {
        dispatch({
          type: GET_CHAT_PICKUP_BADGE,
          payload: 0,
        })
      }
      if(isDelivery) {
        dispatch({
          type: GET_CHAT_DELIVERY_BADGE,
          payload: 0,
        })
      }
      if(isAdminChat && type == 0) {
        dispatch({
          type: GET_CHAT_ADMIN_BADGE,
          payload: 0,
        })
      }
      if(isAdminChat && type == 1) {
        dispatch({
          type: GET_CHAT_ADMIN_BADGE,
          payload: badges,
        })
      }
    // })
    // .catch(error => {
    // })
}

const _mockChatList = [
  {
    id: Math.random().toString(),
    name: 'Tony Stark',
  },
  {
    id: Math.random().toString(),
    name: 'Captain America',
  },
  {
    id: Math.random().toString(),
    name: 'Thor',
  },
  {
    id: Math.random().toString(),
    name: 'Spiderman',
  },
]

const _mockLoadMore = {
  data: [
    {
      iam: true,
      is_deleted: false,
      message: 'test text',
      message_id: Math.random().toString(),
      message_type: 1,
      room_id: null,
      send_datetime: '2020-07-10T14:54:32.4559212+07:00',
      sender_id: null,
      token: null,
    },
    {
      iam: true,
      is_deleted: false,
      message: 'test text',
      message_id: Math.random().toString(),
      message_type: 1,
      room_id: null,
      send_datetime: '2020-07-10T14:54:32.4559212+07:00',
      sender_id: null,
      token: null,
    },
    {
      iam: true,
      is_deleted: false,
      message: 'test text',
      message_id: Math.random().toString(),
      message_type: 1,
      room_id: null,
      send_datetime: '2020-07-10T14:54:32.4559212+07:00',
      sender_id: null,
      token: null,
    },
    {
      iam: true,
      is_deleted: false,
      message: 'test text',
      message_id: Math.random().toString(),
      message_type: 1,
      room_id: null,
      send_datetime: '2020-07-10T14:54:32.4559212+07:00',
      sender_id: null,
      token: null,
    },
    {
      iam: true,
      is_deleted: false,
      message: 'test text',
      message_id: Math.random().toString(),
      message_type: 1,
      room_id: null,
      send_datetime: '2020-07-10T14:54:32.4559212+07:00',
      sender_id: null,
      token: null,
    },
    {
      iam: true,
      is_deleted: false,
      message: 'test text',
      message_id: Math.random().toString(),
      message_type: 1,
      room_id: null,
      send_datetime: '2020-07-10T14:54:32.4559212+07:00',
      sender_id: null,
      token: null,
    },
    {
      iam: true,
      is_deleted: false,
      message: 'test text',
      message_id: Math.random().toString(),
      message_type: 1,
      room_id: null,
      send_datetime: '2020-07-10T14:54:32.4559212+07:00',
      sender_id: null,
      token: null,
    },
    {
      iam: true,
      is_deleted: false,
      message: 'test text',
      message_id: Math.random().toString(),
      message_type: 1,
      room_id: null,
      send_datetime: '2020-07-10T14:54:32.4559212+07:00',
      sender_id: null,
      token: null,
    },
    {
      iam: true,
      is_deleted: false,
      message: 'test text',
      message_id: Math.random().toString(),
      message_type: 1,
      room_id: null,
      send_datetime: '2020-07-10T14:54:32.4559212+07:00',
      sender_id: null,
      token: null,
    },
    {
      iam: true,
      is_deleted: false,
      message: 'test text',
      message_id: Math.random().toString(),
      message_type: 1,
      room_id: null,
      send_datetime: '2020-07-10T14:54:32.4559212+07:00',
      sender_id: null,
      token: null,
    },
  ],
}
