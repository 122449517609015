// import Money from 'js-money'


import moment from 'moment'
import { get, isEmpty } from 'lodash'
import * as word from '../constants/word'

export const generateActionType = name => ({
  REQUEST: `${name}_REQUEST`,
  SUCCESS: `${name}_SUCCESS`,
  FAILURE: `${name}_FAILURE`,
})

// for date format 30-12-2018
export const convertToBuddhistYear = (date) => {
  const dateArr = date.split('-')
  if (dateArr.length !== 3) {
    return date
  }
  const buddhistYear = parseInt(dateArr[2], 10) + 543
  return `${dateArr[0]}-${dateArr[1]}-${buddhistYear}`
}

export const convertToChristYear = (date) => {
  const dateArr = date.split('-')
  if (dateArr.length !== 3) {
    return date
  }
  const christYear = parseInt(dateArr[2], 10) - 543
  return `${dateArr[0]}-${dateArr[1]}-${christYear}`
}

export const convertDateMonthWordToNumber = (date) => {
  const dateArr = date.split('-')
  if (dateArr.length !== 3) {
    return date
  }
  let monthWord
  const dateMonth = dateArr[1]
  if (dateMonth === word.monthWord1) {
    monthWord = '01'
  } else if (dateMonth === word.monthWord2) {
    monthWord = '02'
  } else if (dateMonth === word.monthWord3) {
    monthWord = '03'
  } else if (dateMonth === word.monthWord4) {
    monthWord = '04'
  } else if (dateMonth === word.monthWord5) {
    monthWord = '05'
  } else if (dateMonth === word.monthWord6) {
    monthWord = '06'
  } else if (dateMonth === word.monthWord7) {
    monthWord = '07'
  } else if (dateMonth === word.monthWord8) {
    monthWord = '08'
  } else if (dateMonth === word.monthWord9) {
    monthWord = '09'
  } else if (dateMonth === word.monthWord10) {
    monthWord = '10'
  } else if (dateMonth === word.monthWord11) {
    monthWord = '11'
  } else if (dateMonth === word.monthWord12) {
    monthWord = '12'
  } else {
    monthWord = ''
  }
  return `${dateArr[0]}-${monthWord}-${dateArr[2]}`
}

export const convertDateMonthNumberToWord = (dateValue) => {
  const date = dateValue instanceof Date ? moment(dateValue).format('DD-MM-YYYY') : dateValue
  const dateArr = date.split('-')
  if (dateArr.length !== 3) {
    return date
  }
  if(dateArr[2]=='0001'){
    return 'ไม่ระบุ';
  }
  const dateMonth = dateValue instanceof Date ? dateValue.getMonth() + 1 : parseInt(dateArr[1], 10)
  let monthWord = ''
  if (dateMonth === 1) {
    monthWord = word.monthWord1
  } else if (dateMonth === 2) {
    monthWord = word.monthWord2
  } else if (dateMonth === 3) {
    monthWord = word.monthWord3
  } else if (dateMonth === 4) {
    monthWord = word.monthWord4
  } else if (dateMonth === 5) {
    monthWord = word.monthWord5
  } else if (dateMonth === 6) {
    monthWord = word.monthWord6
  } else if (dateMonth === 7) {
    monthWord = word.monthWord7
  } else if (dateMonth === 8) {
    monthWord = word.monthWord8
  } else if (dateMonth === 9) {
    monthWord = word.monthWord9
  } else if (dateMonth === 10) {
    monthWord = word.monthWord10
  } else if (dateMonth === 11) {
    monthWord = word.monthWord11
  } else if (dateMonth === 12) {
    monthWord = word.monthWord12
  } else {
    monthWord = ''
  }
  return `${dateArr[0]}-${monthWord}-${dateArr[2]}`
}



export const getRegionForCoordinates = (points) => {
  // points should be an array of { latitude: X, longitude: Y }
  let minX, maxX, minY, maxY

  // init first point
  ((point) => {
    minX = point.latitude;
    maxX = point.latitude;
    minY = point.longitude;
    maxY = point.longitude;
  })(points[0])

  // calculate rect
  points.map((point) => {
    minX = Math.min(minX, point.latitude)
    maxX = Math.max(maxX, point.latitude)
    minY = Math.min(minY, point.longitude)
    maxY = Math.max(maxY, point.longitude)
  })

  const midX = (minX + maxX) / 2
  const midY = (minY + maxY) / 2
  const deltaX = (maxX - minX)
  const deltaY = (maxY - minY)

  return {
    latitude: midX,
    longitude: midY,
    latitudeDelta: deltaX + (deltaX / 2),
    longitudeDelta: deltaY + (deltaY / 2)
  }
}

export const randomString = () => (
  Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
)

export const getFilenameFromUriAndroid = (uri, type) => {
  const name = uri.split('/')
  const filename = get(name, `[${name.length - 1}]`, randomString())
  return type === 'image' ? `${filename}.JPG` : `${filename}.MP4`
}

export const converSecondToClock = (timeSecond) => {
  const hour = parseInt(timeSecond / 3600, 10)
  const minute = parseInt(timeSecond % 3600 / 60, 10)
  const second = parseInt(timeSecond % 60, 10)
  return `${('0' + hour).slice(-2)}:${('0' + minute).slice(-2)}:${('0' + second).slice(-2)}`
}

export const getValue = (first, rest, defaultValue) => {
  return get(first, rest, defaultValue) || defaultValue
}

export const addCommaFromInteger = (integer = 0) => {
  if (integer) return integer.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  return 0
}

/**
 * addCommaAndDotFromNumberOrStr(32589) -> '32,589.00'
 * addCommaAndDotFromNumberOrStr(32589.15) -> '32,589.15'
 * addCommaAndDotFromNumberOrStr('32589') -> '32,589.00'
 * addCommaAndDotFromNumberOrStr('32589.15') -> '32,589.15'
 * addCommaAndDotFromNumberOrStr('32589.3') -> '32,589.30'
 * addCommaAndDotFromNumberOrStr(1) -> '1.00'
 * addCommaAndDotFromNumberOrStr('1') -> '1.00'
 * addCommaAndDotFromNumberOrStr(1000) -> '1,000.00'
 * addCommaAndDotFromNumberOrStr('1000') -> '1,000.00'
 */
export const addCommaAndDotFromNumberOrStr = (integerOrFloat) => {
  if (Number.isNaN(integerOrFloat)) {
    throw new Error('addCommaAndDotFromNumberOrStr require Integer / Float / String contain only number')
  }
  if (!`${integerOrFloat}`.includes('.')) {
    return `${addCommaFromInteger(integerOrFloat)}.00`
  }
  const decimalIndex = 1
  const splitIntAndDecimal = `${integerOrFloat}`.split('.')
  const integerWithComma = addCommaFromInteger(splitIntAndDecimal[0])
  const decimalNumber = (splitIntAndDecimal[decimalIndex]).length > 1 ? splitIntAndDecimal[decimalIndex].substr(0, 2) : `${splitIntAndDecimal[1]}0`
  return `${integerWithComma}.${decimalNumber}`
}

export const removeCommaFromNumber = (numberFormat = '') =>
  parseFloat(numberFormat.replace(/([,])/g, ''), 10)

export const removeAllCharExceptNumber = (value = '') =>
  value.replace(/[^0-9]/g, '')

export const removeAllCharExceptNumberAndDot = (value = '') =>
  value.replace(/[^0-9.]/g, '')

export const dateFormat = (date, format) => {
  format = format || "DD/MM/YYYY HH:mm:ss";
  if(moment(date).format('YYYY')<2000){
    return "";
  }
  return moment(date).format(format);
}

export const plusDate = (date, plus) => {
  plus = plus || 1;
  date = date||new Date();
  return moment(date).add(plus, 'day');
}

export const getFile = (src) =>{
  return `${src}?t=${new Date().getTime()}.${getExt(src)}`
}

const getExt = (filename)=>{
  return filename.split('.').pop().toLowerCase();
}

export const formatMB = (bytes) => {
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return 'n/a';
  const i = parseInt(Math.floor(Math.log(Math.abs(bytes)) / Math.log(1024)), 10);
  if (i === 0) return `${bytes} ${sizes[i]})`;
  return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`;
}