import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'moment-timezone'
import { Button } from 'primereact/button'
import { Growl } from 'primereact/growl'
import { Panel } from 'primereact/components/panel/Panel'
import { DataTable } from 'primereact/components/datatable/DataTable'
import { Column } from 'primereact/components/column/Column'
import { ProgressSpinner } from 'primereact/progressspinner'
import {
    getCertResult
} from './service'

export default function ManageCertificateResult() {

    const { adminCertResult } = useSelector(state => state)
    const dispatch = useDispatch()
    const [certificationList, setCertificationList] = useState([])
    const [showProgress, setShowProgress] = useState(false)
    const growl = useRef(null)

    useEffect(() => {
        dispatch(getCertResult())
    }, [])

    useEffect(() => {
        if (adminCertResult.data &&
            !adminCertResult.isLoading &&
            !adminCertResult.isFailed) {
            setCertificationList(adminCertResult.data)
        }
    }, [adminCertResult])

    const showProgressSpinner = () => {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner
                    style={{ width: '80px', height: '80px' }}
                    strokeWidth="8" fill="#EEEEEE"
                    animationDuration="0.5s"
                />
            </div>
        );

        if (showProgress) {
            return (Progress)
        } else
            return (<div></div>)
    }

    const showSuccess = (message) => {
        growl.current.show({
            severity: 'success',
            summary: 'สถานะ',
            detail: message ? message : 'ทำรายการสำเร็จ'
        })
    }
    const showWarn = (message) => {
        growl.current.show({
            severity: 'warn',
            summary: 'สถานะ',
            detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ'
        })
    }

    const viewResult = () => {
        return (
            <div className="p-col-12" >
                <Panel header="สรุปจำนวนผู้ที่ได้รับใบรับรอง/เหรียญ" >
                    {!showProgress && <DataTable
                        value={certificationList}
                        responsive={true}
                        emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)}>
                        <Column field="cert_name" header="ชื่อเหรียญ" sortable={true} filter={true} />
                        <Column field="carbon_credit_start" header="ค่าคาร์บอนเครดิตเริ่มต้น" sortable={true} filter={true} className="p-text-center" />
                        <Column field="carbon_credit_end" header="ค่าคาร์บอนเครดิตสิ้นสุด" sortable={true} filter={true} className="p-text-center" />
                        <Column field="users_amount" header="จำนวนผู้ใช้ที่ได้รับ" sortable={true} filter={true} className="p-text-center" />
                    </DataTable>
                    }
                    {showProgressSpinner()}
                </Panel>
            </div>
        )
    }

    return (
        <div className="p-grid ">
            <Growl ref={growl} />
            <div className="p-col-12">
                <h1>สรุปใบรับรอง/เหรียญ</h1>
            </div>
            {viewResult()}
        </div>
    )
}
