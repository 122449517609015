import React, { Component } from "react";
import { connect } from 'react-redux';
import { AutoComplete } from 'primereact/autocomplete';
import {Chips} from 'primereact/chips';
import { Button } from 'primereact/button';

export class AddU3View extends Component {

  componentDidMount() {
    //console.log("AddU3View :componentDidMount")
  }

  //>Life Cycle : Re-render / Update
  componentWillReceiveProps(nextProps) {

  }

  shouldComponentUpdate(nextProps, nextState) {
    //console.log("AddU3View :shouldComponentUpdate")
    return true;
  }

  componentWillUpdate(nextProps, nextState) {
    //console.log("AddU3View :componentWillUpdate")
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log("AddU3View :componentDidUpdate")
  }

  render() {
    const {suggestions,listSuggestions,valueU3} = this.props
    let chipValue = valueU3
    if(chipValue.length == 0){
      chipValue = [{full_name: "ต้องการส่งทั้งหมด",}]
    }
    return (
      <div className="p-grid">
        <div className="p-col-4">
          <AutoComplete
            //inputClassName="input-100"
            size = {20}
            value={suggestions}
            suggestions={listSuggestions}
            onSelect={this.props.onSelectAutoCompleteSearchName}
            completeMethod={this.props.suggestBrands}
            onChange={(e) => this.props.onUsernameChanged(e.target.value)}
            itemTemplate={this.props.itemTemplateAutoComplete}
            placeholder="ระบุร้านขายของเก่า"
          />
        </div>
        {/* <div className="p-col-2">
          <Button
            label="เพิ่ม"
            className="p-button input-100"
            style={{ paddingTop: "6px" }}
            onClick={()=>this.props.onAddU3()}
          />
        </div> */}
        <div className="p-col-12">
          <Chips
            style={{width:'100%'}}
            value={chipValue}
            itemTemplate={this.props.itemTemplateChipU3}
            onRemove={this.props.onRemove}>
          </Chips>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(AddU3View)