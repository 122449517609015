import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';

import { InputText } from 'primereact/components/inputtext/InputText';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { MapMarkers } from '../../features/map/MapMarkers'
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { Avatar } from '../../features/widget/Avatar';
import { API_ROOT } from '../../constants/api'
import { ProgressSpinner } from 'primereact/progressspinner';
import {Dialog} from 'primereact/dialog';
import * as ServerAPI from './service';
import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'
import * as DashBoardService from './service'
import { isEmpty } from '../../utils/validators';
import {Validators} from '../../utils'
import {CEInputText,CECalendar,CEDropdown} from '../../features/input';
import {CEFileUpload} from '../../features/widget';

class ManageDeliveryCost extends Component {
    static propTypes = {

    }
    static defaultProps = {

    }

    constructor() {
        super();
        this.state = {
            deliveryCost: '',
            deliveryCostList: [],
            distanceName: null,
            distanceGid: null,
            distanceItem: [
                //{ name: 'เลือกระยะทาง', code: 0 },
                { name: 'น้อยกว่า 5 กิโลเมตร', code: 1 },
                { name: '5-9 กิโลเมตร', code: 2 },
                { name: 'มากกว่า 10 กิโลเมตร', code: 3 },
            ],
            weightName: null,
            weightGid: null,
            weightItem: [
                //{ name: 'เลือกน้ำหนัก', code: 0 },
                { name: 'น้อยกว่า 10 กิโลกรัม', code: 1 },
                { name: '10-50 กิโลกรัม', code: 2 },
                { name: '51-500 กิโลกรัม', code: 3 },
                { name: '501-1000 กิโลกรัม', code: 4 },
                { name: 'มากกว่า 1000 กิโลกรัม', code: 5 },
            ],
            isNotValid: false,
            showProgress: false,
            checkedRecalonly: false,
            checkedRecalonlyEdit: null,
            recalonlyAdd : 0,
        };
        this.onClickAddDeliveryCost = this.onClickAddDeliveryCost.bind(this);
        this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
        this.onValidation = this.onValidation.bind(this);
        this.onDistanceChange = this.onDistanceChange.bind(this);
        this.onWeightChange = this.onWeightChange.bind(this);
        this.clearAllDataInput = this.clearAllDataInput.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.onDistanceUpdate = this.onDistanceUpdate.bind(this);
        this.onWeightUpdate = this.onWeightUpdate.bind(this);
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div></div>);
    }
    componentDidMount() {
        this.getTransportFee();
        this.getTranspotWeightType();
        this.getTranspotDistanceType();
    }
    getTransportFee(){
        this.setState({ showProgress: true })
        ServerAPI.getTransportFee().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({deliveryCostList: res })
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }
    getTranspotDistanceType(){
        ServerAPI.getTranspotDistanceType().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({distanceList: res })
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }
    getTranspotWeightType(){
        ServerAPI.getTranspotWeightType().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({weightList: res })
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }
    onDistanceChange(e){
        console.log(e)
        this.setState({distanceObj: e.value});
        this.setState({distanceName: e.value.distance_name});
        this.setState({distanceGid: e.value.gid});
        this.setState({minDistance: e.value.more_than_km});
        this.setState({maxDistance: e.value.less_than_km});
    }
    onWeightChange(e){
        console.log(e)
        this.setState({weightObj: e.value});
        this.setState({weightName: e.value.weight_name});
        this.setState({weightGid: e.value.gid});
        this.setState({minWeight: e.value.more_than_kk});
        this.setState({maxWeight: e.value.less_than_kk});
    }
    onDistanceUpdate(e){
        console.log(e)
        this.setState({distanceObjUpdate: e.value});
        // this.setState({distance_type_name: e.value.distance_name});
        // this.setState({distance_type_id: e.value.gid});
        // this.setState({more_than_km: e.value.more_than_km});
        // this.setState({less_than_km: e.value.less_than_km});
    }
    onWeightUpdate(e){
        console.log(e)
        this.setState({weightObjUpdate: e.value});
        // this.setState({weight_type_name: e.value.weight_name});
        // this.setState({weight_type_id: e.value.gid});
        // this.setState({more_than_kk: e.value.more_than_kk});
        // this.setState({less_than_kk: e.value.less_than_kk});
    }
    actionTemplate(rowData, column) {
        return <div>
            {/* <Button type="button" onClick={() => this.onClickDeleteItem(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button> */}
            <Button type="button" onClick={() => this.onDeliveryCostUpdate(column.rowData)} icon="pi pi-pencil" className="p-button" ></Button>
            <Button type="button" onClick={() => this.onDeliveryCostDelete(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button>
        </div>;
    }
    onClickDeleteItem(rowData) {
        console.log(rowData)
        if (rowData !== '') {
            var id = rowData.gid
            ServerAPI.removeTransportFee(id).then((res) => {
                console.log(res);
                if (res) {
                    var index = res.toString().indexOf("Error:");
                    console.log("find error:" + (index));
                    if (index == -1) {
                        this.showSuccess();
                        this.getTransportFee();
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            }).catch((error) => {
                this.setState({ showProgress: false })
            })
        }else {
            this.showWarn();
            this.setState({ showProgress: false })
        }
        // if (index !== -1) {
        //     var array = this.state.deliveryCostList;
        //     array.splice(index, 1);
        //     this.setState({ deliveryCostList: array });
        // }
    }
    update() {
        this.setState({ showProgress: true })
        console.log(this.state.transportFee)
        if(this.state.transportFee){
            var isNotinput = (
                this.state.transportFee.fee_baht == '' ||
                this.state.isNotValid == true
            );
            if (isNotinput) {
                this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
                this.setState({ showProgress: false })
            } else {
                    // var notValid = false;
                    // this.state.deliveryCostList.forEach(objDeliveryCost => {
                    //     console.log(objDeliveryCost.distance_type_id);
                    //     console.log( this.state.distanceObjUpdate.gid);
                    //     console.log(objDeliveryCost.weight_type_id);
                    //     console.log(this.state.weightObjUpdate.gid);
                    //     if(objDeliveryCost.gid != this.state.transportFee.gid )
                    //     if(objDeliveryCost.distance_type_id == this.state.distanceObjUpdate.gid && 
                    //         objDeliveryCost.weight_type_id == this.state.weightObjUpdate.gid){
                    //         notValid = true;
                    //     }
                    // });
    
                    // if(notValid){
                    //     this.showWarn("ข้อมูลซ้ำ! กรุณาเลือกใหม่อีกครั้ง");
                    // }else{

                        var data = {
                            gid: this.state.transportFee.gid,
                            recalonly: this.state.checkedRecalonlyEdit,
                            // weight_type_id: this.state.weightObjUpdate.gid,
                            // weight_type_name: this.state.weightObjUpdate.weight_name,
                            // distance_type_id: this.state.distanceObjUpdate.gid,
                            // distance_type_name: this.state.distanceObjUpdate.distance_name,
                            // more_than_kk: this.state.weightObjUpdate.more_than_kk,
                            // less_than_kk: this.state.weightObjUpdate.less_than_kk,
                            // more_than_km: this.state.distanceObjUpdate.more_than_km,
                            // less_than_km: this.state.distanceObjUpdate.less_than_km,
                            fee_baht: parseFloat(this.state.transportFee.fee_baht),
                        }

                        ServerAPI.editTransportFee(data).then((res) => {
                            console.log(res);
                            if (res) {
                                var index = res.toString().indexOf("Error:");
                                console.log("find error:" + (index));
                                if (index == -1) {
                                    this.getTransportFee();
                                    this.setState({ displayDialog:false});
                                    this.clearAllDataInput();
                                    this.showSuccess();
                                } else {
                                    this.showWarn();
                                    this.setState({ showProgress: false })
                                }
                            } else {
                                this.showWarn();
                                this.setState({ showProgress: false })
                            }
                        }).catch((error) => {
                            this.setState({ showProgress: false })
                        })
                    
                }
        }
    }
    delete() {
        this.setState({ showProgress: true })
        var data = this.state.transportFee;
        this.onClickDeleteItem(data);
        // let index = this.findSelectedWeightIndex();
        // this.setState({
        //     cars: this.state.cars.filter((val,i) => i !== index),
        //     selectedCar: null,
        //     car: null,
        this.setState({deleteDialog: false});
    }
    updateProperty(property, value) {
        let transportFee = this.state.transportFee;
        transportFee[property] = value;
        this.setState({transportFee: transportFee});
    }

    onDeliveryCostUpdate(e){
        console.log('sdfsdfsdfsdfsdf',e)
        var distanceDropdown = {
            distance_type_id: e.distance_type_id,
            distance_type_name: e.distance_type_name,
            less_than_kk: e.less_than_kk,
            more_than_kk: e.more_than_kk,
        }
        var weightDropdown = {
            weight_type_id: e.weight_type_id,
            weight_type_name: e.weight_type_name,
            less_than_km: e.less_than_km,
            more_than_km: e.more_than_km,
        }
        
        this.setState({
            displayDialog:true,
            transportFee: Object.assign({}, e),
            distanceObjUpdate:  distanceDropdown,
            weightObjUpdate:  weightDropdown,
        });
        this.setState({checkedRecalonlyEdit : e.recalonly })
    }
    onDeliveryCostDelete(e){
        console.log(e)
        this.setState({
            deleteDialog:true,
            transportFee: Object.assign({}, e),
        });
    }
    onValidation(valid){
        console.log(valid)
        if(!valid) this.setState({isNotValid: true })
        else this.setState({isNotValid: false })
    }
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    onClickAddDeliveryCost(){
        this.setState({ showProgress: true })
        var isNotinput = (
            this.state.distanceGid == null||
            this.state.weightGid == null ||
            this.state.deliveryCost == '' ||
            this.state.isNotValid == true ||
            this.state.checkedRecalonly == null 
        );

        if (isNotinput) {
            this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
            this.setState({ showProgress: false })
        } else {
            var notValid = false;
            this.state.deliveryCostList.forEach(objDeliveryCost => {
                console.log(objDeliveryCost.distance_type_id);
                console.log(this.state.distanceGid);
                console.log(objDeliveryCost.weight_type_id);
                console.log(this.state.weightGid);
                if(objDeliveryCost.distance_type_id == this.state.distanceGid && objDeliveryCost.weight_type_id == this.state.weightGid){
                    notValid = true;
                }
            });

            if(notValid){
                this.showWarn("ข้อมูลซ้ำ! กรุณาเลือกใหม่อีกครั้ง");
                this.setState({ showProgress: false })
            }else{
                var deliveryCostList = {
                    // deliveryCost_code: '',
                    // distance: this.state.distanceName,
                    // weight: this.state.weightName,
                    // deliveryCost: parseFloat(this.state.deliveryCost).toFixed(2),
                    weight_type_id: this.state.weightGid,
                    weight_type_name: this.state.weightName,
                    distance_type_id: this.state.distanceGid,
                    distance_type_name: this.state.distanceName,
                    more_than_kk: this.state.minWeight,
                    less_than_kk: this.state.maxWeight,
                    more_than_km: this.state.minDistance,
                    less_than_km: this.state.maxDistance,
                    recalonly: this.state.checkedRecalonly,
                    fee_baht: parseFloat(this.state.deliveryCost),

                }
                console.log(deliveryCostList)
                //this.state.deliveryCostList.push(deliveryCostList)

                ServerAPI.AddTransportFee(deliveryCostList).then((res) => {
                    console.log(res);
                    if (res) {
                        var index = res.toString().indexOf("Error:");
                        console.log("find error:" + (index));
                        if (index == -1) {
                
                            this.showSuccess();
                            this.getTransportFee();
                            this.clearAllDataInput();
                            
                        } else {
                            this.showWarn();
                            this.setState({ showProgress: false })
                        }
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                }).catch((error) => {
                    this.setState({ showProgress: false })
                })
                
            }
        }
        console.log(this.state.deliveryCostList)
    }
    clearAllDataInput(){
        this.setState({ distanceGid: null })
        this.setState({ weightGid: null })
        this.setState({ distanceName: null })
        this.setState({ weightName: null })
        
        
        this.setState({ minWeight: null })
        this.setState({ maxWeight: null })
        this.setState({ minDistance: null })
        this.setState({ maxDistance: null })

        this.setState({ distanceObj: null })
        this.setState({ weightObj: null })

        this.setState({ deliveryCost: '' })
        this.setState({ isNotValid: false })

        this.setState({ transportFee: null })
        this.setState({ distanceObjUpdate: null })
        this.setState({ weightObjUpdate: null })
    }


    
    showAddDeliveryCost(){
        const newWeight = (
            <div className="p-col-12" >
                    <Accordion multiple={true} >
                        <AccordionTab header="เพิ่มค่าขนส่งใหม่">
                            <div className="p-grid">
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>ระยะทาง :</label>
                            </div>
                            <div className="p-col-12 p-md-10 p-lg-11">
                            <Dropdown
                                style={{width:'20%'}}
                                optionLabel="distance_name"
                                placeholder="เลือกระยะทาง"
                                value={this.state.distanceObj}
                                options={this.state.distanceList}
                                onChange={this.onDistanceChange}
                                />
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>น้ำหนัก :</label>
                            </div>
                            <div className="p-col-12 p-md-10 p-lg-11">
                            <Dropdown
                                style={{width:'20%'}}
                                optionLabel="weight_name"  
                                placeholder="เลือกน้ำหนัก"
                                value={this.state.weightObj}
                                options={this.state.weightList}
                                onChange={this.onWeightChange}
                                />
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center'}}>
                                <label>ค่าขนส่ง :</label>
                            </div>
                            <div className="p-col-12 p-md-10 p-lg-11" style={{ alignSelf: 'center' }}>
                            <CEInputText   
                                id="deliveryCost"                        
                                type="money"
                                value={this.state.deliveryCost}
                                name="deliveryCost"
                                placeholder="00.00"
                                tooltip="กรอกค่าขนส่ง (ทศนิยม 2 ตำแหน่ง)"
                                required={true}
                                isvalid={this.onValidation}
                                onChange={(e)=> this.setState({deliveryCost: e.target.value})}
                                />
                                <label style={{marginLeft: '10px'}}>บาท</label>
                            </div>
                            <div className="p-col-12 p-md-10 p-lg-11" style={{ alignSelf: 'center' }}>
                                    <Checkbox inputId="recalonlyId" 
                                              checked={this.state.checkedRecalonly} 
                                              onChange={ (e) => this.setState({checkedRecalonly : e.checked})} />
                                    <label htmlFor="recalonlyId">ใช้ในกรณีที่น้ำหนักหลุดไปจากตารางคำนวณ</label>
                                {console.log('หลัง',this.state.checkedRecalonly)}
                            </div>
                            <div className="p-col-12">
                                <Button
                                    type="submit"
                                    label="เพิ่มค่าขนส่ง"
                                    icon="pi pi-plus"
                                    iconPos="left"
                                    onClick={this.onClickAddDeliveryCost}
                                />
                            </div>
                            
                            </div>
                        </AccordionTab>
                        
                    </Accordion>
            </div>
        );
        return newWeight;
    }

    showDeliveryCostTable(){
        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="บันทึก" icon="pi pi-check" onClick={this.update}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displayDialog: false})} className="p-button-warning"/>
            </div>;
        let deleteDialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ใช่" icon="pi pi-check" onClick={this.delete}/>
            <Button label="ไม่ใช่" icon="pi pi-times" onClick={() => this.setState({deleteDialog: false})} className="p-button-warning"/>
            </div>;
        const deliveryCostTable = (
            <div className="p-col-12">
                <Accordion multiple={true} activeIndex={[0, 1]}>
                    <AccordionTab header="รายการระยะทาง">
                        <Panel header="รายการค่าขนส่ง" >
                            <DataTable value={this.state.deliveryCostList} paginatorPosition="bottom" paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                                responsive={true} 
                                emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                                <label>ไม่พบข้อมูล</label>
                                </div>)}>
                                <Column field="distance_type_name" header="ระยะทาง (กม.)" sortable={true} filter={true} />
                                <Column field="weight_type_name" header="น้ำหนัก (กก.)" sortable={true} filter={true} />
                                <Column field="fee_baht" header="ค่าขนส่ง (บาท)" sortable={true} filter={true} className="p-text-center"/>
                                <Column field="gid" body={this.actionTemplate.bind(this)} header="จัดการ" className="p-text-center" style={{ width: '8em' }} />
                            </DataTable>
                        </Panel>
                    </AccordionTab>
                </Accordion>
                    <Dialog visible={this.state.deleteDialog} style={{width: '40vw'}} header="ยืนยันการลบข้อมูล" modal={true} footer={deleteDialogFooter} onHide={() => this.setState({deleteDialog: false})}>
                        {
                            this.state.transportFee &&
                            
                            <div className="p-grid p-fluid" >
                                <div className="p-col-12"><span>คุณต้องการจะลบข้อมูลต่อไปนี้</span></div>
                                <div className="p-col-4" ><label htmlFor="distance_type_name">ระยะทาง :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.transportFee.distance_type_name}</strong>
                                </div>

                                <div className="p-col-4" ><label htmlFor="weight_type_name">น้ำหนัก :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.transportFee.weight_type_name}</strong>
                                </div>

                                <div className="p-col-4" ><label htmlFor="fee_baht">ค่าขนส่ง (บาท) :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.transportFee.fee_baht}</strong>
                                </div>
                            </div>
                        }
                    </Dialog>
                    <Dialog visible={this.state.displayDialog} style={{width: '40vw'}} header="แก้ไขข้อมูล" modal={true} footer={dialogFooter} onHide={() => this.setState({displayDialog: false})}>
                        {
                            this.state.transportFee &&
                            
                            <div className="p-grid p-fluid" >
                                
                                <div className="p-col-4" style={{ alignSelf: 'center' }}><label htmlFor="distance_type_name">ระยะทาง :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.transportFee.distance_type_name}</strong>
                                </div>

                                <div className="p-col-4" style={{ alignSelf: 'center' }}><label htmlFor="weight_type_name">น้ำหนัก :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.transportFee.weight_type_name}</strong>
                                </div>

                                <div className="p-col-4" style={{ alignSelf: 'center' }}><label htmlFor="fee_baht">ค่าขนส่ง (บาท) :</label></div>
                                <div className="p-col-8" style={{ padding: '0em' }}>
                                    <CEInputText   
                                        id="fee_baht"                        
                                        type="money"
                                        value={this.state.transportFee.fee_baht}
                                        name="fee_baht"
                                        placeholder="00.00"
                                        tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                        required={true}
                                        isvalid={this.onValidation}
                                        onChange={(e) => {this.updateProperty('fee_baht', e.target.value)}}
                                        />
                                    
                                </div>
                                <div className="p-col-12">
                                    <Checkbox inputId="recalonly" 
                                          checked={this.state.checkedRecalonlyEdit} 
                                          onChange={ (e) => this.setState({checkedRecalonlyEdit : e.checked})} />
                                    <label htmlFor="recalonly">ใช้ในกรณีที่น้ำหนักหลุดไปจากตารางคำนวณ</label>
                                </div>
                                

                            
                            </div>
                        }
                    </Dialog>
            </div>
        );
        if(!this.state.showProgress)
        return deliveryCostTable;
    }
    

    render() {
        const addDeliveryCostPanel = this.showAddDeliveryCost();
        const deliveryCostTablePanel = this.showDeliveryCostTable();
        const showProgress = this.showProgressSpinner();
        return <div className="p-grid ">
            <Growl ref={(el) => this.growl = el} />
                <div className="p-col-12">
                        <h1>จัดการค่าขนส่ง</h1>
                    </div>

                    {addDeliveryCostPanel}
                    {showProgress}
                    {deliveryCostTablePanel}

        </div>
    }
}



const mapStateToProps = (state) => ({
    dhbUsers: state.dhbAdmin.dhbUsers,
    locationMaster: state.location,
    provinces: state.location.province,
    districts: state.location.district,
    subDistricts: state.location.subDistrict,
    cardProvince: state.location.cardProvince,
    cardDistrict: state.location.cardDistrict,
    cardSubDistrict: state.location.cardSubDistrict,
    authentication: state.authentication,
    userInfo: state.userInfo.data,
    userInfoMaster: state.userInfo,
    updateWasteproProfile: state.updateWasteproProfile,
    banks: state.master.banks,
    productType: state.master.productType,
    product: state.master.product,
    getFileWastepro: state.getFileWastepro,
})

const mapDispatchToProps = dispatch => ({

    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    fileServiceProps: bindActionCreators(FileService, dispatch),

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ManageDeliveryCost)