import React, { Component } from "react";
import { DataTable } from "primereact/components/datatable/DataTable";
import { Column } from "primereact/components/column/Column";
import { Growl } from "primereact/growl";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import DonateHeaderView from "../donate/DonateHeaderView";
import { Panel } from "primereact/panel";
import { Validators } from "../../utils";

import {
  getBalance,
  getWithdrawHistory,
  saveWithdrawal,
  getWastegenDonate,
  wastegenTransferDonate,
} from "../../service/wastegen.service";
import { Dialog } from "primereact/dialog";

class WastegenDonate extends Component {
  constructor(props) {
    console.log("ExampleWastegenWithdrawCtrl :constructor");
    super(props);
    this.state = {
      //save
      isSave: false,
      //Validate require
      isValidateError: false,
      classNameRequired: {},

      //DonateHeaderView
      withdrawAmount: 0,
      unApproveAmount: 0,
      input_amount: null,
      input_amount_required: {
        valid: true,
        className: "",
        message: "",
      },
      bank_withdraw_code: null,
      bank_withdraw_code_required: {
        valid: true,
        className: "",
        message: "",
      },
      data: [],
      visible: true,
      displayConfirmation: false,
      titleDialog: "",
    };

    this.validateRequired = {};

    //Event : original input text
    this.onChangeInputText = this.onChangeInputText.bind(this);
    //Event : withdraw
    this.onClickNotiMoney = this.onClickNotiMoney.bind(this);

    this.showDialogDonate = this.showDialogDonate.bind(this);
    this.onHide = this.onHide.bind(this);
  }

  componentDidMount() {
    console.log("ExampleWastegenWithdrawCtrl :componentDidMount");
    this.props.dispatch(getBalance());
    this.props.dispatch(getWithdrawHistory());
    this.props.dispatch(getWastegenDonate());
  }

  //>Life Cycle : Re-render / Update
  componentWillReceiveProps(nextProps) {
    console.log("ExampleWastegenWithdrawCtrl :componentWillReceiveProps");
    if (this.state.isSave) {
      if (!nextProps.wastegenWithdraw.isLoading) {
        if (!nextProps.wastegenWithdraw.isFailed) {
          this.props.dispatch(getBalance());
          this.props.dispatch(getWithdrawHistory());
          this.props.dispatch(getWastegenDonate());
          this.setState({ isSave: false });
          this._validateRequired("bank_withdraw_code_required", true);
          this.setState({ input_amount: "" });
          this.setState({ bank_withdraw_code: "" });
        }
        if (nextProps.wastegenWithdraw.isFailed) {
          this.showError(nextProps.wastegenWithdraw.data);
          this._validateRequired(
            "bank_withdraw_code_required",
            false,
            nextProps.wastegenWithdraw.data
          );
          this.setState({ isSave: false });
        }
      }
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    console.log("ExampleWastegenWithdrawCtrl :shouldComponentUpdate");
    return true;
  }
  componentWillUpdate(nextProps, nextState) {
    console.log("ExampleWastegenWithdrawCtrl :componentWillUpdate");
  }
  componentDidUpdate(prevProps, prevState) {
    console.log(
      "ExampleWastegenWithdrawCtrl :componentDidUpdate",
      this.state,
      " ,",
      prevState
    );

    if(this.props.wastegenTransfer.data == 'รหัสถอนเงินไม่ถูกต้อง' && !this.props.wastegenTransfer.isLoading){
      this.showWarn('รหัสถอนเงินไม่ถูกต้อง')
      this.props.dispatch({ type: 'WASTEGEN_TRANSFER_DONATE_REQUEST', payload: true })
    }

    if(this.props.wastegenTransfer.data == 'เกิดข้อผิดพลาด ไม่สามารถถอดเงินได้' && !this.props.wastegenTransfer.isLoading){
      this.showWarn('เกิดข้อผิดพลาด ไม่สามารถถอดเงินได้')
      this.props.dispatch({ type: 'WASTETRAN_TRANSFER_DONATE_REQUEST', payload: true })
    }
    
    if(this.props.wastegenTransfer.data === true && !this.props.wastegenTransfer.isLoading){
      this.showSuccess()
      this.props.dispatch({ type: 'WASTEGEN_TRANSFER_DONATE_REQUEST', payload: true })
    }

    if (prevProps.wastegenBalance.data != this.props.wastegenBalance.data) {
      if (
        this.props.wastegenBalance.data &&
        this.props.wastegenBalance.data.outstanding_balance_thb
      ) {
        this.setState({
          withdrawAmount: this.props.wastegenBalance.data.outstanding_balance_thb.toFixed(
            2
          ),
        });
        this.setState({
          unApproveAmount: this.props.wastegenBalance.data.withdraw_balance_thb.toFixed(
            2
          ),
        });
      }
      console.log(`print state ${this.state}`);
    }
  }
  // Initial function
  initial = (object) => {
    if (object) {
      for (let key in object) {
        let val = object[key];
        this.setState({ [key]: val });
      }
    }
  };
  //> Submit
  onSubmit = () => {
    this.showSuccess();
  };
  //> Event
  onChangeInputText = (e) => {
    const { name, value, required } = e.target;
    this.setState({ [name]: value });
    if (required) {
      let classNamed = [name] + "_required";
      if ([name] == "input_amount") {
        if (Number(value) > Number(this.state.withdrawAmount)) {
          this._validateRequired(
            classNamed,
            false,
            "ยอดทั้งหมดที่ถอนได้ไม่พอสำหรับแจ้งถอน"
          );
        } else {
          this._validateRequired(classNamed, true);
        }
        if (Validators.isEmpty(this.state.bank_withdraw_code)) {
          this._validateRequired(
            "bank_withdraw_code_required",
            false,
            "กรุณาระบุรหัสสำหรับแจ้งถอนเงิน"
          );
        } else {
          this._validateRequired("bank_withdraw_code_required", true);
        }
      }
      if ([name] == "bank_withdraw_code") {
        if (Validators.isEmpty(value)) {
          this._validateRequired(
            classNamed,
            false,
            "กรุณาระบุรหัสสำหรับแจ้งถอนเงิน"
          );
        } else {
          this._validateRequired(classNamed, true);
        }
      }
    }
  };
  onClickNotiMoney = (e) => {
    const { input_amount, withdrawAmount, bank_withdraw_code } = this.state;
    if (
      parseFloat(input_amount) &&
      parseFloat(input_amount) > 0 &&
      Validators.isNotEmpty(this.state.bank_withdraw_code)
    ) {
      if (parseFloat(input_amount) > parseFloat(withdrawAmount)) {
        this.showWarn("ยอดทั้งหมดที่ถอนได้ไม่พอสำหรับแจ้งถอน");
        this._classNameRequiredIsError("input_amount_required", true);
      } else {
        this.setState({ isSave: true });
        this.onHide("displayConfirmation");

        var donateData = {
          waste_uid: this.props.userInfo.uid,
          withdrawal_thb: parseFloat(input_amount),
          bank_withdraw_code: this.state.bank_withdraw_code,
          wasteType: 1,
          org_uid: this.state.orgSelection,
        };

        console.log(donateData);

        this.props.dispatch(wastegenTransferDonate(donateData))
      }
    } else {
      this.showWarn("ไม่สามารถทำรายการได้ กรุณาตรวจสอบ");
      if (Validators.isEmpty(this.state.input_amount)) {
        this._validateRequired(
          "input_amount_required",
          false,
          "กรุณาระบุยอดเงินที่ต้องการถอน"
        );
      }
      if (Validators.isEmpty(this.state.bank_withdraw_code)) {
        this._validateRequired(
          "bank_withdraw_code_required",
          false,
          "กรุณาระบุรหัสสำหรับแจ้งถอนเงิน"
        );
      }
    }
  };

  // ======= Validate ======
  validateAllRequired = (nextState) => {
    if (nextState.input_amount) {
      let classNamed = "input_amount_required";
      if (Number(nextState.input_amount) > Number(this.state.withdrawAmount)) {
        this._validateRequired(
          classNamed,
          false,
          "ยอดทั้งหมดที่ถอนได้ไม่พอสำหรับแจ้งถอน"
        );
      } else {
        this._validateRequired(classNamed, true);
      }
    }
  };
  _validateLogic = (name, value) => {
    if (Validators.isEmpty(value)) {
      this._classNameRequiredIsError(name, true);
    }
  };
  _classNameRequiredIsError = (classNamed, status) => {
    if (!classNamed) {
      if (status) {
        this.showWarn();
      }
    } else {
      if (status) {
        this.setState((pv) => ({
          classNameRequired: {
            ...pv.classNameRequired,
            [classNamed]: "p-error",
          },
        }));
      } else {
        this.setState((pv) => ({
          classNameRequired: {
            ...pv.classNameRequired,
            [classNamed]: null,
          },
        }));
      }
    }
  };
  _validateRequired = (className, valid, message) => {
    message = message || "";
    this.setState({
      [className]: {
        valid: valid,
        className: valid ? "" : "p-error",
        message: message,
      },
    });
  };
  // ======= SHOW MESSAGE BOX ======
  showSuccess = (message) => {
    this.growl.show({
      severity: "success",
      summary: "สถานะ",
      detail: message ? message : "ทำรายการสำเร็จ",
    });
  };
  showError = (message) => {
    this.growl.show({
      severity: "error",
      summary: "คำเตือน",
      detail: message ? message : "เกิดข้อผิดพลาด",
    });
  };
  showWarn = (message) => {
    this.growl.show({
      severity: "warn",
      summary: "คำเตือน",
      detail: message
        ? message
        : "กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน",
    });
  };

  showDialogDonate(name, e) {
    let state = {
      [`${name}`]: true,
    };

    if (e) {
      state = {
        ...state,
        e,
      };
    }
    this.setState({ orgSelection: e.uid });
    this.setState(state);
  }

  onHide(name) {
    this.setState({
      [`${name}`]: false,
    });
  }

  renderFooter = (name) => {
    return <div />;
  };

  onopenContectDetail(e) {
    console.log(e);
    this.onHide("displayConfirmation");
    this.setState({
      contactDetailBodyDialog: true,
      orgSelected: Object.assign({}, e),
    });
  }
  onopenAddressDetail(e) {
    console.log(e);
    this.onHide("displayConfirmation");
    this.setState({
      addressDetailBodyDialog: true,
      orgSelected: Object.assign({}, e),
    });
  }

  openContectDetail(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          onClick={() => this.onopenContectDetail(column.rowData)}
          icon="fa fa-address-book"
          label=""
          className="p-button-outlined"
        />
      </div>
    );
  }
  openAddressDetail(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          onClick={() => this.onopenAddressDetail(column.rowData)}
          icon="fa fa-map-marker"
          label=""
          className="p-button"
        />
      </div>
    );
  }
  openDonateDialog(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          onClick={() => this.showDialogDonate('displayConfirmation',rowData)}
          label="บริจาค"
        />
      </div>
    );
  }

  render() {
    console.log("ExampleWastegenWithdrawCtrl :render", this.state);
    let itemOrg = [];
    if (this.props.wastegenDonate && !this.props.wastegenDonate.isLoading) {
      if (!this.props.wastegenDonate.isFailed) {
        for (let key in this.props.wastegenDonate.data) {
          let entity = this.props.wastegenDonate.data[key];
          let tmpData = {
            org_name: entity.org_name,
            bank_account_name: entity.bank_account_name,
            bank_account_no: entity.bank_account_no,
            bank_name: entity.bank_name,
            contract_detail: "",
            addr_current_detail: "",
            uid: entity.uid,
            telephone_no1: entity.telephone_no1,
            telephone_no2: entity.telephone_no2,
            email: entity.email,
            addr_current_province_name: entity.addr_current_province_name,
            add_current_district_name: entity.add_current_district_name,
            addr_current_subdistrict_name: entity.addr_current_subdistrict_name,
            addr_current_detail: entity.addr_current_detail,
          };
          itemOrg.push(tmpData);
        }
      }
    }
    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} />
        <h1>บริจาค</h1>
        <div className="p-grid">
          <div className="p-col-12">
            <Panel header="เลือกองค์กรเพื่อบริจาค">
              <DataTable
                paginator={true}
                rows={5}
                rowsPerPageOptions={[5, 10, 15]}
                value={itemOrg}
                selection={this.state.orgSelection}
                onSelectionChange={(e) =>
                  this.onClick("displayConfirmation", e)
                }
                dataKey="id"
                responsive={true}
              >
                <Column field="org_name" header="ชื่อองค์กร" />
                <Column field="bank_account_name" header="ชื่อบัญชี" />
                <Column field="bank_account_no" header="เลขบัญชี" />
                <Column field="bank_name" header="ธนาคาร" />
                <Column
                  field="environmental_account_no1"
                  body={this.openContectDetail.bind(this)}
                  header="ข้อมูลติดต่อ"
                  sortable={true}
                  className="p-text-center" style={{ width: "8em" }}
                />
                <Column
                  field="environmental_account_no2"
                  body={this.openAddressDetail.bind(this)}
                  header="ข้อมูลที่อยู่"
                  sortable={true}
                  className="p-text-center" style={{ width: "8em" }}
                />
                <Column
                  field="environmental_account_no2"
                  body={this.openDonateDialog.bind(this)}
                  header="บริจาค"
                  className="p-text-center" style={{ width: "8em" }}
                />
              </DataTable>

              {/* <DataTable
                value={itemOrg}
                paginatorPosition="bottom"
                selectionMode="single"
                paginator={true}
                rows={5}
                rowsPerPageOptions={[5, 10, 15]}
                responsive={true}
                selection={this.state.orgSelection}
                onSelectionChange={(e) =>
                  this.onClick("displayConfirmation", e)
                }
              >
                <Column field="org_name" header="ชื่อองค์กร" sortable={true} />
                <Column
                  field="bank_account_name"
                  header="ชื่อบัญชี"
                  sortable={true}
                />
                <Column
                  field="bank_account_no"
                  header="เลขบัญชี"
                  sortable={true}
                />
                <Column field="bank_name" header="ธนาคาร" sortable={true} />
                <Column
                  field="environmental_account_no1"
                  body={this.openContectDetail.bind(this)}
                  header="ข้อมูลติดต่อ"
                  sortable={true}
                  style={{ width: "8em", textAlign: "center" }}
                />
                <Column
                  field="environmental_account_no1"
                  body={this.openAddressDetail.bind(this)}
                  header="ข้อมูลที่อยู่"
                  sortable={true}
                  style={{ width: "8em", textAlign: "center" }}
                />
              </DataTable>*/}
            </Panel>
            <Dialog
              header={`บริจาคใหักับองค์กร`}
              visible={this.state.displayConfirmation}
              modal
              style={{ width: "700px" }}
              onHide={() => this.onHide("displayConfirmation")}
            >
              <div>
                <DonateHeaderView
                  input_amount={this.state.input_amount}
                  input_amount_required={this.state.input_amount_required}
                  bank_withdraw_code={this.state.bank_withdraw_code}
                  bank_withdraw_code_required={
                    this.state.bank_withdraw_code_required
                  }
                  classNameRequired={this.state.classNameRequired}
                  withdrawAmount={this.state.withdrawAmount}
                  unApproveAmount={this.state.unApproveAmount}
                  onChangeInputText={this.onChangeInputText}
                  onClickNotiMoney={this.onClickNotiMoney}
                />
              </div>
            </Dialog>

            <Dialog
              visible={this.state.contactDetailBodyDialog}
              style={{ width: "50vw" }}
              header="ข้อมูลการติดต่อ"
              modal={true}
              onHide={() => this.setState({ contactDetailBodyDialog: false })}
              dismissableMask={true}
            >
              {this.state.orgSelected && (
                <div className="p-grid p-fluid">
                  <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="telephone_no1">
                  หมายเลขโทรศัพท์ 1 :
                </label>
              </div>
              <div className="p-col-9">
                <strong>{this.state.orgSelected.telephone_no1}</strong>
              </div>
              <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="telephone_no2">
                  หมายเลขโทรศัพท์ 2 :
                </label>
              </div>
              <div className="p-col-9">
                <strong>{this.state.orgSelected.telephone_no2}</strong>
              </div>
              <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="email">
                   อีเมล :
                </label>
              </div>
              <div className="p-col-9">
                <strong>{this.state.orgSelected.email}</strong>
              </div>
                </div>
              )}
            </Dialog>

            <Dialog
              visible={this.state.addressDetailBodyDialog}
              style={{ width: "50vw" }}
              header="ข้อมูลที่อยู่"
              modal={true}
              onHide={() => this.setState({ addressDetailBodyDialog: false })}
              dismissableMask={true}
            >
              {this.state.orgSelected && (
                <div className="p-grid p-fluid">
                  {/* <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="location_name">
                  สถานที่ตั้งองค์กร :
                </label>
              </div>
              <div className="p-col-9">
                <strong>{this.state.orgSelected.location_name}</strong>
              </div> */}
              <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="telephone_no1">
                   จังหวัด :
                </label>
              </div>
              <div className="p-col-9">
              <strong>{this.state.orgSelected.addr_current_province_name}</strong>
              </div>
              <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="telephone_no2">
                  อำเภอ :
                </label>
              </div>
              <div className="p-col-9">
              <strong>{this.state.orgSelected.add_current_district_name}</strong>
              </div>
              <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="telephone_no2">
                  ตำบล :
                </label>
              </div>
              <div className="p-col-9">
              <strong>{this.state.orgSelected.addr_current_subdistrict_name}</strong>
              </div>
              <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="email">
                   บ้านเลขที่/ที่อยู่ :
                </label>
              </div>
              <div className="p-col-9">
              <strong>{this.state.orgSelected.addr_current_detail}</strong>
              </div>
                </div>
              )}
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userAnnounce: state.userAnnounce,
  getWastegenDashLastOrder: state.getWastegenDashLastOrder,
  getWastegenDashProduct: state.getWastegenDashProduct,
  wastegenBalance: state.wastegenBalance,
  wastegenWithdraw: state.wastegenWithdraw,
  wastegenWithdrawHistory: state.wastegenWithdrawHistory,
  wastegenDonate: state.wastegenDonate,
  wastegenTransfer: state.wastegenTransfer,
  userInfo: state.userInfo.data,
});
export default connect(mapStateToProps)(WastegenDonate);
