
export default class AuthenService {
    accessKey = 'access-token';

    // กำหนดค่า access token ไว้ในความจำ browser
    setAuthenticated(accessToken) {
        localStorage.setItem(this.accessKey, accessToken);
    }

    // ดึงค่า access token ในความจำ browser ออกมา
    getAuthenticated() {
        return localStorage.getItem(this.accessKey)
    }

    // ลบค่า access token ที่อยู่ในความจำ browser
    clearAuthenticated() {
        localStorage.removeItem(this.accessKey);
        localStorage.removeItem('user-type');
    }

}