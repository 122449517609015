import React from 'react'
import { Panel } from 'primereact/panel'
import { InputText } from 'primereact/inputtext'
import { Message } from 'primereact/message'
import { Button } from 'primereact/button'
import { Checkbox } from 'primereact/checkbox'
import { Validators } from '../../utils'

export const ChangePinView = ({
    mode,
    isShow,
    onClickShow,
    bank_withdraw_code,
    isValidBankWithDrawCode,
    onChangePin,
    password,
    isValidPassword,
    onChangePassword,
    onClickSubmitPin,
    isPassPinVisible,
    onClickVisible
}) => {
    //> Default Parameters
    bank_withdraw_code = bank_withdraw_code || ""
    password = password || ''
    //>default mode
    /* mode=1 : Have a pin
    *   mode=2 : No have a pin
    */
    mode = mode || 1

    let type = !isPassPinVisible ? "password" : "text"

    return (
                    <Panel header="รหัสเลขใช้สำหรับแจ้งถอนเงิน" >
                        <di className="p-col-12">
                            <label htmlFor="pin_num">
                                รหัสเลขใช้สำหรับแจ้งถอนเงิน
                        </label>
                            <InputText
                                type="number"
                                id="pin_num"
                                style={{marginBottom:'14px'}}
                                className={isValidBankWithDrawCode?'':'p-error'}
                                placeholder="Pin Number"
                                name="inputPin"
                                maxLength={100}
                                onKeyPress={Validators.handleNumber}
                                value={bank_withdraw_code}
                                onChange={(e) => onChangePin(e)}>
                            </InputText>
                            {!isValidBankWithDrawCode&&
                                <Message severity="error" text="กรุณาระบุรหัสเลขใช้สำหรับแจ้งถอนเงิน" />
                            }
                        </di>
                        <di className="p-col-12">
                            <label htmlFor="password">
                                รหัสผ่าน
                        </label>
                            <div className="p-inputgroup">
                                <InputText
                                    required={true}
                                    name="passwordPin"
                                    type={type}
                                    className={isValidPassword ? '' : 'p-error'}
                                    placeholder="Password"
                                    autoComplete="new-password"
                                    value={password}
                                    onChange={(e) => onChangePassword(e)}
                                ></InputText>
                                <Button
                                    style={{ height: '100%' }}
                                    icon={isPassPinVisible ? "fa fa-eye-slash" : "fa fa-eye"}
                                    className="p-col-12 p-button p-button-secondary"
                                    value={isPassPinVisible}
                                    onClick={(e) => onClickVisible(e)}
                                />
                            </div>

                            {
                                !isValidPassword &&
                                <Message severity="error" text="กรุณาระบุรหัสผ่านให้ถูกต้อง" />
                            }
                        </di>

                        <div className="p-col-4">
                            <Button label="ยืนยันการเปลี่ยนรหัสถอนเงิน"
                                onClick={(e) => onClickSubmitPin(e)}
                            >
                            </Button>
                        </div>

                    </Panel>
            
    )

}