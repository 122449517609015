
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';

export const DatatableRankView = ({listRanks,year,contentRank}) => {

    return (
        <Panel header={(<div>{contentRank}</div>)}>
            <DataTable value={listRanks} responsive={true} className="p-text-center" 
            emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)}>
                <Column field="col1" header="ลำดับ" style={{width:'70px'}}/>
                <Column field="col2" header="เดือน" />
                <Column field="col3" header="จังหวัด" />
                <Column field="col4" header="อำเภอ/เขต" />
                <Column field="col5" header="ตำบล/แขวง" />
                <Column className="p-text-right" style={{width:'80px'}} field="col6" header="มูลค่าขาย (บาท)" />
                <Column className="p-text-right" style={{width:'90px'}} field="col7" header="ปริมาณรวม (kg.)" />
            </DataTable>
        </Panel>
    )
}