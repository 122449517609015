//config for API Developer
// export const API_ROOT = 'http://localhost:88/api'
// export const WEB_ROOT = 'http://localhost:88';
// export const WEBSOCKET_ROOT = 'ws://localhost:88/ws'
//config for UAT/Production
export const API_ROOT = 'https://c3leng.com/api'
export const WEB_ROOT = 'https://c3leng.com'
export const WEBSOCKET_ROOT = 'wss://c3leng.com/ws'
//Config for UAT
// export const API_ROOT = 'https://c3leng-uat.canadev.net/api'
// export const WEB_ROOT = 'https://c3leng-uat.canadev.net'
// export const WEBSOCKET_ROOT = 'wss://c3leng-api-uat.canadev.net/ws'
//Config for Developer
// export const API_ROOT = 'https://c3leng-dev.canadev.net/api'
// export const WEB_ROOT = 'https://c3leng-dev.canadev.net'
// export const WEBSOCKET_ROOT = 'wss://c3leng-dev.canadev.net/ws'
// key 'prod' use for production or localhost and key 'dev' use for development server
export const TYPE_ROOT = 'prod'
