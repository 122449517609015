import React from 'react';

import { Panel } from 'primereact/panel';
import {status, getStatus} from '../../constants/order-status'
import { dateFormat } from '../../utils/utilFunctions';

export const StatisticView = ({
    header,
    waitDate,waitOrder,
    waitSendDate,waitSendOrder,
    closeDate,closeOrder,
    cancelDate,cancelOrder,
    overDueDate, overDueOrder
}) => {
    waitDate = waitDate||new Date("0001-01-01T00:00:00")
    waitSendDate = waitSendDate||new Date("0001-01-01T00:00:00")
    closeDate = closeDate||new Date("0001-01-01T00:00:00")
    cancelDate = cancelDate||new Date("0001-01-01T00:00:00")
    overDueDate = overDueDate||new Date("0001-01-01T00:00:00")
    waitOrder = waitOrder||0
    waitSendOrder = waitSendOrder||0
    closeOrder = closeOrder||0
    cancelOrder = cancelOrder||0
    overDueOrder = overDueOrder||0

    return (
        <div className="p-grid product-statistics">
            <div className="p-col-12">
                <Panel header={header?header:"สถิติรับงาน"}>
                    <table>
                        <tbody>
                            <tr>
                                <td className="col-overview col-wait">
                                    <div>W</div>
                                </td>
                                <td className="col-status">
                                    <span className="status-text">{getStatus(2).name}</span>
                                    <span className="status-time">Last Update: <br/>{dateFormat(waitDate)}</span>
                                </td>
                                <td className="col-numbers">
                                    <div>{waitOrder} <span>Orders</span></div>
                                </td>
                            </tr>
                            <tr>
                                <td className="col-overview col-success">
                                    <div>S</div>
                                </td>
                                <td className="col-status">
                                    <span className="status-text">{getStatus(3).name}</span>
                                    <span className="status-time">Last Update: <br/>{dateFormat(waitSendDate)}</span>
                                </td>
                                <td className="col-numbers">
                                    <div>{waitSendOrder} <span>Orders</span></div>
                                </td>
                            </tr>
                            <tr>
                                <td className="col-overview col-delay">
                                    <div>D</div>
                                </td>
                                <td className="col-status">
                                    <span className="status-text">{getStatus(88).name}</span>
                                    <span className="status-time">Last Update: <br/>{dateFormat(closeDate)}</span>
                                </td>
                                <td className="col-numbers">
                                    <div>{closeOrder} <span>Orders</span></div>
                                </td>
                            </tr>
                            <tr>
                                <td className="col-overview col-preorder">
                                    <div>P</div>
                                </td>
                                <td className="col-status">
                                    <span className="status-text">{getStatus(99).name}</span>
                                    <span className="status-time">Last Update: <br/>{dateFormat(cancelDate)}</span>
                                </td>
                                <td className="col-numbers">
                                    <div>{cancelOrder} <span>Orders</span></div>
                                </td>
                            </tr>
                            <tr>
                                <td className="col-overview col-wait">
                                    <div>O</div>
                                </td>
                                <td className="col-status">
                                    <span className="status-text">{getStatus(5).name}</span>
                                    <span className="status-time">Last Update: <br/>{dateFormat(overDueDate)}</span>
                                </td>
                                <td className="col-numbers">
                                    <div>{waitOrder} <span>Orders</span></div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </Panel>
            </div>
        </div>
    )

}