

import React, { Component } from 'react';
import { connect } from 'react-redux';

import {InputText} from 'primereact/inputtext'
import {Dropdown} from 'primereact/dropdown'

import {Validators} from '../../utils'

class BankInfoView extends Component {

    state = {

    }

    //>Initial 
    componentDidMount() {
        //console.log("BankInfoView :componentDidMount")
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        //console.log("BankInfoView :componentWillReceiveProps")
    }

    shouldComponentUpdate(nextProps, nextState) {
        //console.log("BankInfoView :shouldComponentUpdate")
        return true
    }

    componentWillUpdate(nextProps, nextState) {
        //console.log("BankInfoView :componentWillUpdate")

    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("BankInfoView :componentDidUpdate")

    }

    //> Event
    onChangeInputText = (e) => {
        this.props.onChangeInputText(e);
    }
    onChanageBank = (e) =>{
        this.props.onChanageBank(e);
    }

    //> Mode render
    renderWastegen = () => {
        return (
            <div></div>
        )
    }
    renderWastetran = () => {
        return (
            <div></div>
        )
    }
    renderWastepro = () => {
        return (
            <div></div>
        )
    }

    render() {
        //console.log("BankInfoView :render")
        const {
            mode
        } = this.props

        let renderMode;

        switch (mode) {
            case 'wastegen':
                renderMode = this.renderWastegen()
                break;
            case 'wastetran':
                renderMode = this.renderWastetran()
                break;
            case 'wastepro':
                renderMode = this.renderWastepro()
                break;

            default:
                renderMode = (<div></div>)
                break;
        }

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <label >ชื่อบัญชี</label>
                    <InputText
                        maxLength={100}
                        className="p-col-12"
                        placeholder="Account Name"
                        name="bank_account_name"
                        value={this.props.bank_account_name}
                        onChange={this.onChangeInputText}>
                    </InputText>
                </div>

                <div className="p-col-12">
                    <label >เลขที่บัญชี</label>
                    <InputText
                        maxLength={13}
                        onKeyPress={Validators.handleNumber}
                        type="number"
                        className="p-col-12"
                        placeholder="Account Number"
                        name="bank_account_no"
                        value={this.props.bank_account_no}
                        onChange={this.onChangeInputText}>
                    </InputText>
                </div>

                <div className="p-col-12">
                    <label >ธนาคาร</label>
                    <Dropdown style={{ width: '100%' }}
                        optionLabel="bank_name"
                        name="bank_code"
                        value={this.props.selectedBank}
                        options={this.props.banks}
                        onChange={this.onChanageBank}
                        placeholder="เลือกธนาคาร" />
                </div>

                <div className="p-col-12">
                    <label >Prompt-pay</label>
                    <InputText
                        className="p-col-12"
                        placeholder="Prompt-pay"
                        name="bank_promptpay"
                        type="number"
                        maxLength={13}
                        onKeyPress={Validators.handleNumber}
                        value={this.props.bank_promptpay}
                        onChange={this.onChangeInputText}>
                    </InputText>
                </div>

                {/* <div className="p-col-12">
                    <label >รหัสเลขใช้สำหรับแจ้งถอนเงิน</label>
                    <InputText
                        className="p-col-12"
                        placeholder="Pin Number"
                        name="bank_withdraw_code"
                        type="number"
                        keyfilter="pint"
                        value={this.props.bank_withdraw_code}
                        onChange={this.onChangeInputText}>
                    </InputText>
                </div> */}
                {renderMode}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(BankInfoView)
