import React from "react";
const _ = require("lodash");
const { compose, withProps, withStateHandlers, lifecycle } = require("recompose");
const {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} = require("react-google-maps");
const { InfoBox } = require("react-google-maps/lib/components/addons/InfoBox");
// const demoFancyMapStyles = require("./demoFancyMapStyles.json");

const StyledMapWithAnInfoBox = compose(

  withProps(props => {
    const { height } = props
    return {
      googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyA4jjZnsw8iYo3eDeZROTP-S63t1wfPYyo&v=3&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `${height-200}px` }} />,
    mapElement: <div style={{ height: `100%` }} />,
    // center: { lat: 13.7734283, lng: 100.57383099999993 },
    }
  }),
  lifecycle({
    componentWillMount() {
      const refs = {}
      
      this.setState({
        bounds: null,       
        center: { lat: 13.7734283, lng: 100.57383099999993 },
       
        onBoundsChanged: _.debounce(
          () => {
            let { onBoundsChange ,center} = this.props
            this.setState({
              bounds: refs.map.getBounds(),
              center: center
            })
            console.log("props"+ JSON.stringify(center));
            if (onBoundsChange) {
              onBoundsChange(refs.map)
            }
          },
          100,
          { maxWait: 500 }
        ),
        onMapMounted: ref => {
          refs.map = ref;
        },
      })
    },
  }),
  withStateHandlers(
    () => ({
      isOpen: {},
      index: null,
    }),
    {
      onToggleOpen: ({ isOpen }) => (id) => ({
        isOpen: {
          [id]: isOpen[id] == undefined ? true : !isOpen[id],
        },
        index: id,
      }),
      clearToggle: () => () => ({ isOpen: {} }),
    }
  ),
  withScriptjs,
  withGoogleMap
)((props) => (
  <GoogleMap
    defaultZoom={8}
    defaultCenter={props.center}
    center={props.center}
    resetBoundsOnResize={true}
    // defaultOptions={{ styles: demoFancyMapStyles }}
    onClick={() => {
      props.clearToggle();
    }}
    
  >
    {props.markers.map((marker, index) => (
      <Marker
        onClick={() => {
          props.onToggleOpen(index);
        }}
        animation={window.google.maps.Animation.DROP}
        key={index}
        position={{ lat: marker["lat"], lng: marker["lng"] }}
      >
        {props.isOpen[index] === true && (
          <InfoWindow
            key={index}
            options={{ closeBomarkerURL: ``, enableEventPropagation: true }}
            onCloseClick={() => {
              props.onToggleOpen(index);
            }}
          >
            <div
              style={{
                width: "300px",
                height: "150px",
                background: "white",
                boxShadow: "10px 30px 10px #9E9E9E",
                borderRadius: "15px;",
                paddingTop: "10px",
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  marginTop: "0px",
                }}
              >
                <p style={{ margin: "5px" }}>ชื่อ-นามสกุล : </p>
                <p
                  style={{
                    fontWeight: "bold",
                    marginLeft: "20px",
                    margin: "5px",
                  }}
                >
                  {props.markers[props.index]["applicant_name"]}
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  marginTop: "0px",
                }}
              >
                <p style={{ margin: "5px" }}>ประเภทสมาชิก : </p>
                <p
                  style={{
                    fontWeight: "bold",
                    marginLeft: "20px",
                    margin: "5px",
                  }}
                >
                  {props.markers[props.index]["waste_type_name"]}
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <p style={{ margin: "5px" }}>เบอร์โทรศัพท์ : </p>
                <p
                  style={{
                    fontWeight: "bold",
                    marginLeft: "20px",
                    margin: "5px",
                  }}
                >
                  {props.markers[props.index]["telephone_no"]}
                </p>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                }}
              >
                <div>
                  <p style={{ margin: "5px" }}>ที่อยู่ : </p>
                </div>
                <p
                  style={{
                    marginLeft: "20px",
                    margin: "5px",
                  }}
                >
                  จ.{props.markers[props.index]["province_name"]} อ.
                  {props.markers[props.index]["district_name"]} ต.
                  {props.markers[props.index]["subdistrict_name"]}
                </p>
              </div>
            </div>
          </InfoWindow>
        )}
      </Marker>
    ))}
  </GoogleMap>
));

export default StyledMapWithAnInfoBox;
