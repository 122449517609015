

import { API_ROOT } from '../constants/api'
import axios from './axios'
import {
  WASTETRAN_FILE_DELETE,
  WASTEPRO_FILE_DELETE
} from './actionType'

export const deleteWasteTranFile = (fileType, fileName) => (dispatch) => {
  dispatch({ type: WASTETRAN_FILE_DELETE.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastetran/deletefile/${fileType}/${fileName}`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  }).then(res => {
    if (res.status == 200) {
      return res
    }
    throw Error(res.status)
  }).then((res) => {
    dispatch({ type: WASTETRAN_FILE_DELETE.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTETRAN_FILE_DELETE.FAILURE, error })
  })
}

export const deleteWasteproFile = (fileType, fileName) => (dispatch) => {
  dispatch({ type: WASTEPRO_FILE_DELETE.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastepro/deletefile/${fileType}/${fileName}`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  }).then(res => {
    if (res.status == 200) {
      return res
    }
    throw Error(res.status)
  }).then((res) => {
    dispatch({ type: WASTEPRO_FILE_DELETE.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTEPRO_FILE_DELETE.FAILURE, error })
  })
}


