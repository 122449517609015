import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Panel } from "primereact/components/panel/Panel";
import { Checkbox } from "primereact/components/checkbox/Checkbox";
import { Button } from "primereact/components/button/Button";
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from "primereact/components/inputtext/InputText";
import { InputTextarea } from "primereact/components/inputtextarea/InputTextarea";
import { DataTable } from "primereact/components/datatable/DataTable";
import { Column } from "primereact/components/column/Column";
import { FullCalendar } from "primereact/fullcalendar";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Growl } from "primereact/growl";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { MapWithASearchBox } from "../../features/map/MapWithASearchBox";
import { MapMarkers } from "../../features/map/MapMarkers";
import { Calendar } from "primereact/calendar";
import { AutoComplete } from "primereact/autocomplete";
import { Avatar } from "../../features/widget/Avatar";
import { API_ROOT } from "../../constants/api";
import { ProgressSpinner } from "primereact/progressspinner";
import * as ServerAPI from "./service";
import * as LocationService from "../../service/LocationService";
import * as UserService from "../../service/user.service";
import * as MasterService from "../../service/MasterService";
import * as FileService from "../../service/file.service";
import * as DashBoardService from "./service";
import { isEmpty } from "../../utils/validators";
import { CEInputText, CECalendar, CEDropdown } from "../../features/input";
import { CEFileUpload } from "../../features/widget";
import { Dialog } from "primereact/dialog";

class WasteproManageProductPrice extends Component {
  static propTypes = {};
  constructor() {
    super();
    this.state = {
      addressDetail: "",
      priceList: [],
      memberStore: 0,
      brandSuggestions: null,
      wasteTypeSelected: "",
      searchProductType: "",
      searchProduct: "",
      productSelected: "",
      priceSelected1: "",

      memberStore1: [
        { label: "เลือกประเภทสินค้า", value: 0 },
        { label: "กระดาษ", value: 1 },
        { label: "พลาสติก", value: 2 },
        { label: "ประเภทที่ 2", value: 3 },
      ],
      memberStore3: 0,
      memberStore4: [
        { label: "เลือกสินค้า", value: 0 },
        { label: "กระดาษขาวดำ", value: 1 },
        { label: "กระดาษจับจั๊ว", value: 2 },
        { label: "กระดาษกล่องลัง", value: 3 },
      ],

      testData: [
        {
          nameStore: "ร้านมารวย",
          product_type: "กระดาษ",
          product: "กระดาษขาว",
          buy_per_kk: "10",
        },
        {
          nameStore: "ร้านมาจน",
          product_type: "พลาสติก",
          product: "ขวดน้ำพลาสติก",
          buy_per_kk: " 8",
        },
      ],
      reportSearchOrders: [],
      showProgress: false,
    };
    this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
    this.onproductsChange = this.onproductsChange.bind(this);
    this.onsearchProductChange = this.onsearchProductChange.bind(this);
    this.onWasteTypeChange = this.onWasteTypeChange.bind(this);
    this.onsearchProductTypeChange = this.onsearchProductTypeChange.bind(this);
    this.addDataToTable = this.addDataToTable.bind(this);
    this.clearSearchInput = this.clearSearchInput.bind(this);
    this.onValidation = this.onValidation.bind(this);
    this.getsalepricebyu3 = this.getsalepricebyu3.bind(this);
    this.searchProductList = this.searchProductList.bind(this);
    this.getsalepricebyu3ForValid = this.getsalepricebyu3ForValid.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.onWeightUpdate = this.onWeightUpdate.bind(this);
    this.onproductTypeUpdate = this.onproductTypeUpdate.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
  }
  componentDidMount() {
    this.getsalepricebyu3();
    this.getsalepricebyu3ForValid();
    const {
      dashBoardServiceProps,
      locationServiceProps,
      masterServiceProps,
    } = this.props;
    dashBoardServiceProps.getDashboardUsers();
    locationServiceProps.getProvice();
    masterServiceProps.getProductType();
  }
  showProgressSpinner() {
    const Progress = (
      <div className="p-col-12 p-md-8 p-lg-9">
        <ProgressSpinner
          style={{ width: "80px", height: "80px" }}
          strokeWidth="8"
          fill="#EEEEEE"
          animationDuration="0.5s"
        />
      </div>
    );

    if (this.state.showProgress) {
      return Progress;
    } else return <div></div>;
  }
  searchProductList() {
    this.setState({ showProgress: true });
    var search = {
      u3_uid: this.state.memberUid1 || "",
      product_code: this.state.searchProductCode || "",
      product_type_code: this.state.searchProductTypeCode || "",
    };

    ServerAPI.searchSalePriceByU3(search)
      .then((res) => {
        console.log(res);
        if (res) {
          var index = res.toString().indexOf("Error:");
          console.log("find error:" + index);
          if (index == -1) {
            this.setState({ priceList: res });
            // this.getprocessfee();
            // this.clearAllDataInput();
            this.setState({ showProgress: false });
            this.showSuccess();
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        } else {
          this.showWarn();
          this.setState({ showProgress: false });
        }
      })
      .catch((error) => {
        this.setState({ showProgress: false });
      });
  }

  getsalepricebyu3() {
    this.setState({ showProgress: true });
    ServerAPI.getsalepricebyu3()
      .then((res) => {
        console.log(res);
        if (res) {
          var index = res.toString().indexOf("Error:");
          console.log("find error:" + index);
          if (index == -1) {
            this.setState({ priceList: res });
            this.setState({ productPriceList: res });
            this.setState({ showProgress: false });
            //this.showSuccess();
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        } else {
          this.showWarn();
          this.setState({ showProgress: false });
        }
      })
      .catch((error) => {
        this.setState({ showProgress: false });
      });
  }
  getsalepricebyu3ForValid() {
    ServerAPI.getsalepricebyu3()
      .then((res) => {
        console.log(res);
        if (res) {
          var index = res.toString().indexOf("Error:");
          console.log("find error:" + index);
          if (index == -1) {
            this.setState({ productPriceList: res });
            this.setState({ showProgress: false });
            //this.showSuccess();
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        } else {
          this.showWarn();
          this.setState({ showProgress: false });
        }
      })
      .catch((error) => {
        this.setState({ showProgress: false });
      });
  }

  itemTemplate1(item) {
    return <div className="p-clearfix">{item.search_name}</div>;
  }

  onproductTypeUpdate(e) {
    console.log(e);
    this.setState({ productTypeObjUpdate: e.value });
  }
  onWeightUpdate(e) {
    console.log(e);
    this.setState({ productObjUpdate: e.value });
  }

  actionTemplate(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          onClick={() => this.onproductPriceUpdate(column.rowData)}
          icon="pi pi-pencil"
          className="p-button"
        ></Button>
        <Button
          type="button"
          onClick={() => this.onproductPriceDelete(column.rowData)}
          icon="fa fa-trash"
          className="p-button-warning"
        ></Button>
      </div>
    );
  }
  statusTemplate(rowData, column) {
    var label = 'test';
    var classname = 'p-button';
    var icons = 'pi pi-eye';
    if(rowData.is_active == false){
        label = 'ไม่ใช้งาน';
        classname = 'p-button-warning';
        icons = 'pi pi-times';
    }
    if(rowData.is_active == true){
        label = 'ใช้งาน';
        classname = 'p-button';
        icons = 'pi pi-check';
   }
    return <div>
        <Button type="button" label={label} onClick={() => this.onStatusUpdate(column.rowData)} icon={icons} className={classname} ></Button>
    </div>;
}
  onClickDeleteItem(rowData) {
    console.log("rowData : ",rowData);
    if (rowData !== null) {
      var id = rowData.gid;
      ServerAPI.removesalepricebyu3(id)
        .then((res) => {
          console.log(res);
          if (res) {
            var index = res.toString().indexOf("Error:");
            console.log("find error:" + index);
            if (index == -1) {
              this.searchProductList();
              // this.getsalepricebyu3ForValid();
              this.setState({ showProgress: false });
              //this.showSuccess();
            } else {
              this.showWarn();
              this.setState({ showProgress: false });
            }
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        })
        .catch((error) => {});
    } else {
      this.showWarn();
      this.setState({ showProgress: false });
    }

    // if (index !== -1) {
    //     var array = this.state.priceList;
    //     array.splice(index, 1);
    //     this.setState({ priceList: array });
    // }
  }
  update() {
    this.setState({ showProgress: true });
    console.log(this.state.productPrice);
    if (this.state.productPrice) {
      var isNotinput =
        this.state.productPrice.fee_per_kk == "" ||
        this.state.isNotValid == true;
      if (isNotinput) {
        this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
        this.setState({ showProgress: false });
      } else {
        var data = {
          gid: this.state.productPrice.gid,
          fee_per_kk: parseFloat(this.state.productPrice.fee_per_kk),
          is_active: this.state.productPrice.is_active,
        };

        ServerAPI.editSalepricebyu3(data)
          .then((res) => {
            console.log(res);
            if (res) {
              var index = res.toString().indexOf("Error:");
              console.log("find error:" + index);
              if (index == -1) {
                this.getsalepricebyu3();
                this.setState({ displayDialog: false });
                this.setState({ statusDialog:false});
                this.clearAllDataInput();
                this.showSuccess();
              } else {
                this.showWarn();
                this.setState({ showProgress: false });
              }
            } else {
              this.showWarn();
              this.setState({ showProgress: false });
            }
          })
          .catch((error) => {
            this.setState({ showProgress: false });
          });
      }
    }
  }
  delete() {
    this.setState({ showProgress: true });
    var data = this.state.productPrice;
    this.onClickDeleteItem(data);
    // let index = this.findSelectedWeightIndex();
    // this.setState({
    //     cars: this.state.cars.filter((val,i) => i !== index),
    //     selectedCar: null,
    //     car: null,
    this.setState({ deleteDialog: false });
  }
  updateStatus(){
    this.update()
  }
  updateProperty(property, value) {
    let productPrice = this.state.productPrice;
    productPrice[property] = value;
    this.setState({ productPrice: productPrice });
  }

  onproductPriceUpdate(e) {
    console.log(e);
    var productTypeDropdown = {
      product_type_code: e.product_type_code,
      product_type_name: e.product_type_name,
      less_than_kk: e.less_than_kk,
      more_than_kk: e.more_than_kk,
    };
    var productDropdown = {
      product_code: e.product_code,
      product_name: e.product_name,
      less_than_kk: e.less_than_kk,
      more_than_kk: e.more_than_kk,
    };
    this.setState({
      displayDialog: true,
      productPrice: Object.assign({}, e),
      productTypeObjUpdate: productTypeDropdown,
      productObjUpdate: productDropdown,
    });
  }
  onproductPriceDelete(e) {
    console.log(e);
    let _disableDelete = false;
    if(e.is_used){
        _disableDelete = true
    }
    this.setState({
      deleteDialog: true,
      disableDelete: _disableDelete,
      productPrice: Object.assign({}, e),
    });
  }
  onStatusUpdate(e){
    console.log(e)
    this.setState({
        statusDialog: true,
        isNotValid: false,
        productPrice: Object.assign({}, e),
    });
  }
  onValidation(valid) {
    console.log(valid);
    if (!valid) this.setState({ isNotValid: true });
    else this.setState({ isNotValid: false });
  }

  onWasteTypeChange(e) {
    this.setState({ wasteTypeSelected: e.value });
    this.setState({ productTypeCode: e.value.product_type_code });
    const { masterServiceProps, product } = this.props;
    masterServiceProps.getProduct(e.value.product_type_code);
  }
  onsearchProductTypeChange(e) {
    this.setState({ searchProductType: e.value });
    this.setState({ searchProductTypeCode: e.value.product_type_code });
    const { masterServiceProps, product } = this.props;
    masterServiceProps.getProduct(e.value.product_type_code);
  }

  showSuccess = (message) => {
    this.growl.show({
      severity: "success",
      summary: "สถานะ",
      detail: message ? message : "ทำรายการสำเร็จ",
    });
  };
  showWarn = (message) => {
    this.growl.show({
      severity: "warn",
      summary: "สถานะ",
      detail: message ? message : "มีบางอย่างผิดพลาด กรุณาตรวจสอบ",
    });
  };

  addDataToTable() {
    this.setState({ showProgress: true });
    var isNotinput =
      this.state.wasteTypeSelected == "" ||
      this.state.productSelected == "" ||
      this.state.priceSelected1 == "" ||
      // this.state.memberUid == '' ||
      // this.state.memberName == '' ||
      this.state.isNotValid == true;

    if (isNotinput) {
      this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
      this.setState({ showProgress: false });
    } else {
      var notValid = false;
      this.getsalepricebyu3ForValid();
      console.log(this.state.productPriceList);
      if (this.state.productPriceList)
        this.state.productPriceList.forEach(objProductPrice => {
          console.log(objProductPrice.product_type_code);
          console.log(this.state.productTypeCode);
          console.log(objProductPrice.product_code);
          console.log(this.state.productSelected.product_code);
          if (
            objProductPrice.product_type_code == this.state.productTypeCode &&
            objProductPrice.product_code ==
              this.state.productSelected.product_code
          ) {
            notValid = true;
          }
        });

      if (notValid) {
        this.showWarn("ข้อมูลซ้ำ! กรุณาเลือกใหม่อีกครั้ง");
        this.setState({ showProgress: false });
      } else {
        var priceList = {
          gid: this.state.gid,
          u3_uid: this.state.memberUid,
          u3_name: this.state.memberName,
          product_type_code: this.state.wasteTypeSelected.product_type_code,
          product_type_name: this.state.wasteTypeSelected.product_type_name,
          product_code: this.state.productSelected.product_code,
          product_name: this.state.productSelected.product_name,
          fee_per_kk: parseFloat(this.state.priceSelected1),
        };

        ServerAPI.Addsalepricebyu3(priceList)
          .then((res) => {
            console.log(res);
            if (res) {
              var index = res.toString().indexOf("Error:");
              console.log("find error:" + index);
              if (index == -1) {
                this.searchProductList();
                this.clearAllDataInput();
                //this.showSuccess();
              } else {
                this.showWarn();
                this.setState({ showProgress: false });
              }
            } else {
              this.showWarn();
              this.setState({ showProgress: false });
            }
          })
          .catch((error) => {
            this.setState({ showProgress: false });
          });
      }
    }
    console.log(this.state.priceList);
  }
  onproductsChange(e) {
    this.setState({ productSelected: e.value });
  }
  onsearchProductChange(e) {
    this.setState({ searchProduct: e.value });
    this.setState({ searchProductCode: e.value.product_code });
    this.setState({ searchProductName: e.value.product_name });
  }

  clearAllDataInput() {
    this.setState({ wasteTypeSelected: "" });
    this.setState({ productSelected: "" });
    this.setState({ priceSelected1: "" });
    this.setState({ memberName: "" });
    this.setState({ memberUid: "" });
    this.setState({ productPrice: null });
    this.setState({ productTypeObjUpdate: null });
    this.setState({ productObjUpdate: null });
  }

  clearSearchInput() {
    this.setState({ memberName1: null }); //order code from select table
    this.setState({ memberUid1: null });
    this.setState({ searchProductType: null }); //order code from auto complete
    this.setState({ searchProduct: null });
    this.setState({ searchProductTypeCode: null });
    this.setState({ searchProductCode: null });

    console.log(this.state.searchProductType);
    console.log(this.state.searchProduct);
    console.log(this.state.searchProductTypeCode);
    console.log(this.state.searchProductCode);

    this.clearAllDataInput();
  }

  showSearchPanel() {
    const { productType1, product1 } = this.props;
    return (
      <Accordion multiple={true} activeIndex={[0, 1]}>
        <AccordionTab header="เงื่อนไขค้นหาเพื่อจัดการราคารับซื้อ">
          <div className="p-grid p-justify-start">
            <div
              className="p-col-12 p-md-2 p-lg-2"
              style={{ alignSelf: "center" }}
            >
              <label>ประเภทสินค้า :</label>
            </div>
            <div
              className="p-col-12 p-md-10 "
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <Dropdown
                style={{ width: "20%" }}
                value={this.state.searchProductType}
                options={productType1}
                onChange={this.onsearchProductTypeChange}
                placeholder="เลือกประเภทขยะรีไซเคิล"
                optionLabel="product_type_name"
                //dataKey="product_type_code"
              />
            </div>
            <div
              className="p-col-12 p-md-2 p-lg-2"
              style={{ alignSelf: "center" }}
            >
              <label>สินค้า :</label>
            </div>
            <div
              className="p-col-12 p-md-10 "
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <Dropdown
                style={{ width: "20%" }}
                value={this.state.searchProduct}
                options={product1}
                onChange={this.onsearchProductChange}
                placeholder="เลือกสินค้า"
                optionLabel="product_name"
                //dataKey="product_code"
              />
            </div>

            <div className="p-col-12" >
              <Button
                label="ค้นหา"
                icon="fa fa-search"
                iconPos="left"
                onClick={this.searchProductList}
                style={{marginRight: '20px'}}
              />
              <Button
                style={{ backgroundColor: "#f0ad4e" }}
                type="submit"
                label="ยกเลิก"
                onClick={this.clearSearchInput}
              />
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    );
  }

  addNewProductTab() {
    const { productType, product } = this.props;

    return (
      <Accordion multiple={true}>
        <AccordionTab header="เพิ่มราคารับซื้อ">
          <div className="p-grid">
            <label
              className="p-col-12 p-md-3 p-lg-2 "
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              ประเภทขยะรีไซเคิล :
            </label>
            <div className="p-col-12 p-md-9 p-lg-10">
              <Dropdown
                style={{ width: "20%" }}
                value={this.state.wasteTypeSelected}
                options={productType}
                onChange={this.onWasteTypeChange}
                placeholder="เลือกประเภทขยะรีไซเคิล"
                optionLabel="product_type_name"
                //dataKey="product_type_code"
              />
            </div>

            <label
              className="p-col-12 p-md-3 p-lg-2 "
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              สินค้า :
            </label>

            <div className="p-col-12 p-md-9 p-lg-10">
              <Dropdown
                style={{ width: "20%" }}
                value={this.state.productSelected}
                options={product}
                onChange={this.onproductsChange}
                placeholder="เลือกสินค้า"
                optionLabel="product_name"
                //dataKey="product_code"
              />
            </div>
            <label
              className="p-col-12 p-md-3 p-lg-2"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              ราคารับซื้อต่อกิโลกรัม :
            </label>
            <div className="p-col-12 p-md-9 p-lg-10">
              <CEInputText
                style={{ width: "20%" }}
                id="priceSelected1"
                type="money"
                value={this.state.priceSelected1}
                name="priceSelected1"
                placeholder="00.00"
                tooltip="กรอกค่าขนส่ง (ทศนิยม 2 ตำแหน่ง)"
                required={true}
                isvalid={this.onValidation}
                onChange={(e) =>
                  this.setState({ priceSelected1: e.target.value })
                }
              />
              <label style={{ marginLeft: "10px" }}>บาท</label>
            </div>

            <div className="p-col-12 ">
              <Button
                style={{ float: "left" }}
                label="เพิ่มรายการ"
                icon="fa fa-plus"
                iconPos="left"
                onClick={this.addDataToTable}
              />
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    );
  }

  showWeightTable() {
    var list = this.state.priceList;
    var newlist = []
        list.forEach((objlist,index) => {
                if(objlist.product_type_code !== null && objlist.product_code !== null){
                    newlist.push(objlist);
                    console.log(newlist);
                }
        });


    let dialogFooter = (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="บันทึก" icon="pi pi-check" onClick={this.update} />
        <Button
          label="ยกเลิก"
          icon="pi pi-times"
          onClick={() => this.setState({ displayDialog: false })}
          className="p-button-warning"
        />
      </div>
    );
    let deleteDialogFooter = (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="ใช่" icon="pi pi-check" onClick={this.delete} disabled={this.state.disableDelete} />
        <Button
          label="ไม่ใช่"
          icon="pi pi-times"
          onClick={() => this.setState({ deleteDialog: false })}
          className="p-button-warning"
        />
      </div>
    );
    let statusDialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="บันทึก" icon="pi pi-check" onClick={this.updateStatus}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({statusDialog: false})} className="p-button-warning"/>
            </div>;
    const weightTable = (
      <div className="p-col-12">
        <Panel header="รายการราคารับซื้อ">
          <DataTable
            value={newlist}
            paginatorPosition="bottom"
            selectionMode="single"
            paginator={true}
            rows={5}
            rowsPerPageOptions={[5, 10, 15]}
            selection={this.state.selectedCar1}
            onSelectionChange={(e) => this.setState({ selectedCar1: e.value })}
            responsive={true}
            emptyMessage={
              <div style={{ textAlign: 'center', color: 'red' }}>
                <label>ไม่พบข้อมูล</label>
              </div>
            }
          >
            {/* <Column field="u3_name" header="รายชื่อศูนย์จัดการฯ" sortable={true} /> */}
            <Column field="product_type_name" header="ประเภทสินค้า" sortable={true} className="p-text-center"/>
            <Column field="product_name" header="สินค้า" sortable={true} className="p-text-center"/>
            <Column field="fee_per_kk" header="ราคารับซื้อต่อกิโลกรัม" sortable={true} className="p-text-center"/>
            <Column field="is_active" body={this.statusTemplate.bind(this)}  header="สถานะ" sortable={true} className="p-text-center"/>
            <Column field="gid" body={this.actionTemplate.bind(this)} header="จัดการ" className="p-text-center" style={{ width: "10%" }}/>
          </DataTable>
        </Panel>
        <Dialog
          visible={this.state.deleteDialog}
          style={{width:'40vw'}}
          header="ยืนยันการลบข้อมูล"
          modal={true}
          footer={deleteDialogFooter}
          onHide={() => this.setState({ deleteDialog: false })}
        >
          {this.state.productPrice && (
            <div className="p-grid">
              <div className="p-col-12">
                <span>คุณต้องการจะลบข้อมูลต่อไปนี้</span>
              </div>

              <div className="p-col-4">
                <label htmlFor="product_type_name">ประเภทขยะรีไซเคิล :</label>
              </div>
              <div className="p-col-8">
                <strong>{this.state.productPrice.product_type_name}</strong>
              </div>

              <div className="p-col-4">
                <label htmlFor="product_name">สินค้า :</label>
              </div>
              <div className="p-col-8">
                <strong>{this.state.productPrice.product_name}</strong>
              </div>
              <div className="p-col-4">
                <label htmlFor="fee_per_kk">ราคารับซื้อต่อกิโลกรัม :</label>
              </div>
              <div className="p-col-8">
                <strong>{this.state.productPrice.fee_per_kk}</strong>
              </div>

              {this.state.productPrice.is_used && 
                <div className="p-col-12" style={{textAlign: 'center'}} >
                    <span style={{color: 'red'}}>*****สินค้านี้ไม่สามารถลบได้ เนื่องจากมีการซื้อ-ขายสินค้านี้*****</span>
                </div>}
            </div>
          )}
        </Dialog>
        <Dialog
          visible={this.state.displayDialog}
          style={{width:'40vw'}}
          header="แก้ไขข้อมูล"
          modal={true}
          footer={dialogFooter}
          onHide={() => this.setState({ displayDialog: false })}
        >
          {this.state.productPrice && (
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <span>คุณต้องการจะแก้ไขข้อมูลต่อไปนี้</span>
              </div>

              <div className="p-col-4">
                <label htmlFor="product_type_name">ประเภทขยะรีไซเคิล :</label>
              </div>
              <div className="p-col-8">
                <strong>{this.state.productPrice.product_type_name}</strong>
              </div>

              <div className="p-col-4">
                <label htmlFor="product_name">สินค้า :</label>
              </div>
              <div className="p-col-8">
              <strong>{this.state.productPrice.product_name}</strong>
              </div>

              <div className="p-col-4" style={{ alignSelf: "center" }}>
                <label htmlFor="fee_baht">ราคารับซื้อต่อกิโลกรัม :</label>
              </div>
              <div className="p-col-8" style={{ padding: "0em" }}>
                <CEInputText
                  id="fee_per_kk"
                  type="money"
                  value={this.state.productPrice.fee_per_kk}
                  name="fee_per_kk"
                  placeholder="00.00"
                  tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                  required={true}
                  isvalid={this.onValidation}
                  onChange={(e) => {
                    this.updateProperty("fee_per_kk", e.target.value);
                  }}
                />
              </div>
            </div>
          )}
        </Dialog>

        <Dialog dismissableMask={true} visible={this.state.statusDialog} 
            header="สถานะ" modal={true} footer={statusDialogFooter} 
            onHide={() => this.setState({statusDialog: false})}
            style={{width:'30vw'}}
            >
            { this.state.productPrice &&
            <div className="p-grid" >
                <div className="p-col-12" style={{ padding: '1em' }}>
                    <label style={{ marginRight: '5px' }}>สถานะสินค้า : </label>
                </div>
                <br/>
                <br/>
                <div className="p-col-12 p-md-6 p-lg-6">
                    <div className="p-radiobutton-label">
                        <RadioButton inputId="rb2" name="isVerified" value={true} style={{marginBottom:'10px'}}
                            onChange={(e) => {this.updateProperty('is_active', e.target.value)}}
                            checked={this.state.productPrice.is_active === true} />
                        <label htmlFor="rb2" className="p-radiobutton-label">ใช้งาน</label>
                </div>
                    </div>
                    
                <div className="p-col-12 p-md-6 p-lg-6">
                    <div className="p-radiobutton-label">
                        <RadioButton inputId="rb1" name="isVerified" value={false} style={{marginBottom:'10px'}}
                            onChange={(e) => {this.updateProperty('is_active', e.target.value)}}
                            checked={this.state.productPrice.is_active === false} />
                        <label htmlFor="rb1" className="p-radiobutton-label">ไม่ใช้งาน</label>
                    </div> 
                </div>
                
            </div>
            }
        </Dialog>
      </div>
    );
    if (!this.state.showProgress) return weightTable;
  }

  onSubmit() {}

  render() {
    const searchPanel = this.showSearchPanel();
    const addnew = this.addNewProductTab();
    const weightTablePanel = this.showWeightTable();
    const showProgress = this.showProgressSpinner();

    return (
      <div className="p-grid ">
        <Growl ref={(el) => (this.growl = el)} />
        <div className="p-col-12" style={{color:'orange' }}>
          <p>{`* ถ้าไม่มีรายการสินค้าที่รับซื้อ ให้ทำการติดต่อแอดมิน (LINE : @c3leng)`}</p>
        </div>
        {searchPanel}
        {addnew}
        {showProgress}
        {weightTablePanel}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dhbUsers: state.dhbAdmin.dhbUsers,
  locationMaster: state.location,
  provinces: state.location.province,
  districts: state.location.district,
  subDistricts: state.location.subDistrict,
  cardProvince: state.location.cardProvince,
  cardDistrict: state.location.cardDistrict,
  cardSubDistrict: state.location.cardSubDistrict,
  authentication: state.authentication,
  userInfo: state.userInfo.data,
  userInfoMaster: state.userInfo,
  updateWasteproProfile: state.updateWasteproProfile,
  banks: state.master.banks,
  productType: state.master.productType,
  product: state.master.product,
  productType1: state.master.productType,
  product1: state.master.product,
  getFileWastepro: state.getFileWastepro,
});

const mapDispatchToProps = (dispatch) => ({
  dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
  locationServiceProps: bindActionCreators(LocationService, dispatch),
  userServiceProps: bindActionCreators(UserService, dispatch),
  masterServiceProps: bindActionCreators(MasterService, dispatch),
  fileServiceProps: bindActionCreators(FileService, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(WasteproManageProductPrice);
