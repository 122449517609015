import ReconnectingWebSocket from 'reconnecting-websocket'
import { get } from 'lodash'
import { createRef } from 'react'
import { WEBSOCKET_ROOT } from '../constants/api'
const navigationRef = createRef()
let ws
let intervalHandler

export const connectWebsocket = (senderId, senderType) => {
  console.log('connectWebsocket', ws)
  if (!ws) {
    const url = `${WEBSOCKET_ROOT}?uid=${senderId}&t=${senderType}`
    // const url = `ws://103.74.255.222:8088/ws?uid=5b5c88d0-f63a-49e4-b219-6b2e79f61e43&t=2`

    console.log('connectWebsocket', url)
    ws = new ReconnectingWebSocket(url)
  }
}

export const implementWebsocket = onMessage => {
  const ping = () => {
    //console.log('__ping__')
    const messageSending = {
      message: '__ping__',
      message_type: 3,
    }
    sendMessage(JSON.stringify(messageSending))
  }

  const pong = () => {
    //console.log('__pong__')
  }

  ws.onopen = () => {
    console.log('connection opened.')
    intervalHandler = setInterval(ping, 15000)
  }

  ws.onmessage = message => {
    const _message = JSON.parse(message.data)


    //console.log(_message)
    if (_message.message_type === 3) {
      pong()
      return
    }

    // Alert.alert('New message coming!', '', [{ text: 'ปิด', onPress: () => {}, style: 'cancel' }])

    // const rootStatus = get(navigationRef.current.getRootState(), 'routes', [])
    // const currentRouteName = rootStatus[rootStatus.length - 1].name
    // onMessage(_message, currentRouteName)
    onMessage(_message)
  }

  ws.onerror = error => {
    console.log('Websocker error', error.message)
  }

  ws.onclose = event => {
    console.log('Websocker close', event.code, event.reason)
  }
}

export const closeWebsocket = () => {
  if (ws) {
    console.log('Websocker is closing')
    clearInterval(intervalHandler)
    ws.close()
    ws = null
  }
}

export const sendMessage = message => {
  if (ws) {
    ws.send(message)
  }
}

export const getChatRoomType = (userWasteType, receiverWasteType) => {
  if (userWasteType === 'wastegen' || receiverWasteType === 'wastegen') {
    return 'pickup'
  } else if (userWasteType === 'wastepro' || receiverWasteType === 'wastepro') {
    return 'delivery'
  } else if (userWasteType === 'administrator' || receiverWasteType === 'administrator') {
    return 'admin'
  }
  return ''
}

// export const navigateToUserChatScene = ({
//   navigation,
//   orderCode,
//   userWasteType,
//   roomId,
//   receiverId,
//   receiverImage,
//   receiverWasteType,
// }) => {
//   navigation.reset({
//     index: 2,
//     routes: [
//       { name: 'Main', params: { screen: 'Report' } },
//       {
//         name: 'OrderDetailScene',
//         params: {
//           orderCode,
//           userWasteType,
//           fromScene: 'Report',
//           enableEdit: true,
//         },
//       },
//       {
//         name: 'ChatScene',
//         params: {
//           orderCode,
//           roomId,
//           receiverId,
//           receiverImage,
//           receiverWasteType,
//         },
//       },
//     ],
//   })
// }

// export const navigateToAdminChatScene = ({
//   navigation,
//   roomId,
//   receiverImage,
//   receiverWasteType,
// }) => {
//   navigation.reset({
//     index: 2,
//     routes: [
//       // { name: 'Main', params: { screen: 'Report' } },
//       // {
//       //   name: 'OrderDetailScene',
//       //   params: {
//       //     orderCode,
//       //     userWasteType,
//       //     fromScene: 'Report',
//       //     enableEdit: true,
//       //   },
//       // },
//       {
//         name: 'ChatScene',
//         params: {
//           roomId,
//           receiverImage,
//           receiverWasteType,
//           isAdminChat: true,
//         },
//       },
//     ],
//   })
// }
