

import React, { useState } from 'react';

import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { Button } from 'primereact/button';
import {InputText} from 'primereact/inputtext';
import { Chips } from 'primereact/chips';
import { AutoComplete } from 'primereact/autocomplete';

export const MessageAdminView = ({ valueDd, listDd, onChangeDd, valueMessage, onChangeMessage, onSubmit, classNameRequired, 
    valueMemberName, onChangeMemberName, onAddMember, customChipRemove, valueChips, valueProvince, provinceList,onChangedProvinceDb,
    valueDistrict, districtList, onChangedDistrictDb, brandSuggestions }) => {
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            return onsubmit
        }
    }
    const initChips = () => {"ส่งถึงทุกกลุ่ม"}

    const itemTemplate = (item) => {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }

    const suggestBrands = (event) => {
        //console.log(event);
    }

    return (
        <Panel header="ประกาศ">
            <div className="p-grid">
                {/* ROW1 */}
                <div className="p-col-12 p-lg-2" style={{ alignSelf: 'center'}}>
                    <label htmlFor="order_id" >ส่งข้อมูลถึง </label>
                </div>
                <div className="p-col-12 p-lg-10">
                    <Dropdown
                        style={{ width: '100%' }}
                        optionLabel="name"
                        value={valueDd}
                        options={listDd}
                        onChange={onChangeDd}
                        placeholder="เลือก"
                    />
                </div>
                 
                {valueDd.code == 2 &&
                <div className="p-grid" style={{ width: "100%" }}>
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col-12">
                            <label>จังหวัด</label>
                        </div>
                        <div className="p-col-12 ">
                            <Dropdown
                                style={{ width: "100%" }}
                                value={valueProvince}
                                options={provinceList}
                                onChange={onChangedProvinceDb} 
                                optionLabel="province_name" 
                                placeholder="เลือกจังหวัด" 
                                />
                            
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <div className="p-col-12">
                            <label>อำเภอ/เขต</label>
                        </div>
                        <div className="p-col-12 ">
                            <Dropdown
                                style={{ width: "100%" }}
                                value={valueDistrict}
                                options={districtList}
                                onChange={onChangedDistrictDb} 
                                optionLabel="districtName" 
                                placeholder="อำเภอ/เขต"
                                />
                        </div>
                    </div>
                </div>}

                {(valueDd.code == 1 || valueDd.code == 2 ||valueDd.code == 3) &&
                <div className="p-grid">
                    <div className="p-col-12 p-md-2 p-lg-2" style={{ alignSelf: 'center'}}>
                        <label >ชื่อผู้รับ  </label>
                    </div>
                    <div className="p-col-12 p-md-7 p-lg-7" style={{ alignSelf: 'center'}}>
                        <AutoComplete
                        inputClassName="input-100"
                        style={{ width: '100%'}}
                        value={valueMemberName}
                        suggestions={brandSuggestions}
                        onSelect={onAddMember}
                        completeMethod={suggestBrands}
                        onChange={(e) => onChangeMemberName(e.target.value)}
                        itemTemplate={itemTemplate}
                        placeholder="ระบุเบอร์โทรศัพท์/ชื่อสมาชิก" />
                    </div>
            

                {/* <div className="p-col-12" > */}
                    <Chips
                        style={{ margin: '0px 14px 10px 14px', width: '100%'}}
                        value={valueChips}
                        itemTemplate={customChip}
                        onRemove={customChipRemove}
                        
                    />
                {/* </div> */}
                </div>}

                {/* ROW2 */}
                <div className="p-col-12">
                    <InputTextarea
                        placeholder="ระบุข้อความ"
                        name="value_message"
                        className={classNameRequired.value_message_required}
                        style={{ width: '100%' }}
                        rows={10}
                        value={valueMessage}
                        onChange={onChangeMessage}
                        onKeyDown={handleEnter}
                    />
                </div>

                <div className="p-col-12">
                    <Button
                        className="p-col-12"
                        label="ส่งประกาศ"
                        icon="pi pi-check"
                        iconPos="left"
                        onClick={onSubmit} />
                </div>

            </div>
        </Panel>
    )
}

const customChip =(item)=> {
    return (
        <div>
            <span>{item.search_name}</span>
            <i className="pi pi-user-plus" style={{ fontSize: '14px' }}></i>
        </div>
    );
}
