import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getBalance, getWastetranDashProduct,getWastetranDashOrderStat } from '../../service/wastetran.service'
import { userAnnounce } from '../../service/user.service'

import { WastetranOverviews } from '../wastetran-dashboard/WastetranOverviews';
import { WasteNewView } from '../waste-news/WasteNewView';
import { DatatableDashBoardView } from '../data-table/DatatableDashBoardView';
import { StatisticView } from '../dashboard/StatisticView';
import { getStatus } from '../../constants/order-status';
import * as ServerAPI from '../../components/administrator/service';
import {WasteTranNewView} from '../waste-news/WasteTranNewView'

class WastetranDashboard extends Component {
    constructor(props) {
        console.log("WastegenDashboard :constructor")
        super(props);
        this.state = {
            dummySuccessDataTable: [
                { order: 'Order ทั้งหมด', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'จัดส่งสมบูรณ์', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'จัดส่งไม่สมบูรณ์', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'ลูกค้ายกเลิก', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: '%สำเร็จ', ranking: '0%', amount: '0', quantity: '0 kg.' },
            ]
        }
        this.getNewsList = this.getNewsList.bind(this);
    }

    componentDidMount() {
        this.getNewsList();
        console.log("WastegenDashboard :componentDidMount")
        this.props.dispatch(getBalance());
        this.props.dispatch(userAnnounce());
        this.props.dispatch(getWastetranDashProduct())
        this.props.dispatch(getWastetranDashOrderStat())
    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("WastegenDashboard :componentWillReceiveProps")
    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("WastegenDashboard :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("WastegenDashboard :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("WastegenDashboard :componentDidUpdate")

    }

    getNewsList(){
        this.setState({ showProgress: true })
        ServerAPI.getAllNews().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    var list = [];
                    
                    res.forEach((obj,index) => {
                        let userType = []
                        let wastegen = '';
                        let wastetran = '';
                        let wastepro = '';
                        let userTypeLabel = '';
                        let newFileObj = []
                        if(obj.isWasteGen){
                            let type = {name: 'ผู้รักษ์โลก', code: '1'}
                            userType.push(type)
                            wastegen = 'ผู้รักษ์โลก'
                        }
                        if(obj.isWasteTran){
                            let type = {name: 'ทีมขนส่งซีซาเล้ง', code: '2'}
                            userType.push(type)
                            wastetran = 'ทีมขนส่งซีซาเล้ง'
                        }
                        if(obj.isWastePro){
                            let type = {name: 'ศูนย์จัดการฯซีซาเล้ง', code: '3'}
                            userType.push(type)
                            wastepro = 'ศูนย์จัดการฯซีซาเล้ง'
                        }

                        if(wastegen && wastetran && wastepro){
                            userTypeLabel = 'ทุกกลุ่ม'
                        }else{
                            userTypeLabel = wastegen+"\n"+wastetran+"\n"+wastepro
                        }

                        obj.newsImageDetails.forEach(item => {
                            let newValiable = {
                                defaultImage: item.defaultImage,
                                gid: item.gid,
                                name: item.imageName,
                                imageName: item.imageName,
                                isDeleted: item.isDeleted,
                            }
                            newFileObj.push(newValiable)
                        });

                        let arr = {
                            gid: obj.gid,
                            newsCode: obj.newsCode,
                            newsTitle: obj.newsTitle,
                            updateDate: obj.updateDate,
                            createdDate: obj.createdDate,
                            newsDetail: obj.newsDetail,

                            defaultImageIndex: obj.defaultImageIndex,
                            defaultImageName: obj.defaultImageName,
                            newsImageDetails: newFileObj,

                            userType: userType,
                            userTypeLabel: userTypeLabel,
                            isWasteGen: obj.isWasteGen,
                            isWasteTran: obj.isWasteTran,
                            isWastePro: obj.isWastePro,
                        }
                        list.push(arr)

                    });
                    console.log(list)
                    this.setState({ newsDataList: list })
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false})
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false})
            }
        }).catch((error) => {
            this.setState({ showProgress: false})
        })
    }

    render() {
        const {
            wastetranBalance,
            userAnnounce,
            getWastetranDashProduct,
            getWastetranDashOrderStat
        } = this.props

        //> ข่าวสาร
        const contentNews = (
            <WasteTranNewView
                newsDataList = {this.state.newsDataList}
            />
        );

        //Overview
        let summaryBalance = 0;
        let successBalance = 0;
        let noSuccessBalance = 0;
        let donateSummary = 0;
        if (
            !wastetranBalance.isLoading
            && wastetranBalance.data
            && !wastetranBalance.isFailed
        ) {
            summaryBalance = (wastetranBalance.data.income_summary_thb).toFixed(2);
            successBalance = wastetranBalance.data.withdraw_summary_thb.toFixed(2);
            noSuccessBalance = Math.abs(wastetranBalance.data.withdraw_balance_thb.toFixed(2));
            donateSummary = wastetranBalance.data.donate_summary_thb.toFixed(2);
        }
        const contentOverview = (
            <WastetranOverviews
                summaryBalance={summaryBalance}
                successBalance={successBalance}
                noSuccessBalance={noSuccessBalance}
                donateSummary={donateSummary}
            />
        );

        //> ความสำเร็จของคุณ
        let dataList = []
        if (!getWastetranDashProduct.isLoading && !getWastetranDashProduct.isFailed) {
            if (getWastetranDashProduct.data && getWastetranDashProduct.data.length > 0) {
                for (let key in getWastetranDashProduct.data) {
                    let entity = getWastetranDashProduct.data[key]
                    let prepareData = {
                        order: entity.product_type_name,
                        quantity: entity.quantity_kk 
                    };
                    dataList.push(prepareData);
                }
            }
        }
        const contentSuccessDataTable = (
            <DatatableDashBoardView data={dataList} />
        );

        //> สถิติการรับงาน
        let waitDate = null;
        let waitOrder = null;
        let waitSendDate = null;
        let waitSendOrder = null;
        let overDueDate = null;
        let overDueOrder = null;
        let closeDate = null;
        let closeOrder = null;
        let cancelDate = null;
        let cancelOrder = null;
        if (!getWastetranDashOrderStat.isLoading && !getWastetranDashOrderStat.isFailed) {
            if (getWastetranDashOrderStat.data && getWastetranDashOrderStat.data.length > 0) {
                getWastetranDashOrderStat.data.map((value) => {
                    switch (getStatus(value.order_status).value) {
                        case 2:
                            waitDate=value.last_update_date
                            waitOrder=value.stat_count
                            break;
                        case 3:
                            waitSendDate=value.last_update_date
                            waitSendOrder=value.stat_count
                            break;
                        case 5:
                            overDueDate = value.last_update_date
                            overDueOrder = value.stat_count
                        case 88:
                            closeDate=value.last_update_date
                            closeOrder=value.stat_count
                            break;
                        case 99:
                            cancelDate=value.last_update_date
                            cancelOrder=value.stat_count
                            break;
                    }
                })

            }
        }
        const contentStatisticOrders = (
            <StatisticView
               header="สถิติรับงาน"
                waitDate={waitDate}
                waitOrder={waitOrder}
                waitSendDate={waitSendDate}
                waitSendOrder={waitSendOrder}
                overDueDate={overDueDate}
                overDueOrder={overDueOrder}
                closeDate={closeDate}
                closeOrder={closeOrder}
                cancelDate={cancelDate}
                cancelOrder={cancelOrder}
            />
        );

        //> กรณีไม่พบคำสั่งสถิติการรับงาน
        const notFoundcontentStatisticOrders = (
            <div className="card timeline p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <label>
                            <i className="fa fa-star" style={{ color: '#f6ac2b' }}></i>
                            ไม่พบคำสั่งงาน
                        </label>
                    </div>

                </div>
            </div>
        );

        return (
            <div>
                
                {contentNews}
                <h1>Dashboard</h1>
                <div style={{ marginTop: '1em' }} />
                <div className="p-grid dashboard">
                    <div className="p-col-12 p-lg-8">
                        {contentOverview}
                        <div style={{ marginTop: '1em' }} />
                        {contentSuccessDataTable}
                    </div>
                    <div className="p-col-12 p-lg-4">
                        {contentStatisticOrders}
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    wastetranBalance: state.wastetranBalance,
    userAnnounce: state.userAnnounce,
    getWastetranDashProduct: state.getWastetranDashProduct,
    getWastetranDashOrderStat: state.getWastetranDashOrderStat
})

export default connect(mapStateToProps)(WastetranDashboard)