import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Moment from 'react-moment';
import 'moment-timezone';
import {Panel} from 'primereact/components/panel/Panel';
import {Checkbox} from 'primereact/components/checkbox/Checkbox';
import {Button} from 'primereact/components/button/Button';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {InputText} from 'primereact/components/inputtext/InputText';
import {InputTextarea} from 'primereact/components/inputtextarea/InputTextarea';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { ProgressSpinner } from 'primereact/progressspinner';
import { AutoComplete } from 'primereact/autocomplete';
import { ExportExcel } from '../ExportExcel/ExportExcel';

import * as ServerAPI from './service';
import * as DashBoardService from './service'


class WasteproReportInvoice extends Component {
	static propTypes = {

    }
  
    static defaultProps = {
    }

    constructor() {
        super();
        this.state = {
            status: 0,
            admin: 0,
            statusItem: [
                { label: 'เลือกทั้งหมด', value: 0 },
                { label: 'ยังไม่ได้แจ้งหนี้', value: 1 },
                { label: 'แจ้งหนี้แล้วรอชำระ', value: 2 },
                { label: 'ชำระแล้วรอตรวจสอบ', value: 3 },
                { label: 'ชำระหนี้แล้ว', value: 88 },
            ],
            showProgress: false,
            orderCode: null,
            orderID: null,
            memberUid: null,
            date1: null,
            date2: null,
            reportSearchInvoice: [],
        };
        this.onDate1Change = this.onDate1Change.bind(this);
        this.onDate2Change = this.onDate2Change.bind(this);
        this.searchInvoice = this.searchInvoice.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.onSelectOrderID = this.onSelectOrderID.bind(this);
        this.onSelectAdminName = this.onSelectAdminName.bind(this);
        this.salePriceTemplate = this.salePriceTemplate.bind(this);
        this.toFormatDateTime = this.toFormatDateTime.bind(this);
    }
    componentDidMount() {
        
    }

    onDate1Change(e) { //search pickup date start
        //console.log(e.value.toISOString())
        if (this.state.date2 != null) {
            if (e.value <= this.state.date2) {
                this.setState({ date1: e.value })
            }
        } else
            this.setState({ date1: e.value })
    }
    onDate2Change(e) { //search pickup date end
        if (this.state.date1 <= e.value) {
            this.setState({ date2: e.value })
        }
    }
    searchInvoice(){
        //this.setState({ showReportTable: true })
        this.setState({ showProgress: true })
        //-------reset table--------
        // var reportSearchList = {}
        // this.state.reportSearchOrders.push(reportSearchList)
        // this.setState({ reportSearchOrders: [] });
        var searchModel = {
            order_code: this.state.orderID,
            user_search_name: this.state.memberUid,
            user_wasteTypes: this.state.memberType,
            invoice_status: this.state.status,
            admin_code: "",
        };

        if (this.state.date1 != null) {
            //Bankok Time zone (+7hrs)
            var date1 = new Date(this.state.date1.getTime() + 7 * 60 * 60000);
            searchModel.delivery_date_start = date1.toISOString();
        }
        if (this.state.date2 != null) {
            var date2 = new Date(this.state.date2.getTime() + 7 * 60 * 60000);
            searchModel.delivery_date_end = date2.toISOString();
        }

        ServerAPI.searchInvoice(searchModel).then((res) => {
            console.log("response");
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    console.log(res);
                    if (res.length > 0) {
                        this.setState({ reportSearchInvoice: res })
                        this.setState({ showProgress: false })
                    } else {
                        this.showWarn("ไม่พบข้อมูล");
                        this.setState({ showProgress: false })
                    }
                    // this.showSuccess();
                    // this.clearAllDataInput();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }

            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
        })
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div className="p-col-12 p-md-8 p-lg-9"></div>);
    }
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    clearSearchInput() {
        this.setState({ orderCode: null }); //order code from select table
        this.setState({ orderID: null }); //order code from auto complete
        this.setState({ date1: null });
        this.setState({ date2: null });
        this.setState({ status: 0});
        var empty = {}
        this.state.reportSearchInvoice.push(empty)
        this.setState({ reportSearchInvoice: [] });

        //this.clearAllDataInput();
    }

    //auto complate handle (ค้นหา Order ID)
    suggestOrderID(event) {
        //console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */
        //this.setState({ brandSuggestions: event });
    }
    onOrderIDChanged(code) {
        //console.log(code)
        if (code.trim() != "") {
            this.setState({ orderID: code });
            if (code.length > 1) {
                ServerAPI.autoComplateOrderID(code).then((res) => {
                    console.log(res);
                    this.setState({ orderIDSuggestions: res });
                }).catch((error) => {
                })
            }
        } else this.setState({ orderID: code });
    }
    onSelectOrderID(item) {
        this.setState({ orderID: item.value });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    itemOrderIDTemplate(item) {
        return (
            <div className="p-clearfix">
                {item}
            </div>
        );
    }
    onSelectAdminName(item) {
        this.setState({ adminName: item.value.search_name });
        this.setState({ adminUid: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    salePriceTemplate(rowData, column){
        let price = rowData.total_price.toFixed(2);
        return <div>
            <span> {price}</span>
        </div>;
    }
    toFormatDateTime(dateTime){
        let _dateTime = new Date(dateTime);
        let year = _dateTime.getYear();

        if(year==-1899)
            return <div><span></span></div>;
        else 
        return <div><Moment format="DD/MM/YYYY">
                {dateTime}
                    </Moment><br/><Moment format="HH:mm">
                        {dateTime}
                    </Moment></div>; 
    }
    to_transfer_date(rowData, column){
       return this.toFormatDateTime(rowData.delivery_date);
    }
    to_requested_date(rowData, column){
        return this.toFormatDateTime(rowData.pickup_date);
    }
    actionTemplate = (rowData, column) => {
        console.log(rowData)
        return <div>
            <Button
                type="button"
                icon="fa fa-print"
                className="p-button-success"
                onClick={(e) =>  window.open('/#/invoice-u3?order_code='+rowData.order_code, "_blank") }
                style={{ marginRight: '.5em' }}></Button>
        </div>;
    }

    searchPanel(){
        return (
            <div className="p-col-12">
                <Accordion multiple={true} activeIndex={[0, 1]}>
                    <AccordionTab header="เงื่อนไขค้นหาใบแจ้งหนี้">
                    <div className="p-grid " >
                        <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>Order ID :</label>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.orderID}
                                    suggestions={this.state.orderIDSuggestions}
                                    onSelect={this.onSelectOrderID}
                                    completeMethod={this.suggestOrderID.bind(this)}
                                    onChange={(e) => this.onOrderIDChanged(e.value)}
                                    itemTemplate={this.itemOrderIDTemplate.bind(this)}
                                    placeholder="Order ID " />
                        </div>

                        <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>สถานะ :</label>
                        </div>

                        <div className="p-col-12 p-md-4 p-lg-3" style={{ alignSelf: 'center' }}>
                            <Dropdown
                                value={this.state.status}
                                options={this.state.statusItem}
                                onChange={(e) => { this.setState({ status: e.value }) }}
                            />
                        </div>
                        <div className="p-col-12 p-lg-4"></div>

                        <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>ช่วงวันรับสินค้า</label>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <Calendar value={this.state.date1} dateFormat="dd/mm/yy" showIcon={true} placeholder="เลือกวันที่" onChange={this.onDate1Change} />
                        </div>

                        <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <label>ถึง</label>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <Calendar value={this.state.date2} dateFormat="dd/mm/yy" showIcon={true} placeholder="เลือกวันที่" onChange={this.onDate2Change} />
                        </div>
                        <div className="p-col-12 p-lg-4"></div>
                        <div className="p-col-12">
                            <br/>
                                <Button
                                    style={{marginRight:"20px"}}
                                    label="ค้นหา"
                                    icon="fa fa-search"
                                    iconPos="left"
                                    onClick={this.searchInvoice} />
                            
                                <Button
                                    style={{ backgroundColor: '#f0ad4e' }}
                                    type="submit"
                                    label="ยกเลิก"
                                    onClick={this.clearSearchInput}
                                />
                            </div>
                    </div>
                    </AccordionTab>
                </Accordion>
            </div>
        )
    }
    reportInvoiceTable(){
        let sum_price =0;
        let sum_quantity =0;
        if(this.state.reportSearchInvoice)
        if(this.state.reportSearchInvoice.length>0) {
           this.state.reportSearchInvoice.map( function(a){
                let a_sale_price  = a.total_price;
                    sum_price += a_sale_price;
                let quantity = a.total_quantity;
                    sum_quantity += quantity;
            });
        }
        let footerGroup = <ColumnGroup>
                            <Row >
                                <Column footer="รวม" colSpan={6} style={{ textAlign: 'right'}} />
                                <Column footer={sum_quantity.toFixed(2)} style={{ textAlign: 'right'}}/>
                                <Column footer={sum_price.toFixed(2)} style={{ textAlign: 'right'}}/>
                            </Row>
                         </ColumnGroup>;
        var exportExcel =
        <div style={{ textAlign: 'right' }}>
            <ExportExcel data={this.state.reportSearchInvoice} fileName='รายงานการแจ้งหนี้ (ศูนย์จัดการฯซีซาเล้ง)' />
        </div>;
        return (
            <div className="p-col-12">
            <Panel header="รายงานการแจ้งหนี้">
                <DataTable value={this.state.reportSearchInvoice} paginatorPosition="bottom"  paginator={true} rows={5} rowsPerPageOptions={[5, 10, 20]}
                    responsive={true} selection={this.state.dataTableSelection} onSelectionChange={event => this.setState({ dataTableSelection: event.value })} 
                    header={exportExcel} footerColumnGroup={footerGroup}
                    emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                    <label>ไม่พบข้อมูล</label>
                    </div>)}>
                    <Column body={this.actionTemplate} header="พิมพ์ใบแจ้งหนี้" className="p-text-center"/>
                    <Column field="invoice_code" header="เลขที่" sortable={true} />
                    <Column field="order_code" header="Order ID" sortable={true} />
                    <Column field="u3_fullname" header="ชื่อร้าน" sortable={true} />
                    <Column field="delivery_date" body={this.to_transfer_date.bind(this)} header="วันที่ส่ง" style={{width:'10em'}} className="p-text-center"/>
                    <Column field="invoice_status_name" header="สถานะ" sortable={true} className="p-text-center"/>
                    <Column field="total_quantity" header="ปริมาณส่ง (Kg)" sortable={true} className="p-text-right"/>
                    <Column body={this.salePriceTemplate} header="ราคาขาย (บาท)" className="p-text-right"/>
                </DataTable>
            </Panel>
        </div>
        )
    }

    render() {
        const searchPanel = this.searchPanel();
        const invoiceTable = this.reportInvoiceTable();
        const ProgressSpinner = this.showProgressSpinner();
        const {
            dhbUsers,
        } = this.props
        return <div className="p-grid">
                <Growl ref={(el) => this.growl = el} />
                {searchPanel}
                {ProgressSpinner}
                {invoiceTable}  
            </div>
    }
}



const mapStateToProps = (state) => ({

	dhbUsers: state.dhbAdmin.dhbUsers
})

const mapDispatchToProps = dispatch => ({

	dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch)

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(WasteproReportInvoice)