import React, { Component } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/button';
import classNames from 'classnames';


class CEFileUpload extends Component {
    static defaultProps = {

        required:false,
        value: [],
         id:'',  
         className: null, 
         style: null,
         mode: 'basic',
         chooseLabel: 'เลือกไฟล์',
         cancelLabel: 'ยกเลิก',
         multiple:true,
         accept:"image/*",
         maxFileSize:10000000,
         onSelect: () => { },
         onDelete: () => { }
        
    }

    constructor(props) {
        super(props)
        this.state = { value: []}
        
        this.changed = this.changed.bind(this);
        this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
        //this.showCtrl = this.showCtrl.bind(this);
        //regexp = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
        
    }
    componentDidMount() {
        if(this.props.value)
        this.setState({value: this.props.value})
    }
    componentWillReceiveProps(e) {  
      
    
    }
   
    changed = (e) =>{
        console.log("CEFileUpload->changed");
        console.log(e);
           
        this.props.onSelect(e);
        
    }
    itemTemplate(item, layout) {
        console.log("CEFileUpload->itemTemplate");
        console.log(item)
        if (layout === 'list') {
            return (
                <div className="p-grid">
                    <div>{item.name}</div>
                </div>
            );
        }
       
    }
    onClickDeleteItem(index){
        this.props.onDelete(index);
    }
    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" onClick={() => this.onClickDeleteItem(column.rowIndex)} icon="fa fa-trash" className="p-button-warning" ></Button>
        </div>;
    }
    showCtrl(){
        
      const {value, onSelect,id,  className, style,onChange,mode,chooseLabel,cancelLabel,multiple,accept,maxFileSize} = this.props;
     

      return  <div>
                <FileUpload name="wastes[]"
                    onSelect={this.changed}
                    mode={mode}
                    chooseLabel={chooseLabel}
                    cancelLabel={cancelLabel}
                    multiple={multiple}
                    accept={accept}
                    maxFileSize={maxFileSize} />
               { value && <DataTable value={value}>
                    <Column field="name" header="ชื่อไฟล์" />
                    <Column body={this.actionTemplate.bind(this)} header="ลบ" style={{width:'5em'}} />
                </DataTable>
                }
             </div>

    }
    render(){
        const bodyContent = this.showCtrl();
      
        return (
            bodyContent
        )
    }
}

export default CEFileUpload