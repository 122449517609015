import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import 'moment-timezone'
import { Button } from 'primereact/button'
import { Growl } from 'primereact/growl'
import { API_ROOT } from '../../constants/api'
import { Accordion, AccordionTab } from 'primereact/accordion'
import { Panel } from 'primereact/components/panel/Panel'
import { DataTable } from 'primereact/components/datatable/DataTable'
import { Column } from 'primereact/components/column/Column'
import { Dropdown } from 'primereact/components/dropdown/Dropdown'
import { FileUpload } from 'primereact/fileupload'
import { ProgressSpinner } from 'primereact/progressspinner'
import {
    getCertTemplateAll,
    getCertTemplateById,
    createCertTemplate,
    updateCertTemplate,
    deleteCertTemplate
} from './service'
import { CEInputText } from '../../features/input'
import { Dialog } from 'primereact/dialog'
import { Slider } from 'primereact/slider'
import moment from 'moment'
import { orderBy } from 'lodash'

export default function ManageCertificate() {

    const { adminCertTemplate } = useSelector(state => state)
    const dispatch = useDispatch()

    const [certificationList, setCertificationList] = useState([])
    const [isNotValid, setIsNotValid] = useState(false)
    const [showProgress, setShowProgress] = useState(false)
    const [statusItem, setStatusItem] = useState([
        { label: 'เปิดใช้งาน', value: true },
        { label: 'ปิดการใช้งาน', value: false }
    ])
    const [gid, setGid] = useState(0)
    const [certName, setCertName] = useState('')
    const [certText, setCertText] = useState('')
    const [carbonCreditStart, setCarbonCreditStart] = useState()
    const [isValidCreditStart, setIsValidCreditStart] = useState(false)
    const [carbonCreditEnd, setCarbonCreditEnd] = useState()
    const [isValidCreditEnd, setIsValidCreditEnd] = useState(false)
    const [certImageAxisX, setCertImageAxisX] = useState(339)
    const [certImageAxisY, setCertImageAxisY] = useState(98)
    const [certImageHeight, setCertImageHeight] = useState(159)
    const [certImageWidth, setCertImageWidth] = useState(119)
    const [holderNameAxisX, setHolderNameAxisX] = useState(238)
    const [holderNameAxisY, setHolderNameAxisY] = useState(264)
    const [holderNameFontSize, setHolderNameFontSize] = useState(32.0)
    const [certDateAxisX, setCertDateAxisX] = useState(331)
    const [certDateAxisY, setCertDateAxisY] = useState(309)
    const [certDateFontSize, setCertDateFontSize] = useState(24.0)
    const [isActive, setIsActive] = useState(true)
    const [templateImage, setTemplateImage] = useState(null)
    const [certTemplateImage, setCertTemplateImage] = useState('')
    const [cert, setCert] = useState(0)

    const [activeIndex, setActiveIndex] = useState(null)
    const [deleteDialog, setDeleteDialog] = useState(false)
    const [imageDialog, setImageDialog] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [isShowSlide, setIsShowSlide] = useState(false)

    const growl = useRef(null)
    const canvasRef = useRef(null)
    
    useEffect(() => {
        dispatch(getCertTemplateAll())
    }, [])

    useEffect(() => {
        if (adminCertTemplate.data &&
            !adminCertTemplate.isLoading &&
            !adminCertTemplate.isFailed) {
            setCertificationList(orderBy(adminCertTemplate.data, 'carbon_credit_start', 'asc'))
        }
    }, [adminCertTemplate])

    useEffect(() => {
        if (templateImage !== null || certTemplateImage !== '') {
            setIsShowSlide(true)
        } else {
            setIsShowSlide(false)
        }
    }, [templateImage, certTemplateImage])

    useEffect(() => {
        if (certName == '' || carbonCreditStart == '' || carbonCreditEnd == '') {
            setIsNotValid(true)
        } else {
            setIsNotValid(false)
        }
    }, [certName, carbonCreditStart, carbonCreditEnd])

    useEffect(() => {
        if (parseFloat(carbonCreditStart) == 'NaN' || parseFloat(carbonCreditEnd) == 'NaN') {
            setCarbonCreditStart(parseFloat(carbonCreditStart))
            setCarbonCreditEnd(parseFloat(carbonCreditEnd))
        }
    }, [carbonCreditStart, carbonCreditEnd])

    const showProgressSpinner = () => {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner
                    style={{ width: '80px', height: '80px' }}
                    strokeWidth="8" fill="#EEEEEE"
                    animationDuration="0.5s"
                />
            </div>
        );

        if (showProgress) {
            return (Progress)
        } else
            return (<div></div>)
    }

    const actionTemplate = (rowData, column) => {
        return <div>
            <Button
                type="button"
                onClick={() => onCertUpdate(column.rowData)}
                icon="pi pi-pencil"
                className="p-button"
            ></Button>
            <Button
                type="button"
                onClick={() => onDeleteClick(column.rowData)}
                icon="fa fa-trash"
                className="p-button-warning"
            ></Button>
        </div>;
    }

    const onUpdate = () => {
        setShowProgress(true)
        if (isNotValid) {
            showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
            setShowProgress(false)
        } else {
            var min = parseFloat(carbonCreditStart)
            var max = parseFloat(carbonCreditEnd)

            if (min >= max) {
                console.log(min)
                console.log(max)
                showWarn("น้ำหนักต่ำสุด ต้องน้อยกว่าน้ำหนักสูงสุด");
                setShowProgress(false)
            } else {
                var notValid = false;
                certificationList.indexOf("")
                certificationList.forEach(objCertList => {
                    var objMin = parseFloat(objCertList.carbon_credit_start)
                    var objMax = parseFloat(objCertList.carbon_credit_end)
                    // console.log(min + " > " + objMin);
                    // console.log(max + " > " + objMin);
                    // console.log(min + " < " + objMax);
                    // console.log(max + " < " + objMax);
                    if (objCertList.gid != gid)
                        if ((min < objMin && max < objMin) || (min > objMax && max > objMax)) {
                            console.log("pass!");
                        } else if (objMin <= min || objMax >= max) {
                            console.log("not pass!")
                            notValid = true;
                        } else if (objMin >= min || objMax <= max) {
                            console.log("not pass2!")
                            notValid = true;
                        } else console.log("pass2!");
                });

                if (notValid) {
                    showWarn("มีช่วงระยะทางที่ซ้ำซ้อนกัน");
                    setShowProgress(false)
                } else {
                    createUpdateCert()
                }
            }
        }
    }

    const onCertUpdate = (e) => {
        onClearAll()
        dispatch(getCertTemplateById(e.gid)).then((res) => {
            setShowAdd(true)
            setGid(res.gid)
            setCertName(res.cert_name)
            setCertText(res.cert_text)
            setCarbonCreditStart(res.carbon_credit_start)
            setCarbonCreditEnd(res.carbon_credit_end)
            setCertImageAxisX(res.cert_image_axis_x)
            setCertImageAxisY(res.cert_image_axis_y)
            setCertImageHeight(res.cert_image_height)
            setCertImageWidth(res.cert_image_width)
            setHolderNameAxisX(res.holder_name_axis_x)
            setHolderNameAxisY(res.holder_name_axis_y)
            setHolderNameFontSize(res.holder_name_font_size)
            setCertDateAxisX(res.cert_date_axis_x)
            setCertDateAxisY(res.cert_date_axis_y)
            setCertDateFontSize(res.cert_date_font_size)
            setIsActive(res.is_active)
            setTemplateImage(null)
            setCertTemplateImage(res.cert_template_image)
        }).catch((error) => {
            setShowProgress(false)
        })
    }

    const onDeleteClick = (e) => {
        setDeleteDialog(true)
        setCert(Object.assign({}, e))
    }

    const onCertDelete = () => {
        dispatch(deleteCertTemplate(cert.gid)).then(res => {
            showSuccess()
            setDeleteDialog(false)
            dispatch(getCertTemplateAll())
        }).catch(error => {
            showWarn()
        })
    }

    const showSuccess = (message) => {
        growl.current.show({
            severity: 'success',
            summary: 'สถานะ',
            detail: message ? message : 'ทำรายการสำเร็จ'
        })
    }
    const showWarn = (message) => {
        growl.current.show({
            severity: 'warn',
            summary: 'สถานะ',
            detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ'
        })
    }

    const onSave = () => {
        setShowProgress(true)

        if (isNotValid) {
            showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
            setShowProgress(false)
        } else {
            var min = parseFloat(carbonCreditStart)
            var max = parseFloat(carbonCreditEnd)

            if (min >= max) {
                console.log(min)
                console.log(max)
                showWarn("ค่าเริ่มต้น ต้องน้อยกว่าค่าสิ้นสุด");
                setShowProgress(false)
            } else {
                var notValid = false;
                certificationList.forEach(objCert => {
                    var objMin = parseFloat(objCert.carbon_credit_start)
                    var objMax = parseFloat(objCert.carbon_credit_end)
                    // console.log(min + " < " + objMin)
                    // console.log(max + " < " + objMin)
                    // console.log(min + " > " + objMax)
                    // console.log(max + " > " + objMax)
                    if ((min < objMin && max < objMin) || (min > objMax && max > objMax)) {
                        console.log("pass!");
                    } else if (objMin <= min || objMax >= max) {
                        console.log("not pass!")
                        notValid = true;
                    } else if (objMin >= min || objMax <= max) {
                        console.log("not pass2!")
                        notValid = true;
                    } else console.log("pass2!");
                });

                if (notValid) {
                    showWarn("มีช่วงคาร์บอนเครดิตที่ซ้ำซ้อนกัน");
                    setShowProgress(false)
                } else {

                    if (!templateImage && certTemplateImage == '') {
                        showWarn("กรุณาแนบไฟล์รูปใบประกาศ");
                        setShowProgress(false)
                    } else {
                        createUpdateCert()
                    }
                }
            }
        }
    }

    const onSelectTemplateImage = (e) => {
        console.log(e)
        setTemplateImage(e.files[0])
    }

    const onDeleteCertImage = (e) => {
        setCertTemplateImage('')
        setTemplateImage(null)
    }

    const openImage = (rowData, column) => {
        return <div>
            <Button
                type="button"
                onClick={() => onOpenImage(column.rowData)}
                icon="pi pi-images"
                label="เปิดดูภาพ"
                className="p-buttong"
            ></Button>
        </div>;
    }

    const onOpenImage = (data) => {
        setCert(data)
        setImageDialog(true)
    }

    const statusColumn = (rowData, column) => {
        return <div>
            {rowData.is_active ?
                <i className="pi pi-check" style={{ 'fontSize': '3em', 'color': 'green' }}></i>
                :
                <i className="pi pi-times" style={{ 'fontSize': '3em', 'color': 'red' }}></i>
            }
        </div>;
    }

    const onChangeSlider = (e, key) => {
        let newValue;
        if (e.target && e.target.nodeName === "INPUT") {
            newValue = e.target.value
        }
        else {
            newValue = e.value
        }
        key(newValue)
    }

    const customStyle = (header, value, key, maxLength) => {
        const customView = (
            <div style={{ marginBottom: '5px' }}>
                {header}
                <Slider
                    value={value}
                    max={maxLength}
                    onChange={e => onChangeSlider(e, key)}
                    style={{ width: '100%' }} />
            </div>
        )
        return customView;
    }

    const resetCustomImage = () => {
        setCertImageWidth(119)
        setCertImageHeight(159)
        setCertImageAxisX(339)
        setCertImageAxisY(98)
    }
    const resetCustomHolderName = () => {
        setHolderNameFontSize(32)
        setHolderNameAxisX(238)
        setHolderNameAxisY(264)
    }
    const resetCustomDate = () => {
        setCertDateFontSize(24)
        setCertDateAxisX(331)
        setCertDateAxisY(309)
    }

    const createUpdateCert = () => {
        const formData = new FormData()
        formData.append('TemplateImages', templateImage)
        formData.set('gid', gid)
        formData.set('cert_name', certName)
        formData.set('cert_text', certText)
        formData.set('carbon_credit_start', carbonCreditStart)
        formData.set('carbon_credit_end', carbonCreditEnd)
        formData.set('cert_template_image', certTemplateImage)
        formData.set('cert_image_width', parseInt(certImageHeight*0.75,0))
        formData.set('cert_image_height', certImageHeight)
        formData.set('cert_image_axis_x', certImageAxisX)
        formData.set('cert_image_axis_y', certImageAxisY)
        formData.set('holder_name_font_size', holderNameFontSize)
        formData.set('holder_name_axis_x', holderNameAxisX)
        formData.set('holder_name_axis_y', holderNameAxisY)
        formData.set('cert_date_font_size', certDateFontSize)
        formData.set('cert_date_axis_x', certDateAxisX)
        formData.set('cert_date_axis_y', certDateAxisY)
        formData.set('is_active', isActive)

        if (gid == 0) {
            dispatch(createCertTemplate(formData)).then((res) => {
                if (res == 'success') {
                    onClearAll()
                    showSuccess()
                    dispatch(getCertTemplateAll())
                    setShowProgress(false)
                } else {
                    showWarn()
                    setShowProgress(false)
                }
            })
        } else {
            dispatch(updateCertTemplate(formData)).then((res) => {
                if (res == 'success') {
                    onClearAll()
                    showSuccess()
                    dispatch(getCertTemplateAll())
                    setShowProgress(false)
                } else {
                    showWarn()
                    setShowProgress(false)
                }
            })
        }
    }

    const onClearAll = () => {
        if (!templateImage) {
            setShowAdd(false)
        }
        setGid(0)
        setCertName('')
        setCertText('')
        setCarbonCreditStart('')
        setCarbonCreditEnd('')
        setCertImageAxisX(304)
        setCertImageAxisY(59)
        setCertImageHeight(190)
        setCertImageWidth(142.5)
        setHolderNameAxisX(274)
        setHolderNameAxisY(269)
        setHolderNameFontSize(26)
        setCertDateAxisX(641)
        setCertDateAxisY(553)
        setCertDateFontSize(26)
        setIsActive(true)
        setTemplateImage(null)
        setCertTemplateImage('')
        setCert(null)
    }

    useEffect(() => {
        if (cert.gid > 0) {
            const canvas = canvasRef.current
            const ctx = canvas.getContext("2d")
            function drawText(text, centerX, centerY, fontSize, fontFace) {
                ctx.save();
                ctx.font = fontSize + 'px ' + fontFace;
                ctx.fillText(text, centerX, centerY + fontSize);
                ctx.restore();
            }
            var img = new Image
            var avt = new Image
            img.crossOrigin = '*';
            avt.crossOrigin = '*';
            img.onload = function () {
                ctx.drawImage(img, 0, 0, 800, 600)
                ctx.drawImage(
                    avt,
                    cert.cert_image_axis_x,
                    cert.cert_image_axis_y,
                    cert.cert_image_width,
                    cert.cert_image_height
                )
                drawText(
                    'xxxxxxxx xxxxxxxxxx',
                    cert.holder_name_axis_x,
                    cert.holder_name_axis_y,
                    cert.holder_name_font_size,
                    'Kanit'
                )
                drawText(
                    moment(Date()).format('DD MMM YYYY'),
                    cert.cert_date_axis_x,
                    cert.cert_date_axis_y,
                    cert.cert_date_font_size,
                    'Kanit'
                )
            }
            img.src = `${API_ROOT}/certification/getfile/${cert.cert_template_image}`
            avt.src = `/assets/avatar/avatarCert.png`
        }
    }, [imageDialog])

    const onAmountChange = (state, value) => {
        const amount = value.target.value;
        if (!amount || amount.match(/^\d{1,}(\.\d{0,4})?$/)) {
            state(amount)
        }
    }

    const showAddCert = () => {
        const newDistance = (
            <div className="p-col-12" >
                <Accordion multiple={showAdd} activeIndex={[0]} onTabChange={() => {
                    setShowAdd(!showAdd)
                    setCert(null)
                }}>
                    <AccordionTab header="เพิ่มใบรับรอง">
                        <div className="p-grid">
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>ชื่อเหรียญ :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ alignSelf: 'center' }}>
                                <CEInputText
                                    className="input-100"
                                    type="text"
                                    value={certName}
                                    placeholder="กำหนดชื่อเหรียญ"
                                    maxlength={25}
                                    required={true}
                                    onChange={(e) => setCertName(e.target.value)}
                                />
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>สถานะ :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ alignSelf: 'center' }}>
                                <Dropdown
                                    style={{ width: '100%' }}
                                    value={isActive}
                                    options={statusItem}
                                    onChange={(e) => setIsActive(e.value)}
                                />
                            </div>
                            <div className="p-col-12 p-md-0 p-lg-4"></div>

                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>ช่วงคาร์บอนเครดิต :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ alignSelf: 'center' }}>
                                <CEInputText
                                    className="input-100"
                                    id="carbonCreditStart"
                                    type="money"
                                    value={carbonCreditStart}
                                    name="carbonCreditStart"
                                    placeholder="ค่าคาร์บอนเครดิตเริ่มต้น"
                                    tooltip="(ทศนิยม 2 ตำแหน่ง)"
                                    required={true}
                                    isvalid={(e) => setIsValidCreditStart(e)}
                                    onChange={(e) => onAmountChange(setCarbonCreditStart, e)}
                                />
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>ถึง</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ alignSelf: 'center' }}>
                                <CEInputText
                                    className="input-100"
                                    id="carbonCreditEnd"
                                    type="money"
                                    value={carbonCreditEnd}
                                    name="carbonCreditEnd"
                                    placeholder="ค่าคาร์บอนเครดิตสิ้นสุด"
                                    tooltip="(ทศนิยม 2 ตำแหน่ง)"
                                    required={true}
                                    isvalid={(e) => setIsValidCreditEnd(e)}
                                    onChange={(e) => onAmountChange(setCarbonCreditEnd, e)}
                                />
                            </div>
                            <div className="p-col-12 p-md-0 p-lg-4"></div>
                            <div className="p-col-12 p-md-6 p-lg-6"></div>
                            <div className="p-col-12">
                                <FileUpload
                                    name="fileCert[]"
                                    onSelect={onSelectTemplateImage}
                                    chooseLabel={isShowSlide ? "เปลี่ยนไฟล์ใบประกาศ" : "แนบไฟล์ใบประกาศ"}
                                    accept="image/*"
                                    mode="basic"
                                    auto={true}
                                />
                            </div>
                            <div className="p-col-12 p-md-12 p-lg-8">
                                {isShowSlide &&
                                    <span style={{
                                        position: 'absolute',
                                        marginLeft: certImageAxisX,
                                        marginTop: certImageAxisY
                                    }}>
                                        <img
                                            src='assets/avatar/avatarCert.png'
                                            width={parseInt(certImageHeight*0.75,0)}
                                            height={certImageHeight}
                                        />
                                    </span>}
                                {isShowSlide &&
                                    <span style={{
                                        position: 'absolute',
                                        marginLeft: holderNameAxisX,
                                        marginTop: holderNameAxisY,
                                        fontSize: holderNameFontSize
                                    }}>
                                        xxxxxxxx xxxxxxxxxx
                                    </span>}
                                {isShowSlide &&
                                    <span style={{
                                        position: 'absolute',
                                        marginLeft: certDateAxisX,
                                        marginTop: certDateAxisY,
                                        fontSize: certDateFontSize
                                    }}>
                                        {moment(Date()).format('DD MMM YYYY')}
                                    </span>}
                                {templateImage &&
                                    <img width="800px" height="600px" src={templateImage.objectURL} />
                                }
                                {!templateImage && certTemplateImage !== '' &&
                                    <img
                                        width="800px"
                                        height="600px"
                                        src={`${API_ROOT}/certification/getfile/${certTemplateImage}`}
                                    />
                                }
                            </div>
                            <div className="p-col-12 p-md-12 p-lg-2"></div>
                            {isShowSlide &&
                                <div className="p-col-12 p-md-12 p-lg-2">
                                    <Accordion activeIndex={activeIndex} onTabChange={(e) => setActiveIndex(e.index)}>
                                        <AccordionTab header="ปรับแต่งรูป">
                                            {customStyle("ขนาดรูป", certImageHeight, setCertImageHeight, 800)}
                                            {customStyle("แกน x", certImageAxisX, setCertImageAxisX, 800)}
                                            {customStyle("แกน y", certImageAxisY, setCertImageAxisY, 600)}
                                            <br />
                                            <Button
                                                icon="pi pi-refresh"
                                                iconPos="left"
                                                label="ค่าเริ่มต้น"
                                                onClick={resetCustomImage} />
                                        </AccordionTab>
                                        <AccordionTab header="ปรับแต่งชื่อ">
                                            {customStyle("ขนาดชื่อ", holderNameFontSize, setHolderNameFontSize, 100)}
                                            {customStyle("แกน x", holderNameAxisX, setHolderNameAxisX, 800)}
                                            {customStyle("แกน y", holderNameAxisY, setHolderNameAxisY, 600)}
                                            <br />
                                            <Button
                                                icon="pi pi-refresh"
                                                iconPos="left"
                                                label="ค่าเริ่มต้น"
                                                onClick={resetCustomHolderName} />
                                        </AccordionTab>
                                        <AccordionTab header="ปรับแต่งวันที่">
                                            {customStyle("ขนาดวันที่", certDateFontSize, setCertDateFontSize, 100)}
                                            {customStyle("แกน x", certDateAxisX, setCertDateAxisX, 800)}
                                            {customStyle("แกน y", certDateAxisY, setCertDateAxisY, 600)}
                                            <br />
                                            <Button
                                                icon="pi pi-refresh"
                                                iconPos="left"
                                                label="ค่าเริ่มต้น"
                                                onClick={resetCustomDate} />
                                        </AccordionTab>
                                    </Accordion>
                                </div>
                            }
                            {gid !== 0 ?
                                <div className="p-col-12">
                                    <Button
                                        type="submit"
                                        label="บันทึกแก้ไข"
                                        icon="fa fa-save"
                                        iconPos="left"
                                        onClick={onUpdate}
                                    />
                                    <Button
                                        className="p-button-warning"
                                        type="submit"
                                        label="ยกเลิก"
                                        icon="fa fa-refresh"
                                        iconPos="left"
                                        onClick={onClearAll}
                                    />
                                </div> :
                                <div className="p-col-12">
                                    <Button
                                        type="submit"
                                        label="บันทึก"
                                        icon="fa fa-save"
                                        iconPos="left"
                                        onClick={onSave}
                                    />
                                    {templateImage &&
                                        <Button
                                            className="p-button-warning"
                                            type="submit"
                                            label="ยกเลิก"
                                            icon="fa fa-refresh"
                                            iconPos="left"
                                            onClick={onClearAll}
                                        />
                                    }
                                </div>}
                        </div>
                    </AccordionTab>

                </Accordion>
            </div>
        );
        return newDistance;
    }

    const showCertTable = () => {
        let deleteDialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ใช่" icon="pi pi-check" onClick={onCertDelete} />
            <Button label="ไม่ใช่" icon="pi pi-times" onClick={() => setDeleteDialog(false)} className="p-button-warning" />
        </div>;
        const certTable = (
            <div className="p-col-12">
                <Panel header="รายการใบรับรอง" >
                    <DataTable
                        value={certificationList}
                        paginatorPosition="bottom"
                        paginator={true} rows={15}
                        rowsPerPageOptions={[5, 10, 15]}
                        responsive={true}
                        emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)}>
                        <Column field="cert_name" header="ชื่อเหรียญ" sortable={true} filter={true} />
                        <Column field="carbon_credit_start" header="ค่าคาร์บอนเครดิตเริ่มต้น" sortable={true} filter={true} className="p-text-center" />
                        <Column field="carbon_credit_end" header="ค่าคาร์บอนเครดิตสิ้นสุด" sortable={true} filter={true} className="p-text-center" />
                        <Column field="is_active" body={statusColumn} header="สถานะ" sortable={true} className="p-text-center" />
                        <Column body={openImage} header="รูปแบบใบรับรอง" className="p-text-center" />
                        <Column field="gid" body={actionTemplate} header="จัดการ" className="p-text-center" style={{ width: '8em' }} />
                    </DataTable>
                    <div>
                        <canvas
                            ref={canvasRef}
                            width={800} height={600}
                            hidden
                        />
                    </div>
                </Panel>
                <Dialog visible={imageDialog} header="ภาพใบรับรอง" modal={true} onHide={() => setImageDialog(false)}>
                    <div className="p-col-12 p-md-12 p-lg-8">
                        <canvas
                            ref={canvasRef}
                            width={800} height={600}
                        />
                    </div>
                </Dialog>
                <Dialog visible={deleteDialog} width="300px" header="ยืนยันการลบข้อมูล"
                    modal={true} footer={deleteDialogFooter} onHide={() => setDeleteDialog(false)}>
                    {
                        cert &&
                        <div className="p-grid p-fluid" >
                            <div className="p-col-12"><span>คุณต้องการจะลบข้อมูลต่อไปนี้</span></div>
                            <div className="p-col-4" ><label htmlFor="cert_name">ชื่อเหรียญ :</label></div>
                            <div className="p-col-8" >
                                {cert.cert_name}
                            </div>

                            <div className="p-col-4" ><label htmlFor="more_than_km">ค่าคาร์บอนเครดิตเริ่มต้น :</label></div>
                            <div className="p-col-8" >
                                {cert.carbon_credit_start}
                            </div>

                            <div className="p-col-4" ><label htmlFor="less_than_km">ค่าคาร์บอนเครดิตสิ้นสุด :</label></div>
                            <div className="p-col-8" >
                                {cert.carbon_credit_end}
                            </div>
                        </div>
                    }
                </Dialog>
            </div>
        )
        if (!showProgress)
            return certTable;
    }

    return (
        <div className="p-grid ">
            <Growl ref={growl} />
            <div className="p-col-12">
                <h1>ตั้งค่าใบรับรอง/เหรียญ</h1>
            </div>
            {showAddCert()}
            {showProgressSpinner()}
            {showCertTable()}
        </div>
    )
}
