import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";

import { ProgressSpinner } from 'primereact/progressspinner';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import { ToggleButton } from 'primereact/togglebutton';
import * as ServerAPI from './service';
import Dialog from 'primereact/dialog'
import {Panel} from 'primereact/panel';
import { API_ROOT ,WEB_ROOT} from '../../constants/api'
import * as qs from 'query-string';
class RegisterSocialLandingContainer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			visibleErrorDialogy: false,
            errorMessenger:"",
            isLoading:true,
        };
        
        
       
	}
    componentDidMount() {
        this.bindRegister = this.bindRegister(this);
        
    }
    bindRegister() {
        console.log("1 componentDidMount()");
      
        var model = JSON.parse(localStorage.getItem('landing-regis-response'));
        if(model){
            if(this.props.location){
                if(this.props.location.search){
                    let _q = qs.parse(this.props.location.search);
                    if(_q)if(_q.type){
                        model.wastetype = _q.type;
                    }
                }
            }
            if(model.socialtype=="facebook"){
                this.facebookResponse(model.response,model.wastetype);
            } else if(model.socialtype=="google"){
                this.googleResponse(model.response,model.wastetype);
            }

        }

    }

	facebookResponse(response, wastetype) {
        console.log("facebookResponse")
        console.log(response);
        if (response.status == "unknown") {
            this.setState({ errorMessenger: 'เกิดข้อผิดพลาดจาก facebook' });
            this.setState({ isLoading: false });
        } else if (response.accessToken) {
            localStorage.removeItem('auth-facebook');
            localStorage.setItem('auth-facebook', JSON.stringify(response));
            var authModel = {
                Provider: "Facebook",
                Token: response.accessToken
            }
            
            ServerAPI.authenExternal(authModel).then((res) => {

                if (res.response)
                    if (res.response.data)
                        if (res.response.data.Error) {
                            this.setState({ errorMessenger: res.response.data.Error });
                            this.setState({ isLoading: false });
                        }

                if (res.isVerified) {
                    if (wastetype == 1) window.location = "/#/register-wastegen";
                    if (wastetype == 2) window.location = "/#/register-wastetran";
                    if (wastetype == 3) window.location = "/#/register-wastepro";
                }

            }).catch((error) => {
                //this.growl.show({severity: 'error', summary: 'ข้อความจากระบบ', detail: 'เกิดข้อผิดพลาดในการเชื่อมต่อ'});
            })
        } else {
            this.setState({ errorMessenger: 'เกิดข้อผิดพลาดจาก facebook' });
            this.setState({ isLoading: false });
        }

	};
	
	googleResponse(response, wastetype){
        if (response.error) {
            this.setState({ errorMessenger: 'เกิดข้อผิดพลาดจาก google' });
            this.setState({ isLoading: false });
        } else if (response.tokenId) {
            localStorage.removeItem('auth-google');
            localStorage.setItem('auth-google', JSON.stringify(response));
            var authModel = {
                Provider: "Google",
                Token: response.tokenId
            }

            ServerAPI.authenExternal(authModel).then((res) => {


                if (res.response)
                    if (res.response.data)
                        if (res.response.data.Error) {
                            this.setState({ errorMessenger: res.response.data.Error });
                            this.setState({ isLoading: false });
                        }

                if (res.isVerified) {
                    if (wastetype == 1) window.location = "/#/register-wastegen";
                    if (wastetype == 2) window.location = "/#/register-wastetran";
                    if (wastetype == 3) window.location = "/#/register-wastepro";
                }

            }).catch((error) => {
                //this.growl.show({severity: 'error', summary: 'ข้อความจากระบบ', detail: 'เกิดข้อผิดพลาดในการเชื่อมต่อ'});
            })
        } else {
            this.setState({ errorMessenger: 'เกิดข้อผิดพลาดจาก google' });
            this.setState({ isLoading: false });
        }

	}
	onClick(){
        window.open(WEB_ROOT,"_self");
        //window.location = '#/';
    }
	render() {


		const { username, password } = this.state

		const { authentication, loginProps } = this.props
		if (authentication && authentication.accessToken) {
			if (!authentication.userType) {
				if (!authentication.isLoading) {
					loginProps.getUserType();
				}
			}
		}

		if (authentication.logedIn) {
			window.location = '#/' + localStorage.getItem('user-type')
        }
        
        

		return (
            <div className="login-body" >
				<div className="p-grid p-fluid"  style={{textAlign:'center'}}>
                    <div className="p-col-12 p-sm-12 p-md-1 p-lg-3" >
                    </div>
					<div className="p-col-12 p-sm-12 p-md-6 p-lg-6" >
                                    <br/>
									<div className="p-col-12">
                                        {!this.state.isLoading && 
                                        <Panel header="ข้อความจากระบบ">
                                           {this.state.errorMessenger}
                                           <br />
                                           <br />
                                           <Button onClick={this.onClick} label="กลับหน้าหลัก" className="p-button-warning" style={{width:'10em', marginRight:'.25em'}} />
                                        </Panel>
                                        }
										{this.state.isLoading && <ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>}

									</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	authentication: state.authentication

})

const mapDispatchToProps = dispatch => ({

	//loginProps: bindActionCreators(UserService, dispatch)

})


export default
	connect(
		mapStateToProps,
		mapDispatchToProps, null, { forwardRef: true })(RegisterSocialLandingContainer)