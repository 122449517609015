import {
  DHB_USER
} from '../service/actionType'

export const initialState = {
  dhbUsers: {},

}

export default (state = initialState, action) => {
  switch (action.type) {
    case DHB_USER.REQUEST:
   
    {
      return {
        ...state,
        isFetching: true,
        error: null,
      }
    }
    case DHB_USER.FAILURE:
   
   {
      return {
        ...state,
        isFetching: false,
        error: action.error,
      }
    }

    case DHB_USER.SUCCESS: 
    {
      return {
        ...state,
        isFetching: false,
        error: null,
        dhbUsers: action.payload
      }
    }
    
    
    
    default:
      return state
  }
}
