import React, { Component } from 'react';
import { Redirect } from 'react-router';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import Moment from 'react-moment';
import 'moment-timezone';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import {Avatar} from '../../features/widget/Avatar';
import { API_ROOT } from '../../constants/api';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Panel } from 'primereact/components/panel/Panel';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { MapWithASearchBoxAndMarkers } from '../../features/map/MapWithASearchBoxAndMarkers'
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import PropsRoute from '../../PropsRoute';
import * as ServerAPI from './service';
import * as MasterService from '../../service/MasterService'
import WastetranChatWithU1 from './WastetranChatWithU1';

 class WastetranManageOrder extends Component {

    constructor(props) {
        super(props);
        this.state = {
            brandSuggestions: null,
            mapGenMarker:{},
            mapGenMarkers:[],
            mapGenZoom:11,
            mapGenCenter: {lat: 13.7734283, lng: 100.57383099999993},
            brands: ['1111', '2222', '3333', '4444', '5555', '6666', '7777', '8888', '9999'],
            showAddMoreProduct: false,
            orderDetail: [],
            wasteTypeSelected: '',
            productSelected: '',
            amountSelected: '',
            orderID: '',
            status: 1000,
            statusItem: [
                 { label: 'เลือกทั้งหมด', value: 1000 },
                // { label: 'รอนัดหมาย', value: 1 },
                { label: 'รอทีมขนส่ง', value: 2 },
                { label: 'รอส่งสินค้า', value: 3 },
                // { label: 'รอปิด Order', value: 4 },
                // { label: 'ยกเลิก', value: 99 },
                // { label: 'ผิดนัด', value: 999 },
            ],
            date1: null,
            date2: null,
            orderCode: null,
            reportSearchOrders: [],
            showReportPanel: true,
            showProgress: false,
        };
        this.addDataToTable = this.addDataToTable.bind(this);
        this.showAddProduct = this.showAddProduct.bind(this);
        this.onWasteTypeChange = this.onWasteTypeChange.bind(this);
        this.onproductsChange = this.onproductsChange.bind(this);
        this.onOrderIDChanged = this.onOrderIDChanged.bind(this);
        this.onSelectOrderID = this.onSelectOrderID.bind(this);
        this.onFileWasteChanged = this.onFileWasteChanged.bind(this);
        this.onDate1Change = this.onDate1Change.bind(this);
        this.onDate2Change = this.onDate2Change.bind(this);
        this.searchOrder = this.searchOrder.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.onOrderSelected = this.onOrderSelected.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.clearAllDataInput = this.clearAllDataInput.bind(this);
        this.quantityEditor = this.quantityEditor.bind(this);
        this.memoEditor = this.memoEditor.bind(this);
        this.toFormatDateTime = this.toFormatDateTime.bind(this);
        
    }

    

    //>Life Cycle : Initial Render
    componentDidMount() {
        const { masterServiceProps} = this.props
        masterServiceProps.getProductType();
        console.log("componentDidMount")
     }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("componentWillReceiveProps")
     }
    shouldComponentUpdate(nextProps, nextState) { 
        console.log("shouldComponentUpdate")
        return true 
    }
    componentWillUpdate(nextProps, nextState) { 
        console.log("componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) { 
        console.log("componentDidUpdate")

    }

    itemOrderIDTemplate(item) {
        return (
            <div className="p-clearfix">
                {item}
            </div>
        );
    }

    //auto complate handle (ค้นหา Order ID)
    suggestOrderID(event) {
        console.log(event);
    }
    onOrderIDChanged(code) {
        //console.log(code)
        if(code.trim()!=""){
            this.setState({ orderID: code });
            if (code.length >= 1) {
                ServerAPI.autoComplateOrderID(code).then((res) => {
                    console.log(res);
                    this.setState({ orderIDSuggestions: res });
                }).catch((error) => {
                })
            }
        }else this.setState({ orderID: code });
    }
    onSelectOrderID(item) {
        this.setState({ orderID: item.value });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }

    onDate1Change(e){
        //console.log(e.value.toISOString())
        if(this.state.date2 != null){
            if(e.value <= this.state.date2){
                this.setState({ date1: e.value })
            }
        }else  
            this.setState({ date1: e.value })
    }
    onDate2Change(e){
        if(this.state.date1 <= e.value){
            this.setState({ date2: e.value })
        }
    }

    searchOrder(){
        this.setState({ showProgress: true})
        var reportSearchList = {}
        this.state.reportSearchOrders.push(reportSearchList)
        this.setState({ reportSearchOrders: [] });
        var searchModel = {
            order_code: this.state.orderID,
            order_status: this.state.status,
            admin_code: "",
           };

        if(this.state.date1 != null){
            //Bankok Time zone (+7hrs)
            var date1 = new Date(this.state.date1.getTime() + 7*60*60000);
            searchModel.pickup_date_start = date1.toISOString();
        }
        if(this.state.date2 != null){
            //Bankok Time zone (+7hrs)
            var date2 = new Date(this.state.date2.getTime() + 7*60*60000);
            searchModel.pickup_date_end = date2.toISOString();
        }

        ServerAPI.searchOrder(searchModel).then((res) => {
            console.log("response");
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    console.log(res);
                    if(res.length>0){
                        this.setState({reportSearchOrders: res})
                        this.setState({ showProgress: false})
                    }else
                     this.showWarn("ไม่พบข้อมูล");
                     this.setState({ showProgress: false})
                    // this.showSuccess();
                    // this.clearAllDataInput();
                } else {
                    this.showWarn();
                }

            } else {
                this.showWarn();
            }
        }).catch((error) => {
        })
    }
    clearSearchInput(){
        this.setState({orderID: ''});
        this.setState({date1: null});
        this.setState({date2: null});
        this.setState({status: 1000});
        this.setState({orderCode: null});
        var reportSearchList = {}
        //this.state.reportSearchOrders.push(reportSearchList)
        this.setState({reportSearchOrders: []});
        this.setState({ showProgress: false})
        //this.clearAllDataInput();
    }
    onOrderSelected(e){

        console.log(e.value);
        this.setState({orderSelection: e.value})

        this.setState({orderCode: e.value.order_code})
        this.getOrder(e.value.order_code) //get 1 order from table for update
    }
    getOrder(code){
        this.setState({showProgress: true});
        this.setState({ showReportPanel: false})
        ServerAPI.getOrderDetail(code).then((obj) => {
            console.log(obj);
            var newList = obj.orderDetail
            if(obj.order_status == 2)
            newList.forEach((item,index) => {
                let _newList = {
                    ...item,
                    pickup_quantity_kk: item.quantity_kk
                }
                newList[index] = _newList
            });
            this.setState({ orderDetail: newList}) //ตาราง รายการสินค้า
            this.setState({ orderData: obj})
            this.setState({ orderStatus: obj.order_status})

            this.setState({ genID: obj.u1_id})
            this.setState({ genName: obj.u1_fullname})
            this.setState({ genPhone: obj.u1_telephone1})
            this.setState({ genAddress: obj.u1_address})
            this.setState({ genImg: obj.u1_img})

            this.setState({ tranID: obj.u2_id})
            this.setState({ tranName: obj.u2_fulname})

            this.setState({ proID: obj.u3_id})
            this.setState({ proName: obj.u3_fullname})
            this.setState({ proPhone: obj.u3_telephone1})
            this.setState({ proAddress: obj.u3_address})
            this.setState({ proImg: obj.u3_img})


            const markers = [];
            markers.push({ lat: obj.u1_lat, lng: obj.u1_lng });
            this.setState({ mapGenMarkers: markers })

            var proMarder = { lat: obj.u3_lat, lng: obj.u3_lng };
            this.setState({ mapGenMarker: proMarder })

            var center = { lat: obj.u1_lat, lng: obj.u1_lng };
            this.setState({ mapGenCenter: center })
            if (this.state.mapGenZoom == 11) {
                this.setState({ mapGenZoom: 12 });
            } else {
                this.setState({ mapGenZoom: 11 });
            }

            this.setState({ showProgress: false });
            this.setState({ showReportPanel: true})
        }).catch((error) => {
        })

    }


    onFileWasteChanged(e) {
        console.log(e)
        //this.setState({ fileImgIden: e.target.files[0] });
        var list = [];
        for (let index = 0; index < e.files.length; index++) {
            const file = e.files[index];
            list.push(file);
        }
        this.setState({ fileImgWaste: list });
    }

    onWasteTypeChange(e) {

        this.setState({ wasteTypeSelected: e.value });
        this.setState({ productTypeCode: e.value.product_type_code });

        const { masterServiceProps,product } = this.props
        masterServiceProps.getProduct(e.value.product_type_code);
    }
    onproductsChange(e) {
        this.setState({ productSelected: e.value });
    }

    showWastegen(){
        const genPersonName = (
            <div className="p-col-12">
                <span style={{ color: 'red' }}>{this.state.genName} </span><br/>
                <span>{this.state.genAddress} <br/> เบอร์ติดต่อ : {this.state.genPhone}</span>
            </div>
        );
        return genPersonName;
    }
    showWastepro(){
        const proPersonName = (
            <div className="p-col-12">
                <span style={{ color: 'green' }}>{this.state.proName} </span><br/>
                <span>{this.state.proAddress} <br/> เบอร์ติดต่อ : {this.state.proPhone}</span>
            </div>
        );

        return proPersonName;
    }

    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div className="p-col-12 p-md-8 p-lg-9"></div>);
    }

    //edit table handle
    onEditorQuantityChange(props, value) {
        if(value== ""){// null set to 0
            value = '0';
        }else{
            var regex = /^[0-9]*(\.[0-9]{0,2})?$/;
            if (!regex.test(value)) { // set 2 decimal if more decimal
                if(value % 1 !== 0){
                    value = parseFloat(value).toFixed(2)
                }
            }
            if(value.indexOf('.') < 1){//no 0 leading
                value = parseFloat(value)
            }     
        }
        let updatedCars = [...props.value];
        updatedCars[props.rowIndex][props.field] = value;
        this.setState({orderDetail: updatedCars});
    }
    onEditorTextChange(props, value) {
        let updatedCars = [...props.value];
        updatedCars[props.rowIndex][props.field] = value;
        this.setState({orderDetail: updatedCars});
    }
    inputQuantityEditor(props, field) {
        return <InputText type="text" keyfilter="money" value={props.rowData[field]} onChange={(e) => this.onEditorQuantityChange(props, e.target.value)} />;
    }
    inputTextEditor(props, field) {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorTextChange(props, e.target.value)} />;
    }
    quantityEditor(props) {
        return this.inputQuantityEditor(props, 'pickup_quantity_kk');
    }
    memoEditor(props) {
        return this.inputTextEditor(props, 'pickup_remark');
    }

    addDataToTable(){
        var isNotinput = (this.state.wasteTypeSelected == '' || 
                    this.state.productSelected == '' || 
                    this.state.amountSelected == '' 
                    );

        if(isNotinput){
            this.showWarn();
        }else{
            var OrderDetailList = {
                gid: this.state.gid|0,
                product_type_code: this.state.wasteTypeSelected.product_type_code,
                product_code: this.state.productSelected.product_code,
                product_name: this.state.productSelected.product_name,
                // wasteType: this.state.wasteTypeSelected.product_type_name,
                // amount: parseFloat(this.state.amountSelected).toFixed(2),
                // appraisedPrice: parseFloat(this.state.appraisedPriceSelected).toFixed(2),
                // salePrice: parseFloat(this.state.salePriceSelected).toFixed(2),    

                pickup_quantity_kk: parseFloat(this.state.amountSelected).toFixed(2),
                // estimate_price_per_unit: parseFloat(this.state.appraisedPriceSelected).toFixed(2),
                // price_per_unit: parseFloat(this.state.salePriceSelected).toFixed(2),
                pickup_remark: this.state.memo,
            }
            this.state.orderDetail.push(OrderDetailList)

            this.setState({wasteTypeSelected: ''})
            this.setState({productSelected: ''})
            this.setState({amountSelected: ''})
            this.setState({memo: ''})
            console.log(this.state.orderDetail)
            this.showSuccess();
        }
    }
    toFormatDateTime(dateTime){
        let _dateTime = new Date(dateTime);
        let year = _dateTime.getYear();

        if(year==-1899)
            return <div><span></span></div>;
        else 
        return <div><Moment format="DD/MM/YYYY">
                {dateTime}
                    </Moment><br/><Moment format="HH:mm">
                        {dateTime}
                    </Moment></div>; 
    }
    to_transfer_date(rowData, column){
       return this.toFormatDateTime(rowData.delivery_date);
    }
    to_requested_start_date(rowData, column){
        return this.toFormatDateTime(rowData.pickup_start_date);
    }
    to_requested_end_date(rowData, column){
        return this.toFormatDateTime(rowData.pickup_end_date);
    }

    showSearchPanel() {
        const searchResult = this.showSearchResult();
        return (
            <div className="p-col-12">
            <Accordion multiple={true} activeIndex={[0, 1]}>
                <AccordionTab header="เงื่อนไขค้นหาเพื่อจัดการ Orders">
                    <div className="p-grid p-justify-start" >
                        <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>Order ID :</label>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.orderID}
                                    suggestions={this.state.orderIDSuggestions}
                                    onSelect={this.onSelectOrderID}
                                    completeMethod={this.suggestOrderID.bind(this)}
                                    onChange={(e) => this.onOrderIDChanged(e.value)}
                                    itemTemplate={this.itemOrderIDTemplate.bind(this)}
                                    placeholder="Order ID " />
                        </div>
                        <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <label>สถานะ :</label>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-7" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <Dropdown
                                value={this.state.status}
                                options={this.state.statusItem}
                                onChange={(e) => { this.setState({ status: e.value }) }}
                            >
                            </Dropdown>
                        </div>
                        <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <label>วันรับสินค้า :</label>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <Calendar dateFormat="dd/mm/yy"  showIcon={true} value={this.state.date1} onChange={this.onDate1Change}  ></Calendar>
                        </div>
                        <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <label>ถึงวันที่ :</label>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-7" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <Calendar dateFormat="dd/mm/yy"  showIcon={true} value={this.state.date2} onChange={this.onDate2Change} ></Calendar>
                        </div>
                        
                        <div className="p-col-12">
                            <br/>
                            <Button 
                                style={{marginRight:"20px"}}
                                label="ค้นหา" 
                                icon="fa fa-search" 
                                iconPos="left" 
                                onClick={this.searchOrder} />
                        
                            <Button
                                style={{ backgroundColor: '#f0ad4e' }}
                                type="submit"
                                label="ยกเลิก"
                                onClick={this.clearSearchInput}
                            />
                        </div>
                    </div>
                    {searchResult}
                </AccordionTab>
            </Accordion>
            </div>
        );
    }
    showSearchResult() {
        if (this.state.reportSearchOrders.length == 0 || !this.state.showReportPanel) {
            return (<div></div>);
        } else
            return (
            <div>
                <div className="p-col-12">
                <Panel header="รายงานตรวจสอบ Orders" toggleable={true} >
                    <DataTable value={this.state.reportSearchOrders} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={5} rowsPerPageOptions={[5, 10, 15]}
                        responsive={true} selection={this.state.orderSelection} onSelectionChange={e => this.onOrderSelected(e)}>
                        <Column field="order_code" header="รายการ" sortable={true} />
                        <Column field="u1_fullname" header="ชื่อ U1" sortable={true} />
                        <Column field="u3_fullname" header="ชื่อ U3" sortable={true} />
                        <Column field="pickup_start_date" body={this.to_requested_start_date.bind(this)} header="วันนัดรับเริ่ม" style={{width:'10em'}}className="p-text-center"/>
                        <Column field="pickup_end_date" body={this.to_requested_end_date.bind(this)} header="วันนัดรับสิ้นสุด" style={{width:'10em'}} className="p-text-center"/>
                        <Column field="delivery_date" body={this.to_transfer_date.bind(this)} header="วันนัดส่ง" style={{width:'10em'}} className="p-text-center"/>
                        <Column field="delivery_cost" header="ค่าขนส่ง (บาท)" sortable={true} className="p-text-right"/>
                        <Column field="order_status_name" header="สถานะ" sortable={true} className="p-text-center"/>
                    </DataTable>
                </Panel>
                </div>
            </div>
            );
    }
    openChat = (type) => {
        if(type === 'gen'){
            this.setState({redirectToChatU1: true});
        }
        if(type === 'pro'){
            this.setState({redirectToChatU3: true});
        }
        
    }

    transportInfoContent(){
        const wastegenDetail = this.showWastegen();
        const wasteproDetail = this.showWastepro();

        const transportInfo = (
            <div className="p-grid">
                <div className="p-col-12" >
                    <label>Order Id : {this.state.orderCode}</label>
                </div>
                <div className="p-col-12" >
                    <label>ผู้รักษ์โลก</label>
                </div>
                <div className="p-col-12 pg-md-12 p-lg-3">
                </div>
                <div className="p-col-12 pg-md-12 p-lg-9">
                    <div className="p-col-12">
                    <Avatar src={`${API_ROOT}/wastegen/getfile/${this.state.genImg}`}/>
                    </div>
                    {/* <a  href="#/Wastetran/chat-wastegen"> */}
                        <div className="button-demo">
                        {this.state.orderStatus == 2 && 
                        <Button label="แชท" onClick={()=>this.openChat('gen')} icon="fa fa-comment" className="p-button-rounded " style={{marginLeft:'20px'}}/>}
                        </div>
                    {/* </a> */}
                    {wastegenDetail}
                </div>

                <div className="p-col-12" >
                    <label>ศูนย์จัดการฯซีซาเล้ง</label>
                </div>
                <div className="p-col-12 pg-md-12 p-lg-3">
                </div>
                <div className="p-col-12 pg-md-12 p-lg-9">
                    <div className="p-col-12">
                        <Avatar src={`${API_ROOT}/wastetran/getfile/${this.state.proImg}`} />
                    </div>
                    {/* <a  href="#/Wastetran/chat-wastepro"> */}
                        <div className="button-demo">
                        {this.state.orderStatus <= 3 && 
                        <Button label="แชท" onClick={()=>this.openChat('pro')} icon="fa fa-comment" className="p-button-rounded " style={{marginLeft:'20px'}}/>}
                        </div>
                    {/* </a> */}
                    {wasteproDetail}
                    
                </div>

                <div className="p-col-12">
                    <MapWithASearchBoxAndMarkers 
                        marker={this.state.mapGenMarker} 
                        onBoundsChanged={this.mapChanged} 
                        markers={this.state.mapGenMarkers} 
                        center={this.state.mapGenCenter} 
                        zoom={this.state.mapGenZoom}    
                    />
                </div>
            </div>
        );
        return transportInfo;
    }

    orderDetailContent(){
        const orderDetail = (
            <div className="p-col-12">
                <Panel style={{ textAlign: 'center' }} header="รายการสินค้ารับ" >
                    <DataTable value={this.state.orderDetail} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={5} rowsPerPageOptions={[5, 10, 15]}
                        responsive={true} selection={this.state.dataTableSelection1} onSelectionChange={event => this.setState({ dataTableSelection1: event.value })}>
                        <Column field="product_name" header="รายการ" sortable={true} />
                        <Column field="pickup_quantity_kk" header="จำนวน (Kg)" editor={this.quantityEditor}/>
                        <Column field="pickup_remark" header="หมายเหตุ" editor={this.memoEditor}/>
                    </DataTable>
                    {/* <span style={{ color: 'red' }}>{this.state.tableErrorMessage}</span> */}
                </Panel>
            </div>
        );
        return orderDetail;
    }

    addProductContent(){
        const {
            productType,
            product,
        } = this.props
        const addProduct = (
            <div className="p-col-12">
                <Accordion multiple={this.state.showAddMoreProduct} activeIndex={[0]} onTabChange={this.showAddProduct}>
                    <AccordionTab header="รายการรับเพิ่มเติม">
                    <div className="p-grid">
                            <div className="p-col-12">
                            <label className="p-col-12 ">ประเภทขยะรีไซเคิล :</label>
                                <Dropdown
                                    style={{ width: '100%' }}
                                    value={this.state.wasteTypeSelected}
                                    options={productType}
                                    onChange={this.onWasteTypeChange}
                                    placeholder="เลือกประเภทขยะรีไซเคิล"
                                    optionLabel="product_type_name"
                                    dataKey="product_type_code"
                                    />
                            </div>
                            <div className="p-col-12">
                                <label className="p-col-12 ">สินค้า :</label>
                                <Dropdown
                                    style={{ width: '100%' }}
                                    value={this.state.productSelected}
                                    options={product}
                                    onChange={this.onproductsChange}
                                    placeholder="เลือกสินค้า"
                                    optionLabel="product_name"
                                    dataKey="product_code" 
                                    />
                            </div>
                            <div className="p-col-12">
                                <label className="p-col-12 ">จำนวน (กิโลกรัม)</label>
                                <InputText value={this.state.amountSelected} keyfilter="money" placeholder="จำนวน" type="text" className="input-100" onChange={(e) => this.setState({amountSelected: e.target.value})} />
                            </div>
                            <div className="p-col-12">
                                <label className="p-col-12 ">หมายเหตุ</label>
                                <InputText value={this.state.memo} placeholder="หมายเหตุ" type="text" className="input-100" onChange={(e) => this.setState({memo: e.target.value})} />
                            </div>

                            <div className="p-col-12 ">
                                <Button
                                    style={{ float: "left" }}
                                    label="เพิ่มรายการ"
                                    icon="fa fa-plus"
                                    iconPos="left"
                                    onClick={this.addDataToTable}
                                />
                            </div>
                    </div>
                    </AccordionTab>
                </Accordion>
            </div>
        );
        return addProduct;
    }

    showAddProduct(){
        if(this.state.showAddMoreProduct){
            this.setState({showAddMoreProduct: false})
        }else{
            this.setState({showAddMoreProduct: true})
        }
    }

    showOrderDetail() {
        const {           
            provinces,         
            productType,
            product,
        } = this.props;

        const contentTransportInfo = this.transportInfoContent();
        const contentOrderDetail = this.orderDetailContent();
        const contentAddProduct = this.addProductContent();

        if(this.state.orderCode==null){
            return (<div></div>);
        }else
        return (
            <div className="p-grid">
                <div className="p-col-12 p-lg-6">
                    <div className="card card-w-title">
                        <Accordion multiple={true} activeIndex={[0]}>
                            <AccordionTab header="ข้อมูลขนส่ง">
                                {contentTransportInfo}
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>

                <div className="p-col-12 p-lg-6">
                    <div className="card card-w-title">
                        <h1>รายละเอียดสินค้า</h1>
                        {contentOrderDetail}
                        <Button
                            label="เพิ่มรายการ"
                            icon="pi pi-plus"
                            iconPos="left"
                            onClick={this.showAddProduct}/>
                        {contentAddProduct}
                        <label className="p-col-12 ">รูปถ่ายขยะรีไซเคิล</label>
                            <div className="p-col-12">
                                <FileUpload name="wastes[]"
                                    onSelect={(e) => this.onFileWasteChanged(e)}
                                    mode="basic"
                                    chooseLabel="แนบไฟล์"
                                    cancelLabel="ยกเลิก"
                                    multiple={true}
                                    accept="image/*"
                                    maxFileSize={10000000} />
                            </div>
                    </div>
                </div>
                <div className="p-col-12">
                    <Button
                        className="p-col-12"
                        label="บันทึก"
                        icon="pi pi-check"
                        iconPos="left"
                        onClick={this.onSubmit} />
                </div>
            </div>
        );
    }
    

    onSubmit(){
        var productList = this.state.orderDetail;
        console.log(productList)
        var sum = 0;
        for (let i = 0; i < productList.length; i++) {
            const pickup_quantity_kk = Number(productList[i].pickup_quantity_kk);
            sum += pickup_quantity_kk;
        }
        console.log(sum)
        var inputAll = !(this.state.orderCode == '' || this.state.orderDetail == '' );
        if (!inputAll) {
            this.showWarn();
        } else {
            if(sum!=0)
                this.updateOrder();
            else this.showWarn("กรุณาติดต่อผู้ดูแลระบบ เพื่อขอยกเลิกรายการ");
            console.log(this.state.orderDetail)
        }
    }
    updateOrder() {
        const formData = new FormData();
        if (this.state.fileImgWaste != null)
            for (let index = 0; index < this.state.fileImgWaste.length; index++) {
                const file = this.state.fileImgWaste[index];
                formData.append('ProductImages', file);
            }
            
        formData.set('OrderDetailListString', JSON.stringify(this.state.orderDetail) || null);
        formData.set('order_code', this.state.orderCode || 0);
      

        ServerAPI.updateOrder(formData).then((res) => {
            console.log("response");
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.showSuccess();
                    this.clearAllDataInput();
                    this.searchOrder();
                } else {
                    this.showWarn();
                }

            } else {
                this.showWarn();
            }
        }).catch((error) => {
        })
    }
    clearAllDataInput() {
        this.setState({orderCode:null});
        this.setState({ brand: '' });
        this.setState({ wasteTypeSelected: '' });
        this.setState({ productSelected: '' });
        this.setState({ amountSelected: '' });
        this.setState({ memo: ''});
        var OrderDetailList = {}
        this.state.orderDetail.push(OrderDetailList)
        this.setState({ orderDetail: [] });
        this.setState({ genName: '' });
        this.setState({ genPhone: '' });
        this.setState({ genAddress: '' });
        this.setState({ genImg: '' });
        this.setState({ proName: '' });
        this.setState({ proImg: '' });
        this.setState({ proAddress: '' });
        this.setState({ proPhone: '' });
        this.setState({ mapGenMarker: null });
        var markers = {}
        this.state.mapGenMarkers.push(markers)
        this.setState({ mapGenMarkers: [] });
        this.setState({ mapProMarker: {} });
        this.setState({ mapProMarkers: [] });
        this.setState({ mapGenCenter: { lat: 13.7734283, lng: 100.57383099999993 } });
        this.setState({ mapProCenter: { lat: 13.7734283, lng: 100.57383099999993 } });
        this.setState({ mapGenZoom: 11 });
        this.setState({ mapProZoom: 11 });
        this.setState({ fileImgWaste: '' });

    }

    handleOnChat = (otherWasteType, path, userWasteType) => {
        console.log(this.state.orderData)
        let roomType = ''
        let senderId = ''
        let receiverId = ''
        let senderNumberType = ''
        let receiverImage = ''
        let header = ''
        if (userWasteType === 'wastegen' && otherWasteType === 'wastetran') {
          roomType = 'pickup'
          senderId = this.state.orderData.u1_uid
          receiverId = this.state.orderData.u2_uid
          receiverImage = this.state.orderData.u2_img
          senderNumberType = '1'
          header = 'แชทกับทีมขนส่ง'
        } else if (userWasteType === 'wastetran' && otherWasteType === 'wastegen') {
          roomType = 'pickup'
          senderId = this.state.orderData.u2_uid
          receiverId = this.state.orderData.u1_uid
          receiverImage = this.state.orderData.u1_img
          senderNumberType = '2'
          header = 'แชทกับผู้รักษ์โลก'
        } else if (userWasteType === 'wastetran' && otherWasteType === 'wastepro') {
          roomType = 'delivery'
          senderId = this.state.orderData.u2_uid
          receiverId = this.state.orderData.u3_uid
          receiverImage = this.state.orderData.u3_img
          senderNumberType = '2'
          header = 'แชทกับศูนย์จัดการฯซีซาเล้ง'
        } else if (userWasteType === 'wastepro' && otherWasteType === 'wastetran') {
          roomType = 'delivery'
          senderId = this.state.orderData.u3_uid
          receiverId = this.state.orderData.u2_uid
          receiverImage = this.state.orderData.u2_img
          senderNumberType = '3'
          header = 'แชทกับทีมขนส่ง'
        }
        const roomId = `${this.state.orderCode}-${roomType}`
        return <Redirect to={{
            pathname: path,
            state: { 
                roomId: roomId,
                orderCode: this.state.orderCode,
                senderId: senderId, // sender uid (U2 uid)
                receiverId: receiverId, // receiver uid (U1 uid)
                receiverImage: receiverImage, // receiver image name (U1 img)
                receiverWasteType: otherWasteType,// receiver user type (U1 type)
                senderNumberType: senderNumberType,// receiver user type (U1 type)
                header: header
             }
          }}/>
    }


    render() {

        const searchPanel = this.showSearchPanel();
        const showProgress = this.showProgressSpinner();
        const orderDetail = this.showOrderDetail();

        if (this.state.redirectToChatU1) {
            return this.handleOnChat(
                "wastegen", //other type name
                "/wastetran/chat", // path
                "wastetran" //sender type name
                );
        }
        if (this.state.redirectToChatU3) {
            return this.handleOnChat(
                "wastepro", //other type name
                "/wastetran/chat", // path
                "wastetran" //sender type name
                );
        }

        return (

            <div>
                <Growl ref={(el) => this.growl = el} />
                <h1>แจ้งรับขยะรีไซเคิล</h1>
                <div className="p-grid">
                    {/* DETAIL */}
                    {searchPanel}
                    {showProgress}
                    {orderDetail}
                    
                </div>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    productType: state.master.productType,
    product: state.master.product,
})

const mapDispatchToProps = dispatch => ({
    masterServiceProps: bindActionCreators(MasterService, dispatch),

})

export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(WastetranManageOrder)

