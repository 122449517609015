import {
    WASTETRAN_ACCOUNT_BALANCE,
    WASTETRAN_ACCOUNT_WITHDRAW,
    WASTETRAN_ACCOUNT_WITHDRAW_HISTORY,
    WASTETRAN_AUTO_ORDER,
    WASTETRAN_ORDER_SEARCH_NOTI,
    WASTETRAN_DASH_PRODUCT,
    WASTETRAN_DASH_ORDER_STAT,
    WASTETRAN_CHANGE_PIN,
    WASTETRAN_DONATE,
    WASTETRAN_TRANSFER_DONATE,
    WASTETRAN_DONATE_HISTORY,
    WASTETRAN_REGIS_WASTEGEN
} from '../service/actionType'

const initialState = {
    data: null,
    isFailed: false,
    isLoading: false
}


export function wastetranBalance(state = initialState, action) {
    switch (action.type) {
        case WASTETRAN_ACCOUNT_BALANCE.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTETRAN_ACCOUNT_BALANCE.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTETRAN_ACCOUNT_BALANCE.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastetranWithdraw(state = initialState, action) {
    switch (action.type) {
        case WASTETRAN_ACCOUNT_WITHDRAW.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTETRAN_ACCOUNT_WITHDRAW.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTETRAN_ACCOUNT_WITHDRAW.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastetranWithdrawHistory(state = initialState, action) {
    switch (action.type) {
        case WASTETRAN_ACCOUNT_WITHDRAW_HISTORY.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTETRAN_ACCOUNT_WITHDRAW_HISTORY.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTETRAN_ACCOUNT_WITHDRAW_HISTORY.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastetranAutoOrder(state = initialState, action) {
    switch (action.type) {
        case WASTETRAN_AUTO_ORDER.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTETRAN_AUTO_ORDER.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTETRAN_AUTO_ORDER.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}
export function wastetranOrderSearchNoti(state = initialState, action) {
    switch (action.type) {
        case WASTETRAN_ORDER_SEARCH_NOTI.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTETRAN_ORDER_SEARCH_NOTI.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTETRAN_ORDER_SEARCH_NOTI.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}
export function getWastetranDashProduct(state = initialState, action) {
    switch (action.type) {
        case WASTETRAN_DASH_PRODUCT.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTETRAN_DASH_PRODUCT.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTETRAN_DASH_PRODUCT.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}
export function getWastetranDashOrderStat(state = initialState, action) {
    switch (action.type) {
        case WASTETRAN_DASH_ORDER_STAT.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTETRAN_DASH_ORDER_STAT.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTETRAN_DASH_ORDER_STAT.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastetranChangePin(state = initialState, action) {
    switch (action.type) {
        case WASTETRAN_CHANGE_PIN.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTETRAN_CHANGE_PIN.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTETRAN_CHANGE_PIN.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastetranDonate(state = initialState, action) {
    switch (action.type) {
        case WASTETRAN_DONATE.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTETRAN_DONATE.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTETRAN_DONATE.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastetranTransfer(state = initialState, action) {
    switch (action.type) {
        case WASTETRAN_TRANSFER_DONATE.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTETRAN_TRANSFER_DONATE.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTETRAN_TRANSFER_DONATE.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastetranDonateHistory(state = initialState, action) {
    switch (action.type) {
        case WASTETRAN_DONATE_HISTORY.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTETRAN_DONATE_HISTORY.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTETRAN_DONATE_HISTORY.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function wastetranRegisWastegen(state = initialState, action) {
    switch (action.type) {
        case WASTETRAN_REGIS_WASTEGEN.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case WASTETRAN_REGIS_WASTEGEN.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case WASTETRAN_REGIS_WASTEGEN.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}