import React from 'react';

import { Button } from 'primereact/button';
import { status, getStatus } from '../../constants/order-status'
import { convertDateMonthNumberToWord } from '../../utils/utilFunctions';

export const WastegenTimelines = ({ data, onClickRefresh }) => {

    const renderStatus = () => {
        let dataRs = []
        console.log("renderStatus")
        console.log(data)
        let tmpDataRs = data.states.map((value) => {
            let objStatus = getStatus(value.order_status);
            return (
                <div className="p-grid p-col-12">
                    <div className="p-col-3">
                        <span className="event-time">{objStatus.name}</span>
                        <i className={objStatus.icon} style={{ color: objStatus.color }}></i>
                    </div>
                    <div className="p-col-9">
                        <span className="event-owner" style={{ color: objStatus.color }}>
                            {
                                (data.current_order_status!=88 &&data.current_order_status != 1 ) && 'วันที่ ' + convertDateMonthNumberToWord(new Date(value.aspected_date))
                            } 
 {
                                (data.current_order_status!=88 &&data.current_order_status == 1) && 'วันที่ ' + convertDateMonthNumberToWord(new Date(value.actual_date))
                            } 


                            <br/> 
                            { (data.current_order_status==88) &&  'วันที่ ' + convertDateMonthNumberToWord(new Date(value.actual_date))}</span>
                    </div>
                </div>
            )
        })
        dataRs.push(tmpDataRs)
        return dataRs
    }

    return (
        <div className="card timeline p-fluid">
            <div className="p-grid">
                <div className="p-col-12">
                    <label style={{color:'green'}}>
                        <i className="fa fa-star" style={{ color: '#f6ac2b' }}></i>
                            รายการล่าสุด คำสั่งงานที่ {data.order_code?data.order_code:'-'}
                         </label>
                </div>

                {renderStatus()}

                <div className="p-col-12">
                    <Button
                        type="button"
                        label="Refresh"
                        icon="fa fa-refresh"
                        className="rounded-btn raised-btn"
                        onClick={onClickRefresh}>

                    </Button>
                </div>
            </div>
        </div>
    )
}