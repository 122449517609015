

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import SearchView from '../search/SearchView';
import { Accordion, AccordionTab } from 'primereact/accordion';
import DynamicDataTableView from '../data-table/DynamicDataTableView';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Panel } from 'primereact/panel';
import { Validators } from '../../utils';

import { orderSearch } from '../../service/order.service'
import { getBanks } from '../../service/MasterService'
import { FileUpload } from 'primereact/fileupload';

import {
    autoCompleteAdminAll,
    autoCompleteWasteall
} from '../../service/autocomplete.service'

class ReportWithdraw extends Component {

    constructor(props) {
        console.log("ReportWithdraw :constructor")
        super(props);
        this.state = {
            classNameRequired: {},
            //SearchView
            order_id: null,
            orderIdSuggestions: null,
            order_name: null,
            statusValue: { name: "เลือกสถานะ", value: 0 },
            statusList: [
                { name: "เลือกสถานะ", value: 0 },
                { name: 'ยอดรอโอน', value: 1 },
                { name: 'โอนแล้ว', value: 2 },
                { name: 'บริจาคแล้ว', value: 3 },
            ],
            pickupDate: null,
            pickupDateTo: null,
            appointmentDate: null,
            appointmentDateTo: null,
            collector_name: null,
            collectionNameSuggestions: null,
            bank: null,
            //DynamicDataTable
            model: [
                { field: 'col1', header: 'ชื่อสมาชิก', sortable: true },
                { field: 'col2', header: 'วันที่แจ้ง', sortable: true },
                { field: 'col3', header: 'วันที่โอน', sortable: true },
                { field: 'col4', header: 'จำนวนเงิน', sortable: true },
                { field: 'col5', header: 'ชื่อบัญชี', sortable: true },
                { field: 'col6', header: 'เลขที่บัญชี', sortable: true },
                { field: 'col7', header: 'ธนาคาร', sortable: true },
                { field: 'col8', header: 'Prompt Pay', sortable: true },
                { field: 'col9', header: 'สถานะ', sortable: true },
                { field: 'col10', header: 'Memo', sortable: true },
                { field: 'col11', header: 'ผู้ดูแล', sortable: true },
            ],
            pageNumber: 0,
            selection: [],
        }

        //> Event : original input text
        this.onChangeInputText = this.onChangeInputText.bind(this);
        //> Event : search view
        this.onChangeStatus = this.onChangeStatus.bind(this);
        this.onChangePickupdate = this.onChangePickupdate.bind(this);
        this.onChangePickupdateTo = this.onChangePickupdateTo.bind(this);
        this.onChangeAppointmentDate = this.onChangeAppointmentDate.bind(this);
        this.onChangeAppointmentDateTo = this.onChangeAppointmentDateTo.bind(this);
        this.onClickSearch = this.onClickSearch.bind(this);
        this.onSuggestorderIds = this.onSuggestorderIds.bind(this);
        this.onChangeAutocomplete = this.onChangeAutocomplete.bind(this);
        this.onSelectionChange = this.onSelectionChange.bind(this);
        this.onSaveTransfer = this.onSaveTransfer.bind(this);
        this.onChangeBank = this.onChangeBank.bind(this);
        this.onSuggestCollectionNames = this.onSuggestCollectionNames.bind(this);

    }

    componentDidMount() {
        console.log("ReportWithdraw :componentDidMount")
        let criteria = { user_wasteTypes: 4 };

        if (!this.props.orderSearch.data) {
            this.props.dispatch(orderSearch('admin', criteria));
        }

        this.props.dispatch(getBanks());

    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("ReportWithdraw :componentWillReceiveProps")

    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("ReportWithdraw :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("ReportWithdraw :componentWillUpdate")
        if (nextState.order_id != this.state.order_id) {
            nextProps.dispatch(autoCompleteWasteall(nextState.order_id));
        }
        if (nextState.collector_name != this.state.collector_name) {
            nextProps.dispatch(autoCompleteAdminAll(nextState.collector_name));
        }

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("ReportWithdraw :componentDidUpdate")
    }

    // Initial function
    initial = (object) => {
        if (object) {
            for (let key in object) {
                let val = object[key]
                this.setState({ [key]: val })
            }
        }
    }
    //> Submit
    onSubmit = () => {
        this.showSuccess();
    }

    //> Event

    onSelectionChange = (e) => {
        const { value } = e
        this.setState({ selection: value })

    }
    onChangeInputText = (e) => {
        const { name, value, required } = e.target
        this.setState({ [name]: value })
        if (required) {
            let classNamed = [name] + "_required"
            if (Validators.isEmpty(value)) {
                this._classNameRequiredIsError(classNamed, true);
            } else {
                this._classNameRequiredIsError(classNamed, false);
            }
        }
    }
    onChangeStatus = (e) => {
        this.setState({ statusValue: e.value })
    }
    onChangePickupdate = (e) => {
        const { value } = e
        this.setState({ pickupDate: value })
        this._classNameRequiredIsError('pickup_date_required', false)
        if (!this.state.pickupDateTo) {
            this._classNameRequiredIsError('pickup_date_to_required', true)
        } else {
            this._classNameRequiredIsError('pickup_date_to_required', false)
        }
    }
    onChangePickupdateTo = (e) => {
        const { value } = e
        this.setState({ pickupDateTo: value })
        this._classNameRequiredIsError('pickup_date_to_required', false)
        if (!this.state.pickupDate) {
            this._classNameRequiredIsError('pickup_date_required', true)
        } else {
            this._classNameRequiredIsError('pickup_date_required', false)
        }
    }
    onChangeAppointmentDate = (e) => {
        const { value } = e
        this.setState({ appointmentDate: value })
        this._classNameRequiredIsError('appointment_date_required', false)
        if (!this.state.appointmentDateTo) {
            this._classNameRequiredIsError('appointment_date_to_required', true)
        } else {
            this._classNameRequiredIsError('appointment_date_to_required', false)
        }
    }
    onChangeAppointmentDateTo = (e) => {
        const { value } = e
        this.setState({ appointmentDateTo: value })
        this._classNameRequiredIsError('appointment_date_to_required', false)
        if (!this.state.appointmentDate) {
            this._classNameRequiredIsError('appointment_date_required', true)
        } else {
            this._classNameRequiredIsError('appointment_date_required', false)
        }
    }
    onClickSearch = (e) => {
        let criteria = { user_wasteTypes: 4 };
        if (this.state.order_id) {
            criteria.order_code = this.state.order_id
        }
        if (this.state.order_name) {
            criteria.user_search_name = this.state.order_name
        }
        if (this.state.statusValue.value) {
            criteria.order_status = this.state.statusValue.value
        }
        if (this.state.pickupDate) {
            criteria.pickup_date_start = this.state.pickupDate.toISOString();
        }
        if (this.state.pickupDateTo) {
            criteria.pickup_date_end = this.state.pickupDateTo.toISOString();
        }
        if (this.state.collector_name) {
            criteria.admin_code = this.state.collector_name
        }
        console.log(`criteria is :${criteria}`)
        this.props.dispatch(orderSearch(criteria));
        this.setState({ pageNumber: 0 });
    }
    onClickCancel = (e) => {
        //TODO set state =  null
        let criteria = { user_wasteTypes: 4 };
        this.props.dispatch(orderSearch(criteria));
        this.setState({ pageNumber: 0 });
    }
    onSuggestorderIds = (e) => {
        if (this.props.autoCompleteWasteall
            && this.props.autoCompleteWasteall.data
            && !this.props.autoCompleteWasteall.isLoading
        ) {
            // let results = this.props.autoCompleteWasteall.data.filter((orderId) => {
            //     return orderId.toLowerCase().startsWith(e.query.toLowerCase());
            // });

            this.setState({ orderIdSuggestions: this.props.autoCompleteWasteall.data.suggest });
        }
    }

    onSuggestCollectionNames = (e) => {
        if (this.props.autoCompleteAdminAll
            && this.props.autoCompleteAdminAll.data
            && !this.props.autoCompleteAdminAll.isLoading
        ) {
            this.setState({ collectionNameSuggestions: this.props.autoCompleteAdminAll.data.suggest });
        }
    }
    onChangeAutocomplete = (e) => {
        const { value, name } = e.target
        this.setState({ [name]: value })
    }
    onChangeBank = (e) => {
        const { value } = e
        this.setState({ bank: value })
    }
    //> Action print

    // actionTemplate = (rowData, column) => {
    //     console.log(`rowData:${rowData}`);
    //     console.log(`column:${column}`);
    //     return <div>
    //         <FileUpload
    //             mode="basic"
    //             chooseLabel="แนบไฟล์"
    //             cancelLabel="ยกเลิก"
    //             multiple={false}
    //             accept="image/*"
    //             maxFileSize={1000000} />
    //     </div>;
    // }
    onSaveTransfer = () => {
        console.log("onSaveTransfer")
    }

    // ======= Validate ======
    validateAllRequired = (nextState) => {

    }
    _validateLogic = (name, value) => {
        if (Validators.isEmpty(value)) {
            this._classNameRequiredIsError(name, true);
        }
    }
    _classNameRequiredIsError = (classNamed, status) => {
        if (!classNamed) {
            if (status) {
                this.showWarn();
            }
        } else {
            if (status) {
                this.setState(pv => ({
                    classNameRequired: {
                        ...pv.classNameRequired, [classNamed]: "p-error"
                    }
                }))
            } else {
                this.setState(pv => ({
                    classNameRequired: {
                        ...pv.classNameRequired, [classNamed]: ""
                    }
                }))
            }
        }
    }
    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    render() {
        console.log("ReportWithdraw :render")
        console.log(`check : ${this.state.selection}`)
        let dynamicColumns = this.state.model.map((col, i) => {
            if (col.body) {
                return (<Column
                    header={col.header}
                    body={col.body}
                    style={{ textAlign: 'center', width: '8em' }}
                />)
                    ;
            } else if (col.field && col.header) {
                return <Column key={col.field}
                    field={col.field}
                    header={col.header}
                    sortable={col.sortable} />;
            } else {
                return <div></div>
            }
        });


        let data = []
        if (this.props.orderSearch && !this.props.orderSearch.isLoading) {
            if (!this.props.orderSearch.isFailed) {
                for (let key in this.props.orderSearch.data) {
                    let entity = this.props.orderSearch.data[key];
                    let tmpData = {
                        "col1": null,
                        "col2": entity.order_code,
                        "col3": entity.pickup_date,
                        "col4": entity.delivery_date,
                        "col5": "",
                        "col6": "",
                        "col7": "",
                        "col8": "",
                        "col9": "",
                        "col10": "",
                        "col11": "",
                        "col12": ""
                    }
                    data.push(tmpData);
                }
            }

        }

        return (
            <div>
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    {/* DETAIL */}
                    <Accordion activeIndex={0}>
                        <AccordionTab header="เงื่อนไขของรายงานเบิกถอน">
                            <SearchView
                                mode="admin-report-withdraw"
                                classNameRequired={this.state.classNameRequired}
                                order_id={this.state.order_id}
                                orderIdSuggestions={this.state.orderIdSuggestions}
                                order_name={this.state.order_name}
                                statusValue={this.state.statusValue}
                                statusList={this.state.statusList}
                                pickupDate={this.state.pickupDate}
                                pickupDateTo={this.state.pickupDateTo}
                                appointmentDate={this.state.appointmentDate}
                                appointmentDateTo={this.state.appointmentDateTo}
                                collector_name={this.state.collector_name}
                                collectionNameSuggestions={this.state.collectionNameSuggestions}
                                onChangeInputText={this.onChangeInputText}
                                onChangeStatus={this.onChangeStatus}
                                onChangePickupdate={this.onChangePickupdate}
                                onChangePickupdateTo={this.onChangePickupdateTo}
                                onChangeAppointmentDate={this.onChangeAppointmentDate}
                                onChangeAppointmentDateTo={this.onChangeAppointmentDateTo}
                                onClickSearch={this.onClickSearch}
                                onClickCancel={this.onClickCancel}
                                onSuggestorderIds={this.onSuggestorderIds}
                                onSuggestCollectionNames={this.onSuggestCollectionNames}
                                onChangeAutocomplete={this.onChangeAutocomplete}
                                bank={this.state.bank}
                                banks={this.props.banks}
                                onChangeBank={this.onChangeBank}
                            />
                        </AccordionTab>
                    </Accordion>
                </div>

                <div className="p-grid" style={{ marginTop: 20 }}>
                    <Panel header="ประวัติการเบิกถอน">
                        <DynamicDataTableView
                            mode="admin"
                            model={this.state.model}
                            data={data}
                            saveTransfer={true}
                            onSaveTransfer={this.onSaveTransfer}
                            actionTemplate={this.actionTemplate}
                            loading={this.props.orderSearch.isLoading}
                            selection={this.state.selection}
                            onSelectionChange={this.onSelectionChange}
                        />
                    </Panel>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    autoCompleteWasteall: state.autoCompleteWasteall,
    orderSearch: state.orderSearch,
    banks: state.master.banks,
    autoCompleteAdminAll: state.autoCompleteAdminAll,

})

export default connect(mapStateToProps)(ReportWithdraw)
