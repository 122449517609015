import { v4 as uuidv4 } from 'uuid'
import {
  GET_CHAT_MESSAGE_BY_ROOM,
  LOAD_MORE_CHAT_MESSAGE,
  ADD_NEW_CHAT_MESSAGE,
  GET_CHAT_LIST,
  GET_CHAT_PICKUP_BADGE,
  GET_CHAT_DELIVERY_BADGE,
  GET_CHAT_ADMIN_BADGE,
} from '../service/actionType'

const initialChatMessageState = {
  data: {
    messages: [],
  },
  isFailed: false,
  isLoading: false,
}

const initialChatListState = {
  data: [],
  isFailed: false,
  isLoading: false,
}

const initialChatBadge = {
  pickup: 0,
  delivery: 0,
  admin: 0,
  isFailed: false,
  isLoading: false,
}

export function chatMessageByRoom(state = initialChatMessageState, action) {
  switch (action.type) {
    case GET_CHAT_MESSAGE_BY_ROOM.REQUEST:
      return { ...state, data: initialChatMessageState.data, isFailed: false, isLoading: true }
    case LOAD_MORE_CHAT_MESSAGE.REQUEST:
      return { ...state, isFailed: false, isLoading: true }
    case GET_CHAT_MESSAGE_BY_ROOM.FAILURE:
    case LOAD_MORE_CHAT_MESSAGE.FAILURE:
      return { ...state, isFailed: true, isLoading: false }
    case GET_CHAT_MESSAGE_BY_ROOM.SUCCESS: {
      const messageWithId = action.payload.messages.map(item => ({
        ...item,
        message_id: item.message_id || uuidv4(),
      }))
      return {
        ...state,
        isFailed: false,
        isLoading: false,
        data: {
          ...action.payload,
          messages: messageWithId,
        },
      }
    }
    case LOAD_MORE_CHAT_MESSAGE.SUCCESS: {
      const messageWithId = action.payload.messages.map(item => ({
        ...item,
        message_id: item.message_id || uuidv4(),
      }))
      return {
        ...state,
        isFailed: false,
        isLoading: false,
        data: {
          ...state.data,
          messages: [...state.data.messages, ...messageWithId],
        },
      }
    }
    case ADD_NEW_CHAT_MESSAGE: {
      const newMessages = [action.message, ...state.data.messages]
      return { ...state, data: { ...state.data, messages: newMessages } }
    }
    default:
      return state
  }
}

export function chatList(state = initialChatListState, action) {
  switch (action.type) {
    case GET_CHAT_LIST.REQUEST:
      return { ...state, isFailed: false, isLoading: true }
    case GET_CHAT_LIST.FAILURE:
      return { ...state, isFailed: true, isLoading: false }
    case GET_CHAT_LIST.SUCCESS: {
      return {
        ...state,
        isFailed: false,
        isLoading: false,
        data: action.payload,
      }
    }
    default:
      return state
  }
}

export function chatSingleBadge(state = initialChatBadge, action) {
  switch (action.type) {
    case GET_CHAT_PICKUP_BADGE: {
      return {
        ...state,
        isFailed: false,
        isLoading: false,
        pickup: action.payload,
      }
    }
    case GET_CHAT_DELIVERY_BADGE: {
      return {
        ...state,
        isFailed: false,
        isLoading: false,
        delivery: action.payload,
      }
    }
    case GET_CHAT_ADMIN_BADGE: {
      return {
        ...state,
        isFailed: false,
        isLoading: false,
        admin: action.payload,
      }
    }
    default:
      return state
  }
}