import React, { useState } from 'react';

import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Chips } from 'primereact/chips';
import { Avatar } from '../../features/widget/Avatar';
import { API_ROOT } from '../../constants/api';
import { getSticker, stickerPrefix } from './utils'

export default function RightBubble ({message, type, datetime, roomId, getImage, isFirstMessage, isLastMessage}){
  //console.log('is first : '+isFirstMessage)
  //console.log('is Last : '+isLastMessage)
  var imgPath = new Image;
  if(type === 2){
    //   getImage(roomId,message).then(res=>{
    //   //console.log(res)
    //   imgPath.src = res
    //   //console.log(imgPath.src)
    // })
  }
    //console.log(imgPath.src)
  
    let isSticker = false
    if (message.includes(stickerPrefix)) {
      isSticker = true
    }

    return (
    <div className='p-d-flex' key={roomId}>
      <div
        style={
          styles.container
          // type === 1 ? { alignItems: 'center' } : { alignItems: 'flex-start' },
        }>
        {type === 1 && !isSticker && (
          <div style={styles.bubbleContainer}>
            <div style={styles.message}>{message}</div>
          </div>
        )}
        {type === 2 && !isSticker && (
          <div style={styles.imageContainer}>
            {/* <img
              source={{
                uri: `${API_ROOT}/chatmessage/GetImage/${roomId}/${message}`,
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('access-token')
                },
              }}
              style={[styles.image, { height: '70%' }]}
            /> */}
            {
              <img src={`${API_ROOT}/chatmessage/GetImage/${roomId}/${message}`} style={styles.image}/>
              }
          </div>
        )}
        {isSticker && (
          <div style={styles.imageContainer}>
            <img
              src={getSticker(message)}
              style={styles.image}
            />
          </div>
        )}
      </div>
      <div style={styles.datetimeContainer}>
        <div style={styles.datetime}>{datetime}</div>
      </div>
    </div>
  )
}

const styles = ({
  container: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    textAlign: '-webkit-right',
  },
  bubbleContainer: {
    marginRight: 10,
    backgroundColor: 'lightgreen',
    paddingHorizontal: 10,
    paddingVertical: 5,
    borderRadius: 20,
    // maxWidth: '50%',
    width: 'max-content',
  },
  message: {
    fontSize: '18px',
    padding: '0px 10px',
  },
  imageContainer: {
    marginRight: 10,
  },
  image: {
    borderRadius: 10,
    width: '40%',
    height: '20%',
    alignItems: 'flex-end',
    float: 'revert'
  },
  datetimeContainer: {
    marginRight: 16,
    alignItems: 'flex-end',
    textAlign: '-webkit-right',
  },
  datetime: {
    fontSize: '14px',
    color: '#c8c8c8',
    marginLeft: 55,
  }
})

