import React, { Component } from 'react';

import { Accordion, AccordionTab } from 'primereact/accordion';
import { Dropdown } from 'primereact/dropdown';
import {FileUpload} from 'primereact/fileupload';
import { Button } from 'primereact/button';

export class WastetranMoreItems extends Component {
    state = {
        activeIndex: 0
    }

    render() {

        let contentDetail = (
            <div className="p-grid">
                <div className="p-col-12">
                    <label>ประเภทขยะรีไซเคิล</label>
                </div>
                <div className="p-col-12">
                    <Dropdown style={{ width: '100%' }} placeholder="เลือกประเภทขยะรีไซเคิล" />
                </div>

                <div className="p-col-12">
                    <label>สินค้า</label>
                </div>
                <div className="p-col-12">
                    <Dropdown style={{ width: '100%' }} placeholder="เลือกสินค้า" />
                </div>

                <div className="p-col-12">
                    <label>จำนวน (กิโลกรัม)</label>
                </div>
                <div className="p-col-12">
                    <Dropdown style={{ width: '100%' }} placeholder="เลือกจำนวน (กิโลกรัม)" />
                </div>

                <div className="p-col-12">
                    <label>รูปถ่ายขยะรีไซเคิลประกอบ</label>
                </div>
                <div className="p-col-12 content-section implementation">
                    <FileUpload name="demo[]"  multiple={true} maxFileSize={1000000}/>
                </div>

                <div className="p-col-12">
                     <Button label="เพิ่มรายการ" icon="pi pi-plus" iconPos="right" />
                </div>

            </div>
        )

        let contentlist = (
            <div>
                <Accordion activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({ activeIndex: e.index })}>
                    <AccordionTab header="รายการรับเพิ่มเติม">
                        <div className="p-grid">
                            {contentDetail}
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        )

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    {contentlist}
                </div>
            </div>
        )
    }
}