import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getBalance, getWastetranDashProduct,getWastetranDashOrderStat } from '../../service/wastetran.service'
import { userAnnounce } from '../../service/user.service'

import { WastetranOverviews } from '../wastetran-dashboard/WastetranOverviews';
import { WasteNewView } from '../waste-news/WasteNewView';
import { DatatableDashBoardView } from '../data-table/DatatableDashBoardView';
import { StatisticView } from '../dashboard/StatisticView';
import { getStatus } from '../../constants/order-status';
import { Panel } from 'primereact/panel';
import { ListBox } from 'primereact/listbox';
import { ChatRoom } from '../message/ChatRoom';
import { Growl } from 'primereact/growl';
import {InputText} from 'primereact/inputtext';
import { Button } from 'primereact/button';

class WastetranChatView extends Component {
    constructor(props) {
        console.log("WastegenDashboard :constructor")
        super(props);
        this.state = {
            dummySuccessDataTable: [
                { order: 'Order ทั้งหมด', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'จัดส่งสมบูรณ์', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'จัดส่งไม่สมบูรณ์', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'ลูกค้ายกเลิก', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: '%สำเร็จ', ranking: '0%', amount: '0', quantity: '0 kg.' },
            ]
        }
    }

    componentDidMount() {
        // console.log("WastegenDashboard :componentDidMount")
    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        // console.log("WastegenDashboard :componentWillReceiveProps")
    }
    shouldComponentUpdate(nextProps, nextState) {
        // console.log("WastegenDashboard :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        // console.log("WastegenDashboard :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        // console.log("WastegenDashboard :componentDidUpdate")

    }

    chat(){
        if(this.props.location.state){
            return (
                <ChatRoom
                    orderCode={this.props.location.state.orderCode || ''}
                    roomId={this.props.location.state.roomId || ''}
                    senderId={this.props.location.state.senderId || ''}
                    receiverId={this.props.location.state.receiverId || ''}
                    receiverImage={this.props.location.state.receiverImage || ''}
                    receiverWasteType={this.props.location.state.receiverWasteType || ''}
                    senderNumberType={this.props.location.state.senderNumberType || ''}
                    header={this.props.location.state.header || ''}
                />
            );
        }else
            return (<h1>ไม่พบข้อมูล</h1>)
    }

    render() {
        const chatWastetran = this.chat()
        return (
            <div>
                <h1>Chat</h1>
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid dashboard">

                    {/* <div className="p-col-4 p-md-3 p-lg-4">
                        {showChatTable}
                    </div> */}
                    <div className="p-col-12 " >
                        {chatWastetran}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    wastetranBalance: state.wastetranBalance,
    userAnnounce: state.userAnnounce,
    getWastetranDashProduct: state.getWastetranDashProduct,
    getWastetranDashOrderStat: state.getWastetranDashOrderStat
})

export default connect(mapStateToProps)(WastetranChatView)