

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { ExportExcel } from '../ExportExcel/ExportExcel';

class ExampleExcel extends Component {

    constructor(props) {
        console.log("ExampleCtrl :constructor")
        super(props);
        this.state = {
            buying : this.createBuying(),
            fileName: 'reports-buying.xlsx'
        }
    }
    componentDidMount() {
        console.log("ExampleExcel :componentDidMount")
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("ExampleExcel :componentWillReceiveProps")

    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("ExampleExcel :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("ExampleExcel :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("ExampleExcel :componentDidUpdate")

    }

    createBuying = () => {
        let buying = []
        for (let i = 0; i <= 25; i++) {
            buying.push({
                firstName: `first${i}`, lastName: `last${i}`,
                email: `abc${i}@gmail.com`, address: `000${i} street city, ST`, zipcode: `0000${i}`
            });
        }

        buying.push({
            firstName: ``,
            lastName: ``,
            email: `รวม`,
            address: 2000.00,
            zipcode: ``
        });

        return buying;
    }

    // Initial function
    initial = (object) => {
        if (object) {
            for (let key in object) {
                let val = object[key]
                this.setState({ [key]: val })
            }
        }
    }

    //> Submit
    onSubmit = () => {
        this.showSuccess();
    }

    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    render() {
        console.log("ExampleExcel :render")
        console.log(this.state.buying)
        return (
            <div>
                <Growl ref={(el) => this.growl = el} />
                <h1>Profile</h1>
                <div className="p-grid">
                    {/* DETAIL */}
                    <ExportExcel data={this.state.buying} fileName={this.state.fileName}/>
                    <div className="p-col-12">
                        {/* <Button
                            className="p-col-12"
                            label="บันทึก"
                            icon="pi pi-check"
                            iconPos="left"
                            onClick={this.onSubmit} /> */}
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(ExampleExcel)
