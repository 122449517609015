import React, { Component } from 'react';
import { connect } from 'react-redux';

import { WastegenOverviews } from '../wastegen-dashboard/WastegenOverviews';
import { WastegenTimelines } from '../wastegen-dashboard/WastegenTimelines';

import { getBalance, getWastegenDashLastOrder, getWastegenDashProduct } from '../../service/wastegen.service'
import { userAnnounce } from '../../service/user.service'
import { WasteNewView } from '../waste-news/WasteNewView';
import { DatatableGoodnessView } from '../data-table/DatatableGoodnessView';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { DataView, DataViewLayoutOptions } from "primereact/dataview";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';

import { orderAutoComplete, orderSearch } from '../../service/order.service'
import TableProductGoodnessView from '../data-table/TableProductGoodnessView';
import { dateFormat } from '../../utils/utilFunctions'

class WastegenGoodness extends Component {

    constructor(props) {
        console.log("WastegenGoodness :constructor")
        super(props);
        this.state = {
            model: [
                // { field: 'col1', header: 'พิมพ์ใบสรุป', body: this.actionTemplate },
                // { field: 'col2', header: 'เลขที่', sortable: true },
                { field: 'col3', header: 'รายการสินค้า', sortable: true },
                { field: 'col4', header: 'วันที่ขาย', sortable: true },
                // { field: 'col5', header: 'นัดส่ง', sortable: true },
                { field: 'col6', header: 'ปริมาณเก็บ (Kg)', sortable: true , style:{textAlign:'center'}},
                // { field: 'col7', header: 'ราคาประเมิน (บาท)', sortable: true , style:{textAlign:'right'}},
                // { field: 'col8', header: 'ปริมาณรับซื้อ (Kg)', sortable: true , style:{textAlign:'right'}},
                { field: 'col9', header: 'ราคารับซื้อ (บาท)', sortable: true , style:{textAlign:'center'}},
                // { field: 'col10', header: 'ทีมขนส่ง', sortable: true },
                // { field: 'col11', header: 'สถานะ', sortable: true },
            ],
            productTypeDataTable: [
                { type: 'กระดาษลัง', ranking: '0', amount: '0', quantity: '20 kg.' },
                { type: 'กระดาษจับจั๊ว', ranking: '0', amount: '0', quantity: '0 kg.' },
                { type: 'การดาษขาวดำ', ranking: '0', amount: '0', quantity: '0 kg.' },
                { tpye: 'กระดาษ(ไม่ระบุ)', ranking: '0', amount: '0', quantity: '0 kg.' },
            ],
            selectedProduct: null,
            visible: false,
            layout: 'list',
            selectedCar1: null,
        }

        this.onClickRefresh = this.onClickRefresh.bind(this);
    }

    componentDidMount() {
        console.log("WastegenGoodness :componentDidMount")
        this.props.dispatch(getBalance());
        this.props.dispatch(userAnnounce());
        this.props.dispatch(getWastegenDashLastOrder());
        this.props.dispatch(getWastegenDashProduct());
        let criteria = {};
        this.props.dispatch(orderSearch("wastegen", criteria));
    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("WastegenGoodness :componentWillReceiveProps")
    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("WastegenGoodness :shouldComponentUpdate")

        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("WastegenGoodness :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("WastegenGoodness :componentDidUpdate")
    }

    //> EVENT
    onClickRefresh = (e) => {
        this.props.dispatch(getWastegenDashLastOrder());
    }
    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }


   

   

    renderHeader() {
        return (
            <div className="p-grid">
                <div className="p-col-12" style={{ textAlign: 'right' }}>
                    <DataViewLayoutOptions layout={this.state.layout} onChange={(e) => this.setState({ layout: e.value })} />
                </div>
            </div>
        );
    }

    render() {
        console.log("WastegenGoodness :render")
        const {
            wastegenBalance,
            userAnnounce,
            getWastegenDashLastOrder,
            getWastegenDashProduct
        } = this.props
        //> ข่าวสาร
        const contentNews = (
            <WasteNewView
                data={userAnnounce.data}
            />
        );
        const header = this.renderHeader();

        
        //Overview
        let summaryBalance = 0;
        let successBalance = 0;
        let noSuccessBalance = 0;
        if (
            !wastegenBalance.isLoading
            && wastegenBalance.data
            && !wastegenBalance.isFailed
        ) {
            summaryBalance = wastegenBalance.data.income_summary_thb.toFixed(2);
            successBalance = Math.abs(wastegenBalance.data.withdraw_summary_thb.toFixed(2));
            noSuccessBalance = wastegenBalance.data.withdraw_balance_thb.toFixed(2);
        }
        const contentOverview = (
            <WastegenOverviews
                summaryBalance={summaryBalance}
                successBalance={successBalance}
                noSuccessBalance={noSuccessBalance}
            />
        );

        //> ความสำเร็จของคุณ
        let dataList = []
        if (!getWastegenDashProduct.isLoading && !getWastegenDashProduct.isFailed) {
            if (getWastegenDashProduct.data && getWastegenDashProduct.data.length > 0) {
                for (let key in getWastegenDashProduct.data) {
                    let entity = getWastegenDashProduct.data[key]
                    let prepareData = {
                        order: entity.product_type_name,
                        quantity: entity.quantity_kk
                    };
                    dataList.push(prepareData);
                }
            }
        }
        

        const showDetailDataTable = (
            <Panel>
                <div className="p-grid">
                    <div className="p-col-3">
                        <h4>
                            ประเภทสินค้า : 
                    </h4>
                    </div>
                    <div className="p-col-9">
                        <h4>
                            กระดาษ
                        </h4>
                    </div>
                    <div className="p-col-3">
                        <b>สินค้า :</b>  
                    </div>
                    <div className="p-col-9">
                         กระดาษลัง
                    </div>
                    <div className="p-col-3">
                        <b>ขายวันที่ :</b>  
                    </div>
                    <div className="p-col-9">
                         22/02/2020
                    </div>
                    <div className="p-col-3">
                        <b>ส่งวันที่ :</b>  
                    </div>
                    <div className="p-col-9">
                        23/02/2020
                    </div>
                    <div className="p-col-3">
                        <b>จำนวน</b> 
                    </div>
                    <div className="p-col-9">
                         20 &nbsp;&nbsp;&nbsp;&nbsp;กิโลกรัม
                    </div>
                    <div className="p-col-3">
                        <b>ราคา</b>
                    </div>
                    <div className="p-col-9">
                        1234 &nbsp;&nbsp;&nbsp;&nbsp;บาท
                    </div>
                    <div >

                    </div>
                </div>
               
            </Panel>
        )

        const contentSuccessDataTable = (
            <DatatableGoodnessView data={dataList} />
        );

        //> รายการล่าสุด
        let contentTimeline = (
            <div className="card timeline p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <label>
                            <i className="fa fa-star" style={{ color: '#f6ac2b' }}></i>
                            ไม่พบคำสั่งงาน
                        </label>
                    </div>

                </div>
            </div>
        );

        if (!getWastegenDashLastOrder.isLoading && !getWastegenDashLastOrder.isFailed) {
            if (getWastegenDashLastOrder.data) {
                contentTimeline = (
                    <WastegenTimelines
                        data={getWastegenDashLastOrder.data}
                        onClickRefresh={this.onClickRefresh}
                    />
                )
            }
        }

        let data = []
        if (this.props.orderSearch && !this.props.orderSearch.isLoading) {
            if (!this.props.orderSearch.isFailed) {
                for (let key in this.props.orderSearch.data) {
                    let entity = this.props.orderSearch.data[key];

                    for (let keyDetail in entity.orderDetail) {
                        let entityDetail = entity.orderDetail[keyDetail]
                        if (entityDetail) {
                            let tmpData = {
                                // "col1": null,
                                // "col2": entity.order_code,
                                "col3": entityDetail.product_name,
                                "col4": dateFormat(entity.pickup_date),
                                "col5": dateFormat(entity.delivery_date),
                                "col6": entityDetail.pickup_quantity_kk,
                                "col7": entityDetail.estimate_price_per_unit,
                                "col8": entityDetail.delivery_quantity_kk,
                                "col9": entityDetail.price_per_unit,
                                "col10": entity.u2_fullname,
                                "col11": entity.order_status_name
                            }
                            data.push(tmpData);
                        }

                    }
                }
            }

        }

        return (
            <div className="p-grid ">
                <div className="p-col-12 ">
                    <h1>ค่าความดี</h1>
                </div>
            <Panel>
                <Button style={{marginTop:"10px"}} label="ดูรายละเอียด" onClick={this.handleClick} />
                <div style={{ marginTop: '1em' }} />
                <div className="p-grid ">
                    
                        <div className="p-col-12 ">
                            {contentSuccessDataTable}
                        </div>
                        <br/>
                        <div className="p-col-12 " style={{ marginTop: 20 }}>
                            <TableProductGoodnessView
                                mode="wastegen"
                                model={this.state.model}
                                data={data}
                                exportExcel={true}
                                actionTemplate={this.actionTemplate}
                                loading={this.props.orderSearch.isLoading}
                            />
                        </div>
                        <br/>
                        <div className=" p-col-12">
                            {showDetailDataTable}
                        </div>
                </div>
            </Panel>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    orderSearch: state.orderSearch,
    wastegenBalance: state.wastegenBalance,
    userAnnounce: state.userAnnounce,
    getWastegenDashLastOrder: state.getWastegenDashLastOrder,
    getWastegenDashProduct: state.getWastegenDashProduct
})

export default connect(mapStateToProps)(WastegenGoodness)