import React, { Component } from 'react';

export class Avatar extends Component {
    static defaultProps = {
        src: 'assets/avatar/user.png',
        width: '100',
        height: '100',
        styles: {borderRadius: '50%'},
    }

    constructor(props) {
        super(props)
          
        }
    
   

    render(){
        const src = this.props.src;      
        const ext = new RegExp(/\.(JPG|JPEG|PNG|jpg|jpeg|gif|png|tiff|svg)$/);
        const isCorrect = ext.test(src);
       
        if(isCorrect){          
            return <img alt="Profile" width={this.props.width} height={this.props.height} style={this.props.styles} src={this.props.src} onError={(e) => e.target.src='assets/avatar/user.png'}/> 
        }
        return <img alt="Profile" width={this.props.width} height={this.props.height} style={this.props.styles} src="assets/avatar/user.png" />
    }
}