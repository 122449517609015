

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';

import { getDistrict, getSubDistrict } from '../../service/LocationService';

import ImageInfoView from '../profile/ImageInfoView';

import { ProfileInfoView } from '../profile/ProfileInfoView';
import { Validators, SecureUtils } from '../../utils';
import BankInfoView from '../profile/BankInfoView';
import AddressInfoView from '../profile/AddressInfoView';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox';
import AddressSwapInfoView from '../profile/AddressSwapInfoView';
import ChangePassword from '../change-password/ChangePassword';
import { InputSwitch } from 'primereact/inputswitch';

import { updateWastegenProfile, changePassword, getUserInfo } from '../../service/user.service'
import { wastegenChangePin } from '../../service/wastegen.service'
import * as ServerAPI from '../register/service'

import { Checkbox } from 'primereact/checkbox';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ChangePinView } from '../changepin/ChangePinView';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { API_ROOT } from '../../constants/api';

class ExampleWastegenProfileCtrl extends Component {

    constructor(props) {
        //console.log("ExampleWastegenProfileCtrl :constructor")
        super(props);
        this.state = {
            //> page control
            isInitial: true,
            isUpdate: false,
            updateSuccess: false,
            isValidateError: false,
            mode: 'wastegen',
            //> profile info
            selectedPersonPrefix: null,
            selectedCompanyType: null,
            personPrefixs: [
                { name: 'นาย', code: 1 },
                { name: 'นาง', code: 2 },
                { name: 'นางสาว', code: 3 },
            ],
            companyTypes: [
                { name: 'บริษัทจำกัด', code: 1 },
                { name: 'ห้างหุ้นส่วน', code: 2 },
                { name: 'บริษัทมหาชน', code: 3 },
                { name: 'ห้างร้าน', code: 4 },
            ],
            listFileDelete: [],
            errMobileMsg: null,
            isErrorPhone: false,
            //> Bank info
            selectedBank: null,
            //> Change Pin
            modePin: 2,
            isShowChangePin: false,
            isChangePin: false,
            isSubmitPin: false,
            inputPin: null,
            passwordPin: null,
            isValidBankWithDrawCode: true,
            isValidPasswordPin: true,
            isPassPinVisible: false,
            //> Address Current info
            selectedAdrCurrentProv: null,
            selectedAdrCurrentDistrict: null,
            selectedAdrCurrentSubDistrict: null,
            userCardAddress: false,
            //> Map
            marker: {},
            mapZoom: 11,
            mapCenter: { lat: 13.7734283, lng: 100.57383099999993 },
            //PASSWORD
            isShowChangePassword: false,
            isChangePassword: false,
            checkedPasswordVisible: false,
            typePasswordInput: 'password',
            errorMessageOldPassword: null,
            errorMessagePassword: null,
            errorMessageConfirmPassword: null,
            oldPassword: null,
            newPassword: null,
            confirmPassword: null,
            disableNewPass: true
        }
    }

    componentDidMount() {
        //console.log("ExampleWastegenProfileCtrl :componentDidMount", this.props)
        this.props.dispatch(getUserInfo())
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        //console.log("ExampleWastegenProfileCtrl :componentWillReceiveProps", this.state)

        if (!nextProps.userInfoLoad.isLoading
            && !nextProps.changePasswordLoad.isLoading
        ) {
            this.initialDropdown(nextProps);

            if (this.state.isInitial) {
                if (
                    nextProps.userInfoModel
                ) {
                    this.initial(nextProps.userInfoModel)
                }

            }
        }

        //> Status submit
        if (this.state.isSubmitPin && this.state.isChangePin && !nextProps.wastegenChangePin.isLoading) {
            if (nextProps.wastegenChangePin.isFailed) {
                this.showError("ไม่สามารถเปลี่ยนรหัสเลขใช้สำหรับแจ้งถอนเงิน กรุณาตรวจสอบรหัสผ่าน")
                this.setState({ isChangePin: false })
                this.setState({ isSubmitPin: false })
            } else {
                this.showSuccess("เปลี่ยนรหัสเลขใช้สำหรับแจ้งถอนเงิน สำเร็จ")
                this.setState({ isChangePin: false })
                this.setState({ isSubmitPin: false })
                this.setState({ inputPin: null })
                this.setState({ passwordPin: null })
                this.setState({isShowChangePin:false})
                this.props.dispatch(getUserInfo());
                this.setState({ isInitial: true })
            }
        }
        if (this.state.isUpdate) {
            switch (this.state.isChangePassword) {
                case true:
                    if (!nextProps.changePasswordLoad.isLoading) {
                        if (nextProps.changePasswordLoad.isFailed) {
                            this.showError("รหัสผ่าน :" + nextProps.changePasswordModel)
                            this.setState({ isUpdate: false })
                            this.setState({ isInitial: false })
                            this.setState({ isChangePassword: false })
                        } else {
                            //remove pin out of process
                            delete this.state['bank_withdraw_code']
                            this.props.dispatch(updateWastegenProfile(this.state));
                            if (!nextProps.updateWastegenProfileLoad.isLoading) {
                                if (!nextProps.updateWastegenProfileLoad.isFailed) {
                                    this.props.dispatch(getUserInfo());
                                    this.showSuccess("บันทึกข้อมูลสำเร็จ")
                                    this.setState({ isUpdate: false })
                                    this.setState({ isInitial: true })
                                    this.setState({ isChangePassword: false })
                                    this.setState({ isShowChangePassword: false })
                                }

                                if (nextProps.updateWastegenProfileLoad.isFailed) {
                                    this.showError("บันทึกข้อมูลผิดพลาด")
                                    this.setState({ isUpdate: false })
                                    this.setState({ isInitial: false })
                                }
                            }
                        }
                    }
                    break;
                case false:
                    if (!nextProps.userInfoLoad.isLoading) {
                        if (!nextProps.userInfoLoad.isFailed) {
                            this.props.dispatch(getUserInfo());
                            this.showSuccess("บันทึกข้อมูลสำเร็จ")
                            this.setState({ isUpdate: false })
                            this.setState({ isInitial: true })
                            this.setState({ isChangePassword: false })
                            this.setState({ isShowChangePassword: false })
                        }

                        if (nextProps.userInfoLoad.isFailed) {
                            this.showError("บันทึกข้อมูลผิดพลาด")
                            this.setState({ isUpdate: false })
                            this.setState({ isInitial: false })
                        }
                    }
                    break;
            }
        }


    }
    shouldComponentUpdate(nextProps, nextState) {
        //console.log("ExampleWastegenProfileCtrl :shouldComponentUpdate")
        if (this.state.isValidateError != nextState.isValidateError) {
            this.validateRequire(nextState)
        }

        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("ExampleWastegenProfileCtrl :componentWillUpdate ")



    }
    componentDidUpdate(prevProps, prevState) {
        //console.log("ExampleWastegenProfileCtrl :componentDidUpdate")
        this.initialSelected(this.state)
    }
    //> Submit
    onSubmit = async (e) => {
        await this.validateRequire(this.state);
        if (this.state.isValidateError) {
            this.showWarn();
        } else {
            if (this.state.isChangePassword) {
                var jsPass = {
                    username: SecureUtils.getItem('user').username,
                    oldPassword: this.state.oldPassword,
                    newPassword: this.state.newPassword,
                    confirmPassword: this.state.confirmPassword,
                    statusMessage: "string",
                }
                this.props.dispatch(changePassword(JSON.parse(JSON.stringify(jsPass))))
            } else {
                const formData = new FormData();

                for (let key in this.props.userInfoModel) {
                    let valState = this.state[key]
                    if (typeof valState == 'object') {
                        if (Validators.isNotEmpty(valState)) {
                            formData.append([key], JSON.stringify(valState))
                        }
                    } else {
                        if (Validators.isNotEmpty(valState)) {
                            formData.set([key], valState || '')
                        }
                    }
                }
                const fLimit = 1000000;
                if (this.state.fileImgPerson && this.state.fileImgPerson.size <= fLimit) {
                    console.log("fileImgPerson : ",this.state.fileImgPerson);
                    formData.append('FileImgPerson', this.state.fileImgPerson);
                }

                this.state.listFileDelete.map(fileName => {
                    fileName = fileName || ""
                    let fileType = null;
                    let spFname = fileName.split("-")
                    if (spFname.length > 0) {
                        fileType = spFname[0]
                    }
                    // this.props.dispatch(deleteWasteTranFile(fileType, fileName))
                })
                this.props.dispatch(updateWastegenProfile(formData));

                //remove pin out of process
                // delete this.state['bank_withdraw_code']
                // this.props.dispatch(updateWastegenProfile(this.state))
                this.setState({ isShowChangePassword: false })
            }

            this.setState({ isUpdate: true })
        }

    }

    //> Initial function
    initial = (nextProps) => {
        if (nextProps) {
            for (let key in nextProps) {
                let val = nextProps[key]
                this.setState({ [key]: val })
            }
            this.setState({ isInitial: false })
            //> pin
            if (Validators.isNotEmpty(nextProps.bank_withdraw_code)) {
                this.setState({
                    modePin: 1
                })
            } else {
                this.setState({
                    modePin: 2
                })
            }
            
            // img_person_name
            if(nextProps.img_person_name){
                this.setState({img_person_name:nextProps.img_person_name})
            }
        }
    }
    //> Initial Droupdown
    initialDropdown = (nextProps) => {
        //console.log("Initial Droupdown ", nextProps)
        //> banks
        if (nextProps.banks && nextProps.banks.length > 0) {
            this.setState({ banks: nextProps.banks })
        }
        //> adrees current info
        if (nextProps.provinceModel && nextProps.provinceModel.length > 0) {
            this.setState({ province: nextProps.provinceModel })
        }
        if (nextProps.currentDistrictModel && nextProps.currentDistrictModel.length > 0) {
            this.setState({ currentDistrict: nextProps.currentDistrictModel })
        }
        if (nextProps.currentSubDistrictModel && nextProps.currentSubDistrictModel.length > 0) {
            this.setState({ currentSubDistrict: nextProps.currentSubDistrictModel })
        }
    }
    //> Initail selected
    initialSelected = (nextState) => {
        //console.log("Initail selected ", nextState)

        const {
            //profile info
            selectedPersonPrefix,
            person_prefix_code,
            personPrefixs,
            selectedCompanyType,
            org_type,
            companyTypes,
            //bank info
            selectedBank,
            bank_code,
            banks,
            //Adress current info : province
            selectedAdrCurrentProv,
            addr_current_province_code,
            province,
            //Adress current info : district
            selectedAdrCurrentDistrict,
            addr_current_district_code,
            currentDistrict,
            //Adress current info : sub district
            selectedAdrCurrentSubDistrict,
            addr_current_subdistrict_code,
            currentSubDistrict,
            //Map
            latitude,
            longitude,
            marker
        } = nextState

        //> map
        if (!marker.lat && latitude && longitude) {
            this.setState({ marker: { lat: latitude, lng: longitude } })
            this.setState({ mapCenter: { lat: latitude, lng: longitude } })
        }

        //> profile info
        if (!selectedPersonPrefix && person_prefix_code) {
            for (let key in personPrefixs) {
                let val = personPrefixs[key]
                if (person_prefix_code == val.code) {
                    this.setState({ selectedPersonPrefix: val })
                }
            }
        }
        if (!selectedCompanyType && org_type) {
            for (let key in companyTypes) {
                let val = companyTypes[key]
                if (org_type == val.code) {
                    this.setState({ selectedCompanyType: val })
                }
            }
        }

        if (!selectedPersonPrefix && person_prefix_code) {
            for (let key in personPrefixs) {
                let val = personPrefixs[key]
                if (person_prefix_code == val.code) {
                    this.setState({ selectedPersonPrefix: val })
                }
            }
        }

        //> bank info
        if (!selectedBank && bank_code) {
            for (let key in banks) {
                let val = banks[key]
                if (bank_code == val.bank_code) {
                    this.setState({ selectedBank: val })
                }
            }
        }
        //> Adress current info
        if (!selectedAdrCurrentProv && addr_current_province_code) {
            for (let key in province) {
                let val = province[key]
                if (addr_current_province_code == val.provinceCode) {
                    //console.log("province ")
                    this.setState({ selectedAdrCurrentProv: val })
                }
            }
        }
        if (!selectedAdrCurrentDistrict && addr_current_district_code) {
            for (let key in currentDistrict) {
                let val = currentDistrict[key]
                if (addr_current_district_code == val.districtCode) {
                    this.setState({ selectedAdrCurrentDistrict: val })
                }
            }
        }
        if (!selectedAdrCurrentSubDistrict && addr_current_subdistrict_code) {
            for (let key in currentSubDistrict) {
                let val = currentSubDistrict[key]
                if (addr_current_subdistrict_code == val.subDistrictCode) {
                    this.setState({ selectedAdrCurrentSubDistrict: val })
                }
            }
        }

    }
    //> Validate
    validateRequire = async (state) => {
        this._classNameRequired(false)

        if (state.person_type == '1') {
            this._validateLogic(state.person_prefix_code)
            this._validateLogic(state.person_first_name)
            this._validateLogic(state.person_last_name)
            if (state.person_idcard) {
                if (Validators.isInValid(state.person_idcard, 13)) {
                    this._classNameRequired(true)
                }
            }
        } else {
            this._validateLogic(state.org_type)
            this._validateLogic(state.org_name)
            this._validateLogic(state.org_code)
            if (state.org_code) {
                if (Validators.isInValid(state.org_code, 13)) {
                    this._classNameRequired(true)
                }
            } else {
                this._classNameRequired(true)
            }
        }

        //Password
        if (state.isChangePassword == true) {
            this._validateLogic(state.oldPassword);
            if (state.newPassword != state.confirmPassword) {
                this._classNameRequired(true)
            }
        }
        //Email
        if (state.email) {
            if (!Validators.isEmail(state.email)) {
                this._classNameRequired(true)
            }
        }
        //Mobile number
        if (Validators.isInValidPhoneNumberRequired(state.telephone_no1, 10)) {
            this._classNameRequired(true)
        }
        //address
        this._validateAddressInfo(1);
        //Phone
        if(state.isErrorPhone||!state.telephone_no1){
            this._classNameRequired(true)
        }

    }
    _validateAddressInfo = (type) => {
        //>Card
        let chkCardProv = Validators.isEmpty(this.state.selectedAdrCardProv)
        let chkCardDistrict = Validators.isEmpty(this.state.selectedAdrCardDistrict)
        let chkCardSubDistrict = Validators.isEmpty(this.state.selectedAdrCardSubDistrict)
        let chkCardAddress = Validators.isEmpty(this.state.addr_card_detail)
        //>Current
        let chkCurrentProv = Validators.isEmpty(this.state.selectedAdrCurrentProv)
        let chkCurrentDistrict = Validators.isEmpty(this.state.selectedAdrCurrentDistrict)
        let chkCurrentSubDistrict = Validators.isEmpty(this.state.selectedAdrCurrentSubDistrict)
        let chkCurrentAddress = Validators.isEmpty(this.state.addr_current_detail)
        if (type == 2) {
            if (
                chkCardProv
                || chkCardDistrict
                || chkCardSubDistrict
                || chkCardAddress
            ) {
                this._classNameRequired(true)
            }
        } else {
            if (
                chkCurrentProv
                || chkCurrentDistrict
                || chkCurrentSubDistrict
                || chkCurrentAddress
            ) {
                this._classNameRequired(true)
            }
        }
    }
    _validateLogic = (value) => {
        if (Validators.isEmpty(value)) {
            this._classNameRequired(true)
        }
    }
    _classNameRequired = (status) => {
        const classNameRequired = status == true ? "p-error" : "";
        this.setState({ classNameRequired: classNameRequired })
        this.setState({ isValidateError: status });
    }

    onSelectFileImgPerson = (e) => {
        this.setState({ fileImgPerson: e.files[0] });
    }
    onRefreshPerson = (e) => {
        this.setState({ img_person_name: this.props.userInfoModel.img_person_name })
        this.setState({
            listFileDelete: this.state.listFileDelete.filter(item => item != this.props.userInfoModel.img_person_name)
        })
    }
    onDeletePerson = (e) => {
        this.state.listFileDelete.push(this.state.img_person_name)
        this.setState({ img_person_name: null })
        this.setState({ fileImgPerson: null });
    }

    //> Event
    onChangeInputText = (e) => {
        const { name, value, required } = e.target
        this.setState({ [name]: value })

        //case check email
        if (name == "email") {
            if (!Validators.isEmpty(value) && !Validators.isEmail(value)) {
                this.setState({ isValidateError: true })
            } else {
                this.setState({ isValidateError: false })
            }
        }

        if (required) {
            if (Validators.isEmpty(value)) {
                this.setState({ isValidateError: true })
            } else {
                this.setState({ isValidateError: false })
            }
        }
        //Tel mobile 1
        if (name == 'telephone_no1') {
            var telNameModel = {
                telephoneNumber: value
            }
            ServerAPI.checkExitPhoneAndName(telNameModel).then((res) => {
                let tmpChkUid = res.filter((value) => {
                    return value.userId == SecureUtils.getItem('uid')
                })
                if (res.length > 0 && tmpChkUid.length == 0) {
                    this.setState({ errMobileMsg: "หมายเลข " + value + " มีอยู่แล้วในระบบ" });
                    this.setState({ isErrorPhone: true });
                } else {
                    this.setState({ errMobileMsg: null });
                    this.setState({ isErrorPhone: false });
                }
            }).catch((error) => {
                this.setState({ errMobileMsg: "กรุณาติดต่อผู้ดูแลระบบ" });
                this.setState({ isErrorPhone: true });
            })
        }

    }
    //> Event Profile info
    onChangePersonPrefix = (e) => {
        const { name, value } = e.target
        this.setState({ selectedPersonPrefix: value })
        this.setState({ [name]: value.code })
    }
    onChangeCompanyType = (e) => {
        const { name, value } = e.target
        this.setState({ selectedCompanyType: value })
        this.setState({ [name]: value.code })
    }
    //> Event :Bank
    onChanageBank = (e) => {
        const { name, value } = e.target
        this.setState({ selectedBank: value })
        this.setState({ [name]: value.bank_code })
    }
    //> Event : Adress current
    onChangeAdrCurrProv = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCurrentProv: value })
        this.setState({ addr_current_province_code: value.provinceCode });
        this.setState({ selectedAdrCurrentDistrict: null })
        this.setState({ addr_current_district_code: null })
        this.setState({ selectedAdrCurrentSubDistrict: null })
        this.setState({ addr_current_subdistrict_code: null })

        this.props.dispatch(getDistrict(value.provinceCode));
    }
    onChangeAdrCurrentDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCurrentDistrict: value })
        this.setState({ addr_current_province_code: value.provinceCode });

        this.setState({ selectedAdrCurrentSubDistrict: null })
        this.setState({ addr_current_subdistrict_code: null })

        this.props.dispatch(getSubDistrict(value.districtCode));
    }
    onChangeAdrCurrentSubDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCurrentSubDistrict: value })
        this.setState({ addr_current_province_code: value.provinceCode });
        this.setState({ addr_current_district_code: value.districtCode });
        this.setState({ addr_current_subdistrict_code: value.subDistrictCode });
    }
    //> Event change password
    onChangeSwitchPassword = (e) => {
        this.setState({ isShowChangePassword: e.checked })
        if (this.state.isShowChangePassword) {
            //console.log("isShow is ", this.state.isShowChangePassword)
            this.setState({ isChangePassword: false })
        }
    }
    onChangeOldPassword = (e) => {
        let password = e.target.value;
        this.setState({ errorMessageOldPassword: null });
        if (Validators.isNotEmpty(password)) {
            this.setState({ errorMessageOldPassword: "" });
            this.setState({ isChangePassword: true })
            this.setState({ oldPassword: password })
            this.setState({ disableNewPass: false });
            if (
                this.state.newPassword != this.state.confirmPassword
                && !this.state.newPassword
                || !this.state.confirmPassword
            ) {
                this.setState({ errorMessagePassword: "กรุณาระบุรหัสผ่าน" });
                this.setState({ errorMessageConfirmPassword: "กรุณาระบุรหัสผ่าน" });

                this._classNameRequired(true)
            } else {
                this._classNameRequired(false)
            }
        } else {
            this.setState({ isChangePassword: false })
            this._classNameRequired(false);
            this.setState({ disableNewPass: true });

        }
    }
    onChangeNewPassword = (e) => {
        let password = e.target.value;
        this.setState({ errorMessagePassword: null });

        if (password.length >= 6) {
            const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
            const isOk = re.test(password);
            if (!isOk) {
                this.setState({ errorMessagePassword: "ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่" });
                this._classNameRequired(true)
            } else {
                this.setState({ newPassword: password });
                this.setState({ errorMessagePassword: "" });
                if (password == this.state.confirmPassword) {
                    this.setState({ errorMessageConfirmPassword: "" });
                    this._classNameRequired(false)
                } else {
                    this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
                    this._classNameRequired(true)
                }
            }
        } else {
            this.setState({ errorMessagePassword: "กรุณากรอกอย่างน้อย 6 ตัวอักษร (ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่)" });
            this._classNameRequired(true)
        }
    }
    onChangeConfirmPassword = (e) => {
        let cfpassword = e.target.value;
        this.setState({ errorMessageConfirmPassword: "" });
        //console.log("isChangePassword ", this.state.isChangePassword, "pass ", this.state.newPassword, " cfpass ", cfpassword)
        if (this.state.newPassword == cfpassword) {
            this.setState({ confirmPassword: cfpassword });
            this.setState({ errorMessageConfirmPassword: "" });
            this.setState({ isChangePassword: true })
            this._classNameRequired(false)
        } else {
            this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
            this.setState({ confirmPassword: cfpassword });

            this._classNameRequired(true)
        }
    }
    onChangePasswordVisible = (e) => {
        this.setState({ checkedPasswordVisible: e.value })
        if (e.value) {
            this.setState({ typePasswordInput: 'text' });
        } else {
            this.setState({ typePasswordInput: 'password' });
        }
    }
    //>Change pin
    onClickShow = (e) => {
        this.setState({ isShowChangePin: e.checked })
    }
    onChangePin = (e) => {
        const { name, value } = e.target
        this.setState({ isChangePin: true })
        this.setState({ [name]: value })

        //validate input pin number
        if (name == 'inputPin') {
            if (Validators.isEmpty(value)) {
                this.setState({ isValidBankWithDrawCode: false })
            } else {
                this.setState({ isValidBankWithDrawCode: true })
            }
        }
        this.validatePassword(this.state.passwordPin)
    }
    onChangePinPassword = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
        //Validate required
        if (Validators.isNotEmpty(value)) {
            this.validatePassword(value)
        }
    }
    validatePassword = async (password) => {
        //Validate required
        if (password != SecureUtils.getItem("user").password) {
            this.setState({ isValidPasswordPin: false })
        } else {
            this.setState({ isValidPasswordPin: true })
        }
    }
    onClickVisible = (e) => {
        this.setState((pv) => ({ isPassPinVisible: !pv.isPassPinVisible }))
    }
    onSubmitChangePin = async (e) => {
        //Validate required
        await this.validatePassword(this.state.passwordPin)
        if (this.state.isChangePin && this.state.isValidPasswordPin) {
            var jsDataPin = {
                password: this.state.passwordPin,
                bank_withdraw_code: this.state.inputPin
            };
            this.props.dispatch(wastegenChangePin(jsDataPin))
            this.setState({ isSubmitPin: true })
        } else {
            if (!this.state.isValidPasswordPin) {
                this.showWarn("กรุณาระบุรหัสผ่านให้ถูกต้อง")
            }

            if (Validators.isEmpty(this.state.inputPin)) {
                this.showWarn("กรุณาระบุข้อมูล PIN ให้ถูกต้อง")
                this.setState({ isValidBankWithDrawCode: false })
            }
        }
    }
    //> Event map
    onCenterChanged = (coor) => {
        if (coor) {
            this.setState({ latitude: coor.lat })
            this.setState({ longitude: coor.lng })
            this.setState({ marker: { lat: coor.lat, lng: coor.lng } })

        }

    }
    onMarkerMoved = (e) => {
        //console.log("onMarkerMoved");

        var coor = e.latLng.toJSON();
        this.setState({ latitude: coor.lat })
        this.setState({ longitude: coor.lng })
        this.setState({ marker: { lat: coor.lat, lng: coor.lng } })
        this.setState({ mapCenter: { lat: coor.lat, lng: coor.lng } })

    }



    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    render() {
        //console.log("ExampleWastegenProfileCtrl :render", this.state)

        return (
            <div>
                <h1>Profile</h1>
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col-12">
                            <Accordion activeIndex={[0,1,2]} multiple={true}>
                                <AccordionTab header="ข้อมูลสมาชิก">
                                    {!this.props.userInfoLoad.isLoading &&
                                        <ProfileInfoView
                                            {...this.state}
                                            mode={this.state.mode}
                                            onChangeInputText={this.onChangeInputText}
                                            onChangePersonPrefix={this.onChangePersonPrefix}
                                            onChangeCompanyType={this.onChangeCompanyType}
                                        />
                                    }
                                </AccordionTab>
                                

                                <AccordionTab header="รายละเอียดที่อยู่ติดต่อ">
                                    {
                                        <AddressSwapInfoView
                                            {...this.state}
                                            mode={this.state.mode}
                                            required={true}
                                            userCardAddress={this.state.userCardAddress}
                                            onChangeInputText={this.onChangeInputText}
                                            onChangeAdrCurrProv={this.onChangeAdrCurrProv}
                                            onChangeAdrCurrentDistrict={this.onChangeAdrCurrentDistrict}
                                            onChangeAdrCurrentSubDistrict={this.onChangeAdrCurrentSubDistrict}
                                        />
                                    }
                                </AccordionTab>
                            </Accordion>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <div className="p-col-12">
                            <Accordion activeIndex={[0,1,2]} multiple={true}>
                                <AccordionTab header="รายละเอียดบัญชีธนาคาร">
                                    {
                                        <BankInfoView
                                            {...this.state}
                                            mode={this.state.mode}
                                            onChangeInputText={this.onChangeInputText}
                                            onChanageBank={this.onChanageBank}
                                        />
                                    }
                                </AccordionTab>
                            </Accordion>
                        </div>
                        {/* {
                            <ChangePinView
                                mode={this.state.modePin}
                                isShow={this.state.isShowChangePin}
                                onClickShow={this.onClickShow}
                                bank_withdraw_code={this.state.inputPin}
                                onChangePin={this.onChangePin}
                                password={this.state.passwordPin}
                                onChangePassword={this.onChangePinPassword}
                                isValidPassword={this.state.isValidPasswordPin}
                                isValidBankWithDrawCode={this.state.isValidBankWithDrawCode}
                                onClickSubmitPin={this.onSubmitChangePin}
                                isPassPinVisible={this.state.isPassPinVisible}
                                onClickVisible={this.onClickVisible}
                            />
                        } */}
                        <div className="p-col-12">
                        <Accordion activeIndex={[0,1,2]} multiple={true}>
                        <AccordionTab header="Location google address">
                                <div className="p-grid">
                                    <div className="p-col-12">

                                        <label >

                                            Address location (latitude: {this.state.latitude} longitude:{this.state.longitude})
                                    </label>
                                    </div>

                                    <div className="p-col-12">
                                        <MapWithASearchBox
                                            zoom={this.state.mapZoom}
                                            center={this.state.mapCenter}
                                            marker={this.state.marker}
                                            onCenterChanged={this.onCenterChanged}
                                            onMarkerMoveEnd={this.onMarkerMoved} />
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                        </div>
                        
                        <div className="p-col-12">
                            <Accordion activeIndex={[0,1,2]} multiple={true}>
                            <AccordionTab header="รูปโปรไฟล์">
                                <ImageInfoView
                                    header="รูปถ่ายสมาชิก"
                                    src={`${API_ROOT}/wastegen/getfile/${this.state.img_person_name}`}
                                    fileName={this.state.img_person_name}
                                    name="filePerson[]"
                                    onSelect={this.onSelectFileImgPerson}
                                    blob={this.state.fileImgPerson}
                                    accept="image/*" 
                                    onRefresh={this.onRefreshPerson}
                                    onDelete={this.onDeletePerson}
                                />
                                </AccordionTab>
                            </Accordion>
                        </div>
                        {/* <div className="card card-w-title">
                            <h1 className="p-col-12">
                                <label>เปลี่ยนรหัสผ่าน</label>
                                <Checkbox
                                    style={{ float: 'right' }}
                                    checked={this.state.isShowChangePassword}
                                    onChange={this.onChangeSwitchPassword} />
                            </h1>
                            {this.state.isShowChangePassword == true &&
                                <ChangePassword
                                    {...this.state}
                                    onChangeOldPassword={this.onChangeOldPassword}
                                    onChangeNewPassword={this.onChangeNewPassword}
                                    onChangeConfirmPassword={this.onChangeConfirmPassword}
                                    onChangePasswordVisible={this.onChangePasswordVisible}
                                />}
                        </div> */}
                        
                    </div>

                    {(localStorage.getItem('second-token'))?'':
                        <div className="p-col-12">
                            {this.props.updateWastegenProfileLoad.isLoading &&
                                <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                            }
                            {!this.props.updateWastegenProfileLoad.isLoading &&
                                <Button
                                    className="p-col-12"
                                    label="บันทึก"
                                    icon="pi pi-check"
                                    iconPos="left"
                                    onClick={this.onSubmit} />
                            }
                        </div>
                    }
                    
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    provinceModel: state.location.province,
    currentDistrictModel: state.location.district,
    currentSubDistrictModel: state.location.subDistrict,
    userInfoModel: state.userInfo.data,
    banksModel: state.master.banks,
    changePasswordModel: state.changePassword.data,

    locationLoad: state.location,
    userInfoLoad: state.userInfo,
    masterLoad: state.master,
    changePasswordLoad: state.changePassword,
    updateWastegenProfileLoad: state.updateWastegenProfile,
    wastegenChangePin: state.wastegenChangePin
})

export default connect(mapStateToProps)(ExampleWastegenProfileCtrl)