

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';

import { getDistrict, getSubDistrict, getCardDistrict, getCardSubDistrict, getChipDistrict, getArrChipDistrict } from '../../service/LocationService';
import { Validators, SecureUtils } from '../../utils';
import { ProfileInfoView } from '../profile/ProfileInfoView';
import ImageInfoView from '../profile/ImageInfoView';
import { API_ROOT } from '../../constants/api';
import AddressSwapInfoView from '../profile/AddressSwapInfoView';
import DrivenInfoView from '../profile/DrivenInfoView';
import BankInfoView from '../profile/BankInfoView';
import LocationRecycleView from '../profile/LocationRecycleView';
import { Checkbox } from 'primereact/checkbox';
import ChangePassword from '../change-password/ChangePassword';
import { getUserInfo, updateWastetranProfile, changePassword } from '../../service/user.service';
import { ProgressSpinner } from 'primereact/progressspinner';

import { deleteWasteTranFile } from '../../service/file.service';
import { wastetranChangePin } from '../../service/wastetran.service'
import * as ServerAPI from '../register/service'

import { ChangePinView } from '../changepin/ChangePinView';
import { Accordion, AccordionTab } from 'primereact/accordion';

class ExampleWastetranChangePassword extends Component {


    constructor(props) {
        console.log("ExampleWastetranChangePassword :constructor")
        super(props);
        this.state = {
            //> page control
            isInitial: true,
            isUpdate: false,

            isValidateError: false,
            mode: 'wastetran',
            //> profile info
            selectedPersonPrefix: null,
            selectedCompanyType: null,
            personPrefixs: [
                { name: 'นาย', code: 1 },
                { name: 'นาง', code: 2 },
                { name: 'นางสาว', code: 3 },
            ],
            companyTypes: [
                { name: 'บริษัทจำกัด', code: 1 },
                { name: 'ห้างหุ้นส่วน', code: 2 },
                { name: 'บริษัทมหาชน', code: 3 },
                { name: 'ห้างร้าน', code: 4 },
            ],
            listFileDelete: [],
            errMobileMsg:null,
            isErrorPhone:false,
            //> Bank info
            selectedBank: null,
            //> Change Pin
            modePin: 2,
            isShowChangePin: false,
            isChangePin: false,
            isSubmitPin: false,
            inputPin: null,
            passwordPin: null,
            isValidBankWithDrawCode: true,
            isValidPasswordPin: true,
            isPassPinVisible: false,
            //> Address card user current
            checkCardAddress: null,
            //> Address Current info
            selectedAdrCurrentProv: null,
            selectedAdrCurrentDistrict: null,
            selectedAdrCurrentSubDistrict: null,
            //> Address Card info
            selectedAdrCardProv: null,
            selectedAdrCardDistrict: null,
            selectedAdrCardSubDistrict: null,
            //> Chip
            isInitialLoadChip: true,
            provinceChipSelected: null,
            districtChipSelected: null,
            selectedChipDistrict: [],
            //> PASSWORD
            isShowChangePassword: false,
            isChangePassword: false,
            checkedPasswordVisible: false,
            typePasswordInput: 'password',
            errorMessageOldPassword: null,
            errorMessagePassword: null,
            errorMessageConfirmPassword: null,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            disableNewPass: true
        }
    }

    componentDidMount() {
        console.log("ExampleWastetranChangePassword :componentDidMount")
        this.props.dispatch(getUserInfo());

    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("ExampleWastetranChangePassword :componentWillReceiveProps")
        if (
            nextProps.userInfoModel
            && !nextProps.userInfoLoad.isLoading
            && !nextProps.changePasswordLoad.isLoading
            && !nextProps.locationLoad.isFetching
            && !nextProps.getArrChipDistrict.isLoading
        ) {
            this.initialDropdown(nextProps);

            if (this.state.isInitial) {
                if (
                    nextProps.userInfoModel
                ) {
                    this.initial(nextProps.userInfoModel)
                    this.initialSelected(this.state)
                }

            }
        }
        //> Status submit
        if (this.state.isSubmitPin && this.state.isChangePin && !nextProps.wastetranChangePin.isLoading) {
            if (nextProps.wastetranChangePin.isFailed) {
                this.showError("ไม่สามารถเปลี่ยนรหัสเลขใช้สำหรับแจ้งถอนเงิน กรุณาตรวจสอบรหัสผ่าน")
                this.setState({ isChangePin: false })
                this.setState({ isSubmitPin: false })
            } else {
                this.showSuccess("เปลี่ยนรหัสเลขใช้สำหรับแจ้งถอนเงิน สำเร็จ")
                this.setState({ isChangePin: false })
                this.setState({ isSubmitPin: false })
                this.setState({ inputPin: null })
                this.setState({ passwordPin: null })
                this.setState({isShowChangePin:false})
                this.props.dispatch(getUserInfo());
                this.setState({ isInitial: true })
            }
        }
        if (this.state.isUpdate) {
            switch (this.state.isChangePassword) {
                case true:
                    if (!nextProps.changePasswordLoad.isLoading) {
                        if (nextProps.changePasswordLoad.isFailed) {
                            this.showError("รหัสผ่าน :" + nextProps.changePasswordModel)
                            this.setState({ isUpdate: false })
                            this.setState({ isInitial: false })
                            this.setState({ isChangePassword: false })
                        } else {
                            this.saveWastetran()
                            if (!nextProps.updateWastetranProfileLoad.isLoading) {
                                if (!nextProps.updateWastetranProfileLoad.isFailed) {
                                    this.props.dispatch(getUserInfo());
                                    this.showSuccess("บันทึกข้อมูลสำเร็จ")
                                    this.setState({ isUpdate: false })
                                    this.setState({ isInitial: true })
                                    this.setState({ isChangePassword: false })
                                    this.setState({ isShowChangePassword: false })
                                    this.setState({ listFileDelete: [] })
                                }

                                if (nextProps.updateWastetranProfileLoad.isFailed) {
                                    this.showError("บันทึกข้อมูลผิดพลาด")
                                    this.setState({ isUpdate: false })
                                    this.setState({ isInitial: false })
                                }
                            }
                        }
                    }
                    break;
                case false:
                    if (!nextProps.userInfoLoad.isLoading) {
                        if (!nextProps.userInfoLoad.isFailed) {
                            this.props.dispatch(getUserInfo());
                            this.showSuccess("บันทึกข้อมูลสำเร็จ")
                            this.setState({ isUpdate: false })
                            this.setState({ isInitial: true })
                            this.setState({ isChangePassword: false })
                            this.setState({ isShowChangePassword: false })
                            this.setState({ listFileDelete: [] })
                        }

                        if (nextProps.userInfoLoad.isFailed) {
                            this.showError("บันทึกข้อมูลผิดพลาด")
                            this.setState({ isUpdate: false })
                            this.setState({ isInitial: false })
                        }
                    }
                    break;
            }
        }


    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("ExampleWastetranChangePassword :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("ExampleWastetranChangePassword :componentWillUpdate")
        if (this.state.isValidateError != nextState.isValidateError) {
            this.validateRequire(nextState)
        }
    }
    componentDidUpdate(prevProps, prevState) {
        console.log("ExampleWastetranChangePassword :componentDidUpdate")
        this.initialSelected(this.state)
        if (this.state.person_type != prevState.person_type) {
            this.validateRequire(this.state)
        }
    }

    saveWastetran = () => {
        //remove pin out of process
        delete this.state['bank_withdraw_code']

        const formData = new FormData();
        for (let key in this.props.userInfoModel) {
            let valState = this.state[key]
            if (typeof valState == 'object') {
                if (Validators.isNotEmpty(valState)) {
                    formData.append([key], JSON.stringify(valState))
                }
            } else {
                if (Validators.isNotEmpty(valState)) {
                    formData.set([key], valState || '')
                }
            }
        }

        //Custom field other overide that
        let areListString = this.state.selectedChipDistrict
        formData.set('areaListString', JSON.stringify(areListString))
        formData.set("addr_card_use_current", this.state.checkCardAddress ? 1 : 0)

        //>custom File
        const fLimit = 1000000;
        if (this.state.fileImgIden && this.state.fileImgIden.size <= fLimit) {
            formData.append('FileImgIden', this.state.fileImgIden);
        }
        if (this.state.fileImgRegis && this.state.fileImgRegis.size <= fLimit) {
            formData.append('FileImgRegis', this.state.fileImgRegis);
        }
        if (this.state.fileImgPerson && this.state.fileImgPerson.size <= fLimit) {
            formData.append('FileImgPerson', this.state.fileImgPerson);
        }
        if (this.state.fileImgVehicle && this.state.fileImgVehicle.size <= fLimit) {
            formData.append('FileImgVehicle', this.state.fileImgVehicle);
        }

        //Delete file in list action
        this.state.listFileDelete.map(fileName => {
            fileName = fileName || ""
            let fileType = null;
            let spFname = fileName.split("-")
            if (spFname.length > 0) {
                fileType = spFname[0]
            }
            this.props.dispatch(deleteWasteTranFile(fileType, fileName))
        })
        this.props.dispatch(updateWastetranProfile(formData));

    }

    //> Submit
    onSubmit = async (e) => {
        e.preventDefault();

        await this.validateRequire(this.state);

        if (this.state.isValidateError) {
            this.showWarn();
        } else {
            var isNotinput = (
                this.state.oldPassword == '' ||
                this.state.newPassword == '' ||
                this.state.confirmPassword==''
            );
            if (isNotinput) {
                this.showWarn();
            }else{
                console.log(this.state.oldPassword)
                console.log( this.state.newPassword)
                console.log(this.state.confirmPassword)
            if (this.state.isChangePassword) {
                var jsPass = {
                    username: SecureUtils.getItem('user').username,
                    oldPassword: this.state.oldPassword,
                    newPassword: this.state.newPassword,
                    confirmPassword: this.state.confirmPassword,
                    statusMessage: "string",
                }
                this.props.dispatch(changePassword(JSON.parse(JSON.stringify(jsPass))))
            } else {
                this.saveWastetran()
                this.setState({ isShowChangePassword: false })
            }

            this.setState({ isUpdate: true })
            }
        }
    }

    setUid = (userInfo) => {
        if (userInfo && userInfo.user_wasteTypes) {
            for (let key in userInfo.user_wasteTypes) {
                this.setState({ uid: userInfo.user_wasteTypes[key].uid })
            }
        }
    }
    //> Initial function
    initial = (nextProps) => {
        if (nextProps) {
            for (let key in nextProps) {
                let val = nextProps[key]
                this.setState({ [key]: val })
            }
            this.setState({ isInitial: false })
            this.setUid(nextProps)
            //> pin
            if (Validators.isNotEmpty(nextProps.bank_withdraw_code)) {
                this.setState({
                    modePin: 1
                })
            } else {
                this.setState({
                    modePin: 2
                })
            }
            //>chipselect
            if (this.state.isInitialLoadChip == true
                && this.state.selectedChipDistrict.length == 0
                && nextProps.areaList
                && nextProps.areaList.length > 0
            ) {
                for (let key in nextProps.areaList) {
                    let entity = nextProps.areaList[key];
                    this.state.selectedChipDistrict.push(entity);
                }
                this.setState({ isInitialLoadChip: false })
            }
        }
    }
    //> Initial Droupdown
    initialDropdown = (nextProps) => {
        console.log("Initial Droupdown ", nextProps)
        //> banks
        if (nextProps.banks && nextProps.banks.length > 0) {
            this.setState({ banks: nextProps.banks })
        }
        //> adrees current info
        if (nextProps.provinceModel && nextProps.provinceModel.length > 0) {
            this.setState({ province: nextProps.provinceModel })
        }
        if (nextProps.currentDistrictModel && nextProps.currentDistrictModel.length > 0) {
            this.setState({ currentDistrict: nextProps.currentDistrictModel })
        }
        if (nextProps.currentSubDistrictModel && nextProps.currentSubDistrictModel.length > 0) {
            this.setState({ currentSubDistrict: nextProps.currentSubDistrictModel })
        }
        //> adrees card info
        if (nextProps.cardProvinceModel && nextProps.cardProvinceModel.length > 0) {
            this.setState({ cardProvince: nextProps.cardProvinceModel })
        }
        if (nextProps.cardDistrictModel && nextProps.cardDistrictModel.length > 0) {
            this.setState({ cardDistrict: nextProps.cardDistrictModel })
        }
        if (nextProps.cardSubDistrictModel && nextProps.cardSubDistrictModel.length > 0) {
            this.setState({ cardSubDistrict: nextProps.cardSubDistrictModel })
        }
        //> chip
        if (nextProps.chipProvinceModel && nextProps.chipProvinceModel.length > 0) {
            this.setState({ chipProvince: nextProps.chipProvinceModel })
        }
        if (nextProps.chipDistrictModel && nextProps.chipDistrictModel.length > 0) {
            this.setState({ chipDistrict: nextProps.chipDistrictModel })
        }
    }
    //> Initail selected
    initialSelected = (nextState) => {
        console.log("Initail selected ", nextState)

        const {
            //profile info
            selectedPersonPrefix,
            person_prefix_code,
            personPrefixs,
            selectedCompanyType,
            org_type,
            companyTypes,
            //bank info
            selectedBank,
            bank_code,
            banks,
            //Adress current info : province
            selectedAdrCurrentProv,
            addr_current_province_code,
            province,
            //Adress current info : district
            selectedAdrCurrentDistrict,
            addr_current_district_code,
            currentDistrict,
            //Adress current info : sub district
            selectedAdrCurrentSubDistrict,
            addr_current_subdistrict_code,
            currentSubDistrict,

            //Adress Card info : province
            selectedAdrCardProv,
            addr_card_province_code,
            cardProvince,
            //Address user current
            checkCardAddress,
            addr_card_use_current,
            //Adress current info : district
            selectedAdrCardDistrict,
            addr_card_district_code,
            cardDistrict,
            //Adress current info : sub district
            selectedAdrCardSubDistrict,
            addr_card_subdistrict_code,
            cardSubDistrict,
            //Chip
            isInitialLoadChip,
            chipProvince,
            areaList,
            provinceChipSelected,
            selectedChipDistrict,
        } = nextState

        //> profile info
        if (!selectedPersonPrefix && person_prefix_code) {
            for (let key in personPrefixs) {
                let val = personPrefixs[key]
                if (person_prefix_code == val.code) {
                    this.setState({ selectedPersonPrefix: val })
                }
            }
        }
        if (!selectedCompanyType && org_type) {
            for (let key in companyTypes) {
                let val = companyTypes[key]
                if (org_type == val.code) {
                    this.setState({ selectedCompanyType: val })
                }
            }
        }

        if (!selectedPersonPrefix && person_prefix_code) {
            for (let key in personPrefixs) {
                let val = personPrefixs[key]
                if (person_prefix_code == val.code) {
                    this.setState({ selectedPersonPrefix: val })
                }
            }
        }

        //> bank info
        if (!selectedBank && bank_code) {
            for (let key in banks) {
                let val = banks[key]
                if (bank_code == val.bank_code) {
                    this.setState({ selectedBank: val })
                }
            }
        }
        //> Adress use current
        if (checkCardAddress == null && addr_card_use_current) {
            if (addr_card_use_current == 1) {
                this.setState({ checkCardAddress: true })
            }
        }
        //> Adress current info
        if (!selectedAdrCurrentProv && addr_current_province_code) {
            for (let key in province) {
                let val = province[key]
                if (addr_current_province_code == val.provinceCode) {
                    //console.log("province ")
                    this.setState({ selectedAdrCurrentProv: val })
                }
            }
        }
        if (!selectedAdrCurrentDistrict && addr_current_district_code) {
            for (let key in currentDistrict) {
                let val = currentDistrict[key]
                if (addr_current_district_code == val.districtCode) {
                    this.setState({ selectedAdrCurrentDistrict: val })
                }
            }
        }
        if (!selectedAdrCurrentSubDistrict && addr_current_subdistrict_code) {
            for (let key in currentSubDistrict) {
                let val = currentSubDistrict[key]
                if (addr_current_subdistrict_code == val.subDistrictCode) {
                    this.setState({ selectedAdrCurrentSubDistrict: val })
                }
            }
        }
        //> Adress card info
        if (!selectedAdrCardProv && addr_card_province_code) {
            for (let key in cardProvince) {
                let val = cardProvince[key]
                if (addr_card_province_code == val.provinceCode) {
                    //console.log("province ")
                    this.setState({ selectedAdrCardProv: val })
                }
            }
        }
        if (!selectedAdrCardDistrict && addr_card_district_code) {
            for (let key in cardDistrict) {
                let val = cardDistrict[key]
                if (addr_card_district_code == val.districtCode) {
                    this.setState({ selectedAdrCardDistrict: val })
                }
            }
        }
        if (!selectedAdrCardSubDistrict && addr_card_subdistrict_code) {
            for (let key in cardSubDistrict) {
                let val = cardSubDistrict[key]
                if (addr_card_subdistrict_code == val.subDistrictCode) {
                    this.setState({ selectedAdrCardSubDistrict: val })
                }
            }
        }
        //>chip
        // if (!provinceChipSelected && chipProvince) {
        //     for (let key in areaList) {
        //         let entity = areaList[key];
        //         let provinceCode = entity.provinceCode;
        //         if (provinceCode) {
        //             for (let i in chipProvince) {
        //                 let p = chipProvince[i]
        //                 if (provinceCode == p.provinceCode) {
        //                     this.setState({ provinceChipSelected: p })
        //                 }
        //             }
        //             break
        //         }
        //     }
        // }

        // if (isInitialLoadChip == true
        //     && selectedChipDistrict.length == 0
        //     && areaList
        //     && !this.props.getArrChipDistrict.isLoading
        //     && !this.props.getArrChipDistrict.isFailed
        //     && this.props.getArrChipDistrict.data
        // ) {
        //     for (let key in areaList) {
        //         let entity = areaList[key];
        //         let distCode = entity.districtCode;

        //         if (
        //             distCode
        //         ) {
        //             for (let i in this.props.getArrChipDistrict.data) {
        //                 let item = this.props.getArrChipDistrict.data[i]
        //                 const ans = item.districtCode

        //                 if (distCode == ans) {
        //                     this.state.selectedChipDistrict.push(item);
        //                     this.setState({ isInitialLoadChip: false })
        //                     break;
        //                 }
        //             }
        //         }
        //     }

        // }

    }
    //> Validate
    validateRequire = async (state) => {
        this._classNameRequired(false)

        if (state.person_type == '1') {
            this._validateLogic(state.person_prefix_code)
            this._validateLogic(state.person_first_name)
            this._validateLogic(state.person_last_name)
            if (state.person_idcard) {
                if (Validators.isInValid(state.person_idcard, 13)) {
                    this._classNameRequired(true)
                }
            }
        } else {
            this._validateLogic(state.org_type)
            this._validateLogic(state.org_name)
            this._validateLogic(state.org_code)
            if (state.org_code) {
                if (Validators.isInValid(state.org_code, 13)) {
                    this._classNameRequired(true)
                }
            } else {
                this._classNameRequired(true)
            }
        }

        //Password
        if (state.isChangePassword == true) {
            this._validateLogic(state.oldPassword);
            if (state.newPassword != state.confirmPassword) {
                this._classNameRequired(true)
            }
        }
        //Email
        if (state.email) {
            if (!Validators.isEmail(state.email)) {
                this._classNameRequired(true)
            }
        }
        //Mobile number
        if (Validators.isInValidPhoneNumberRequired(state.telephone_no1, 10)) {
            this._classNameRequired(true)
        }
        //address
        // if (state.checkCardAddress) {
        //     this._validateAddressInfo(2)
        // } else {
        //     this._validateAddressInfo(1)
        //     this._validateAddressInfo(2)
        // }
        //location area recycle
        if (Validators.isEmpty(state.selectedChipDistrict)) {
            //this._classNameRequired(true)
        } 
        //Phone
        if(state.isErrorPhone||!state.telephone_no1){
            this._classNameRequired(true)
        }
    }
    _validateAddressInfo = (type) => {
        //>Card
        let chkCardProv = Validators.isEmpty(this.state.selectedAdrCardProv)
        let chkCardDistrict = Validators.isEmpty(this.state.selectedAdrCardDistrict)
        let chkCardSubDistrict = Validators.isEmpty(this.state.selectedAdrCardSubDistrict)
        let chkCardAddress = Validators.isEmpty(this.state.addr_card_detail)
        //>Current
        let chkCurrentProv = Validators.isEmpty(this.state.selectedAdrCurrentProv)
        let chkCurrentDistrict = Validators.isEmpty(this.state.selectedAdrCurrentDistrict)
        let chkCurrentSubDistrict = Validators.isEmpty(this.state.selectedAdrCurrentSubDistrict)
        let chkCurrentAddress = Validators.isEmpty(this.state.addr_current_detail)
        if (type == 2) {
            if (
                chkCardProv
                || chkCardDistrict
                || chkCardSubDistrict
                || chkCardAddress
            ) {
                this._classNameRequired(true)
            }
        } else {
            if (
                chkCurrentProv
                || chkCurrentDistrict
                || chkCurrentSubDistrict
                || chkCurrentAddress
            ) {
                this._classNameRequired(true)
            }
        }
    }
    _validateLogic = (value) => {
        if (Validators.isEmpty(value)) {
            this._classNameRequired(true)
        }
    }
    _classNameRequired = (status) => {
        const classNameRequired = status == true ? "p-error" : "";
        this.setState({ classNameRequired: classNameRequired })
        this.setState({ isValidateError: status })
    }
    //> Event
    onChangeInputText = (e) => {
        const { name, value, required } = e.target
        this.setState({ [name]: value })

        //case check email
        if (name == "email") {
            if (!Validators.isEmpty(value) && !Validators.isEmail(value)) {
                this.setState({ isValidateError: true })
            } else {
                this.setState({ isValidateError: false })
            }
        }
        //Check required
        if (required) {
            if (Validators.isEmpty(value)) {
                this.setState({ isValidateError: true })
            } else {
                this.setState({ isValidateError: false })
            }
        }
        //Tel mobile 1
        if (name == 'telephone_no1') {
            var telNameModel = {
                telephoneNumber: value
            }
            ServerAPI.checkExitPhoneAndName(telNameModel).then((res) => {
                let tmpChkUid = res.filter((value) => {
                    return value.userId == SecureUtils.getItem('uid')
                })
                if (res.length > 0 && tmpChkUid.length == 0) {
                    this.setState({ errMobileMsg: "หมายเลข " + value + " มีอยู่แล้วในระบบ" });
                    this.setState({ isErrorPhone: true });
                } else {
                    this.setState({ errMobileMsg: null });
                    this.setState({ isErrorPhone: false });
                }
            }).catch((error) => {
                this.setState({ errMobileMsg: "กรุณาติดต่อผู้ดูแลระบบ" });
                this.setState({ isErrorPhone: true });
            })
        }
    }
    //> Image View
    onSelectFileImgIden = (e) => {
        this.setState({ fileImgIden: e.files[0] });
    }
    onRefreshIden = (e) => {
        this.setState({ img_iden_name: this.props.userInfoModel.img_iden_name })
        this.setState({
            listFileDelete: this.state.listFileDelete.filter(item => item != this.props.userInfoModel.img_iden_name)
        })
    }
    onDeleteIden = (e) => {
        this.state.listFileDelete.push(this.state.img_iden_name)
        this.setState({ img_iden_name: null })
        this.setState({ fileImgIden: null });
    }
    onSelectFileImgRegis = (e) => {
        this.setState({ fileImgRegis: e.files[0] });
    }
    onRefreshRegis = (e) => {
        this.setState({ img_regis_org_name: this.props.userInfoModel.img_regis_org_name })
        this.setState({
            listFileDelete: this.state.listFileDelete.filter(item => item != this.props.userInfoModel.img_regis_org_name)
        })
    }
    onDeleteRegis = (e) => {
        this.state.listFileDelete.push(this.state.img_regis_org_name)
        this.setState({ img_regis_org_name: null })
        this.setState({ fileImgRegis: null });
    }
    onSelectFileImgPerson = (e) => {
        this.setState({ fileImgPerson: e.files[0] });
    }
    onRefreshPerson = (e) => {
        this.setState({ img_person_name: this.props.userInfoModel.img_person_name })
        this.setState({
            listFileDelete: this.state.listFileDelete.filter(item => item != this.props.userInfoModel.img_person_name)
        })
    }
    onDeletePerson = (e) => {
        this.state.listFileDelete.push(this.state.img_person_name)
        this.setState({ img_person_name: null })
        this.setState({ fileImgPerson: null });
    }
    onSelectFileImgVehicle = (e) => {
        this.setState({ fileImgVehicle: e.files[0] });
    }
    onRefreshVehicle = (e) => {
        this.setState({ vehicle_img_name: this.props.userInfoModel.vehicle_img_name })
        this.setState({
            listFileDelete: this.state.listFileDelete.filter(item => item != this.props.userInfoModel.vehicle_img_name)
        })
    }
    onDeleteVehicle = (e) => {
        this.state.listFileDelete.push(this.state.vehicle_img_name)
        this.setState({ vehicle_img_name: null })
        this.setState({ fileImgPerson: null });
    }
    //> Event Location Recycle
    onChangeProvinceChip = (e) => {
        this.setState({ provinceChipSelected: e.value })
        this.props.dispatch(getChipDistrict(e.value.provinceCode))
    }
    onChangeDistrictChip = (e) => {
        this.setState({ districtChipSelected: e.value });
        let chk = false;
        this.state.selectedChipDistrict.map((value) => {
            if (value.districtName == e.value.districtName) {
                chk = true
            }
        })
        if (chk == true) {
            this.showError("มีอำเภอซ้ำ กรุณาเลือกใหม่อีกครั้ง");
        } else {
            this.state.selectedChipDistrict.push(e.value);
        }
    }
    itemTemplate = (item) => {
        return (
            <div >
                <span>{item.districtName} {item.provinceName}</span>
                <i className="pi pi-user-plus" style={{ fontSize: '14px' }}></i>
            </div>
        );
    }
    onRemove = (e) => {
        this.setState({
            selectedChipDistrict: this.state.selectedChipDistrict.filter(item => item.districtCode != e.value[0].districtCode)
        })
    }
    //> Event Profile info
    onChangePersonPrefix = (e) => {
        const { name, value } = e.target
        this.setState({ selectedPersonPrefix: value })
        this.setState({ [name]: value.code })
    }
    onChangeCompanyType = (e) => {
        const { name, value } = e.target
        this.setState({ selectedCompanyType: value })
        this.setState({ [name]: value.code })
    }
    //> Event :Bank
    onChanageBank = (e) => {
        const { name, value } = e.target
        this.setState({ selectedBank: value })
        this.setState({ [name]: value.bank_code })
    }
    //> Event Address
    onDuplicate = (e) => {
        const { checked } = e
        this.setState({ checkCardAddress: checked })
    }
    onChangeAdrCurrProv = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCurrentProv: value })
        this.setState({ addr_current_province_code: value.provinceCode });
        this.setState({ selectedAdrCurrentDistrict: null })
        this.setState({ addr_current_district_code: null })
        this.setState({ selectedAdrCurrentSubDistrict: null })
        this.setState({ addr_current_subdistrict_code: null })

        this.props.dispatch(getDistrict(value.provinceCode));
    }
    onChangeAdrCurrentDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCurrentDistrict: value })
        this.setState({ addr_current_province_code: value.provinceCode });

        this.setState({ selectedAdrCurrentSubDistrict: null })
        this.setState({ addr_current_subdistrict_code: null })

        this.props.dispatch(getSubDistrict(value.districtCode));
    }
    onChangeAdrCurrentSubDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCurrentSubDistrict: value })
        this.setState({ addr_current_province_code: value.provinceCode });
        this.setState({ addr_current_district_code: value.districtCode });
        this.setState({ addr_current_subdistrict_code: value.subDistrictCode });
    }
    onChangeAdrCardProv = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCardProv: value })
        this.setState({ addr_card_province_code: value.provinceCode });
        this.setState({ selectedAdrCardDistrict: null })
        this.setState({ addr_card_district_code: null })
        this.setState({ selectedAdrCardSubDistrict: null })
        this.setState({ addr_card_subdistrict_code: null })

        this.props.dispatch(getCardDistrict(value.provinceCode));
    }
    onChangeAdrCardDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCardDistrict: value })
        this.setState({ addr_card_province_code: value.provinceCode });

        this.setState({ selectedAdrCardSubDistrict: null })
        this.setState({ addr_card_subdistrict_code: null })

        this.props.dispatch(getCardSubDistrict(value.districtCode));
    }
    onChangeAdrCardSubDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCardSubDistrict: value })
        this.setState({ addr_card_province_code: value.provinceCode });
        this.setState({ addr_card_district_code: value.districtCode });
        this.setState({ addr_card_subdistrict_code: value.subDistrictCode });
    }
    //> Event change password
    onChangeSwitchPassword = (e) => {
        this.setState({ isShowChangePassword: e.checked })
        if (this.state.isShowChangePassword) {
            console.log("isShow is ", this.state.isShowChangePassword)
            this.setState({ isChangePassword: false })
        }
    }
    onChangeOldPassword = (e) => {
        let password = e.target.value;
        this.setState({ errorMessageOldPassword: null });
        if (Validators.isNotEmpty(password)) {
            this.setState({ errorMessageOldPassword: "" });
            this.setState({ isChangePassword: true })
            this.setState({ oldPassword: password })
            this.setState({ disableNewPass: false });
            if (
                this.state.newPassword != this.state.confirmPassword
                && !this.state.newPassword
                || !this.state.confirmPassword
            ) {
                this.setState({ errorMessagePassword: "กรุณาระบุรหัสผ่าน" });
                this.setState({ errorMessageConfirmPassword: "กรุณาระบุรหัสผ่าน" });

                this._classNameRequired(true)
            } else {
                this._classNameRequired(false)
            }
        } else {
            this.setState({ isChangePassword: false })
            this._classNameRequired(false);
            this.setState({ disableNewPass: true });

        }
    }
    onChangeNewPassword = (e) => {
        let password = e.target.value;
        this.setState({ errorMessagePassword: null });

        if (password.length >= 6) {
            const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
            const isOk = re.test(password);
            if (!isOk) {
                this.setState({ errorMessagePassword: "ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่" });
                this._classNameRequired(true)
            } else {
                this.setState({ newPassword: password });
                this.setState({ errorMessagePassword: "" });
                if (password == this.state.confirmPassword) {
                    this.setState({ errorMessageConfirmPassword: "" });
                    this._classNameRequired(false)
                } else {
                    this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
                    this._classNameRequired(true)
                }
            }
        } else {
            this.setState({ errorMessagePassword: "กรุณากรอกอย่างน้อย 6 ตัวอักษร (ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่)" });
            this._classNameRequired(true)
        }
    }
    onChangeConfirmPassword = (e) => {
        let cfpassword = e.target.value;
        this.setState({ errorMessageConfirmPassword: "" });
        console.log("isChangePassword ", this.state.isChangePassword, "pass ", this.state.newPassword, " cfpass ", cfpassword)
        if (this.state.newPassword == cfpassword) {
            this.setState({ confirmPassword: cfpassword });
            this.setState({ errorMessageConfirmPassword: "" });
            this.setState({ isChangePassword: true })
            this._classNameRequired(false)
        } else {
            this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
            this.setState({ confirmPassword: cfpassword });

            this._classNameRequired(true)
        }
    }
    onChangePasswordVisible = (e) => {
        this.setState({ checkedPasswordVisible: e.value })
        if (e.value) {
            this.setState({ typePasswordInput: 'text' });
        } else {
            this.setState({ typePasswordInput: 'password' });
        }
    }

    //>Change pin
    onClickShow = (e) => {
        this.setState({ isShowChangePin: e.checked })
    }
    onChangePin = (e) => {
        const { name, value } = e.target
        this.setState({ isChangePin: true })
        this.setState({ [name]: value })

        //validate input pin number
        if (name == 'inputPin') {
            if (Validators.isEmpty(value)) {
                this.setState({ isValidBankWithDrawCode: false })
            } else {
                this.setState({ isValidBankWithDrawCode: true })
            }
        }
        this.validatePassword(this.state.passwordPin)
    }
    onChangePinPassword = (e) => {
        const { name, value } = e.target
        this.setState({ [name]: value })
        //Validate required
        if (Validators.isNotEmpty(value)) {
            this.validatePassword(value)
        }
    }
    validatePassword = async (password) => {
        //Validate required
        if (password != SecureUtils.getItem("user").password) {
            this.setState({ isValidPasswordPin: false })
        } else {
            this.setState({ isValidPasswordPin: true })
        }
    }
    onClickVisible = (e) => {
        this.setState((pv) => ({ isPassPinVisible: !pv.isPassPinVisible }))
    }
    onSubmitChangePin = async (e) => {
        //Validate required
        await this.validatePassword(this.state.passwordPin)
        if (this.state.isChangePin && this.state.isValidPasswordPin) {
            var jsDataPin = {
                password: this.state.passwordPin,
                bank_withdraw_code: this.state.inputPin
            };
            this.props.dispatch(wastetranChangePin(jsDataPin))
            this.setState({ isSubmitPin: true })
        } else {
            if (!this.state.isValidPasswordPin) {
                this.showWarn("กรุณาระบุรหัสผ่านให้ถูกต้อง")
            }

            if (Validators.isEmpty(this.state.inputPin)) {
                this.showWarn("กรุณาระบุข้อมูล PIN ให้ถูกต้อง")
                this.setState({ isValidBankWithDrawCode: false })
            }
        }
    }

    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }

    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }


    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    render() {
        console.log(this.state.selectedCompanyType)
        console.log("ExampleWastetranChangePassword :render", this.state)
        const { userInfoModel } = this.props
        return (
            <div className="p-col-12">
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid ">
                    <div className="p-col-12">
                        <div className="card card-w-title">
                            <h1 className="p-col-12">
                                <label>เปลี่ยนรหัสผ่าน</label>
                                {/* <Checkbox
                                    style={{ float: 'right' }}
                                    checked={this.state.isShowChangePassword}
                                    onChange={this.onChangeSwitchPassword} />
                                    */}
                            </h1> 
                            {/* {this.state.isShowChangePassword == true && */}
                                <ChangePassword
                                    {...this.state}
                                    onChangeOldPassword={this.onChangeOldPassword}
                                    onChangeNewPassword={this.onChangeNewPassword}
                                    onChangeConfirmPassword={this.onChangeConfirmPassword}
                                    onChangePasswordVisible={this.onChangePasswordVisible}
                                />
                                {/* } */}

                        </div>
                    </div>

                    <div className="p-col-12">
                        {this.props.updateWastetranProfileLoad.isLoading &&
                            <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                        }
                        {!this.props.updateWastetranProfileLoad.isLoading &&
                            <Button
                                className="p-col-12"
                                label="บันทึก"
                                icon="pi pi-check"
                                iconPos="left"
                                onClick={this.onSubmit} />
                        }
                    </div>

                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    provinceModel: state.location.province,
    currentDistrictModel: state.location.district,
    currentSubDistrictModel: state.location.subDistrict,
    cardProvinceModel: state.location.cardProvince,
    cardDistrictModel: state.location.cardDistrict,
    cardSubDistrictModel: state.location.cardSubDistrict,
    userInfoModel: state.userInfo.data,
    banksModel: state.master.banks,
    changePasswordModel: state.changePassword.data,

    chipProvinceModel: state.location.chipProvince,
    chipDistrictModel: state.location.chipDistrict,

    locationLoad: state.location,
    userInfoLoad: state.userInfo,
    masterLoad: state.master,
    changePasswordLoad: state.changePassword,
    updateWastetranProfileLoad: state.updateWastetranProfile,
    getArrChipDistrict: state.getArrChipDistrict,
    wastetranChangePin: state.wastetranChangePin
})

export default connect(mapStateToProps)(ExampleWastetranChangePassword)
