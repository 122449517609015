import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import * as UserService from '../service/user.service';
import { ProgressSpinner } from 'primereact/progressspinner';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import { ToggleButton } from 'primereact/togglebutton';
import { TYPE_ROOT } from '../constants/api'

class Login extends Component {

	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
			submitted: false,
			checkedPasswordVisible: false,
			typePasswordInput: 'password',
		};
	}

	setPasswordVisible = (e) => {
		console.log(e)
		if (this.state.checkedPasswordVisible) {
			this.setState({ checkedPasswordVisible: false })
			this.setState({ typePasswordInput: 'password' });
		} else {
			this.setState({ checkedPasswordVisible: true })
			this.setState({ typePasswordInput: 'text' });
		}
	}

	handleChange(e) {
		const { name, value } = e.target;
		this.setState({ [name]: value });
	}

	handleSubmit(e) {
		e.preventDefault();
		this.setState({ submitted: true });
		const { username, password } = this.state;
		if (username && password) {
			const { loginProps } = this.props
			loginProps.logout();
			loginProps.login(username, password);
		}
	}

	_handleKeyDownEnter = (e) => {
		if (e.key === 'Enter') {
			this.handleSubmit(e);
		}
	}

	facebookResponse = (response) => {
		const { loginProps } = this.props
		var authModel = {
			Provider: "Facebook",
			Token: response.accessToken
		}
		loginProps.logout();
		loginProps.loginExternal(authModel);

	};
	responseGoogle = (response) => {
		if(TYPE_ROOT === 'prod') {
			const { loginProps } = this.props
			var authModel = {
				Provider: "Google",
				Token: response.tokenId
			}
			loginProps.logout();
			loginProps.loginExternal(authModel);
		}
	};
	render() {


		const { username, password } = this.state

		const { authentication, loginProps } = this.props
		if (authentication && authentication.accessToken) {
			if (!authentication.userType) {
				if (!authentication.isLoading) {
					loginProps.getUserType();
				}
			}
		}

		if (authentication.logedIn) {
			window.location = '#/' + localStorage.getItem('user-type')
		}

		return (
			<div className="login-body">
				<div className="p-grid p-fluid">
					
					<div className="p-col-11 p-sm-12 p-md-8 p-lg-6">


						<div className="card card-w-title">
							<div className="login-panel-content">
								<div className="p-grid">
									<div className="p-col-12 p-md-2 p-lg-2" style={{ textAlign: 'right' }}>
										<img src="assets/layout/images/login/icon-login.svg" alt="avalon-react" />
									</div>
									<div className="p-col-12 p-md-10 p-lg-10" style={{ textAlign: 'right' }}>
										<h2 className="welcome-text">Welcome Guest</h2>
										<span className="guest-sign-in">Sign in to C3Leng</span>
									</div >
									<div className="p-col-12" style={{ textAlign: 'right' }} >
									<a href="/#">หน้าหลัก</a>
									</div>
									
									<div className="p-col-12" style={{ textAlign: 'left' }}>
										<label className="login-label">Username</label>
										<div className="login-input">
											<InputText
												placeholder="Username"
												name="username"
												value={username}
												onChange={this.handleChange.bind(this)}
												onKeyDown={(this._handleKeyDownEnter.bind(this))} />
										</div>
									</div>
									<div className="p-col-12" style={{ textAlign: 'left' ,marginTop:'20px'}}>
										<label className="login-label">Password</label>
										<div className="p-inputgroup" style={{height:'35px'}}>
											<InputText
												className="p-inputtext p-component p-filled p-col-12"
												type={this.state.typePasswordInput}
												placeholder="Password"
												name="password"
												value={password}
												onChange={this.handleChange.bind(this)}
												onKeyDown={(this._handleKeyDownEnter.bind(this))} />
												
												
											<Button
												style={{ height: '100%',width:'2em' }}
												icon={this.state.checkedPasswordVisible ? "fa fa-eye-slash" : "fa fa-eye"}
												className="p-col-12 p-button p-button-secondary"
												value={this.state.checkedPasswordVisible}
												onClick={this.setPasswordVisible}
											/>
												</div>
									</div>
									

									{authentication.isFailed &&
										<div className="p-col-12">
											<label style={{ color: 'red' }}>Username หรือ Password ไม่ถูกต้อง !!!</label>
										</div>
									}
								

									<div className="p-col-12" style={{ textAlign: 'right' }} >
										<a href="/#/forget-password">ลืมรหัสผ่าน</a>
									</div>

									<div className="p-col-12 p-lg-12 button-pane">
										{!authentication.isLoading &&
											<Button
												icon="fa fa-fw fa-sign-in"
												label="Sign In"
												style={{ width: '100%' }}
												onClick={this.handleSubmit.bind(this)}
											/>
										}
										{authentication.isLoading &&
											<ProgressSpinner style={{width: '50px', height: '50px'}} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s"/>}

									</div>
									{/* <div className="p-col-12 p-md-6 link-pane">
										<a href="/#">Forget Password?</a>
									</div> */}
									
									{/* <div className="p-col-5"  style={{borderWidth:'0.5px', borderTop: 'solid', color: '#acacac' ,marginTop:'16px',marginLeft:'19px'}}></div> */}
									<div className="p-col-2 p-offset-5" style={{ color: '#acacac' ,textAlign:'center'}}> หรือ</div>
									{/* <div className="p-col-5" style={{borderWidth:'0.5px', borderTop: 'solid', color: '#acacac' ,marginTop:'16px'}}></div> */}

									<div className="p-col-12 p-lg-12">
										<FacebookLogin
											icon="fa fa-fw fa-facebook"
											appId="2833348596989439"
											autoLoad={false}
											fields="name,email"
											textButton="เข้าสู่ระบบด้วย Facebook"
											cssClass="p-button p-button-info btn-block"
											style={{ float: 'left' }}
											callback={this.facebookResponse} />
										{TYPE_ROOT === 'prod' && <GoogleLogin
											clientId="314933320437-jklu9icuovfkhc1dhbaaar03sjvckvnc.apps.googleusercontent.com"
											buttonText="เข้าสู่ระบบด้วย Google account"
											render={renderProps => (
												<button type="button"
													class="p-button p-button-danger btn-block metro"
													onClick={renderProps.onClick}>
													<i class="fa fa fa-fw fa-google"></i>
													เข้าสู่ระบบด้วย Google account
														 </button>
											)}

											onSuccess={this.responseGoogle}
											onFailure={this.responseGoogle}
											cookiePolicy={'single_host_origin'}
										/>}
									</div>
								</div>
							</div>
						</div>

					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	authentication: state.authentication

})

const mapDispatchToProps = dispatch => ({

	loginProps: bindActionCreators(UserService, dispatch)

})


export default
	connect(
		mapStateToProps,
		mapDispatchToProps, null, { forwardRef: true })(Login)