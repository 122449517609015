import axios from 'axios'
import { API_ROOT } from '../../constants/api'



export const authenExternal = (model) =>{
  
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/authen/external`,
    data: model
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const wastegenRegisWastetran = ()  => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastegen/register/wastetran`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  })
    .then((res) => {
      return res
    }).catch((error) => {
      return error
    })
}

export const wastetranRegisWastegen = ()  => {
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastetran/register/wastegen`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
  })
    .then((res) => {
      return res
    }).catch((error) => {
      return error
    })
}