import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { closeWebsocket } from '../../utils/chat'
import classNames from 'classnames';
import { API_ROOT } from '../../constants/api';
import * as UserService from '../../service/user.service'
import { authentication } from '../../reducer/authen.reducer';
import { getFile } from '../../utils/utilFunctions'
import { WASTE_TYPE, WASTE_TYPE_NUM_TO_NAME } from '../../constants/mappingValue'
export class WastgenInlineProfile extends Component {

    constructor() {
        super();
        this.state = {
            isLoadAvatar: false,
            isLoading: true,
            expanded: true,
            fileImgRegis:null
        };
        this.onClick = this.onClick.bind(this);
        this.redirectProfile = this.redirectProfile.bind(this);
    }

    // ========= Initial : props to state =========
    initial = (object) => {
        if (object) {
            for (let key in object) {
                let val = object[key]
                this.setState({ [key]: val })
            }
            this.setState({ isLoading: false })
        }
    }

    onClick(event) {
        this.setState({ expanded: !this.state.expanded });
        event.preventDefault();
    }

    redirectProfile = (event) => {
        this.props.redirectProfile(event)
    }
    onRegisTranClick = (event) => {
        this.props.onRegisTranClick(event)
    }
    changePasswordU1 = (event) => {
        this.props.changePasswordU1(event)
    }
    changePinU1 = (event) => {
        this.props.changePinU1(event)
    }

    logout = () => {
        const { userServiceProps } = this.props
        userServiceProps.logout();
        closeWebsocket();
    }

    swapToWastetran = () => {
        localStorage.setItem('user-type', 'wastetran');
        window.location = '#/wastetran';
    }

    swapToAdmin = () => {

        var token=localStorage.getItem('second-token');

        localStorage.removeItem('second-token')

        localStorage.setItem('access-token', token);
        localStorage.setItem('user-type', 'administrator');

        if(this.state.fromChat){
            window.location = '#/administrator/chat';
        }else{
            window.location = '#/administrator/manage-members';
        }
        
    }

    render() {
        const {
            userInfo,
            fileServiceProps,
            getFileWastegen
        } = this.props
        var currentUserInfo = null
        if(userInfo){
            currentUserInfo = userInfo.is_available_register;
        }
        
        let fullName = "Loading...";
        let imageProfile = (
            <img alt="Profile" className="profile-image" src="assets/avatar/user.png" />
        );

        if (this.state.isLoading) {
            if (userInfo) {
                this.initial(userInfo);
                this.setState({ isLoadAvatar: true })
            }
            if(this.props.location.search == '?from=chat'){
                this.setState({ fromChat: true})
            }
        }

      
        if(userInfo){
            if(userInfo.person_type==1){
                if (userInfo.person_first_name && userInfo.person_last_name) {
                    fullName = userInfo.person_first_name + " " + userInfo.person_last_name
                }
            }else{
                fullName = userInfo.org_name
            }
        }

        if (this.props.userInfo) {
            if (!this.props.userInfo.img_person_name) {
                imageProfile = (
                    <img alt="Profile" className="profile-image" src="assets/avatar/user.png" />
                );
            } else {
                imageProfile = (
                    <img alt="Profile" className="profile-image image-cropper" src={`${API_ROOT}/wastegen/getfile/${this.props.userInfo.img_person_name}`} />

                );
            }
        }

        const contentProfile = (
            <div className={classNames('profile', { 'profile-expanded': this.state.expanded })}>
                <button className="p-link" onClick={this.onClick}>
                   {imageProfile}
                    <span className="profile-name">{fullName}</span>
                    <i className="fa fa-fw fa-caret-down"></i>
                    <span className="profile-role">ผู้รักษ์โลก</span>
                </button>
            </div>
        );

        if (!localStorage.getItem('user-type')) {
            window.location = "#/"
        }

        let secondToken = localStorage.getItem('second-token');
        
        return (<div>
            {contentProfile}

            <ul className="layout-menu profile-menu">

                
                {(secondToken)?
                    <li role="menuitem">
                        <button
                            className="p-link"
                            tabIndex={this.state.expanded ? '2' : '-1'}
                            onClick={(this.swapToAdmin.bind(true))}>
                            <i className="fa fa-fw fa-reply-all"></i>
                            <span>กลับหน้า Admin</span>
                        </button>
                        <div className="layout-menu-tooltip">
                            <div className="layout-menu-tooltip-arrow"></div>
                            <div className="layout-menu-tooltip-text">กลับหน้า Admin</div>
                        </div>
                    </li>
                    :''
                } 
                {(secondToken)?'':
                    <li role="menuitem">
                        <button
                            className="p-link"
                            tabIndex={this.state.expanded ? '2' : '-1'}
                            onClick={(this.logout.bind(true))}>
                            <i className="fa fa-fw fa-sign-out"></i>
                            <span>Logout</span>
                        </button>
                        <div className="layout-menu-tooltip">
                            <div className="layout-menu-tooltip-arrow"></div>
                            <div className="layout-menu-tooltip-text">Logout</div>
                        </div>
                    </li>
                } 

                <li role="menuitem">
                    <button className="p-link" tabIndex={this.state.expanded ? '3' : '-1'}
                        onClick={this.redirectProfile}>
                        <i className="fa fa-fw fa-user"></i>
                        <span>Profile</span>
                    </button>

                    <div className="layout-menu-tooltip">
                        <div className="layout-menu-tooltip-arrow"></div>
                        <div className="layout-menu-tooltip-text">Profile</div>
                    </div>
                </li>

                {currentUserInfo != null && !currentUserInfo && userInfo &&
                <li role="menuitem">
                    <button
                        className="p-link"
                        tabIndex={this.state.expanded ? '1' : '-1'}
                        onClick={(this.swapToWastetran.bind(this))}>
                        <i className="fa fa-fw fa-user-secret"></i>
                        <span>สลับเป็นทีมขนส่งซีซาเล้ง</span>
                    </button>
                    <div className="layout-menu-tooltip">
                        <div className="layout-menu-tooltip-arrow"></div>
                        <div className="layout-menu-tooltip-text">สลับเป็นทีมขนส่งซีซาเล้ง</div>
                    </div>
                </li>}

                {currentUserInfo != null && currentUserInfo && userInfo &&
                <li role="menuitem">
                    <button
                        className="p-link"
                        tabIndex={this.state.expanded ? '1' : '-1'}
                        onClick={this.onRegisTranClick}>
                        <i className="fa fa-fw fa-user-plus"></i>
                        <span>สมัครเป็นทีมขนส่งซีซาเล้ง</span>
                    </button>
                    <div className="layout-menu-tooltip">
                        <div className="layout-menu-tooltip-arrow"></div>
                        <div className="layout-menu-tooltip-text">สมัครเป็นผู้เก็บขยะรีไซเคิล</div>
                    </div>
                </li>}
                
                {(secondToken)?'':
                    <li role="menuitem">
                        <button className="p-link" tabIndex={this.state.expanded ? '4' : '-1'}
                            onClick={this.changePasswordU1}>
                            <i className="pi pi-key"></i>
                            <span>เปลี่ยนรหัสผ่าน</span>
                        </button>

                        <div className="layout-menu-tooltip">
                            <div className="layout-menu-tooltip-arrow"></div>
                            <div className="layout-menu-tooltip-text">เปลี่ยนรหัสผ่าน</div>
                        </div>
                    </li>
                }
                {(secondToken)?'':
                    <li role="menuitem">
                        <button className="p-link" tabIndex={this.state.expanded ? '5' : '-1'}
                            onClick={this.changePinU1}>
                            <i className="pi pi-key"></i>
                            <span>เปลี่ยนรหัสแจ้งถอนเงิน</span>
                        </button>

                        <div className="layout-menu-tooltip">
                            <div className="layout-menu-tooltip-arrow"></div>
                            <div className="layout-menu-tooltip-text">เปลี่ยนรหัสแจ้งถอนเงิน</div>
                        </div>
                    </li>
                }
            </ul>
        </div>)
    }
}


export default WastgenInlineProfile
