import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import classNames from 'classnames';
import {AppTopbar} from '../AppTopbar';
import {AppInlineProfile} from '../AppInlineProfile';
import {AppFooter} from '../AppFooter';
import {AppMenu} from '../AppMenu';
import { Route } from 'react-router-dom';
import {Dashboard} from '../demo/Dashboard';
import {FormsDemo} from '../demo/FormsDemo';
import {SampleDemo} from '../demo/SampleDemo';
import {DataDemo} from '../demo/DataDemo';
import {PanelsDemo} from '../demo/PanelsDemo';
import {OverlaysDemo} from '../demo/OverlaysDemo';
import {MenusDemo} from '../demo/MenusDemo';
import {MessagesDemo} from '../demo/MessagesDemo';
import {ChartsDemo} from '../demo/ChartsDemo';
import {MiscDemo} from '../demo/MiscDemo';
import {EmptyPage} from '../demo/EmptyPage';
import {Documentation} from '../demo/Documentation';
import {UtilsDemo} from '../demo/UtilsDemo';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import 'primereact/resources/primereact.min.css';
// import 'fullcalendar/dist/fullcalendar.css';
import 'font-awesome/css/font-awesome.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';

import { connect } from 'react-redux'
import * as LocationService from '../../service/LocationService'

import style from './style.css';
//import * as MasterServiceAPI from '../../service';
import * as ServerAPI from './service';
import {MapWithASearchBoxAndMarkers} from '../../features/map/MapWithASearchBoxAndMarkers'
// import { compareByFieldSpec } from 'fullcalendar';
import {CEInputText,CECalendar,CEDropdown} from '../../features/input';

class TestContainer extends Component {
    static propTypes = {

    }
  
    static defaultProps = {
      
    }
  
    componentDidMount() {
      const { locationServiceProps,provinces} = this.props
      locationServiceProps.getProvice()
      
    }
    componentWillReceiveProps(e) {     
      console.log("componentWillReceiveProps");
      console.log(e.provinces[1]);
      let prov = e.provinces[1];
      this.setState({provinceSelected: prov})
      
    }
    constructor(props) {
        super(props);
        this.state = {
            marker:{lat: 13.7734283, lng: 100.57383099999993},
            mapMarkers:[{lat: 13.7734283, lng: 100.57383099999993}],
            mapZoom:11,
            mapCenter: {lat: 13.7734283, lng: 100.57383099999993},
            provinces: props.provinces
        }
        this.onProvinceChanged = this.onProvinceChanged.bind(this)
    }
    onProvinceChanged(e) {
      console.log(e)
      this.setState({ provinceSelected: e.value, genAddressProvince: e.value.province_name });
      this.setState({ provinceCode: e.value.provinceCode });
    
  }

    
   
    render() {
        const {

          provinces,
           
          } = this.props

       //landing-wrapper
        let landingWrapper = classNames('landing-wrapper');
        let headerTop = classNames('header-top');
        let headerContent = classNames('header-content');
        let landingMenu = classNames('landing-menu');
        
        return (
           <div>
            <CEInputText                           
                            type="text"
                            className="input-100"
                            value={this.state.name}
                            name="name1"
                            placeholder="ชื่อ"
                            tooltip="กรอกเป็นภาษาไทยหรือภาษาอังกฤษ"
                            required={true}
                            onChange={(e)=> this.setState({name: e.target.value})}
                            isvalid={(valid) => console.log(valid)}
                             />

              <CEInputText                           
                            type="number"
                            className="input-100"
                            value={this.state.number}
                            name="number1"
                            placeholder="ตัวเลข"
                            tooltip="กรอกเป็นตัวเลขเท่านั้น"
                            required={true}
                            onChange={(e)=> this.setState({number: e.target.value})}
                            isvalid={(valid) => console.log(valid)}
                             />

               <CEInputText                           
                            type="number"
                            className="input-100"
                            value={this.state.number2}
                            name="number2"
                            placeholder="ตัวเลข 5-10 ตัว"
                            tooltip="กรอกเป็นตัวเลข 5-10 ตัวเท่านั้น"
                            minlength={5}
                            maxlength={10}
                            required={true}
                            onChange={(e)=> this.setState({number2: e.target.value})}
                            isvalid={(valid) => console.log(valid)}
                             />

                    <CEInputText                           
                            type="number"
                            className="input-100"
                            value={this.state.number2}
                            name="number2"
                            placeholder="ตัวเลข 0-3"
                            tooltip="กรอกเป็นตัวเลข 0-3 ตัวเท่านั้น"
                            minvalue={0}
                            maxvalue={3}
                            required={true}
                            onChange={(e)=> this.setState({number2: e.target.value})}
                            isvalid={(valid) => console.log(valid)}
                             />
                  
                        <CECalendar dateFormat="dd/mm/yy" hourFormat="24" showIcon={true} showTime={true}
                                    value={this.state.date1} 
                                    onChange={(e) => this.setState({date1:e.value})} required={true} 
                                    isvalid={(valid) => console.log("calendar:"+ valid)} ></CECalendar>

                        <CEDropdown
                                            required={true} isvalid={(valid) => console.log("Dropdown:"+ valid)}
                                            style={{ width: "100%" }}
                                            value={this.state.provinceSelected}
                                            // options={this.state.provinceSelectItems} 
                                            options={provinces}
                                            onChange={this.onProvinceChanged} 
                                            optionLabel="province_name" 
                                            placeholder="เลือกจังหวัด" />
          </div>
        );
  }
}



const mapStateToProps = (state) => ({
    provinces: state.location.province,
    district: state.location,
    subDistrict: state.location.subDistrict
  })
  
const mapDispatchToProps = dispatch => ({
   
    locationServiceProps: bindActionCreators(LocationService, dispatch)
    
  })
  

export default 
  connect(
    mapStateToProps,
    mapDispatchToProps, null, {forwardRef: true})(TestContainer)

