import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import classNames from 'classnames';
import { AppTopbar } from '../AppTopbar';
import { AppFooter } from '../AppFooter';
import { AppMenu } from '../AppMenu';
import { Route } from 'react-router-dom';
import DashboardAdminContainer from './DashboardAdminContainer';
import ReportApplicationWaseGenContainer from './ReportApplicationWaseGenContainer';
import ReportApplicationWaseTranContainer from './ReportApplicationWaseTranContainer';
import ReportApplicationWaseProContainer from './ReportApplicationWaseProContainer';
import { AdministratorInlineProfile } from './AdministratorInlineProfile';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import 'primereact/resources/primereact.min.css';
import 'font-awesome/css/font-awesome.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';


import { connect } from 'react-redux'

import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import { get } from 'https';
import CreateOrderContainer from './CreateOrderContainer';
import ManageOrderContainer from './ManageOrderContainer';
import ManageInvoiceContainer from './ManageInvoiceContainer';
import ManageMoneyTransferContainer from './ManageMoneyTransferContainer';
import ReportInvoice from './ReportInvoice';
import ExampleAdminReportWithdraw from './ExampleAdminReportWithdraw';
import ReportMoneyTransferContainter from './ReportMoneyTransferContainter';
import ReportOrder from './ReportOrder';
import ReportWithdraw from './ReportWithdraw';
import ManageProductPrice from './ManageProductPrice';
import ManageProductFee from './ManageProductFee';
import ManageDeliveryCost from './ManageDeliveryCost';
import ManageDeliveryDistance from './ManageDeliveryDistance';
import ManageDeliveryWeight from './ManageDeliveryWeight';
import ManageFeeWeight from './ManageFeeWeight';
import ManageAdminMember from './ManageAdminMember';
import ManageLevelAdmin from './ManageLevelAdmin';
import ManageMemberContainer from './ManageMemberContainer';
import ManageCertificate from './ManageCertificate';
import ManageCertificateResult from './ManageCertificateResult';
import ManageSettingParameter from './ManageSettingParameter';
import ChatAdministrator from './ChatAdministrator';
import ManageFAQ from './ManageFAQ';
import ManageNews from './ManageNews';
import ManageSettingReward from './ManageSettingReward';
import ManageEnvironmentalCorporateAccount from './ManageEnvironmentalCorporateAccount';
import ManageProduct from './ManageProduct';
import ManageProductType from './ManageProductType';
import ManageDonateHistory from './ManageDonateHistory';

import {
    connectWebsocket,
    implementWebsocket,
    closeWebsocket,
    navigateToAdminChatScene,
    navigateToUserChatScene,
    sendMessage
  } from '../../utils/chat'
import { addNewChatMessage } from '../../service/ChatService'
import * as AdminService from '../../service/admin.service'
import moment from 'moment'
import { WASTE_TYPE, WASTE_TYPE_NUM_TO_NAME } from '../../constants/mappingValue'
import { getBadgeAdmin } from './service'
import ReportRating from './ReportRating';
import ReportWasteByLocation from './ReportWasteByLocation';
class AdministratorContainer extends Component {
    static propTypes = {

    }

    static defaultProps = {
    }

    componentDidMount() {
        const { locationServiceProps, userServiceProps, AdminServiceProps } = this.props
        locationServiceProps.getProvice();
        userServiceProps.getUserInfo();
        const body = {
            //per_page: 20,
            time_stamp: moment()
              .add(1, 'days')
              .format('YYYY-MM-DDTHH:mm:ss'),
          }
        AdminServiceProps.getUsersAllForManagement(body);
    }
    componentWillReceiveProps(nextProps) {
        //console.log(nextProps)
        if(nextProps.userInfo.data && !nextProps.userInfo.isFetching && this.tickerLoad){
            this.tickerLoad = false;
            getBadgeAdmin().then((res)=>{
                this.createMenu(nextProps.userInfo.data,res);
                this.setState({badge: res})
            })
            if(nextProps.userInfo.data){
                //console.log(nextProps)
                this.manageChatWebsocket(nextProps.userInfo.data,'administrator')
            }else {
                closeWebsocket()
            }
            
            
        }
        if((nextProps !== this.props) && this.props.userList.length > 0){
            console.log(this.props)
            getBadgeAdmin().then((res)=>{
                this.createMenu(nextProps.userInfo.data,res);
                this.setState({badge: res})
            })
            this.setState({userList: this.props.userList})
        }
    }
    constructor() {
        super();
        this.tickerLoad = true;
        this.state = {
            layoutMode: 'static',
            profileMode: 'inline',
            layoutCompact: true,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            rotateMenuButton: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkMenu: false,
            menuActive: false,
            theme: 'green',
            layout: 'green',
            version: 'v3',
            badge: 0,
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.createMenu();

    }
    manageChatWebsocket = (userInfo, userWasteType) => {
        const currentUserInfo = userInfo.user_wasteTypes.find(
          item => item.wasteType === WASTE_TYPE[userWasteType],
        )
        const senderId = currentUserInfo.uid
        const senderType = currentUserInfo.wasteType
        const sendReadMessage = messageId => {
            const messageSending = {
              message_id: messageId,
              message_type: 4,
            }
            sendMessage(JSON.stringify(messageSending))
            console.log('send read')
          }
        const onMessage = (message, currentRouteName) => {
            // if (!message.iam) {
            //     sendReadMessage(message.message_id)
            //   }
            addNewChatMessage(message)
            getBadgeAdmin().then((res)=>{
                this.createMenu(userInfo,res);
                this.setState({badge: res})
            })
            this.props.AdminServiceProps.updateUsersAll(this.props.userList, message.room_id, 1, message)
        //   if (currentRouteName === 'ChatScene') {
        //     addNewChatMessage(message)
        //   } else {
        //       console.log('fadeInMessageBanner(message)')
        //       //fadeInMessageBanner(message)
        //   }
        }
        connectWebsocket(senderId, senderType)
        implementWebsocket(onMessage)
    }

    onMenuClick(event) {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            rotateMenuButton: !this.state.rotateMenuButton,
            topbarMenuActive: false
        }));

        if (this.state.layoutMode === 'overlay') {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        }
        else {
            if (this.isDesktop())
                this.setState({ staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive });
            else
                this.setState({ staticMenuMobileActive: !this.state.staticMenuMobileActive });
        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({ activeTopbarItem: null });
        else
            this.setState({ activeTopbarItem: event.item });

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
        if (!event.item.items && this.isHorizontal()) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    onDocumentClick(event) {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

        if (!this.rightPanelClick) {
            this.setState({
                rightPanelActive: false
            })
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            rotateMenuButton: false,
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    changeTheme(theme) {
        this.setState({ theme: theme });
        if (this.state.version === 'v3') {
            this.changeStyleSheetUrl('theme-css', theme, 'theme');
        } else {
            this.changeStyleSheetUrl('theme-css', theme + '-v4', 'theme');
        }
    }

    changeLayout(layout, special) {
        this.setState({ layout: layout });
        if (this.state.version === 'v3') {
            this.changeStyleSheetUrl('layout-css', layout, 'layout');
        } else {
            this.changeStyleSheetUrl('layout-css', layout + '-v4', 'layout');
        }

        if (special) {
            this.setState({
                darkMenu: true
            })
        }
    }

    changeVersion(version) {
        this.setState({ version: version });
        if (version === 'v3') {
            this.changeStyleSheetUrl('layout-css', this.state.layout, 'layout');
            this.changeStyleSheetUrl('theme-css', this.state.theme, 'theme');
        } else {
            this.changeStyleSheetUrl('layout-css', this.state.layout + '-v4', 'layout');
            this.changeStyleSheetUrl('theme-css', this.state.theme + '-v4', 'theme');
        }
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');
        element.setAttribute('href', newURL);
    }

    createMenu(userInfo,badges) {
        if (userInfo) {
        this.menu = [
            {label: 'Dashboard', icon: 'fa fa-fw fa-home', to: '/administrator' },
            {label: 'Chat', icon: 'fa fa-fw fa-comments', to: '/administrator/chat' , badge: badges || null },

            {label: 'สร้าง Order', icon: 'fa fa-fw fa-gg', to: '/administrator/new-order' },
            {label: 'จัดการ Order', icon: 'fa fa-fw fa-gg', to: '/administrator/manage-orders' },
            {label: 'จัดการ Invoice', icon: 'fa fa-fw fa-gg', to:'/administrator/manage-invoice'},
            {label: 'จัดการรายการโอนเงิน', icon: 'fa fa-fw fa-gg', to:'/administrator/manage-withdrawal'},
            {label: 'จัดการรายการบริจาค', icon: 'fa fa-fw fa-gg', to:'/administrator/manage-donate-history'},
            {label: 'จัดการราคารับซื้อ', icon: 'fa fa-fw fa-gg', to:'/administrator/manage-product-price'},
            
            {
                label: 'จัดการค่าบริการ', icon: 'fa fa-fw fa-gg',
                items: [
                    { label: 'ตั้งค่าน้ำหนักค่าบริการ', icon: 'fa fa-fw fa-cog', to: '/administrator/manage-fee-weight' },
                    { label: 'จัดการค่าบริการ', icon: 'fa fa-fw fa-chevron-right', to: '/administrator/manage-product-fee' },
                ]

            },
            {
                label: 'จัดการค่าขนส่ง', icon: 'fa fa-fw fa-gg',
                items: [
                    { label: 'ตั้งค่าระยะทางขนส่ง', icon: 'fa fa-fw fa-cog', to: '/administrator/manage-delivery-distance' },
                    { label: 'ตั้งค่าน้ำหนักขนส่ง', icon: 'fa fa-fw fa-cog', to: '/administrator/manage-delivery-weight' },
                    { label: 'จัดการค่าขนส่ง', icon: 'fa fa-fw fa-chevron-right', to: '/administrator/manage-delivery-cost' },
                ]

            },
            {
                label: 'จัดการผู้ดูแลระบบ', icon: 'fa fa-fw fa-gg',
                items: [
                    { label: 'ตั้งค่าระดับผู้ดูแลระบบ', icon: 'fa fa-cog', to: '/administrator/manage-level-admin' },
                    { label: 'ตั้งค่าความสามารถ', icon: 'fa fa-chevron-right', to: '/administrator/manage-admin-member' },
                ]
            },
            { label: 'จัดการข้อมูลสมาชิก', icon: 'fa fa-fw fa-gg', to: '/administrator/manage-members' },
            // {label: 'ตั้งค่าใบรับรอง/เหรียญ', icon: 'fa fa-cog', to:'/administrator/manage-certificate'},
            {label: 'ตั้งค่าคำถามที่พบบ่อย (FAQ)', icon: 'fa fa-cog', to:'/administrator/manage-faq'},
            {label: 'จัดการข่าว (สำหรับแอพฯ)', icon: 'fa fa-fw fa-cog', to:'/administrator/manage-news'},
            {
                label: 'รายงาน', icon: 'fa fa-fw fa-credit-card',
                items: [
                    { label: 'รายงานตรวจสอบ Order', icon: 'fa fa-fw fa-bars', to: '/administrator/report-order' },
                    { label: 'รายงานการโอนเงิน', icon: 'fa fa-fw fa-bars', to: '/administrator/report-withdrawal' },
                    { label: 'รายงานแจ้งหนี้/Invoice', icon: 'fa fa-fw fa-bars', to: '/administrator/report-invoice' },
                    { label: 'รายงานการให้ดาว', icon: 'fa fa-fw fa-bars', to: '/administrator/report-rating' },
                    { label: 'รายงานสมาชิกตาม Location', icon: 'fa fa-fw fa-bars', to: '/administrator/report-waste-by-location' },
                ]
            },
            {
                label: 'รายชื่อผู้สมัครใช้งาน', icon: 'fa fa-fw fa-user',
                items: [
                    { label: 'ผู้รักษ์โลก', icon: 'fa fa-fw fa-bars', to: '/administrator/report-wastegen' },
                    { label: 'ทีมขนส่งซีซาเล้ง', icon: 'fa fa-fw fa-bars', to: '/administrator/report-wastetran' },
                    { label: 'ศูนย์จัดการฯซีซาเล้ง', icon: 'fa fa-fw fa-bars', to: '/administrator/report-wastepro' },
                    
                ]

            },
            {label: 'ตั้งค่าการจัดการความดี', icon: 'fa fa-fw fa-cog', to:'/administrator/manage-setting-reward'},

            {
                label: 'ตั้งค่าใบรับรอง/เหรียญ', icon: 'fa fa-fw fa-cog',
                items: [
                    { label: 'ตั้งค่าใบรับรอง/เหรียญ', icon: 'fa fa-fw fa-certificate', to: '/administrator/manage-certificate' },
                    { label: 'สรุปใบรับรอง/เหรียญ', icon: 'pi pi-list', to: '/administrator/manage-certificate-result' }
                ]

            },

            {label: 'ตั้งค่าบัญชีองค์กรสิ่งแวดล้อม', icon: 'fa fa-fw fa-cog', to:'/administrator/manage-environmental-account'},
            {
                label: 'ตั้งค่าข้อมูลหลัก', icon: 'fa fa-fw fa-cog',
                items: [
                    { label: 'ตั้งค่าพารามิเตอร์', icon: 'pi pi-key', to: '/administrator/manage-setting-parameter' },
                    { label: 'ตั้งค่าประเภทสินค้า', icon: 'pi pi-list', to: '/administrator/manage-setting-producttype' },
                    { label: 'ตั้งค่าสินค้า', icon: 'pi pi-list', to: '/administrator/manage-setting-product' }
                ]

            },
            

        ];

        let permission = userInfo.permission
            if (!permission.manage_order) {
                this.menu = this.hideMenuAdmin(this.menu,'จัดการ Order')
            }
            if (!permission.manage_invoice) {
                this.menu = this.hideMenuAdmin(this.menu,'จัดการ Invoice')
            }
            if (!permission.manage_transfer) {
                this.menu = this.hideMenuAdmin(this.menu,'จัดการรายการโอนเงิน')
            }
            if (!permission.manage_sale_price) {
                this.menu = this.hideMenuAdmin(this.menu,'จัดการราคารับซื้อ')
            }
            if (!permission.manage_process_fee) {
                this.menu = this.hideMenuAdmin(this.menu,'จัดการค่าบริการ')
            }
            if (!permission.manage_delivery_fee) {
                this.menu = this.hideMenuAdmin(this.menu,'จัดการค่าขนส่ง')
            }
            if (!permission.manage_administrator) {
                this.menu = this.hideMenuAdmin(this.menu,'จัดการผู้ดูแลระบบ')
            }
            if (!permission.view_report) {
                this.menu = this.hideMenuAdmin(this.menu,'รายงาน')
            }
            if (!permission.manage_password) {
                this.menu = this.hideMenuAdmin(this.menu,'รีเซ็ตรหัสผ่าน')
            }
            if (!permission.view_member) {
                this.menu = this.hideMenuAdmin(this.menu,'ดูหน้าจอสมาชิก')
            }
            if (!permission.manage_aproval) {
                this.menu = this.hideMenuAdmin(this.menu,'การอนุมัติสมาชิก')
            }
            if (!permission.chat) {
                this.menu = this.hideMenuAdmin(this.menu,'Chat')
            }
            if (!permission.create_order) {
                this.menu = this.hideMenuAdmin(this.menu,'สร้าง Order')
            }
            if (!permission.manage_certificate) {
                this.menu = this.hideMenuAdmin(this.menu,'ตั้งค่าใบรับรอง/เหรียญ')
            }
            if (!permission.manage_env_account) {
                this.menu = this.hideMenuAdmin(this.menu,'ตั้งค่าบัญชีองค์กรสิ่งแวดล้อม')
            }
            if (!permission.manage_faq) {
                this.menu = this.hideMenuAdmin(this.menu,'ตั้งค่าคำถามที่พบบ่อย (FAQ)')
            }
            if (!permission.manage_master) {
                this.menu = this.hideMenuAdmin(this.menu,'ตั้งค่าข้อมูลหลัก')
            }
            if (!permission.manage_member) {
                this.menu = this.hideMenuAdmin(this.menu,'จัดการข้อมูลสมาชิก')
            }
            if (!permission.manage_news) {
                this.menu = this.hideMenuAdmin(this.menu,'จัดการข่าว (สำหรับแอพฯ)')
            }
            if (!permission.manage_reward) {
                this.menu = this.hideMenuAdmin(this.menu,'ตั้งค่าการจัดการความดี')
            }
            if (!permission.view_donate) {
                this.menu = this.hideMenuAdmin(this.menu,'จัดการรายการบริจาค')
            }
            if (!permission.view_user_list) {
                this.menu = this.hideMenuAdmin(this.menu,'รายชื่อผู้สมัครใช้งาน')
            }
            
        } else {
            this.menu = [];
        }
    }

    hideMenuAdmin(list,label){
        for (let key in list) {
            let value = list[key]
            if(value.label == label){
                list.splice(key, 1);
            }
        }
        return list;
    }



    render() {

        const {

            province,
            userServiceProps,
            userInfo
        } = this.props
        let layoutClassName = classNames('layout-wrapper', {
            'menu-layout-static': this.state.layoutMode !== 'overlay',
            'menu-layout-overlay': this.state.layoutMode === 'overlay',
            'layout-menu-overlay-active': this.state.overlayMenuActive,
            'menu-layout-slim': this.state.layoutMode === 'slim',
            'menu-layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-menu-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-menu-static-active': this.state.staticMenuMobileActive
        });

        let menuClassName = classNames('layout-menu-container', { 'layout-menu-dark': this.state.darkMenu });

        return (
            <div className={layoutClassName} onClick={this.onDocumentClick}>
                <div>
                    <AppTopbar
                        profileMode={this.state.profileMode} horizontal={this.isHorizontal()}
                        topbarMenuActive={this.state.topbarMenuActive}
                        activeTopbarItem={this.state.activeTopbarItem}
                        onMenuButtonClick={this.onMenuButtonClick}
                        onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                        onTopbarItemClick={this.onTopbarItemClick} />

                    <div className={menuClassName} onClick={this.onMenuClick}>
                        <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{ height: '100%',width:'250px' }}>
                            <div>
                                {(this.state.profileMode === 'inline' && this.state.layoutMode !== 'horizontal') && <AdministratorInlineProfile userServiceProps={userServiceProps} userInfo={userInfo.userInfo} />}
                                <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} onRootMenuItemClick={this.onRootMenuItemClick}
                                    layoutMode={this.state.layoutMode} active={this.state.menuActive} />
                            </div>
                        </ScrollPanel>
                    </div>

                    <div className="layout-main">

                        <Route path="/administrator" exact component={DashboardAdminContainer} />
                        <Route path="/administrator/chat" exact component={ChatAdministrator} {...this.props} />
                        <Route path="/administrator/report-wastegen" component={ReportApplicationWaseGenContainer} />
                        <Route path="/administrator/report-wastetran" component={ReportApplicationWaseTranContainer} />
                        <Route path="/administrator/report-wastepro" component={ReportApplicationWaseProContainer} />
                        <Route path="/administrator/new-order" component={CreateOrderContainer} />
                        <Route path="/administrator/manage-orders" component={ManageOrderContainer} />
                        <Route path="/administrator/manage-invoice" component={ManageInvoiceContainer} />
                        <Route path="/administrator/report-rating" component={ReportRating} />
                        <Route path="/administrator/report-waste-by-location" component={ReportWasteByLocation} />
                        <Route path="/administrator/manage-withdrawal" component={ManageMoneyTransferContainer} />
                        {/* <Route path="/administrator/reports-withdraw" component={ManageMoneyTransfer} /> */}
                        <Route path="/administrator/reports-withdraw" component={ExampleAdminReportWithdraw} />
                        <Route path="/administrator/report-invoice" component={ReportInvoice} />
                        <Route path="/administrator/report-withdrawal" component={ReportMoneyTransferContainter} />
                        <Route path="/administrator/report-order" component={ReportOrder} />
                        <Route path="/administrator/admin-report-withdraw" component={ReportWithdraw} />
                        <Route path="/administrator/manage-product-price" component={ManageProductPrice} />
                        <Route path="/administrator/manage-product-fee" component={ManageProductFee} />
                        <Route path="/administrator/manage-fee-weight" component={ManageFeeWeight} />
                        <Route path="/administrator/manage-delivery-cost" component={ManageDeliveryCost} />
                        <Route path="/administrator/manage-delivery-distance" component={ManageDeliveryDistance} />
                        <Route path="/administrator/manage-delivery-weight" component={ManageDeliveryWeight} />
                        <Route path="/administrator/manage-level-admin" component={ManageLevelAdmin} {...this.props}/>
                        <Route path="/administrator/manage-admin-member" component={ManageAdminMember} />
                        <Route path="/administrator/manage-members" component={ManageMemberContainer} {...this.props}/>
                        <Route path="/administrator/manage-certificate" component={ManageCertificate} />
                        <Route path="/administrator/manage-certificate-result" component={ManageCertificateResult} />
                        <Route path="/administrator/manage-setting-parameter" component={ManageSettingParameter} />
                        <Route path="/administrator/manage-faq" component={ManageFAQ} />
                        <Route path="/administrator/manage-news" component={ManageNews} />
                        <Route path="/administrator/manage-setting-reward" component={ManageSettingReward} />
                        <Route path="/administrator/manage-environmental-account" component={ManageEnvironmentalCorporateAccount} />
                        <Route path="/administrator/manage-setting-product" component={ManageProduct} />
                        <Route path="/administrator/manage-setting-producttype" component={ManageProductType} />
                        <Route path="/administrator/manage-donate-history" component={ManageDonateHistory} />
                        
                        
                        
                    </div>


                </div>
            </div>
        );
    }
}



const mapStateToProps = (state) => ({
    province: state.location.province,
    district: state.location,
    subDistrict: state.location.subDistrict,
    authentication: state.authentication,
    userInfo: state.userInfo,
    userList: state.getUsersAll.data,
})

const mapDispatchToProps = dispatch => ({

    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    AdminServiceProps: bindActionCreators(AdminService, dispatch)
})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(AdministratorContainer)

