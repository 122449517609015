import React, { Component } from 'react';


export const WasteproOverviews = ({ outstanding_balance_thb,payment_amount_thb,total_payment_amount_thb}) => {

    return (
        <div className="p-grid ">
            <div className="p-col-12 p-md-6 p-lg-4">
                <div className="p-grid overview-box overview-box-1">
                    <div className="overview-box-title">
                        <i className="fa fa-inbox"></i>
                        <span>ยอดเงินค้างชำระ</span>
                    </div>
                    <div className="overview-box-count">{outstanding_balance_thb}</div>
                    <div className="overview-box-stats">บาท</div>
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-4">
                <div className="p-grid overview-box overview-box-2">
                    <div className="overview-box-title">
                        <i className="fa fa-inbox"></i>
                        <span>ยอดเงินชำระแล้ว</span>
                    </div>
                    <div className="overview-box-count">{payment_amount_thb}</div>
                    <div className="overview-box-stats">บาท</div>
                </div>
            </div>
            <div className="p-col-12 p-md-6 p-lg-4">
                <div className="p-grid overview-box overview-box-3">
                    <div className="overview-box-title">
                        <i className="fa fa-folder"></i>
                        <span>ยอดเงินทั้งหมด</span>
                    </div>
                    <div className="overview-box-count">{total_payment_amount_thb}</div>
                    <div className="overview-box-stats">บาท</div>
                </div>
            </div>
        </div>
    )

}