import {
    ORDER_SEARCH,
    ORDER_AUTO_COMP
} from '../service/actionType'

const initialState = {
    data: null,
    isFailed: false,
    isLoading: false
}

export function orderSearch(state = initialState, action) {
    switch (action.type) {
        case ORDER_SEARCH.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case ORDER_SEARCH.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case ORDER_SEARCH.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function orderAutoComplete(state = initialState, action) {
    switch (action.type) {
        case ORDER_AUTO_COMP.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case ORDER_AUTO_COMP.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case ORDER_AUTO_COMP.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}