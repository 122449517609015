

import React, { Component } from 'react';
import { connect } from 'react-redux';

import {InputText} from 'primereact/inputtext';
import {RadioButton} from 'primereact/radiobutton';


class DrivenInfoView extends Component {

    state = {

    }

    //>Initial 
    componentDidMount() {
        //console.log("DrivenInfoView :componentDidMount")
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        //console.log("DrivenInfoView :componentWillReceiveProps")
    }

    shouldComponentUpdate(nextProps, nextState) {
        //console.log("DrivenInfoView :shouldComponentUpdate")
        return true
    }

    componentWillUpdate(nextProps, nextState) {
        //console.log("DrivenInfoView :componentWillUpdate")

    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("DrivenInfoView :componentDidUpdate")

    }

    //> Event
    onChangeInputText = (e) => {
        this.props.onChangeInputText(e);
    }

    //> Mode render
    renderWastegen = () => {
        return (
            <div></div>
        )
    }
    renderWastetran = () => {
        return (
            <div></div>
        )
    }
    renderWastepro = () => {
        return (
            <div></div>
        )
    }

    render() {
        //console.log("DrivenInfoView :render")
        const {
            mode
        } = this.props

        let renderMode;

        switch (mode) {
            case 'wastegen':
                renderMode = this.renderWastegen()
                break;
            case 'wastetran':
                renderMode = this.renderWastetran()
                break;
            case 'wastepro':
                renderMode = this.renderWastepro()
                break;

            default:
                renderMode = (<div></div>)
                break;
        }

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <InputText
                        maxLength={100}
                        className="input-100"
                        placeholder="ทะเบียนรถ"
                        name="vehicle_no"
                        value={this.props.vehicle_no}
                        onChange={this.onChangeInputText}>
                    </InputText>
                </div>

                <div className="p-col-12 required">
                    <label>
                        ประเภทรถ
                </label>
                </div>

                <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <RadioButton
                        inputId="cb1"
                        value="1"
                        name="vehicle_type"
                        onChange={this.onChangeInputText}
                        checked={this.props.vehicle_type == '1'} />
                    <label htmlFor="cb1" className="p-checkbox-label">มอเตอร์ไซค์</label>
                </div>
                <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <RadioButton
                        name="vehicle_type"
                        inputId="cb2"
                        value="2"
                        onChange={this.onChangeInputText}
                        checked={this.props.vehicle_type == '2'}></RadioButton>
                    <label htmlFor="cb2" className="p-checkbox-label">ซาเล้ง</label>
                </div>
                <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <RadioButton
                        name="vehicle_type"
                        inputId="cb3"
                        value="3"
                        onChange={this.onChangeInputText}
                        checked={this.props.vehicle_type == '3'}></RadioButton>
                    <label htmlFor="cb3" className="p-checkbox-label">รถกระบะและบรรทุก 4 ล้อ</label>
                </div>
                <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <RadioButton
                        name="vehicle_type"
                        inputId="cb4"
                        value="4"
                        onChange={this.onChangeInputText}
                        checked={this.props.vehicle_type == '4'}></RadioButton>
                    <label htmlFor="cb4" className="p-checkbox-label">รถบรรทุก 6 ล้อ</label>
                </div>
                <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <RadioButton
                        name="vehicle_type"
                        inputId="cb5"
                        value="5"
                        onChange={this.onChangeInputText}
                        checked={this.props.vehicle_type == '5'}></RadioButton>
                    <label htmlFor="cb5" className="p-checkbox-label">รถบรรทุก 10 ล้อ</label>
                </div>
                <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                    <RadioButton
                        name="vehicle_type"
                        inputId="cb6"
                        value="6"
                        onChange={this.onChangeInputText}
                        checked={this.props.vehicle_type == '6'}></RadioButton>
                    <label htmlFor="cb6" className="p-checkbox-label">รถพ่วง</label>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(DrivenInfoView)
