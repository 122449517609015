import React, { Component } from 'react';
import { FileUpload } from 'primereact/fileupload';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/components/column/Column';
import { Button } from 'primereact/button';
import classNames from 'classnames';
import { API_ROOT } from '../../constants/api';

class CEFileUploadWithImg extends Component {
    static defaultProps = {

        required:false,
        value: [],
         id:'',  
         className: null, 
         style: null,
         mode: 'basic',
         chooseLabel: 'เลือกไฟล์',
         cancelLabel: 'ยกเลิก',
         multiple:true,
         accept:"image/*",
         maxFileSize:10000000,
         onSelect: () => { },
         onDelete: () => { },
         onSelectCover: () => { }
        
    }

    constructor(props) {
        super(props)
        this.state = { 
            value: [],
            selectedCover: null,
        }

        this.changed = this.changed.bind(this);
        this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
        this.showCtrl = this.showCtrl.bind(this);
        //regexp = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
        
    }
    componentDidMount() {
        if(this.props.value)
        this.setState({value: this.props.value})
    }
    componentWillReceiveProps(e) {  
      
    
    }
   
    changed = (e) =>{
        console.log("CEFileUploadWithImg->changed");
        console.log(e);
           
        this.props.onSelect(e);
        
    }
    itemTemplate(item, layout) {
        console.log("CEFileUploadWithImg->itemTemplate");
        console.log(item)
        if (layout === 'list') {
            return (
                <div className="p-grid">
                    <div>{item.name}</div>
                </div>
            );
        }
       
    }
    onClickDeleteItem(index){
        this.props.onDelete(index);
    }
    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" onClick={() => this.onClickDeleteItem(column.rowIndex)} icon="fa fa-trash" className="p-button-warning" ></Button>
        </div>;
    }
    brandTemplate(rowData, column) {
        // console.log(rowData)
        if(rowData.imageName){
            var src = `${API_ROOT}/user/news/getfile/${rowData.imageName}`;
        }else{
            var src = rowData.objectURL;
        }
        
        return <img src={src} width="48px" />;
    }
    onSelectedCover(e){
        if(e.value != null){
            this.setState({selectedCover: e.value})
            this.props.onSelectCover(e);
        }
    }
    showCtrl(){
        
      const {value, onSelect,id,  className, style,onChange,mode,chooseLabel,cancelLabel,multiple,accept,maxFileSize,masterImageIndex} = this.props;
     
      var selectedTable = this.state.selectedCover;
      if(value.length != 0)
      value.forEach((obj,index) => {
          if(index == masterImageIndex){
            selectedTable = obj;
          }
      });

      return  <div>
                <FileUpload name="wastes[]"
                    onSelect={this.changed}
                    mode={mode}
                    chooseLabel={chooseLabel}
                    cancelLabel={cancelLabel}
                    multiple={multiple}
                    accept={accept}
                    maxFileSize={maxFileSize} 
                    auto={true}/>
                    
               { value && 
               <DataTable value={value} selection={selectedTable} 
                // onSelectionChange={e => this.setState({selectedCover: e.value})} 
                onSelectionChange={e => this.onSelectedCover(e)}
                emptyMessage={(
                    <div style={{ textAlign: 'center', color: 'red' }}>
                    <label>ไม่พบไฟล์</label></div>
                   )}
               >
                    <Column selectionMode="single" header="หน้าปก" style={{textAlign:'center',width:'5em'}}/>
                    <Column field="brand" header="รูป" body={this.brandTemplate} style={{textAlign:'center',width:'6em'}}/>
                    <Column field="name" header="ชื่อไฟล์" />
                    <Column body={this.actionTemplate.bind(this)} header="ลบ" style={{textAlign:'center',width:'5em'}} />
                </DataTable>
                }
             </div>

    }
    render(){
        const bodyContent = this.showCtrl();
      
        return (
            <div>
                {/* <div className="p-col-12 p-md-6 p-lg-6"> */}
                    {bodyContent}
                {/* </div> */}
                {/* <div className="p-col-12 p-md-6 p-lg-6">
                    {this.state.selectedCover ? <h4>หน้าปกข่าว</h4> : null}
                    {this.state.selectedCover ? <img width='100%' src={this.state.selectedCover.objectURL}/> : null}
                </div> */}
            </div>
            
        )
    }
}

export default CEFileUploadWithImg