import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';

import { InputText } from 'primereact/components/inputtext/InputText';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { MapMarkers } from '../../features/map/MapMarkers'
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { Avatar } from '../../features/widget/Avatar';
import { API_ROOT } from '../../constants/api'
import { ProgressSpinner } from 'primereact/progressspinner';
import * as ServerAPI from './service';
import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'
import * as DashBoardService from './service'
import { isEmpty } from '../../utils/validators';
import { CEInputText, CECalendar, CEDropdown } from '../../features/input';
import { CEFileUpload } from '../../features/widget';
import {Dialog} from 'primereact/dialog';
import {CEFileUploadWithImg} from '../../features/widget';
import {Galleria} from 'primereact/galleria';
import { RadioButton } from 'primereact/radiobutton';

class ManageProduct extends Component {
    static propTypes = {

    }
    constructor() {
        super();
        this.state = {
            addressDetail: '',
            productList: [],
            memberStore: 0,
            brandSuggestions: null,
            wasteTypeSelected: '',
            searchProductType: '',
            searchProduct: '',
            productSelected: '',
            priceSelected1: '',
            memberName1:null,
            searchProductTypeCode:0,

            memberStore1: [
                { label: 'เลือกประเภทสินค้า', value: 0 },
                { label: 'กระดาษ', value: 1 },
                { label: 'พลาสติก', value: 2 },
                { label: 'ประเภทที่ 2', value: 3 },
            ],
            memberStore3: 0,
            memberStore4: [
                { label: 'เลือกสินค้า', value: 0 },
                { label: 'กระดาษขาวดำ', value: 1 },
                { label: 'กระดาษจับจั๊ว', value: 2 },
                { label: 'กระดาษกล่องลัง', value: 3 },
            ],

            testData: [
                {
                    nameStore: 'ร้านมารวย',
                    product_type: 'กระดาษ',
                    product: 'กระดาษขาว',
                    buy_per_kk: '10',
                },
                {
                    nameStore: 'ร้านมาจน',
                    product_type: 'พลาสติก',
                    product: 'ขวดน้ำพลาสติก',
                    buy_per_kk: ' 8',
                }
            ],
            reportSearchOrders: [],
            showProgress: false,

            productStatus:true,

            productStatusSelect: [
                { label: 'ใช้งาน', value: true },
                { label: 'ไม่ใช้งาน', value: false },
            ],
            productStatusSelect1: [
                { name: 'ใช้งาน', code: 1 ,is_active : true},
                { name: 'ไม่ใช้งาน', code: 0 ,is_active : false},
            ],

            productName :'',

            //image
            fileImgPorduct: [],

        };
        this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
        this.onMemberNameChanged = this.onMemberNameChanged.bind(this);
        this.onMemberName1Changed = this.onMemberName1Changed.bind(this);
        this.onSelectMemberName = this.onSelectMemberName.bind(this);
        this.onSelectMemberName1 = this.onSelectMemberName1.bind(this);
        this.onproductsChange = this.onproductsChange.bind(this);
        this.onsearchProductChange = this.onsearchProductChange.bind(this);
        this.onWasteTypeChange = this.onWasteTypeChange.bind(this);
        this.onsearchProductTypeChange = this.onsearchProductTypeChange.bind(this);
        this.addDataToTable = this.addDataToTable.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.onValidation = this.onValidation.bind(this);
        this.getproductAll = this.getproductAll.bind(this);
        this.searchProductList = this.searchProductList.bind(this);
        this.getproductAllForValid = this.getproductAllForValid.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.onWeightUpdate = this.onWeightUpdate.bind(this);
        this.onproductTypeUpdate = this.onproductTypeUpdate.bind(this);
        this.onproductsStatusChange = this.onproductsStatusChange.bind(this);
        this.onproductsStatusChange1 = this.onproductsStatusChange1.bind(this);
        this.onWasteTypeEditChange = this.onWasteTypeEditChange.bind(this);
        this.onFileDelete = this.onFileDelete.bind(this);
        this.updateStatus = this.updateStatus.bind(this);

        

    }
    componentDidMount() {
        this.getproductAll();
        this.getproductAllForValid();
        const { dashBoardServiceProps, locationServiceProps, masterServiceProps } = this.props
        dashBoardServiceProps.getDashboardUsers();
        locationServiceProps.getProvice();
        masterServiceProps.getProductType();
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div></div>);
    }

    searchProductList(){
        this.setState({ showProgress: true })
        var search = {
            search_name: this.state.memberName1 || null,
            product_type_code: this.state.searchProductTypeCode || 0,
        }
        console.log(this.state.searchProductCode);
        console.log(this.state.searchProductTypeCode);

        if((this.state.memberName1 == null && this.state.searchProductTypeCode == 0)){
            this.getproductAll();
        }else{
        ServerAPI.searchProductList(this.state.searchProductTypeCode,search.search_name).then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({productList: res })
                    //this.getproductAll();
                    // this.clearAllDataInput();
                    this.setState({ showProgress: false })
                    // this.showSuccess();
                    
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }
}

    getproductAll() {
        this.setState({ showProgress: true })
        ServerAPI.getproductAll().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({ productList: res })
                    this.setState({ productPriceList: res})
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }

    getproductAllForValid() {
        ServerAPI.getproductAll().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({ productPriceList: res})
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }


    itemTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }

    itemTemplate1(item) {
        return (
            <div className="p-clearfix">
                {item.product_name}
            </div>
        );
    }

    onSelectMemberName(item) {
        this.setState({ memberName: item.value.search_name });
        this.setState({ memberUid: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    onSelectMemberName1(item) {
        console.log(item)
        this.setState({ memberName1: item.value.product_name });
        this.setState({ memberUid1: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
        console.log(item);
    }
    suggestMemberName(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }
    suggestMemberName1(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }

    onMemberNameChanged(search_name) {
        console.log(search_name)
        console.log(typeof (search_name))
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwastepro(search_name).then((res) => {
                        console.log(res);
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName: search_name });
                this.setState({ memberUid: null });
            }
    }
    onMemberName1Changed(product_name) {
        console.log(product_name)
        console.log(typeof (product_name))
        if (typeof (product_name) != "object")
            if (product_name.trim() != "") {
                this.setState({ memberName1: product_name });
                if (product_name.length >= 2) {
                    ServerAPI.searchProduct(product_name).then((res) => {
                        console.log(res);
                        this.setState({ memberSuggestions1: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName1: product_name });
                this.setState({ memberUid1: null });
            }
    }

    onproductTypeUpdate(e){
        console.log(e)
        this.setState({productTypeObjUpdate: e.value});
    }

    onWeightUpdate(e){
        console.log(e)
        this.setState({productObjUpdate: e.value});
    }

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" onClick={() => this.onproductPriceUpdate(column.rowData)} icon="pi pi-pencil" className="p-button" ></Button>
            <Button type="button" onClick={() => this.onproductPriceDelete(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button>
        </div>;
    }
    statusTemplate(rowData, column) {
        var label = 'test';
        var classname = 'p-button';
        var icons = 'pi pi-eye';
        if(rowData.is_active == false){
            label = 'ไม่ใช้งาน';
            classname = 'p-button-warning';
            icons = 'pi pi-times';
        }
        if(rowData.is_active == true){
            label = 'ใช้งาน';
            classname = 'p-button';
            icons = 'pi pi-check';
       }
        return <div>
            <Button type="button" label={label} onClick={() => this.onStatusUpdate(column.rowData)} icon={icons} className={classname} ></Button>
        </div>;
    }
    openFiles(rowData, column) {
        return <div>
            <Button type="button" onClick={() => this.onOpenFiles(column.rowData)} icon="pi pi-images" label="" className="p-buttong" ></Button>
        </div>;
    }

    onClickDeleteItem(rowData) {
        console.log(rowData)
        if (rowData !== '') {
            var gid = rowData.gid
            ServerAPI.removeProduct(gid).then((res) => {
                console.log(res);
                if (res) {
                    var index = res.toString().indexOf("Error:");
                    console.log("find error:" + (index));
                    if (index == -1) {
                        this.getproductAll()
                        this.setState({ showProgress: false })
                        //this.showSuccess();
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            }).catch((error) => {
            })
        }else {
            this.showWarn();
            this.setState({ showProgress: false })
        }

        // if (index !== -1) {
        //     var array = this.state.productList;
        //     array.splice(index, 1);
        //     this.setState({ productList: array });
        // }
        
    }
    update() {
        this.setState({ showProgress: true })
        console.log(this.state.productEdit)
        if(this.state.productEdit){
            var isNotinput = (
                this.state.product_type_code == '' ||
                this.state.productEdit.product_name == '' ||
                //this.state.is_active_foredit == '' ||
                this.state.isNotValid == true
            );
            if (isNotinput) {
                this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
                this.setState({ showProgress: false })
            } else {
                let isValid = false;
                this.state.productPriceList.forEach((item) => {
                  if (item.product_name == this.state.productEdit.product_name && item.product_code != this.state.productEdit.product_code) {
                    isValid = true;
                  }
                });
                if (isValid) {
                  this.showWarn("ชื่อสินค้าซ้ำกับรายการอื่น");
                  this.setState({ showProgress: false });
                } else {
                
                        const formData = new FormData();
                        if(this.state.fileImgNameUpdateNew){
                            formData.append('ProductImages', this.state.fileImgNameUpdateNew);
                        }
                        formData.set('gid', this.state.productEdit.gid);
                        formData.set('product_type_code', this.state.productTypeObjUpdate.product_type_code);
                        formData.set('product_name', this.state.productEdit.product_name);
                        formData.set('is_active', this.state.productEdit.is_active);
                        // var data = {
                        //     gid: this.state.productEdit.gid,
                        //     product_type_code : this.state.productTypeObjUpdate.product_type_code,
                        //     product_name : this.state.productEdit.product_name,
                        //     is_active: this.state.is_active_foredit.code,
                        // }
                        console.log(formData);
                        ServerAPI.editProduct(formData).then((res) => {
                            console.log(res);
                            if (res) {
                                var index = res.toString().indexOf("Error:");
                                console.log("find error:" + (index));
                                if (index == -1) {
                                    this.getproductAll();
                                    this.setState({ displayDialog:false});
                                    this.setState({ statusDialog:false});
                                    this.clearAllDataInput();
                                    this.showSuccess();
                                } else {
                                    this.showWarn();
                                    this.setState({ showProgress: false })
                                }
                            } else {
                                this.showWarn();
                                this.setState({ showProgress: false })
                            }
                        }).catch((error) => {
                            this.setState({ showProgress: false })
                        })
                }    
            }
        }
    }
    delete() {
        this.setState({ showProgress: true })
        var data = this.state.productEdit;
        this.onClickDeleteItem(data);

        // let index = this.findSelectedWeightIndex();
        // this.setState({
        //     cars: this.state.cars.filter((val,i) => i !== index),
        //     selectedCar: null,
        //     car: null,
        this.setState({deleteDialog: false});
    }
    updateStatus(){
        this.update()
    }
    updateProperty(property, value) {
        let productEdit = this.state.productEdit;
        productEdit[property] = value;
        this.setState({productEdit: productEdit});
    }

    onproductPriceUpdate(e){
        console.log(e)
        if(e.is_active == true){
            var is_active_foredit = {
                name: 'ใช้งาน',
                code: 1,
                is_active:true,
            }
        }else{
            var is_active_foredit = {
                name: 'ไม่ใช้งาน',
                code: 0,
                is_active:false,
            }
        }
        
        var productTypeDropdown = {
            product_type_code: e.product_type_code,
            product_type_name: e.product_type_name,
            //image_name:e.image_name
        }
        var productDropdown = {
            product_code: e.product_code,
            product_name: e.product_name,
        }
        console.log(productTypeDropdown);
        this.setState({
            displayDialog:true,
            productEdit: Object.assign({}, e),
            productTypeObjUpdate:  productTypeDropdown,
            productObjUpdate:  productDropdown,
            is_active_foredit :  is_active_foredit,
            fileImgNameUpdate : e.image_name,
            fileImgNameUpdateNew : null,
        });
        console.log(this.state.productTypeObjUpdate);
    }
    onproductPriceDelete(e){
        console.log(e)
        let _disableDelete = false;
        if(e.is_used){
            _disableDelete = true
        }
        this.setState({
            deleteDialog:true,
            disableDelete: _disableDelete,
            productEdit: Object.assign({}, e),
        });
    }
    onStatusUpdate(e){
        console.log(e)
        var productTypeDropdown = {
            product_type_code: e.product_type_code,
            product_type_name: e.product_type_name,
            //image_name:e.image_name
        }
        this.setState({
            statusDialog: true,
            isNotValid: false,
            productTypeObjUpdate:  productTypeDropdown,
            productEdit: Object.assign({}, e),
        });
      }
    onValidation(valid) {
        console.log(valid)
        if (!valid) this.setState({ isNotValid: true })
        else this.setState({ isNotValid: false })
    }

    onWasteTypeChange(e) {
        this.setState({ wasteTypeSelected: e.value });
        this.setState({ productTypeCode: e.value.product_type_code });
        const { masterServiceProps, product } = this.props
        masterServiceProps.getProduct(e.value.product_type_code);

    }
    onWasteTypeEditChange(e) {
        console.log(e);
        this.setState({ productTypeObjUpdate: e.value });
        this.setState({ product_type_code: e.value.product_type_code });
        const { masterServiceProps, product } = this.props
        masterServiceProps.getProduct(e.value.product_type_code);

    }
    onsearchProductTypeChange(e) {
        this.setState({ searchProductType: e.value });
        this.setState({ searchProductTypeCode: e.value.product_type_code });
        const { masterServiceProps, product } = this.props
        masterServiceProps.getProduct(e.value.product_type_code);

    }

    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    addDataToTable() {
        this.setState({ showProgress: true })
        var isNotinput = (
            this.state.wasteTypeSelected == '' ||
            this.state.productName == '' ||
            this.state.is_active == '' ||
            this.state.isNotValid == true
        );

        if (isNotinput) {
            this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
            this.setState({ showProgress: false })
        } else {
            var notValid = false;
            this.getproductAllForValid();
            console.log(this.state.productPriceList)
            if(this.state.productPriceList)
            this.state.productPriceList.forEach(objProductPrice => {
                console.log(objProductPrice.product_name);
                console.log(this.state.productName);
                if ( objProductPrice.product_name == this.state.productName) {
                    notValid = true;
                }
            });

            if (notValid) {
                this.showWarn("ชื่อสินค้าซ้ำกับรายการอื่น");
                this.setState({ showProgress: false })
            } else {

                const formData = new FormData();
                // if (this.state.fileImgProduct != null)
                    // for (let index = 0; index < this.state.fileImgProduct.length; index++) {
                    //     const file = this.state.fileImgProduct[index];
                    formData.append('ProductImages', this.state.select_img);
                    // }
                    formData.set('product_type_code', this.state.productTypeCode || '');
                    formData.set('product_name', this.state.productName || '');
                    formData.set('is_active', this.state.productStatus);
                
                //     var productList = {
                //     product_type_code: this.state.productTypeCode,
                //     product_name: this.state.productName,
                //     is_active: this.state.productStatus,
                // }

                ServerAPI.AddProduct(formData).then((res) => {
                    console.log(res);
                    if (res) {
                        var index = res.toString().indexOf("Error:");
                        console.log("find error:" + (index));
                        if (index == -1) {
                            // this.searchProductList();
                            this.clearAllDataInput();
                            this.showSuccess();
                            this.getproductAll();
                        } else {
                            this.showWarn();
                            this.setState({ showProgress: false })
                        }
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                }).catch((error) => {
                    this.setState({ showProgress: false })
                })
            }
        }
        console.log(this.state.productList)
    }
    onproductsStatusChange(e) {
        console.log('สถานะ',e);
        this.setState({ productStatus: e.value });
    }
    onproductsStatusChange1(e) {
        console.log('สถานะ',e);
        this.setState({ is_active_foredit: e.value });
    }

    onproductsChange(e) {
        this.setState({ productSelected: e.value });
        
    }
    onsearchProductChange(e) {
        this.setState({ searchProduct: e.value });
        this.setState({ searchProductCode: e.value.product_code });
        this.setState({ searchProductName: e.value.product_name });
    }

    clearAllDataInput() {
        this.setState({ select_img: '' })
        this.setState({ wasteTypeSelected: '' })
        this.setState({ productSelected: '' })
        this.setState({ priceSelected1: '' })
        this.setState({ memberName: '' })
        this.setState({ memberUid: '' })
        this.setState({ productEdit: null })
        this.setState({ productTypeObjUpdate: null })
        this.setState({ productObjUpdate: null })
        this.setState({ productName: '' })
        this.setState({ productStatus: null })
        
        
    }

    clearSearchInput() {
        this.getproductAll();
        this.setState({ memberName1: null }); //order code from select table
        this.setState({ memberUid1: null }); 
        this.setState({ searchProductType: null }); //order code from auto complete
        this.setState({ searchProduct: null });
        this.setState({ searchProductTypeCode: 0 });
        this.setState({ searchProductCode: null });

        console.log(this.state.searchProductType)
        console.log(this.state.searchProduct)
        console.log(this.state.searchProductTypeCode)
        console.log(this.state.searchProductCode)

        this.clearAllDataInput();
    }

    onFileDelete(index){
        var array = this.state.fileImgProduct;
        array.splice(index, 1);
        this.setState({ fileImgProduct: array });
    }
    onFileProductImg(e) {
        console.log(e.files)
        //this.setState({ fileImgIden: e.target.files[0] });
        var list = [];
        for (let index = 0; index < e.files.length; index++) {
            const file = e.files[index];
            console.log(file)
            list.push(file);
        }
        if(this.state.fileImgProduct){
            var arr = this.state.fileImgProduct.concat(list);
            this.setState({ fileImgProduct: arr });
        }else{
            this.setState({ fileImgProduct: list });
        }
    }

    onOpenFiles(e){
        console.log(e)
            this.setState({
            filesDialog:true,
            filesData: Object.assign({}, e),
            fileImgName : e.image_name,
        });
    }
    

    showSearchPanel() {
        const {
            productType1,
            product1,
        } = this.props;
        return (

            <Accordion multiple={true} activeIndex={[0, 1]}>
                <AccordionTab header="เงื่อนไขค้นหาเพื่อจัดการราคารับซื้อ">
                    <div className="p-grid p-justify-start" >
                        
                        <div className="p-col-12 p-md-2 p-lg-2" style={{ alignSelf: 'center' }}>
                            <label>ประเภทสินค้า :</label>
                        </div>
                        <div className="p-col-12 p-md-10 p-lg-10" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <Dropdown
                                style={{ width: '20%' }}
                                value={this.state.searchProductType}
                                options={productType1}
                                onChange={this.onsearchProductTypeChange}
                                placeholder="เลือกประเภทขยะรีไซเคิล"
                                optionLabel="product_type_name"
                                //dataKey="product_type_code"
                            />
                        </div>
                        <div className="p-col-12 p-md-2 p-lg-2" style={{ alignSelf: 'center' }}>
                            <label>ชื่อสินค้า :</label>
                        </div>
                        <div className="p-col-12 p-md-10 p-lg-10" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <AutoComplete
                                inputClassName="input-100"
                                value={this.state.memberName1}
                                suggestions={this.state.memberSuggestions1}
                                onSelect={this.onSelectMemberName1}
                                completeMethod={this.suggestMemberName1.bind(this)}
                                onChange={(e) => this.onMemberName1Changed(e.target.value)}
                                itemTemplate={this.itemTemplate1.bind(this)}
                                placeholder="ระบุชื่อสินค้า" />
                        </div>


                        {/* <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>ชื่อสินค้า :</label>
                        </div>
                        <div className="p-col-12 p-md-10 p-lg-11" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <Dropdown
                                style={{ width: '20%' }}
                                value={this.state.searchProduct}
                                options={product1}
                                onChange={this.onsearchProductChange}
                                placeholder="เลือกสินค้า"
                                optionLabel="product_name"
                                //dataKey="product_code"
                            />
                        </div> */}

                        <div className="p-col-12">
                            <br/>
                            <Button
                                style={{marginRight:"20px"}}
                                label="ค้นหา"
                                icon="fa fa-search"
                                iconPos="left"
                                onClick={this.searchProductList} />
                       
                            <Button
                                style={{ backgroundColor: '#f0ad4e' }}
                                type="submit"
                                label="ยกเลิก"
                                onClick={this.clearSearchInput}
                            />
                        </div>
                    </div>



                </AccordionTab>
            </Accordion>
        )
    }
    
    onSelectTemplateImage = (e) => {
        console.log(e.files[0])
        this.setState({select_img : e.files[0] })
        
    }

    onSelectTemplateImageUpdate = (e) => {
        console.log(e.files[0])
        this.setState({fileImgNameUpdateNew : e.files[0] })
        
    }

    addNewProductTab() {
        const {
            productType,
            product,
        } = this.props;

        let fileImg;

        if(this.state.select_img){
            fileImg = this.state.select_img.objectURL
        }
        return (
            <Accordion multiple={true} 
            // activeIndex={[0, 1]}
            >
                <AccordionTab header="เพิ่มสินค้า">
                    <div className="p-grid">
                        {/* <label className="p-col-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>ชื่อศูนย์จัดการฯซีซาเล้ง :</label>
                        <div className="p-col-10 ">
                            <AutoComplete
                                style={{ width: '20%' }}
                                value={this.state.memberName}
                                suggestions={this.state.memberSuggestions}
                                onSelect={this.onSelectMemberName}
                                completeMethod={this.suggestMemberName.bind(this)}
                                onChange={(e) => this.onMemberNameChanged(e.target.value)}
                                itemTemplate={this.itemTemplate.bind(this)}
                                placeholder="ระบุรหัสสมาชิก/ชื่อสมาชิก" />
                        </div> */}

                        <label className="p-col-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>ประเภทขยะรีไซเคิล :</label>
                        <div className="p-col-10">
                            <Dropdown
                                style={{ width: '20%' }}
                                value={this.state.wasteTypeSelected}
                                options={productType}
                                onChange={this.onWasteTypeChange}
                                placeholder="เลือกประเภทขยะรีไซเคิล"
                                optionLabel="product_type_name"
                                //dataKey="product_type_code"
                            />
                        </div>

                        <label className="p-col-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>ชื่อสินค้า :</label>

                        <div className="p-col-10">
                            <InputText placeholder='กรอกชื่อสินค้า' 
                                value={this.state.productName} 
                                onChange={(e) => this.setState({productName: e.target.value})} />
                           
                        </div>
                        <div className="p-col-12 p-md-2 p-lg-2" style={{ alignSelf: 'center' }}>
                                <label>สถานะ :</label>
                            </div>
                            <div className="p-col-12 p-md-10 p-lg-10" style={{ alignSelf: 'center' }}>
                                <Dropdown
                                    placeholder="เลือกสถานะ"
                                    style={{ width: '20%' }}
                                    value={this.state.productStatus}
                                    options={this.state.productStatusSelect}
                                    onChange={this.onproductsStatusChange}

                                />
                            </div>
                            <div className="p-col-6">
                                <FileUpload
                                    name="wastes"
                                    onSelect={(e) => this.onSelectTemplateImage(e)}
                                    chooseLabel="แนบรูปไอคอน"
                                    accept="image/*"
                                    mode="basic"
                                    auto={true}
                                />
                                {/* <CEFileUpload name="wastes[]"
                                    value={this.state.fileImgProduct}
                                    onSelect={(e) => this.onFileProductImg(e)}
                                    onDelete={(index) => this.onFileDelete(index)}
                                    mode="basic"
                                    chooseLabel="แนบไฟล์"
                                    cancelLabel="ยกเลิก"
                                    multiple={true}
                                    accept="image/*"
                                    maxFileSize={10000000} /> */}
                            </div>
                            <div className="p-col-12">
                                <img src={fileImg} width='100px'></img>
                            </div>
                            
                        <div className="p-col-12 ">
                            <Button
                                style={{ float: "left" }}
                                label="เพิ่มรายการ"
                                icon="fa fa-plus"
                                iconPos="left"
                                onClick={this.addDataToTable}

                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>

        )
    }


    showWeightTable() {
        const responsiveOptions = [
            {
                breakpoint: '1024px',
                numVisible: 5
            },
            {
                breakpoint: '768px',
                numVisible: 3
            },
            {
                breakpoint: '560px',
                numVisible: 1
            }
        ];
        const { productType1} = this.props;
        var list = this.state.productList;
        var newlist = []
        var newOptionDropdown = []
        if(productType1.length > 0)
        list.forEach((objlist,index) => {
            productType1.forEach((objproduct) => {
                // console.log(objlist);
                // console.log(objproduct);
                if(objlist.product_type_code == objproduct.product_type_code){
                    var listnew = {
                        ...objlist,
                        // gid: objlist.gid,
                        // product_type_code: objlist.product_type_code,
                        // product_code: objlist.product_code,
                        // product_name: objlist.product_name,
                        // is_active: objlist.is_active,
                        // is_deleted: objlist.is_deleted,
                        // image_name :objlist.image_name,

                        product_type_name: objproduct.product_type_name,
                    }
                    newlist.push(listnew);
                }
            });
        });

        productType1.forEach((objproduct) => {
            var newOption = {
                product_type_code: objproduct.product_type_code,
                product_type_name: objproduct.product_type_name,
            }
            newOptionDropdown.push(newOption)
        })

        let link = `${API_ROOT}/master/product/getfile/${this.state.fileImgNameUpdate}`

        let fileImgUpdate;

        if(this.state.fileImgNameUpdateNew){
            link = this.state.fileImgNameUpdateNew.objectURL
        }


        // console.log(this.state.productList);
        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="บันทึก" icon="pi pi-check" onClick={this.update}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displayDialog: false})} className="p-button-warning"/>
            </div>;
        let deleteDialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ใช่" icon="pi pi-check" onClick={this.delete} disabled={this.state.disableDelete}/>
            <Button label="ไม่ใช่" icon="pi pi-times" onClick={() => this.setState({deleteDialog: false})} className="p-button-warning"/>
            </div>;
        let statusDialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="บันทึก" icon="pi pi-check" onClick={this.updateStatus}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({statusDialog: false})} className="p-button-warning"/>
            </div>;
        const weightTable = (
            <div className="p-col-12">
                <Panel header="รายการสินค้า" >
                    <DataTable value={newlist} paginatorPosition="bottom"
                        selectionMode="single" paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                        selection={this.state.selectedCar1} onSelectionChange={e => this.setState({ selectedCar1: e.value })}
                        responsive={true} emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)} >
                        <Column field="product_type_name" header="ประเภทสินค้า" sortable={true} filter={true} className="p-text-center"/>
                        <Column field="product_name" header="สินค้า" sortable={true} filter={true} className="p-text-center"/>
                        <Column field="is_active" header="สถานะการใช้งาน" body={this.statusTemplate.bind(this)} sortable={true} className="p-text-center"/>
                        <Column body={this.openFiles.bind(this)} header="รูปภาพไอคอน" className="p-text-center" style={{ width: '9em' }} />
                        <Column field="gid" body={this.actionTemplate.bind(this)} header="จัดการ" className="p-text-center" style={{ width: '10%' }} />
                    </DataTable>
                </Panel>
                <Dialog visible={this.state.deleteDialog} style={{width:'40vw'}} header="ยืนยันการลบข้อมูล" modal={true} footer={deleteDialogFooter} onHide={() => this.setState({deleteDialog: false})}>
                        {
                            this.state.productEdit &&
                            
                            <div className="p-grid p-fluid" >
                                <div className="p-col-12"><span>คุณต้องการจะลบข้อมูลต่อไปนี้</span></div>
                                <div className="p-col-4" ><label htmlFor="product_type_name">ประเภทสินค้า :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productEdit.product_type_name}</strong>
                                </div>
                                <div className="p-col-4" ><label htmlFor="product_name">สินค้า :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productEdit.product_name}</strong>
                                </div>
                                <div className="p-col-4" ><label htmlFor="is_active_name">สถานะ :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productEdit.is_active ? 'ใช้งาน' : 'ไม่ใช้งาน'}</strong>
                                </div>
                                {this.state.productEdit.is_used && 
                                <div className="p-col-12" style={{textAlign: 'center'}} >
                                    <span style={{color: 'red'}}>*****สินค้านี้ไม่สามารถลบได้ เนื่องจากมีการซื้อ-ขายสินค้านี้*****</span>
                                </div>}
                            </div>
                        }
                        {/* {console.log(this.state.productEdit)} */}
                    </Dialog>
                    <Dialog visible={this.state.displayDialog} style={{width: '40vw'}} header="แก้ไขข้อมูล" modal={true} footer={dialogFooter} onHide={() => this.setState({displayDialog: false})}>
                        {
                            this.state.productEdit &&
                            
                            <div className="p-grid p-fluid" style={{marginBottom: '65px'}}>
                                
                                <div className="p-col-12"><span>คุณต้องการจะแก้ไขข้อมูลต่อไปนี้</span></div>
                                <div className="p-col-4" ><label htmlFor="product_type_name">ประเภทขยะรีไซเคิล :</label></div>
                                <div className="p-col-8" >
                                    <Dropdown
                                        style={{ width: '50%' }}
                                        value={this.state.productTypeObjUpdate}
                                        options={newOptionDropdown}
                                        onChange={this.onWasteTypeEditChange}
                                        placeholder="เลือกประเภทขยะรีไซเคิล"
                                        optionLabel="product_type_name"
                                        //dataKey="product_type_code"
                                    />
                                    
                                </div>
                                <div className="p-col-4" ><label htmlFor="product_name">สินค้า :</label></div>
                                <div className="p-col-8"  >
                                <InputText placeholder='กรอกชื่อสินค้า' 
                                    value={this.state.productEdit.product_name} 
                                    onChange={(e) => {this.updateProperty('product_name', e.target.value)}}
                                    // onChange={(e) => this.setState({product_name: e.target.value})} 
                                    />
                                </div>
                                <div className="p-col-4">
                                    <label htmlFor="product_type_name">รูปไอคอน :</label>
                                </div>
                                <div className="p-col-8">
                                <img  src={link} onError={(e) => e.target.src='assets/layout/images/exception/image-not-found.png'} width='180px'></img>
                                </div>
                                
                                <div className="p-col-6">
                                    <FileUpload
                                        name="wastes[]"
                                        onSelect={(e) => this.onSelectTemplateImageUpdate(e)}
                                        chooseLabel="แนบรูปไอคอน"
                                        accept="image/*"
                                        mode="basic"
                                        auto={true}
                                    />
                                    {/* <ImageInfoView
                                        header="รูปไอคอน"
                                        src={`${API_ROOT}/master/product/getfile/${this.state.image_name}`}
                                        fileName={this.state.fileImgNameUpdate}
                                        name="filePerson[]"
                                        onSelect={(e) => this.onSelectTemplateImageUpdate(e)}
                                        blob={this.state.fileImgPerson}
                                        accept="image/*" 
                                        onRefresh={this.onRefreshPerson}
                                        onDelete={this.onDeletePerson}
                                    /> */}
                                </div>

                            </div>
                        }
                    </Dialog>
                    <Dialog 
                        visible={this.state.filesDialog} 
                        style={{width: '30vw'}} 
                        header="รูปภาพไอคอนสินค้า" 
                        modal={true}  
                        onHide={() => this.setState({filesDialog: false})}
                        dismissableMask={true}>
                            <div className="p-grid p-fluid" style={{marginBottom: '60px'}}>
                                <img width='100%' style={{alignItems:'center'}} src={`${API_ROOT}/master/product/getfile/${this.state.fileImgName}`} onError={(e) => e.target.src='assets/layout/images/exception/image-not-found.png'}/>
                            </div>
                    </Dialog>

                    <Dialog dismissableMask={true} visible={this.state.statusDialog} 
                        header="สถานะ" modal={true} footer={statusDialogFooter} 
                        onHide={() => this.setState({statusDialog: false})}
                        style={{width:'30vw'}}
                        >
                        { this.state.productEdit &&
                        <div className="p-grid" >
                            <div className="p-col-12" style={{ padding: '1em' }}>
                                <label style={{ marginRight: '5px' }}>สถานะสินค้า : </label>
                            </div>
                            <br/>
                            <br/>
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <div className="p-radiobutton-label">
                                    <RadioButton inputId="rb2" name="isVerified" value={true} style={{marginBottom:'10px'}}
                                        onChange={(e) => {this.updateProperty('is_active', e.target.value)}}
                                        checked={this.state.productEdit.is_active === true} />
                                    <label htmlFor="rb2" className="p-radiobutton-label">ใช้งาน</label>
                            </div>
                                </div>
                                
                            <div className="p-col-12 p-md-6 p-lg-6">
                                <div className="p-radiobutton-label">
                                    <RadioButton inputId="rb1" name="isVerified" value={false} style={{marginBottom:'10px'}}
                                        onChange={(e) => {this.updateProperty('is_active', e.target.value)}}
                                        checked={this.state.productEdit.is_active === false} />
                                    <label htmlFor="rb1" className="p-radiobutton-label">ไม่ใช้งาน</label>
                                </div> 
                            </div>
                            
                        </div>
                        }
                    </Dialog>

            </div>
        );
        if(!this.state.showProgress)
        return weightTable;
    }

    onSubmit() {

    }

    render() {
        const searchPanel = this.showSearchPanel();
        const addnew = this.addNewProductTab();
        const weightTablePanel = this.showWeightTable();
        const showProgress = this.showProgressSpinner();


        return <div className="p-grid ">
            <Growl ref={(el) => this.growl = el} />
            {searchPanel}
            {addnew}
            {showProgress}
            {weightTablePanel}
        </div>
    }
}



const mapStateToProps = (state) => ({
    dhbUsers: state.dhbAdmin.dhbUsers,
    locationMaster: state.location,
    provinces: state.location.province,
    districts: state.location.district,
    subDistricts: state.location.subDistrict,
    cardProvince: state.location.cardProvince,
    cardDistrict: state.location.cardDistrict,
    cardSubDistrict: state.location.cardSubDistrict,
    authentication: state.authentication,
    userInfo: state.userInfo.data,
    userInfoMaster: state.userInfo,
    updateWasteproProfile: state.updateWasteproProfile,
    banks: state.master.banks,
    productType: state.master.productType,
    product: state.master.product,
    productType1: state.master.productType,
    product1: state.master.product,
    getFileWastepro: state.getFileWastepro,
})

const mapDispatchToProps = dispatch => ({

    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    fileServiceProps: bindActionCreators(FileService, dispatch),

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ManageProduct)