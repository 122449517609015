

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { InputText } from 'primereact/inputtext'
import { ToggleButton } from 'primereact/togglebutton'


class ChangePassword extends Component {

    state = {

    }

    //>Initial 
    componentDidMount() {
        //console.log("ChangePassword :componentDidMount")
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        //console.log("ChangePassword :componentWillReceiveProps")
    }

    shouldComponentUpdate(nextProps, nextState) {
        //console.log("ChangePassword :shouldComponentUpdate")
        return true
    }

    componentWillUpdate(nextProps, nextState) {
        //console.log("ChangePassword :componentWillUpdate")

    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("ChangePassword :componentDidUpdate")

    }

    //> Event
    onChangeOldPassword = (e) => { this.props.onChangeOldPassword(e) }
    onChangeNewPassword = (e) => { this.props.onChangeNewPassword(e) }
    onChangeConfirmPassword = (e) => { this.props.onChangeConfirmPassword(e) }
    onChangePasswordVisible = (e) => { this.props.onChangePasswordVisible(e) }

    render() {
        //console.log("ChangePassword :render")
        const {
            typePasswordInput,
            classNameRequired,
            isChangePassword,
            errorMessageOldPassword,
            errorMessagePassword,
            errorMessageConfirmPassword,
            checkedPasswordVisible,
            disableNewPass
        } = this.props

        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <label className="required">รหัสผ่านเดิม</label>
                    <InputText
                        required={true}
                        name="oldPassword"
                        type={typePasswordInput}
                        className={classNameRequired}
                        placeholder="Old Password" autoComplete="new-password"
                        onChange={this.onChangeOldPassword}
                    >
                    </InputText>
                    <span style={{ color: 'red' }}>{errorMessageOldPassword} </span>
                </div>

                <div className="p-col-12">
                    <label className="required">รหัสผ่านใหม่</label>
                    <InputText
                        required={true}
                        name="password"
                        className={classNameRequired}
                        placeholder="New Password"
                        type={typePasswordInput}
                        onChange={this.onChangeNewPassword}
                        disabled={disableNewPass}>
                    </InputText>
                    {isChangePassword &&
                        <span style={{ color: 'red' }}>{errorMessagePassword} </span>
                    }
                </div>

                <div className="p-col-12">
                    <label className="required">ยืนยันรหัสผ่าน</label>
                    <InputText
                        required={true}
                        name="confirmPassword"
                        className={classNameRequired}
                        placeholder="Confirm Password"
                        type={typePasswordInput}
                        onChange={this.onChangeConfirmPassword}
                        disabled={disableNewPass}
                    >
                    </InputText>

                    {isChangePassword &&
                        <span style={{ color: 'red' }}>{errorMessageConfirmPassword} </span>
                    }
                </div>

                <div className="p-col-12 p-md-3 p-lg-3" >
                    <ToggleButton style={{
                        width: '150px'
                        , marginTop: '5px'
                        , backgroundColor: '#43a047'
                        , color: '#ffffff'
                        , borderColor: '#3b8e3f'

                    }}
                        className="p-button-secondary"
                        onLabel="ซ่อนรหัสผ่าน"
                        offLabel="แสดงรหัสผ่าน"
                        onIcon="fa fa-eye-slash"
                        offIcon="fa fa-eye"
                        checked={checkedPasswordVisible}
                        onChange={this.onChangePasswordVisible}
                    />

                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(ChangePassword)
