import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Moment from "react-moment";
import "moment-timezone";
import { InputText } from "primereact/inputtext";
import { AutoComplete } from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Growl } from "primereact/growl";
import { Avatar } from "../../features/widget/Avatar";
import { API_ROOT } from "../../constants/api";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Panel } from "primereact/components/panel/Panel";
import { DataTable } from "primereact/components/datatable/DataTable";
import { Column } from "primereact/components/column/Column";
import { MapWithASearchBoxAndMarkers } from "../../features/map/MapWithASearchBoxAndMarkers";
import { Dropdown } from "primereact/components/dropdown/Dropdown";
import { FileUpload } from "primereact/fileupload";
import { Calendar } from "primereact/calendar";
import { ProgressSpinner } from "primereact/progressspinner";
import * as ServerAPI from "./service";
import * as LocationService from "../../service/LocationService";
import * as UserService from "../../service/user.service";
import * as FileService from "../../service/file.service";
import * as DashBoardService from "./service";
import * as MasterService from "../../service/MasterService";
import { isEmpty } from "../../utils/validators";
import { Validators } from "../../utils";
import { CEInputText, CECalendar, CEDropdown } from "../../features/input";
import { CEFileUpload } from "../../features/widget";
import { CEFileUploadWithImg } from "../../features/widget";
import { Dialog } from "primereact/dialog";
import { InputSwitch } from "primereact/inputswitch";
import { RadioButton } from "primereact/radiobutton";
import { Card } from "primereact/card";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { FileView } from "../../features/widget/FileView";
import ImageInfoView from "../profile/ImageInfoView";
import { MultiSelect } from "primereact/multiselect";
import { ScrollPanel } from "primereact/scrollpanel";
import scrollToComponent from "react-scroll-to-component";
import { Galleria } from "primereact/galleria";
import { Editor } from "primereact/editor";
import Quill from "quill";

// configure Quill to use inline styles so the email's format properly
var DirectionAttribute = Quill.import("attributors/attribute/direction");
Quill.register(DirectionAttribute, true);

var AlignClass = Quill.import("attributors/class/align");
Quill.register(AlignClass, true);

var BackgroundClass = Quill.import("attributors/class/background");
Quill.register(BackgroundClass, true);

var ColorClass = Quill.import("attributors/class/color");
Quill.register(ColorClass, true);

var DirectionClass = Quill.import("attributors/class/direction");
Quill.register(DirectionClass, true);

var FontClass = Quill.import("attributors/class/font");
Quill.register(FontClass, true);

var SizeClass = Quill.import("attributors/class/size");
Quill.register(SizeClass, true);

var AlignStyle = Quill.import("attributors/style/align");
Quill.register(AlignStyle, true);

var BackgroundStyle = Quill.import("attributors/style/background");
Quill.register(BackgroundStyle, true);

var ColorStyle = Quill.import("attributors/style/color");
Quill.register(ColorStyle, true);

var DirectionStyle = Quill.import("attributors/style/direction");
Quill.register(DirectionStyle, true);

var FontStyle = Quill.import("attributors/style/font");
Quill.register(FontStyle, true);

var SizeStyle = Quill.import("attributors/style/size");
Quill.register(SizeStyle, true);

// create new Quill instance here...

class ManageNews extends Component {
  static propTypes = {};
  static defaultProps = {};

  constructor() {
    super();
    this.state = {
      newsHeader: "",
      answer: "",
      newsDataList: [],
      isNotValid: false,
      isNotValidUpdate: false,
      showProgress: false,
      vehicle_img_name: "",
      newsBody: "",
      listFileDelete: [],
      vehicle_img_name: "",
      fileImgWaste: [],
      selectedMasterIndex: null,
      userType: [
        { name: "ผู้รักษ์โลก", code: "1" },
        { name: "ทีมขนส่งซีซาเล้ง", code: "2" },
        { name: "ศูนย์จัดการฯซีซาเล้ง", code: "3" },
      ],
      userTypeItem: [
        // { name: 'ทุกกลุ่ม', code: '0' },
        { name: "ผู้รักษ์โลก", code: "1" },
        { name: "ทีมขนส่งซีซาเล้ง", code: "2" },
        { name: "ศูนย์จัดการฯซีซาเล้ง", code: "3" },
      ],
      showAddNewsPanel: false,
      newsCode: "",
      deletedGid: [],
      filesData: null,
    };
    this.onClickAddDistance = this.onClickAddDistance.bind(this);
    this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
    this.onValidation = this.onValidation.bind(this);
    this.getNewsList = this.getNewsList.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.onUpdateValidation = this.onUpdateValidation.bind(this);
    this.clearAllDataInput = this.clearAllDataInput.bind(this);
    this.onToggleAccordion = this.onToggleAccordion.bind(this);
    this.itemTemplate = this.itemTemplate.bind(this);
    this.newUpdate = this.newUpdate.bind(this);
  }
  componentDidMount() {
    this.getNewsList();
  }
  showProgressSpinner() {
    const Progress = (
      <div className="p-col-12 p-md-8 p-lg-9">
        <ProgressSpinner
          style={{ width: "80px", height: "80px" }}
          strokeWidth="8"
          fill="#EEEEEE"
          animationDuration="0.5s"
        />
      </div>
    );

    if (this.state.showProgress) {
      return Progress;
    } else return <div />;
  }
  getNewsList() {
    this.setState({ showProgress: true });
    ServerAPI.getAllNews()
      .then((res) => {
        console.log(res);
        if (res) {
          var index = res.toString().indexOf("Error:");
          console.log("find error:" + index);
          if (index == -1) {
            var list = [];

            res.forEach((obj, index) => {
              let userType = [];
              let wastegen = "";
              let wastetran = "";
              let wastepro = "";
              let userTypeLabel = "";
              let newFileObj = [];
              if (obj.isWasteGen) {
                let type = { name: "ผู้รักษ์โลก", code: "1" };
                userType.push(type);
                wastegen = "ผู้รักษ์โลก";
              }
              if (obj.isWasteTran) {
                let type = { name: "ทีมขนส่งซีซาเล้ง", code: "2" };
                userType.push(type);
                wastetran = "ทีมขนส่งซีซาเล้ง";
              }
              if (obj.isWastePro) {
                let type = { name: "ศูนย์จัดการฯซีซาเล้ง", code: "3" };
                userType.push(type);
                wastepro = "ศูนย์จัดการฯซีซาเล้ง";
              }

              if (wastegen && wastetran && wastepro) {
                userTypeLabel = "ทุกกลุ่ม";
              } else {
                userTypeLabel = wastegen + "\n" + wastetran + "\n" + wastepro;
              }

              obj.newsImageDetails.forEach((item) => {
                let newValiable = {
                  defaultImage: item.defaultImage,
                  gid: item.gid,
                  name: item.imageName,
                  imageName: item.imageName,
                  isDeleted: item.isDeleted,
                };
                newFileObj.push(newValiable);
              });

              let arr = {
                gid: obj.gid,
                newsCode: obj.newsCode,
                newsTitle: obj.newsTitle,
                updateDate: obj.updateDate,
                createdDate: obj.createdDate,
                newsDetail: obj.newsDetail,

                defaultImageIndex: obj.defaultImageIndex,
                defaultImageName: obj.defaultImageName,
                newsImageDetails: newFileObj,

                userType: userType,
                userTypeLabel: userTypeLabel,
                isWasteGen: obj.isWasteGen,
                isWasteTran: obj.isWasteTran,
                isWastePro: obj.isWastePro,
              };
              list.push(arr);
            });
            console.log(list);
            this.setState({ newsDataList: list.reverse() });
            this.setState({ showProgress: false });
            //this.showSuccess();
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        } else {
          this.showWarn();
          this.setState({ showProgress: false });
        }
      })
      .catch((error) => {
        this.setState({ showProgress: false });
      });
  }
  actionTemplate(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          onClick={() => (
            scrollToComponent(this.createNewsPanel, {
              offset: 0,
              align: "top",
              duration: 500,
            }),
            this.onRowClickUpdate(column.rowData)
          )}
          icon="pi pi-pencil"
          className="p-button"
        />
        {/* <Button type="button" onClick={() => this.onDistanceUpdate(column.rowData)} icon="pi pi-pencil" className="p-button" ></Button> */}
        <Button
          type="button"
          onClick={() => this.onDistanceDelete(column.rowData)}
          icon="fa fa-trash"
          className="p-button-warning"
        />
      </div>
    );
  }
  openNewsBody(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          onClick={() => this.onOpenNewsBody(column.rowData)}
          icon="pi pi-file"
          label="ดูเนื้อหา"
          className="p-button"
        />
      </div>
    );
  }
  openFiles(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          onClick={() => this.onOpenFiles(column.rowData)}
          icon="pi pi-images"
          label="เปิดดูไฟล์"
          className="p-buttong"
        />
      </div>
    );
  }
  onClickDeleteItem(rowData) {
    if (rowData !== "") {
      console.log(rowData);
      var gid = rowData.gid;
      ServerAPI.deleteNews(gid)
        .then((res) => {
          console.log(res);
          if (res) {
            var index = res.toString().indexOf("Error:");
            console.log("find error:" + index);
            if (index == -1) {
              this.showSuccess();
              this.getNewsList();
            } else {
              this.showWarn();
              this.setState({ showProgress: false });
            }
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        })
        .catch((error) => {
          this.setState({ showProgress: false });
        });
    } else {
      this.showWarn();
      this.setState({ showProgress: false });
    }
  }
  update() {
    this.setState({ showProgress: true });
    var isNotinput =
      this.state.newsHeader == "" ||
      this.state.newsBody == "" ||
      this.state.userType.length == 0 ||
      this.state.isNotValid == true;

    if (isNotinput) {
      this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
      this.setState({ showProgress: false });
    } else {
      if (this.state.selectedMasterIndex == null) {
        this.showWarn("กรุณาแนบไฟล์รูปและเลือกหน้าปก");
        this.setState({ showProgress: false });
      } else {
        const userTypeSelected = this.state.userType;
        var genSelected = false;
        var tranSelected = false;
        var proSelected = false;

        userTypeSelected.forEach((item) => {
          switch (item.code) {
            case "1":
              genSelected = true;
              break;
            case "2":
              tranSelected = true;
              break;
            case "3":
              proSelected = true;
              break;
          }
        });
        const formData = new FormData();

        if (this.state.fileImgWaste != null)
          for (let index = 0; index < this.state.fileImgWaste.length; index++) {
            if (this.state.fileImgWaste[index].objectURL) {
              const file = this.state.fileImgWaste[index];
              formData.append("newsImages", file);
            }
          }
        formData.set("gid", this.state.gid || "");
        formData.set("newsCode", this.state.newsCode || "");

        if (this.state.deletedGid.length != 0)
          for (let index = 0; index < this.state.deletedGid.length; index++) {
            if (this.state.deletedGid[index]) {
              const gid = this.state.deletedGid[index];
              formData.append("deleteImageGid", gid);
            }
          }

        formData.set("newsTitle", this.state.newsHeader || "");
        formData.set("newsDetail", this.state.newsBody || "");
        formData.set("isWasteGen", genSelected || false);
        formData.set("isWasteTran", tranSelected || false);
        formData.set("isWastePro", proSelected || false);
        formData.set("defaultImageIndex", this.state.selectedMasterIndex || 0);
        // formData.set('defaultImageName', rowData.defaultImageName || '');

        console.log(this.state.fileImgWaste);
        console.log(this.state.selectedMasterIndex);
        console.log(this.state.deletedGid);
        // this.showSuccess();
        // this.setState({showProgress: false})
        ServerAPI.editNews(formData)
          .then((res) => {
            console.log(res);
            if (res) {
              var index = res.toString().indexOf("Error:");
              console.log("find error:" + index);
              if (index == -1) {
                this.getNewsList();
                this.clearAllDataInput();
                this.showSuccess();
              } else {
                this.showWarn();
                this.setState({ showProgress: false });
              }
            } else {
              this.showWarn();
              this.setState({ showProgress: false });
            }
          })
          .catch((error) => {
            this.setState({ showProgress: false });
          });
      }
    }
  }

  delete() {
    this.setState({ showProgress: true });
    var data = this.state.deleteData;
    this.onClickDeleteItem(data);
    this.setState({ deleteDialog: false });
  }
  updateProperty(property, value) {
    let distance = this.state.distance;
    distance[property] = value;
    this.setState({ distance: distance });
  }
  //edit news
  onRowClickUpdate(rowData) {
    console.log(rowData);
    let code = rowData.newsCode;
    this.clearAllDataInput();
    ServerAPI.getNewsByCode(code)
      .then((res) => {
        console.log(res);
        if (res) {
          var index = res.toString().indexOf("Error:");
          console.log("find error:" + index);
          if (index == -1) {
            let userType = [];
            let newFileObj = [];
            res.forEach((obj, index) => {
              if (obj.isWasteGen) {
                let type = { name: "ผู้รักษ์โลก", code: "1" };
                userType.push(type);
              }
              if (obj.isWasteTran) {
                let type = { name: "ทีมขนส่งซีซาเล้ง", code: "2" };
                userType.push(type);
              }
              if (obj.isWastePro) {
                let type = { name: "ศูนย์จัดการฯซีซาเล้ง", code: "3" };
                userType.push(type);
              }

              obj.newsImageDetails.forEach((item) => {
                let newValiable = {
                  defaultImage: item.defaultImage,
                  gid: item.gid,
                  name: item.imageName,
                  imageName: item.imageName,
                  isDeleted: item.isDeleted,
                };
                newFileObj.push(newValiable);
              });

              this.setState({
                newsHeader: obj.newsTitle,
                newsBody: obj.newsDetail,

                userType: userType,
                fileImgWaste: newFileObj,

                selectedMasterIndex: obj.defaultImageIndex,
                newsCode: obj.newsCode,
                gid: obj.gid,
                showAddNewsPanel: true,
                selectedMaster: null,
              });
            });
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        } else {
          this.showWarn();
          this.setState({ showProgress: false });
        }
      })
      .catch((error) => {
        this.setState({ showProgress: false });
      });
  }
  onDistanceUpdate(e) {
    console.log(e);
    this.setState({
      displayDialog: true,
      distance: Object.assign({}, e),
    });
  }
  onDistanceDelete(e) {
    console.log(e);
    this.setState({
      deleteDialog: true,
      deleteData: Object.assign({}, e),
    });
  }
  onOpenNewsBody(e) {
    console.log(e);
    this.setState({
      newsBodyDialog: true,
      newsbodyData: Object.assign({}, e),
    });
  }
  onOpenFiles(e) {
    console.log(e);

    var imgList = [];
    e.newsImageDetails.forEach((item, index) => {
      let array = {
        url: `${API_ROOT}/user/news/getfile/${item.imageName}`,
        name: item.name,
        gid: item.gid,
        index: index,
      };
      imgList.push(array);
    });
    console.log(imgList);
    if (imgList.length != 0) {
      this.setState({
        filesDialog: true,
        filesData: Object.assign({}, e),
        fileImgList: imgList,
      });
    } else {
      this.showWarn("ไม่พบไฟล์รูปภาพ");
    }
  }
  onValidation(valid) {
    console.log(valid);
    if (!valid) this.setState({ isNotValid: true });
    else this.setState({ isNotValid: false });
  }
  onUpdateValidation(valid) {
    console.log(valid);
    if (!valid) this.setState({ isNotValidUpdate: true });
    else this.setState({ isNotValidUpdate: false });
  }
  showSuccess = (message) => {
    this.growl.show({
      severity: "success",
      summary: "สถานะ",
      detail: message ? message : "ทำรายการสำเร็จ",
    });
  };
  showWarn = (message) => {
    this.growl.show({
      severity: "warn",
      summary: "สถานะ",
      detail: message ? message : "มีบางอย่างผิดพลาด กรุณาตรวจสอบ",
    });
  };

  onClickAddDistance() {
    console.log(this.state.userType);
    this.setState({ showProgress: true });
    var isNotinput =
      this.state.newsHeader == "" ||
      this.state.newsBody == "" ||
      this.state.userType.length == 0 ||
      this.state.isNotValid == true;

    if (isNotinput) {
      this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
      this.setState({ showProgress: false });
    } else {
      if (this.state.selectedMasterIndex == null) {
        this.showWarn("กรุณาแนบไฟล์รูปและเลือกหน้าปก");
        this.setState({ showProgress: false });
      } else {
        console.log(this.state.fileImgWaste);
        // var date = new Date().toLocaleString()
        const userTypeSelected = this.state.userType;
        var genSelected = false;
        var tranSelected = false;
        var proSelected = false;

        userTypeSelected.forEach((item) => {
          switch (item.code) {
            case "1":
              genSelected = true;
              break;
            case "2":
              tranSelected = true;
              break;
            case "3":
              proSelected = true;
              break;
          }
        });
        const formData = new FormData();
        if (this.state.fileImgWaste != null)
          for (let index = 0; index < this.state.fileImgWaste.length; index++) {
            const file = this.state.fileImgWaste[index];
            formData.append("newsImages", file);
          }
        formData.set("newsTitle", this.state.newsHeader || "");
        formData.set("newsDetail", this.state.newsBody || "");
        formData.set("isWasteGen", genSelected || false);
        formData.set("isWasteTran", tranSelected || false);
        formData.set("isWastePro", proSelected || false);
        formData.set("defaultImageIndex", this.state.selectedMasterIndex || 0);

        console.log(formData);
        ServerAPI.createNews(formData)
          .then((res) => {
            console.log(res);
            if (res) {
              var index = res.toString().indexOf("Error:");
              console.log("find error:" + index);
              if (index == -1) {
                this.showSuccess();
                this.getNewsList();
                this.clearAllDataInput();
                this.setState({ showProgress: false });
              } else {
                this.showWarn();
                this.setState({ showProgress: false });
              }
            } else {
              this.showWarn();
              this.setState({ showProgress: false });
            }
          })
          .catch((error) => {
            this.setState({ showProgress: false });
          });
      }
    }
  }

  clearAllDataInput() {
    this.setState({
      newsHeader: "",
      newsBody: "",
      wasteType: null,
      fileImgWaste: [],
      selectedMasterIndex: null,
      newsCode: "",
      showAddNewsPanel: false,
      showProgress: false,
      userType: [
        { name: "ผู้รักษ์โลก", code: "1" },
        { name: "ทีมขนส่งซีซาเล้ง", code: "2" },
        { name: "ศูนย์จัดการฯซีซาเล้ง", code: "3" },
      ],
      deletedGid: [],
      gid: 0,
    });
  }

  imgButton(rowData, column) {
    console.log(rowData);
    if (rowData.fileImg) {
      //const path = '/api/account/transfer/'+rowData.fileImg;
      const path = rowData.fileImg.objectURL;
      return (
        <div>
          <Button
            label="เปิดดูไฟล์"
            icon="fa fa-eye"
            iconPos="left"
            onClick={(e) => window.open(path, "_blank")}
          />
        </div>
      );
    }
  }
  onSelectFileImgVehicle = (e) => {
    this.setState({ fileImgVehicle: e.files[0] });
  };
  onRefreshVehicle = (e) => {
    this.setState({ vehicle_img_name: this.state.vehicle_img_name });
    this.setState({
      listFileDelete: this.state.listFileDelete.filter(
        (item) => item != this.state.vehicle_img_name
      ),
    });
  };
  onDeleteVehicle = (e) => {
    this.state.listFileDelete.push(this.state.vehicle_img_name);
    this.setState({ vehicle_img_name: null });
    this.setState({ fileImgVehicle: null });
  };

  onFileDelete(index) {
    let array = this.state.fileImgWaste;

    if (array[index].gid) {
      let arrayGid = this.state.deletedGid;
      arrayGid.push(array[index].gid);
      this.setState({ deletedGid: arrayGid });
      console.log(arrayGid);
    }

    array.splice(index, 1);
    this.setState({ fileImgWaste: array });

    var currentIndex = this.state.selectedMasterIndex;
    if (index == currentIndex) {
      this.setState({ selectedMasterIndex: null });
    } else if (index < currentIndex) {
      currentIndex--;
      this.setState({ selectedMasterIndex: currentIndex });
    }

    console.log("delete index : " + index);
    console.log("current index : " + currentIndex);
  }
  onFileWasteChanged(e) {
    console.log(e.files);
    //this.setState({ fileImgIden: e.target.files[0] });
    var list = [];
    for (let index = 0; index < e.files.length; index++) {
      const file = e.files[index];
      console.log(file);
      list.push(file);
    }
    if (this.state.fileImgWaste) {
      var arr = this.state.fileImgWaste.concat(list);
      this.setState({ fileImgWaste: arr });
    } else {
      this.setState({ fileImgWaste: list });
    }
  }
  onSelectMaster(e) {
    console.log(e);
    if (e.value.name)
      this.state.fileImgWaste.forEach((list, index) => {
        if (list.name == e.value.name) {
          this.setState({ selectedMaster: e.value });
          this.setState({ selectedMasterIndex: index });
          console.log(index);
        }
      });

    if (e.value.imageName)
      this.state.fileImgWaste.forEach((list, index) => {
        console.log(list);
        if (list.imageName == e.value.imageName) {
          this.setState({ selectedMaster: null });
          this.setState({ selectedMasterIndex: index });
          console.log(index);
        }
      });
  }

  toFormatDateTime(dateTime) {
    return (
      <div>
        <Moment format="DD/MM/YYYY">{dateTime}</Moment>
        <br />
        <Moment format="HH:mm">{dateTime}</Moment>
      </div>
    );
  }
  to_update_date(rowData, column) {
    // let delivery_date = new Date(rowData.delivery_date);
    // let year = delivery_date.getYear();
    // if(year==-1899)
    //     return <div><span></span></div>;
    // else
    return this.toFormatDateTime(rowData.updateDate);
  }

  to_create_date(rowData, column) {
    return this.toFormatDateTime(rowData.createdDate);
  }

  onToggleAccordion() {
    if (this.state.showAddNewsPanel) {
      this.setState({ showAddNewsPanel: false });
    } else {
      this.setState({ showAddNewsPanel: true });
    }
  }

  itemTemplate(item) {
    if (item.index == this.state.filesData.defaultImageIndex) {
      return (
        <div className="p-grid p-nogutter p-justify-center">
          <span style={{ position: "absolute" }}>รูปปก</span>
          <img
            src={`${item.url}`}
            alt={item.gid}
            style={{
              width: "50%",
              display: "block",
              border: "5px solid gold",
              borderRadius: "5px",
              paddingTop: "20px",
            }}
          />
        </div>
      );
    } else
      return (
        <div className="p-grid p-nogutter p-justify-center">
          <img
            src={`${item.url}`}
            alt={item.gid}
            style={{ width: "50%", display: "block" }}
          />
        </div>
      );
  }
  previewTemplate(item) {
    if (item) return <img src={`${item.url}`} style={{ width: "100%" }} />;
  }

  newUpdate(newData) {
    if (newData !== null)
    {
      let replaceOne = newData.replaceAll("&gt;", ">");
      let replaceTwo = replaceOne.replaceAll("&lt;", "<");
      let replaceThree = replaceTwo.replaceAll("<p>", '<p style="margin: 0;">')
      this.setState({ newsBody: replaceThree })
    }
  }

  showAddCertificate() {
    const newDistance = (
      <div className="p-col-12">
        <Accordion
          multiple={this.state.showAddNewsPanel}
          activeIndex={[0]}
          onTabChange={this.onToggleAccordion}
        >
          <AccordionTab header="เพิ่มข่าว">
            <div className="p-grid">
              <div
                className="p-col-12 p-md-1 p-lg-1"
                style={{ alignSelf: "center" }}
              >
                <label>กลุ่มที่เห็น :</label>
              </div>
              <div className="p-col-12 p-md-11 p-lg-11">
                <MultiSelect
                  placeholder="เลือกกลุ่มที่สามารถเห็น"
                  maxSelectedLabels={2}
                  selectedItemsLabel="ทุกกลุ่ม"
                  style={{ width: "100%" }}
                  optionLabel="name"
                  value={this.state.userType}
                  options={this.state.userTypeItem}
                  onChange={(e) => this.setState({ userType: e.value })}
                />
                {/* <Dropdown
                                optionLabel="name"
                                value={this.state.wasteType}
                                options={this.state.wasteTypeItem}
                                onChange={(e)=>this.setState({wasteType: e.value})}
                            /> */}
              </div>
              <div
                className="p-col-12 p-md-1 p-lg-1"
                style={{ alignSelf: "center" }}
              >
                <label>ห้วข่าว :</label>
              </div>
              <div
                className="p-col-12 p-md-11 p-lg-11"
                style={{ alignSelf: "center" }}
              >
                <CEInputText
                  className="input-100"
                  type="text"
                  value={this.state.newsHeader}
                  placeholder="ระบุห้วข้อข่าว"
                  required={true}
                  onChange={(e) =>
                    this.setState({ newsHeader: e.target.value })
                  }
                />
              </div>
              <div className="p-col-12 p-md-1 p-lg-1">
                <label>เนื้อหาข่าว :</label>
              </div>
              <div className="p-col-12 p-md-11 p-lg-11">
                <Editor
                  style={{ height: "320px", width: "100%" }}
                  value={this.state.newsBody}
                  onTextChange={(e) => this.newUpdate(e.htmlValue)}
                />
                {/* <InputTextarea
                  rows={5}
                  style={{ width: "100%" }}
                  value={this.state.newsBody}
                  placeholder="ระบุเนื้อหาข่าว"
                  onChange={(e) => this.setState({ newsBody: e.target.value })}
                /> */}
              </div>
              {/* <div className="p-col-12 p-md-6 p-lg-6"></div> */}

              <div className="p-col-12 p-md-6 p-lg-6">
                <CEFileUploadWithImg
                  name="wastes[]"
                  value={this.state.fileImgWaste}
                  onSelect={(e) => this.onFileWasteChanged(e)}
                  onDelete={(index) => this.onFileDelete(index)}
                  mode="basic"
                  chooseLabel="แนบไฟล์"
                  cancelLabel="ยกเลิก"
                  multiple={true}
                  accept="image/*"
                  maxFileSize={10000000}
                  onSelectCover={(e) => this.onSelectMaster(e)}
                />
              </div>
              {this.state.selectedMasterIndex != null && (
                <div className="p-col-12 p-md-6 p-lg-6">
                  <h4>รูปหน้าปก</h4>
                  {this.state.selectedMaster ? (
                    <img
                      width="100%"
                      src={this.state.selectedMaster.objectURL}
                    />
                  ) : (
                    <img
                      width="100%"
                      src={`${API_ROOT}/user/news/getfile/${
                        this.state.fileImgWaste[this.state.selectedMasterIndex]
                          .imageName
                      }`}
                    />
                  )}
                </div>
              )}

              {this.state.newsCode ? (
                <div className="p-col-12">
                  <Button
                    type="submit"
                    label="บันทึกแก้ไข"
                    icon="fa fa-save"
                    iconPos="left"
                    onClick={this.update}
                  />
                  <Button
                    className="p-button-warning"
                    type="submit"
                    label="ยกเลิก"
                    icon="fa fa-refresh"
                    iconPos="left"
                    onClick={this.clearAllDataInput}
                  />
                </div>
              ) : (
                <div className="p-col-12">
                  <Button
                    type="submit"
                    label="บันทึก"
                    icon="fa fa-save"
                    iconPos="left"
                    onClick={this.onClickAddDistance}
                  />
                </div>
              )}
            </div>
          </AccordionTab>
        </Accordion>
      </div>
    );
    return newDistance;
  }

  showDistanceTable() {
    const responsiveOptions = [
      {
        breakpoint: "1024px",
        numVisible: 5,
      },
      {
        breakpoint: "768px",
        numVisible: 3,
      },
      {
        breakpoint: "560px",
        numVisible: 1,
      },
    ];
    let dialogFooter = (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="บันทึก" icon="pi pi-check" onClick={this.update} />
        <Button
          label="ยกเลิก"
          icon="pi pi-times"
          onClick={() => this.setState({ displayDialog: false })}
          className="p-button-warning"
        />
      </div>
    );
    let deleteDialogFooter = (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="ใช่" icon="pi pi-check" onClick={this.delete} />
        <Button
          label="ไม่ใช่"
          icon="pi pi-times"
          onClick={() => this.setState({ deleteDialog: false })}
          className="p-button-warning"
        />
      </div>
    );

    const distanceTable = (
      <div className="p-col-12">
        <Panel header="รายการข่าว">
          <DataTable
            value={this.state.newsDataList}
            paginatorPosition="bottom"
            paginator={true}
            rows={15}
            rowsPerPageOptions={[5, 10, 15]}
            responsive={true}
            emptyMessage={
              <div style={{ textAlign: "center", color: "red" }}>
                <label>ไม่พบข้อมูล</label>
              </div>
            }
          >
            <Column
              field="newsTitle"
              header="หัวข่าว"
              sortable={true}
              filter={true}
            />
            <Column
              field="userTypeLabel"
              header="กลุ่มที่เห็น"
              sortable={true}
              filter={true}
              className="p-text-center"
            />
            <Column
              field="createDate"
              body={this.to_create_date.bind(this)}
              header="วันที่ลง"
              sortable={true}
              filter={true}
              className="p-text-center"
              style={{ width: "7em" }}
            />
            <Column
              field="updateDate"
              body={this.to_update_date.bind(this)}
              header="แก้ไขล่าสุด"
              sortable={true}
              filter={true}
              className="p-text-center"
              style={{ width: "7em" }}
            />
            <Column
              field="newsDetail"
              body={this.openNewsBody.bind(this)}
              header="เนื้อหาข่าว"
              className="p-text-center"
              style={{ width: "9em" }}
            />
            <Column
              body={this.openFiles.bind(this)}
              header="รูปภาพ"
              className="p-text-center"
              style={{ width: "9em" }}
            />
            <Column
              body={this.actionTemplate.bind(this)}
              header="จัดการ"
              className="p-text-center"
              style={{ width: "8em" }}
            />
          </DataTable>
        </Panel>
        <Dialog
          dismissableMask={true}
          visible={this.state.deleteDialog}
          style={{ width: "50vw" }}
          header="ยืนยันการลบข้อมูล"
          modal={true}
          footer={deleteDialogFooter}
          onHide={() => this.setState({ deleteDialog: false })}
        >
          {this.state.deleteData && (
            <div className="p-grid">
              <div className="p-col-12">
                <span>คุณต้องการจะลบข่าวนี้</span>
              </div>
              <div className="p-col-2">
                <label>หัวข่าว : </label>
              </div>
              <div className="p-col-10">
                <strong>{this.state.deleteData.newsTitle}</strong>
              </div>
              <div className="p-col-2">
                <label>กลุ่มที่เห็น : </label>
              </div>
              <div className="p-col-10">
                <strong>{this.state.deleteData.userTypeLabel}</strong>
              </div>
              <div className="p-col-2">
                <label>วันที่ลง : </label>
              </div>
              <div className="p-col-10">
                <strong>
                  {this.toFormatDateTime(this.state.deleteData.createdDate)}
                </strong>
              </div>
              <div className="p-col-2">
                <label>แก้ไขล่าสุด : </label>
              </div>
              <div className="p-col-10">
                <strong>
                  {this.toFormatDateTime(this.state.deleteData.updateDate)}
                </strong>
              </div>
            </div>
          )}
        </Dialog>
        <Dialog
          visible={this.state.displayDialog}
          style={{ width: "50vw" }}
          header="แก้ไขข้อมูล"
          modal={true}
          footer={dialogFooter}
          onHide={() => this.setState({ displayDialog: false })}
        >
          {this.state.distance && <div className="p-grid p-fluid">hello</div>}
        </Dialog>
        <Dialog
          visible={this.state.newsBodyDialog}
          style={{ width: "50vw" }}
          header="เนื้อหาข่าว"
          modal={true}
          onHide={() => this.setState({ newsBodyDialog: false })}
          dismissableMask={true}
        >
          {this.state.newsbodyData && (
            <div className="p-grid p-fluid">
              <div
                className="p-col-12"
                dangerouslySetInnerHTML={{
                  __html: this.state.newsbodyData.newsDetail,
                }}
              />
            </div>
          )}
        </Dialog>
        <Dialog
          visible={this.state.filesDialog}
          style={{ width: "50vw" }}
          header="รูปภาพ"
          modal={true}
          onHide={() => this.setState({ filesDialog: false })}
          dismissableMask={true}
        >
          {this.state.filesData && this.state.fileImgList.length != 0 && (
            <div className="p-grid">
              <div className="p-col-12">
                <Galleria
                  value={this.state.fileImgList}
                  previewItemTemplate={this.previewTemplate}
                  thumbnailItemTemplate={this.itemTemplate}
                  activeIndex={this.state.activeIndex}
                  onItemChange={(e) => this.setState({ activeIndex: e.index })}
                  numVisible={5}
                  responsiveOptions={responsiveOptions}
                  thumbnailsPosition="top"
                  indicatorItemTemplate={this.indicatorTemplate}
                />
                {/* {this.state.fileImgList.map((item,index)=>
                                        <img width='80px' height='80px' src={`${API_ROOT}/user/news/getfile/${item}`} style={{marginRight: "5px"}}/>
                                    )} */}
              </div>

              {/* <div className="p-col-12">
                                        <h5>รูปหน้าปก</h5>
                                        
                                        <img width='300px' src={`${API_ROOT}/user/news/getfile/${this.state.filesData.defaultImageName}`}/>
                                    </div> */}
            </div>
          )}
        </Dialog>
      </div>
    );
    if (!this.state.showProgress) return distanceTable;
  }

  render() {
    const showAddCertificatePanel = this.showAddCertificate();
    const distanceTablePanel = this.showDistanceTable();
    const showProgress = this.showProgressSpinner();
    console.log(this.state.newsBody)

    return (
      <div
        className="p-grid "
        ref={(section) => {
          this.createNewsPanel = section;
        }}
      >
        <Growl ref={(el) => (this.growl = el)} />
        <div className="p-col-12">
          <h1>ตั้งค่าข่าว</h1>
        </div>
        {showAddCertificatePanel}
        {showProgress}
        {distanceTablePanel}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  productType: state.master.productType,
  product: state.master.product,
});

const mapDispatchToProps = (dispatch) => ({
  masterServiceProps: bindActionCreators(MasterService, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ManageNews);
