import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Moment from 'react-moment';
import 'moment-timezone';
import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { InputText } from 'primereact/components/inputtext/InputText';
import { AutoComplete } from 'primereact/autocomplete';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { ProgressSpinner } from 'primereact/progressspinner';


import * as ServerAPI from './service';
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'

class ManageDonateHistory extends Component {
    static propTypes = {

    }

    static defaultProps = {
    }

    constructor() {
        super();
        this.state = {

            bank_code: 0,
            to_bank_code: 0,
            adminName: '',
            adminUid: '',
            orgName: '',
            orgUid: '',
            memberName: '',
            memberUid: '',
            memberType: 0,
            memberTypeItem: [
                { label: 'เลือกทั้งหมด', value: 0 },
                { label: 'ผู้รักษ์โลก', value: 1 },
                { label: 'ทีมขนส่งซีซาเล้ง', value: 2 },

            ],
            statusItem: [
                { label: 'เลือกทั้งหมด', value: '-1' },
                { label: 'ต้องการถอน', value: '0' },
                { label: 'โอนแล้ว', value: '1' },
                { label: 'บริจาคแล้ว', value: '2' },
            ],
            statusItemSelected: '-1',
            manageTable: [
                1, 2, 3, 4, 5, 6, 7
            ],
            orderDetail: [
                1, 2, 3, 4, 5
            ],
            acceptFileExt: '.jpg,.jpeg,.png,.pdf,.JPG,.JPEG,.PNG,.PDF',
            request_date_start: null,
            request_date_end: null,
            transfer_date_start: null,
            transfer_date_end: null
        };

        this.onAdminNameChanged = this.onAdminNameChanged.bind(this);
        this.onSelectAdminName = this.onSelectAdminName.bind(this);
        this.onMemberNameChanged = this.onMemberNameChanged.bind(this);
        this.onOrgNameChanged = this.onOrgNameChanged.bind(this);
        this.onSelectMemberName = this.onSelectMemberName.bind(this);
        this.onSelectOrgName = this.onSelectOrgName.bind(this);
        this.onChanageBank = this.onChanageBank.bind(this);
        this.onDateRequestStartChange = this.onDateRequestStartChange.bind(this);
        this.onDateRequestEndChange = this.onDateRequestEndChange.bind(this);
        this.onDateTransferStartChange = this.onDateTransferStartChange.bind(this);
        this.onDateTransferEndChange = this.onDateTransferEndChange.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.searchDonateRequest = this.searchDonateRequest.bind(this);
        this.onBasicUpload = this.onBasicUpload.bind(this);
        this.imgButton = this.imgButton.bind(this);
        this.datePicker = this.datePicker.bind(this);
        this.onClickTransfer = this.onClickTransfer.bind(this);
        this.toFormatDateTime = this.toFormatDateTime.bind(this);
    }


    componentDidMount() {
        const { masterServiceProps } = this.props
        masterServiceProps.getBanks();
    }

    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    ExportButton(rowData, column) {
        return <div style={{ textAlign: 'center' }}>
            <Button type="button" icon="fa fa-print" className="p-button-success" ></Button>
        </div>;
    }
    onBasicUpload(e, rowData) {

        var index = this.state.dataTableDonateRequestTable.indexOf(rowData);
        var _dataTable = this.state.dataTableDonateRequestTable;
        _dataTable[index].file = e.files[0];
        this.setState({ dataTableDonateRequestTable: _dataTable });
    }

    onDatePickerChange(e, rowData) {
        var index = -1;
        this.state.dataTableSelection.find(function (value, index) {

            if (value == rowData) {
                console.log(rowData);
                rowData.tranfer_date_calendar = e.value;
                rowData.tranfer_date_iso = e.value.toISOString();
            }
            // this.to_transfer_date(rowData, column);

        });
        if (index != -1)
            this.state.dataTableSelection[index] = Object.assign({}, rowData);
    }
    datePicker(rowData, column) {

        var isChecked = false;
        if (this.state.dataTableSelection) {
            this.state.dataTableSelection.find(function (value, index) {
                //console.log('Visited index ', index, ' with value ', value==rowData); 
                if (value == rowData && rowData.transaction_img == null) {
                    isChecked = true;

                }
                // this.to_transfer_date(rowData, column);

            });

        }
        if (isChecked) {
            return <Calendar readOnlyInput={true} showOnFocus={false} inputStyle={{ width: '100px' }} panelStyle={{ color: 'green' }} touchUI={true} dateFormat="dd/mm/yy" hourFormat="24" showIcon={true} value={rowData.tranfer_date_calendar} onChange={(e) => this.onDatePickerChange(e, rowData)} showTime={true} ></Calendar>;
        } else {
            return this.to_transfer_date(rowData, column);
        }


    }
    imgButton(rowData, column) {

        if (rowData.transaction_img) {
            const path = '/api/account/transfer/' + rowData.transaction_img;
            return <div>
                <Button
                    label="เปิดดูไฟล์"
                    icon="fa fa-eye"
                    iconPos="left"
                    onClick={(e) => window.open(path, "_blank")} />
            </div>;
        }
        else return <div>
            <FileUpload mode="basic" name="transactionImg"
                accept="image/*"
                chooseLabel="แนบไฟล์"
                style={{ fontSize: '10px' }}
                onSelect={(e) => this.onBasicUpload(e, rowData)}
                maxFileSize={10000000} />
        </div>;
    }
    onClickTransfer() {

        //console.log(this.state.dataTableSelection);
        var isNotAttachAllFiles = false;
        var isSomeTransfered = false;
        if (this.state.dataTableSelection) {


            if (this.state.dataTableSelection.length > 0) {
                this.state.dataTableSelection.forEach(element => {
                    if (!element.file) isNotAttachAllFiles = true;
                    if (element.admin_name) isSomeTransfered = true;
                });
            }

            if (isSomeTransfered) {
                this.showWarn("รายการที่เลือกได้แจ้งการโอนแล้ว");

            } else if (isNotAttachAllFiles) {
                this.showWarn("กรุณาแนบไฟล์หลักฐานการโอน");

            } else {


                this.state.dataTableSelection.forEach(obj => {

                    const formData = new FormData();
                    formData.append('transaction_file', obj.file);
                    formData.set('waste_type', obj.waste_type);
                    formData.set('transaction_uid', obj.transaction_uid);
                    formData.set('transaction_date', obj.tranfer_date_iso);
                    ServerAPI.AddTransfer(formData).then((res) => {
                        // console.log("response");
                        if (res) {
                            var index = res.toString().indexOf("Error:");
                            //console.log("find error:" + (index));
                            if (index == -1) {
                                this.showSuccess();
                                this.searchDonateRequest();
                            } else {
                                this.showWarn();
                            }

                        } else {
                            this.showWarn();
                        }
                    }).catch((error) => {
                    })

                });
                this.searchDonateRequest();
                //this.clearSearchInput();
            }
        } else {
            this.showWarn("กรุณาเลือกรายการ");
        }
    }

    suggestOrderID(event) { }
    suggestMemberName(event) { }
    itemOrderIDTemplate(item) {
        return (
            <div className="p-clearfix">
                {item}
            </div>
        );
    }
    itemTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }
    onMemberNameChanged(search_name) {
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwasteAll(search_name).then((res) => {
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName: search_name });
                this.setState({ memberUid: null });
            }
    }
    suggestAdminName(event) { }
    onAdminNameChanged(search_name) {
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ adminName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchadminAll(search_name).then((res) => {
                        this.setState({ adminSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ adminName: search_name });
                this.setState({ adminUid: null });
            }
    }
    onSelectMemberName(item) {
        this.setState({ memberName: item.value.search_name });
        this.setState({ memberUid: item.value.uid });
    }
    onSelectAdminName(item) {
        this.setState({ adminName: item.value.search_name });
        this.setState({ adminUid: item.value.uid });
    }
    onSelectOrgName(item) {
        this.setState({ orgName: item.value.search_name });
        this.setState({ orgUid: item.value.uid });
    }
    onChanageBank(val) {
        this.setState({ selectedBank: val });
        this.setState({ bank_code: val.bank_code });
    }

    onChanageToBank(val) {
        this.setState({ to_selectedBank: val });
        this.setState({ to_bank_code: val.bank_code });
    }

    onDateRequestStartChange(e) {
        if (this.state.request_date_end != null) {
            if (e.value <= this.state.request_date_end) {
                this.setState({ request_date_start: e.value })
            } this.setState({ request_date_start: null })
        } else this.setState({ request_date_start: e.value })
    }
    onDateRequestEndChange(e) {
        if (this.state.request_date_start <= e.value)
            this.setState({ request_date_end: e.value })
        else this.setState({ request_date_end: null })

    }
    onDateTransferStartChange(e) {
        if (this.state.transfer_date_end != null) {
            if (e.value <= this.state.transfer_date_end) {
                this.setState({ transfer_date_start: e.value })
            } this.setState({ transfer_date_start: null })
        } else this.setState({ transfer_date_start: e.value })
    }
    onDateTransferEndChange(e) {
        if (this.state.transfer_date_start <= e.value)
            this.setState({ transfer_date_end: e.value })
        else this.setState({ transfer_date_end: null })
    }

    clearSearchInput() {

        this.setState({ memberUid: null });
        this.setState({ memberName: '' });
        this.setState({ memberType: 0 });
        this.setState({ adminName: '' });
        this.setState({ adminUid: null });
        this.setState({ orgName: '', orgUid: ''})
        this.setState({ request_date_start: null });
        this.setState({ request_date_end: null });
        this.setState({ transfer_date_start: null });
        this.setState({ transfer_date_end: null });
        this.setState({ selectedBank: 0 });
        this.setState({ to_selectedBank: 0 });
        this.setState({ bank_code: 0 });
        this.setState({ to_bank_code: 0 });

        this.setState({ dataTableDonateRequestTable: null });

    }


    searchDonateRequest() {
        this.setState({ dataTableSelection: null });
        this.setState({ showProgress: true })

        var searchModel = {
            user_search_uid: this.state.memberUid,
            user_wasteTypes: this.state.memberType,
            admin_code: this.state.adminUid,
            org_search_uid: this.state.orgUid,
            to_bank_code: this.state.to_bank_code,
        };

        if (this.state.transfer_date_start != null) {
            //Bankok Time zone (+7hrs)
            var transfer_date_start = new Date(this.state.transfer_date_start.getTime() + 7 * 60 * 60000);
            searchModel.transfer_date_start = transfer_date_start.toISOString();
        }
        if (this.state.transfer_date_end != null) {
            //Bankok Time zone (+7hrs)
            var transfer_date_end = new Date(this.state.transfer_date_end.getTime() + 7 * 60 * 60000);
            searchModel.transfer_date_end = transfer_date_end.toISOString();
        }

        ServerAPI.searchDonateRequest(searchModel).then((res) => {

            if (res) {
                var index = res.toString().indexOf("Error:");
                if (index == -1) {
                    if (res.length > 0) {
                        this.setState({ dataTableDonateRequestTable: res })
                        this.setState({ showProgress: false })
                    } else {
                        this.showWarn("ไม่พบข้อมูล");
                        console.log(res);
                        this.setState({ dataTableDonateRequestTable: [] })
                        this.setState({ showProgress: false })
                    }
                    // this.showSuccess();
                    // this.clearAllDataInput();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }

            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }

    //> Auto complete search environmental corporate account
    onSelectOrgName(item) {
        this.setState({ orgName: item.value.search_name });
        this.setState({ orgUid: item.value.uid });
    }


    suggestOrgName(event) {
        console.log(event);
    }

    onOrgNameChanged(search_name) {
        if (typeof search_name != "object")
            if (search_name.trim() != "") {
                this.setState({ orgName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchOrgAll(search_name)
                        .then((res) => {
                            this.setState({ orgSuggestions: res });
                        })
                        .catch((error) => { });
                }
            } else {
                this.setState({ orgName: search_name });
                this.setState({ orgUid: null });
            }
    }
    //> End of Auto complete search environmental corporate account

    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div className="p-col-12 p-md-8 p-lg-9"></div>);
    }

    showSearchPanel() {
        return (
            <div className="p-col-12">
                <div className="content-section implementation">

                    <Accordion multiple={true} activeIndex={[0, 1]}>
                        <AccordionTab header="เงื่อนไขค้นหาเพื่อจัดการรายการบริจาค">
                            <div className="p-grid p-justify-start" >


                                <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                    <label>สมาชิก: </label>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'left' }}>
                                    <AutoComplete
                                        value={this.state.memberName}
                                        suggestions={this.state.memberSuggestions}
                                        onSelect={this.onSelectMemberName}
                                        completeMethod={this.suggestMemberName.bind(this)}
                                        onChange={(e) => this.onMemberNameChanged(e.target.value)}
                                        itemTemplate={this.itemTemplate.bind(this)}
                                        placeholder="ระบุรหัสสมาชิก/ชื่อสมาชิก" />
                                </div>
                                <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                    <label>ประเภทสมาชิก:</label>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'left' }}>
                                    <Dropdown
                                        style={{ width: '210px' }}
                                        value={this.state.memberType}
                                        options={this.state.memberTypeItem}
                                        onChange={(e) => { this.setState({ memberType: e.value }) }}
                                    >
                                    </Dropdown>
                                </div>

                                {/* <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <label>ช่วงวันที่แจ้ง :</label>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.request_date_start} onChange={this.onDateRequestStartChange} ></Calendar>
                                </div>
                                <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <label>ถึงวันที่ :</label>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.request_date_end} onChange={this.onDateRequestEndChange} ></Calendar>
                                </div> */}

                                <div className="p-col-12 p-md-12 p-lg-4" style={{ alignSelf: 'center' }}>

                                </div>


                                <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <label>ช่วงวันที่บริจาค:</label>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.transfer_date_start} onChange={this.onDateTransferStartChange} ></Calendar>
                                </div>
                                <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <label>ถึงวันที่:</label>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.transfer_date_end} onChange={this.onDateTransferEndChange} ></Calendar>
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-4" style={{ alignSelf: 'center' }}>

                                </div>
                                <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <label>ชื่อองค์กรการกุศล:</label>
                                </div>
                                <div className="p-col-12  p-md-4 p-lg-3">
                                    <AutoComplete
                                        value={this.state.orgName}
                                        suggestions={this.state.orgSuggestions}
                                        onSelect={this.onSelectOrgName}
                                        completeMethod={this.suggestOrgName.bind(this)}
                                        onChange={(e) => this.onOrgNameChanged(e.target.value)}
                                        itemTemplate={this.itemTemplate.bind(this)}
                                        placeholder="ระบุชื่อองค์กรการกุศล" />
                                </div>

                                <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <label>ธนาคารที่บริจาค:</label>
                                </div>
                                <div className="p-col-12  p-md-4 p-lg-3">
                                    <Dropdown
                                        style={{ width: '210px' }}
                                        optionLabel="bank_name"
                                        name="bank_code"
                                        value={this.state.to_selectedBank}
                                        options={this.props.to_banks}
                                        onChange={(e) => this.onChanageToBank(e.target.value)}
                                        placeholder="เลือกธนาคาร" />
                                </div>


                                <div className="p-col-12 p-md-12 p-lg-4" style={{ alignSelf: 'center' }}>

                                </div>

                                <div className="p-col-12 p-md-12 p-lg-8" style={{ alignSelf: 'center' }}>

                                </div>

                                <div className="p-col-12">
                                    <br />
                                    <Button
                                        style={{ marginRight: "20px" }}
                                        label="ค้นหา"
                                        icon="fa fa-search"
                                        iconPos="left"
                                        onClick={this.searchDonateRequest} />

                                    <Button
                                        style={{ backgroundColor: '#f0ad4e' }}
                                        type="submit"
                                        label="ยกเลิก"
                                        onClick={this.clearSearchInput}
                                    />
                                </div>
                            </div>


                        </AccordionTab>


                    </Accordion>
                </div>
            </div>

        );
    }
    toFormatDateTime(dateTime) {
        return <div><Moment format="DD/MM/YYYY">
            {dateTime}
        </Moment><br /><Moment format="HH:mm">
                {dateTime}
            </Moment></div>;
    }
    to_transfer_date(rowData, column) {

        let tranfer_date = new Date(rowData.tranfer_date);
        let year = tranfer_date.getYear();

        if (year == -1899)
            return <div><span></span></div>;
        else
            return this.toFormatDateTime(rowData.tranfer_date);
    }

    to_requested_date(rowData, column) {
        return this.toFormatDateTime(rowData.requested_date);
    }
    showResult() {
        if (this.state.showProgress) return <div></div>;

        var header = <div className="p-clearfix" style={{ 'lineHeight': '1.87em' }}>
            {/* <Button onClick={this.onClickTransfer} label="โอนเงินให้แล้ว" icon="fa fa-save" style={{ 'float': 'center' }} /> */}

        </div>;




        return <div className="p-grid">




            <div className="p-col-12">
                <Panel header="ประวัติการบริจาค" >
                    <DataTable value={this.state.dataTableDonateRequestTable} paginatorPosition="bottom" paginator={true} rows={15}
                        responsive={true} selection={this.state.dataTableSelection} onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                        scrollable={true} rowsPerPageOptions={[5, 10, 15]}
                    >
                        {/* <Column selectionMode="multiple" style={{ width: '3em' }} />
                        <Column body={this.imgButton} style={{ textAlign: 'center', width: '9em' }} /> */}
                        <Column field="transfer_date" body={this.datePicker} header="วันที่บริจาค" sortable={false} filter={true} className="p-text-center" style={{ width: '8em' }} />
                        <Column field="waste_name" header="ชื่อสมาชิก" sortable={true} filter={true} />
                        {/* <Column field="request_date" body={this.to_requested_date.bind(this)} header="วันที่แจ้ง" sortable={false} style={{ textAlign: 'center' }} /> */}
                        <Column field="donate_thb" header="จำนวนเงิน" sortable={true} filter={true} className="p-text-right" style={{ width: '8em' }} />
                        <Column field="to_org_name" header="ชื่อองค์กรการกุศล" sortable={true} filter={true} />
                        <Column field="to_bank_account_name" header="ชื่อบัญชีบริจาค" sortable={true} filter={true} />
                        <Column field="to_bank_account_no" header="เลขบัญชีบริจาค" sortable={true} filter={true} />
                        <Column field="to_bank_name" header="ธนาคารบริจาค" sortable={true} filter={true} />
                    </DataTable>
                </Panel>
            </div>
        </div>
    }
    render() {

        const searchPanel = this.showSearchPanel();
        const showProgress = this.showProgressSpinner();
        const showResult = this.showResult();
        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} />
                {searchPanel}
                {showProgress}
                {showResult}
            </div>
        );


    }
}



const mapStateToProps = (state) => ({
    banks: state.master.banks,
    to_banks: state.master.banks,
})

const mapDispatchToProps = dispatch => ({

    masterServiceProps: bindActionCreators(MasterService, dispatch)

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ManageDonateHistory)