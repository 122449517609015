import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Moment from 'react-moment';
import 'moment-timezone';
import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { InputText } from 'primereact/components/inputtext/InputText';
import { AutoComplete } from 'primereact/autocomplete';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { ProgressSpinner } from 'primereact/progressspinner';
import { ExportExcel } from '../ExportExcel/ExportExcel';



import * as ServerAPI from './service';
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'

class ReportMoneyTransferContainter extends Component {
    static propTypes = {

    }

    static defaultProps = {
    }

    constructor() {
        super();
        this.state = {

            bank_code: 0,
            adminName: '',
            adminUid: '',
            memberName: '',
            memberUid: '',
            memberType: 0,
            memberTypeItem: [
                { label: 'เลือกทั้งหมด', value: 0 },
                { label: 'ผู้รักษ์โลก', value: 1 },
                { label: 'ทีมขนส่งซีซาเล้ง', value: 2 },

            ],
            statusItem: [
                { label: 'เลือกทั้งหมด', value: '-1' },
                { label: 'ต้องการถอน', value: '0' },
                { label: 'โอนแล้ว', value: '1' },
                { label: 'บริจาคแล้ว', value: '2' },
            ],
            statusItemSelected: '-1',
            manageTable: [
                1, 2, 3, 4, 5, 6, 7
            ],
            orderDetail: [
                1, 2, 3, 4, 5
            ],
            acceptFileExt: '.jpg,.jpeg,.png,.pdf,.JPG,.JPEG,.PNG,.PDF',
            request_date_start: null,
            request_date_end: null,
            transfer_date_start: null,
            transfer_date_end: null
        };

        this.onAdminNameChanged = this.onAdminNameChanged.bind(this);
        this.onSelectAdminName = this.onSelectAdminName.bind(this);
        this.onMemberNameChanged = this.onMemberNameChanged.bind(this);
        this.onSelectMemberName = this.onSelectMemberName.bind(this);
        this.onChanageBank = this.onChanageBank.bind(this);
        this.onDateRequestStartChange = this.onDateRequestStartChange.bind(this);
        this.onDateRequestEndChange = this.onDateRequestEndChange.bind(this);
        this.onDateTransferStartChange = this.onDateTransferStartChange.bind(this);
        this.onDateTransferEndChange = this.onDateTransferEndChange.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.searchWithDrawRequest = this.searchWithDrawRequest.bind(this);
        this.onClickTransfer = this.onClickTransfer.bind(this);
        this.toFormatDateTime = this.toFormatDateTime.bind(this);
    }


    componentDidMount() {
        const { masterServiceProps } = this.props
        masterServiceProps.getBanks();
    }

    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    ExportButton(rowData, column) {
        return <div style={{ textAlign: 'center' }}>
            <Button type="button" icon="fa fa-print" className="p-button-success" ></Button>
        </div>;
    }
   
   
    
    onClickTransfer(){
        console.log(this.state.dataTableSelection);
        var isNotAttachAllFiles = false;
        var isSomeTransfered = false;
        if(this.state.dataTableSelection){

        
        if(this.state.dataTableSelection.length>0){
            this.state.dataTableSelection.forEach(element => {
                if(!element.file) isNotAttachAllFiles= true;
                if(element.admin_name) isSomeTransfered = true;
            });
        }
        if(isSomeTransfered){
            this.showWarn("รายการที่เลือกได้แจ้งการโอนแล้ว");
        }else if(isNotAttachAllFiles){
            this.showWarn("กรุณาแนบไฟล์หลักฐานการโอน");
        }else{
            this.state.dataTableSelection.forEach(obj => {
               
                const formData = new FormData();
                formData.append('transaction_file', obj.file);
                formData.set('waste_type', obj.waste_type);
                formData.set('transaction_uid', obj.transaction_uid);

                ServerAPI.AddTransfer(formData).then((res) => {
                    console.log("response");
                    if (res) {
                        var index = res.toString().indexOf("Error:");
                        console.log("find error:" + (index));
                        if (index == -1) {
                            this.showSuccess();
                          
                        } else {
                            this.showWarn();
                        }
        
                    } else {
                        this.showWarn();
                    }
                }).catch((error) => {
                })
        
            });

            //this.clearSearchInput();
        }
        }else{
            this.showWarn("กรุณาเลือกรายการ");
        }
    }
    
    suggestOrderID(event) { }
    suggestMemberName(event) { }
    itemOrderIDTemplate(item) {
        return (
            <div className="p-clearfix">
                {item}
            </div>
        );
    }
    itemTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }
    onMemberNameChanged(search_name) {
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwasteAll(search_name).then((res) => {
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName: search_name });
                this.setState({ memberUid: null });
            }
    }
    suggestAdminName(event) { }
    onAdminNameChanged(search_name) {
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ adminName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchadminAll(search_name).then((res) => {
                        this.setState({ adminSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ adminName: search_name });
                this.setState({ adminUid: null });
            }
    }
    onSelectMemberName(item) {
        this.setState({ memberName: item.value.search_name });
        this.setState({ memberUid: item.value.uid });
    }
    onSelectAdminName(item) {
        this.setState({ adminName: item.value.search_name });
        this.setState({ adminUid: item.value.uid });
    }
    onChanageBank(val) {
        this.setState({ selectedBank: val });
        this.setState({ bank_code: val.bank_code });
    }

    onDateRequestStartChange(e) {
        if (this.state.request_date_end != null) {
            if (e.value <= this.state.request_date_end) {
                this.setState({ request_date_start: e.value })
            } this.setState({ request_date_start: null })
        } else this.setState({ request_date_start: e.value })
    }
    onDateRequestEndChange(e) {
        if (this.state.request_date_start <= e.value)
            this.setState({ request_date_end: e.value })
        else this.setState({ request_date_end: null })

    }
    onDateTransferStartChange(e) {
        if (this.state.transfer_date_end != null) {
            if (e.value <= this.state.transfer_date_end) {
                this.setState({ transfer_date_start: e.value })
            } this.setState({ transfer_date_start: null })
        } else this.setState({ transfer_date_start: e.value })
    }
    onDateTransferEndChange(e) {
        if (this.state.transfer_date_start <= e.value)
            this.setState({ transfer_date_end: e.value })
        else this.setState({ transfer_date_end: null })
    }

    clearSearchInput() {

        this.setState({ memberUid: null });
        this.setState({ memberName: '' });
        this.setState({ memberType: 0 });
        this.setState({ adminName: '' });
        this.setState({ adminUid: null });
        this.setState({ request_date_start: null });
        this.setState({ request_date_end: null });
        this.setState({ transfer_date_start: null });
        this.setState({ transfer_date_end: null });
        this.setState({ selectedBank: 0 });

        this.setState({ dataTableWithDrawRequestTable: null });

    }


    searchWithDrawRequest() {
        this.setState({ showProgress: true })

        var searchModel = {
            user_search_name: this.state.memberUid,
            user_wasteTypes: this.state.memberType,
            admin_code: this.state.adminUid,
            bank_code: this.state.bank_code,
            withdrawal_status_code: this.state.statusItemSelected,
        };

        if (this.state.request_date_start != null) {
            //Bankok Time zone (+7hrs)
            var request_date_start = new Date(this.state.request_date_start.getTime() + 7 * 60 * 60000);
            searchModel.request_date_start = request_date_start.toISOString();
        }
        if (this.state.request_date_end != null) {
            //Bankok Time zone (+7hrs)
            var request_date_end = new Date(this.state.request_date_end.getTime() + 7 * 60 * 60000);
            searchModel.request_date_end = request_date_end.toISOString();
        }

        if (this.state.transfer_date_start != null) {
            //Bankok Time zone (+7hrs)
            var transfer_date_start = new Date(this.state.transfer_date_start.getTime() + 7 * 60 * 60000);
            searchModel.transfer_date_start = transfer_date_start.toISOString();
        }
        if (this.state.transfer_date_end != null) {
            //Bankok Time zone (+7hrs)
            var transfer_date_end = new Date(this.state.transfer_date_end.getTime() + 7 * 60 * 60000);
            searchModel.transfer_date_end = transfer_date_end.toISOString();
        }
        
        ServerAPI.searchWithDrawRequest(searchModel).then((res) => {
            
            if (res) {
                var index = res.toString().indexOf("Error:");
                
                if (index == -1) {
                    
                    if (res.length > 0) {
                        this.setState({ dataTableWithDrawRequestTable: res })
                        this.setState({ showProgress: false })
                    } else {
                        this.showWarn("ไม่พบข้อมูล");
                        this.setState({ dataTableWithDrawRequestTable: [] })
                        this.setState({ showProgress: false })
                    }
                    // this.showSuccess();
                    // this.clearAllDataInput();
                } else {
                    this.showWarn();
                }

            } else {
                this.showWarn();
            }
        }).catch((error) => {
        })
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div className="p-col-12 p-md-8 p-lg-9"></div>);
    }
    toFormatDateTime(dateTime){
        return <div><Moment format="DD/MM/YYYY">
        {dateTime}
            </Moment><br/><Moment format="HH:mm">
                {dateTime}
            </Moment></div>; 
    }
    to_transfer_date(rowData, column){
       
        let tranfer_date = new Date(rowData.tranfer_date);
        let year = tranfer_date.getYear();

        if(year==-1899)
            return <div><span></span></div>;
        else 
            return this.toFormatDateTime(rowData.tranfer_date);
    }
   
    to_requested_date(rowData, column){
        return this.toFormatDateTime(rowData.requested_date);
    }

    showSearchPanel(){
        return (
            <div className="p-col-12">
                <div className="content-section implementation">

                    <Accordion multiple={true} activeIndex={[0, 1]}>
                        <AccordionTab header="เงื่อนไขค้นหาเพื่อจัดการรายการโอนเงิน">
                            <div className="p-grid p-justify-start" >

                               
                                    <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                        <label>สมาชิก : </label>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'left' }}>
                                        <AutoComplete
                                            value={this.state.memberName}
                                            suggestions={this.state.memberSuggestions}
                                            onSelect={this.onSelectMemberName}
                                            completeMethod={this.suggestMemberName.bind(this)}
                                            onChange={(e) => this.onMemberNameChanged(e.target.value)}
                                            itemTemplate={this.itemTemplate.bind(this)}
                                            placeholder="ระบุรหัสสมาชิก/ชื่อสมาชิก" />
                                    </div>
                                    <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                        <label>ประเภทสมาชิก :</label>
                                    </div>
                                    <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'left' }}>
                                        <Dropdown
                                            value={this.state.memberType}
                                            options={this.state.memberTypeItem}
                                            onChange={(e) => { this.setState({ memberType: e.value }) }}
                                        >
                                        </Dropdown>
                                    </div>
                                    <div className="p-col-12 p-md-12 p-lg-4" style={{ alignSelf: 'center' }}>
                                        
                                    </div>
                                

                                <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <label>ช่วงวันที่แจ้ง :</label>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.request_date_start} onChange={this.onDateRequestStartChange} ></Calendar>
                                </div>
                                <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <label>ถึงวันที่ :</label>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.request_date_end} onChange={this.onDateRequestEndChange} ></Calendar>
                                </div>

                                <div className="p-col-12 p-md-12 p-lg-4" style={{ alignSelf: 'center' }}>
                                        
                                        </div>


                                <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <label>ช่วงวันที่โอน :</label>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.transfer_date_start} onChange={this.onDateTransferStartChange} ></Calendar>
                                </div>
                                <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <label>ถึงวันที่ :</label>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.transfer_date_end} onChange={this.onDateTransferEndChange} ></Calendar>
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-4" style={{ alignSelf: 'center' }}>
                                        
                                </div>
                                <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <label>ธนาคาร:</label>
                                </div>
                                <div className="p-col-12  p-md-4 p-lg-3">
                                    
                                    <Dropdown style={{ width: '100%' }}
                                        optionLabel="bank_name"
                                        name="bank_code"
                                        value={this.state.selectedBank}
                                        options={this.props.banks}
                                        onChange={(e) => this.onChanageBank(e.target.value)}
                                        placeholder="เลือกธนาคาร" />
                                </div>

                                <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                    <label>ผู้ดูแล : </label>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                    <AutoComplete
                                        value={this.state.adminName}
                                        suggestions={this.state.adminSuggestions}
                                        onSelect={this.onSelectAdminName}
                                        completeMethod={this.suggestAdminName.bind(this)}
                                        onChange={(e) => this.onAdminNameChanged(e.target.value)}
                                        itemTemplate={this.itemTemplate.bind(this)}
                                        placeholder="ระบุรหัสผู้ดูแล/ชื่อผู้ดูแล" />
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-4" style={{ alignSelf: 'center' }}>
                                        
                                </div>
                                <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                    <label>สถานะ :</label>
                                </div>
                                <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'left' }}>
                                    <Dropdown
                                        value={this.state.statusItemSelected}
                                        options={this.state.statusItem}
                                        onChange={(e) => { this.setState({ statusItemSelected: e.value }) }}
                                        
                                    >
                                    </Dropdown>
                                </div>
                                <div className="p-col-12 p-md-12 p-lg-8" style={{ alignSelf: 'center' }}>

                                </div>

                                <div className="p-col-12">
                                    <Button
                                        style={{marginRight:"20px"}}
                                        label="ค้นหา"
                                        icon="fa fa-search"
                                        iconPos="left"
                                        onClick={this.searchWithDrawRequest} />
                                    <Button
                                        style={{ backgroundColor: '#f0ad4e' }}
                                        type="submit"
                                        label="ยกเลิก"
                                        onClick={this.clearSearchInput}
                                    />
                                </div>
                            </div>


                        </AccordionTab>


                    </Accordion>
                </div>
            </div>

        );
    }

    dateTemplate(rowData, column){
       
        let tranfer_date = new Date(rowData.tranfer_date);
        let year = tranfer_date.getYear();

        if(year==-1899)
            return <div><span></span></div>;
        else 
            return <div><span>{rowData.tranfer_date}</span></div>;
    }
    showResult(){
        let sum =0;
        if(this.state.dataTableWithDrawRequestTable)
        if(this.state.dataTableWithDrawRequestTable.length>0) {
           this.state.dataTableWithDrawRequestTable.map( function(a){ 
                let a_sale_price  = a.withdrawal_thb;
                 sum += a_sale_price;
            });
        }
        let footerGroup = <ColumnGroup>
                            <Row>
                                <Column footer="รวม:" colSpan={3} style={{ textAlign: 'right'}}/>
                                <Column footer={sum.toFixed(2)+" บาท"} style={{ textAlign: 'right'}}/>
                            </Row>
                         </ColumnGroup>;

        if(this.state.showProgress) return <div></div>;

        var tmp = []
        const Objmaster = this.state.dataTableWithDrawRequestTable;
        const total = {
            withdrawal_thb:0,
        };
        if(Objmaster)
        for (let i = 0; i < Objmaster.length; i++) {
            const obj = {
               "ชื่อสมาชิก": Objmaster[i].waste_name,
               "วันที่แจ้ง": Objmaster[i].requested_date?(new Date(Objmaster[i].requested_date)).toLocaleDateString():'',
               "วันที่โอน": Objmaster[i].tranfer_date? ( (new Date(Objmaster[i].tranfer_date).getYear()==-1899)? '':(new Date(Objmaster[i].tranfer_date)).toLocaleDateString()):'',
               "จำนวนเงิน (บาท)":Objmaster[i].withdrawal_thb,
               "ชื่อบัญชี": Objmaster[i].bank_account_name,
               "เลขที่บัญชี": Objmaster[i].bank_account_no,
               "ธนาคาร": Objmaster[i].bank_name,
               "Prompt pay": Objmaster[i].bank_promptpay,
               "สถานะ": Objmaster[i].withdrawal_status,
               "ผู้ดูแล": Objmaster[i].admin_name,
            }
            console.log(Objmaster[i].request_date)
            console.log(Objmaster[i].transfer_date)
            total.withdrawal_thb += Number(Objmaster[i].withdrawal_thb);
            tmp.push(obj);
            
        }
        //The last record
        const obj = {
            "จำนวนเงิน (บาท)":total.withdrawal_thb,
         }
         tmp.push(obj);
         console.log(tmp);
         //End the last record
        var exportExcel =
            <div style={{ textAlign: 'left' }}>
            
                <ExportExcel data={tmp} fileName="รายงานการโอนเงิน" />
            </div>;      
        return <div className="p-grid">
            <div className="p-col-12">
                <Panel header="ประวัติการเบิกถอน" >
                    <DataTable header={exportExcel} value={this.state.dataTableWithDrawRequestTable} paginatorPosition="bottom" paginator={true} rows={15}
                        responsive={true} selection={this.state.dataTableSelection} onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                        scrollable={true} rowsPerPageOptions={[5, 10, 15]} 
                        footerColumnGroup={footerGroup}>
                        <Column field="waste_name" header="ชื่อสมาชิก" sortable={true} filter={true} />
                        <Column field="requested_date" body={this.to_requested_date.bind(this)} header="วันที่แจ้ง" sortable={false} filter={true} className="p-text-center"/>
                        <Column field="tranfer_date" body={this.to_transfer_date.bind(this)} header="วันที่โอน" sortable={false} filter={true} className="p-text-center"/>
                        <Column field="withdrawal_thb" header="จำนวนเงิน (บาท)" sortable={true} filter={true} style={{ textAlign: 'right' }}/>
                        <Column field="bank_account_name" header="ชื่อบัญชี" sortable={true} filter={true} />
                        <Column field="bank_account_no" header="เลขที่บัญชี" sortable={true} filter={true} />
                        <Column field="bank_name" header="ธนาคาร" sortable={true} filter={true} />
                        <Column field="bank_promptpay" header="Prompt pay" sortable={true} filter={true} />
                        <Column field="withdrawal_status" header="สถานะ" sortable={true} filter={true} className="p-text-center"/>
                        <Column field="admin_name" header="ผู้ดูแล" sortable={true} filter={true} />
                    </DataTable>
                </Panel>
            </div>
        </div>
    }
    render() {

        const searchPanel = this.showSearchPanel();       
        const showProgress = this.showProgressSpinner();
        const showResult = this.showResult();
        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} />
                {searchPanel}
                {showProgress}
                {showResult}
            </div>
        );


    }
}



const mapStateToProps = (state) => ({
    banks: state.master.banks,
})

const mapDispatchToProps = dispatch => ({

    masterServiceProps: bindActionCreators(MasterService, dispatch)

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ReportMoneyTransferContainter)