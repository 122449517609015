import { LOGIN,LOGOUT,USER_TYPE } from '../service/actionType'

const initialState = {
  accessToken:null,
  userType:null, 
  isFailed: false, 
  isLoading: false,
  logedIn:false
}
export function authentication(state = initialState, action) {
  switch (action.type) {
    case LOGIN.REQUEST:
      return { ...state, isFailed: false, accessToken: null, isLoading: true };
    case LOGIN.SUCCESS:
      return { ...state, isFailed: false, accessToken: action.payload, isLoading: false };
    case LOGIN.FAILURE:
      return { ...state, isFailed: true, accessToken: action.payload, isLoading: false };
    case LOGOUT.SUCCESS:
      return { ...state, accessToken:null,userType:null, isFailed: false,isLoading: false,logedIn:false}; 
      case USER_TYPE.REQUEST:
      return { ...state, isFailed: false, userType: null, isLoading: true,logedIn:false };
    case USER_TYPE.SUCCESS:
      return { ...state, isFailed: false, userType: action.payload, isLoading: false ,logedIn:true};
    case USER_TYPE.FAILURE:
      return { ...state, isFailed: true, userType: action.payload, isLoading: false ,logedIn:false};
    default:
      return state
  }
}