import React, { Component } from 'react';
import { Button } from 'primereact/button';
export const WastegenOverviews=({summaryBalance,successBalance,noSuccessBalance, carbonCreditSummary, donateSummary})=> {

        return (
            <div className="p-grid ">
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="p-grid overview-box overview-box-1">
                        <div className="overview-box-title">
                            <i className="fa fa-inbox"></i>
                            <span>ยอดสะสม</span>
                        </div>
                        <div className="overview-box-count">{summaryBalance}</div>
                        <div className="overview-box-stats">บาท</div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="p-grid overview-box overview-box-2">
                        <div className="overview-box-title">
                            <i className="fa fa-inbox"></i>
                            <span>ยอดเงินเบิกแล้ว</span>
                        </div>
                        <div className="overview-box-count">{successBalance}</div>
                        <div className="overview-box-stats">{`บาท (บริจาค ${donateSummary} บาท)`}</div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="p-grid overview-box overview-box-3">
                        <div className="overview-box-title">
                            <i className="fa fa-folder"></i>
                            <span>ยอดเงินค้างเบิก</span>
                        </div>
                        <div className="overview-box-count">{noSuccessBalance}</div>
                        <div className="overview-box-stats">บาท</div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                
                    <div className="p-grid overview-box overview-box-6">
                        <div className="overview-box-title">
                            <i className="fa fa-leaf"></i>
                            <span>ระดับความสำเร็จ</span>
                        </div>
                        <div className="overview-box-count">{carbonCreditSummary}</div>
                        <div className="overview-box-stats">คาร์บอนเครดิต</div>

                        {/* <div className="p-col-12" style={{textAlign: 'end',padding: '0px'}}>
                            <a href="#/wastegen/certificate">
                                <div style={{color: 'white'}}><u>รับเหรียญ</u></div>
                            </a>
                        </div> */}
                    </div>
                </div>
            </div>
        )
    
}