import {
    ADMIN_ANNOUNCE_ADD,
    ADMIN_DASH_SUMMARY_AREA,
    ADMIN_DASH_SUMMARY_WITHDRAW,
    ADMIN_DASH_SUMMARY_AREA_SEARCH,
    ADMIN_USER_LIST,
    ADMIN_CERT_TEMPLATE_LIST,
    ADMIN_CERT_RESULT_LIST,
    ADMIN_USER_LIST_CHAT,
    LOAD_MORE_ADMIN_USER_LIST,
    ADD_NEW_ADMIN_USER_LIST,
} from '../service/actionType'

const initialState = {
    data: null,
    isFailed: false,
    isLoading: false
}

const initialUserListState = {
    data: [],
    isFailed: false,
    isLoading: false,
}

export function adminAnnounceAdd(state = initialState, action) {
    switch (action.type) {
        case ADMIN_ANNOUNCE_ADD.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case ADMIN_ANNOUNCE_ADD.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case ADMIN_ANNOUNCE_ADD.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function getAdminDashSummaryArea(state = initialState, action) {
    switch (action.type) {
        case ADMIN_DASH_SUMMARY_AREA.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case ADMIN_DASH_SUMMARY_AREA.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case ADMIN_DASH_SUMMARY_AREA.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function getAdminDashSummaryAreaSearch(state = initialState, action) {
    switch (action.type) {
        case ADMIN_DASH_SUMMARY_AREA_SEARCH.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case ADMIN_DASH_SUMMARY_AREA_SEARCH.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case ADMIN_DASH_SUMMARY_AREA_SEARCH.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function getAdminDashSummaryWithdraw(state = initialState, action) {
    switch (action.type) {
        case ADMIN_DASH_SUMMARY_WITHDRAW.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case ADMIN_DASH_SUMMARY_WITHDRAW.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case ADMIN_DASH_SUMMARY_WITHDRAW.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function getUsersAllForManagement(state = initialState, action) {
    switch (action.type) {
        case ADMIN_USER_LIST.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case ADMIN_USER_LIST.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case ADMIN_USER_LIST.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function adminCertTemplate(state = initialState, action) {
    switch (action.type) {
        case ADMIN_CERT_TEMPLATE_LIST.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case ADMIN_CERT_TEMPLATE_LIST.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case ADMIN_CERT_TEMPLATE_LIST.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function adminCertResult(state = initialState, action) {
    switch (action.type) {
        case ADMIN_CERT_RESULT_LIST.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case ADMIN_CERT_RESULT_LIST.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case ADMIN_CERT_RESULT_LIST.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function getUsersAll(state = initialUserListState, action) {
    switch (action.type) {
      case ADMIN_USER_LIST_CHAT.REQUEST:
        return { ...state, data: initialUserListState.data, isFailed: false, isLoading: true }
      case LOAD_MORE_ADMIN_USER_LIST.REQUEST:
        return { ...state, isFailed: false, isLoading: true }
      case ADMIN_USER_LIST_CHAT.FAILURE:
      case LOAD_MORE_ADMIN_USER_LIST.FAILURE:
        return { ...state, isFailed: true, isLoading: false }
      case ADMIN_USER_LIST_CHAT.SUCCESS: {
        return {
          ...state,
          isFailed: false,
          isLoading: false,
          data: action.payload,
        }
      }
      case LOAD_MORE_ADMIN_USER_LIST.SUCCESS: {
        return {
          ...state,
          isFailed: false,
          isLoading: false,
          data: [...state.data, ...action.payload],
        }
      }
      case ADD_NEW_ADMIN_USER_LIST: {
        const newUsers = [action.message, ...state.data.messages]
        return { ...state, data: { ...state.data, newUsers } }
      }
      default:
        return state
    }
  }