
import { API_ROOT } from '../constants/api'
import axios from './axios'

import {
  WASTEPRO_ORDER_SEARCH
  , WASTEPRO_DASH_PAYMENT
  , WASTEPRO_DASH_PRODUCT
  , WASTEPRO_DASH_SUMMARY_OVERDUE
  , WASTEPRO_DASH_SUMMARY_PAID
  , WASTEPRO_DASH_SUMMARY_PRODUCTS,
  WASTEPRO_DASH_ORDER_STAT
} from './actionType'


export const wasteproOrderSearch = (criteria) => (dispatch) => {
  dispatch({ type: WASTEPRO_ORDER_SEARCH.REQUEST, isLoading: true })
  return axios({
    method: 'post',
    url: `${API_ROOT}/wastepro/order/search`,
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    },
    data: JSON.stringify(criteria)
  }).then((res) => {
    dispatch({ type: WASTEPRO_ORDER_SEARCH.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTEPRO_ORDER_SEARCH.FAILURE, payload: error.message })
  })
}

export const getWasteproDashPayment = () => (dispatch) => {
  dispatch({ type: WASTEPRO_DASH_PAYMENT.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastepro/dashboard/payment`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  }).then(res => {
    if (res.status == 200) {
      return res
    }
    throw Error(res.status)
  }).then((res) => {
    dispatch({ type: WASTEPRO_DASH_PAYMENT.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTEPRO_DASH_PAYMENT.FAILURE, error })
  })
}

export const getWasteproDashProduct = () => (dispatch) => {
  dispatch({ type: WASTEPRO_DASH_PRODUCT.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastepro/dashboard/product`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  }).then(res => {
    if (res.status == 200) {
      return res
    }
    throw Error(res.status)
  }).then((res) => {
    dispatch({ type: WASTEPRO_DASH_PRODUCT.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTEPRO_DASH_PRODUCT.FAILURE, error })
  })
}

export const getWasteproDashSummary = (invoice_status) => (dispatch) => {
  switch (invoice_status) {
    case 2:
      dispatch({ type: WASTEPRO_DASH_SUMMARY_OVERDUE.REQUEST, isLoading: true })
      return axios({
        method: 'get',
        url: `${API_ROOT}/wastepro/dashboard/summary/${invoice_status}`,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
      }).then(res => {
        if (res.status == 200) {
          return res
        }
        throw Error(res.status)
      }).then((res) => {
        dispatch({ type: WASTEPRO_DASH_SUMMARY_OVERDUE.SUCCESS, payload: res.data })
      }).catch((error) => {
        dispatch({ type: WASTEPRO_DASH_SUMMARY_OVERDUE.FAILURE, error })
      })
      break;
    case 88:
      dispatch({ type: WASTEPRO_DASH_SUMMARY_PAID.REQUEST, isLoading: true })
      return axios({
        method: 'get',
        url: `${API_ROOT}/wastepro/dashboard/summary/${invoice_status}`,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
      }).then(res => {
        if (res.status == 200) {
          return res
        }
        throw Error(res.status)
      }).then((res) => {
        dispatch({ type: WASTEPRO_DASH_SUMMARY_PAID.SUCCESS, payload: res.data })
      }).catch((error) => {
        dispatch({ type: WASTEPRO_DASH_SUMMARY_PAID.FAILURE, error })
      })
      break;
    case 0:
      dispatch({ type: WASTEPRO_DASH_SUMMARY_PRODUCTS.REQUEST, isLoading: true })
      return axios({
        method: 'get',
        url: `${API_ROOT}/wastepro/dashboard/summary/${invoice_status}`,
        headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
      }).then(res => {
        if (res.status == 200) {
          return res
        }
        throw Error(res.status)
      }).then((res) => {
        dispatch({ type: WASTEPRO_DASH_SUMMARY_PRODUCTS.SUCCESS, payload: res.data })
      }).catch((error) => {
        dispatch({ type: WASTEPRO_DASH_SUMMARY_PRODUCTS.FAILURE, error })
      })
      break;
  }


}

export const getWasteproDashOrderStat = () => (dispatch) => {
  dispatch({ type: WASTEPRO_DASH_ORDER_STAT.REQUEST, isLoading: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/wastepro/dashboard/orderstat`,
    headers: { Authorization: 'Bearer ' + localStorage.getItem('access-token') }
  }).then(res => {
    if (res.status == 200) {
      return res
    }
    throw Error(res.status)
  }).then((res) => {
    dispatch({ type: WASTEPRO_DASH_ORDER_STAT.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: WASTEPRO_DASH_ORDER_STAT.FAILURE, error })
  })
}