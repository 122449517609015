import React, { Component } from "react";
import { connect } from "react-redux";
import { ScrollPanel } from "primereact/scrollpanel";

class DeleteAccount extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="login-body">
        <div className="p-grid">
          <div className="p-col-12">
            <div
              className="card card-w-title"
              style={{ marginRight: "10%", marginTop: "2%" }}
            >
              <div className="login-panel-content">
                <div className="p-grid">
                  <div className="p-col-9 p-md-10 p-lg-10">
                    <h2 className="welcome-text">ขั้นตอนการลบบัญชีผู้ใช้ของ ซีซาเล้ง</h2>
                  </div>

                  <div
                    className="p-col-3 p-md-2 p-lg-2"
                    style={{ textAlign: "right" }}
                  >
                    <a href="">หน้าหลัก</a>
                  </div>
                  <ScrollPanel style={{ width: "100%" }}>
                    <img
                      src="assets/images/delete_account.png"
                      alt=""
                      style={{ borderRadius: 20 }}
                    />
                  </ScrollPanel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(DeleteAccount);
