

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { AutoComplete } from 'primereact/autocomplete';
//import { WastetranTransportInfo } from '../wastetran-noti-recycle/WastetranTransportInfo';
import { WastetranOrderDetails } from '../wastetran-noti-recycle/WastetranOrderDetails';
import { WastetranMoreItems } from '../wastetran-noti-recycle/WastetranMoreItems';
import TransportsView from '../transport/TransportsView';
import { AccordionTab, Accordion } from 'primereact/accordion';

import { Validators } from '../../utils'
import DynamicDataTableView from '../data-table/DynamicDataTableView';

class ExampleWasteproNotiRecycleCtrl extends Component {

    constructor(props) {
        console.log("ExampleWasteproNotiRecycleCtrl :constructor")
        super(props);
        this.state = {
            //TransportView
            orderIdSuggestions: null,

            //Map
            marker: { lat: 13.7734283, lng: 100.57383099999993 },
            mapZoom: 11,
            mapCenter: { lat: 13.7734283, lng: 100.57383099999993 }
        }

        //Event Map
        this.onMarkerMoved = this.onMarkerMoved.bind(this);
    }

    orderIds = ['1111', '2222', '3333', '4444', '5555', '6666', '7777', '8888', '9999'];

    componentDidMount() {
        console.log("ExampleWasteproNotiRecycleCtrl :componentDidMount")
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("ExampleWasteproNotiRecycleCtrl :componentWillReceiveProps")

    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("ExampleWasteproNotiRecycleCtrl :componentWillReceiveProps")

    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("ExampleWasteproNotiRecycleCtrl :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("ExampleWasteproNotiRecycleCtrl :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("ExampleWasteproNotiRecycleCtrl :componentDidUpdate")

    }

    // Initial function
    initial = (object) => {
        if (object) {
            for (let key in object) {
                let val = object[key]
                this.setState({ [key]: val })
            }
        }
    }

    //> Submit
    onSubmit = () => {
        this.showSuccess();
    }

    //> EVENT

    //>Handle
    suggestorderIds = (event) => {
        let results = this.orderIds.filter((brand) => {
            return brand.toLowerCase().startsWith(event.query.toLowerCase());
        });

        this.setState({ orderIdSuggestions: results });
    }

    //> Event Map
    onMarkerMoved = (e) => {
        var coor = e.latLng.toJSON();
        this.setState({ latitude: coor.lat })
        this.setState({ longitude: coor.lng })
    }

    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    render() {
        console.log("ExampleWasteproNotiRecycleCtrl :render")
        let contentFindOrder = (
            <div>
                <div className="p-col-12">
                    <label>ค้นหาเลขที่ (Order ID):</label>
                </div>
                <div className="p-col-12">
                    <span className="p-fluid">
                        <AutoComplete
                            placeholder="Search Order Id"
                            value={this.state.brand}
                            onChange={(e) => this.setState({ brand: e.value })}
                            suggestions={this.state.orderIdSuggestions}
                            completeMethod={this.suggestorderIds.bind(this)} />
                    </span>
                </div>
            </div>
        )

        let gmap = {
            marker: this.state.marker,
            mapCenter: this.state.mapCenter,
            mapZoom: this.state.mapZoom
        };
        let contentTransportInfo = (
            <Accordion activeIndex={0}>
                <AccordionTab header="ข้อมูลขนส่ง">
                    {Validators.isNotEmpty(this.state.orderIdSuggestions) &&
                        <TransportsView
                            mode="wastetran"
                            orderIdSuggestions={this.state.orderIdSuggestions}
                            gmap={gmap}
                        />
                    }
                    {Validators.isEmpty(this.state.orderIdSuggestions) &&
                        <label>ไม่พบข้อมูล</label>

                    }
                </AccordionTab>
                <AccordionTab header="รายงานการขาย">
                    {Validators.isNotEmpty(this.state.orderIdSuggestions) &&
                        <DynamicDataTableView
                        />
                    }
                    {Validators.isEmpty(this.state.orderIdSuggestions) &&
                        <label>ไม่พบข้อมูล</label>

                    }
                </AccordionTab>
            </Accordion>
        )

        let contentOderDetail = (
            <WastetranOrderDetails />
        )

        let contentMoreItems = (
            <WastetranMoreItems />
        )

        return (
            <div>
                <Growl ref={(el) => this.growl = el} />
                <h1>แจ้งรับขยะรีไซเคิล</h1>
                <div className="p-grid">
                    {/* DETAIL */}

                    <div className="p-col-12 p-lg-6">
                        <div className="card card-w-title">
                            <h1>รับขยะรีไซเคิล</h1>
                            {contentFindOrder}
                            {contentTransportInfo}
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <div className="card card-w-title">
                            <h1>รายละเอียดสินค้า</h1>
                            {contentOderDetail}
                            {contentMoreItems}
                        </div>
                    </div>
                    <div className="p-col-12">
                        <Button
                            className="p-col-12"
                            label="บันทึก"
                            icon="pi pi-check"
                            iconPos="left"
                            onClick={this.onSubmit} />
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(ExampleWasteproNotiRecycleCtrl)
