import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Moment from 'react-moment';
import 'moment-timezone';
import { Panel } from 'primereact/components/panel/Panel';

import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';

import { DataDemo } from '../demo/DataDemo';

import * as LocationService from '../../service/LocationService'
import * as ReportService from './service'
import { ExportExcel } from '../ExportExcel/ExportExcel';
import { ScrollPanel } from 'primereact/scrollpanel';


class ReportApplicationWaseProContainer extends Component {
    static propTypes = {

    }

    static defaultProps = {
    }

    constructor() {
        super();
        this.state = {
            dataTableValue: [],

        };
        this.toFormatDateTime = this.toFormatDateTime.bind(this);


    }

    componentDidMount() {
        //this.carService.getCarsMedium().then(data => this.setState({dataTableValue: data}));
        const { reportServiceProps } = this.props
        reportServiceProps.getReportApplicantWastepro();

    }
    toFormatDateTime(dateTime) {
        let _dateTime = new Date(dateTime);
        let year = _dateTime.getYear();

        if (year == -1899)
            return <div><span></span></div>;
        else
            return <div><Moment format="DD/MM/YYYY">
                {dateTime}
            </Moment><br /><Moment format="HH:mm">
                    {dateTime}
                </Moment></div>;
    }
    to_created_date(rowData, column) {
        return this.toFormatDateTime(rowData.created_date);
    }

    render() {
        const {
            rptApplicants,
        } = this.props
        var tmp = []
        const Objmaster = rptApplicants.sort(function(a,b){return new Date(a.created_date) - new Date(b.created_date)}).reverse();
        if (Objmaster)
            for (let i = 0; i < Objmaster.length; i++) {
                const obj = {
                    "ชื่อผู้สมัคร": Objmaster[i].applicant_name,
                    "หมายเลขโทรศัพท์": Objmaster[i].telephone_no1,
                    "อีเมล​์": Objmaster[i].email,
                    "ไลน์ไอดี": Objmaster[i].line_id,
                    "ตำบล/แขวง": Objmaster[i].subdistrict_name,
                    "อำเภอ/เขต": Objmaster[i].district_name,
                    "จังหวัด": Objmaster[i].province_name,
                    "ชื่อเข้าใช้งาน": Objmaster[i].username,
                    "วันเวลาที่สมัคร": (new Date(Objmaster[i].created_date)).toLocaleDateString(),
                }
                tmp.push(obj);
                console.log(tmp);
            }
        var exportExcel =
            <div style={{ textAlign: 'left' }}>
                <ExportExcel data={tmp} fileName="รายงานข้อมูลศูนย์จัดการฯซีซาเล้ง" />
            </div>;
        return <div className="p-grid ">
            <div className="card card-w-title">
                <div className="p-col-12">
                    <h1>ศูนย์จัดการฯซีซาเล้ง</h1>
                    {exportExcel}
                    <ScrollPanel header="รายชื่อผู้สมัครใช้งาน" >
                        <DataTable value={Objmaster} selectionMode="single" scrollable={true} style={{ width: 'auto' }} paginatorPosition="bottom"
                            paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                            responsive={true} selection={this.state.dataTableSelection} onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                        >
                            <Column filter={true} field="applicant_name" header="ชื่อผู้สมัคร" sortable={true} style={{ width: '150px' }} />
                            <Column filter={true} field="telephone_no1" header="หมายเลขโทรศัพท์" sortable={true} style={{ width: '150px' }} />
                            <Column filter={true} field="email" header="อีเมล​" sortable={true} style={{ width: '250px' }} />
                            <Column filter={true} field="line_id" header="ไลน์ไอดี" sortable={true} style={{ width: '250px' }} />
                            <Column filter={true} field="addr_current_detail" header="บ้านเลขที่" sortable={true} style={{ width: '200px', overflow: 'hidden', textOverflow: 'ellipsis' }} />
                            <Column filter={true} field="subdistrict_name" header="ตำบล/แขวง" sortable={true} style={{ width: '150px' }} />
                            <Column filter={true} field="district_name" header="อำเภอ/เขต" sortable={true} style={{ width: '150px' }} />
                            <Column filter={true} field="province_name" header="อำเภอ/เขต" sortable={true} style={{ width: '150px' }} />
                            <Column filter={true} field="username" header="ชื่อเข้าใช้งาน" sortable={true} style={{ width: '150px' }} />
                            <Column filter={true} field="created_date" body={this.to_created_date.bind(this)} header="วันเวลาที่สมัคร" className="p-text-center" style={{ width: '150px' }} />
                        </DataTable>
                    </ScrollPanel>
                </div>
            </div>


        </div>
    }
}



const mapStateToProps = (state) => ({
    province: state.location.province,
    district: state.location.district,
    subDistrict: state.location.subDistrict,
    rptApplicants: state.reportAdmin.wpApplicants
})

const mapDispatchToProps = dispatch => ({

    locationServiceProps: bindActionCreators(LocationService, dispatch),
    reportServiceProps: bindActionCreators(ReportService, dispatch)

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ReportApplicationWaseProContainer)