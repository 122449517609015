import React, { Component } from 'react';
import { connect } from 'react-redux';
import { WastegenOverviews } from '../wastegen-dashboard/WastegenOverviews';
import { WastegenTimelines } from '../wastegen-dashboard/WastegenTimelines';
import { getBalance,getWastegenDashLastOrder,getWastegenDashProduct } from '../../service/wastegen.service'
import { userAnnounce } from '../../service/user.service'
import { WasteNewView } from '../waste-news/WasteNewView';
import { DatatableDashBoardView } from '../data-table/DatatableDashBoardView';
import {Panel} from 'primereact/panel';


class WasteproContact extends Component {

    constructor(props) {
        super(props);
        this.state = {
            dummySuccessDataTable: [
                { order: 'ขวดพลาสติกใส', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'ขวดแก้ว', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'อลูมิเนียม', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'กระดาษ', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'กล่องนม', ranking: '0', amount: '0', quantity: '0 kg.' },
            ]
        }

        this.onClickRefresh=this.onClickRefresh.bind(this);
    }

    componentDidMount() {
        this.props.dispatch(getBalance());
        this.props.dispatch(userAnnounce());
        this.props.dispatch(getWastegenDashLastOrder());
        this.props.dispatch(getWastegenDashProduct());
    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("WastegenDashboard :componentWillReceiveProps")
    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("WastegenDashboard :shouldComponentUpdate")

        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("WastegenDashboard :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("WastegenDashboard :componentDidUpdate")
    }

    //> EVENT
    onClickRefresh = (e)=>{
        this.props.dispatch(getWastegenDashLastOrder());
    }
    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }


    render() {
        const {
            wastegenBalance,
            userAnnounce,
        } = this.props
        //> ข่าวสาร
        const contentNews = (
            <Panel>
               <div className="p grid">
                   <div classNam="p-col-12" >
                        <b>ติดต่อเรา</b>
                   </div>
                   <br/>
                   <div classNam="p-col-12" >
                        แจ้งรับขยะรีไซเคิล
                   </div>
                   <div classNam="p-col-12" >
                        สอบถามรายละเอียด โทร. 021151482
                   </div>
                   <div classNam="p-col-12" >
                        C3leng by GGT
                   </div>
               </div>
            </Panel>
        );

        return (
            <div>
                <h1>ข้อมูลการติดต่อ C3Leng</h1>
                {contentNews}
                <div style={{ marginTop: '1em' }} />
                <div className="p-grid dashboard">
                    <div className="p-col-12 p-lg-8">
                        <div style={{ marginTop: '1em' }} />
                    </div>
                    <div className="p-col-12 p-lg-4">
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userAnnounce: state.userAnnounce,
    getWasteproDashPayment: state.getWasteproDashPayment,
    getWasteproDashProduct: state.getWasteproDashProduct,
    getWasteproDashSummary: state.getWasteproDashSummary,
    getWasteproDashOrderStat:state.getWasteproDashOrderStat
})

export default connect(mapStateToProps)(WasteproContact)