import axios from 'axios'
import { API_ROOT } from '../constants/api'
import {
  PROVINCE, DISTRICT, SUB_DISTRICT,CARD_PROVINCE,CARD_DISTRICT, CARD_SUB_DISTRICT,
  CHIP_PROVINCE,
  CHIP_DISTRICT,
  ARR_CHIP_DISTRICT
} from './actionType'

// Location
export const getProvice = () => (dispatch) => {
  dispatch({ type: PROVINCE.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/province/all`,
  }).then((res) => {
    dispatch({ type: PROVINCE.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: PROVINCE.FAILURE, error })
  })
}

export const getDistrict = (provinceCode) => (dispatch) => {
  dispatch({ type: DISTRICT.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/district/${provinceCode}`,
  }).then((res) => {
    dispatch({ type: DISTRICT.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: DISTRICT.FAILURE, error })
  })
}
export const getSubDistrict = (districtCode) => (dispatch) => {
  dispatch({ type: SUB_DISTRICT.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/subdistrict/${districtCode}`,
  }).then((res) => {
    dispatch({ type: SUB_DISTRICT.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: SUB_DISTRICT.FAILURE, error })
  })
  
}

export const getCardProvice = () => (dispatch) => {
  dispatch({ type: CARD_PROVINCE.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/province/all`,
  }).then((res) => {
    dispatch({ type: CARD_PROVINCE.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: CARD_PROVINCE.FAILURE, error })
  })
}

export const getCardDistrict = (provinceCode) => (dispatch) => {
  dispatch({ type: CARD_DISTRICT.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/district/${provinceCode}`,
  }).then((res) => {
    dispatch({ type: CARD_DISTRICT.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: CARD_DISTRICT.FAILURE, error })
  })
}

export const getCardSubDistrict = (districtCode) => (dispatch) => {
  dispatch({ type: CARD_SUB_DISTRICT.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/subdistrict/${districtCode}`,
  }).then((res) => {
    dispatch({ type: CARD_SUB_DISTRICT.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: CARD_SUB_DISTRICT.FAILURE, error })
  })
  
}

export const  getAwaitDistrict = async (provinceCode) => {
  try {
    let res = await axios.get(`${API_ROOT}/master/district/${provinceCode}`)
    
    return res.data;
  } catch (error) {
    return null;
  }
}

export const  getAwaitSubDistrict = async (districtCode) => {
  try {
    let res = await axios.get(`${API_ROOT}/master/subdistrict/${districtCode}`)
    
    return res.data;
  } catch (error) {
    return null;
  }
}



export const getChipProvice = () => (dispatch) => {
  dispatch({ type: CHIP_PROVINCE.REQUEST, isFetching: true })
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/province/all`,
  }).then((res) => {
    dispatch({ type: CHIP_PROVINCE.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: CHIP_PROVINCE.FAILURE, error })
  })
}

export const getChipDistrict = (provinceCode) => (dispatch) => {
  dispatch({ type: CHIP_DISTRICT.REQUEST , isFetching: true})
  return axios({
    method: 'get',
    url: `${API_ROOT}/master/district/${provinceCode}`,
  }).then((res) => {
    dispatch({ type: CHIP_DISTRICT.SUCCESS, payload: res.data })
  }).catch((error) => {
    dispatch({ type: CHIP_DISTRICT.FAILURE, error })
  })
}

export const getArrChipDistrict = (arrProv) => (dispatch) => {
  dispatch({ type: ARR_CHIP_DISTRICT.REQUEST , isFetching: true})

  let result = []
  for(let key in arrProv){
    let entity = arrProv[key];
    let provinceCode = entity.provinceCode
    axios({
      method: 'get',
      url: `${API_ROOT}/master/district/${provinceCode}`,
    }).then((res) => {
      res.data.map((rs)=>{
        result.push(rs)
      })
    }).catch((error) => {
      return dispatch({ type: ARR_CHIP_DISTRICT.FAILURE, error })
    })
  }

  return dispatch({ type: ARR_CHIP_DISTRICT.SUCCESS, payload: result })
}