import React from 'react'
import { Button } from 'primereact/button';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

export const ExportExcel = ({ data, fileName, isDisable }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';

    const exportToExcel = (data, fileName) => {
        console.log(data, fileName)
        const ws = XLSX.utils.json_to_sheet(data);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const dataBlob = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(dataBlob, fileName + fileExtension);
    }

    return (
        <Button
            type="button"
            icon="fa fa-fw fa-file-excel-o"
            iconPos="left"
            label="Export"
            disabled={isDisable || false}
            onClick={() => exportToExcel(data, fileName)} />
    )
}