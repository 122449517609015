

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { AutoComplete } from 'primereact/autocomplete';
import { WastetranOrderDetails } from '../wastetran-noti-recycle/WastetranOrderDetails';
import { WastetranMoreItems } from '../wastetran-noti-recycle/WastetranMoreItems';
import TransportsView from '../transport/TransportsView';
import { AccordionTab, Accordion } from 'primereact/accordion';

import { Validators } from '../../utils'

import { wastetranAutoOrder, wastetranOrderSearchNoti } from '../../service/wastetran.service'

import { API_ROOT } from '../../constants/api';
import { InputText } from 'primereact/inputtext';

class ExampleWastetranNotiRecycleCtrl extends Component {

    constructor(props) {
        console.log("ExampleWastetranNotiRecycleCtrl :constructor")
        super(props);
        this.state = {
            isSearch: false,
            //TransportView
            orderIdSuggestions: null,
            order_id: null,
            //Map
            mapZoom: 11,
            //Datatable noti
            dataReceive: []
        }

        //Search
        this.onSuggestorderIds = this.onSuggestorderIds.bind(this);
        this.onClickSearch = this.onClickSearch.bind(this);
        this.onClickCancel = this.onClickCancel.bind(this);
    }

    componentDidMount() {
        console.log("ExampleWastetranNotiRecycleCtrl :componentDidMount")
        let criteria = { user_wasteTypes: 2 };
        this.props.dispatch(wastetranOrderSearchNoti(criteria));
        this.setState({ isSearch: true })
    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("ExampleWastetranNotiRecycleCtrl :componentWillReceiveProps")

    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("ExampleWastetranNotiRecycleCtrl :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("ExampleWastetranNotiRecycleCtrl :componentWillUpdate")
        if (nextState.order_id != this.state.order_id) {
            nextProps.dispatch(wastetranAutoOrder(nextState.order_id));
        }
    }
    componentDidUpdate(prevProps, prevState) {
        console.log("ExampleWastetranNotiRecycleCtrl :componentDidUpdate")
        if (prevState.isSearch) {
            this.state.dataReceive = [];
            if (this.props.wastetranOrderSearchNoti && !this.props.wastetranOrderSearchNoti.isLoading) {
                if (!this.props.wastetranOrderSearchNoti.isFailed) {
                    if (this.props.wastetranOrderSearchNoti.data && this.props.wastetranOrderSearchNoti.data.length > 0) {
                        let entity = this.props.wastetranOrderSearchNoti.data[this.props.wastetranOrderSearchNoti.data.length - 1];

                        for (let keyDetail in entity.orderDetail) {
                            let entityDetail = entity.orderDetail[keyDetail]
                            if (entityDetail) {
                                let tmpData = {
                                    "order": entityDetail.product_name,
                                    "quantity": entityDetail.pickup_quantity_kk,
                                    "remark": entityDetail.pickup_remark,
                                }
                                this.state.dataReceive.push(tmpData);
                            }

                        }
                    }
                }
            }
            this.setState({ isSearch: false })
        }

    }

    // Initial function
    initial = (object) => {
        if (object) {
            for (let key in object) {
                let val = object[key]
                this.setState({ [key]: val })
            }
        }
    }

    //> Submit
    onSubmit = () => {
        this.showSuccess();
    }

    //> EVENT
    onChangeAutocomplete = (e) => {
        const { value, name } = e.target
        this.setState({ [name]: value })
    }
    onClickSearch = (e) => {
        let criteria = { user_wasteTypes: 2 };
        if (this.state.order_id) {
            criteria.order_code = this.state.order_id
        }
        this.props.dispatch(wastetranOrderSearchNoti(criteria));
        this.setState({ isSearch: true })
    }
    onClickCancel = (e) => {
        this.setState({ order_id: null });
        let criteria = { user_wasteTypes: 2 };
        this.props.dispatch(wastetranOrderSearchNoti(criteria));
        this.setState({ isSearch: true })
    }
    //>Handle
    onSuggestorderIds = (e) => {
        if (this.props.wastetranAutoOrder
            && this.props.wastetranAutoOrder.data
            && !this.props.wastetranAutoOrder.isLoading
        ) {
            this.setState({ orderIdSuggestions: this.props.wastetranAutoOrder.data });
        }
    }

    /* Cell Editing */
    onEditorValueChange = (props, value) => {
        let updatedCars = [...props.value];
        updatedCars[props.rowIndex][props.field] = value;
        this.setState({ dataReceive: updatedCars });
    }

    inputTextEditor = (props, field) => {
        return <InputText type="text"
            value={props.rowData[field]}
            onChange={(e) => this.onEditorValueChange(props, e.target.value)} />;
    }

    orderEditor = (props) => {
        console.log(props)
        return this.inputTextEditor(props, 'order');
    }
    quantityEditor = (props) => {
        return this.inputTextEditor(props, 'quantity');
    }
    remarkEditor = (props) => {
        return this.inputTextEditor(props, 'remark');
    }


    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    render() {
        console.log("ExampleWastetranNotiRecycleCtrl :render")
        let contentFindOrder = (
            <div>
                <div className="p-col-12">
                    <label>ค้นหาเลขที่ (Order ID):</label>
                </div>
                <div className="p-col-12">
                    <span className="p-fluid">
                        <AutoComplete
                            placeholder="Search Order Id"
                            name="order_id"
                            value={this.state.order_id}
                            onChange={this.onChangeAutocomplete}
                            suggestions={this.state.orderIdSuggestions}
                            completeMethod={this.onSuggestorderIds} />
                    </span>
                </div>
                <div className="p-col-12">
                    <Button
                        style={{ width: 100 }}
                        label="ค้นหา"
                        icon="fa fa-search"
                        onClick={this.onClickSearch}
                    />
                    <Button
                        style={{ width: 100 }}
                        label="ยกเลิก"
                        icon="fa fa-refresh"
                        className="p-button-warning"
                        onClick={this.onClickCancel}
                    />
                </div>
            </div>
        )



        let data = (
            <label>ไม่พบข้อมูล</label>
        )

        if ((this.props.wastetranOrderSearchNoti
            && this.props.wastetranOrderSearchNoti.data
            && this.props.wastetranOrderSearchNoti.data.length > 0
        )) {
            let entity = this.props.wastetranOrderSearchNoti.data[this.props.wastetranOrderSearchNoti.data.length - 1];
            let gmapU1 = {
                marker: { lat: entity.u1_lat, lng: entity.u1_lng },
                mapCenter: { lat: entity.u1_lat, lng: entity.u1_lng },
                mapZoom: this.state.mapZoom
            };
            let gmapU3 = {
                marker: { lat: entity.u3_lat, lng: entity.u3_lng },
                mapCenter: { lat: entity.u3_lat, lng: entity.u3_lng },
                mapZoom: this.state.mapZoom
            };
            let urlU1 = "assets/avatar/user.png";
            if (Validators.isNotEmpty(entity.u1_img)) {
                urlU1 = `${API_ROOT}/wastetran/getfile/${entity.u1_img}`;
            }
            let urlU3 = "assets/avatar/user.png";
            if (Validators.isNotEmpty(entity.u3_img)) {
                urlU3 = `${API_ROOT}/wastetran/getfile/${entity.u3_img}`;
            }
            data = (
                <TransportsView
                    mode="wastetran"
                    orderIdSuggestions={entity.order_code}
                    imageUrlU1={urlU1}
                    u1_fullname={entity.u1_fullname}
                    u1_address={entity.u1_address}
                    imageUrlU3={urlU3}
                    u3_fullname={entity.u3_fullname}
                    u3_address={entity.u3_address}
                    gmap={gmapU1}
                    gmapU3={gmapU3}
                />
            )
        }
        let contentTransportInfo = (
            <Accordion activeIndex={0}>
                <AccordionTab header="ข้อมูลขนส่ง">
                    {data}
                </AccordionTab>
            </Accordion>
        )

        let contentOderDetail = (
            <WastetranOrderDetails
                data={this.state.dataReceive}
                orderEditor={this.orderEditor}
                quantityEditor={this.quantityEditor}
                remarkEditor={this.remarkEditor}
            />
        )

        let contentMoreItems = (
            <WastetranMoreItems />
        )

        return (
            <div>
                <Growl ref={(el) => this.growl = el} />
                <h1>แจ้งรับขยะรีไซเคิล</h1>
                <div className="p-grid dashboard">
                    {/* DETAIL */}

                    <div className="p-col-12 p-lg-6">
                        <div className="card card-w-title">
                            <h1>รับขยะรีไซเคิล</h1>
                            {contentFindOrder}
                            {contentTransportInfo}
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <div className="card card-w-title">
                            <h1>รายละเอียดสินค้า</h1>
                            {contentOderDetail}
                            {contentMoreItems}
                        </div>
                    </div>
                    <div className="p-col-12">
                        <Button
                            className="p-col-12"
                            label="บันทึก"
                            icon="pi pi-check"
                            iconPos="left"
                            onClick={this.onSubmit} />
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    wastetranAutoOrder: state.wastetranAutoOrder,
    wastetranOrderSearchNoti: state.wastetranOrderSearchNoti
})

export default connect(mapStateToProps)(ExampleWastetranNotiRecycleCtrl)
