export const status = [
    { name: "รอร้านค้ายืนยัน", value: 0, color: "NAVY", icon: 'fa fa-map-signs', showdate: false, message: 'อยู่ระหว่างรอร้านค้ายืนยัน', subMessage: '' },
    { name: "รอนัดหมาย", value: 1, color: "NAVY", icon: 'fa fa-map-signs', showdate: false, message: 'อยู่ระหว่างรอนัดหมาย', subMessage: '' },
    { name: "รอทีมขนส่ง", value: 2, color: "PURPLE", icon: 'fa fa-comment', showdate: true, message: 'อยู่ระหว่างรอทีมขนส่ง', subMessage: '' },
    { name: "รอส่งศูนย์จัดการฯ", value: 3, color: "GREEN", icon: 'fa fa-map', showdate: true, message: 'อยู่ระหว่างรอส่งศูนย์จัดการฯซีซาเล้ง', subMessage: '' },
    { name: "รอปิด Order", value: 4, color: "RED", icon: 'fa fa-history', showdate: true, message: 'อยู่ระหว่างรอปิด Order', subMessage: '' },
    { name: "เกินกำหนดรับสินค้า", value: 5, color: "RED", icon: 'fa fa-history', showdate: false, message: 'เกินกำหนดรับสินค้า', subMessage: '' },
    { name: "เลือกร้านค้าใหม่", value: 6, color: "RED", icon: 'fa fa-history', showdate: false, message: 'เลือกร้านค้าใหม่', subMessage: '' },
    { name: "ปิด Order", value: 88, color: "OLIVE", icon: 'fa fa-history', showdate: false, message: 'อยู่ระหว่างปิด Order', subMessage: '' },
    { name: "ยกเลิก", value: 99, color: "MAROON", icon: 'fa fa-history', showdate: false, message: 'อยู่ระหว่างยกเลิก', subMessage: '' },
    { name: "ผิดนัด", value: 999, color: "RED", icon: 'fa fa-history', showdate: false, message: 'อยู่ระหว่างผิดนัด', subMessage: '' },
    { name: "ร้านค้าปฏิเสธรับซื้อ", value: 998, color: "RED", icon: 'fa fa-history', showdate: false, message: 'ร้านค้าปฏิเสธรับซื้อ', subMessage: '' },
]

export const getStatus = (statusCode) => {
    let rs = status.filter((value) => { return value.value == statusCode })
    return rs[0]
}