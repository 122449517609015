import React, { Component } from 'react';
import { connect } from 'react-redux';

import { WastegenOverviews } from '../wastegen-dashboard/WastegenOverviews';
import { WastegenTimelines } from '../wastegen-dashboard/WastegenTimelines';

import { getBalance, getWastegenDashLastOrder, getWastegenDashProduct } from '../../service/wastegen.service'
import { userAnnounce } from '../../service/user.service'
import { WasteNewView } from '../waste-news/WasteNewView';
import { DatatableGoodnessView } from '../data-table/DatatableGoodnessView';
import { Panel } from 'primereact/panel';
import { ListBox } from 'primereact/listbox';
import { ChatWastegenWhitU2View } from '../message/ChatWastegenWhitU2View';
import { Growl } from 'primereact/growl';
import {InputText} from 'primereact/inputtext';
import { Button } from 'primereact/button';

class WastegenChatWithU2 extends Component {

    constructor(props) {
        console.log("WastegenDashboard :constructor")
        super(props);
        this.state = {
            dummySuccessDataTable: [
                { order: 'ขวดพลาสติกใส', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'ขวดแก้ว', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'อลูมิเนียม', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'กระดาษ', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'กล่องนม', ranking: '0', amount: '0', quantity: '0 kg.' },
            ],
            selectedChat:'',
        }

        this.onClickRefresh = this.onClickRefresh.bind(this);
    }

    componentDidMount() {
        console.log("WastegenDashboard :componentDidMount")
        this.props.dispatch(getBalance());
        this.props.dispatch(userAnnounce());
        this.props.dispatch(getWastegenDashLastOrder());
        this.props.dispatch(getWastegenDashProduct());
    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("WastegenDashboard :componentWillReceiveProps")
    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("WastegenDashboard :shouldComponentUpdate")

        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("WastegenDashboard :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("WastegenDashboard :componentDidUpdate")
    }

    //> EVENT
    onClickRefresh = (e) => {
        this.props.dispatch(getWastegenDashLastOrder());
    }
    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }


    render() {
        const {
            userAnnounce,
        } = this.props
        //> ข่าวสาร
        const contentNews = (
            <WasteNewView
                data={userAnnounce.data}
            />
        );
        const citySelectItems = [
            { label: 'นาย อิศยา กาบนันทา', value: '1' },
            { label: 'eissaya U1', value: '2' },
            { label: 'usertran eisya', value: '3' },
            { label: 'ร้านมารวย', value: '4' },
            { label: 'นาย ประทีบ จันทรา', value: '5' },
            { label: 'นาย ประเทือง จันทรา', value: '6'},
            { label: 'นาย ประหยัด จันทรา', value: '7'},
            { label: 'นาย ประทุ จันทรา', value: '8'},
            { label: 'นาย ประปา จันทรา', value: '9'},
            { label: 'นาย ประวัติ จันทรา', value: '10'},
            { label: 'นาย ประจบ จันทรา', value: '11'},
            { label: 'นาย ประแจง จันทรา', value: '12'},
            { label: 'นาย ประนอม จันทรา', value: '13'},
            { label: 'นาย ประวรรณ จันทรา', value: '14'},
        ];

        
        const showChatTable = (
                <Panel header="Chat">
                    <div className="p-grid p-fluid" >
                        <div className="p-col-12">
                            <div className="p-inputgroup">
                                <InputText placeholder="ค้นหาสมาชิก"/>
                                <Button 
                                icon="fa fa-search" 
                                />
                            </div>
                        </div>
                    </div>
                    <ListBox
                        value={this.state.selectedChat}
                        filter={false} 
                        filterPlaceholder="Search"
                        options={citySelectItems}
                        nChange={(e) => this.setState({ selectedChat: e.value })}
                        itemTemplate={this.carTemplate}
                        style={{ width: '100%' }}
                        listStyle={{ maxHeight: '450px' }} />
                </Panel>
        )

        const chatWastegen = (
                <ChatWastegenWhitU2View
                    valueDd={this.state.valueDd}
                    listDd={this.state.listMessage}
                    onChangeDd={this.onChangeDd}
                    onChangeMessage={this.onChangeMessage}
                    onSubmit={this.onSubmit}
                    classNameRequired={this.state.classNameRequired}
                />
        );

        return (
            <div>
                <h1>Chat</h1>
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid dashboard">

                    {/* <div className="p-col-12 p-md-3 p-lg-4">
                        {showChatTable}
                    </div> */}
                    <div className="p-col-12 " >
                        {chatWastegen}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    wastegenBalance: state.wastegenBalance,
    userAnnounce: state.userAnnounce,
    getWastegenDashLastOrder: state.getWastegenDashLastOrder,
    getWastegenDashProduct: state.getWastegenDashProduct
})

export default connect(mapStateToProps)(WastegenChatWithU2)