import axios from 'axios'
import { API_ROOT } from '../../constants/api'


export const checkExit = (username) =>{
  
  return axios({
    method: 'get',
    url: `${API_ROOT}/user/checkexit/${username}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const checkExitPhoneAndName = (model) =>{
  
  return axios({
    method: 'post',
    url: `${API_ROOT}/user/checkexit`,
    data: model
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}

export const Register = (registerModel) =>{
  
    return axios({
      method: 'post',
      url: `${API_ROOT}/user/wastegen/Register`,
      data: registerModel
    }).then((res) => {
      return res.data;
    }).catch((error) => {
      return error;
    })
  }
  
export const RegisterWasteTran = (formData) =>{
  return axios({
        method: 'post',
        url: `${API_ROOT}/user/wastetran/Register`,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(function (res) {
        return res.data;
      })
      .catch(function (error) {
        return error;
      });
}

export const RegisterWastePro = (formData) =>{
  return axios({
        method: 'post',
        url: `${API_ROOT}/user/wastepro/Register`,
        data: formData,
        headers: {'Content-Type': 'multipart/form-data' }
      })
      .then(function (res) {
        return res.data;
      })
      .catch(function (error) {
        return error;
      });
}

export const searchMemberName = (search_name) =>{
  
  return axios({
    method: 'get',
    url: `${API_ROOT}/autocomplete/wastepro/${search_name}`,
  }).then((res) => {
    return res.data;
  }).catch((error) => {
    return error;
  })
}  