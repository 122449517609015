import React, { Component } from 'react';
import propsTypes from 'prop-types'

import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { RadioButton } from 'primereact/radiobutton';
import { Checkbox } from 'primereact/checkbox';
import { Panel } from 'primereact/panel';
import { Message } from 'primereact/message';
import { InputMask } from 'primereact/inputmask';
import { Validators } from '../../utils';
import { ValidateInputNumberLengthView } from '../required/ValidateInputNumberLengthView';
import { ValidateInputNumberView } from '../required/ValidateInputNumberView';
import { ValidateInputTextView } from '../required/ValidateInputTextView';
import { ValidateDropdownView } from '../required/ValidateDropdownView';
import { ValidatePhoneNumberRequiredView } from '../required/ValidatePhoneNumberRequiredView';


export class ProfileInfoView extends Component {

    static defaultProps = {
        errMobileMsg:null
    }

    static propTypes = {
        errMobileMsg:propsTypes.string
    }


    state = {
        //wastepro
        wasteTypesSelected: [],
        wasteTypes: [
            { label: 'ขวดแก้ว', value: 1 },
            { label: 'กระดาษ', value: 2 },
            { label: 'พลาสติก', value: 3 },
            { label: 'โลหะ/อโลหะ', value: 4 },
            { label: 'อิเล็กทรอนิกส์/เครื่องใช้ไฟฟ้า', value: 5 }
        ]
    }

    componentDidMount() {
        //console.log("ProfileInfoView :componentDidMount")
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
    }
    shouldComponentUpdate(nextProps, nextState) {
        //console.log("ProfileInfoView :shouldComponentUpdate")
        return true;
    }
    componentWillUpdate(nextProps, nextState) {

        //console.log("ProfileInfoView :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        //console.log("ProfileInfoView :componentDidUpdate")

    }

    //> EVENT
    onChangeInputText = (e) => {
        this.props.onChangeInputText(e);
    }
    onChangePersonPrefix = (e) => {
        this.props.onChangePersonPrefix(e);
    }
    onChangeCompanyType = (e) => {
        this.props.onChangeCompanyType(e);
    }
    //> EVENT WASTEPRO
    onCheckChange = (e) => {
        this.props.onCheckChange(e);
    }

    //> Render page
    personType = () => {
        return (
            <div className="p-grid  p-fluid" >
                <div className="p-col-12">
                    <div >
                        <label class="required">คำนำหน้าชื่อ</label>
                    </div>
                    <ValidateDropdownView
                        required={true}
                        name="person_prefix_code"
                        value={this.props.selectedPersonPrefix}
                        options={this.props.personPrefixs}
                        onChange={this.onChangePersonPrefix}
                        placeholder="เลือกคำนำหน้า"
                        optionLabel="name"
                        errorMessage="กรุณาระบุคำนำหน้าชื่อ"
                    />
                </div>

                <div style={{ marginTop: '.5em' }}></div>

                <div className="p-col-12">
                    <div>
                        <label className="required">ชื่อ</label>
                    </div>
                    <ValidateInputTextView
                        errorMessage="กรุณาระบุชื่อ"
                        value={this.props.person_first_name}
                        onChange={this.onChangeInputText}
                        name="person_first_name"
                        placeholder="First Name"
                    />
                </div>

                <div className="p-col-12">
                    <div>
                        <label className="required">นามสกุล </label>
                    </div>

                    <ValidateInputTextView
                        errorMessage="กรุณาระบุนามสกุล"
                        value={this.props.person_last_name}
                        onChange={this.onChangeInputText}
                        name="person_last_name"
                        placeholder="Last Name"
                    />
                </div>

                <div className="p-col-12">
                    <div >
                        <label>เลขบัตรประชาชน</label>
                    </div>

                    <ValidateInputNumberView
                        value={this.props.person_idcard}
                        onChange={this.onChangeInputText}
                        errorMessage="กรุณาระบุเลขบัตรประชาชนจำนวน 13 หลัก"
                        mask="9999999999999"
                        name="person_idcard"
                        placeholder="Thai Id"
                    />

                </div>
            </div>
        )
    }
    legalType = () => {
        return (
            <div className="p-grid  p-fluid">
                <div className="p-col-12">
                    <div>
                        <label className="required">ประเภทนิติบุคคล</label>
                    </div>
                    <ValidateDropdownView
                        required={true}
                        name="org_type"
                        value={this.props.selectedCompanyType}
                        options={this.props.companyTypes}
                        onChange={this.onChangeCompanyType}
                        placeholder="เลือกประเภทนิติบุคคล"
                        optionLabel="name"
                        errorMessage="กรุณาระบุประเภทนิติบุคคล"
                    />

                    <div style={{ marginTop: '.5em' }}></div>
                </div>

                <div className="p-col-12">
                    <div>
                        <label className="required">ชื่อนิติบุคคล</label>
                    </div>
                    <ValidateInputTextView
                        maxLength={100}
                        required={true}
                        placeholder="Company Name"
                        value={this.props.org_name}
                        name="org_name"
                        onChange={this.onChangeInputText}
                        errorMessage="กรุณาระบุชื่อนิติบุคคล"
                    />
                </div>
                <div className="p-col-12">
                    <div>
                        <label className="required">เลขนิติบุคคล</label>
                    </div>

                    <ValidateInputNumberLengthView
                        value={this.props.org_code}
                        onChange={this.onChangeInputText}
                        errorMessage="กรุณาระบุเลขนิติบุคคล จำนวน 13 หลัก"
                        mask="9999999999999"
                        name="org_code"
                        placeholder="Company Id"
                    />

                </div>

            </div>
        )
    }

    //MODE
    renderWastegen = () => {
        return (
            <div className="p-col-12">
                <Panel header={<label className="required">สถานที่เก็บขยะรีไซเคิล</label>}>
                    <div className="p-grid p-fluid">
                        <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                            <RadioButton
                                inputId="cb1"
                                value="1"
                                name="location_type_code"
                                onChange={this.onChangeInputText}
                                checked={this.props.location_type_code == '1'} />
                            <label htmlFor="cb1" className="p-checkbox-label">บ้านในโครงการจัดสรร</label>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                            <RadioButton
                                name="location_type_code"
                                inputId="cb2"
                                value="2"
                                onChange={this.onChangeInputText}
                                checked={this.props.location_type_code == '2'}></RadioButton>
                            <label htmlFor="cb2" className="p-checkbox-label">คอนโดมีเนียม / อพาร์ทเม้นท์</label>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                            <RadioButton
                                name="location_type_code"
                                inputId="cb3"
                                value="3"
                                onChange={this.onChangeInputText}
                                checked={this.props.location_type_code == '3'}></RadioButton>
                            <label htmlFor="cb3" className="p-checkbox-label">ร้านค้า / ร้านอาหาร</label>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                            <RadioButton
                                name="location_type_code"
                                inputId="cb4"
                                value="4"
                                onChange={this.onChangeInputText}
                                checked={this.props.location_type_code == '4'}></RadioButton>
                            <label htmlFor="cb4" className="p-checkbox-label">บ้านพักอาศัยทั่วไป</label>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                            <RadioButton
                                name="location_type_code"
                                inputId="cb5"
                                value="5"
                                onChange={this.onChangeInputText}
                                checked={this.props.location_type_code == '5'}></RadioButton>
                            <label htmlFor="cb5" className="p-checkbox-label">อาคารสำนักงาน / โรงงาน</label>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                            <RadioButton
                                name="location_type_code"
                                inputId="cb6"
                                value="6"
                                onChange={this.onChangeInputText}
                                checked={this.props.location_type_code == '6'}></RadioButton>
                            <label htmlFor="cb6" className="p-checkbox-label">สถานศึกษา / สถานที่ราชการ</label>
                        </div>
                        <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                            <RadioButton
                                name="location_type_code"
                                inputId="cb7"
                                value="7"
                                onChange={this.onChangeInputText}
                                checked={this.props.location_type_code == '7'}></RadioButton>
                            <label htmlFor="cb7" className="p-checkbox-label">อื่นๆ</label>
                        </div>
                    </div>
                </Panel>
            </div>
        )
    }
    renderWastetran = () => {
        return (
            <div></div>
        )
    }
    renderWastepro = () => {
        const wasteTypesSelected = this.props.wasteTypesSelected || []
        return (
            <div className="p-col-12">
                {/* <Panel header={<label className="required">ประเภทสินค้าที่รับซื้อ</label>}>

                    <div className="p-grid p-fluid">
                        {wasteTypesSelected.length == 0 &&
                            <div className="p-col-12">
                                <Message severity="error" text="กรุณาระบุประเภทสินค้าที่รับซื้อ" />
                            </div>
                        }
                        <div className="p-col-12 p-md-12 p-lg-6" style={{ textAlign: 'left' }}>
                            <Checkbox
                                inputId="cb1"
                                value={this.props.wasteTypes[0]}
                                onChange={this.onCheckChange}
                                checked={this.props.wasteTypesSelected.indexOf(this.props.wasteTypes[0]) !== -1}>
                            </Checkbox>
                            <label htmlFor="cb1" className="p-checkbox-label">ขวดแก้ว</label>
                        </div>
                        <div className="p-col-12 p-md-12 p-lg-6" style={{ textAlign: 'left' }}>
                            <Checkbox
                                inputId="cb2"
                                value={this.props.wasteTypes[1]}
                                onChange={this.onCheckChange}
                                checked={this.props.wasteTypesSelected.indexOf(this.props.wasteTypes[1]) !== -1}>
                            </Checkbox>
                            <label htmlFor="cb2" className="p-checkbox-label">กระดาษ</label>
                        </div>
                        <div className="p-col-12 p-md-12 p-lg-6" style={{ textAlign: 'left' }}>
                            <Checkbox
                                inputId="cb3"
                                value={this.props.wasteTypes[2]}
                                onChange={this.onCheckChange}
                                checked={this.props.wasteTypesSelected.indexOf(this.props.wasteTypes[2]) !== -1}>
                            </Checkbox>
                            <label htmlFor="cb3" className="p-checkbox-label">พลาสติก</label>
                        </div>
                        <div className="p-col-12 p-md-12 p-lg-6" style={{ textAlign: 'left' }}>
                            <Checkbox
                                inputId="cb4"
                                value={this.props.wasteTypes[3]}
                                onChange={this.onCheckChange}
                                checked={this.props.wasteTypesSelected.indexOf(this.props.wasteTypes[3]) !== -1}>
                            </Checkbox>
                            <label htmlFor="cb4" className="p-checkbox-label">โลหะ/อโลหะ</label>
                        </div>
                        <div className="p-col-12 p-md-12 p-lg-6" style={{ textAlign: 'left' }}>
                            <Checkbox
                                inputId="cb5"
                                value={this.props.wasteTypes[4]}
                                onChange={this.onCheckChange}
                                checked={this.props.wasteTypesSelected.indexOf(this.props.wasteTypes[4]) !== -1}>
                            </Checkbox>
                            <label htmlFor="cb5" className="p-checkbox-label">อิเล็กทรอนิกส์/เครื่องใช้ไฟฟ้า</label>
                        </div>
                    </div>

                </Panel> */}
            </div>
        )
    }

    render() {
        //console.log("ProfileInfoView :render", this.props)

        const {
            mode
        } = this.props

        let renderMode;

        switch (mode) {
            case 'wastegen':
                renderMode = this.renderWastegen()
                break;
            case 'wastetran':
                renderMode = this.renderWastetran()
                break;
            case 'wastepro':
                renderMode = this.renderWastepro()
                break;

            default:
                renderMode = (<div></div>)
                break;
        }

        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <label>ประเภทสมาชิก</label>
                </div>
                <div className="p-col-6">
                    <RadioButton inputId="rb1"
                        name="person_type" value="1"
                        onChange={this.onChangeInputText}
                        checked={this.props.person_type == '1'} />
                    <label htmlFor="rb1" className="p-radiobutton-label">บุคคลธรรมดา</label>

                </div>
                <div className="p-col-6">
                    <RadioButton inputId="rb2"
                        name="person_type" value="2"
                        onChange={this.onChangeInputText}
                        checked={this.props.person_type == '2'} />
                    <label htmlFor="rb2" className="p-radiobutton-label">นิติบุคคล</label>
                </div>

                <div className="p-col-12">
                    {this.props.person_type == '1' && this.personType()}
                    {this.props.person_type == '2' && this.legalType()}
                </div>


                <div className="p-col-12 p-fluid">
                    <label className="required">หมายเลขโทรศัพท์มือถือ</label>
                    <div className="p-col-12">

                        <ValidatePhoneNumberRequiredView
                            required={true}
                            value={this.props.telephone_no1}
                            onChange={this.onChangeInputText}
                            errorMessage={this.props.errMobileMsg||"กรุณาระบุหมายเลขโทรศัพท์ให้ถูกต้อง จำนวน 10 หลัก"}
                            isErrorPhone={this.props.isErrorPhone}
                            mask="9999999999"
                            name="telephone_no1"
                            placeholder="Mobile Number 1"
                        />
                    </div>
                    {mode != 'wastegen' &&
                        <div className="p-col-12">
                            <InputText
                                maxLength={100}
                                className="p-col-12"
                                name="telephone_no2"
                                placeholder="Mobile Number 2"
                                value={this.props.telephone_no2}
                                onChange={this.onChangeInputText}
                            />
                        </div>
                    }
                </div>

                <div className="p-col-12">
                    <label>Line Id</label>
                    <InputText
                        className="p-col-12"
                        placeholder="Line ID"
                        name="line_id"
                        value={this.props.line_id}
                        maxLength={45}
                        onChange={this.onChangeInputText}>
                    </InputText>
                </div>
                <div className="p-col-12 p-fluid">
                    <label htmlFor="email_id">
                        Email
                    </label>
                    <InputText
                        id="email_id"
                        className={!Validators.isEmail(this.props.email) ? 'p-error' : ''}
                        placeholder="Email"
                        name="email"
                        maxLength={45}
                        value={this.props.email}
                        onChange={this.onChangeInputText}
                    >
                    </InputText>
                    {!Validators.isEmail(this.props.email) &&
                        <Message severity="error" text="ระบุอีเมล์ไม่ถูกต้อง" />
                    }
                </div>

                {renderMode}

            </div>
        )
    }
}