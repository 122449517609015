import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Moment from 'react-moment';
import 'moment-timezone';
import {Panel} from 'primereact/components/panel/Panel';
import {Checkbox} from 'primereact/components/checkbox/Checkbox';
import {Button} from 'primereact/components/button/Button';
import {Dropdown} from 'primereact/components/dropdown/Dropdown';
import {InputText} from 'primereact/components/inputtext/InputText';
import {InputTextarea} from 'primereact/components/inputtextarea/InputTextarea';
import {DataTable} from 'primereact/components/datatable/DataTable';
import {Column} from 'primereact/components/column/Column';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { ProgressSpinner } from 'primereact/progressspinner';
import { AutoComplete } from 'primereact/autocomplete';
import { ExportExcel } from '../ExportExcel/ExportExcel';
import * as ServerAPI from './service';
import * as DashBoardService from './service'
import { isValid } from 'redux-form';


class ReportInvoice extends Component {
	static propTypes = {

    }
  
    static defaultProps = {
    }

    constructor() {
        super();
        this.state = {
            status: 0,
            admin: 0,
            statusItem: [
                { label: 'เลือกทั้งหมด', value: 0 },
                { label: 'ยังไม่ได้แจ้งหนี้', value: 1 },
                { label: 'แจ้งหนี้แล้วรอชำระ', value: 2 },
                { label: 'ชำระแล้วรอตรวจสอบ', value: 3 },
                { label: 'ชำระหนี้แล้ว', value: 88 },
            ],
            showProgress: false,
            orderCode: null,
            orderID: null,
            memberUid: null,
            date1: null,
            date2: null,
            reportSearchInvoice: [],
        };
        this.onDate1Change = this.onDate1Change.bind(this);
        this.onDate2Change = this.onDate2Change.bind(this);
        this.searchInvoice = this.searchInvoice.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.onSelectOrderID = this.onSelectOrderID.bind(this);
        this.onSelectMemberName = this.onSelectMemberName.bind(this);
        this.onSelectAdminName = this.onSelectAdminName.bind(this);
        this.salePriceTemplate = this.salePriceTemplate.bind(this);
        this.deliveryCostTemplate = this.deliveryCostTemplate.bind(this);
        this.processFeeTemplate = this.processFeeTemplate.bind(this);
        this.headerTemplate = this.headerTemplate.bind(this);
        this.footerTemplate = this.footerTemplate.bind(this);
        this.toFormatDateTime = this.toFormatDateTime.bind(this);
       
        this.showSuccess =this.showSuccess.bind(this);
        this.showWarn = this.showWarn.bind(this);

    }
    componentDidMount() {
        const {dashBoardServiceProps} = this.props
		dashBoardServiceProps.getDashboardUsers();
    }

    onDate1Change(e) { //search pickup date start
        //console.log(e.value.toISOString())
        if (this.state.date2 != null) {
            if (e.value <= this.state.date2) {
                this.setState({ date1: e.value })
            }
        } else
            this.setState({ date1: e.value })
    }
    onDate2Change(e) { //search pickup date end
        if (this.state.date1 <= e.value) {
            this.setState({ date2: e.value })
        }
    }
    searchInvoice(){
        //this.setState({ showReportTable: true })
        this.setState({ showProgress: true })
        //-------reset table--------
        // var reportSearchList = {}
        // this.state.reportSearchOrders.push(reportSearchList)
        // this.setState({ reportSearchOrders: [] });
        var searchModel = {
            order_code: this.state.orderID,
            user_search_name: this.state.memberUid,
            user_wasteTypes: this.state.memberType,
            invoice_status: this.state.status,
            admin_code: "",
        };

        if (this.state.date1 != null) {
            //Bankok Time zone (+7hrs)
            var date1 = new Date(this.state.date1.getTime() + 7 * 60 * 60000);
            searchModel.delivery_date_start = date1.toISOString();
        }
        if (this.state.date2 != null) {
            var date2 = new Date(this.state.date2.getTime() + 7 * 60 * 60000);
            searchModel.delivery_date_end = date2.toISOString();
        }

        ServerAPI.searchInvoice(searchModel).then((res) => {
            console.log("response");
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                   
                    if (res.length > 0) {
                        var array = res;
                        var listOfInvoice = [];
                        for (let i in array) {
                            var list =  array[i].invoiceDetail;
                            for (let j in list) {
                                var obj =list[j];
                                obj.sale_price =  Number((obj.delivery_quantity_kk * obj.price_per_unit).toFixed(2));

                                var Objmaster = Object.assign({},  array[i]); 
                                Objmaster =  Object.assign(obj, Objmaster);                          
                                listOfInvoice.push(Objmaster);                              
                            }
                        }
                        this.setState({reportSearchInvoice:listOfInvoice});
                        this.setState({ showProgress: false })
                    } else {
                        this.showWarn("ไม่พบข้อมูล");
                        this.setState({ showProgress: false })
                    }
                    // this.showSuccess();
                    // this.clearAllDataInput();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }

            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
        })
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div className="p-col-12 p-md-8 p-lg-9"></div>);
    }
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    clearSearchInput() {
        this.setState({ orderCode: null }); //order code from select table
        this.setState({ orderID: null }); //order code from auto complete
        this.setState({ memberUid: null });
        this.setState({ memberName: '' });
        this.setState({ adminName: '' });
        this.setState({ adminUid: '' });
        //this.setState({ memberType: 0 });
        this.setState({ date1: null });
        this.setState({ date2: null });
        this.setState({ status: 0});
        var empty = {}
        this.state.reportSearchInvoice.push(empty)
        this.setState({ reportSearchInvoice: [] });

        //this.clearAllDataInput();
    }

    //auto complate handle (ค้นหา Order ID)
    suggestOrderID(event) {
        //console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */
        //this.setState({ brandSuggestions: event });
    }
    onOrderIDChanged(code) {
        //console.log(code)
        if (code.trim() != "") {
            this.setState({ orderID: code });
            if (code.length > 1) {
                ServerAPI.autoComplateOrderID(code).then((res) => {
                    console.log(res);
                    this.setState({ orderIDSuggestions: res });
                }).catch((error) => {
                })
            }
        } else this.setState({ orderID: code });
    }
    onSelectOrderID(item) {
        this.setState({ orderID: item.value });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    itemOrderIDTemplate(item) {
        return (
            <div className="p-clearfix">
                {item}
            </div>
        );
    }
    //auto complate handle (ค้นหาสมาชิก)
    suggestMemberName(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }
    itemMemberTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }
    onMemberNameChanged(search_name) {
        console.log(search_name)
        console.log(typeof (search_name))
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwastepro(search_name).then((res) => {
                        console.log(res);
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName: search_name });
                this.setState({ memberUid: null });
            }
    }
    onSelectMemberName(item) {
        this.setState({ memberName: item.value.search_name });
        this.setState({ memberUid: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    //auto complate handle (ค้นหาแอดมิน)
    suggestAdminName(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }
    itemAdminTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }
    onAdminNameChanged(search_name) {
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ adminName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchadminAll(search_name).then((res) => {
                        console.log(res);
                        this.setState({ adminSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ adminName: search_name });
                this.setState({ adminUid: null });
            }
    }
    onSelectAdminName(item) {
        this.setState({ adminName: item.value.search_name });
        this.setState({ adminUid: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    salePriceTemplate(rowData, column){
        let price = rowData.delivery_quantity_kk * rowData.price_per_unit;
        return <div>
            <span> {price.toFixed(2)}</span>
        </div>;
    }
    deliveryCostTemplate(rowData, column){
        let price = rowData.delivery_cost;
        return <div>
            <span> {price.toFixed(2)}</span>
        </div>;
    }
    processFeeTemplate(rowData, column){
        let price = rowData.process_fee;
        return <div>
            <span> {price.toFixed(2)}</span>
        </div>;
    }
    headerTemplate(data) {
        //console.log(data)
        return data.u3_fullname;
    }
    footerTemplate(data, index) {
        return (
            <React.Fragment>
                <td key={data.u3_fullname + '_footerTotalLabel'} colSpan="6" style={{textAlign: 'right'}}>รวม:</td>
                <td key={data.u3_fullname + '_footerTotalValue'} style={{textAlign: 'right'}}>{this.calculateQuantityTotal(data.u3_fullname)}</td>
                <td key={data.u3_fullname + '_footerTotalValue'} style={{textAlign: 'right'}}>{this.calculatePriceTotal(data.u3_fullname)}</td>
            </React.Fragment>
        );
    }

    calculateQuantityTotal(brand) {
        let total = 0;
        if(this.state.reportSearchInvoice) {
            for(let car of this.state.reportSearchInvoice) {
                if(car.u3_fullname === brand) {
                    total += car.delivery_quantity_kk;
                }
            }
        }

        return total;
    }
    calculatePriceTotal(brand) {
        let total = 0;
        if(this.state.reportSearchInvoice) {
            for(let car of this.state.reportSearchInvoice) {
                if(car.u3_fullname === brand) {
                    var price = car.delivery_quantity_kk*car.price_per_unit;
                    total += price;
                }
            }
        }

        return total.toFixed(2);
    }
    toFormatDateTime(dateTime){
        return <div><Moment format="DD/MM/YYYY">
        {dateTime}
            </Moment><br/><Moment format="HH:mm">
                {dateTime}
            </Moment></div>; 
    }
    to_transfer_date(rowData, column){
       
        let delivery_date = new Date(rowData.delivery_date);
        let year = delivery_date.getYear();

        if(year==-1899)
            return <div><span></span></div>;
        else 
            return this.toFormatDateTime(rowData.delivery_date);
    }
   
    to_requested_date(rowData, column){
        return this.toFormatDateTime(rowData.pickup_date);
    }

    searchPanel(){
        return (
            <div className="p-col-12">
                <Accordion multiple={true} activeIndex={[0, 1]}>
                    <AccordionTab header="เงื่อนไขค้นหาราการแจ้งหนี้">
                    <div className="p-grid " >
                        <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>Order ID :</label>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.orderID}
                                    suggestions={this.state.orderIDSuggestions}
                                    onSelect={this.onSelectOrderID}
                                    completeMethod={this.suggestOrderID.bind(this)}
                                    onChange={(e) => this.onOrderIDChanged(e.value)}
                                    itemTemplate={this.itemOrderIDTemplate.bind(this)}
                                    placeholder="Order ID " />
                        </div>

                        <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>สมาชิก :	 </label>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.memberName}
                                    suggestions={this.state.memberSuggestions}
                                    onSelect={this.onSelectMemberName}
                                    completeMethod={this.suggestMemberName.bind(this)}
                                    onChange={(e) => this.onMemberNameChanged(e.target.value)}
                                    itemTemplate={this.itemMemberTemplate.bind(this)}
                                    placeholder="ระบุรหัสสมาชิก/ชื่อสมาชิก" />
                        </div>
                        <div className="p-col-12 p-lg-4"></div>

                        <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>ช่วงวันส่งสินค้า</label>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <Calendar value={this.state.date1} dateFormat="dd/mm/yy" showIcon={true} placeholder="เลือกวันที่" onChange={this.onDate1Change} />
                        </div>

                        <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <label>ถึง</label>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <Calendar value={this.state.date2} dateFormat="dd/mm/yy" showIcon={true} placeholder="เลือกวันที่" onChange={this.onDate2Change} />
                        </div>
                        <div className="p-col-12 p-lg-4"></div>
                        <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>สถานะ :</label>
                        </div>

                        <div className="p-col-12 p-md-4 p-lg-3" style={{ alignSelf: 'center' }}>
                            <Dropdown
                                value={this.state.status}
                                options={this.state.statusItem}
                                onChange={(e) => { this.setState({ status: e.value }) }}
                            />
                        </div>
                        <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>ผู้ดูแล :</label>
                        </div>
                        <div className="p-col-12 p-md-4 p-lg-3" style={{ alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.adminName}
                                    suggestions={this.state.adminSuggestions}
                                    onSelect={this.onSelectAdminName}
                                    completeMethod={this.suggestAdminName.bind(this)}
                                    onChange={(e) => this.onAdminNameChanged(e.target.value)}
                                    itemTemplate={this.itemAdminTemplate.bind(this)}
                                    placeholder="ระบุรหัสผู้ดูแล/ชื่อผู้ดูแล" />
                        </div>
                        <div className="p-col-12 p-lg-4"></div>
                        <div className="p-col-12">
                                <Button
                                    style={{marginRight:"20px"}}
                                    label="ค้นหา"
                                    icon="fa fa-search"
                                    iconPos="left"
                                    onClick={this.searchInvoice} />
                                <Button
                                    style={{ backgroundColor: '#f0ad4e' }}
                                    type="submit"
                                    label="ยกเลิก"
                                    onClick={this.clearSearchInput}
                                />
                            </div>
                    </div>
                    </AccordionTab>
                </Accordion>
            </div>
        )
    }
   
    reportInvoiceTable(){
        var tmp = []
        const Objmaster = this.state.reportSearchInvoice;
        for (let i = 0; i < Objmaster.length; i++) {
            const obj = {
               "เลขที่": Objmaster[i].invoice_code,
               "ชื่อร้าน": Objmaster[i].u3_fullname,
               "ผู้ขาย": Objmaster[i].u1_fullname,
               "ผู้ขนส่่ง": Objmaster[i].u2_fullname,
               "รายการ":Objmaster[i].product_name,
               "วันที่ส่ง": (new Date(Objmaster[i].delivery_date)).toLocaleDateString(),
               "ผู้ดูแล": Objmaster[i].admin_name,
               "สถานะ": Objmaster[i].invoice_status_name,
               "ปริมาณส่ง (Kg)": Objmaster[i].delivery_quantity_kk,
               "ราคาขาย (บาท)": Number((Objmaster[i].delivery_quantity_kk * Objmaster[i].price_per_unit).toFixed(2)),
               "ค่าขนส่ง (บาท)": Number(Objmaster[i].delivery_cost.toFixed(2)),
               "ค่าบริการ (บาท)": Number(Objmaster[i].process_fee.toFixed(2))
            }
            tmp.push(obj);
        }
         //console.log(tmp);

        var exportExcel =
        <div style={{ textAlign: 'left' }}>
            <ExportExcel data={tmp} fileName="รายงานรายการแจ้งหนี้" />
        </div>;
        return (
            <div className="p-col-12">
            <Panel header="รายงานรายการแจ้งหนี้">
               
                <DataTable value={this.state.reportSearchInvoice} paginatorPosition="bottom"  paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                    responsive={true} //selection={this.state.dataTableSelection} onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                    style={{ textAlign: 'center' }} selectionMode="single"
                    header={exportExcel}
                    rowGroupMode="subheader" rowGroupHeaderTemplate={this.headerTemplate}
                    sortField="u3_fullname" sortOrder={1} groupField="u3_fullname"
                    rowGroupFooterTemplate={this.footerTemplate} style={{marginTop:'30px'}}
                    emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                    <label>ไม่พบข้อมูล</label>
                    </div>)}>
                    <Column field="invoice_code" header="เลขที่" filter={true} />
                    <Column field="u3_fullname" header="ชื่อร้าน" filter={true} />
                    <Column field="u1_fullname" header="ผู้ขาย" filter={true} />
                    <Column field="u2_fullname" header="ผู้ขนส่ง" filter={true} />
                    <Column field="product_name" header="รายการ" filter={true} />
                    <Column field="delivery_date" body={this.to_transfer_date.bind(this)} header="วันที่ส่ง" sortable={false} filter={true} className="p-text-center"/>
                    <Column field="admin_name" header="ผู้ดูแล" filter={true} />
                    <Column field="invoice_status_name" header="สถานะ" filter={true} style={{ textAlign: 'center'}}/>
                    <Column field="delivery_quantity_kk" header="ปริมาณส่ง (Kg)" filter={true} className="p-text-right" style={{ width: '7em'}}/>
                    <Column field='sale_price' body={this.salePriceTemplate} header="ราคาขาย (บาท)" filter={true} className="p-text-right" style={{ width: '7em'}}/>
                    <Column field='delivery_cost' body={this.deliveryCostTemplate} header="ค่าขนส่ง (บาท)" filter={true} className="p-text-right" style={{ width: '7em'}}/>
                    <Column field='process_fee' body={this.processFeeTemplate} header="ค่าบริการ (บาท)" filter={true} className="p-text-right" style={{ width: '7em'}}/>
                </DataTable>
            </Panel>
        </div>
        )
    }

    render() {
        const searchPanel = this.searchPanel();
        const invoiceTable = this.reportInvoiceTable();
        const ProgressSpinner = this.showProgressSpinner();
        const {
            dhbUsers,
        } = this.props
        return <div className="p-grid">
                <Growl ref={(el) => this.growl = el} />
                {searchPanel}
                {ProgressSpinner}
                {invoiceTable}  
            </div>
    }
}



const mapStateToProps = (state) => ({

	dhbUsers: state.dhbAdmin.dhbUsers
})

const mapDispatchToProps = dispatch => ({

	dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch)

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ReportInvoice)