import React, { Component } from 'react';
import PropsTyps from 'prop-types'
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';

export class WastetranOrderDetails extends Component {
    static defaultProps = {
        data:[],

        orderEditor:null,
        quantityEditor:null,
        remarkEditor:null
    }

    static propTypes = {
        data:PropsTyps.array,

        orderEditor:PropsTyps.func,
        quantityEditor:PropsTyps.func,
        remarkEditor:PropsTyps.func
    }
    
    //EVENT Edit
    orderEditor=(e)=>this.props.orderEditor(e);
    quantityEditor=(e)=>this.props.quantityEditor(e);
    remarkEditor=(e)=>this.props.remarkEditor(e);

    render() {

        const {
            data
        } = this.props
        return (
            <div className="p-grid">
                <div className="p-col-12">
                    <DataTable value={data} 
                    editable={true} 
                    responsive={true}  
                    header="รายการสินค้ารับ">
                        <Column field="order" header="รายการ" editor={this.orderEditor}  style={{height: '3.5em'}}/>
                        <Column field="quantity" header="ปริมาณเก็บ (Kg)" editor={this.quantityEditor} style={{height: '3.5em'}}/>
                        <Column field="remark" header="หมายเหตุ" editor={this.remarkEditor} style={{height: '3.5em'}}/>
                    </DataTable>
                </div>
                <div className="p-col-12">
                    <Button 
                        label="เพิ่มรายการ" 
                        icon="pi pi-plus" 
                        iconPos="right" 
                    />
                </div>
            </div>
        )
    }
}