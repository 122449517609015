import React, { Component } from 'react';

import { RadioButton } from 'primereact/radiobutton';
import { InputText } from 'primereact/inputtext';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Messages } from 'primereact/messages';
import { Message } from 'primereact/message';
import { InputSwitch } from 'primereact/inputswitch';

import * as Validators from '../../utils/validators'


import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { ToggleButton } from 'primereact/togglebutton';
import ChangePassword from '../change-password/ChangePassword';

import * as SecureUtils from '../../utils/secureUtils';

export class WastegenProfile extends Component {


    constructor(props) {
        super(props)
        this.state = {
            //Validate
            isValidateError: false,
            classNameRequired: 'p-col-12',

            isLoading: true,
            isUpdate: false,
            isLoadDist: false,
            isLoadSubDist: false,
            selectedProv: null,
            selectedDistrict: null,
            selectedSubDistrict: null,
            companyTypes: [
                { name: 'บริษัทจำกัด', code: 1 },
                { name: 'ห้างหุ้นส่วน', code: 2 },
                { name: 'บริษัทมหาชน', code: 3 },
                { name: 'ห้างร้าน', code: 4 },
            ],
            selectedCompanyType: null,
            locationRecycle: [
                { inputId: 1, value: "บ้านในโครงการจัดสรร" },
                { inputId: 2, value: "คอนโดมีเนียม / อพาร์ทเม้นท์" },
                { inputId: 3, value: "ร้านค้า / ร้านอาหาร" },
                { inputId: 4, value: "อื่นๆ" },
                { inputId: 5, value: "บ้านพักอาศัยทั่วไป" },
                { inputId: 6, value: "อาคารสำนักงาน / โรงงาน" },
                { inputId: 7, value: "สถานศึกษา / สถานที่ราชการ" }
            ],
            dummyDropdownBank: [
                { name: 'กสิกรไทย', code: '1' },
                { name: 'นครหลวงไทย', code: '2' },
                { name: 'กรุงเทพ', code: '3' },
                { name: 'ไทยพาณิทย์', code: '4' },
            ],
            selectedBank: null,
            personPrefixs: [
                { name: 'นาย', code: 1 },
                { name: 'นาง', code: 2 },
                { name: 'นางสาว', code: 3 },
            ],
            selectedPersonPrefix: null,
            marker: {},
            mapZoom: 11,
            mapCenter: { lat: 13.7734283, lng: 100.57383099999993 },
            //PASSWORD
            isShowChangePassword: false,
            isChangePassword: false,
            checkedPasswordVisible: false,
            typePasswordInput: 'password',
            errorMessageOldPassword: null,
            errorMessagePassword: null,
            errorMessageConfirmPassword: null,
            oldPassword: null,
            password: null,
            confirmPassword: null,
            disableNewPass: true
        }

        this.onMarkerMoved = this.onMarkerMoved.bind(this);
    }

    componentDidMount() {
        const { userServiceProps, locationServiceProps, userInfo } = this.props

        this.setState({ isLoading: true })
    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("WastegenProfile :componentWillReceiveProps", this.state)
        //> Transform props to state
        if (nextProps.userInfo) {
            if (
                !nextProps.userInfoMaster.isLoading
                && !nextProps.updateWastegenProfile.isLoading
                && !nextProps.changePassword.isLoading
                && !nextProps.locationMaster.isFetching
            ) {
                this.initialSelected(this.state);
            }
        }
    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("WastegenProfile :shouldComponentUpdate")
        if (nextProps.userInfo) {
            if (
                nextState.isLoading
                && !nextProps.userInfoMaster.isLoading
                && !nextProps.updateWastegenProfile.isLoading
                && !nextProps.changePassword.isLoading
                && !nextProps.locationMaster.isFetching
            ) {
                if (nextState.isUpdate) {
                    if (nextProps.updateWastegenProfile.isFailed) {
                        this.showError();
                    } else {
                        if (nextState.isShowChangePassword) {
                            if (!nextProps.changePassword.isFailed) {
                                this.initialForm(nextProps.updateWastegenProfile.data)
                                this.showSuccess();
                                this.initialSelected(nextProps.updateWastegenProfile.data);
                            } else {
                                this.showError(nextProps.changePassword.data)
                            }
                        } else {
                            this.initialForm(nextProps.updateWastegenProfile.data)
                            this.showSuccess();
                            this.initialSelected(nextProps.updateWastegenProfile.data);
                        }
                    }
                    this.setState({ isUpdate: false })
                    this.setState({ isLoading: false })
                } else {
                    this.initialForm(nextProps.userInfo)
                    this.initialSelected(nextProps.userInfo);
                }

                this.validateRequire(nextState);
            }
        }

        if (!nextProps.changePassword.isLoading) {
            if (nextProps.changePassword.isFailed) {
                if (nextState.isUpdate) {
                    return false;
                }
            }
        }



        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("WastegenProfile :componentWillUpdate")


        if (this.state.isValidateError != nextState.isValidateError) {
            this.validateRequire(nextState);
        }
        if (this.state.person_type != nextState.person_type) {
            this.validateRequire(nextState);
        }

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("WastegenProfile :componentDidUpdate")
        if (prevState.isChangePassword) {
            if (prevState.isUpdate) {
                if (!this.props.changePassword.isLoading) {
                    if (!this.props.changePassword.isFailed) {
                        if (!this.props.updateWastegenProfile.isLoading) {
                            this.props.userServiceProps.updateWastegenProfile(prevState)
                            this.setState({ isShowChangePassword: false })
                            this.setState({ isChangePassword: false })
                        }
                    }
                }
            }
        }
    }

    // ========= PREPARE =========
    initialForm(object) {
        if (object) {
            for (let key in object) {
                let val = object[key]
                this.setState({ [key]: val })
            }
            this.setState({ isLoading: false })
            if(object.latitude && object.longitude)
            this.setState({ marker: { lat: object.latitude, lng: object.longitude } })
        }
    }


    initialSelected = (state) => {

        const { province, district, subDistrict, locationServiceProps, locationMaster, banks } = this.props

        //person_prefix_code default :: check dropdown
        if (!state.selectedPersonPrefix &&state.person_prefix_code) {
            for (let key in this.state.personPrefixs) {
                let val = this.state.personPrefixs[key]
                if (state.person_prefix_code == val.code) {
                    this.setState({ selectedPersonPrefix: val })
                }
            }
        }
        //person_prefix_code default :: check dropdown
        if (!state.selectedCompanyType && state.org_type) {
            for (let key in this.state.companyTypes) {
                let val = this.state.companyTypes[key]
                if (state.org_type == val.code) {
                    this.setState({ selectedCompanyType: val })
                }
            }
        }
        //bank_code default :: check dropdown
        if (!state.selectedBank && state.bank_code) {
            for (let key in banks) {
                let val = banks[key]
                if (state.bank_code == val.bank_code) {
                    this.setState({ selectedBank: val })

                }
            }
        }
        //province default :: check dropdown
        if (!state.selectedProv && state.addr_current_province_code && !locationMaster.isFetching) {
            for (let key in province) {
                let val = province[key]
                if (state.addr_current_province_code == val.provinceCode) {
                    //console.log("province ")
                    this.setState({ selectedProv: val })
                    locationServiceProps.getDistrict(val.provinceCode)
                }
            }
        }
        //District default :: check dropdown
        if (!state.selectedDistrict && state.addr_current_district_code && !locationMaster.isFetching) {
            for (let key in district) {
                let val = district[key]
                if (state.addr_current_district_code == val.districtCode) {
                    //console.log("district ",val)
                    this.setState({ selectedDistrict: val })
                    locationServiceProps.getSubDistrict(val.districtCode)
                }
            }
        }
        //District default :: check dropdown
        if (!state.selectedSubDistrict && state.addr_current_subdistrict_code ) {
            subDistrict.map((val) => {
                if (state.addr_current_subdistrict_code == val.subDistrictCode) {
                    //console.log("subDistrict ",val)
                    this.setState({ selectedSubDistrict: val })
                }
            })
            for (let key in subDistrict) {
                const val = subDistrict[key]

            }
        }

    }

    // ======== Validate data =========
    //> Validate
    validateRequire = (state) => {
        this._classNameRequired(false)

        this._validateLogic(state.telephone_no1)
        if (state.person_type == '1') {
            this._validateLogic(state.person_prefix_code)
            this._validateLogic(state.person_first_name)
            this._validateLogic(state.person_last_name)
        } else {
            this._validateLogic(state.org_type)
            this._validateLogic(state.org_name)
        }

        //Password
        if (state.isChangePassword == true) {
            this._validateLogic(state.oldPassword);
            this._validateLogic(state.password);
            this._validateLogic(state.confirmPassword);
        }
    }
    _validateLogic = (value) => {
        if (Validators.isEmpty(value)) {
            this._classNameRequired(true)
        }
    }
    _classNameRequired = (status) => {
        const classNameRequired = status == true ? "p-error p-col-12" : "p-col-12";
        this.setState({ classNameRequired: classNameRequired })
        this.setState({ isValidateError: status })
    }

    // ========= Event =========
    onChangeInputText = (e) => {
        const { name, value, required } = e.target
        this.setState({ [name]: value })
        if (required) {
            if (Validators.isEmpty(value)) {
                this.setState({ isValidateError: true })
            } else {
                this.setState({ isValidateError: false })
            }
        }
    }

    onChangeProv = (e) => {
        const { locationServiceProps } = this.props
        const { value } = e.target
        this.setState({ selectedProv: value })
        this.setState({ addr_current_province_code: value.provinceCode });

        this.setState({ selectedDistrict: null })
        this.setState({ addr_current_province_code: null })
        this.setState({ selectedSubDistrict: null })
        this.setState({ addr_current_subdistrict_code: null })

        locationServiceProps.getDistrict(value.provinceCode);
        e.preventDefault()
    }

    onChangeDistrict = (e) => {

        const { locationServiceProps } = this.props
        const { value } = e.target
        this.setState({ selectedDistrict: value })
        this.setState({ addr_current_province_code: value.provinceCode });

        this.setState({ selectedSubDistrict: null })
        this.setState({ addr_current_subdistrict_code: null })

        locationServiceProps.getSubDistrict(value.districtCode);
        e.preventDefault()
    }


    onChangeSubDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedSubDistrict: value })
        this.setState({ addr_current_province_code: value.provinceCode });
        this.setState({ addr_current_district_code: value.districtCode });
        this.setState({ addr_current_subdistrict_code: value.subDistrictCode });
    }

    onChangePersonPrefix = (e) => {
        const { name, value } = e.target
        this.setState({ selectedPersonPrefix: value })
        this.setState({ [name]: value.code })
    }

    onChangeCompanyType = (e) => {
        const { name, value } = e.target
        this.setState({ selectedCompanyType: value })
        this.setState({ [name]: value.code })
    }

    onChanageBank = (e) => {
        const { name, value } = e.target
        this.setState({ selectedBank: value })
        this.setState({ [name]: value.bank_code })
    }

    // ======== SAVE =========
    onSubmit = (e) => {
        e.preventDefault();

        this.validateRequire(this.state);

        if (this.state.isValidateError) {
            this.showWarn();
        } else {
            if (this.state.isChangePassword) {
                var jsPass = {
                    username: SecureUtils.getItem('user').username,
                    oldPassword: this.state.oldPassword,
                    newPassword: this.state.password,
                    confirmPassword: this.state.confirmPassword,
                    statusMessage: "string",
                }
                this.props.userServiceProps.changePassword(JSON.parse(JSON.stringify(jsPass)))
            } else {
                this.props.userServiceProps.updateWastegenProfile(this.state)
                this.setState({ isShowChangePassword: false })
            }

            this.setState({ isUpdate: true })
            this.setState({ isLoading: true })
        }

    }

    // ======== MAP ========
    onMarkerMoved = (e) => {
        
            var coor = e.latLng.toJSON();
            this.setState({ latitude: coor.lat })
            this.setState({ longitude: coor.lng })
            this.setState({ marker: { lat: coor.lat, lng: coor.lng } })
            this.setState({ mapCenter: { lat: coor.lat, lng: coor.lng } })
    }
    

    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }

    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'สถานะ', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }


    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    // === EVENT CHANGE PASSWORD ===
    onChangeOldPassword = (e) => {
        let password = e.target.value;
        this.setState({ errorMessageOldPassword: null });
        if (Validators.isNotEmpty(password)) {
            this.setState({ errorMessageOldPassword: "" });
            this.setState({ errorMessagePassword: "กรุณาระบุรหัสผ่าน" });
            this.setState({ errorMessageConfirmPassword: "กรุณาระบุรหัสผ่าน" });
            this.setState({ isChangePassword: true })
            this.setState({ oldPassword: password })
            this.setState({ disableNewPass: false });
        } else {
            this.setState({ isChangePassword: false })
            this._classNameRequired(false);
            this.setState({ disableNewPass: true });

        }
    }

    onChangeNewPassword = (e) => {
        let password = e.target.value;
        this.setState({ errorMessagePassword: null });

        if (password.length >= 6) {
            const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
            const isOk = re.test(password);
            if (!isOk) {
                this.setState({ errorMessagePassword: "ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่" });
                this._classNameRequired(true)
            } else {
                this.setState({ password: password });
                this.setState({ errorMessagePassword: "" });
                if (password == this.state.confirmPassword) {
                    this.setState({ errorMessageConfirmPassword: "" });
                    this._classNameRequired(false)
                } else {
                    this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
                    this._classNameRequired(true)
                }
            }
        } else {
            this.setState({ errorMessagePassword: "กรุณากรอกอย่างน้อย 6 ตัวอักษร (ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่)" });
            this._classNameRequired(true)
        }
    }

    onChangeConfirmPassword = (e) => {
        let cfpassword = e.target.value;
        this.setState({ errorMessageConfirmPassword: "" });
        if (this.state.password == cfpassword) {
            this.setState({ confirmPassword: cfpassword });
            this.setState({ errorMessageConfirmPassword: "" });
            this._classNameRequired(false)
        } else {
            this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
            this.setState({ confirmPassword: cfpassword });

            this._classNameRequired(true)
        }
    }

    onChangePasswordVisible = (e) => {
        this.setState({ checkedPasswordVisible: e.value })
        if (e.value) {
            this.setState({ typePasswordInput: 'text' });
        } else {
            this.setState({ typePasswordInput: 'password' });
        }
    }

    onChangeSwitchPassword = (e) => {
        this.setState({ isShowChangePassword: e.value })
        if (this.state.isShowChangePassword) {
            console.log("isShow is ", this.state.isShowChangePassword)
            this.setState({ isChangePassword: false })
        }
    }

    // ========= RENDER =========
    render() {
        const { changePassword, banks, userInfoMaster, userInfo, province, district, subDistrict, updateWastegenProfile, locationServiceProps } = this.props

        console.log(this.state)


        //> loading all page
        let formInfomation = (
            <div>Loading...</div>
        )
        let contentBank = (
            <div>Loading...</div>
        )
        let contentContact = (
            <div>Loading...</div>
        )
        let contentLocation = (
            <div>Loading...</div>
        )
        let contentLogin = (
            <div>Loading...</div>
        )
        //console.log("load state ", this.state.isLoading)
        //console.log("update load ", updateWastegenProfile.isLoading)
        if (!userInfoMaster.isLoading && !updateWastegenProfile.isLoading) {

            //> Transform Dropdown to Object selected

            //> get Distrct and Sub Distict
            // if(this.state.isLoadDist&&district.length==0){
            //     locationServiceProps.getDistrict(this.state.addr_current_province_code)
            //     this.setState({isLoadSubDist:true})
            //     this.setState({isLoadDist:false})
            // }
            if (this.state.isLoadSubDist && subDistrict.length == 0) {
                let distCode = this.state.addr_current_district_code;
                //console.log("dist code ,",distCode)
                this.setState({ isLoadSubDist: false })
            }



            //> Manage page
            const personType = (
                <div className="p-grid">
                    <div className="p-col-12" style={{ textAlign: 'left' }}>
                        <label class="required">คำนำหน้าชื่อ</label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown
                            className={this.state.classNameRequired}
                            name="person_prefix_code"
                            value={this.state.selectedPersonPrefix}
                            options={this.state.personPrefixs}
                            onChange={this.onChangePersonPrefix}
                            placeholder="เลือกคำนำหน้า"
                            optionLabel="name"
                        />
                    </div>

                    <div style={{ marginTop: '.5em' }}></div>

                    <div className="p-col-12">
                        <label className="required">ชื่อ</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            required={true}
                            type="text"
                            className={this.state.classNameRequired}
                            value={this.state.person_first_name}
                            name="person_first_name"
                            placeholder="First Name"
                            onChange={this.onChangeInputText} />

                    </div>

                    <div className="p-col-12">
                        <label className="required">นามสกุล </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            required={true}
                            type="text"
                            className={this.state.classNameRequired}
                            name="person_last_name"
                            placeholder="Last Name"
                            value={this.state.person_last_name}
                            onChange={this.onChangeInputText} />
                    </div>

                    <div className="p-col-12">
                        <label>เลขบัตรประชาชน</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="p-col-12"
                            placeholder="Thai ID"
                            name="person_idcard"
                            maxlength="13"
                            keyfilter="pint"
                            value={this.state.person_idcard}
                            onChange={this.onChangeInputText}
                        ></InputText>
                    </div>
                </div>
            );

            const legalType = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label className="required">ประเภทนิติบุคคล</label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown
                            required={true}
                            className={this.state.classNameRequired}
                            name="org_type"
                            value={this.state.selectedCompanyType}
                            options={this.state.companyTypes}
                            onChange={this.onChangeCompanyType}
                            placeholder="เลือกประเภทนิติบุคคล"
                            optionLabel="name" />
                        <div style={{ marginTop: '.5em' }}></div>
                    </div>

                    <div className="p-col-12">
                        <label className="required">ชื่อนิติบุคคล</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            required={true}
                            type="text"
                            className={this.state.classNameRequired}
                            placeholder="Company Name"
                            value={this.state.org_name}
                            name="org_name"
                            onChange={this.onChangeInputText} />
                    </div>
                    <div className="p-col-12">
                        <label className="required">เลขนิติบุคคล</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            required={true}
                            className={this.state.classNameRequired}
                            placeholder="Company Id"
                            name="org_code"
                            value={this.state.org_code}
                            onChange={this.onChangeInputText}
                            maxlength="13"
                            keyfilter="pint"
                        ></InputText>
                    </div>

                </div>

            );

            //> ข้อมูลสมาชิก
            formInfomation = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label>ประเภทสมาชิก</label>
                    </div>
                    <div className="p-col-6">
                        <RadioButton inputId="rb1"
                            name="person_type" value="1"
                            onChange={this.onChangeInputText}
                            checked={this.state.person_type == '1'} />
                        <label htmlFor="rb1" className="p-radiobutton-label">บุคคลธรรมดา</label>

                    </div>
                    <div className="p-col-6">
                        <RadioButton inputId="rb2"
                            name="person_type" value="2"
                            onChange={this.onChangeInputText}
                            checked={this.state.person_type == '2'} />
                        <label htmlFor="rb2" className="p-radiobutton-label">นิติบุคคล</label>
                    </div>

                    <div className="p-col-12">
                        {this.state.person_type == '1' && personType}
                        {this.state.person_type == '2' && legalType}
                    </div>


                    <div className="p-col-12">
                        <label class="required">หมายเลขโทรศัพท์</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className={this.state.classNameRequired}
                            placeholder="Phone"
                            name="telephone_no1"
                            maxlength="10"
                            keyfilter="pint"
                            value={this.state.telephone_no1}
                            onChange={this.onChangeInputText}></InputText>
                    </div>
                    <div className="p-col-12">
                        <label>สถานที่เก็บขยะรีไซเคิล</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <RadioButton
                            inputId="cb1"
                            value="1"
                            name="location_type_code"
                            onChange={this.onChangeInputText}
                            checked={this.state.location_type_code == '1'} />
                        <label htmlFor="cb1" className="p-checkbox-label">บ้านในโครงการจัดสรร</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <RadioButton
                            name="location_type_code"
                            inputId="cb2"
                            value="2"
                            onChange={this.onChangeInputText}
                            checked={this.state.location_type_code == '2'}></RadioButton>
                        <label htmlFor="cb2" className="p-checkbox-label">คอนโดมีเนียม / อพาร์ทเม้นท์</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <RadioButton
                            name="location_type_code"
                            inputId="cb3"
                            value="3"
                            onChange={this.onChangeInputText}
                            checked={this.state.location_type_code == '3'}></RadioButton>
                        <label htmlFor="cb3" className="p-checkbox-label">ร้านค้า / ร้านอาหาร</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <RadioButton
                            name="location_type_code"
                            inputId="cb4"
                            value="4"
                            onChange={this.onChangeInputText}
                            checked={this.state.location_type_code == '4'}></RadioButton>
                        <label htmlFor="cb4" className="p-checkbox-label">บ้านพักอาศัยทั่วไป</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <RadioButton
                            name="location_type_code"
                            inputId="cb5"
                            value="5"
                            onChange={this.onChangeInputText}
                            checked={this.state.location_type_code == '5'}></RadioButton>
                        <label htmlFor="cb5" className="p-checkbox-label">อาคารสำนักงาน / โรงงาน</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <RadioButton
                            name="location_type_code"
                            inputId="cb6"
                            value="6"
                            onChange={this.onChangeInputText}
                            checked={this.state.location_type_code == '6'}></RadioButton>
                        <label htmlFor="cb6" className="p-checkbox-label">สถานศึกษา / สถานที่ราชการ</label>
                    </div>
                    <div className="p-col-12 p-md-6 p-lg-6" style={{ textAlign: 'left' }}>
                        <RadioButton
                            name="location_type_code"
                            inputId="cb7"
                            value="7"
                            onChange={this.onChangeInputText}
                            checked={this.state.location_type_code == '7'}></RadioButton>
                        <label htmlFor="cb7" className="p-checkbox-label">อื่นๆ</label>
                    </div>
                </div>
            );

            //> รายละเอียดบัญชีธนาคาร
            contentBank = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label >ชื่อบัญชี</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="p-col-12"
                            placeholder="Account Name"
                            name="bank_account_name"
                            value={this.state.bank_account_name}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                    <div className="p-col-12">
                        <label >เลขที่บัญชี</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="p-col-12"
                            placeholder="Account Number"
                            name="bank_account_no"
                            maxlength="13"
                            keyfilter="pint"
                            value={this.state.bank_account_no}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                    <div className="p-col-12">
                        <label >ธนาคาร</label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="bank_name"
                            name="bank_code"
                            value={this.state.selectedBank}
                            options={banks}
                            onChange={this.onChanageBank}
                            placeholder="เลือกธนาคาร" />
                    </div>

                    <div className="p-col-12">
                        <label >Prompt-pay</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="p-col-12"
                            placeholder="Prompt-pay"
                            name="bank_promptpay"
                            maxlength="13"
                            keyfilter="pint"
                            value={this.state.bank_promptpay}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                    <div className="p-col-12">
                        <label >รหัสเลขใช้สำหรับแจ้งถอนเงิน</label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="p-col-12"
                            placeholder="Pin Number"
                            name="bank_withdraw_code"
                            keyfilter="pint"
                            value={this.state.bank_withdraw_code}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                </div>
            );

            //> รายละเอียดที่อยู่ติดต่อ
            contentContact = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label >
                            จังหวัด
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="province_name"
                            value={this.state.selectedProv}
                            options={province}
                            onChange={this.onChangeProv}
                            placeholder="เลือกจังหวัด" />

                    </div>


                    <div className="p-col-12">
                        <label >
                            อำเภอ/เขต
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="districtName"
                            value={this.state.selectedDistrict}
                            options={district}
                            onChange={this.onChangeDistrict}
                            placeholder="เลือกอำเภอ/เขต"
                            disabled={!district} />

                    </div>

                    <div className="p-col-12">
                        <label >
                            ตำบล/แขวง
                    </label>
                    </div>
                    <div className="p-col-12">
                        <Dropdown style={{ width: '100%' }}
                            optionLabel="subDistrictName"
                            value={this.state.selectedSubDistrict}
                            options={subDistrict}
                            onChange={this.onChangeSubDistrict}
                            placeholder="เลือกตำบล/แขวง" />

                    </div>

                    <div className="p-col-12">
                        <label >
                            เลขที่บ้าน/ที่อยู่
                    </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="p-col-12"
                            placeholder="Address ID"
                            name="addr_current_detail"
                            value={this.state.addr_current_detail}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                    <div className="p-col-12">
                        <label >
                            Line ID
                    </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="p-col-12"
                            placeholder="Line ID"
                            name="line_id"
                            value={this.state.line_id}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>

                    <div className="p-col-12">
                        <label >
                            Email
                    </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            className="p-col-12"
                            placeholder="Email"
                            name="email"
                            value={this.state.email}
                            onChange={this.onChangeInputText}>
                        </InputText>
                    </div>
                </div>
            );


            //> Location google address
            contentLocation = (
                <div className="p-grid">
                    <div className="p-col-12">
                        <label >
                            xxx
                            Address location (latitude: {this.state.latitude} longitude:{this.state.longitude})
                    </label>
                    </div>

                    <div className="p-col-12">
                        <MapWithASearchBox
                            zoom={this.state.mapZoom}
                            center={this.state.mapCenter}
                            marker={this.state.marker}
                            onMarkerMoveEnd={this.onMarkerMoved} />

                    </div>
                </div>
            );

            //> ข้อมูล Login
            contentLogin = (
                <ChangePassword
                    {...this.state}
                    onChangeOldPassword={this.onChangeOldPassword}
                    onChangeNewPassword={this.onChangeNewPassword}
                    onChangeConfirmPassword={this.onChangeConfirmPassword}
                    onChangePasswordVisible={this.onChangePasswordVisible}
                />
            );
        }//End Tax check User Info


        return (
            <div>
                <h1>Profile</h1>
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid">
                    <div className="p-col-12 p-lg-6">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>ข้อมูลสมาชิก</h1>
                                {formInfomation}
                            </div>
                        </div>
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>รายละเอียดที่อยู่ติดต่อ</h1>
                                {contentContact}
                            </div>
                        </div>

                        {<div className="p-lg-12  p-md-12  p-sm-12">
                            <div className="card card-w-title">
                                <h1 className="p-col-12">
                                    <label>เปลี่ยนรหัสผ่าน</label>
                                    <InputSwitch
                                        style={{ float: 'right' }}
                                        checked={this.state.isShowChangePassword}
                                        onChange={this.onChangeSwitchPassword} />
                                </h1>
                                {this.state.isShowChangePassword == true && contentLogin}

                            </div>
                        </div>}
                    </div>

                    <div className="p-col-12 p-lg-6">
                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>รายละเอียดบัญชีธนาคาร</h1>
                                {contentBank}
                            </div>
                        </div>

                        <div className="p-col-12">
                            <div className="card card-w-title">
                                <h1>Location google address</h1>
                                {contentLocation}
                            </div>
                        </div>
                    </div>


                    <div className="p-col-12">
                        <Button className="p-col-12"
                            label="Save"
                            onClick={this.onSubmit}
                        ></Button>
                    </div>
                </div>
            </div>
        )
    }
}

