import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import * as UserService from '../service/user.service';
import { ProgressSpinner } from 'primereact/progressspinner';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import { ToggleButton } from 'primereact/togglebutton';
import {ScrollPanel} from 'primereact/scrollpanel';
import { Accordion, AccordionTab } from 'primereact/accordion';
import * as ServerAPI from '../components/administrator/service';

class FrequentlyAskedQuestions extends Component {

	constructor(props) {
		super(props);

		this.state = {
			faqList: [],
		};

		this.getAllfaq = this.getAllfaq.bind(this);
		this.faq = this.faq.bind(this);
	}

	componentDidMount() {
        this.getAllfaq();
	}

	getAllfaq(){
        this.setState({ showProgress: true })
        ServerAPI.getAllfaq().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({faqList: res })
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false})
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false})
            }
        }).catch((error) => {
            this.setState({ showProgress: false})
        })
	}
	
	faq(){
		const faq = this.state.faqList.map(item => 
							<AccordionTab header={item.question}>
								<p>{item.answer}</p>
							</AccordionTab>
						
			)
			return faq

		// return <div style={{ marginBottom: '10%',paddingLeft: '7px'}}>
		// 		<Accordion multiple={true}>
        //             <AccordionTab header="Application C3leng คืออะไร" >
        //                 <p>เป็น Application ที่สร้างขึ้นเพื่อเป็นช่องทางให้คนทุกลุ่ม ทุกเพศทุกวัย และองค์กรธุรกิจ มีส่วนร่วมในการรับผิดชอบต่อสิ่งแวดล้อมได้ง่าย 
		// 					สะดวกและรวดเร็ว โดยเข้าถึงการกำจัดขยะฯ ที่เหมาะสม
		// 				</p>
        //             </AccordionTab>
        //             <AccordionTab header="C3leng คืออะไร">
        //                 <p>C3leng เป็นตัวกลางให้ผู้ทิ้งขยะฯ หรือขายขยะฯ ด้วยการนัดหมายผู้ขนส่งไปรับขยะฯในเวลาและสถานที่นัดหมายและนำขยะฯ เข้าสู่ระบบการจัดการที่มีประสิทธิภาพ</p>
        //             </AccordionTab>
        //             <AccordionTab header="C3leng มีกระบวนการกำจัดขยะอย่างไร" disabled={false}>
        //                 <p>
		// 					<b>กระบวนการกำจัดขยะ Recycle ประกอบด้วยผู้เกี่ยวข้อง 3 ส่วน</b><br/>
		// 					- Waste Generator : ผู้ทิ้งหรือผู้ต้องการขาย ในที่นี้ก็คือ ผู้บริโภค<br/>
		// 					- Waste Transportation : ผู้ขนส่งขยะรีไซเคิล ในที่นี้ก็คือ ซาเล้ง รถเล็ก และผู้สนใจเข้าร่วมโครงการ<br/>
		// 					- Waste Processor : ศูนย์การจัดการขยะรีไซเคิล ในที่นี้คือ ศูนย์จัดการฯซีซาเล้งในภูมิภาคและพื้นที่ให้บริการ
		// 				</p>
        //             </AccordionTab>
        //         </Accordion>
		// 	</div>
	}

	render() {
		const showfaq = this.faq()
		return (
			<div className="login-body" >
				<div className="p-grid">
					<div className="p-col-12">
						<div className="card card-w-title" style={{ marginRight: '10%',marginTop: '2%'}}>
							<div className="login-panel-content">
								<div className="p-grid">
									<div className="p-col-9 p-md-10 p-lg-10">
										<h1 className="welcome-text" style={{marginLeft:'20px',marginTop:'20px', fontSize:'40px'}}>คำถามที่พบบ่อย</h1>
									</div >
									<div className="p-col-3 p-md-2 p-lg-2" style={{ textAlign: 'right', marginTop:'40px'}}>
										<a href="/#">หน้าหลัก</a>
									</div>
									<ScrollPanel style={{width: '100%', height: '500px'}}>
										<Accordion multiple={true}>
											{showfaq}
											</Accordion>
									</ScrollPanel>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({

})

const mapDispatchToProps = dispatch => ({

})


export default
	connect(
		mapStateToProps,
		mapDispatchToProps, null, { forwardRef: true })(FrequentlyAskedQuestions)