import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "primereact/button";
import { InputText } from "primereact/components/inputtext/InputText";
import { Growl } from "primereact/growl";
import { Panel } from "primereact/panel";
import moment from "moment";
import { Column } from "primereact/components/column/Column";
import { DataTable } from "primereact/components/datatable/DataTable";
import { Accordion, AccordionTab } from "primereact/accordion";
import { getWasteTranDonateHistory } from "../../service/wastetran.service";

class WastetranDonateHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
    };
  }

  componentDidMount() {
    this.props.dispatch(getWasteTranDonateHistory());
  }

  showSuccess = (message) => {
    this.growl.show({
      severity: "success",
      summary: "สถานะ",
      detail: message ? message : "ทำรายการสำเร็จ",
    });
  };
  showError = (message) => {
    this.growl.show({
      severity: "error",
      summary: "คำเตือน",
      detail: message ? message : "เกิดข้อผิดพลาด",
    });
  };
  showWarn = (message) => {
    this.growl.show({
      severity: "warn",
      summary: "คำเตือน",
      detail: message
        ? message
        : "กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน",
    });
  };

  showSearchPanel() {
    return (
      <Accordion multiple={false} activeIndex={[0, 1]}>
        <AccordionTab header="เงื่อนไขค้นหาประวัติการบริจาค">
          <div className="p-grid p-justify-start">
            <div
              className="p-col-12 p-md-2 p-lg-2"
              style={{ alignSelf: "center" }}
            >
              <label>ชื่อองค์กร :</label>
            </div>
            <div
              className="p-col-12 p-md-4 p-lg-4"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <InputText
                placeholder="ระบุชื่อองค์กร"
                name="environmental_account_no"
                maxlength="13"
                keyfilter="pint"
                value={this.state.environmental_account_no}
                onChange={(e) =>
                  this.setState({ environmental_account_no: e.target.value })
                }
              />
            </div>
            <div
              className="p-col-12 p-md-2 p-lg-2"
              style={{ alignSelf: "center" }}
            >
              <label>ชื่อบัญชี :</label>
            </div>
            <div
              className="p-col-12 p-md-4 p-lg-4"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <InputText
                placeholder="ระบุชื่อบัญชี"
                name="environmental_account_no"
                maxlength="13"
                keyfilter="pint"
                value={this.state.environmental_account_no}
                onChange={(e) =>
                  this.setState({ environmental_account_no: e.target.value })
                }
              />
            </div>
            <div
              className="p-col-12 p-md-2 p-lg-2"
              style={{ alignSelf: "center" }}
            >
              <label>เลขที่บัญชี :</label>
            </div>
            <div
              className="p-col-12 p-md-4 p-lg-4"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <InputText
                placeholder="ระบุเลขที่บัญชี"
                name="environmental_account_no"
                maxlength="13"
                keyfilter="pint"
                value={this.state.environmental_account_no}
                onChange={(e) =>
                  this.setState({ environmental_account_no: e.target.value })
                }
              />
            </div>
            <div
              className="p-col-12 p-md-2 p-lg-2"
              style={{ alignSelf: "center" }}
            >
              <label>ธนาคาร :</label>
            </div>
            <div
              className="p-col-12 p-md-4 p-lg-4"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <InputText
                placeholder="ระบุธนาคาร"
                name="environmental_account_no"
                maxlength="13"
                keyfilter="pint"
                value={this.state.environmental_account_no}
                onChange={(e) =>
                  this.setState({ environmental_account_no: e.target.value })
                }
              />
            </div>
            <div
              className="p-col-12 p-md-2 p-lg-2"
              style={{ alignSelf: "center" }}
            >
              <label>วันที่ทำการบริจาค :</label>
            </div>
            <div
              className="p-col-12 p-md-4 p-lg-4"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <InputText
                placeholder="ระบุวันที่ทำการบริจาค"
                name="environmental_account_no"
                maxlength="13"
                keyfilter="pint"
                value={this.state.environmental_account_no}
                onChange={(e) =>
                  this.setState({ environmental_account_no: e.target.value })
                }
              />
            </div>
            <div
              className="p-col-12 p-md-2 p-lg-2"
              style={{ alignSelf: "center" }}
            >
              <label>จำนวนเงิบบริจาค :</label>
            </div>
            <div
              className="p-col-12 p-md-4 p-lg-4"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <InputText
                placeholder="ระบุจำนวนเงิบบริจาค"
                name="environmental_account_no"
                maxlength="13"
                keyfilter="pint"
                value={this.state.environmental_account_no}
                onChange={(e) =>
                  this.setState({ environmental_account_no: e.target.value })
                }
              />
            </div>

            <div className="p-col-12">
              <br />
              <Button
                style={{ marginRight: "20px" }}
                label="ค้นหา"
                icon="fa fa-search"
                iconPos="left"
              />
              <Button
                style={{ backgroundColor: "#f0ad4e" }}
                type="submit"
                label="ยกเลิก"
              />
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    );
  }

  render() {
    let showSearchPanel = this.showSearchPanel();
    let itemHistory = [];
    if (
      this.props.wastetranDonateHistory &&
      !this.props.wastetranDonateHistory.isLoading
    ) {
      if (!this.props.wastetranDonateHistory.isFailed) {
        for (let key in this.props.wastetranDonateHistory.data) {
          let entity = this.props.wastetranDonateHistory.data[key];
          let tmpData = {
            gid: entity.gid,
            org_name:entity.org_name,
            waste_type: entity.wasteType,
            waste_uid: entity.waste_uid,
            waste_name: entity.waste_name,
            bank_name: entity.bank_name,
            bank_code: entity.bank_code,
            bank_account_no: entity.bank_account_no,
            bank_account_name: entity.bank_account_name,
            bank_promptpay: entity.bank_promptpay,
            donate_thb: entity.donate_thb,
            transaction_img: entity.transaction_img,
            approver_date: entity.approver_date,
            requested_date: moment(entity.requested_date).format("DD/MM/YYYY"),
            approver_uid: entity.approver_uid,
          };
          itemHistory.push(tmpData);
        }
      }
    }

    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} />
        {/* {showSearchPanel} */}
        <h1>ประวัติการบริจาค</h1>
        <div className="p-grid">
          <div className="p-col-12">
            <Panel header="เลือกองค์กรเพื่อบริจาค">
              <DataTable
                paginator={true}
                rows={5}
                rowsPerPageOptions={[5, 10, 15]}
                value={itemHistory}
                dataKey="id"
                responsive={true}
                emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)}
              >
                
                <Column field="org_name" header="ชื่อองค์กร" sortable />
                <Column field="bank_account_name" header="ชื่อบัญชี" sortable />
                <Column field="bank_account_no" header="เลขบัญชี" sortable />
                <Column field="bank_name" header="ธนาคาร" sortable />
                <Column
                  field="requested_date"
                  header="วันที่ทำการบริจาค"
                  sortable
                  className="p-text-center"
                />
                <Column
                  field="donate_thb"
                  header="จำนวนเงิบบริจาค (บาท)"
                  sortable
                  className="p-text-center"
                />
              </DataTable>
            </Panel>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  wastetranDonateHistory: state.wastetranDonateHistory,
});

export default connect(mapStateToProps)(WastetranDonateHistory);
