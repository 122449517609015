import React, { Component } from 'react';
import { connect } from 'react-redux';

import { WastegenOverviews } from '../wastegen-dashboard/WastegenOverviews';
import { WastegenTimelines } from '../wastegen-dashboard/WastegenTimelines';

import { getBalance,getWastegenDashLastOrder,getWastegenDashProduct } from '../../service/wastegen.service'
import { userAnnounce } from '../../service/user.service'
import { WasteGenNewView } from '../waste-news/WasteGenNewView';
import { DatatableDashBoardView } from '../data-table/DatatableDashBoardView';

import { API_ROOT } from '../../constants/api';
import * as ServerAPI from '../../components/administrator/service';




class WastegenDashboard extends Component {

    constructor(props) {
        console.log("WastegenDashboard :constructor")
        super(props);
        this.state = {
            dummySuccessDataTable: [
                { order: 'ขวดพลาสติกใส', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'ขวดแก้ว', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'อลูมิเนียม', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'กระดาษ', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'กล่องนม', ranking: '0', amount: '0', quantity: '0 kg.' },
            ],
        }

        this.onClickRefresh=this.onClickRefresh.bind(this);
        this.getNewsList = this.getNewsList.bind(this);
    }

    componentDidMount() {
        this.getNewsList();
        console.log("WastegenDashboard :componentDidMount")
        this.props.dispatch(getBalance());
        this.props.dispatch(userAnnounce());
        this.props.dispatch(getWastegenDashLastOrder());
        this.props.dispatch(getWastegenDashProduct());
    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("WastegenDashboard :componentWillReceiveProps")
    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("WastegenDashboard :shouldComponentUpdate")

        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("WastegenDashboard :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("WastegenDashboard :componentDidUpdate")
    }

    //> EVENT
    onClickRefresh = (e)=>{
        this.props.dispatch(getWastegenDashLastOrder());
    }
    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    getNewsList(){
        this.setState({ showProgress: true })
        ServerAPI.getAllNews().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    var list = [];
                    
                    res.forEach((obj,index) => {
                        let userType = []
                        let wastegen = '';
                        let wastetran = '';
                        let wastepro = '';
                        let userTypeLabel = '';
                        let newFileObj = []
                        if(obj.isWasteGen){
                            let type = {name: 'ผู้รักษ์โลก', code: '1'}
                            userType.push(type)
                            wastegen = 'ผู้รักษ์โลก'
                        }
                        if(obj.isWasteTran){
                            let type = {name: 'ทีมขนส่งซีซาเล้ง', code: '2'}
                            userType.push(type)
                            wastetran = 'ทีมขนส่งซีซาเล้ง'
                        }
                        if(obj.isWastePro){
                            let type = {name: 'ศูนย์จัดการฯซีซาเล้ง', code: '3'}
                            userType.push(type)
                            wastepro = 'ศูนย์จัดการฯซีซาเล้ง'
                        }

                        if(wastegen && wastetran && wastepro){
                            userTypeLabel = 'ทุกกลุ่ม'
                        }else{
                            userTypeLabel = wastegen+"\n"+wastetran+"\n"+wastepro
                        }

                        obj.newsImageDetails.forEach(item => {
                            let newValiable = {
                                defaultImage: item.defaultImage,
                                gid: item.gid,
                                name: item.imageName,
                                imageName: item.imageName,
                                isDeleted: item.isDeleted,
                            }
                            newFileObj.push(newValiable)
                        });

                        let arr = {
                            gid: obj.gid,
                            newsCode: obj.newsCode,
                            newsTitle: obj.newsTitle,
                            updateDate: obj.updateDate,
                            createdDate: obj.createdDate,
                            newsDetail: obj.newsDetail,

                            defaultImageIndex: obj.defaultImageIndex,
                            defaultImageName: obj.defaultImageName,
                            newsImageDetails: newFileObj,

                            userType: userType,
                            userTypeLabel: userTypeLabel,
                            isWasteGen: obj.isWasteGen,
                            isWasteTran: obj.isWasteTran,
                            isWastePro: obj.isWastePro,
                        }
                        list.push(arr)

                    });
                    console.log(list)
                    this.setState({ newsDataList: list })
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false})
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false})
            }
        }).catch((error) => {
            this.setState({ showProgress: false})
        })
    }


    render() {
        const {
            wastegenBalance,
            userAnnounce,
            getWastegenDashLastOrder,
            getWastegenDashProduct
        } = this.props
        //> ข่าวสาร
        const contentNews = (
            <WasteGenNewView
            newsDataList = {this.state.newsDataList}
            />
        );

        //Overview
        let summaryBalance = 0;
        let successBalance = 0;
        let noSuccessBalance = 0;
        let carbonCreditSummary = 0;
        let donateSummary = 0;
        if (
            !wastegenBalance.isLoading
            && wastegenBalance.data
            && !wastegenBalance.isFailed
        ) {
            summaryBalance = wastegenBalance.data.income_summary_thb.toFixed(2);
            successBalance = Math.abs(wastegenBalance.data.withdraw_summary_thb.toFixed(2));
            noSuccessBalance = wastegenBalance.data.withdraw_balance_thb.toFixed(2);
            carbonCreditSummary = wastegenBalance.data.carbon_credit_summary.toFixed(2);
            donateSummary = wastegenBalance.data.donate_summary_thb.toFixed(2);
        }
        const contentOverview = (
            <WastegenOverviews
                summaryBalance={summaryBalance}
                successBalance={successBalance}
                noSuccessBalance={noSuccessBalance}
                carbonCreditSummary={carbonCreditSummary}
                donateSummary={donateSummary}
            />
        );

        //> ความสำเร็จของคุณ
        let dataList = []
        if(!getWastegenDashProduct.isLoading && !getWastegenDashProduct.isFailed){
            if(getWastegenDashProduct.data && getWastegenDashProduct.data.length>0){
               for(let key in getWastegenDashProduct.data){
                   let entity = getWastegenDashProduct.data[key]
                   let prepareData = { 
                       order: entity.product_type_name, 
                       quantity: entity.quantity_kk
                    };
                    dataList.push(prepareData);
               }
            }
        }
        const contentSuccessDataTable = (
            <DatatableDashBoardView data={dataList}/>
        );

        //> รายการล่าสุด
        let contentTimeline = (
            <div className="card timeline p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <label>
                            <i className="fa fa-star" style={{ color: '#f6ac2b' }}></i>
                            ไม่พบคำสั่งงาน
                        </label>
                    </div>

                </div>
            </div>
        );

        if(!getWastegenDashLastOrder.isLoading && !getWastegenDashLastOrder.isFailed){
            if(getWastegenDashLastOrder.data){
                contentTimeline = (
                    <WastegenTimelines 
                        data={getWastegenDashLastOrder.data}
                        onClickRefresh={this.onClickRefresh}
                    />
                )
            }
        }

        return (
            <div>
                
                {contentNews}
                <h1>Dashboard</h1>
                <div style={{ marginTop: '1em' }} />
                <div className="p-grid dashboard">
                    <div className="p-col-12 p-lg-8">
                        {contentOverview}
                        <div style={{ marginTop: '1em' }} />
                        {contentSuccessDataTable}
                    </div>
                    <div className="p-col-12 p-lg-4">
                        {contentTimeline}
                    </div>
                </div>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    wastegenBalance: state.wastegenBalance,
    userAnnounce: state.userAnnounce,
    getWastegenDashLastOrder:state.getWastegenDashLastOrder,
    getWastegenDashProduct:state.getWastegenDashProduct
})

export default connect(mapStateToProps)(WastegenDashboard)