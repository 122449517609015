import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'

import {
  location,
  getArrChipDistrict
} from './locationReducer'
import reportAdmin from './rptAdminReducer'
import dhbAdmin from './dhbAdminReducer'
import {authentication} from './authen.reducer'
import {
  master,
  settingParameter
} from './masterReducer'
import {userInfo,
  updateWastegenProfile,
  updateWasteproProfile,
  updateWastetranProfile,
  changePassword,
  userAnnounce,
  userCert
} from './userinfo.reducer'
import {
  deleteWasteTranFile,
  deleteWasteproFile
} from './file.reducer'
import {
  orderSearch,
  orderAutoComplete
} from './order.reducer'

import {
  wastegenBalance,
  wastegenWithdraw,
  wastegenWithdrawHistory,
  getWastegenDashLastOrder,
  getWastegenDashProduct,
  wastegenChangePin,
  wastegenDonate,
  wastegenTransfer,
  wastegenDonateHistory,
  wastegenRegisWastetran
} from './wastegen.reducer'

import {
  wastetranBalance,
  wastetranWithdraw,
  wastetranWithdrawHistory,
  wastetranAutoOrder,
  wastetranOrderSearchNoti,
  getWastetranDashProduct,
  getWastetranDashOrderStat,
  wastetranChangePin,
  wastetranDonate,
  wastetranTransfer,
  wastetranDonateHistory,
  wastetranRegisWastegen
} from './wastetran.reducer'

import {
  wasteproOrderSearch,
  getWasteproDashPayment,
  getWasteproDashProduct,
  getWasteproDashSummary,
  getWasteproDashOrderStat
} from './wastepro.reducer'

import {
  autoCompleteAdminAll,
  autoCompleteProduct,
  autoCompleteWasteall,
  autoCompleteWastegen,
  autoCompleteWastetran,
  autoCompleteWastepro,
} from './autocomplete.reducer'

import {
  adminAnnounceAdd,
  getAdminDashSummaryArea,
  getAdminDashSummaryAreaSearch,
  getAdminDashSummaryWithdraw,
  getUsersAllForManagement, 
  adminCertTemplate,
  adminCertResult,
  getUsersAll,
} from './admin.reducer'

import {
  chatMessageByRoom,
  chatList,
  chatSingleBadge,
} from './chatReducer'

export default combineReducers({
  form: formReducer,
  location,
  getArrChipDistrict,
  reportAdmin,
  dhbAdmin,
  authentication,
  userInfo,
  updateWastegenProfile,
  updateWasteproProfile,
  updateWastetranProfile,
  master,

  changePassword,

  //> ORDER
  orderSearch,
  orderAutoComplete,

  //>Wastegen
  wastegenBalance,
  wastegenWithdraw,
  wastegenWithdrawHistory,
  getWastegenDashLastOrder,
  getWastegenDashProduct,
  wastegenChangePin,
  wastegenDonate,
  wastegenTransfer,
  wastegenDonateHistory,
  wastegenRegisWastetran,
  //>Wastetran
  wastetranBalance,
  wastetranWithdraw,
  wastetranWithdrawHistory,
  wastetranAutoOrder,
  wastetranOrderSearchNoti,
  getWastetranDashProduct,
  getWastetranDashOrderStat,
  wastetranChangePin,
  wastetranDonate,
  wastetranTransfer,
  wastetranDonateHistory,
  wastetranRegisWastegen,
  //>Wastepro
  wasteproOrderSearch,
  getWasteproDashPayment,
  getWasteproDashProduct,
  getWasteproDashSummary,
  getWasteproDashOrderStat,
  //>Auto complate
  autoCompleteAdminAll,
  autoCompleteProduct,
  autoCompleteWasteall,
  autoCompleteWastegen,
  autoCompleteWastetran,
  autoCompleteWastepro,
  //>ADMIN
  adminAnnounceAdd,
  getAdminDashSummaryArea,
  getAdminDashSummaryAreaSearch,
  getAdminDashSummaryWithdraw,
  getUsersAllForManagement,
  adminCertTemplate,
  adminCertResult,
  getUsersAll,
  //>USER
  userAnnounce,
  userCert,
  //>File
  deleteWasteTranFile,
  deleteWasteproFile,
  //>CHAT
  chatMessageByRoom,
  chatList,
  chatSingleBadge,
  //>MASTER
  settingParameter
})
