import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';

import { InputText } from 'primereact/components/inputtext/InputText';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';




export class WastetranNotiWithdraw extends Component {

    constructor() {
        super();
        this.state = {



            status: '0',
            status2: '0',
            statusItem: [
                { label: 'เลือกทั้งหมด', value: '0' },
                { label: 'รอนัดหมาย', value: '1' },
                { label: 'รอทีมขนส่ง', value: '2' },
                { label: 'รอส่งสินค้า', value: '3' },
                { label: 'ปิด Order', value: '4' },
                { label: 'ยกเลิก', value: '5' },
                { label: 'ผิดนัด', value: '6' },
            ],

            manageTable: [
                1, 2, 3, 4, 5, 6, 7
            ],
            orderDetail: [
                1, 2, 3, 4, 5
            ],
            acceptFileExt: '.jpg,.jpeg,.png,.pdf,.JPG,.JPEG,.PNG,.PDF',
        };

    }

  
    render() {
      

       

        return (


            // <div>
            //     <h1>แจ้งถอนเงิน</h1>
            //     <div className="p-grid">
            //         <div className="p-lg-12  p-md-12  p-sm-12">
            //             <div className="card card-w-title">
            //                 <span >จะใช้งานได้ภายในเดือน มกราคม 2563</span>
            //             </div>
            //         </div>
            //     </div>
            // </div>


            <div className="p-grid dashboard" >
               
                    
                <div className="p-col-12 p-md-6 p-lg-4" style={{textAlign:'center'}}>
                    <div className="p-grid overview-box overview-box-3" >
                        <div className="overview-box-title">
                            <i className="fa fa-folder"></i>
                            
                        </div >
                        <div className="p-col-12" style={{textAlign:'center'}}>
                            <span className="overview-title">ยอดเงินค้างเบิก</span>
                        </div>
                        <div className="overview-box-count">10,000 บาท</div>
                        
                    </div>
                </div>

                <div className="p-col-12 p-md-3 p-lg-3" style={{ alignSelf: 'center' }}>
                        <label>ยอดเงินที่ต้องการถอน :</label>
                    
                        <InputText style={{marginBottom:'5px'}} placeholder="10,000"></InputText>
                    
                        <Button type="button" label="แจ้งถอนเงิน" style={{ width: '200px' }}></Button>
                    </div>

                    <div className="p-col-12">
                        <Panel header="ประวัติการเบิกถอน" >
                            <DataTable  value={this.state.manageTable} paginatorPosition="bottom" selectionMode="single"  rows={5}
                                responsive={true} selection={this.state.dataTableSelection} onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                                scrollable={true}  
                            >
                                <Column field="1" header="วันที่แจ้ง" sortable={true} />
                                <Column field="2" header="วันที่โอน" sortable={true} />
                                <Column field="3" header="จำนวนเงิน" sortable={true} />
                                <Column field="4" header="ชื่อบัญชี" sortable={true} />
                                <Column field="5" header="เลขที่บัญชี" sortable={true} />
                                <Column field="6" header="ธนาคาร" sortable={true} />
                                <Column field="7" header="สถานะ" sortable={true} />
                            </DataTable>
                        </Panel>
                    </div>

                </div>
          
        )
    }
}