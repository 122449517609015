import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Panel } from "primereact/components/panel/Panel";
import { Checkbox } from "primereact/components/checkbox/Checkbox";
import { Button } from "primereact/components/button/Button";
import { RadioButton } from 'primereact/radiobutton';
import { InputText } from "primereact/components/inputtext/InputText";
import { InputTextarea } from "primereact/components/inputtextarea/InputTextarea";
import { DataTable } from "primereact/components/datatable/DataTable";
import { Column } from "primereact/components/column/Column";
import { FullCalendar } from "primereact/fullcalendar";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Growl } from "primereact/growl";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { MapWithASearchBox } from "../../features/map/MapWithASearchBox";
import { MapMarkers } from "../../features/map/MapMarkers";
import { Calendar } from "primereact/calendar";
import { AutoComplete } from "primereact/autocomplete";
import { Avatar } from "../../features/widget/Avatar";
import { API_ROOT } from "../../constants/api";
import { ProgressSpinner } from "primereact/progressspinner";
import * as ServerAPI from "./service";
import * as LocationService from "../../service/LocationService";
import * as UserService from "../../service/user.service";
import * as MasterService from "../../service/MasterService";
import * as FileService from "../../service/file.service";
import * as DashBoardService from "./service";
import { isEmpty } from "../../utils/validators";
import { CEInputText, CECalendar, CEDropdown } from "../../features/input";
import { CEFileUpload } from "../../features/widget";
import { Dialog } from "primereact/dialog";
import { Font } from "@react-pdf/renderer";

class ManageProductType extends Component {
  static propTypes = {};
  constructor() {
    super();
    this.state = {
      addressDetail: "",
      priceList: [],
      memberStore: 0,
      brandSuggestions: null,
      wasteTypeSelected: "",
      searchProductType: "",
      searchProduct: "",
      productSelected: "",
      priceSelected1: "",
      productTypeNameUpdate: "",

      memberStore1: [
        { label: "เลือกประเภทสินค้า", value: 0 },
        { label: "กระดาษ", value: 1 },
        { label: "พลาสติก", value: 2 },
        { label: "ประเภทที่ 2", value: 3 },
      ],
      memberStore3: 0,
      memberStore4: [
        { label: "เลือกสินค้า", value: 0 },
        { label: "กระดาษขาวดำ", value: 1 },
        { label: "กระดาษจับจั๊ว", value: 2 },
        { label: "กระดาษกล่องลัง", value: 3 },
      ],

      testData: [
        {
          nameStore: "ร้านมารวย",
          product_type: "กระดาษ",
          product: "กระดาษขาว",
          buy_per_kk: "10",
        },
        {
          nameStore: "ร้านมาจน",
          product_type: "พลาสติก",
          product: "ขวดน้ำพลาสติก",
          buy_per_kk: " 8",
        },
      ],
      isActive: [{ is_active: "ใช้งาน" }, { is_active: "ไม่ใช้งาน" }],
      isActiveSelected: {is_active: "ใช้งาน" },
      reportSearchOrders: [],
      showProgress: false,
      activeIndex: null,

      productTypeName:'',
    };
    this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
    this.onMemberNameChanged = this.onMemberNameChanged.bind(this);
    this.onMemberName1Changed = this.onMemberName1Changed.bind(this);
    this.onSelectMemberName = this.onSelectMemberName.bind(this);
    this.onSelectMemberName1 = this.onSelectMemberName1.bind(this);
    this.onproductsChange = this.onproductsChange.bind(this);
    this.onsearchProductChange = this.onsearchProductChange.bind(this);
    this.onWasteTypeChange = this.onWasteTypeChange.bind(this);
    this.onsearchProductTypeChange = this.onsearchProductTypeChange.bind(this);
    this.clearSearchInput = this.clearSearchInput.bind(this);
    this.onValidation = this.onValidation.bind(this);
    this.searchProductList = this.searchProductList.bind(this);
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.onWeightUpdate = this.onWeightUpdate.bind(this);
    this.onproductTypeUpdate = this.onproductTypeUpdate.bind(this);
    this.updateStatus = this.updateStatus.bind(this);
  }
  componentDidMount() {
    this.getAllProductType();
  }
  showProgressSpinner() {
    const Progress = (
      <div className="p-col-12 p-md-8 p-lg-9">
        <ProgressSpinner
          style={{ width: "80px", height: "80px" }}
          strokeWidth="8"
          fill="#EEEEEE"
          animationDuration="0.5s"
        />
      </div>
    );

    if (this.state.showProgress) {
      return Progress;
    } else return <div />;
  }
  searchProductList() {
    this.setState({ showProgress: true });
    var search = this.state.productTypeName || ''
    ServerAPI.searchProductTypeList(search)
      .then((res) => {
        console.log(res);
        if (res) {
          var index = res.toString().indexOf("Error:");
          console.log("find error:" + index);
          if (index == -1) {
            this.setState({ priceList: res });
            // this.getAllProductType();
            // this.clearAllDataInput();
            this.setState({ showProgress: false });
            // this.showSuccess();
          } else {
            this.showWarn('กรุณากรอก "ชื่อประเภทสินค้า" ');
            this.getAllProductType();
            this.setState({ showProgress: false });
          }
        } else {
          this.showWarn();
          this.setState({ showProgress: false });
        }
      })
      .catch((error) => {
        this.setState({ showProgress: false });
      });
  }

  getAllProductType() {
    this.setState({ showProgress: true });
    ServerAPI.getAllProductType()
      .then((res) => {
        console.log(res);
        if (res) {
          var index = res.toString().indexOf("Error:");
          console.log("find error:" + index);
          if (index == -1) {
            this.setState({ priceList: res });
            this.setState({ priceListForValidExit: res });
            this.setState({ showProgress: false });
            //this.showSuccess();
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        } else {
          this.showWarn();
          this.setState({ showProgress: false });
        }
      })
      .catch((error) => {
        this.setState({ showProgress: false });
      });
  }

  itemTemplate(item) {
    return <div className="p-clearfix">{item.search_name}</div>;
  }

  itemTemplate1(item) {
    return <div className="p-clearfix">{item.search_name}</div>;
  }

  onSelectMemberName(item) {
    this.setState({ memberName: item.value.search_name });
    this.setState({ memberUid: item.value.uid });
    // this.setState({u1_uid: item.value.uid})
    // this.getProfile(item.value.uid)
  }
  onSelectMemberName1(item) {
    console.log(item);
    this.setState({ memberName1: item.value.search_name });
    this.setState({ memberUid1: item.value.uid });
    // this.setState({u1_uid: item.value.uid})
    // this.getProfile(item.value.uid)
    console.log(item);
  }
  suggestMemberName(event) {
    console.log(event);
    /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

    //this.setState({ brandSuggestions: event });
  }
  suggestMemberName1(event) {
    console.log(event);
    /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

    //this.setState({ brandSuggestions: event });
  }

  onMemberNameChanged(search_name) {
    console.log(search_name);
    console.log(typeof search_name);
    if (typeof search_name != "object")
      if (search_name.trim() != "") {
        this.setState({ memberName: search_name });
        if (search_name.length >= 2) {
          ServerAPI.searchwastepro(search_name)
            .then((res) => {
              console.log(res);
              this.setState({ memberSuggestions: res });
            })
            .catch((error) => {});
        }
      } else {
        this.setState({ memberName: search_name });
        this.setState({ memberUid: null });
      }
  }
  onMemberName1Changed(search_name) {
    console.log(search_name);
    console.log(typeof search_name);
    if (typeof search_name != "object")
      if (search_name.trim() != "") {
        this.setState({ memberName1: search_name });
        if (search_name.length >= 2) {
          ServerAPI.searchwastepro(search_name)
            .then((res) => {
              console.log(res);
              this.setState({ memberSuggestions: res });
            })
            .catch((error) => {});
        }
      } else {
        this.setState({ memberName1: search_name });
        this.setState({ memberUid1: null });
      }
  }

  onproductTypeUpdate(e) {
    console.log(e);
    this.setState({ productTypeObjUpdate: e.value });
  }
  onWeightUpdate(e) {
    console.log(e);
    this.setState({ productObjUpdate: e.value });
  }

  actionTemplate(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          onClick={() => this.onEditProdcutType(column.rowData)}
          icon="pi pi-pencil"
          className="p-button"
        />

        <Button
          type="button"
          onClick={() => this.onproductPriceDelete(column.rowData)}
          icon="fa fa-trash"
          className="p-button-warning"
        />
      </div>
    );
  }
  statusTemplate(rowData, column) {
    var label = 'test';
    var classname = 'p-button';
    var icons = 'pi pi-eye';
    if(rowData.is_active == false){
        label = 'ไม่ใช้งาน';
        classname = 'p-button-warning';
        icons = 'pi pi-times';
    }
    if(rowData.is_active == true){
        label = 'ใช้งาน';
        classname = 'p-button';
        icons = 'pi pi-check';
   }
    return <div>
        <Button type="button" label={label} onClick={() => this.onStatusUpdate(column.rowData)} icon={icons} className={classname} ></Button>
    </div>;
  }
  onClickDeleteItem(rowData) {
    
  }

  onClick(itemIndex) {
    let activeIndex = this.state.activeIndex ? [...this.state.activeIndex] : [];

    if (activeIndex.length === 0) {
      activeIndex.push(itemIndex);
    } else {
      const index = activeIndex.indexOf(itemIndex);
      if (index === -1) {
        activeIndex.push(itemIndex);
      } else {
        activeIndex.splice(index, 1);
      }
    }

    this.setState({ activeIndex });
  }

  update() {
    var isNotinput =
    this.state.productTypeNameUpdate == "" ||
    this.state.isNotValid == true;

  if (isNotinput) {
    this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
    this.setState({ showProgress: false });
  } else {
    let isValid = false;
    this.state.priceListForValidExit.forEach((item) => {
      if (item.product_type_name == this.state.productTypeNameUpdate && item.product_type_code != this.state.productTypeCodeUpdate){
        isValid = true;
      }
    });

    if (isValid) {
      this.showWarn("ชื่อประเภทสินค้าซ้ำกับรายการอื่น");
      this.setState({ showProgress: false });
    } else {

      const formData = new FormData();
        if(this.state.fileImgNameUpdateNew){
            formData.append('ProductWasteTypeImages', this.state.fileImgNameUpdateNew);
          }
      formData.set('gid', this.state.productTypeGidUpdate);
      formData.set('product_type_code', this.state.productTypeCodeUpdate);
      formData.set('product_type_name', this.state.productTypeNameUpdate);
      formData.set('is_active', this.state.productPrice.is_active);
      // let data = {
      //   gid:this.state.productTypeGidUpdate,
      //   product_type_code: this.state.productTypeCodeUpdate,
      //   product_type_name: this.state.productTypeNameUpdate,
      //   is_active: isActive,
      // };
      this.setState({ showProgress: true });
      ServerAPI.updateProductType(formData)
        .then((res) => {
          console.log(res);
          if (res) {
            var index = res.toString().indexOf("Error:");
            console.log("find error:" + index);
            if (index == -1) {
              this.getAllProductType();
              this.setState({ displayDialog: false });
              this.clearAllDataInput();
              this.showSuccess();
            } else {
              this.showWarn();
              this.setState({ showProgress: false });
            }
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        })
        .catch((error) => {
          this.setState({ showProgress: false });
        });
    }
  }
  }
  delete() {
    this.setState({ showProgress: true });

    let id = this.state.productTypeGidDelete
    
    ServerAPI.removeProductType(id)
        .then((res) => {
          console.log(res);
          if (res) {
            var index = res.toString().indexOf("Error:");
            console.log("find error:" + index);
            if (index == -1) {
              this.getAllProductType()
              this.setState({ showProgress: false });
              this.showSuccess();
            } else {
              this.showWarn();
              this.setState({ showProgress: false });
            }
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        })
        .catch((error) => {});
    this.setState({ deleteDialog: false });
  }
  updateStatus(){
      this.setState({ statusDialog: false });
        const formData = new FormData();

        formData.set('gid', this.state.productPrice.gid);
        formData.set('product_type_code', this.state.productPrice.product_type_code);
        formData.set('product_type_name', this.state.productPrice.product_type_name);
        formData.set('is_active', this.state.productPrice.is_active);
        this.setState({ showProgress: true });
        ServerAPI.updateProductType(formData)
          .then((res) => {
            console.log(res);
            if (res) {
              var index = res.toString().indexOf("Error:");
              console.log("find error:" + index);
              if (index == -1) {
                this.getAllProductType();
                this.setState({ displayDialog: false });
                this.clearAllDataInput();
                this.showSuccess();
              } else {
                this.showWarn();
                this.setState({ showProgress: false });
              }
            } else {
              this.showWarn();
              this.setState({ showProgress: false });
            }
          })
          .catch((error) => {
            this.setState({ showProgress: false });
          });
      
  }
  updateProperty(property, value) {
    let productPrice = this.state.productPrice;
    productPrice[property] = value;
    this.setState({ productPrice: productPrice });
  }

  onEditProdcutType = (e) => {
    let isActiveSelected = { is_active: e.is_active };
    this.setState({
      displayDialog: true,
      productTypeGidUpdate:e.gid,
      productTypeCodeUpdate: e.product_type_code,
      productTypeNameUpdate: e.product_type_name,
      productTypeIsActiveUpdate: isActiveSelected,
      fileImgNameUpdate : e.image_name,
      fileImgNameUpdateNew : null,
      productPrice: Object.assign({}, e),
    });
  };

  onproductPriceDelete(e) {
    let isActiveSelected = { is_active: e.is_active };
    let _disableDelete = false;
    if(e.is_used){
        _disableDelete = true
    }
    this.setState({
      deleteDialog: true,
      productTypeGidDelete:e.gid,
      productTypeCodeDelete: e.product_type_code,
      productTypeNameDelete: e.product_type_name,
      productTypeIsActiveDelete: isActiveSelected,
      disableDelete: _disableDelete,
      productPrice: Object.assign({}, e),
    });
  }
  onStatusUpdate(e){
    console.log(e)
    this.setState({
        statusDialog: true,
        isNotValid: false,
        productPrice: Object.assign({}, e),
    });
  }

  onValidation(valid) {
    console.log(valid);
    if (!valid) this.setState({ isNotValid: true });
    else this.setState({ isNotValid: false });
  }

  onWasteTypeChange(e) {
    this.setState({ wasteTypeSelected: e.value });
    this.setState({ productTypeCode: e.value.product_type_code });
    const { masterServiceProps, product } = this.props;
    masterServiceProps.getProduct(e.value.product_type_code);
  }

  onIsActiveChanged = (e) => {
    console.log(e);
    this.setState({ isActiveSelected: e.value });
  };
  onIsActiveUpdate = (e) => {
    this.setState({ productTypeIsActiveUpdate: e.value });
  };

  onsearchProductTypeChange(e) {
    this.setState({ searchProductType: e.value });
    this.setState({ searchProductTypeCode: e.value.product_type_code });
    const { masterServiceProps, product } = this.props;
    masterServiceProps.getProduct(e.value.product_type_code);
  }

  showSuccess = (message) => {
    this.growl.show({
      severity: "success",
      summary: "สถานะ",
      detail: message ? message : "ทำรายการสำเร็จ",
    });
  };
  showWarn = (message) => {
    this.growl.show({
      severity: "warn",
      summary: "สถานะ",
      detail: message ? message : "มีบางอย่างผิดพลาด กรุณาตรวจสอบ",
    });
  };

  addDataToTable = () => {
    this.setState({ showProgress: true });
    var isNotinput =
      this.state.isActiveSelected == "" ||
      this.state.memberName == "" ||
      // this.state.select_img == "" ||
      this.state.isNotValid == true;

    if (isNotinput) {
      this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
      this.setState({ showProgress: false });
    } else {
      let isValid = false;
      this.state.priceListForValidExit.forEach((item) => {
        if (item.product_type_name == this.state.memberName) {
          isValid = true;
        }
      });

      if (isValid) {
        this.showWarn("ชื่อประเภทสินค้าซ้ำกับรายการอื่น");
        this.setState({ showProgress: false });
      } else {
        let isActive = true;
        if (this.state.isActiveSelected.is_active === "ไม่ใช้งาน") {
          isActive = false;
        }

        const formData = new FormData();

        formData.append('ProductWasteTypeImages', this.state.select_img);
        formData.set('product_type_name', this.state.memberName || '');
        formData.set('is_active', isActive );
        

        // var newProductType = {
        //   product_type_name: this.state.memberName,
        //   is_active: isActive,
        // };

        this.clearAllDataInput()

        ServerAPI.addProductType(formData)
          .then((res) => {
            console.log(res);
            if (res) {
              var index = res.toString().indexOf("Error:");
              console.log("find error:" + index);
              if (index == -1) {
                this.getAllProductType()
                this.showSuccess();
              } else {
                this.showWarn();
                this.setState({ showProgress: false });
              }
            } else {
              this.showWarn();
              this.setState({ showProgress: false });
            }
          })
          .catch((error) => {
            this.setState({ showProgress: false });
          });
      }
    }
  };
  onproductsChange(e) {
    this.setState({ productSelected: e.value });
  }
  onsearchProductChange(e) {
    this.setState({ searchProduct: e.value });
    this.setState({ searchProductCode: e.value.product_code });
    this.setState({ searchProductName: e.value.product_name });
  }

  clearAllDataInput() {
    this.setState({ memberName: "" });
    this.setState({ isActiveSelected: "" });
    this.setState({ select_img: "" });
  }

  clearSearchInput() {
    this.getAllProductType();
    this.setState({ memberName1: null }); //order code from select table
    this.setState({ memberUid1: null });
    this.setState({ searchProductType: null }); //order code from auto complete
    this.setState({ searchProduct: null });
    this.setState({ searchProductTypeCode: null });
    this.setState({ searchProductCode: null });
    this.setState({ productTypeName: '' });

    console.log(this.state.searchProductType);
    console.log(this.state.searchProduct);
    console.log(this.state.searchProductTypeCode);
    console.log(this.state.searchProductCode);

    this.clearAllDataInput();
  }

  showSearchPanel() {
    return (
      <Accordion multiple={true} activeIndex={[0, 1]}>
        <AccordionTab header="เงื่อนไขค้นหารายการประเภทสินค้า">
          <div className="p-grid p-justify-start">
            {/* <div
              className="p-col-12 p-md-2 p-lg-1"
              style={{ alignSelf: "center" }}
            >
              <label>รหัสประเภทสินค้า:</label>
            </div>
            <div
              className="p-col-12 p-md-10 p-lg-11"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <InputText
                style={{ width: "20%" }}
                placeholder="ระบุรหัสประเภทสินค้า"
              />
            </div> */}
            <div
              className="p-col-12 p-md-2 p-lg-2"
              style={{ alignSelf: "center"}}
            >
              <label>ชื่อประเภทสินค้า :</label>
            </div>
            <div
              className="p-col-12 p-md-10 p-lg-10"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <InputText
                style={{ width: "20%" }}
                placeholder="ระบุชื่อประเภทสินค้า"
                value={this.state.productTypeName}
                onChange={(e) => this.setState({productTypeName: e.target.value})}
              />

            </div>
            {/* <div
              className="p-col-12 p-md-2 p-lg-1"
              style={{ alignSelf: "center" }}
            >
              <label>สถานะการใช้งาน:</label>
            </div>
            <div
              className="p-col-12 p-md-10 p-lg-11"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <Dropdown
                style={{ width: "20%" }}
                value={this.state.isActiveSelected}
                options={this.state.isActive}
                onChange={this.onIsActiveChanged}
                placeholder="เลือกสถานะการใช้งาน"
                optionLabel="is_active"
              />
            </div> */}

            <div className="p-col-12">
              <br />
              <Button
                style={{ marginRight: "20px" }}
                label="ค้นหา"
                icon="fa fa-search"
                iconPos="left"
                onClick={this.searchProductList}
              />

              <Button
                style={{ backgroundColor: "#f0ad4e" }}
                type="submit"
                label="ยกเลิก"
                onClick={this.clearSearchInput}
              />
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    );
  }


  onSelectTemplateImage = (e) => {
    console.log(e.files[0])
    this.setState({select_img : e.files[0] })
    
}

  addNewProductTab() {
    let fileImg;

        if(this.state.select_img){
            fileImg = this.state.select_img.objectURL
        }
    return (
      <Accordion 
        // activeIndex={0}
      >
        <AccordionTab header="เพิ่มประเภทสินค้า">
          <div className="p-grid">
            <label
              className="p-col-2 "
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              ประเภทสินค้า :
            </label>
            <div className="p-col-10 ">
              <InputText
                style={{ width: "20%" }}
                placeholder="ระบุประเภทสินค้า"
                value={this.state.memberName}
                onChange={(e) => this.setState({ memberName: e.target.value })}
              />
            </div>

            <label
              className="p-col-2 "
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              สถานะการใช้งาน :
            </label>
            <div className="p-col-10">
              <Dropdown
                style={{ width: "20%" }}
                value={this.state.isActiveSelected}
                options={this.state.isActive}
                onChange={this.onIsActiveChanged}
                placeholder="เลือกสถานะการใช้งาน"
                optionLabel="is_active"
              />
            </div>
            <div className="p-col-6">
                <FileUpload
                    name="wastes"
                    onSelect={(e) => this.onSelectTemplateImage(e)}
                    chooseLabel="แนบรูปไอคอน"
                    accept="image/*"
                    mode="basic"
                    auto={true}
                />
            </div>
            <div className="p-col-12">
                <img src={fileImg} width='100px'></img>
            </div>

            <div className="p-col-12 ">
              <Button
                style={{ float: "left" }}
                label="เพิ่มรายการ"
                icon="fa fa-plus"
                onClick={this.addDataToTable}
              />
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    );
  }

  onOpenFiles(e){
    console.log(e)
        this.setState({
        filesDialog:true,
        filesData: Object.assign({}, e),
        fileImgName : e.image_name,
    });
}
  openFiles(rowData, column) {
    return <div>
        <Button type="button" onClick={() => this.onOpenFiles(column.rowData)} icon="pi pi-images" label="" className="p-buttong" ></Button>
    </div>;
}

onSelectTemplateImageUpdate = (e) => {
  console.log(e.files[0])
  this.setState({fileImgNameUpdateNew : e.files[0] })
  
}
  showWeightTable() {

    let link = `${API_ROOT}/master/producttype/getfile/${this.state.fileImgNameUpdate}`


    if(this.state.fileImgNameUpdateNew){
        link = this.state.fileImgNameUpdateNew.objectURL
    }

    let dialogFooter = (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="บันทึก" icon="pi pi-check" onClick={this.update} />
        <Button
          label="ยกเลิก"
          icon="pi pi-times"
          onClick={() => this.setState({ displayDialog: false })}
          className="p-button-warning"
        />
      </div>
    );
    let deleteDialogFooter = (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="ใช่" icon="pi pi-check" onClick={this.delete} disabled={this.state.disableDelete}/>
        <Button
          label="ไม่ใช่"
          icon="pi pi-times"
          onClick={() => this.setState({ deleteDialog: false })}
          className="p-button-warning"
        />
      </div>
    );
    let statusDialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
      <Button label="บันทึก" icon="pi pi-check" onClick={this.updateStatus}/>
      <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({statusDialog: false})} className="p-button-warning"/>
    </div>;
    const weightTable = (
      <div className="p-col-12">
        <Panel header="รายการประเภทสินค้า">
          <DataTable
            value={this.state.priceList}
            paginatorPosition="bottom"
            selectionMode="single"
            paginator={true}
            rows={15}
            rowsPerPageOptions={[5, 10, 15]}
            selection={this.state.selectedCar1}
            onSelectionChange={(e) => this.setState({ selectedCar1: e.value })}
            responsive={true}
            emptyMessage={
              <div style={{ textAlign: 'center', color: 'red' }}>
                <label>ไม่พบข้อมูล</label>
              </div>
            }
          >
            <Column field="product_type_code" header="รหัสประเภทสินค้า" sortable={true} filter={true} className="p-text-center"/>
            <Column field="product_type_name" header="ชื่อประเภทสินค้า" sortable={true} filter={true} className="p-text-center" />
            <Column field="is_active" header="สถานะการใช้งาน" body={this.statusTemplate.bind(this)} sortable={true} className="p-text-center" />
            <Column body={this.openFiles.bind(this)} header="รูปภาพไอคอน" className="p-text-center" style={{ width: '9em' }} />
            <Column field="gid" body={this.actionTemplate.bind(this)} header="จัดการ" className="p-text-center" style={{ width: "10%" }} />
          </DataTable>
        </Panel>
        <Dialog
          visible={this.state.deleteDialog}
          style={{width:'40vw'}}
          header="ยืนยันการลบข้อมูล"
          modal={true}
          footer={deleteDialogFooter}
          onHide={() => this.setState({ deleteDialog: false })}
        >
          {this.state.productTypeCodeDelete && (
            <div className="p-grid">
              <div className="p-col-12"><h3>รายละเอียดประเภทสินค้า</h3></div>
              
              <div className="p-col-4">
                <label style={{marginLeft:10}} htmlFor="product_type_name">รหัสประเภทสินค้า :</label>
              </div>
              <div className="p-col-8">
                <strong>{this.state.productTypeCodeDelete}</strong>
              </div>

              <div className="p-col-4">
                <label style={{marginLeft:10}} htmlFor="product_type_name">ชื่อประเภทสินค้า :</label>
              </div>
              <div className="p-col-8">
                <strong>{this.state.productTypeNameDelete}</strong>
              </div>

              <div className="p-col-4">
                <label style={{marginLeft:10}} htmlFor="product_type_name">สถานะการใช้งาน :</label>
              </div>
              <div className="p-col-8">
                <strong>{this.state.productTypeIsActiveDelete.is_active ? 'ใช้งาน' : 'ไม่ใช้งาน'}</strong>
              </div>

              {this.state.productPrice.is_used && 
                <div className="p-col-12" style={{textAlign: 'center'}} >
                    <span style={{color: 'red'}}>*****สินค้านี้ไม่สามารถลบได้ เนื่องจากมีการซื้อ-ขายสินค้านี้*****</span>
                </div>}
            </div>
          )}
        </Dialog>
        <Dialog
          visible={this.state.displayDialog}
          style={{ width: "40vw" }}
          header="แก้ไขข้อมูล"
          modal={true}
          footer={dialogFooter}
          onHide={() => this.setState({ displayDialog: false })}
        >
          {this.state.productTypeCodeUpdate && (
            <div className="p-grid" style={{marginBottom: '65px'}}>
              <div className="p-col-4">
                <label htmlFor="product_type_name">รหัสประเภทสินค้า :</label>
              </div>
              <div className="p-col-8">
                <strong>{this.state.productTypeCodeUpdate}</strong>
              </div>

              <div className="p-col-4">
                <label htmlFor="product_type_name">ชื่อประเภทสินค้า :</label>
              </div>
              <div className="p-col-8">
                <InputText
                  style={{ width: "50%" }}
                  placeholder="ระบุรหัสประเภทสินค้า"
                  value={this.state.productTypeNameUpdate}
                  onChange={(e) => {
                    this.setState({ productTypeNameUpdate: e.target.value });
                  }}
                />
              </div>
              {/* <div className="p-col-4">
                <label htmlFor="product_type_name">สถานะการใช้งาน :</label>
              </div>
              <div className="p-col-8">
                <div style={{ width: "50%" }}>
                  <Dropdown
                    value={this.state.productTypeIsActiveUpdate}
                    options={this.state.isActive}
                    onChange={this.onIsActiveUpdate}
                    optionLabel="is_active"
                  />
                </div>
              </div> */}
              <div className="p-col-4">
                <label htmlFor="product_type_name">รูปไอคอน :</label>
              </div>
                <div className="p-col-8">
                  <img  src={link}  onError={(e) => e.target.src='assets/layout/images/exception/image-not-found.png'} width='180px'></img>
                </div>

                <div className="p-col-6">
                    <FileUpload
                        name="wastes[]"
                        onSelect={(e) => this.onSelectTemplateImageUpdate(e)}
                        chooseLabel="แนบรูปไอคอน"
                        accept="image/*"
                        mode="basic"
                        auto={true}
                    />
                </div>
            </div>
          )}
        </Dialog>
        <Dialog 
            visible={this.state.filesDialog} 
            style={{width: '30vw'}} 
            header="รูปภาพไอคอนประเภทสินค้า" 
            modal={true}  
            onHide={() => this.setState({filesDialog: false})}
            dismissableMask={true}>
                <div className="p-grid p-fluid" style={{marginBottom: '60px'}}>
                    <img width='100%' style={{alignItems:'center'}} src={`${API_ROOT}/master/producttype/getfile/${this.state.fileImgName}`} onError={(e) => e.target.src='assets/layout/images/exception/image-not-found.png'} />
                </div>
        </Dialog>

        <Dialog dismissableMask={true} visible={this.state.statusDialog} 
            header="สถานะ" modal={true} footer={statusDialogFooter} 
            onHide={() => this.setState({statusDialog: false})}
            style={{width:'30vw'}}
            >
            { this.state.productPrice &&
            <div className="p-grid" >
                <div className="p-col-12" style={{ padding: '1em' }}>
                    <label style={{ marginRight: '5px' }}>สถานะสินค้า : </label>
                </div>
                <br/>
                <br/>
                <div className="p-col-12 p-md-6 p-lg-6">
                    <div className="p-radiobutton-label">
                        <RadioButton inputId="rb2" name="isVerified" value={true} style={{marginBottom:'10px'}}
                            onChange={(e) => {this.updateProperty('is_active', e.target.value)}}
                            checked={this.state.productPrice.is_active === true} />
                        <label htmlFor="rb2" className="p-radiobutton-label">ใช้งาน</label>
                </div>
                    </div>
                    
                <div className="p-col-12 p-md-6 p-lg-6">
                    <div className="p-radiobutton-label">
                        <RadioButton inputId="rb1" name="isVerified" value={false} style={{marginBottom:'10px'}}
                            onChange={(e) => {this.updateProperty('is_active', e.target.value)}}
                            checked={this.state.productPrice.is_active === false} />
                        <label htmlFor="rb1" className="p-radiobutton-label">ไม่ใช้งาน</label>
                    </div> 
                </div>
                
            </div>
            }
        </Dialog>
      </div>
    );
    if (!this.state.showProgress) return weightTable;
  }

  onSubmit() {}

  render() {
    const searchPanel = this.showSearchPanel();
    const addnew = this.addNewProductTab();
    const weightTablePanel = this.showWeightTable();
    const showProgress = this.showProgressSpinner();

    return (
      <div className="p-grid ">
        <Growl ref={(el) => (this.growl = el)} />
        {searchPanel}
        {addnew}
        {showProgress}
        {weightTablePanel}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  dhbUsers: state.dhbAdmin.dhbUsers,
  locationMaster: state.location,
  provinces: state.location.province,
  districts: state.location.district,
  subDistricts: state.location.subDistrict,
  cardProvince: state.location.cardProvince,
  cardDistrict: state.location.cardDistrict,
  cardSubDistrict: state.location.cardSubDistrict,
  authentication: state.authentication,
  userInfo: state.userInfo.data,
  userInfoMaster: state.userInfo,
  updateWasteproProfile: state.updateWasteproProfile,
  banks: state.master.banks,
  productType: state.master.productType,
  product: state.master.product,
  productType1: state.master.productType,
  product1: state.master.product,
  getFileWastepro: state.getFileWastepro,
});

const mapDispatchToProps = (dispatch) => ({
  dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
  locationServiceProps: bindActionCreators(LocationService, dispatch),
  userServiceProps: bindActionCreators(UserService, dispatch),
  masterServiceProps: bindActionCreators(MasterService, dispatch),
  fileServiceProps: bindActionCreators(FileService, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ManageProductType);
