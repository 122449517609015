import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames';
import { AppTopbar } from './../AppTopbar';
import { AppMenu } from './../AppMenu';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import 'primereact/resources/primereact.min.css';
// import 'fullcalendar/dist/fullcalendar.css';
import 'font-awesome/css/font-awesome.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import style from './style.css';


import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'
import * as ChatService from '../../service/ChatService'
import { addNewChatMessage } from '../../service/ChatService'

import { WastetranNotiWithdraw } from './WastetranNotiWithdraw';
import { WastetranProfile } from './WastetranProfile';
import WastetranManageOrder from './WastetranManageOrder';
import WastetranManageAccept from './WastetranManageAccept';
import { WastetranReportBuying } from './WastetranReportBuying';
import { WastetranInlineProfile } from './WastetranInlineProfile';
import PropsRoute from '../../PropsRoute';
import ExampleWastetranProfileCtrl from './ExampleWastetranProfileCtrl';
import ExampleWastetranChangePassword from './ExampleWastetranChangePassword';
import ExampleWastetranChangePin from './ExampleWastetranChangePin';
import ExampleWastetranNotiWithdrawCtrl from './ExampleWastetranNotiWithdrawCtrl';
import ExampleWastetranReportBuyingCtrl from './ExampleWastetranReportBuyingCtrl';
import ExampleWastetranReportAmountCtrl from './ExampleWastetranReportAmountCtrl';
import ExampleWastetranNotiRecycleCtrl from './ExampleWastetranNotiRecycleCtrl';
import WastetranDashboard from './WastetranDashboard';
import WastetranChatAdmin from './WastetranChatAdmin';
import WastetranChatView from './WastetranChatView';
import WastetranChatWithU1 from './WastetranChatWithU1';
import WastetranChatWithU3 from './WastetranChatWithU3';
import WastetranContact from './WastetranContact';
import WastetranNewsDetail from './WastetranNewsDetail';
import {
    connectWebsocket,
    implementWebsocket,
    closeWebsocket,
    navigateToAdminChatScene,
    navigateToUserChatScene,
    sendMessage,
  } from '../../utils/chat'
import { WASTE_TYPE, WASTE_TYPE_NUM_TO_NAME } from '../../constants/mappingValue'
import WastetranDonate from './WastetranDonate';
import WastetranDonateHistory from './WastetranDonateHistory';
import { getBadgeAdmin } from './service'
import {Dialog} from 'primereact/dialog';
import { Button } from 'primereact/components/button/Button';
import * as ServiceAPI from './service';
import { get } from 'lodash'

class WastetranContainer extends Component {
    static propTypes = {

    }

    static defaultProps = {
    }

    componentDidMount() {
        const { locationServiceProps, userServiceProps, masterServiceProps, ChatServiceProps } = this.props

        locationServiceProps.getProvice();
        userServiceProps.getUserInfo();
        locationServiceProps.getCardProvice();
        locationServiceProps.getChipProvice();
        masterServiceProps.getBanks();
        ChatServiceProps.getBadgeAdmin();

    }

    componentWillReceiveProps(nextProps) {
        console.log("WastegenContainer :componentWillReceiveProps")
        if (
            nextProps.userInfo
            && !nextProps.locationMaster.isFetching
            && this.tickerLoad
            && !nextProps.getArrChipDistrict.isLoading
        ) {
            if (nextProps.userInfo.addr_current_province_code) {
                nextProps.locationServiceProps.getDistrict(nextProps.userInfo.addr_current_province_code);
            }
            if (nextProps.userInfo.addr_current_district_code) {
                nextProps.locationServiceProps.getSubDistrict(nextProps.userInfo.addr_current_district_code);
            }
            if (nextProps.userInfo.addr_card_province_code) {
                nextProps.locationServiceProps.getCardDistrict(nextProps.userInfo.addr_card_province_code);
            }
            if (nextProps.userInfo.addr_card_district_code) {
                nextProps.locationServiceProps.getCardSubDistrict(nextProps.userInfo.addr_card_district_code);
            }
            if(nextProps.userInfo){
                const currentUserInfo = nextProps.userInfo.user_wasteTypes.find(
                    item => item.wasteType === WASTE_TYPE['wastetran'],
                )
                console.log(currentUserInfo)
                if(currentUserInfo.profileStatusCode != 99) this.setState({userNotCompleteDialog: true})
            }

            if (nextProps.userInfo){
                this.manageChatWebsocket(nextProps.userInfo,'wastetran')
            }else {
                closeWebsocket()
            }
            //chip
            // if(nextProps.userInfo.areaList){
            //     if(nextProps.userInfo.areaList.length>0){
            //         let provCode;
            //         for(let key in nextProps.userInfo.areaList){
            //             let entity = nextProps.userInfo.areaList[key];
            //             let provinceCode = entity.provinceCode;
            //             if(provinceCode){
            //                 provCode=provinceCode
            //             }
            //         }
            //         if(provCode){
            //             nextProps.locationServiceProps.getChipDistrict(provCode);
            //         }
            //     }
            // }
            // let arrProv = []
            // if (nextProps.userInfo.areaList) {
            //     if (nextProps.userInfo.areaList.length > 0) {
            //         let provCode;
            //         for (let key in nextProps.userInfo.areaList) {
            //             let entity = nextProps.userInfo.areaList[key];
            //             let distCode = entity.distCode;
            //             let provinceCode = entity.provinceCode;
            //             let tmpObj = { districtCode: distCode, provinceCode: provinceCode }
            //             let chkPvCode = arrProv.filter((rs) => {
            //                 return rs.provinceCode == provinceCode
            //             })
            //             if (chkPvCode.length == 0) {
            //                 arrProv.push(tmpObj);
            //             }
            //         }
                    
            //         nextProps.locationServiceProps.getArrChipDistrict(arrProv);
                    
            //     }
            // }


            this.tickerLoad = false;
            
        }
        if(!nextProps.chatSingleBadge.isLoading){
            getBadgeAdmin().then((res)=>{
                    this.createMenu(nextProps.userInfo,res);
                    this.setState({badge: res})
                })
            
        }
    }

    constructor() {
        super();
        this.tickerLoad = true;
        this.state = {
            layoutMode: 'static',
            profileMode: 'inline',
            layoutCompact: true,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            rotateMenuButton: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkMenu: false,
            menuActive: false,
            theme: 'green',
            layout: 'green',
            version: 'v3',
            displayDialog: false,
            userNotCompleteDialog: false,
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.createMenu();
    }

    manageChatWebsocket = (userInfo, userWasteType) => {
        const currentUserInfo = userInfo.user_wasteTypes.find(
          item => item.wasteType === WASTE_TYPE[userWasteType],
        )
        const senderId = currentUserInfo.uid
        const senderType = currentUserInfo.wasteType

        const onMessage = (message, currentRouteName) => {
            addNewChatMessage(message)
            getBadgeAdmin().then((res)=>{
                this.createMenu(userInfo,res);
                this.setState({badge: res})
            })
        //   if (currentRouteName === 'ChatScene') {
        //     addNewChatMessage(message)
        //   } else {
        //       console.log('fadeInMessageBanner(message)')
        //       //fadeInMessageBanner(message)
        //   }
        }
        connectWebsocket(senderId, senderType)
        implementWebsocket(onMessage)
    }

    onMenuClick(event) {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            rotateMenuButton: !this.state.rotateMenuButton,
            topbarMenuActive: false
        }));

        if (this.state.layoutMode === 'overlay') {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        }
        else {
            if (this.isDesktop())
                this.setState({ staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive });
            else
                this.setState({ staticMenuMobileActive: !this.state.staticMenuMobileActive });
        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({ activeTopbarItem: null });
        else
            this.setState({ activeTopbarItem: event.item });

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if (get(event, 'item.href', null))
        {
            window.open(get(event, 'item.href', null))
        }
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
        if (!event.item.items && this.isHorizontal()) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    onDocumentClick(event) {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

        if (!this.rightPanelClick) {
            this.setState({
                rightPanelActive: false
            })
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            rotateMenuButton: false,
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    changeTheme(theme) {
        this.setState({ theme: theme });
        if (this.state.version === 'v3') {
            this.changeStyleSheetUrl('theme-css', theme, 'theme');
        } else {
            this.changeStyleSheetUrl('theme-css', theme + '-v4', 'theme');
        }
    }

    changeLayout(layout, special) {
        this.setState({ layout: layout });
        if (this.state.version === 'v3') {
            this.changeStyleSheetUrl('layout-css', layout, 'layout');
        } else {
            this.changeStyleSheetUrl('layout-css', layout + '-v4', 'layout');
        }

        if (special) {
            this.setState({
                darkMenu: true
            })
        }
    }

    changeVersion(version) {
        this.setState({ version: version });
        if (version === 'v3') {
            this.changeStyleSheetUrl('layout-css', this.state.layout, 'layout');
            this.changeStyleSheetUrl('theme-css', this.state.theme, 'theme');
        } else {
            this.changeStyleSheetUrl('layout-css', this.state.layout + '-v4', 'layout');
            this.changeStyleSheetUrl('theme-css', this.state.theme + '-v4', 'theme');
        }
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');
        element.setAttribute('href', newURL);
    }

    createMenu(userInfo,badges) {
        if (userInfo) {
        this.menu = [
            { label: 'Dashboard', icon: 'fa fa-fw fa-home', to: '/wastetran' },
            { label: 'แชทกับแอดมิน', icon: 'fa fa-comments', to: '/Wastetran/admin-chat', badge: badges || null  },
            //{ label: 'chat with U1', icon: 'fa fa-comments', to: '/Wastetran/chat-wastegen' },
            //{ label: 'chat with U3', icon: 'fa fa-comments', to: '/Wastetran/chat-wastepro' },
            { label: 'แจ้งรับขยะรีไซเคิล', icon: 'fa fa-fw fa-circle', to: '/wastetran/manage-orders' },
            { label: 'แจ้งถอนเงิน', icon: 'fa fa-fw fa-gg', to: '/wastetran/noti-withdraw' },
            { label: 'บริจาค', icon: 'fa fa-fw fa-money', to: '/wastetran/donate' },
            { label: 'ประวัติการบริจาค', icon: 'fa fa-fw fa-history', to: '/wastetran/donate-history' },
            { label: 'รายงานการขาย', icon: 'fa fa-fw fa-credit-card', to: '/wastetran/report-buying' },
            //{ label: 'ข้อมูลการติดต่อ C3Leng', icon: 'fa fa-address-card-o', to: '/wastetran/wastetran-contact' },
            { label: 'ตั้งค่าการรับงาน', icon: 'fa fa-fw fa-cog', to: '/wastetran/manage-accept' },
            { label: 'ตารางค่าขนส่ง', icon: 'fa fa-fw fa-cog', href: 'https://c3leng.com/assets/shipping/shipping_price.pdf' },
            
            //{ label: 'ใบราคา', icon: 'fa fa-fw fa-credit-card', to: '/wastetran/reports-amount' },

            // {
            //     label: 'Settings', icon: 'fa fa-fw fa-bars',
            //     items: [
            //         { label: 'Static Menu', icon: 'fa fa-fw fa-bars', command: () => this.setState({ layoutMode: 'static' }) },
            //         { label: 'Inline Profile', icon: 'fa fa-sun-o fa-fw', command: () => this.setState({ profileMode: 'inline' }) },
            //         { label: 'Top Profile', icon: 'fa fa-moon-o fa-fw', command: () => this.setState({ profileMode: 'top' }) },
            //         { label: 'Light Menu', icon: 'fa fa-sun-o fa-fw', command: () => this.setState({ darkMenu: false }) },
            //         { label: 'Dark Menu', icon: 'fa fa-moon-o fa-fw', command: () => this.setState({ darkMenu: true }) }
            //     ]
            // }
        ];
    }
    }

    redirectProfile = (event) => {
        this.setState({staticMenuMobileActive:false})
        window.location = '#/wastetran/profile';
        event.preventDefault();
        this.setState({userNotCompleteDialog: false})
    }
    redirectRegisGen = (event) => {
        this.setState({staticMenuMobileActive:false})
        ServiceAPI.wastetranRegisWastegen().then(res => {
            if(res.status == 200){
                localStorage.setItem('user-type', 'wastegen');
                window.location = '#/wastegen/profile';
                event.preventDefault();
            }
            this.setState({displayDialog: false})
        })
        
    }
    onRegisGenClick = (event) => {
        this.setState({displayDialog: true})
    }
    changePasswordU2 = (event) => {
        this.setState({staticMenuMobileActive:false})
        window.location = '#/wastetran/change-password';
        event.preventDefault();
    }
    changePinU2 = (event) => {
        this.setState({staticMenuMobileActive:false})
        window.location = '#/wastetran/change-pin';
        event.preventDefault();
    }

    render() {
        const {

            province,
            userServiceProps,
            userInfo
        } = this.props

        let layoutClassName = classNames('layout-wrapper', {
            'menu-layout-static': this.state.layoutMode !== 'overlay',
            'menu-layout-overlay': this.state.layoutMode === 'overlay',
            'layout-menu-overlay-active': this.state.overlayMenuActive,
            'menu-layout-slim': this.state.layoutMode === 'slim',
            'menu-layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-menu-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-menu-static-active': this.state.staticMenuMobileActive
        });
        let menuClassName = classNames('layout-menu-container', { 'layout-menu-dark': this.state.darkMenu });

        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ยืนยัน" icon="pi pi-check" onClick={this.redirectRegisGen}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displayDialog: false})} className="p-button-warning"/>
        </div>;
        let userNotCompleteFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ทำตอนนี้" icon="pi pi-check" onClick={this.redirectProfile}/>
            <Button label="ยังไม่ทำตอนนี้" icon="pi pi-times" onClick={() => this.setState({userNotCompleteDialog: false})} className="p-button-warning"/>
        </div>;
        return (
            <div className={layoutClassName} onClick={this.onDocumentClick}>
                <div>
                    <AppTopbar profileMode={this.state.profileMode} horizontal={this.isHorizontal()}
                        topbarMenuActive={this.state.topbarMenuActive} activeTopbarItem={this.state.activeTopbarItem}
                        onMenuButtonClick={this.onMenuButtonClick} onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                        onTopbarItemClick={this.onTopbarItemClick} />

                    <div className={menuClassName} onClick={this.onMenuClick}>
                        <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{ height: '100%' }}>
                            <div className="menu-scroll-content">
                                {(this.state.profileMode === 'inline' && this.state.layoutMode !== 'horizontal') &&
                                <WastetranInlineProfile
                                    {...this.props}
                                    redirectProfile={this.redirectProfile}
                                    onRegisGenClick={this.onRegisGenClick}
                                    changePasswordU2={this.changePasswordU2}
                                    changePinU2={this.changePinU2}
                                />}
                                <AppMenu
                                    model={this.menu}
                                    onMenuItemClick={this.onMenuItemClick}
                                    onRootMenuItemClick={this.onRootMenuItemClick}
                                    layoutMode={this.state.layoutMode} 
                                    active={this.state.menuActive} />
                            </div>
                        </ScrollPanel>
                    </div>

                    <div className="layout-main">

                        <PropsRoute path="/wastetran" exact component={WastetranDashboard} />
                        <PropsRoute path="/wastetran/admin-chat" exact component={WastetranChatAdmin} {...this.props} />
                        <PropsRoute path="/wastetran/chat" exact component={WastetranChatView} />
                        <PropsRoute path="/wastetran/chat-wastegen" exact component={WastetranChatWithU1} />
                        <PropsRoute path="/wastetran/chat-wastepro" exact component={WastetranChatWithU3} />
                        <PropsRoute path="/wastetran/example-profile" component={WastetranProfile} {...this.props} />
                        <PropsRoute path="/wastetran/manage-orders" component={WastetranManageOrder} />
                        <PropsRoute path="/wastetran/example-noti-withdraw" component={WastetranNotiWithdraw} />
                        <PropsRoute path="/wastetran/example-report-buying" component={WastetranReportBuying} />
                        <PropsRoute path="/wastetran/wastetran-contact" component={WastetranContact} />
                        <PropsRoute path="/wastetran/news-detail" component={WastetranNewsDetail} />
                        <PropsRoute path="/wastetran/donate" component={WastetranDonate} />
                        <PropsRoute path="/wastetran/donate-history" component={WastetranDonateHistory} />
                        
                        

                        <PropsRoute
                            path="/wastetran/profile"
                            component={ExampleWastetranProfileCtrl}
                            userInfo={this.props.userInfo}
                            province={this.props.province}
                            district={this.props.district}
                            subDistrict={this.props.subDistrict}
                            cardProvince={this.props.cardProvince}
                            cardDistrict={this.props.cardDistrict}
                            cardSubDistrict={this.props.cardSubDistrict}
                            banks={this.props.banks}
                            chipProvince={this.props.chipProvince}
                            chipDistrict={this.props.chipDistrict}
                            getArrChipDistrict={this.props.getArrChipDistrict}
                        />
                        <PropsRoute
                            path="/wastetran/change-password"
                            component={ExampleWastetranChangePassword}
                            userInfo={this.props.userInfo}
                            province={this.props.province}
                            district={this.props.district}
                            subDistrict={this.props.subDistrict}
                            cardProvince={this.props.cardProvince}
                            cardDistrict={this.props.cardDistrict}
                            cardSubDistrict={this.props.cardSubDistrict}
                            banks={this.props.banks}
                            chipProvince={this.props.chipProvince}
                            chipDistrict={this.props.chipDistrict}
                            getArrChipDistrict={this.props.getArrChipDistrict}
                        />
                        <PropsRoute
                            path="/wastetran/change-pin"
                            component={ExampleWastetranChangePin}
                            userInfo={this.props.userInfo}
                            province={this.props.province}
                            district={this.props.district}
                            subDistrict={this.props.subDistrict}
                            cardProvince={this.props.cardProvince}
                            cardDistrict={this.props.cardDistrict}
                            cardSubDistrict={this.props.cardSubDistrict}
                            banks={this.props.banks}
                            chipProvince={this.props.chipProvince}
                            chipDistrict={this.props.chipDistrict}
                            getArrChipDistrict={this.props.getArrChipDistrict}
                        />
                        

                        <PropsRoute
                            path="/wastetran/noti-withdraw"
                            component={ExampleWastetranNotiWithdrawCtrl}
                        />

                        <PropsRoute
                            path="/wastetran/report-buying"
                            component={ExampleWastetranReportBuyingCtrl}
                        />

                        <PropsRoute
                            path="/wastetran/noti-recycle"
                            component={ExampleWastetranNotiRecycleCtrl}
                        />

                        <PropsRoute
                            path="/wastetran/reports-amount"
                            component={ExampleWastetranReportAmountCtrl}
                        />

                        <PropsRoute
                            path="/wastetran/manage-accept"
                            component={WastetranManageAccept}
                            userInfo={this.props.userInfo}
                        />

                    </div>

                </div>
                <Dialog 
                    visible={this.state.displayDialog} 
                    style={{width: '40vw'}} 
                    header="สมัครเป็นผู้รักษ์โลก" 
                    modal={true} 
                    footer={dialogFooter} 
                    onHide={() => this.setState({displayDialog: false})}>
                        ต้องการสมัครเป็นผู้รักษ์โลกทันที
                </Dialog>

                <Dialog 
                    visible={this.state.userNotCompleteDialog} 
                    style={{width: '40vw'}} 
                    header="แจ้งเตือน" 
                    modal={true} 
                    footer={userNotCompleteFooter} 
                    onHide={() => this.setState({userNotCompleteDialog: false})}>
                        ข้อมูลโปรไฟล์ไม่ครบถ้วน แก้ไขข้อมูลเพื่อรับงาน
                </Dialog>
            </div>
        );
    }
}



const mapStateToProps = (state) => ({
    locationMaster: state.location,
    province: state.location.province,
    district: state.location.district,
    subDistrict: state.location.subDistrict,
    authentication: state.authentication,
    userInfo: state.userInfo.data,
    userInfoMaster: state.userInfo,
    updateWastetranProfile: state.updateWastetranProfile,

    cardProvince: state.location.cardProvince,
    cardDistrict: state.location.cardDistrict,
    cardSubDistrict: state.location.cardSubDistrict,

    chipProvince: state.location.chipProvince,
    chipDistrict: state.location.chipDistrict,
    getArrChipDistrict: state.getArrChipDistrict,

    banks: state.master.banks,

    changePassword: state.changePassword,
    chatSingleBadge: state.chatSingleBadge,
})

const mapDispatchToProps = dispatch => ({
    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    fileServiceProps: bindActionCreators(FileService, dispatch),
    ChatServiceProps: bindActionCreators(ChatService, dispatch),
})



export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(WastetranContainer)

