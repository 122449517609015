import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';

import { InputText } from 'primereact/components/inputtext/InputText';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { MapMarkers } from '../../features/map/MapMarkers'
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { Avatar } from '../../features/widget/Avatar';
import { API_ROOT } from '../../constants/api'
import { ProgressSpinner } from 'primereact/progressspinner';
import * as ServerAPI from './service';
import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'
import * as DashBoardService from './service'
import { isEmpty } from '../../utils/validators';
import {Dialog} from 'primereact/dialog';

import { CEInputText, CECalendar, CEDropdown } from '../../features/input';
import { CEFileUpload } from '../../features/widget';

class ManageProductFee extends Component {
    static propTypes = {

    }
    constructor() {
        super();
        this.state = {
            addressDetail: '',
            priceList: [],

            brandSuggestions: null,
            wasteTypeSelected: null,
            searchProductType: '',
            searchProduct: '',
            productSelected: null,
            priceSelected: '',
            weigthSelected: '',

            memberStore: 0,
            memberStore1: [
                { label: 'เลือกประเภทสินค้า', value: 0 },
                { label: 'กระดาษ', value: 1 },
                { label: 'พลาสติก', value: 2 },
                { label: 'ประเภทที่ 2', value: 3 },
            ],
            memberStore3: 0,
            memberStore4: [
                { label: 'เลือกสินค้า', value: 0 },
                { label: 'กระดาษขาวดำ', value: 1 },
                { label: 'กระดาษจับจั๊ว', value: 2 },
                { label: 'กระดาษกล่องลัง', value: 3 },
            ],

            weigth1: null,
            weigth1Item: [
                //{ name: 'เลือกระยะทาง', code: 0 },
                { name: 'น้อยกว่า 50 กิโลกรัม', code: 1 },
                { name: '50-500 กิโลกรัม', code: 2 },
                { name: '500-1000 กิโลกรัม', code: 3 },
                { name: 'มากกว่า 1000 กิโลกรัม', code: 4 },
            ],
            testData: [
                {
                    product_type: 'กระดาษ',
                    product: 'กระดาษขาว',
                    weigth: 'น้อยกว่า 50',
                    buy_per_kk: '2',
                },
                {
                    product_type: 'พลาสติก',
                    product: 'ขวดน้ำพลาสติก',
                    weigth: '50-100',
                    buy_per_kk: '2.5',
                }
            ],
            isNotValid: false,
            showProgress: false,
        };
        this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
        this.onMemberNameChanged = this.onMemberNameChanged.bind(this);
        this.onSelectMemberName = this.onSelectMemberName.bind(this);
        this.onproductsChange = this.onproductsChange.bind(this);
        this.onsearchProductChange = this.onsearchProductChange.bind(this);
        this.onWasteTypeChange = this.onWasteTypeChange.bind(this);
        this.onsearchProductTypeChange = this.onsearchProductTypeChange.bind(this);
        this.addDataToTable = this.addDataToTable.bind(this);
        this.onWeigth1Change = this.onWeigth1Change.bind(this);
        this.onValidation = this.onValidation.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.getprocessfee = this.getprocessfee.bind(this);
        this.onWeightChange = this.onWeightChange.bind(this);
        this.searchProductList = this.searchProductList.bind(this);
        this.getprocessfeeForCheck = this.getprocessfeeForCheck.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.onProductTypeUpdate = this.onProductTypeUpdate.bind(this);
        this.onProductUpdate = this.onProductUpdate.bind(this);
        this.onWeightUpdate = this.onWeightUpdate.bind(this);
    }
    componentDidMount() {
        this.getprocessfee();
        this.getfeeWeight1Type();
        this.getprocessfeeForCheck();
        const { dashBoardServiceProps, locationServiceProps, masterServiceProps } = this.props
        dashBoardServiceProps.getDashboardUsers();
        locationServiceProps.getProvice();
        masterServiceProps.getProductType();
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div></div>);
    }
    searchProductList() {
        this.setState({ showProgress: true })
        var productList = {
            product_code: this.state.searchProductCode,
            product_type_code: this.state.searchProductTypeCode,
        }

        ServerAPI.searchProuctFee(productList).then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({ priceList: res })
                    this.setState({ showProgress: false })
                    // this.getprocessfee();
                    // this.clearAllDataInput();
                    this.showSuccess();

                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })

    }

    onWeigth1Change(e) {
        this.setState({ weigth1: e.value });
    }

    getprocessfee() {
        this.setState({ showProgress: true })
        ServerAPI.getprocessfee().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({ priceList: res }) //table
                    this.setState({ processFeeList: res }) //for valid add
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }
    getprocessfeeForCheck() {
        ServerAPI.getprocessfee().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({ processFeeList: res }) //for valid add
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }

    getfeeWeight1Type() {
        ServerAPI.getFeeWeightType().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({ weightTypeList: res })
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }

    itemTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }

    onSelectMemberName(item) {
        this.setState({ memberName: item.value.search_name });
        this.setState({ memberUid: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    suggestMemberName(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }

    onMemberNameChanged(search_name) {
        console.log(search_name)
        console.log(typeof (search_name))
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwasteAll(search_name).then((res) => {
                        console.log(res);
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName: search_name });
                this.setState({ memberUid: null });
            }
    }
    onProductTypeUpdate(e){
        console.log(e)
        this.setState({ProductTypeObjUpdate: e.value});
    }
    onProductUpdate(e){
        console.log(e)
        this.setState({ProductObjUpdate: e.value});
    }
    onWeightUpdate(e){
        console.log(e)
        this.setState({weightObjUpdate: e.value});
    }

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" onClick={() => this.onProductFeeUpdate(column.rowData)} icon="pi pi-pencil" className="p-button" ></Button>
            <Button type="button" onClick={() => this.onProductFeeDelete(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button>
        </div>;
    }


    onClickDeleteItem(rowData) {
        console.log(rowData)
        if (rowData !== '') {
            var id = rowData.gid
            ServerAPI.removeprocessfee(id).then((res) => {
                console.log(res);
                if (res) {
                    var index = res.toString().indexOf("Error:");
                    console.log("find error:" + (index));
                    if (index == -1) {
                        this.searchProductList();
                        this.getprocessfeeForCheck();
                        this.setState({ showProgress: false })
                        //this.showSuccess();
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            }).catch((error) => {
                this.setState({ showProgress: false })
            })
        }else {
            this.showWarn();
            this.setState({ showProgress: false })
        }
        // if (index !== -1) {
        //     var array = this.state.priceList;
        //     array.splice(index, 1);
        //     this.setState({ priceList: array });
        // }
    }

    update() {
        this.setState({ showProgress: true })
        console.log(this.state.productFee)
        if(this.state.productFee){
            var isNotinput = (
                this.state.productFee.fee_per_kk == '' ||
                this.state.isNotValid == true
            );
            if (isNotinput) {
                this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
                this.setState({ showProgress: false })
            } else {
                        var data = {
                            gid: this.state.productFee.gid,
                            fee_per_kk: parseFloat(this.state.productFee.fee_per_kk),
                        }

                        ServerAPI.editProcessfee(data).then((res) => {
                            console.log(res);
                            if (res) {
                                var index = res.toString().indexOf("Error:");
                                console.log("find error:" + (index));
                                if (index == -1) {
                                    this.getprocessfee();
                                    this.setState({ displayDialog:false});
                                    this.clearAllDataInput();
                                    this.showSuccess();
                                } else {
                                    this.showWarn();
                                    this.setState({ showProgress: false })
                                }
                            } else {
                                this.showWarn();
                                this.setState({ showProgress: false })
                            }
                        }).catch((error) => {
                            this.setState({ showProgress: false })
                        })
                    
                }
        }
    }
    delete() {
        this.setState({ showProgress: true })
        var data = this.state.productFee;
        this.onClickDeleteItem(data);
        // let index = this.findSelectedWeightIndex();
        // this.setState({
        //     cars: this.state.cars.filter((val,i) => i !== index),
        //     selectedCar: null,
        //     car: null,
        this.setState({deleteDialog: false});
    }
    updateProperty(property, value) {
        let productFee = this.state.productFee;
        productFee[property] = value;
        this.setState({productFee: productFee});
    }

    onProductFeeUpdate(e){
        console.log(e)
        var ProductTypeDropdown = {
            product_type_code: e.product_type_code,
            product_type_name: e.product_type_name,
            less_than_kk: e.less_than_kk,
            more_than_kk: e.more_than_kk,
        }
        var ProductDropdown = {
            product_code: e.product_code,
            product_name: e.product_name,
            less_than_km: e.less_than_km,
            more_than_km: e.more_than_km,
        }
        var weightDropdown = {
            weight_type_id: e.weight_type_id,
            weight_type_name: e.weight_type_name,
            less_than_km: e.less_than_km,
            more_than_km: e.more_than_km,
        }
        this.setState({
            displayDialog:true,
            productFee: Object.assign({}, e),
            ProductTypeObjUpdate:  ProductTypeDropdown,
            ProductObjUpdate:  ProductDropdown,
            weightObjUpdate:  weightDropdown,
        });
    }
    onProductFeeDelete(e){
        console.log(e)
        this.setState({
            deleteDialog:true,
            productFee: Object.assign({}, e),
        });
    }

    onValidation(valid) {
        console.log(valid)
        if (!valid) this.setState({ isNotValid: true })
        else this.setState({ isNotValid: false })
    }

    onWasteTypeChange(e) {

        this.setState({ wasteTypeSelected: e.value });
        this.setState({ productTypeCode: e.value.product_type_code });

        const { masterServiceProps, product } = this.props
        masterServiceProps.getProduct(e.value.product_type_code);

    }
    onproductsChange(e) {
        this.setState({ productSelected: e.value });
    }

    onsearchProductTypeChange(e) {
        this.setState({ searchProductType: e.value });
        this.setState({ searchProductTypeCode: e.value.product_type_code });

        const { masterServiceProps, product } = this.props
        masterServiceProps.getProduct(e.value.product_type_code);
    }
    onsearchProductChange(e) {
        this.setState({ searchProduct: e.value });
        this.setState({ searchProductTypeCode: e.value.product_type_code });
        this.setState({ searchProductCode: e.value.product_code });
    }

    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    addDataToTable() {
        this.setState({ showProgress: true })
        var isNotinput = (
            this.state.wasteTypeSelected == null ||
            this.state.productSelected == null ||
            this.state.priceSelected == '' ||
            this.state.weigth1 == null ||
            this.state.isNotValid == true
        );

        if (isNotinput) {
            this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
            this.setState({ showProgress: false })
        } else {
            var notValid = false;
            this.getprocessfeeForCheck();
            console.log(this.state.processFeeList)
            if (this.state.processFeeList)
                this.state.processFeeList.forEach(objPriceCost => {

                    console.log(objPriceCost);
                    console.log(objPriceCost.product_type_code);
                    console.log(this.state.wasteTypeSelected.product_type_code);
                    console.log(objPriceCost.product_name);
                    console.log(this.state.productSelected.product_name);
                    console.log(objPriceCost.weight_type_name);
                    console.log(this.state.weigth1.weight_name);

                    if (objPriceCost.product_type_code == this.state.wasteTypeSelected.product_type_code &&
                        objPriceCost.product_name == this.state.productSelected.product_name &&
                        objPriceCost.weight_type_name == this.state.weigth1.weight_name) {
                        notValid = true;
                    }
                });

            if (notValid) {
                this.showWarn("ข้อมูลซ้ำ! กรุณาเลือกใหม่อีกครั้ง");
                this.setState({ showProgress: false })
            } else {
                var priceList = {

                    weight_type_name: this.state.weigth1.name,
                    weight_type_id: this.state.idweigth,
                    more_than_kk: this.state.min_per_kk,
                    less_than_kk: this.state.max_per_kk,
                    weight_name: this.state.nameWeigth,
                    product_type_code: this.state.wasteTypeSelected.product_type_code,
                    product_type_name: this.state.wasteTypeSelected.product_type_name,
                    product_name: this.state.productSelected.product_name,
                    product_code: this.state.productSelected.product_code,
                    // wasteType: this.state.wasteTypeSelected.product_type_name,
                    fee_per_kk: parseFloat(this.state.priceSelected),
                }

                ServerAPI.Addprocessfee(priceList).then((res) => {
                    console.log(res);
                    if (res) {
                        var index = res.toString().indexOf("Error:");
                        console.log("find error:" + (index));
                        if (index == -1) {
                            this.searchProductList();
                            this.clearAllDataInput();
                            this.showSuccess();

                        } else {
                            this.showWarn();
                            this.setState({ showProgress: false })
                        }
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                }).catch((error) => {
                    this.setState({ showProgress: false })
                })




            }
            console.log(priceList)
        }
    }



    clearAllDataInput() {

        this.setState({ wasteTypeSelected: null })
        this.setState({ productSelected: null })
        this.setState({ priceSelected: '' })
        this.setState({ weigth1: null })
        this.setState({ idweigth: null })
        this.setState({ min_per_kk: null })
        this.setState({ max_per_kk: null })
        this.setState({ nameWeigth: null })
        this.setState({ memberName1: null }); //order code from select table
        this.setState({ memberName: null });
        this.setState({ priceSelected: '' });

    }

    clearSearchInput() {

        this.setState({ searchProductType: null }); //order code from auto complete
        this.setState({ searchProduct: null });
        this.setState({ searchProductTypeCode: null });
        this.setState({ searchProductCode: null });

        console.log(this.state.searchProductType)
        console.log(this.state.searchProduct)
        console.log(this.state.searchProductTypeCode)
        console.log(this.state.searchProductCode)
        this.clearAllDataInput();
    }
    onWeightChange(e) {
        this.setState({ weigth1: e.value });
        this.setState({ idweigth: e.value.gid });
        this.setState({ nameWeigth: e.value.weight_name });
        this.setState({ min_per_kk: e.value.more_than_kk });
        this.setState({ max_per_kk: e.value.less_than_kk });
    }

    showSearchPanel() {
        const {
            productType1,
            product1,
        } = this.props;
        return (

            <Accordion multiple={true} activeIndex={[0, 1]}>
                <AccordionTab header="เงื่อนไขค้นหาเพื่อจัดการค่าบริการ">
                    <div className="p-grid p-justify-start" >

                        <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>ประเภทสินค้า :</label>
                        </div>
                        <div className="p-col-12 p-md-10 p-lg-11" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <Dropdown
                                style={{ width: '20%' }}
                                value={this.state.searchProductType}
                                options={productType1}
                                onChange={this.onsearchProductTypeChange}
                                placeholder="เลือกประเภทขยะรีไซเคิล"
                                optionLabel="product_type_name"
                            //dataKey="product_type_code"
                            />

                        </div>
                        <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>สินค้า :</label>
                        </div>
                        <div className="p-col-12 p-md-10 p-lg-11" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <Dropdown
                                style={{ width: '20%' }}
                                value={this.state.searchProduct}
                                options={product1}
                                onChange={this.onsearchProductChange}
                                placeholder="เลือกสินค้า"
                                optionLabel="product_name"
                            //dataKey="product_code"
                            />
                        </div>


                        <div className="p-col-12">
                            <br/>
                            <Button
                                style={{marginRight:"20px"}}
                                label="ค้นหา"
                                icon="fa fa-search"
                                iconPos="left"
                                onClick={this.searchProductList} />
                            <Button
                                style={{ backgroundColor: '#f0ad4e' }}
                                type="submit"
                                label="ยกเลิก"
                                onClick={this.clearSearchInput}
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
        )
    }


    addNewProductTab() {
        const {
            productType,
            product,
        } = this.props;


        return (
            <Accordion multiple={true} activeIndex={[1]}>
                <AccordionTab header="เพิ่มค่าบริการ">
                    <div className="p-grid">

                        <label className="p-col-2 ">ประเภทขยะรีไซเคิล :</label>
                        <div className="p-col-10">
                            <Dropdown
                                style={{ width: '20%' }}
                                value={this.state.wasteTypeSelected}
                                options={productType}
                                onChange={this.onWasteTypeChange}
                                placeholder="เลือกประเภทขยะรีไซเคิล"
                                optionLabel="product_type_name"
                            //dataKey="product_type_code"
                            />
                        </div>

                        <label className="p-col-2 ">สินค้า :</label>

                        <div className="p-col-10">
                            <Dropdown
                                style={{ width: '20%' }}
                                value={this.state.productSelected}
                                options={product}
                                onChange={this.onproductsChange}
                                placeholder="เลือกสินค้า"
                                optionLabel="product_name"
                            //dataKey="product_code"
                            />

                        </div>
                        <label className="p-col-2" >น้ำหนัก :</label>
                        <div className="p-col-10">
                            <Dropdown
                                style={{ width: '20%' }}
                                optionLabel="weight_name"
                                placeholder="เลือกน้ำหนัก"
                                value={this.state.weigth1}
                                options={this.state.weightTypeList}
                                onChange={this.onWeightChange}
                            />
                        </div>

                        <label className="p-col-12 p-md-2 p-lg-2" >ค่าบริการร้อยละ :</label>
                        <div className="p-col-12 p-md-10 p-lg-10">
                            <CEInputText
                                id="priceSelected"
                                type="money"
                                value={this.state.priceSelected}
                                minvalue={0}
                                maxvalue={100}
                                name="priceSelected"
                                placeholder="00.00"
                                tooltip="กรอกค่าบริการ (ทศนิยม 2 ตำแหน่ง)"
                                required={true}
                                isvalid={this.onValidation}
                                onChange={(e) => this.setState({ priceSelected: e.target.value })}
                            />
                            <label style={{ marginLeft: '10px' }}>ต่อราคาขาย</label>
                        </div>
                        <div className="p-col-12 ">
                            <Button
                                style={{ float: "left" }}
                                label="เพิ่มรายการ"
                                icon="fa fa-plus"
                                iconPos="left"
                                onClick={this.addDataToTable}
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
        )
    }
    showWeightTable() {
        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="บันทึก" icon="pi pi-check" onClick={this.update}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displayDialog: false})} className="p-button-warning"/>
            </div>;
        let deleteDialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ใช่" icon="pi pi-check" onClick={this.delete}/>
            <Button label="ไม่ใช่" icon="pi pi-times" onClick={() => this.setState({deleteDialog: false})} className="p-button-warning"/>
            </div>;
        const weightTable = (
            <div className="p-col-12">
                <Panel header="รายการค่าบริการ" >
                    <DataTable value={this.state.priceList} paginatorPosition="bottom" paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                        responsive={true}
                        emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)} >
                        <Column field="product_type_name" header="ประเภทสินค้า" sortable={true} filter={true} />
                        <Column field="product_name" header="สินค้า" sortable={true} filter={true} />
                        <Column field="weight_type_name" header="น้ำหนัก" sortable={true} filter={true} className="p-text-center" />
                        <Column field="fee_per_kk" header="ค่าบริการต่อราคาขาย" sortable={true} filter={true} className="p-text-center" />
                        <Column field="gid" body={this.actionTemplate.bind(this)} header="จัดการ" className="p-text-center" style={{ width: '10%' }} />
                    </DataTable>
                </Panel>

                <Dialog visible={this.state.deleteDialog} style={{width: '40vw'}} header="ยืนยันการลบข้อมูล" modal={true} footer={deleteDialogFooter} onHide={() => this.setState({deleteDialog: false})}>
                        {
                            this.state.productFee &&
                            
                            <div className="p-grid p-fluid" >
                                <div className="p-col-12"><span>คุณต้องการจะลบข้อมูลต่อไปนี้</span></div>
                                <div className="p-col-4" ><label htmlFor="product_type_name">ประเภทขยะรีไซเคิล :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productFee.product_type_name}</strong>
                                </div>

                                <div className="p-col-4" ><label htmlFor="product_name">สินค้า :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productFee.product_name}</strong>
                                </div>

                                <div className="p-col-4" ><label htmlFor="weight_type_name">น้ำหนัก (บาท) :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productFee.weight_type_name}</strong>
                                </div>
                                <div className="p-col-4" ><label htmlFor="fee_per_kk">ค่าบริการ (ร้อยละ) :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productFee.fee_per_kk}</strong>
                                </div>
                            </div>
                            
                        }
                    </Dialog>
                    <Dialog visible={this.state.displayDialog} style={{width: '40vw'}} header="แก้ไขข้อมูล" modal={true} footer={dialogFooter} onHide={() => this.setState({displayDialog: false})}>
                        {
                            this.state.productFee &&
                            
                            <div className="p-grid p-fluid" >
                                
                                <div className="p-col-12"><span>คุณต้องการจะแก้ไขข้อมูลต่อไปนี้</span></div>
                                <div className="p-col-4" ><label htmlFor="product_type_name">ประเภทขยะรีไซเคิล :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productFee.product_type_name}</strong>
                                </div>

                                <div className="p-col-4" ><label htmlFor="product_name">สินค้า :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productFee.product_name}</strong>
                                </div>

                                <div className="p-col-4" ><label htmlFor="weight_type_name">น้ำหนัก (บาท) :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.productFee.weight_type_name}</strong>
                                </div>
                                <div className="p-col-4" style={{ alignSelf: 'center' }}><label htmlFor="fee_per_kk">ค่าบริการ (ร้อยละ) :</label></div>
                                <div className="p-col-8" style={{ padding: '0em' }}>
                                    <CEInputText   
                                        id="fee_per_kk"                        
                                        type="money"
                                        value={this.state.productFee.fee_per_kk}
                                        name="fee_per_kk"
                                        placeholder="00.00"
                                        tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                        required={true}
                                        isvalid={this.onValidation}
                                        onChange={(e) => {this.updateProperty('fee_per_kk', e.target.value)}}
                                        />
                                    
                                </div>
                            </div>
                        }
                    </Dialog>
            </div>
        );
        if(!this.state.showProgress)
        return weightTable;
    }
    onSubmit() {

    }

    render() {
        const searchPanel = this.showSearchPanel();
        const addnew = this.addNewProductTab();
        const weightTablePanel = this.showWeightTable();
        const showProgress = this.showProgressSpinner();


        return <div className="p-grid ">
            <Growl ref={(el) => this.growl = el} />
            <div className="p-col-12">
                <h1>จัดการค่าบริการ</h1>
            </div>
            {searchPanel}
            {addnew}
            {showProgress}
            {weightTablePanel}

        </div>
    }
}



const mapStateToProps = (state) => ({
    dhbUsers: state.dhbAdmin.dhbUsers,
    locationMaster: state.location,
    provinces: state.location.province,
    districts: state.location.district,
    subDistricts: state.location.subDistrict,
    cardProvince: state.location.cardProvince,
    cardDistrict: state.location.cardDistrict,
    cardSubDistrict: state.location.cardSubDistrict,
    authentication: state.authentication,
    userInfo: state.userInfo.data,
    userInfoMaster: state.userInfo,
    updateWasteproProfile: state.updateWasteproProfile,
    banks: state.master.banks,
    productType: state.master.productType,
    product: state.master.product,
    productType1: state.master.productType,
    product1: state.master.product,
    getFileWastepro: state.getFileWastepro,
})

const mapDispatchToProps = dispatch => ({

    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    fileServiceProps: bindActionCreators(FileService, dispatch),

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ManageProductFee)