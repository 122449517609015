import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getBalance, getWastetranDashProduct,getWastetranDashOrderStat } from '../../service/wastetran.service'
import { userAnnounce } from '../../service/user.service'

import { WastetranOverviews } from '../wastetran-dashboard/WastetranOverviews';
import { WasteNewView } from '../waste-news/WasteNewView';
import { DatatableDashBoardView } from '../data-table/DatatableDashBoardView';
import { StatisticView } from '../dashboard/StatisticView';
import { getStatus } from '../../constants/order-status';
import { Panel } from 'primereact/panel';
import { ListBox } from 'primereact/listbox';
import { ChatWastetranWhitU3View } from '../message/ChatWastetranWhitU3View';
import { Growl } from 'primereact/growl';
import {InputText} from 'primereact/inputtext';
import { Button } from 'primereact/button';

class WastetranChatWithU3 extends Component {
    constructor(props) {
        console.log("WastegenDashboard :constructor")
        super(props);
        this.state = {
            dummySuccessDataTable: [
                { order: 'Order ทั้งหมด', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'จัดส่งสมบูรณ์', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'จัดส่งไม่สมบูรณ์', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'ลูกค้ายกเลิก', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: '%สำเร็จ', ranking: '0%', amount: '0', quantity: '0 kg.' },
            ]
        }
    }

    componentDidMount() {
        console.log("WastegenDashboard :componentDidMount")
        this.props.dispatch(getBalance());
        this.props.dispatch(userAnnounce());
        this.props.dispatch(getWastetranDashProduct())
        this.props.dispatch(getWastetranDashOrderStat())
    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("WastegenDashboard :componentWillReceiveProps")
    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("WastegenDashboard :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("WastegenDashboard :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("WastegenDashboard :componentDidUpdate")

    }

    render() {
        const {
            userAnnounce,
        } = this.props

        //> ข่าวสาร
        const contentNews = (
            <WasteNewView
                data={userAnnounce.data}
            />
        );

        const citySelectItems = [
            { label: 'นาย อิศยา กาบนันทา', value: '1' },
            { label: 'eissaya U1', value: '2' },
            { label: 'usertran eisya', value: '3' },
            { label: 'ร้านมารวย', value: '4' },
            { label: 'นาย ประทีบ จันทรา', value: '5' },
            { label: 'นาย ประเทือง จันทรา', value: '6'},
            { label: 'นาย ประหยัด จันทรา', value: '7'},
            { label: 'นาย ประทุ จันทรา', value: '8'},
            { label: 'นาย ประปา จันทรา', value: '9'},
            { label: 'นาย ประวัติ จันทรา', value: '10'},
            { label: 'นาย ประจบ จันทรา', value: '11'},
            { label: 'นาย ประแจง จันทรา', value: '12'},
            { label: 'นาย ประนอม จันทรา', value: '13'},
            { label: 'นาย ประวรรณ จันทรา', value: '14'},
        ];
        const showChatTable = (
            <Panel header="Chat">
                <div className="p-grid p-fluid" >
                    <div className="p-col-12">
                        <div className="p-inputgroup">
                            <InputText placeholder="ค้นหาสมาชิก"/>
                            <Button 
                            icon="fa fa-search" 
                            />
                        </div>
                    </div>
                </div>
                <ListBox
                    value={this.state.selectedCar}
                    filter={false} 
                    filterPlaceholder="Search"
                    options={citySelectItems}
                    nChange={(e) => this.setState({ selectedCar: e.value })}
                    itemTemplate={this.carTemplate}
                    style={{ width: '100%' }}
                    listStyle={{ maxHeight: '450px' }} />

            </Panel>
    )

    const chatWastetran = (
            <ChatWastetranWhitU3View
                valueDd={this.state.valueDd}
                listDd={this.state.listMessage}
                onChangeDd={this.onChangeDd}
                onChangeMessage={this.onChangeMessage}
                onSubmit={this.onSubmit}
                classNameRequired={this.state.classNameRequired}
            />
    );

        return (
            <div>
                <h1>Chat</h1>
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid dashboard">

                    {/* <div className="p-col-4 p-md-3 p-lg-4">
                        {showChatTable}
                    </div> */}
                    <div className="p-col-12 " >
                        {chatWastetran}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    wastetranBalance: state.wastetranBalance,
    userAnnounce: state.userAnnounce,
    getWastetranDashProduct: state.getWastetranDashProduct,
    getWastetranDashOrderStat: state.getWastetranDashOrderStat
})

export default connect(mapStateToProps)(WastetranChatWithU3)