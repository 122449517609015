import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import classNames from 'classnames';
import { AppTopbar } from './../AppTopbar';
import { AppFooter } from './../AppFooter';
import { AppMenu } from './../AppMenu';
import { Route } from 'react-router-dom';
import { Dashboard } from '../demo/Dashboard';
import { ScrollPanel } from 'primereact/components/scrollpanel/ScrollPanel';
import 'primereact/resources/primereact.min.css';
// import 'fullcalendar/dist/fullcalendar.css';
import 'font-awesome/css/font-awesome.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import style from './style.css';


import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'
import * as ChatService from '../../service/ChatService'
import { addNewChatMessage } from '../../service/ChatService'
import { get } from 'https';
import { AppInlineProfile } from '../AppInlineProfile';
import { WastegenProfile } from './WastegenProfile';
import { WastegenWithdraw } from './WastegenWithdraw';
import { WastgenReportsBuying } from './WastgenReportsBuying';
import { WastegenReportsAmount } from './WastegenReportsAmount';
import { WastgenInlineProfile, WastegenInlineProfile } from './WastegenInlineProfile';
import PropsRoute from '../../PropsRoute';
import ExampleChangePasswordCtrl from './ExampleChangePasswordCtrl';
import ExampleWastegenProfileCtrl from './ExampleWastegenProfileCtrl';
import ExampleWastegenChangePassword from './ExampleWastegenChangePassword';
import ExampleWastegenWithdrawCtrl from './ExampleWastegenWithdrawCtrl';
import ExampleWastegenReportBuyingCtrl from './ExampleWastegenReportBuyingCtrl';
import ExampleWastegenReportAmountCtrl from './ExampleWastegenReportAmountCtrl';
import WastegenDashboard from './WastegenDashboard';
import WastegenGoodness from './WastegenGoodness';
import WastegenCertificate from './WastegenCertificate';
import WastegenChatMassage from './WastegenChatMassage';
import WastegenContact from './WastegenContact';
import ExampleWastegenChangePin from './ExampleWastegenChangePin';
import WastegenChatView from './WastegenChatView';
import WastegenChatAdmin from './WastegenChatAdmin';
import WastegenChatWithU2 from './WastegenChatWithU2';
import WastegenNewsDetail from './WastegenNewsDetail';
import WastegenOrderList from './WastegenOrderList';
import {
    connectWebsocket,
    implementWebsocket,
    closeWebsocket,
    navigateToAdminChatScene,
    navigateToUserChatScene,
    sendMessage
  } from '../../utils/chat'
import { WASTE_TYPE, WASTE_TYPE_NUM_TO_NAME } from '../../constants/mappingValue'
import WastegenDonate from './WastegenDonate';
import WastegenDonateHistory from './WastegenDonateHistory';
import { getBadgeAdmin } from './service'
import {Dialog} from 'primereact/dialog';
import { Button } from 'primereact/components/button/Button';
import * as ServiceAPI from './service';

class WastegenContainer extends Component {
    static propTypes = {

    }

    static defaultProps = {
    }

    componentDidMount() {
        const { locationServiceProps, masterServiceProps, userServiceProps, ChatServiceProps } = this.props
        locationServiceProps.getProvice();
        userServiceProps.getUserInfo();
        masterServiceProps.getBanks()
        ChatServiceProps.getBadgeAdmin();

    }

    componentWillReceiveProps(nextProps) {
        console.log("WastegenContainer :componentWillReceiveProps")
        if (
            nextProps.userInfo
            && !nextProps.locationMaster.isFetching
            && this.tickerLoad
        ) {
            if (nextProps.userInfo.addr_current_province_code) {
                this.props.locationServiceProps.getDistrict(nextProps.userInfo.addr_current_province_code);
            }
            if (nextProps.userInfo.addr_current_district_code) {
                this.props.locationServiceProps.getSubDistrict(nextProps.userInfo.addr_current_district_code);
            }
            if(nextProps.userInfo){
                const currentUserInfo = nextProps.userInfo.user_wasteTypes.find(
                    item => item.wasteType === WASTE_TYPE['wastegen'],
                )
                console.log(currentUserInfo)
                if(currentUserInfo.profileStatusCode != 99) this.setState({userNotCompleteDialog: true})
            }
            this.tickerLoad = false;
            if (nextProps.userInfo){
                this.manageChatWebsocket(nextProps.userInfo,'wastegen')
            }else {
                closeWebsocket()
            }
        }
        if(!nextProps.chatSingleBadge.isLoading){
            getBadgeAdmin().then((res)=>{
                    this.createMenu(nextProps.userInfo,res);
                    this.setState({badge: res})
                })
            
        }
    }

    constructor() {
        super();
        this.tickerLoad = true;
        this.state = {
            layoutMode: 'static',
            profileMode: 'inline',
            layoutCompact: true,
            overlayMenuActive: false,
            staticMenuDesktopInactive: false,
            staticMenuMobileActive: false,
            rotateMenuButton: false,
            topbarMenuActive: false,
            activeTopbarItem: null,
            darkMenu: false,
            menuActive: false,
            theme: 'green',
            layout: 'green',
            version: 'v3',
            displayDialog: false,
            userNotCompleteDialog: false
        };

        this.onDocumentClick = this.onDocumentClick.bind(this);
        this.onMenuClick = this.onMenuClick.bind(this);
        this.onMenuButtonClick = this.onMenuButtonClick.bind(this);
        this.onTopbarMenuButtonClick = this.onTopbarMenuButtonClick.bind(this);
        this.onTopbarItemClick = this.onTopbarItemClick.bind(this);
        this.onMenuItemClick = this.onMenuItemClick.bind(this);
        this.onRootMenuItemClick = this.onRootMenuItemClick.bind(this);
        this.createMenu();
    }

    manageChatWebsocket = (userInfo, userWasteType) => {
        const currentUserInfo = userInfo.user_wasteTypes.find(
          item => item.wasteType === WASTE_TYPE[userWasteType],
        )
        const senderId = currentUserInfo.uid
        const senderType = currentUserInfo.wasteType
        const onMessage = (message, currentRouteName) => {
            addNewChatMessage(message)
            getBadgeAdmin().then((res)=>{
                this.createMenu(userInfo,res);
                this.setState({badge: res})
            })
        //   if (currentRouteName === 'ChatScene') {
        //     addNewChatMessage(message)
        //   } else {
        //       console.log('fadeInMessageBanner(message)')
        //       //fadeInMessageBanner(message)
        //   }
        }
        connectWebsocket(senderId, senderType)
        implementWebsocket(onMessage)
    }
      
    onMenuClick(event) {
        this.menuClick = true;
    }

    onMenuButtonClick(event) {
        this.menuClick = true;
        this.setState(({
            rotateMenuButton: !this.state.rotateMenuButton,
            topbarMenuActive: false
        }));

        if (this.state.layoutMode === 'overlay') {
            this.setState({
                overlayMenuActive: !this.state.overlayMenuActive
            });
        }
        else {
            if (this.isDesktop())
                this.setState({ staticMenuDesktopInactive: !this.state.staticMenuDesktopInactive });
            else
                this.setState({ staticMenuMobileActive: !this.state.staticMenuMobileActive });
        }

        event.preventDefault();
    }

    onTopbarMenuButtonClick(event) {
        this.topbarItemClick = true;
        this.setState({ topbarMenuActive: !this.state.topbarMenuActive });
        this.hideOverlayMenu();
        event.preventDefault();
    }

    onTopbarItemClick(event) {
        this.topbarItemClick = true;

        if (this.state.activeTopbarItem === event.item)
            this.setState({ activeTopbarItem: null });
        else
            this.setState({ activeTopbarItem: event.item });

        event.originalEvent.preventDefault();
    }

    onMenuItemClick(event) {
        if (!event.item.items) {
            this.hideOverlayMenu();
        }
        if (!event.item.items && this.isHorizontal()) {
            this.setState({
                menuActive: false
            })
        }
    }

    onRootMenuItemClick(event) {
        this.setState({
            menuActive: !this.state.menuActive
        });
    }

    onDocumentClick(event) {
        if (!this.topbarItemClick) {
            this.setState({
                activeTopbarItem: null,
                topbarMenuActive: false
            });
        }

        if (!this.menuClick) {
            if (this.isHorizontal() || this.isSlim()) {
                this.setState({
                    menuActive: false
                })
            }

            this.hideOverlayMenu();
        }

        if (!this.rightPanelClick) {
            this.setState({
                rightPanelActive: false
            })
        }

        this.topbarItemClick = false;
        this.menuClick = false;
        this.rightPanelClick = false;
    }

    hideOverlayMenu() {
        this.setState({
            rotateMenuButton: false,
            overlayMenuActive: false,
            staticMenuMobileActive: false
        })
    }

    isTablet() {
        let width = window.innerWidth;
        return width <= 1024 && width > 640;
    }

    isDesktop() {
        return window.innerWidth > 1024;
    }

    isMobile() {
        return window.innerWidth <= 640;
    }

    isOverlay() {
        return this.state.layoutMode === 'overlay';
    }

    isHorizontal() {
        return this.state.layoutMode === 'horizontal';
    }

    isSlim() {
        return this.state.layoutMode === 'slim';
    }

    changeTheme(theme) {
        this.setState({ theme: theme });
        if (this.state.version === 'v3') {
            this.changeStyleSheetUrl('theme-css', theme, 'theme');
        } else {
            this.changeStyleSheetUrl('theme-css', theme + '-v4', 'theme');
        }
    }

    changeLayout(layout, special) {
        this.setState({ layout: layout });
        if (this.state.version === 'v3') {
            this.changeStyleSheetUrl('layout-css', layout, 'layout');
        } else {
            this.changeStyleSheetUrl('layout-css', layout + '-v4', 'layout');
        }

        if (special) {
            this.setState({
                darkMenu: true
            })
        }
    }

    changeVersion(version) {
        this.setState({ version: version });
        if (version === 'v3') {
            this.changeStyleSheetUrl('layout-css', this.state.layout, 'layout');
            this.changeStyleSheetUrl('theme-css', this.state.theme, 'theme');
        } else {
            this.changeStyleSheetUrl('layout-css', this.state.layout + '-v4', 'layout');
            this.changeStyleSheetUrl('theme-css', this.state.theme + '-v4', 'theme');
        }
    }

    changeStyleSheetUrl(id, value, prefix) {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');
        element.setAttribute('href', newURL);
    }

    createMenu(userInfo,badges) {
        if (userInfo) {
        this.menu = [
            { label: 'Dashboard', icon: 'fa fa-fw fa-home', to: '/wastegen' },
            { label: 'แชทกับแอดมิน', icon: 'fa fa-comments', to: '/wastegen/admin-chat', badge: badges || null },
            //{ label: 'ChatWhitU2', icon: 'fa fa-comments', to: '/wastegen/chat' },
            { label: 'แจ้งถอนเงิน', icon: 'fa fa-fw fa-gg', to: '/wastegen/withdraw' },
            { label: 'บริจาค', icon: 'fa fa-fw fa-money', to: '/wastegen/donate' },
            { label: 'ประวัติการบริจาค', icon: 'fa fa-fw fa-history', to: '/wastegen/donate-history' },
            //{ label: 'ค่าความดี', icon: 'fa fa-star-half-o', to: '/wastegen/Goodness' },
            { label: 'รายการขาย', icon: 'fa fa-fw fa-circle', to: '/wastegen/order-list' },
            { label: 'รายงานการขาย', icon: 'fa fa-fw fa-credit-card', to: '/wastegen/reports-buying' },
            { label: 'ใบราคา', icon: 'fa fa-fw fa-credit-card', to: '/wastegen/reports-amount' },
            { label: 'ใบรับรอง/เหรียญ', icon: 'fa fa-fw fa-certificate', to: '/wastegen/certificate' },
            
            //{ label: 'ข้อมูลการติดต่อ C3Leng', icon: 'fa fa-address-card-o', to: '/wastegen/wastegen-contact' },
            //{ label: 'ใบรับรอง/เหรียญ', icon: 'fa fa-trophy', to: '/wastegen/certificate' },

            // {
            //     label: 'Settings', icon: 'fa fa-fw fa-bars',
            //     items: [
            //         { label: 'Static Menu', icon: 'fa fa-fw fa-bars', command: () => this.setState({ layoutMode: 'static' }) },
            //         { label: 'Inline Profile', icon: 'fa fa-sun-o fa-fw', command: () => this.setState({ profileMode: 'inline' }) },
            //         { label: 'Top Profile', icon: 'fa fa-moon-o fa-fw', command: () => this.setState({ profileMode: 'top' }) },
            //         { label: 'Light Menu', icon: 'fa fa-sun-o fa-fw', command: () => this.setState({ darkMenu: false }) },
            //         { label: 'Dark Menu', icon: 'fa fa-moon-o fa-fw', command: () => this.setState({ darkMenu: true }) }
            //     ]
            // }
        ];
    }
    }

    redirectProfile = (event) => {
        this.setState({staticMenuMobileActive:false})
        window.location = '#/wastegen/profile';
        event.preventDefault();
        this.setState({userNotCompleteDialog: false})
    }
    redirectRegisTran = (event) => {
        this.setState({staticMenuMobileActive:false})
        ServiceAPI.wastegenRegisWastetran().then(res => {
            if(res.status == 200){
                localStorage.setItem('user-type', 'wastetran');
                window.location = '#/wastetran/profile';
                event.preventDefault();
            }
            this.setState({displayDialog: false})
        })
        
    }
    onRegisTranClick = (event) => {
        this.setState({displayDialog: true})
    }
    changePasswordU1 = (event) => {
        this.setState({staticMenuMobileActive:false})
        window.location = '#/wastegen/change-password';
        event.preventDefault();
    }
    changePinU1 = (event) => {
        this.setState({staticMenuMobileActive:false})
        window.location = '#/wastegen/change-pin';
        event.preventDefault();
    }

    render() {
        const {

            province,
            locationServiceProps,
            userServiceProps,
            userInfo

        } = this.props

        let layoutClassName = classNames('layout-wrapper', {
            'menu-layout-static': this.state.layoutMode !== 'overlay',
            'menu-layout-overlay': this.state.layoutMode === 'overlay',
            'layout-menu-overlay-active': this.state.overlayMenuActive,
            'menu-layout-slim': this.state.layoutMode === 'slim',
            'menu-layout-horizontal': this.state.layoutMode === 'horizontal',
            'layout-menu-static-inactive': this.state.staticMenuDesktopInactive,
            'layout-menu-static-active': this.state.staticMenuMobileActive
        });

        let menuClassName = classNames('layout-menu-container', { 'layout-menu-dark': this.state.darkMenu });

        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ยืนยัน" icon="pi pi-check" onClick={this.redirectRegisTran}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displayDialog: false})} className="p-button-warning"/>
        </div>;

        let userNotCompleteFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ทำตอนนี้" icon="pi pi-check" onClick={this.redirectProfile}/>
            <Button label="ยังไม่ทำตอนนี้" icon="pi pi-times" onClick={() => this.setState({userNotCompleteDialog: false})} className="p-button-warning"/>
        </div>;

        return (
            <div className={layoutClassName} onClick={this.onDocumentClick}>
                <div>
                    <AppTopbar
                        profileMode={this.state.profileMode} horizontal={this.isHorizontal()}
                        topbarMenuActive={this.state.topbarMenuActive}
                        activeTopbarItem={this.state.activeTopbarItem}
                        onMenuButtonClick={this.onMenuButtonClick}
                        onTopbarMenuButtonClick={this.onTopbarMenuButtonClick}
                        onTopbarItemClick={this.onTopbarItemClick} />

                    <div className={menuClassName} onClick={this.onMenuClick}>
                        <ScrollPanel ref={(el) => this.layoutMenuScroller = el} style={{ height: '100%' }}>
                            <div className="menu-scroll-content">
                                {(this.state.profileMode === 'inline' && this.state.layoutMode !== 'horizontal') && <WastgenInlineProfile redirectProfile={this.redirectProfile} 
                                changePasswordU1={this.changePasswordU1}  changePinU1={this.changePinU1} onRegisTranClick={this.onRegisTranClick} {...this.props} />}
                                <AppMenu model={this.menu} onMenuItemClick={this.onMenuItemClick} onRootMenuItemClick={this.onRootMenuItemClick}
                                    layoutMode={this.state.layoutMode} active={this.state.menuActive} />
                            </div>
                        </ScrollPanel>
                    </div>

                    <div className="layout-main">

                        <PropsRoute path="/wastegen" exact component={WastegenDashboard} />
                        <PropsRoute path="/wastegen/example-profile" component={WastegenProfile} {...this.props} />
                        <PropsRoute path="/wastegen/example-withdraw" component={WastegenWithdraw} />
                        <PropsRoute path="/wastegen/example-reports-buying" component={WastgenReportsBuying} />
                        <PropsRoute path="/wastegen/example-reports-amount" component={WastegenReportsAmount} />
                        <PropsRoute path="/wastegen/Goodness" component={WastegenGoodness} />
                        <PropsRoute path="/wastegen/certificate" component={WastegenCertificate} />
                        <PropsRoute path="/wastegen/admin-chat" component={WastegenChatAdmin} {...this.props} />
                        <PropsRoute path="/wastegen/wastegen-contact" component={WastegenContact} />
                        <PropsRoute path="/wastegen/chat" component={WastegenChatView} />
                        <PropsRoute path="/wastegen/news-detail" component={WastegenNewsDetail} />
                        <PropsRoute path="/wastegen/donate" component={WastegenDonate} />
                        <PropsRoute path="/wastegen/donate-history" component={WastegenDonateHistory} />
                        
                        
                        <PropsRoute
                            path="/wastegen/profile"
                            component={ExampleWastegenProfileCtrl}
                            userInfo={this.props.userInfo}
                            province={this.props.province}
                            district={this.props.district}
                            subDistrict={this.props.subDistrict}
                            banks={this.props.banks}
                        />
                        <PropsRoute
                            path="/wastegen/change-password"
                            component={ExampleWastegenChangePassword}
                            userInfo={this.props.userInfo}
                            province={this.props.province}
                            district={this.props.district}
                            subDistrict={this.props.subDistrict}
                            banks={this.props.banks}
                        />
                        <PropsRoute
                            path="/wastegen/change-pin"
                            component={ExampleWastegenChangePin}
                            userInfo={this.props.userInfo}
                            province={this.props.province}
                            district={this.props.district}
                            subDistrict={this.props.subDistrict}
                            banks={this.props.banks}
                        />
                        

                        <PropsRoute
                            path="/wastegen/changepassword"
                            component={ExampleChangePasswordCtrl}
                            {...this.props} />

                        <PropsRoute
                            path="/wastegen/withdraw"
                            component={ExampleWastegenWithdrawCtrl}
                        />
                        <PropsRoute
                            path="/wastegen/order-list"
                            component={WastegenOrderList}
                        />
                        <PropsRoute
                            path="/wastegen/reports-buying"
                            component={ExampleWastegenReportBuyingCtrl}
                        />
                        
                        <PropsRoute
                            path="/wastegen/reports-amount"
                            component={WastegenReportsAmount}
                        />


                    </div>


                </div>
                <Dialog 
                    visible={this.state.displayDialog} 
                    style={{width: '40vw'}} 
                    header="สมัครเป็นทีมขนส่งซีซาเล้งทันที" 
                    modal={true} 
                    footer={dialogFooter} 
                    onHide={() => this.setState({displayDialog: false})}>
                        ต้องการสลับเป็นทีมขนส่งซีซาเล้งทันที
                </Dialog>

                <Dialog 
                    visible={this.state.userNotCompleteDialog} 
                    style={{width: '40vw'}} 
                    header="แจ้งเตือน" 
                    modal={true} 
                    footer={userNotCompleteFooter} 
                    onHide={() => this.setState({userNotCompleteDialog: false})}>
                        ท่านต้องระบุตำแหน่งที่อยู่ในแผนที่ และป้อนข้อมูลบัญชีธนาคารก่อน ถึงจะเริ่มทำการซื้อขายได้
                </Dialog>
            </div>
        );
    }
}



const mapStateToProps = (state) => ({
    locationMaster: state.location,
    province: state.location.province,
    district: state.location.district,
    subDistrict: state.location.subDistrict,
    authentication: state.authentication,
    userInfo: state.userInfo.data,
    userInfoMaster: state.userInfo,
    updateWastegenProfile: state.updateWastegenProfile,
    changePassword: state.changePassword,

    banks: state.master.banks,
    getFileWastegen: state.getFileWastegen,
    chatSingleBadge: state.chatSingleBadge,

})

const mapDispatchToProps = dispatch => ({
    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    fileServiceProps: bindActionCreators(FileService, dispatch),
    ChatServiceProps: bindActionCreators(ChatService, dispatch),
})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(WastegenContainer)

