

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { getDistrict, getSubDistrict, getCardSubDistrict, getCardDistrict } from '../../service/LocationService';
import { ProfileInfoView } from '../profile/ProfileInfoView';
import { Validators, SecureUtils } from '../../utils';
import ImageInfoView from '../profile/ImageInfoView';
import { API_ROOT } from '../../constants/api';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox';
import BankInfoView from '../profile/BankInfoView';
import AddressSwapInfoView from '../profile/AddressSwapInfoView';
import { Checkbox } from 'primereact/checkbox';
import { InputSwitch } from 'primereact/inputswitch';
import ChangePassword from '../change-password/ChangePassword';
import { ProgressSpinner } from 'primereact/progressspinner';

import { getUserInfo, changePassword, updateWasteproProfile } from '../../service/user.service';
import { deleteWasteproFile } from '../../service/file.service';
import * as ServerAPI from '../register/service'

class ExampleWasteproProfileCtrl extends Component {

    constructor(props) {
        //console.log("ExampleWasteproProfileCtrl :constructor")
        super(props);
        this.state = {
            isInitial: true,
            isUpdate: false,
            isInitialSelected: true,
            isValidateError: false,
            //> profile info
            selectedPersonPrefix: null,
            selectedCompanyType: null,
            personPrefixs: [
                { name: 'นาย', code: 1 },
                { name: 'นาง', code: 2 },
                { name: 'นางสาว', code: 3 },
            ],
            companyTypes: [
                { name: 'บริษัทจำกัด', code: 1 },
                { name: 'ห้างหุ้นส่วน', code: 2 },
                { name: 'บริษัทมหาชน', code: 3 },
                { name: 'ห้างร้าน', code: 4 },
            ],
            listFileDelete: [],
            errMobileMsg:null,
            isErrorPhone:false,
            //> Bank info
            selectedBank: null,
            //> Address card user current
            checkCardAddress: null,
            //> Address Current info
            selectedAdrCurrentProv: null,
            selectedAdrCurrentDistrict: null,
            selectedAdrCurrentSubDistrict: null,
            //> Address Card info
            selectedAdrCardProv: null,
            selectedAdrCardDistrict: null,
            selectedAdrCardSubDistrict: null,
            //wastepro order
            wasteTypesSelected: [],
            uid: null,
            wasteTypes: [
                { label: 'ขวดแก้ว', value: 1 },
                { label: 'กระดาษ', value: 2 },
                { label: 'พลาสติก', value: 3 },
                { label: 'โลหะ/อโลหะ', value: 4 },
                { label: 'อิเล็กทรอนิกส์/เครื่องใช้ไฟฟ้า', value: 5 }
            ],
            //map
            marker: {},
            mapZoom: 11,
            mapCenter: { lat: 13.7734283, lng: 100.57383099999993 },
            firstCenter: {},
            //PASSWORD
            isShowChangePassword: false,
            isChangePassword: false,
            checkedPasswordVisible: false,
            typePasswordInput: 'password',
            errorMessageOldPassword: null,
            errorMessagePassword: null,
            errorMessageConfirmPassword: null,
            oldPassword: null,
            newPassword: null,
            confirmPassword: null,
            disableNewPass: true
        }
    }

    componentDidMount() {
        //console.log("ExampleWasteproProfileCtrl :componentDidMount")
        this.props.dispatch(getUserInfo())
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        //console.log("ExampleWasteproProfileCtrl :componentWillReceiveProps", nextProps)
        if (
            nextProps.userInfoModel
            && !nextProps.userInfoLoad.isLoading
            && !nextProps.changePasswordLoad.isLoading
            && !nextProps.locationLoad.isFetching
        ) {
            this.initialDropdown(nextProps);

            if (this.state.isInitial) {
                if (
                    nextProps.userInfoModel
                ) {
                    this.initial(nextProps.userInfoModel)
                }

            }
        }

        //> Status submit
        if (this.state.isUpdate) {
            switch (this.state.isChangePassword) {
                case true:
                    if (!nextProps.changePasswordLoad.isLoading) {
                        if (nextProps.changePasswordLoad.isFailed) {
                            this.showError("รหัสผ่าน :" + nextProps.changePasswordModel)
                            this.setState({ isUpdate: false })
                            this.setState({ isInitial: false })
                            this.setState({ isChangePassword: false })
                        } else {
                            this.saveWastePro()
                            if (!nextProps.updateWasteproProfileLoad.isLoading) {
                                if (!nextProps.updateWasteproProfileLoad.isFailed) {
                                    this.props.dispatch(getUserInfo());
                                    this.showSuccess("บันทึกข้อมูลสำเร็จ")
                                    this.setState({ isUpdate: false })
                                    this.setState({ isInitial: true })
                                    this.setState({ isInitialSelected: true })
                                    this.setState({ isChangePassword: false })
                                    this.setState({ isShowChangePassword: false })
                                    this.setState({ listFileDelete: [] })
                                }

                                if (nextProps.updateWasteproProfileLoad.isFailed) {
                                    this.showError("บันทึกข้อมูลผิดพลาด")
                                    this.setState({ isUpdate: false })
                                    this.setState({ isInitial: false })
                                }
                            }
                        }
                    }
                    break;
                case false:
                    if (!nextProps.userInfoLoad.isLoading) {
                        if (!nextProps.userInfoLoad.isFailed) {
                            this.props.dispatch(getUserInfo());
                            this.showSuccess("บันทึกข้อมูลสำเร็จ")
                            this.setState({ isUpdate: false })
                            this.setState({ isInitial: true })
                            this.setState({ isInitialSelected: true })
                            this.setState({ isChangePassword: false })
                            this.setState({ isShowChangePassword: false })
                            this.setState({ listFileDelete: [] })
                        }

                        if (nextProps.userInfoLoad.isFailed) {
                            this.showError("บันทึกข้อมูลผิดพลาด")
                            this.setState({ isUpdate: false })
                            this.setState({ isInitial: false })
                        }
                    }
                    break;
            }
        }

    }
    shouldComponentUpdate(nextProps, nextState) {
        //console.log("ExampleWasteproProfileCtrl :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        //console.log("ExampleWasteproProfileCtrl :componentWillUpdate")
        if (this.state.isValidateError != nextState.isValidateError) {
            this.validateRequire(nextState)
        }

    }
    componentDidUpdate(prevProps, prevState) {
        //console.log("ExampleWasteproProfileCtrl :componentDidUpdate")
        this.initialSelected(this.state)
        if (this.state.person_type != prevState.person_type) {
            this.validateRequire(this.state)
        }
    }

    saveWastePro = () => {
        const formData = new FormData();
        let wasteTypeString = [];
        for (let key in this.state.wasteTypesSelected) {
            let entity = this.state.wasteTypesSelected[key]
            wasteTypeString.push(entity.value)
        }

        //>custom File
        const fLimit = 1000000;
        if (this.state.fileImgIden && this.state.fileImgIden.size <= fLimit) {
            formData.append('FileImgIden', this.state.fileImgIden);
        }
        if (this.state.fileImgRegis && this.state.fileImgRegis.size <= fLimit) {
            formData.append('FileImgRegis', this.state.fileImgRegis);
        }
        if (this.state.fileImgPerson && this.state.fileImgPerson.size <= fLimit) {
            formData.append('FileImgPerson', this.state.fileImgPerson);
        }
        if (this.state.fileImgShop && this.state.fileImgShop.size <= fLimit) {
            formData.append('FileImgShop', this.state.fileImgShop);
        }

        formData.append('area_rai', this.state.area_rai || 0);
        formData.append('area_ngan', this.state.area_ngan || 0);
        formData.append('area_square_wah', this.state.area_square_wah || 0);
        formData.append('vehicle_type', this.state.vehicle_type || 0);
        formData.append('vehicle_no', this.state.vehicle_no);
        formData.append('vehicle_img_name', this.state.vehicle_img_name);
        formData.append('bank_account_name', this.state.bank_account_name||'');
        formData.append('bank_account_no', this.state.bank_account_no||'');
        formData.append('bank_code', this.state.bank_code);
        formData.append('bank_promptpay', this.state.bank_promptpay||'');
        formData.append('bank_withdraw_code', this.state.bank_withdraw_code);
        // formData.append('wasteTypeList', this.state.wasteTypesSelected);
        formData.append('wasteTypeListString', JSON.stringify(wasteTypeString));
        formData.append('uid', this.state.uid);
        formData.append('person_type', this.state.person_type || 0);
        formData.append('person_prefix_code', this.state.person_prefix_code || 0);
        formData.append('person_first_name', this.state.person_first_name||'');
        formData.append('person_last_name', this.state.person_last_name||'');
        formData.append('person_idcard', this.state.person_idcard);
        formData.append('org_type', this.state.org_type || 0);
        formData.append('org_name', this.state.org_name||'');
        formData.append('org_code', this.state.org_code || 0);
        formData.append('telephone_no1', this.state.telephone_no1);
        formData.append('telephone_no2', this.state.telephone_no2 || '');
        formData.append('line_id', this.state.line_id || '');
        formData.append('email', this.state.email||'');
        formData.append('addr_card_province_code', this.state.addr_card_province_code || 0);
        formData.append('addr_card_district_code', this.state.addr_card_district_code || 0);
        formData.append('addr_card_subdistrict_code', this.state.addr_card_subdistrict_code || 0);
        formData.append('addr_card_detail', this.state.addr_card_detail||'');
        formData.append('addr_current_province_code', this.state.addr_current_province_code || 0);
        formData.append('addr_current_district_code', this.state.addr_current_district_code || 0);
        formData.append('addr_current_subdistrict_code', this.state.addr_current_subdistrict_code || 0);
        formData.append('addr_current_detail', this.state.addr_current_detail||'');
        formData.append('latitude', this.state.latitude || 0);
        formData.append('longitude', this.state.longitude || 0);
        formData.append('img_iden_name', this.state.img_iden_name);
        formData.append('img_regis_org_name', this.state.img_regis_org_name);
        formData.append('img_person_name', this.state.img_person_name);
        formData.append('update_date', this.state.update_date);
        formData.append('update_by_uid', this.state.update_by_uid);
        formData.append('addr_card_use_current', (this.state.checkCardAddress) ? 1 : 0);

        //Delete file in list action
        this.state.listFileDelete.map(fileName => {
            fileName = fileName || ""
            let fileType = null;
            let spFname = fileName.split("-")
            if (spFname.length > 0) {
                fileType = spFname[0]
            }
            this.props.dispatch(deleteWasteproFile(fileType, fileName))
        })
        this.props.dispatch(updateWasteproProfile(formData))
    }
    //> Submit
    onSubmit = async (e) => {
        e.preventDefault();

        await this.validateRequire(this.state);

        if (this.state.isValidateError) {
            this.showWarn();
        } else {

            if (this.state.isChangePassword) {
                var jsPass = {
                    username: SecureUtils.getItem('user').username,
                    oldPassword: this.state.oldPassword,
                    newPassword: this.state.newPassword,
                    confirmPassword: this.state.confirmPassword,
                    statusMessage: "string",
                }
                this.props.dispatch(changePassword(JSON.parse(JSON.stringify(jsPass))))
            } else {
                this.saveWastePro()
                this.setState({ isShowChangePassword: false })
            }

            this.setState({ isUpdate: true })
        }
    }

    //> Initial function
    initial = (nextProps) => {
        if (nextProps) {
            for (let key in nextProps) {
                let val = nextProps[key]
                this.setState({ [key]: val })
            }
            this.setState({ isInitial: false })
            this.setUid(nextProps)
        }
    }
    //> Initial Droupdown
    initialDropdown = (nextProps) => {
        //console.log("Initial Droupdown ", nextProps)
        //> banks
        if (nextProps.banks && nextProps.banks.length > 0) {
            this.setState({ banks: nextProps.banks })
        }
        //> adrees current info
        if (nextProps.provinceModel && nextProps.provinceModel.length > 0) {
            this.setState({ province: nextProps.provinceModel })
        }
        if (nextProps.currentDistrictModel && nextProps.currentDistrictModel.length > 0) {
            this.setState({ currentDistrict: nextProps.currentDistrictModel })
        }
        if (nextProps.currentSubDistrictModel && nextProps.currentSubDistrictModel.length > 0) {
            this.setState({ currentSubDistrict: nextProps.currentSubDistrictModel })
        }
        //> adrees card info
        if (nextProps.cardProvinceModel && nextProps.cardProvinceModel.length > 0) {
            this.setState({ cardProvince: nextProps.cardProvinceModel })
        }
        if (nextProps.cardDistrictModel && nextProps.cardDistrictModel.length > 0) {
            this.setState({ cardDistrict: nextProps.cardDistrictModel })
        }
        if (nextProps.cardSubDistrictModel && nextProps.cardSubDistrictModel.length > 0) {
            this.setState({ cardSubDistrict: nextProps.cardSubDistrictModel })
        }
    }
    //> Initail selected
    initialSelected = (nextState) => {
        //console.log("Initail selected ", nextState)

        const {
            //profile info
            selectedPersonPrefix,
            person_prefix_code,
            personPrefixs,
            selectedCompanyType,
            org_type,
            companyTypes,
            //bank info
            selectedBank,
            bank_code,
            banks,
            //Adress current info : province
            selectedAdrCurrentProv,
            addr_current_province_code,
            province,
            //Adress current info : district
            selectedAdrCurrentDistrict,
            addr_current_district_code,
            currentDistrict,
            //Adress current info : sub district
            selectedAdrCurrentSubDistrict,
            addr_current_subdistrict_code,
            currentSubDistrict,

            //Adress Card info : province
            selectedAdrCardProv,
            addr_card_province_code,
            cardProvince,
            //Address user current
            checkCardAddress,
            addr_card_use_current,
            isInitialSelected,
            //Adress current info : district
            selectedAdrCardDistrict,
            addr_card_district_code,
            cardDistrict,
            //Adress current info : sub district
            selectedAdrCardSubDistrict,
            addr_card_subdistrict_code,
            cardSubDistrict,
            //WasteType
            wasteTypesSelected,
            wasteTypeList,
            wasteTypes,
            //Map
            latitude,
            longitude,
            marker
        } = nextState

        //> map
        if (!marker.lat && latitude && longitude) {
            this.setState({ marker: { lat: latitude, lng: longitude } })
            this.setState({ mapCenter: { lat: latitude, lng: longitude } })
        }
        //> profile info
        if (!selectedPersonPrefix && person_prefix_code) {
            for (let key in personPrefixs) {
                let val = personPrefixs[key]
                if (person_prefix_code == val.code) {
                    this.setState({ selectedPersonPrefix: val })
                }
            }
        }
        if (!selectedCompanyType && org_type) {
            for (let key in companyTypes) {
                let val = companyTypes[key]
                if (org_type == val.code) {
                    this.setState({ selectedCompanyType: val })
                }
            }
        }

        if (!selectedPersonPrefix && person_prefix_code) {
            for (let key in personPrefixs) {
                let val = personPrefixs[key]
                if (person_prefix_code == val.code) {
                    this.setState({ selectedPersonPrefix: val })
                }
            }
        }

        //> bank info
        if (!selectedBank && bank_code) {
            for (let key in banks) {
                let val = banks[key]
                if (bank_code == val.bank_code) {
                    this.setState({ selectedBank: val })
                }
            }
        }
        //> Adress use current
        if (checkCardAddress == null && addr_card_use_current) {
            if (addr_card_use_current == 1) {
                this.setState({ checkCardAddress: true })
            }
        }
        //> Adress current info
        if (!selectedAdrCurrentProv && addr_current_province_code) {
            for (let key in province) {
                let val = province[key]
                if (addr_current_province_code == val.provinceCode) {
                    //console.log("province ")
                    this.setState({ selectedAdrCurrentProv: val })
                }
            }
        }
        if (!selectedAdrCurrentDistrict && addr_current_district_code) {
            for (let key in currentDistrict) {
                let val = currentDistrict[key]
                if (addr_current_district_code == val.districtCode) {
                    this.setState({ selectedAdrCurrentDistrict: val })
                }
            }
        }
        if (!selectedAdrCurrentSubDistrict && addr_current_subdistrict_code) {
            for (let key in currentSubDistrict) {
                let val = currentSubDistrict[key]
                if (addr_current_subdistrict_code == val.subDistrictCode) {
                    this.setState({ selectedAdrCurrentSubDistrict: val })
                }
            }
        }
        //> Adress card info
        if (!selectedAdrCardProv && addr_card_province_code) {
            for (let key in cardProvince) {
                let val = cardProvince[key]
                if (addr_card_province_code == val.provinceCode) {
                    //console.log("province ")
                    this.setState({ selectedAdrCardProv: val })
                }
            }
        }
        if (!selectedAdrCardDistrict && addr_card_district_code) {
            for (let key in cardDistrict) {
                let val = cardDistrict[key]
                if (addr_card_district_code == val.districtCode) {
                    this.setState({ selectedAdrCardDistrict: val })
                }
            }
        }
        if (!selectedAdrCardSubDistrict && addr_card_subdistrict_code) {
            for (let key in cardSubDistrict) {
                let val = cardSubDistrict[key]
                if (addr_card_subdistrict_code == val.subDistrictCode) {
                    this.setState({ selectedAdrCardSubDistrict: val })
                }
            }
        }
        //> WasteType
        if (isInitialSelected && wasteTypesSelected.length == 0 && wasteTypeList && wasteTypeList.length != 0) {
            for (let key in wasteTypeList) {
                let entity = wasteTypeList[key]
                if (wasteTypesSelected.indexOf(entity) == -1) {
                    for (let inx in wasteTypes) {
                        let result = wasteTypes[inx]
                        if (result.value == entity) {
                            this.state.wasteTypesSelected.push(result);
                            this.setState({ isInitialSelected: !isInitialSelected })
                        }
                    }
                }
            }
        }
    }

    //> Validate
    validateRequire = async (state) => {
        this._classNameRequired(false)

        if (state.person_type == '1') {
            this._validateLogic(state.person_prefix_code)
            this._validateLogic(state.person_first_name)
            this._validateLogic(state.person_last_name)
            if (state.person_idcard) {
                if (Validators.isInValid(state.person_idcard, 13)) {
                    this._classNameRequired(true)
                }
            }
        } else {
            this._validateLogic(state.org_type)
            this._validateLogic(state.org_name)
            this._validateLogic(state.org_code)
            if (state.org_code) {
                if (Validators.isInValid(state.org_code, 13)) {
                    this._classNameRequired(true)
                }
            } else {
                this._classNameRequired(true)
            }
        }

        //Password
        if (state.isChangePassword == true) {
            this._validateLogic(state.oldPassword);
            if (state.newPassword != state.confirmPassword) {
                this._classNameRequired(true)
            }
        }
        //Email
        if (state.email) {
            if (!Validators.isEmail(state.email)) {
                this._classNameRequired(true)
            }
        }

        //Mobile number
        if (Validators.isInValidPhoneNumberRequired(state.telephone_no1, 10)) {
            this._classNameRequired(true)
        }

        //wasteType
        // state.wasteTypesSelected = state.wasteTypesSelected || []
        // console.log('state.wasteTypesSelected ', state.wasteTypesSelected)
        // if (state.wasteTypesSelected && state.wasteTypesSelected.length == 0) {
        //     this._classNameRequired(true)
        // }


        //address
        this._validateAddressInfo(1)
        // if (state.checkCardAddress) {
        //     this._validateAddressInfo(1)
        // } else {
        //     this._validateAddressInfo(1)
        //     this._validateAddressInfo(2)
        // } 
        //Phone
        if(state.isErrorPhone||!state.telephone_no1){
            this._classNameRequired(true)
        }
    }
    _validateAddressInfo = (type) => {
        //>Card
        let chkCardProv = Validators.isEmpty(this.state.selectedAdrCardProv)
        let chkCardDistrict = Validators.isEmpty(this.state.selectedAdrCardDistrict)
        let chkCardSubDistrict = Validators.isEmpty(this.state.selectedAdrCardSubDistrict)
        let chkCardAddress = Validators.isEmpty(this.state.addr_card_detail)
        //>Current
        let chkCurrentProv = Validators.isEmpty(this.state.selectedAdrCurrentProv)
        let chkCurrentDistrict = Validators.isEmpty(this.state.selectedAdrCurrentDistrict)
        let chkCurrentSubDistrict = Validators.isEmpty(this.state.selectedAdrCurrentSubDistrict)
        let chkCurrentAddress = Validators.isEmpty(this.state.addr_current_detail)
        if (type == 2) {
            if (
                chkCardProv
                || chkCardDistrict
                || chkCardSubDistrict
                || chkCardAddress
            ) {
                this._classNameRequired(true)
            }
        } else {
            if (
                chkCurrentProv
                || chkCurrentDistrict
                || chkCurrentSubDistrict
                || chkCurrentAddress
            ) {
                this._classNameRequired(true)
            }
        }
    }
    _validateLogic = (value) => {
        if (Validators.isEmpty(value)) {
            this._classNameRequired(true)
        }
    }
    _classNameRequired = (status) => {
        const classNameRequired = status == true ? "p-error" : "";
        this.setState({ classNameRequired: classNameRequired })
        this.setState({ isValidateError: status })
    }
    //> Event
    onChangeInputText = (e) => {
        const { name, value, required } = e.target
        this.setState({ [name]: value })
        //case check email
        if (name == "email") {
            if (!Validators.isEmpty(value) && !Validators.isEmail(value)) {
                this.setState({ isValidateError: true })
            } else {
                this.setState({ isValidateError: false })
            }
        }
        //Check required
        if (required) {
            if (Validators.isEmpty(value)) {
                this.setState({ isValidateError: true })
            } else {
                this.setState({ isValidateError: false })
            }
        } 
        //Tel mobile 1
        if (name == 'telephone_no1') {
            var telNameModel = {
                telephoneNumber: value
            }
            ServerAPI.checkExitPhoneAndName(telNameModel).then((res) => {
                let tmpChkUid = res.filter((value) => {
                    return value.userId == SecureUtils.getItem('uid')
                })
                if (res.length > 0 && tmpChkUid.length == 0) {
                    this.setState({ errMobileMsg: "หมายเลข " + value + " มีอยู่แล้วในระบบ" });
                    this.setState({ isErrorPhone: true });
                } else {
                    this.setState({ errMobileMsg: null });
                    this.setState({ isErrorPhone: false });
                }
            }).catch((error) => {
                this.setState({ errMobileMsg: "กรุณาติดต่อผู้ดูแลระบบ" });
                this.setState({ isErrorPhone: true });
            })
        }
    }
    //> Image View
    onSelectFileImgIden = (e) => {
        this.setState({ fileImgIden: e.files[0] });
    }
    onRefreshIden = (e) => {
        this.setState({ img_iden_name: this.props.userInfoModel.img_iden_name })
        this.setState({
            listFileDelete: this.state.listFileDelete.filter(item => item != this.props.userInfoModel.img_iden_name)
        })
    }
    onDeleteIden = (e) => {
        this.state.listFileDelete.push(this.state.img_iden_name)
        this.setState({ img_iden_name: null })
        this.setState({ fileImgIden: null });
    }
    onSelectFileImgRegis = (e) => {
        this.setState({ fileImgRegis: e.files[0] });
    }
    onRefreshRegis = (e) => {
        this.setState({ img_regis_org_name: this.props.userInfoModel.img_regis_org_name })
        this.setState({
            listFileDelete: this.state.listFileDelete.filter(item => item != this.props.userInfoModel.img_regis_org_name)
        })
    }
    onDeleteRegis = (e) => {
        this.state.listFileDelete.push(this.state.img_regis_org_name)
        this.setState({ img_regis_org_name: null })
        this.setState({ fileImgRegis: null });
    }
    onSelectFileImgPerson = (e) => {
        this.setState({ fileImgPerson: e.files[0] });
    }
    onRefreshPerson = (e) => {
        this.setState({ img_person_name: this.props.userInfoModel.img_person_name })
        this.setState({
            listFileDelete: this.state.listFileDelete.filter(item => item != this.props.userInfoModel.img_person_name)
        })
    }
    onDeletePerson = (e) => {
        this.state.listFileDelete.push(this.state.img_person_name)
        this.setState({ img_person_name: null })
        this.setState({ fileImgPerson: null });
    }
    onSelectFileImgShop = (e) => {
        this.setState({ fileImgShop: e.files[0] });
    }
    onRefreshShop = (e) => {
        this.setState({ vehicle_img_name: this.props.userInfoModel.vehicle_img_name })
        this.setState({
            listFileDelete: this.state.listFileDelete.filter(item => item != this.props.userInfoModel.vehicle_img_name)
        })
    }
    onDeleteShop = (e) => {
        this.state.listFileDelete.push(this.state.vehicle_img_name)
        this.setState({ vehicle_img_name: null })
        this.setState({ fileImgShop: null });
    }
    //> Event Profile info
    onChangePersonPrefix = (e) => {
        const { name, value } = e.target
        this.setState({ selectedPersonPrefix: value })
        this.setState({ [name]: value.code })
    }
    onChangeCompanyType = (e) => {
        const { name, value } = e.target
        this.setState({ selectedCompanyType: value })
        this.setState({ [name]: value.code })
    }
    //> Event :Bank
    onChanageBank = (e) => {
        const { name, value } = e.target
        this.setState({ selectedBank: value })
        this.setState({ [name]: value.bank_code })
    }
    //> Event Address
    onDuplicate = (e) => {
        const { checked } = e
        //console.log("check dup : ", e)
        this.setState({ checkCardAddress: checked })
    }
    onChangeAdrCurrProv = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCurrentProv: value })
        this.setState({ addr_current_province_code: value.provinceCode });
        this.setState({ selectedAdrCurrentDistrict: null })
        this.setState({ addr_current_district_code: null })
        this.setState({ selectedAdrCurrentSubDistrict: null })
        this.setState({ addr_current_subdistrict_code: null })

        this.props.dispatch(getDistrict(value.provinceCode));
    }
    onChangeAdrCurrentDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCurrentDistrict: value })
        this.setState({ addr_current_province_code: value.provinceCode });

        this.setState({ selectedAdrCurrentSubDistrict: null })
        this.setState({ addr_current_subdistrict_code: null })

        this.props.dispatch(getSubDistrict(value.districtCode));
    }
    onChangeAdrCurrentSubDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCurrentSubDistrict: value })
        this.setState({ addr_current_province_code: value.provinceCode });
        this.setState({ addr_current_district_code: value.districtCode });
        this.setState({ addr_current_subdistrict_code: value.subDistrictCode });
    }
    onChangeAdrCardProv = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCardProv: value })
        this.setState({ addr_card_province_code: value.provinceCode });
        this.setState({ selectedAdrCardDistrict: null })
        this.setState({ addr_card_district_code: null })
        this.setState({ selectedAdrCardSubDistrict: null })
        this.setState({ addr_card_subdistrict_code: null })

        this.props.dispatch(getCardDistrict(value.provinceCode));
    }
    onChangeAdrCardDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCardDistrict: value })
        this.setState({ addr_card_province_code: value.provinceCode });

        this.setState({ selectedAdrCardSubDistrict: null })
        this.setState({ addr_card_subdistrict_code: null })

        this.props.dispatch(getCardSubDistrict(value.districtCode));
    }
    onChangeAdrCardSubDistrict = (e) => {
        const { value } = e.target
        this.setState({ selectedAdrCardSubDistrict: value })
        this.setState({ addr_card_province_code: value.provinceCode });
        this.setState({ addr_card_district_code: value.districtCode });
        this.setState({ addr_card_subdistrict_code: value.subDistrictCode });
    }
    //> Event change password
    onChangeSwitchPassword = (e) => {
        this.setState({ isShowChangePassword: e.checked })
        if (this.state.isShowChangePassword) {
            //console.log("isShow is ", this.state.isShowChangePassword)
            this.setState({ isChangePassword: false })
        }
    }
    onChangeOldPassword = (e) => {
        let password = e.target.value;
        this.setState({ errorMessageOldPassword: null });
        if (Validators.isNotEmpty(password)) {
            this.setState({ errorMessageOldPassword: "" });
            this.setState({ isChangePassword: true })
            this.setState({ oldPassword: password })
            this.setState({ disableNewPass: false });
            if (
                this.state.newPassword != this.state.confirmPassword
                && !this.state.newPassword
                || !this.state.confirmPassword
            ) {
                this.setState({ errorMessagePassword: "กรุณาระบุรหัสผ่าน" });
                this.setState({ errorMessageConfirmPassword: "กรุณาระบุรหัสผ่าน" });

                this._classNameRequired(true)
            } else {
                this._classNameRequired(false)
            }
        } else {
            this.setState({ isChangePassword: false })
            this._classNameRequired(false);
            this.setState({ disableNewPass: true });

        }
    }
    onChangeNewPassword = (e) => {
        let password = e.target.value;
        this.setState({ errorMessagePassword: null });

        if (password.length >= 6) {
            const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
            const isOk = re.test(password);
            if (!isOk) {
                this.setState({ errorMessagePassword: "ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่" });
                this._classNameRequired(true)
            } else {
                this.setState({ newPassword: password });
                this.setState({ errorMessagePassword: "" });
                if (password == this.state.confirmPassword) {
                    this.setState({ errorMessageConfirmPassword: "" });
                    this._classNameRequired(false)
                } else {
                    this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
                    this._classNameRequired(true)
                }
            }
        } else {
            this.setState({ errorMessagePassword: "กรุณากรอกอย่างน้อย 6 ตัวอักษร (ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่)" });
            this._classNameRequired(true)
        }
    }
    onChangeConfirmPassword = (e) => {
        let cfpassword = e.target.value;
        this.setState({ errorMessageConfirmPassword: "" });
        //console.log("isChangePassword ", this.state.isChangePassword, "pass ", this.state.newPassword, " cfpass ", cfpassword)
        if (this.state.newPassword == cfpassword) {
            this.setState({ confirmPassword: cfpassword });
            this.setState({ errorMessageConfirmPassword: "" });
            this.setState({ isChangePassword: true })
            this._classNameRequired(false)
        } else {
            this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
            this.setState({ confirmPassword: cfpassword });

            this._classNameRequired(true)
        }
    }
    onChangePasswordVisible = (e) => {
        this.setState({ checkedPasswordVisible: e.value })
        if (e.value) {
            this.setState({ typePasswordInput: 'text' });
        } else {
            this.setState({ typePasswordInput: 'password' });
        }
    }
    //> EVENT WASTEPRO
    onCheckChange = (e) => {
        const { value, checked } = e.target
        debugger
        switch (checked) {
            case true:
                if (this.state.wasteTypesSelected.indexOf(value) == -1) {
                    this.state.wasteTypesSelected.push(value)
                }
                break;
            case false:
                if (this.state.wasteTypesSelected.indexOf(value) > -1) {
                    let tmpFilter = this.state.wasteTypesSelected.filter(
                        item => item.value != value.value
                    );
                    // console.log(tmpFilter)
                    this.setState({
                        wasteTypesSelected: tmpFilter
                    })
                }
                break;
        }
    }
    setUid = (userInfo) => {
        if (userInfo && userInfo.user_wasteTypes) {
            for (let key in userInfo.user_wasteTypes) {
                this.setState({ uid: userInfo.user_wasteTypes[key].uid })
            }
        }
    }
    //> Event map
    onMarkerMoved = (e) => {
        //console.log("--onMarkerMoved");
        //console.log(e.latLng);
        //console.log(e);
        var coor = e.latLng.toJSON();
        this.setState({ latitude: coor.lat })
        this.setState({ longitude: coor.lng })
        this.setState({ marker: { lat: coor.lat, lng: coor.lng } })
        this.setState({ mapCenter: { lat: coor.lat, lng: coor.lng } })
    }
    onCenterChanged = (coor) => {
        if (coor) {
            this.setState({ latitude: coor.lat })
            this.setState({ longitude: coor.lng })
            this.setState({ marker: { lat: coor.lat, lng: coor.lng } })

        }

    }

    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }

    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }


    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    render() {
        //console.log("ExampleWasteproProfileCtrl :render", this.state)
        const { userInfoModel } = this.props

        // const fileIden = (
        //     <div>Loading...</div>
        // )
        // const fileRegis = (
        //     <div>Loading...</div>
        // )
        // const filePerson = (
        //     <div>Loading...</div>
        // )
        // const fileShop = (
        //     <div>Loading...</div>
        // )

        // if (userInfoModel) {
        //     fileIden = (
        //         <ImageInfoView
        //             header="รูปถ่ายบัตรประชาชน / หนังสือรับรอง"
        //             src={`${API_ROOT}/wastepro/getfile/${this.props.userInfoModel.img_iden_name}`}
        //             name="fileIden[]"
        //             onSelect={this.onSelectFileImgIden}
        //             accept="image/*"
        //         />
        //     )
        //     fileRegis = (
        //         <ImageInfoView
        //             header="กรณีเป็นนิติบุคคลรูปถ่าย ภพ20"
        //             src={`${API_ROOT}/wastepro/getfile/${this.props.userInfoModel.img_regis_org_name}`}
        //             name="fileRegis[]"
        //             onSelect={this.onSelectFileImgRegis}
        //             accept="image/*"
        //         />
        //     )
        //     filePerson = (
        //         <ImageInfoView
        //             header="รูปถ่ายสมาชิก"
        //             src={`${API_ROOT}/wastepro/getfile/${this.props.userInfoModel.img_person_name}`}
        //             name="filePerson[]"
        //             onSelect={this.onSelectFileImgPerson}
        //             accept="image/*"
        //         />
        //     )
        //     fileShop = (
        //         <ImageInfoView
        //             header="รูปถ่ายหน้าร้าน"
        //             src={`${API_ROOT}/wastepro/getfile/${this.props.userInfoModel.vehicle_img_name}`}
        //             name="fileShop[]"
        //             onSelect={this.onSelectFileImgShop}
        //             accept="image/*"
        //         />
        //     )
        // }


        return (
            <div >
                <Growl ref={(el) => this.growl = el} />
                <h1>Profile</h1>
                <div className="p-grid">
                    <div className="p-col-12 p-lg-6">

                        <div className="p-col-12">
                    <Accordion activeIndex={[0,1,2]} multiple={true}>
                        <AccordionTab header="ข้อมูลสมาชิก">
                                {userInfoModel &&
                                    <ProfileInfoView
                                        {...this.state}
                                        mode="wastepro"
                                        onCheckChange={this.onCheckChange}
                                        onChangeInputText={this.onChangeInputText}
                                        onChangePersonPrefix={this.onChangePersonPrefix}
                                        onChangeCompanyType={this.onChangeCompanyType}
                                    />
                                }
                        </AccordionTab>
                        <AccordionTab header="ที่ตั้งร้าน / ที่ตั้งบริษัท">
                                {
                                    <AddressSwapInfoView
                                        {...this.state}
                                        mode="wastepro"
                                        required={true}
                                        useCardAddress={false}
                                        onChangeInputText={this.onChangeInputText}
                                        onChangeAdrCurrProv={this.onChangeAdrCurrProv}
                                        onChangeAdrCurrentDistrict={this.onChangeAdrCurrentDistrict}
                                        onChangeAdrCurrentSubDistrict={this.onChangeAdrCurrentSubDistrict}
                                    />
                                }
                        </AccordionTab>
                        <AccordionTab header="ที่อยู่ตามบัตรประชาชน / ที่อยู่นิติบุคคล">
                                <div className="p-col-12 " style={{ textAlign: 'left' }}>
                                    <Checkbox
                                        checked={this.state.checkCardAddress}
                                        onChange={this.onDuplicate} />
                                    <label htmlFor="pro_cb" className="p-checkbox-label">เหมือนที่อยู่ที่ตั้งร้าน / ที่ตั้งบริษัท</label>
                                </div>
                                {
                                    <AddressSwapInfoView
                                        {...this.state}
                                        disabled={this.state.checkCardAddress}
                                        useCardAddress={!this.state.checkCardAddress}
                                        onChangeInputText={this.onChangeInputText}
                                        onChangeAdrCardProv={this.onChangeAdrCardProv}
                                        onChangeAdrCardDistrict={this.onChangeAdrCardDistrict}
                                        onChangeAdrCardSubDistrict={this.onChangeAdrCardSubDistrict}
                                    />
                                }
                        </AccordionTab>
                    </Accordion>
                        </div>
                    </div>

                    <div className="p-col-12 p-lg-6">

                        <div className="p-col-12">
                        <Accordion activeIndex={[0,1,2]} multiple={true}>
                            <AccordionTab header="แนบเอกสารประกอบ">
                                {userInfoModel &&
                                    <ImageInfoView
                                        header="รูปถ่ายบัตรประชาชน / หนังสือรับรอง"
                                        src={`${API_ROOT}/wastepro/getfile/${this.state.img_iden_name}`}
                                        name="fileIden[]"
                                        fileName={this.state.img_iden_name}
                                        onSelect={this.onSelectFileImgIden}
                                        blob={this.state.fileImgIden}
                                        accept="image/*"
                                        onRefresh={this.onRefreshIden}
                                        onDelete={this.onDeleteIden}
                                    />
                                }
                                {(userInfoModel  && this.state.person_type == 2 )&&
                                    <ImageInfoView
                                        header="กรณีเป็นนิติบุคคลรูปถ่าย ภพ20"
                                        src={`${API_ROOT}/wastepro/getfile/${this.state.img_regis_org_name}`}
                                        name="fileRegis[]"
                                        fileName={this.state.img_regis_org_name}
                                        onSelect={this.onSelectFileImgRegis}
                                        blob={this.state.fileImgRegis}
                                        accept="image/*"
                                        onRefresh={this.onRefreshRegis}
                                        onDelete={this.onDeleteRegis}
                                    />
                                }
                                {userInfoModel &&
                                    <ImageInfoView
                                        header="รูปถ่ายสมาชิก"
                                        src={`${API_ROOT}/wastepro/getfile/${this.state.img_person_name}`}
                                        name="filePerson[]"
                                        fileName={this.state.img_person_name}
                                        onSelect={this.onSelectFileImgPerson}
                                        blob={this.state.fileImgPerson}
                                        accept="image/*"
                                        onRefresh={this.onRefreshPerson}
                                        onDelete={this.onDeletePerson}
                                    />
                                }
                                {userInfoModel &&
                                    <ImageInfoView
                                        header="รูปถ่ายหน้าร้าน"
                                        src={`${API_ROOT}/wastepro/getfile/${this.state.vehicle_img_name}`}
                                        name="fileShop[]"
                                        fileName={this.state.vehicle_img_name}
                                        onSelect={this.onSelectFileImgShop}
                                        blob={this.state.fileImgShop}
                                        accept="image/*"
                                        onRefresh={this.onRefreshShop}
                                        onDelete={this.onDeleteShop}
                                    />
                                }
                            </AccordionTab>
                            <AccordionTab header="Location google address">
                                <div className="p-col-12">
                                    <label >

                                        Address location (latitude: {this.state.latitude} longitude:{this.state.longitude})
                                </label>
                                </div>
                                <div className="p-col-12">
                                    <MapWithASearchBox
                                        zoom={this.state.mapZoom}
                                        center={this.state.mapCenter}
                                        marker={this.state.marker}
                                        onCenterChanged={this.onCenterChanged}
                                        onMarkerMoveEnd={this.onMarkerMoved} />
                                </div>
                            </AccordionTab>
                            {/* <AccordionTab header="รายละเอียดบัญชีธนาคาร">
                                {
                                    <BankInfoView
                                        {...this.state}
                                        mode={this.state.mode}
                                        onChangeInputText={this.onChangeInputText}
                                        onChanageBank={this.onChanageBank}
                                    />
                                }
                            </AccordionTab> */}
                            </Accordion>
                        </div>
{/* 
                        <div className="card card-w-title">
                            <h1 className="p-col-12">
                                <label>เปลี่ยนรหัสผ่าน</label>
                                <Checkbox
                                    style={{ float: 'right' }}
                                    checked={this.state.isShowChangePassword}
                                    onChange={this.onChangeSwitchPassword} />
                            </h1>
                            {this.state.isShowChangePassword == true &&
                                <ChangePassword
                                    {...this.state}
                                    onChangeOldPassword={this.onChangeOldPassword}
                                    onChangeNewPassword={this.onChangeNewPassword}
                                    onChangeConfirmPassword={this.onChangeConfirmPassword}
                                    onChangePasswordVisible={this.onChangePasswordVisible}
                                />}

                        </div> */}

                    </div>

                    {(localStorage.getItem('second-token'))?'':
                        <div className="p-col-12">
                            {this.props.updateWasteproProfileLoad.isLoading &&
                                <ProgressSpinner style={{ width: '50px', height: '50px' }} strokeWidth="8" fill="#EEEEEE" animationDuration=".5s" />
                            }
                            {!this.props.updateWasteproProfileLoad.isLoading &&
                                <Button
                                    className="p-col-12"
                                    label="บันทึก"
                                    icon="pi pi-check"
                                    iconPos="left"
                                    onClick={this.onSubmit} />
                            }
                        </div>
                    }
                </div>


            </div>

        )
    }
}

const mapStateToProps = (state) => ({
    provinceModel: state.location.province,
    currentDistrictModel: state.location.district,
    currentSubDistrictModel: state.location.subDistrict,
    cardProvinceModel: state.location.cardProvince,
    cardDistrictModel: state.location.cardDistrict,
    cardSubDistrictModel: state.location.cardSubDistrict,
    userInfoModel: state.userInfo.data,
    banksModel: state.master.banks,
    changePasswordModel: state.changePassword.data,

    locationLoad: state.location,
    userInfoLoad: state.userInfo,
    masterLoad: state.master,
    changePasswordLoad: state.changePassword,
    updateWasteproProfileLoad: state.updateWasteproProfile
})

export default connect(mapStateToProps)(ExampleWasteproProfileCtrl)
