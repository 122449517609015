

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Panel } from 'primereact/components/panel/Panel';

import { addCommaAndDotFromNumberOrStr } from '../../utils/utilFunctions'
import { Message } from 'primereact/message';

class WithDrawHeaderView extends Component {

    static defaultProps = {
        input_amount:null,
        input_amount_required:{
            valid:true,
            className:'',
            message:''
        },
        bank_withdraw_code:null,
        bank_withdraw_code_required:{
            valid:true,
            className:'',
            message:''
        },
        withdrawAmount: 0,
        unApproveAmount: 0,
        title: "ยอดทั้งหมดที่ถอนได้",
        onChangeInputText: null,
        onClickNotiMoney: null,
    }

    static propTypes = {
        input_amount:PropTypes.number,
        input_amount_required:PropTypes.func,
        bank_withdraw_code:PropTypes.number,
        bank_withdraw_code_required:PropTypes.func,
        withdrawAmount: PropTypes.number,
        unApproveAmount: PropTypes.number,
        title: PropTypes.string,

        onChangeInputText: PropTypes.func,
        onClickNotiMoney: PropTypes.func,
    }

    //> Event
    handlePositiveNumber = (e) => {
        const {maxLength,value} = e.target
        let numVal = Number(value).toFixed(0);
        console.log("maxLength ",maxLength," ,value.length ",numVal.length)
        if(maxLength && maxLength>0){
            if(maxLength<=numVal.length){
                e.preventDefault()
            }
        }

        const characterCode = e.key
        if (characterCode === 'Backspace') return

        const characterNumber = characterCode=='.'?0:Number(characterCode).toFixed(0)
        console.info(characterNumber)
        if (characterNumber >= 0 && characterNumber <= 9) {
            if (e.currentTarget.value && e.currentTarget.value.length) {
                return
            } else if (characterNumber === 0) {
                // e.preventDefault()
            }
        } else {
            e.preventDefault()
        }
    }
    onChangeInputText = (e) => {
        this.props.onChangeInputText(e);
    }
    onClickNotiMoney = (e) => {
        this.props.onClickNotiMoney(e)
    }

    //> Mode render
    renderWastegen = () => {
        return (
            <div></div>
        )
    }
    renderWastetran = () => {
        return (
            <div></div>
        )
    }
    renderWastepro = () => {
        return (
            <div></div>
        )
    }

    render() {
        const {
            mode,
            classNameRequired
        } = this.props

        console.log("WithDrawHeaderView :render", classNameRequired)

        let renderMode;

        switch (mode) {
            case 'wastegen':
                renderMode = this.renderWastegen()
                break;
            case 'wastetran':
                renderMode = this.renderWastetran()
                break;
            case 'wastepro':
                renderMode = this.renderWastepro()
                break;

            default:
                renderMode = (<div></div>)
                break;
        }

        return (
            <div className="p-grid dashboard p-fluid" >
                <div className="p-col-12 p-md-5 p-lg-4">
                    <div className="p-grid overview-box overview-box-1">
                        <div className="overview-box-title product-statistics">
                            <table>
                                <tbody>
                                    <tr>
                                        <td className="col-overview col-withdraw">
                                            <div className="fa fa-fw fa-credit-card" ></div>
                                            <span>{this.props.title}</span>
                                        </td>
                                    </tr>
                                    <tr></tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="overview-box-count" style={{ textAlign: 'right' }}>
                            <span><label style={{ fontSize: 24 }}>{addCommaAndDotFromNumberOrStr(this.props.withdrawAmount)} บาท</label></span>
                        </div>

                        <div className="overview-box-title" style={{ textAlign: 'right' }}>
                            <span >ยอดเงินเบิกที่ยังไม่อนุมัติ <label style={{ fontSize: 16 }}>{addCommaAndDotFromNumberOrStr(this.props.unApproveAmount)}</label> บาท</span>
                        </div>
                    </div>
                </div>

                <div className="p-col-12 p-md-3 p-lg-3">
                    <div className="p-col-12">
                        <label>ยอดเงินที่ต้องการถอน </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            required={true}
                            className={this.props.input_amount_required.className}
                            name="input_amount"
                            value={this.props.input_amount}
                            type="number"
                            onKeyPress={this.handlePositiveNumber}
                            maxLength={11}
                            onChange={this.onChangeInputText}
                            placeholder="ระบุจำนวนเงิน"
                        />
                        {!this.props.input_amount_required.valid&&
                            <Message severity="error" text={this.props.input_amount_required.message}></Message>
                        }
                    </div>
                    <div className="p-col-12">
                        <label>รหัสสำหรับแจ้งถอนเงิน </label>
                    </div>
                    <div className="p-col-12">
                        <InputText
                            type="number"
                            onKeyPress={this.handlePositiveNumber}
                            required={true}
                            className={this.props.bank_withdraw_code_required.className}
                            name="bank_withdraw_code"
                            value={this.props.bank_withdraw_code}
                            onChange={this.onChangeInputText}
                            placeholder="Pin Number"
                        />
                        {!this.props.bank_withdraw_code_required.valid&&
                            <Message severity="error" text={this.props.bank_withdraw_code_required.message}></Message>
                        }
                    </div>
                    
                    {(localStorage.getItem('second-token'))?'':
                        <div className="p-col-12">
                            <Button
                                label="แจ้งถอนเงิน"
                                onClick={this.onClickNotiMoney} />
                        </div>
                    }
                </div>
                {renderMode}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({

})

export default connect(mapStateToProps)(WithDrawHeaderView)
