

import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';


import { PDFViewer, PDFDownloadLink } from '@react-pdf/renderer';

import { PdfExample } from '../pdf/PdfExample';
import { PdfTableExample } from '../pdf/PdfTableExample';

class ExamplePDF extends Component {

    constructor(props) {
        console.log("ExamplePDF :constructor")
        super(props);
        this.state = {
        }
    }

    componentDidMount() {
        console.log("ExamplePDF :componentDidMount")
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("ExamplePDF :componentWillReceiveProps")

    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("ExamplePDF :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("ExamplePDF :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("ExamplePDF :componentDidUpdate")

    }

    // Initial function
    initial = (object) => {
        if (object) {
            for (let key in object) {
                let val = object[key]
                this.setState({ [key]: val })
            }
        }
    }

    //> Submit
    onSubmit = () => {
        this.showSuccess();
    }

    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    render() {
        console.log("ExamplePDF :render")
        const pdfView = (
            <PDFViewer width="100vh" height="100vh">
                <PdfExample />
            </PDFViewer>
        )
        const pdfDownload = (
            <PDFDownloadLink document={<PdfExample />} fileName="fee_acceptance.pdf" >
                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <button>Download PDF</button>)}
            </PDFDownloadLink>
        )
        const pdfBlob = (
            <PDFDownloadLink document={<PdfExample />}  >
                {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <button><a target='_blank' href={url}>Download PDF</a></button>)}
            </PDFDownloadLink>
        )

        let columns = [
            {columnName:'col1',label:'#',alignCenter:true},
            {columnName:'col2',label:'รายการ'},
            {columnName:'col3',label:'ปริมาณ',alignRight:true},
            {columnName:'col4',label:'ราคาขาย',alignRight:true},
        ]
        let data = [
            {
                col1: "1",
                col2: "ขวดแก้ว",
                col3: "10",
                col4: "20.00",
            },
            {
                col1: "1",
                col2: "ขวดแก้ว",
                col3: "10",
                col4: "20.00",
            },
            {
                col1: "1",
                col2: "ขวดแก้ว",
                col3: "10",
                col4: "20.00",
            },
            {
                col1: "1",
                col2: "ขวดแก้ว",
                col3: "10",
                col4: "20.00",
            }
        ]
        let summary = [
            {header:'รวมปริมาณ',value:'10 Kg.'},
            {header:'รวมราคาส่ง',value:'100 บาท'},
        ]
        const pdfDataTable = (
            <PDFViewer width="100%" height="100%">
                <PdfTableExample
                    title="ใบราคา"
                    data={data}
                    orderCode="99-xxx-xxx-xxxx"
                    date="20-มกราคม-2020"
                    model={columns}
                    summary={summary}
                />
            </PDFViewer>
        )

        return (
            <div>
                <Growl ref={(el) => this.growl = el} />
                <h1>Profile</h1>
                <div className="p-grid">
                    {/* DETAIL */}
                    <div style={{ width: '100%', height: '100vh' }}>
                        {pdfDataTable}
                    </div>
                    <div className="p-col-12">
                        <Button
                            className="p-col-12"
                            label="บันทึก"
                            icon="pi pi-check"
                            iconPos="left"
                            onClick={this.onSubmit} />
                    </div>
                </div>
            </div>

        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(ExamplePDF)
