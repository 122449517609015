import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux'
import { getBalance, getWastetranDashProduct,getWastetranDashOrderStat } from '../../service/wastetran.service'
import { userAnnounce } from '../../service/user.service'
import { getAdminUid, getBadgeAdmin } from '../../service/ChatService'
import { WastetranOverviews } from '../wastetran-dashboard/WastetranOverviews';
import { WasteNewView } from '../waste-news/WasteNewView';
import { DatatableDashBoardView } from '../data-table/DatatableDashBoardView';
import { StatisticView } from '../dashboard/StatisticView';
import { getStatus } from '../../constants/order-status';
import { Panel } from 'primereact/panel';
import { ListBox } from 'primereact/listbox';
import { ChatRoom } from '../message/ChatRoom';
import { Growl } from 'primereact/growl';
import {InputText} from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { WASTE_TYPE } from '../../constants/mappingValue'
import { ProgressSpinner } from 'primereact/progressspinner';
import * as serviceAPI from './service'
class WastetranChatAdmin extends Component {
    constructor(props) {
        console.log("WastegenDashboard :constructor")
        super(props);
        this.state = {
            dummySuccessDataTable: [
                { order: 'Order ทั้งหมด', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'จัดส่งสมบูรณ์', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'จัดส่งไม่สมบูรณ์', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: 'ลูกค้ายกเลิก', ranking: '0', amount: '0', quantity: '0 kg.' },
                { order: '%สำเร็จ', ranking: '0%', amount: '0', quantity: '0 kg.' },
            ],
            showProgress: true
        }
    }

    componentDidMount() {
        // console.log("WastegenDashboard :componentDidMount")
        getAdminUid().then(res => {
            console.log(res)
            this.setState({ adminInfo: res})
        })
    }
    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        // console.log("WastegenDashboard :componentWillReceiveProps")
    }
    shouldComponentUpdate(nextProps, nextState) {
        // console.log("WastegenDashboard :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        // console.log("WastegenDashboard :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        // console.log("WastegenDashboard :componentDidUpdate")

    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div></div>);
    }

    chat(){
        if(this.props.userInfo && this.state.adminInfo){
            const currentUserInfo = this.props.userInfo.user_wasteTypes.find(
                item => item.wasteType === 2 ,
              )
              let roomId = `${currentUserInfo.uid}-${currentUserInfo.wasteType}-admin`
              console.log(roomId)
              console.log(this.state.adminInfo.uid)
                return (
                    <ChatRoom
                        roomId={roomId}
                        receiverId={this.state.adminInfo.uid || ''}
                        receiverImage={'assets/layout/images/avatar.png'}
                        receiverWasteType={'administrator' || ''}
                        isAdminChat={true}
                        isAmAdmin={false}
                        header={'แชทกับแอดมิน'}
                        onUpdateAdminBadge={(e)=>this.updateAdminBadge(e)}
                        adminBadge={this.props.chatSingleBadge.admin}
                    />
                );
        }else
            return this.showProgressSpinner()
    }
    updateAdminBadge(e){
        this.props.ChatServiceProps.updateReadAll(e.roomId,e.type,e.badges)
    }

    render() {
        const chatWastetran = this.chat()
        return (
            <div>
                <h1>Chat</h1>
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid dashboard">

                    {/* <div className="p-col-4 p-md-3 p-lg-4">
                        {showChatTable}
                    </div> */}
                    <div className="p-col-12 " >
                        {chatWastetran}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    wastetranBalance: state.wastetranBalance,
    userAnnounce: state.userAnnounce,
    getWastetranDashProduct: state.getWastetranDashProduct,
    getWastetranDashOrderStat: state.getWastetranDashOrderStat,
    chatSingleBadge: state.chatSingleBadge,
})

export default connect(mapStateToProps)(WastetranChatAdmin)