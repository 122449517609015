import React, { Component } from 'react';
export class WastegenReportsAmount extends Component {
    render(){
        return (
            <div>
                <h1>ใบราคา</h1>
                <div className="p-grid">
                    <div className="p-lg-12  p-md-12  p-sm-12">
                        <div className="card card-w-title">
                            <span >อยู่ระหว่างการพัฒนา</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}