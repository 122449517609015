import React, { Component } from 'react';
import { Dropdown } from 'primereact/dropdown';
import classNames from 'classnames';
import { max } from 'moment';

class CEDropdown extends Component {
    static defaultProps = {

        required:false,
        value: null,
         id:'',  
         className: null, 
         style: {},
         options:null,
         placeholder:'',
         optionLabel:{},
         dataKey:'',
        onChange: () => { },
        isvalid: false
        
    }

    constructor(props) {
        super(props)
        this.state = { isvalid: false,value: null}
        this.validation = this.validation.bind(this);
        this.changed = this.changed.bind(this);
        //this.showCtrl = this.showCtrl.bind(this);
        //regexp = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
        
    }
    componentDidMount() {
        this.setState({value: this.props.value})
        const _isValid = this.validation(this.props.value);
        this.props.isvalid(_isValid);
    }
    componentWillReceiveProps(e) {     
      
        const _isValid = this.validation(e.value);
        this.props.isvalid(_isValid);
        if(e.value!=null){
            
            this.setState({value:e.value})
        }else{
            this.setState({value:null})
        }
      
      }
    validation(value){
        const {required} = this.props;
        var _valid = true;
        //console.log("validation");
         //console.log(value);
        if( required && (!value || value==null || value=='' || value=={})){
           
             _valid = false;
        }else{
            if(value._$visited ==true) _valid = false;
        }
        return _valid;
    } 
    changed = (e) =>{
        this.setState({value:e.value})
        const _isValid = this.validation(e.value);
        this.props.isvalid(_isValid);
        this.props.onChange(e);
        
    }
    
    showCtrl(){
        
      const {required,value, id,  className, style,onChange,options,placeholder,optionLabel,dataKey} = this.props;
      const _isValid = this.validation(this.state.value);
      
      const _className = classNames(className, {
        'p-error': !_isValid
        });

     
      

      return  <Dropdown
                className={_className}
                style={style}
                value={value}
                options={options}
                onChange={this.changed}
                placeholder={placeholder}
                optionLabel={optionLabel}
                dataKey={dataKey}
                />
       

    }
    render(){
        const bodyContent = this.showCtrl();
      
        return (
            bodyContent
        )
    }
}

export default CEDropdown