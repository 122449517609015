import React, { Component } from 'react';
import { Button } from 'primereact/button';

export class FileView extends Component {
    static defaultProps = {
        src: ''
    }

    constructor(props) {
        super(props)
        this.getExt = this.getExt.bind(this);
        this.showCtrl = this.showCtrl.bind(this);
    }
    
    getExt(filename){
        return filename.split('.').pop();
    }
    
    showCtrl(){
        
        const {src} = this.props;
        console.log("show ctrl: "+src)
        const _personImg = (
            <img  style={{borderRadius: "50%"}} width="300px" height="300px" src={src}/> 
        )
        const _img = (
            <img width="95%" src={src}/> 
        )
       
       const _file = (
           <Button label="ดาวน์โหลด" style={{width: '90px'}} className="p-button-success success-btn btn-block" onClick={() => window.location = src } />
                                  
       )
       const ext = this.getExt(src);
       console.log(ext);
       if(ext==="pdf"){
            return _file;
       }
       if(ext==="png" 
       || ext==="jpg" 
       || ext==="jpeg"){
           if(src.indexOf("person") != -1){
               return _personImg;
           }else{
               return _img;
           }
           
       }

       return (<br />);

    }
    render(){
        const bodyContent = this.showCtrl();
       
        return (
            bodyContent
        )
    }
}