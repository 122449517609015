import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Dropdown } from 'primereact/components/dropdown/Dropdown';


import * as DashBoardService from './service'
import { Growl } from 'primereact/growl';
import { DatatableRankView } from '../data-table/DatatableRankView';
import { DatatableOutBalanceView } from '../data-table/DatatableOutBalanceView';
import { Validators, SecureUtils } from '../../utils';
import { MessageAdminView } from '../message/MessageAdminView';
import { Button } from 'primereact/button';
import * as LocationService from '../../service/LocationService'
import * as AdminService from '../../service/admin.service'
import { dateFormat } from '../../utils/utilFunctions';
import { map } from 'lodash'
import moment from 'moment'

class DashboardAdminContainer extends Component {
    static propTypes = {

    }

    static defaultProps = {
    }

    constructor() {
        super();
        this.state = {
            isSave: false,
            classNameRequired: {},
            //MesageView
            valueDd: { name: 'ทุกกลุ่ม', code: 0 },
            listMessage: [
                { name: 'ทุกกลุ่ม', code: 0 },
                { name: 'ผู้รักษ์โลก', code: 1 },
                { name: 'ทีมขนส่งซีซาเล้ง', code: 2 },
                { name: 'ศูนย์จัดการฯซีซาเล้ง', code: 3 },
            ],
            valueMessage: null,
            //DataTableRankView
            yearSearch: dateFormat(new Date(), 'YYYY'),
            year: { code: 0, name: parseInt(dateFormat(new Date(), 'YYYY')) },
            listYear: [
            ],
            month: { code: '00', name: 'ทุกเดือน' },
            listMonth: [
                { code: '00', name: 'ทุกเดือน' },
                { code: '01', name: 'มกราคม' },
                { code: '02', name: 'กุมภาพันธ์' },
                { code: '03', name: 'มีนาคม' },
                { code: '04', name: 'เมษายน' },
                { code: '05', name: 'พฤษภาคม' },
                { code: '06', name: 'มิถุนายน' },
                { code: '07', name: 'กรกฎาคม' },
                { code: '08', name: 'สิงหาคม' },
                { code: '09', name: 'กันยายน' },
                { code: '10', name: 'ตุลาคม' },
                { code: '11', name: 'พฤศจิกายน' },
                { code: '12', name: 'ธันวาคม' }
            ],
            listRanks: [],
            //DatatableOutBalanceView
            listOutBalance: [

            ],
            memberNameChip: '',
            memberNameChips: [],
            initChips: [{ uid: null, search_name: 'ส่งถึงทุกคน' }],
            provinceSelected: {},
            districtSelected: {},
            //AutoComplete
            brandSuggestions: null
        };

        //>Event MessageView
        this.onChangeMessage = this.onChangeMessage.bind(this);
        this.onChangeDd = this.onChangeDd.bind(this);
        //>Event Rank
        this.onChangeDropdown = this.onChangeDropdown.bind(this);
        this.onChangeMemberName = this.onChangeMemberName.bind(this);
        this.customChipRemove = this.customChipRemove.bind(this);
        this.onProvinceChanged = this.onProvinceChanged.bind(this);
        this.onDistrictChanged = this.onDistrictChanged.bind(this);
    }

    componentDidMount() {
        const { dashBoardServiceProps, adminServiceProps } = this.props
        dashBoardServiceProps.getDashboardUsers();
        // adminServiceProps.getAdminDashSummaryArea();
        adminServiceProps.getAdminDashSummaryWithdraw();

        let criteria = {
            sale_year: dateFormat(new Date(), 'YYYY'),
            sale_month: this.state.month.code
        }
        // console.log(criteria)
        this.props.adminServiceProps.getAdminDashSummaryAreaSearch(criteria);

        //prepare year
        for (let i = 0; i < 10; i++) {
            let tmpYear = { code: i, name: this.state.yearSearch - i }
            this.state.listYear.push(tmpYear);
        }
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        //console.log("DashboardAdminContainer :componentWillReceiveProps")
        if (!nextProps.getAdminDashSummaryAreaSearch.isLoading && !nextProps.getAdminDashSummaryAreaSearch.isFailed) {
            if (nextProps.getAdminDashSummaryAreaSearch.data) {
                let rs = nextProps.getAdminDashSummaryAreaSearch.data.map((value, index) => {
                    let monXX = this.state.listMonth.filter((valueMonth, index) => { return value.sale_month == valueMonth.code })[0]
                    //console.log("xx ", monXX)
                    return {
                        col1: index + 1,
                        col2: monXX.name,
                        col3: value.province_name,
                        col4: value.district_name,
                        col5: value.subdistrict_name,
                        col6: value.total_price.toFixed(2),
                        col7: value.total_quantity_kk.toFixed(2),
                    };
                })

                this.setState({ listRanks: rs })
            }

        }
        if (!nextProps.getAdminDashSummaryWithdraw.isLoading && !nextProps.getAdminDashSummaryWithdraw.isFailed) {
            if (nextProps.getAdminDashSummaryWithdraw.data) {
                let rs = nextProps.getAdminDashSummaryWithdraw.data.map((value, index) => {
                    let userType = ""
                    switch (value.userWasteType) {
                        case 1:
                            userType = "ผู้รักษ์โลก"
                            break;
                        case 2:
                            userType = "ทีมขนส่งซีซาเล้ง"
                            break;
                        case 3:
                            userType = "ศูนย์จัดการฯซีซาเล้ง"
                            break;
                    }
                    return {
                        col1: index + 1,
                        col2: userType,
                        col3: value.positive_value.toFixed(2),
                        col4: value.negative_value.toFixed(2),
                    };
                })

                this.setState({ listOutBalance: rs })
            }
        }

    }
    shouldComponentUpdate(nextProps, nextState) {
        //console.log("DashboardAdminContainer :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        //console.log("DashboardAdminContainer :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        //console.log("DashboardAdminContainer :componentDidUpdate")
        if (this.state.isSave) {
            if (!this.props.adminAnnounceAdd.isLoading) {
                if (this.props.adminAnnounceAdd.isFailed) {
                    this.showError();
                } else {
                    this.showSuccess("บันทึกข่าวสารสำเร็จ")
                    this.setState({
                        valueMessage: "",
                        memberNameChip: '',
                        memberNameChips: [],
                        initChips: [{ uid: null, search_name: 'ส่งถึงทุกคน' }],
                        provinceSelected: {},
                        districtSelected: {}
                    })
                }
                this.setState({ isSave: false })
            }
        }

    }

    //>Event
    onChangeMessage = (e) => {
        const { value } = e.target
        this.setState({ valueMessage: value })
        if (Validators.isEmpty(value)) {
            this._classNameRequiredIsError("value_message_required", true);
        } else {
            this._classNameRequiredIsError("value_message_required", false);
        }
    }
    onChangeDd = (e) => {
        this.setState({ valueDd: e.value })
        this.setState({
            memberNameChip: '',
            memberNameChips: [],
            initChips: [{ uid: null, search_name: 'ส่งถึงทุกคน' }],
            provinceSelected: {},
            districtSelected: {}
        })
    }
    onChangeDropdown = e => {
        const { name, value } = e.target
        this.setState({ [name]: value })
    }
    onClickSearch = e => {
        let criteria = {
            sale_year: this.state.year.name,
            sale_month: this.state.month.code
        }
        this.setState({ yearSearch: this.state.year.name })
        //console.log(criteria)
        this.props.adminServiceProps.getAdminDashSummaryAreaSearch(criteria);
    }

    //> Validate Required
    _classNameRequiredIsError = (classNamed, status) => {
        if (!classNamed) {
            if (status) {
                this.showWarn();
            }
        } else {
            if (status) {
                this.setState(pv => ({
                    classNameRequired: {
                        ...pv.classNameRequired, [classNamed]: "p-error"
                    }
                }))
            } else {
                this.setState(pv => ({
                    classNameRequired: {
                        ...pv.classNameRequired, [classNamed]: ""
                    }
                }))
            }
        }
    }
    //> Submit
    onSubmit = (e) => {
        //console.log(this.state)
        if (Validators.isEmpty(this.state.valueMessage)) {
            this.showWarn("กรุณาระบุข้อความ")
            this._classNameRequiredIsError("value_message_required", true);
        } else {
            let criteria = {
                gid: 0,
                waste_receiver_group: this.state.valueDd.code || 0,
                is_all_waste: this.state.memberNameChips.length > 0 ? false : true,
                waste_user_id: map(this.state.memberNameChips, 'uid') || [],
                province_code: this.state.provinceSelected.provinceCode || 0,
                district_code: this.state.districtSelected.districtCode || 0,
                announcement_time: moment().format(),
                announcement_message: this.state.valueMessage
            };

            this.props.adminServiceProps.adminAnnounceAdd(criteria).then(res => {
                this.setState({ isSave: true });
            });

        }
        this.forceUpdate()
    }

    // ======= SHOW MESSAGE BOX ======
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showError = (message) => {
        this.growl.show({ severity: 'error', summary: 'คำเตือน', detail: message ? message : 'เกิดข้อผิดพลาด' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'คำเตือน', detail: message ? message : 'กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน' });
    }

    customChipRemove(e) {
        console.log(e);

        var array = this.state.memberNameChips; // make a separate copy of the array
        var index = array.indexOf(e.value[0])
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ memberNameChips: array });
        }
        //console.log(array);
    }

    onChangeMemberName = (searchName) => {
        if (typeof (searchName) != "object")
            if (searchName.trim() != "") {
                this.setState({ memberNameChip: searchName });
                if (searchName.length >= 1) {
                    DashBoardService.searchWaste(
                        this.state.valueDd.code,
                        this.state.provinceSelected.provinceCode || 0,
                        this.state.districtSelected.districtCode || 0,
                        searchName).then((res) => {
                        this.setState({ brandSuggestions: res });
                    }).catch((error) => {

                    })
                }
            } else this.setState({ memberNameChip: searchName });
    }

    onAddMember = (e) => {
        var name = e.value;
        var isNotvalid = false;
        if (name)
            if (this.state.memberNameChips.length != 0) {
                this.state.memberNameChips.forEach(obj => {
                    console.log(obj.uid)
                    console.log(name.uid)
                    if (obj.uid == name.uid) {
                        isNotvalid = true;
                    }
                });
                if (isNotvalid) {
                    this.showWarn("ในรายการมีชื่อนี้อยู่แล้ว");
                } else {
                    this.state.memberNameChips.push(name);
                    this.setState({ memberNameChip: '' })
                }
            } else {
                this.state.memberNameChips.push(name);
                this.setState({ memberNameChip: '' })
            }
    }

    onProvinceChanged(e) {
        const { locationServiceProps } = this.props
        this.setState({ provinceSelected: e.value })
        this.setState({ districtSelected: {} })
        this.setState({ subDistrictSelected: {} })
        this.setState({ districtCode: 0 })
        this.setState({ subDistrictCode: 0 })
        locationServiceProps.getDistrict(e.value.provinceCode);
        locationServiceProps.getSubDistrict(0);
        this.setState({ provinceCode: e.value.provinceCode });
    }
    onDistrictChanged(e) {
        const { locationServiceProps } = this.props
        this.setState({ districtSelected: e.value });
        this.setState({ subDistrictSelected: {} })
        this.setState({ subDistrictCode: 0 })
        locationServiceProps.getSubDistrict(e.value.districtCode);
        this.setState({ districtCode: e.value.districtCode });
    }

    render() {
        //console.log("dashboard render: ", this.state)
        const {
            dhbUsers,
            provinces,
            districts,
            subDistricts
        } = this.props

        let contentOverview = (
            <div className="p-grid dashboard">

                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="p-grid overview-box overview-box-1">
                        <div className="overview-box-title">
                            <i className="fa fa-inbox"></i>
                            <span>จำนวนงานรอนัดหมาย</span>
                        </div>
                        <div className="overview-box-count">{dhbUsers.order_wait_appointment_count}</div>
                        <div className="overview-box-stats">Orders</div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="p-grid overview-box overview-box-2">
                        <div className="overview-box-title">
                            <i className="fa fa-map-pin"></i>
                            <span>จำนวนงานที่อยู่ระหว่างไปเก็บ-ส่ง</span>
                        </div>
                        <div className="overview-box-count">{dhbUsers.order_wait_pickup_count}</div>
                        <div className="overview-box-stats">Orders</div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="p-grid overview-box overview-box-3">
                        <div className="overview-box-title">
                            <i className="fa fa-folder"></i>
                            <span>จำนวนงานที่ปิด Order แล้ว</span>
                        </div>
                        <div className="overview-box-count">{dhbUsers.order_closed_count}</div>
                        <div className="overview-box-stats">Orders</div>
                    </div>
                </div>
                <div className="p-col-12 p-md-6 p-lg-3">
                    <div className="p-grid overview-box overview-box-4">
                        <div className="overview-box-title">
                            <i className="fa fa-user"></i>
                            <span>จำนวนสมาชิก</span>
                        </div>
                        <div className="p-col-12 overview-box-stats">ผู้รักษ์โลก {dhbUsers.gen_count} คน</div>
                        <div className="p-col-12 overview-box-stats">ทีมขนส่งซีซาเล้ง {dhbUsers.tran_count}  คน</div>
                        <div className="p-col-12 overview-box-stats">ศูนย์จัดการฯซีซาเล้ง {dhbUsers.pro_count}  คน </div>

                    </div>
                </div>
            </div>
        );

        let search = (
            <div className="p-grid p-fluid">
                <div className="p-col-12 p-lg-1">
                    <labe htmlFor="rank_year_id" style={{ float: "right" }}>ปี </labe>
                </div>
                <div className="p-col-12 p-lg-3">
                    <Dropdown id="rank_year_id"
                        style={{ width: '100%' }}
                        optionLabel="name"
                        name="year"
                        value={this.state.year}
                        options={this.state.listYear}
                        onChange={this.onChangeDropdown}
                        placeholder="เลือกปี"
                    />
                </div>

                <div className="p-col-12 p-lg-1">
                    <label htmlFor="rank_month_id" style={{ float: "right" }}>เดือน </label>
                </div>
                <div className="p-col-12 p-lg-4">
                    <Dropdown id="rank_month_id"
                        style={{ width: '100%' }}
                        optionLabel="name"
                        name="month"
                        value={this.state.month}
                        options={this.state.listMonth}
                        onChange={this.onChangeDropdown}
                        placeholder="เลือกเดือน"
                    />
                </div>
                <div className="p-col-12 p-lg-2">
                    <Button
                        label="ค้นหา"
                        icon="fa fa-search"
                        onClick={this.onClickSearch}
                    />
                </div>
            </div>
        )
        //console.log(this.state.year)
        let contentRank = (
            <div className="p-grid">
                <div className="p-col-12 p-lg-12">
                    <labe ><i class="fa fa-globe"></i> 5 อันดับมูลค่าขายสูง ปี {this.state.yearSearch}</labe>
                </div>
            </div>
        )

        let valueMessage = this.state.valueMessage
        let dataChips = this.state.memberNameChips
        if (dataChips.length == 0) {
            dataChips = this.state.initChips
        }
        return (
            <div>
                <h1>Dashboard</h1>
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid dashboard">
                    <div className="p-col-12 p-lg-12">
                        {contentOverview}
                    </div>
                    <div className="p-col-12 p-lg-7">
                        {search}
                        <DatatableRankView
                            contentRank={contentRank}
                            year={this.state.year.name}
                            listRanks={this.state.listRanks}
                        />
                        <DatatableOutBalanceView
                            listOutBalance={this.state.listOutBalance}
                        />
                    </div>
                    <div className="p-col-12 p-lg-5">
                        <MessageAdminView
                            valueDd={this.state.valueDd}
                            listDd={this.state.listMessage}
                            onChangeDd={this.onChangeDd}
                            valueMessage={valueMessage}
                            onChangeMessage={this.onChangeMessage}
                            onSubmit={this.onSubmit}
                            classNameRequired={this.state.classNameRequired}
                            valueMemberName={this.state.memberNameChip}
                            onChangeMemberName={this.onChangeMemberName}
                            onAddMember={this.onAddMember}
                            customChipRemove={this.customChipRemove}
                            valueChips={dataChips}
                            valueProvince={this.state.provinceSelected}
                            provinceList={provinces}
                            onChangedProvinceDb={this.onProvinceChanged}
                            valueDistrict={this.state.districtSelected}
                            districtList={districts}
                            onChangedDistrictDb={this.onDistrictChanged}
                            brandSuggestions={this.state.brandSuggestions}
                        />
                    </div>
                </div>

            </div>
        )
    }
}



const mapStateToProps = (state) => ({

    dhbUsers: state.dhbAdmin.dhbUsers,
    adminAnnounceAdd: state.adminAnnounceAdd,
    getAdminDashSummaryAreaSearch: state.getAdminDashSummaryAreaSearch,
    getAdminDashSummaryWithdraw: state.getAdminDashSummaryWithdraw,
    provinces: state.location.province,
    districts: state.location.district,
    subDistricts: state.location.subDistrict,
})

const mapDispatchToProps = dispatch => ({

    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
    adminServiceProps: bindActionCreators(AdminService, dispatch),
    locationServiceProps: bindActionCreators(LocationService, dispatch),

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(DashboardAdminContainer)