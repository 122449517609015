import React from 'react'
import { Validators } from '../../utils';
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Message } from 'primereact/message';

export const ValidateInputTextView = ({ 
    value, 
    onChange, 
    name,
    placeholder,
    errorMessage,
    required,
    maxLength
}) => {

    errorMessage = errorMessage||`กรุณาระบุ ${placeholder}`
    required = required || true
    maxLength = maxLength?maxLength:45;

    return (
        <div>
                <InputText
                    maxLength={maxLength}
                    required={required}
                    className={Validators.isInValid(value) ? 'p-error' : ''}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                />
            {
                Validators.isInValid(value) &&
                <Message severity="error" text={errorMessage} />
            }
        </div>
    )
}