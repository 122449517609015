exports.isNull = function (value) {
    return ((value == null) || (!value));
}

exports.isNotNull = function (value) {
    return !module.exports.isNull(value);
}

exports.isEmpty = function (value) {
    if (module.exports.isNull(value)) {
        return true;
    }
    return (Object.keys(value).length === 0 && value.constructor === Object) || (value.length <= 0);
}

exports.isNotEmpty = function (value) {
    return !module.exports.isEmpty(value);
}

exports.isEmail = function (mail) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return (true)
    }
    if (module.exports.isEmpty(mail)) {
        return true
    } else {
        return (false)
    }
}
exports.isPhonenumber = function (inputtxt) {
    var phoneno = /^0\d{9}$/;
    if (inputtxt.match(phoneno)) {
        return true;
    } else {
        return false;
    }
}

exports.isValid = function (value, length) {
    length = length || 0
    if (module.exports.isNotEmpty(value)) {
        value = value.toString().replace("_", "")
        if (length == 0) {
            return true;
        } else if (value.length === length) {
            return true
        } else {
            return false;
        }
    }
    return false;
}

exports.isInValid = function (value, length) {
    return !module.exports.isValid(value, length);
}

exports.isValidNoneRequired = function (value, length) {
    length = length || 0
    if (module.exports.isNotEmpty(value)) {
        value = value.toString().replace("_", "")
        if (length == 0) {
            return true;
        } else if (value.length === length) {
            return true
        } else {
            return false;
        }
    }
    return true;
}

exports.isInValidNoneRequired = function (value, length) {
    return !module.exports.isValidNoneRequired(value, length);
}

exports.isValidPhoneNumberNoneRequired = function (value, length) {
    length = length || 0
    if (module.exports.isNotEmpty(value)) {
        value = value.toString().replace("_", "")
        if (length == 0 && exports.isPhonenumber(value)) {
            return true;
        } else if (value.length === length && exports.isPhonenumber(value)) {
            return true
        } else {
            return false;
        }
    }
    return true;
}

exports.isInValidPhoneNumberNoneRequired = function (value, length) {
    return !module.exports.isValidPhoneNumberNoneRequired(value, length);
}
exports.isValidPhoneNumberRequired = function (value, length) {
    length = length || 0
    value = value||""
    value = value.toString().replace("_", "")
    if (module.exports.isNotEmpty(value)) {
        if (length == 0 && exports.isPhonenumber(value)) {
            return true;
        } else if (value.length === length && exports.isPhonenumber(value)) {
            return true
        } else {
            return false;
        }
    }
    return false;
}

exports.isInValidPhoneNumberRequired = function (value, length) {
    return !module.exports.isValidPhoneNumberRequired(value, length);
}

//for validate type number of <Inputtext onKeyPress="?" />
exports.handleNumber = (e) => {
    console.log("handleNumber >> ",e)
    
    const {maxLength,value} = e.target
    console.log("maxLength ",maxLength," ,value.length ",value.length)
    if(maxLength && maxLength>0){
        if(maxLength<=value.length){
            e.preventDefault()
        }
    }

    const characterCode = e.key
    if (characterCode === 'Backspace') return

    const characterNumber = Number(characterCode)
    if (characterNumber >= 0 && characterNumber <= 9) {
        if (e.currentTarget.value && e.currentTarget.value.length) {
            return
        } else if (characterNumber === 0) {
            // e.preventDefault()
        }
    } else {
        e.preventDefault()
    }


}