import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthenService from './service/authenService'

const PrivateRoute = ({ component: Component, ...rest }) => {
    //> Check Token authenticate from localstorage
    const checkAuthen = new AuthenService().getAuthenticated();
    return (
        <Route {...rest} render={props => (
            checkAuthen ? <Component {...props} /> : <Redirect to="/" />
        )} />
    )
}

export default PrivateRoute

