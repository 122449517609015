import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import 'primereact/resources/primereact.min.css';
// import 'fullcalendar/dist/fullcalendar.css';
import 'font-awesome/css/font-awesome.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import ScrollAnimation from 'react-animate-on-scroll';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';

import { connect } from 'react-redux'
import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as WastegenService from '../../service/wastegen.service'
import * as WastetranService from '../../service/wastetran.service'
import style from './style.css';
//import * as MasterServiceAPI from '../../service';
import * as ServerAPI from './service';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import { WEB_ROOT, TYPE_ROOT } from '../../constants/api'
import { closeWebsocket } from '../../utils/chat'
import scrollToComponent from 'react-scroll-to-component';
import {AlertDialogView} from '../dialog/AlertDialogView';

class LandingContainer extends Component {

    static propTypes = {

    }

    static defaultProps = {
    }

    iconList = {
        barOpen: "fa fa-bars",
        barClosed: "fa fa-times"
    }

    constructor() {
        super();
        this.state = {
            alertDialog: true,
            isAuthenticated: false,
            user: null, token: '',
            errorMessenger: '',
            isNavActive: true,
            iconBar: this.iconList.barOpen,
            displayDialog: false,
            registerDialog: false
        };
        this.onClickRegister = this.onClickRegister.bind(this);
        this.onClickNavbar = this.onClickNavbar.bind(this);
        this.loginButton = this.loginButton.bind(this);
        this.redirectToRegis = this.redirectToRegis.bind(this);

    }

    onClickNavbar() {
        //Responsive navbar mobile only
        var x = document.getElementById("topNavbarMenuList");
        if (x.className === "p-grid nav-list-menu") {
            x.className += " nav-top-responsive";
        } else {
            x.className = "p-grid nav-list-menu";
        }
        this.handleNavAction();
    }

    handleNavAction = () => {
        this.setState((pv) => ({
            isNavActive: !pv.isNavActive
        }))

        if (this.state.isNavActive) {
            this.setState((pv) => ({ iconBar: this.iconList.barClosed }));
        } else {
            this.setState((pv) => ({ iconBar: this.iconList.barOpen }));
        }
        //console.log(this.state.isNavActive," , ",this.state.iconBar)
    }

    componentDidMount() {
        const { locationServiceProps, userServiceProps } = this.props
        locationServiceProps.getProvice()
        userServiceProps.getUserInfo();
    }

    redirectToRegis() {
        console.log('redirect to login')
        if (this.state.userTypeNo == 2 && this.state.userTypeRegis == 1) {
            ServerAPI.wastetranRegisWastegen().then(res => {
                if (res.status == 200) {
                    localStorage.setItem('user-type', 'wastegen');
                    window.location = "/#/wastegen/profile";
                }
            })
        }
        if (this.state.userTypeNo == 1 && this.state.userTypeRegis == 2) {
            ServerAPI.wastegenRegisWastetran().then(res => {
                if (res.status == 200) {
                    localStorage.setItem('user-type', 'wastetran');
                    window.location = "/#/wastetran/profile";
                }
            })
        }
        this.setState({ registerDialog: false })
    }
    onClickRegister(wastetype) {
        const { userInfo } = this.props
        if (!localStorage.getItem('access-token') || wastetype == 3) {
            localStorage.removeItem('auth-facebook');
            localStorage.removeItem('auth-google');
            if (wastetype == 1) window.location = "/#/register-wastegen";
            if (wastetype == 2) window.location = "/#/register-wastetran";
            if (wastetype == 3) window.location = "/#/register-wastepro";
        } else {
            var userType = localStorage.getItem('user-type')
            var wastetypeCode = 0;
            switch (userType) {
                case 'wastegen':
                    wastetypeCode = 1
                    break;
                case 'wastetran':
                    wastetypeCode = 2
                    break;
                case 'wastepro':
                    wastetypeCode = 3
                    break;
            }
            if (!userInfo.is_available_register) {
                this.setState({ displayDialog: true })
            } else {


                if (wastetypeCode == 2 && wastetype == 1) {
                    //WastetranServiceProps.wastetranRegisWastegen()
                    //window.location = "/#/"+userType+"/register-gen";
                    this.setState({
                        registerDialog: true,
                        userTypeNo: wastetypeCode,
                        userTypeRegis: wastetype,
                        userTypeName: 'ผู้รักษ์โลก'
                    })
                } else if (wastetypeCode == 1 && wastetype == 2) {
                    //WastegenServiceProps.wastegenRegisWastetran()
                    //window.location = "/#/"+userType+"/register-tran";
                    this.setState({
                        registerDialog: true,
                        userTypeNo: wastetypeCode,
                        userTypeRegis: wastetype,
                        userTypeName: 'ทีมขนส่งซีซาเล้ง'
                    })
                } else {
                    this.setState({ displayDialog: true })
                }
            }
        }

    }

    facebookResponse = (response, wastetype) => {

        if (response) {
            if (response.status != "unkhow") {
                var model = {
                    response: response,
                    wastetype: wastetype,
                    socialtype: 'facebook'
                }
                localStorage.removeItem('landing-regis-response');
                localStorage.setItem('landing-regis-response', JSON.stringify(model));
                window.location = "/#/register-landing?type=" + wastetype;
            } else {
                window.open(WEB_ROOT, "_self");
            }
        }
        /*
        if (response.status == "unknown") {
            this.setState({ errorMessenger: '' });
        } else if (response.accessToken) {
            localStorage.removeItem('auth-facebook');
            localStorage.setItem('auth-facebook', JSON.stringify(response));
            var authModel = {
                Provider: "Facebook",
                Token: response.accessToken
            }
            
            ServerAPI.authenExternal(authModel).then((res) => {

                if (res.response)
                    if (res.response.data)
                        if (res.response.data.Error) {
                            this.setState({ errorMessenger: res.response.data.Error });
                            this.setState({ visibleErrorDialogy: true });
                        }

                if (res.isVerified) {
                    if (wastetype == 1) window.location = "/#/register-wastegen";
                    if (wastetype == 2) window.location = "/#/register-wastetran";
                    if (wastetype == 3) window.location = "/#/register-wastepro";
                }

            }).catch((error) => {
                //this.growl.show({severity: 'error', summary: 'ข้อความจากระบบ', detail: 'เกิดข้อผิดพลาดในการเชื่อมต่อ'});
            })
        } else {
            this.setState({ errorMessenger: '' });
            this.setState({ visibleErrorDialogy: true });
        }
        */

    };

    responseGoogle = (response, wastetype) => {
        if(TYPE_ROOT === 'prod') {
            if (response) {
                if (response.status != "unkhow") {
                    var model = {
                        response: response,
                        wastetype: wastetype,
                        socialtype: 'google'
                    }
                    localStorage.removeItem('landing-regis-response');
                    localStorage.setItem('landing-regis-response', JSON.stringify(model));
                    window.location = "/#/register-landing";
                } else {
                    window.open(WEB_ROOT, "_self");
                }
            }
        }

        /*
        if (response.error) {
            this.setState({ errorMessenger: '' });
        } else if (response.tokenId) {
            localStorage.removeItem('auth-google');
            localStorage.setItem('auth-google', JSON.stringify(response));
            var authModel = {
                Provider: "Google",
                Token: response.tokenId
            }

            ServerAPI.authenExternal(authModel).then((res) => {


                if (res.response)
                    if (res.response.data)
                        if (res.response.data.Error) {
                            this.setState({ errorMessenger: res.response.data.Error });
                            this.setState({ visibleErrorDialogy: true });
                        }

                if (res.isVerified) {
                    if (wastetype == 1) window.location = "/#/register-wastegen";
                    if (wastetype == 2) window.location = "/#/register-wastetran";
                    if (wastetype == 3) window.location = "/#/register-wastepro";
                }

            }).catch((error) => {
                //this.growl.show({severity: 'error', summary: 'ข้อความจากระบบ', detail: 'เกิดข้อผิดพลาดในการเชื่อมต่อ'});
            })
        } else {
            this.setState({ errorMessenger: '' });
            this.setState({ visibleErrorDialogy: true });
        }
        */
    }

    renderFeature = () => {
        return (
            <div id="features">
                <div className="p-grid">
                    <div className="p-col-6 p-md-3">
                        <h3>ช่วยโลกด้วยการเป็นผู้ขายขยะรีไซเคิล</h3>
                        <p>คุณสามารถช่วยโลกและมีรายได้ไปพร้อมๆ กัน ง่ายๆ โดยการสมัครสมาชิกเข้าร่วมโครงการเป็นผู้รักษ์โลก และนำขยะรีไซเคิลมาขายให้กับเราง่ายๆ จากที่บ้านไม่จำเป็นต้องเดินทางไปถึงที่ขาย เรามีบริการมารับถึงที่.</p>
                    </div>
                    <div className="p-col-6 p-md-3">
                        <h3>ช่วยโลกด้วยการเป็นผู้ทีมขนส่งซีซาเล้งขนย้ายขยะรีไซเคิล</h3>
                        <p>ถ้ามีรถซาเล้ง รถกะบะ หรือรถขนส่งใดๆ คุณสามารถช่วยโลกง่ายๆ โดยการสมัครสมาชิกเข้าร่วมโครงการเป็นทีมขนส่งซีซาเล้ง และเราจะบอกคุณเองว่าสามารถไปรับขยะรีไซเคิลได้จากที่ไหน และขนมาให้เราง่ายๆ แค่นี้ ก็ช่วยโลกพร้อมกับมีรายได้ด้วย .</p>
                    </div>
                    <div className="p-col-6 p-md-3">
                        <h3>ช่วยโลกพร้อมกับพัฒนาธุกิจศูนย์จัดการฯซีซาเล้งของคุณ</h3>
                        <p>หากคุณเป็นร้านรับซ้อของเก่า ขยะรีไซเคิล คุณจะสามารถมีรายได้โดยเราจะจัดหาขยะรีไซเคิลที่ผ่านการคัดกรองแล้ว มาส่งถึงที่ในราคาที่เหมาะดีที่สุด แค่นี้คุณก็ขยานธุกิจได้พร้อมกับมีส่วยช่วยโลกลดขยะได้แล้ว.</p>
                    </div>
                    <div className="p-col-6 p-md-3">
                        <h3>อยากช่วยโลกแต่ไม่อยากทำธุรกิจได้มั๊ย ?</h3>
                        <p>คุณก็สามารถช่วยโลกลดขยะได้ง่าย เพียงแค่บอกต่อโครงการของเรา หรือ Share ไปยัง Social บอกเพื่อนๆ ของคุณ.</p>
                    </div>
                </div>
            </div>
        );
    }

    renderLogin = () => {
        var disableFacebookGoogle = false
        if (localStorage.getItem('access-token')) {
             disableFacebookGoogle = true
        }
        return (
            <div ref={(section) => { this.registerWaste = section; }}>
                <Dialog header="ข้อความจากระบบ" visible={this.state.visibleErrorDialogy} onHide={(e) => this.setState({ visibleErrorDialogy: false })} style={{ width: '30vw' }} >
                    {this.state.errorMessenger}
                </Dialog>
                <div className="p-grid">
                    <div className="p-col-12 p-md-4">
                        <div className="price-content list-group">
                            <div style={{ textAlign: 'right' }}>
                                <h1 style={{ color: '#000', marginBottom: 0, marginTop: 0 }}>Earth Lover</h1>
                                <h2 style={{ color: '#000', marginBottom: 0, marginTop: 0 }}>ผู้รักษ์โลกร่วมกับซีซาเล้ง</h2>
                                <h2 style={{ color: '#000', marginBottom: 5, marginTop: 0 }}>(ผู้ทิ้งหรือผู้ขายขยะรีไซเคิล)</h2>
                            </div>
                            <div className="price-header price-header-u1 list-group-item">
                            </div>
                            <div className="list-group-item"><span>บุคคลทั่วไป / ร้านค้าฯ / องค์กรฯ / ชุมชนฯ ที่ต้องการนำขยะรีไซเคิลเข้าสู่กระบวนการจัดการที่ดีและถูกต้อง เพื่อลดขยะตกค้าง นำมาซึ่งสิ่งแวดล้อมที่ดีและโลกน่าอยู่มากขึ้น</span></div>
                            <div className="list-group-item button-section">
                                <Button label="สมัครเป็นผู้รักษ์โลก" className="p-button-secondary info-btn btn-block" onClick={() => { this.onClickRegister(1) }} />
                                <FacebookLogin
                                    appId="2833348596989439"
                                    autoLoad={false}
                                    fields="name,email"
                                    textButton="สมัครสมาชิกด้วย Facebook account"
                                    cssClass="p-button p-button-secondary info-btn btn-block"
                                    callback={(e) => { this.facebookResponse(e, 1) }}
                                    isDisabled={disableFacebookGoogle} />
                                {TYPE_ROOT === 'prod' && <GoogleLogin
                                    clientId="314933320437-jklu9icuovfkhc1dhbaaar03sjvckvnc.apps.googleusercontent.com"
                                    buttonText="สมัครสมาชิกด้วย Google account"
                                    render={renderProps => (
                                        <button disabled={disableFacebookGoogle} className="p-button p-button-secondary info-btn btn-block" onClick={renderProps.onClick} >สมัครสมาชิกด้วย Google account</button>
                                    )}
                                    onSuccess={(e) => { this.responseGoogle(e, 1) }}
                                    onFailure={(e) => { this.responseGoogle(e, 1) }}
                                    style={{ backgroundColor: '#5bc0de' }}
                                    icon={false}
                                    className="p-button p-button-secondary info-btn btn-block"
                                    cookiePolicy={'single_host_origin'}
                                />}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <div className="price-content list-group">
                            <div style={{ textAlign: 'right' }}>
                                <h1 style={{ color: '#000', marginBottom: 0, marginTop: 0 }}>Move</h1>
                                <h2 style={{ color: '#000', marginBottom: 0, marginTop: 0 }}>ทีมขนส่งซีซาเล้งเพื่อสิ่งแวดล้อม</h2>
                                <h2 style={{ color: '#000', marginBottom: 5, marginTop: 0 }}>(ผู้ขนส่งขยะรีไซเคิล)</h2>
                            </div>
                            <div className="price-header price-header-u2 list-group-item">
                            </div>
                            <div className="list-group-item"><span>เป็นผู้ขนส่ง เพื่อนำขยะรีไซเคิลจาก “ผู้รักษ์โลก” เพื่อส่งมอบไปยัง “ศูนย์จัดการฯ” เข้าสู่กระบวนการจัดการค้าวัสดุรีไซเคิล เพื่อคัดแยกประเภทต่อไป</span><span style={{ display: 'inline-block', width: '150px' }}></span></div>
                            <div className="list-group-item button-section">
                                <Button label="สมัครเป็นทีมขนส่งซีซาเล้ง" className="p-button-secondary info-btn btn-block" onClick={() => { this.onClickRegister(2) }} />
                                <FacebookLogin
                                    appId="2833348596989439"
                                    autoLoad={false}
                                    fields="name,email"
                                    textButton="สมัครสมาชิกด้วย Facebook account"
                                    cssClass="p-button p-button-secondary info-btn btn-block"
                                    callback={(e) => { this.facebookResponse(e, 2) }}
                                    isDisabled={disableFacebookGoogle} />
                                {TYPE_ROOT === 'prod' && <GoogleLogin
                                    clientId="314933320437-jklu9icuovfkhc1dhbaaar03sjvckvnc.apps.googleusercontent.com"
                                    buttonText="สมัครสมาชิกด้วย Google account"
                                    render={renderProps => (
                                        <button disabled={disableFacebookGoogle} className="p-button p-button-secondary info-btn btn-block" onClick={renderProps.onClick} >สมัครสมาชิกด้วย Google account</button>
                                    )}
                                    onSuccess={(e) => { this.responseGoogle(e, 2) }}
                                    onFailure={(e) => { this.responseGoogle(e, 2) }}
                                    className="p-button p-button-secondary info-btn btn-block"
                                    cookiePolicy={'single_host_origin'}
                                />}
                            </div>
                        </div>
                    </div>
                    <div className="p-col-12 p-md-4">
                        <div className="price-content list-group">
                            <div style={{ textAlign: 'right' }}>
                                <h1 style={{ color: '#000', marginBottom: 0, marginTop: 0 }}>Collecting Center</h1>
                                <h2 style={{ color: '#000', marginBottom: 0, marginTop: 0 }}>ศูนย์จัดการฯซีซาเล้งเพื่อชุมชนสะอาด</h2>
                                <h2 style={{ color: '#000', marginBottom: 5, marginTop: 0 }}>(ร้านรับซื้อขยะรีไซเคิล)</h2>
                            </div>
                            <div className="price-header price-header-u3 list-group-item">
                            </div>
                            <div className="list-group-item"><span>เป็นผู้รวบรวม, คัดแยกขยะรีไซเคิล เพื่อเข้าสู่กระบวนการแปรรูปเป็นวัตถุดิบทดแทน, ลดการใช้ทรัพยากรธรรมชาติ เพื่อส่งต่อสิ่งแวดล้อมที่ดีและยั่งยืนให้คนรุ่นต่อไป</span></div>
                            <div className="list-group-item button-section">
                                <Button label="สมัครสมาชิกเป็นศูนย์จัดการฯซีซาเล้ง" className="p-button-secondary info-btn btn-block" onClick={() => { this.onClickRegister(3) }} />
                                <FacebookLogin
                                    appId="2833348596989439"
                                    autoLoad={false}
                                    fields="name,email"
                                    textButton="สมัครสมาชิกด้วย Facebook account"
                                    cssClass="p-button p-button-secondary info-btn btn-block"
                                    callback={(e) => { this.facebookResponse(e, 3) }}
                                    isDisabled={disableFacebookGoogle} />
                                {TYPE_ROOT === 'prod' && <GoogleLogin
                                    clientId="314933320437-jklu9icuovfkhc1dhbaaar03sjvckvnc.apps.googleusercontent.com"
                                    buttonText="สมัครสมาชิกด้วย Google account"
                                    render={renderProps => (
                                        <button disabled={disableFacebookGoogle} className="p-button p-button-secondary info-btn btn-block" onClick={renderProps.onClick} >สมัครสมาชิกด้วย Google account</button>
                                    )}
                                    onSuccess={(e) => { this.responseGoogle(e, 3) }}
                                    onFailure={(e) => { this.responseGoogle(e, 3) }}

                                    className="p-button p-button-secondary info-btn btn-block"
                                    cookiePolicy={'single_host_origin'}
                                />}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    loggingOut = () => {
        const { userServiceProps } = this.props
        userServiceProps.logout();
        closeWebsocket();
    }

    loginButton() {

        const beforeLogin = (
            <div className="p-col-12 p-md-6 p-lg-5 p-xl-4 sign-up-buttonpane">
                <a href="#/login"><button>Login</button></a>
                <a onClick={() => scrollToComponent(this.registerWaste, { offset: 0, align: 'top', duration: 1500 })} style={{ cursor: 'pointer' }}><button>Register</button></a>
            </div>
        );
        const afterLogin = (
            <div className="p-col-12 p-md-6 p-lg-5 p-xl-4 sign-up-buttonpane">
                <a href=""><button onClick={this.loggingOut}>Logout</button></a>
                <a href={"#/" + localStorage.getItem('user-type')}><button>Member</button></a>
            </div>
        );
        var token = localStorage.getItem('access-token');
        if (token) {
            return afterLogin;
        } else {
            return beforeLogin;
        }
    }

    render() {
        const {
            province,
        } = this.props
        const btnLogin = this.loginButton();
        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ปิด" icon="pi pi-times" onClick={() => this.setState({ displayDialog: false })} className="p-button-warning" />
        </div>;
        let registerFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ยืนยัน" icon="pi pi-check" onClick={this.redirectToRegis} />
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({ registerDialog: false })} className="p-button-warning" />
        </div>;
       
        return (

            <div className="landing-wrapper">
                <div id="header">
                    <div className="p-grid header-section bg-green-trans fixed-top">
                        <div className="p-col-12 p-sm-3 p-md-2 p-lg-2 p-xl-1">
                            <a href=""><img alt="Logo" src="assets/layout/images/logo_c3leng.png" /></a>
                            <a href="javascript:void(0);" className="icon-res-menu" onClick={() => { this.onClickNavbar() }}>
                                <i class={this.state.iconBar}></i>
                            </a>
                        </div>
                        <div className="p-col-12 p-sm-9 p-md-10 p-lg-10 p-xl-11 nav-mb-section">
                            <div id="topNavbarMenuList" className="p-grid nav-list-menu">
                                <div className="p-col-12 p-md-6 p-lg-7 p-xl-8">
                                    {/* <a href="/#">หน้าหลัก</a> */}
                                    <a onClick={() => scrollToComponent(this.registerWaste, { offset: 0, align: 'top', duration: 1500 })} style={{ cursor: 'pointer' }}>สมัครสมาชิก</a>
                                    <a href="#/privacy-policy">นโยบายความเป็นส่วนตัว</a>
                                </div>
                                {btnLogin}
                            </div>
                        </div>
                    </div>
                    <div className="p-grid">
                        <div className="p-col-12 p-md-8 p-md-offset-2 p-lg-7 p-lg-offset-1 p-xl-5 p-xl-offset-1">
                            <div className="header-content">
                                <ScrollAnimation animateIn='bounceInRight' animateOut='bounceOutLeft'>
                                    <div className="header-panel">
                                        <h1>ซี-ซาเล้ง</h1>
                                        <h2>จัดการขยะรีไซเคิลเข้าสู่ระบบฯ ครบ จบ ในแอปฯเดียว</h2>
                                    </div>
                                </ScrollAnimation>
                            </div>
                        </div>
                    </div>
                </div>

                {/*this.renderFeature()*/}

                <div className="p-grid">
                    <div className="p-col-12">
                        <img alt="" src="assets/layout/images/backdrop.jpg" style={{ width: '100%' }} />
                    </div>
                </div>

                <div id="pricing" className="p-grid container-data">
                    {/* include Render login */}
                    {this.renderLogin()}
                </div>

                <div id="footer">
                    <div className="p-grid container-data">
                        <div className="p-col-12 p-md-7 p-lg-6 p-xl-6">
                            <img alt="Logo" src="assets/layout/images/logo_c3leng.png" class="" />
                            <div>
                                <span>“แจ้งรับขยะรีไซเคิล สอบถามรายละเอียด โทร. 021151482"</span>
                                <div>C3Leng by GGT </div>
                            </div>
                        </div>
                        <div className="p-col-12 p-md-3 p-lg-5 p-xl-5 mb-none">
                            <ul className="landing-menu">
                                <li>
                                    <a href="/#">Home</a>
                                </li>
                                <li>
                                    <a onClick={() => scrollToComponent(this.registerWaste, { offset: 0, align: 'top', duration: 1500 })} style={{ cursor: 'pointer' }}>Register</a>
                                </li>
                                <li>
                                    <a href="#/faq">คำถามที่พบบ่อย</a>
                                </li>
                            </ul>
                        </div>
                        <div className="p-col-12 p-md-2 p-lg-1 p-xl-1">
                            <img alt="" src="assets/layout/images/line_qrcode.jpg" class="img-qrcode" />
                            <div className="text-center">@c3leng</div>
                        </div>
                    </div>
                </div>
                <Dialog
                    visible={this.state.displayDialog}
                    style={{ width: '40vw' }}
                    header="แจ้งเตือน"
                    modal={true}
                    footer={dialogFooter}
                    onHide={() => this.setState({ displayDialog: false })}>
                    คุณเป็นสมาชิกนี้อยู่แล้วในระบบ
                </Dialog>

                <Dialog
                    visible={this.state.registerDialog}
                    style={{ width: '40vw' }}
                    header="แจ้งเตือน"
                    modal={true}
                    footer={registerFooter}
                    onHide={() => this.setState({ registerDialog: false })}>
                    คุณต้องการสมัครเป็น{this.state.userTypeName}
                </Dialog>

                {/* <AlertDialogView
                    onHide={(e) => this.setState({ alertDialog: false })}
                    showDialog={this.state.alertDialog}
                /> */}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    province: state.province,
    district: state.district,
    subDistrict: state.subDistrict,
    authentication: state.authentication,
    userInfo: state.userInfo.data,
})

const mapDispatchToProps = dispatch => ({
    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    WastegenServiceProps: bindActionCreators(WastegenService, dispatch),
    WastetranServiceProps: bindActionCreators(WastetranService, dispatch),
})

export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(LandingContainer)

