import {
    USER_INFO,
    UPDATE_WASTEGEN_PROFILE,
    UPDATE_WASTEPRO_PROFILE,
    UPDATE_WASTETRAN_PROFILE,
    CHANGE_PASSWORD,
    USER_ANNOUNCE,
    USER_CERT_LIST
} from '../service/actionType'

const initialState = {
    data: null,
    isFailed: false,
    isLoading: false
}


export function userInfo(state = initialState, action) {
    switch (action.type) {
        case USER_INFO.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case USER_INFO.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case USER_INFO.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function updateWastegenProfile(state = initialState, action) {
    switch (action.type) {
        case UPDATE_WASTEGEN_PROFILE.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case UPDATE_WASTEGEN_PROFILE.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case UPDATE_WASTEGEN_PROFILE.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function updateWasteproProfile(state = initialState, action) {
    switch (action.type) {
        case UPDATE_WASTEPRO_PROFILE.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case UPDATE_WASTEPRO_PROFILE.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case UPDATE_WASTEPRO_PROFILE.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}
export function updateWastetranProfile(state = initialState, action) {
    switch (action.type) {
        case UPDATE_WASTETRAN_PROFILE.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case UPDATE_WASTETRAN_PROFILE.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case UPDATE_WASTETRAN_PROFILE.FAILURE:

            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function changePassword(state = initialState, action) {
    switch (action.type) {
        case CHANGE_PASSWORD.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case CHANGE_PASSWORD.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case CHANGE_PASSWORD.FAILURE:

            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function userAnnounce(state = initialState, action) {
    switch (action.type) {
        case USER_ANNOUNCE.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case USER_ANNOUNCE.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case USER_ANNOUNCE.FAILURE:

            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}

export function userCert(state = initialState, action) {
    switch (action.type) {
        case USER_CERT_LIST.REQUEST:
            return { ...state, isFailed: false, data: null, isLoading: true };
        case USER_CERT_LIST.SUCCESS:
            return { ...state, isFailed: false, data: action.payload, isLoading: false };
        case USER_CERT_LIST.FAILURE:
            return { ...state, isFailed: true, data: action.payload, isLoading: false };
        default:
            return state
    }
}


