import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';

import { InputText } from 'primereact/components/inputtext/InputText';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { MapMarkers } from '../../features/map/MapMarkers'
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { Avatar } from '../../features/widget/Avatar';
import { API_ROOT } from '../../constants/api'
import { ProgressSpinner } from 'primereact/progressspinner';
import {Spinner} from 'primereact/spinner';
import {Dialog} from 'primereact/dialog';
import * as ServerAPI from './service';
import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'
import * as DashBoardService from './service'
import { isEmpty } from '../../utils/validators';
import {Validators} from '../../utils'
import {CEInputText,CECalendar,CEDropdown} from '../../features/input';
import {CEFileUpload} from '../../features/widget';

class ManageDeliveryDistance extends Component {
    static propTypes = {

    }
    static defaultProps = {

    }

    constructor() {
        super();
        this.state = {
            distanceList: [],
            distanceLabel: '',
            minDistance: '',
            maxDistance: '',
            isNotValid: false,
            isNotValidUpdate: false,
            showProgress: false,
        };
        this.onClickAddDistance = this.onClickAddDistance.bind(this);
        this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
        this.onValidation = this.onValidation.bind(this);
        this.getTranspotDistanceType = this.getTranspotDistanceType.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.onUpdateValidation = this.onUpdateValidation.bind(this);
        
    }
    componentDidMount() {
        this.getTranspotDistanceType();
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div></div>);
    }
    getTranspotDistanceType(){
        this.setState({ showProgress: true })
        ServerAPI.getTranspotDistanceType().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({distanceList: res })
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false})
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false})
            }
        }).catch((error) => {
            this.setState({ showProgress: false})
        })
    }
    actionTemplate(rowData, column) {
        return <div>
            {/* <Button type="button" onClick={() => this.onClickDeleteItem(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button> */}
            <Button type="button" onClick={() => this.onDistanceUpdate(column.rowData)} icon="pi pi-pencil" className="p-button" ></Button>
            <Button type="button" onClick={() => this.onDistanceDelete(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button>
        </div>;
    }
    onClickDeleteItem(rowData) {
        if (rowData !== '') {
            console.log(rowData)
            var id = rowData.gid;
            ServerAPI.removeTranspotDistanceType(id).then((res) => {
                console.log(res);
                if (res) {
                    var index = res.toString().indexOf("Error:");
                    console.log("find error:" + (index));
                    if (index == -1) {
                        this.showSuccess();
                        this.getTranspotDistanceType();
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false})
                    }
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false})
                }
            }).catch((error) => {
                this.setState({ showProgress: false})
            })
        }else{
            this.showWarn();
            this.setState({ showProgress: false})
        }
        // if (index !== -1) {
        //     var array = this.state.distanceList;
        //     array.splice(index, 1);
        //     this.setState({ distanceList: array });
        // }
    }
    update() {
        this.setState({ showProgress: true })
        console.log(this.state.distance)
        console.log(this.state.isNotValidUpdate)
        if(this.state.distance){
            var isNotinput = (
                this.state.distance.distance_name == '' ||
                this.state.distance.more_than_km == '' ||
                this.state.distance.less_than_km == '' ||
                this.state.isNotValidUpdate == true
            );
    
            if (isNotinput) {
                this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
                this.setState({ showProgress: false })
            } else {
                    var min = parseFloat(this.state.distance.more_than_km)
                    var max = parseFloat(this.state.distance.less_than_km)
    
                if(min >= max){
                    console.log(min)
                    console.log(max)
                    this.showWarn("น้ำหนักต่ำสุด ต้องน้อยกว่าน้ำหนักสูงสุด");
                    this.setState({ showProgress: false })
                }else{
                    var notValid = false;
                    this.state.distanceList.indexOf("")
                    this.state.distanceList.forEach(objdistanceList => {
                        var objMin = parseFloat(objdistanceList.more_than_km)
                        var objMax = parseFloat(objdistanceList.less_than_km)
                        console.log(min+" > "+objMin);
                        console.log(max+" > "+objMin);
                        console.log(min+" < "+objMax);
                        console.log(max+" < "+objMax);
                        
                        if(objdistanceList.gid != this.state.distance.gid)
                        if((min < objMin && max < objMin) || (min > objMax && max > objMax)){
                            console.log("pass!");
                        }else if(objMin <= min || objMax >= max){
                            console.log("not pass!")
                            notValid = true;
                            }else if(objMin >= min || objMax <= max){
                            console.log("not pass2!")
                            notValid = true;
                            }else console.log("pass2!");
                    }); 
    
                    if(notValid){
                        this.showWarn("มีช่วงระยะทางที่ซ้ำซ้อนกัน");
                        this.setState({ showProgress: false })
                    }else{
                        var data = {
                            gid: this.state.distance.gid,
                            distance_name: this.state.distance.distance_name,
                            more_than_km: parseFloat(this.state.distance.more_than_km),
                            less_than_km: parseFloat(this.state.distance.less_than_km),
                        }

                        ServerAPI.editTransportDistanceType(data).then((res) => {
                            console.log(res);
                            if (res) {
                                var index = res.toString().indexOf("Error:");
                                console.log("find error:" + (index));
                                if (index == -1) {
                                    this.getTranspotDistanceType();
                                    this.setState({ displayDialog:false});
                                    this.showSuccess();
                                } else {
                                    this.showWarn();
                                    this.setState({ showProgress: false })
                                }
                            } else {
                                this.showWarn();
                                this.setState({ showProgress: false })
                            }
                        }).catch((error) => {
                            this.setState({ showProgress: false })
                        })
                    }
                }
            }
        }
    }

    delete() {
        this.setState({ showProgress: true })
        var data = this.state.distance;
        this.onClickDeleteItem(data);
        // let index = this.findSelectedWeightIndex();
        // this.setState({
        //     cars: this.state.cars.filter((val,i) => i !== index),
        //     selectedCar: null,
        //     car: null,
        this.setState({deleteDialog: false});
    }
    updateProperty(property, value) {
        let distance = this.state.distance;
        distance[property] = value;
        this.setState({distance: distance});
    }

    onDistanceUpdate(e){
        console.log(e)
        this.setState({
            displayDialog:true,
            distance: Object.assign({}, e)
        });
    }
    onDistanceDelete(e){
        console.log(e)
        this.setState({
            deleteDialog:true,
            distance: Object.assign({}, e)
        });
    }
    onValidation(valid){
        console.log(valid)
        if(!valid) this.setState({isNotValid: true })
        else this.setState({isNotValid: false })
    }
    onUpdateValidation(valid){
        console.log(valid)
        if(!valid) this.setState({isNotValidUpdate: true })
        else this.setState({isNotValidUpdate: false })
    }
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    onClickAddDistance(){
        this.setState({ showProgress: true })
        var isNotinput = (this.state.distanceLabel == '' ||
            this.state.minDistance == '' ||
            this.state.maxDistance == '' ||
            this.state.isNotValid == true
        );

        if (isNotinput) {
            this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
            this.setState({ showProgress: false })
        } else {
                var min = parseFloat(this.state.minDistance)
                var max = parseFloat(this.state.maxDistance)

                if(min >= max){
                    console.log(min)
                    console.log(max)
                    this.showWarn("ค่าเริ่มต้น ต้องน้อยกว่าค่าสิ้นสุด");
                    this.setState({ showProgress: false })
                }else{
                    var notValid = false;
                    this.state.distanceList.forEach(objDistance => {
                        var objMin = parseFloat(objDistance.more_than_km)
                        var objMax = parseFloat(objDistance.less_than_km)
                        console.log(min+" < "+objMin);
                        console.log(max+" < "+objMin);
                        console.log(min+" > "+objMax);
                        console.log(max+" > "+objMax);
                        if((min < objMin && max < objMin) || (min > objMax && max > objMax)){
                            console.log("pass!");
                        }else if(objMin <= min || objMax >= max){
                                console.log("not pass!")
                                notValid = true;
                              }else if(objMin >= min || objMax <= max){
                                console.log("not pass2!")
                                notValid = true;
                              }else console.log("pass2!");
                    }); 
    
                    if(notValid){
                        this.showWarn("มีช่วงระยะทางที่ซ้ำซ้อนกัน");
                        this.setState({ showProgress: false })
                    }else{
                        var distanceList = {
                            // gid: 0,
                            // distance_code: '',
                            distance_name: this.state.distanceLabel,
                            more_than_km: parseFloat(this.state.minDistance),
                            less_than_km: parseFloat(this.state.maxDistance),
                        }
                        //this.state.distanceList.push(distanceList)

                        ServerAPI.AddTranspotDistanceType(distanceList).then((res) => {
                            console.log(res);
                            if (res) {
                                var index = res.toString().indexOf("Error:");
                                console.log("find error:" + (index));
                                if (index == -1) {
                                    this.showSuccess();
                                    this.getTranspotDistanceType();
                                    this.setState({ distanceLabel: '' })
                                    this.setState({ minDistance: '' })
                                    this.setState({ maxDistance: '' })
                                } else {
                                    this.showWarn();
                                    this.setState({ showProgress: false})
                                }
                            } else {
                                this.showWarn();
                                this.setState({ showProgress: false})
                            }
                        }).catch((error) => {
                            this.setState({ showProgress: false})
                        })

                        
                        //this.showSuccess();
                        //this.onSubmit(distanceList);
                    }
                    console.log(this.state.distanceList)
                }
        }
    }

    showAddDistance(){
        const newDistance = (
            <div className="p-col-12" >
                    <Accordion multiple={true}  >
                        <AccordionTab header="เพิ่มระยะทางใหม่">
                            <div className="p-grid">
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>ระยะทาง :</label>
                            </div>
                            <div className="p-col-12 p-md-10 p-lg-11">
                            <CEInputText                         
                                type="text"
                                value={this.state.distanceLabel}
                                placeholder="กำหนดระยะทาง"
                                maxlength={25}
                                required={true}
                                onChange={(e)=> this.setState({distanceLabel: e.target.value})}
                                />
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>ค่าเริ่มต้น :</label>
                            </div>
                            <div className="p-col-12 p-md-10 p-lg-11" style={{ alignSelf: 'center' }}>
                                <CEInputText   
                                    id="minDistance"                        
                                    type="money"
                                    value={this.state.minDistance}
                                    name="minDistance"
                                    placeholder="ต่ำสุด"
                                    tooltip="กรอกระยะ (ทศนิยม 2 ตำแหน่ง)"
                                    required={true}
                                    isvalid={this.onValidation}
                                    onChange={(e)=> this.setState({minDistance: e.target.value})}
                                    />
                                <label style={{marginLeft: '10px'}}>กิโลเมตร</label>
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>ค่าสิ้นสุด :</label>
                            </div>
                            <div className="p-col-12 p-md-10 p-lg-11">
                                <CEInputText   
                                    id="maxDistance"                        
                                    type="money"
                                    value={this.state.maxDistance}
                                    name="maxDistance"
                                    placeholder="สูงสุด"
                                    tooltip="กรอกระยะ (ทศนิยม 2 ตำแหน่ง)"
                                    required={true}
                                    isvalid={this.onValidation}
                                    onChange={(e)=> this.setState({maxDistance: e.target.value})}
                                    />
                                <label style={{marginLeft: '10px'}}>กิโลเมตร</label>
                            </div>
                            <div className="p-col-12">
                                <Button
                                    type="submit"
                                    label="เพิ่มระยะทาง"
                                    icon="pi pi-plus"
                                    iconPos="left"
                                    onClick={this.onClickAddDistance}
                                />
                            </div>
                            
                            </div>
                        </AccordionTab>
                        
                    </Accordion>
            </div>
        );
        return newDistance;
    }

    showDistanceTable(){
        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="บันทึก" icon="pi pi-check" onClick={this.update}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displayDialog: false})} className="p-button-warning"/>
            </div>;
        let deleteDialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ใช่" icon="pi pi-check" onClick={this.delete}/>
            <Button label="ไม่ใช่" icon="pi pi-times" onClick={() => this.setState({deleteDialog: false})} className="p-button-warning"/>
            </div>;
        const distanceTable = (
            <div className="p-col-12">
                    <Panel header="รายการระยะทาง" >
                        <DataTable value={this.state.distanceList} paginatorPosition="bottom" paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                            responsive={true}
                            emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                            </div>)}>
                            <Column field="distance_name" header="ระยะทาง" sortable={true} filter={true} />
                            <Column field="more_than_km" header="ค่าต่ำสุด (กม.)" sortable={true} filter={true} className="p-text-center"/>
                            <Column field="less_than_km" header="ค่าสูงสุด (กม.)" sortable={true} filter={true} className="p-text-center"/>
                            <Column field="gid" body={this.actionTemplate.bind(this)} header="จัดการ" className="p-text-center" style={{ width: '8em' }} />
                        </DataTable>
                    </Panel>
                    <Dialog visible={this.state.deleteDialog} style={{width: '40vw'}} header="ยืนยันการลบข้อมูล" modal={true} footer={deleteDialogFooter} onHide={() => this.setState({deleteDialog: false})}>
                        {
                            this.state.distance &&
                            
                            <div className="p-grid p-fluid" >
                                <div className="p-col-12"><span>คุณต้องการจะลบข้อมูลต่อไปนี้</span></div>
                                <div className="p-col-4" ><label htmlFor="distance_name">ระยะทาง :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.distance.distance_name}</strong>
                                </div>

                                <div className="p-col-4" ><label htmlFor="more_than_km">ค่าเริ่มต้น (กม.) :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.distance.more_than_km}</strong>
                                </div>

                                <div className="p-col-4" ><label htmlFor="less_than_km">ค่าสิ้นสุด (กม.) :</label></div>
                                <div className="p-col-8" >
                                    <strong>{this.state.distance.less_than_km}</strong>
                                </div>
                            </div>
                        }
                    </Dialog>
                    <Dialog visible={this.state.displayDialog} style={{width: '40vw'}} header="แก้ไขข้อมูล" modal={true} footer={dialogFooter} onHide={() => this.setState({displayDialog: false})}>
                        {
                            this.state.distance &&

                            <div className="p-grid p-fluid" >
                                
                                <div className="p-col-4" style={{ alignSelf: 'center' }}><label htmlFor="distance_name">ระยะทาง :</label></div>
                                <div className="p-col-8" >
                                    <CEInputText   
                                        id="distance_name"                      
                                        type="text"
                                        value={this.state.distance.distance_name}
                                        placeholder="กำหนดช่วงน้ำหนัก"
                                        maxlength={25}
                                        required={true}
                                        onChange={(e) => {this.updateProperty('distance_name', e.target.value)}}
                                        />
                                </div>

                                <div className="p-col-4" style={{ alignSelf: 'center' }}><label htmlFor="more_than_km">ค่าเริ่มต้น (กม.) :</label></div>
                                <div className="p-col-8" >
                                    <CEInputText   
                                        id="more_than_km"                        
                                        type="money"
                                        value={this.state.distance.more_than_km}
                                        name="more_than_km"
                                        placeholder="ต่ำสุด"
                                        tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                        required={true}
                                        isvalid={this.onUpdateValidation}
                                        onChange={(e) => {this.updateProperty('more_than_km', e.target.value)}}
                                        />
                                        
                                </div>

                                <div className="p-col-4" style={{ alignSelf: 'center' }}><label htmlFor="less_than_km">ค่าสิ้นสุด (กม.) :</label></div>
                                <div className="p-col-8" >
                                    <CEInputText   
                                        id="less_than_km"                        
                                        type="money"
                                        value={this.state.distance.less_than_km}
                                        name="less_than_km"
                                        placeholder="สูงสุด"
                                        tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                        required={true}
                                        isvalid={this.onUpdateValidation}
                                        onChange={(e) => {this.updateProperty('less_than_km', e.target.value)}}
                                        />
                                    
                                </div>
                            </div>
                        }
                    </Dialog>
            </div>
        );
        if(!this.state.showProgress)
        return distanceTable;
    }
    

    render() {
        const addDistancePanel = this.showAddDistance();
        const distanceTablePanel = this.showDistanceTable();
        const showProgress = this.showProgressSpinner();
        
        return <div className="p-grid ">
            <Growl ref={(el) => this.growl = el} />
                <div className="p-col-12">
                        <h1>ตั้งค่าระยะทางขนส่ง</h1>
                    </div>
                    
                    {addDistancePanel}
                    {showProgress}
                    {distanceTablePanel}

        </div>
    }
}



const mapStateToProps = (state) => ({
    // dhbUsers: state.dhbAdmin.dhbUsers,
    // locationMaster: state.location,
    // provinces: state.location.province,
    // districts: state.location.district,
    // subDistricts: state.location.subDistrict,
    // cardProvince: state.location.cardProvince,
    // cardDistrict: state.location.cardDistrict,
    // cardSubDistrict: state.location.cardSubDistrict,
    // authentication: state.authentication,
    // userInfo: state.userInfo.data,
    // userInfoMaster: state.userInfo,
    // updateWasteproProfile: state.updateWasteproProfile,
    // banks: state.master.banks,
    // productType: state.master.productType,
    // product: state.master.product,
    // getFileWastepro: state.getFileWastepro,
})

const mapDispatchToProps = dispatch => ({

    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    fileServiceProps: bindActionCreators(FileService, dispatch),

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ManageDeliveryDistance)