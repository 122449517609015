import React, { Component } from "react";
import { Panel } from "primereact/panel";
import { Dropdown } from "primereact/dropdown";
import { InputTextarea } from "primereact/components/inputtextarea/InputTextarea";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Chips } from "primereact/chips";
import { ListBox } from "primereact/listbox";
import moment from "moment";
import * as ServerAPI from "./service";
import * as UserService from "../../service/user.service";
import { updateReadAll } from "../../service/ChatService";
import LeftBubble from "./LeftBubble";
import RightBubble from "./RightBubble";
import { minBy, get } from "lodash";
import { sendMessage } from "../../utils/chat";
import scrollToComponent from "react-scroll-to-component";
import { FileUpload } from "primereact/fileupload";
import { implementWebsocket } from "../../utils/chat";
import { getFilenameFromUriAndroid } from "../../utils/utilFunctions";
import { API_ROOT } from "../../constants/api";
import { WASTE_TYPE } from "../../constants/mappingValue";
import { Dialog } from "primereact/dialog";
import { updateUsersAll } from "../../service/admin.service";
import * as sticker from "../../constants/sticker";
import { stickerPrefix } from "./utils";
import { OverlayPanel } from "primereact/overlaypanel";

const stickerDefaultList = [...Array(40)].map((_, index) => {
  if (index < 9) {
    return { id: `0${index + 1}` };
  }
  return { id: (index + 1).toString() };
});

export class ChatRoom extends Component {
  static defaultProps = {
    orderCode: "",
    receiverId: "",
    receiverImage: "",
    receiverWasteType: "",
    roomId: "",
    header: "แชท",
    isAdminChat: false,
    isAmAdmin: false,
    userList: "",
    receiverUserName: "",
    onUpdateUserList: () => {},
    onUpdateAdminBadge: () => {},
    adminBadge: 0,
  };

  constructor(props) {
    super(props);
    this.state = {
      test: "test",
      receiverImage: "",
      receiverWasteType: "",
      receiverId: "",
      message: "",
      messages: [],
      isFirstRender: true,
      stickerHeight: 0,
    };
    this.messageTemplate = this.messageTemplate.bind(this);
    this.handleOnSend = this.handleOnSend.bind(this);
  }

  roomId = this.props.roomId;
  pageSize = 20;

  componentDidMount() {
    console.log("chatRoom :componentDidMount");
    console.log(this.props);
    const listBoxElement = document.getElementsByClassName(
      "p-listbox-list-wrapper"
    );
    if (this.props.isAmAdmin) {
      listBoxElement[1].addEventListener("scroll", (e) =>
        this.handleScroll(e, this.props.roomId)
      );
    } else {
      listBoxElement[0].addEventListener("scroll", (e) =>
        this.handleScroll(e, this.props.roomId)
      );
    }
    if (this.state.isFirstRender) {
      this.getChatMessage(this.props.roomId, this.pageSize);
      this.setState({ isFirstRender: false });
    }
    const onMessage = (message, currentRouteName) => {
      let updateAdminBadge = {
        badges: this.props.adminBadge,
        roomId: this.props.roomId,
        type: 1,
      };
      this.props.onUpdateAdminBadge(updateAdminBadge);
      let sendReadData = {
        userList: this.props.userList,
        roomId: message.room_id,
        type: 1,
        message: message,
      };
      this.props.onUpdateUserList(sendReadData);

      this.getChatMessage(this.props.roomId, this.pageSize);
    };
    implementWebsocket(onMessage);
  }
  componentWillReceiveProps(nextProps) {
    console.log("chatRoom :componentWillReceiveProps");
    if (nextProps.roomId != this.props.roomId) {
      this.getChatMessage(nextProps.roomId, this.pageSize);
    }
  }
  shouldComponentUpdate(nextProps, nextState) {
    console.log("chatRoom :shouldComponentUpdate");
    return true;
  }
  componentWillUpdate(nextProps, nextState) {
    console.log("chatRoom :componentWillUpdate");
  }
  componentDidUpdate(prevProps, prevState) {
    console.log("chatRoom :componentDidUpdate");
  }
  handleScroll(e) {
    let scrollTop = e.srcElement.scrollTop;
    if (scrollTop == 0) {
      console.log("load more old chat data");
      this.getMoreChatMessage(this.props.roomId, this.pageSize);
    }
  }
  getChatMessage(roomId, pageSize) {
    const timeStamp = moment()
      .add(5, "seconds")
      .format("YYYY-MM-DDTHH:mm:ss");
    ServerAPI.getChatMessageByRoom(roomId, pageSize, timeStamp)
      .then((res) => {
        this.setState({
          messages: res.reverse(),
        });
        this.goToEnd();
        ServerAPI.updateReadAll(roomId).then((res) => {
          console.log(res);
          if (this.props.isAdminChat) {
            let updateAdminBadge = {
              badges: this.props.adminBadge,
              roomId: this.props.roomId,
              type: 0,
            };
            this.props.onUpdateAdminBadge(updateAdminBadge);
          }

          let sendReadData = {
            userList: this.props.userList,
            roomId: this.props.roomId,
            type: 0,
          };
          this.props.onUpdateUserList(sendReadData);
        });

        // if(this.props.isAdminChat && this.props.isAmAdmin) {
        //     console.log('do')
        //     console.log(this.props.chatList)
        //     ServerAPI.updateUsersAll(this.props.chatList, roomId, 0).then((res) => {
        //         console.log(res)
        //     })
        //   }
      })
      .catch((error) => {});
  }
  getMoreChatMessage(roomId, pageSize) {
    const timeStamp = get(
      minBy(this.state.messages, "send_datetime"),
      "send_datetime",
      ""
    );
    ServerAPI.loadMoreChatMessage(roomId, pageSize, timeStamp)
      .then((res) => {
        console.log(res);
        let moreChat = res.reverse();
        let currentChat = this.state.messages;
        let newChat = moreChat.concat(currentChat);
        this.setState({
          messages: newChat,
        });
        if (res.length != 0) this.bounceScrollwhenLoadMore();
      })
      .catch((error) => {});
  }
  onBasicUpload(e) {
    console.log(e.files[0]);
    const _file = e.files[0];
    this.setState({ fileImage: _file });
    ServerAPI.sendChatImage(
      _file,
      this.props.roomId,
      (isSuccessed, imageName) => {
        if (isSuccessed) {
          this.handleOnSend(imageName, 2);
        }
      }
    );
  }

  handleInputText(e) {
    this.setState({ message: e.target.value });
  }
  onPressEnterSend(e) {
    const characterCode = e.key;
    if (characterCode === "Enter") {
      this.handleOnSend(this.state.message, 1);
    }
  }

  handleOnSend = (message, type) => {
    if (message) {
      console.log("send message", message);
      const messageSending = {
        message,
        message_type: type,
        room_id: this.props.roomId,
        receiver_id: this.props.receiverId,
        receiver_waste_type: WASTE_TYPE[this.props.receiverWasteType],
        order_code: !this.props.isAdminChat ? this.props.orderCode : null,
        is_admin_chat: this.props.isAdminChat ? true : false,
      };
      sendMessage(JSON.stringify(messageSending));

      this.setState({ message: "" });
      this.getChatMessage(this.props.roomId, this.pageSize);
    }
  };

  goToEnd = () => {
    const listBoxElement = document.getElementsByClassName(
      "p-listbox-list-wrapper"
    );
    let chatListBox;
    if (this.props.isAmAdmin) {
      chatListBox = listBoxElement[1];
    } else {
      chatListBox = listBoxElement[0];
    }
    let _scrollTop = chatListBox.scrollTop;
    let _scrollHeight = chatListBox.scrollHeight;
    let newScrollTop = _scrollTop + _scrollHeight;
    chatListBox.scrollTop = newScrollTop;
    this.setState({ maxScroll: newScrollTop });
  };
  bounceScrollwhenLoadMore() {
    const listBoxElement = document.getElementsByClassName(
      "p-listbox-list-wrapper"
    );
    let chatListBox;
    if (this.props.isAmAdmin) {
      chatListBox = listBoxElement[1];
    } else {
      chatListBox = listBoxElement[0];
    }
    let _scrollTop = chatListBox.scrollTop;
    let _scrollHeight = chatListBox.scrollHeight;
    let newScrollTop = _scrollHeight - this.state.maxScroll;
    chatListBox.scrollTop = newScrollTop;
    this.setState({ maxScroll: 0 + _scrollHeight });
  }

  chatMessages() {
    return (
      <ListBox
        options={this.state.messages}
        optionLabel="name"
        itemTemplate={this.messageTemplate}
        style={{ width: "100%" }}
        listStyle={{ height: "400px" }}
        listClassName="chatListBox"
        dataKey="roomId"
      />
    );
  }

  messageTemplate(item) {
    let messageCompoment = null;
    if (!item.iam) {
      messageCompoment = (
        <LeftBubble
          message={item.message}
          type={item.message_type}
          datetime={moment(item.send_datetime).format("DD/MM/YYYY HH:mm:ss")}
          roomId={this.props.roomId}
          avataImage={this.props.receiverImage}
          userWasteType={this.props.receiverWasteType}
          receiverId={this.props.receiverId}
        />
      );
    } else if (item.iam) {
      messageCompoment = (
        <RightBubble
          message={item.message}
          type={item.message_type}
          datetime={moment(item.send_datetime).format("DD/MM/YYYY HH:mm:ss")}
          roomId={this.props.roomId}
          getImage={this.getImageSource}
        />
      );
    }

    return messageCompoment;
  }

  getImageSource(roomId, message) {
    var myImage = new Image();
    const src = `${API_ROOT}/chatmessage/GetImage/${roomId}/${message}`;
    const options = {
      method: "get",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + localStorage.getItem("access-token"),
      },
    };

    return fetch(src, options)
      .then((res) => res.blob())
      .then((blob) => {
        var objectURL = URL.createObjectURL(blob);
        myImage.src = objectURL;
        //console.log(myImage.src)
        return URL.createObjectURL(blob);
      });
    //console.log(myImage.src)
    //return myImage.src
  }
  onViewScreenClick(e) {
    console.log(e);
    this.setState({
      displayUserScreenDialog: true,
      receiverUserName: e,
    });
  }
  getToken(e) {
    // const { adminServiceProps } = this.props
    if (this.state.receiverUserName) {
      ServerAPI.getToken(this.state.receiverUserName)
        .then((res) => {
          if (res) {
            UserService.getUserTypeFunction().then((re) => {
              if (re) {
                const userType = localStorage.getItem("user-type");
                console.log(userType);
                window.location = "#/" + userType + "?from=chat";
                // this.props.history.push({
                //     pathname: '/'+userType,
                // })
              }
              this.setState({ getTokenfail: true });
            });
          } else {
            this.setState({ getTokenfail: true });
          }
        })
        .catch((error) => {
          console.log(error);
          this.setState({ getTokenfail: true });
        });
    }
  }

  handleOnShowSticker = () => {
    this.setState({ stickerHeight: 216 });
  };

  onHideSticker = () => {
    this.setState({ stickerHeight: 0 });
  };

  onSelectSticker = (stickerId) => {
    this.handleOnSend(`${stickerPrefix}${stickerId}`, 1, true);
    this.op.hide();
  };

  inputMessage() {
    console.log(stickerDefaultList);
    return (
      <>
        <div className="p-inputgroup">
          <FileUpload
            mode="basic"
            name="fileImage"
            accept="image/*"
            maxFileSize={10000000}
            //onUpload={this.onBasicUploadAuto}
            onSelect={(e) => this.onBasicUpload(e)}
            auto
            chooseLabel="ภาพ"
          />
          <Button
            type="button"
            icon="pi pi-plus"
            label='สติกเกอร์'
            onClick={(e) => this.op.toggle(e)}
            aria-haspopup
            style={{ width: '30%'}}
            aria-controls="overlay_panel"
            className="select-product-button"
          />

          <OverlayPanel
            ref={(el) => (this.op = el)}
            showCloseIcon
            id="overlay_panel"
            style={{ width: "450px" }}
            className="overlaypanel-demo"
          >
            {stickerDefaultList.map((item, index) => (
              <img
                src={sticker[`sticker_${item.id}`]}
                style={{
                  width: "20%",
                  resizeMode: "contain",
                }}
                onClick={() => this.onSelectSticker(item.id)}
              />
            ))}
          </OverlayPanel>

          <InputText
            placeholder="ระบุข้อความ"
            value={this.state.message}
            onChange={(e) => this.handleInputText(e)}
            onKeyPress={(e) => this.onPressEnterSend(e)}
          />

          <Button
            style={{ height: "100%", width: "2em" }}
            icon="fa fa-paper-plane"
            className="p-button-defual"
            onClick={() => this.handleOnSend(this.state.message, 1)}
          />
        </div>
      </>
    );
  }

  render() {
    const showChatMassage = this.chatMessages();
    const inputMessage = this.inputMessage();
    const header = (
      <div className="p-clearfix" style={{ lineHeight: "1.87em" }}>
        <span>{this.props.header}</span>
        {this.props.isAmAdmin && (
          <Button
            onClick={() => this.onViewScreenClick(this.props.receiverUserName)}
            icon="pi pi-user"
            label="หน้ามุมมองสมาชิก"
            style={{ float: "right", margin: "0" }}
          />
        )}
      </div>
    );
    let dialogUserScreenFooter = (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button
          label="ยืนยัน"
          icon="pi pi-check"
          onClick={() => this.getToken()}
        />
        <Button
          label="ยกเลิก"
          icon="pi pi-times"
          onClick={() => this.setState({ displayUserScreenDialog: false })}
          className="p-button-warning"
        />
      </div>
    );
    return (
      <Panel header={header}>
        <div className="p-grid p-fluid">
          {/* ROW2 */}
          <div className="p-col-12 chat">{showChatMassage}</div>
          <div className="p-col-12">{inputMessage}</div>
        </div>

        <Dialog
          dismissableMask={true}
          visible={this.state.displayUserScreenDialog}
          width="300px"
          header="มุมมองสมาชิก"
          modal={true}
          footer={dialogUserScreenFooter}
          onHide={() => this.setState({ displayUserScreenDialog: false })}
        >
          {this.state.receiverUserName && (
            <div className="p-grid">
              {/* <div className="p-col-4" style={{ alignSelf: 'center' }}></div> */}
              <div className="p-col-12" style={{ padding: "1em 5em 1em 5em" }}>
                ต้องการไปที่หน้าจอสมาชิก ?
              </div>
              {this.state.getTokenfail && (
                <div
                  className="p-col-12"
                  style={{ padding: "1em 5em 1em 5em" }}
                >
                  เกิดข้อผิดพลาด
                </div>
              )}
            </div>
          )}
        </Dialog>
      </Panel>
    );
  }
}
