import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Moment from 'react-moment';
import 'moment-timezone';
import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { InputText } from 'primereact/components/inputtext/InputText';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { Calendar } from 'primereact/calendar';
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { ProgressSpinner } from 'primereact/progressspinner';
import { AutoComplete } from 'primereact/autocomplete';
import { API_ROOT } from '../../constants/api';
import * as ServerAPI from './service';
import * as DashBoardService from './service'
import { isValid } from 'redux-form';
import moment from 'moment'
import { Dialog } from 'primereact/dialog';


class ManageInvoiceContainer extends Component {
    static propTypes = {

    }

    static defaultProps = {
    }

    constructor() {
        super();
        this.state = {
            status: 0,
            admin: 0,
            statusItem: [
                { label: 'เลือกทั้งหมด', value: 0 },
                { label: 'ยังไม่ได้แจ้งหนี้', value: 1 },
                { label: 'แจ้งหนี้แล้วรอชำระ', value: 2 },
                { label: 'ชำระแล้วรอตรวจสอบ', value: 3 },
                { label: 'ชำระหนี้แล้ว', value: 88 },
            ],
            showProgress: false,
            orderCode: null,
            orderID: null,
            memberUid: null,
            date1: null,
            date2: null,
            reportSearchInvoice: [],
            imageDialog: false,
            imageUrl: "",
            imageError: false
        };
        this.onDate1Change = this.onDate1Change.bind(this);
        this.onDate2Change = this.onDate2Change.bind(this);
        this.searchInvoice = this.searchInvoice.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.onSelectOrderID = this.onSelectOrderID.bind(this);
        this.onSelectMemberName = this.onSelectMemberName.bind(this);
        this.onSelectAdminName = this.onSelectAdminName.bind(this);
        this.salePriceTemplate = this.salePriceTemplate.bind(this);
        this.onInvoicingClick = this.onInvoicingClick.bind(this);
        this.onPaidClick = this.onPaidClick.bind(this);
        this.tableHeader = this.tableHeader.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.updateInvoice = this.updateInvoice.bind(this);
        this.toFormatDateTime = this.toFormatDateTime.bind(this);
        this.onImageOpen = this.onImageOpen.bind(this);
        this.imgButton = this.imgButton.bind(this);
        this.handleImageError = this.handleImageError.bind(this);
    }
    componentDidMount() {
        const { dashBoardServiceProps } = this.props
        dashBoardServiceProps.getDashboardUsers();
    }

    onDate1Change(e) { //search pickup date start
        //console.log(e.value.toISOString())
        if (this.state.date2 != null) {
            if (e.value <= this.state.date2) {
                this.setState({ date1: e.value })
            }
        } else
            this.setState({ date1: e.value })
    }
    onDate2Change(e) { //search pickup date end
        if (this.state.date1 <= e.value) {
            this.setState({ date2: e.value })
        }
    }
    searchInvoice() {
        this.setState({ dataTableSelection: null })
        //this.setState({ showReportTable: true })
        this.setState({ showProgress: true })
        //-------reset table--------
        // var reportSearchList = {}
        // this.state.reportSearchOrders.push(reportSearchList)
        // this.setState({ reportSearchOrders: [] });
        var searchModel = {
            order_code: this.state.orderID,
            user_search_name: this.state.memberUid,
            user_wasteTypes: this.state.memberType,
            invoice_status: this.state.status,
            admin_code: "",
        };

        if (this.state.date1 != null) {
            //Bankok Time zone (+7hrs)
            var date1 = new Date(this.state.date1.getTime() + 7 * 60 * 60000);
            searchModel.delivery_date_start = date1.toISOString();
        }
        if (this.state.date2 != null) {
            var date2 = new Date(this.state.date2.getTime() + 7 * 60 * 60000);
            searchModel.delivery_date_end = date2.toISOString();
        }

        ServerAPI.searchInvoice(searchModel).then((res) => {
            console.log("response");
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    console.log(res);
                    if (res.length > 0) {

                        let data = []
                        res.forEach(element => {
                            var obj = {
                                'invoice_code': element['invoice_code'],
                                'u3_fullname': element['u3_fullname'],
                                'delivery_date': element['delivery_date'] ? `${moment(element['delivery_date']).format("DD-MM-YYYY HH:mm:ss")}` : "",
                                'admin_name': element['admin_name'],
                                'invoice_status_name': element['invoice_status_name'],
                                'total_quantity': element['total_quantity'],
                                'invoice_code': element['invoice_code'],
                                'total_price': element['total_price'],
                                'invoice_status': element['invoice_status'],
                                'payment_image_name': element['payment_image_name']
                            }

                            data.push(obj)
                        });
                        this.setState({ reportSearchInvoice: data })
                        this.setState({ showProgress: false })
                    } else {
                        this.showWarn("ไม่พบข้อมูล");
                        this.setState({ showProgress: false })
                    }
                    // this.showSuccess();
                    // this.clearAllDataInput();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }

            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
        })
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div className="p-col-12 p-md-8 p-lg-9"></div>);
    }
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    clearSearchInput() {
        this.setState({ dataTableSelection: null })
        this.setState({ orderCode: null }); //order code from select table
        this.setState({ orderID: null }); //order code from auto complete
        this.setState({ memberUid: null });
        this.setState({ memberName: '' });
        this.setState({ adminName: '' });
        this.setState({ adminUid: '' });
        //this.setState({ memberType: 0 });
        this.setState({ date1: null });
        this.setState({ date2: null });
        this.setState({ status: 0 });
        var empty = {}
        this.state.reportSearchInvoice.push(empty)
        this.setState({ reportSearchInvoice: [] });

        //this.clearAllDataInput();
    }

    //auto complate handle (ค้นหา Order ID)
    suggestOrderID(event) {
        //console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */
        //this.setState({ brandSuggestions: event });
    }
    onOrderIDChanged(code) {
        //console.log(code)
        if (code.trim() != "") {
            this.setState({ orderID: code });
            if (code.length > 1) {
                ServerAPI.autoComplateOrderID(code).then((res) => {
                    console.log(res);
                    this.setState({ orderIDSuggestions: res });
                }).catch((error) => {
                })
            }
        } else this.setState({ orderID: code });
    }
    onSelectOrderID(item) {
        this.setState({ orderID: item.value });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    itemOrderIDTemplate(item) {
        return (
            <div className="p-clearfix">
                {item}
            </div>
        );
    }
    //auto complate handle (ค้นหาสมาชิก)
    suggestMemberName(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }
    itemMemberTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }
    onMemberNameChanged(search_name) {
        console.log(search_name)
        console.log(typeof (search_name))
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwastepro(search_name).then((res) => {
                        console.log(res);
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName: search_name });
                this.setState({ memberUid: null });
            }
    }
    onSelectMemberName(item) {
        this.setState({ memberName: item.value.search_name });
        this.setState({ memberUid: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    //auto complate handle (ค้นหาแอดมิน)
    suggestAdminName(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }
    itemAdminTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }
    onAdminNameChanged(search_name) {
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ adminName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchadminAll(search_name).then((res) => {
                        console.log(res);
                        this.setState({ adminSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ adminName: search_name });
                this.setState({ adminUid: null });
            }
    }
    onSelectAdminName(item) {
        this.setState({ adminName: item.value.search_name });
        this.setState({ adminUid: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    salePriceTemplate(rowData, column) {
        let price = rowData.total_price.toFixed(2);
        return <span>{price}</span>;
    }
    onInvoicingClick() {
        var inValid = false
        if (this.state.dataTableSelection) {
            if (this.state.dataTableSelection.length != 0) {
                this.state.dataTableSelection.forEach(function (item) {
                    if (item.invoice_status != 1) {
                        inValid = true
                    }
                });
                console.log(inValid)
                if (inValid) {
                    this.showWarn("มีบางรายการเคยแจ้งหนี้แล้ว");
                } else {
                    this.updateInvoice(2);
                }
            }
        } else this.showWarn("ไม่พบรายการที่เลือก");
    }
    onPaidClick() {
        var inValid = false
        var inValid2 = false
        if (this.state.dataTableSelection) {
            if (this.state.dataTableSelection.length != 0) {
                this.state.dataTableSelection.forEach(function (item) {
                    if (item.invoice_status != 2 && item.invoice_status != 3) {
                        inValid = true
                    }
                    if (item.invoice_status == 1) {
                        inValid2 = true
                    }
                });
                console.log(inValid)
                if (inValid) {
                    if (inValid2) {
                        this.showWarn("มีบางรายการยังไม่เคยแจ้งหนี้");
                    } else
                        this.showWarn("มีบางรายการเคยชำระหนี้แล้ว");
                } else {
                    this.updateInvoice(88);
                }
            }
        } else this.showWarn("ไม่พบรายการที่เลือก");
    }

    updateInvoice(status) {
        var invoiceStatus = status;
        if (this.state.dataTableSelection)
            if (this.state.dataTableSelection.length != 0) {
                var array = this.state.dataTableSelection;
                for (let index = 0; index < array.length; index++) {
                    const item = array[index];
                    var invoice = {
                        order_code: item.order_code,
                        invoice_code: item.invoice_code,
                        invoice_status: invoiceStatus,
                    }
                    ServerAPI.updateInvoice(invoice).then((res) => {

                        if (res) {
                            var index = res.toString().indexOf("Error:");
                            console.log("find error:" + (index));
                            if (index == -1) {
                                this.showSuccess();
                                this.searchInvoice();
                            } else {
                                this.showWarn();
                            }
                        } else {
                            this.showWarn();
                        }
                    }).catch((error) => {
                    })
                }
            } else {
                this.showWarn("กรุณาเลือกรายการ");
            }
    }

    onImageOpen(path) {
        this.setState({
            imageDialog: true,
            imageUrl: path,
            imageError: false // Reset the error state when opening a new image
        });
    }

    handleImageError() {
        this.setState({ imageError: true });
    }

    imgButton(rowData, column) {

        if (rowData.payment_image_name) {
            const path = `${API_ROOT}/wastepro/getfile/${rowData.payment_image_name}?v=${new Date().getTime()}`;
            return <div>

                <Button
                    type="button"
                    onClick={() => this.onImageOpen(path)}
                    icon="pi pi-images"
                    label="เปิดดูภาพ"
                    className="p-buttong"
                ></Button>
            </div>;
        }
        else return <div></div>;
    }

    toFormatDateTime(dateTime) {
        return <span><Moment format="DD/MM/YYYY">
            {dateTime}
        </Moment> <Moment format="HH:mm">
                {dateTime}
            </Moment></span>;
    }
    to_transfer_date(rowData, column) {
        if (rowData.delivery_date == "") return <div></div>;
        return <div>
            {rowData.delivery_date.split(" ")[0]}
            <br />
            {rowData.delivery_date.split(" ")[1]}
        </div>
    }

    to_requested_date(rowData, column) {
        return this.toFormatDateTime(rowData.pickup_date);
    }

    searchPanel() {
        return (
            <div className="p-col-12">
                <Accordion multiple={true} activeIndex={[0, 1]}>
                    <AccordionTab header="เงื่อนไขค้นหาเพื่อจัดการ Orders">
                        <div className="p-grid " >
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>Order ID :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.orderID}
                                    suggestions={this.state.orderIDSuggestions}
                                    onSelect={this.onSelectOrderID}
                                    completeMethod={this.suggestOrderID.bind(this)}
                                    onChange={(e) => this.onOrderIDChanged(e.value)}
                                    itemTemplate={this.itemOrderIDTemplate.bind(this)}
                                    placeholder="Order ID " />
                            </div>

                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>สมาชิก :	 </label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.memberName}
                                    suggestions={this.state.memberSuggestions}
                                    onSelect={this.onSelectMemberName}
                                    completeMethod={this.suggestMemberName.bind(this)}
                                    onChange={(e) => this.onMemberNameChanged(e.target.value)}
                                    itemTemplate={this.itemMemberTemplate.bind(this)}
                                    placeholder="ระบุรหัสสมาชิก/ชื่อสมาชิก" />
                            </div>
                            <div className="p-col-12 p-lg-4"></div>

                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>ช่วงวันส่งสินค้า</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Calendar value={this.state.date1} dateFormat="dd/mm/yy" showIcon={true} placeholder="เลือกวันที่" onChange={this.onDate1Change} />
                            </div>

                            <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label>ถึง</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Calendar value={this.state.date2} dateFormat="dd/mm/yy" showIcon={true} placeholder="เลือกวันที่" onChange={this.onDate2Change} />
                            </div>
                            <div className="p-col-12 p-lg-4"></div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>สถานะ :</label>
                            </div>

                            <div className="p-col-12 p-md-4 p-lg-3" style={{ alignSelf: 'center' }}>
                                <Dropdown
                                    value={this.state.status}
                                    options={this.state.statusItem}
                                    onChange={(e) => { this.setState({ status: e.value }) }}
                                />
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>ผู้ดูแล :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.adminName}
                                    suggestions={this.state.adminSuggestions}
                                    onSelect={this.onSelectAdminName}
                                    completeMethod={this.suggestAdminName.bind(this)}
                                    onChange={(e) => this.onAdminNameChanged(e.target.value)}
                                    itemTemplate={this.itemAdminTemplate.bind(this)}
                                    placeholder="ระบุรหัสผู้ดูแล/ชื่อผู้ดูแล" />
                            </div>
                            <div className="p-col-12 p-lg-4"></div>
                            <div className="p-col-12">
                                <br />
                                <Button
                                    style={{ marginRight: "20px" }}
                                    label="ค้นหา"
                                    icon="fa fa-search"
                                    iconPos="left"
                                    onClick={this.searchInvoice} />
                                <Button
                                    style={{ backgroundColor: '#f0ad4e' }}
                                    type="submit"
                                    label="ยกเลิก"
                                    onClick={this.clearSearchInput}
                                />
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        )
    }
    tableHeader() {
        return (
            <div className="p-clearfix" >
                <Button label="แจ้งหนี้" icon="fa fa-save" onClick={this.onInvoicingClick} style={{ marginRight: "20px" }} />
                <Button label="ชำระหนี้แล้ว" icon="fa fa-save" onClick={this.onPaidClick} />
            </div>
        );
    }
    reportInvoiceTable() {
        const header = this.tableHeader();
        return (
            <div className="p-col-12">
                <Panel header="รายงานรายการแจ้งหนี้">
                    {header}
                    <DataTable value={this.state.reportSearchInvoice} paginatorPosition="bottom" paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                        responsive={true} selection={this.state.dataTableSelection} onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                        emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)}>
                        <Column selectionMode="multiple" style={{ width: '3em' }} />
                        <Column field="payment_image_name" body={this.imgButton} header="หลักฐานการชำระหนี้" className="p-text-center" style={{ width: '9.5em' }} />
                        <Column field="invoice_code" header="เลขที่" sortable={true} filter={true} />
                        <Column field="u3_fullname" header="ชื่อร้าน" sortable={true} filter={true} />
                        <Column field="delivery_date" body={this.to_transfer_date.bind(this)} header="วันที่ส่ง" sortable={false} filter={true} className="p-text-center" />
                        <Column field="admin_name" header="ผู้ดูแล" sortable={true} filter={true} />
                        <Column field="invoice_status_name" header="สถานะ" sortable={true} filter={true} className="p-text-center" />
                        <Column field="total_quantity" header="ปริมาณส่ง (Kg)" sortable={true} filter={true} className="p-text-right" />
                        <Column body={this.salePriceTemplate} header="ราคาขาย (บาท)" filter={true} className="p-text-right" />
                    </DataTable>
                </Panel>
                <Dialog
                    visible={this.state.imageDialog}
                    header="หลักฐานการชำระหนี้"
                    modal={true}
                    onHide={() => this.setState({ imageDialog: false })}
                >
                    <div className="p-col-12 p-md-12 p-lg-8" style={{ position: 'relative' }}>
                        {this.state.imageError ? (
                            <Card title="Error">
                            <p className="m-0" style={{width: '450px'}}>
                            ไม่สามารถแสดงไฟล์ภาพได้โปรดดาว์นโหลดเพื่อตรวจสอบ
                            </p>
                        </Card>
                        ) : (
                            <img
                                src={this.state.imageUrl}
                                alt="ภาพหลักฐาน"
                                style={{ width: '100%', height: 'auto' }}
                                onError={this.handleImageError}
                            />
                        )}

                    </div>
                    <Button
                        label="ดาว์นโหลดไฟล์"
                        icon="fa fa-eye"
                        iconPos="left"
                        onClick={(e) => window.open(this.state.imageUrl, "_blank")}
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0,
                            margin: '10px'
                        }}
                    />
                </Dialog>
            </div>
        )
    }

    render() {
        const searchPanel = this.searchPanel();
        const invoiceTable = this.reportInvoiceTable();
        const ProgressSpinner = this.showProgressSpinner();
        const {
            dhbUsers,
        } = this.props
        return <div className="p-grid">
            <Growl ref={(el) => this.growl = el} />
            {searchPanel}
            {ProgressSpinner}
            {invoiceTable}
        </div>
    }
}



const mapStateToProps = (state) => ({

    dhbUsers: state.dhbAdmin.dhbUsers
})

const mapDispatchToProps = dispatch => ({

    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch)

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ManageInvoiceContainer)