import React, { useState } from 'react';
import { Panel } from 'primereact/panel';
import { Dropdown } from 'primereact/dropdown';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Chips } from 'primereact/chips';
import { Avatar } from '../../features/widget/Avatar';
import { API_ROOT } from '../../constants/api';
import { getSticker, stickerPrefix } from './utils'

export default function LeftBubble ({message, type, avataImage, userWasteType, datetime, roomId,receiverId}){
    let avatar;
    if(userWasteType == 'administrator'){
      avatar = avataImage
    }else{
      avatar = `${API_ROOT}/${userWasteType}/getfile/${avataImage}`
    }
    let isSticker = false
    if (message.includes(stickerPrefix)) {
      isSticker = true
    }

    return (
    <div className="p-grid" key={roomId}>
      <div className="p-col-12">
      <Avatar src={avatar} styles={styles.avataStyle}/>
      {/* <div
        style={
          styles.container
          // type === 1 ? { alignItems: 'center' } : { alignItems: 'flex-start' },
        }> */}
        {type === 1 && !isSticker && (
          <div style={styles.bubbleContainer}>
            <div style={styles.message}>{message}</div>
          </div>
        )}
        {type === 2 && !isSticker && (
          <div style={styles.imageContainer}>
            {/* <img
              src={{
                uri: `${API_ROOT}/chatmessage/GetImage/${roomId}/${message}`,
                method: 'GET',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('access-token')
                },
              }}
              // style={[styles.image, { height: '70%' }]}
            /> */}
            <img src={`${API_ROOT}/chatmessage/GetImage/${roomId}/${message}`} style={styles.image}/>
          </div>
        )}
        {isSticker && (
          <div style={styles.imageContainer}>
            <img
              src={getSticker(message)}
              style={styles.image}
            />
          </div>
        )}
        <div style={styles.datetimeContainer}>
          <div style={styles.datetime}>{datetime}</div>
        </div>
      </div>
      {/* </div> */}
    </div>
  )
}

const styles = ({
    container: {
      flex: 1,
      flexDirection: 'row',
    },
    bubbleContainer: {
      marginLeft: 55,
      backgroundColor: '#f5f5f5',
      paddingHorizontal: 10,
      paddingVertical: 5,
      borderRadius: 20,
      // maxWidth: '50%',
      width: 'max-content',
    },
    message: {
      fontSize: '18px',
      padding: '0px 10px',
    },
    imageContainer: {
      marginLeft: 10,
    },
    image: {
      borderRadius: 10,
      width: '40%',
      height: '20%',
      float: 'revert',
      marginLeft: '10px',
    },
    datetimeContainer: {
      marginLeft: '12%' + 15,
    },
    datetime: {
      fontSize: '14px',
      color: '#c8c8c8',
      marginLeft: 55,
    },
    avataStyle: {
      borderRadius: '50%',
      width: 50,
      height: 50
    }
  })

