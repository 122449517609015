import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Moment from 'react-moment';
import 'moment-timezone';
import { Panel } from 'primereact/components/panel/Panel';
import { Button } from 'primereact/components/button/Button';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import { AutoComplete } from 'primereact/autocomplete';
import { API_ROOT } from '../../constants/api';
import * as ServerAPI from './service';
import * as DashBoardService from './service'
import { MapMarkers } from "../../features/map/MapMarkers";
import StyledMapWithAnInfoBox from "../../features/map/MapMarkersWasteByLocat";
import moment from 'moment'
import { ExportExcel } from '../ExportExcel/ExportExcel';


class ReportWasteByLocation extends Component {
    static propTypes = {

    }

    static defaultProps = {
    }

    constructor() {
        super();
        this.state = {
            status: 0,
            admin: 0,
            statusItem: [
                { label: 'เลือกทั้งหมด', value: 0 },
                { label: 'ยังไม่ได้แจ้งหนี้', value: 1 },
                { label: 'แจ้งหนี้แล้วรอชำระ', value: 2 },
                { label: 'ชำระแล้วรอตรวจสอบ', value: 3 },
                { label: 'ชำระหนี้แล้ว', value: 88 },
            ],
            statusSearchItem: [
                { label: 'ผู้ขาย', value: 1 },
                { label: 'ผู้ขนส่ง', value: 2 },
                { label: 'ร้านรับซื้อ', value: 3 },
                { label: 'ผู้ดูแลระบบ', value: 4 },
            ],
            activeStatusItem: [
                { label: 'เลือกทั้งหมด', value: 0 },
                { label: 'ใช้งาน', value: 1 },
                { label: 'ไม่ใช้งาน', value: 2 }
            ],
            activeStatusCode: 0,
            showProgress: false,
            orderCode: null,
            orderID: null,
            memberUid: null,
            date1: null,
            date2: null,
            reportSearchWaste: [],
            province: 0,
            district: 0,
            subDistrict: 0,
            provinceCode: 0,
            districtCode: 0,
            subDistrictCode: 0,
            subDistrictList: [],
            districtList: [],
            provinceSelected: "",
            mapProMarkers: [],
            excelDisable: true,
            width: 0,
            height: 0
        };
        this.onDate1Change = this.onDate1Change.bind(this);
        this.onDate2Change = this.onDate2Change.bind(this);
        this.searchReportWasteByLocation = this.searchReportWasteByLocation.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.onSelectOrderID = this.onSelectOrderID.bind(this);
        this.onSelectMemberName = this.onSelectMemberName.bind(this);
        this.onSelectAdminName = this.onSelectAdminName.bind(this);
        this.onInvoicingClick = this.onInvoicingClick.bind(this);
        this.onPaidClick = this.onPaidClick.bind(this);
        this.tableHeader = this.tableHeader.bind(this);
        this.showSuccess = this.showSuccess.bind(this);
        this.showWarn = this.showWarn.bind(this);
        this.updateInvoice = this.updateInvoice.bind(this);
        this.toFormatDateTime = this.toFormatDateTime.bind(this);
        this.onProvinceChanged = this.onProvinceChanged.bind(this);
        this.onDistrictChanged = this.onDistrictChanged.bind(this);
        this.onSubDistrictChanged = this.onSubDistrictChanged.bind(this);
        this.to_created_date = this.to_created_date.bind(this);
        this.reConstruct = this.reConstruct.bind(this);
    }

    componentDidMount() {
        const { dashBoardServiceProps } = this.props
        dashBoardServiceProps.getDashboardUsers();
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ width: window.innerWidth, height: window.innerHeight });
    };

    onDate1Change(e) { //search pickup date start
        //console.log(e.value.toISOString())
        if (this.state.date2 != null) {
            if (e.value <= this.state.date2) {
                this.setState({ date1: e.value })
            }
        } else
            this.setState({ date1: e.value })
    }
    onDate2Change(e) { //search pickup date end
        if (this.state.date1 <= e.value) {
            this.setState({ date2: e.value })
        }
    }
    searchReportWasteByLocation() {
        this.setState({ dataTableSelection: null })
        this.setState({ showProgress: true })
        const formData = new FormData();

        let localDate1 = "";
        let localDate2 = "";
        if (this.state.date1 != null) {
            var date = new Date(this.state.date1.getTime() + 7 * 60 * 60000);
            localDate1 = moment(date).format("YYYY-MM-DD");
        }
        if (this.state.date2 != null) {
            var date = new Date(this.state.date2.getTime() + 7 * 60 * 60000);
            localDate2 = moment(date).format("YYYY-MM-DD");
        }

        formData.set('user_uid', this.state.memberUid || "");
        formData.set('user_waste_type', this.state.memberType || "");
        formData.set('register_date_start', localDate1 || "");
        formData.set('register_date_end', localDate2 || "");
        formData.set('active_status_code', this.state.activeStatusCode || 0);

        if (this.state.subDistrictCode != 0) {
            formData.set('subdistrict_code', this.state.subDistrictCode || 0);
        }
        if (this.state.districtCode != 0) {
            formData.set('district_code', this.state.districtCode || 0);
        }
        if (this.state.provinceCode != 0) {
            formData.set('province_code', this.state.provinceCode || 0);
        }

        ServerAPI.searchReportWasteByLocation(formData).then((res) => {
            console.log("response");
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    console.log(res);
                    if (res.length > 0) {
                        let listMarkers = []
                        let data = []

                        res.forEach(element => {
                            if (element['latitude'] != 0 && element['longitude'] != 0) {
                                var obj = {
                                    'username': element['username'],
                                    'applicant_name': element['applicant_name'],
                                    'waste_type_name': element['waste_type_name'],
                                    'telephone_no': element['telephone_no'],
                                    'register_date': element['register_date'] ? `${moment(element['register_date']).format("DD-MM-YYYY,HH:mm:ss")}` : "",
                                    'province_name': element['province_name'],
                                    'district_name': element['district_name'],
                                    'subdistrict_name': element['subdistrict_name'],
                                    'lat': element['latitude'],
                                    'lng': element['longitude'],
                                }
                                listMarkers.push(obj)
                            }

                            var obj = {
                                'username': element['username'],
                                'applicant_name': element['applicant_name'],
                                'waste_type_name': element['waste_type_name'],
                                'telephone_no': element['telephone_no'],
                                'register_date': element['register_date'] ? `${moment(element['register_date']).format("DD-MM-YYYY,HH:mm:ss")}` : "",
                                'province_name': element['province_name'],
                                'district_name': element['district_name'],
                                'subdistrict_name': element['subdistrict_name'],
                                'latitude': element['latitude'],
                                'longitude': element['longitude'],
                            }

                            data.push(obj)
                        });



                        this.setState({ mapProMarkers: listMarkers })
                        this.setState({ reportSearchWaste: data.reverse() })
                        this.setState({ showProgress: false })
                        this.reConstruct(res)
                    } else {
                        this.showWarn("ไม่พบข้อมูล");
                        this.setState({ showProgress: false })
                        this.setState({ reportSearchWaste: {} })
                    }
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }

            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
        })
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div className="p-col-12 p-md-8 p-lg-9"></div>);
    }
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    clearSearchInput() {
        this.setState({ dataTableSelection: null })
        this.setState({ orderCode: null }); //order code from select table
        this.setState({ orderID: null }); //order code from auto complete
        this.setState({ memberUid: null });
        this.setState({ memberName: '' });
        this.setState({ adminName: '' });
        this.setState({ adminUid: '' });
        this.setState({ memberType: 0 });
        this.setState({ date1: null });
        this.setState({ date2: null });
        this.setState({ status: 0 });
        this.setState({ province: 0 });
        this.setState({ district: 0 });
        this.setState({ subDistrict: 0 });
        this.setState({ provinceCode: 0 });
        this.setState({ districtCode: 0 });
        this.setState({ subDistrictCode: 0 });
        this.setState({ subDistrictList: [] });
        this.setState({ districtList: [] });
        this.setState({ provinceSelected: "" });
        this.setState({ excelDisable: true });
        this.setState({ reportSearchWaste: {} });

        //this.clearAllDataInput();
    }

    //auto complate handle (ค้นหา Order ID)
    suggestOrderID(event) {
        //console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */
        //this.setState({ brandSuggestions: event });
    }
    onOrderIDChanged(code) {
        //console.log(code)
        if (code.trim() != "") {
            this.setState({ orderID: code });
            if (code.length > 1) {
                ServerAPI.autoComplateOrderID(code).then((res) => {
                    console.log(res);
                    this.setState({ orderIDSuggestions: res });
                }).catch((error) => {
                })
            }
        } else this.setState({ orderID: code });
    }
    onSelectOrderID(item) {
        this.setState({ orderID: item.value });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    itemOrderIDTemplate(item) {
        return (
            <div className="p-clearfix">
                {item}
            </div>
        );
    }
    //auto complate handle (ค้นหาสมาชิก)
    suggestMemberName(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }
    itemMemberTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }
    onMemberNameChanged(search_name) {
        console.log(search_name)
        console.log(typeof (search_name))
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwastepro(search_name).then((res) => {
                        console.log(res);
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName: search_name });
                this.setState({ memberUid: null });
            }
    }
    onSelectMemberName(item) {
        this.setState({ memberName: item.value.search_name });
        this.setState({ memberUid: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    //auto complate handle (ค้นหาแอดมิน)
    suggestAdminName(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }
    itemAdminTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }
    onAdminNameChanged(search_name) {
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ adminName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchadminAll(search_name).then((res) => {
                        console.log(res);
                        this.setState({ adminSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ adminName: search_name });
                this.setState({ adminUid: null });
            }
    }
    onSelectAdminName(item) {
        this.setState({ adminName: item.value.search_name });
        this.setState({ adminUid: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }

    onInvoicingClick() {
        var inValid = false
        if (this.state.dataTableSelection) {
            if (this.state.dataTableSelection.length != 0) {
                this.state.dataTableSelection.forEach(function (item) {
                    if (item.invoice_status != 1) {
                        inValid = true
                    }
                });
                console.log(inValid)
                if (inValid) {
                    this.showWarn("มีบางรายการเคยแจ้งหนี้แล้ว");
                } else {
                    this.updateInvoice(2);
                }
            }
        } else this.showWarn("ไม่พบรายการที่เลือก");
    }
    onPaidClick() {
        var inValid = false
        var inValid2 = false
        if (this.state.dataTableSelection) {
            if (this.state.dataTableSelection.length != 0) {
                this.state.dataTableSelection.forEach(function (item) {
                    if (item.invoice_status != 2 && item.invoice_status != 3) {
                        inValid = true
                    }
                    if (item.invoice_status == 1) {
                        inValid2 = true
                    }
                });
                console.log(inValid)
                if (inValid) {
                    if (inValid2) {
                        this.showWarn("มีบางรายการยังไม่เคยแจ้งหนี้");
                    } else
                        this.showWarn("มีบางรายการเคยชำระหนี้แล้ว");
                } else {
                    this.updateInvoice(88);
                }
            }
        } else this.showWarn("ไม่พบรายการที่เลือก");
    }

    updateInvoice(status) {
        var invoiceStatus = status;
        if (this.state.dataTableSelection)
            if (this.state.dataTableSelection.length != 0) {
                var array = this.state.dataTableSelection;
                for (let index = 0; index < array.length; index++) {
                    const item = array[index];
                    var invoice = {
                        order_code: item.order_code,
                        invoice_code: item.invoice_code,
                        invoice_status: invoiceStatus,
                    }
                    ServerAPI.updateInvoice(invoice).then((res) => {

                        if (res) {
                            var index = res.toString().indexOf("Error:");
                            console.log("find error:" + (index));
                            if (index == -1) {
                                this.showSuccess();
                                this.searchReportWasteByLocation();
                            } else {
                                this.showWarn();
                            }
                        } else {
                            this.showWarn();
                        }
                    }).catch((error) => {
                    })
                }
            } else {
                this.showWarn("กรุณาเลือกรายการ");
            }
    }

    imgButton(rowData, column) {

        if (rowData.payment_image_name) {
            const path = `${API_ROOT}/wastepro/getfile/${rowData.payment_image_name}?v=${new Date().getTime()}`;
            return <div>
                <Button
                    label="เปิดดูไฟล์"
                    icon="fa fa-eye"
                    iconPos="left"
                    onClick={(e) => window.open(path, "_blank")} />
            </div>;
        }
        else return <div></div>;
    }

    toFormatDateTime(dateTime) {
        return <span><Moment format="DD/MM/YYYY">
            {dateTime}
        </Moment> <Moment format="HH:mm">
                {dateTime}
            </Moment></span>;
    }
    to_transfer_date(rowData, column) {

        let delivery_date = new Date(rowData.delivery_date);
        let year = delivery_date.getYear();

        if (year == -1899)
            return <span></span>;
        else
            return this.toFormatDateTime(rowData.delivery_date);
    }

    to_requested_date(rowData, column) {
        return this.toFormatDateTime(rowData.pickup_date);
    }

    itemTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }

    onMarkerClicked(e) {
        console.log("onMarkerClicked");
        var coor = e.latLng.toJSON();
        console.log(coor);
    }

    mapChanged(e) {
        console.log(e);
    }

    onProvinceChanged(e) {
        console.log(e);
        this.setState({
            provinceSelected: e.value,
            genAddressProvince: e.value.province_name,
        });
        this.setState({ provinceCode: e.value.provinceCode });
        this.setState({ districtSelected: null });
        this.setState({ subDistrictSelected: null });
        ServerAPI.getDistrict(e.value.provinceCode).then((res) => {
            this.setState({ districtList: res });
        });
    }

    onDistrictChanged(e) {
        this.setState({
            districtSelected: e.value,
            genAddressDistrict: e.value.districtName,
        });
        this.setState({ districtCode: e.value.districtCode });
        this.setState({ subDistrictSelected: null });
        ServerAPI.getSubDistrict(e.value.districtCode).then((res) => {
            this.setState({ subDistrictList: res });
        });
    }

    onSubDistrictChanged(e) {
        this.setState({ subDistrictSelected: e.value });
        this.setState({ subDistrictCode: e.value.subDistrictCode });
    }

    toFormatDateTime(dateTime) {
        let _dateTime = new Date(dateTime);
        let year = _dateTime.getYear();

        if (year == -1899)
            return <div><span></span></div>;
        else
            return <div><Moment format="DD/MM/YYYY">
                {dateTime}
            </Moment><br /><Moment format="HH:mm">
                    {dateTime}
                </Moment></div>;
    }
    to_created_date(rowData, column) {
        if (rowData.register_date == "") return <div></div>;
        return <div>
            {rowData.register_date.split(",")[0]}
            <br />
            {rowData.register_date.split(",")[1]}
        </div>
    }

    reConstruct(listOfOrder) {
        var tmp = []
        const Objmaster = listOfOrder || [];
        if (Objmaster.length > 0)
            for (let i = 0; i < Objmaster.length; i++) {
                const obj = {
                    "ชื่อ-นามสกุล": Objmaster[i].applicant_name,
                    "ประเภทสมาชิก": Objmaster[i].waste_type_name,
                    "เบอร์โทรศัพท์": Objmaster[i].telephone_no,
                    "วันที่ลงทะเบียน": (new Date(Objmaster[i].register_date)).toLocaleDateString(),
                    "จังหวัด": Objmaster[i].province_name,
                    "อำเภอ": Objmaster[i].district_name,
                    "ตำบล": Objmaster[i].subdistrict_name,
                    "Latitude": Objmaster[i].latitude,
                    "Longtitude": Objmaster[i].longitude,
                }
                tmp.push(obj);
            }
        this.setState({ excelData: tmp })
        this.setState({ excelDisable: false })
    }

    searchPanel() {
        const {
            provinces,
        } = this.props;

        return (
            <div className="p-col-12">
                <Accordion multiple={true} activeIndex={[0, 1]}>
                    <AccordionTab header="เงื่อนไขค้นหาค้นหาสมาชิกตามพื้นที่">
                        <div className="p-grid " >
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>สมาชิก :	 </label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.memberName}
                                    suggestions={this.state.memberSuggestions}
                                    onSelect={this.onSelectMemberName}
                                    completeMethod={this.suggestMemberName.bind(this)}
                                    onChange={(e) => this.onMemberNameChanged(e.target.value)}
                                    itemTemplate={this.itemMemberTemplate.bind(this)}
                                    placeholder="ระบุรหัสสมาชิก/ชื่อสมาชิก" />
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label>ประเภทสมาชิก :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-7" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Dropdown
                                    value={this.state.memberType}
                                    options={this.state.statusSearchItem}
                                    onChange={(e) => { this.setState({ memberType: e.value }) }}
                                >
                                </Dropdown>
                            </div>

                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>ช่วงวันที่ลงทะเบียน</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Calendar value={this.state.date1} dateFormat="dd/mm/yy" showIcon={true} placeholder="เลือกวันที่" onChange={this.onDate1Change} />
                            </div>

                            <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label>ถึงวันที่</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Calendar value={this.state.date2} dateFormat="dd/mm/yy" showIcon={true} placeholder="เลือกวันที่" onChange={this.onDate2Change} />
                            </div>
                            <div className="p-col-12 p-lg-4"></div>

                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>จังหวัด :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ alignSelf: 'center' }}>
                                <Dropdown
                                    required={true}
                                    value={this.state.provinceSelected}
                                    options={provinces}
                                    placeholder="เลือกจังหวัด"
                                    optionLabel="province_name"
                                    onChange={this.onProvinceChanged}
                                />
                            </div>


                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>อำเภอ/เขต :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ alignSelf: 'center' }}>
                                <Dropdown
                                    required={true}
                                    value={this.state.districtSelected}
                                    options={this.state.districtList}
                                    placeholder="อำเภอ/เขต"
                                    optionLabel="districtName"
                                    onChange={this.onDistrictChanged}
                                />
                            </div>

                            <div className="p-col-12 p-lg-4"></div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>ตำบล/แขวง :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ alignSelf: 'center' }}>
                                <Dropdown
                                    required={true}
                                    value={this.state.subDistrictSelected}
                                    options={this.state.subDistrictList}
                                    placeholder="ตำบล/แขวง"
                                    optionLabel="subDistrictName"
                                    onChange={this.onSubDistrictChanged}
                                />
                            </div>

                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>สถานะผู้ใช้ :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-7" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Dropdown
                                    value={this.state.activeStatusCode}
                                    options={this.state.activeStatusItem}
                                    onChange={(e) => { this.setState({ activeStatusCode: e.value }) }}
                                >
                                </Dropdown>
                            </div>

                            <div className="p-col-12">
                                <br />
                                <Button
                                    style={{ marginRight: "20px" }}
                                    label="ค้นหา"
                                    icon="fa fa-search"
                                    iconPos="left"
                                    onClick={this.searchReportWasteByLocation} />
                                <Button
                                    style={{ backgroundColor: '#f0ad4e' }}
                                    type="submit"
                                    label="ยกเลิก"
                                    onClick={this.clearSearchInput}
                                />
                            </div>
                        </div>
                    </AccordionTab>
                </Accordion>
            </div>
        )
    }
    tableHeader() {
        return (
            <div className="p-clearfix" >
                <Button label="แจ้งหนี้" icon="fa fa-save" onClick={this.onInvoicingClick} style={{ marginRight: "20px" }} />
                <Button label="ชำระหนี้แล้ว" icon="fa fa-save" onClick={this.onPaidClick} />
            </div>
        );
    }
    reportInvoiceTable() {
        const header = this.tableHeader();
        if (this.state.showProgress) return <div></div>;
        var exportExcel =
            <div style={{ textAlign: 'left' }}>
                <ExportExcel data={this.state.excelData} isDisable={this.state.excelDisable} fileName="รายงานสมาชิกตาม Location" />
            </div>;
        return (
            <div className="p-col-12">
                <Panel header="รายงานสมาชิกตามพื้นที่">
                    <div className="p-col-12" style={{ height: '100%'}}>
                        {/* <MapMarkers
                            onMarkerClicked={this.onMarkerClicked}
                            onBoundsChanged={this.mapChanged}
                            markers={this.state.mapProMarkers}
                            zoom={11}
                        /> */}
                        <StyledMapWithAnInfoBox markers={this.state.mapProMarkers} height={this.state.height} />
                    </div>
                    <br />
                    {exportExcel}
                    <DataTable value={this.state.reportSearchWaste} paginatorPosition="bottom" paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                        responsive={true} selection={this.state.dataTableSelection} onSelectionChange={event => this.setState({ dataTableSelection: event.value })}
                        emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)}>
                        <Column field="applicant_name" header="ชื่อ-สกุล" sortable={true} filter={true} className="p-text-left" />
                        <Column field="waste_type_name" header="ประเภทสมาชิก" sortable={true} filter={true} className="p-text-left" />
                        <Column field="telephone_no" header="เบอร์โทรศัพท์" sortable={true} filter={true} className="p-text-left" />
                        <Column field="register_date" body={this.to_created_date.bind(this)} header="วันที่ลงทะเบียน" sortable={true} filter={true} className="p-text-center" />
                        <Column field="province_name" header="จังหวัด" sortable={true} filter={true} className="p-text-left" />
                        <Column field="district_name" header="อำเภอ" sortable={true} filter={true} className="p-text-left" />
                        <Column field="subdistrict_name" header="ตำบล" sortable={true} filter={true} className="p-text-left" />
                        <Column field="latitude" header="Latitude" sortable={true} filter={true} className="p-text-left" />
                        <Column field="longitude" header="Longtitude" sortable={true} filter={true} className="p-text-left" />
                    </DataTable>
                </Panel>
            </div>
        )
    }

    render() {
        const searchPanel = this.searchPanel();
        const invoiceTable = this.reportInvoiceTable();
        const ProgressSpinner = this.showProgressSpinner();
        const {
            dhbUsers,
        } = this.props

        return <div className="p-grid">
            <Growl ref={(el) => this.growl = el} />
            {searchPanel}
            {ProgressSpinner}
            {invoiceTable}
        </div>
    }
}



const mapStateToProps = (state) => ({
    provinces: state.location.province,
    dhbUsers: state.dhbAdmin.dhbUsers
})

const mapDispatchToProps = dispatch => ({

    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch)

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ReportWasteByLocation)