import * as sticker from '../../constants/sticker'

export const stickerPrefix = '!!!c3leng-sticker-'

export const getSticker = message => {
  const stickerName = message.replace(stickerPrefix, '')
  if (stickerName && sticker[`sticker_${stickerName}`]) {
    return sticker[`sticker_${stickerName}`]
  }
  return null
}
