import React, { Component } from 'react';
import { InputText } from 'primereact/inputtext';
import {InputMask} from 'primereact/inputmask';
import classNames from 'classnames';
import { max } from 'moment';

class CEInputText extends Component {
    static defaultProps = {

        required:false,
        value: '',
        id:null,
        placeholder: '',
        tooltip:'',
        type: 'text',
        className: '',
        style: '',
        regexp: '',
        minlength: '',
        maxlength: '',
        minvalue: '',
        maxvalue: '',
        onChange: () => { },
        onKeyPress: () => { },
        isvalid: () => {  },
        mask:"9999999999999",
        disabled:false

        
    }

    constructor(props) {
      
        super(props)
        this.state = { isvalid: true,value: ''}
        this.validation = this.validation.bind(this);
        this.changed = this.changed.bind(this);
        //this.showCtrl = this.showCtrl.bind(this);
        //regexp = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
        
    }
    componentDidMount() {
       
       
        this.setState({value: this.props.value})
        const _isValid = this.validation(this.props.value);
        this.props.isvalid(_isValid);
        
    }
   
    validation(value){
        var _valid = true;
        const {required, id, regexp,placeholder, type,  className, style,minlength,maxlength,onChange,minvalue,maxvalue} = this.props;     
        
        /* check input required? */
        if(required && (value==''|| value==null))_valid =  false;
        //console.log(type)
        /* check input type */
        if(regexp!='' && regexp!=null && regexp!=undefined){
            var checkValue = new RegExp(regexp);
            const isRegexp = checkValue.test(value);
            if(!isRegexp) {
                _valid = false;
             }
        }
        if(type=='number') {
            var checkNumber = new RegExp("^[0-9]*$");
            const isNumber = checkNumber.test(value);
            console.log(isNumber)
            if(value){
                var index = value.toString().indexOf("e");
                console.log(index)
                if(index!=-1)  _valid = false;
            }
            
            if(!isNumber && value!='') _valid = false;
        }else if(type=='money'){
            var checkNumber = new RegExp("^[0-9]+(\.[0-9]{1,2})?$");
            const isNumber = checkNumber.test(value);
            if(!isNumber) {
               if(value && value!='') _valid = false;
            }
        } else if(type=='telephone') {
            var checkNumber = new RegExp("^[0]{1}[0-9]*$");
            const isNumber = checkNumber.test(value);
            if(!isNumber && value!='' && required) _valid = false;
        }else  if(type=='email'){
            var checkEmail = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");
            const isEmail = checkEmail.test(value);
            console.log("isEmail:"+isEmail)
            if(!isEmail && value!='') _valid = false;
        }

        /* check length */
        if(type!='email'){
            if(minlength!=0 && value.length < Number(minlength) && type!='email') _valid =  false;
            if(maxlength!=0 && value.length > Number(maxlength)  && type!='email') _valid = false;
        }
        if(minvalue!='' && type=='number' && value!=""){
            if( Number(value) < Number(minvalue) ) _valid = false;
        }
        if(maxvalue!='' && type=='number' && value!="" ){
            if( Number(value) > Number(maxvalue) ) _valid = false;
        }
        if(minvalue!='' && type=='money' && value!=""){
            if( Number(value) < Number(minvalue) ) _valid = false;
        }
        if(maxvalue!='' && type=='money' && value!="" ){
            if( Number(value) > Number(maxvalue) ) _valid = false;
        }
        

        return _valid;
    } 
    changed = (e) =>{
        console.log(e.target.value)
        this.setState({value:e.target.value})
        const _isValid = this.validation(e.target.value);
        console.log(_isValid)

        this.props.onChange(e,_isValid);
        this.props.isvalid(_isValid);
        
    }
    
    showCtrl(){
      
      

      const {required,name,mask,tooltip,value, id, placeholder, type,  className, style,minlength,maxlength,onChange,onKeyPress,disabled} = this.props;
  
      var el =  document.getElementById(id);
   
      if(el)  el.classList.remove("p-error");
    
      let _txtTooltip = '';
      if(tooltip=='' || tooltip==null) _txtTooltip = "กรุณากรอก" + placeholder ; else _txtTooltip = tooltip;
      

      let _isValid = this.validation(this.state.value);
      let _className = "";
      //console.log(_isValid)
      if(_isValid ){
        _className =  classNames('p-inputtext p-component',className)
        var _el =  document.getElementById(id);
        if(_el)  el.classList.remove("p-error");
        
      }else{
        _className =  classNames('p-inputtext p-component',className, 'p-error')
        var _el =  document.getElementById(id);
        if(_el)  el.classList.add("p-error");
      }


      
        /*tooltip={_txtTooltip} 
                        tooltipOptions={{event: 'focus',position: 'bottom'}}*/
        if(type=="telephone" || (type=="number" && mask!="")){
            return <InputMask
                id={id} 
                name={name}
                mask={mask}
                className={_className}  
                value={value} 
                placeholder={placeholder} 
                onChange={this.changed}
                disabled={disabled}
                >
            </InputMask>
        }else if (type=="number" && mask==""){
            return <InputText 
                id={id} 
                name={name}
                value={value} 
                placeholder={placeholder} 
                type="number"
                className={_className} 
                onChange={this.changed}
                onKeyPress={onKeyPress}
                disabled={disabled}
            />
        }else{
            return <InputText 
            id={id} 
            name={name}
            value={value} 
            placeholder={placeholder} 
            type="text"
            className={_className} 
            onChange={this.changed}
            onKeyPress={onKeyPress}
            maxLength={maxlength}
            minLength={minlength}
            disabled={disabled}
            />
        }
      
       

    }
    render(){
        const bodyContent = this.showCtrl();
      
        return (
            bodyContent
        )
    }
}

export default CEInputText