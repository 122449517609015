

import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox';


class TransportsView extends Component {

    static defaultProps = {
        orderIdSuggestions: null,

        //Event
        onMarkerMoved: null,
        onMarkerMovedU3:null
    }

    static propTypes = {
        orderIdSuggestions: PropTypes.array,

        //Event
        onMarkerMoved: PropTypes.func,
        onMarkerMovedU3:PropTypes.func
    }

    //>Initial 
    componentDidMount() {
        console.log("TransportsView :componentDidMount")
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("TransportsView :componentWillReceiveProps")
    }

    shouldComponentUpdate(nextProps, nextState) {
        console.log("TransportsView :shouldComponentUpdate")
        return true
    }

    componentWillUpdate(nextProps, nextState) {
        console.log("TransportsView :componentWillUpdate")

    }

    componentDidUpdate(prevProps, prevState) {
        console.log("TransportsView :componentDidUpdate")

    }

    //> Event
    onMarkerMoved = (e) => {
        this.props.onMarkerMoved(e);
    }
    onMarkerMovedU3 = (e)=>this.props.onMarkerMovedU3(e);

    //> Mode render
    renderWastegen = () => {
        return (
            <div></div>
        )
    }
    renderWastetran = () => {
        return (
            <div className="card timeline p-fluid">
                <div className="p-grid">
                    <div className="p-col-12">
                        <label>
                            <i className="fa fa-star" style={{ color: '#3eb839' }}></i>
                            Order Id : {this.props.orderIdSuggestions}
                        </label>
                    </div>
                    <div className="p-col-3">
                        <span className="event-time">ผู้รักษ์โลก</span>
                        <i className="fa fa-map-signs" style={{ color: '#3984b8' }}></i>
                    </div>
                    <div className="p-col-9">
                        <div className="event-content">
                            <img alt="Profile" 
                            style={{width:'30%',height:'30%'}} 
                            className="profile-image image-cropper" src={this.props.imageUrlU1} />
                        </div>

                        <div className="p-col-2"></div>
                        <div className="p-col-10">
                            <span className="event-owner" style={{ color: '#3984b8' }}>{this.props.u1_fullname}</span>
                            <span className="event-text"> {this.props.u1_address}</span>
                        </div>
                        
                        <div className="p-col-12">
                            <span className="event-owner" 
                            style={{ fontWeight: 'bold' }}>แผนที่</span>
                            <MapWithASearchBox
                                zoom={this.props.gmap.mapZoom}
                                center={this.props.gmap.mapCenter}
                                marker={this.props.gmap.marker} />
                        </div>

                    </div>

                    <div className="p-col-3">
                        <span className="event-time">ศูนย์จัดการฯซีซาเล้ง</span>
                        <i className="fa fa-map" style={{ color: '#3eb839' }}></i>
                    </div>
                    <div className="p-col-9">

                        <div className="event-content">
                            <img alt="Profile" 
                            style={{width:'30%',height:'30%'}} 
                            className="profile-image image-cropper" src={this.props.imageUrlU3} />
                        </div>

                        <div className="p-col-2"></div>
                        <div className="p-col-10">
                            <span className="event-owner" style={{ color: '#3eb839' }}>{this.props.u3_fullname}</span>
                            <span className="event-text"> {this.props.u3_address}</span>
                        </div>

                        <div className="p-col-12">
                            <span className="event-owner" 
                            style={{ fontWeight: 'bold' }}>แผนที่</span>
                            <MapWithASearchBox
                                zoom={this.props.gmapU3.mapZoom}
                                center={this.props.gmapU3.mapCenter}
                                marker={this.props.gmapU3.marker} />
                        </div>
                    </div>


                </div>
            </div>
        )
    }
    renderWastepro = () => {
        return (
            <div></div>
        )
    }

    render() {
        console.log("TransportsView :render")
        const {
            mode
        } = this.props

        let renderMode;

        switch (mode) {
            case 'wastegen':
                renderMode = this.renderWastegen()
                break;
            case 'wastetran':
                renderMode = this.renderWastetran()
                break;
            case 'wastepro':
                renderMode = this.renderWastepro()
                break;

            default:
                renderMode = (<div></div>)
                break;
        }

        return (
            <div className="p-grid">
                {renderMode}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(TransportsView)
