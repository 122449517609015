import React, { Component } from 'react';
import { Route, withRouter } from 'react-router-dom';
import Login from "./pages/Login";
import Error from "./pages/Error";
import NotFound from "./pages/NotFound";
import Access from "./pages/Access";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import DeleteAccount from './pages/DeleteAccount';
import { LandingContainer,RegisterSocialLandingContainer ,AppleLandingContainer} from "./components/landing";
import { WastegenContainer } from "./components/wastegen";
import { WastetranContainer } from "./components/wastetran";
import { WasteproContainer } from "./components/wastepro";
import { WastegenRegisContainer, WastetranRegisContainer, WasteproRegisContainer } from "./components/register";
import { AdministratorContainer } from "./components/administrator";
import { TestContainer } from "./components/test";
import PrivateRoute from './PrivateRoute';
import ExampleAvatar from './components/test/ExampleAvatar';
import { WastegenDashboard } from './components/wastegen/WastegenDashboard';

import ExampleCtrl from './components/test/ExampleCtrl';
import ExampleExcel from './components/test/ExampleExcel';
import TestStateLessCtrl from './components/test/TestStateLessCtrl';

import WastgenReportsBuyingPrint from "./components/wastegen/WastgenReportsBuyingPrint";
import WastetranReportsBuyingPrint from "./components/wastetran/WastetranReportsBuyingPrint";
import WasteproReportsBuyingPrint from "./components/wastepro/WasteproReportsBuyingPrint";
import WasteproInvoiceBuyingPrint from "./components/wastepro/WasteproInvoiceBuyingPrint";
import ExamplePDF from './components/test/ExamplePDF';
import FrequentlyAskedQuestions from './pages/FrequentlyAskedQuestions';
import ForgetPassword from './pages/ForgetPassword';
import Landing from './pages/Landing';

class AppWrapper extends Component {
	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			window.scrollTo(0, 0)
		}
	}

	wastegenUrl = 'wastegen'
	wastetranUrl = 'wastetran'
	wasteproUrl = 'wastepro'
	wasteadminUrl = 'administrator'


	render() {
		let urlName = this.props.location.pathname;
		switch (urlName) {
			case "/":
				return <Landing />;
			case "/index":
				return <Route path="/index" component={Landing} />
			case "/login":
				return <Route path="/login" component={Login} />
			case "/error":
				return <Route path="/error" component={Error} />
			case "/notfound":
				return <Route path="/notfound" component={NotFound} />
			case "/access":
				return <Route path="/access" component={Access} />
			case "/callback-apple":
				return <Route path="/callback-apple" component={AppleLandingContainer} />
			case "/register-landing":
					return <Route path="/register-landing" component={RegisterSocialLandingContainer} />
			case "/register-wastegen":
				return <Route path="/register-wastegen" component={WastegenRegisContainer} />
			case "/register-wastetran":
				return <Route path="/register-wastetran" component={WastetranRegisContainer} />
			case "/register-wastepro":
				return <Route path="/register-wastepro" component={WasteproRegisContainer} />
			case "/privacy-policy":
					return <Route path="/privacy-policy" component={PrivacyPolicy} />
			case "/delete-account":
					return <Route path="/delete-account" component={DeleteAccount} />
			case "/faq":
					return <Route path="/faq" component={FrequentlyAskedQuestions} />
			case "/forget-password":
					return <Route path="/forget-password" component={ForgetPassword} />

			case "/test":
				return <Route path="/test" component={TestContainer} />
			case "/test-avatar":
				return <Route path="/test-avatar" component={ExampleAvatar} />

			case "/report-u1":
				return <Route path="/report-u1" component={WastgenReportsBuyingPrint} />
			case "/report-u2":
				return <Route path="/report-u2" component={WastetranReportsBuyingPrint} />
			case "/report-u3":
				return <Route path="/report-u3" component={WasteproReportsBuyingPrint} />
			case "/invoice-u3":
				return <Route path="/invoice-u3" component={WasteproInvoiceBuyingPrint} />
			case "/test-excel":
				return <Route path={urlName} component={ExampleExcel} />
			case "/test-stateless":
				return <Route path={urlName} component={TestStateLessCtrl} />
			case "/test-pdf":
				return <Route path={urlName} component={ExamplePDF} />



			default:

				let checkUserTypeAuthen = localStorage.getItem('user-type');

				//>For Login
				if (checkUserTypeAuthen == this.wastegenUrl) {
					return <PrivateRoute path={urlName} component={WastegenContainer} />
				} else if (checkUserTypeAuthen == this.wastetranUrl) {
					return <PrivateRoute path={urlName} component={WastetranContainer} />
				} else if (checkUserTypeAuthen == this.wasteproUrl) {
					return <PrivateRoute path={urlName} component={WasteproContainer} />
				} else if (checkUserTypeAuthen == this.wasteadminUrl) {
					return <PrivateRoute path={urlName} component={AdministratorContainer} />
				} else {
					return <Landing />;
				}

		}
	}
}

export default withRouter(AppWrapper);