import axios from 'axios';
import { SecureUtils } from '../utils';
// Add a request interceptor
axios.interceptors.request.use(function (config) {
    // Do something before request is sent
   
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
axios.interceptors.response.use(function (response) {
 
    // Do something with response data
  
    return response;
  }, function (error) {
    
    if(error.response.status==401){
        console.log(error.response)
        SecureUtils.clear();
        //window.location.reload(true);
    }
   
    // Do something with response error
    return Promise.reject(error);
  });

export default axios;