import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';

import { InputText } from 'primereact/components/inputtext/InputText';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Card } from 'primereact/card';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Growl } from 'primereact/growl';
import { FileUpload } from 'primereact/fileupload';
import { Dropdown } from 'primereact/dropdown';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox'
import { MapMarkers } from '../../features/map/MapMarkers'
import { Calendar } from 'primereact/calendar';
import { AutoComplete } from 'primereact/autocomplete';
import { Avatar } from '../../features/widget/Avatar';
import { API_ROOT } from '../../constants/api'
import { ProgressSpinner } from 'primereact/progressspinner';
import * as ServerAPI from './service';
import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'
import * as DashBoardService from './service'
import { isEmpty } from '../../utils/validators';
import { CEInputText, CECalendar, CEDropdown,CEPassword } from '../../features/input';
import { CEFileUpload } from '../../features/widget';
import {Dialog} from 'primereact/dialog';
import { RadioButton } from 'primereact/radiobutton';

class ManageLevelAdmin extends Component {
    static propTypes = {

    }
    constructor() {
        super();
        this.state = {
            priceList: [],
            memberStore: 0,
            brandSuggestions: null,
            wasteTypeSelected: '',
            searchProductType: '',
            searchProduct: '',
            productSelected: '',
            priceSelected1: '',
            errorMessageUsername: '',
            messageUsername: '',
            errorMessagePassword: '',
            errorMessageConfirmPassword: '',
            cfpassword: '',

            adminNameList: [],
            memberAdminName:'',
            username:'',
            firstnameAdmin:'',
            lastnameAdmin:'',
            password:'',
            confirmPassword:'',
            phoneNumberAdmin:'',
            emailAdmin:'',
            checkedPasswordVisible: false,
            typePasswordInput: 'password',
            prefixCode_admin:0,

           

            levelAdministrator: [],
           

            person:'',
            personPrefixs: [
                { name: 'นาย', code: 1 },
                { name: 'นาง', code: 2 },
                { name: 'นางสาว', code: 3 },
            ],
            personPrefixs1: [
                { name: 'นาย', code: 1 },
                { name: 'นาง', code: 2 },
                { name: 'นางสาว', code: 3 },
            ],
            
            reportSearchOrders: [],
            showProgress: false,

            errorMessageTelephoneNumber1: '',
            errorMessageTelephoneNumber: '',
            isvalid_telephoneNumber1: false,

        };
        this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
        this.onMemberNameChanged = this.onMemberNameChanged.bind(this);
        this.onMemberName1Changed = this.onMemberName1Changed.bind(this);
        this.onSelectMemberName = this.onSelectMemberName.bind(this);
        this.onSelectMemberName1 = this.onSelectMemberName1.bind(this);
        this.onWasteTypeChange = this.onWasteTypeChange.bind(this);
        this.onsearchProductTypeChange = this.onsearchProductTypeChange.bind(this);
        this.addDataToTable = this.addDataToTable.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.onValidation = this.onValidation.bind(this);
        this.searchProductList = this.searchProductList.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.onWeightUpdate = this.onWeightUpdate.bind(this);
        this.onproductTypeUpdate = this.onproductTypeUpdate.bind(this);
        this.getAdminRoles = this.getAdminRoles.bind(this);
        this.onnameTypeChange = this.onnameTypeChange.bind(this);
        this.onnameTypeChange1 = this.onnameTypeChange1.bind(this);
        this.onTelephoneNumber1Changed = this.onTelephoneNumber1Changed.bind(this);
        this.onTelephoneUpdateChanged = this.onTelephoneUpdateChanged.bind(this);
        
        

    }
    componentDidMount() {
        this.getAdminRoles();
        this.getAdminPermission();
        const { dashBoardServiceProps, locationServiceProps, masterServiceProps,userServiceProps } = this.props
        dashBoardServiceProps.getDashboardUsers();
        locationServiceProps.getProvice();
        masterServiceProps.getProductType();
        userServiceProps.getUserInfo();
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div></div>);
    }

    getAdminPermission() {
        this.setState({ showProgress: true })
        ServerAPI.getAdminPermission().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    var optionItemAdmin = [
                        {role_name: res.admin_lvl_1.role_name ,gid: res.admin_lvl_1.gid },
                        {role_name: res.admin_lvl_2.role_name ,gid: res.admin_lvl_2.gid },
                        {role_name: res.admin_lvl_3.role_name ,gid: res.admin_lvl_3.gid },
                    ]
                    this.setState({ listDropdownAdmin: optionItemAdmin })
                    this.setState({ manageList: res })
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }

    getAdminRoles(){
        this.setState({ showProgress: true })
        ServerAPI.getAdminRoles().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({adminNameList: res })
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false})
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false})
            }
        }).catch((error) => {
            this.setState({ showProgress: false})
        })
    }

    searchProductList(){
        this.setState({ showProgress: true })
        var search = {
            u3_uid: this.state.memberUid1 || '',
            product_code: this.state.searchProductCode || '',
            product_type_code: this.state.searchProductTypeCode || '',
        }
        ServerAPI.searchSalePriceByU3(search).then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({priceList: res })
                    // this.getprocessfee();
                    // this.clearAllDataInput();
                    this.setState({ showProgress: false })
                    this.showSuccess();
                    
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false })
            }
        }).catch((error) => {
            this.setState({ showProgress: false })
        })
    }

    itemTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }

    itemTemplate1(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }

    onSelectMemberName(item) {
        this.setState({ memberName: item.value.search_name });
        this.setState({ memberUid: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    onSelectMemberName1(item) {
        console.log(item)
        this.setState({ memberName1: item.value.search_name });
        this.setState({ memberUid1: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
        console.log(item);
    }
    suggestMemberName(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }
    suggestMemberName1(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }

    onMemberNameChanged(search_name) {
        console.log(search_name)
        console.log(typeof (search_name))
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwastepro(search_name).then((res) => {
                        console.log(res);
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName: search_name });
                this.setState({ memberUid: null });
            }
    }
    onMemberName1Changed(search_name) {
        console.log(search_name)
        console.log(typeof (search_name))
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName1: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwasteAll(search_name).then((res) => {
                        console.log(res);
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName1: search_name });
                this.setState({ memberUid1: null });
            }
    }

    onproductTypeUpdate(e){
        console.log(e)
        this.setState({productTypeObjUpdate: e.value});
    }
    onWeightUpdate(e){
        console.log(e)
        this.setState({productObjUpdate: e.value});
 
    }

    actionTemplate(rowData, column) {
        return <div>
            <Button type="button" onClick={() => this.onproductPriceUpdate(column.rowData)} icon="pi pi-pencil" className="p-button" ></Button>
            {/* <Button type="button" onClick={() => this.onproductPriceDelete(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button> */}
        </div>;
    }
    onClickDeleteItem(rowData) {
        console.log(rowData)
        if (rowData !== '') {
            var id = rowData.gid
            ServerAPI.removesalepricebyu3(id).then((res) => {
                console.log(res);
                if (res) {
                    var index = res.toString().indexOf("Error:");
                    console.log("find error:" + (index));
                    if (index == -1) {
                        this.searchProductList();
                        this.getsalepricebyu3ForValid()
                        this.setState({ showProgress: false })
                        //this.showSuccess();
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false })
                }
            }).catch((error) => {
            })
        }else {
            this.showWarn();
            this.setState({ showProgress: false })
        }

        // if (index !== -1) {
        //     var array = this.state.priceList;
        //     array.splice(index, 1);
        //     this.setState({ priceList: array });
        // }
        
    }
    
    delete() {
        this.setState({ showProgress: true })
        var data = this.state.adminList;
        this.onClickDeleteItem(data);
        // let index = this.findSelectedWeightIndex();
        // this.setState({
        //     cars: this.state.cars.filter((val,i) => i !== index),
        //     selectedCar: null,
        //     car: null,
        this.setState({deleteDialog: false});
    }

    updateProperty(property, value) {
        let adminList = this.state.adminList;
        adminList[property] = value;
        this.setState({adminList: adminList});
    }
   

    onproductPriceUpdate(e){
        console.log(e)
        if(e.prefixCode == 1){
            var prefixCodeAdmin = {
                code: e.prefixCode,
                name: 'นาย',
            }
        }
        if(e.prefixCode == 2){
            var prefixCodeAdmin = {
                code: e.prefixCode,
                name: 'นาง',
            }
        }
        if(e.prefixCode == 3){
            var prefixCodeAdmin = {
                code: e.prefixCode,
                name: 'นางสาว',
            }
        }
        // if(e.role_id == 491){
        //     var levelAdmin = {
        //         role_id: e.role_id,
        //         role_name: e.role_name,
        //     }
        // }
        // if(e.role_id == 493){
        //     var levelAdmin = {
        //         role_id: e.role_id,
        //         role_name: e.role_name,
        //     }
        // }
        // if(e.role_id == 494){
        //     var levelAdmin = {
        //         role_id: e.role_id,
        //         role_name: e.role_name,
        //     }
        // }
        var levelAdmin = {
            gid: e.role_id,
            role_name: e.role_name,
        }
        console.log(levelAdmin);
        console.log(this.state.listDropdownAdmin);
      
        this.setState({
            displayDialog:true,
            adminList: Object.assign({}, e),
            prefixCodeAdmin:  prefixCodeAdmin,
            dropdownlevelAdmin:  levelAdmin,
            telupdate :e.telephoneNumber1,
            errorMessageTelephoneNumber:false,
        });
    }
    onproductPriceDelete(e){
        console.log(e)
        this.setState({
            deleteDialog:true,
            adminList: Object.assign({}, e),
        });
    }
    onWasteTypeChange(e) {
        this.setState({ wasteTypeSelected: e.value });
        this.setState({ productTypeCode: e.value.product_type_code });
        const { masterServiceProps, product } = this.props
        masterServiceProps.getProduct(e.value.product_type_code);

    }
    onsearchProductTypeChange(e) {
        this.setState({ searchProductType: e.value });
        this.setState({ searchProductTypeCode: e.value.product_type_code });
        const { masterServiceProps, product } = this.props
        masterServiceProps.getProduct(e.value.product_type_code);

    }

    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

    update() {
        this.setState({ showProgress: true })
        console.log(this.state.adminList)
        if(this.state.adminList){
            var isNotinput = (
                this.state.adminList.firstName == '' ||
                // this.state.telupdate == '' ||
                this.state.adminList.lastname == '' ||
                // this.state.username == '' ||
                // this.state.passwordAdmin == '' ||
                // this.state.confirmPassword == '' ||
                //this.state.phoneNumberAdmin == '' ||
                this.state.adminList.email == '' ||
                this.state.isNotValid == true
            );
            if (isNotinput && this.state.displayApproveDialog == false ) {
              
                    this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
                    this.setState({ showProgress: false })
                
            } else {
                    var data = {
                        is_verified : this.state.adminList.is_verified, 
                        uid : this.state.adminList.uid,
                        role_id: this.state.dropdownlevelAdmin.gid,
                        role_name: this.state.dropdownlevelAdmin.role_name,
                        prefixCode: this.state.prefixCodeAdmin.code,
                        firstName : this.state.adminList.firstName,
                        lastName : this.state.adminList.lastName,
                        telephoneNumber : this.state.telupdate,
                        email : this.state.adminList.email,
                        username : 'P@ssw0rd',
                        password : 'P@ssw0rd',
                        confirmPassword : 'P@ssw0rd',
                    }
                    //console.log(data)

                        ServerAPI.UpdateAdministratorProfile(data).then((res) => {
                            console.log(res);
                            if (res) {
                                var index = res.toString().indexOf("Error:");
                                console.log("find error:" + (index));
                                if (index == -1) {
                                    this.getAdminRoles();
                                    this.setState({ displayDialog:false});
                                    this.setState({ displayApproveDialog:false});
                                    this.clearAllDataInput();
                                    this.showSuccess();
                                } else {
                                    this.showWarn();
                                    this.setState({ showProgress: false })
                                }
                            } else {
                                this.showWarn();
                                this.setState({ showProgress: false })
                            }
                        }).catch((error) => {
                            this.setState({ showProgress: false })
                        })
                    
                }
        }
    }


    addDataToTable() {
        this.setState({ showProgress: true })
        var isNotinput = (
            this.state.prefixCode_admin == 0||
            this.state.levelAdministrator.gid == 0||
            this.state.levelAdministrator.role_name == ''||
            this.state.username == ''||
            this.state.firstnameAdmin == ''||
            this.state.lastnameAdmin == ''||
            this.state.password == ''||
            this.state.confirmPassword == ''||
            // this.state.phoneNumberAdmin == ''||
            this.state.emailAdmin == ''||
            this.state.isNotValid == true
        );

        if (isNotinput) {
            this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
            this.setState({ showProgress: false })
        } else {
            var admin = this.state.memberAdminName
            var notValid = false;
            this.state.adminNameList.forEach(obj => {
                if (admin == obj.admin){
                    notValid = true;
                }
            });

            if (notValid) {
                this.showWarn("มีแอดมินนี้ซ้ำ! กรุณาเลือกใหม่อีกครั้ง");
                this.setState({ showProgress: false })
            } 
                var data = {
                    //uid: this.state.uid,
                    //u3_uid: this.state.memberUid,
                    //u3_name: this.state.memberName,
                    prefixCode : this.state.prefixCode_admin,
                    role_id : this.state.levelAdministrator.gid,
                    role_name : this.state.levelAdministrator.role_name,
                    username : this.state.username,
                    firstname : this.state.firstnameAdmin,
                    lastname : this.state.lastnameAdmin,
                    password : this.state.password,
                    confirmPassword : this.state.confirmPassword,
                    telephoneNumber : this.state.phoneNumberAdmin,
                    email : this.state.emailAdmin,
                }
                //this.state.adminNameList.push(data)
                this.showSuccess();
                this.setState({ showProgress: false})


                //console.log(data)
                ServerAPI.CreateAdministrator(data).then((res) => {
                    console.log(res);
                    if (res) {
                        var index = res.toString().indexOf("Error:");
                        console.log("find error:" + (index));
                        if (index == -1) {
                            this.getAdminRoles();
                            //this.searchProductList();
                            this.clearAllDataInput();
                            //this.showSuccess();

                        } else {
                            this.showWarn();
                            this.setState({ showProgress: false })
                        }
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false })
                    }
                }).catch((error) => {
                    this.setState({ showProgress: false })
                })
            
        }
        console.log(JSON.stringify(data))
        //console.log(this.state.adminNameList)
    }
    
    
    clearAllDataInput() {
        this.setState({ levelAdministrator:[]})
        this.setState({ lastnameAdmin: '' })
        this.setState({ firstnameAdmin: '' })
        this.setState({ username: '' })
        this.setState({ role_name: '' })
        this.setState({ personPrefixs: [] })
        this.setState({ personPrefix: '' })
        this.setState({ prefixCode_admin: 0 })
        this.setState({ prefixCode: '' })
        this.setState({ password: '' })
        this.setState({ confirmPassword: '' })
        this.setState({ phoneNumberAdmin: '' })
        this.setState({ emailAdmin: '' })
        this.setState({ wasteTypeSelected: '' })
        this.setState({ productSelected: '' })
        this.setState({ priceSelected1: '' })
        this.setState({ memberName: '' })
        this.setState({ memberUid: '' })
        this.setState({ adminList: null })
        this.setState({ productTypeObjUpdate: null })
        this.setState({ productObjUpdate: null })

    }

    clearSearchInput() {
        this.setState({ memberName1: null }); //order code from select table
        this.setState({ memberUid1: null }); 
        this.setState({ searchProductType: null }); //order code from auto complete
        this.setState({ searchProduct: null });
        this.setState({ searchProductTypeCode: null });
        this.setState({ searchProductCode: null });

        console.log(this.state.searchProductType)
        console.log(this.state.searchProduct)
        console.log(this.state.searchProductTypeCode)
        console.log(this.state.searchProductCode)

        this.clearAllDataInput();
    }

    onValidation(valid){
        console.log(valid)
        if(!valid) this.setState({isNotValid: true })
        else this.setState({isNotValid: false })
    }

    onnameTypeChange(e) {
        this.setState({ personPrefix: e.value });
        this.setState({ prefixCode_admin: e.value.code });

    }
    onnameTypeChange1(e) {
        this.setState({ prefixCodeAdmin: e.value });
        this.setState({ prefixCode: e.value.code });

    }

    setPasswordVisible = (e) => {
		console.log(e)
		if (this.state.checkedPasswordVisible) {
			this.setState({ checkedPasswordVisible: false })
			this.setState({ typePasswordInput: 'password' });
		} else {
			this.setState({ checkedPasswordVisible: true })
			this.setState({ typePasswordInput: 'text' });
		}
	}

    onConfirmPasswordChanged(cfpassword) {
        this.setState({ errorMessageConfirmPassword: "" });
        this.setState({ confirmPassword: "" });

        if (this.state.password == cfpassword) {
            this.setState({ confirmPassword: cfpassword });
            this.setState({ errorMessageConfirmPassword: "" });
        } else {
            this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
            this.setState({ confirmPassword: cfpassword });
        }
    }

    onPasswordChanged(password) {
        this.setState({ errorMessagePassword: "" });
        this.setState({ password: password });

        if (password.length >= 6) {
            const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
            const isOk = re.test(password);
            if (!isOk) {
                this.setState({ errorMessagePassword: "ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่" });
            } else {
                this.setState({ password: password });
                this.setState({ errorMessagePassword: "" });

                if (password == this.state.confirmPassword) {
                    this.setState({ errorMessageConfirmPassword: "" });
                } else {
                    this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
                }
            }
        } else {
            this.setState({ errorMessagePassword: "กรุณากรอกอย่างน้อย 6 ตัวอักษร (ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่)" });
        }
    }

    onUsernameChanged(username) {
        this.setState({ errorMessageUsername: "" });
        this.setState({ messageUsername: "" });
        this.setState({ username: username });
        if (username.length >= 6) {
            const re = new RegExp("^[A-Za-z][A-Za-z0-9]*$");
            const isOk = re.test(username);
            if (!isOk){
                this.setState({ errorMessageUsername: "ตัวอักษรภาษาอังกฤษและตัวเลข โดยตัวแรกต้องเป็นภาษาอังกฤษเท่านั้น" });
                this.setState({ messageUsername: "" });
            }
            } else {
                this.setState({ errorMessageUsername: "กรุณากรอกอย่างน้อย 6 ตัวอักษร (ภาษาอังกฤษและตัวเลข)" });
                this.setState({ messageUsername: "" });
            }
    }

    isNotvalidStep2() {
        this.setState({ errorMessageLastStep: "" });
        var inputStep2 = !((this.state.telephoneNumber1 == '') || !this.state.isvalid_telephoneNumber1 || (!this.state.isvalid_email && this.state.email!=""));
        var hasErrorMsg = this.state.errorMessageTelephoneNumber1 != '';
        if (!inputStep2 || hasErrorMsg) {
            this.setState({ errorMessageLastStep: "กรุณากรอกข้อมูลที่มีเครื่องหมาย * ให้ครบถ้วน" });
        } else
            this.setState({ errorMessageLastStep: "" });

        return !inputStep2 || hasErrorMsg;
    }



    onTelephoneNumber1Changed(telephoneNumber,valid) {
        // this.setState({ errorMessageTelephoneNumber1: "" });
        this.setState({ phoneNumberAdmin: telephoneNumber });

        // if (telephoneNumber.length == 10 && valid) {

        //     console.log(this.state.isvalid_telephoneNumber1);
        //     var telNameModel = {
        //         telephoneNumber: telephoneNumber
        //     }
        //     ServerAPI.checkExitPhoneAndName(telNameModel).then((res) => {
        //         console.log(res);
        //         if (res.length > 0) {
        //             this.setState({ errorMessageTelephoneNumber1: "หมายเลข " + telephoneNumber + " มีอยู่แล้วในระบบ" });
        //         } else {
        //             this.setState({ phoneNumberAdmin: telephoneNumber });
        //             this.setState({ errorMessageTelephoneNumber1: "" });
        //         }
        //     }).catch((error) => {
        //         this.setState({ errorMessageTelephoneNumber1: "กรุณาติดต่อผู้ดูแลระบบ" });
        //         this.setState({ messageTelephoneNumber1: "" });
        //     })
        // } else {
        //     this.setState({ errorMessageTelephoneNumber1: "กรุณากรอกหมายเลขโทรศัพท์เคลื่อนที่ 10 ตัวเลข (ขึ้นต้นด้วยเลข 0)" });
        // }
    }

    onTelephoneUpdateChanged(telephoneNumber,valid) {
        // this.setState({ errorMessageTelephoneNumber1: "" });
        this.setState({ telupdate : telephoneNumber });

        // if (telephoneNumber.length == 10 && valid) {

        //     console.log(this.state.isvalid_telephoneNumber1);
        //     var telNameModel = {
        //         telephoneNumber: telephoneNumber
        //     }
        //     ServerAPI.checkExitPhoneAndName(telNameModel).then((res) => {
        //         console.log(res);
        //         if (res.length > 0) {
        //             this.setState({ errorMessageTelephoneNumber: "หมายเลข " + telephoneNumber + " มีอยู่แล้วในระบบ" });
        //         } else {
        //             this.setState({ telupdate : telephoneNumber });
        //             this.setState({ errorMessageTelephoneNumber: "" });
        //         }
        //     }).catch((error) => {
        //         this.setState({ errorMessageTelephoneNumber: "กรุณาติดต่อผู้ดูแลระบบ" });
        //         this.setState({ messageTelephoneNumber1: "" });
        //     })
        // } else {
        //     this.setState({ errorMessageTelephoneNumber: "กรุณากรอกหมายเลขโทรศัพท์เคลื่อนที่ 10 ตัวเลข (ขึ้นต้นด้วยเลข 0)" });
        // }
    }
    
    onStatusUpdate(e){
        console.log(e)
        if(e.prefixCode == 1){
            var prefixCodeAdmin = {
                code: e.prefixCode,
                name: 'นาย',
            }
        }
        if(e.prefixCode == 2){
            var prefixCodeAdmin = {
                code: e.prefixCode,
                name: 'นาง',
            }
        }
        if(e.prefixCode == 3){
            var prefixCodeAdmin = {
                code: e.prefixCode,
                name: 'นางสาว',
            }
        }
        var levelAdmin = {
            gid: e.role_id,
            role_name: e.role_name,
        }
        console.log(levelAdmin);
        console.log(this.state.listDropdownAdmin);
      
        this.setState({
            displayApproveDialog: true,
            adminList: Object.assign({}, e),
            prefixCodeAdmin:  prefixCodeAdmin,
            dropdownlevelAdmin:  levelAdmin,
            telupdate :e.telephoneNumber1,
            errorMessageTelephoneNumber:false,
        });

    }
    statusApproveTemplate(rowData, column) {
        console.log(this.props);
        return <div>
            {rowData.is_verified ? 
            <Button type="button" onClick={() => this.onStatusUpdate(column.rowData)} icon="pi pi-check" label="ตรวจสอบผู้ใช้แล้ว" className="p-button" style={{width: '13em',marginBottom: '0px'}}></Button>
            : <Button type="button" onClick={() => this.onStatusUpdate(column.rowData)} icon="pi pi-times" label="ยังไม่ได้ตรวจสอบผู้ใช้" className="p-button-warning" style={{width: '13em',marginBottom: '0px'}}></Button>
            }
        </div>;
    }

    dialogApprovePanel(){
        let dialogApproveFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="บันทึก" icon="pi pi-check" onClick={this.update}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displayApproveDialog: false})} className="p-button-warning"/>
            </div>;
        return(
        <Dialog dismissableMask={true} visible={this.state.displayApproveDialog} width="300px" header="อนุญาตให้ใช้งานระบบ" modal={true} footer={dialogApproveFooter} onHide={() => this.setState({displayApproveDialog: false})}>
                
                { this.state.adminList &&
                <div className="p-grid" >
                    {/* <div className="p-col-4" style={{ alignSelf: 'center' }}></div> */}
                    <div className="p-col-12" style={{ padding: '1em' }}>
                    <label style={{ marginRight: '5px' }}>สถานะตรวจสอบ : </label>
                        <RadioButton inputId="rb1" name="is_verified" value={true}
                            onChange={(e) => {this.updateProperty('is_verified', e.target.value)}}
                            //onChange={(e) => this.setState({ personType: e.value })}
                            checked={this.state.adminList.is_verified === true}
                            />
                        <label htmlFor="rb1" className="p-radiobutton-label" style={{marginRight: '20px'}}>ตรวจสอบผู้ใช้แล้ว</label>
                        <RadioButton inputId="rb2" name="is_verified" value={false}
                            onChange={(e) => {this.updateProperty('is_verified', e.target.value)}}
                            //onChange={(e) => this.setState({ personType: e.value })}
                            checked={this.state.adminList.is_verified === false} />
                        <label htmlFor="rb2" className="p-radiobutton-label">ยังไม่ได้ตรวจสอบผู้ใช้</label>
                
                    </div>
                </div>
                }
        </Dialog>
        )
    }
    
    showSearchPanel() {
        const {
            productType1,
            product1,
        } = this.props;
        
        return (

            <Accordion multiple={true} activeIndex={[0, 1]}>
                <AccordionTab header="เงื่อนไขค้นหาเพื่อจัดการผู้ดูแลระบบ">
                    <div className="p-grid p-justify-start" >
                        <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                            <label>ค้นหาสมาชิก : </label>
                        </div>
                        <div className="p-col-12 p-md-10 p-lg-11" style={{ textAlign: 'left', alignSelf: 'center' }}>
                            <AutoComplete
                                style={{ width: '20%' }}
                                value={this.state.memberName1}
                                suggestions={this.state.memberSuggestions}
                                onSelect={this.onSelectMemberName1}
                                completeMethod={this.suggestMemberName1.bind(this)}
                                onChange={(e) => this.onMemberName1Changed(e.target.value)}
                                itemTemplate={this.itemTemplate.bind(this)}
                                placeholder="ระบุรหัสสมาชิก/ชื่อสมาชิก" />
                        </div>
                        <div className="p-col-12">
                            <br/>
                            <Button
                                style={{marginRight:"20px"}}
                                label="ค้นหา"
                                icon="fa fa-search"
                                iconPos="left"
                                onClick={this.searchProductList} />
                            <Button
                                style={{ backgroundColor: '#f0ad4e' }}
                                type="submit"
                                label="ยกเลิก"
                                onClick={this.clearSearchInput}
                            />
                        </div>
                    </div>



                </AccordionTab>
            </Accordion>
        )
    }


    addNewAdministratorTab() {
        const {
            productType,
            product,
        } = this.props;

        return (
            <Accordion multiple={true} activeIndex={[0]}>
                <AccordionTab header="เพิ่มผู้ดูแลระบบ">
                    <div className="p-grid">
                    <label className="p-col-12 p-md-2 p-lg-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>คำนำหน้า : </label>
                        <div className="p-col-12 p-md-4 ">
                            <CEDropdown
                                required={true} 
                                isvalid={(valid) => console.log("Dropdown:"+ valid)}
                                className="input-101"
                                value={this.state.personPrefix}
                                // options={this.state.provinceSelectItems} 
                                options={this.state.personPrefixs}
                                //onChange={(e)=> this.setState({person: e.target.value})}
                                onChange={this.onnameTypeChange} 
                                optionLabel="name" 
                                placeholder="เลือกคำนำหน้า" />
                        </div>
                        <label className="p-col-12 p-md-2 p-lg-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>ระดับผู้ดูแลระบบ :</label>
                        <div className="p-col-12 p-md-4 ">
                                <CEDropdown
                                    required={true} 
                                    isvalid={(valid) => console.log("Dropdown:"+ valid)}
                                    className="input-101"
                                    value={this.state.levelAdministrator}
                                    // options={this.state.provinceSelectItems} 
                                    options={this.state.listDropdownAdmin}
                                    onChange={(e)=> this.setState({levelAdministrator: e.target.value})}
                                    //onChange={this.onnameTypeChange} 
                                    optionLabel= "role_name"
                                    placeholder="เลือกระดับผู้ดูแล" />
                        </div>
                        
                        <label className="p-col-12 p-md-2 p-lg-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>ชื่อ : </label>
                        <div className="p-col-12 p-md-4 ">
                            {/* <AutoComplete
                                style={{ width: '20.5%' }}
                                value={this.state.memberName}
                                suggestions={this.state.memberSuggestions}
                                onSelect={this.onSelectMemberName}
                                completeMethod={this.suggestMemberName.bind(this)}
                                onChange={(e) => this.onMemberNameChanged(e.target.value)}
                                itemTemplate={this.itemTemplate.bind(this)}
                                placeholder="ระบุรหัสสมาชิก/ชื่อสมาชิก" /> */}
                                <CEInputText
                                    id="firstnameAdmin"
                                    type="text"
                                    className="input-101"
                                    value={this.state.firstnameAdmin}
                                    name="firstnameAdmin"
                                    placeholder="ระบุ ชื่อ"
                                    //tooltip="กรอก เป็นตัวเลขหรือตัวอักษร"
                                    maxlength={200}
                                    required={true}
                                    onChange={(e)=> this.setState({firstnameAdmin: e.target.value})}
                                    isvalid={this.onValidation}
                                    />
                        </div>
                        
                        <label className="p-col-12 p-md-2 p-lg-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>เบอร์โทร :</label>
                        <div className="p-col-12 p-md-4 ">
                            {/* <InputText style={{ width: '214px'  }} 
                                       value={this.state.phoneNumberAdmin} 
                                       onChange={(e) => this.setState({phoneNumberAdmin: e.target.value})}
                                       placeholder="ระบุ เบอร์โทร" /> */}
                            <span style={{ color: 'red' }}>{this.state.errorMessageTelephoneNumber1} </span>
                            <CEInputText
                                    id="phoneNumberAdmin"
                                    type="telephone"
                                    className="input-101"
                                    value={this.state.phoneNumberAdmin} 
                                    name="phoneNumberAdmin"
                                    placeholder="ระบุ เบอร์โทร"
                                    tooltip="กรอกเป็นตัวเลข 10 ตัวเท่านั้น (ตัวแรกเป็น 0 )"
                                    mask="9999999999"
                                    maxlength={10}
                                    required={false}
                                    onChange={(e,v)=> this.onTelephoneNumber1Changed(e.target.value,v)}
                                    isvalid={(valid) => this.setState({isvalid_telephoneNumber1:valid})}
                                    // onChange={(e)=> this.setState({phoneNumberAdmin: e.target.value})}
                                    // isvalid={this.onValidation}
                                    />
                        </div>
                       
                        
                        <label className="p-col-12 p-md-2 p-lg-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>นามสกุล : </label>
                        <div className="p-col-12 p-md-4 ">
                                <CEInputText
                                    id="lastnameAdmin"
                                    type="text"
                                    className="input-101"
                                    value={this.state.lastnameAdmin}
                                    name="lastnameAdmin"
                                    placeholder="ระบุ นามสกุล"
                                    //tooltip="กรอก เป็นตัวเลขหรือตัวอักษร"
                                    maxlength={200}
                                    required={true}
                                    onChange={(e)=> this.setState({lastnameAdmin: e.target.value})}
                                    isvalid={this.onValidation}
                                    />
                        </div>

                        <label className="p-col-12 p-md-2 p-lg-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>Email :</label>
                        <div className="p-col-12 p-md-4 ">
                            {/* <InputText style={{ width: '214px' }} 
                                       value={this.state.emailAdmin} 
                                       onChange={(e) => this.setState({emailAdmin: e.target.value})}
                                       placeholder="ระบุ Email" /> */}

                            <CEInputText
                                    id="emailAdmin"
                                    type="email"
                                    className="input-101"
                                    value={this.state.emailAdmin}
                                    name="emailAdmin"
                                    placeholder="ระบุ Email"
                                    //tooltip="กรอก เป็นตัวเลขหรือตัวอักษร"
                                    maxlength={200}
                                    required={true}
                                    onChange={(e)=> this.setState({emailAdmin: e.target.value})}
                                    isvalid={this.onValidation}
                                    />
                        </div>
                        <label className="p-col-12 p-md-2 p-lg-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>Username :</label>
                        
                        <div className="p-col-12 p-md-10 ">
                        <span style={{ color: 'red' }}>{this.state.errorMessageUsername} </span>
                        <div className="p-inputgroup" style={{height:'35px'}}>
                            <CEInputText
                                    id="username"
                                    type="text"
                                    className="input-101"
                                    value={this.state.username}
                                    name="username"
                                    placeholder="ระบุ Username"
                                    //tooltip="กรอก เป็นตัวเลขหรือตัวอักษร"
                                    maxlength={200}
                                    required={true}
                                    onChange={(e)=> this.onUsernameChanged( e.target.value)}
                                    isvalid={this.onValidation}
                                    />
                            </div>
                        </div>

                        <label className="p-col-12 p-md-2 p-lg-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>Password :</label>
                        <div className="p-col-12 p-md-10">
                                <span style={{ color: 'red' }}>{this.state.errorMessagePassword} </span>
                                  <div className="p-inputgroup" style={{height:'35px'}}>
                                    <CEPassword
                                            regexp="^(?=.*[a-z])(?=.*[A-Z]).{6,}$"                      
                                            type={this.state.typePasswordInput}
                                            className="input-101"
                                            value={this.state.password}
                                            name="typePasswordInput"
                                            placeholder="รหัสผ่าน"
                                            tooltip={this.state.errorMessagePassword}
                                            minlength={6}
                                            maxlength={100}
                                            required={true}
                                            onChange={(e)=> this.onPasswordChanged(e.target.value)}
                                            isvalid={this.onValidation}
                                    />

                                    <Button
                                        style={{ height: '100%' }}
                                        icon={this.state.checkedPasswordVisible ? "fa fa-eye-slash" : "fa fa-eye"}
                                        className="p-col-12 p-button p-button-secondary"
                                        value={this.state.checkedPasswordVisible}
                                        onClick={this.setPasswordVisible}
                                    />
                                </div>


                        </div>
                        <label className="p-col-12 p-md-2 p-lg-2 " style={{ textAlign: 'left', alignSelf: 'center' }}>Confirm Password :</label>
                       
                        <div className="p-col-12 p-md-10">
                        <span style={{ color: 'red' }}>{this.state.errorMessageConfirmPassword} </span>
                        <div className="p-inputgroup" style={{height:'35px'}}>
                        <CEPassword
                                regexp="^(?=.*[a-z])(?=.*[A-Z]).{6,}$"                      
                                type={this.state.typePasswordInput}
                                className="input-101"
                                value={this.state.confirmPassword}
                                name="typePasswordInput"
                                placeholder="รหัสผ่าน"
                                tooltip={this.state.errorMessagePassword}
                                minlength={6}
                                maxlength={100}
                                required={true}
                                onChange={(e)=> this.onConfirmPasswordChanged(e.target.value)}
                                isvalid={this.onValidation}
                        />
                        </div>
                        </div>
                        <div className="p-col-12 ">
                            <Button
                                style={{ float: "left" }}
                                label="เพิ่มผู้ดูแลระบบ"
                                icon="fa fa-plus"
                                iconPos="left"
                                onClick={this.addDataToTable}
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>

        )
    }


    showWeightTable() {
        const dialogApprovePanel = this.dialogApprovePanel();
        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="บันทึก" icon="pi pi-check" onClick={this.update}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displayDialog: false})} className="p-button-warning"/>
            </div>;
        // let deleteDialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
        //     <Button label="ใช่" icon="pi pi-check" onClick={this.delete}/>
        //     <Button label="ไม่ใช่" icon="pi pi-times" onClick={() => this.setState({deleteDialog: false})} className="p-button-warning"/>
        //     </div>;
        const weightTable = (
            <div className="p-col-12">
                <Panel header="รายการผู้ดูแลระบบ" >
                    <DataTable paginatorPosition="bottom" value={this.state.adminNameList} 
                        selectionMode="single" paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                        selection={this.state.selectedCar1} onSelectionChange={e => this.setState({ selectedCar1: e.value })}
                        responsive={true} emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)} >
                        <Column field="username" header="รหัส" sortable={true} filter={true} />
                        <Column field="full_name" header="ชื่อ-สกุล" sortable={true} filter={true} />
                        <Column field="role_name" header="ระดับผู้ดูแลระบบ" sortable={true} filter={true} className="p-text-center"/>
                        <Column field="telephoneNumber1" header="เบอร์โทร" sortable={true} filter={true} />
                        <Column field="email" header="อีเมล" sortable={true} filter={true} />
                        <Column body={this.statusApproveTemplate.bind(this)} field="is_verified" sortable={true} header="สถานะ" className="p-text-center" style={{width: '15em' }} />
                        <Column field="gid" body={this.actionTemplate.bind(this)} header="จัดการ" className="p-text-center" style={{ width: '4em' }} />
                    </DataTable>
                </Panel>
                {/* <Dialog dismissableMask={true} visible={this.state.deleteDialog} width="300px" header="ยืนยันการลบข้อมูล" modal={true} footer={deleteDialogFooter} onHide={() => this.setState({deleteDialog: false})}>
                        {
                            this.state.adminList &&
                            
                            <div className="p-grid p-fluid" >
                                <div className="p-col-12"><span>คุณต้องการจะลบข้อมูลต่อไปนี้</span></div>
                                <div className="p-col-4" ><label htmlFor="username">รหัสผู้ดูแลระบบ :</label></div>
                                <div className="p-col-8" >
                                    {this.state.username}
                                </div>

                                <div className="p-col-4" ><label htmlFor="firstnameAdmin">ชื่อผู้ดูแลระบบ :</label></div>
                                <div className="p-col-8" >
                                    {this.state.firstnameAdmin}
                                </div>

                                <div className="p-col-4" ><label htmlFor="lastnameAdmin">นามสกุลผู้ดูแลระบบ :</label></div>
                                <div className="p-col-8" >
                                    {this.state.lastnameAdmin}
                                </div>

                                <div className="p-col-4" ><label htmlFor="product_type_name">ระดับผู้ดูแลระบบ :</label></div>
                                <div className="p-col-8" >
                                    {this.state.product_type_name}
                                </div>

                                <div className="p-col-4" ><label htmlFor="phoneNumberAdmin">เบอร์โทร :</label></div>
                                <div className="p-col-8" >
                                    {this.state.phoneNumberAdmin}
                                </div>

                                <div className="p-col-4" ><label htmlFor="emailAdmin">email :</label></div>
                                <div className="p-col-8" >
                                    {this.state.emailAdmin}
                                </div>

                                
                            </div>
                        }
                    </Dialog> */}
                    <Dialog dismissableMask={true} visible={this.state.displayDialog} style={{width: '40vw'}} header="แก้ไขข้อมูล" modal={true} footer={dialogFooter} onHide={() => this.setState({displayDialog: false})}>
                    
                        
                        {
                            this.state.adminList &&
                            <div className="p-grid p-fluid" >
                                {console.log(this.state.adminList)}
                                <div className="p-col-12"><span>คุณต้องการจะแก้ไขข้อมูลต่อไปนี้</span></div>
                                <div className="p-col-3" ><label htmlFor="prefixCode">คำนำหน้า :</label></div>
                                <div className="p-col-9" >
                                    <CEDropdown
                                        required={true} 
                                        isvalid={(valid) => console.log("Dropdown:"+ valid)}
                                        className="input-101"
                                        value={this.state.prefixCodeAdmin}
                                        // options={this.state.provinceSelectItems} 
                                        options={this.state.personPrefixs1}
                                        //onChange={(e)=> this.setState({person: e.target.value})}
                                        onChange={this.onnameTypeChange1} 
                                        optionLabel="name" 
                                        placeholder="เลือกคำนำหน้า" />
                                </div>
                                
                                <div className="p-col-3" ><label htmlFor="firstName">ชื่อ :</label></div>
                                <div className="p-col-9" >
                                    <CEInputText   
                                        id="firstName"
                                        type="text"
                                        value={this.state.adminList.firstName}
                                        name="firstName"
                                        tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                        required={true}
                                        isvalid={this.onValidation}
                                        onChange={(e) => {this.updateProperty('firstName', e.target.value)}}
                                        />
                                </div>

                                 <div className="p-col-3" ><label htmlFor="lastName">นามสกุล:</label></div>
                                <div className="p-col-9">
                                    <CEInputText   
                                        id="lastName"                        
                                        type="text"
                                        value={this.state.adminList.lastName}
                                        name="lastName"
                                        tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                        required={true}
                                        isvalid={this.onValidation}
                                        onChange={(e) => {this.updateProperty('lastName', e.target.value)}}
                                        />
                                </div>

                                
                               
                                <div className="p-col-3" ><label htmlFor="product_type_name">ระดับผู้ดูแลระบบ :</label></div>
                                <div className="p-col-9" >
                                    <CEDropdown
                                        required={true} 
                                        isvalid={(valid) => console.log("Dropdown:"+ valid)}
                                        className="input-101"
                                        value={this.state.dropdownlevelAdmin}
                                        // options={this.state.provinceSelectItems} 
                                        options={this.state.listDropdownAdmin}
                                        onChange={(e)=> this.setState({dropdownlevelAdmin: e.target.value})}
                                        //onChange={this.onnameTypeChange} 
                                        optionLabel= "role_name"
                                        placeholder="เลือกระดับผู้ดูแล" />
                                </div>

                                <div className="p-col-3" ><label htmlFor="phoneNumberAdmin1">เบอร์โทร :</label></div>
                                <div className="p-col-9" >
                                        <span style={{ color: 'red' }}>{this.state.errorMessageTelephoneNumber} </span>
                                        <CEInputText   
                                            id="telephoneNumber1"  
                                            type="telephone"
                                            tooltip="กรอกเป็นตัวเลข 10 ตัวเท่านั้น (ตัวแรกเป็น 0 )"
                                            mask="9999999999"
                                            value={this.state.telupdate}
                                            name="telephoneNumber1"
                                            placeholder="ระบุ เบอร์โทร"
                                            //tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                            maxlength={10}
                                            required={false}
                                            onChange={(e,v)=> this.onTelephoneUpdateChanged(e.target.value,v)}
                                            isvalid={(valid) => this.setState({isvalid_telephoneNumber1:valid})}

                                            // isvalid={this.onValidation}
                                            // onChange={(e) => {this.updateProperty('telephoneNumber1', e.target.value)}}
                                        />
                                </div>

                                <div className="p-col-3" ><label htmlFor="emailAdmin">email :</label></div>
                                <div className="p-col-9" >
                                    
                                        <CEInputText   
                                            id="email"                     
                                            type="email"
                                            value={this.state.adminList.email}
                                            name="email"
                                            placeholder="ระบุ Email"
                                            //tooltip="กรอกน้ำหนัก (ทศนิยม 2 ตำแหน่ง)"
                                            required={true}
                                            isvalid={this.onValidation}
                                            onChange={(e) => {this.updateProperty('email', e.target.value)}}
                                        />
                                </div>

                            </div>
                        }
                    </Dialog>
                    {dialogApprovePanel}
            </div>
        );
        if(!this.state.showProgress)
        return weightTable;
    }

    onSubmit() {

    }

    render() {
       
        const searchPanel = this.showSearchPanel();
        const addnew = this.addNewAdministratorTab();
        const weightTablePanel = this.showWeightTable();
        const showProgress = this.showProgressSpinner();


        return <div className="p-grid ">
            <Growl ref={(el) => this.growl = el} />
            <div className="p-col-12">
                <h1>ตั้งค่าระดับผู้ดูแลระบบ</h1>
            </div>
            {/* {searchPanel} */}
            {addnew}
            {showProgress}
            {weightTablePanel}
            
        </div>
    }
}



const mapStateToProps = (state) => ({
    dhbUsers: state.dhbAdmin.dhbUsers,
    locationMaster: state.location,
    provinces: state.location.province,
    districts: state.location.district,
    subDistricts: state.location.subDistrict,
    cardProvince: state.location.cardProvince,
    cardDistrict: state.location.cardDistrict,
    cardSubDistrict: state.location.cardSubDistrict,
    authentication: state.authentication,
    userInfo: state.userInfo.data,
    userInfoMaster: state.userInfo,
    updateWasteproProfile: state.updateWasteproProfile,
    banks: state.master.banks,
    productType: state.master.productType,
    product: state.master.product,
    productType1: state.master.productType,
    product1: state.master.product,
    getFileWastepro: state.getFileWastepro,
})

const mapDispatchToProps = dispatch => ({

    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    fileServiceProps: bindActionCreators(FileService, dispatch),
    

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ManageLevelAdmin)