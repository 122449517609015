import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { Panel } from "primereact/components/panel/Panel";
import { Checkbox } from "primereact/components/checkbox/Checkbox";
import { Button } from "primereact/components/button/Button";

import { InputText } from "primereact/components/inputtext/InputText";
import { InputTextarea } from "primereact/components/inputtextarea/InputTextarea";
import { DataTable } from "primereact/components/datatable/DataTable";
import { Column } from "primereact/components/column/Column";
import { FullCalendar } from "primereact/fullcalendar";
import { Card } from "primereact/card";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Growl } from "primereact/growl";
import { FileUpload } from "primereact/fileupload";
import { Dropdown } from "primereact/dropdown";
import { MapWithASearchBox } from "../../features/map/MapWithASearchBox";
import { MapMarkers } from "../../features/map/MapMarkers";
import { Calendar } from "primereact/calendar";
import { AutoComplete } from "primereact/autocomplete";
import { Avatar } from "../../features/widget/Avatar";
import { API_ROOT } from "../../constants/api";
import { ProgressSpinner } from "primereact/progressspinner";
import * as ServerAPI from "./service";
import * as LocationService from "../../service/LocationService";
import * as UserService from "../../service/user.service";
import * as MasterService from "../../service/MasterService";
import * as FileService from "../../service/file.service";
import * as DashBoardService from "./service";
import { isEmpty } from "../../utils/validators";
import { CEInputText, CECalendar, CEDropdown } from "../../features/input";
import { CEFileUpload } from "../../features/widget";
import { Dialog } from "primereact/dialog";
import { RadioButton } from "primereact/radiobutton";
import * as Validators from "../../utils/validators";

class ManageEnvironmentalCorporateAccount extends Component {
  static propTypes = {};
  constructor() {
    super();
    this.state = {
      priceList: [],
      memberStore: 0,
      brandSuggestions: null,
      wasteTypeSelected: "",
      searchProductType: "",
      searchProduct: "",
      productSelected: "",
      priceSelected1: "",


      memberStore1: [
        { label: "เลือกประเภทสินค้า", value: 0 },
        { label: "กระดาษ", value: 1 },
        { label: "พลาสติก", value: 2 },
        { label: "ประเภทที่ 2", value: 3 },
      ],
      memberStore3: 0,
      memberStore4: [
        { label: "เลือกสินค้า", value: 0 },
        { label: "กระดาษขาวดำ", value: 1 },
        { label: "กระดาษจับจั๊ว", value: 2 },
        { label: "กระดาษกล่องลัง", value: 3 },
      ],

      testData: [
        {
          nameStore: "ร้านมารวย",
          product_type: "กระดาษ",
          product: "กระดาษขาว",
          buy_per_kk: "10",
        },
        {
          nameStore: "ร้านมาจน",
          product_type: "พลาสติก",
          product: "ขวดน้ำพลาสติก",
          buy_per_kk: " 8",
        },
      ],
      reportSearchOrders: [],
      showProgress: false,
      environmentalDetail: "",
      environmentalDetail1: "",
      environmental_account_no: null,
      environmental_account_no1: null,
      environmental_name: "",
      listAccount: [],

      //Validate
      isValidateError: false,

      //error massage
      errorMessageLastStep: "",

      //bank
      selectedBank: null,
      bankAccountName: "",
      bankAccountNo: "",
      //bank update
      selectedBank1: null,

      // add new account
      telephoneNumber1: "",
      telephoneNumber2: "",
      email: "",
      locationType: 0,
      placeTypes: null,
      provinceSelected: {},
      districtSelected: null,
      districtsSelected: [],
      districtsSelectedUpdate: [],
      subDistrictSelected: null,
      subDistrictsSelected: [],
      subDistrictsSelectedUpdate: [],
      addressDetail: "",
    };
    this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
    this.onMemberName1Changed = this.onMemberName1Changed.bind(this);
    this.onSelectMemberName1 = this.onSelectMemberName1.bind(this);
    this.onproductsChange = this.onproductsChange.bind(this);
    this.onsearchProductChange = this.onsearchProductChange.bind(this);
    this.onWasteTypeChange = this.onWasteTypeChange.bind(this);
    this.onsearchProductTypeChange = this.onsearchProductTypeChange.bind(this);
    this.addDataToTable = this.addDataToTable.bind(this);
    this.clearSearchInput = this.clearSearchInput.bind(this);
    this.onValidation = this.onValidation.bind(this);
    this.getOrganizationAccountDonate = this.getOrganizationAccountDonate.bind(
      this
    );
    this.searchAccountList = this.searchAccountList.bind(this);
    this.getOrganizationAccountDonateForValid = this.getOrganizationAccountDonateForValid.bind(
      this
    );
    this.update = this.update.bind(this);
    this.delete = this.delete.bind(this);
    this.onWeightUpdate = this.onWeightUpdate.bind(this);
    this.onproductTypeUpdate = this.onproductTypeUpdate.bind(this);

    this.ondistrictChange = this.ondistrictChange.bind(this);
    this.onprovinceChanged = this.onprovinceChanged.bind(this);
    this.onSubDistrictChanged = this.onSubDistrictChanged.bind(this);

    this.ondistrictChangeUpdate = this.ondistrictChangeUpdate.bind(this);
    this.onprovinceChangedUpdate = this.onprovinceChangedUpdate.bind(this);
    this.onSubDistrictChangedUpdate = this.onSubDistrictChangedUpdate.bind(
      this
    );

    this.isNotvalidStep2 = this.isNotvalidStep2.bind(this);
    this.isNotvalidStep3 = this.isNotvalidStep3.bind(this);
  }
  componentDidMount() {
    this.getOrganizationAccountDonate();
    this.getOrganizationAccountDonateForValid();
    const {
      dashBoardServiceProps,
      locationServiceProps,
      masterServiceProps,
    } = this.props;
    dashBoardServiceProps.getDashboardUsers();
    locationServiceProps.getProvice();
    masterServiceProps.getProductType();
    masterServiceProps.getBanks();
  }
  showProgressSpinner() {
    const Progress = (
      <div className="p-col-12 p-md-8 p-lg-9">
        <ProgressSpinner
          style={{ width: "80px", height: "80px" }}
          strokeWidth="8"
          fill="#EEEEEE"
          animationDuration="0.5s"
        />
      </div>
    );

    if (this.state.showProgress) {
      return Progress;
    } else return <div />;
  }


  searchAccountList() {
    this.setState({ showProgress: true });
    var search = {
      org_name : this.state.memberName1 || "",
      bank_account_no: this.state.environmental_account_no || "",
    };

    ServerAPI.searchDonateAccount(search)
      .then((res) => {
        console.log(res);
        if (res) {
          var index = res.toString().indexOf("Error:");
          console.log("find error:" + index);
          if (index == -1) {
            this.setState({ listAccount : res });
            // this.getprocessfee();
            // this.clearAllDataInput();
            this.setState({ showProgress: false });
            this.showSuccess();
            
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        } else {
          this.showWarn();
          this.setState({ showProgress: false });
        }
      })
      .catch((error) => {
        this.setState({ showProgress: false });
      });
  }

  getOrganizationAccountDonate() {
    this.setState({ showProgress: true });
    ServerAPI.getOrganizationAccount()
      .then((res) => {
        console.log(res);
        if (res) {
          var index = res.toString().indexOf("Error:");
          console.log("find error:" + index);
          if (index == -1) {
            this.setState({ listAccount: res });
            this.setState({ showProgress: false });
            //this.showSuccess();
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        } else {
          this.showWarn();
          this.setState({ showProgress: false });
        }
      })
      .catch((error) => {
        this.setState({ showProgress: false });
      });
  }

  getOrganizationAccountDonateForValid() {
    ServerAPI.getOrganizationAccount()
      .then((res) => {
        console.log(res);
        if (res) {
          var index = res.toString().indexOf("Error:");
          console.log("find error:" + index);
          if (index == -1) {
            this.setState({ listAccount: res });
            this.setState({ showProgress: false });
            //this.showSuccess();
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        } else {
          this.showWarn();
          this.setState({ showProgress: false });
        }
      })
      .catch((error) => {
        this.setState({ showProgress: false });
      });
  }

  itemTemplate1(item) {
    return <div className="p-clearfix">{item.search_name}</div>;
  }

  onSelectMemberName1(item) {
    console.log('check item for find name');
    console.log(item);

    this.setState({ memberName1: item.value.search_name });
    this.setState({ memberUid1: item.value.uid });
    // this.setState({u1_uid: item.value.uid})
    // this.getProfile(item.value.uid)
    console.log(item);
  }


  suggestMemberName1(event) {
    console.log(event);
    /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

    //this.setState({ brandSuggestions: event });
  }

  onMemberName1Changed(search_name) {
    console.log(search_name);
    console.log(typeof search_name);
    if (typeof search_name != "object")
      if (search_name.trim() != "") {
        this.setState({ memberName1: search_name });
        if (search_name.length >= 2) {
          ServerAPI.searchOrgAll(search_name)
            .then((res) => {
              console.log(res);
              this.setState({ memberSuggestions1: res });
            })
            .catch((error) => {});
        }
      } else {
        this.setState({ memberName1: search_name });
        this.setState({ memberUid1: null });
      }
  }

  update() {
    this.setState({ showProgress: true });
    console.log(this.state.donateDialog);
    if (this.state.donateDialog) {
      if (!this.state.isvalid_email) {
        this.showWarn("กรุณากรอกอีเมลให้ถูกต้อง");
        this.setState({ showProgress: false });
      } else if (
        this.state.donateDialog.org_name == "" ||
        this.state.donateDialog.telephone_no1 == "" ||
        this.state.donateDialog.telephone_no2 == "" ||
        this.state.donateDialog.email == "" ||
        this.state.location_type_code == 0 ||
        this.state.provinceCodeUpdate == "" ||
        this.state.districtCodeUpdate == "" ||
        this.state.subDistrictCodeUpdate == "" ||
        this.state.donateDialog.addr_current_detail == "" ||
        this.state.donateDialog.bank_account_name == "" ||
        this.state.donateDialog.bank_account_no == "" ||
        this.state.bank_code == ""
      ) {
        this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
        this.setState({ showProgress: false });
      } else {
        var data = {
          gid: this.state.donateDialog.gid,
          uid: this.state.donateDialog.uid,
          org_name: this.state.donateDialog.org_name,
          telephone_no1: this.state.donateDialog.telephone_no1,
          telephone_no2: this.state.donateDialog.telephone_no2,
          email: this.state.donateDialog.email,
          location_type_code: this.state.location_type_code,
          addr_current_province_code: this.state.provinceCodeUpdate,
          addr_current_district_code: this.state.districtCodeUpdate,
          addr_current_subdistrict_code: this.state.subDistrictCodeUpdate,
          addr_current_detail: this.state.donateDialog.addr_current_detail,
          bank_account_name: this.state.donateDialog.bank_account_name,
          bank_account_no: this.state.donateDialog.bank_account_no,
          bank_code: this.state.bank_code,
        };

        console.log("data : ", data);

        ServerAPI.updateDonateOrganizationAccount(data)
          .then((res) => {
            console.log(res);
            if (res) {
              var index = res.toString().indexOf("Error:");
              console.log("find error:" + index);
              if (index == -1) {
                this.getOrganizationAccountDonate();
                this.setState({ displayDialog: false });
                //this.clearAllDataInput();
                this.showSuccess();
              } else {
                this.showWarn();
                this.setState({ showProgress: false });
              }
            } else {
              this.showWarn();
              this.setState({ showProgress: false });
            }
          })
          .catch((error) => {
            this.setState({ showProgress: false });
          });
      }
    }
  }

  onproductTypeUpdate(e) {
    console.log(e);
    this.setState({ productTypeObjUpdate: e.value });
  }
  onWeightUpdate(e) {
    console.log(e);
    this.setState({ productObjUpdate: e.value });
  }

  actionTemplate(rowData, column) {
    console.log("column : ", column);
    return (
      <div>
        <Button
          type="button"
          onClick={() => this.ondonateUpdate(column.rowData)}
          icon="pi pi-pencil"
          className="p-button"
        />
        <Button
          type="button"
          onClick={() => this.onAccountDelete(column.rowData)}
          icon="fa fa-trash"
          className="p-button-warning"
        />
      </div>
    );
  }

  onClickDeleteItem(rowData) {
    console.log(rowData);
    if (rowData !== "") {
      var gid = rowData.gid;
      ServerAPI.removeDonateOrganizationAccount(gid)
        .then((res) => {
          console.log(res);
          if (res) {
            var index = res.toString().indexOf("Error:");
            console.log("find error:" + index);
            if (index == -1) {
              //this.searchAccountList();
              this.getOrganizationAccountDonateForValid();
              this.setState({ showProgress: false });
              //this.showSuccess();
            } else {
              this.showWarn();
              this.setState({ showProgress: false });
            }
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        })
        .catch((error) => {});
    } else {
      this.showWarn();
      this.setState({ showProgress: false });
    }

    // if (index !== -1) {
    //     var array = this.state.priceList;
    //     array.splice(index, 1);
    //     this.setState({ priceList: array });
    // }
  }

  delete() {
    this.setState({ showProgress: true });
    var data = this.state.deleteData;
    this.onClickDeleteItem(data);
    // let index = this.findSelectedWeightIndex();
    // this.setState({
    //     cars: this.state.cars.filter((val,i) => i !== index),
    //     selectedCar: null,
    //     car: null,
    this.setState({ deleteDialog: false });
  }

  updateProperty(property, value) {
    let donateDialog = this.state.donateDialog;
    donateDialog[property] = value;
    this.setState({ donateDialog: donateDialog });
  }

  ondonateUpdate(e) {
    console.log(e);
    const { locationServiceProps } = this.props;
    locationServiceProps.getDistrict(e.addr_current_province_code);
    locationServiceProps.getSubDistrict(e.addr_current_district_code);
    const { banks, province, district, subDistrict } = this.props;
    let distselect = {};
    district.forEach((element) => {
      console.log(element);
      if (element.districtCode == e.addr_current_district_code) {
        distselect = element;
      }
    });

    let bankselected = {
      bank_code: e.bank_code,
      bank_name: e.bank_name,
    };
    let provselect = {
      provinceCode: e.addr_current_province_code,
      province_name: e.addr_current_province_name,
    };

    let distselected = {
      districtCode: e.addr_current_district_code,
      districtName: e.add_current_district_name,
    };

    let subdistselect = {
      subDistrictCode: e.addr_current_subdistrict_code,
      subDistrictName: e.addr_current_subdistrict_name,
      districtCode: e.addr_current_district_code,
      districtName: e.add_current_district_name,
      provinceCode: e.addr_current_province_code,
      provinceName: e.addr_current_province_name,
    };

    console.log(provselect);
    console.log(distselected);
    console.log(subdistselect);

    console.log(distselected);
    this.setState({
      displayDialog: true,
      donateDialog: Object.assign({}, e),
      bankselected_update: bankselected,
      location_type_code: e.location_type_code,
      prov_sh_update: provselect,
      dist_sh_update: distselected,
      subdist_sh_update: subdistselect,
      bank_code: e.bank_code,
      provinceCodeUpdate: e.addr_current_province_code,
      districtCodeUpdate: e.addr_current_district_code,
      subDistrictCodeUpdate: e.addr_current_subdistrict_code,
    });
  }
  onAccountDelete(e) {
    console.log(e);
    this.setState({
      deleteDialog: true,
      deleteData: Object.assign({}, e),
    });
  }

  onValidation(valid) {
    console.log(valid);
    if (!valid) this.setState({ isNotValid: true });
    else this.setState({ isNotValid: false });
  }

  onWasteTypeChange(e) {
    this.setState({ wasteTypeSelected: e.value });
    this.setState({ productTypeCode: e.value.product_type_code });
    const { masterServiceProps, product } = this.props;
    masterServiceProps.getProduct(e.value.product_type_code);
  }
  onsearchProductTypeChange(e) {
    this.setState({ searchProductType: e.value });
    this.setState({ searchProductTypeCode: e.value.product_type_code });
    const { masterServiceProps, product } = this.props;
    masterServiceProps.getProduct(e.value.product_type_code);
  }

  showSuccess = (message) => {
    this.growl.show({
      severity: "success",
      summary: "สถานะ",
      detail: message ? message : "ทำรายการสำเร็จ",
    });
  };
  showWarn = (message) => {
    this.growl.show({
      severity: "warn",
      summary: "สถานะ",
      detail: message ? message : "มีบางอย่างผิดพลาด กรุณาตรวจสอบ",
    });
  };

  addDataToTable() {
    this.setState({ showProgress: true });
    if (!this.state.isvalid_email) {
      this.showWarn("กรุณากรอกอีเมลให้ถูกต้อง");
      this.setState({ showProgress: false });
    } else if (
      this.state.environmental_name == "" ||
      this.state.telephoneNumber1 == "" ||
      this.state.telephoneNumber2 == "" ||
      this.state.email == "" ||
      this.state.bankAccountName == "" ||
      this.state.bankAccountNo == "" ||
      this.state.bank_code == null ||
      this.state.locationType == 0 ||
      this.state.districtCode == 0 ||
      this.state.subDistrictCode == 0 ||
      this.state.provinceCode == 0 ||
      this.state.addressDetail == ""
    ) {
      this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
      this.setState({ showProgress: false });
    } else {
      var data = {
        org_name: this.state.environmental_name,
        telephone_no1: this.state.telephoneNumber1,
        telephone_no2: this.state.telephoneNumber2,
        email: this.state.email,
        location_type_code: this.state.locationType,
        addr_current_province_code: this.state.provinceCode,
        addr_current_district_code: this.state.districtCode,
        addr_current_subdistrict_code: this.state.subDistrictCode,
        addr_current_detail: this.state.addressDetail,
        bank_account_name: this.state.bankAccountName,
        bank_account_no: this.state.bankAccountNo,
        bank_code: this.state.bank_code,
      };

      ServerAPI.CreateOrganizationDonateAccount(data)
        .then((res) => {
          console.log(res);
          if (res) {
            var index = res.toString().indexOf("Error:");
            console.log("find error:" + index);
            if (index == -1) {
              this.getOrganizationAccountDonate();
              this.showSuccess();
              this.clearAllDataInput();
            } else {
              this.showWarn();
              this.setState({ showProgress: false });
            }
          } else {
            this.showWarn();
            this.setState({ showProgress: false });
          }
        })
        .catch((error) => {
          this.setState({ showProgress: false });
        });
    }
    this.setState({ showProgress: false });
    console.log(this.state.data);
  }

  onproductsChange(e) {
    this.setState({ productSelected: e.value });
  }
  onsearchProductChange(e) {
    this.setState({ searchProduct: e.value });
    this.setState({ searchProductCode: e.value.product_code });
    this.setState({ searchProductName: e.value.product_name });
  }

  clearAllDataInput() {
    this.setState({
      environmental_name: "",
      telephoneNumber1: "",
      telephoneNumber2: "",
      email: "",
      bankAccountName: "",
      bankAccountNo: "",
      bank_code: null,
      bank_name: null,
      selectedBank: "",
      locationType: 0,
      districtCode: 0,
      placeTypes: null,
      subDistrictCode: 0,
      provinceCode: 0,
      addressDetail: "",
      isNotValid: true,
      provinceSelected: null,
      districtSelected: null,
      subDistrictSelected: null,
    });
  }

  clearSearchInput() {
    this.setState({ memberName1: null }); //order code from select table
    this.setState({ memberUid1: null });
    this.setState({ searchProductType: null }); //order code from auto complete
    this.setState({ searchProduct: null });
    this.setState({ searchProductTypeCode: null });
    this.setState({ searchProductCode: null });

    this.setState({ environmentalDetail: "" });
    this.setState({ environmental_account_no: "" });
    this.setState({ environmental_name: "" });

    console.log(this.state.searchProductType);
    console.log(this.state.searchProduct);
    console.log(this.state.searchProductTypeCode);
    console.log(this.state.searchProductCode);

    this.clearAllDataInput();
  }

  // district SubDistrict province start
  onprovinceChanged(e) {
    const { locationServiceProps } = this.props;
    this.setState({ provinceSelected: e.value });
    this.setState({ districtSelected: null });
    this.setState({ subDistrictSelected: null });
    this.setState({ districtCode: 0 });
    this.setState({ subDistrictCode: 0 });
    locationServiceProps.getDistrict(e.value.provinceCode);
    this.setState({ provinceCode: e.value.provinceCode }, this.isNotvalidStep3);
  }
  ondistrictChange(e) {
    const { locationServiceProps } = this.props;
    this.setState({ districtSelected: e.value });
    this.state.districtsSelected.push(e.value);
    this.setState({ subDistrictSelected: null });
    this.setState({ subDistrictCode: 0 });
    locationServiceProps.getSubDistrict(e.value.districtCode);
    this.setState({ districtCode: e.value.districtCode }, this.isNotvalidStep3);
  }
  onSubDistrictChanged(e) {
    this.setState({ subDistrictSelected: e.value });
    this.state.subDistrictsSelected.push(e.value);
    this.setState(
      { subDistrictCode: e.value.subDistrictCode },
      this.isNotvalidStep3
    );
  }
  // district SubDistrict province end

  //***UPDATE***  district SubDistrict province start
  onprovinceChangedUpdate(e) {
    console.log(e);
    const { locationServiceProps } = this.props;
    this.setState({ prov_sh_update: e.value });
    this.setState({ dist_sh_update: null });
    this.setState({ subdist_sh_update: null });
    this.setState({ districtCodeUpdate: 0 });
    this.setState({ subDistrictCodeUpdate: 0 });
    locationServiceProps.getDistrict(e.value.provinceCode);
    this.setState(
      { provinceCodeUpdate: e.value.provinceCode },
      this.isNotvalidStep3
    );
  }
  ondistrictChangeUpdate(e) {
    console.log(e.value);
    const { locationServiceProps } = this.props;
    this.setState({ dist_sh_update: e.value });
    this.state.districtsSelectedUpdate.push(e.value);
    this.setState({ subdist_sh_update: null });
    this.setState({ subDistrictCodeUpdate: 0 });
    locationServiceProps.getSubDistrict(e.value.districtCode);
    this.setState(
      { districtCodeUpdate: e.value.districtCode },
      this.isNotvalidStep3
    );
  }

  onSubDistrictChangedUpdate(e) {
    console.log(e.value);
    this.setState({ subdist_sh_update: e.value });
    this.state.subDistrictsSelectedUpdate.push(e.value);
    this.setState(
      { subDistrictCodeUpdate: e.value.subDistrictCode },
      this.isNotvalidStep3
    );
  }
  //***UPDATE*** */ district SubDistrict province end

  // ========= Event =========
  onChangeInputText = (e) => {
    const { name, value, required } = e.target;
    this.setState({ [name]: value });
    if (required) {
      if (Validators.isEmpty(value)) {
        this.setState({ isValidateError: true });
      } else {
        this.setState({ isValidateError: false });
      }
    }
  };
  onChanageBank = (e) => {
    const { name, value } = e.target;
    this.setState({ selectedBank: value });
    this.setState({ bank_code: value.bank_code });
  };
  onChanageBank1 = (e) => {
    const { name, value } = e.target;
    this.setState({ bankselected_update: value });
    this.setState({ bank_code: value.bank_code });
  };

  onTelephoneNumber1Changed(telephoneNumber, valid) {
    this.setState({ telephoneNumber1: telephoneNumber });
    if (telephoneNumber.length == 10 && valid) {
      console.log(this.state.isvalid_telephoneNumber1);

      this.setState(
        { telephoneNumber1: telephoneNumber },
        this.isNotvalidStep2
      );
    } else {
      this.setState(
        {
          errorMessageTelephoneNumber1:
            "กรุณากรอกหมายเลขโทรศัพท์เคลื่อนที่ 10 ตัวเลข (ขึ้นต้นด้วยเลข 0)",
        },
        this.isNotvalidStep2
      );
    }
  }

  //valid valid valid   strat
  isNotvalidStep2() {
    this.setState({ errorMessageLastStep: "" });
    var inputStep2 = !(
      this.state.telephoneNumber1 == "" ||
      this.state.locationType == 0 ||
      !this.state.isvalid_telephoneNumber1 ||
      (!this.state.isvalid_email && this.state.email != "")
    );
    var hasErrorMsg = this.state.errorMessageTelephoneNumber1 != "";
    if (!inputStep2 || hasErrorMsg) {
      this.setState({
        errorMessageLastStep: "กรุณากรอกข้อมูลครบถ้วน",
      });
    } else this.setState({ errorMessageLastStep: "" });

    return !inputStep2 || hasErrorMsg;
  }

  isNotvalidStep3() {
    this.setState({ errorMessageLastStep: "" });
    //console.log(this.state.provinceCode+":"+this.state.districtCode+":"+this.state.subDistrictCode)
    var inputStep3 = !(
      this.state.subDistrictCode == 0 ||
      this.state.districtCode == 0 ||
      this.state.provinceCode == 0 ||
      this.state.addressDetail == ""
    );
    if (!inputStep3) {
      this.setState({
        errorMessageLastStep: "กรุณากรอกข้อมูลให้ครบถ้วน",
      });
    } else this.setState({ errorMessageLastStep: "" });
    return !inputStep3;
  }

  //valid valid valid   end

  showSearchPanel() {
    const { productType1, product1 } = this.props;
    return (
      <Accordion multiple={true} 
      activeIndex={[0, 1]}
      >
        <AccordionTab header="เงื่อนไขค้นหาเพื่อจัดการบัญชีองค์กรเกี่ยวกับสิ่งแวดล้อม">
          <div className="p-grid p-justify-start">
            <div
              className="p-col-12 p-md-2 p-lg-2"
              style={{ alignSelf: "center" }}
            >
              <label>ชื่อองค์กร :</label>
            </div>
            <div
              className="p-col-12 p-md-10 p-lg-10"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <AutoComplete
                style={{ width: "20%" }}
                value={this.state.memberName1}
                suggestions={this.state.memberSuggestions1}
                onSelect={this.onSelectMemberName1}
                completeMethod={this.suggestMemberName1.bind(this)}
                onChange={(e) => this.onMemberName1Changed(e.target.value)}
                itemTemplate={this.itemTemplate1.bind(this)}
                placeholder="ระบุชื่อองค์กร"
              />
            </div>
            <div
              className="p-col-12 p-md-2 p-lg-2"
              style={{ alignSelf: "center" }}
            >
              <label>หมายเลขบัญชีธนาคาร :</label>
            </div>
            <div
              className="p-col-12 p-md-10 p-lg-10"
              style={{ textAlign: "left", alignSelf: "center" }}
            >
              <InputText
                style={{ width: "21%" }}
                placeholder="ระบุหมายเลขบัญชี"
                name="environmental_account_no"
                maxlength="13"
                keyfilter="pint"
                value={this.state.environmental_account_no}
                onChange={(e) =>
                  this.setState({ environmental_account_no: e.target.value })
                }
                // onChange={this.onChangeInputText}
              />
            </div>
            <div className="p-col-12">
              <br/>
              <Button
                style={{ marginRight: "20px" }}
                label="ค้นหา"
                icon="fa fa-search"
                iconPos="left"
                onClick={this.searchAccountList}
              />
              <Button
                style={{ backgroundColor: "#f0ad4e" }}
                type="submit"
                label="ยกเลิก"
                onClick={this.clearSearchInput}
              />
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    );
  }

  addNewAccountTab() {
    const { province, district, subDistrict, banks } = this.props;

    return (
      <Accordion multiple={true} >
        <AccordionTab header="เพิ่มบัญชีองค์กรเกี่ยวกับสิ่งแวดล้อม">
          <div className="p-grid">
            <div className="p-col">
              <div className="p-col-12">
                <label>ชื่อองค์กร</label>
              </div>
              <div className="p-col-12 ">
                <InputText
                  className="p-col-12"
                  // style={{ width: '20%' }}
                  placeholder="ระบุชื่อองค์กร"
                  name="environmental_name"
                  value={this.state.environmental_name}
                  onChange={(e) =>
                    this.setState({ environmental_name: e.target.value })
                  }
                  // onChange={this.onChangeInputText}
                />
               
              </div>
            </div>

            <div className="p-col">
              <div className="p-col-12">
                <label>หมายเลขโทรศัพท์ 1</label>
              </div>
              <div className="p-col-12">
                <CEInputText
                  id="telephoneNumber1"
                  type="telephone"
                  className="p-col-12"
                  value={this.state.telephoneNumber1}
                  name="telephoneNumber1"
                  placeholder="ระบุหมายเลขโทรศัพท์ 1"
                  tooltip="กรอกหมายเลขโทรศัพท์ 10 หลัก"
                  mask="9999999999"
                  required={true}
                  onChange={(e) =>
                    this.setState({ telephoneNumber1: e.target.value })
                  }
                  //onChange={(e,v)=> this.onTelephoneNumber1Changed(e.target.value,v)}
                  isvalid={(valid) =>
                    this.setState({ isvalid_telephoneNumber1: valid })
                  }
                />
              </div>
            </div>

            <div className="p-col">
              <div className="p-col-12">
                <label>หมายเลขโทรศัพท์ 2</label>
              </div>
              <div className="p-col-12">
                <CEInputText
                  id="telephoneNumber2"
                  type="text"
                  className="p-col-12"
                  value={this.state.telephoneNumber2}
                  name="telephoneNumber2"
                  placeholder="ระบุหมายเลขโทรศัพท์ 2"
                  tooltip="กรอกหมายเลขโทรศัพท์ 10 หลัก"
                  maxlength={100}
                  required={false}
                  onChange={(e) =>
                    this.setState({ telephoneNumber2: e.target.value })
                  }
                  isvalid={(valid) => console.log(valid)}
                />
              </div>
            </div>

            <div className="p-col">
              <div className="p-col-12">
                <label>อีเมล (e-mail)</label>
              </div>
              <div className="p-col-12">
                <CEInputText
                  id="email"
                  type="email"
                  className="p-col-12"
                  value={this.state.email}
                  name="email"
                  placeholder="ระบุอีเมล"
                  tooltip="กรอก Email"
                  maxlength={45}
                  required={false}
                  onChange={(e) => this.setState({ email: e.target.value })}
                  isvalid={(valid) => this.setState({ isvalid_email: valid })}
                />
              </div>
            </div>
            <div className="p-col-12" />

            <div className="p-col">
              <div className="p-col-12">
                <label>ชื่อบัญชี</label>
              </div>
              <div className="p-col-12">
                <InputText
                  className="p-col-12"
                  placeholder="ระบุชื่อบัญชี"
                  name="bankAccountName"
                  value={this.state.bankAccountName}
                  onChange={(e) =>
                    this.setState({ bankAccountName: e.target.value })
                  }
                  //onChange={this.onChangeInputText}
                />
              </div>
            </div>
            <div className="p-col">
              <div className="p-col-12">
                <label>หมายเลขบัญชี</label>
              </div>
              <div className="p-col-12">
                <InputText
                  className="p-col-12"
                  placeholder="ระบุหมายเลขบัญชี"
                  name="bankAccountNo"
                  maxlength="13"
                  keyfilter="pint"
                  value={this.state.bankAccountNo}
                  onChange={(e) =>
                    this.setState({ bankAccountNo: e.target.value })
                  }
                  // onChange={this.onChangeInputText}
                />
              </div>
            </div>
            <div className="p-col">
              <div className="p-col-12">
                <label>ธนาคาร</label>
              </div>
              <div className="p-col-12">
                <Dropdown
                  style={{ width: "100%" }}
                  optionLabel="bank_name"
                  name="bank_code"
                  value={this.state.selectedBank}
                  options={banks}
                  onChange={this.onChanageBank}
                  placeholder="เลือกธนาคาร"
                />
              </div>
            </div>

            <label
              className="p-col-12 "
              style={{
                textAlign: "left",
                alignSelf: "center",
                marginTop: "10px",
              }}
            >
              ประเภทสถานที่ตั้งองค์กร
            </label>
            <div
              className="p-col-12 p-md-6 p-lg-6"
              style={{ textAlign: "left" }}
            >
              <RadioButton
                inputId="cb1"
                value="1"
                onChange={(e) =>
                  this.setState(
                    { locationType: 1, placeTypes: e.value },
                    this.isNotvalidStep2
                  )
                }
                checked={this.state.placeTypes === "1"}
              />
              <label htmlFor="cb1" className="p-checkbox-label">
                บ้านในโครงการจัดสรร
              </label>
            </div>
            <div
              className="p-col-12 p-md-6 p-lg-6"
              style={{ textAlign: "left" }}
            >
              <RadioButton
                inputId="cb2"
                value="2"
                onChange={(e) =>
                  this.setState(
                    { locationType: 2, placeTypes: e.value },
                    this.isNotvalidStep2
                  )
                }
                checked={this.state.placeTypes === "2"}
              />
              <label htmlFor="cb2" className="p-checkbox-label">
                บ้านพักอาศัยทั่วไป
              </label>
            </div>
            <div
              className="p-col-12 p-md-6 p-lg-6"
              style={{ textAlign: "left" }}
            >
              <RadioButton
                inputId="cb3"
                value="3"
                onChange={(e) =>
                  this.setState(
                    { locationType: 3, placeTypes: e.value },
                    this.isNotvalidStep2
                  )
                }
                checked={this.state.placeTypes === "3"}
              />
              <label htmlFor="cb3" className="p-checkbox-label">
                คอนโดมีเนียม / อพาร์ทเม้นท์
              </label>
            </div>
            <div
              className="p-col-12 p-md-6 p-lg-6"
              style={{ textAlign: "left" }}
            >
              <RadioButton
                inputId="cb4"
                value="4"
                onChange={(e) =>
                  this.setState(
                    { locationType: 4, placeTypes: e.value },
                    this.isNotvalidStep2
                  )
                }
                checked={this.state.placeTypes === "4"}
              />
              <label htmlFor="cb4" className="p-checkbox-label">
                อาคารสำนักงาน / โรงงาน
              </label>
            </div>
            <div
              className="p-col-12 p-md-6 p-lg-6"
              style={{ textAlign: "left" }}
            >
              <RadioButton
                inputId="cb5"
                value="5"
                onChange={(e) =>
                  this.setState(
                    { locationType: 5, placeTypes: e.value },
                    this.isNotvalidStep2
                  )
                }
                checked={this.state.placeTypes === "5"}
              />
              <label htmlFor="cb5" className="p-checkbox-label">
                ร้านค้า / ร้านอาหาร
              </label>
            </div>
            <div
              className="p-col-12 p-md-6 p-lg-6"
              style={{ textAlign: "left" }}
            >
              <RadioButton
                inputId="cb6"
                value="6"
                onChange={(e) =>
                  this.setState(
                    { locationType: 6, placeTypes: e.value },
                    this.isNotvalidStep2
                  )
                }
                checked={this.state.placeTypes === "6"}
              />
              <label htmlFor="cb6" className="p-checkbox-label">
                สถานศึกษา / สถานที่ราชการ
              </label>
            </div>
            <div
              className="p-col-12 p-md-6 p-lg-12"
              style={{ textAlign: "left" }}
            >
              <RadioButton
                inputId="cb7"
                value="7"
                onChange={(e) =>
                  this.setState(
                    { locationType: 7, placeTypes: e.value },
                    this.isNotvalidStep2
                  )
                }
                checked={this.state.placeTypes === "7"}
              />
              <label htmlFor="cb7" className="p-checkbox-label">
                อื่นๆ
              </label>
            </div>

            <div className="p-col">
              <div className="p-col-12">
                <label>จังหวัด</label>
              </div>
              <div className="p-col-12">
                {/* <Dropdown
                                style={{ width: "100%" }}
                                value={this.state.provinceSelected}
                                options={province}
                                onChange={this.onprovinceChanged} 
                                placeholder="เลือกจังหวัด" optionLabel="province_name" /> */}

                <CEDropdown
                  required={true}
                  style={{ width: "100%" }}
                  value={this.state.provinceSelected}
                  options={province}
                  onChange={this.onprovinceChanged}
                  optionLabel="province_name"
                  placeholder="เลือกจังหวัด"
                  isvalid={(valid) => console.log(valid)}
                />
              </div>
            </div>
            <div className="p-col">
              <div className="p-col-12">
                <label>อำเภอ/เขต</label>
              </div>

              <div className="p-col-12" style={{ textAlign: "left" }}>
                <CEDropdown
                  required={true}
                  style={{ width: "100%" }}
                  value={this.state.districtSelected}
                  options={district}
                  onChange={this.ondistrictChange}
                  optionLabel="districtName"
                  placeholder="อำเภอ/เขต"
                  isvalid={(valid) => console.log(valid)}
                />
              </div>
            </div>

            <div className="p-col">
              <div className="p-col-12">
                <label>ตำบล/แขวง</label>
              </div>
              <div className="p-col-12">
                <CEDropdown
                  required={true}
                  style={{ width: "100%" }}
                  value={this.state.subDistrictSelected}
                  options={subDistrict}
                  onChange={this.onSubDistrictChanged}
                  optionLabel="subDistrictName"
                  placeholder="ตำบล/แขวง"
                  isvalid={(valid) => console.log(valid)}
                />
                <div style={{ marginTop: ".5em" }} />
              </div>
            </div>

            <div className="p-col">
              <div className="p-col-12">
                <label>เลขที่บ้าน/ที่อยู่</label>
              </div>
              <div className="p-col-12">
                <CEInputText
                  id="addressDetail"
                  type="text"
                  className="input-100"
                  value={this.state.addressDetail}
                  name="addressDetail"
                  placeholder="ระบุที่อยู่"
                  tooltip="กรอกที่อยู่"
                  maxlength={200}
                  required={true}
                  onChange={(e) =>
                    this.setState(
                      { addressDetail: e.target.value },
                      this.isNotvalidStep3
                    )
                  }
                  isvalid={(valid) => console.log(valid)}
                />
              </div>
            </div>

            <div className="p-col-12" style={{ textAlign: "center" }}>
              <span style={{ color: "red" }}>
                {" "}
                {this.state.errorMessageLastStep}
              </span>
            </div>
            <br />
            <div className="p-col-12 ">
              <Button
                style={{ float: "left" }}
                label="เพิ่มรายการ"
                icon="fa fa-plus"
                iconPos="left"
                onClick={this.addDataToTable}
              />
            </div>
          </div>
        </AccordionTab>
      </Accordion>
    );
  }
  onopenContectDetail(e) {
    console.log(e);
    this.setState({
      contactDetailBodyDialog: true,
      contactBodyData: Object.assign({}, e),
    });
  }
  onopenAddressDetail(e) {
    console.log(e);
    this.setState({
      addressDetailBodyDialog: true,
      AddressBodyData: Object.assign({}, e),
    });
  }
  openContectDetail(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          onClick={() => this.onopenContectDetail(column.rowData)}
          icon="fa fa-address-book"
          label=""
          className="p-button"
        />
      </div>
    );
  }
  openAddressDetail(rowData, column) {
    return (
      <div>
        <Button
          type="button"
          onClick={() => this.onopenAddressDetail(column.rowData)}
          icon="fa fa-map-marker"
          label=""
          className="p-button"
        />
      </div>
    );
  }

  locationType(code) {
    var locationTypeName = "";
    switch (code) {
      case 1:
        locationTypeName = "บ้านในโครงการจัดสรร";
        break;
      case 2:
        locationTypeName = "บ้านพักอาศัยทั่วไป";
        break;
      case 3:
        locationTypeName = "คอนโดมีเนียม / อพาร์ทเม้นท์";
        break;
      case 4:
        locationTypeName = "อาคารสำนักงาน / โรงงาน";
        break;
      case 5:
        locationTypeName = "ร้านค้า / ร้านอาหาร";
        break;
      case 6:
        locationTypeName = "สถานศึกษา / สถานที่ราชการ";
        break;
      case 7:
        locationTypeName = "อื่นๆ";
        break;
    }
    return locationTypeName;
  }

  showWeightTable() {
    const { province, district, subDistrict, banks } = this.props;
    let districtList = [];
    district.forEach((dist) => {
      let item = {
        districtCode: dist.districtCode,
        districtName: dist.districtName,
      };
      districtList.push(item);
    });

    var list = this.state.listAccount;
    console.log(list);
    list.forEach((element, index) => {
      let location_name = this.locationType(element.location_type_code);
      var newlist = {
        gid: element.gid,
        uid: element.uid,
        org_name: element.org_name,
        telephone_no1: element.telephone_no1,
        telephone_no2: element.telephone_no2,
        email: element.email,
        location_type_code: element.location_type_code,
        addr_current_province_code: element.addr_current_province_code,
        addr_current_district_code: element.addr_current_district_code,
        addr_current_subdistrict_code: element.addr_current_subdistrict_code,
        addr_current_province_name: element.addr_current_province_name,
        add_current_district_name: element.add_current_district_name,
        addr_current_subdistrict_name: element.addr_current_subdistrict_name,
        addr_current_detail: element.addr_current_detail,
        bank_account_name: element.bank_account_name,
        bank_account_no: element.bank_account_no,
        bank_code: element.bank_code,
        bank_name: element.bank_name,
        created_date: element.created_date,
        update_date: element.update_date,
        update_by_uid: element.update_by_uid,
        is_deleted: element.is_deleted,
        location_name: location_name,
      };
      list[index] = newlist;
    });
    console.log("list : ", list);

    let dialogFooter = (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="บันทึก" icon="pi pi-check" onClick={this.update} />
        <Button
          label="ยกเลิก"
          icon="pi pi-times"
          onClick={() => this.setState({ displayDialog: false })}
          className="p-button-warning"
        />
      </div>
    );
    let deleteDialogFooter = (
      <div className="ui-dialog-buttonpane p-clearfix">
        <Button label="ใช่" icon="pi pi-check" onClick={this.delete} />
        <Button
          label="ไม่ใช่"
          icon="pi pi-times"
          onClick={() => this.setState({ deleteDialog: false })}
          className="p-button-warning"
        />
      </div>
    );
    const weightTable = (
      <div className="p-col-12">
        <Panel header="รายการบัญชีองค์กรเกี่ยวกับสิ่งแวดล้อม">
          <DataTable value={list} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
            selection={this.state.selectedCar1} onSelectionChange={(e) => this.setState({ selectedCar1: e.value })} responsive={true}
            emptyMessage={
              <div style={{ textAlign: 'center', color: 'red' }}>
                <label>ไม่พบข้อมูล</label>
              </div>
            }
          >
            <Column field="org_name" header="ชื่อองค์กร" sortable={true} filter={true} />
            <Column field="bank_account_name" header="ชื่อบัญชี" sortable={true} filter={true} />
            <Column field="bank_account_no" header="เลขบัญชี" sortable={true} filter={true} />
            <Column field="bank_name" header="ธนาคาร" sortable={true} filter={true} className="p-text-left" />
            <Column field="environmental_account_no1" body={this.openContectDetail.bind(this)} header="ข้อมูลติดต่อ" sortable={true} filter={true} className="p-text-center" style={{ width: "7em" }}/>
            <Column field="environmental_account_no1" body={this.openAddressDetail.bind(this)} header="ข้อมูลที่อยู่" sortable={true} filter={true} className="p-text-center" style={{ width: "7em" }}/>
            <Column field="gid" body={this.actionTemplate.bind(this)} header="จัดการ" className="p-text-center" style={{ width: "10%" }}/>
          </DataTable>
        </Panel>

        <Dialog
          visible={this.state.contactDetailBodyDialog}
          style={{ width: "40vw" }}
          header="ข้อมูลการติดต่อ"
          modal={true}
          onHide={() => this.setState({ contactDetailBodyDialog: false })}
          dismissableMask={true}
        >
          {this.state.contactBodyData && (
            <div className="p-grid">
              <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="telephone_no1">
                  หมายเลขโทรศัพท์ 1 :
                </label>
              </div>
              <div className="p-col-9">
                <strong>{this.state.contactBodyData.telephone_no1}</strong>
              </div>
              <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="telephone_no2">
                  หมายเลขโทรศัพท์ 2 :
                </label>
              </div>
              <div className="p-col-9">
                <strong>{this.state.contactBodyData.telephone_no2}</strong>
              </div>
              <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="email">
                   อีเมล :
                </label>
              </div>
              <div className="p-col-9">
                <strong>{this.state.contactBodyData.email}</strong>
              </div>
              </div>
          )}
        </Dialog>

        <Dialog
          visible={this.state.addressDetailBodyDialog}
          style={{ width: "40vw" }}
          header="ข้อมูลที่อยู่"
          modal={true}
          onHide={() => this.setState({ addressDetailBodyDialog: false })}
          dismissableMask={true}
        >
          {this.state.AddressBodyData && (
            <div className="p-grid p-fluid">
              <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="location_name">
                  สถานที่ตั้งองค์กร :
                </label>
              </div>
              <div className="p-col-9">
                <strong>{this.state.AddressBodyData.location_name}</strong>
              </div>
              <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="telephone_no1">
                   จังหวัด :
                </label>
              </div>
              <div className="p-col-9">
              <strong>{this.state.AddressBodyData.addr_current_province_name}</strong>
              </div>
              <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="telephone_no2">
                  อำเภอ :
                </label>
              </div>
              <div className="p-col-9">
              <strong>{this.state.AddressBodyData.add_current_district_name}</strong>
              </div>
              <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="telephone_no2">
                  ตำบล :
                </label>
              </div>
              <div className="p-col-9">
              <strong>{this.state.AddressBodyData.addr_current_subdistrict_name}</strong>
              </div>
              <div className="p-col-3" style={{textAlign:'right'}}>
                <label htmlFor="email">
                   บ้านเลขที่/ที่อยู่ :
                </label>
              </div>
              <div className="p-col-9">
              <strong>{this.state.AddressBodyData.addr_current_detail}</strong>
              </div>
            </div>
          )}
        </Dialog>

        {/* แก้ไขนะ ---------------------------------------------------------------------------------------------------------- */}
        {/* แก้ไข ---------------------------------------------------------------------------------------------------------- */}
        <Dialog
          visible={this.state.displayDialog}
          style={{ width: "50vw" }}
          header="แก้ไขข้อมูล"
          modal={true}
          footer={dialogFooter}
          onHide={() => this.setState({ displayDialog: false })}
        >
          {this.state.donateDialog && (
            <div className="p-grid p-fluid">
              <div className="p-col-12">
                <span>คุณต้องการจะแก้ไขข้อมูลต่อไปนี้</span>
              </div>
              <div className="p-col">
                <div className="p-col-12">
                  <label>ชื่อองค์กร</label>
                </div>
                <div className="p-col-12 ">
                  <InputText
                    className="p-col-12"
                    placeholder="ระบุชื่อองค์กร"
                    name="org_name"
                    value={this.state.donateDialog.org_name}
                    onChange={(e) => {
                      this.updateProperty("org_name", e.target.value);
                    }}

                    // onChange={(e) => this.setState({ environmental_name: e.target.value })}
                  />
                </div>
              </div>

              <div className="p-col">
                <div className="p-col-12">
                  <label>หมายเลขโทรศัพท์ 1</label>
                </div>
                <div className="p-col-12">
                  <CEInputText
                    id="telephone_no1"
                    type="telephone"
                    className="p-col-12"
                    value={this.state.donateDialog.telephone_no1}
                    name="telephone_no1"
                    placeholder="ระบุหมายเลขโทรศัพท์ 1"
                    tooltip="กรอกหมายเลขโทรศัพท์ 10 หลัก"
                    mask="9999999999"
                    required={true}
                    onChange={(e) => {
                      this.updateProperty("telephone_no1", e.target.value);
                    }}
                    //onChange={(e,v)=> this.onTelephoneNumber1Changed(e.target.value,v)}
                    isvalid={(valid) =>
                      this.setState({ isvalid_telephoneNumber1: valid })
                    }
                  />
                </div>
              </div>

              <div className="p-col">
                <div className="p-col-12">
                  <label>หมายเลขโทรศัพท์ 2</label>
                </div>
                <div className="p-col-12">
                  <CEInputText
                    id="telephone_no2"
                    type="text"
                    className="p-col-12"
                    value={this.state.donateDialog.telephone_no2}
                    name="telephone_no2"
                    placeholder="ระบุหมายเลขโทรศัพท์มือถือ 2"
                    tooltip="กรอกหมายเลขโทรศัพท์ 10 หลัก"
                    maxlength={100}
                    required={false}
                    onChange={(e) => {
                      this.updateProperty("telephone_no2", e.target.value);
                    }}
                    isvalid={(valid) => console.log(valid)}
                  />
                </div>
              </div>

              <div className="p-col-12">
                <div className="p-col-12">
                  <label>อีเมล (e-mail)</label>
                </div>
                <div className="p-col-12">
                  <CEInputText
                    id="email"
                    type="email"
                    className="p-col-12"
                    value={this.state.donateDialog.email}
                    name="email"
                    placeholder="ระบุอีเมล"
                    tooltip="กรอก Email"
                    maxlength={45}
                    required={false}
                    onChange={(e) => {
                      this.updateProperty("email", e.target.value);
                    }}
                    isvalid={(valid) => this.setState({ isvalid_email: valid })}
                  />
                </div>
              </div>

              <div className="p-col-12" />
              <div className="p-col">
                <div className="p-col-12">
                  <label>ชื่อบัญชี</label>
                </div>
                <div className="p-col-12">
                  <InputText
                    id="bank_account_name"
                    className="p-col-12"
                    placeholder="ระบุชื่อบัญชี"
                    name="bank_account_name"
                    value={this.state.donateDialog.bank_account_name}
                    onChange={(e) => {
                      this.updateProperty("bank_account_name", e.target.value);
                    }}
                    //onChange={this.onChangeInputText}
                  />
                </div>
              </div>
              <div className="p-col">
                <div className="p-col-12">
                  <label>หมายเลขบัญชี</label>
                </div>
                <div className="p-col-12">
                  <InputText
                    id="bank_account_no"
                    className="p-col-12"
                    placeholder="ระบุหมายเลขบัญชี"
                    name="bank_account_no"
                    maxlength="13"
                    keyfilter="pint"
                    value={this.state.donateDialog.bank_account_no}
                    onChange={(e) => {
                      this.updateProperty("bank_account_no", e.target.value);
                    }}
                    // onChange={this.onChangeInputText}
                  />
                </div>
              </div>
              <div className="p-col">
                <div className="p-col-12">
                  <label>ธนาคาร</label>
                </div>
                <div className="p-col-12">
                  <Dropdown
                    style={{ width: "100%" }}
                    optionLabel="bank_name"
                    name="bank_name"
                    value={this.state.bankselected_update}
                    options={banks}
                    onChange={this.onChanageBank1}
                    placeholder="เลือกธนาคาร"
                  />
                </div>
              </div>

              <label
                className="p-col-12 "
                style={{
                  textAlign: "left",
                  alignSelf: "center",
                  marginTop: "10px",
                }}
              >
                ประเภทสถานที่ตั้งองค์กร
              </label>
              <div
                className="p-col-12 p-md-6 p-lg-6"
                style={{ textAlign: "left" }}
              >
                <RadioButton
                  inputId="cb8"
                  value="8"
                  onChange={(e) =>
                    this.setState(
                      { location_type_code: 1 },
                      this.isNotvalidStep2
                    )
                  }
                  checked={this.state.location_type_code == "1"}
                />
                <label htmlFor="cb8" className="p-checkbox-label">
                  บ้านในโครงการจัดสรร
                </label>
              </div>
              <div
                className="p-col-12 p-md-6 p-lg-6"
                style={{ textAlign: "left" }}
              >
                <RadioButton
                  inputId="cb9"
                  value="9"
                  onChange={(e) =>
                    this.setState(
                      { location_type_code: 2 },
                      this.isNotvalidStep2
                    )
                  }
                  checked={this.state.location_type_code == "2"}
                />
                <label htmlFor="cb9" className="p-checkbox-label">
                  บ้านพักอาศัยทั่วไป
                </label>
              </div>
              <div
                className="p-col-12 p-md-6 p-lg-6"
                style={{ textAlign: "left" }}
              >
                <RadioButton
                  inputId="cb10"
                  value="10"
                  onChange={(e) =>
                    this.setState(
                      { location_type_code: 3 },
                      this.isNotvalidStep2
                    )
                  }
                  checked={this.state.location_type_code == "3"}
                />
                <label htmlFor="cb10" className="p-checkbox-label">
                  คอนโดมีเนียม / อพาร์ทเม้นท์
                </label>
              </div>
              <div
                className="p-col-12 p-md-6 p-lg-6"
                style={{ textAlign: "left" }}
              >
                <RadioButton
                  inputId="cb11"
                  value="11"
                  onChange={(e) =>
                    this.setState(
                      { location_type_code: 4 },
                      this.isNotvalidStep2
                    )
                  }
                  checked={this.state.location_type_code == "4"}
                />
                <label htmlFor="cb11" className="p-checkbox-label">
                  อาคารสำนักงาน / โรงงาน
                </label>
              </div>
              <div
                className="p-col-12 p-md-6 p-lg-6"
                style={{ textAlign: "left" }}
              >
                <RadioButton
                  inputId="cb12"
                  value="12"
                  onChange={(e) =>
                    this.setState(
                      { location_type_code: 5 },
                      this.isNotvalidStep2
                    )
                  }
                  checked={this.state.location_type_code == "5"}
                />
                <label htmlFor="cb12" className="p-checkbox-label">
                  ร้านค้า / ร้านอาหาร
                </label>
              </div>
              <div
                className="p-col-12 p-md-6 p-lg-6"
                style={{ textAlign: "left" }}
              >
                <RadioButton
                  inputId="cb13"
                  value="13"
                  onChange={(e) =>
                    this.setState(
                      { location_type_code: 6 },
                      this.isNotvalidStep2
                    )
                  }
                  checked={this.state.location_type_code == "6"}
                />
                <label htmlFor="cb13" className="p-checkbox-label">
                  สถานศึกษา / สถานที่ราชการ
                </label>
              </div>
              <div
                className="p-col-12 p-md-6 p-lg-12"
                style={{ textAlign: "left" }}
              >
                <RadioButton
                  inputId="cb14"
                  value="14"
                  onChange={(e) =>
                    this.setState(
                      { location_type_code: 7 },
                      this.isNotvalidStep2
                    )
                  }
                  checked={this.state.location_type_code == "7"}
                />
                <label htmlFor="cb14" className="p-checkbox-label">
                  อื่นๆ
                </label>
              </div>

              {console.log("province_name", this.state.prov_sh_update)}
              {console.log("province_name", province)}
              <div className="p-col">
                <div className="p-col-12">
                  <label>จังหวัด</label>
                </div>
                <div className="p-col-12">
                  <CEDropdown
                    required={true}
                    style={{ width: "100%" }}
                    value={this.state.prov_sh_update}
                    options={province}
                    onChange={this.onprovinceChangedUpdate}
                    optionLabel="province_name"
                    placeholder="เลือกจังหวัด"
                    isvalid={(valid) => console.log(valid)}
                  />
                </div>
              </div>

              {console.log("districtName", this.state.dist_sh_update)}
              {console.log("districtName", districtList)}
              <div className="p-col">
                <div className="p-col-12">
                  <label>อำเภอ/เขต</label>
                </div>
                <div className="p-col-12" style={{ textAlign: "left" }}>
                  <CEDropdown
                    required={true}
                    style={{ width: "100%" }}
                    value={this.state.dist_sh_update}
                    options={districtList}
                    onChange={this.ondistrictChangeUpdate}
                    optionLabel="districtName"
                    placeholder="อำเภอ/เขต"
                    isvalid={(valid) => console.log(valid)}
                  />
                </div>
              </div>

              {console.log("subDistrictName", this.state.subdist_sh_update)}
              {console.log("subDistrictName", subDistrict)}
              <div className="p-col">
                <div className="p-col-12">
                  <label>ตำบล/แขวง</label>
                </div>
                <div className="p-col-12">
                  <CEDropdown
                    required={true}
                    style={{ width: "100%" }}
                    value={this.state.subdist_sh_update}
                    options={subDistrict}
                    onChange={this.onSubDistrictChangedUpdate}
                    optionLabel="subDistrictName"
                    placeholder="ตำบล/แขวง"
                    isvalid={(valid) => console.log(valid)}
                  />
                  <div style={{ marginTop: ".5em" }} />
                </div>
              </div>

              <div className="p-col">
                <div className="p-col-12">
                  <label>เลขที่บ้าน/ที่อยู่</label>
                </div>
                <div className="p-col-12">
                  <CEInputText
                    id="addr_current_detail"
                    type="text"
                    className="input-100"
                    value={this.state.donateDialog.addr_current_detail}
                    name="addr_current_detail"
                    placeholder="ระบุที่อยู่"
                    tooltip="กรอกที่อยู่"
                    maxlength={200}
                    required={true}
                    onChange={(e) => {
                      this.updateProperty(
                        "addr_current_detail",
                        e.target.value
                      );
                    }}
                    isvalid={(valid) => console.log(valid)}
                  />
                </div>
              </div>
            </div>
          )}
        </Dialog>
        {/* แก้ไขนะ ---------------------------------------------------------------------------------------------------------- */}
        {/* แก้ไข ---------------------------------------------------------------------------------------------------------- */}

        <Dialog
          visible={this.state.deleteDialog}
          style={{ width: "30vw" }}
          header="ยืนยันการลบข้อมูล"
          modal={true}
          footer={deleteDialogFooter}
          onHide={() => this.setState({ deleteDialog: false })}
        >
          {this.state.deleteData && (
            <div className="p-grid p-fluid">
              <div className="p-col-12" style={{textAlign:'center'}}>
                <i className="pi pi-exclamation-triangle p-mr-3" style={{fontSize: '3rem'}} />
              </div>
              <div className="p-col-12" style={{textAlign:'center'}}>
                <span>คุณต้องการจะลบข้อมูลบัญชีองค์กรนี้ใช่หรือไม่</span>
              </div>
              <div className="p-col-12" style={{textAlign:'center'}}>
                <label htmlFor="environmental_name" >องค์กร : {this.state.deleteData.org_name}</label>
              </div>
            </div>
          )}
        </Dialog>
      </div>
    );
    if (!this.state.showProgress) return weightTable;
  }

  onSubmit() {}

  render() {
    const searchPanel = this.showSearchPanel();
    const addnew = this.addNewAccountTab();
    const weightTablePanel = this.showWeightTable();
    const showProgress = this.showProgressSpinner();

    return (
      <div className="p-grid ">
        <h1>ตั้งค่าบัญชีองค์กรสิ่งแวดล้อม</h1>
        <Growl ref={(el) => (this.growl = el)} />
        {searchPanel}
        {addnew}
        {showProgress}
        {weightTablePanel}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  province: state.location.province,
  district: state.location.district,
  subDistrict: state.location.subDistrict,

  dhbUsers: state.dhbAdmin.dhbUsers,
  locationMaster: state.location,
  cardProvince: state.location.cardProvince,
  cardDistrict: state.location.cardDistrict,
  cardSubDistrict: state.location.cardSubDistrict,
  authentication: state.authentication,
  userInfo: state.userInfo.data,
  userInfoMaster: state.userInfo,
  updateWasteproProfile: state.updateWasteproProfile,
  banks: state.master.banks,
  productType: state.master.productType,
  product: state.master.product,
  productType1: state.master.productType,
  product1: state.master.product,
  getFileWastepro: state.getFileWastepro,
});

const mapDispatchToProps = (dispatch) => ({
  dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
  locationServiceProps: bindActionCreators(LocationService, dispatch),
  userServiceProps: bindActionCreators(UserService, dispatch),
  masterServiceProps: bindActionCreators(MasterService, dispatch),
  fileServiceProps: bindActionCreators(FileService, dispatch),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  null,
  { forwardRef: true }
)(ManageEnvironmentalCorporateAccount);
