import React, { Component } from 'react';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';

export const DatatableWasteproDashView = ({ data, header }) => {

    return (
        <div className="p-grid">
            <div className="p-col-12">
                <div className="content-section implementation">
                    <DataTable 
                    value={data} 
                    responsive={true} 
                    header={header}
                    emptyMessage={
                        (<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                        </div>)
                    }
                    >
                        <Column field="order" header="รายการ" sortable={true} />
                        <Column className="p-text-right" field="amount" header="ยอด (บาท)" sortable={true} />
                        <Column className="p-text-right" field="unit" header="ปริมาณ (Kg)" sortable={true} />
                        <Column className="p-text-right" field="price" header="ราคา/หน่วย (บาท)" sortable={true} />
                    </DataTable>
                </div>
            </div>
        </div>
    )
}