import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'

import { InputText } from 'primereact/inputtext';
import { Button } from "primereact/button";
import * as UserService from '../service/user.service';
import { ProgressSpinner } from 'primereact/progressspinner';
import FacebookLogin from 'react-facebook-login';
import { GoogleLogin } from 'react-google-login';
import { ToggleButton } from 'primereact/togglebutton';
import { CEInputText, CECalendar, CEDropdown, CEPassword } from '../features/input';
import { useRouteMatch } from 'react-router-dom';
import { Growl } from 'primereact/growl';


class ForgetPassword extends Component {

	constructor(props) {
		super(props);

		this.state = {
			username: '',
			password: '',
			confirmPassword: '',
			submitted: false,
			checkedPasswordVisible: false,
			typePasswordInput: 'password',
			telephoneNumber1: '',
			cfpassword: '',

			// Otp
			mobile_no: '',
			otpRef: '',
			otpCode: '',
			otpVerify: null,
			otpShow: false,
			otpTimeOut: 0,
			otpButtonDisable: false,
			otpTypeInput: false,
			otpDisabled: false,

			// error message step 1
			errorMessageTelephoneNumber1: '',
			errorMessageUsername: '',
			errorMessageConfirmPassword: '',
			errorMessagePassword: '',
			errorMessageOtp: '',

			// valid step and otp show or hide
			userType: 0, // Test default 0
			checkStep1: true,
			checkStep2: true,
			checkStep3: true,
			checkStep4: true,
			activeStep: 0,

			// save succress change page
			buttonDisable :true,
			showPageSuccress:false,
			showPage:true,
		};

		//validation
		this.isValidStep1 = this.isValidStep1.bind(this);
	}

	onPressOtp() {
		//   this.setState({ otpTimeOut: 60, otpButtonDisable: true, otpCode: '', errorMessageOtp: '', otpVerify: null, otpButtonDisable: false })
		//   this.setState({ mobile_no: this.state.telephoneNumber1, otpRef: 'XXXXXX', otpShow: true });
		this.setState({ otpTimeOut: 60, otpButtonDisable: true, otpCode: '', errorMessageOtp: '', otpVerify: null })
		setInterval(() => {
			this.setState((prevState) => ({ otpTimeOut: prevState.otpTimeOut - 1 }))
			if (this.state.otpTimeOut == 0) {
				this.setState({ otpButtonDisable: false })
			}
		}, 1000)
		if (this.state.otpRef == '') {
			this.onOtpSend()
		} else {
			this.onOtpResend()
		}

	}


	onOtpSend() {
		UserService.otpResetPasswordSend(this.state.telephoneNumber1).then((res) => {
			console.log(res);
			if (res.mobile_no.length > 0) {
				this.setState({ mobile_no: res.mobile_no, otpRef: res.otp_ref, otpShow: true });
			} else {
				this.setState({ mobile_no: '', otpRef: '', otpShow: false });
			}
		}).catch((error) => {
			this.setState({ errorMessageTelephoneNumber1: "เครือข่ายขัดข้อง" });
		})
	}

	onOtpResend() {
		UserService.otpResetPasswordResend(this.state.telephoneNumber1).then((res) => {
			console.log(res);
			if (res.mobile_no.length > 0) {
				this.setState({ mobile_no: res.mobile_no, otpRef: res.otp_ref, otpShow: true });
			} else {
				this.setState({ mobile_no: '', otpRef: '', otpShow: false });
			}
		}).catch((error) => {
			this.setState({ errorMessageTelephoneNumber1: "เครือข่ายขัดข้อง" });
		})
	}

	onOtpVerify(value) {
		//    let otpCode = value
		//    this.setState({ otpCode: otpCode })
		//    this.setState({ errorMessageOtp: "", otpVerify: true, otpButtonDisable: true, otpTypeInput: true, otpDisabled: true });

		let otpCode = value
		this.setState({ otpCode: otpCode })
		let mobile_no = this.state.telephoneNumber1
		let otp_ref = this.state.otpRef
		if (otpCode.length == 6) {
			this.setState({
				errorMessageOtp: "",
				otpVerify: true,
				otpButtonDisable: true,
				otpTypeInput: true,
				otpDisabled: true
			});
			// UserService.otpVerifyChangePassword(mobile_no, otpCode, otp_ref).then((res) => {
			// 	console.log(res);
			// 	if (res == true) {
			// 		this.setState({ errorMessageOtp: "", otpVerify: true, otpButtonDisable: true, otpTypeInput: true, otpDisabled: true });
			// 	} else {
			// 		this.setState({ errorMessageOtp: res.response.data, otpVerify: false });
			// 	}
			// }).catch((error) => {
			// 	this.setState({ errorMessageOtp: error, otpVerify: false });
			// })
		} else {
			this.setState({ errorMessageOtp: "รหัสยืนยันไม่ถูกต้อง", otpVerify: false, otpDisabled: false });
		}
	}

	isValidStep1() {
		const { telephoneNumber1, otpCode, password, confirmPassword, userType } = this.state
		const { errorMessageTelephoneNumber1, errorMessageOtp, errorMessagePassword, errorMessageConfirmPassword } = this.state
		if (telephoneNumber1 !== '' && otpCode !== '' && password !== '' && confirmPassword !== '' && userType !== '' && userType !== 0) {
			if (errorMessageTelephoneNumber1 == '' && errorMessageOtp == '' && errorMessagePassword == '' && errorMessageConfirmPassword == '') {
				this.setState({ activeStep: 1 })
			} else {
				this.setState({ alertShow: true, alertTheme: 5, alertTitle: 'แจ้งเตือน', alertSubTitle: 'กรุณาตรวจสอบความถูกต้อง' })
				this.setState({ activeStep: 0 })
			}
		} else {
			this.setState({ alertShow: true, alertTheme: 5, alertTitle: 'แจ้งเตือน', alertSubTitle: 'กรุณาระบุข้อมูลให้ครบถ้วน' })
			this.setState({ activeStep: 0 })
		}
	}

	onTelephoneNumber1Changed(telephoneNumber, valid) {
		console.log(telephoneNumber)
		//const mobileNo = this.isNumber(telephoneNumber)
		this.setState({ telephoneNumber1: telephoneNumber });
		this.setState({ errorMessageTelephoneNumber1: "" });
		if (telephoneNumber.length == 10 && valid) {
			var telNoModel = {
				telephoneNumber: telephoneNumber
			}
			UserService.checkExitPhoneAndName(telNoModel).then((res) => {
				console.log(res)
				if (res.length > 0) {
					this.setState({ telID: res[0].userId })
					console.log(res[0].userId)

					console.log(this.state.userUid)
					if (this.state.userUid) {
						if (res[0].userId == this.state.userUid) {
							this.setState({ telephoneNumber1: telephoneNumber });
							this.setState({ errorMessageUsername: "" });
							this.setState({ errorMessageTelephoneNumber1: "" });
						} else {
							this.setState({ errorMessageTelephoneNumber1: "หมายเลข " + telephoneNumber + " ไม่ตรงกับชื่อผู้ใช้" });
						}
					} else {
						this.setState({ errorMessageTelephoneNumber1: "กรุณากรอกชื่อผู้ใช้ให้ถูกต้อง" });
					}
					this.setState({ telephoneNumber1: telephoneNumber });

				} else {
					this.setState({ errorMessageTelephoneNumber1: "หมายเลข " + telephoneNumber + " ไม่มีอยู่ในระบบ" });
				}
			}).catch((error) => {
				this.setState({ errorMessageTelephoneNumber1: "กรุณาติดต่อผู้ดูแลระบบ" });
			})
		} else {
			this.setState({ errorMessageTelephoneNumber1: "กรอกหมายเลขโทรศัพท์ 10 ตัวเลข (ขึ้นต้นด้วยเลข 0)" });
			this.setState({ telID: '' })
		}
	}

	isNumber(text) {
		let value = '';
		let valid = false;
		let numbers = '0123456789';
		if (text)
			for (var i = 0; i < text.length; i++) {
				if (numbers.indexOf(text[i]) > -1) {
					value = value + text[i];
					valid = true
				}
				else {
					this.setState({
						alertShow: true,
						alertTheme: 5,
						alertTitle: 'แจ้งเตือน',
						alertSubTitle: 'กรอกเฉพาะตัวเลขเท่านั้น'
					})
					valid = false
				}
			}
		return { value, valid }
	}

	onUsernameChanged(username) {
		// this.setState({ errorMessageUsername: "" });
		// this.setState({ messageUsername: "" });
		this.setState({ username: username });
		if (username.length >= 6) {
			const re = new RegExp("^[A-Za-z0-9]*$");
			const isOk = re.test(username);
			if (isOk) {
				console.log(username);
				UserService.checkExit(username).then((res) => {
					console.log(res);
					if (res.length > 0) {
						this.setState({ userUid: res[0].userId })
						this.setState({ username: username });
						this.setState({ errorMessageUsername: "" });
						console.log(res[0].userId)

						console.log(this.state.telID)
						if (this.state.telID) {
							if (res[0].userId == this.state.telID) {
								this.setState({ username: username });
								this.setState({ errorMessageUsername: "" });
								this.setState({ errorMessageTelephoneNumber1: "" });
							} else {
								this.setState({ errorMessageUsername: "ชื่อผู้ใช้ " + username + " ไม่ตรงกับหมายเลขโทรศัพท์" });
							}
						} else {
							this.setState({ errorMessageUsername: "กรุณากรอกชื่อผู้ใช้ให้ถูกต้อง" });
						}
						this.setState({ errorMessageUsername: "" });
					} else {
						this.setState({ errorMessageUsername: "ชื่อผู้ใช้ " + username + " ไม่มีอยู่ในระบบ" });
					}
				}).catch((error) => {
					this.setState({ errorMessageUsername: "กรุณาติดต่อผู้ดูแลระบบ" });
					this.setState({ messageUsername: "" });
				})
			} else {
				this.setState({ errorMessageUsername: "ตัวอักษรภาษาอังกฤษและตัวเลข โดยตัวแรกต้องเป็นภาษาอังกฤษเท่านั้น" });
				this.setState({ messageUsername: "" });
			}
		} else {
			this.setState({ errorMessageUsername: "กรุณากรอกอย่างน้อย 6 ตัวอักษร (ภาษาอังกฤษและตัวเลข)" });
			this.setState({ messageUsername: "" });
			this.setState({ userUid: '' })
		}

		//this.isNotvalidStep1();

	}

	onPasswordChanged(password) {
		//this.setState({ errorMessagePassword: "" });
		this.setState({ password: password });

		if (password.length >= 6) {
			const re = new RegExp("^(?=.*[a-z])(?=.*[A-Z]).{6,}$");
			const isOk = re.test(password);
			if (!isOk) {
				this.setState({ errorMessagePassword: "ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่" });
			} else {
				this.setState({ password: password });
				this.setState({ errorMessagePassword: "" });

				if (password == this.state.confirmPassword) {
					this.setState({ errorMessageConfirmPassword: "" });
					this.setState({ buttonDisable: false });
				} else {
					this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
					this.setState({ buttonDisable: true });
				}
			}
		} else {
			this.setState({ errorMessagePassword: "กรุณากรอกอย่างน้อย 6 ตัวอักษร (ภาษาอังกฤษตัวพิมพ์เล็ก ตัวพิมพ์ใหญ่)" });
		}

	}

	onConfirmPasswordChanged(cfpassword) {
		this.setState({ errorMessageConfirmPassword: "" });
		this.setState({ confirmPassword: "" });

		if (this.state.password == cfpassword) {
			this.setState({ confirmPassword: cfpassword });
			this.setState({ errorMessageConfirmPassword: "" });
			this.setState({ buttonDisable: false });
		} else {
			this.setState({ errorMessageConfirmPassword: "รหัสผ่านยืนยันไม่ตรงกัน" });
			this.setState({ confirmPassword: cfpassword });
			this.setState({ buttonDisable: true });
		}
	}


	setPasswordVisible = (e) => {
		console.log(e)
		if (this.state.checkedPasswordVisible) {
			this.setState({ checkedPasswordVisible: false })
			this.setState({ typePasswordInput: 'password' });
		} else {
			this.setState({ checkedPasswordVisible: true })
			this.setState({ typePasswordInput: 'text' });
		}
	}


	showSuccess = (message) => {
		this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'เปลี่ยนรหัสผ่านสำเร็จ' });
	}
	showWarn = (message) => {
		this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
	}
	backToLogin = (res) => {
		var index = res.toString().indexOf("Error:");
		console.log("find error:" + (index));
			if (index == -1) {
			window.location = '/#/login'
			this.showSuccess();
		//<a href="/#/login"></a>
		}
	}

	onSubmit = () => {
		let isValid = false
		isValid = (this.state.username && this.state.password && this.state.mobile_no && this.state.otpCode && this.state.otpRef &&
			!this.state.errorMessageUsername && !this.state.errorMessageTelephoneNumber1 && !this.state.errorMessageOtp &&
			!this.state.errorMessagePassword && !this.state.errorMessageConfirmPassword);
		if (isValid) {
			let data = {
				username: this.state.username,
				newPassword: this.state.password,
				mobile_no: this.state.mobile_no,
				otp_code: parseInt(this.state.otpCode),
				otp_ref: this.state.otpRef,
			}
			console.log(data)
			UserService.otpVerifyChangePassword(data).then((res) => {
				if (res) {
					console.log(res);
					var index = res.toString().indexOf("Error:");
					console.log("find error:" + (index));
					if (index == -1) {
						this.showSuccess();
						// this.backToLogin(res);
						this.setState({ showPage : false })
						this.setState({ showPageSuccress : true })
					} else {
						this.showWarn('รหัส OTP ไม่ถูกต้อง กรุณากรอกข้อมูลใหม่');
					}
				} else {
					this.showWarn();
					this.setState({ showProgress: false })
				}
			}).catch((error) => {
				this.showWarn('เกิดข้อผิดพลาด');
			})
		} else {
			this.showWarn('กรุณากรอกข้อมูลให้ครบถ้วน');
		}
	}

	render() {
		return (
			<div className="login-body">
				<Growl ref={(el) => this.growl = el} />
				<div className="p-grid p-fluid">
					<div className="p-col-11 p-sm-12 p-md-8 p-lg-6">
						<div className="card card-w-title">
							<div className="login-panel-content">
				{/* default page */}
						{this.state.showPage && 
								<div className="p-grid">
									<div className="p-col-12 p-md-2 p-lg-2" style={{ textAlign: 'right' }}>
										<img src="assets/layout/images/login/icon-login.svg" alt="avalon-react" />
									</div>
									<div className="p-col-12 p-md-10 p-lg-10" style={{ textAlign: 'right' }}>
										<h2 className="welcome-text">ตั้งรหัสผ่านใหม่</h2>
										<span className="guest-sign-in">Set password C3Leng</span>
									</div >
									<div className="p-col-12" style={{ textAlign: 'right' }} >
										<a href="/#">หน้าหลัก</a><br />
										<a href="/#/login">หน้า login</a>
									</div>
									<div className="p-col-12" style={{ textAlign: 'left' }}>
										<label className="login-label">ชื่อผู้ใช้</label>
										<div className="login-input">
											{/* <span style={{ color: 'red' }}>{this.state.errorMessageUsername} </span>
											<InputText
												placeholder="Username"
												name="username"
												value={username}
												disabled={this.state.otpDisabled || this.state.otpButtonDisable}
												onChange={this.handleChange.bind(this)}
												onKeyDown={(this._handleKeyDownEnter.bind(this))} />
										 */}
											<CEInputText
												id="username"
												type="text"
												className="input-100"
												value={this.state.username}
												name="username"
												placeholder="ชื่อเข้าใช้งาน"
												tooltip="กรอกชื่อเข้าใช้งานเป็นภาษาอังกฤษและตัวเลขเท่านั้น"
												minlength={6}
												maxlength={45}
												regexp="^[A-Za-z0-9]*$"
												required={true}
												onChange={(e) => this.onUsernameChanged(e.target.value)}
												isvalid={(valid) => console.log(valid)}
												disabled={this.state.otpDisabled || this.state.otpButtonDisable}
											/>
											<span style={{ color: 'red' }}>{this.state.errorMessageUsername} </span>
										</div>
									</div>
									<div className="p-col-12" style={{ textAlign: 'left', marginTop: '5px' }}>
										<div className="p-grid">
											<div className="p-col-6 login-input">
												<label className="login-label">เบอร์โทรศัพท์</label>
												<CEInputText
													id="telephoneNumber1"
													type="telephone"
													className="input-100"
													value={this.state.telephoneNumber1}
													name="telephoneNumber1"
													placeholder="หมายเลขโทรศัพท์"
													tooltip="กรอกหมายเลขโทรศัพท์ 10 หลัก"
													mask="9999999999"
													required={true}
													onChange={(e, v) => this.onTelephoneNumber1Changed(e.target.value, v)}
													isvalid={(valid) => this.setState({ isvalid_telephoneNumber1: valid })}
													disabled={this.state.otpDisabled || this.state.otpButtonDisable}
												/>
												<span style={{ color: 'red' }}>{this.state.errorMessageTelephoneNumber1} </span>

												{/* <CEInputText
												id="telephoneNumber1"
												type="telephone"
												className="input-101"
												value={this.state.telephoneNumber1}
												name="telephoneNumber1"
												placeholder="ระบุ เบอร์โทรศัพท์"
												tooltip="กรอกเป็นตัวเลข 10 ตัวเท่านั้น (ตัวแรกเป็น 0 )"
												mask="9999999999"
												maxLength={10}
												disabled={this.state.otpDisabled || this.state.otpButtonDisable}
												//required={false}
												onChange={(e,v) => this.onTelephoneNumber1Changed(e.target.value,v)}
												//onChange={(e)=> this.setState({phoneNumberAdmin: e.target.value})}
												/> */}
											</div>
											{!this.state.telephoneNumber1 == '' && this.state.errorMessageTelephoneNumber1 == '' && this.state.errorMessageUsername == '' && !this.state.otpDisabled &&
												<div className="p-col-6 login-input" style={{ marginTop: '18px' }}>
													<Button
														//icon="fa fa-fw fa fa-key"
														label={(this.state.otpTimeOut > 0 && this.state.otpButtonDisable && 'ขอ OTP ' + this.state.otpTimeOut + ' วินาที') ||
															(this.state.otpTimeOut == 0 && !this.state.otpButtonDisable && 'ขอ OTP เพื่อยืนยัน') ||
															(this.state.otpRef !== '' && !this.state.otpButtonDisable && 'ขอ OTP อีกครั้ง')}
														style={{ width: '100%' }}
														disabled={this.state.otpButtonDisable}
														onClick={this.onPressOtp.bind(this)}>

													</Button>
												</div>
											}
										</div>
									</div>
									{this.state.otpShow &&
										<div className="p-col-12" style={{ textAlign: 'left' }}>
											<div className="p-grid">
												<div className="login-input p-col-6" style={{ marginTop: '-6px' }}>
													<label className="login-label">รหัสอ้างอิง</label>
													<InputText
														placeholder=""
														name="otpRef"
														value={this.state.otpRef}
														disabled={true}
													/>
												</div>
												<div className="login-input p-col-6" style={{ marginTop: '-6px' }}>
													<label className="login-label">รหัส OTP</label>
													<CEPassword
														regexp="^[0-9]*$"
														type={"password"}
														className="input-100"
														name="otpCode"
														value={this.state.otpCode}
														placeholder="กรอก OTP"
														minlength={0}
														maxlength={6}
														//required={true}
														//disabled={this.state.otpDisabled}
														onChange={(e) => this.onOtpVerify(e.target.value)}
													//isvalid={(valid) => console.log(valid)}
													/>
												</div>
											</div>
										</div>
									}
									<div className="p-col-12" style={{ textAlign: 'left', marginTop: '-4px' }}>
										<label className="login-label">รหัสผ่านใหม่</label>
										<div className="p-inputgroup" style={{ height: '35px' }}>
											<CEPassword
												regexp="^(?=.*[a-z])(?=.*[A-Z]).{6,}$"
												type={this.state.typePasswordInput}
												className="input-100"
												value={this.state.password}
												name="typePasswordInput"
												placeholder="รหัสผ่าน"
												minlength={6}
												maxlength={100}
												required={true}
												disabled={!this.state.otpVerify}
												onChange={(e) => this.onPasswordChanged(e.target.value)}
												isvalid={(valid) => console.log(valid)}
											/>
											<Button
												style={{ height: '100%' }}
												icon={this.state.checkedPasswordVisible ? "fa fa-eye-slash" : "fa fa-eye"}
												className="p-col-12 p-button p-button-secondary"
												value={this.state.checkedPasswordVisible}
												onClick={this.setPasswordVisible}
												disabled={!this.state.otpVerify}
											/>
										</div>
										<br />
										<label className="login-label">ยืนยันรหัสผ่านใหม่อีกครั้ง</label>
										<div className="p-inputgroup" style={{ height: '35px' }}>
											<CEPassword
												regexp="^(?=.*[a-z])(?=.*[A-Z]).{6,}$"
												type={this.state.typePasswordInput}
												className="input-100"
												value={this.state.confirmPassword}
												name="typePasswordInput"
												placeholder="รหัสผ่าน"
												tooltip={this.state.errorMessagePassword}
												minlength={6}
												maxlength={100}
												required={true}
												disabled={!this.state.otpVerify}
												onChange={(e) => this.onConfirmPasswordChanged(e.target.value)}
												isvalid={(valid) => console.log(valid)}
											/>
											<Button
												style={{ height: '100%' }}
												icon={this.state.checkedPasswordVisible ? "fa fa-eye-slash" : "fa fa-eye"}
												className="p-col-12 p-button p-button-secondary"
												value={this.state.checkedPasswordVisible}
												onClick={this.setPasswordVisible}
												disabled={!this.state.otpVerify}
											/>
										</div>
										<span style={{ color: 'red' }}>{this.state.errorMessageConfirmPassword}</span>
									</div>
									<div className="p-col-12">
										<Button
											label="บันทึก"
											style={{ marginTop: '10px' }}
											onClick={this.onSubmit.bind(this)}
											disabled={this.state.buttonDisable}
										>
										</Button>
									</div>
								</div>
							}
				{/* show succress */}
					{this.state.showPageSuccress&& 
								<div className="p-grid" style={{ textAlign: 'center'}}>
									<div className="p-col-12  " style={{marginTop:'30px'}}>
										<img alt="project" src='../assets/layout/images/register/correctimages.png' style={{ width: 150, height: 150 }} />
									</div>
									<div className="p-col-12 ">
										<h1 >เปลี่ยนรหัสผ่านสำเร็จ</h1>
									</div>
									<div className="p-col-6 " style={{ textAlign: 'right', }}>
										<a href="#/login"><Button  label="เข้าสู่ระบบ"/></a>
									</div>
									<div className="p-col-6" style={{ textAlign:'left' }}>
										<a href="#/"><Button label="กลับสู่หน้าหลัก" /></a>
									</div>
								</div>
								}
							</div>
						</div>
					</div>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	authentication: state.authentication

})

const mapDispatchToProps = dispatch => ({

	loginProps: bindActionCreators(UserService, dispatch)

})


export default
	connect(
		mapStateToProps,
		mapDispatchToProps, null, { forwardRef: true })(ForgetPassword)