import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux';
import Moment from 'react-moment';
import 'moment-timezone';
import { InputText } from 'primereact/inputtext';
import { AutoComplete } from 'primereact/autocomplete';
import { Button } from 'primereact/button';
import { Growl } from 'primereact/growl';
import {Avatar} from '../../features/widget/Avatar';
import { API_ROOT } from '../../constants/api';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Panel } from 'primereact/components/panel/Panel';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { Column } from 'primereact/components/column/Column';
import { MapWithASearchBoxAndMarkers } from '../../features/map/MapWithASearchBoxAndMarkers'
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from 'primereact/calendar';
import { ProgressSpinner } from 'primereact/progressspinner';
import * as ServerAPI from './service';
import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as FileService from '../../service/file.service'
import * as DashBoardService from './service'
import * as MasterService from '../../service/MasterService'
import { isEmpty } from '../../utils/validators';
import {Validators} from '../../utils'
import {CEInputText,CECalendar,CEDropdown} from '../../features/input';
import {CEFileUpload} from '../../features/widget';
import {Dialog} from 'primereact/dialog';
import {InputSwitch} from 'primereact/inputswitch';
import {RadioButton} from 'primereact/radiobutton';
import {Card} from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import {InputTextarea} from 'primereact/inputtextarea';
import { FileView } from '../../features/widget/FileView';
import ImageInfoView from '../profile/ImageInfoView';

 class ManageFAQ extends Component {
    static propTypes = {

    }
    static defaultProps = {

    }

    constructor() {
        super();
        this.state = {
            question: '',
            answer: '',
            faqList: [],
            isNotValid: false,
            isNotValidUpdate: false,
            showProgress: false,
            vehicle_img_name: '',
        };
        this.onClickAddDistance = this.onClickAddDistance.bind(this);
        this.onClickDeleteItem = this.onClickDeleteItem.bind(this);
        this.onValidation = this.onValidation.bind(this);
        this.getAllfaq = this.getAllfaq.bind(this);
        this.update = this.update.bind(this);
        this.delete = this.delete.bind(this);
        this.onUpdateValidation = this.onUpdateValidation.bind(this);
        
    }
    componentDidMount() {
        this.getAllfaq();
    }
    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div></div>);
    }
 
    getAllfaq(){
        this.setState({ showProgress: true })
        ServerAPI.getAllfaq().then((res) => {
            console.log(res);
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.setState({faqList: res })
                    this.setState({ showProgress: false })
                    //this.showSuccess();
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false})
                }
            } else {
                this.showWarn();
                this.setState({ showProgress: false})
            }
        }).catch((error) => {
            this.setState({ showProgress: false})
        })
    }
    actionTemplate(rowData, column) {
        return <div>
            {/* <Button type="button" onClick={() => this.onClickDeleteItem(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button> */}
            <Button type="button" onClick={() => this.onDistanceUpdate(column.rowData)} icon="pi pi-pencil" className="p-button" ></Button>
            <Button type="button" onClick={() => this.onDistanceDelete(column.rowData)} icon="fa fa-trash" className="p-button-warning" ></Button>
        </div>;
    }
    onClickDeleteItem(rowData) {
        if (rowData !== '') {
            console.log(rowData)
            var id = rowData.gid;
            ServerAPI.removeFAQ(id).then((res) => {
                console.log(res);
                if (res) {
                    var index = res.toString().indexOf("Error:");
                    console.log("find error:" + (index));
                    if (index == -1) {
                        this.showSuccess();
                        this.getAllfaq();
                    } else {
                        this.showWarn();
                        this.setState({ showProgress: false})
                    }
                } else {
                    this.showWarn();
                    this.setState({ showProgress: false})
                }
            }).catch((error) => {
                this.setState({ showProgress: false})
            })
        }else{
            this.showWarn();
            this.setState({ showProgress: false})
        }
        // if (index !== -1) {
        //     var array = this.state.distanceList;
        //     array.splice(index, 1);
        //     this.setState({ distanceList: array });
        // }
    }

    update() {
        this.setState({ showProgress: true })
        console.log(this.state.manageFAQ)
        if(this.state.manageFAQ){
            var isNotinput = (
                this.state.manageFAQ.question == '' ||
                this.state.manageFAQ.answer == ''  
                // ||this.state.isNotValid == true
            );
            if (isNotinput) {
                this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน !! ");
                this.setState({ showProgress: false })
            } else {
                        var data = {
                            gid: this.state.manageFAQ.gid,
                            question: this.state.manageFAQ.question,
                            answer: this.state.manageFAQ.answer,
                        }

                        ServerAPI.editFAQ(data).then((res) => {
                            console.log(res);
                            if (res) {
                                var index = res.toString().indexOf("Error:");
                                console.log("find error:" + (index));
                                if (index == -1) {
                                    this.getAllfaq();
                                    this.setState({ displayDialog:false});
                                    this.showSuccess();
                                } else {
                                    this.showWarn();
                                    this.setState({ showProgress: false })
                                }
                            } else {
                                this.showWarn();
                                this.setState({ showProgress: false })
                            }
                        }).catch((error) => {
                            this.setState({ showProgress: false })
                        })
                    }
                }
            }
        
    

    delete() {
        this.setState({ showProgress: true })
        var data = this.state.manageFAQ;
        this.onClickDeleteItem(data);
        // let index = this.findSelectedWeightIndex();
        // this.setState({
        //     cars: this.state.cars.filter((val,i) => i !== index),
        //     selectedCar: null,
        //     car: null,
        this.setState({deleteDialog: false});
    }
    updateProperty(property, value) {
        let manageFAQ = this.state.manageFAQ;
        manageFAQ[property] = value;
        this.setState({manageFAQ: manageFAQ});
    }

    onDistanceUpdate(e){
        console.log(e)
        this.setState({
            displayDialog:true,
            manageFAQ: Object.assign({}, e)
        });
    }
    onDistanceDelete(e){
        console.log(e)
        this.setState({
            deleteDialog:true,
            manageFAQ: Object.assign({}, e)
        });
    }
    onValidation(valid){
        console.log(valid)
        if(!valid) this.setState({isNotValid: true })
        else this.setState({isNotValid: false })
    }
    onUpdateValidation(valid){
        console.log(valid)
        if(!valid) this.setState({isNotValidUpdate: true })
        else this.setState({isNotValidUpdate: false })
    }
    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }

   

    onClickAddDistance(){
        this.setState({ showProgress: true })
        var isNotinput = (
            this.state.question == '' ||
            this.state.answer == ''  ||
            this.state.isNotValid == true
        );

        if (isNotinput) {
            this.showWarn("กรุณากรอกข้อมูลให้ครบถ้วน");
            this.setState({ showProgress: false })
        } else {
                var quest = this.state.question
                var notValid = false;
                this.state.faqList.forEach(obj => {
                    if(quest == obj.question){
                        notValid = true;
                    }
                });
                    if(notValid){
                        this.showWarn("มีคำถามนี้อยู่แล้ว กรุณากรอกใหม่");
                        this.setState({ showProgress: false })
                    }else{

                        var faqList = {
                            // gid: 0,
                            // distance_code: '',
                            
                            question: this.state.question,
                            answer: this.state.answer,
                            
                        }
                        this.state.faqList.push(faqList)
                        this.showSuccess();
                        this.setState({ showProgress: false})

                        ServerAPI.addFAQ(faqList).then((res) => {
                            console.log(res);
                            if (res) {
                                var index = res.toString().indexOf("Error:");
                                console.log("find error:" + (index));
                                if (index == -1) {
                                    this.showSuccess();
                                    this.getAllfaq();
                                    this.setState({ question: '' })
                                    this.setState({ answer: '' })
                                } else {
                                    this.showWarn();
                                    this.setState({ showProgress: false})
                                }
                            } else {
                                this.showWarn();
                                this.setState({ showProgress: false})
                            }
                        }).catch((error) => {
                            this.setState({ showProgress: false})
                        })
                        
                    }
                    console.log(this.state.faqList)
                
        }
    }
    
    onSelectFileImgVehicle = (e) => {
        this.setState({ fileImgVehicle: e.files[0] });
    }
    onRefreshVehicle = (e) => {
        this.setState({ vehicle_img_name: this.state.vehicle_img_name })
        this.setState({
            listFileDelete: this.state.listFileDelete.filter(item => item != this.state.vehicle_img_name)
        })
    }
    onDeleteVehicle = (e) => {
        this.state.listFileDelete.push(this.state.vehicle_img_name)
        this.setState({ vehicle_img_name: null })
        this.setState({ fileImgVehicle: null });
    }

    showAddCertificate(){
        const newDistance = (
            <div className="p-col-12" >
                    <Accordion multiple={true} activeIndex={[0]} >
                        <AccordionTab header="เพิ่มคำถามที่พบบ่อย">
                            <div className="p-grid">
                            <div className="p-col-12 p-md-1 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>คำถาม :</label>
                            </div>
                            <div className="p-col-12 p-md-11 p-lg-11" style={{ alignSelf: 'center' }}>
                                <CEInputText   
                                    className="input-100"                      
                                    type="text"
                                    value={this.state.question}
                                    placeholder="ระบุคำถาม"
                                    required={true}
                                    onChange={(e)=> this.setState({question: e.target.value})}
                                    />
                            </div>
                            <div className="p-col-12 p-md-1 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>คำตอบ :</label>
                            </div>
                            <div className="p-col-12 p-md-11 p-lg-11">
                                <CEInputText   
                                    className="input-100"   
                                        id="cabonCredit"                        
                                        type="text"
                                        value={this.state.answer}
                                        name="cabonCredit"
                                        placeholder="ระบุคำตอบ"
                                        required={true}
                                        isvalid={this.onValidation}
                                        onChange={(e)=> this.setState({answer: e.target.value})}
                                        />
                            </div>
                            <div className="p-col-12 p-md-6 p-lg-6"></div>
                            
                            <div className="p-col-12">
                                <Button
                                    type="submit"
                                    label="เพิ่มรายการ"
                                    icon="pi pi-plus"
                                    iconPos="right"
                                    onClick={this.onClickAddDistance}
                                />
                            </div>
                            
                            </div>
                        </AccordionTab>
                        
                    </Accordion>
            </div>
        );
        return newDistance;
    }

    showDistanceTable(){
        let dialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="บันทึก" icon="pi pi-check" onClick={this.update}/>
            <Button label="ยกเลิก" icon="pi pi-times" onClick={() => this.setState({displayDialog: false})} className="p-button-warning"/>
            </div>;
        let deleteDialogFooter = <div className="ui-dialog-buttonpane p-clearfix">
            <Button label="ใช่" icon="pi pi-check" onClick={this.delete}/>
            <Button label="ไม่ใช่" icon="pi pi-times" onClick={() => this.setState({deleteDialog: false})} className="p-button-warning"/>
            </div>;
        const distanceTable = (
            <div className="p-col-12">
                    <Panel header="รายการคำถามที่พบบ่อย" >
                        <DataTable value={this.state.faqList} paginatorPosition="bottom" paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                            responsive={true}
                            emptyMessage={(<div style={{ textAlign: 'center', color: 'red' }}>
                            <label>ไม่พบข้อมูล</label>
                            </div>)}>
                            <Column field="question" header="คำถาม" sortable={true} />
                            <Column field="answer" header="คำตอบ" sortable={true}/>
                            <Column field="gid" body={this.actionTemplate.bind(this)} header="ลบ" className="p-text-center" style={{ width: '8em' }} />
                        </DataTable>
                    </Panel>
                    <Dialog dismissableMask={true} visible={this.state.deleteDialog}  height= "100px" width="300px" header="ยืนยันการลบข้อมูล" modal={true} footer={deleteDialogFooter} onHide={() => this.setState({deleteDialog: false})}>
                        {
                            this.state.manageFAQ &&
                            
                            <div className="p-grid p-fluid" >
                                <div className="p-col-12"><span>คุณต้องการจะลบข้อมูลต่อไปนี้</span></div>
                                <div className="p-col-2" ><label htmlFor="question">คำถาม :</label></div>
                                <div className="p-col-10" >
                                    {this.state.manageFAQ.question}
                                </div>

                                <div className="p-col-2" ><label htmlFor="answer">คำตอบ :</label></div>
                                <div className="p-col-10" >
                                    {this.state.manageFAQ.answer}
                                </div>
                            </div>
                        }
                    </Dialog>
                    <Dialog dismissableMask={true} visible={this.state.displayDialog}   width="500px" header="แก้ไขข้อมูล" modal={true} footer={dialogFooter} onHide={() => this.setState({displayDialog: false})}>
                        {
                            this.state.manageFAQ &&

                            <div className="p-grid p-fluid" >
                                
                                <div className="p-col-1" 
                                // style={{ alignSelf: 'center' }}
                                >
                                <label htmlFor="question">คำถาม </label></div>
                                {/* <div className="p-col-10" >
                                    <CEInputText   
                                        id="question"                      
                                        type="text"
                                        value={this.state.manageFAQ.question}
                                        placeholder="คำถาม"
                                        // maxlength={25}
                                        required={true}
                                        isvalid={this.onValidation}
                                        onChange={(e) => {this.updateProperty('question', e.target.value)}}
                                        />
                                </div> */}

                                <div className="p-col-11" >
                                    <InputTextarea 
                                        id="question" 
                                        rows={5} 
                                        cols={30}
                                        value={this.state.manageFAQ.question}
                                        placeholder="คำถาม"
                                        required={true} 
                                        isvalid={this.onValidation}
                                        onChange={(e) => {this.updateProperty('question', e.target.value)}}
                                        />
                                </div>

                                <div className="p-col-1" ><label htmlFor="answer">คำตอบ </label></div>
                                <div className="p-col-11" >

                                    {/* <CEInputText   
                                        id="answer"                      
                                        type="text"
                                        value={this.state.manageFAQ.answer}
                                        placeholder="คำตอบ"
                                        // maxlength={25}
                                        required={true}
                                        isvalid={this.onValidation}
                                        onChange={(e) => {this.updateProperty('answer', e.target.value)}}
                                        /> */}
                                        
                                    <InputTextarea 
                                        id="answer"  
                                        rows={5} 
                                        cols={30} 
                                        value={this.state.manageFAQ.answer}
                                        placeholder="คำตอบ"
                                        //required={true}
                                        //isvalid={this.onValidation}
                                        onChange={(e) => {this.updateProperty('answer', e.target.value)}}
                                        
                                        />
                                </div>

                              
                            </div>
                        }
                    </Dialog>
            </div>
        );
        if(!this.state.showProgress)
        return distanceTable;
    }

    render() {
        const showAddCertificatePanel = this.showAddCertificate();
        const distanceTablePanel = this.showDistanceTable();
        const showProgress = this.showProgressSpinner();
        
        return <div className="p-grid ">
            <Growl ref={(el) => this.growl = el} />
                <div className="p-col-12">
                        <h1>ตั้งค่าคำถามที่พบบ่อย (FAQ)</h1>
                    </div>
                    {showAddCertificatePanel}
                    {showProgress}
                    {distanceTablePanel}

        </div>
    }
    
}


const mapStateToProps = (state) => ({
    productType: state.master.productType,
    product: state.master.product,
})

const mapDispatchToProps = dispatch => ({
    masterServiceProps: bindActionCreators(MasterService, dispatch),

})

export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ManageFAQ)

