import { API_ROOT } from '../constants/api'
import axios from 'axios'

import {
    AUTO_ADMINALL,
    AUTO_PROD,
    AUTO_WASTEALL,
    AUTO_WASTEGEN,
    AUTO_WASTETRAN,
    AUTO_WASTEPRO
} from './actionType'

export const autoCompleteAdminAll = (search_name) => (dispatch) => {
    dispatch({ type: AUTO_ADMINALL.REQUEST, isLoading: true })
    return axios({
        method: 'get',
        url: `${API_ROOT}/autocomplete/adminall/${search_name}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
    }).then((res) => {
        let rs = res.data.map((value) => {
            return value.search_name
        })
        res.data.suggest = rs;
        dispatch({ type: AUTO_ADMINALL.SUCCESS, payload: res.data })
    }).catch((error) => {
        dispatch({ type: AUTO_ADMINALL.FAILURE, payload: error.message })
    })
}

export const autoCompleteProduct = (search_name) => (dispatch) => {
    dispatch({ type: AUTO_PROD.REQUEST, isLoading: true })
    return axios({
        method: 'get',
        url: `${API_ROOT}/autocomplete/product/${search_name}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
    }).then((res) => {
        
        let rs = res.data.map((value) => {
            return value.product_name
        })
        res.data.suggest = rs;
        dispatch({ type: AUTO_PROD.SUCCESS, payload: res.data })
    }).catch((error) => {
        dispatch({ type: AUTO_PROD.FAILURE, payload: error.message })
    })
}

export const autoCompleteWasteall = (search_name) => (dispatch) => {
    dispatch({ type: AUTO_WASTEALL.REQUEST, isLoading: true })
    return axios({
        method: 'get',
        url: `${API_ROOT}/autocomplete/wasteall/${search_name}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
    }).then((res) => {
        
        let rs = res.data.map((value) => {
            return value.search_name
        })
        res.data.suggest = rs;
        dispatch({ type: AUTO_WASTEALL.SUCCESS, payload: res.data })
    }).catch((error) => {
        dispatch({ type: AUTO_WASTEALL.FAILURE, payload: error.message })
    })
}

export const autoCompleteWastegen = (search_name) => (dispatch) => {
    dispatch({ type: AUTO_WASTEGEN.REQUEST, isLoading: true })
    return axios({
        method: 'get',
        url: `${API_ROOT}/autocomplete/wastegen/${search_name}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
    }).then((res) => {
        
        let rs = res.data.map((value) => {
            return value.search_name
        })
        res.data.suggest = rs;
        dispatch({ type: AUTO_WASTEGEN.SUCCESS, payload: res.data })
    }).catch((error) => {
        dispatch({ type: AUTO_WASTEGEN.FAILURE, payload: error.message })
    })
}

export const autoCompleteWastetran = (search_name) => (dispatch) => {
    dispatch({ type: AUTO_WASTETRAN.REQUEST, isLoading: true })
    return axios({
        method: 'get',
        url: `${API_ROOT}/autocomplete/wastetran/${search_name}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
    }).then((res) => {
        
        let rs = res.data.map((value) => {
            return value.search_name
        })
        res.data.suggest = rs;
        dispatch({ type: AUTO_WASTETRAN.SUCCESS, payload: res.data })
    }).catch((error) => {
        dispatch({ type: AUTO_WASTETRAN.FAILURE, payload: error.message })
    })
}

export const autoCompleteWastepro = (search_name) => (dispatch) => {
    dispatch({ type: AUTO_WASTEPRO.REQUEST, isLoading: true })
    return axios({
        method: 'get',
        url: `${API_ROOT}/autocomplete/wastepro/${search_name}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
    }).then((res) => {
        
        let rs = res.data.map((value) => {
            return value.search_name
        })
        res.data.suggest = rs;
        dispatch({ type: AUTO_WASTEPRO.SUCCESS, payload: res.data })
    }).catch((error) => {
        dispatch({ type: AUTO_WASTEPRO.FAILURE, payload: error.message })
    })
}