import SecureStorage from 'secure-web-storage'
import {AES,SHA256,enc} from 'crypto-js';

// var CryptoJS = require("crypto-js");
 
var SECRET_KEY = 'c3leng secret key';
 
const secureStorage = new SecureStorage(localStorage, {
    hash: function hash(key) {
        key = SHA256(key, SECRET_KEY);
 
        return key.toString();
    },
    encrypt: function encrypt(data) {
        data = AES.encrypt(data, SECRET_KEY);
 
        data = data.toString();
 
        return data;
    },
    decrypt: function decrypt(data) {
        data = AES.decrypt(data, SECRET_KEY);
 
        data = data.toString(enc.Utf8);
 
        return data;
    }
});

export const setItem = function(name,data){
    // stores in localStorage like:
    // key => value
    // "ad36d572..." => "w1svi6n..."
    secureStorage.setItem(name, data);
}

export const getItem = function(name){
    // returns { secret: 'data' }
    return secureStorage.getItem(name);
}

export const removeItem = function(name){
    // removes the entry 'data'
    secureStorage.removeItem(name);
}
 
export const key = function(id){
    // returns the hashed version of the key you passed into setItem with the given id.
    return secureStorage.key(id)
}
 
export const clear = function(){
    // clears all data in the underlining sessionStorage/localStorage.
    secureStorage.clear();
}
 
export const length = function(){
    // the number of entries in the underlining sessionStorage/localStorage.
    return secureStorage.length;
}
