import React, { Component } from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import Moment from 'react-moment';
import 'moment-timezone';
import { Panel } from 'primereact/components/panel/Panel';
import { Checkbox } from 'primereact/components/checkbox/Checkbox';
import { Button } from 'primereact/components/button/Button';
import { Dropdown } from 'primereact/components/dropdown/Dropdown';
import { InputText } from 'primereact/components/inputtext/InputText';
import { InputTextarea } from 'primereact/components/inputtextarea/InputTextarea';
import { DataTable } from 'primereact/components/datatable/DataTable';
import { DataView, DataViewLayoutOptions } from 'primereact/dataview';
import { Column } from 'primereact/components/column/Column';
import { FullCalendar } from 'primereact/fullcalendar';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Calendar } from 'primereact/calendar';
import { MapWithASearchBox } from '../../features/map/MapWithASearchBox';
import { MapMarkers } from '../../features/map/MapMarkers'
import { AutoComplete } from 'primereact/autocomplete';
import { Avatar } from '../../features/widget/Avatar';
import { FileUpload } from 'primereact/fileupload';
import { Growl } from 'primereact/growl';
import { API_ROOT } from '../../constants/api';
import { MapWithASearchBoxAndMarkers } from '../../features/map/MapWithASearchBoxAndMarkers';
import { ProgressSpinner } from 'primereact/progressspinner';
import { CEInputText, CECalendar, CEDropdown } from '../../features/input';
import { CEFileUpload } from '../../features/widget';
import * as ServerAPI from './service';
import * as LocationService from '../../service/LocationService'
import * as UserService from '../../service/user.service'
import * as MasterService from '../../service/MasterService'
import * as FileService from '../../service/file.service'
import * as DashBoardService from './service'
import { RadioButton } from 'primereact/radiobutton';
import moment from 'moment'


class ManageOrderContainer extends Component {
    static propTypes = {

    }

    static defaultProps = {
    }

    constructor() {
        super();
        this.state = {
            memberType: 0,
            memberTypeItem: [
                { label: 'เลือกทั้งหมด', value: 0 },
                { label: 'ผู้รักษ์โลก', value: 1 },
                { label: 'ทีมขนส่งซีซาเล้ง', value: 2 },
                { label: 'ศูนย์จัดการฯซีซาเล้ง', value: 3 },
            ],
            pickDateStart: null,
            pickDateEnd: null,
            deliveryDate: null,
            date3: null,
            date4: null,
            date1: null,
            date2: null,
            selectedProductImg: null,
            status: 1000,
            status2: 1000,
            statusSearchItem: [
                { label: 'เลือกทั้งหมด', value: 1000 },
                { label: 'รอร้านค้ายืนยัน', value: 0 },
                { label: 'รอนัดหมาย', value: 1 },
                { label: 'รอทีมขนส่ง', value: 2 },
                { label: 'รอส่งสินค้า', value: 3 },
                { label: 'รอปิด Order', value: 4 },
                { label: 'เกินกำหนดรับสินค้า', value: 5 },
                { label: 'ยกเลิก', value: 99 },
                { label: 'ผิดนัด', value: 999 },
                { label: 'ร้านค้าปฏิเสธรับซื้อ', value: 998 },
            ],
            statusItem: [
                // { label: 'เลือกทั้งหมด', value: 1000 },
                { label: 'รอร้านค้ายืนยัน', value: 0 },
                { label: 'รอนัดหมาย', value: 1 },
                { label: 'รอทีมขนส่ง', value: 2 },
                { label: 'รอส่งสินค้า', value: 3 },
                { label: 'รอปิด Order', value: 4 },
                { label: 'เกินกำหนดรับสินค้า', value: 5 },
                { label: 'ปิด Order', value: 88 },
                { label: 'ยกเลิก', value: 99 },
                { label: 'ผิดนัด', value: 999 },
                { label: 'ร้านค้าปฏิเสธรับซื้อ', value: 998 },
            ],
            manageTable: [
                1, 2, 3, 4, 5, 6, 7
            ],

            mapGenZoom: 11,
            mapGenMarker: {},
            mapGenCenter: { lat: 13.7734283, lng: 100.57383099999993 },
            mapMarker: { lat: 13.7734283, lng: 100.57383099999993 },
            mapTranMarker: {},
            mapTranMarkers: [],
            mapTranZoom: 11,
            mapTranCenter: { lat: 13.7734283, lng: 100.57383099999993 },
            mapProMarkers: [],
            mapProZoom: 11,
            mapProCenter: { lat: 13.7734283, lng: 100.57383099999993 },
            OrderList: [
                // wasteType: '',
                // amount: '',
                // appraisedPrice: '',
                // salePrice: '',
            ],
            brandSuggestions: null,
            addressDetail: '',
            province: 0,
            district: 0,
            subDistrict: 0,
            provinceCode: 0,
            districtList: [],
            districtCode: 0,
            subDistrictCode: 0,
            subDistrictList: [],
            lineId: '',
            email: '',
            genPhone: '',
            deliveryCost: '',
            ModelInputLocation: {
                latitude: "13.7734283",
                longitude: "100.57383099999993",
                distance_km: 10
            },
            wastetranDataList: [],
            wasteproDataList: [],
            provinceSelected: '',
            wasteTypeSelected: '',
            productSelected: '',
            amountSelected: '',
            appraisedPriceSelected: '',
            salePriceSelected: '',
            dataTableSelection2: '',
            dataTableSelection3: '',
            genImg: null,
            tranImg: null,
            proImg: null,
            personPrefixCode: '',
            personPrefix: '',
            orderID: null,
            orderDetail: [],
            gid: 0,
            showProgress: false,
            reportSearchOrders: [],
            orderCode: null,
            showReportPanel: true,
            memberUid: null,
            showReportTable: true,
            showHideSearch: true,
            isNotValid: false,

            selectDay: null,
        };
        this.onUpload = this.onUpload.bind(this);
        this.onWasteTypeChange = this.onWasteTypeChange.bind(this);
        this.onproductsChange = this.onproductsChange.bind(this);
        this.onUsernameChanged = this.onUsernameChanged.bind(this);
        this.onMemberNameChanged = this.onMemberNameChanged.bind(this);
        this.onOrderIDChanged = this.onOrderIDChanged.bind(this);
        //this.onSelectAutoCompleteSearchName = this.onSelectAutoCompleteSearchName.bind(this);
        this.onSelectMemberName = this.onSelectMemberName.bind(this);
        this.onSelectOrderID = this.onSelectOrderID.bind(this);
        this.onFileWasteChanged = this.onFileWasteChanged.bind(this);
        this.onFileDelete = this.onFileDelete.bind(this);
        this.onTableTranSelected = this.onTableTranSelected.bind(this);
        this.onTableProSelected = this.onTableProSelected.bind(this);
        //this.getProfile = this.getProfile.bind(this);
        this.onProvinceChanged = this.onProvinceChanged.bind(this);
        this.onDistrictChanged = this.onDistrictChanged.bind(this);
        this.onSubDistrictChanged = this.onSubDistrictChanged.bind(this);
        this.mapChanged = this.mapChanged.bind(this);
        this.onMarkerMoved = this.onMarkerMoved.bind(this);
        this.onMarkerClicked = this.onMarkerClicked.bind(this);
        this.onMarkerProClicked = this.onMarkerProClicked.bind(this);
        this.addDataToTable = this.addDataToTable.bind(this);
        this.onClickSaveOrder = this.onClickSaveOrder.bind(this);
        this.onPickDateStartChange = this.onPickDateStartChange.bind(this);
        this.onPickDateEndChange = this.onPickDateEndChange.bind(this);
        // this.onPickupDateChange = this.onPickupDateChange.bind(this);
        this.onDeliveryDateChange = this.onDeliveryDateChange.bind(this);
        this.onDate3Change = this.onDate3Change.bind(this);
        this.onDate4Change = this.onDate4Change.bind(this);
        this.onDate1Change = this.onDate1Change.bind(this);
        this.onDate2Change = this.onDate2Change.bind(this);
        this.clearAllDataInput = this.clearAllDataInput.bind(this);
        this.searchOrder = this.searchOrder.bind(this);
        this.onOrderSelected = this.onOrderSelected.bind(this);
        this.selectedTran = this.selectedTran.bind(this);
        this.selectedPro = this.selectedPro.bind(this);
        this.clearSearchInput = this.clearSearchInput.bind(this);
        this.showProgressSpinner = this.showProgressSpinner.bind(this);
        this.itemProductImgTemplate = this.itemProductImgTemplate.bind(this);
        this.requestProLocation = this.requestProLocation.bind(this);
        this.quantityEditor = this.quantityEditor.bind(this);
        this.estimatePricePerUnitEditor = this.estimatePricePerUnitEditor.bind(this)
        this.pickupQuantityEditor = this.pickupQuantityEditor.bind(this)
        this.deliveryQuantityEditor = this.deliveryQuantityEditor.bind(this)
        this.deliveryPricePerUnitEditor = this.deliveryPricePerUnitEditor.bind(this)
        this.remarkEditor = this.remarkEditor.bind(this)
        this.selectedTranByLatLng = this.selectedTranByLatLng.bind(this);
        this.selectedProByLatLng = this.selectedProByLatLng.bind(this);
        this.onOrderKeyPress = this.onOrderKeyPress.bind(this);
        this.showReportResult = this.showReportResult.bind(this);
        this.showHideSearchPanel = this.showHideSearchPanel.bind(this);

        this.distanceTemplate = this.distanceTemplate.bind(this);
        this.toFormatDateTime = this.toFormatDateTime.bind(this);
        this.onValidation = this.onValidation.bind(this);
    }


    componentDidMount() {
        const { dashBoardServiceProps, locationServiceProps, masterServiceProps } = this.props
        dashBoardServiceProps.getDashboardUsers();
        locationServiceProps.getProvice();
        masterServiceProps.getProductType();
    }
    onValidation(valid) {
        console.log(valid)
        if (!valid) this.setState({ isNotValid: true })
        else this.setState({ isNotValid: false })
    }
    onFileDelete(index) {
        var array = this.state.fileImgWaste;
        array.splice(index, 1);
        this.setState({ fileImgWaste: array });
    }
    onFileWasteChanged(e) {
        //this.setState({ fileImgIden: e.target.files[0] });
        var list = [];
        for (let index = 0; index < e.files.length; index++) {
            const file = e.files[index];
            list.push(file);
        }
        if (this.state.fileImgWaste) {
            var arr = this.state.fileImgWaste.concat(list);
            this.setState({ fileImgWaste: arr });
        } else {
            this.setState({ fileImgWaste: list });
        }
    }
    onProvinceChanged(e) {
        console.log(e)
        this.setState({ provinceSelected: e.value, genAddressProvince: e.value.province_name });
        this.setState({ provinceCode: e.value.provinceCode });
        this.setState({ districtSelected: null })
        this.setState({ subDistrictSelected: null })
        ServerAPI.getDistrict(e.value.provinceCode).then((res) => {
            this.setState({ districtList: res });
        });
    }
    onDistrictChanged(e) {

        this.setState({ districtSelected: e.value, genAddressDistrict: e.value.districtName });
        this.setState({ districtCode: e.value.districtCode });
        this.setState({ subDistrictSelected: null })
        ServerAPI.getSubDistrict(e.value.districtCode).then((res) => {
            this.setState({ subDistrictList: res });
        });
    }
    onSubDistrictChanged(e) {
        this.setState({ subDistrictSelected: e.value });
        this.setState({ subDistrictCode: e.value.subDistrictCode });
    }
    // onDate1Change(e) { //date in order edit
    //     //console.log(e.value.toISOString())
    //     if (this.state.date2 != null) {
    //         if (e.value < this.state.date2) {
    //             this.setState({ date1: e.value })
    //         }
    //     } else
    //         this.setState({ date1: e.value })
    // }

    onPickDateStartChange(e) {
        //console.log(e.value.toISOString())
        if (this.state.deliveryDate != null || this.state.pickDateEnd != null) {
            if (e.value < this.state.deliveryDate || e.value < this.state.pickDateEnd) {
                this.setState({ pickDateStart: e.value })
            }
        } else
            this.setState({ pickDateStart: e.value })
    }
    onPickDateEndChange(e) {
        console.log(e)
        if (this.state.pickDateStart != null) {
            if (e.value > this.state.pickDateStart) {
                this.setState({ pickDateEnd: e.value })
            }
        } else
            this.setState({ pickDateEnd: e.value })
    }

    onDeliveryDateChange(e) { //date in order edit 
        //if (this.state.pickDateStart < e.value) {
        this.setState({ deliveryDate: e.value })
        //}
    }
    // onPickupDateChange(e){
    //     console.log(e)
    //     this.setState({ pickDate: e.value })
    //     var dateEnd = e.value[1]
    //     if(dateEnd != null){
    //         dateEnd.setUTCHours(16)
    //         dateEnd.setUTCMinutes(59)
    //         dateEnd.setUTCSeconds(59)
    //     }
    //     this.setState({ pickDateStart: e.value[0], pickDateEnd: dateEnd})
    // }

    onDate3Change(e) { //date in search order
        //console.log(e.value.toISOString())
        if (this.state.date4 != null) {
            if (e.value <= this.state.date4) {
                this.setState({ date3: e.value })
            }
        } else
            this.setState({ date3: e.value })
    }
    onDate4Change(e) { //date in search order
        if (this.state.date3 <= e.value) {
            this.setState({ date4: e.value })
        }
    }

    onDate1Change(e) { //date in search order
        //console.log(e.value.toISOString())
        if (this.state.date2 != null) {
            if (e.value <= this.state.date2) {
                this.setState({ date1: e.value })
            }
        } else
            this.setState({ date1: e.value })
    }
    onDate2Change(e) { //date in search order
        if (this.state.date1 <= e.value) {
            this.setState({ date2: e.value })
        }
    }

    addDataToTable() {
        var isNotinput = (this.state.wasteTypeSelected == '' ||
            this.state.productSelected == '' ||
            this.state.amountSelected == '' ||
            this.state.appraisedPriceSelected == '' ||
            this.state.salePriceSelected == ''
        );

        if (isNotinput) {
            this.showWarn();
        } else {
            var OrderDetailList = {
                gid: this.state.gid,
                product_type_code: this.state.wasteTypeSelected.product_type_code,
                product_code: this.state.productSelected.product_code,
                product_name: this.state.productSelected.product_name,
                // wasteType: this.state.wasteTypeSelected.product_type_name,
                // amount: parseFloat(this.state.amountSelected).toFixed(2),
                // appraisedPrice: parseFloat(this.state.appraisedPriceSelected).toFixed(2),
                // salePrice: parseFloat(this.state.salePriceSelected).toFixed(2),    

                delivery_quantity_kk: 0,
                delivery_price_per_unit: 0,
                quantity_kk: parseFloat(this.state.amountSelected).toFixed(2),
                estimate_price_per_unit: parseFloat(this.state.appraisedPriceSelected).toFixed(2),
                price_per_unit: parseFloat(this.state.salePriceSelected).toFixed(2),
            }
            this.state.orderDetail.push(OrderDetailList)

            this.setState({ wasteTypeSelected: '' })
            this.setState({ productSelected: '' })
            this.setState({ amountSelected: '' })
            this.setState({ appraisedPriceSelected: '' })
            this.setState({ salePriceSelected: '' })
            console.log(this.state.orderDetail)
            this.showSuccess();
        }
    }
    onWasteTypeChange(e) {

        this.setState({ wasteTypeSelected: e.value });
        this.setState({ productTypeCode: e.value.product_type_code });

        const { masterServiceProps, product } = this.props
        masterServiceProps.getProduct(e.value.product_type_code);

    }
    onproductsChange(e) {
        this.setState({ productSelected: e.value });
    }

    onUpload(event) {
        this.growl.show({ severity: 'info', summary: 'Success', detail: 'File Uploaded' });
    }

    // onFileWasteChanged(e) {
    //     console.log(e)
    //     //this.setState({ fileImgIden: e.target.files[0] });
    //     this.setState({ fileImgWaste: e.files[0] });
    // }

    //auto complate handle (ผู้รักษ์โลก)
    suggestBrands(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */
        //this.setState({ brandSuggestions: event });
    }
    onUsernameChanged(search_name) {
        this.setState({ brand: search_name });
        if (search_name.length >= 2) {
            ServerAPI.searchMemberName(search_name).then((res) => {
                console.log(res);
                this.setState({ brandSuggestions: res });

            }).catch((error) => {
            })
        }
    }
    // onSelectAutoCompleteSearchName(item) {
    //     this.setState({ brand: item.value.search_name });
    //     this.setState({ u1_uid: item.value.uid })
    //     this.getProfile(item.value.uid)
    // }

    itemTemplate(item) {
        return (
            <div className="p-clearfix">
                {item.search_name}
            </div>
        );
    }

    //auto complate handle (ค้นหาสมาชิก)
    suggestMemberName(event) {
        console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */

        //this.setState({ brandSuggestions: event });
    }
    onMemberNameChanged(search_name) {
        console.log(search_name)
        console.log(typeof (search_name))
        if (typeof (search_name) != "object")
            if (search_name.trim() != "") {
                this.setState({ memberName: search_name });
                if (search_name.length >= 2) {
                    ServerAPI.searchwasteAll(search_name).then((res) => {
                        console.log(res);
                        this.setState({ memberSuggestions: res });
                    }).catch((error) => {
                    })
                }
            } else {
                this.setState({ memberName: search_name });
                this.setState({ memberUid: null });
            }
    }
    onSelectMemberName(item) {
        this.setState({ memberName: item.value.search_name });
        this.setState({ memberUid: item.value.uid });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }

    //auto complate handle (ค้นหา Order ID)
    suggestOrderID(event) {
        //console.log(event);
        /*
         if(this.state.brandSuggestions){
             let results = this.state.brandSuggestions.filter((brand) => {
                 return brand.search_name.toLowerCase().startsWith(event.query.toLowerCase());
             });
         }
       */
        //this.setState({ brandSuggestions: event });
    }
    onOrderKeyPress(e) {
        /*
        var code = "";
        
        if(e.which!=32) {
            code = e.target.value + e.key
        } else{
            console.log("space bar");
        }
        console.log(code);
        
        var code = e.query;
        if(e.which!=32){
            this.setState({ orderID: code });
            if (code.length > 1) {
                ServerAPI.autoComplateOrderID(code).then((res) => {
                    console.log(res);
                    this.setState({ orderIDSuggestions: res });
                }).catch((error) => {
                })
            }
        }else{
            return ;
        }*/
    }
    onOrderIDChanged(code) {
        //console.log(code)
        if (code.trim() != "") {
            this.setState({ orderID: code });
            if (code.length > 1) {
                ServerAPI.autoComplateOrderID(code).then((res) => {
                    console.log(res);
                    this.setState({ orderIDSuggestions: res });
                }).catch((error) => {
                })
            }
        } else this.setState({ orderID: code });


    }
    onSelectOrderID(item) {
        this.setState({ orderID: item.value });
        // this.setState({u1_uid: item.value.uid})
        // this.getProfile(item.value.uid)
    }
    itemOrderIDTemplate(item) {
        return (
            <div className="p-clearfix">
                {item}
            </div>
        );
    }

    onCenterChanged = (coor) => {
        console.log("onCenterChanged");
        console.log(this.state.mapGenMarker);
        if (coor) {
            this.setState({ latitude: coor.lat })
            this.setState({ longitude: coor.lng })
            this.setState({ mapGenMarker: { lat: coor.lat, lng: coor.lng } })
            let searchProModel = {
                latitude: coor.lat,
                longitude: coor.lng,
                distance_km: 10
            }
            this.requestProLocation(searchProModel);
        }

    }

    onMarkerMoved(e) {
        console.log("onMarkerMoved");
        console.log(coor);
        var coor = e.latLng.toJSON();
        this.setState({ mapGenMarker: coor })
        this.setState({ latitude: coor.lat })
        this.setState({ longitude: coor.lng })
        //this.setState({ longitude: coor.lng })
        //this.setState({ marker: { lat: coor.lat, lng: coor.lng } })
        this.setState({ mapCenter: { lat: coor.lat, lng: coor.lng } })
        let searchProModel = {
            latitude: coor.lat,
            longitude: coor.lng,
            distance_km: 10
        }
        this.requestProLocation(searchProModel);
    }

    requestProLocation(searchProModel) {
        ServerAPI.getProLocation(searchProModel).then((res) => {
            console.log(res);
            this.setState({ wasteproDataList: res });
            const markers = [];
            res.forEach(loc => {
                markers.push({ lat: loc.lat, lng: loc.lng });
            });
            this.setState({ mapProMarkers: markers });
            this.setState({ mapProCenter: { lat: searchProModel.latitude, lng: searchProModel.longitude } });
            this.setState({ showProgress: false })
            if (this.state.mapProZoom == 12) {
                this.setState({ mapProZoom: 13 });
            } else {
                this.setState({ mapProZoom: 12 });
            }

        }).catch((error) => {
        })
    }

    bindingProfile() {
        const {
            locationMaster,
            provinces,
            districts,
            subDistricts,
            localtionServiceProps,
        } = this.props
        if (this.state.provinceCode && !locationMaster.isFetching) {
            provinces.forEach(objProvince => {
                // console.log(JSON.stringify(objProvince));
                if (objProvince.provinceCode == this.state.provinceCode) {
                    this.setState({ provinceSelected: objProvince });
                    this.setState({ genAddressProvince: objProvince.province_name })
                }
            });
        }
        if (this.state.districtCode) {
            this.state.districtList.forEach(objDistrict => {
                if (objDistrict.districtCode == this.state.districtCode) {
                    this.setState({ districtSelected: objDistrict });
                    this.setState({ genAddressDistrict: objDistrict.districtName })
                }
            });
        }
        if (this.state.subDistrictCode) {
            this.state.subDistrictList.forEach(objSubDistrict => {
                if (objSubDistrict.subDistrictCode == this.state.subDistrictCode) {
                    this.setState({ subDistrictSelected: objSubDistrict });
                }
            });
        }
    }
    onTableProSelected(e) {
        this.setState({ dataTableSelection2: '' })
        this.setState({ u2_uid: '' })
        console.log(e.value);
        this.setState({ dataTableSelection3: e.value })
        this.setState({ proImg: e.value.img_person_name })
        this.setState({ proName: e.value.pro_name })
        this.setState({ proAddress: e.value.address_detail })
        this.setState({ proPhone: e.value.telephone_no1 })
        this.setState({ u3_uid: e.value.uid })


        this.setState({ mapProCenter: { lat: e.value.lat, lng: e.value.lng } });
        if (this.state.mapProZoom == 12) {
            this.setState({ mapProZoom: 13 });
        } else {
            this.setState({ mapProZoom: 12 });
        }

        var marder = { lat: e.value.lat, lng: e.value.lng };
        this.setState({ mapTranMarker: marder });

        let searchTranModel = {
            latitude: e.value.lat,
            longitude: e.value.lng,
            distance_km: 10,
            u3_uid: e.value.uid
        }
        ServerAPI.getTranLocation(searchTranModel).then((res) => {
            console.log(res);
            this.setState({ wastetranDataList: res });
            const markers = [];
            res.forEach(loc => {
                markers.push({ lat: loc.lat, lng: loc.lng });
            });
            this.setState({ mapTranMarkers: markers });
            this.setState({ mapTranCenter: { lat: e.value.lat, lng: e.value.lng } });
            if (this.state.mapTranZoom == 12) {
                this.setState({ mapTranZoom: 13 });
            } else {
                this.setState({ mapTranZoom: 12 });
            }

        }).catch((error) => {
        })

    }

    onTableTranSelected(e) {
        console.log(e.value);
        this.setState({ dataTableSelection2: e.value })
        this.setState({ tranImg: e.value.img_person_name })
        this.setState({ tranName: e.value.tran_name })
        this.setState({ tranAddress: e.value.address_detail })
        this.setState({ tranPhone: e.value.telephone_no1 })
        this.setState({ u2_uid: e.value.uid })

        this.setState({ mapTranCenter: { lat: e.value.lat, lng: e.value.lng } });
        if (this.state.mapTranZoom == 12) {
            this.setState({ mapTranZoom: 13 });
        } else {
            this.setState({ mapTranZoom: 12 });
        }
    }
    mapChanged(e) {
        console.log(e);
    }
    onMarkerClicked(e) {
        console.log("onMarkerClicked");
        var coor = e.latLng.toJSON();
        console.log(coor);
        this.selectedTranByLatLng(coor);

    }
    onMarkerProClicked(e) {
        console.log("onMarkerClicked");
        var coor = e.latLng.toJSON();
        console.log(coor);
        this.selectedProByLatLng(coor);
    }

    showSuccess = (message) => {
        this.growl.show({ severity: 'success', summary: 'สถานะ', detail: message ? message : 'ทำรายการสำเร็จ' });
    }
    showWarn = (message) => {
        this.growl.show({ severity: 'warn', summary: 'สถานะ', detail: message ? message : 'มีบางอย่างผิดพลาด กรุณาตรวจสอบ' });
    }
    clearAllDataInput() {
        this.setState({ orderCode: null });//order code from select table
        this.setState({ brand: '' });
        this.setState({ addressDetail: '' });
        this.setState({ provinceSelected: '' });
        this.setState({ districtSelected: '' });
        this.setState({ subDistrictSelected: '' });
        this.setState({ lineId: '' });
        this.setState({ email: '' });
        this.setState({ genPhone: '' });
        this.setState({ pickDateStart: null });
        this.setState({ pickDateEnd: null });
        this.setState({ deliveryDate: null });
        this.setState({ deliveryCost: '' })
        this.setState({ wasteTypeSelected: '' });
        this.setState({ productSelected: '' });
        this.setState({ amountSelected: '' });
        this.setState({ appraisedPriceSelected: '' });
        this.setState({ salePriceSelected: '' });
        var OrderDetailList = {}
        this.state.orderDetail.push(OrderDetailList)
        this.setState({ orderDetail: [] });
        this.setState({ wastetranDataList: '' });
        this.setState({ wasteproDataList: '' });
        this.setState({ personPrefix: '' });
        this.setState({ genFname: '' });
        this.setState({ genLname: '' });
        this.setState({ genAddressProvince: '' });
        this.setState({ genAddressDistrict: '' });
        this.setState({ genImg: '' });
        this.setState({ tranName: '' });
        this.setState({ tranImg: '' });
        this.setState({ tranAddress: '' });
        this.setState({ tranPhone: '' });
        this.setState({ proName: '' });
        this.setState({ proImg: '' });
        this.setState({ proAddress: '' });
        this.setState({ proPhone: '' });
        this.setState({ mapGenMarker: {} });
        this.setState({ mapTranMarker: {} });
        this.setState({ mapProMarker: {} });
        this.setState({ mapTranMarkers: [] });
        this.setState({ mapProMarkers: [] });
        this.setState({ mapGenCenter: { lat: 13.7734283, lng: 100.57383099999993 } });
        this.setState({ mapTranCenter: { lat: 13.7734283, lng: 100.57383099999993 } });
        this.setState({ mapProCenter: { lat: 13.7734283, lng: 100.57383099999993 } });
        this.setState({ mapGenZoom: 11 });
        this.setState({ mapTranZoom: 11 });
        this.setState({ mapProZoom: 11 });
        this.setState({ dataTableSelection2: '' });
        this.setState({ dataTableSelection3: '' });
        this.setState({ u2_uid: '' });
        this.setState({ u3_uid: '' });
        this.setState({ tranUid: '' });
        this.setState({ proUid: '' });
        this.setState({ fileImgWaste: null });
        this.setState({ showHideSearch: true })
        this.setState({ showReportTable: true })


    }
    clearSearchInput() {
        this.setState({ orderCode: null }); //order code from select table
        this.setState({ orderID: null }); //order code from auto complete
        this.setState({ memberUid: null });
        this.setState({ memberName: '' });
        this.setState({ memberType: 0 });
        this.setState({ date3: null });
        this.setState({ date4: null });
        this.setState({ date1: null });
        this.setState({ date2: null });
        this.setState({ status: 1000 });
        this.setState({ selectDay: null });
        var reportSearchList = {}
        this.state.reportSearchOrders.push(reportSearchList)
        this.setState({ reportSearchOrders: [] });

        this.clearAllDataInput();
    }
    onClickSaveOrder() {
        var inputAll = !(this.state.addressDetail == '' || this.state.provinceSelected == '' || this.state.districtSelected == '' ||
            this.state.subDistrictSelected == '' || this.state.provinceSelected == null || this.state.districtSelected == null ||
            this.state.subDistrictSelected == null || this.state.genPhone == '' || this.state.pickDateStart == null || this.state.deliveryDate == null ||
            this.state.pickDateStart == '' || this.state.deliveryDate == '' || this.state.orderDetail == '' || this.state.isNotValid
            // this.state.wastetranDataList == '' || this.state.wasteproDataList == '' ||
            // this.state.dataTableSelection2 == '' || this.state.dataTableSelection3 == ''
        );
        if (!inputAll) {
            this.showWarn();
        } else {
            this.updateOrder();
        }
    }
    updateOrder() {
        console.log(this.state);
        const formData = new FormData();
        // formData.set('ProductImages',this.state.googleId);
        // formData.set('ProductImageNames',this.state.googleId);
        if (this.state.fileImgWaste != null)
            for (let index = 0; index < this.state.fileImgWaste.length; index++) {
                const file = this.state.fileImgWaste[index];
                formData.append('ProductImages', file);
            }
        formData.set('OrderDetailListString', JSON.stringify(this.state.orderDetail) || null);
        //formData.set('OrderDetail', this.state.orderDetail || null);
        formData.set('order_code', this.state.orderCode || null);
        formData.set('order_status', this.state.status2 || 0);
        formData.set('u1_uid', this.state.u1_uid || '');
        formData.set('u2_uid', this.state.u2_uid || '');
        formData.set('u3_uid', this.state.u3_uid || '');
        formData.set('admin_name', this.state.adminName || 0);
        formData.set('person_type', this.state.personType || 0);
        formData.set('person_prefix_code', this.state.personPrefixCode || 0);
        formData.set('person_first_name', this.state.genFname || '');
        formData.set('person_last_name', this.state.genLname || '');
        formData.set('person_idcard', this.state.personIdcard || '');
        formData.set('org_type', this.state.orgTypeCode || 0);
        formData.set('org_name', this.state.orgName || '');
        formData.set('telephone_no1', this.state.genPhone || '');
        formData.set('telephone_no2', this.state.genPhone2 || '');
        formData.set('line_id', this.state.lineId || '');
        formData.set('email', this.state.email || '');
        formData.set('addr_current_province_code', this.state.provinceCode || 0);
        formData.set('addr_current_district_code', this.state.districtCode || 0);
        formData.set('addr_current_subdistrict_code', this.state.subDistrictCode || 0);
        formData.set('addr_current_detail', this.state.addressDetail || '');
        formData.set('latitude', this.state.latitude || 0);
        formData.set('longitude', this.state.longitude || 0);
        formData.set('pickup_start_date', this.state.pickDateStart.toISOString() || '');
        formData.set('pickup_end_date', this.state.pickDateEnd.toISOString() || '');
        formData.set('delivery_date', this.state.deliveryDate.toISOString() || '');
        formData.set('delivery_cost', this.state.deliveryCost || 0);
        formData.set('remark', this.state.memo || '');
        formData.set('u3_remark', this.state.u3Remark || null);
        formData.set('delivery_vehicle_type_remark', this.state.deliveryVehicleTypeRemark || null);
        formData.set('delivery_distance_km', this.state.deliveryDistanceKm || 0);
        // formData.set('created_date',this.state.googleId);
        // formData.set('update_date',this.state.googleId);
        ServerAPI.updateOrder(formData).then((res) => {
            console.log("response");
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    this.showSuccess();
                    this.clearAllDataInput();
                    this.searchOrder();
                } else {
                    this.showWarn();
                }

            } else {
                this.showWarn();
            }
        }).catch((error) => {
        })
    }

    // statusItem: [
    //     { label: 'รอนัดหมาย', value: 1 },
    //     { label: 'รอทีมขนส่ง', value: 2 },
    //     { label: 'รอส่งสินค้า', value: 3 },
    //     { label: 'รอปิด Order', value: 4 },
    //     { label: 'ปิด Order', value: 88 },
    //     { label: 'ยกเลิก', value: 99 },
    //     { label: 'ผิดนัด', value: 999 },
    // ],

    showStatus() {
        var statusName = ""
        switch (this.state.status2) {
            case 0:
                statusName = "รอร้านค้ายืนยัน";
                break
            case 1:
                statusName = "รอนัดหมาย";
                break
            case 2:
                statusName = "รอทีมขนส่ง";
                break
            case 3:
                statusName = "รอส่งสินค้า";
                break
            case 4:
                statusName = "รอปิด Order";
                break
            case 5:
                statusName = "เกินกำหนดรับสินค้า";
                break
            case 88:
                statusName = "ปิด Order";
                break
            case 99:
                statusName = "ยกเลิก";
                break
            case 999:
                statusName = "ผิดนัด";
                break
            case 998:
                statusName = "ร้านค้าปฏิเสธรับซื้อ";
                break

        }
        return statusName;

    }

    showWastegenName() {
        const genPersonName = (
            <span style={{ color: 'blue' }}>{this.state.personPrefix} {this.state.genFname} {this.state.genLname} </span>
        );
        const genOrgName = (
            <span style={{ color: 'blue' }}>{this.state.orgType} {this.state.orgName} </span>
        );

        if (this.state.personType == 1) {
            return genPersonName;
        } if (this.state.personType == 2) {
            return genOrgName;
        }
    }

    showWastegen() {
        const genPersonName = (
            <div className="p-col-12">
                <span style={{ color: 'blue' }}>{this.state.personPrefix} {this.state.genFname} {this.state.genLname} </span><br />
                <span>{this.state.genAddressProvince} {this.state.genAddressDistrict} <br /> เบอร์ติดต่อ : {this.state.genPhone}</span>
            </div>
        );
        const genOrgName = (
            <div className="p-col-12">
                <span style={{ color: 'blue' }}>{this.state.orgType} {this.state.orgName} </span><br />
                <span>{this.state.genAddressProvince} {this.state.genAddressDistrict} <br /> เบอร์ติดต่อ : {this.state.genPhone}</span>
            </div>
        );

        if (this.state.personType == 1) {
            return genPersonName;
        } if (this.state.personType == 2) {
            return genOrgName;
        }
    }
    showWastetran() {
        const tranPersonName = (
            <div className="p-col-12">
                <span style={{ color: 'red' }}>{this.state.tranName} </span><br />
                <span>{this.state.tranAddress} <br /> เบอร์ติดต่อ : {this.state.tranPhone}</span>
            </div>
        );
        // const tranOrgName = (
        //     <div className="p-col-12">
        //         <span style={{ color: 'blue' }}>{this.state.orgType} {this.state.orgName} </span>
        //         <span>{this.state.genAddress} , Mob : {this.state.genPhone}</span>
        //     </div>
        // );

        // if(this.state.personType == 1){
        //     return tranPersonName;
        // }if(this.state.personType == 2){
        //     return tranOrgName;
        // }
        return tranPersonName;
    }
    showWastepro() {
        const proPersonName = (
            <div className="p-col-12">
                <span style={{ color: 'green' }}>{this.state.proName} </span><br />
                <span>{this.state.proAddress} <br /> เบอร์ติดต่อ : {this.state.proPhone}</span>
            </div>
        );
        // const proOrgName = (
        //     <div className="p-col-12">
        //         <span style={{ color: 'blue' }}>{this.state.orgType} {this.state.orgName} </span>
        //         <span>{this.state.genAddress} , Mob : {this.state.genPhone}</span>
        //     </div>
        // );

        // if(this.state.personType == 1){
        //     return proPersonName;
        // }if(this.state.personType == 2){
        //     return proOrgName;
        // }
        return proPersonName;
    }
    itemProductImgTemplate(item, layout) {

        const src = API_ROOT + "/order/getproductimg/" + item;
        return (

            <div className="p-col-12 p-md-4">
                <img alt="Product Image" width="120" src={src} onClick={(e) => this.setState({ selectedProductImg: src })} />
            </div>

        );

    }
    searchOrder() {
        this.setState({ showReportTable: true })

        this.setState({ showProgress: true })
        var reportSearchList = {}
        this.state.reportSearchOrders.push(reportSearchList)
        this.setState({ reportSearchOrders: [] });
        // const formData = new FormData();
        // formData.set('order_code', this.state.orderID || '');
        // formData.set('user_search_name', this.state.memberName || '');
        // formData.set('user_wasteTypes', this.state.memberType || 0);
        // formData.set('pickup_date_start', this.state.date3.toISOString() || '');
        // formData.set('pickup_date_end', this.state.date4.toISOString() || '');
        // //formData.set('status_code', this.state.status || 0);
        // //formData.set('admin_code', this.state.genFname || '');

        var searchModel = {
            order_code: this.state.orderID,
            user_search_name: this.state.memberUid,
            user_wasteTypes: this.state.memberType,
            order_status: this.state.status,
            admin_code: "",
        };

        if (this.state.date3 != null) {
            //Bankok Time zone (+7hrs)
            var date3 = new Date(this.state.date3.getTime() + 7 * 60 * 60000);
            searchModel.pickup_date_start = date3.toISOString();
        }
        if (this.state.date4 != null) {
            var date4 = new Date(this.state.date4.getTime() + 7 * 60 * 60000);
            searchModel.pickup_date_end = date4.toISOString();
        }
        if (this.state.date1 != null) {
            //Bankok Time zone (+7hrs)
            var date1 = new Date(this.state.date1.getTime() + 7 * 60 * 60000);
            searchModel.delivery_date_start = date1.toISOString();
        }
        if (this.state.date2 != null) {
            var date2 = new Date(this.state.date2.getTime() + 7 * 60 * 60000);
            searchModel.delivery_date_end = date2.toISOString();
        }
        console.log(searchModel);


        ServerAPI.searchOrder(searchModel).then((res) => {
            console.log("response");
            if (res) {
                var index = res.toString().indexOf("Error:");
                console.log("find error:" + (index));
                if (index == -1) {
                    console.log(res);
                    if (res.length > 0) {

                        let data = []
                        res.forEach(element => {
                            var obj = {
                                'order_code': element['order_code'],
                                'u1_fullname': element['u1_fullname'],
                                'u2_fullname': element['u2_fullname'],
                                'u3_fullname': element['u3_fullname'],
                                'pickup_start_date': element['pickup_start_date'] ? `${moment(element['pickup_start_date']).format("DD-MM-YYYY HH:mm:ss")}` : "",
                                'pickup_end_date': element['pickup_end_date'] ? `${moment(element['pickup_end_date']).format("DD-MM-YYYY HH:mm:ss")}` : "",
                                'delivery_date': element['delivery_date'] ? `${moment(element['delivery_date']).format("DD-MM-YYYY HH:mm:ss")}` : "",
                                'delivery_cost': element['delivery_cost'],
                                'order_status_name': element['order_status_name'],
                                'admin_name': element['admin_name'],
                            }

                            data.push(obj)
                        });
                        this.setState({ reportSearchOrders: data.reverse() })
                        this.setState({ showProgress: false })
                    } else {
                        this.showWarn("ไม่พบข้อมูล");
                        this.setState({ showProgress: false })
                    }
                    // this.showSuccess();
                    // this.clearAllDataInput();
                } else {
                    this.showWarn();
                }

            } else {
                this.showWarn();
            }
        }).catch((error) => {
        })
    }

    onOrderSelected(e) {
        this.clearAllDataInput();
        console.log(e.value);
        this.setState({ orderSelection: e.value })

        this.setState({ orderCode: e.value.order_code })
        this.getOrder(e.value.order_code) //get 1 order from table for update

    }

    //get 1 order from table for update
    getOrder(code) {
        this.setState({ showReportTable: false })
        this.setState({ showHideSearch: false })
        this.setState({ showProgress: true });
        this.setState({ showReportPanel: false })
        ServerAPI.getOrderDetail(code).then((obj) => {
            console.log(obj);
            this.setState({ addressDetail: obj.addr_current_detail })
            this.setState({ provinceCode: obj.addr_current_province_code })
            this.setState({ districtCode: obj.addr_current_district_code })
            this.setState({ subDistrictCode: obj.addr_current_subdistrict_code })
            this.setState({ lineId: obj.line_id })
            this.setState({ email: obj.email })
            this.setState({ genImg: obj.img_person_name })
            this.setState({ genPhone: obj.telephone_no1 })
            this.setState({ genFname: obj.person_first_name })
            this.setState({ genLname: obj.person_last_name })
            this.setState({ personType: obj.person_type })
            this.setState({ personPrefixCode: obj.person_prefix_code })
            this.setState({ personIdcard: obj.person_idcard })
            this.setState({ latitude: obj.latitude })
            this.setState({ longitude: obj.longitude })
            this.setState({ adminName: obj.admin_name })
            this.setState({ productImageNames: obj.productImageNames })
            this.setState({ memo: obj.remark })
            this.setState({ u3Remark: obj.u3_remark })
            this.setState({ deliveryVehicleTypeRemark: obj.delivery_vehicle_type_remark })
            this.setState({ deliveryDistanceKm: obj.delivery_distance_km })

            if (this.state.personPrefixCode == 1) {
                this.setState({ personPrefix: 'นาย' })
            } if (this.state.personPrefixCode == 2) {
                this.setState({ personPrefix: 'นาง' })
            } if (this.state.personPrefixCode == 3) {
                this.setState({ personPrefix: 'นางสาว' })
            }
            this.setState({ orgTypeCode: obj.org_type })
            if (this.state.orgTypeCode == 1) {
                this.setState({ orgType: 'บริษัทจำกัด' })
            } if (this.state.orgTypeCode == 2) {
                this.setState({ orgType: 'ห้างหุ้นส่วน' })
            } if (this.state.orgTypeCode == 3) {
                this.setState({ orgType: 'บริษัทมหาชน' })
            } if (this.state.orgTypeCode == 4) {
                this.setState({ orgType: 'ห้างร้าน' })
            }
            this.setState({ orgName: obj.org_name })

            var genMarder = { lat: obj.latitude, lng: obj.longitude };
            this.setState({ mapGenCenter: genMarder })
            this.setState({ mapGenMarker: genMarder })
            if (this.state.mapGenZoom == 12) {
                this.setState({ mapGenZoom: 12 });
            } else {
                this.setState({ mapGenZoom: 13 });
            }

            var marder = { lat: obj.latitude, lng: obj.longitude };
            this.setState({ mapProMarker: marder });

            ServerAPI.getDistrict(obj.addr_current_province_code).then((res) => {
                this.setState({ districtList: res });
                ServerAPI.getSubDistrict(obj.addr_current_district_code).then((res) => {
                    this.setState({ subDistrictList: res });
                    this.bindingProfile();
                });
            });




            var p_start = new Date(obj.pickup_start_date);
            var p_end = new Date(obj.pickup_end_date);
            var date = [p_start, p_end]
            this.setState({ pickDate: date });
            this.setState({ pickDateStart: p_start });
            this.setState({ pickDateEnd: p_end });
            var delivery_end = new Date(obj.delivery_date);
            this.setState({ deliveryDate: delivery_end });

            this.setState({ deliveryCost: obj.delivery_cost })
            this.setState({ orderDetail: obj.orderDetail }) //ตาราง รายการสินค้า
            this.setState({ orderListString: obj.orderDetailListString })//รายละเอียดสินค้า string ??
            this.setState({ statusName: obj.order_status_name })
            this.setState({ status2: obj.order_status })
            this.setState({ u1_uid: obj.u1_uid })
            this.setState({ tranUid: obj.u2_uid })
            this.setState({ proUid: obj.u3_uid })


            let searchProModel = {
                latitude: obj.latitude,
                longitude: obj.longitude,
                distance_km: 100
            }
            ServerAPI.getProLocation(searchProModel).then((res) => {
                console.log(res);
                this.setState({ wasteproDataList: res }, this.selectedPro);

                const markers = [];
                res.forEach(loc => {
                    markers.push({ lat: loc.lat, lng: loc.lng });
                });
                this.setState({ mapProMarkers: markers });
            }).catch((error) => {
            })
            //this.setState({ dataTableSelection3: obj.u3_uid })
        }).catch((error) => {
        })
    }

    selectedPro() {
        console.log("selectedTran" + this.state.proUid)
        if (!this.state.proUid) {
            this.selectedTran();
        } else {
            this.state.wasteproDataList.forEach((objPro, index) => {
                if (objPro.uid == this.state.proUid) {
                    this.setState({ dataTableSelection3: objPro })
                    this.setState({ proName: objPro.pro_name })
                    this.setState({ proAddress: objPro.address_detail })
                    this.setState({ proPhone: objPro.telephone_no1 })
                    this.setState({ u3_uid: objPro.uid })

                    var marder = { lat: objPro.lat, lng: objPro.lng };
                    this.setState({ mapTranMarker: marder });
                    this.setState({ mapProCenter: marder });

                    let searchTranModel = {
                        latitude: objPro.lat,
                        longitude: objPro.lng,
                        distance_km: 50,
                        u3_uid: objPro.uid
                    }
                    ServerAPI.getTranLocation(searchTranModel).then((res) => {
                        console.log(res);
                        this.setState({ wastetranDataList: res }, this.selectedTran);
                        const markers = [];
                        res.forEach(loc => {
                            markers.push({ lat: loc.lat, lng: loc.lng });
                        });
                        this.setState({ mapTranMarkers: markers });
                    }).catch((error) => {
                    })

                    this.state.wasteproDataList.splice(index, 1);
                    this.state.wasteproDataList.unshift(objPro);
                }
            });
        }
    }
    selectedTran() {
        console.log("selectedPro" + this.state.tranUid)
        if (!this.state.tranUid) {
            this.setState({ showProgress: false });
            this.setState({ showReportPanel: true })
        } else {
            this.state.wastetranDataList.forEach((objTran, index) => {
                if (objTran.uid == this.state.tranUid) {
                    this.setState({ dataTableSelection2: objTran })
                    this.setState({ tranName: objTran.tran_name })
                    this.setState({ tranAddress: objTran.address_detail })
                    this.setState({ tranPhone: objTran.telephone_no1 })
                    this.setState({ u2_uid: objTran.uid })

                    var marder = { lat: objTran.lat, lng: objTran.lng };
                    this.setState({ mapTranCenter: marder });

                    this.state.wastetranDataList.splice(index, 1);
                    this.state.wastetranDataList.unshift(objTran);
                }
                this.setState({ showProgress: false });
                this.setState({ showReportPanel: true })
            });
        }

    }
    selectedProByLatLng(coor) {
        this.setState({ dataTableSelection2: '' })
        this.setState({ u2_uid: '' })
        this.state.wasteproDataList.forEach(objPro => {
            console.log("objPro.lat:" + objPro.lat);
            console.log("coor.lat   :" + coor.lat.toFixed(12));
            console.log("objPro.lng:" + objPro.lng);
            console.log("coor.lng   :" + coor.lng.toFixed(12));
            if (objPro.lat.toFixed(12) == coor.lat.toFixed(12) && objPro.lng.toFixed(12) == coor.lng.toFixed(12)) {
                console.log("ตรง");
                this.setState({ dataTableSelection3: objPro })
                this.setState({ proName: objPro.pro_name })
                this.setState({ proAddress: objPro.address_detail })
                this.setState({ proPhone: objPro.telephone_no1 })
                this.setState({ u3_uid: objPro.uid })

                var marder = { lat: objPro.lat, lng: objPro.lng };
                this.setState({ mapTranMarker: marder });

                let searchTranModel = {
                    latitude: objPro.lat,
                    longitude: objPro.lng,
                    distance_km: 10,
                    u3_uid: objPro.uid
                }
                ServerAPI.getTranLocation(searchTranModel).then((res) => {
                    console.log(res);
                    this.setState({ wastetranDataList: res });
                    const markers = [];
                    res.forEach(loc => {
                        markers.push({ lat: loc.lat, lng: loc.lng });
                    });
                    this.setState({ mapTranMarkers: markers });
                }).catch((error) => {
                })
            }
        });
    }


    selectedTranByLatLng(coor) {

        this.state.wastetranDataList.forEach(objTran => {

            if (objTran.lat.toFixed(12) == coor.lat.toFixed(12) && objTran.lng.toFixed(12) == coor.lng.toFixed(12)) {

                this.setState({ dataTableSelection2: objTran })
                this.setState({ tranImg: objTran.img_person_name })
                this.setState({ tranName: objTran.tran_name })
                this.setState({ tranAddress: objTran.address_detail })
                this.setState({ tranPhone: objTran.telephone_no1 })
                this.setState({ u2_uid: objTran.uid })
            }
        });
    }

    //edit table handle
    onEditorQuantityChange(props, value) {
        if (value == "") {// null set to 0
            value = '0';
        } else {
            var regex = /^[0-9]*(\.[0-9]{0,2})?$/;
            if (!regex.test(value)) { // set 2 decimal if more decimal
                if (value % 1 !== 0) {
                    value = parseFloat(value).toFixed(2)
                }
            }
            if (value.indexOf('.') < 1) {//no 0 leading
                value = parseFloat(value)
            }
        }
        let updatedCars = [...props.value];
        updatedCars[props.rowIndex][props.field] = value;
        this.setState({ orderDetail: updatedCars });
    }
    onEditorTextChange(props, value) {
        let updatedCars = [...props.value];
        updatedCars[props.rowIndex][props.field] = value;
        this.setState({ orderDetail: updatedCars });
    }
    inputQuantityEditor(props, field) {
        return <InputText type="text" keyfilter="money" value={props.rowData[field]} onChange={(e) => this.onEditorQuantityChange(props, e.target.value)} />;
    }
    inputTextEditor(props, field) {
        return <InputText type="text" value={props.rowData[field]} onChange={(e) => this.onEditorTextChange(props, e.target.value)} />;
    }
    quantityEditor(props) {
        return this.inputQuantityEditor(props, 'quantity_kk');
    }
    estimatePricePerUnitEditor(props) {
        return this.inputQuantityEditor(props, 'estimate_price_per_unit');
    }
    pickupQuantityEditor(props) {
        return this.inputQuantityEditor(props, 'pickup_quantity_kk');
    }
    deliveryQuantityEditor(props) {
        return this.inputQuantityEditor(props, 'delivery_quantity_kk');
    }
    deliveryPricePerUnitEditor(props) {
        return this.inputQuantityEditor(props, 'price_per_unit');
    }

    remarkEditor(props) {
        return this.inputTextEditor(props, 'remark');
    }

    actionTemplate(rowData, column) {
        //console.log(rowData);
        let price = (rowData.quantity_kk * rowData.estimate_price_per_unit).toFixed(2);
        return <div>
            <span> {price}</span>
        </div>;
    }
    actionDeliveryPriceTemplate(rowData, column) {
        //console.log(rowData);
        let price = (rowData.delivery_quantity_kk * rowData.price_per_unit).toFixed(2);
        return <div>
            <span> {price}</span>
        </div>;
    }
    distanceTemplate(rowData, column) {
        let distance = rowData.distance_km.toFixed(3);
        return <div>
            <span> {distance}</span>
        </div>;
    }
    toFormatDateStartToEnd(dateStart, dateEnd) {
        return <div><Moment format="DD/MM/YYYY">
            {dateStart}
        </Moment><br />ถึง<br /><Moment format="DD/MM/YYYY">
                {dateEnd}
            </Moment></div>;
    }
    toFormatDateTime(dateTime) {
        return <span><Moment format="DD/MM/YYYY">
            {dateTime}
        </Moment> <Moment format="HH:mm">
                {dateTime}
            </Moment></span>;
    }
    to_transfer_date(rowData, column) {
        const date = moment(rowData.delivery_date, 'DD-MM-YYYY HH:mm:ss').toDate();
        const year = date.getFullYear;
        if (year == -1899)
            return <span></span>;
        else
            if (rowData.delivery_date == "") return <div></div>;
        return <div>
            {rowData.delivery_date.split(",")[0]}
            <br />
            {rowData.delivery_date.split(",")[1]}
        </div>
    }

    to_requested_start_date(rowData, column) {
        if (rowData.pickup_start_date == "") return <div></div>;
        return <div>
            {rowData.pickup_start_date.split(" ")[0]}
            <br />
            {rowData.pickup_start_date.split(" ")[1]}
        </div>
    }
    to_requested_end_date(rowData, column) {
        if (rowData.pickup_end_date == "") return <div></div>;
        return <div>
            {rowData.pickup_end_date.split(" ")[0]}
            <br />
            {rowData.pickup_end_date.split(" ")[1]}
        </div>
    }
    onSelectedStatus(e) {
        if (e.value == 99) {
            if (window.confirm('ต้องการยกเลิก Order นี้ ?')) {
                this.setState({ status2: e.value })
            }
        } else
            this.setState({ status2: e.value })
    }

    showProgressSpinner() {
        const Progress = (
            <div className="p-col-12 p-md-8 p-lg-9" >
                <ProgressSpinner style={{ width: '80px', height: '80px' }} strokeWidth="8" fill="#EEEEEE" animationDuration="0.5s" />
            </div>
        );

        if (this.state.showProgress) {
            return (Progress);
        } else
            return (<div className="p-col-12 p-md-8 p-lg-9"></div>);
    }
    showReportResult() {
        if (this.state.showReportTable) {
            this.setState({ showReportTable: false })
        } else {
            this.setState({ showReportTable: true })
        }
    }

    to_created_date(rowData, column) {
        if (rowData.register_date == "") return <div></div>;
        return <div>
            {rowData.register_date.split(",")[0]}
            <br />
            {rowData.register_date.split(",")[1]}
        </div>
    }

    showSearchResult() {
        if (this.state.reportSearchOrders.length == 0 || !this.state.showReportPanel) {
            return (<div></div>);
        } else
            return (<div><div className="p-col-12" >

                <Accordion multiple={this.state.showReportTable} activeIndex={[0]} onTabChange={this.showReportResult}>
                    <AccordionTab header="รายงานตรวจสอบ Orders">
                        <DataTable value={this.state.reportSearchOrders} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                            responsive={true} selection={this.state.orderSelection} onSelectionChange={e => this.onOrderSelected(e)}>
                            <Column field="order_code" header="รายการ" sortable={true} filter={true} />
                            <Column field="u1_fullname" header="ชื่อ U1" sortable={true} filter={true} />
                            <Column field="u2_fullname" header="ชื่อ U2" sortable={true} filter={true} />
                            <Column field="u3_fullname" header="ชื่อ U3" sortable={true} filter={true} />
                            <Column field="pickup_start_date" body={this.to_requested_start_date.bind(this)} header="วันนัดรับเริ่ม" sortable={false} filter={true} className="p-text-center" />
                            <Column field="pickup_end_date" body={this.to_requested_end_date.bind(this)} header="วันนัดรับสิ้นสุด" sortable={false} filter={true} className="p-text-center" />
                            <Column field="delivery_date" body={this.to_transfer_date.bind(this)} header="วันนัดส่ง" sortable={false} filter={true} className="p-text-center" />
                            <Column field="delivery_cost" header="ค่าขนส่ง (บาท)" sortable={true} filter={true} className="p-text-center" />
                            <Column field="order_status_name" header="สถานะ" sortable={true} filter={true} className="p-text-center" />
                            <Column field="admin_name" header="ผู้ดูแล" sortable={true} filter={true} />
                        </DataTable>
                    </AccordionTab>
                </Accordion>
            </div></div>);
    }
    showProductImageView() {
        if (this.state.selectedProductImg != null) {
            return (<div>
                <img alt="Product Image" width="98%" src={this.state.selectedProductImg} />
                <Button

                    label="ซ่อนรูปภาพ"
                    icon="fa fa-eye-slash"
                    iconPos="left"
                    onClick={(e) => this.setState({ selectedProductImg: null })} />
            </div>
            );
        } else {
            return (<div></div>);
        }

    }
    showHideSearchPanel() {
        if (this.state.showHideSearch) {
            this.setState({ showHideSearch: false })
        } else {
            this.setState({ showHideSearch: true })
        }
    }

    showSearchPanel() {
        const searchResult = this.showSearchResult();
        return (
            <div className="p-col-12">
                <Accordion multiple={this.state.showHideSearch} activeIndex={[0]} onTabChange={this.showHideSearchPanel}>
                    <AccordionTab header="เงื่อนไขค้นหาเพื่อจัดการ Orders">
                        <div className="p-grid p-justify-start" >
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>Order ID :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.orderID}
                                    suggestions={this.state.orderIDSuggestions}
                                    onSelect={this.onSelectOrderID}
                                    completeMethod={this.suggestOrderID.bind(this)}
                                    onChange={(e) => this.onOrderIDChanged(e.value)}
                                    onKeyPress={this.onOrderKeyPress}
                                    itemTemplate={this.itemOrderIDTemplate.bind(this)}
                                    placeholder="Order ID " />
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>สมาชิก : </label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <AutoComplete
                                    value={this.state.memberName}
                                    suggestions={this.state.memberSuggestions}
                                    onSelect={this.onSelectMemberName}
                                    completeMethod={this.suggestMemberName.bind(this)}
                                    onChange={(e) => this.onMemberNameChanged(e.target.value)}
                                    itemTemplate={this.itemTemplate.bind(this)}
                                    placeholder="ระบุรหัสสมาชิก/ชื่อสมาชิก" />
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ alignSelf: 'center' }}>
                                <label>ประเภทสมาชิก :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Dropdown
                                    value={this.state.memberType}
                                    options={this.state.memberTypeItem}
                                    onChange={(e) => { this.setState({ memberType: e.value }) }}
                                >
                                </Dropdown>
                            </div>
                            <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <label>สถานะ :</label>
                            </div>
                            <div className="p-col-12 p-md-4 p-lg-10" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                <Dropdown
                                    value={this.state.status}
                                    options={this.state.statusSearchItem}
                                    onChange={(e) => { this.setState({ status: e.value }) }}
                                >
                                </Dropdown>
                            </div>



                            <div className="p-col-12">
                                <div className="p-field-radiobutton">
                                    <RadioButton inputId="d1" name="selectDay" value={0} onChange={(e) => this.setState({ selectDay: e.value, date1: null, date2: null })} checked={this.state.selectDay === 0} />
                                    <label htmlFor="d1">วันรับสินค้า</label>
                                </div>
                                {this.state.selectDay == 0 &&
                                    <div className="p-grid p-col-12">
                                        <div className="p-col-12 p-md-2 p-lg-2" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <label>ช่วงวันรับสินค้า :</label>
                                        </div>
                                        <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.date3} onChange={this.onDate3Change} ></Calendar>
                                        </div>
                                        <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <label>ถึงวันที่ :</label>
                                        </div>
                                        <div className="p-col-12 p-md-4 p-lg-6" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.date4} onChange={this.onDate4Change} ></Calendar>
                                        </div>
                                    </div>
                                }
                                <br />
                                <div className="p-field-radiobutton">
                                    <RadioButton inputId="d2" name="selectDay" value={1} onChange={(e) => this.setState({ selectDay: e.value, date3: null, date4: null })} checked={this.state.selectDay === 1} />
                                    <label htmlFor="d2">วันส่งสินค้า</label>
                                </div>
                                {this.state.selectDay == 1 &&
                                    <div className="p-grid p-col-12">
                                        <div className="p-col-12 p-md-2 p-lg-2" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <label>ช่วงวันส่งสินค้า :</label>
                                        </div>
                                        <div className="p-col-12 p-md-4 p-lg-3" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.date1} onChange={this.onDate1Change} ></Calendar>
                                        </div>
                                        <div className="p-col-12 p-md-2 p-lg-1" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <label>ถึงวันที่ :</label>
                                        </div>
                                        <div className="p-col-12 p-md-4 p-lg-6" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <Calendar dateFormat="dd/mm/yy" showIcon={true} value={this.state.date2} onChange={this.onDate2Change} ></Calendar>
                                        </div>
                                    </div>
                                }
                            </div>





                            <div className="p-col-12">
                                <br />
                                <Button
                                    style={{ marginRight: "20px" }}
                                    label="ค้นหา"
                                    icon="fa fa-search"
                                    iconPos="left"
                                    onClick={this.searchOrder} />

                                <Button
                                    style={{ backgroundColor: '#f0ad4e' }}
                                    type="submit"
                                    label="ยกเลิก"
                                    onClick={this.clearSearchInput}
                                />
                            </div>
                        </div>
                        {searchResult}

                    </AccordionTab>


                </Accordion></div>
        )
    }
    matchingResult() {
        const wastegenDetail = this.showWastegen();
        const wastetranDetail = this.showWastetran();
        const wasteproDetail = this.showWastepro();
        console.log(this.state.dataTableSelection3)
        if (this.state.dataTableSelection3 == '' || this.state.dataTableSelection3 == 0 || this.state.dataTableSelection2 == '') {
            return (<div></div>)
        } else
            return (<Accordion multiple={true} activeIndex={[0]}>
                <AccordionTab header="ผลการ Matching">
                    <div className="p-grid">
                        <div className="p-col-12" >
                            <label>ผู้รักษ์โลก</label>
                        </div>
                        <div className="p-col-12 pg-md-12 p-lg-3">

                        </div>
                        <div className="p-col-12 pg-md-12 p-lg-9">
                            <div className="p-col-12">
                                <Avatar src={`${API_ROOT}/wastegen/getfile/${this.state.genImg}`} />
                            </div>
                            {wastegenDetail}
                        </div>

                        <div className="p-col-12" >
                            <label>ทีมขนส่งขยะรีไซเคิล</label>
                        </div>
                        <div className="p-col-12 pg-md-12 p-lg-3">

                        </div>
                        <div className="p-col-12 pg-md-12 p-lg-9">
                            <div className="p-col-12">
                                <Avatar src={`${API_ROOT}/wastetran/getfile/${this.state.tranImg}`} />
                            </div>
                            {wastetranDetail}

                        </div>

                        <div className="p-col-12" >
                            <label>ศูนย์จัดการฯซีซาเล้ง</label>
                        </div>
                        <div className="p-col-12 pg-md-12 p-lg-3">

                        </div>
                        <div className="p-col-12 pg-md-12 p-lg-9">
                            <div className="p-col-12">
                                <Avatar src={`${API_ROOT}/wastetran/getfile/${this.state.proImg}`} />
                            </div>
                            {wasteproDetail}

                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
            )
    }
    addNewProductTab() {
        const {
            productType,
            product,
        } = this.props;
        if (this.state.status2 != 1 && this.state.status2 != 2) {
            return (<div></div>)
        }
        return (
            <Accordion multiple={true} activeIndex={[0]}>
                <AccordionTab header="เพิ่มรายการสินค้า">
                    <div className="p-grid">
                        <label className="p-col-12 ">ประเภทขยะรีไซเคิล :</label>
                        <div className="p-col-12">
                            <Dropdown
                                style={{ width: '100%' }}
                                value={this.state.wasteTypeSelected}
                                options={productType}
                                onChange={this.onWasteTypeChange}
                                placeholder="เลือกประเภทขยะรีไซเคิล"
                                optionLabel="product_type_name"
                                dataKey="product_type_code"
                            />
                        </div>
                        <div className="p-col-12">
                            <label className="p-col-12 ">สินค้า :</label>
                            <Dropdown
                                style={{ width: '100%' }}
                                value={this.state.productSelected}
                                options={product}
                                onChange={this.onproductsChange}
                                placeholder="เลือกสินค้า"
                                optionLabel="product_name"
                                dataKey="product_code"
                            />
                        </div>
                        <label className="p-col-12 p-md-6 p-lg-4">จำนวน (กิโลกรัม)</label>
                        <div className="p-col-12 p-md-6 p-lg-8">
                            <InputText value={this.state.amountSelected} keyfilter="money" placeholder="จำนวน" type="text" className="input-100" onChange={(e) => this.setState({ amountSelected: e.target.value })} />
                        </div>
                        <label className="p-col-12 p-md-6 p-lg-4">ราคาประเมิน/หน่วย</label>
                        <div className="p-col-12 p-md-6 p-lg-8">
                            <InputText value={this.state.appraisedPriceSelected} keyfilter="money" placeholder="00.00" type="text" className="input-100" onChange={(e) => this.setState({ appraisedPriceSelected: e.target.value })} />
                        </div>
                        <label className="p-col-12 p-md-6 p-lg-4">ราคาขาย/หน่วย</label>
                        <div className="p-col-12 p-md-6 p-lg-8">
                            <InputText value={this.state.salePriceSelected} keyfilter="money" placeholder="00.00" type="text" className="input-100" onChange={(e) => this.setState({ salePriceSelected: e.target.value })} />
                        </div>

                        <div className="p-col-12 ">
                            <Button
                                style={{ float: "left" }}
                                label="เพิ่มรายการ"
                                icon="fa fa-plus"
                                iconPos="right"
                                onClick={this.addDataToTable}
                            />
                        </div>
                    </div>
                </AccordionTab>
            </Accordion>
        )
    }
    showOrderDetail() {
        const {
            provinces,
        } = this.props;

        const statusName = this.showStatus();
        const wastegenName = this.showWastegenName();
        const productImageView = this.showProductImageView();
        const matchingTab = this.matchingResult();
        const addProductTab = this.addNewProductTab();

        var tranHeader = <div style={{ 'textAlign': 'left' }}>
            <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
            <InputText type="search" onInput={(e) => this.setState({ globalFilterTran: e.target.value })} placeholder="กรอกชื่อ-สกุล หรือที่อยู่" size="50" />
        </div>;
        var proHeader = <div style={{ 'textAlign': 'left' }}>
            <i className="pi pi-search" style={{ margin: '4px 4px 0 0' }}></i>
            <InputText type="search" onInput={(e) => this.setState({ globalFilterPro: e.target.value })} placeholder="กรอกชื่อ-สกุล หรือที่อยู่" size="50" />
        </div>;
        if (this.state.orderCode == null || this.state.showProgress) {
            return (<div></div>);
        } else
            return (
                <div className="p-grid">
                    <div className="p-col-12 pg-md-12 p-lg-6">
                        <div className="card card-w-title">
                            <Accordion multiple={true} activeIndex={[0, 1, 2]}>
                                <AccordionTab header="Order">
                                    <div className="p-grid">
                                        <div className="p-col-12 " >
                                            <label>Order Id : {this.state.orderCode}</label>
                                        </div>
                                        <div className="p-col-12" >
                                            <label>ชื่อผู้รักษ์โลก : {wastegenName}</label>
                                        </div>

                                        <div className="p-col-12" >
                                            <label>สถานะ : {this.state.statusName}</label>
                                        </div>
                                    </div>

                                </AccordionTab>
                                <AccordionTab header="รายละเอียดที่อยู่">
                                    <div className="p-grid">
                                        <label className="p-col-12 required">เลขที่บ้าน/ที่อยู่</label>
                                        <div className="p-col-12 ">
                                            <CEInputText
                                                id="addressDetail"
                                                type="text"
                                                className="input-100"
                                                value={this.state.addressDetail}
                                                name="addressDetail"
                                                placeholder="เลขที่บ้าน/ที่อยู่"
                                                tooltip="กรอก เป็นตัวเลขหรือตัวอักษร"
                                                maxlength={200}
                                                required={true}
                                                onChange={(e) => this.setState({ addressDetail: e.target.value })}
                                                isvalid={this.onValidation}
                                            />
                                        </div>
                                        <label className="p-col-12 required">จังหวัด</label>
                                        <div className="p-col-12 ">
                                            <CEDropdown
                                                required={true}
                                                style={{ width: "100%" }}
                                                value={this.state.provinceSelected}
                                                options={provinces}
                                                onChange={this.onProvinceChanged}
                                                optionLabel="province_name"
                                                placeholder="เลือกจังหวัด"
                                                isvalid={(valid) => console.log(valid)} />
                                        </div>
                                        <label className="p-col-12 required">อำเภอ/เขต</label>
                                        <div className="p-col-12 ">
                                            <CEDropdown
                                                required={true}
                                                style={{ width: "100%" }}
                                                value={this.state.districtSelected}
                                                options={this.state.districtList}
                                                onChange={this.onDistrictChanged} optionLabel="districtName" placeholder="อำเภอ/เขต"
                                                isvalid={(valid) => console.log(valid)} />
                                        </div>
                                        <label className="p-col-12 required">ตำบล/แขวง</label>
                                        <div className="p-col-12 ">
                                            <CEDropdown
                                                required={true}
                                                style={{ width: "100%" }}
                                                value={this.state.subDistrictSelected}
                                                options={this.state.subDistrictList}
                                                onChange={this.onSubDistrictChanged} optionLabel="subDistrictName" placeholder="ตำบล/แขวง"
                                                isvalid={(valid) => console.log(valid)} />
                                        </div>



                                        <label className="p-col-12 p-md-6" style={{ textAlign: 'left', alignSelf: 'center' }}>Line ID</label>
                                        <div className="p-col-12 p-md-6" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <InputText value={this.state.lineId} placeholder="Line ID" type="text" className="input-100" className="input-100" onChange={(e) => this.setState({ lineId: e.target.value })} />
                                        </div>
                                        <label className="p-col-12 p-md-6" style={{ textAlign: 'left', alignSelf: 'center' }}>Email</label>
                                        <div className="p-col-12 p-md-6" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            {/* <InputText value={this.state.email} placeholder="Email Address" type="text" className="input-100" className="input-100" onChange={(e) => this.setState({ email: e.target.value })} /> */}
                                            <CEInputText
                                                id="email"
                                                type="email"
                                                className="input-100"
                                                value={this.state.email}
                                                name="email"
                                                placeholder="email"
                                                tooltip="กรอก email"
                                                maxlength={45}
                                                required={false}
                                                onChange={(e) => this.setState({ email: e.target.value })}

                                            />
                                        </div>
                                        <label className="p-col-12 p-md-6 required" style={{ textAlign: 'left', alignSelf: 'center' }}>Mobile</label>
                                        <div className="p-col-12 p-md-6" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <CEInputText
                                                id="genPhone"
                                                type="telephone"
                                                className="input-100"
                                                value={this.state.genPhone}
                                                name="genPhone"
                                                placeholder="Mobile Number"
                                                tooltip="กรอก หมายเลข 10 หลัก"
                                                mask="9999999999"
                                                required={true}
                                                onChange={(e) => this.setState({ genPhone: e.target.value })}
                                                isvalid={this.onValidation}
                                            />
                                        </div>
                                        <div className="p-col-12">
                                            <label >

                                                พิกัดสถานที่รับขยะฯ (latitude: {this.state.mapGenMarker.lat} longitude:{this.state.mapGenMarker.lng})
                                            </label>
                                        </div>
                                        <div className="p-col-12">

                                            <MapWithASearchBox zoom={this.state.mapGenZoom} marker={this.state.mapGenMarker} onMarkerMoveEnd={this.onMarkerMoved} onCenterChanged={this.onCenterChanged} center={this.state.mapGenCenter} />
                                        </div>
                                    </div>
                                </AccordionTab>
                                <AccordionTab header="รายละเอียด Orders">
                                    <div className="p-grid">
                                        <div className="p-col-12 p-md-4 required" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <label >ช่วงวันที่นัดรับสินค้า :</label>
                                        </div>
                                        <div className="p-col-12 p-md-4 " style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <CECalendar
                                                dateFormat="dd/mm/yy" hourFormat="24" showIcon={true} showTime={true}
                                                value={this.state.pickDateStart}
                                                onChange={this.onPickDateStartChange} required={true}
                                                isvalid={(valid) => console.log("calendar:" + valid)}
                                            />
                                        </div>
                                        <div className="p-col-12 p-md-4 " style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <CECalendar
                                                dateFormat="dd/mm/yy" hourFormat="24" showIcon={true} showTime={true}
                                                value={this.state.pickDateEnd}
                                                onChange={this.onPickDateEndChange} required={true}
                                                isvalid={(valid) => console.log("calendar:" + valid)}
                                            />
                                        </div>
                                        {/* <div className="p-col-12 p-md-8 " style={{ textAlign: 'left', alignSelf: 'center' }}>
                                        <CECalendar 
                                            dateFormat="dd/mm/yy" hourFormat="24" showIcon={true} showTime={true}
                                            value={this.state.date1} 
                                            onChange={this.onDate1Change} required={true} 
                                            isvalid={(valid) => console.log("calendar:"+ valid)} />
                                            <CECalendar 
                                                dateFormat="dd/mm/yy" hourFormat="24" showIcon={true} showTime={false}
                                                value={this.state.pickDate} 
                                                onChange={this.onPickupDateChange} required={true} 
                                                isvalid={(valid) => console.log("calendar:"+ valid)}
                                                selectionMode="range" placeholder='เช่น 01/01/2020 - 02/01/2020'
                                                />
                                        </div> */}
                                        <div className="p-col-12 p-md-4 required" style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <label>วันที่นัดส่งสินค้า :</label>
                                        </div>
                                        <div className="p-col-12 p-md-8 " style={{ textAlign: 'left', alignSelf: 'center' }}>
                                            <CECalendar
                                                dateFormat="dd/mm/yy" hourFormat="24" showIcon={true} showTime={true}
                                                value={this.state.deliveryDate}
                                                onChange={this.onDeliveryDateChange} required={true}
                                                isvalid={(valid) => console.log("calendar:" + valid)} />
                                        </div>
                                        <label className="p-col-12 ">ค่าขนส่ง (บาท)</label>
                                        <div className="p-col-12 ">
                                            <CEInputText
                                                id="deliveryCost"
                                                type="money"
                                                className="input-100"
                                                value={this.state.deliveryCost}
                                                name="deliveryCost"
                                                placeholder="00.00"
                                                tooltip="กรอกค่าขนส่ง"
                                                required={false}
                                                onChange={(e) => this.setState({ deliveryCost: e.target.value })}
                                            />
                                        </div>

                                        <label className="p-col-12 ">รูปถ่ายขยะรีไซเคิล</label>
                                        <div className="p-col-12">


                                            {productImageView}
                                        </div>
                                        <div className="p-col-12">

                                            <DataView style={{ border: '0px' }} value={this.state.productImageNames} layout="grid" itemTemplate={this.itemProductImgTemplate}></DataView>
                                        </div>
                                        <div className="p-col-12">
                                            <CEFileUpload name="wastes[]"
                                                onSelect={(e) => this.onFileWasteChanged(e)}
                                                onDelete={(index) => this.onFileDelete(index)}
                                                mode="basic"
                                                value={this.state.fileImgWaste}
                                                chooseLabel="แนบไฟล์"
                                                cancelLabel="ยกเลิก"
                                                multiple={true}
                                                accept="image/*"
                                                maxFileSize={10000000} />
                                        </div>
                                    </div>
                                </AccordionTab>
                            </Accordion>
                        </div>


                        {/* 
                        <div className="card card-w-title">
                            <Accordion multiple={true} activeIndex={[0]}>
                                
                            </Accordion>



                        </div> */}
                    </div>

                    <div className="p-col-12 pg-md-12 p-lg-6">
                        <div className="card card-w-title">
                            <Accordion multiple={true} activeIndex={[0, 1, 2]}>
                                <AccordionTab header="ค้นหา ศูนย์จัดการฯซีซาเล้ง">
                                    <div className="p-grid">
                                        <DataTable value={this.state.wasteproDataList} header={proHeader}
                                            globalFilter={this.state.globalFilterPro}
                                            //paginatorPosition="bottom" paginator={true} rows={5} rowsPerPageOptions={[5, 10, 15]}
                                            selectionMode="single" scrollable={true} scrollHeight="150px"
                                            responsive={true} selection={this.state.dataTableSelection3} onSelectionChange={e => this.onTableProSelected(e)}>
                                            <Column field="pro_name" header="ชื่อ-สกุล" />
                                            <Column field="address_detail" header="ที่อยู่" />
                                            <Column body={this.distanceTemplate} header="ระยะทาง (กม.)" style={{ width: '8em' }} />
                                        </DataTable>
                                        <div className="p-col-12">
                                            <MapMarkers marker={this.state.mapGenMarker} onMarkerClicked={this.onMarkerProClicked} onBoundsChanged={this.mapChanged} markers={this.state.mapProMarkers} center={this.state.mapProCenter} zoom={this.state.mapProZoom} />
                                        </div>
                                    </div>
                                </AccordionTab>

                                <AccordionTab header="ค้นหาอทีมขนส่งขยะรีไซเคิล">
                                    <div className="p-grid">
                                        <div className="p-col-12">
                                            <DataTable value={this.state.wastetranDataList} header={tranHeader}
                                                globalFilter={this.state.globalFilterTran}
                                                // paginatorPosition="bottom" rows={5} rowsPerPageOptions={[5, 10, 15]} paginator={true}
                                                selectionMode="single"
                                                scrollable={true} scrollHeight="150px"
                                                responsive={true} selection={this.state.dataTableSelection2} onSelectionChange={e => this.onTableTranSelected(e)}>
                                                <Column field="tran_name" header="ชื่อ-สกุล" />
                                                <Column field="address_detail" header="ที่อยู่" />
                                                <Column body={this.distanceTemplate} header="ระยะทาง (กม.)" style={{ width: '8em' }} />
                                            </DataTable>
                                        </div>

                                    </div>
                                    <div className="p-col-12">
                                        <MapMarkers marker={this.state.mapTranMarker} onMarkerClicked={this.onMarkerClicked} onBoundsChanged={this.mapChanged} markers={this.state.mapTranMarkers} center={this.state.mapTranCenter} zoom={this.state.mapTranZoom} />
                                    </div>
                                </AccordionTab>
                            </Accordion>
                            {matchingTab}
                        </div>
                    </div>
                    <div className="p-col-12">
                        <div className="card card-w-title">
                            {addProductTab}
                            <DataTable header="รายละเอียดสินค้า" value={this.state.orderDetail} paginatorPosition="bottom" selectionMode="single" paginator={true} rows={15} rowsPerPageOptions={[5, 10, 15]}
                                responsive={true} selection={this.state.orderDetailSelection} onSelectionChange={event => this.setState({ orderDetailSelection: event.value })}>
                                <Column field="product_name" header="รายการ" sortable={true} filter={true} />
                                <Column field="quantity_kk" header="ปริมาณประเมิน(Kg)" filter={true} editor={this.quantityEditor} className="p-text-right" />
                                <Column field="estimate_price_per_unit" header="ราคาประเมิน/หน่วย(บาท)" filter={true} editor={this.estimatePricePerUnitEditor} className="p-text-right" />
                                <Column body={this.actionTemplate} header="ราคาประเมิน(บาท)" filter={true} className="p-text-right" />
                                <Column field="pickup_quantity_kk" header="ปริมาณเก็บ(Kg)" filter={true} editor={this.pickupQuantityEditor} className="p-text-right" />
                                <Column field="delivery_quantity_kk" header="ปริมาณส่ง(Kg)" filter={true} editor={this.deliveryQuantityEditor} className="p-text-right" />
                                <Column field="price_per_unit" header="ราคา/หน่วย(บาท)" filter={true} editor={this.deliveryPricePerUnitEditor} className="p-text-right" />
                                <Column body={this.actionDeliveryPriceTemplate} header="ราคาขาย (บาท)" filter={true} className="p-text-right" />
                                <Column field="remark" header="หมายเหตุ" filter={true} editor={this.remarkEditor} />
                            </DataTable>

                            <br />
                            <div className="p-col-12 p-lg-2" style={{ alignSelf: 'center' }}>
                                <label>สถานะ :</label>
                            </div>
                            <div className="p-col-12 p-lg-10" style={{ alignSelf: 'center' }}>
                                <Dropdown
                                    value={this.state.status2}
                                    options={this.state.statusItem}
                                    //onChange={(e) => { this.setState({ status2: e.value }) }}
                                    onChange={(e) => this.onSelectedStatus(e)}
                                />
                            </div>
                            <div className="p-col-12 " >
                                <label>memo :</label>
                            </div>
                            <div className="p-col-12 ">
                                <InputTextarea value={this.state.memo} autoResize={true} onChange={(e) => this.setState({ memo: e.target.value })} rows={5} style={{ width: '100%' }}></InputTextarea>
                            </div>
                        </div>
                    </div>

                    <div className="p-col-12">
                        <Button
                            style={{ marginRight: "20px" }}
                            type="submit"
                            label="บันทึกรายการ Order"
                            icon="fa fa-save"
                            iconPos="left"
                            onClick={this.onClickSaveOrder}
                        />
                        <Button
                            style={{ backgroundColor: '#f0ad4e' }}
                            type="submit"
                            label="ยกเลิก"
                            onClick={this.clearAllDataInput}
                        />
                    </div>

                </div>
            );
    }

    render() {
        const searchPanel = this.showSearchPanel();
        const orderDetail = this.showOrderDetail();
        const showProgress = this.showProgressSpinner();
        return (
            <div className="p-grid">
                <Growl ref={(el) => this.growl = el} />
                {searchPanel}
                {showProgress}
                {orderDetail}
            </div>
        );
    }
}



const mapStateToProps = (state) => ({

    dhbUsers: state.dhbAdmin.dhbUsers,
    locationMaster: state.location,
    provinces: state.location.province,
    districts: state.location.district,
    subDistricts: state.location.subDistrict,
    cardProvince: state.location.cardProvince,
    cardDistrict: state.location.cardDistrict,
    cardSubDistrict: state.location.cardSubDistrict,
    authentication: state.authentication,
    userInfo: state.userInfo.data,
    userInfoMaster: state.userInfo,
    updateWasteproProfile: state.updateWasteproProfile,
    banks: state.master.banks,
    productType: state.master.productType,
    product: state.master.product,
    getFileWastepro: state.getFileWastepro,
})

const mapDispatchToProps = dispatch => ({

    dashBoardServiceProps: bindActionCreators(DashBoardService, dispatch),
    locationServiceProps: bindActionCreators(LocationService, dispatch),
    userServiceProps: bindActionCreators(UserService, dispatch),
    masterServiceProps: bindActionCreators(MasterService, dispatch),
    fileServiceProps: bindActionCreators(FileService, dispatch),

})


export default
    connect(
        mapStateToProps,
        mapDispatchToProps, null, { forwardRef: true })(ManageOrderContainer)