

import React, { Component } from 'react';
import { connect } from 'react-redux';

import {Dropdown} from 'primereact/dropdown'
import {InputText} from 'primereact/inputtext'


class AddressInfoView extends Component {

    state = {

    }

    //>Initial 
    componentDidMount() {
        //console.log("AddressInfoView :componentDidMount")
    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        //console.log("AddressInfoView :componentWillReceiveProps")
    }

    shouldComponentUpdate(nextProps, nextState) {
        //console.log("AddressInfoView :shouldComponentUpdate")
        return true
    }

    componentWillUpdate(nextProps, nextState) {
        //console.log("AddressInfoView :componentWillUpdate")

    }

    componentDidUpdate(prevProps, prevState) {
        //console.log("AddressInfoView :componentDidUpdate")

    }

    //> Event
    onChangeInputText = (e) => {
        this.props.onChangeInputText(e);
    }
    onChangeAdrCurrProv = (e) =>{
        this.props.onChangeAdrCurrProv(e);
    }
    onChangeAdrCurrentDistrict = (e) =>{
        this.props.onChangeAdrCurrentDistrict(e);
    }
    onChangeAdrCurrentSubDistrict = (e) =>{
        this.props.onChangeAdrCurrentSubDistrict(e);
    }

    //> Mode render
    renderWastegen = () => {
        return (
            <div></div>
        )
    }
    renderWastetran = () => {
        return (
            <div></div>
        )
    }
    renderWastepro = () => {
        return (
            <div></div>
        )
    }

    render() {
        //console.log("AddressInfoView :render")
        const {
            mode
        } = this.props

        let renderMode;

        switch (mode) {
            case 'wastegen':
                renderMode = this.renderWastegen()
                break;
            case 'wastetran':
                renderMode = this.renderWastetran()
                break;
            case 'wastepro':
                renderMode = this.renderWastepro()
                break;

            default:
                renderMode = (<div></div>)
                break;
        }

        return (
            <div className="p-grid p-fluid">
                <div className="p-col-12">
                    <label className="required">จังหวัด</label>
                    <Dropdown style={{ width: '100%' }}
                        optionLabel="province_name"
                        value={this.props.selectedAdrCurrentProv}
                        options={this.props.province}
                        onChange={this.onChangeAdrCurrProv}
                        placeholder="เลือกจังหวัด" />

                </div>

                <div className="p-col-12">
                    <label className="required">อำเภอ/เขต</label>
                    <Dropdown style={{ width: '100%' }}
                        optionLabel="districtName"
                        value={this.props.selectedAdrCurrentDistrict}
                        options={this.props.currentDistrict}
                        onChange={this.onChangeAdrCurrentDistrict}
                        placeholder="เลือกอำเภอ/เขต" />

                </div>

                <div className="p-col-12">
                    <label className="required">ตำบล/แขวง</label>
                    <Dropdown style={{ width: '100%' }}
                        optionLabel="subDistrictName"
                        value={this.props.selectedAdrCurrentSubDistrict}
                        options={this.props.currentSubDistrict}
                        onChange={this.onChangeAdrCurrentSubDistrict}
                        placeholder="เลือกตำบล/แขวง" />

                </div>

                <div className="p-col-12">
                    <label className="required">เลขที่บ้าน/ที่อยู่</label>
                    <InputText
                        maxLength={200}
                        className="p-col-12"
                        placeholder="Address ID"
                        name="addr_current_detail"
                        value={this.props.addr_current_detail}
                        onChange={this.onChangeInputText}>
                    </InputText>
                </div>

                
                {renderMode}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
})

export default connect(mapStateToProps)(AddressInfoView)
