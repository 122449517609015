import React, { Component } from 'react';
import { connect } from 'react-redux';

import { userAnnounce } from '../../service/user.service';
import {
    getWasteproDashPayment,
    getWasteproDashProduct,
    getWasteproDashSummary,
    getWasteproDashOrderStat
} from '../../service/wastepro.service';
import { WasteNewView } from '../waste-news/WasteNewView';
import { Panel } from 'primereact/panel';
import { ListBox } from 'primereact/listbox';
import { ChatWasteproView } from '../message/ChatWasteproView';
import { Growl } from 'primereact/growl';
import {InputText} from 'primereact/inputtext';
import { Button } from 'primereact/button';


class WasteproChatMassage extends Component {

    constructor(props) {
        super(props)
        this.state = {
            dummyData: [
                { order: 'ขวดแก้ว(สีชา,สีเขียว,สีขาว)', amount: 0, unit: '0 kg', price: 0 },
                { order: 'ขวดพลาสติกใส', amount: 0, unit: '0 kg', price: 0 },
                { order: 'อลูมิเนียม', amount: 0, unit: '0 kg', price: 0 },
                { order: 'กระดาษ', amount: 0, unit: '0 kg', price: 0 },
                { order: 'กล่องนม', amount: 0, unit: '0 kg', price: 0 },
            ],
            selectedChat:'',
        }
    }

    componentDidMount() {
        console.log("WasteproDashboard :componentDidMount")
        this.props.dispatch(userAnnounce())
        this.props.dispatch(getWasteproDashPayment());

        // this.props.dispatch(getWasteproDashProduct());
        this.props.dispatch(getWasteproDashOrderStat());

        this.props.dispatch(getWasteproDashSummary(2));
        this.props.dispatch(getWasteproDashSummary(88));
        this.props.dispatch(getWasteproDashSummary(0));

    }

    //>Life Cycle : Re-render / Update
    componentWillReceiveProps(nextProps) {
        console.log("WasteproDashboard :componentWillReceiveProps")

    }
    shouldComponentUpdate(nextProps, nextState) {
        console.log("WasteproDashboard :shouldComponentUpdate")
        return true
    }
    componentWillUpdate(nextProps, nextState) {
        console.log("WasteproDashboard :componentWillUpdate")

    }
    componentDidUpdate(prevProps, prevState) {
        console.log("WasteproDashboard :componentDidUpdate")

    }

    render() {
        const {
            userAnnounce,
            getWasteproDashPayment,
            getWasteproDashProduct,
            getWasteproDashSummary,
            getWasteproDashOrderStat
        } = this.props

        //>ข่าวสาร
        const contentNews = (
            <WasteNewView data={userAnnounce.data} />
        );

        const citySelectItems = [
            { label: 'นาย อิศยา กาบนันทา', value: '1' },
            { label: 'eissaya U1', value: '2' },
            { label: 'usertran eisya', value: '3' },
            { label: 'ร้านมารวย', value: '4' },
            { label: 'นาย ประทีบ จันทรา', value: '5' },
            { label: 'นาย ประเทือง จันทรา', value: '6'},
            { label: 'นาย ประหยัด จันทรา', value: '7'},
            { label: 'นาย ประทุ จันทรา', value: '8'},
            { label: 'นาย ประปา จันทรา', value: '9'},
            { label: 'นาย ประวัติ จันทรา', value: '10'},
            { label: 'นาย ประจบ จันทรา', value: '11'},
            { label: 'นาย ประแจง จันทรา', value: '12'},
            { label: 'นาย ประนอม จันทรา', value: '13'},
            { label: 'นาย ประวรรณ จันทรา', value: '14'},
        ];
        const showChatTable = (
            <Panel header="Chat">
                <div className="p-grid p-fluid" >
                    <div className="p-col-12">
                        <div className="p-inputgroup">
                            <InputText placeholder="ค้นหาสมาชิก"/>
                            <Button 
                            icon="fa fa-search" 
                            />
                        </div>
                    </div>
                </div>
                <ListBox
                    value={this.state.selectedChat}
                    filter={true} 
                    filterPlaceholder="Search"
                    options={citySelectItems}
                    nChange={(e) => this.setState({ selectedChat: e.value })}
                    itemTemplate={this.carTemplate}
                    style={{ width: '100%' }}
                    listStyle={{ maxHeight: '400px' }} />
            </Panel>
    )

    const chatWastepro = (
            <ChatWasteproView
                valueDd={this.state.valueDd}
                listDd={this.state.listMessage}
                onChangeDd={this.onChangeDd}
                onChangeMessage={this.onChangeMessage}
                onSubmit={this.onSubmit}
                classNameRequired={this.state.classNameRequired}
            />
    );

        return (
            <div>
                <h1>Chat</h1>
                <Growl ref={(el) => this.growl = el} />
                <div className="p-grid dashboard">

                    {/* <div className="p-col-4 p-md-3 p-lg-4">
                        {showChatTable}
                    </div> */}
                    <div className="p-col-12"  >
                        {chatWastepro}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    userAnnounce: state.userAnnounce,
    getWasteproDashPayment: state.getWasteproDashPayment,
    getWasteproDashProduct: state.getWasteproDashProduct,
    getWasteproDashSummary: state.getWasteproDashSummary,
    getWasteproDashOrderStat:state.getWasteproDashOrderStat
})

export default connect(mapStateToProps)(WasteproChatMassage)