
import { API_ROOT } from '../constants/api'
import axios from './axios'
import {
    ADMIN_ANNOUNCE_ADD,
    ADMIN_DASH_SUMMARY_AREA,
    ADMIN_DASH_SUMMARY_WITHDRAW,
    ADMIN_DASH_SUMMARY_AREA_SEARCH,
    ADMIN_USER_LIST_CHAT,
    LOAD_MORE_ADMIN_USER_LIST,
    ADD_NEW_ADMIN_USER_LIST,
} from './actionType'
import { map, without, orderBy, includes } from 'lodash'

export const adminAnnounceAdd = (data) => (dispatch) => {
    dispatch({ type: ADMIN_ANNOUNCE_ADD.REQUEST, isLoading: true })
    return axios({
        method: 'post',
        url: `${API_ROOT}/AnnouncementLog/sendannouncement`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        },
        data: data
    })
        .then((res) => {
            if (res.status == 200) {
                return res
            }
            throw Error(res.status)
        })
        .then((res) => {
            dispatch({ type: ADMIN_ANNOUNCE_ADD.SUCCESS, payload: res.data })
        }).catch((error) => {
            dispatch({ type: ADMIN_ANNOUNCE_ADD.FAILURE, payload: error.message })
        })
}

export const getAdminDashSummaryArea = () => (dispatch) => {
    dispatch({ type: ADMIN_DASH_SUMMARY_AREA.REQUEST, isLoading: true })
    return axios({
        method: 'get',
        url: `${API_ROOT}/dashboard/summary/area`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
    }).then((res) => {
        dispatch({ type: ADMIN_DASH_SUMMARY_AREA.SUCCESS, payload: res.data })
    }).catch((error) => {
        dispatch({ type: ADMIN_DASH_SUMMARY_AREA.FAILURE, payload: error.message })
    })
}

export const getAdminDashSummaryAreaSearch = ({sale_year,sale_month}) => (dispatch) => {
    let sale_top = 5;

    dispatch({ type: ADMIN_DASH_SUMMARY_AREA_SEARCH.REQUEST, isLoading: true })
    return axios({
        method: 'get',
        url: `${API_ROOT}/dashboard/summary/area/${sale_year}/${sale_month}/${sale_top}`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
    }).then((res) => {
        dispatch({ type: ADMIN_DASH_SUMMARY_AREA_SEARCH.SUCCESS, payload: res.data })
    }).catch((error) => {
        dispatch({ type: ADMIN_DASH_SUMMARY_AREA_SEARCH.FAILURE, payload: error.message })
    })
}

export const getAdminDashSummaryWithdraw = () => (dispatch) => {
    dispatch({ type: ADMIN_DASH_SUMMARY_WITHDRAW.REQUEST, isLoading: true })
    return axios({
        method: 'get',
        url: `${API_ROOT}/dashboard/summary/withdraw`,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
    }).then((res) => {
        dispatch({ type: ADMIN_DASH_SUMMARY_WITHDRAW.SUCCESS, payload: res.data })
    }).catch((error) => {
        dispatch({ type: ADMIN_DASH_SUMMARY_WITHDRAW.FAILURE, payload: error.message })
    })
}

export const getUsersAllForManagement = (body) => (dispatch) => {
    dispatch({ type: ADMIN_USER_LIST_CHAT.REQUEST, isFetching: true })
    return axios({
      method: 'post',
      url: `${API_ROOT}/user/GetUsersAllForManagement`,
      data: body,
      headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
    }).then((res) => {
      dispatch({ type: ADMIN_USER_LIST_CHAT.SUCCESS, payload: res.data })
    }).catch((error) => {
      console.log(error)
      dispatch({ type: ADMIN_USER_LIST_CHAT.FAILURE, error })
    })
  }

  export const loadMoreUsersAllForManagement = (body) => dispatch => {
    dispatch({ type: LOAD_MORE_ADMIN_USER_LIST.REQUEST })
    return axios({
        method: 'post',
        url: `${API_ROOT}/user/GetUsersAllForManagement`,
        data: body,
        headers: {
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        }
    }).then(res => {
        dispatch({ type: LOAD_MORE_ADMIN_USER_LIST.SUCCESS, payload: res.data })
    }).catch(error => {
        dispatch({ type: LOAD_MORE_ADMIN_USER_LIST.FAILURE, error })
    })
  }

  export const addNewUsersAllForManagement = users => dispatch => {
    dispatch({ type: ADD_NEW_ADMIN_USER_LIST, users })
  }

  export const updateUsersAll = (chatList, roomId, type, ws) => dispatch => {
    if (chatList) {
      var chatListOfId = map(chatList, function(o) {
        if (o.last_chat.room_id == roomId) return o
      })
      var chatListById = without(chatListOfId, undefined)[0]
      var chatListNew = without(chatList, chatListById)
      if (chatList.length > 0) {
        if (type == 0) {
          chatListById.last_chat.count_unread = 0
        }
        if (type == 1) {
          chatListById.last_chat.count_unread += 1
          chatListById.last_chat.message_time = ws.send_datetime
        }
      }
      chatListNew.unshift(chatListById)
      dispatch({
        type: ADMIN_USER_LIST_CHAT.SUCCESS,
        payload: orderBy(chatListNew, 'last_chat.message_time', 'desc'),
      })
      return orderBy(chatListNew, 'last_chat.message_time', 'desc')
    }
  }